import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';

export const DATA_SELECTION_OPTION = ['Today', 'Past Week', 'Past Month', 'Past 3 Months']
export const DATE_ENTITY_PROPERTY = 'createdAt'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DEFAULT_START_DATE = moment().subtract(1, 'month').format(DATE_FORMAT)
export const DEFAULT_END_DATE = moment().format(DATE_FORMAT)

export const trackingTableColumnsBuilder = () => {
  const columns = []

  columns.push(...[
    { field: 'id', headerName: 'ID', width: 180, hide: true },
    { field: 'user', headerName: 'Sales Name', width: 180,
      valueFormatter: ({ value }) => `${value?.firstName} ${value?.lastName}` },
    { field: '__category', headerName: 'Category', width: 180,
      renderCell: ({ row }) => row?.formSubmission?.form?.category?.name
        ?? row?.poster?.campaign?.category?.name
        ?? row?.qrCode?.set?.category?.name },
    { field: 'poster', headerName: 'Poster', width: 200,
      valueFormatter: ({ value }) => value?.name },
    { field: '_campaign', headerName: 'Campaign', width: 200,
      valueFormatter: ({ row }) => row?.poster?.campaign?.id ? `${row?.poster?.campaign?.id}. ${row?.poster?.campaign?.name}` : '' },
    { field: 'formSubmission', headerName: 'Form', width: 200,
      valueFormatter: ({ value }) => value?.form?.id ? `${value?.form?.id}. ${value?.form?.name}` : '' },
    { field: 'qrCode', headerName: 'QR Code', width: 200,
      valueFormatter: ({ value }) => value?.id ? `${value?.id}. ${value?.name}` : '' },
    { field: 'ip', headerName: 'IP', width: 200 },
    { field: 'device', headerName: 'Device', width: 250 },
    { field: 'browser', headerName: 'Browser', width: 250 },
    { field: 'source', headerName: 'Source', width: 250 },
    { field: 'city', headerName: 'City', width: 200 },
    { field: 'country', headerName: 'Country', width: 200 },
    { field: 'postalCode', headerName: 'Postal', width: 200 },
    { field: 'address', headerName: 'Address', width: 350 },
    { field: 'createdAt', headerName: 'Date', width: 200,
      valueFormatter: ({ value }) => moment(value).format('L LT') },
  ])
  return columns
}

export const useChartStyles = makeStyles((theme) => ({
  title: {
    borderBottom: `2px ${theme.palette.primary.main} solid`,
    margin: theme.spacing(2, 0)
  },
  marginBottom: {
    marginBottom: '2.5rem'
  },
  pieGraph: {
    maxHeight: '200px'
  },
  barGraph: {
    maxHeight: '200px'
  },
  hBarGraph: {
    maxHeight: '450px'
  }
}))
