import { customerActionsType } from '../../actions';
import LocalStorage from '../../utils/LocalStorage';
import { LS_KEY_NOTE_EXPANDED } from '../../views/customer/interview/interviewHelper';

export const customerReducer = (state = initialState, action = {}) => {
  const {type, payload} = action;
  switch (type) {
    case customerActionsType.Register:
      return {...state, success: true, error: undefined, newCustomer: payload};
    case customerActionsType.actionError:
      return {...state, success: false, error: payload, user: null};

    case customerActionsType.FetchAllRoles:
      return {...state, allUserRoles: payload};

    case customerActionsType.FetchCustomers:
      return {...state, customers: payload, error: undefined};
    case customerActionsType.FetchCustomersPerPage:
      return {...state, customersPerPage: payload, error: undefined}
    case customerActionsType.FetchCurrentCustomer:
      return {...state, currentCustomer: payload, customerTableStatus: 'EDIT', error: undefined}
    case customerActionsType.SetCustomersTableStatus:
      return {...state, customerTableStatus: payload}
    case customerActionsType.ActiveCustomers:
      return {...state, activationMessage: payload}
    case customerActionsType.CreateCustomer:
      return {...state,customerTableStatus: 'closed',error: undefined}
    case customerActionsType.UpdateCustomer:
      return {...state,customerTableStatus: 'closed',error: undefined}
    case  customerActionsType.FetchCurrentCustomerCheck:
      return {...state,customerTableStatus: 'CHECK',currentCustomer: payload}
    case customerActionsType.Fetch_Products_In_Customer:
      return {...state,product: payload}
    case customerActionsType.FetchCustomerCategories:
      return { ...state, allCategories: action.payload }
    case customerActionsType.FetchCustomerNotes:
      return { ...state, currentCustomerNotes: action.payload }
    case customerActionsType.FetchCustomerPayment:
      return { ...state, currentCustomerPayment: action.payload }
    case customerActionsType.FetchCurrentCustomerContract:
      return { ...state, currentCustomerContract: action.payload }
    case customerActionsType.FetchCurrentCustomerInterview:
      return { ...state, currentCustomerInterview: action.payload }

    case customerActionsType.FetchCustomerImageFile:
      return { ...state, customerNoteImages: { ...state.customerNoteImages, [action.payload.id]: action.payload.image } }
    case customerActionsType.InitNoteExpand:
      return { ...state, customerNoteExpand: action.payload }
    case customerActionsType.ChangeNoteExpand:{
      let newNoteState = [ ...state.customerNoteExpand ]
      if (action.payload.expand) {
        // add id to list
        newNoteState.push(action.payload.id)
      } else {
        // remove id from list
        newNoteState = newNoteState.filter(id => id !== action.payload.id)
      }
      LocalStorage.setObjectItem(LS_KEY_NOTE_EXPANDED, newNoteState)   // save to local storage
      return { ...state, customerNoteExpand: newNoteState }}
    case customerActionsType.Searching:
      return {...state, isSearching: action.payload, error: undefined}
    case customerActionsType.DuplicateEmail:
      return {...state,success: false,error: action.payload, isSearching: false}
    case customerActionsType.ContinueCreate:
      return {...state,error: undefined, isSearching: false}
    // case customerActionsType.ValidEmailError:
    //   return {...state, error: action.payload, isSearching: false}
    case customerActionsType.FetchCustomerProps:
      return {...state,customerFetchBody:payload}


    case customerActionsType.FetchClasses:{
      const dataClass = state.customersPerPage.data?.map(cust =>({
          ...cust,
          class: payload?.filter(eachClass =>{
            const studentsId = eachClass.students?.map(student => student.id)
            return studentsId?.includes(cust.id)
          })
        }))
      return {...state,customersPerPage:{...state.customersPerPage, data : dataClass}, allClasses:payload}}



    case customerActionsType.FetchAllContracts: {
      const dataContract = state.customersPerPage?.data?.map(cust => ({
        ...cust,
        contract: payload?.filter(eachClass => eachClass.customer_id === cust.id
        )
      }))
      return {
        ...state,
        customersPerPage: { ...state.customersPerPage, data: dataContract },
        allContractsForCustomer: payload
      }
    }



    case customerActionsType.MapClassAndContract: {

      // !!state.allClasses && fetchClasses()(dispatch);
      // fetchAllContract()(dispatch);

      const dataClass2 = state.customersPerPage.data?.map(cust => ({
        ...cust,
        class: state?.allClasses?.filter(eachClass => {
          const studentsId = eachClass.students?.map(student => student.id)
          return studentsId?.includes(cust.id)
        })
      }))

      const dataContract2 = dataClass2?.map(cust => ({
        ...cust,
        contract: state?.allContractsForCustomer?.filter(eachClass => eachClass.customer_id === cust.id
        )
      }))

      return { ...state, customersPerPage: { ...state.customersPerPage, data: dataContract2 } }
    }

    case customerActionsType.copyCurrentCustomerInfo:
      return {...state, currentCustomerInfo: action.payload}



    default:
      return state;
  }
}

const initialState = {
  newCustomer:  undefined,
  error: undefined,
  success: undefined,
  customers: undefined,
  customersPerPage: {},    // Used for customer table pagination
  customerTableStatus: 'closed',
  currentCustomer: undefined,
  activationMessage: undefined,
  product: undefined,
  allUserRoles: [],
  allCategories: [],
  currentCustomerNotes: [],
  currentCustomerPayment: [],
  currentCustomerContract: {},
  currentCustomerInterview: {},
  isSearching: false,
  customerNoteImages: {},       // Image cache storage by Notes ID
  customerNoteExpand: [],        // Saves expand state for Notes grid
  customerFetchBody: {},


  allClasses: [],
  allContractsForCustomer: [],

  currentCustomerInfo: {}
}
export default  customerReducer