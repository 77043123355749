import React, { useEffect, useState } from 'react';
import { Button, Paper, SvgIcon , Stack } from '@mui/material';
import { PlusCircle as PlusIcon } from 'react-feather';
import { connect, useDispatch } from 'react-redux';
import ViewHead from '../../../components/public/ViewHead';
import { Functions } from '../../../utils/Functions';
import useAuthCheck from '../../../hooks/useAuthCheck';
import DataTable from './DataTable';
import { trainingMessageActionsTypes } from '../../../actions';
import AddMessage from './AddMessage';
import StudentMessageTable from './StudentMessageTable';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import extraMessageInfoModal from './extraMessageInfoModal';
import DraggableDialog from "../../../components/mui/dialog/DraggableDialog";



function MessageInfo({ formStatus, messageDetail }) {
  useCurrentViewCheck(Functions.Training_Message);
  const dispatch = useDispatch();
  const [isAdd, isEdit, isRead] = useAuthCheck(
    [Functions.Training_Message_Add_New_Message,
      Functions.Training_Message_Edit_Message,
      Functions.Training_Message_Student_Read]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState(<div />);
  const [bothStaffAndStudent, setBothStaffAndStudent] = useState(false);

  useEffect(() => {
    setModalInfo(extraMessageInfoModal(messageDetail));
  }, [messageDetail]);

  const handleModalOpen = async () => {
    setModalOpen(true);
  };

  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    let isBothStudentAndStaff = false;
    if (isAdd && isRead) {
      isBothStudentAndStaff = true;
    }
    if (isRead && !isBothStudentAndStaff) {
      dispatch({ type: trainingMessageActionsTypes.SetTrainingMessageFormStatus, payload: 'StudentView' });
    } else {
      setBothStaffAndStudent(true);
    }
  }, [isRead, isAdd]);

  const topButtons = () => {
    if (isAdd) {
      return (
        <Stack spacing={3} direction='row'>
          <Button color='secondary' variant='contained'
                  onClick={() => {
                    dispatch({ type: trainingMessageActionsTypes.SetTrainingMessageFormStatus, payload: 'ADD' });
                  }}
                  startIcon={
                    <SvgIcon fontSize='small'>
                      <PlusIcon />
                    </SvgIcon>
                  }
          >
            Add Message
          </Button>
          {
            bothStaffAndStudent &&
            <Button color='secondary' variant='contained'
                    onClick={() => {
                      dispatch({ type: trainingMessageActionsTypes.SetTrainingMessageFormStatus,
                        payload: formStatus === 'StudentView' ? 'Close' : 'StudentView' });
                    }}
                    startIcon={
                      <SvgIcon fontSize='small'>
                        <PlusIcon />
                      </SvgIcon>
                    }
            >
              { formStatus === 'StudentView' ? 'Message Management' : 'View Your Messages' }
            </Button>
          }
        </Stack>
      );
    } 
      return null;
    
  };

  const renderTable = () => {
    switch (formStatus) {
      case 'ADD':
        return <AddMessage />;
      // case 'EDIT':
      //   return <EditMessage />;
      case 'StudentView':
        return <StudentMessageTable isRead={isRead} />;
      default:
        return <DataTable isEdit={isEdit} handleModalOpen={handleModalOpen} />;
    }
  };

  return (
    <ViewHead noMaxWidth functionId={Functions.Training_Message}
              topButtons={formStatus === 'ADD' ? null : topButtons()}>
      {renderTable()}
        <DraggableDialog open={Boolean(modalOpen)} onClose={handleModalClose}
                         title='View Message' isLoaded={Boolean(modalOpen)}>
          <Paper elevation={0}>
            {modalInfo}
          </Paper>
        </DraggableDialog>
    </ViewHead>
  );
}

const mapStateToProps = ({ trainingMessage }) => ({
    formStatus: trainingMessage.trainingMessageFormStatus,
    messageDetail: trainingMessage.fetchedOneMessageInfo
  });

export default connect(mapStateToProps)(MessageInfo);
