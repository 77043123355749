import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Paper, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import { useInvoiceViewStyles } from '../invoiceHelper';
import InvoiceDisplayLoader from './InvoiceDisplayLoader';
import { fetchInvoicePublic, signInvoice } from '../../../../actions/administrative/administrativeAction';
import CenterDisplay from '../../../../components/misc/CenterDisplay';
import Page from '../../../../components/public/Page';
import { sendErrorMessage } from '../../../../actions';
import { cropSignatureCanvas, getImageSizeFromDataUrl } from '../../../../utils/ImageUtils';

function InvoicePublicView() {

  const {uid} = useParams()
  const {iid} = useParams()
  const dispatch = useDispatch()
  const classes = useInvoiceViewStyles()
  const currentInvoice = useSelector(state => state.administrative.currentInvoice)

  const { t, i18n } = useTranslation()

  const signatureRef = useRef(null)

  const [isSaving, setIsSaving] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [message, setMessage] = useState('Loading...')

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchInvoicePublic(uid, iid, setMessage, t('contractSignedMessage'))(dispatch))
    })()
  }
  useEffect(loadData, [dispatch, uid, iid])

  useEffect(() => {
    if (isLoaded) {
      if (currentInvoice?.head?.language && i18n.language !== currentInvoice.head.language) {
        i18n.changeLanguage(currentInvoice.head.language)
      }
      document.title = `${t("invoice")}: ${iid}`
    } else {
      document.title = t("invoice")
    }
  }, [isLoaded, iid])

  const renderSignature = () => (
      <div className={classes.marginTop}>
        <Typography variant='h3'>{t('signAndConfirm')}:</Typography>
        <div className={classes.signatureArea}>
          <SignaturePad ref={signatureRef} />
        </div>
        <Grid container justifyContent='flex-start'>
          <Grid item>
            <Tooltip title={t('clear')} placement='top'>
              <IconButton color='primary'
                          size='small'
                          component='span'
                          onClick={() => signatureRef?.current?.clear()}>
                <Delete size={20} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    )

  const renderInvoice = () =>
    <Page className={classes.root}>
      <Container className={classes.container} maxWidth='md'>

        <InvoiceDisplayLoader invoice={currentInvoice}/>

        <Paper className={classes.paper}>
          <div className={classes.marginTop}>
            { renderSignature() }
          </div>
        </Paper>

        <div className={classes.submitButtonArea}>{ renderSubmit() }</div>
      </Container>
      <div className={classes.marginBottom} id='emptyDivAtTheBottom' />
    </Page>


  const onSubmitError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }
  const onSubmitSuccess = () => {
    setIsLoaded(false)
    setMessage(t('contractSignedMessage'))
  }

  const renderSubmit = () => (
      <Grid container justifyContent='space-around' spacing={1}>
        <Grid item>
          <Button color='primary'
                  variant='contained'
                  disabled={isSaving}
                  onClick={() => onSubmit()}>
            { isSaving ? t('submitting') : t('confirm') }
          </Button>
        </Grid>
      </Grid>
    )

  const onSubmit = async () => {
    setIsSaving(true)

    try {
      if (signatureRef?.current.isEmpty()) {
        setIsSaving(false)
        sendErrorMessage(dispatch, t('signatureError'))
        return
      }

      const signature = signatureRef.current.toDataURL('image/png')
      const encodeSignature = signature?.replace('data:image/png;base64,', '')

      if (!signature) {
        setIsSaving(false)
        sendErrorMessage(dispatch, t('signatureErrorGeneric'))
        return
      }

      // Test signature size
      const signatureData = cropSignatureCanvas(signatureRef.current.canvas)
      const imageSizeSig = getImageSizeFromDataUrl(signatureData)

      if (imageSizeSig < 1000) {
        // if the signature is too small
        setIsSaving(false)
        sendErrorMessage(dispatch, t('signatureErrorSize'))
        return
      }

      const data = {
        signature: encodeSignature,
        iid: currentInvoice.iid,
        uid: parseInt(uid,10),
      }

      await signInvoice(data, onSubmitSuccess, onSubmitError)(dispatch)
    } catch (e) {
      setIsSaving(false)
      sendErrorMessage(dispatch, t('signatureErrorGeneric'))
    }
  }

  return (
    <div>
      {
        isLoaded
          ? renderInvoice()
          :
          <CenterDisplay>
            <Typography variant='h1' color='textPrimary'>{ message }</Typography>
          </CenterDisplay>
      }
    </div>
  )
}

export default InvoicePublicView
