import {Pagination, FormControl, Grid, MenuItem, Select, Typography} from '@mui/material'
import React, {useState} from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {useDispatch} from 'react-redux'
import {ChangeRowsPerPage} from '../../../actions'
import LocalStorage from '../../../utils/LocalStorage'
import {LocalStorageItems, PAGE_PARAMS} from '../../../constants'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1, 2)
    },
    selection: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        fontSize: '0.875rem'
    }
}))

function CustomFooterOverlay(page, handlePageChange, pageSize, setPageSize, apiRef, pageCount) {
    // for pages without setPageSize
    const isSetPageSize = apiRef !== undefined
    const tempRef = isSetPageSize ? apiRef : setPageSize

    const classes = useStyles()
    const selectionSize = Object.keys(tempRef.current?.state?.selection)?.length || 0
    // Incorporate FILTERS selection
    const allRows =
        tempRef?.current?.state?.getVisibleRowModels?.length > 0
            ? tempRef?.current.state?.getVisibleRowModels.length
            : tempRef.current?.state?.rows.ids?.length

    const [rowPerPage, setRowPerPage] = useState(() => {
        try {
            const userSetting = LocalStorage.getObjectItem(LocalStorageItems.UserSetting)
            if (userSetting.rowsPerPage) {
                return userSetting.rowsPerPage
            }
            return pageSize
        } catch (e) {
            return pageSize
        }
    })
    const rowsPerPage = tempRef.current?.state?.options?.rowsPerPageOptions || PAGE_PARAMS.rowsPerPageOptions
    const dispatch = useDispatch()
    const handlePageSizeChange = e => {
        ChangeRowsPerPage(e.target.value)(dispatch)
        setPageSize(e.target.value)
        setRowPerPage(e.target.value)
    }

    return (
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" className={classes.root}>
            <Grid item>{selectionSize > 0 ? `${selectionSize} items selected` : `${allRows} items`}</Grid>

            <Grid item>
                <Grid container alignItems="center">
                    {isSetPageSize && (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Grid item>
                                <Typography variant="body2">Page Size:</Typography>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <Select
                                        size="small"
                                        value={rowPerPage}
                                        onChange={e => handlePageSizeChange(e)}
                                        // disableUnderline
                                        className={classes.selection}
                                    >
                                        {rowsPerPage.map((t, i) => (
                                            <MenuItem key={i} value={t}>
                                                {t}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </div>
                    )}
                    <Grid item>
                        <Pagination
                            count={pageCount || Math.ceil(allRows / rowPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            siblingCount={1}
                            boundaryCount={2}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CustomFooterOverlay
