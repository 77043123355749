import React from "react";
import { Navigate } from 'react-router';
import FunctionView from '../../../views/system/function';

const functionRoutes = [
    {
        path: 'function',
        routes: [
            {
                path: "",
                exact: 'true',
                component:<FunctionView/>
            },
            {
                component:()=><Navigate to="404"/> // this must be the last one
            },

        ]
    },
]

export default functionRoutes;