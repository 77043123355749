import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IconButton, Paper } from '@mui/material';
import {Edit as EditIcon, Eye} from 'react-feather';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import EnhancedTableToolbar from './EmployeeDetails/EnhancedTableToolBar';
import { activateEmployee, employeeActions, fetchAllRoles, fetchEmployees } from '../../actions';
import { CATEGORY_ITEMS } from '../../constants';
import PageGrid from '../../components/mui/datagrid/PageGrid';
import DraggableDialog from "../../components/mui/dialog/DraggableDialog";
import EmployeeDetails from "./EmployeeDetails/EmployeeDetails";
import EmployeeInfo from "./EmployeeDetails/EmployeeInfo";

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'fixed',
    right: 100,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  Button: {
    position: 'fixed',
    right: 100
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  root: {
    paddingLeft:1,
    paddingRight:1,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

function TableRender({
                       employees, allUserRoles, activationMessage
                     }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState([]);

  const loadData = () => {
    // only attempt to fetch roles if there are no roles
    if (allUserRoles.length <= 0) {
      fetchAllRoles()(dispatch);
    }
  };
  useEffect(loadData, [dispatch, allUserRoles]);

  // const [open, setOpen] = useState(false);
  // const [hash, setHash] = useState('init');
  // const [delId, setDelId] = useState(null);
  // const [clickedRow, setClickedRow] = useState("")
  const [openRead, setOpenRead] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // const handleDelete = id => {
  //   setDelId(id);
  //   setOpen(true);
  //   setHash(Math.random().toString(16).substring(2, 10));
  // };
  // for update
  const handleEdit = id => {
    dispatch({ type: employeeActions.SetEmployeeFormStatus, payload: 'EDIT' });
    dispatch({ type: employeeActions.SetCurrentEditEmployee, payload: id });
  };

  const handleShow = id => {
    dispatch({ type: employeeActions.SetEmployeeFormStatus, payload: 'READ' });
    dispatch({ type: employeeActions.SetCurrentEditEmployee, payload: id });
  };

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      resizable: false,
      disableClickEventBubbling: true,
      filterable: false,
      width: 105,
      renderCell: params => (
        <div style={{ position: 'relative', width: '100%' }}>
          <div className={classes.funcCell}>
              <Tooltip title='Read' placement='top'>
                <IconButton color='primary' component='span' size='small' style={{ marginLeft: 10 }}
                            onClick={async (event) => {
                              event.stopPropagation();
                              await handleShow(params.row.id);
                              // await setClickedRow(params.row.id);
                              await setOpenRead(true);
                            }}>
                  <Eye size={20} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Edit' placement='top'>
                <IconButton color='primary' component='span' size='small'
                            onClick={async (event) => {
                              event.stopPropagation();
                              await handleEdit(params.row.id);
                              // await setClickedRow(params.row.id);
                              await setOpenEdit(true);
                            }
                            }
                >
                  <EditIcon size={20} />
                </IconButton>
              </Tooltip>
            </div>
        </div>
      )
    },
    { field: 'id', headerName: 'ID', width: 100 },
    {
      field: 'name', headerName: 'Name', width: 150,
      renderCell: params => {
        const name = params.row.nickName ? `${params.row.firstName} (${params.row.nickName}) ${params.row.lastName}` : `${params.row.firstName} ${params.row.lastName}`;
        return params.row.isActive ? name : <del>{name}</del>;
      }
    },
    { field: 'email', headerName: 'Email', width: 230 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'wechat', headerName: 'Wechat', width: 115 },
    { field: 'isStaff', headerName: 'Staff', width: 120, type: 'boolean' },
    { field: 'isCustomer', headerName: 'Customer', width: 120, type: 'boolean' },
    {
      field: 'role', headerName: 'Role', width: 180,
      renderCell: params => `${params.row.isActive === true ? 'A' : 'I'} | ${params.row.roleIds.map(roleId =>
        allUserRoles.find(role => role.id.toString() === roleId.toString())?.name || 'Unknown').join(', ')}`
    },
    {
      field: 'isPartTime', headerName: 'Status', width: 100,
      renderCell: params => params.row.staffInfo?.status
    },
    {
      field: 'createdAt', headerName: 'Created', width: 200,
      valueFormatter: ({ value }) => moment(value).format('L LT')
    }
  ];

  const [searchArgs, setSearchArgs] = useState({
    searchKey: undefined,
    role: CATEGORY_ITEMS.all,
    category: CATEGORY_ITEMS.all,
    isActive: undefined,
    status: CATEGORY_ITEMS.all
  });

  const fetchProps = useSelector(state => state.employee.employeeFetchBody);
  const [activation, setActivation] = useState(CATEGORY_ITEMS.all);
  const handleSearchChange = searchVal => {
    const searchStr = searchVal.trim().toLowerCase();
    setSearchArgs(prevState => ({
      ...prevState,
      searchKey: searchStr
    }));
  };

  const handleRoleChange = newRole => setSearchArgs(prevState => ({
    ...prevState,
    role: newRole,
    category: newRole === 'Customer'
      ? true
      : newRole === 'Staff'
        ? false
        : CATEGORY_ITEMS.all
  }));

  const handleStatusChange = newStatus => setSearchArgs(prevState => ({
    ...prevState,
    status: newStatus
  }));

  const setActiveStatus = status =>
    setSearchArgs(prevState => ({
      ...prevState,
      isActive: status
    }));

  const handleActiveChange = isActive => {
    if (isActive === 'Active') {
      setActiveStatus(true);
      setActivation('Active');
    } else if (isActive === 'Inactive') {
      setActiveStatus(false);
      setActivation('Inactive');
    } else {
      setActiveStatus(undefined);
      setActivation(CATEGORY_ITEMS.all);
    }
  };

  const onActive = (isActive) => {
    const data = { ids: selected, isActive };
    activateEmployee(data, fetchProps)(dispatch);
  };

  useEffect(() => {
    if (activationMessage) {
      //  setMessage(true)
      setSelected([]);
    }
  }, [activationMessage]);


  return (
    <div>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleChange={handleSearchChange}
          setSelectedRole={handleRoleChange}
          setSelectedActive={handleActiveChange}
          setSelectedStatus={handleStatusChange}
          selectedRole={searchArgs.role}
          selectedActive={activation}
          selectedStatus={searchArgs.status}
          onActive={onActive}
          isLoading={isLoading}
        />
        <div style={{ minHeight: 400, width: '100%' }}>
          <PageGrid
            columns={columns}
            datas={employees}
            searchArgs={searchArgs}
            fetchDatas={fetchEmployees}
            selected={selected}
            setSelected={setSelected}
            setIsLoading={setIsLoading}
          />
        </div>
      </Paper>
      <DraggableDialog open={Boolean(openRead)} onClose={() => setOpenRead(null)}
                       title='View Employee' isLoaded={Boolean(openRead)}>
        <Paper elevation={0}>
          <EmployeeDetails/>
        </Paper>

      </DraggableDialog>

      <DraggableDialog open={Boolean(openEdit)} onClose={() => setOpenEdit(null)}
                       title='Edit Employee' isLoaded={Boolean(openEdit)}>
        <Paper elevation={0}>
          <EmployeeInfo/>
        </Paper>

      </DraggableDialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
    allUserRoles: state.customer.allUserRoles,
    delStaffId: state.employee.delStaffId,
    activationMessage: state.employee.activationMessage
  });

export default connect(mapStateToProps, { fetchEmployees })(TableRender);
