import { getColorForGraph } from '../../../utils/Scripts';

const _genDataSet = (resultData) => {
  const labels = Object.keys(resultData)
  const data = Object.values(resultData)
  const {length} = data

  return {
    labels,
    datasets: [
      {
        data,
        borderWidth: 1,
        backgroundColor: getColorForGraph(length),
      },
    ],
  }
}

export const genOverviewByUserChartData = (allData, showActive, activeParams) => {
  const dataByUserData = allData.reduce((res, cur) => {
    if (!showActive || activeParams.every(param => cur[param])) {
      const currentUser = cur.user_fullName
      if (res[currentUser]) {
        res[currentUser] += 1
      } else {
        res[currentUser] = 1
      }
    }
    return res
  }, {})

  return _genDataSet(dataByUserData)
}

export const genOverviewByCatData = (allData, catName, showActive, activeParams) => {
  const dataByCat = allData.reduce((res, cur) => {
    if (!showActive || activeParams.some(param => cur[param])) {
      const dataRes = cur[catName] && cur[catName] !== '' ? cur[catName] : 'Unknown'
      if (res[dataRes]) {
        res[dataRes] += 1
      } else {
        res[dataRes] = 1
      }
    }
    return res
  }, {})
  return _genDataSet(dataByCat)
}

export const genOverviewByDeviceData = (allData, deviceParamName, showActive, activeParams) => {
  const dataByCat = allData.reduce((res, cur) => {
    if (!showActive || activeParams.some(param => cur[param])) {
      const dataRes = (cur[deviceParamName] && cur[deviceParamName] !== ''
        ? cur[deviceParamName]
        : 'Unknown Unknown').split(' ')[0]
      if (res[dataRes]) {
        res[dataRes] += 1
      } else {
        res[dataRes] = 1
      }
    }
    return res
  }, {})
  return _genDataSet(dataByCat)
}

export const genOverviewByBrowserData = (allData, deviceParamName, showActive, activeParams) => {
  const dataByCat = allData.reduce((res, cur) => {
    if (!showActive || activeParams.some(param => cur[param])) {
      const dataRes = (cur[deviceParamName] && cur[deviceParamName] !== ''
        ? cur[deviceParamName]
        : 'Unknown/Unknown').split('/')[0]
      if (res[dataRes]) {
        res[dataRes] += 1
      } else {
        res[dataRes] = 1
      }
    }
    return res
  }, {})
  return _genDataSet(dataByCat)
}

export const genUserDataByCatData = (selectedUser, allData, catNames, showActive, activeParams) => {
  const dataByCat = allData.reduce((res, cur) => {
    if (cur.user_fullName === selectedUser) {
      let found
      if (!showActive || activeParams.some((param, idx) => {
        found = cur[catNames[idx]]
        return cur[param] && cur[catNames[idx]]
      })) {
        const dataRes = found ?? cur[catNames.find(c => cur[c])] ?? 'Unknown'
        if (res[dataRes]) {
          res[dataRes] += 1
        } else {
          res[dataRes] = 1
        }
      }
    }
    return res
  }, {})

  return _genDataSet(dataByCat)
}
