import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {en} from './locales/en'
import {zh} from './locales/zh'
import { LANGUAGE } from '../constants';

const resources = {
    en,
    zh
}

const lng = localStorage.getItem('language') || LANGUAGE.ENGLISH

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng,
        fallbackLng: LANGUAGE.ENGLISH,
        keySeparator: ".",

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
