import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonGroup, FormControlLabel,
  FormHelperText,
  Grid,
  Paper, Portal, Switch,
  TextField,
  Typography
, Autocomplete } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';
import moment from 'moment';
import parse from 'html-react-parser';
import { ServerBaseURL } from '../../../../constants';
import { useContractViewStyles } from '../../contract/contractHelper';
import { genInvoiceID, INVOICE_EDITOR_VALIDATOR, useInvoiceEditorStyles } from '../invoiceHelper';
import {
  createInvoice,
  fetchAllCustomerForInvoice,
  fetchAllInvoiceHeads, updateInvoice
} from '../../../../actions/administrative/administrativeAction';
import SubmitButton from '../../../../components/mui/button/SubmitButton';
import { sendErrorMessage } from '../../../../actions';
import InvoiceEditorItems from './InvoiceEditorItems';


const schema = yup.object().shape(INVOICE_EDITOR_VALIDATOR)
function InvoiceEditor({ isEdit, originalData, onSubmitSuccess }) {

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  })

  const dispatch = useDispatch()
  const classes = useInvoiceEditorStyles()
  const viewClasses = useContractViewStyles()

  const portalRef = useRef(null)

  const allCustomers = useSelector(state => state.administrative.allCustomers)
  const allTemplates = useSelector(state => state.administrative.allInvoiceHeads)

  const [type, setType] = useState(isEdit ? originalData.type : null)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isCustomerLoaded, setIsCustomerLoaded] = useState(false)
  const [isTemplatesLoaded, setIsTemplatesLoaded] = useState(false)

  const [isIDTouched, setIsIDTouched] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [forceRefresh, setForceRefresh] = useState(0)

  const [invoiceBody, setInvoiceBody] = useState(
    isEdit
      ? {
        iid: originalData.iid, dateCode: originalData.dateCode,
        dueDate: moment(originalData.dueDate).format('YYYY-MM-DD'),
        notes: '', instructions: originalData.instructions,
        tax: originalData.tax
      }
      :
      {
        iid: genInvoiceID(),
        dateCode: moment().format('YYYYMMDD'),
        dueDate: moment().add(5, 'days').format('YYYY-MM-DD'),
        notes: '', instructions: '', tax: true
      }
  )

  const [selectedHeadAsk, setSelectedHeadAsk] = useState(null)
  const [selectedBillingAsk, setSelectedBillingAsk] = useState(null)
  const [selectedHeadIssue, setSelectedHeadIssue] = useState(null)
  const [selectedBillingIssue, setSelectedBillingIssue] = useState(null)
  const [customerEmail, setCustomerEmail] = useState(isEdit ? originalData.email : '')
  const [customerIsCompany, setCustomerIsCompany] = useState(isEdit ? originalData.isCompany : false)
  const [invoiceItems, setInvoiceItems] = useState([])

  const loadData = () => {
    (async() => {
      setIsCustomerLoaded(await fetchAllCustomerForInvoice()(dispatch))
      setIsTemplatesLoaded(await fetchAllInvoiceHeads()(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    // Load all details
    if (isCustomerLoaded && isTemplatesLoaded) {
      setIsLoaded(true)
      if (isEdit) {
        // load old head
        if (type === 'asked') {
          setSelectedHeadAsk(allCustomers.find(usr => usr.id === originalData.customer?.id))
          setSelectedBillingAsk(allTemplates.find(template => template.id === originalData.head?.id))
        } else if (type === 'issuing') {
          setSelectedHeadIssue(allTemplates.find(template => template.id === originalData.head?.id))
          setSelectedBillingIssue(allCustomers.find(usr => usr.id === originalData.customer?.id))
        }

        // Set items
        try {
          setInvoiceItems(JSON.parse(originalData.items))
          setTimeout(() => setForceRefresh(forceRefresh + 1), 500);
        } catch (e) {
          // console.log('Cannot set items', e.message)
          sendErrorMessage(dispatch,`Cannot set items${  e.message}`)
        }
      }
    }
  }, [isCustomerLoaded, isTemplatesLoaded])

  const onSubmit = async (formData) => {
    setIsSaving(true)

    if (type === 'asked') {
      if (!selectedHeadAsk) {
        setIsSaving(false)
        return setError('head', { type: 'manual', message: 'Invoice head is required' })
      }
      if (!selectedBillingAsk) {
        setIsSaving(false)
        return setError('billing', { type: 'manual', message: 'Invoice billing is required' })
      }
    } else {
      if (!selectedHeadIssue) {
        setIsSaving(false)
        return setError('head', { type: 'manual', message: 'Invoice head is required' })
      }
      if (!selectedBillingIssue) {
        setIsSaving(false)
        return setError('billing', { type: 'manual', message: 'Invoice billing is required' })
      }
    }

    // Check items
    if (invoiceItems.length <= 0) {
      setIsSaving(false)
      return setError('items', { type: 'manual', message: 'Invoice item is required' })
    }

    // check date
    const dateCode = moment(formData.dateCode)
    if (!dateCode.isValid()) {
      setIsSaving(false)
      return setError('dueDate', { type: 'manual', message: 'Invalid date' })
    }
    const dueDate = moment(formData.dueDate)
    // if (!dueDate.isValid() || dueDate.isBefore()) {
    if (!dueDate.isValid()) { // to allow the dabe can be before for 
      setIsSaving(false)
      return setError('dueDate', { type: 'manual', message: 'Must be a valid date in the future' })
    }

    const res = {
      ...formData,
      dueDate: dueDate.toISOString(),
      type,
      isCompany: customerIsCompany,
      tax: invoiceBody.tax,
      customerId: type === 'asked' ? selectedHeadAsk.id : selectedBillingIssue.id,
      headId: type === 'asked' ? selectedBillingAsk.id : selectedHeadIssue.id,
      items: JSON.stringify(invoiceItems)
    }

    if (isEdit) {
      res.iid = originalData.iid
      return  updateInvoice(res, onSubmitSuccess, onError)(dispatch)
    } 
      return createInvoice(res, onSubmitSuccess, onError)(dispatch)
    
  }

  const onError = (msg) => {
    sendErrorMessage(dispatch, msg)
    setIsSaving(false)
  }

  const onSetTax = () => setInvoiceBody({ ...invoiceBody, tax: !invoiceBody.tax })

  const renderFormTypeSelection = () =>
    <Paper className={classes.paper}>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12}>
          <Typography variant='h2' className={classes.title}>Invoice Type</Typography>
        </Grid>
        <Grid item>
          <ButtonGroup variant="contained" color="primary" disabled={isEdit || isSaving}>
            <Button
              className={clsx({
                [classes.buttonSelected]: type === 'asked',
                [classes.buttonUnselected]: type === 'issuing'
              })}
              onClick={() => setType('asked')}>
              Asked
            </Button>
            <Button
              className={clsx({
                [classes.buttonSelected]: type === 'issuing',
                [classes.buttonUnselected]: type === 'asked'
              })}
              onClick={() => setType('issuing')}>
              Issuing
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Paper>

  const renderFormHeadIssue = () =>
    <Paper className={classes.paper}>
      <Typography variant='h2' className={classes.title}>Head</Typography>
      <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.head?.message)}>
        <Autocomplete
          disabled={isSaving || originalData?.status === 'sent'}
          options={allTemplates}
          noOptionsText='No Template'
          getOptionLabel={(option) => option.name}
          onChange={(e, v) => {
            if (!isIDTouched) {
              setInvoiceBody({ ...invoiceBody, iid: genInvoiceID(v.cidPrefix) })
            }
            setSelectedHeadIssue(v)
          }}
          renderInput={(params) =>
            <TextField {...params} label='Select a head template...' variant='outlined' size='small'/>
          }
        />
        <FormHelperText error={Boolean(errors.head?.message)}>
          { errors.head?.message ? errors.head?.message : '' }
        </FormHelperText>
      </FormControl>
      {selectedHeadIssue
      &&
      <Grid container spacing={5} direction='row' alignItems='flex-start'>
        <Grid item xs={12} className={classes.editor}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {/* <div className={viewClasses.headerText} */}
              {/*     dangerouslySetInnerHTML={{ __html: selectedHeadIssue?.text }} /> */}
              <div className={viewClasses.headerText}>
                {parse(selectedHeadIssue?.text  || '')}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={viewClasses.headerImage}>
              <img alt='header logo for company'
                   src={`${ServerBaseURL}/asset/contractTemplate/headerImage/${selectedHeadIssue?.id}.png`} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      }
    </Paper>

  const renderFormHeadAsk = () =>
    <Paper className={classes.paper}>
      <Typography variant='h2' className={classes.title}>Head</Typography>
      <FormControl fullWidth className={classes.autofillForm}
                   error={Boolean(errors.head?.message)}>
        <Autocomplete
          disabled={isSaving || isEdit}
          options={allCustomers}
          noOptionsText='No Customer'
          getOptionLabel={(option) => `${option.id}. ${option.firstName} ${option.lastName}`}
          onChange={(e, v) => {
            setSelectedHeadAsk(v)
            setCustomerEmail(v?.email)
            setCustomerIsCompany(false)
          }}
          renderInput={(params) =>
            <TextField {...params} label='Select a customer...' variant='outlined' size='small'/>
          }
        />
        <FormHelperText error={Boolean(errors.head?.message)}>
          { errors.head?.message ? errors.head?.message : '' }
        </FormHelperText>
      </FormControl>
      {selectedHeadAsk
      &&
      <Grid container spacing={5} direction='row' justifyContent='space-between' alignItems='flex-start'>
        <Grid item className={classes.editor}>
          {customerIsCompany
            ?
            // <div className={viewClasses.headerText}
            //      dangerouslySetInnerHTML={{ __html: selectedHeadAsk?.companyDetails }} />
            <div className={viewClasses.headerText}>{parse(selectedHeadAsk?.companyDetails || '')} </div>
            :
            <div>
              <Typography variant='body1'>{selectedHeadAsk.firstName} {selectedHeadAsk.lastName}</Typography>
              <Typography variant='body1'>{selectedHeadAsk.email}</Typography>
              <Typography variant='body1'>{selectedHeadAsk.phone}</Typography>
              <Typography variant='body1'>{selectedHeadAsk.address}</Typography>
              <Typography variant='body1'>{selectedHeadAsk.city}, {selectedHeadAsk.country}</Typography>
              <Typography variant='body1'>{selectedHeadAsk.zipcode}</Typography>
            </div>
          }
        </Grid>
        <Grid item>
          <FormControlLabel
            className={classes.companyToggle}
            control={
              <Switch
                disabled={isSaving || originalData?.status === 'sent' || !selectedHeadAsk?.companyDetails?.length > 0}
                size='small'
                checked={customerIsCompany}
                onChange={() => setCustomerIsCompany(!customerIsCompany)}
              />
            }
            labelPlacement='start'
            label='Company'
          />
        </Grid>
      </Grid>
      }
    </Paper>

  const renderFormBody = () =>
    <Paper className={classes.paper}>
      <Typography variant='h2' className={`${classes.title} ${classes.marginBottom}`}>Body</Typography>
      <Grid container justifyContent='space-between' alignItems='flex-start' spacing={3}>

        <Grid item xs={12}>
          <FormControl fullWidth className={classes.marginBottom}>
            <TextField fullWidth size='small'
                       label='Invoice #' required
                       disabled={isEdit || isSaving}
                       name='iid' inputRef={register}
                       error={Boolean(errors.iid?.message)}
                       helperText={errors.iid?.message ? errors.iid?.message : ''}
                       value={invoiceBody.iid}
                       onChange={(e) => {
                         setIsIDTouched(true)
                         setInvoiceBody({ ...invoiceBody, iid: e.currentTarget.value })
                       }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth className={classes.marginBottom}>
            <TextField fullWidth size='small'
                       label='Date Code' required
                       disabled={isSaving}
                       InputProps={{ readOnly: originalData?.status === 'sent' }}
                       name='dateCode' inputRef={register}
                       error={Boolean(errors.dateCode?.message)}
                       helperText={errors.dateCode?.message ? errors.dateCode?.message : ''}
                       value={invoiceBody.dateCode}
                       onChange={(e) =>
                         setInvoiceBody({ ...invoiceBody, dateCode: e.currentTarget.value })}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth className={classes.marginBottom}>
            <TextField fullWidth size='small' type="date"
                       label='Due Date' required
                       disabled={isSaving}
                       InputProps={{ readOnly: originalData?.status === 'sent' }}
                       name='dueDate' inputRef={register}
                       error={Boolean(errors.dueDate?.message)}
                       helperText={errors.dueDate?.message}
                       value={invoiceBody.dueDate}
                       onChange={(e) =>
                         setInvoiceBody({ ...invoiceBody, dueDate: e.currentTarget.value })}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth className={classes.marginBottom}>
            <TextField fullWidth size='small'
                       label='Instructions'
                       disabled={isSaving}
                       InputProps={{ readOnly: originalData?.status === 'sent' }}
                       name='instructions' inputRef={register}
                       error={Boolean(errors.instructions?.message)}
                       helperText={errors.instructions?.message ? errors.instructions?.message : ''}
                       value={invoiceBody.instructions}
                       onChange={(e) =>
                         setInvoiceBody({ ...invoiceBody, instructions: e.currentTarget.value })}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth className={classes.marginBottom}>
            <TextField fullWidth size='small'
                       label='Notes'
                       disabled={isSaving}
                       InputProps={{ readOnly: originalData?.status === 'sent' }}
                       name='notes' inputRef={register}
                       error={Boolean(errors.notes?.message)}
                       helperText={errors.notes?.message ? errors.notes?.message : ''}
                       value={invoiceBody.notes}
                       onChange={(e) =>
                         setInvoiceBody({ ...invoiceBody, notes: e.currentTarget.value })}
            />
          </FormControl>
        </Grid>

      </Grid>
    </Paper>

  const renderFormBillingIssue = () =>
    <Paper className={classes.paper}>
      <Typography variant='h2' className={classes.title}>Billing</Typography>
      <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.billing?.message)}>
        <Autocomplete
          disabled={isSaving || isEdit}
          options={allCustomers}
          noOptionsText='No Customer'
          getOptionLabel={(option) => `${option.id}. ${option.firstName} ${option.lastName}`}
          onChange={(e, v) => {
            setSelectedBillingIssue(v)
            setCustomerEmail(v?.email)
            setCustomerIsCompany(false)
          }}
          renderInput={(params) =>
            <TextField {...params} label='Select a customer...' variant='outlined' size='small'/>
          }
        />
        <FormHelperText error={Boolean(errors.billing?.message)}>
          { errors.billing?.message ? errors.billing?.message : '' }
        </FormHelperText>
      </FormControl>
      {selectedBillingIssue
      &&
      <Grid container spacing={5} direction='row' justifyContent='space-between' alignItems='flex-start'>
        <Grid item className={classes.editor}>
          {customerIsCompany
            ?
            <div className={viewClasses.headerText}>{parse(selectedBillingIssue?.companyDetails || '')}</div>
            // <div className={viewClasses.headerText}
            //      dangerouslySetInnerHTML={{ __html: selectedBillingIssue?.companyDetails }} />
            :
            <div>
              <Typography variant='body1'>{selectedBillingIssue.firstName} {selectedBillingIssue.lastName}</Typography>
              <Typography variant='body1'>{selectedBillingIssue.email}</Typography>
              <Typography variant='body1'>{selectedBillingIssue.phone}</Typography>
              <Typography variant='body1'>{selectedBillingIssue.address}</Typography>
              <Typography variant='body1'>{selectedBillingIssue.city}, {selectedBillingIssue.country}, {selectedBillingIssue.zipcode}</Typography>
            </div>
          }
        </Grid>
        <Grid item>
          <FormControlLabel
            className={classes.companyToggle}
            control={
              <Switch
                disabled={isSaving || !selectedBillingIssue?.companyDetails?.length > 0 || originalData?.status === 'sent'}
                size='small'
                checked={customerIsCompany}
                onChange={() => setCustomerIsCompany(!customerIsCompany)}
              />
            }
            labelPlacement='start'
            label='Company'
          />
        </Grid>
      </Grid>
      }
    </Paper>

  const renderFormBillingAsk = () =>
    <Paper className={classes.paper}>
      <Typography variant='h2' className={classes.title}>Billing</Typography>
      <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.billing?.message)}>
        <Autocomplete
          disabled={isSaving || originalData?.status === 'sent'}
          options={allTemplates}
          noOptionsText='No Template'
          getOptionLabel={(option) => option.name}
          onChange={(e, v) => {
            if (!isIDTouched) {
              setInvoiceBody({ ...invoiceBody, iid: genInvoiceID(v.cidPrefix) })
            }
            setSelectedBillingAsk(v)
          }}
          renderInput={(params) =>
            <TextField {...params} label='Select a billing template...' variant='outlined' size='small'/>
          }
        />
        <FormHelperText error={Boolean(errors.billing?.message)}>
          { errors.billing?.message ? errors.billing?.message : '' }
        </FormHelperText>
      </FormControl>
      {selectedBillingAsk
      &&
      <Grid container spacing={5} direction='row' alignItems='flex-start'>
        <Grid item xs={12} className={classes.editor}>
          <Grid container spacing={2}>
            <Grid item xs={12}>

              {/* <div className={viewClasses.headerText} */}
              {/*     dangerouslySetInnerHTML={{ __html: selectedBillingAsk?.text }} /> */}
              <div className={viewClasses.headerText}>{parse(selectedBillingAsk?.text || '')}</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      }
    </Paper>

  const renderSubmit = () =>
    <Paper className={classes.paper}>
      <Grid container spacing={3} justifyContent='space-between' alignItems='center'>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth size='small'
                     label='Customer Email' required
                     disabled={isSaving}
                     name='email' inputRef={register}
                     error={Boolean(errors.email?.message)}
                     helperText={errors.email?.message}
                     value={customerEmail}
                     onChange={(e) =>
                       setCustomerEmail(e.currentTarget.value)}
          />
        </Grid>
        <Grid item>
          <SubmitButton isSaving={isSaving} text='Save Invoice' isSavingText='Saving'
                        fullWidth variant='contained' color='primary'
          />
        </Grid>
      </Grid>
    </Paper>

  const renderForm = () =>
    <div>
      { renderFormTypeSelection() }
      {
        type === 'asked' &&
        <div>
          { renderFormHeadAsk() }
          { renderFormBody() }
          { renderFormBillingAsk() }
        </div>
      }
      {
        type === 'issuing' &&
        <div>
          { renderFormHeadIssue() }
          { renderFormBody() }
          { renderFormBillingIssue() }
        </div>
      }
    </div>

  return (
    <div>
      { isLoaded
        ?
        <div>
          { type &&
          <Portal container={portalRef.current} key={forceRefresh}>
            <Paper className={classes.paper}>
              <Typography variant='h2' className={classes.title}>Items</Typography>
              <InvoiceEditorItems tax={invoiceBody.tax} setTax={onSetTax}
                                  items={invoiceItems} setItems={setInvoiceItems}
                                  disabled={isSaving || originalData?.status === 'sent'}/>
              <FormHelperText error={Boolean(errors.items?.message)}>
                { errors.items?.message ? errors.items?.message : '' }
              </FormHelperText>
            </Paper>
          </Portal>
          }
          <form onSubmit={handleSubmit(onSubmit)}>
            { renderForm() }
            <div ref={portalRef}/>
            { type && renderSubmit() }
          </form>
        </div>
        :
        <Typography variant='h2'>Loading.. .</Typography>
      }
    </div>
  )
}

export default InvoiceEditor
