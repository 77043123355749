import {classActionsType} from "../../actions/class/classActions";

export const classReducer = (state = initialState, action = {}) => {
  const {type, payload} = action;
  switch (type) {
    case classActionsType.Register:
      return {...state, success: true, error: undefined, newClass: payload};
    case classActionsType.actionError:
      return {...state, success: false, error: payload, user: null};
    case classActionsType.FetchClasses:
      return {...state, class: payload}
    case classActionsType.FetchClassesProps:
      return {...state, classesFetchBody: payload}
    case classActionsType.FetchCurrentClass:
      return {...state, currentClass: payload, classTableStatus: 'UPDATE'}
    case classActionsType.FetchCurrentClassForEdit:
      return {...state, currentClass: payload}
    case classActionsType.CloneCurrentClass:
      return {...state, currentClass: payload, classTableStatus: 'CLONE'}
    case classActionsType.SetClassTableStatus:
      return {...state, classTableStatus: payload}
    case classActionsType.ActiveClass:
      return {...state, activationMessage: payload}
    case classActionsType.FetchTeachers:
      return {...state, teacherList: payload }
    case classActionsType.FetchStudents:
      return {...state, studentList: payload }
    case classActionsType.FetchSyllabus:
      return {...state, syllabusList: payload }
    case classActionsType.CreateClass:
      return {...state, classTableStatus: 'closed'}
    case classActionsType.UpdateClass:
      return {...state, classTableStatus: 'closed'}
    case classActionsType.DeleteClass:
      return {...state, classTableStatus: 'closed'}
    case classActionsType.FetchClassHistory:
      return {...state, classHistory: payload}
    case classActionsType.FetchOneClassHistory:
      return {...state, oneHistory: payload, classTableStatus: 'UpdateClassDeliver'}
    case classActionsType.FetchOneClass:
      return {...state, oneClass: payload}
    case classActionsType.UpdateClassHistory:
      // TODO: Clean up later
      // return {...state, classTableStatus: 'READ'}
      return state
    case classActionsType.AddClassHistory:
      // TODO: Clean up later
      // return {...state, classTableStatus: 'READ'}
      return state
    case classActionsType.DeleteHistory:
      return {...state, classTableStatus: 'READ'}
    case classActionsType.UpdateSubmission:
      return {...state, classTableStatus: 'READ'}
    case classActionsType.FetchHomework:
      return {...state, homework: payload,classTableStatus: 'READ' }
    case classActionsType.FetchCurrentHomework:
      return {...state, currentHomework: payload,classTableStatus: 'UpdateHomework' }
    case classActionsType.FetchOneHomework:
      return {...state, oneHomework: payload,classTableStatus: 'ReadHomework' }
    case classActionsType.UpdateHomework:
      return {...state, classTableStatus: 'READ'}
    case classActionsType.AddHomework:
      return {...state, classTableStatus: 'READ'}
    case classActionsType.AddClassReview:
      return {...state, classTableStatus: 'READ'}
    case classActionsType.ReadOneClassHistory:
      return {...state, oneHistory: payload,classTableStatus: 'ReadReview' }

    case classActionsType.SetClassViewData:
      return { ...state, classViewData: payload }
    case classActionsType.ClearClassViewData:
      return { ...state, classViewData: null }
    case classActionsType.FetchClassTransferByClassId:
      return { ...state, classTransferDataByClassId:payload }
      case classActionsType.FetchClassTransferByCustomerId:
      return { ...state, classTransferDataByCustomerId:payload }
    default:
      return state;
  }
}

const initialState = {
  newClass:  undefined,
  error: undefined,
  success: undefined,
  class: undefined,
  classesFetchBody: undefined,
  classTableStatus: 'closed',
  currentClass: undefined,
  activationMessage: undefined,
  teacherList:[],
  studentList:[],
  syllabusList:[],
  classHistory: {},
  oneHistory:{},
  oneClass: undefined,
  homework: undefined,
  currentHomework: undefined,
  oneHomework: undefined,
  classTransferDataByClassId:[],
  classTransferDataByCustomerId:[],
  classViewData: null
}
export default classReducer