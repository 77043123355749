import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {authCheck} from "../../actions";

function AuthGuard({children}) {
    const dispatch = useDispatch()
    const auth = useSelector(state =>state?.auth)
    const  navigate = useNavigate();
    const [afterCheck,setAfterCheck] = useState(false);

    const doCheck = ()=>{
        if(!afterCheck) {
            authCheck()(dispatch);
            setAfterCheck(()=>true);
        }
    }
    useEffect(doCheck,[]);

    const checkResult = ()=>{
        if(afterCheck) {
            if (!auth.success && auth.error) {
                navigate('/auth/login')
            }
        }
    }
    useEffect(checkResult,[auth.success])

    return (
        <div>
            {children}
        </div>
    );
}
// const mapStateToProps=({auth})=>({auth})
export default AuthGuard;
