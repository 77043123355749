import React, {useEffect, useState} from 'react'
import {alpha} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import {IconButton, Paper} from '@mui/material'
import {Edit as EditIcon, Eye} from 'react-feather'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import {CATEGORY_ITEMS} from '../../constants'
import PageGrid from '../../components/mui/datagrid/PageGrid'
import ResponsiveDialog from '../../components/dialog/ResponsiveDialog'
import {activateProduct, fetchProducts, deleteProduct, prepareEditProduct, prepareReadProduct} from '../../actions'
import SearchProducts from './SearchBar'
import DraggableDialog from '../../components/mui/dialog/DraggableDialog'
import ReadProductForm from './ReadProduct'
import EditProductForm from './EditProductForm'
import AddProductForm from './AddProductForm'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2)
    },
    funcCell: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    search: {
        position: 'fixed',
        right: 100,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto'
        }
    },
    Button: {
        position: 'fixed',
        right: 100
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch'
            }
        }
    }
}))

function ProductGrid({setLoading, isOpen, setIsOpen}) {
    // const apiRef = useGridApiRef()
    const classes = useStyles()
    // const [open, setOpen] = useState(false)
    // const [hash, setHash] = useState('init')
    // const [delId, setDelId] = useState(null)
    const open = false
    const hash = 'init'
    const delId = null
    // const [clickedRow, setClickedRow] = useState("")
    const [openRead, setOpenRead] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [rowId, setRowId] = useState(null)
    const dispatch = useDispatch()
    const catchError = () => {
        // alert(error);
    }
    const columns = [
        {
            field: '_actionField',
            headerName: ' ',
            resizable: false,
            disableClickEventBubbling: true,
            filterable: false,
            width: 100,
            renderCell: params => (
                <div style={{position: 'relative', width: '100%'}}>
                    <div className={classes.funcCell}>
                        <Tooltip title="Read" placement="top">
                            <IconButton
                                color="primary"
                                component="span"
                                size="small"
                                onClick={async event => {
                                    event.stopPropagation()
                                    // setLoading(true);
                                    await prepareReadProduct(params.row.id, () => {
                                        setLoading(false)
                                    })(dispatch)
                                    // await setClickedRow(params.row.id);
                                    await setOpenRead(true)
                                    setRowId(params.row.id)
                                    setMode('read')
                                }}
                            >
                                <Eye size={20} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit" placement="top">
                            <IconButton
                                color="primary"
                                component="span"
                                size="small"
                                onClick={async event => {
                                    event.stopPropagation()
                                    // setLoading(true)
                                    await prepareEditProduct(params.row.id, () => {
                                        setLoading(false)
                                    })(dispatch)
                                    // await setClickedRow(params.row.id);
                                    await setOpenEdit(true)
                                    setRowId(params.row.id)
                                    setMode('edit')
                                }}
                            >
                                <EditIcon size={20} />
                            </IconButton>
                        </Tooltip>
                        {/* {params.row.isActive === true ? */}
                        {/*    <Tooltip title="Delete" placement='top'> */}
                        {/*        <IconButton component="span" size="small" style={{ marginLeft: 10 }} */}
                        {/*                    onClick={(event) => { */}
                        {/*                        event.stopPropagation(); */}
                        {/*                        handleDelete(params.row.id); */}
                        {/*                    }}> */}
                        {/*            <Trash2 size={20} color="red"/> */}
                        {/*        </IconButton> */}
                        {/*    </Tooltip> : null */}
                        {/* } */}
                    </div>
                </div>
            )
        },
        {field: 'id', headerName: 'ID', width: 70},
        {
            field: 'name',
            headerName: 'Name',
            width: 130,
            renderCell: params => (params.row.isActive ? params.row.name : <del>{params.row.name}</del>)
        },
        {field: 'category', headerName: 'Category', width: 130},
        {
            field: 'price',
            headerName: 'Price',
            type: 'number',
            width: 130
        },
        {
            field: 'slug',
            headerName: 'Slug',
            width: 160
        },
        {
            field: 'isActive',
            headerName: 'IsActive',
            width: 130
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            width: 200,
            valueFormatter: ({value}) => moment(value).format('L LT')
        },
        {
            field: 'isReviewed',
            headerName: 'Is Reviewed',
            width: 120
        }
    ]

    const [isLoading, setIsLoading] = useState(false)
    const [selected, setSelected] = useState([]) // selected rows
    const [searchArgs, setSearchArgs] = useState({searchKey: undefined, category: CATEGORY_ITEMS.all}) // aruguments from search bar
    const activationMessage = useSelector(state => state?.products.activationMessage)
    const products = useSelector(state => state?.products.products)
    const fetchProps = useSelector(state => state?.products.productFetchBody)
    const [mode, setMode] = useState(null)

    const handleSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase()
        setSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }

    const handleCategoryChange = newCategory =>
        setSearchArgs(prevState => ({
            ...prevState,
            category: newCategory
        }))

    const onActive = isActive => {
        const data = {ids: selected, isActive}
        activateProduct(data, fetchProps)(dispatch)
    }

    useEffect(() => {
        if (activationMessage) {
            //  setMessage(true)
            setSelected([])
        }
    }, [activationMessage])

    useEffect(() => {
        if (isOpen) {
            setMode('add')
        }
    }, [isOpen])

    useEffect(async () => {
        if (mode === 'edit') {
            await prepareEditProduct(rowId, () => {
                setLoading(false)
            })(dispatch)
            setOpenEdit(true)
            setOpenRead(false)
        }
        if (mode === 'read') {
            await prepareReadProduct(rowId, () => {
                setLoading(false)
            })(dispatch)
            setOpenEdit(false)
            setOpenRead(true)
        }
    }, [mode])

    const handleFetchProducts = data =>
        data.map(value => {
            value.category = value.category?.name
            return value
        })

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <SearchProducts
                    numSelected={selected.length}
                    handleChange={handleSearchChange}
                    selectedCategory={searchArgs.category}
                    setSelectedCategory={handleCategoryChange}
                    onActive={onActive}
                    className={classes.search}
                    isLoading={isLoading}
                />
                <PageGrid
                    columns={columns}
                    datas={products}
                    handleFetchData={handleFetchProducts}
                    searchArgs={searchArgs}
                    fetchDatas={fetchProducts}
                    selected={selected}
                    setSelected={setSelected}
                    setIsLoading={setIsLoading}
                />
            </Paper>
            <div>
                <ResponsiveDialog
                    isOpen={open}
                    openHash={hash}
                    title="Delete Parameter Confirm"
                    content="Are you sure?"
                    Buttons={[
                        {
                            name: 'Yes',
                            event: () => {
                                deleteProduct(delId, catchError, {fetchProps})(dispatch)
                            }
                        },
                        {
                            name: 'Cancel'
                        }
                    ]}
                />
            </div>

            <DraggableDialog
                open={Boolean(openRead)}
                onClose={() => setOpenRead(null)}
                title="View Product"
                isLoaded={Boolean(openRead)}
            >
                <Paper elevation={0}>
                    <ReadProductForm cb={setMode} />
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                open={Boolean(openEdit)}
                onClose={() => setOpenEdit(null)}
                title="Edit Product"
                isLoaded={Boolean(openEdit)}
            >
                <Paper elevation={0}>
                    <EditProductForm />
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                    open={Boolean(isOpen)}
                    onClose={() => setIsOpen(false)}
                    title="Create Product"
                    isLoaded={Boolean(isOpen)}
                >
                    <Paper elevation={0}>
                        <AddProductForm />
                    </Paper>
                </DraggableDialog>
        </div>
    )
}

export default ProductGrid
