import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@mui/material';
import ViewHead from '../../../components/public/ViewHead';
import { Functions } from '../../../utils/Functions';
import { fetchSyllabus } from '../../../actions';
import LoadingScreen from '../../../components/public/LoadingScreen';
import SyllabusTreeView from './SyllabusTreeView'
import { SYLLABUS_DISPLAY_TYPE, useSyllabusViewStyles } from './syllabusHelper';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import useAuthCheck from '../../../hooks/useAuthCheck';
import SelectClassView from './SyllabusTreeView/SelectClassView';

function SyllabusMainView() {
  useCurrentViewCheck(Functions.Training_Management_Syllabus_Information)

  const [canEdit] = useAuthCheck([
    Functions.Training_Management_Syllabus_Information_Edit_Syllabus
  ])

  const dispatch = useDispatch()
  const classes = useSyllabusViewStyles()

  const [isLoaded, setIsLoaded] = useState(false)

  const [selectedPoint, setSelectedPoint] = useState(null)
  const [openClassSelectModal, setOpenClassSelectModal] = useState(false)

  const allSyllabus = useSelector(state => state.syllabuses.allSyllabuses)

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchSyllabus()(dispatch))
    })()
  }
  useEffect(loadData, [])

  const handleAddDelivery = (pointId) => {
    setSelectedPoint(pointId)
    setOpenClassSelectModal(true)
  }

  return(
    <div>
      <ViewHead noMaxWidth functionId={Functions.Training_Management_Syllabus_Information}>
        { isLoaded
          ? <SyllabusTreeView data={allSyllabus} canEdit={canEdit} onAddDelivery={handleAddDelivery}
                              displayType={SYLLABUS_DISPLAY_TYPE.EDIT}/>
          : <LoadingScreen message='Loading...' />
        }
        <Modal
          open={openClassSelectModal}
          onClose={() => setOpenClassSelectModal(false)}
          className={classes.modalDisplay}
          disablePortal
          disableRestoreFocus
          disableEnforceFocus
          disableAutoFocus
          closeAfterTransition
        >
          <SelectClassView selectedPoint={selectedPoint} />
        </Modal>
      </ViewHead>
    </div>
  )
}

export default SyllabusMainView
