import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import { Edit as EditIcon } from 'react-feather';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RateReviewIcon from '@mui/icons-material/RateReview';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { Dashboard } from '@mui/icons-material';
import {
  classActionsType,
  deleteHistory, fetchClassHistory,
  fetchOneClassHistory, readOneClassHistory
} from '../../../actions/class/classActions';
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog';
import useAuthCheck from '../../../hooks/useAuthCheck';
import { Functions } from '../../../utils/Functions';
import LXGrid from '../../../components/mui/datagrid/XGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  editIcon: {
    cursor: 'pointer'
  },
  tableCell: {
    width: 80,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px'
  },
  funcCell: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  },
  add: {
    '& > span': {
      margin: theme.spacing(2)
    }
  },
}));

function ClassDeliver({
                        isEdit,
                        classHistory,
                        oneClass,
                        fetchOneClassHistory,
                        deleteHistory,
                        readOneClassHistory,
                        user
                      }) {

  const classes = useStyles();
  const [isGive, isRead] = useAuthCheck(
    [Functions.Training_Class_Class_Details_Give_Feedback,
      Functions.Training_Class_Class_Details_Read_Feedback
    ]);

  const [isLoading, setLoading] = React.useState(false);


  const handleEdit = (historyId) => {
    setLoading(true);
    fetchOneClassHistory(oneClass.data.id, historyId, () => {
      setLoading(false);
    });
  };

  const handleRead = (historyId) => {
    setLoading(true);
    readOneClassHistory(oneClass.data.id, historyId, () => {
      setLoading(false);
    });
  };

  const dispatch = useDispatch();

  // const [delId, setDelId] = useState(null);
  // const [open, setOpen] = useState(false);
  // const [hash, setHash] = useState('init');

  const delId = null
  const open = false
  const hash = 'init'
  // const handleDelete = (id) => {
  //   setDelId(id);
  //   setOpen(true);
  //   setHash(Math.random().toString(16).substring(2, 10));
  // };

  const columns = [
    {
      field: '_actionField',
      headerName: ' ',
      resizable: false,
      disableClickEventBubbling: true,
      filterable: false,
      width: 100,
      renderCell: params => (
        <div style={{ position: 'relative', width: '100%' }}>
          <div className={classes.funcCell}>

            {isRead && (
              <Tooltip title="Read" placement='top'>
                <IconButton color='primary' component='span' size='small'
                            onClick={(event) => {
                              event.stopPropagation();
                              handleRead(params.row.id);
                            }}>
                  <VisibilityIcon size={15} />
                </IconButton>
              </Tooltip>

            )}

            {isGive && !classHistory.data.find(history => history.id === params.row.id).feedback.find(feedback => feedback.user.id === user.id) && (
              <Tooltip title="Review" placement='top'>
                <IconButton color='primary' component='span' size='small'
                            onClick={(event) => {
                              event.stopPropagation();
                              handleEdit(params.row.id);
                            }}>
                  <RateReviewIcon size={15} />
                </IconButton>
              </Tooltip>

            )}
            {isEdit && (
              <Tooltip title="Edit" placement='top'>
                <IconButton color='primary' component='span' size='small'
                            onClick={(event) => {
                              event.stopPropagation();
                              handleEdit(params.row.id);}}>
                  <EditIcon size={15} />
                </IconButton>
              </Tooltip>

            )}


            {/* {isEdit&&( */}

            {/*      <IconButton component="span" size="small" style={{marginLeft: 10}} onClick={(event) => { */}
            {/*        event.stopPropagation(); */}
            {/*        handleDelete(params.row.id) */}
            {/*      }}> */}
            {/*        <Trash2 size={15} color="red"/> */}
            {/*      </IconButton> */}
            {/* )} */}
          </div>
        </div>
      )
    },
    { field: 'id', headerName: 'Id', width: 50, sortable: false },
    { field: 'name', headerName: 'Name', width: 150, sortable: false },
    { field: 'teacher', headerName: 'Teacher', width: 150, sortable: false },
    { field: 'syllabus', headerName: 'Lesson On', width: 200 },
    {
      field: 'startTime', headerName: 'Start Time', width: 200,
      valueFormatter: ({ value }) => moment(value).format('L LT')
    },
    {
      field: 'endTime', headerName: 'End Time', width: 200,
      valueFormatter: ({ value }) => moment(value).format('L LT')
    },
    { field: 'absentee', headerName: 'Absentee', width: 100, sortable: false },
    { field: 'notes', headerName: 'Notes', width: 100, sortable: false },
    {
      field: 'createAt', headerName: 'CreateAt', width: 200,
      valueFormatter: ({ value }) => moment(value).format('L LT')
    }
  ];


  const rows = classHistory ? classHistory.data?.map((item) => ({
      id: item.id,
      name: item.extension,
      teacher: `${item.teacher.firstName} ${item.teacher.lastName}`,
      syllabus: `${item.points.map(point =>{
        if (point?.chapter?.topic?.order && point?.chapter?.order && point?.order ){
          return`${point.chapter.topic.syllabus.name} > ${point.chapter.topic.order + 1}.${point.chapter.order + 1}.${point.order + 1} ${point.name}`
        }
          return ''
        
        }).join(', ')
      }`,
      startTime: item.startTime,
      endTime: item.endTime,
      absentee:
        oneClass?.data?.students.filter(x => !(item.attendants.map(v => v.id).includes(x.id)))
          .map(attendant => `${attendant.firstName} ${attendant.lastName}`).join('|'),
      createAt: item.createdAt,
      notes: item.notes
    })) : [];
  const data = { columns, rows };
  return (

    <div>
      {
        !isLoading &&
        <div>
          <div className={classes.root}>
            <Typography variant='h5' color='textPrimary'>Daily Classes Delivered &nbsp;&nbsp;</Typography>
            {isEdit &&
              <Tooltip title='Add Class Delivery' placement='top'>
                <IconButton
                  size='small'
                  onClick={() => {
                    dispatch({
                      type: classActionsType.SetClassTableStatus,
                      payload: 'AddClassDeliver'
                    });
                  }}>
                  <AddCircleIcon color='primary' fontSize='medium' />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title='Progress' placement='top'>
              <IconButton
                size='small'
                onClick={() => dispatch({
                  type: classActionsType.SetClassTableStatus,
                  payload: 'SyllabusProgress'
                })}>
                <Dashboard color='primary' fontSize='medium'/>
              </IconButton>
            </Tooltip>
          </div>
          <Grid item>
            <Paper className={classes.paper}>
              {classHistory ?
                <div>
                  <LXGrid
                    {...data}
                    totalHeight={400}
                    checkboxSelection
                    disableSelectionOnClick
                    sortModel={[{ field: 'createAt', sort: 'desc' }]}
                  />
                  <ResponsiveDialog isOpen={open} openHash={hash}
                                    title='Delete Parameter Confirm' content='Are you sure?'
                                    Buttons={[
                                      {
                                        name: 'Yes',
                                        event: () => {
                                          deleteHistory(delId);
                                        }
                                      },
                                      {
                                        name: 'Cancel'
                                      }
                                    ]} />
                </div> : null
              }
            </Paper>
          </Grid>
        </div>
      }
    </div>

  );
}

const mapStateToProps = (state) => ({
  currentClass: state.classes?.currentClass,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  fetchOneClassHistory,
  deleteHistory,
  fetchClassHistory,
  readOneClassHistory
})(ClassDeliver);
