import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCustomerCheck
} from '../../../actions';
import { Functions } from '../../../utils/Functions';
import LoadingScreen from '../../../components/public/LoadingScreen';
import CheckCustomer from './CheckCustomer';
import useCurrentViewCheck from "../../../hooks/useCurrentViewCheck";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.dark,
//     minHeight: '100%',
//     padding: theme.spacing(3),
//   },
//   // closeButton: {
//   //   marginBottom: "1rem",
//   // },
// }));

function CheckCustomerFloat(props) {
  useCurrentViewCheck(Functions.Customer_Management_Registered_Information);
  const dispatch = useDispatch();
  const {studentId:userId} = props
  // const userId = props.studentId;
  const currentCustomer = useSelector(state => state.customer.currentCustomer)
  const [isCustomerLoaded, setIsCustomerLoaded] = useState(false)
  const onFloating = true;
  const loadData = () => {
    (async() => {
      setIsCustomerLoaded(await fetchCustomerCheck(userId)(dispatch))
    })()
  }
  useEffect(loadData, [])

  const renderTable = () => <CheckCustomer onFloating={onFloating} currentCustomer={currentCustomer}/>;
  const renderLoading = () =><LoadingScreen message='Loading...' />
  return (
    <div
      // className={classes.root}
    >
      {/* <Button type='button' */}
      {/*        variant='contained' */}
      {/*        className={classes.closeButton} */}
      {/*        color='secondary' */}
      {/*        onClick={() => {props.setChildStudentId(null) */}
      {/*        }}> */}
      {/*  Close */}
      {/* </Button> */}
      {isCustomerLoaded ?
        renderTable() :
        renderLoading()}
    </div>
  );
}

export default CheckCustomerFloat
