import React, { useEffect, useRef, useState } from 'react';
import interact from 'interactjs';
import makeStyles from '@mui/styles/makeStyles';
import { Cancel } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { getTotalWidth } from '../../../../utils/Scripts';
import setupInteractable from '../../../../components/misc/InteractableComponent';
import { EMPTY_IMAGE } from '../posterGenHelper';

const useStyles = makeStyles(() => ({
  posterBox: {
    position: 'absolute',
    backgroundSize: '100% 100%',
    outline: '2px solid white'
  },
  closeButton: {
    position: 'absolute',
    top: '-12px',
    left: '-12px',
    '& .button': {
      color: 'red',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '24px',
      height: '24px',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      borderRadius: '50%'
    }
  }
}))

function PreviewModal({ width=500, height=500, imageSrc=EMPTY_IMAGE, setIsShow }) {

  const defaultSize = 300

  const classes = useStyles()
  const loadImageBoxRef = useRef(null)
  const totalWidth = getTotalWidth()

  const [containerWidth, setContainerWidth] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)

  useEffect(() => {
    // scale image
    const scale = height > width ? height / defaultSize : width / defaultSize
    setContainerWidth(Math.round(width / scale))
    setContainerHeight(Math.round(height / scale))
  }, [height, width])

  useEffect(() => {
    if (loadImageBoxRef?.current) {
      const c = interact(loadImageBoxRef?.current)
      setupInteractable(c, () => {}, '.viewContainer',
        { left: false, right: true, bottom: true, top: false, })
    }
  }, [loadImageBoxRef])

  return (
    <div
      ref={loadImageBoxRef}
      className={classes.posterBox}
      style={{ display: 'block', zIndex: 99,
        top: 70, left: (totalWidth-containerWidth-20),
        width:containerWidth, height: containerHeight }}
    >
      <div className={classes.closeButton}>
        <IconButton color='primary'
                    size='small'
                    component='span'
                    onClick={() => setIsShow(false)}>
          <Cancel className='button'/>
        </IconButton>
      </div>
      <img src={imageSrc}
           style={{ width: '100%', height: '100%', objectFit: 'contain' }}
           alt='move this to where you want to place the logo'/>
    </div>
  )
}

export default PreviewModal
