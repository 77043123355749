import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import ViewHead from '../../../../components/public/ViewHead';
import { Functions } from '../../../../utils/Functions';
import { fetchPosterFile, fetchPosterTemplate } from '../../../../actions/forms/posterMakerAction';
import LoadingScreen from '../../../../components/public/LoadingScreen';
import {
  addImageToPixieSrc,
  b642Image, cleanPixieScript, EMPTY_IMAGE,
  POSTER_PLACEHOLDER,
  posterFileNameGenerator,
  replaceStringForPoster,
  usePosterTemplateDisplayStyles
} from '../posterGenHelper';
import FormSelectorView from './editor/formSelectorView';
import GroupPosterView from './editor/groupPosterView';
import { copyToClipboard, spaceTrimmer } from '../../../../utils/Scripts';
import NotFound from '../../../../components/public/NotFound';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import PreviewModal from './PreviewModal';
import { sendErrorMessage } from '../../../../actions';

function PosterGenerator() {
  useCurrentViewCheck(Functions.Forms_Poster_Generator_Generate)
  const  navigate = useNavigate();
  const {id} = useParams()
  const dispatch = useDispatch()
  const classes = usePosterTemplateDisplayStyles()

  const currentPosterTemplate = useSelector(state => state.posterMaker.allPosterTemplates)
  const currentPosterFile = useSelector(state => state.posterMaker.currentPosterFile)

  const [hasError, setHasError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPosterLoaded, setIsPosterLoaded] = useState(null)
  const [isPosterTemplateLoaded, setIsPosterTemplateLoaded] = useState(null)

  const [savedConfig, setSavedConfig] = useState(null)
  const [selectedSubmissions, setSelectedSubmissions] = useState([])
  const [generatedPixieConfig, setGeneratedPixieConfig] = useState([])
  const [savedPosters, setSavedPosters] = useState([])
  const [posterNames, setPosterNames] = useState([])
  const [zipFileName, setZipFileName] = useState(null)
  const [archiveFile, setArchiveFile] = useState(null)

  const [isShowPreview, setIsShowPreview] = useState(false)
  const [previewImage, setPreviewImage] = useState({ width: 500, height: 500, src: EMPTY_IMAGE })

  const [currentStep, setCurrentStep] = useState(0)
  const [errMsg, setErrMsg] = useState('')

  const loadData = () => {
    fetchPosterFile(id, setIsPosterLoaded)(dispatch)
    fetchPosterTemplate(id, setIsPosterTemplateLoaded)(dispatch)
  }
  useEffect(loadData, [dispatch, id])

  useEffect(() => {
    const runOnLoad = async () => {
      if (isPosterLoaded !== null && isPosterTemplateLoaded !== null) {
        if (isPosterLoaded && isPosterTemplateLoaded) {
          if (currentPosterFile && id === currentPosterTemplate?.id?.toString()) {
            // set preview image size
            const baseImage = await b642Image(currentPosterFile)
            setPreviewImage({
              ...previewImage,
              width: baseImage.naturalWidth,
              height: baseImage.naturalHeight
            })
            setSavedConfig(addImageToPixieSrc(currentPosterTemplate.config, currentPosterFile))
            setIsLoaded(true)
          }
        } else {
          setHasError(true)
          setIsLoaded(true)
        }
      }
    }
    runOnLoad().then()
  }, [id, currentPosterTemplate, currentPosterFile, isPosterLoaded, isPosterTemplateLoaded, dispatch])

  useEffect(() => {
    if (errMsg) {
      sendErrorMessage(dispatch, errMsg)
      setErrMsg('')
    }
  }, [errMsg, dispatch])

  const handleNextStep = () => setCurrentStep(currentStep + 1)

  const handleArchiveDownload = () => saveAs(archiveFile, zipFileName || 'posters.zip')
  const handleImageSave = () => {
    if (!archiveFile) {
      const zip = new JSZip()
      savedPosters.forEach((image, idx) => {
        const imageData = image.replace(/^data:image\/(png|jpg);base64,/, '')
        zip.file(`${posterNames[idx]}.png`, imageData, { base64: true })
      })
      // saveAs(zippedFile, 'posters.zip')
      // setArchiveFile(zippedFile)
      zip.generateAsync({ type: 'blob' }).then((e) => {
        setArchiveFile(e)
      })
    }
  }

  const handleTextCopy = () => copyToClipboard(dispatch, currentPosterTemplate.copyText)

  // return null if good to go
  // return int of first unedited index
  const handleLastStepCheck = () => {
    // Test if all the images have been generated
    const unSaved = []
    savedPosters.forEach((poster, idx) => {
      if (!poster) {
        unSaved.push(idx + 1)
      }
    })

    if (unSaved.length > 0) {
      // switch user to first poster in list
      setErrMsg(`Posters ${unSaved.join(',')} are unedited`)
      return unSaved[0]
    }
    return null
  }

  const handlePosterPop = (data) => {
    setPreviewImage({ ...previewImage, src: data })
    setIsShowPreview(true)
  }

  const handleOnFormSelectionComplete = ({ selected, headers, form }) => {

    const tempConfigs = []
    const tempNames = []
    selected.forEach(submission => {
      // each submission index in selected
      // corresponds to their Pixie config and save state index
      let tempCurrentConfig = savedConfig
      Object.keys(submission).forEach(curHeader => {
        if (!['id', 'createdAt'].includes(curHeader)) {
          const currentHeaderMap = headers[curHeader].text
          tempCurrentConfig = tempCurrentConfig.replace(currentHeaderMap, replaceStringForPoster(submission[curHeader]))
        }
      })

      // Replace ID and Poster Title
      const posterName = currentPosterTemplate.name
      const posterId = `YC-${moment(submission.createdAt).format('YYYYMMDD')}-${submission.id}`
      tempCurrentConfig = tempCurrentConfig.replace(POSTER_PLACEHOLDER.postertitle.text, posterName)
      tempCurrentConfig = tempCurrentConfig.replace(POSTER_PLACEHOLDER.jobid.text, posterId)
      tempNames.push(posterFileNameGenerator(headers, submission))
      tempConfigs.push(tempCurrentConfig)
    })

    // Download file name
    setZipFileName(`${spaceTrimmer(form.name)}-${moment().format('YYYYMMDD')}-${selected.length}`)

    setSavedPosters(Array(selected.length).fill(''))
    setGeneratedPixieConfig(tempConfigs)
    setSelectedSubmissions(selected)
    setPosterNames(tempNames)
    handleNextStep()
  }

  const selectionRender = () => {
    if (currentStep === 0) {
      // Select which form submission to use
      return <FormSelectorView
        formId={currentPosterTemplate.form.id}
        selectedId={selectedSubmissions}
        onComplete={(data) => {
          handleOnFormSelectionComplete(data)
        }} />
    } if (currentStep > 0 && currentStep <= selectedSubmissions.length) {
      // Edit each poster with the corresponding form submission data
      return <GroupPosterView
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        submissions={selectedSubmissions}
        config={generatedPixieConfig}
        setConfig={setGeneratedPixieConfig}
        savedPosters={savedPosters}
        setSavedPosters={setSavedPosters}
        onPosterPop={handlePosterPop}
      />
    } if (currentStep === selectedSubmissions.length + 1) {

      // Test if all the images have been generated
      const testIsNotComplete = handleLastStepCheck()

      if (testIsNotComplete) {
        // switch user to first poster in list
        setCurrentStep(testIsNotComplete)
        return <Typography>Error</Typography>
      } 
        // all the posters have been edited
        handleImageSave()
        // Save everything and give the notice that it's done
        return(
          <Grid container spacing={5} justifyContent='center' alignItems='center'>
            <Grid item xs={12}>
              <Typography variant='h2'>Completed</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                {/* Left */}
                <Grid item xs={12} sm={8} lg={10}>
                  <TextField variant='outlined' fullWidth size='small'
                             label='Copy Text' name='copyText' multiline rows={5}
                             inputProps={{ readOnly: true }}
                             defaultValue={currentPosterTemplate.copyText}
                  />
                </Grid>

                {/* Right */}
                <Grid item xs={12} sm={4} lg={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Button color='primary' fullWidth
                              variant='contained'
                              onClick={() => handleTextCopy()}
                      >
                        Copy Text
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button color='primary' fullWidth
                              variant='contained'
                              disabled={!archiveFile}
                              onClick={() => handleArchiveDownload()}
                      >
                        Download ZIP
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        )
      
    } 
      // Error, should not be here
      return <Typography>Error</Typography>
    
  }

  const renderView = () =>
    <Paper className={classes.paper} elevation={0}>
      { currentStep > 0 && currentStep < selectedSubmissions.length + 1 && isShowPreview &&
      <PreviewModal
        setIsShow={setIsShowPreview}
        width={previewImage.width}
        height={previewImage.height}
        imageSrc={previewImage.src}
      /> }
      <Grid container>
        <Grid item xs={12}>
          { selectionRender() }
        </Grid>
      </Grid>
    </Paper>

  const renderBackButton = () =>
    <Button color='secondary'
            variant='contained'
            onClick={() => {
              cleanPixieScript()
              navigate(`/app/forms/poster/view/${id}`)
            }}>
      Back
    </Button>

  return (
    <ViewHead
      functionId={Functions.Forms_Poster_Generator_Generate} noMaxWidth
      topButtons={renderBackButton()} className='viewContainer'>
      {
        hasError
          ? <NotFound message='Template cannot be loaded' />
          : isLoaded ? renderView() : <LoadingScreen message='Loading...' />
      }
    </ViewHead>
  )
}

export default PosterGenerator
