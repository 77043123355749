const _ = require('lodash');



export const createFormData = (data, fileArrays, formData = null) => {
    if (!formData) {
        formData = new FormData();
    }

    Object.keys(data).forEach((key)=> {
        if (fileArrays.includes(key)) {

            if (data[key].length > 0) {
                formData.append(key, data[key][0])
            }
        } else {
            formData.append(key, data[key])
        }
    })

    return formData;
}

export const createMutileFormData = (data, fileArrays) => {

    const formData = new FormData();

    Object.keys(data).forEach((key)=> {
        if(key!=="index"){
            const name = key.replace(/_\d+$/,'');

            if (fileArrays.includes(key)) {

                if (data[key].length > 0) {
                    formData.append(name, data[key][0])
                }
            } else {
                formData.append(name, data[key])
            }
        }

    })

    return formData;
}


export const capitalize = (str) => {
    if (typeof str !== 'string') {
        return '';
    }

    if (!str.includes(' ')) {
        return _.capitalize(str);
    }
    return str.split(' ').map(s => _.capitalize(s)).join(' ')

}

export const replaceUnUsedMask = (str) => str.replace(/_/g, '').replace(/(-+)$/g, '')


export const formatByteSize = (bytes, decimal = 2) => {
    const scale = ['bytes', 'kb', 'mb', 'gb']
    if (bytes === 0) return '0 bytes'
    const i = Math.trunc(Math.floor(Math.log(bytes) / Math.log(1024)))
    if (i === 0) return `${bytes} ${scale[i]}`
    return `${(bytes / 1024**i).toFixed(decimal)} ${scale[i]}`
}


