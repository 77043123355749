import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import { sendSuccessMessage } from '../../../../actions';
import history from '../../../../utils/history';
import { useInvoiceListStyles } from '../invoiceHelper';
import ViewHead from '../../../../components/public/ViewHead';
import InvoiceEditor from './InvoiceEditor';
import CenterDisplay from '../../../../components/misc/CenterDisplay';
import { fetchInvoice } from '../../../../actions/administrative/administrativeAction';

function InvoiceCreateView() {
  useCurrentViewCheck(Functions.Administrative_Invoice_Edit)
  const  navigate = useNavigate();
  const {uid} = useParams()
  const {iid} = useParams()
  const dispatch = useDispatch()
  const classes = useInvoiceListStyles()
  const currentInvoice = useSelector(state => state.administrative.currentInvoice)

  const [isLoaded, setIsLoaded] = useState(false)

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchInvoice(uid, iid)(dispatch))
    })()
  }
  useEffect(loadData, [dispatch, uid, iid])

  const onSuccess = (msg, uidTemp, iidTemp) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/administrative/invoice/view/${uidTemp}/${iidTemp}`)
    }, 1000)
  }

  const renderBackButton = () => (
      <div className={classes.pageTitle}>
        <Button color='secondary'
                variant='contained'
                onClick={() => history.goBack()}>
          Back
        </Button>
      </div>
    )

  return (
    <ViewHead functionId={Functions.Administrative_Invoice_Edit} topButtons={renderBackButton()}>
      {
        isLoaded
          ?
          <InvoiceEditor
            originalData={currentInvoice}
            onSubmitSuccess={onSuccess}
            isEdit
          />
          :
          <CenterDisplay><Typography variant='h1' color='textPrimary'>Loading...</Typography></CenterDisplay>
      }
    </ViewHead>
  )
}

export default InvoiceCreateView
