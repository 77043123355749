import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
 Checkbox, FormControlLabel, FormGroup, FormLabel, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import { debounce } from 'lodash';
import PublishIcon from '@mui/icons-material/Publish';
import IconButton from '@mui/material/IconButton';
import { AttachFile, Delete } from '@mui/icons-material';
import moment from 'moment';
import { Clipboard } from 'react-feather';
import Chip from '@mui/material/Chip';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { copyBoardStyle, USER_ROLE_ID } from '../../../constants';
import { copyToClipboard } from '../../../utils/Scripts';
import LoadingScreen from '../../../components/public/LoadingScreen';
import { validateEmail } from '../../customer/register/customerHelper';
import { EMPLOYEE_CREATE_VALIDATOR, employeeStatus } from '../employeeHelper';
import EmployeeServiceTimeEditor, { validateServiceTime } from './EmployeeServiceTimeEditor';
import AddressAutoComplete from '../../../components/api/AddressAutoComplete';
import SubmitButton from '../../../components/mui/button/SubmitButton';
import {
  employeeActions,
  updateEmployee,
  uploadEmployeeFile,
  emailCheck,
  sendSuccessMessage,
  getEmployeeFile, deleteEmployeeFile,
  fetchEmployee
} from '../../../actions';

const useStyles = makeStyles(theme => ({
  root: {},
  paper__form: {
    padding: '2rem',
    margin: '0 auto'
  },
  form__title: {
    marginBottom: '.5rem'
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 120
  },
  serviceTimeContainer: {
    marginBottom: theme.spacing(6)
  },
  marginTopForm: {
    marginTop: theme.spacing(2)
  },
  notePaper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3)
  },
  downloadText: {},
  role: {
    paddingTop: theme.spacing(1),
  }
}));


const schema = yup.object().shape(EMPLOYEE_CREATE_VALIDATOR);

function EditEmployeeForm({
                            updateEmployee,
                            employeeInfo,
                            allUserRoles,
                            error,
                            isSearching,
                            emailCheck,
                            uploadEmployeeFile,
                            getEmployeeFile,
                            deleteEmployeeFile
                          }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { register, errors, setError, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: employeeInfo
  });


  const cEmployee = useSelector(state => state.employee?.currentEmployee);

  const [isSaving, setIsSaving] = useState(false);
  const [serviceTime, setServiceTime] = useState([]);
  const [status, setStatus] = useState(employeeInfo.staffInfo?.status);
  const [joinDate, setJoinDate] = useState(employeeInfo?.staffInfo?.joinDate || '');
  const [leaveDate, setLeaveDate] = useState(employeeInfo?.staffInfo?.leaveDate || '');
  const [notes, setNotes] = useState(employeeInfo.staffInfo?.notes || '');
  const [userFiles, setUserFiles] = useState(cEmployee?.files);
  const [isLoaded, setIsLoaded] = useState(false);

  const [display, setDisplay] = useState(!employeeInfo?.staffInfo?.leaveDate);


  // default select student
  const [selectedCat, setSelectedCat] = useState([USER_ROLE_ID.STUDENT]);

  const joining = new Date(joinDate);
  const leaving = new Date(leaveDate);
  // For initializing service time
  useEffect(() => {
    try {
      if (employeeInfo) {
        if (employeeInfo.roleIds) {
          setSelectedCat(employeeInfo.roleIds.map(item => parseInt(item,10)));
        }

        setIsLoaded(true);
      }
      if (employeeInfo?.staffInfo?.serviceTime) {
        setServiceTime(JSON.parse(employeeInfo?.staffInfo?.serviceTime));

      } else {
        setServiceTime([]);
      }
    } catch (e) {
      // console.log(e);
    }
  }, [employeeInfo]);

  useEffect(() => {
    setUserFiles(cEmployee?.files?.filter(file => file.isActive));
  }, [cEmployee]);

  const catchError = () => {
    setIsSaving(false);
  };
  const afterSaving = () => {
    setIsSaving(false);
  };

  const handleDisplay = () => {
    setDisplay(!display);
  };

  const handleEditSubmit = (formData) => {

    // const keys = Object.keys(formData);
    // for (let i = 0; i < keys.length; i+=1) {
    //   if (formData[keys[i]]) {
    //     delete formData[keys[i]];
    //   }
    // }

    // validate categories
    if (!formData.isStaff && !formData.isCustomer) {
      return setError('category', { type: 'manual', message: 'At least one is required' })
    }

    // Validate service time
    const validation = validateServiceTime(serviceTime);
    if (!validation.rs) {
      // failed
      setServiceTime(validation.result);
      return false;
    }

    if (leaving < joining && leaveDate)
      return false;

    updateEmployee({
      ...formData,
      id: employeeInfo.id,
      roleIds: selectedCat,
      serviceTime: validation.result
      , status
    }, afterSaving, catchError);
    return setIsSaving(true);

  };

  const onUploadSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg);
    // Stay on files page
  };

  const uploadFile = (fileData, inputType) => {
    const file = new FormData();

    file.append('userId', employeeInfo.id);
    file.append('type', inputType);
    file.append('file', fileData);
    uploadEmployeeFile(file, employeeInfo.id, onUploadSuccess);
  };

  const deleteFile = (userId, uid) => {
    deleteEmployeeFile({ userId: parseInt(userId,10), uid }, userId, onUploadSuccess);
  };

  const handleAddressCallback = (value) => {
    setValue('city', value.city);
    setValue('country', value.country);
  };

  const changeHandler = debounce((val) => {
    if (!val || !validateEmail(val)) {
      dispatch({ type: employeeActions.ContinueCreate });
    }
    if (val && validateEmail(val)) {
      emailCheck(val, employeeInfo.id);
    }
  }, 500);

  const handleChange = (e) => {
    setNotes(e.target.value);
  };

  const handleJoinDate = (e) => {
    setJoinDate(e.target.value);
  };
  const handleLeaveDate = (e) => {
    setLeaveDate(e.target.value);
  };

  const renderForm = () =>
    <Grid container>
      {isLoaded
        ? <div>
          {userFiles?.length > 0
            ?
            <Grid item xs={12}>
              {renderTable()}
            </Grid>
            : <Typography variant='body1' className={classes.marginTopForm}>No File</Typography>
          }
        </div>
        : <Grid item xs={12}><LoadingScreen message='Loading...' /></Grid>
      }
    </Grid>;


  const renderTable = () =>
    <TableContainer component={Paper} className={classes.notePaper}>
      <Table size='small'>

        <TableHead>
          <TableRow>
            <TableCell>Download</TableCell>
            <TableCell>Created Date</TableCell>
            <TableCell>File Name</TableCell>
            <TableCell align='right'>Delete</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>

          {userFiles.map((employeeFile) =>
            <TableRow key={employeeFile.uid}>
              <TableCell scope='row'>
                <Tooltip title='Download' placement='top'>
                  <IconButton
                    size='small'
                    onClick={() => getEmployeeFile({
                      userId: employeeInfo.id,
                      uid: employeeFile.uid,
                      fileName: `employee-${employeeInfo?.firstName}_${employeeInfo?.lastName}`
                    })}
                  >
                    <AttachFile fontSize='large' />
                  </IconButton>
                </Tooltip>
              </TableCell>

              <TableCell scope='row'>
                <div className={classes.downloadText}>
                  {moment(employeeFile.createdAt).format('L LT')}
                </div>
              </TableCell>

              <TableCell scope='row'>
                {employeeFile.fileName}
              </TableCell>

              <TableCell align='right'>
                <div>
                  <Tooltip title='Delete' placement='top'>
                    <IconButton
                      size='small'
                      onClick={() => deleteFile(employeeInfo.id, employeeFile.uid)}
                    >
                      <Delete style={{ color: 'indianred' }} fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>

            </TableRow>
          )}

        </TableBody>
      </Table>
    </TableContainer>;

  const onCopy = (targetName) => {
    copyToClipboard(dispatch, employeeInfo[targetName]);
  };

  return (
    <Paper className={clsx(classes.paper__form, classes.root)}>
      <Stack>
        <Typography className={classes.form__title} variant='h2' align='center'>
          Editing an Existing Employee
        </Typography>
        <form noValidate onSubmit={handleSubmit(handleEditSubmit)}>
          <Grid container justifyContent='flex-start' spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField required variant='standard' margin='normal' name='firstName' id='firstName'
                         label='FirstName' size='small' fullWidth
                         autoComplete='firstName' autoFocus inputRef={register}
                         error={Boolean(errors.firstName?.message)}
                         helperText={errors.firstName?.message}
                         InputLabelProps={{
                           shrink: true
                         }}
                         InputProps={{
                           startAdornment: (
                             <InputAdornment position='start'>
                               <Tooltip title='Copy Form Value' placement='top'>
                                 <IconButton color='primary'
                                             size='small'
                                             component='span'
                                             onClick={() => onCopy('firstName')}
                                 >
                                   <Clipboard {...copyBoardStyle} />
                                 </IconButton>
                               </Tooltip>
                             </InputAdornment>
                           )
                         }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField required variant='standard' margin='normal' name='lastName' id='lastName'
                         label='LastName' size='small' fullWidth
                         autoComplete='lastName' inputRef={register}
                         error={Boolean(errors.lastName?.message)}
                         helperText={errors.lastName?.message}
                         InputLabelProps={{
                           shrink: true
                         }}
                         InputProps={{
                           startAdornment: (
                             <InputAdornment position='start'>
                               <Tooltip title='Copy Form Value' placement='top'>
                                 <IconButton color='primary'
                                             size='small'
                                             component='span'
                                             onClick={() => onCopy('lastName')}
                                 >
                                   <Clipboard {...copyBoardStyle} />
                                 </IconButton>
                               </Tooltip>
                             </InputAdornment>
                           )
                         }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField required variant='standard' margin='normal' name='nickName' id='nickName'
                         label='NickName' size='small' fullWidth
                         autoComplete='nickName' inputRef={register}
                         error={Boolean(errors.nickName?.message)}
                         helperText={errors.nickName?.message}
                         InputLabelProps={{
                           shrink: true
                         }}
                         InputProps={{
                           startAdornment: (
                             <InputAdornment position='start'>
                               <Tooltip title='Copy Form Value' placement='top'>
                                 <IconButton color='primary'
                                             size='small'
                                             component='span'
                                             onClick={() => onCopy('nickName')}
                                 >
                                   <Clipboard {...copyBoardStyle} />
                                 </IconButton>
                               </Tooltip>
                             </InputAdornment>
                           )
                         }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField required variant='standard' margin='normal' name='email' id='email'
                         label='Email' size='small' fullWidth
                         autoComplete='email' inputRef={register}
                         error={Boolean(errors.email?.message)}
                         helperText={errors.email?.message}
                         InputLabelProps={{
                           shrink: true
                         }}
                         InputProps={{
                           endAdornment: (
                             <InputAdornment position='end'>
                               {isSearching ? <CircularProgress size='20px' /> : ''}
                             </InputAdornment>
                           ),

                           startAdornment: (
                             <InputAdornment position='start'>
                               <Tooltip title='Copy Form Value' placement='top'>
                                 <IconButton color='primary'
                                             size='small'
                                             component='span'
                                             onClick={() => onCopy('email')}
                                 >
                                   <Clipboard {...copyBoardStyle} />
                                 </IconButton>
                               </Tooltip>
                             </InputAdornment>
                           )
                         }}
                         onChange={(e) => changeHandler(e.target.value)}
              />
              <div style={{ paddingLeft: '10px', color: '#F44336', fontSize: '12px' }}>{error}</div>

            </Grid>
            <Grid item xs={12} md={6}>
              <TextField required variant='standard' margin='normal' name='phone' id='phone'
                         label='Phone' size='small' fullWidth
                         autoComplete='phone' inputRef={register}
                         error={Boolean(errors.phone?.message)}
                         helperText={errors.phone?.message}
                         InputLabelProps={{
                           shrink: true
                         }}
                         InputProps={{
                           startAdornment: (
                             <InputAdornment position='start'>
                               <Tooltip title='Copy Form Value' placement='top'>
                                 <IconButton color='primary'
                                             size='small'
                                             component='span'
                                             onClick={() => onCopy('phone')}
                                 >
                                   <Clipboard {...copyBoardStyle} />
                                 </IconButton>
                               </Tooltip>
                             </InputAdornment>
                           )
                         }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField variant='standard' margin='normal' name='wechat' id='wechat'
                         label='WeChat' size='small' fullWidth
                         error={Boolean(errors.wechat?.message)}
                         helperText={errors.wechat?.message}
                         autoComplete='wechat' inputRef={register}
                         InputLabelProps={{
                           shrink: true
                         }}
                         InputProps={{
                           startAdornment: (
                             <InputAdornment position='start'>
                               <Tooltip title='Copy Form Value' placement='top'>
                                 <IconButton color='primary'
                                             size='small'
                                             component='span'
                                             onClick={() => onCopy('wechat')}
                                 >
                                   <Clipboard {...copyBoardStyle} />
                                 </IconButton>
                               </Tooltip>
                             </InputAdornment>
                           )
                         }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AddressAutoComplete variant='standard' margin='normal'
                                   label='Address'
                                   register={register} TextComponent={TextField}
                                   handleAddressCallBack={handleAddressCallback} className={classes.addressAuto}
                                   error={
                                     Boolean(errors.address?.message)
                                   } currentAddress={employeeInfo.address}
                                   InputLabelProps={{
                                     shrink: true
                                   }}
                                   InputProps={{
                                     startAdornment: (
                                       <InputAdornment position='start'>
                                         <Tooltip title='Copy Form Value' placement='top'>
                                           <IconButton color='primary'
                                                       size='small'
                                                       component='span'
                                                       onClick={() => onCopy('address')}
                                           >
                                             <Clipboard {...copyBoardStyle} />
                                           </IconButton>
                                         </Tooltip>
                                       </InputAdornment>
                                     )
                                   }}
                                   helperText={errors.address?.message}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField variant='standard' margin='normal' name='city' id='city'
                         label='City' size='small' fullWidth
                         autoComplete='city' inputRef={register}
                         InputLabelProps={{
                           shrink: true
                         }}
                         error={Boolean(errors.city?.message)}
                         helperText={errors.city?.message}
                         InputProps={{
                           startAdornment: (
                             <InputAdornment position='start'>
                               <Tooltip title='Copy Form Value' placement='top'>
                                 <IconButton color='primary'
                                             size='small'
                                             component='span'
                                             onClick={() => onCopy('city')}
                                 >
                                   <Clipboard {...copyBoardStyle} />
                                 </IconButton>
                               </Tooltip>
                             </InputAdornment>
                           )
                         }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField variant='standard' margin='normal' name='zipcode' id='zipcode'
                         label='Zipcode' size='small' fullWidth
                         autoComplete='zipcode' inputRef={register}
                         InputLabelProps={{
                           shrink: true
                         }}
                         error={Boolean(errors.zipcode?.message)}
                         helperText={errors.zipcode?.message}
                         InputProps={{
                           startAdornment: (
                             <InputAdornment position='start'>
                               <Tooltip title='Copy Form Value' placement='top'>
                                 <IconButton color='primary'
                                             size='small'
                                             component='span'
                                             onClick={() => onCopy('zipcode')}
                                 >
                                   <Clipboard {...copyBoardStyle} />
                                 </IconButton>
                               </Tooltip>
                             </InputAdornment>
                           )
                         }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField variant='standard' margin='normal' name='country' id='country'
                         label='Country' size='small' fullWidth
                         autoComplete='country' inputRef={register}
                         InputLabelProps={{
                           shrink: true
                         }}
                         error={Boolean(errors.country?.message)}
                         helperText={errors.country?.message}
                         InputProps={{
                           startAdornment: (
                             <InputAdornment position='start'>
                               <Tooltip title='Copy Form Value' placement='top'>
                                 <IconButton color='primary'
                                             size='small'
                                             component='span'
                                             onClick={() => onCopy('country')}
                                 >
                                   <Clipboard {...copyBoardStyle} />
                                 </IconButton>
                               </Tooltip>
                             </InputAdornment>
                           )
                         }}
              />
            </Grid>
          </Grid>

          {/* Role & Status */}
          <Grid container spacing={3} style={{ margin: '2rem 0' }}>
            <Grid item xs={12}>
              <Typography variant='h4' color='textPrimary'>
                Role & Status
              </Typography>
            </Grid>

            {/* Left */}
            <Grid item xs={12} md={6}>
              <Stack spacing={3}>
                <FormControl fullWidth className={classes.role}>
                  <InputLabel>Role</InputLabel>
                  <Select
                    size='small'
                    multiple
                    value={selectedCat}
                    onChange={(e) => {
                      setSelectedCat(e.target.value);
                    }}
                    renderValue={
                      (selected) => (
                        <div>
                          {
                            selected.map((value) =>
                              (<Chip key={value}
                                     label={allUserRoles.find(role => role.id.toString() === value.toString())?.name || 'Unknown'} />))
                          }
                        </div>
                      )
                    }
                  >
                    {
                      allUserRoles.map((item) => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))
                    }
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 3 }}
                             component='fieldset'
                             variant='standard'>
                  <FormLabel component='legend' required
                             error={Boolean(errors.category?.message)}>User Category</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox name='isStaff'
                                  defaultChecked={employeeInfo?.isStaff}
                                  inputRef={register} />
                      }
                      label='Staff'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox name='isCustomer'
                                  defaultChecked={employeeInfo?.isCustomer}
                                  inputRef={register} />
                      }
                      label='Customer'
                    />
                  </FormGroup>
                  <FormHelperText error={Boolean(errors.category?.message)}>
                    {errors.category?.message ? errors.category?.message : 'At least one is required'}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    size='small'
                    select
                    variant="outlined"
                    label="Status"
                    name='status'
                    InputLabelProps={{
                       shrink: true
                    }}
                    fullWidth
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    value={status}
                    error={Boolean(errors.status?.message)} helperText={errors.status?.message} id='status'
                  >
                    {employeeStatus.map((value,index) => (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>))
                    }
                  </TextField>
                </FormControl>


                <Box>
                  <TextField
                    style={{
                      paddingRight: '8px'
                    }}
                    size='small'
                    inputRef={register}
                    id='joinDate'
                    label='JoinDate'
                    name='joinDate'
                    autoComplete='joinDate'
                    type='date'
                    value={joinDate}
                    onChange={handleJoinDate}
                    InputLabelProps={{
                      shrink: true
                    }}

                    error={Boolean(errors.joinDate?.message)}
                    helperText={errors.joinDate?.message}
                  />
                  {display ?
                    <Button
                      variant='contained'
                      onClick={handleDisplay}
                    >
                      Quit
                    </Button> :
                    <TextField
                      size='small'
                      inputRef={register}
                      id='leaveDate'
                      name='leaveDate'
                      label='LeaveDate'
                      autoComplete='leaveDate'
                      type='date'
                      value={leaveDate}
                      onChange={handleLeaveDate}
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={joining > leaving}
                      helperText={errors.leaveDate?.message}
                    />}
                </Box>
              </Stack>
            </Grid>

            {/* Right */}
            <Grid item xs={12} md={6}>
              <TextField
                variant='outlined'
                inputRef={register}
                id='notes'
                label='notes'
                name='notes'
                autoComplete='notes'
                value={notes}
                minRows={13}
                maxRows={18}
                multiline
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true
                }}
                error={Boolean(errors.notes?.message)}
                helperText={errors.notes?.message}
              />
            </Grid>
          </Grid>

          <Grid container direction='row'>
            <Grid item xs={12}>
              <Typography variant='h6' align='left'>
                File List

                <label htmlFor='raised-button-file'>
                  <input
                    accept='*'
                    style={{ display: 'none' }}
                    id='raised-button-file'
                    type='file'
                    onChange={(e) => uploadFile(e.target.files[0], 'picture')}
                  />
                  <Button component='span'>
                    <PublishIcon />
                    upload
                  </Button>
                </label>
              </Typography>
            </Grid>
            {renderForm()}

          </Grid>

          <Grid item xs={12} className={classes.serviceTimeContainer}>
            <EmployeeServiceTimeEditor serviceTime={serviceTime} setServiceTime={setServiceTime} />
          </Grid>

          <Grid container spacing={2}>
            <Grid item sm={3}>
              <SubmitButton fullWidth variant='contained' color='primary'
                            isSaving={isSaving} text='Save' isSavingText='Saving' />
            </Grid>
            <Grid item sm={3}>
              <Button type='button' fullWidth variant='contained' color='primary' onClick={() => {
                dispatch({ type: employeeActions.SetEmployeeFormStatus, payload: 'Close' });
              }}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
    allUserRoles: state.customer.allUserRoles,
    staffInfo: state.employee?.staffInfo,
    error: state.employee?.error,
    isSearching: state.employee?.isSearching
  });

export default connect(mapStateToProps,
  {
    updateEmployee, emailCheck, uploadEmployeeFile, getEmployeeFile,
    deleteEmployeeFile, fetchEmployee
  }
)(EditEmployeeForm);
