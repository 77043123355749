import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Button, Grid, Paper, Typography} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import {Functions} from '../../../../utils/Functions';
import ViewHead from '../../../../components/public/ViewHead';
import {fetchCustomerInterviews} from '../../../../actions/customer/interviewActions';
import history from '../../../../utils/history';
import LoadingScreen from '../../../../components/public/LoadingScreen';
import {useInterviewEditorStyles} from '../interviewHelper';
import {initCustomerNote, sendErrorMessage} from '../../../../actions';
import ReviewControllerContainer from './ReviewControllerContainer';
import ReviewHistoryContainer from './ReviewHistoryContainer';
import useAuthCheck from '../../../../hooks/useAuthCheck';
import {capitalizeFirstChar} from '../../../../utils/Scripts';

function InterviewReview({interviewId, uuid}) {
    useCurrentViewCheck(Functions.Interview_Review)
    const  navigate = useNavigate();
    const [canEditInterview] = useAuthCheck([Functions.Interview_Edit])

    const urlId = parseInt(useParams().id,10)
    const urlUid = useParams().uid
    const dispatch = useDispatch()
    const classes = useInterviewEditorStyles()

    const allCustomerInterviews = useSelector(state => state?.interview.allCustomerInterviews)
    const [currentInterview, setCurrentInterview] = useState({})

    const [isLoaded, setIsLoaded] = useState(false)
    const [isInterviewsLoaded, setIsInterviewsLoaded] = useState(false)

    const loadData = () => {
        (async () => {
            setIsInterviewsLoaded(await fetchCustomerInterviews(urlUid || uuid)(dispatch))
        })()
        initCustomerNote()(dispatch)
    }
    useEffect(loadData, [dispatch, urlId || interviewId, urlUid || uuid])

    useEffect(() => {
        if (isInterviewsLoaded) {
            const foundCurrent = allCustomerInterviews.find(iv => iv.id === urlId ? urlId : interviewId)
            if (!foundCurrent) {
                return sendErrorMessage(dispatch, 'Invalid interview')
            }
            setCurrentInterview(foundCurrent)
            setIsLoaded(true)
        }
        return ()=>{}
    }, [isInterviewsLoaded, allCustomerInterviews, urlId, interviewId, dispatch])

    const renderView = () =>
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant='h4'>
                    {
                        `[${currentInterview.status}]
            ${currentInterview.customer.firstName} ${currentInterview.customer.lastName}, 
            ${moment(currentInterview.scheduledStartTime).format('L LT')},
            ${capitalizeFirstChar(currentInterview.product.name)},
            ${currentInterview?.type.length > 0 ? `${capitalizeFirstChar(currentInterview.type)},` : ''}
            ${currentInterview.interviewer.firstName} ${currentInterview.interviewer.lastName}`
                    }</Typography>
            </Grid>
            {
                canEditInterview &&
                <Grid item xs={12}>
                    <ReviewControllerContainer id={urlId || interviewId} uid={urlUid || uuid}
                                               interviewData={currentInterview}/>
                </Grid>
            }
            <Grid item xs={12}>
                <Grid container>
                    <ReviewHistoryContainer uid={urlUid || uuid} interviewData={allCustomerInterviews}/>
                </Grid>
            </Grid>
        </Grid>

    const renderTopButtons = () =>
        <div>
            {
                isLoaded &&
                <div>
                    <Button color='secondary'
                            variant='contained'
                            onClick={() => history.goBack()}>
                        Back
                    </Button>
                    {
                        canEditInterview &&
                        <Button color='secondary'
                                variant='contained'
                                style={{marginLeft: '1rem'}}
                                onClick={() => navigate(`/app/customer/interview/edit/${urlId || interviewId}`)}>
                            Edit This Interview
                        </Button>
                    }
                    <Button color='secondary'
                            variant='contained'
                            style={{marginLeft: '1rem'}}
                            onClick={() => navigate(`/app/customer/registered/check/${currentInterview.customer?.id}`)}>
                        View Customer
                    </Button>
                </div>
            }
        </div>

    return (
        <div>
            {urlId ? <ViewHead functionId={Functions.Interview_Review} topButtons={renderTopButtons()}>
                    {
                        isLoaded
                            ?
                            <Paper className={classes.paper}>
                                {/* <Prompt */}
                                {/*    when={canEditInterview && currentInterview.startTime && !currentInterview.endTime} */}
                                {/*    message='The interview is still in progress, are you sure you want to move away from this page?'/> */}
                                {renderView()}
                            </Paper>
                            :
                            <LoadingScreen message='Loading...'/>
                    }
                </ViewHead>
                :
                <div>
                    {isLoaded
                    ?
                    <Paper className={classes.paper}>
                        {/* <Prompt */}
                        {/*    when={canEditInterview && currentInterview.startTime && !currentInterview.endTime} */}
                        {/*    message='The interview is still in progress, are you sure you want to move away from this page?'/> */}
                        {renderView()}
                    </Paper>
                    :
                    <LoadingScreen message='Loading...'/>
                    }
                </div>
            }
        </div>

    )
}

export default InterviewReview
