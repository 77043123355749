import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from 'react'
import {sendSuccessMessage} from '../../../../actions'
import LoadingScreen from '../../../../components/public/LoadingScreen'
import {fetchContractTerm} from '../../../../actions/administrative/administrativeAction'
import ContractTermEditor from './ContractTermEditor'

function EditContractTermView({rowId}) {
    const dispatch = useDispatch()
    const currentTerm = useSelector(state => state.administrative.currentContractTerm)

    const [isLoaded, setIsLoaded] = useState(false)

    const loadData = () => {
        ;(async () => {
            setIsLoaded(await fetchContractTerm(rowId)(dispatch))
        })()
    }
    useEffect(loadData, [dispatch, rowId])

    const onSuccess = msg => {
        sendSuccessMessage(dispatch, msg)
    }

    return (
        <div>
            {isLoaded ? (
                <ContractTermEditor onSubmitSuccess={onSuccess} isEdit originalData={currentTerm} />
            ) : (
                <LoadingScreen message="Loading..." />
            )}
        </div>
    )
}

export default EditContractTermView
