import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css'; // https://www.npmjs.com/package/react-perfect-scrollbar
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // https://www.npmjs.com/package/react-draft-wysiwyg
import 'nprogress/nprogress.css'; // https://www.npmjs.com/package/nprogress
import {composeWithDevTools} from "redux-devtools-extension";
import "./apis/xGrid";
import {createStore, applyMiddleware} from 'redux';
import {Provider as ReduxProvider} from 'react-redux';
import thunk from 'redux-thunk';
import React from 'react';
// import {createRoot} from 'react-dom/client';
import ReactDOM from 'react-dom';
import reducer from './reducers'
import App from "./App";
import {ENABLE_REDUX_DEV_TOOLS} from "./constants";
import axios,{setInterceptor} from './apis/api';


const reduxStore = createStore(
    reducer, {},
    ENABLE_REDUX_DEV_TOOLS ?
        composeWithDevTools(
            applyMiddleware(thunk),
        ) :
        applyMiddleware(thunk),
)

setInterceptor(axios,reduxStore);

// const container = document.getElementById('root')
// const root = createRoot(container)
// root.render(
//   <ReduxProvider store={reduxStore}>
//     <App/>
//   </ReduxProvider>
// );

ReactDOM.render(
  <ReduxProvider store={reduxStore}>

    <App/>
  </ReduxProvider>
  ,
  document.getElementById('root')
);
