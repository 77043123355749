import React, {useEffect} from "react";
import {connect} from "react-redux";
import StudentMessageList from "./StudentMessageList"
import { fetchStudentMessage} from "../../../actions";

function StudentMessageTable({studentMessage, isRead, fetchStudentMessage}) {
  /*  const [isLoading, setLoading] = React.useState(false) */
    useEffect(() => {
        fetchStudentMessage();
    }, [fetchStudentMessage])


    const handleEdit = (id) => {
        // setLoading(true);
        const studentMessageId = studentMessage[id].id;
        fetchStudentMessage(studentMessageId, () => {/* setLoading(false) */});
    }



    return (
        <div>
            {(studentMessage) ? <StudentMessageList messages={studentMessage} isEdit={isRead} handleEdit={handleEdit}/> : "No data"}
        </div>
    )
}

const mapStateToProps = (state) => ({
    studentMessage: state.trainingMessage?.studentMessage,
})

export default connect(mapStateToProps, {fetchStudentMessage})(StudentMessageTable);
