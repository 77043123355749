import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from 'react'
import {
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    Autocomplete
} from '@mui/material'
import moment from 'moment'
import {useForm} from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
    createInterview,
    fetchAllCustomers,
    fetchAllInterviewers,
    fetchAllProducts,
    updateInterview
} from '../../../../actions/customer/interviewActions'
import {
    INTERVIEW_STATUS,
    INTERVIEW_STATUS_DICT,
    INVOICE_EDITOR_ITEM_VALIDATOR,
    useInterviewEditorStyles
} from '../interviewHelper'
import {sendErrorMessage} from '../../../../actions'
import SubmitButton from '../../../../components/mui/button/SubmitButton'

const schema = yup.object().shape(INVOICE_EDITOR_ITEM_VALIDATOR)
function InterviewEditor({isEdit, originalData = {}, defaultSelectedUser = null, onSubmitSuccess}) {
    const {register, handleSubmit, errors, setError} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })

    const dispatch = useDispatch()
    const classes = useInterviewEditorStyles()

    const allInterviewers = useSelector(state => state.interview.allInterviewers)
    const allCustomers = useSelector(state => state.interview.allCustomers)
    const allProducts = useSelector(state => state.interview.allProducts)
    const fetchProps = useSelector(state => state.interview.interviewFetchBody)

    const [isSaving, setIsSaving] = useState(false)

    const [isLoaded, setIsLoaded] = useState(false)
    const [isCustomersLoaded, setIsCustomersLoaded] = useState(false)
    const [isInterviewersLoaded, setIsInterviewersLoaded] = useState(false)
    const [isProductsLoaded, setIsProductsLoaded] = useState(false)

    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [selectedInterviewer, setSelectedInterviewer] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState('')

    const loadData = () => {
        ;(async () => {
            setIsCustomersLoaded(await fetchAllInterviewers()(dispatch))
            setIsInterviewersLoaded(await fetchAllCustomers()(dispatch))
            setIsProductsLoaded(await fetchAllProducts()(dispatch))
        })()
    }
    useEffect(loadData, [])

    useEffect(() => {
        if (isCustomersLoaded && isInterviewersLoaded && isProductsLoaded) {
            setIsLoaded(true)

            if (isEdit) {
                // Fill default values
                setSelectedProduct(allProducts?.find(p => p?.id === originalData?.product?.id))
                setSelectedCustomer(allCustomers?.find(c => c?.id === originalData?.customer?.id))
                setSelectedInterviewer(allInterviewers?.find(i => i?.id === originalData?.interviewer?.id))
                setSelectedStatus(originalData.status)
            }

            // Set default selected customer
            if (defaultSelectedUser) {
                const foundCustomer = allCustomers?.find(c => c?.id === defaultSelectedUser)
                if (foundCustomer) {
                    setSelectedCustomer(foundCustomer)
                }
            }
        }
    }, [isCustomersLoaded, isInterviewersLoaded, isProductsLoaded])

    const onError = msg => {
        setIsSaving(false)
        sendErrorMessage(dispatch, msg)
    }

    const onSubmit = async formData => {
        setIsSaving(true)

        const momentStart = moment(formData.scheduledStartTime)
        const momentEnd = moment(formData.scheduledEndTime)

        if (!isEdit) {
            // Only check when creating
            const current = moment()
            if (momentStart.isBefore(current)) {
                setIsSaving(false)
                return setError('scheduledStartTime', {type: 'manual', message: 'Start time must be in the future'})
            }

            if (momentEnd.isBefore(current)) {
                setIsSaving(false)
                return setError('scheduledEndTime', {type: 'manual', message: 'End time must be in the future'})
            }
        }

        // The time cannot be equal
        if (momentStart.isSameOrAfter(momentEnd)) {
            setIsSaving(false)
            return setError('scheduledStartTime', {type: 'manual', message: 'Start time must be before end time'})
        }

        let data = {
            customerId: selectedCustomer?.id,
            interviewerId: selectedInterviewer?.id,
            productId: selectedProduct?.id,
            type: formData?.type,
            scheduledStartTime: momentStart?.toISOString(),
            scheduledEndTime: momentEnd?.toISOString()
        }

        if (isEdit) {
            data = {
                ...data,
                id: originalData.id,
                score: formData.score.trim() !== '' ? parseFloat(formData.score) : null,
                status: selectedStatus,
                startTime: formData.startTime !== '' ? moment(formData.startTime).toISOString() : null,
                endTime: formData.endTime !== '' ? moment(formData.endTime).toISOString() : null
            }
            return updateInterview(data, fetchProps, onSubmitSuccess, onError)(dispatch)
        }
        return createInterview(data, fetchProps, onSubmitSuccess, onError)(dispatch)
    }

    const renderForm = () => (
        <Grid container spacing={4} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h4">Customer</Typography>
                <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.customer?.message)}>
                    <Autocomplete
                        disabled={isSaving || isEdit}
                        options={allCustomers}
                        noOptionsText="No Customer"
                        getOptionLabel={option => `${option.id}. ${option.firstName} ${option.lastName}`}
                        value={selectedCustomer}
                        onChange={(e, v) => setSelectedCustomer(v)}
                        renderInput={params => (
                            <TextField {...params} label="Customer" variant="outlined" size="small" required />
                        )}
                    />
                    <FormHelperText error={Boolean(errors.customer?.message)}>
                        {errors.customer?.message ? errors.customer?.message : ''}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">Interviewer</Typography>
                <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.interviewer?.message)}>
                    <Autocomplete
                        disabled={isSaving}
                        options={allInterviewers}
                        noOptionsText="No Interviewer"
                        getOptionLabel={option => `${option.id}. ${option.firstName} ${option.lastName}`}
                        value={selectedInterviewer}
                        onChange={(e, v) => setSelectedInterviewer(v)}
                        renderInput={params => (
                            <TextField {...params} label="Interviewer" variant="outlined" size="small" required />
                        )}
                    />
                    <FormHelperText error={Boolean(errors.interviewer?.message)}>
                        {errors.interviewer?.message ? errors.interviewer?.message : ''}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">Product</Typography>
                <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.product?.message)}>
                    <Autocomplete
                        disabled={isSaving}
                        options={allProducts}
                        noOptionsText="No Product"
                        getOptionLabel={option => option.name}
                        value={selectedProduct}
                        onChange={(e, v) => setSelectedProduct(v)}
                        renderInput={params => (
                            <TextField {...params} label="Product" variant="outlined" size="small" required />
                        )}
                    />
                    <FormHelperText error={Boolean(errors.interviewer?.message)}>
                        {errors.interviewer?.message ? errors.interviewer?.message : ''}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">Type</Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    inputRef={register}
                    label="Type"
                    name="type"
                    className={classes.autofillForm}
                    defaultValue={isEdit ? originalData.type : ''}
                    error={Boolean(errors.type?.message)}
                    helperText={errors.type?.message || 'The interview method; ie. onsite, zoom, etc'}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" className={classes.marginBottom}>
                    Time
                </Typography>
                <Grid container spacing={6} justifyContent="space-evenly">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            inputRef={register}
                            fullWidth
                            required
                            name="scheduledStartTime"
                            label="Scheduled Start Time"
                            type="datetime-local"
                            InputLabelProps={{shrink: true}}
                            disabled={isSaving}
                            defaultValue={
                                isEdit
                                    ? moment(originalData.scheduledStartTime).format('YYYY-MM-DDTHH:mm')
                                    : moment()
                                          .set({hour: 12, minute: 0, second: 0, millisecond: 0})
                                          .add(1, 'd')
                                          .format('YYYY-MM-DDTHH:mm')
                            }
                            error={Boolean(errors.scheduledStartTime?.message)}
                            helperText={errors.scheduledStartTime?.message ? errors.scheduledStartTime?.message : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            inputRef={register}
                            fullWidth
                            required
                            name="scheduledEndTime"
                            label="Scheduled End Time"
                            type="datetime-local"
                            InputLabelProps={{shrink: true}}
                            disabled={isSaving}
                            defaultValue={
                                isEdit
                                    ? moment(originalData.scheduledEndTime).format('YYYY-MM-DDTHH:mm')
                                    : moment()
                                          .set({hour: 13, minute: 0, second: 0, millisecond: 0})
                                          .add(1, 'd')
                                          .format('YYYY-MM-DDTHH:mm')
                            }
                            error={Boolean(errors.scheduledEndTime?.message)}
                            helperText={errors.scheduledEndTime?.message ? errors.scheduledEndTime?.message : ''}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    const renderEditOnlyForm = () => (
        <Grid container spacing={4} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h4" className={classes.detailsView}>
                    Details
                </Typography>
                <FormHelperText>
                    Change the following to manually override interview details; Eg. If there was a mistake
                </FormHelperText>
                <Grid container spacing={6} justifyContent="space-evenly" className={classes.detailsDate}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            inputRef={register}
                            fullWidth
                            name="startTime"
                            label="Interview Start Time"
                            type="datetime-local"
                            InputLabelProps={{shrink: true}}
                            disabled={isSaving}
                            defaultValue={
                                isEdit && originalData.startTime
                                    ? moment(originalData.startTime).format('YYYY-MM-DDTHH:mm')
                                    : ''
                            }
                            error={Boolean(errors.startTime?.message)}
                            helperText={errors.startTime?.message ? errors.startTime?.message : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            inputRef={register}
                            fullWidth
                            name="endTime"
                            label="Interview End Time"
                            type="datetime-local"
                            InputLabelProps={{shrink: true}}
                            disabled={isSaving}
                            defaultValue={
                                isEdit && originalData.endTime
                                    ? moment(originalData.endTime).format('YYYY-MM-DDTHH:mm')
                                    : ''
                            }
                            error={Boolean(errors.endTime?.message)}
                            helperText={errors.endTime?.message ? errors.endTime?.message : ''}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={6} justifyContent="space-evenly">
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required disabled={isSaving} className={classes.status}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                size="small"
                                fullWidth
                                name="status"
                                input={<Input />}
                                value={selectedStatus}
                                onChange={e => setSelectedStatus(e.target.value)}
                            >
                                {Object.keys(INTERVIEW_STATUS).map((u, index) => (
                                    <MenuItem key={index} value={INTERVIEW_STATUS[u]}>
                                        {INTERVIEW_STATUS_DICT[INTERVIEW_STATUS[u]]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth className={classes.score}>
                            <TextField
                                fullWidth
                                size="small"
                                name="score"
                                label="Score"
                                type="number"
                                disabled={isSaving}
                                inputRef={register}
                                defaultValue={originalData.score}
                                inputProps={{min: 0, max: 5, step: 0.5}}
                                error={Boolean(errors.score?.message)}
                                helperText={errors.score?.message}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    const renderSubmit = () => (
        <Grid container spacing={3} justifyContent="flex-end" alignItems="center" className={classes.submitButton}>
            <Grid item>
                <SubmitButton
                    isSaving={isSaving}
                    text="Save"
                    isSavingText="Saving"
                    fullWidth
                    variant="contained"
                    color="primary"
                />
            </Grid>
        </Grid>
    )

    return (
        <div>
            {isLoaded ? (
                <Paper className={classes.paper}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {renderForm()}
                        {isEdit && renderEditOnlyForm()}
                        {renderSubmit()}
                    </form>
                </Paper>
            ) : (
                <Typography variant="h3" color="textPrimary">
                    Loading...
                </Typography>
            )}
        </div>
    )
}

export default InterviewEditor
