import React, { useEffect } from 'react';
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from "../../../hooks/useCurrentViewCheck";
import {Functions} from "../../../utils/Functions";
import TextLine from "./TextLine";
import AuthCheck from "../../../components/auth/AuthCheck";
import UserAvatarWithUploader from "../UserAvatarWithUploader";
import LoadingScreen from "../../../components/public/LoadingScreen";
import { fetchUser } from '../../../actions';


const useStyles = makeStyles((theme) => ({
// todo clear up those
    paper: {
        backgroundColor: theme.palette.background.dark,
        padding: '1rem 1rem',
    },
    infoContainer: {
        marginTop: '1rem',
    },
    avatar: {
        width: 60,
        height: 60,

    },
    avatarBox: {
        transform: "translate(-50px,-10px)",

    },
    avatarRoot: {
        "&::after": {
            content: "''",
            display: "block",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: 100,
            height: 22,
            backgroundColor: '#000',
            opacity: .25,
            zIndex: 1200,
        }
    },
    title: {
        [theme.breakpoints.down('xl')]: {
            transform: "translateY(10px)",
        }
    },
    PhotoTitle: {
        [theme.breakpoints.down('xl')]: {
            transform: "translateY(28px)",
        }
    },
    changePhotoIcon: {
        color: '#000000',
        marginLeft: 61,
        marginBottom: 21,
        opacity: .3,
        zIndex: 1201,
    },

}));

function PersonalInfo({fetchUser, user, authUser}) {
    useCurrentViewCheck(Functions.My_Profile_Personal_Information);
    const  navigate = useNavigate();
    useEffect(() => {
       fetchUser(user?.id)
    }, []);
    const classes = useStyles();
    const handleChangePassword = ()=>{
        navigate('/app/personal/changePassword')
    }
    if(!user){
        return <LoadingScreen/>
    }
    return (
        <Paper className={classes.paper}>
            <Typography variant="h4" color="textPrimary">
                Basic Information
            </Typography>
            <Typography variant="body2" color="textSecondary">
                Some info may be visible to other people.
            </Typography>
            <Grid container direction="column" spacing={3} className={classes.infoContainer}>
                <Grid item>
                    <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} sm={2}>
                            <Typography variant="body2" color="textSecondary" className={classes.PhotoTitle}>
                                Photo
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={7}>
                            <Typography variant="body2" color="textSecondary">
                                A Photo helps personalise your account
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <UserAvatarWithUploader/>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TextLine title="Name" value={`${authUser?.firstName} ${authUser?.lastName}`}/>
                </Grid>
                <Grid item>
                    <TextLine title="Email" value={authUser?.email || ''}/>
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={12} sm={3}>
                            <Typography variant="body2" color="textSecondary" className={classes.title}>
                                Password
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="body2" color="textPrimary">
                                ********
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={7}>
                            <AuthCheck functionId={Functions.My_Profile_Personal_Information_Change_Password}>
                                <Button color="primary" style={{lineHeight:1}} onClick={()=>{
                                    handleChangePassword();
                                }}>
                                    Change Password
                                </Button>
                            </AuthCheck>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TextLine title="Phone" value={authUser?.phone ||''}/>
                </Grid>
                <Grid item>
                    <TextLine title="Wechat" value={authUser?.wechat || ''}/>
                </Grid>
                <Grid item>
                    <TextLine title="Address" value={`${authUser?.address}, ${authUser?.city}, ${authUser?.country} ${authUser?.zipcode}`}/>
                </Grid>

            </Grid>

        </Paper>
    );
}

const mapStateToProps = (state) => ({
        user: state.auth?.user,
        authUser: state.auth?.authUser
    })

export default connect(mapStateToProps, {fetchUser})(PersonalInfo);

