import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Grid, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { INVOICE_EDITOR_ITEM_VALIDATOR, useInvoiceEditorStyles } from '../invoiceHelper';
import SubmitButton from '../../../../components/mui/button/SubmitButton';

const schema = yup.object().shape(INVOICE_EDITOR_ITEM_VALIDATOR)
const InvoiceEditorItemsDialogue = ({ itemIdx, items, setItems, onClose}) => {

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  })

  const classes = useInvoiceEditorStyles()

  const onSubmit = async (formData) => {

    if (itemIdx === -1) {
      // Add new
      setItems([...items, formData])
    } else {
      // Replace old one
      const tempItemData = [...items]
      tempItemData.splice(itemIdx, 1, formData)
      setItems(tempItemData)
    }
    onClose()
  }

  const renderForm = () =>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4} justifyContent='flex-end'>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField fullWidth size='small'
                       label='Quantity' type='number' required
                       name='quantity' inputRef={register}
                       defaultValue={ itemIdx === -1 ? '' : items[itemIdx]?.quantity }
                       error={Boolean(errors.quantity?.message)}
                       helperText={errors.quantity?.message ? errors.quantity?.message : ''}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField fullWidth size='small'
                       label='Price' required
                       name='price' inputRef={register}
                       defaultValue={ itemIdx === -1 ? '' : items[itemIdx]?.price }
                       error={Boolean(errors.price?.message)}
                       helperText={errors.price?.message ? errors.price?.message : ''}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField fullWidth size='small'
                       label='Discount'
                       name='discount' inputRef={register}
                       defaultValue={ itemIdx === -1 ? '' : items[itemIdx]?.discount }
                       error={Boolean(errors.discount?.message)}
                       helperText={errors.discount?.message ? errors.discount?.message : ''}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField fullWidth size='small'
                       label='Description' required
                       name='description' inputRef={register}
                       defaultValue={ itemIdx === -1 ? '' : items[itemIdx]?.description }
                       error={Boolean(errors.description?.message)}
                       helperText={errors.description?.message ? errors.description?.message : ''}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <TextField fullWidth size='small'
                       label='Notes'
                       name='notes' inputRef={register}
                       defaultValue={ itemIdx === -1 ? '' : items[itemIdx]?.notes }
                       error={Boolean(errors.notes?.message)}
                       helperText={errors.notes?.message ? errors.notes?.message : ''}
            />
          </FormControl>
        </Grid>

        <Grid item>
          <SubmitButton text={itemIdx === -1 ? 'Add Item' : 'Update Item'} isSavingText='Saving'
                        fullWidth variant='contained' color='primary' className={classes.marginTop}
          />
        </Grid>
      </Grid>
    </form>


  return renderForm()
}

export default InvoiceEditorItemsDialogue
