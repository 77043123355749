import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import {Edit as EditIcon, Eye} from 'react-feather';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog';
import {
    classActionsType,
    deleteHomework,
    fetchCurrentHomework, fetchOneHomework
} from '../../../actions/class/classActions';
import LXGrid from '../../../components/mui/datagrid/XGrid';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    editIcon: {
        cursor: 'pointer'
    },
    tableCell: {
        width: 80,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px'
    },
    funcCell: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    add: {
        '& > span': {
            margin: theme.spacing(2)
        }
    }
}));
function Homework({ isEdit, homework, fetchCurrentHomework, fetchOneHomework }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleEdit = (homeworkId) => {

        fetchCurrentHomework(homework.data[0].id, homeworkId);
        dispatch({ type: classActionsType.SetClassTableStatus, payload: 'EditHomework' });
    };

    // const [delId, setDelId] = useState(null);
    // const [open, setOpen] = useState(false);
    // const [hash, setHash] = useState('init');
    const delId = null
    const open = false
    const hash = 'init'
    // const handleDelete = (id) => {
    //     setDelId(id);
    //     setOpen(true);
    //     setHash(Math.random().toString(16).substring(2, 10));
    // };

    const handleRead = (homeworkId) => {
        fetchOneHomework(homework.data[0].id, homeworkId);
        dispatch({ type: classActionsType.SetClassTableStatus, payload: 'ReadHomework' });
    };

    const columns = [
        {
            field: '_actionField',
            headerName: ' ',
            resizable: false,
            disableClickEventBubbling: true,
            filterable: false,
            width: 100,
            renderCell: params => (
                <div style={{ position: 'relative', width: '100%' }}>
                    <div className={classes.funcCell}>
                        <Tooltip title="Read" placement="top">
                            <IconButton color="primary" component="span" size="small"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleRead(params.row.id);
                                        }
                                        }
                            >
                                <Eye size={15}/>
                            </IconButton>
                        </Tooltip>
                        {isEdit && (
                            <Tooltip title="Edit" placement="top">
                                <IconButton color="primary" component="span" size="small"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleEdit(params.row.id);
                                            }
                                            }
                                >
                                    <EditIcon size={15}/>
                                </IconButton>
                            </Tooltip>
                        )}

                        {/* {isEdit && ( */}
                        {/*    <IconButton component="span" size="small" style={{ marginLeft: 10 }} onClick={(event) => { */}
                        {/*        event.stopPropagation(); */}
                        {/*        handleDelete(params.row.id); */}
                        {/*    }}> */}
                        {/*        <Trash2 size={15} color="red"/> */}
                        {/*    </IconButton> */}
                        {/* )} */}
                    </div>
                </div>
            )
        },
        { field: 'id', headerName: 'ID', width: 50, sortable: false },
        { field: 'extension', headerName: 'DCID', width: 150, sortable: false },
        { field: 'name', headerName: 'Name', width: 200, sortable: false },
        { field: 'status', headerName: 'Submitted/Total', width: 200 },
        {
            field: 'dueDay', headerName: 'Due Day', width: 200,
            valueFormatter: ({ value }) => moment(value).format('L LT')
        },
        {
            field: 'created', headerName: 'Created', width: 200,
            valueFormatter: ({ value }) => moment(value).format('L LT')
        }
    ];

    const rows = homework?.data.length > 0 ? homework.data[0]?.homework.map((item) => {
        let submissionTotal = ""
        let submitted = ""
        if (item.submissions) {
            submissionTotal = item.submissions.length;
            submitted = item.submissions.reduce((total, num) => total + (num.status === 'unsubmitted' ? 0 : 1), 0);
        }

        return {
            id: item.id,
            extension: item.extension,
            name: item.name,
            status: `${submitted}/${submissionTotal}`,
            created: item.createdAt,
            dueDay: item.dueDate
        };
    }) : [];
    const data = { columns, rows };
    return (
        <div>
            <div className={classes.root}>
                <Typography variant="h5" color="textPrimary">Homework &nbsp;&nbsp;</Typography>
                {isEdit ?
                    <AddCircleIcon color="primary"
                                   onClick={() => {
                                       dispatch({ type: classActionsType.SetClassTableStatus, payload: 'AddHomework' });
                                   }}
                    /> : <div/>
                }
            </div>
            <Grid item>
                <Paper className={classes.paper}>
                    <div style={{ width: '100%' }}>
                        <LXGrid
                            {...data}
                            totalHeight={400}
                            checkboxSelection
                            disableSelectionOnClick
                            sortModel={[{ field: 'created', sort: 'desc' }]}
                        />
                        <ResponsiveDialog isOpen={open} openHash={hash}
                                          title="Delete Parameter Confirm" content="Are you sure?"
                                          Buttons={[
                                              {
                                                  name: 'Yes',
                                                  event: () => {
                                                      deleteHomework(delId)(dispatch);
                                                  }
                                              },
                                              {
                                                  name: 'Cancel'
                                              }
                                          ]}/>
                    </div>
                </Paper>
            </Grid>

        </div>
    );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { fetchCurrentHomework, fetchOneHomework })(Homework);
