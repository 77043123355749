import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Container,
  Grid,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography
} from '@mui/material';
import moment from 'moment';
import { useNotesStyles } from '../customerData';
import LoadingScreen from '../../../components/public/LoadingScreen';
import { fetchCustomerContracts } from '../../../actions';

function EditCustomerOrder({ currentCustomer, fetchCustomerContracts}) {
  const classes = useNotesStyles()
  const allContracts = useSelector(state => state.customer.currentCustomerContract)
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    if (currentCustomer) {
      (async () => {
        setIsLoaded(fetchCustomerContracts(currentCustomer.id))
      })()
    } else {
      setIsLoaded(false)
    }
  }, [currentCustomer])

  const renderTable = () =>
      <TableContainer component={Paper} className={classes.notePaper}>
        <Table size="small">

          <TableHead>
            <TableRow>
              <TableCell>Created Date</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>

            {allContracts.map((contract) =>
                <TableRow key={contract.cid}>
                  <TableCell scope="row">
                    { moment(contract.createdAt).format('L LT') }
                  </TableCell>
                  <TableCell scope="row">
                    { contract.name }
                  </TableCell>
                  <TableCell scope="row">
                    { contract.price }
                  </TableCell>
                </TableRow>
            ) }

          </TableBody>
        </Table>
      </TableContainer>
  const renderForm = () =>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h3'>Order</Typography>
        </Grid>
        { isLoaded
            ? <div>
              {allContracts?.length > 0
                  ?
                  <Grid item xs={12}>
                    {renderTable()}
                  </Grid>
                  : <Typography variant='body1' className={classes.marginTop}>No Order</Typography>
              }
            </div>
            : <Grid item xs={12}><LoadingScreen message='Loading...' /></Grid>
        }

      </Grid>

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper__form}>
          <Paper className={classes.paper}>
            <Container maxWidth="lg">
              { renderForm() }
              <div className={classes.marginBottom} id='emptyDivAtTheBottom' />
            </Container>
          </Paper>
      </Paper>
    </Grid>

  );
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
    fetchCustomerContracts
})(EditCustomerOrder);
