import React, { useState } from 'react';
import { FormControl, FormHelperText, FormLabel, Grid, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { CONTRACT_UPLOAD_VALIDATOR, useContractFormViewStyles } from './contractHelper';
import SubmitButton from '../../../components/mui/button/SubmitButton';
import { sendErrorMessage } from '../../../actions';
import { mbToBytes } from '../../forms/formBuilder/formBuilderData';
import { uploadContractFile } from '../../../actions/administrative/administrativeAction';


const schema = yup.object().shape(CONTRACT_UPLOAD_VALIDATOR)
const AddContractFileDialogue = ({uid, cid, onSubmitSuccess}) => {

  const [isSaving, setIsSaving] = useState(false)

  const classes = useContractFormViewStyles()
  const dispatch = useDispatch()

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  })

  const onSubmit = async (formData) => {
    setIsSaving(true)

    if (!formData.file || (formData.file && (formData.file.length === 0))) {
      setIsSaving(false)
      setError('file', { type: 'manual', message: 'Required' })
      return
    }

    if (formData.file[0].size > mbToBytes(5)) {
      setIsSaving(false)
      setError('file', { type: 'manual', message: `File must be less than 5mb` })
      return
    }

    const file = new FormData()
    file.append('file', formData.file[0])
    file.append('name', formData.name)
    file.append('description', formData.description)
    file.append('uid', uid)
    file.append('cid', cid)

    // upload
    await uploadContractFile(file, onSubmitSuccess, onError)(dispatch)
  }

  const onError = (msg) => {
    sendErrorMessage(dispatch, msg)
    setIsSaving(false)
  }

  const renderForm = () =>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5} direction='row' justifyContent='space-around'>

        <Grid item xs={12}>
          <Typography variant='h3'>Upload File</Typography>
        </Grid>


        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField fullWidth size='small'
                       label='Name' required
                       disabled={isSaving} variant='outlined'
                       name='name' inputRef={register}
                       error={Boolean(errors.name?.message)}
                       helperText={errors.name?.message ? errors.name?.message : 'Max 30 characters'}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField fullWidth size='small' label='Description'
                       disabled={isSaving} variant='outlined'
                       name='description' inputRef={register}
                       error={Boolean(errors.description?.message)}
                       helperText={errors.description?.message ? errors.description?.message : 'Max 200 characters'}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth required
                       error={Boolean(errors.file?.message)}>
            <FormLabel component='legend'>File</FormLabel>
            <div className={classes.marginLeft}>
              <input type='file' name='file' ref={register}/>
              <FormHelperText>{errors.file?.message ? errors.file?.message : 'Max 5 mb'}</FormHelperText>
            </div>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <SubmitButton isSaving={isSaving} text='Upload' isSavingText='Uploading'
                        fullWidth variant='contained' color='primary'
          />
        </Grid>

      </Grid>
    </form>


  return renderForm()

}

export default AddContractFileDialogue
