import {CircularProgress} from "@mui/material";
import React from 'react'

export function LogLoading() {
    return <div className="loadingDiv" style={{zIndex:"10000",position:"fixed",top:"0",left:"0",
    width:"100vw",height:"100vh",backgroundColor:"rgba(196, 196, 196, 0.48)"}}>
        <CircularProgress style={{position: 'fixed', top: '45%', left: "45%",fontSize:"2.5%"}} />
    </div>
}

export default LogLoading