import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import {sendErrorMessage} from '../../../actions';
import {fetchFile} from '../../../actions/forms/formActions';


export const handleFileOpen = (id) => (dispatch) => {
    fetchFile(id,
        (msg) => {
            sendErrorMessage(dispatch, msg)
        })(dispatch)
}

export const getHeaderLabel = (name, isRequired, isUnique) => {
    let tempName = name
    if (isRequired) {
        tempName = `${tempName} *`
    }
    if (isUnique) {
        tempName = `[U] ${tempName}`
    }
    return tempName
}


export const LS_KEY_CREATE_CUSTOMER = 'new_user_to_add'
export const addCustomerFromForm = (dispatch, history, customerDetails, tableHeaders) => {
    const  navigate = useNavigate();

    let usableDetails = {}
    function toLowerKeys(obj) {
        return Object.keys(obj).reduce((accumulator, key) => {
            accumulator[key.toLowerCase()] = obj[key];
            return accumulator;
        }, {});
    }
    if (tableHeaders[0].name !== "webFormName") {

        // Filter out the headers that contains a unique tag
        const tagHeaders = tableHeaders.filter(hdr => hdr.tag !== null)
        // Create object to send to be registered
        usableDetails = Object.keys(customerDetails).reduce((res, current) => {
            const found = tagHeaders.find(th => th.name === current)
            if (found) {
                res[found.tag] = customerDetails[current]
            }
            return res
        }, {})

        // Tracking details
        usableDetails.repName = customerDetails.repUser ?? ''
        usableDetails.repId = customerDetails.repUserId ?? ''
        usableDetails.source = customerDetails.source ?? ''
    } else {
        const lowerKeysObj = toLowerKeys(customerDetails)
        Object.keys(lowerKeysObj).forEach(key => {
            usableDetails[key] = lowerKeysObj[key]
        })

    }

    // Write details to localstorage
    localStorage.setItem(LS_KEY_CREATE_CUSTOMER, JSON.stringify(usableDetails))

    // change view to registered
    navigate('/app/customer/registered/add')
}

export const useDisplaySubmissionStyles = makeStyles(() => ({
    paper: {
        padding: '2rem',
        marginBottom: '2rem'
    },
    pageTitle: {
        marginBottom: '1rem',
    }
}))
