import React from "react";
import { Navigate } from 'react-router';
import NewCustomerRegisterView from '../../views/customer/RegisterView';
import HooksTrainingView from '../../views/training_lee/HooksTrainingView';
import Footer from '../../views/training_lee/FooterView';
import FormViewerView from '../../views/forms/formList/formViewer';
import ScanPosterView from '../../views/campaign/campaignDetails/poster/public/scanPosterView';
import ScanQRCodeView from '../../views/qrcode/public/scanQRCodeView';
import ContractView from '../../views/administrative/contract/contractUserView';
import InvoicePublicView from '../../views/administrative/invoice/view/invoicePublicView';
import ReviewPublicView from '../../views/review/reviewView/reviewPublicView';
import ActiveCustomerView from '../../views/auth/ActivateCustomerView';

const customersRoutes = [
    {
        path:'customers',
        routes:[
            {
                exact: 'true',
                path: 'FreshStart/:id',
                component: <NewCustomerRegisterView/>
            },
            {
                component: <Navigate to="404" /> // this must be the last one
            },
        ]
    },
    {
        path:'training_lee',
        routes:[
            {
                path:'hooks',
                exact: 'true',
                component: <HooksTrainingView/>
            },
            {
                path:'footer',
                exact: 'true',
                component: <Footer/>
            }
        ]
    },
    {
        path:'form',
        routes:[
            {
                path:':uid?/:fid',
                exact: 'true',
                component:<FormViewerView/>
            },
        ]
    },
    {
        path: 'poster',
        routes: [
            {
                path: ':cid/:pid/:uid',
                exact: 'true',
                component: <ScanPosterView/>
            }
        ]
    },
    {
        path: 'qr',
        routes: [
            {
                path: ':sid/:cid/:uid',
                exact: 'true',
                component:<ScanQRCodeView/>
            }
        ]
    },
    {
        path: 'contract',
        routes: [
            {
                path: ':uid/:cid',
                exact: 'true',
                component:<ContractView/>
            }
        ]
    },
    {
        path: 'invoice',
        routes: [
            {
                path: ':uid/:iid',
                exact: 'true',
                component:<InvoicePublicView/>
            }
        ]
    },
    {
        path: 'review',
        routes: [
            {
                path: ':uid',
                exact: 'true',
                component:<ReviewPublicView/>
            }
        ]
    },
    {
        path: 'activate',
        routes: [
            {
                path: ':uid/:vid',
                exact: 'true',
                component: <ActiveCustomerView/>
            }
        ]
    },
]

export default customersRoutes
