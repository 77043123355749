import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import {IconButton, List, ListItem, TextField} from "@mui/material";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {useRef, useState} from "react";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch } from 'react-redux';
import { sendErrorMessage } from '../../../../actions';

function ContractPaymentHistoryReference(props) {

    const dispatch = useDispatch()
    const {setFormData,formData} = props
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const imgInput = useRef()

    const [referenceData, setReferenceData] = useState(
        {
                pic:  new File(["foo"], "foo.txt", {
                    type: "text/plain",
                }),
                note:"",
            })
    return (
        <div>
            <IconButton color='primary'
                        size='small'
                        component='span'
                        onClick={handleClickOpen}
            >
                <NoteAddOutlinedIcon />
            </IconButton>
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Payment History Reference
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div style={{display:"flex" ,marginTop:"10px",marginBottom:"10px", width:"500px",justifyContent:"space-between"}}>
                            <TextField fullWidth size='small'
                                       sx={{width:"220px"}}
                                       label='email'required
                                       name='email'
                                       value={formData.email}
                                       onChange={(e) =>
                                           setFormData({ ...formData, email: e.target.value })}

                            />
                            <TextField fullWidth size='small'
                                       sx={{width:"220px"}}
                                       label='payer'required
                                       name='payer'
                                       value={formData.payer}
                                       onChange={(e) =>
                                           setFormData({ ...formData, payer: e.target.value })}
                            />
                        </div>
                        {/* pic */}
                        <div>
                            <div style={{marginBottom:"15px"}}>
                                <DialogContentText>
                                Pic to upload:
                                </DialogContentText>
                                {formData?.pic.map((item)=>(
                                        <div key={item.toString()} >
                                            <div>
                                                {item?.name}
                                            </div>
                                            <div>
                                                <img width="150px" src={URL.createObjectURL(item)} alt='Reference Pic'/>
                                            </div>
                                            <div style={{marginBottom:"20px"}} />
                                        </div>
                                    ))}
                            </div>

                            <Button
                                variant="contained"
                                component="label"
                            >
                                Upload File
                                <input ref={imgInput} type="file" accept='image/*' name="file" hidden onChange={(e)=>{
                                    if ( imgInput.current.value !== null){
                                        const picArray = formData.pic
                                        let checkExist = false
                                        for (let i = 0 ;i <formData?.pic?.length ; i+=1){
                                            if(
                                                e.target.files[0].name === formData?.pic[i].name
                                            ) {
                                                checkExist = true
                                            }
                                        }

                                        if (checkExist === false){
                                            picArray.push(e.target.files[0])
                                            setFormData({ ...formData, pic: picArray })
                                            setReferenceData({
                                                pic:new File(["foo"], "foo.txt", {
                                                    type: "text/plain",
                                                }),
                                                note:"",
                                            })
                                        }else {
                                            sendErrorMessage(dispatch,"Photo name select already exist")
                                        }
                                        imgInput.current.value = null
                                    }

                                }
                                } />
                            </Button>
                        </div>
                        {/* note */}
                        <div style={{marginTop:"15px"}}>
                            <DialogContentText>
                                Note:
                            </DialogContentText>

                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {formData?.note.map((item,index)=>(
                                        <ListItem key={item.toString()}>
                                            {index + 1}.
                                            <ListItemText primary={item} />
                                        </ListItem>
                                        ))}

                            </List>
                            <div style={{display:"flex",alignItems:"center"}}>
                                <TextField fullWidth size='small'
                                           sx={{width:"500px"}}
                                           label='note'required
                                           name='note'
                                           value={referenceData.note}
                                           onChange={(e) =>
                                               setReferenceData({ ...referenceData, note: e.target.value })}
                                />
                                <div style={{
                                    marginLeft:"10px"
                                }}>
                                    <IconButton color='primary'
                                                size='small'
                                                component='span'
                                                disabled={referenceData.note === ""}
                                                onClick={ ()=>{
                                                    const noteArray = formData.note
                                                    noteArray.push(referenceData.note)
                                                    setFormData({ ...formData, note: noteArray })
                                                    setReferenceData({
                                                        pic:null,
                                                        note:"",
                                                    })
                                                }}
                                    >
                                        <AddCircleOutlineOutlinedIcon />
                                    </IconButton>
                                </div>
                            </div>


                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{
                        if(referenceData.note !== ""){
                            const noteArray = formData.note
                            noteArray.push(referenceData.note)
                            setFormData({ ...formData, note: noteArray })
                            setReferenceData({
                                pic:null,
                                note:"",
                            })
                        }
                        handleClose()
                    }}>close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ContractPaymentHistoryReference