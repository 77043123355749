import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Button,
  Grid,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PublishIcon from '@mui/icons-material/Publish';
import { AttachFile, Delete } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import {
  deleteCustomerFile, getCustomerFile,
  sendSuccessMessage,
  uploadCustomerFile

} from '../../../actions';
import EditCustomerNotes from './EditCustomerNotes';
import { useNotesStyles } from '../customerData';
import EditCustomerPayment from './EditCustomerPayment';
import LoadingScreen from '../../../components/public/LoadingScreen';
import EditCustomerOrder from './EditCustomerOrder';
import EditCustomerCompany from './EditCustomerCompany';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {/* <Typography >{children}</Typography> */}
          <div>{children}</div>

        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

TabPanel.defaultProps = {
  children: <div />
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const progress = ['2020-01-01 10:10:01 Registration',
  '2020-01-01 10:10:01 Enrollment',
  '2020-01-01 10:10:01 Deposit',
  '2020-01-01 10:10:01 Payment 1',
];

function EditCustomerTab({
                           currentCustomer,
                           uploadCustomerFile,
                           getCustomerFile,
                           deleteCustomerFile,
                         }) {


  const classes = useNotesStyles()
  const dispatch = useDispatch()

  const [value, setValue] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false)
  const [userResumeFiles, setUserResumeFiles] = useState([])
  const [userPictureFiles, setUserPictureFiles] = useState([])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (currentCustomer) {
      setUserResumeFiles(currentCustomer.files.filter(file => file.type === 'resume'))
      setUserPictureFiles(currentCustomer.files.filter(file => file.type === 'picture'))
      setIsLoaded(true)
    }
  }, [currentCustomer])

  const onUploadSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    // Stay on files page
    setValue(2)
  }

  const uploadFile = (fileData, inputType) => {
    const file = new FormData();
    file.append('userId', currentCustomer.id)
    file.append('type', inputType)
    file.append('file', fileData)
    uploadCustomerFile(file, currentCustomer.id, onUploadSuccess)
  }

  const deleteFile = (userId, uid) => {
    deleteCustomerFile(
      { userId: parseInt(userId,10), uid },
      userId,
      onUploadSuccess
    )
  }

  const renderForm = () =>
      <Grid container>
        { isLoaded
            ? <div>
              {userResumeFiles?.length > 0
                  ?
                  <Grid item xs={12}>
                    {renderTable()}
                  </Grid>
                  : <Typography variant='body1' className={classes.marginTop}>No Resume</Typography>
              }
            </div>
            : <Grid item xs={12}><LoadingScreen message='Loading...' /></Grid>
        }
      </Grid>

  const renderForm2 = () =>
      <Grid container>
        { isLoaded
            ? <div>
              {userPictureFiles?.length > 0
                  ?
                  <Grid item xs={12}>
                    {renderTable2()}
                  </Grid>
                  : <Typography variant='body1' className={classes.marginBottom}>No Picture</Typography>
              }
            </div>
            : <Grid item xs={12}><LoadingScreen message='Loading...' /></Grid>
        }
      </Grid>

  const renderTable = () =>
      <TableContainer component={Paper} className={classes.notePaper}>
        <Table size="small">

          <TableHead>
            <TableRow>
              <TableCell>Download</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>File Name</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>

            {userResumeFiles.map((resume) =>
                <TableRow key={resume.uid}>
                  <TableCell scope="row">
                    <Tooltip title='Download' placement='top'>
                      <IconButton
                          size="small"
                          onClick={() => getCustomerFile({
                            userId: currentCustomer.id,
                            uid: resume.uid,
                            fileName: `student-resume-${currentCustomer.firstName}_${currentCustomer.lastName}-${currentCustomer?.wechat || 'nowechat'}.${resume?.fileName.split('.').pop()}`
                          })}
                      >
                        <AttachFile fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>

                  <TableCell scope="row">
                    <div className={classes.downloadText}>
                    {moment(resume.createdAt).format('L LT') }
                    </div>
                  </TableCell>

                  <TableCell scope="row">
                    { resume.fileName}
                    </TableCell>

                  <TableCell align="right">
                    <div className={classes.deleteButton}>
                      <Tooltip title='Delete' placement='top'>
                        <IconButton
                            size="small"
                            onClick={() => deleteFile(currentCustomer.id, resume.uid)}
                        >
                          <Delete style={{ color: 'indianred' }} fontSize="small"/>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>

                </TableRow>
            ) }

          </TableBody>
        </Table>
      </TableContainer>


  const renderTable2 = () =>
      <TableContainer component={Paper} className={classes.notePaper}>
        <Table size="small">

          <TableHead>
            <TableRow>
              <TableCell>Download</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>File Name</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>

            {userPictureFiles.map((pic) =>
                <TableRow key={pic.uid}>
                  <TableCell scope="row">
                    <Tooltip title='Download' placement='top'>
                      <IconButton
                          size="small"
                          onClick={() => getCustomerFile({ userId: currentCustomer.id, uid: pic.uid })}
                      >
                        <AttachFile fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>

                  <TableCell scope="row">
                    <div className={classes.downloadText}>
                      {moment(pic.createdAt).format('L LT') }
                    </div>
                  </TableCell>

                  <TableCell scope="row">
                    { pic.fileName}
                  </TableCell>

                  <TableCell align="right">
                    <div className={classes.deleteButton}>
                      <Tooltip title='Delete' placement='top'>
                        <IconButton
                            size="small"
                            onClick={() => deleteFile(currentCustomer.id, pic.uid)}
                        >
                          <Delete style={{ color: 'indianred' }} fontSize="small"/>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>

                </TableRow>
            ) }

          </TableBody>
        </Table>
      </TableContainer>

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper__form}>
        <AppBar position='static' color='default'>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
          >
            <Tab label='Orders' {...a11yProps(0)} />
            <Tab label='Progress' {...a11yProps(1)} />
            <Tab label='Files' {...a11yProps(2)} />
            <Tab label='Payment' {...a11yProps(3)} />
            <Tab label='Notes' {...a11yProps(4)} />
            <Tab label='Company' {...a11yProps(5)} />

          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <EditCustomerOrder currentCustomer={currentCustomer}/>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Paper className={classes.paper__form}>
            <Typography className={classes.form__title} variant='h2' align='center'>
              Progress
            </Typography>
            {progress.map((p,i) => (
                <Typography variant='h6' align='left' key={i} className={classes.p2}>
                  {p} <br />
                </Typography>
              ))}

          </Paper>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Paper className={classes.paper__form}>

            <Grid container direction='row'>
              <Grid item xs={12}>
                <Typography variant='h6' align='left' className={classes.p2}>
                  Resume
                  <label htmlFor='raised-button-file'>
                    <input
                      accept='*'
                      style={{ display: 'none' }}
                      id='raised-button-file'
                      type='file'
                      onChange={(e) => uploadFile(e.target.files[0], 'resume')}
                    />
                    <Button component='span'>
                      <PublishIcon />
                      upload
                    </Button>
                  </label>
                </Typography>
              </Grid>
              { renderForm() }
            </Grid>

            <Grid container direction='row'>
              <Grid item xs={12}>
                <Typography variant='h6' align='left' className={classes.p2}>
                  Picture
                  <label htmlFor='file-picture'>
                    <input
                      accept='*'
                      style={{ display: 'none' }}
                      id='file-picture'
                      type='file'
                      onChange={(e) => uploadFile(e.target.files[0], 'picture')}
                    />
                    <Button component='span'>
                      <PublishIcon />
                      upload
                    </Button>
                  </label>
                </Typography>
              </Grid>

              { renderForm2() }


            </Grid>

          </Paper>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <EditCustomerPayment currentCustomer={currentCustomer}/>
        </TabPanel>

        <TabPanel value={value} index={4}>
          <EditCustomerNotes currentCustomer={currentCustomer}/>
        </TabPanel>

        <TabPanel value={value} index={5}>
          <EditCustomerCompany currentCustomer={currentCustomer} />
        </TabPanel>

      </Paper>
    </Grid>

  );
}

const mapStateToProps = (state) => ({
  sales: state.auth.user,
  currentCustomer: state.customer?.currentCustomer
});

export default connect(mapStateToProps, {
  uploadCustomerFile,
  getCustomerFile,
  deleteCustomerFile,
})(EditCustomerTab);
