import React, {useState, useEffect, cloneElement} from 'react'
import {useTheme} from '@mui/styles'
import './MultiSelectByCallBack.css'
import {Autocomplete} from '@mui/material'

/**
 * A Select that allows input to filter the items available as well as allowing for multiple selections.
 */
const MultiSelectByCallback = ({
    data = [],
    defaultSelected = [],
    placeholder = 'Please select...',
    displayField = 'id',
    keyField = 'id',
    callback = () => {},
    disabled = false,
    autoClose = false
}) => {
    const theme = useTheme()

    const [dataStorage, setDataStorage] = useState(data)
    const [valueStorage, setValueStorage] = useState([])

    useEffect(() => {
        setDataStorage(data)
        setValueStorage(defaultSelected || [])
    }, [data, defaultSelected])

    const filterChange = (event, value) => {
        const newData = data.filter(item => item[displayField].toLowerCase().includes(value.toLowerCase()))
        setDataStorage(newData)
    }

    const handleChange = e => {
        setValueStorage(e.value)
        callback(e.value)
    }

    const itemRender = (li, itemProps) => {
        const {selected} = itemProps
        const style = selected
            ? {
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 'bold',
                  color: theme.palette.primary.main
              }
            : {fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}
        const itemChildren = <span style={style}>{li.props.children}</span>
        return cloneElement(li, li.props, itemChildren)
    }

    const renderView = () => (
        <Autocomplete
            multiple
            options={dataStorage}
            dataItemKey={keyField}
            placeholder={placeholder}
            filterable
            autoClose={autoClose}
            disabled={disabled}
            onFilterChange={filterChange}
            textField={displayField}
            value={valueStorage}
            onChange={handleChange}
            itemRender={itemRender}
        />
    )

    return renderView()
}

export default MultiSelectByCallback
