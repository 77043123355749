import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  navButtons: {
    marginTop: theme.spacing(6)
  }
}));

function HorizontalLinearStepper(props) {

  const {steps, stepContent, onComplete,
    showBackButton=true, showResetButton=false, showButtons=true,
    currentStep=0
  } = props

  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(currentStep)
  const [skipped, setSkipped] = useState(new Set())

  useEffect(() => {
    // callback when done
    if (activeStep === steps.length) {
      onComplete()
    }
  }, [steps, activeStep])

  useEffect(() => {
    setActiveStep(currentStep)
  }, [currentStep])


  const isStepSkipped = (step) => skipped.has(step)

  const handleReset = () => setActiveStep(0)

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps = {}
          const labelProps = {}
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.instructions}>
                Finished
              </Typography>
            </Grid>

            {
              showButtons && showResetButton &&
              <Grid item xs={12}>
                <Grid container justifyContent='flex-end'>
                  <Grid item className={classes.navButtons}>
                    <Button onClick={handleReset} className={classes.button}>
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.instructions}>{stepContent(activeStep)}</div>
            </Grid>

            {
              showButtons &&
              <Grid item xs={12}>
                <Grid container justifyContent='flex-end'>
                  <Grid item className={classes.navButtons}>
                    {
                      showBackButton &&
                      <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    }
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            }

          </Grid>
        )}
      </div>
    </div>
  )
}

HorizontalLinearStepper.propTypes = {
  steps: PropTypes.shape([]).isRequired,
  stepContent: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  showResetButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showButtons: PropTypes.bool,
  currentStep: PropTypes.number
}

HorizontalLinearStepper.defaultProps = {
  showResetButton: false,
  showBackButton: true,
  showButtons: true,
  currentStep:0
}

export default HorizontalLinearStepper
