import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid } from '@mui/material';
import interact from 'interactjs';
import { DEFAULT_COORDS, EMPTY_IMAGE, PIXIE_HEIGHT_OFFSET, usePosterTemplateCreateStyles } from '../../posterGenHelper';
import setupInteractable from '../../../../../components/misc/InteractableComponent';
import MergeImages from '../../../../../utils/mergeImages/mergeImages';
import { getTotalHeight } from '../../../../../utils/Scripts';

const PosterSetImageView = ({ loadImage, baseImage,
                              handleStepBack, handleStepComplete,
                              originalData={}, isEdit=false }) => {

  const classes = usePosterTemplateCreateStyles()

  const posterCanvasRef = useRef(null)
  const posterWrapperRef = useRef(null)
  const loadImageBoxRef = useRef(null)

  const [isLoaded, setIsLoaded] = useState(false)
  const [expandHeight, setExpandHeight] = useState(800)

  const [baseImageSize, setBaseImageSize] = useState({ width: 0, height: 0 })
  const [imageScale, setImageScale] = useState(1.0)
  const [loadImageCoords, setLoadImageCoords] = useState(DEFAULT_COORDS)

  const [previewStyle, setPreviewStyle] = useState({ top: 0, left: 0, width: 0, height: 0 })
  const [previewImage, setPreviewImage] = useState(EMPTY_IMAGE)

  useEffect(() => {
    // see if this is in editing mode
    if (isEdit && isLoaded && originalData) {
      setLoadImageCoords(originalData)
      showPreviewCoordinates(originalData)
    }
  }, [isEdit, originalData, isLoaded])

  const handlePrevious = () => handleStepBack()
  const handleNext = async () => {
    const mergedImage = await MergeImages(
      [{ src: baseImage }, {
          src: previewImage, x: loadImageCoords['1'].x, y: loadImageCoords['1'].y,
          width: loadImageCoords['2'].x - loadImageCoords['1'].x, height: loadImageCoords['2'].y - loadImageCoords['1'].y
        }], {
        crossOrigin: 'Anonymous',
        width: baseImageSize.width,
        height: baseImageSize.height
      }
    )

    setPreviewImage(EMPTY_IMAGE)
    const data = { coords: loadImageCoords, mergedImage }
    handleStepComplete(data)
  }

  const handleSkipNext = async () => {
    // This merge step is skipped when there are no images
    const data = { coords: null, mergedImage: baseImage }
    handleStepComplete(data)
  }

  const locSetDrag = (e) => {

    // Canvas location
    const canvasBounds = posterCanvasRef.current.getBoundingClientRect()
    // QR Code location
    const bounds = e.currentTarget.getBoundingClientRect()

    // Top Left
    const pos1X = Math.round((bounds.left - canvasBounds.left) / imageScale)
    const pos1Y = Math.round((bounds.top - canvasBounds.top) / imageScale)

    // Bottom Right
    const pos2X = Math.round((bounds.left + bounds.width - canvasBounds.left) / imageScale)
    const pos2Y = Math.round((bounds.top + bounds.height - canvasBounds.top) / imageScale)

    // Reset QR moved transformation and data (data save upon releasing pointer)
    e.target.setAttribute('data-x', 0)
    e.target.setAttribute('data-y', 0)
    e.target.style.webkitTransform =`translate(${  0  }px,${  0  }px)`
    e.target.style.transform =`translate(${  0  }px,${  0  }px)`

    setLoadImageCoords({ 1: { x: pos1X, y: pos1Y }, 2: { x: pos2X, y: pos2Y } })

    showPreviewCoordinates({ 1: { x: pos1X, y: pos1Y }, 2: { x: pos2X, y: pos2Y } })
  }

  const showPreviewCoordinates = (forceData) => {
    let width
    let height
    let top
    let left

    // ForceData
    // true = sent by moving qr code; false = clicked preview button
    if (forceData) {
      width = ((forceData['2'].x - forceData['1'].x) * imageScale)
      height = ((forceData['2'].y - forceData['1'].y) * imageScale)
      top = (forceData['1'].y * imageScale)
      left = (forceData['1'].x * imageScale)
    } else {
      width = ((loadImageCoords['2'].x - loadImageCoords['1'].x) * imageScale)
      height = ((loadImageCoords['2'].y - loadImageCoords['1'].y) * imageScale)
      top = (loadImageCoords['1'].y * imageScale)
      left = (loadImageCoords['1'].x * imageScale)
    }

    setPreviewStyle({ top, left, width, height })
  }

  useEffect(() => {
    const runOnStart = async () => {
      // Obtain refs
      if (posterCanvasRef.current) {
        if (loadImage?.length > 0) {
          // Has this particular image (incoming logo or qr code image)
          const tempImageForProp = new Image()
          tempImageForProp.src = loadImage
          tempImageForProp.onload = (() => {
            const imageSize = {
              width: tempImageForProp.naturalWidth,
              height: tempImageForProp.naturalHeight
            }
            setPreviewImage(loadImage)
            b64ToPreview(baseImage, imageSize)
          })
        } else {
          // does not have this image to set, move on to next step
          await handleSkipNext()
        }
      }
    }
    runOnStart().then()
  }, [posterCanvasRef, loadImage, baseImage])

  const b64ToPreview = (b64file, previewImageSize) => {
    // set up the sticker
    let stickerSize = previewImageSize
    let stickerScale = 1

    // generate the poster image and insert into canvas
    const img = new Image()
    img.src = b64file
    img.onload = (() => {
      const canvas = posterCanvasRef.current
      const context = canvas.getContext('2d')

      // Calculate scale based on width first
      let maxWidth = posterWrapperRef.current.getBoundingClientRect().width - 50
      let scale = maxWidth / img.naturalWidth

      // Test if height is too big after scale
      if (getTotalHeight() - PIXIE_HEIGHT_OFFSET < img.naturalHeight * scale) {
        // adjust scale to base off of height rather than width
        scale = (getTotalHeight() - PIXIE_HEIGHT_OFFSET) / img.naturalHeight
        maxWidth = img.naturalWidth * scale
      }

      setImageScale(scale)
      setBaseImageSize({ width: img.naturalWidth, height: img.naturalHeight })

      canvas.width = maxWidth
      canvas.height = img.naturalHeight * scale
      context.drawImage(img, 0, 0, img.naturalWidth * scale, img.naturalHeight * scale)

      setExpandHeight(canvas.height + 100)

      if (!isEdit) {
        // test if sticker is larger than the canvas size
        if (stickerSize.width > canvas.width) {
          // readjust width
          stickerScale = canvas.width / stickerSize.width
          stickerSize = ({
            height: stickerSize.height * stickerScale,
            width: stickerSize.width * stickerScale,
          })
        }

        if (stickerSize.height > canvas.height) {
          // readjust height
          stickerScale = canvas.height / stickerSize.height
          stickerSize = ({
            height: stickerSize.height * stickerScale,
            width: stickerSize.width * stickerScale,
          })
        }

        // Actual coordinates
        setLoadImageCoords({
          1: { x: 0, y: 0 },
          2: {
            x: Math.round(stickerSize.width / scale),
            y: Math.round(stickerSize.height / scale)
          }
        })

        // Preview coordinates
        showPreviewCoordinates({
          1: { x: 0, y: 0 },
          2: {
            x: Math.round(stickerSize.width),
            y: Math.round(stickerSize.height)
          }
        })
      }
      setIsLoaded(true)
    })
  }

  useEffect(() => {
    // when QR code div is loaded
    if (loadImageBoxRef?.current && posterCanvasRef.current) {
      const c = interact(loadImageBoxRef?.current)
      setupInteractable(c, locSetDrag)
    }
  }, [loadImageBoxRef, posterCanvasRef, imageScale, locSetDrag])

  const renderView = () =>
    <Grid container spacing={5} direction='row' justifyContent='center' alignItems='flex-start'>
      <Grid item xs={12} ref={posterWrapperRef} style={{ height: expandHeight }}>

        <Grid container spacing={1} direction='column' alignItems='center'>

          <Grid item xs={12}>
            <div className={classes.stiffPoster}>
              <div
                ref={loadImageBoxRef}
                className={classes.posterQRBox}
                style={{ display: 'block' , ...previewStyle }}
              >
                <img src={previewImage}
                     style={{ width: '100%', height: '100%' }}
                     alt='move this to where you want to place the logo'/>
              </div>
              <canvas id='posterCanvas' width={300} height={500} ref={posterCanvasRef} style={{ zIndex: 1 }}/>
            </div>

          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent='space-between'>
        <Grid item>
          <Button
            color='primary'
            variant='contained'
            onClick={handlePrevious}>
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Button
            color='primary'
            variant='contained'
            onClick={handleNext}>
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>

  return renderView()
}


export default PosterSetImageView
