import {
  Button,
  ButtonGroup, IconButton,
  InputLabel,
  MenuItem, Paper,
  Select, Tooltip
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { Delete, ListAlt } from '@mui/icons-material';
import { Edit } from 'react-feather';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import useAuthCheck from '../../../hooks/useAuthCheck';
import { sendErrorMessage, sendSuccessMessage } from '../../../actions';
import {
  changeSetStatus,
  deleteQRCodeSet,
  fetchAllQRCodeSets,
  fetchQRCodeCategories
} from '../../../actions/qrcode/qrcodeAction';
import { Functions } from '../../../utils/Functions';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { QRCODE_STATUS, useQRCodeListStyles } from '../qrcodeHelper';
import ViewHead from '../../../components/public/ViewHead';
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog';
import { CATEGORY_ITEMS } from '../../../constants';
import PageGrid from '../../../components/mui/datagrid/PageGrid';
import DraggableDialog from "../../../components/mui/dialog/DraggableDialog";
import QRCodeDetailsView from "../qrcodeDetails";
import EditQRCodeSetViw from "./editor/editQRCodeSetView";
import SearchBox from '../../../components/public/SearchBox';

function QRCodeListView() {
useCurrentViewCheck(Functions.QRCode_Set_List)
  const  navigate = useNavigate();
  const [ canViewDetails, canAddQRSet, canUpdateQRSet ] = useAuthCheck(([
    Functions.QRCode_Code,
    Functions.QRCode_Set_Add,
    Functions.QRCode_Set_Edit,
  ]))

  const classes = useQRCodeListStyles()
  const dispatch = useDispatch()

  const allQRCodeSets = useSelector(state => state.qrcode.allQRCodeSets)
  const allCategories = useSelector(state => state.qrcode.allCategories)
  const setFetchProps = useSelector(state => state.qrcode.setFetchProps)

  const [selectedCat, setSelectedCat] = useState(CATEGORY_ITEMS.all)

  const [checkboxSelected, setCheckboxSelected] = useState([])

  const [hash, setHash] = useState('init')
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
  const [selectedSetForDel, setSelectedSetForDel] = useState(-1)
  const [clickedRow, setClickedRow] = useState("")
  const [openDetails, setOpenDetails] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // fetch params
  const [searchArgs, setSearchArgs] = useState({
    searchKey: undefined,
    category: CATEGORY_ITEMS.all,
  })

  const loadData = async () => {
    await fetchQRCodeCategories()(dispatch)
  }
  useEffect(()=>{loadData()}, [])

  const handleDeleteOpen = (id) => {
    setSelectedSetForDel(id)
    setOpenDeleteDialogue(true)
    setHash(Math.random().toString(16).substring(2, 10));
  }

  const onDeleteSuccess = (msg) => sendSuccessMessage(dispatch, msg)
  const onDeleteError = (msg) => sendErrorMessage(dispatch, msg)
  const onDeleteSet = () => {
    deleteQRCodeSet(selectedSetForDel, setFetchProps,
      (msg) => onDeleteSuccess(msg),
      (msg) => onDeleteError(msg))
    (dispatch)
  }

  const onStatusChange = (isActive) => {
    changeSetStatus(checkboxSelected.map(x => parseInt(x,10)),
      isActive, setFetchProps,
      (msg) => {
        setCheckboxSelected([])
        onDeleteSuccess(msg)
      },
      (msg) => onDeleteError(msg)
    )(dispatch)
  }

  const filterResultsByCat = (cat) =>
    setSearchArgs(prevState => ({ ...prevState, category: cat }))

  const onSearch = (searchVal) => setSearchArgs(prevState =>
    ({ ...prevState, searchKey: searchVal.toLowerCase().trim() }))

  const searchHandler = debounce((searchVal) => {
    onSearch(searchVal)
  }, 500)
  const renderSearchContainer = () => <Grid item xs={12} sm={7} md={9} lg={10}>
      <div className={classes.search}>
        <SearchBox
          placeholder='Search...'
          size='small'
          type='search'
          searchHandler={searchHandler}
          isLoading={isLoading}
        />
      </div>
    </Grid>

  const renderCategorySelector = () => (
      <Grid item xs={12} sm={5} md={3} lg={2}>
        <FormControl className={classes.formControl} size='small'>
          <InputLabel>Category</InputLabel>
          <Select
            value={selectedCat}
            onChange={(e) => {
              setSelectedCat(e.target.value)
              filterResultsByCat(e.target.value)
            }}
          >
            <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
            {/* todo : check if id exists */}
            { allCategories?.map( (c) => <MenuItem key={c.id} value={c.name}>{c.name}</MenuItem> ) }
          </Select>
        </FormControl>
      </Grid>
    )

  const renderStatusButtons = () => (
      <Grid item className={classes.activityButtons}
            xs={12}>
        <ButtonGroup variant='contained' color='primary' size='small'>
          <Button
            disabled={checkboxSelected.length <= 0}
            onClick={() => onStatusChange(true)}>
            Enable
          </Button>
          <Button
            disabled={checkboxSelected.length <= 0}
            onClick={() => onStatusChange(false)}>
            Disable
          </Button>
        </ButtonGroup>
      </Grid>
    )

  const renderTopToolbar = () => <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
      { renderSearchContainer() }
      { renderCategorySelector() }
      { canUpdateQRSet ? renderStatusButtons() : <div /> }
    </Grid>

  const renderGrid = () => {
    const columns = []

    if (canViewDetails) {
      columns.push({
        field: '_view', headerName: ' ', resizable: false,
        disableClickEventBubbling: true, width: 60,
        filterable: false, renderCell: (params) =>
          <Tooltip title='Details' placement='top'>
            <IconButton color='primary'
                        size='small'
                        component='span'
                        onClick={async () => {
                          await setClickedRow(params.row.id);
                          await setOpenDetails(true);
                        }
                            // navigate(`/app/qr/view/${params.row.id}`)
            }>
              <ListAlt size={20} />
            </IconButton>
          </Tooltip>
      })
    }

    if (canUpdateQRSet) {
      columns.push({
        field: '_delete', headerName: ' ', resizable: false,
        disableClickEventBubbling: true, width: 75,
        filterable: false, renderCell: (params) => (
            <div>
              <Tooltip title='Edit' placement='top'>
                <IconButton color='primary'
                            size='small'
                            component='span'
                            onClick={async () => {
                              await setClickedRow(params.row.id);
                              await setOpenEdit(true);
                            }
                                // navigate(`/app/qr/edit/${params.row.id}`)
                }>
                  <Edit size={20} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Delete' placement='top'>
                <IconButton color='primary'
                            size='small'
                            component='span'
                            onClick={() => handleDeleteOpen(params.row.id)}>
                  <Delete size={20} />
                </IconButton>
              </Tooltip>
            </div>
          )
      })
    }
    columns.push(...[
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'name', headerName: 'Name', width: 270,
        renderCell: params => params.row.isActive ? params.row.name : <del>{params.row.name}</del> },
      { field: 'category', headerName: 'Category', width: 180, valueFormatter: ({ value }) => value ? value.name : ''},
      { field: 'status', headerName: 'Method', width: 150, valueFormatter: ({ value }) => QRCODE_STATUS.find(s => s.symbol === value).name },
      { field: 'createdAt', headerName: 'Date', width: 200, valueFormatter: ({ value }) => moment(value).format('L LT')},
      { field: 'scans', headerName: 'Scans', width: 100 },
      { field: 'description', headerName: 'Description', width: 350 },
    ])

    return <PageGrid columns={columns}
                     datas={allQRCodeSets}
                     fetchDatas={fetchAllQRCodeSets}
                     searchArgs={searchArgs}
                     defaultSortColumn='id'
                     selected={checkboxSelected}
                     setSelected={setCheckboxSelected}
                     setIsLoading={setIsLoading}
    />
  }

  const renderAddNewButton = () => (
      <div>
        {canAddQRSet
          ? <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate('/app/qr/add')}>
            Add New
          </Button>
          : ''
        }
      </div>
    )

  return (
    <ViewHead functionId={Functions.QRCode_Set_List} topButtons={renderAddNewButton()} noMaxWidth>
      <Paper elevation={0} className={classes.paper}>
        { renderTopToolbar() }
        { renderGrid() }
      </Paper>
      <ResponsiveDialog
        isOpen={openDeleteDialogue} openHash={hash}
        title="Delete QR Code Set" content={`Delete QR Code Set #${selectedSetForDel}?`}
        Buttons={
          [
            { name: "Yes", event: () => onDeleteSet() },
            { name: "Cancel", event: () => setSelectedSetForDel(-1) }
          ]
        }
      />

      <DraggableDialog open={Boolean(openDetails)} onClose={() => setOpenDetails(null)}
                       title='View QR Code' isLoaded={Boolean(openDetails)}>
        <Paper elevation={0}>
          <QRCodeDetailsView qrId={clickedRow}/>
        </Paper>

      </DraggableDialog>

      <DraggableDialog open={Boolean(openEdit)} onClose={() => setOpenEdit(null)}
                       title='Edit QR Code' isLoaded={Boolean(openEdit)}>
        <Paper elevation={0}>
          <EditQRCodeSetViw qrId={clickedRow}/>
        </Paper>

      </DraggableDialog>
    </ViewHead>
  )
}

export default QRCodeListView
