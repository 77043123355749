import { MatricActionType } from '../../actions';

const defaultState = {
  allAlertedPosts: [],

  allKeywords: [],
  monitorParams: {},
  topUpParams: {},

  showDisabledKeywords: false,
  currentMonitorIndexTab: 0,
  currentMonitorDataTab: 0,
  tableRowsPerPage: 10,

  currentTopUpIndexTab: 0,
  currentTopUpStatusTab: 0,
  accountExpandStatus: [],
  currentTopUpUser: {},
  allTopUpUsers: [],

  currentTopUpPost: {},
  allTopUpPosts: [],
}

export const matricReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case MatricActionType.FetchAllAlertedPosts:
      return { ...state, allAlertedPosts: action.payload }

    case MatricActionType.FetchAllKeywords:
      return { ...state, allKeywords: action.payload }

    case MatricActionType.FetchMonitorParams:
      return { ...state, monitorParams: action.payload }
    case MatricActionType.FetchTopUpParams:
      return { ...state, topUpParams: action.payload }

    case MatricActionType.ChangeShowDisabledKeywords:
      return { ...state, showDisabledKeywords: action.payload }
    case MatricActionType.ChangeMonitorIndexTab:
      return { ...state, currentMonitorIndexTab: action.payload }
    case MatricActionType.ChangeMonitorDataTab:
      return { ...state, currentMonitorDataTab: action.payload }

    case MatricActionType.FetchTopUpUser:
      return { ...state, currentTopUpUser: action.payload }
    case MatricActionType.FetchAllTopUpUsers:
      return { ...state, allTopUpUsers: action.payload }

    case MatricActionType.ChangeTopUpIndexTab:
      return { ...state, currentTopUpIndexTab: action.payload }
    case MatricActionType.ChangeTopUpStatusTab:
      return { ...state, currentTopUpStatusTab: action.payload }

    case MatricActionType.FetchTopUpPost:
      return { ...state, currentTopUpPost: action.payload }
    case MatricActionType.FetchTopUpPosts:
      return { ...state, allTopUpPosts: action.payload }

    default:
      return state
  }
}
export default matricReducer
