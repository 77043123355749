import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  Grid, InputLabel,
  MenuItem,
  Paper, Select,
  TextField,
  Typography
, Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Archive, Clipboard, Edit as EditIcon, PenTool, Video } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { noteField, textField, useEditCustomerStyle } from '../customerData';
import EditCustomerTab from './EditCustomerTab';
import WaitingOnly from '../../../components/public/WaitingOnly';
import {
  fetchClassTransferByCustomerId
} from '../../../actions/class/classActions';
import {
  customerActionsType, fetchAllRoles, fetchCustomerContract,
  fetchCustomerInterview, fetchProducts,
  sendErrorMessage,
  updateCustomer
} from '../../../actions';
import useAuthCheck from '../../../hooks/useAuthCheck';
import { Functions } from '../../../utils/Functions';
import { copyToClipboard } from '../../../utils/Scripts';
import { copyBoardStyle, USER_ROLE_ID } from '../../../constants';
import ClassChangeHistory from '../../training/class/ClassChangeHistory'

const schema = yup.object().shape({
  firstName: yup.string().required().min(2).trim(),
  lastName: yup.string().required().min(2).trim(),
  country: yup.string().required('Country is required').trim(),
  address: yup.string().required('Address is required').trim(),
  email: yup.string().email().required().trim().lowercase(),
  phone: yup.string().required().min(10).max(11).trim(),
  class: yup.array().min(1).max(3)
});


function CheckCustomer({
                         onFloating
                       }) {
  const  navigate = useNavigate();
    const currentCustomer = useSelector(state => state.customer?.currentCustomer)
    const products = useSelector(state => state.customer.product ? state.customer.product.filter(pr => pr.isActive) : [])
    const latestInterview = useSelector(state => state.customer?.currentCustomerInterview)
    const latestContract = useSelector(state => state.customer?.currentCustomerContract)
    const allUserRoles = useSelector(state => state.customer.allUserRoles)
  const [ canTraceCustomer, canViewContract] = useAuthCheck(
    [
      Functions.Customer_Management_Tracing,
      Functions.Administrative_Contract_List]);


  const [readOnly, setReadOnly] = useState(textField.map(() => true));

  const {id} = currentCustomer;
  const [isSaving, setIsSaving] = useState(false);
  const [selectedCat, setSelectedCat] = useState([USER_ROLE_ID.STUDENT]);
  const [productSelect, setProductSelect] = useState([]);

  // const states = products.map((item) => {
  //   if (currentCustomer.regInfo) {
  //     const customerSplit = currentCustomer.regInfo.split('|');
  //     return !!customerSplit.find(item1 => item1 === item.name);
  //   } return false;
  // });

  // const [currentCustomerDisplayNote, setCurrentCustomerDisplayNote] = useState({
  //   ...currentCustomer,
  //   notes: currentCustomer?.notes[0]?.notes
  // });
  //
  const currentCustomerDisplayNote = {  ...currentCustomer,
    notes: currentCustomer?.notes[0]?.notes}

  const classes = useEditCustomerStyle();
  const { register, errors, handleSubmit, getValues } = useForm(
    {
      resolver: yupResolver(schema),
      reValidateMode: 'onChange',
      defaultValues: currentCustomerDisplayNote
    });
  const dispatch = useDispatch();

  const loadData = () => {
    // only attempt to fetch roles if there are no roles
    if (allUserRoles.length <= 0) {
      fetchAllRoles()(dispatch);
    }
    fetchClassTransferByCustomerId(currentCustomer?.id)(dispatch)
  };
  useEffect(loadData, [dispatch, allUserRoles]);


  const handleSaveSubmit = (formData) => {
    const tempCustomer = currentCustomer;
    Object.keys(formData).forEach((item) => {
      tempCustomer[item] = formData[item];
      return null;
    });
    let regInfo = '';
    formData.class.map((value) => {
      regInfo = regInfo.concat(value, '|');
      return null;
    });

    const { firstName, lastName, email, phone, isActive, country, city, zipcode, address } = tempCustomer;
    const tempCustomer1 = {
      firstName,
      lastName,
      email,
      phone,
      isActive,
      country,
      city,
      zipcode,
      address,
      regInfo,
      userId: tempCustomer.id
    };
    const tempReadOnly = readOnly.map(() => true);
    setReadOnly(tempReadOnly);
    updateCustomer(tempCustomer1, afterSaving, catchError)(dispatch);
    setIsSaving(true);
  };
  const catchError = () => {
    setIsSaving(false);
  };
  const afterSaving = () => {
    setIsSaving(false);
  };
  const handleEdit = (e, index) => {
    const tempReadOnly = [...readOnly];
    tempReadOnly[index] = false;
    setReadOnly(tempReadOnly);
  };

  useEffect(() => {
    fetchProducts();
    fetchCustomerInterview(currentCustomer.uid);
    fetchCustomerContract(currentCustomer.uid);
  }, []);

  const handleChange = () => {

  };


  useEffect(() => {
    if (currentCustomer.roleIds) {
      setSelectedCat(currentCustomer.roleIds.map(item => parseInt(item,10)));
    }
    if (currentCustomer.regInfo) {
      const array = currentCustomer.regInfo.split('|');
      array.pop();
      setProductSelect(array);
    }
  }, [currentCustomer]);

  const onCopy = (name) => {
    const value = getValues(name);
    if (value) {
      copyToClipboard(dispatch, value,
        `${name} ${value} added to clipboard`,
        `${name} ${value} cannot be copied `);
    } else {
      sendErrorMessage(dispatch, `Null ${name} cannot be copied `);
    }
  };
  const onCopyAll = () => {
    const value = getValues();
    const product = currentCustomer.regInfo.slice(0, -1);
    const obj = {
      ...value, company: 'ITLabPro',
      representative: `${currentCustomer.sales.firstName.concat(' ', currentCustomer.sales.lastName)}`,
      products: product,
      category: selectedCat.map(item => allUserRoles.find(item1 => item1.id === item).name).join(', ')
    };
    const keys = Object.keys(obj);
    let result = '';
    keys.forEach((value1 => {
      result = `${result  }${capitalizeFirstLetter(value1)} : ${obj[value1]} \n`;
    }));
    copyToClipboard(dispatch, result, `Information added to clipboard`, `Information cannot be copied `);
  };

  // const findRoles = (id) => {
  //   switch (id) {
  //     case 7:
  //       return 'Student';
  //     case 10:
  //       return 'Customer';
  //     case 11:
  //       return 'Referrer';
  //     case 12:
  //       return 'Cooperator';
  //     default:
  //       return  '';
  //   }
  //   return ''
  // };
  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <Grid container spacing={2}>
      {isSaving ? <WaitingOnly /> : null}
      <Grid item>
        <Paper className={classes.paper__form}>
          <Grid container>
            <Grid item>
              <Typography className={classes.form__title} variant='h2' align='left'>
                {currentCustomer.firstName.concat(' ', currentCustomer.lastName)}
                <Tooltip title="Edit">
                  <IconButton color='primary' component='span' size='small'
                              className={classes.topButtons}
                              onClick={(event) => {
                                event.stopPropagation();
                                dispatch(dispatch({
                                    type: customerActionsType.SetCustomersTableStatus,
                                    payload: 'EDIT'
                                  }
                                ));
                                navigate(`/app/customer/registered/edit/${id}`);
                              }}
                  >
                    <EditIcon size={25} />
                  </IconButton>
                </Tooltip>
                {
                  canViewContract &&
                  <Tooltip title={!latestInterview ? 'No Interview' : 'Latest Interview'}>
                    <IconButton color='primary' component='span' size='small'
                                className={clsx(classes.topButtons, {
                                  [classes.inactiveInterview]: !latestInterview
                                })}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (latestInterview) {
                                    navigate(`/app/customer/interview/review/${currentCustomer.uid}/${latestInterview.id}`);
                                  }
                                }}
                    >
                      <Video size={25} />
                    </IconButton>
                  </Tooltip>
                }
                {canTraceCustomer && <Tooltip title="Tracing">
                  <IconButton color='primary' component='span' size='small'
                              className={classes.topButtons}
                              onClick={(event) => {
                                event.stopPropagation();
                                navigate(`/app/customer/tracing/${currentCustomer.id}`);
                              }}
                  >
                    <Archive size={20} />
                  </IconButton>
                </Tooltip>}
                {
                  canViewContract &&
                  <Tooltip title={!latestContract[0] ? 'No Contract' : 'Latest Contract'}>
                    <IconButton color='primary' component='span' size='small'
                                className={clsx(classes.topButtons, {
                                  [classes.inactiveInterview]: !latestContract[0]
                                })}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (latestContract[0]) {
                                    navigate(`/app/administrative/contract/view/${currentCustomer.id}/${latestContract[0].cid}`);
                                  }
                                }}
                    >
                      <PenTool size={25} />
                    </IconButton>
                  </Tooltip>
                }
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <Tooltip title='Copy Form Value' placement='bottom'>
                <IconButton color='primary'
                            size='small'
                            component='span'
                            onClick={() => onCopyAll()}
                >
                  <Clipboard {...copyBoardStyle} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={12} sm={6}>
              <TextField size='small' fullWidth margin='normal' name='company' id='company'
                         label='From'
                         inputProps={{ readOnly: true, style: { cursor: 'default' } }}
                         className={classes.text}
                         defaultValue="ITLabPro"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField size='small' fullWidth margin='normal' name='sales' id='sales'
                         label='Rep'
                         inputProps={{ readOnly: true, style: { cursor: 'default' } }}
                         className={classes.text}
                         defaultValue={currentCustomer.sales?.firstName.concat(' ', currentCustomer.sales?.lastName)}
              />
            </Grid>
          </Grid>
          <form noValidate onSubmit={handleSubmit(handleSaveSubmit)} style={{ marginBottom: '40px' }}>
            <Grid container spacing={1} justifyContent="space-between">
              {
                textField.map((item,index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <TextField size='small' fullWidth margin='normal' name={item.name} id={item.name}
                                 label={item.name === 'gmail' || item.name === 'gitName' || item.name === 'zipcode' || item.name === 'wechat' ? `${item.label} ` : `${item.label} *`}
                                 autoComplete={item.label} inputRef={register}
                                 error={Boolean(errors[item.name]?.message)}
                                 helperText={errors[item.name]?.message}
                                 onClick={event => handleEdit(event, index)}
                                 className={classes.text}
                                 InputLabelProps={{
                                   shrink: true
                                 }}
                                 InputProps={
                                   {
                                     startAdornment: <Tooltip title='Copy Link' placement='top'>
                                       <IconButton color='primary'
                                                   size='small'
                                                   component='span'
                                                   onClick={() => onCopy(item.name)}
                                       >
                                         <Clipboard {...copyBoardStyle} />
                                       </IconButton>
                                     </Tooltip>,
                                     readOnly: true,
                                     style: { cursor: 'default' }
                                   }
                                 }
                      />
                    </Grid>
                  ))
              }

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth className={classes.categorySelector}>
                  <InputLabel>Role</InputLabel>
                  <Select
                    size='small'
                    multiple
                    inputProps={{ readOnly: true }}
                    value={selectedCat}
                    onChange={(e) => {
                      setSelectedCat(e.target.value);
                    }}
                    renderValue={
                      (selected) => (
                        <div className={classes.chips}>
                          {
                            selected.map((value,index) =>
                              (<Chip key={index}
                                     label={allUserRoles.find(role => role.id.toString() === value.toString())?.name || 'Unknown'} />))
                          }
                        </div>
                      )
                    }
                  >
                    {
                      allUserRoles.map((item) => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))
                    }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth className={classes.categorySelector}>
                  <InputLabel>Please Select Products</InputLabel>
                  <Select
                    size='small'
                    multiple
                    value={productSelect}
                    inputProps={{ readOnly: true }}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value,index) => (
                            <Chip key={index} label={value} className={classes.chip} />
                          ))}
                        </div>)}
                    onChange={handleChange}
                    inputRef={register} name='class' id='class'
                  >
                    {products.map((item,index) => (
                        <MenuItem key={index} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <ClassChangeHistory customerId = {currentCustomer?.id} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ m: 3 }}
                             component='fieldset'
                             variant='standard'>
                  <FormLabel component='legend' required>User Category</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox name='isStaff'
                                  checked={currentCustomer?.isStaff}
                                  inputRef={register} />
                      }
                      label='Staff'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox name='isCustomer'
                                  checked={currentCustomer?.isCustomer}
                                  inputRef={register} />
                      }
                      label='Customer'
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            
          
              {/* note */}
              {
                noteField.map((item,index) => (
                    <Grid item xs={12} sm={12} key={index}>
                      <FormControl fullWidth margin='normal' className={classes.noteField}>
                        <TextField size='small' fullWidth margin='normal' name={item.name} id={item.name}
                                   label={`${item.label}`}
                                   autoComplete='off'
                                   inputRef={register}
                                   error={Boolean(errors[item.name]?.message)}
                                   helperText={errors[item.name]?.message}
                                   multiline
                                   rows={4}
                                   variant='outlined'
                                   className={classes.input}
                                   inputProps={{ readOnly: true }}
                                   InputLabelProps={{
                                     shrink: true
                                   }}
                        />
                      </FormControl>
                    </Grid>
                  ))
              }
            </Grid>
            <FormHelperText error={Boolean(errors.class?.message)}>
              {errors.class?.message}
            </FormHelperText>
            <Grid> </Grid>
          </form>
        </Paper>
      </Grid>
      {onFloating ? '' : <EditCustomerTab />}
    </Grid>
  );
}

// const mapStateToProps = (state) => ({
//   sales: state.auth.user,
//   currentCustomer: state.customer?.currentCustomer,
//   products: state.customer.product ? state.customer.product.filter(pr => pr.isActive) : [],
//   latestInterview: state.customer?.currentCustomerInterview,
//   latestContract: state.customer?.currentCustomerContract,
//   allUserRoles: state.customer.allUserRoles
// });
//
// export default connect(mapStateToProps, {
//   updateCustomer,
//   fetchProducts,
//   fetchCustomerInterview,
//   fetchCustomerContract
// })(CheckCustomer);

export default CheckCustomer
