import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Grid,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip,
  Typography
} from '@mui/material';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import { useNotesStyles } from '../customerData';
import {
    createCustomerNote,
    deleteCustomerNote,
    fetchCustomerNotes,
    sendErrorMessage,
    sendSuccessMessage
} from '../../../actions';
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog';
import LoadingScreen from '../../../components/public/LoadingScreen';
import AddNoteInput from "../../../components/form/NoteInput";

function EditCustomerNotes({
                           currentCustomer,
                           fetchCustomerNotes,
                           createCustomerNote,
                           deleteCustomerNote
                         }) {
  const classes = useNotesStyles()
  const dispatch = useDispatch()
  const allNotes = useSelector(state => state.customer.currentCustomerNotes)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [hash, setHash] = useState(Math.random().toString(16).substring(2, 10))
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
  const [selectedForDel, setSelectedForDel] = useState(-1)


  useEffect(() => {
    if (currentCustomer) {
      (async () => {
        setIsLoaded(fetchCustomerNotes(currentCustomer.id))
      })()
    } else {
      setIsLoaded(false)
    }
  }, [currentCustomer])

  const handleDeleteOpen = (id) => {
    setSelectedForDel(id)
    setOpenDeleteDialogue(true)
    setHash(Math.random().toString(16).substring(2, 10));
  }

  const onAddCB = (id, notes) => {
    setIsSaving(true)
    const cleanNote = notes.trim()
    const hasChanged = cleanNote !== ''
    if (hasChanged) {
      createCustomerNote({ customerId: currentCustomer.id.toString(), notes: cleanNote },
        currentCustomer.id,
        (msg) => onOperationSuccess(msg),
        (msg) => onOperationError(msg))
    }
  }
  const onOperationSuccess = (msg) => {
    setIsSaving(false)
    sendSuccessMessage(dispatch, msg)
  }
  const onOperationError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }

  const onDeleteNote = () => {
    deleteCustomerNote(selectedForDel, currentCustomer.id, onOperationSuccess, onOperationError)
    setSelectedForDel(-1)
    setOpenDeleteDialogue(false)
  }

  const addNewNoteDialogue = () =>
      <Paper className={classes.notePaperAdd}>
        <Typography variant='h4'>Add New Note:</Typography>
        <div>
            {/* <AddCategoryInput cb={onAddCB} id='' name='' label='Notes' */}
          <AddNoteInput cb={onAddCB} id='' name='' label='Notes'
                            placeholder='Enter notes here' disabled={isSaving}/>
        </div>
      </Paper>

  const renderTable = () =>
      <TableContainer component={Paper} className={classes.notePaper}>
        <Table size="small">

          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>By</TableCell>
              <TableCell>Note</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>

            {allNotes.map((note) =>
                <TableRow key={note.id}>
                  <TableCell scope="row">
                    { moment(note.createdAt).format('L LT') }
                  </TableCell>

                  <TableCell scope="row">
                    { note.user.firstName } { note.user.lastName }
                  </TableCell>

                  <TableCell scope="row">
                    { note.notes }
                  </TableCell>

                  <TableCell align="right">
                    <Tooltip title='Delete' placement='top'>
                      <IconButton color='primary'
                                  size='small'
                                  component='span'
                                  onClick={() => handleDeleteOpen(note.id)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>

                </TableRow>
            ) }

          </TableBody>
        </Table>
      </TableContainer>
  const renderForm = () =>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h3'>Notes</Typography>
        </Grid>
        { isLoaded
            ? <div>
              {allNotes?.length > 0
                  ?
                  <Grid item xs={12}>
                    {renderTable()}
                  </Grid>
                  : <Typography variant='body1' className={classes.marginTop}>No notes</Typography>
              }

              <Grid item xs={12}>
                { addNewNoteDialogue() }
              </Grid>
            </div>
            : <Grid item xs={12}><LoadingScreen message='Loading...' /></Grid>
        }

      </Grid>

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper__form}>
          <Paper className={classes.paper}>
            <Container maxWidth="lg">
              { renderForm() }

              <ResponsiveDialog
                  isOpen={openDeleteDialogue} openHash={hash}
                  title="Delete Note" content="Delete this note?"
                  Buttons={
                    [
                      { name: "Yes", event: () => onDeleteNote() },
                      { name: "Cancel", event: () => setSelectedForDel(-1) }
                    ]
                  }
              />

              <div className={classes.marginBottom} id='emptyDivAtTheBottom' />

            </Container>
          </Paper>


      </Paper>
    </Grid>

  );
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
  fetchCustomerNotes,
  createCustomerNote,
  deleteCustomerNote
})(EditCustomerNotes);
