import api from '../../../apis/api';

/**
 * Return the fetched result as per the Pagination requirements.
 * A function for api.post() to match the postArgs criteria to determine which action to take.
 * @param postUrl url of the route to fetch the data
 * @param postArgs args including the search props
 * @param postActionType the action to dispatch to set the postArgs when the page changes
 * @param fallback  fallback function to call with malformed postArgs, can be a simple get function to retrieve basic data
 * @returns {(function(*): Promise<AxiosResponse<any>|fallback>)|*}
 */
export const pagedGridDefaultFetch = (postUrl, postArgs, postActionType, fallback=()=>{}) => async dispatch => {
  if (postArgs?.fetchProps) {
    // Retrieve data with using the props same as the last time
    return api.post(postUrl, postArgs.fetchProps)
  } if (postArgs?.page) {
    // update the fetchProps state
    dispatch({ type: postActionType, payload: postArgs })
    // Retrieve data by page plus other conditions
    return api.post(postUrl, postArgs)
  } 
    return fallback()
  
}
export default pagedGridDefaultFetch