import React from 'react';
import {
  Checkbox,
  FormControlLabel, FormGroup, FormHelperText, FormLabel,
  NativeSelect, Radio, RadioGroup,
  TextField
} from '@mui/material';
import { DEFAULT_SELECT_VALUE } from './formBuilderData';
import AddressAutoComplete from '../../../components/api/AddressAutoComplete';



export const addInputToPreview = (formData,handleAddressCallBack,currentAddress,setCurrentCity,setCurrentCountry) => {

  let defaultSelection;
  const marginLeft = {
    marginLeft: '2rem'
  }

  switch (formData.inputType) {
    case 'text' :
      switch (formData.tag) {
        case 'address':
          return (
            <AddressAutoComplete label={`${formData.label}`}
                                 name={formData.name}
                                 helperText={formData.description}
                                 TextComponent={TextField}
                                 id={formData.name}
                                 required = {formData.isRequired}
                                 handleAddressCallBack = {handleAddressCallBack}
                                 InputLabelProps={{
                                   shrink: true
                                 }}
            />
          )
        case 'university':
          return (
            <AddressAutoComplete label={`${formData.label}`}
                                 name={formData.name}
                                 helperText={formData.description}
                                 TextComponent={TextField}
                                 id={formData.name}
                                 required = {formData.isRequired}
                                 handleAddressCallBack = {()=>null}
                                 InputLabelProps={{
                                   shrink: true
                                 }}
            />
          )

        case 'city':

          return (
            <AddressAutoComplete label={`${formData.label}`}
                                 name={formData.name}
                                 helperText={formData.description}
                                 TextComponent={TextField}
                                 id={formData.name}
                                 required = {formData.isRequired}
                                 handleAddressCallBack = {handleAddressCallBack}
                                 InputLabelProps={{
                                   shrink: true
                                 }}
                                 currentAddress={currentAddress?.city}
            />
          )

        case 'country':
         return(
           <TextField variant='outlined' fullWidth size='small'
                     label={`${formData.label} ${formData.isRequired ? '*' : ''}`}
                     name={formData.name} helperText={formData.description}
                     InputLabelProps={{
                       shrink: true
                     }}
                     onChange={setCurrentCountry}
                     value = {currentAddress?.country}

          />
        )
        default:
          return (
            <TextField variant='outlined' fullWidth size='small'
                       label={`${formData.label} ${formData.isRequired ? '*' : ''}`}
                       name={formData.name} helperText={formData.description}
                       InputLabelProps={{
                         shrink: true
                       }}


            />
          )

      }


    case 'textarea':
      return (
        <TextField variant='outlined' fullWidth size='small'
                   multiline rows={5}
                   label={`${formData.label} ${formData.isRequired ? '*' : ''}`}
                   name={formData.name} helperText={formData.description}
        />
      )
    case 'select':
      defaultSelection = formData.values.find(c => c.isDefaultValue === true)
      return (
        <div>
          <FormLabel component="legend">{formData.label} {formData.isRequired && '*'}</FormLabel>
          <div style={marginLeft}>
            <NativeSelect
              defaultValue={defaultSelection && defaultSelection.value}
            >
              <option value={DEFAULT_SELECT_VALUE}/>
              { formData.values.map((c,i) => <option key={i} value={c.name}>{c.value}</option>) }
            </NativeSelect>
            <FormHelperText>{formData.description}</FormHelperText>
          </div>
        </div>
      )
    case 'radio':
      defaultSelection = formData.values.find(c => c.isDefaultValue === true)
      return (
        <div>
          <FormLabel component="legend">{formData.label} {formData.isRequired && '*'}</FormLabel>
          <div style={marginLeft}>
            <RadioGroup defaultValue={defaultSelection}>
              { formData.values.map((c,i) => (
                <FormControlLabel
                  key={i}
                  value={c.value}
                  control={<Radio name={c.name}/>}
                  label={c.value} />
              ))
              }
            </RadioGroup>
            <FormHelperText>{formData.description}</FormHelperText>
          </div>
        </div>
      )
    case 'check':
      defaultSelection = formData.values.find(c => c.isDefaultValue === true)
      return (
        <div>
          <FormLabel component="legend">{formData.label} {formData.isRequired && '*'}</FormLabel>
          <div style={marginLeft}>
            <FormGroup>
              { formData.values.map((c,i) => (
                <FormControlLabel
                  key={i}
                  control={<Checkbox name={c.name} />}
                  label={c.value}
                />
              ))
              }
            </FormGroup>
            <FormHelperText>{formData.description}</FormHelperText>
          </div>
        </div>
      )
    case 'file':
      return (
        <div>
          <FormLabel component="legend">{formData.label} {formData.isRequired && '*'}</FormLabel>
          <div style={marginLeft}>
            {formData.fileType
              ? <input type="file" name={formData.name} accept={formData.fileType} />
              : <input type="file" name={formData.name} />
            }
            <FormHelperText>{formData.description}</FormHelperText>
          </div>
        </div>
      )

    default:
      return <div />
      // console.log('Unsupported input type')
  }
}
export default addInputToPreview
