import {isBoolean} from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
// import {TreeListBooleanEditor, TreeListTextEditor} from '@progress/kendo-react-treelist'

export const LS_KEY_SYLLABUS_EXPANDED = 'class_syllabus_expanded'

export const TREE_LIST_EXPAND_FIELD = 'expanded' // flag prop for row expansion
export const TREE_LIST_EDIT_FIELD = 'inEdit' // flag prop for row edit
export const TREE_LIST_TYPE_FIELD = 'type' // prop for row type
export const TREE_LIST_CHILD_FIELD = 'child' // prop for child tree
export const TREE_LIST_NUMBER_PREFIX_FIELD = 'prefix' // prop for syllabus's number prefix
export const TREE_LIST_IS_COMPLETE_FIELD = 'isComplete' // prop for syllabus's number prefix
export const TREE_LIST_COMPLETE_DATE = 'dateCompleted' // prop for date completed

export const TREE_NODE_ID_FIELD = 'nodeId' // prop for node Id

export const TREE_ROW_EDIT_FIELD = 'isEditable'
export const SYLLABUS_TYPES = {
    SYLLABUS: 'syllabus',
    TOPICS: 'topics',
    CHAPTERS: 'chapters',
    POINTS: 'points'
}

export const SYLLABUS_TYPES_VALUES = Object.values(SYLLABUS_TYPES)

export const SYLLABUS_DISPLAY_TYPE = {
    VIEW: 'view',
    EDIT: 'edit',
    SELECT_SYLLABUS: 'select_syllabus',
    SELECT_POINT: 'select_point'
}

export const SYLLABUS_MAX_LENGTH = 255

const skipLevel = -2
/**
 * Given a Syllabus object, transform it to a TreeList required data structure;
 *
 * @param data - original Syllabus data structure
 * @param selected - array containing IDs of selected, can still be changed
 * @param completed - array containing IDs of completed, cannot be changed
 * @param completedMap - a mapping of ID to date corresponding to the completed variable above
 * @param expanded - object containing all currently expanded IDs for the corresponding layers
 * @param editId - int containing the current ID that's being edited
 * @param viewType - determines which view this should be in
 * @param parent - leave at null: previous recursive level object
 * @param level - do not change: current recursion depth
 * @param nameChain - do not change: current name of the child node
 * @param skip - do not change: counter to determine whether or not to skip mapping child
 */
export const mapTreeData = (
    data,
    selected,
    completed,
    completedMap,
    expanded,
    editId,
    viewType,
    parent = null,
    level = 0,
    nameChain = '',
    skip = skipLevel
) =>
    data.map((childTemp, idx) => {
        const child = {...childTemp}
        // Using SYLLABUS_TYPES to determine the corresponding level and child object
        const recursionKey = SYLLABUS_TYPES_VALUES
        const currentLevelValue = recursionKey[level]
        // Test and set isExpanded, isEdit, and the current type for bookkeeping
        const isExpand = expanded[currentLevelValue].includes(child.id)
        // To lessen load on frontend re-render
        // skip mapping fields if they are not expended past skipLevel
        const nextSkip = skip === skipLevel ? (isExpand ? skip : skip + 1) : skip + 1
        child[TREE_LIST_EXPAND_FIELD] = isExpand
        child[TREE_LIST_EDIT_FIELD] = isBoolean(editId)
            ? editId
            : child.isNew
            ? true
            : child.id === editId[currentLevelValue]
        child[TREE_LIST_TYPE_FIELD] = currentLevelValue

        if (viewType === SYLLABUS_DISPLAY_TYPE.VIEW) {
            // When in view mode, only want to look at whether or not each point is done
            // that will cause the check to trickle up and check in a following check
            child[TREE_LIST_IS_COMPLETE_FIELD] =
                level === SYLLABUS_TYPES_VALUES.length - 1 ? completed.includes(child.id) : false
        } else {
            // Other modes will check if this is checked normally
            child[TREE_LIST_IS_COMPLETE_FIELD] = child[TREE_LIST_IS_COMPLETE_FIELD] || selected.includes(child.id)
        }

        if (
            level === SYLLABUS_TYPES_VALUES.length - 1 &&
            (viewType === SYLLABUS_DISPLAY_TYPE.VIEW || viewType === SYLLABUS_DISPLAY_TYPE.SELECT_POINT)
        ) {
            // The other modes don't need this info
            // and only shown when on the Point node
            child[TREE_LIST_COMPLETE_DATE] = completedMap[child.id]
        }

        // Only want to show the name in view and select point mode
        // the other modes will have fluctuating Syllabus number
        const shouldShowId = viewType === SYLLABUS_DISPLAY_TYPE.VIEW || viewType === SYLLABUS_DISPLAY_TYPE.SELECT_POINT
        // Name to be displayed next to the name, corresponds to the depth
        const newNameChain = level === 0 ? (shouldShowId ? `${idx + 1}` : 'X') : `${nameChain}.${idx + 1}`
        child[TREE_LIST_NUMBER_PREFIX_FIELD] = newNameChain

        // Bookkeeping parameters for easier access through the tree later
        child.index = idx
        child.maxIndex = data.length
        child.parent =
            !!parent && Object.prototype.hasOwnProperty.call(parent, 'parent') ? [...parent.parent, idx] : [idx]
        child.parentId =
            !!parent && Object.prototype.hasOwnProperty.call(parent, 'parentId')
                ? [...parent.parentId, child.id]
                : [child.id]

        // Test if there are more child in the list and recursively map them
        if (viewType === SYLLABUS_DISPLAY_TYPE.EDIT) {
            // Stop rendering past the collapsed depth to save performance when in editing mode
            // because each keystroke will cause this function to run
            // update: $nextSkip variable requirement is removed since tree will be builded at once
            if (recursionKey.length >= level + 1 && child[recursionKey[level + 1]]?.length > 0) {
                child[TREE_LIST_CHILD_FIELD] = mapTreeData(
                    child[recursionKey[level + 1]],
                    selected,
                    completed,
                    completedMap,
                    expanded,
                    editId,
                    viewType,
                    child,
                    level + 1,
                    newNameChain,
                    nextSkip
                )
            }
        } else if (child[recursionKey[level + 1]]?.length > 0) {
            // Render everything when not in edit mode
            child[TREE_LIST_CHILD_FIELD] = mapTreeData(
                child[recursionKey[level + 1]],
                selected,
                completed,
                completedMap,
                expanded,
                editId,
                viewType,
                child,
                level + 1,
                newNameChain,
                nextSkip
            )

            if (viewType === SYLLABUS_DISPLAY_TYPE.VIEW) {
                // when in view mode, if all the child nodes are done, then the parent node is also done
                const totalChildren = child[TREE_LIST_CHILD_FIELD].length
                const filledChildren = child[TREE_LIST_CHILD_FIELD].filter(
                    innerChild => innerChild[TREE_LIST_IS_COMPLETE_FIELD]
                )

                if (filledChildren.length === totalChildren) {
                    child[TREE_LIST_IS_COMPLETE_FIELD] = true
                }
            }
        }
        return child
    })

export const treeNodeList = (list, datas, depth, maxDepth, nodeId, prefix, parentPath) => {
    datas?.forEach(Item => {
        if (Item[nodeId] === undefined) {
            Item[nodeId] = `${prefix[depth]}-${Item.id}`
            Item.type = prefix[depth]
            if (parentPath === undefined || parentPath === '') parentPath = `${Item[nodeId]}`
            else parentPath = `${parentPath}/${Item[nodeId]}`
            Item.parentPath = parentPath
            list.push(Item)
            if (depth < maxDepth) treeNodeList(list, Item.child, depth + 1, maxDepth, nodeId, prefix, parentPath)
            parentPath = parentPath.substring(0, parentPath.lastIndexOf('/'))
        }
    })
    return list
}

/**
 * Generate a column for the Syllabus TreeList based on the provided SYLLABUS_DISPLAY_TYPE.
 *
 * @param displayType - SYLLABUS_DISPLAY_TYPE
 * @param canEdit - Whether or not the current user can edit the Syllabus
 * @param CommandDeliveryCell - CommandCell for adding to class delivery
 * @param CommandEditCell - CommandCell for editing
 * @returns {*[]} - Column for TreeList
 */
export const generateColumnsForTreeList = (displayType, canEdit, CommandDeliveryCell, CommandEditCell) => {
    const columns = []
    switch (displayType) {
        case SYLLABUS_DISPLAY_TYPE.EDIT:
            columns.push(
                ...[
                    {
                        field: TREE_LIST_NUMBER_PREFIX_FIELD,
                        title: '#',
                        width: 50
                    },
                    {
                        field: 'name',
                        title: 'Name',
                        width: 280,
                        editable: true,
                        expandable: true
                    },
                    {
                        field: 'description',
                        title: 'Description',
                        width: 250,
                        editable: true
                    },
                    {
                        field: 'notes',
                        title: 'Notes',
                        width: 250,
                        editable: true
                    }
                ]
            )
            if (canEdit) {
                columns.push(
                    ...[
                        {
                            field: 'deliver',
                            title: 'Deliver',
                            renderCell: CommandDeliveryCell,
                            width: 100
                        },
                        {
                            field: 'actions',
                            title: 'Actions',
                            renderCell: CommandEditCell,
                            width: 220
                        }
                    ]
                )
            }
            break
        case SYLLABUS_DISPLAY_TYPE.VIEW:
        case SYLLABUS_DISPLAY_TYPE.SELECT_POINT:
            columns.push(
                ...[
                    {
                        field: TREE_LIST_IS_COMPLETE_FIELD,
                        title: '✓',
                        width: 50,
                        editCell: true
                    },
                    {
                        field: TREE_LIST_NUMBER_PREFIX_FIELD,
                        title: '#',
                        width: 50
                    },
                    {
                        field: 'name',
                        title: 'Name',
                        width: 280,
                        expandable: true
                    },
                    {
                        field: TREE_LIST_COMPLETE_DATE,
                        title: 'Date Completed',
                        width: 280
                    },
                    {
                        field: 'description',
                        title: 'Description',
                        width: 250
                    },
                    {
                        field: 'notes',
                        title: 'Notes',
                        width: 250
                    }
                ]
            )
            break
        case SYLLABUS_DISPLAY_TYPE.SELECT_SYLLABUS:
            columns.push(
                ...[
                    {
                        field: TREE_LIST_IS_COMPLETE_FIELD,
                        title: '✓',
                        width: 50,
                        editCell: true
                    },
                    {
                        field: TREE_LIST_NUMBER_PREFIX_FIELD,
                        title: '#',
                        width: 50
                    },
                    {
                        field: 'name',
                        title: 'Name',
                        width: 280,
                        expandable: true
                    },
                    {
                        field: 'description',
                        title: 'Description',
                        width: 250
                    },
                    {
                        field: 'notes',
                        title: 'Notes',
                        width: 250
                    }
                ]
            )
            break
        default:
            columns.push(
                ...[
                    {
                        field: TREE_LIST_NUMBER_PREFIX_FIELD,
                        title: '#',
                        width: 50
                    },
                    {
                        field: 'name',
                        title: 'Name',
                        width: 280,
                        expandable: true
                    },
                    {
                        field: 'description',
                        title: 'Description',
                        width: 250
                    },
                    {
                        field: 'notes',
                        title: 'Notes',
                        width: 250
                    }
                ]
            )
            break
    }
    return columns
}

export const useSyllabusViewStyles = makeStyles(theme => ({
    modalDisplay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '800px',
        margin: '0 auto'
    },
    modalPaper: {
        width: '50vw',
        padding: '2rem',
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        }
    }
}))

export const useTreeListStyles = makeStyles(() => ({
    normalTree: {
        '& .k-text-nowrap': {
            whiteSpace: 'inherit!important'
        }
    },
    syllabusTree: {
        // Only show the syllabus checkbox
        '& .k-master-row[aria-level="2"], & .k-master-row[aria-level="3"], & .k-master-row[aria-level="4"]': {
            '& .k-checkbox': {
                display: 'none'
            }
        }
    },
    pointTree: {
        // Only show the points checkbox
        '& .k-master-row[aria-level="1"], & .k-master-row[aria-level="2"], & .k-master-row[aria-level="3"]': {
            '& .k-checkbox': {
                display: 'none'
            }
        }
    },
    colorTree: {
        // Syllabus
        '& .k-master-row[aria-level="1"]': {
            backgroundColor: '#e0e0ff',
            '&:hover': {
                backgroundColor: '#d8d7ff'
            }
        },
        // Topic
        '& .k-master-row[aria-level="2"]': {
            backgroundColor: '#feffeb',
            '&:hover': {
                backgroundColor: '#feffda'
            }
        },
        // Chapter
        '& .k-master-row[aria-level="3"]': {
            backgroundColor: '#ffe9e9',
            '&:hover': {
                backgroundColor: '#ffdbdb'
            }
        },
        // Point
        '& .k-master-row[aria-level="4"]': {
            backgroundColor: '#e7fffe',
            '&:hover': {
                backgroundColor: '#d6fffe'
            }
        }
    }
}))
