import React from 'react';
import {
    TextField,
    Grid,
    Paper,
    Typography,
    List,
    ListSubheader,
    ListItem,
    ListItemText,
    Divider,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {connect} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
updateOneMessage} from "../../../actions";


const schema=yup.object().shape({
    content: yup.string().required('Required')
        .min(2, 'message must be at least 2 characters')
        .max(255, 'message must be less than 255 characters')
        .trim(),

})

const useStyles=makeStyles((theme)=>({
    smallGrid:{
        margin:'0.5em 0',
    },  form__title: {
        marginBottom: ".5rem",
    },
    paper__form: {
        padding: "1rem 1rem",
        margin: "0 auto",
    },
    name__margin:{
        margin:'20px 0'
    },
    select__margin:{
        padding:'20px',
    },
    label__styles:{
        padding:'20px 10px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 240,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}))
//
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };
//
//
// function getStyles(name, personName, theme) {
//     return {
//         fontWeight:
//             personName.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }



function EditMessage({oneMessage}) {

    const {register, errors} = useForm(
        {
            resolver:yupResolver(schema),
            defaultValues:oneMessage
        }
    )


    const classStyle=useStyles();


/*    const handleChangeStudents=(event)=>{
        setStudentName(event.target.value);
    }

    const handleChangeClasses=(event)=>{
        setClasses(event.target.value)
    }

    const handleChangeRole=(event)=>{
        setRole(event.target.value)
    }

    const onSubmit=(data)=>{
        const data1={...data,students:studentArray}
        const id=oneMessage.id
        if (studentArray.length === 0) {
            setError('classes', { type: 'manual', message: 'Please select classes' });
            return;
        }
        setIsSaving(true);
        updateOneMessage(id,data1,afterSaving,catchError);
    } */


    return(
        <div>
            <Paper className={classStyle.paper__form}>
                {/* <Button variant='contained' color='primary' onClick={()=>{ */}
                {/*    dispatch({type:trainingMessageActionsTypes.SetTrainingMessageFormStatus,payload:'Close'}) */}
                {/* }}>Back</Button> */}
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <Typography className={classStyle.form__title} variant="h2" align="center">Message Details</Typography>
                    </Grid>
                </Grid>
                <form noValidate  >
                    <Grid container >
                        <Grid item xs={12} md={6} className={classStyle.name__margin}>
                    <TextField multiline rows={5} variant="outlined" name='content' label='Message *' inputRef={register}
                      fullWidth    inputProps={{ readOnly: true, style: { cursor: 'default' } }}
                        error={Boolean(errors.content?.message)} helperText={errors.content?.message}
                    />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <List className={classStyle.smallGrid}>
                                <ListSubheader>
                                    Send Message To Students
                                </ListSubheader>
                                {oneMessage.submissions?.map((value)=>(<ListItem key={value.student.id} >
                                        <ListItemText primary={(value.student.id).toString(10).concat('.',value.student.firstName,' ',value.student.lastName)}/>
                                    </ListItem>))}
                                <Divider />
                            </List>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </div>


    )

}

export default connect(({trainingMessage})=>({
    oneMessage: trainingMessage.fetchedOneMessage,
    classes: trainingMessage.classes
}),{updateOneMessage})(EditMessage);
