import _ from 'lodash'
import {Check, Clear} from '@mui/icons-material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {sendErrorMessage} from '../../../actions'
import {VALID_INPUT_TYPES} from '../formBuilder/formBuilderData'
import {fetchFile} from '../../../actions/forms/formActions'

export const handleFileOpen = id => dispatch => {
    fetchFile(id, msg => {
        sendErrorMessage(dispatch, msg)
    })(dispatch)
}

export const getHeaderLabel = (name, isRequired, isUnique) => {
    let tempName = name
    if (isRequired) {
        tempName = `${tempName} *`
    }
    if (isUnique) {
        tempName = `[U] ${tempName}`
    }
    return tempName
}

export const genHoverCell = (form, compName, compType, selectedNames, rowValue) => {
    const currentComponent = form?.components.find(comp => comp.id === compName)
    // console.log(currentComponent)
    // Don't need hover for empty value
    if (
        _.isEmpty(rowValue) ||
        compType === VALID_INPUT_TYPES.text ||
        compType === VALID_INPUT_TYPES.file ||
        !currentComponent
    )
        return ''

    const styles = {
        hoverCell: {
            width: '100%',
            padding: '1rem',
            fontFamily: 'Sans-Serif',
            maxHeight: '50vh',
            overflow: 'auto'
        },
        cellTitle: {marginBottom: '1rem'},
        marginLeft: {marginLeft: '1.25rem'},
        inactive: {
            color: 'darkslateblue',
            position: 'relative',
            bottom: '7px',
            left: '8px'
        },
        active: {
            color: 'green',
            fontWeight: 'bold',
            fontSize: '1.25rem',
            position: 'relative',
            bottom: '6px',
            left: '8px'
        }
    }

    return (
        <div style={styles.hoverCell}>
            <h3 style={styles.cellTitle}>{currentComponent.label}</h3>
            {!_.isEmpty(currentComponent.values) ? (
                <div style={styles.marginLeft}>
                    {currentComponent.values.map(val =>
                        selectedNames.includes(val.id) ? (
                            <div key={val.id}>
                                <Check />
                                <span style={styles.active}>{val.value}</span>
                            </div>
                        ) : (
                            <div key={val.id}>
                                <Clear />
                                <span style={styles.inactive}>{val.value}</span>
                            </div>
                        )
                    )}
                </div>
            ) : (
                rowValue.split('\n').map((row, index) => <p key={index}>{row}</p>)
            )}
        </div>
    )
}

export const LS_KEY_CREATE_CUSTOMER = 'new_user_to_add'
export const addCustomerFromForm = (dispatch, navigate, customerDetails, tableHeaders) => {
    // Filter out the headers that contains a unique tag
    const tagHeaders = tableHeaders.filter(hdr => hdr.tag !== null)
    // Create object to send to be registered
    const usableDetails = Object.keys(customerDetails).reduce((res, current) => {
        const found = tagHeaders.find(th => th.name === current)
        if (found) {
            res[found.tag] = customerDetails[current]
        }
        return res
    }, {})

    // Tracking details
    usableDetails.repName = customerDetails.repUser ?? ''
    usableDetails.repId = customerDetails.repUserId ?? ''
    usableDetails.source = customerDetails.source ?? ''

    // Write details to localstorage
    localStorage.setItem(LS_KEY_CREATE_CUSTOMER, JSON.stringify(usableDetails))

    // change view to registered
    navigate('/app/customer/registered/add')
}

export const useDisplaySubmissionStyles = makeStyles(() => ({
    paper: {
        padding: '2rem',
        marginBottom: '2rem'
    },
    pageTitle: {
        marginBottom: '1rem'
    }
}))
