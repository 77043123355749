import api from '../../apis/api';
import { sendErrorMessage } from '../message/messageActions';

export const resActionType = {
  AddGmail: 'AddGmail',
  FetchResources: 'FetchResources',
  AddResources: 'AddResources',
  AddStudent: 'AddStudent',
  FetchOneResource: 'FetchOneResources',
  DeleteResources: 'DeleteResources'
};

export const addGmail = (signedInUser) => async dispatch => {
  try {
    const result = await api.post('/google/gmail/create', { gmail: signedInUser });
    if (result.data.rs) {
      // create if dont find duplicate
      dispatch({ type: resActionType.AddGmail, payload: result.data });
    } else {
      // list info if record exists
      await listAll(signedInUser)(dispatch);
    }
  } catch (e) {
    sendErrorMessage(dispatch,'Error in addGmail')
    // console.log(e.message);
  }
};

export const listAll = (signedInUser) => async dispatch => {
  try {
    const preData = await api.get(`/google/resources/user/${signedInUser}`);
    if (preData.data.rs) {
      dispatch({ type: resActionType.FetchResources, payload: preData.data.data.result });
    }

  } catch (e) {
    sendErrorMessage(dispatch,'Error in listAll')
    // console.log(e.message);
  }
};

export const addResource = (newItem) => async dispatch => {
  try {
    const result = await api.post('/google/resources/create', newItem);
    if (result.data.rs) {
      dispatch({ type: resActionType.AddResources, payload: result.data.data.result });
    }

  } catch (e) {
    sendErrorMessage(dispatch,'Error in addResource')
    // console.log(e.message);
  }
  return false
};

export const editResource = (url, name) => async () => {
  try {
    const result = await api.put(`google/resources/edit/${url}`, { name });
    if (result.data?.rs) {
      return true;
    }

  } catch (e) {
    return false;
  }
  return false;
};

export const deleteResource = (newItem) => async dispatch => {
  try {
    let res = true
    for(let i = 0; i < newItem.resourcesPermissions.length; i+=1){
      const value = newItem.resourcesPermissions[i]
      res = deletePermission(newItem.gmail.gmail, newItem.url, value.permission)(dispatch);
      if(!res) return false
    }

    const result = await api.put(`/google/resources/delete/${newItem.id}`);
    if (result.data.rs) {
      return true
    }
  } catch (e) {
    sendErrorMessage(dispatch, 'Error in deleteResource')
    // console.log(e.message);
    // return false
  }
  return false
};

export const addStudent = (permissionInfo) => async dispatch => {
  try {
    const result = await api.post(`/google/permissions/create`, permissionInfo);
    if (result.data.rs) {
      dispatch({ type: resActionType.AddStudent, payload: result.data.data });
    }
  } catch (e) {
    sendErrorMessage(dispatch, 'Error in addStudent')
    // console.log(e.message);
  }
  return false
};

export const fetchOneResource = (rid) => async dispatch => {
  try {
    const result = await api.get(`/google/resources/${rid}`);
    if (result.data?.rs) {
      return result.data?.data?.resourcesPermissions;
    }
  } catch (e) {
    sendErrorMessage(dispatch,'Error in fetchOneResource')
    // console.log(e.message);
  }
  return false
};

export const deletePermission = (user, url, permission) => async () => {
  try {
    const result = await api.put(`/google/permissions/delete/${user}/${url}/${permission}`);
    if (result.data?.rs) {
      await listAll(user);
      return true;
    }
    return false
  } catch (e) {
    return false;
  }
};