import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Box, FormControlLabel, FormGroup, Grid, Typography} from '@mui/material'
import EqualSwitch from '../components/mui/bi/EqualSwitch'
import {Functions} from '../utils/Functions'
import ViewHead from '../components/public/ViewHead'
import {fetchBIData} from '../actions/bi/biActions'
import BIContractStackedLineDiagram from '../components/bi/dashboard/BiContractStackedLineDiagram'
import {authCheck, sendErrorMessage, sendMessage, sendSuccessMessage} from '../actions'
import {DEFAULT_APP_TITLE, GroupByTimeUnit} from '../constants'
import timeSpanOptionsMenu from '../components/bi/dashboard/timeSpanOptionsMenu'
import DatePickerComponent, {formatDate} from '../components/misc/DatePickerComponent'
import GroupUnitSelection from '../components/bi/dashboard/GroupUnitSelection'

function DashboardView() {
    const title = 'Contract'

    const [dateRangeFromDatePicker, setDateRangeFromDatePicker] = useState()

    const today = new Date()

    const dashboardDateRanges = [
        {
            label: timeSpanOptionsMenu[0].label, // yesterday
            startDate: formatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)),
            endDate: formatDate(today)
        },
        {
            label: timeSpanOptionsMenu[1].label, // 3 days ago
            startDate: formatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 3)),
            endDate: formatDate(today)
        },
        {
            label: timeSpanOptionsMenu[2].label, // last week
            startDate: formatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)),
            endDate: formatDate(today)
        },
        {
            label: timeSpanOptionsMenu[3].label, // last 3 weeks
            startDate: formatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 21)),
            endDate: formatDate(today)
        },
        {
            label: timeSpanOptionsMenu[4].label, // last month
            startDate: formatDate(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())),
            endDate: formatDate(today)
        },
        {
            label: timeSpanOptionsMenu[5].label, // last 3 months
            startDate: formatDate(new Date(today.getFullYear(), today.getMonth() - 3, today.getDate())),
            endDate: formatDate(today)
        },
        {
            label: timeSpanOptionsMenu[6].label, // last quarter
            startDate: formatDate(new Date(today.getFullYear(), today.getMonth() - 4, today.getDate())),
            endDate: formatDate(today)
        },
        {
            label: timeSpanOptionsMenu[7].label, // last 3 quarters
            startDate: formatDate(new Date(today.getFullYear(), today.getMonth() - 9, today.getDate())),
            endDate: formatDate(today)
        },
        {
            label: timeSpanOptionsMenu[8].label, // last half year
            startDate: formatDate(new Date(today.getFullYear(), today.getMonth() - 6, today.getDate())),
            endDate: formatDate(today)
        },
        {
            label: timeSpanOptionsMenu[9].label, // last year
            startDate: formatDate(new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())),
            endDate: formatDate(today)
        },
        {
            label: timeSpanOptionsMenu[10].label, // last 3 years
            startDate: formatDate(new Date(today.getFullYear() - 3, today.getMonth(), today.getDate())),
            endDate: formatDate(today)
        }
        // custom date range selection is in date picker
    ]

    // set default range here
    const defaultRange = timeSpanOptionsMenu[5].label // last 3 month

    // label name for date picker
    const labelName = 'dashboardView'

    const defaultGroupBy = localStorage.getItem('groupBy')
    // GroupByTimeUnit.MONTH
    const localStorageGroupBy = localStorage.getItem('groupBy') || defaultGroupBy

    const [groupBy, setGroupBy] = useState(localStorageGroupBy)
    // Default timeSpan data
    const defaultTimeSpan = {
        timeSpanDateRange: {
            startDate: '2023-8-01',
            endDate: '2023-10-31'
        }
    }

    useEffect(() => {
        const localStorageDate = localStorage.getItem(`${labelName}-TimeSpan`)
        if (localStorageDate) {
            const temp = [
                dashboardDateRanges.filter(e => e.label === localStorageDate)[0].startDate,
                dashboardDateRanges.filter(e => e.label === localStorageDate)[0].endDate
            ]
            let convertsDate
            if (temp) {
                convertsDate = temp.map(ele => {
                    const [month, day, year] = ele.split('/')
                    let isoDateString = ''
                    if (month && day && year) {
                        isoDateString = `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}T00:00:00.000Z`
                        return new Date(isoDateString).toISOString()
                    }
                    return null
                })
            }

            const startDate = convertsDate[0]
            const endDate = convertsDate[1]
            setTimeSpan({
                timeSpanDateRange: {
                    startDate,
                    endDate
                }
            })
        }
    }, [])

    const [isCountrySelected, setIsCountrySelected] = useState(false)
    const [timeSpan, setTimeSpan] = useState(defaultTimeSpan)

    useEffect(() => {
        if (dateRangeFromDatePicker !== null && dateRangeFromDatePicker !== undefined) {
            if (dateRangeFromDatePicker[0] && dateRangeFromDatePicker[1]) {
                const startDate = dateRangeFromDatePicker[0]
                const endDate = dateRangeFromDatePicker[1]
                const label = dashboardDateRanges.filter(
                    ele => ele.startDate === startDate && ele.endDate === endDate
                )[0]?.label
                const followingGroupBy = timeSpanOptionsMenu.filter(ele => ele.label === label)[0]?.value.groupBy
                setGroupBy(followingGroupBy)
            }
        }
    }, [dateRangeFromDatePicker])

    useEffect(() => {
        // store to localStorage on change
        localStorage.setItem('groupBy', groupBy)
    }, [groupBy])

    useEffect(() => {
        if (dateRangeFromDatePicker !== null && dateRangeFromDatePicker !== undefined) {
            const convertedDates = dateRangeFromDatePicker.map(dateString => {
                if (dateString !== null && dateString !== undefined) {
                    const [month, day, year] = dateString.split('/')
                    let isoDateString = ''
                    if (month && day && year) {
                        isoDateString = `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}T00:00:00.000Z`
                        return new Date(isoDateString).toISOString()
                    }
                }
                return null
            })
            const startDate = convertedDates[0]
            const endDate = convertedDates[1]
            setTimeSpan({
                timeSpanDateRange: {
                    startDate,
                    endDate
                }
            })
        }
    }, [dateRangeFromDatePicker])

    // if custom date range is selected, set group unit accordingly
    useEffect(() => {
        if (
            dateRangeFromDatePicker !== null &&
            dateRangeFromDatePicker !== undefined &&
            Array.isArray(dateRangeFromDatePicker) &&
            dateRangeFromDatePicker[0] !== null &&
            dateRangeFromDatePicker[1] !== null &&
            Array.isArray(dateRangeFromDatePicker) &&
            dateRangeFromDatePicker[0] !== undefined &&
            dateRangeFromDatePicker[1] !== undefined
        ) {
            const newArray = dashboardDateRanges.filter(ele => ele.label !== 'Custom')
            // if end date of dateRangeFromDatePicker is not equal of any end date in dashboardDateRanges excluding 'custom'
            if (newArray.every(ele => ele.endDate !== dateRangeFromDatePicker[1])) {
                const startDate = new Date(dateRangeFromDatePicker[0])
                const endDate = new Date(dateRangeFromDatePicker[1])
                startDate.setHours(0, 0, 0, 0)
                endDate.setHours(0, 0, 0, 0)
                const timeDiff = endDate.getTime() - startDate.getTime()
                const dayDiff = Math.floor(timeDiff / (1000 * 3600 * 24))
                // if date range is no more than 31 days, group unit is Day
                if (dayDiff <= 31) {
                    setGroupBy(GroupByTimeUnit.DAY)
                } else if (dayDiff > 31 && dayDiff <= 365) {
                    setGroupBy(GroupByTimeUnit.MONTH)
                } else {
                    setGroupBy(GroupByTimeUnit.YEAR)
                }
            }
        }
    }, [dateRangeFromDatePicker])

    const [yAxisValueName, setYAxisValueName] = useState('amount')

    const dispatch = useDispatch()
    const onSuccess = msg => {
        // const { timeSpan } = data
        // const msg = `Data from ${timeSpan[0]} to ${timeSpan[timeSpan.length - 1]}`
        sendSuccessMessage(dispatch, msg)
    }
    const onError = error => {
        sendErrorMessage(dispatch, error.message)
    }

    useEffect(() => {
        if (groupBy && timeSpan) {
            fetchBIData({...timeSpan, groupBy, isCountrySelected}, onSuccess, onError)(dispatch)
        }
    }, [timeSpan, groupBy, isCountrySelected])

    useEffect(() => {
        document.title = DEFAULT_APP_TITLE
    }, [])

    // switch to display contract amounts OR turnover
    const handleSwitchYAxisValue = e => {
        const {checked} = e.target
        const yAxisValueName = `${checked ? 'amount' : 'turnover'}`
        setYAxisValueName(yAxisValueName)
    }

    const handleSwitchCountry = e => {
        const {checked} = e.target
        setIsCountrySelected(!checked)
    }

    return (
        <ViewHead functionId={Functions.System_Management_Functions} isShowTitle={false}>
            <Grid container alignItems="center" spacing={1}>
                {/* eCharts title */}
                <Grid item xs={12} sm={6} md={2} sx={{marginBottom: '10px'}}>
                    <FormGroup>
                        <FormControlLabel
                            control={<EqualSwitch defaultChecked onChange={e => handleSwitchYAxisValue(e)} />}
                            label={
                                <Typography
                                    variant="body2"
                                    sx={{color: '#546E7A'}}
                                >{`${title} ${yAxisValueName}`}</Typography>
                            }
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12} sm={6} md={2} sx={{marginBottom: '10px'}}>
                    <FormGroup>
                        <FormControlLabel
                            control={<EqualSwitch defaultChecked onChange={e => handleSwitchCountry(e)} />}
                            label={
                                <Typography variant="body2" sx={{color: '#546E7A'}}>
                                    {isCountrySelected ? 'Show products' : 'Show countries'}
                                </Typography>
                            }
                        />
                    </FormGroup>
                </Grid>

                {/* date picker and group unit picker */}
                <Box sx={{display: 'flex'}}>
                    <Box sx={{marginRight: '20px'}}>
                        <DatePickerComponent
                            labelName={labelName}
                            setDateRangeFromDatePicker={setDateRangeFromDatePicker}
                            defaultRange={defaultRange}
                            props={dashboardDateRanges}
                        />
                    </Box>
                    <Box>
                        <GroupUnitSelection
                            defaultGroupBy={defaultGroupBy}
                            setGroupUnitFromPicker={setGroupBy}
                            groupBy={groupBy}
                        />
                    </Box>
                </Box>
            </Grid>

            <BIContractStackedLineDiagram diagramTitle={`${title} ${yAxisValueName}`} yAxisValueName={yAxisValueName} />
        </ViewHead>
    )
}

export default connect(null, {authCheck, sendMessage})(DashboardView)
