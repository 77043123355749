import React, { useState } from 'react';
import { Box, Fade, Grid, IconButton, ListItem, Popper, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Clipboard, ExternalLink, RefreshCw, Trash, Edit, ChevronsUp } from 'react-feather';
import { InfoOutlined } from '@mui/icons-material';
import { copyToClipboard } from '../../../../utils/Scripts';
import { BBS_SITE_LIST, BROKEN_IMAGE_SRC, POST_NEW_STATUS_TIME, useMatricListItemView } from '../../matricHelper';

function TopUpListItem({ postData, handleEdit, handleDelete, handleRefresh, handleTopUp, handleOpenImage }) {

  const dispatch = useDispatch()
  const classes = useMatricListItemView()

  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget)
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  let keywords = `${ BBS_SITE_LIST.find(c =>
    c.symbol === postData.site).name } > `                    // site name
  keywords += `${ postData.category } > ${ postData.user }`   // category & poster

  const foundTime = moment(postData.foundTime)
  const foundTimeSince = foundTime.fromNow()
  const foundTimeLocal = foundTime.format('L LT')

  const postViews = postData.views || '?'
  const postReplies = postData.replies || '?'

  const postLastToppedUpTime = postData?.topUpTime ? moment(postData.topUpTime) : null
  const postLastToppedUpSince = postLastToppedUpTime ? postLastToppedUpTime.fromNow() : 'Never'
  const postLastToppedUpLocal = postLastToppedUpTime ? postLastToppedUpTime.format('L LT') : 'Never'

  const postRunTime = postData?.startTime ? moment(postData?.startTime) : null
  const postRunTimeSince = postRunTime ? postRunTime.fromNow(true) : 'Disabled'
  const postRunTimeLocal = postRunTime ? postRunTime.format('L LT') : 'Disabled'

  return (
    <ListItem className={clsx(classes.listRoot, {
      [classes.newPostEntrance]: moment().diff(foundTime, 'seconds') <= POST_NEW_STATUS_TIME
    })}>
      <Grid container justifyContent='space-between'>

        {/* Left */}
        <Grid item xs={12} md={8} xl={5}>
          <Tooltip title={postData.isAlive
            ? postData.isActive
              ? postData.status !== 1
                ? 'Top up error'
                : 'Actively running'
              : 'Active but not running'
            : 'Dead'
          }
                   placement='top'>
            <div className={clsx(classes.aliveCircle, {
              'alive': postData.isAlive && postData.isActive && postData.status === 1,
              'disabled': postData.isAlive && !postData.isActive,
              'error': postData.isAlive && postData.status !== 1,
              'dead': !postData.isAlive,
            })} />
          </Tooltip>
          <div className={classes.leftPanel}>
            <Typography
              variant='h5'
              aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
              onMouseEnter={(e) => handlePopoverOpen(e)}
              onMouseLeave={() => handlePopoverClose()}
            >
              { postData.title }
            </Typography>

            <Typography variant='body1'>{ keywords }</Typography>

            <Tooltip title={foundTimeLocal} placement='top'>
              <Typography variant='body2' component='span'>Added:  {foundTimeSince} | </Typography>
            </Tooltip>

            <Tooltip title={postLastToppedUpLocal} placement='top'>
              <Typography variant='body2' component='span'>Last:  {postLastToppedUpSince} | </Typography>
            </Tooltip>

            <Typography variant='body2' component='span'>Timer:  {postData.timer} hour(s) | </Typography>

            <Tooltip title={postRunTimeLocal} placement='top'>
              <Typography variant='body2' component='span'>Running:  {postRunTimeSince}</Typography>
            </Tooltip>
          </div>
        </Grid>

        {/* Right */}
        <Grid item xs={12} md={4} xl={2}>
          <Grid container direction='column' justifyContent='flex-end' alignItems='flex-end'>
            <Grid item>
              <Tooltip title='Top Up Post' placement='top'>
                <IconButton size='small'
                            component='span'
                            onClick={() => handleTopUp({ site: postData.site, id: postData.id })}
                >
                  <ChevronsUp />
                </IconButton>
              </Tooltip>

              <Tooltip title='Edit Post' placement='top'>
                <IconButton size='small'
                            component='span'
                            onClick={() => handleEdit({ site: postData.site, id: postData.id })}
                >
                  <Edit />
                </IconButton>
              </Tooltip>

              <Tooltip title='Delete Post' placement='top'>
                <IconButton size='small'
                            component='span'
                            onClick={() => handleDelete({ site: postData.site, id: postData.id })}
                >
                  <Trash />
                </IconButton>
              </Tooltip>

              <Tooltip title='Open Post' placement='top'>
                <IconButton size='small'
                            component='span'
                            onClick={() => window.open(postData.url, '_blank')}
                >
                  <ExternalLink />
                </IconButton>
              </Tooltip>

              <Tooltip title='Copy Post Link' placement='top'>
                <IconButton size='small'
                            component='span'
                            onClick={() => copyToClipboard(dispatch, postData.url)}
                >
                  <Clipboard />
                </IconButton>
              </Tooltip>

              <Tooltip title='Update' placement='top'>
                <IconButton size='small'
                            component='span'
                            onClick={() => handleRefresh({ site: postData.site, id: postData.id })}
                >
                  <RefreshCw />
                </IconButton>
              </Tooltip>

            </Grid>

            <Grid item>
              <div className={classes.rightPanel}>
                <Typography variant='body2' component='span'>Total: </Typography>
                <Tooltip title={`Replies ${postReplies}; Views: ${postViews}`} placement='top'>
                  <Typography variant='body2' component='span'>
                    {postReplies}/{postViews}
                  </Typography>
                </Tooltip>
                <div className={classes.infoButton}>
                  <Tooltip title={postData.lastAction || 'Something weird happened'} placement='top'>
                    <InfoOutlined fontSize='small'/>
                  </Tooltip>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Right - QR */}
        <Grid item xs={12} xl={4} className={classes.qrPanel}>
          { postData.qr.map((code,index) =>
            <div
              aria-hidden="true"
              onClick={() => handleOpenImage(code)}
            >
              <img key={index} src={code}
                   alt='qr code found within the post'
                   onError={(e) => {
                     e.target.onerror = null
                     e.target.src = BROKEN_IMAGE_SRC
                   }}
              />
            </div>
           ) }
        </Grid>
      </Grid>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement='top'
        disablePortal
        style={{
          zIndex: 9999
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box className={classes.descriptionHover}>
              { postData?.description
                ? postData?.description
                : <Typography className='empty'>No content was found on this post</Typography>
              }
            </Box>
          </Fade>
        )}
      </Popper>
    </ListItem>
  )
}

export default TopUpListItem
