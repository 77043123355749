import React from "react";
import { Navigate } from 'react-router';
import functionRoutes from "./function";
import RegisteredInfo from '../../../views/customer/register';
import OnlineUsers from '../../../views/system/onlineusers';
import Index from '../../../views/system/setting';
import SwitchUser from '../../../views/system/switchUser';

const systemRoutes = [
    {
        path: 'system',
        routes: [
            ...functionRoutes,
            {
                path:'switchUser',
                exact: 'true',
                component:<SwitchUser/>
            },
            {
                path:'setting',
                exact: 'true',
                component:<Index/>
            },
            {
                path:'onlineUsers',
                exact: 'true',
                component:<OnlineUsers/>
            },
            {
                path:'users',
                exact: 'true',
                component:<RegisteredInfo/>
            },
            {
                component:()=><Navigate to="404"/> // this must be the last one
            },

        ]
    },
]

export default systemRoutes;
