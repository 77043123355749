import {connect, useDispatch, useSelector} from 'react-redux'
import {
    Paper,
    TextField,
    Typography,
    Autocomplete,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormHelperText,
    InputAdornment,
    Tooltip
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'
import {useForm} from 'react-hook-form'
import React, {useEffect, useState} from 'react'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import IconButton from '@mui/material/IconButton'
import {Clipboard, Edit, Eye} from 'react-feather'
import _ from 'lodash'
import SubmitButton from '../../components/mui/button/SubmitButton'
import {
    addNewProduct,
    addProductCategories,
    fetchProductCategory,
    fetchProducts,
    ProductsActions,
    sendErrorMessage
} from '../../actions'
import {copyBoardStyle} from '../../constants'
import {copyToClipboard} from '../../utils/Scripts'
import DraggableDialog from '../../components/mui/dialog/DraggableDialog'

const useStyles = makeStyles(() => ({
    root: {},
    paper__form: {
        padding: '1rem 1rem',
        margin: '0 auto'
    },
    form__title: {
        marginBottom: '.5rem'
    },
    add__categories: {
        margin: '2em'
    },
    save__button: {
        margin: '3em 0em'
    },
    text: {
        cursor: 'default'
    }
}))

const schema = yup.object().shape({
    name: yup.string().min(2).max(100).required(),
    price: yup.number().required().max(999999.99).typeError('Please input a correct price'),
    slug: yup.string().min(2).max(100).required(),
    category: yup.string()
})
const defaultValue = {
    name: '',
    price: '',
    slug: '',
    category: '',
    imageUrl:
        'https://images.unsplash.com/photo-1611915387288-fd8d2f5f928b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
}

function ProductEditor({isEdit, cb, fetchProductCategory, editProduct, id, categories, isReadOnly = false}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const defaultValues = isEdit ? {...editProduct, name: _.startCase(editProduct.name)} : defaultValue
    const {register, errors, handleSubmit, setValue} = useForm({
        resolver: yupResolver(schema),
        defaultValues
    })
    const [isReviewed, setIsReviewed] = useState(isEdit ? editProduct?.isReviewed : false)

    const [IsSaving, setIsSaving] = useState(false)

    const [selectedCate, setSelectedCate] = useState(isEdit ? editProduct.category : null)

    const productsProps = useSelector(state => state?.products?.productFetchBody)

    const [modelOpen, setModelOpen] = useState(false)
    const [currentProduct, setCurrentProduct] = useState(isEdit ? editProduct : defaultValue)
    useEffect(() => setValue('isReviewed', isReviewed), [isReviewed])

    const onCopy = targetName => {
        if (targetName === 'category') {
            copyToClipboard(dispatch, currentProduct[targetName]?.name)
        } else {
            copyToClipboard(dispatch, currentProduct[targetName])
        }
    }

    const onsubmit = data => {
        let submissionData = {}

        if (isReviewed && !selectedCate) {
            return sendErrorMessage('Category must be selected for reviewed products')
        }

        let hostList = []
        if (data.hosts && data.hosts !== '') {
            hostList = data.hosts?.trim()?.split('\n')
            hostList = hostList.filter(v => v !== '')
            hostList = hostList.map(v => v.trim().toLowerCase())
            if (hostList.length > 0 && hostList[0] !== '' && !isReviewed) {
                return sendErrorMessage('Category must be selected for reviewed products')
            }
        }

        submissionData = {...data, name: data.name.trim().toLowerCase(), slug: data.slug.trim().toLowerCase()}
        if (selectedCate) {
            const categoryId = selectedCate.id

            submissionData = {...submissionData, categoryId}
            delete submissionData.category
        }

        if (data.imageUrl && data.imageUrl !== '') {
            submissionData = {...submissionData, imageUrl: submissionData.imageUrl.trim()}
        }
        if (data.productUrl && data.productUrl !== '') {
            submissionData = {...submissionData, productUrl: submissionData.productUrl.trim()}
        }

        if (isEdit) {
            cb({...submissionData, isActive: editProduct.isActive, hosts: hostList}, afterSaving, catchError, id)
        } else {
            cb({...submissionData, hosts: hostList}, afterSaving, catchError)
        }
        setIsSaving(true)
        return true
    }

    const catchError = () => {
        setIsSaving(false)
    }

    const afterSaving = () => {
        dispatch(fetchProducts(productsProps))
        setIsSaving(false)
        dispatch({type: ProductsActions.SetProductFormStatus, payload: 'Close'})
    }
    useEffect(() => {
        fetchProductCategory()
    }, [IsSaving])

    const setSlug = value => {
        if (value) {
            const slug = value.toLowerCase()
            const replaced = slug.replace(/ /g, '-')
            setValue('slug', replaced)
        }
    }

    return (
        <div>
            <Paper className={classes.paper__form}>
                {/* <Button variant='contained' color='primary' onClick={()=>dispatch({type:ProductsActions.SetProductFormStatus,payload:'Close'})}>Back</Button> */}
                <Grid container spacing={2}>
                    <Grid item>
                        {isEdit &&
                            (isReadOnly ? (
                                <Typography className={classes.form__title} variant="h2">
                                    {currentProduct?.name}
                                </Typography>
                            ) : (
                                <Typography className={classes.form__title} variant="h2">
                                    {editProduct?.name}
                                </Typography>
                            ))}
                    </Grid>
                    {isReadOnly && (
                        <Grid item>
                            <div>
                                <Tooltip title="Edit" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => cb('edit')}
                                    >
                                        <Edit fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Grid>
                    )}
                </Grid>
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onsubmit)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="name"
                                label="Name"
                                size="small"
                                autoComplete="name"
                                autoFocus
                                required
                                inputRef={register({required: true, maxLength: 30})}
                                error={Boolean(errors.name?.message)}
                                helperText={errors.name?.message}
                                onChange={event => {
                                    setSlug(event.target.value)
                                    setCurrentProduct(v => ({...v, name: event.target.value}))
                                }}
                                InputLabelProps={{shrink: true}}
                                InputProps={
                                    isEdit
                                        ? {
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <Tooltip title="Copy Form Value" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => onCopy('name')}
                                                          >
                                                              <Clipboard {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </InputAdornment>
                                              ),
                                              readOnly: isReadOnly
                                          }
                                        : {}
                                }
                            />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="price"
                                label="Price "
                                size="small"
                                required
                                inputRef={register({required: true})}
                                error={Boolean(errors.price?.message)}
                                helperText={errors.price?.message}
                                InputLabelProps={{shrink: true}}
                                onChange={event => {
                                    setCurrentProduct(v => ({...v, price: event.target.value}))
                                }}
                                InputProps={
                                    isEdit
                                        ? {
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <Tooltip title="Copy Form Value" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => onCopy('price')}
                                                          >
                                                              <Clipboard {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </InputAdornment>
                                              ),
                                              readOnly: isReadOnly
                                          }
                                        : {}
                                }
                            />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="slug"
                                label="Slug "
                                size="small"
                                inputRef={register({required: true})}
                                required
                                error={Boolean(errors.slug?.message)}
                                helperText={errors.slug?.message}
                                InputLabelProps={{shrink: true}}
                                InputProps={
                                    isEdit
                                        ? {
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <Tooltip title="Copy Form Value" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => onCopy('slug')}
                                                          >
                                                              <Clipboard {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </InputAdornment>
                                              ),
                                              readOnly: isReadOnly
                                          }
                                        : {}
                                }
                                onChange={event => {
                                    setCurrentProduct(v => ({...v, slug: event.target.value}))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Autocomplete
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                options={categories}
                                getOptionLabel={option => (option ? option.name : '')}
                                autoSelect
                                onChange={(e, v) => {
                                    setSelectedCate(v)
                                    setCurrentProduct(p => ({...p, category: v}))
                                }}
                                value={selectedCate}
                                noOptionsText="There is no matching option"
                                name="category"
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        label="Category"
                                        InputLabelProps={{shrink: true}}
                                        InputProps={
                                            isEdit
                                                ? {
                                                      ...params.InputProps,
                                                      startAdornment: (
                                                          <InputAdornment position="start">
                                                              <Tooltip title="Copy Form Value" placement="top">
                                                                  <IconButton
                                                                      color="primary"
                                                                      size="small"
                                                                      component="span"
                                                                      onClick={() => onCopy('category')}
                                                                  >
                                                                      <Clipboard {...copyBoardStyle} />
                                                                  </IconButton>
                                                              </Tooltip>
                                                          </InputAdornment>
                                                      ),
                                                      readOnly: isReadOnly
                                                  }
                                                : {
                                                      ...params.InputProps
                                                  }
                                        }
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="imageUrl"
                                id="imageName"
                                label="Image Url"
                                size="small"
                                inputRef={register}
                                InputLabelProps={{shrink: true}}
                                defaultValue={defaultValue.imageUrl}
                                InputProps={
                                    isEdit
                                        ? {
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <Tooltip title="Copy Form Value" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => onCopy('imageUrl')}
                                                          >
                                                              <Clipboard {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title="Preview Image" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => setModelOpen(true)}
                                                          >
                                                              <Eye {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </InputAdornment>
                                              ),
                                              readOnly: isReadOnly
                                          }
                                        : {
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <Tooltip title="Preview Image" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => setModelOpen(true)}
                                                          >
                                                              <Eye {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </InputAdornment>
                                              )
                                          }
                                }
                                onChange={event => {
                                    setCurrentProduct(v => ({...v, imageUrl: event.target.value}))
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="productUrl"
                                id="name"
                                label="Product Url"
                                size="small"
                                inputRef={register}
                                InputLabelProps={{shrink: true}}
                                InputProps={
                                    isEdit
                                        ? {
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <Tooltip title="Copy Form Value" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => onCopy('productUrl')}
                                                          >
                                                              <Clipboard {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </InputAdornment>
                                              ),
                                              readOnly: isReadOnly
                                          }
                                        : {}
                                }
                                onChange={event => {
                                    setCurrentProduct(v => ({...v, productUrl: event.target.value}))
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="description"
                                id="name"
                                label="Description"
                                rows={3}
                                InputLabelProps={{shrink: true}}
                                multiline
                                inputRef={register}
                                InputProps={
                                    isEdit
                                        ? {
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <Tooltip title="Copy Form Value" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => onCopy('description')}
                                                          >
                                                              <Clipboard {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </InputAdornment>
                                              ),
                                              readOnly: isReadOnly
                                          }
                                        : {}
                                }
                                onChange={event => {
                                    setCurrentProduct(v => ({...v, description: event.target.value}))
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} lg={7}>
                            <FormControlLabel
                                inputRef={register}
                                control={
                                    <Checkbox
                                        name="isReviewed"
                                        id="isReviewed"
                                        checked={isReviewed}
                                        disabled={isReadOnly}
                                        onChange={() => setIsReviewed(reviewed => !reviewed)}
                                    />
                                }
                                label="Is it reviewed?"
                            />
                        </Grid>

                        {isReviewed && (
                            <Grid item xs={12} lg={12}>
                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="hosts"
                                        id="name"
                                        label="Allowed Website Hosts"
                                        rows={3}
                                        InputLabelProps={{shrink: true}}
                                        multiline
                                        inputRef={register}
                                        InputProps={
                                            isEdit
                                                ? {
                                                      startAdornment: (
                                                          <InputAdornment position="start">
                                                              <Tooltip title="Copy Form Value" placement="top">
                                                                  <IconButton
                                                                      color="primary"
                                                                      size="small"
                                                                      component="span"
                                                                      onClick={() => onCopy('hosts')}
                                                                  >
                                                                      <Clipboard {...copyBoardStyle} />
                                                                  </IconButton>
                                                              </Tooltip>
                                                          </InputAdornment>
                                                      ),
                                                      readOnly: isReadOnly
                                                  }
                                                : {}
                                        }
                                        onChange={event => {
                                            setCurrentProduct(v => ({...v, hosts: event.target.value}))
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {isReviewed && (
                            <Grid item xs={12} lg={12}>
                                <FormControl>
                                    <FormHelperText id="my-helper-text">
                                        One Hostname Per line, Leave blank to allow full unrestricted access
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        )}
                        {!isReadOnly && (
                            <Grid item container className={classes.save__button}>
                                <Grid xs={6} item>
                                    <SubmitButton
                                        text="Save"
                                        isSavingText="Saving"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        isSaving={IsSaving}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </Paper>
            <DraggableDialog open={modelOpen} onClose={() => setModelOpen(false)} title={currentProduct?.name}>
                <div style={{maxWidth: '100%', maxHeight: '100%', textAlign: 'center'}}>
                    <img
                        src={currentProduct?.imageUrl}
                        width="70%"
                        height="60%"
                        style={{objectFit: 'fill'}}
                        alt={currentProduct?.name}
                    />
                </div>
            </DraggableDialog>
        </div>
    )
}

export default connect(
    ({products}) => ({
        categories: products.productCategories
    }),
    {addNewProduct, fetchProductCategory, addProductCategories}
)(ProductEditor)
