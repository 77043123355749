import React, {Fragment} from 'react';
import { Navigate } from 'react-router-dom';
import { useRoutes } from 'react-router';
import authRoutes from "./auth.routes";
import customersRoutes from "./noAuthGuard/customers.routes";
import appRoutes from "./app/index.routes";
import RootView from "../views/RootView";
import NotFoundView from '../views/errors/NotFoundView';
import SystemErrorView from '../views/errors/SystemErrorView';

// export const renderRoutes = (routes = []) => (
//     <Suspense fallback={<LoadingScreen/>}>
//         <Switch>
//             {routes.map((route, i) => {
//                 const Guard = route.guard || Fragment;
//                 const Layout = route.layout || Fragment;
//                 const Component = route.component;
//
//                 return (
//                     <Route
//                         key={i}
//                         path={route.path}
//                         exact={route.exact}
//                         render={(props) => (
//                             <Guard>
//                                 <Layout>
//                                     {route.routes
//                                         ? renderRoutes(route.routes)
//                                         : <Component {...props} />}
//                                 </Layout>
//                             </Guard>
//                         )}
//                     />
//                 );
//             })}
//         </Switch>
//     </Suspense>
// );

export const renderRoutes = (routes = [],
                             guard=Fragment ,
                             layout = Fragment) => routes.map((route => {
    const Guard = route.guard || guard || Fragment;
    const Layout = route.layout || layout || Fragment;
    const Component = route.component;

    if ('routes' in route) {
        return {
            path: route.path,
            children: renderRoutes(route.routes,Guard, Layout)
        }
    }
    if ('path' in route) {
        return {
            path: route.path,
            element:
              <Guard><Layout>{Component}</Layout></Guard>
        }
    }
    return {
        element: <Guard><Layout>{Component}</Layout></Guard>
    }
}))





// export default routes;


export const AppRoutes = ()=>{
    // Multiple routers use  …[routerName]
    const routes = [
        ...authRoutes,
        ...customersRoutes,
        ...appRoutes,
        {
            exact: 'true',
            path: '404',
            component: <NotFoundView/>
        },
        {
            exact: 'true',
            path: 'error',
            component: <SystemErrorView/>
        },
        {
            path: '*',
            routes: [
                {
                    exact: 'true',
                    path: '',
                    component: <RootView/>,
                },
                {
                    component: <Navigate to="404"/>
                }
            ]
        },
    ];
    return useRoutes(renderRoutes(routes))

}