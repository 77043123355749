import React from 'react';
import { Navigate } from 'react-router';
import EditTopUpPostView from '../../../views/matric/topup/data/postEdit/editTopUpPostView';
import AddTopUpPostView from '../../../views/matric/topup/data/postEdit/addTopUpPostView';
import EditAccountView from '../../../views/matric/topup/accounts/edit/editAccountView';
import AddAccountView from '../../../views/matric/topup/accounts/edit/addAccountView';
import TopUpIndexView from '../../../views/matric/topup';
import AddKeywordsView from '../../../views/matric/monitor/keywords/AddKeywordsView';
import MonitorIndexView from '../../../views/matric/monitor';

const matricRoutes = [
  {
    path: 'matric',
    routes: [
      {
        path:'monitor/:tab?',
        exact: 'true',
        component:<MonitorIndexView/>
      },
      {
        path:'monitor/keywords/add',
        exact: 'true',
        component:<AddKeywordsView/>
      },
      {
        path:'topup/:tab?',
        exact: 'true',
        component:<TopUpIndexView/>
      },
      {
        path:'topup/accounts/add',
        exact: 'true',
        component:<AddAccountView/>
      },
      {
        path:'topup/accounts/edit/:site/:username',
        exact: 'true',
        component:<EditAccountView/>
      },
      {
        path:'topup/post/add',
        exact: 'true',
        component:<AddTopUpPostView/>
      },
      {
        path:'topup/post/edit/:site/:id',
        exact: 'true',
        component:<EditTopUpPostView/>
      },
      {
        component:<Navigate to="404"/>
      },
    ]
  }
]

export default matricRoutes
