import React, {useState} from 'react';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import { Button, Checkbox, FormControlLabel, Paper, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import {editSysParam, SystemSettingsActionTypes} from "../../../actions";
import SubmitButton from "../../../components/mui/button/SubmitButton";

const useStyles = makeStyles(() => ({
    root: {},
    paper__form: {
        padding: "1rem 1rem",
        margin: "0 auto",
    },
    form__title: {
        marginBottom: ".5rem",
    },

}))


const schema = yup.object().shape({
    key: yup.string().required("Key is a required field").min(2).trim(),
    val: yup.string().required("Value is a required field").min(1).trim(),
});
function EditSysParamForm() {
    const [isSaving, setIsSaving] = React.useState(false);
    const classes = useStyles();
    const setting = useSelector(state=>state.system.setting);
    const [currentParam,setCurrentParam] = useState(()=>{
        if(setting.data&& setting.currentId){
            const current = _.find(setting.data,{id:setting.currentId})
            current.val = current.decodeVal
            return current;
        }
            return null;
        
    })

    const {register, errors, handleSubmit,setError } = useForm({
        resolver: yupResolver(schema),
        reValidateMode: "onChange",
        defaultValues:currentParam
    })
    const dispatch = useDispatch();


    const handleSaveSubmit = (formData) => {

        if(setting.data && setting.data.length>0){
            for( let i = 0 ;i<setting.data.length;i+=1){
                if(setting.data[i].key===formData.key && currentParam.id!==setting.data[i].id){
                    setError("key",{type:'manual',message:"Key already exists, Try anther one."})
                    return
                }
            }
        }

        setIsSaving(true);
        editSysParam({...formData,id:currentParam.id},afterSaving,catchError)(dispatch);

    }
    const catchError = () => {
        // todo handle Error
        setIsSaving(false);
    }
    const afterSaving = () => {
        setIsSaving(false);
        dispatch({type: SystemSettingsActionTypes.SetSettingFormStatus, payload: "Ready"})
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={8}>
                <Paper className={classes.paper__form}>
                    <Typography className={classes.form__title} variant="h2" align="center">
                        Add a new System Parameter
                    </Typography>
                    <form noValidate onSubmit={handleSubmit(handleSaveSubmit)}>

                        <TextField variant="outlined" margin="normal" fullWidth name="key" id="key"
                                   label="Key *" size="small"
                                   autoComplete="key" autoFocus inputRef={register}
                                   error={Boolean(errors.key?.message)}
                                   helperText={errors.key?.message}
                        />
                        <TextField variant="outlined" margin="normal" fullWidth name="val" id="val"
                                   label="Value *" size="small"
                                   autoComplete="val" inputRef={register}
                                   error={Boolean(errors.val?.message)}
                                   helperText={errors.val?.message}
                        />
                        <FormControlLabel control={
                            <Checkbox name="isEncrypted" checked={currentParam.isEncrypted} onChange={()=>{
                                setCurrentParam({...currentParam,isEncrypted:!currentParam.isEncrypted})
                            }}
                                      inputRef={register}/>
                        } label="Encrypt Value"/>


                        <Grid container spacing={2}>
                            <Grid item sm={3}>
                                <SubmitButton  fullWidth variant="contained" color="primary"
                                    isSaving={isSaving} text="Save" isSavingText="Saving"/>
                            </Grid>
                            <Grid item sm={3}>
                                <Button type="button" fullWidth variant="contained" color="primary" onClick={() => {
                                    dispatch({type: SystemSettingsActionTypes.SetSettingFormStatus, payload: "Ready"})
                                }}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>


                    </form>
                </Paper>
            </Grid>
        </Grid>

    );
}

export default EditSysParamForm;
