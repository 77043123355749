import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, Grid, Paper, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { QRCODE_STATUS, QRCODE_VALIDATOR, useQRCodeEditorStyles } from '../../qrcodeHelper';
import SubmitButton from '../../../../components/mui/button/SubmitButton';
import { createQRCode, updateQRCode } from '../../../../actions/qrcode/qrcodeAction';
import { sendErrorMessage } from '../../../../actions';

const schema = yup.object().shape(QRCODE_VALIDATOR)
function QRCodeEditor({ isEdit, qrCodeSet, originalData={}, onSubmitSuccess }) {

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const dispatch = useDispatch()
  const classes = useQRCodeEditorStyles()

  const [isSaving, setIsSaving] = useState(false)
  const isWebsite = qrCodeSet.status === QRCODE_STATUS[0].symbol

  const onSubmit = async (formData) => {
    setIsSaving(true)

    if (isEdit) {
      await updateQRCode({ ...formData, id: originalData.id },
        (msg) => onSubmitSuccess(msg, qrCodeSet.id),
        (msg) => onError(msg))(dispatch)
    } else {
      await createQRCode({ ...formData, setId: qrCodeSet.id },
        (msg) => onSubmitSuccess(msg, qrCodeSet.id),
        (msg) => onError(msg))(dispatch)
    }
  }

  const onError = (msg) => {
    sendErrorMessage(dispatch, msg)
    setIsSaving(false)
  }

  const renderForm = () =>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5}>

        <Grid item xs={12} sm={isWebsite ? 6 : 12}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField fullWidth size='small'
                       label='Name' required
                       disabled={isSaving} variant='outlined'
                       name='name' inputRef={register}
                       defaultValue={ isEdit ? originalData.name : '' }
                       error={Boolean(errors.name?.message)}
                       helperText={errors.name?.message ? errors.name?.message : 'Max 30 characters'}
            />
          </FormControl>
        </Grid>

        {
          isWebsite &&
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth className={classes.formControl}>
              <TextField fullWidth size='small'
                         label='URL' required
                         disabled={isSaving} variant='outlined'
                         name='url' inputRef={register}
                         defaultValue={ isEdit ? originalData.url : '' }
                         error={Boolean(errors.url?.message)}
                         helperText={errors.url?.message}
              />
            </FormControl>
          </Grid>
        }

        <Grid item xs={12}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField fullWidth size='small'
                       label='Description'
                       multiline rows={5}
                       disabled={isSaving} variant='outlined'
                       name='description' inputRef={register}
                       defaultValue={ isEdit ? originalData.description : '' }
                       error={Boolean(errors.description?.message)}
                       helperText={errors.description?.message}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} direction='row' justifyContent='flex-end'>
            <Grid item xs={2} className={classes.submitButton}>
              <SubmitButton isSaving={isSaving} text='Submit' isSavingText='Submitting'
                            fullWidth variant='contained' color='primary'
              />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </form>

  return (
    <Paper className={classes.paper}>
      { renderForm() }
    </Paper>
  )
}

export default QRCodeEditor
