import React from 'react';
import { Button, Grid, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useClassEditorStyle } from './classHelper';
import { Functions } from '../../../utils/Functions';
import { classActionsType } from '../../../actions/class/classActions';
import ViewHead from '../../../components/public/ViewHead';
import { SYLLABUS_DISPLAY_TYPE } from '../syllabus/syllabusHelper';
import SyllabusTreeView from '../syllabus/SyllabusTreeView';

function SyllabusProgressView({ data }) {

  const classes = useClassEditorStyle()
  const dispatch = useDispatch()

  // Get all the data required
  const dataData = data?.data || data
  const {syllabus} = dataData
  const completedSet = new Set()
  const completedMap = {}
  if (dataData?.history?.length > 0) {
    dataData.history.forEach(history => {
      if (history.points?.length > 0) {
        history.points.forEach(point => {
          completedSet.add(point.id)
          completedMap[point.id] = moment(history.createdAt).format('L LT')
        })
      }
    })
  }

  // Convert set to list
  const completedList = [ ...completedSet ]

  const renderView = () =>
    <Grid container>
      <Grid item xs={12}>
        <SyllabusTreeView data={syllabus} completed={completedList} completedMap={completedMap}
                          displayType={SYLLABUS_DISPLAY_TYPE.VIEW} />
      </Grid>
    </Grid>

  const renderTopButton = () =>
    <Button type='button'
            variant='contained'
            color='primary'
            onClick={() =>
              dispatch({ type: classActionsType.SetClassTableStatus, payload: 'READ' })}>
      Back To Class
    </Button>

  return (
    <ViewHead functionId={Functions.Training_Class_Class_Progress}
              topButtons={renderTopButton()} noMaxWidth>
      <Paper className={classes.paper__form}>
        { renderView() }
      </Paper>
    </ViewHead>
  )
}

export default SyllabusProgressView
