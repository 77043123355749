import React from 'react'
import moment from 'moment'
import {Paper, Typography} from '@mui/material'
import LXGrid from '../../../components/mui/datagrid/XGrid'

export const renderUsersTable = (isSmallView, users, isLoaded, className) => {
    const columns = [
        {field: 'id', headerName: 'ID', width: 80},
        {field: 'lastName', headerName: 'Last Name', width: 200},
        {field: 'firstName', headerName: 'First Name', width: 200},
        {
            field: 'isPointed',
            headerName: 'Responder',
            width: 130,
            valueFormatter: ({value}) => (value ? 'Pointed' : '-----')
        },
        {field: 'createdAt', headerName: 'Date', width: 200, valueFormatter: ({value}) => moment(value).format('L LT')},
        {field: 'scans', headerName: 'Scanned', width: 130}
    ]

    return (
        <div>
            <Typography variant="h2" color="textPrimary">
                Scanning Responders
            </Typography>
            <Paper elevation={0}>
                <LXGrid
                    totalHeight={isSmallView ? '50vh' : '30vh'}
                    rows={users ?? []}
                    columns={columns}
                    loading={!isLoaded}
                    className={className}
                    showToolbar
                    disableSelectionOnClick
                />
            </Paper>
        </div>
    )
}
export const dataURLtoBlob = dataurl => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
        n -= 1
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {type: mime})
}
