import Chip from '@mui/material/Chip'
import Popover from '@mui/material/Popover'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {cloneDeep, debounce} from 'lodash'
import {Plus, Check} from 'react-feather'
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import {addTag, deleteTag, updateTag, searchPrefixByTitle, searchPrefixByNote} from '../../actions/public/tagActions'
import DraggableDialog from '../mui/dialog/DraggableDialog'
import {colorsOptions, colorsValues} from '../../constants'

export default function Tags(props) {
    const {datas, current, group, canEdit} = props
    const [anchorEl, setAnchorEl] = useState(null)
    const [tags, setTags] = useState([])
    const [open, setOpen] = useState(false)
    const [openCreate, setOpenCreate] = useState(false)
    const [selectedTag, setSelectedTag] = useState(null)
    const [newItem, setNewItem] = useState({})
    const dispatch = useDispatch()
    const columns = []
    const prefixTitleResult = useSelector(state => state.tag.prefixMatchByTitle)
    const prefixNoteResult = useSelector(state => state.tag.prefixMatchByNote)

    const [isTitleValid, setTitleValid] = useState(true)
    // const [value, setValue] = useState('');

    const handleError = () => {
        if (newItem.title !== '') {
            setTitleValid(false)
        }
    }

    useEffect(() => {
        setTags(datas)
    }, [datas])

    const handleClose = () => {
        setOpen(false)
        setAnchorEl(null)
    }

    const handleOpen = e => {
        setAnchorEl(e.target)
        setOpen(true)
    }

    const sendSearchTitleRequest = async title => {
        await searchPrefixByTitle(title)(dispatch)
    }

    const sendSearchNoteRequest = async note => {
        await searchPrefixByNote(note)(dispatch)
    }

    const handleSearchPrefixByTitle = debounce(title => sendSearchTitleRequest(title), 500)

    const handleSearchPrefixByNote = debounce(note => sendSearchNoteRequest(note), 500)

    const handleDelete = async item => {
        const {groupId, id, group} = item
        const data = {groupId, id, group}
        await deleteTag(data)(dispatch)
    }

    const handleUpdate = async item => {
        const {note, title, id, group, ascendantID, color, detail} = item
        const data = {note, title: `${title}`, group, id, ascendantID, color, detail}
        await updateTag(data)(dispatch)
    }

    const handleAdd = async item => {
        const {note, title, color, detail} = item
        const data = {note, title, group, ascendantID: current, color, detail}
        await addTag(data)(dispatch)
        setOpen(false)
    }

    function renderColorOptions() {
        const options = []
        colorsOptions.forEach((curColor, index) => {
            options.push(
                <FormControlLabel
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginRight: '0.5rem',
                        marginLeft: '0.5rem',
                        color: colorsValues[index]
                    }}
                    value={curColor}
                    control={<Radio color={curColor} sx={{color: `${colorsValues[index]}`}} />}
                    onChange={() => {
                        newItem.color = curColor
                    }}
                    label={curColor}
                />
            )
        })
        return options
    }

    function renderColorSelection(item) {
        return (
            <FormControl
                sx={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <RadioGroup row defaultValue={item.color} name="radio-buttons-group">
                    {renderColorOptions()}
                </RadioGroup>
            </FormControl>
        )
    }

    function renderPop(item) {
        if (canEdit) {
            return null
        }
        return `Note: ${item.detail}`
    }

    function renderTagModAndAdd() {
        let item = {title: 'title', note: 'note', detail: 'detail', color: 'default'}
        if (selectedTag !== undefined) {
            item = {...selectedTag}
        }
        if (canEdit) {
            return (
                <DraggableDialog
                    open={Boolean(openCreate)}
                    onClose={() => setOpenCreate(null)}
                    title="Edit Tag"
                    isLoaded={Boolean(openCreate)}
                >
                    <Paper elevation={0}>
                        <Autocomplete
                            sx={{margin: '5px 0'}}
                            freeSolo
                            disableClearable
                            renderInput={params => {
                                params.inputProps.value = null
                                return (
                                    <TextField
                                        autoFocus
                                        label="Title"
                                        variant="standard"
                                        size="small"
                                        {...params}
                                        defaultValue={item.title}
                                        onChange={e => {
                                            if (e.target.value !== '') {
                                                handleSearchPrefixByTitle(e.target.value)
                                                newItem.title = e.target.value
                                            } else {
                                                newItem.title = ''
                                            }
                                            handleError()
                                        }}
                                        required="true"
                                        error={!isTitleValid && newItem.title === ''}
                                        helperText={!isTitleValid && newItem.title === '' && 'Must enter a Title !!'}
                                    />
                                )
                            }}
                            options={prefixTitleResult}
                            getOptionLabel={tag => tag.title}
                        />

                        <div>
                            <Autocomplete
                                sx={{margin: '5px 0 '}}
                                freeSolo
                                disableClearable
                                renderInput={params => {
                                    params.inputProps.value = null
                                    return (
                                        <TextField
                                            label="Note"
                                            variant="standard"
                                            size="small"
                                            {...params}
                                            defaultValue={item.note}
                                            onChange={e => {
                                                if (e.target.value !== '') {
                                                    item.note = e.target.value
                                                    handleSearchPrefixByNote(e.target.value)
                                                    newItem.note = e.target.value
                                                } else {
                                                    newItem.note = ''
                                                }
                                            }}
                                        />
                                    )
                                }}
                                options={prefixNoteResult}
                                getOptionLabel={tag => tag.note}
                            />
                        </div>
                        <div style={{margin: '5px 0 15px 0'}}>
                            <TextField
                                id="outlined-multiline-flexible"
                                fullWidth
                                multiline
                                rows={4}
                                label="Detail"
                                variant="standard"
                                defaultValue={item.detail}
                                onChange={e => {
                                    newItem.detail = e.target.value
                                }}
                            />
                        </div>
                        <div>
                            <FormLabel>Color</FormLabel>
                            {renderColorSelection(item)}
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', padding: '5px 0', margin: '5px'}}>
                            <Button
                                variant="outlined"
                                size="small"
                                startIcon={<Check />}
                                onClick={() => {
                                    if (selectedTag !== null) handleUpdate(newItem)
                                    else handleAdd(newItem)
                                    setOpenCreate(null)
                                }}
                                disabled={newItem.title === ''}
                            >
                                Submit
                            </Button>
                        </div>
                    </Paper>
                </DraggableDialog>
            )
        }
        return null
    }

    tags.forEach(item => {
        if (Number(item.ascendantID) === current) {
            const content = `${item.title}  ${item.note}`
            columns.push(
                <div style={{marginRight: '5px'}}>
                    <Chip
                        label={content}
                        size="small"
                        onClick={e => {
                            if (canEdit) {
                                setOpenCreate(true)
                                setSelectedTag(item)
                                setNewItem(cloneDeep(item))
                            } else handleOpen(e)
                        }}
                        color={item.color}
                        clickable="true"
                        onDelete={() => handleDelete(item)}
                    />
                    <Popover
                        sx={{
                            boxShadow: 1,
                            borderRadius: 2,
                            p: 2,
                            minWidth: 300
                        }}
                        id={item.id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        disableRestoreFocus
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                boxShadow: 1,
                                borderRadius: 2,
                                p: 2,
                                minWidth: 300
                            }}
                        >
                            {renderPop(item)}
                        </Box>
                    </Popover>
                </div>
            )
        }
    })
    if (canEdit) {
        columns.push(
            <div>
                <IconButton
                    color="primary"
                    size="small"
                    component="span"
                    onClick={() => {
                        setNewItem({title: '', note: '', detail: '', color: 'default'})
                        setSelectedTag(null)
                        setOpenCreate(true)
                        setTitleValid(true)
                    }}
                >
                    <Plus size={15} />
                </IconButton>
                {renderTagModAndAdd()}
            </div>
        )
    }
    return columns
}
