import {GroupByTimeUnit} from '../../../constants'

const timeSpanGroupByUnitMenu = [
    {
        label: 'Year',
        value: GroupByTimeUnit.YEAR
    },
    {
        label: 'Month',
        value: GroupByTimeUnit.MONTH
    },
    {
        label: 'Day',
        value: GroupByTimeUnit.DAY
    }
]

export default timeSpanGroupByUnitMenu
