import React from 'react';
import { Button, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ViewHead from '../../../../../components/public/ViewHead';
import { Functions } from '../../../../../utils/Functions';
import { sendSuccessMessage } from '../../../../../actions';
import TopUpPostEditor from './TopUpPostEditor';

function AddTopUpPostView() {
  const  navigate = useNavigate();
  const dispatch = useDispatch()

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/matric/topup`)
    }, 1000)
  }

  const renderBackButton = () =>
    <Button color='secondary'
            variant='contained'
            onClick={() => navigate('/app/matric/topup')}>
      Back
    </Button>

  return (
    <ViewHead functionId={Functions.Matric_TopUp_Add_TopUp_Post} noMaxWidth topButtons={renderBackButton()}>
      <Paper elevation={0}>
        <TopUpPostEditor onSubmitSuccess={onSuccess} />
      </Paper>
    </ViewHead>
  )
}

export default AddTopUpPostView
