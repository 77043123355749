import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        absolute: true,
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        backgroundColor: 'black',
        opacity: .3,
        zIndex: 10000
    }
}));
function WaitingOnly() {
    const classes = useStyles();

    return (
        <div className={classes.root}/>
    );
}

export default WaitingOnly;
