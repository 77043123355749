import React from 'react'
import {useDispatch} from 'react-redux'
import {Button} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import {Functions} from '../../../utils/Functions'
import ViewHead from '../../../components/public/ViewHead'
import {sendSuccessMessage} from '../../../actions'
import MMQJobTemplateEditor from './MMQJobTemplateEditor'
import {fetchAllMMQJobs} from '../../../actions/administrative/mmqJobsAction'

function CreateMMQJobTemplateView() {
    useCurrentViewCheck(Functions.Administrative_Contract_Template_Create)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onSuccess = msg => {
        sendSuccessMessage(dispatch, msg)
        fetchAllMMQJobs()(dispatch)
        setTimeout(() => {
            navigate(`/app/administrative/contractTemplate`)
            // window.location.reload()
        }, 1000)
    }

    const renderBackButton = () => (
        <Button color="secondary" variant="contained" onClick={() => navigate('/app/administrative/contractTemplate')}>
            Back
        </Button>
    )

    return (
        <ViewHead
            functionId={Functions.Administrative_Contract_Template_Create}
            topButtons={renderBackButton()}
            noMaxWidth
        >
            <MMQJobTemplateEditor onSubmitSuccess={onSuccess} />
        </ViewHead>
    )
}

export default CreateMMQJobTemplateView
