import api from "../../apis/api";
import { isMobile } from '../../utils/Scripts';
import { pagedGridDefaultFetch } from '../../components/mui/datagrid/PagedGridAction';
import { sendErrorMessage } from '../message/messageActions';

export const FormsActionType = {
    FetchAllForms: "FetchAllForms",
    FetchForm: "FetchForm",
    FetchWebForm: "FetchWebForm",
    FetchFormResults: "FetchFormResults",
    FetchFormSubmission: "FetchFormSubmission",
    FetchWebFormResults: "FetchWebFormResults",
    FetchWebFormSubmission: "FetchWebFormSubmission",
    FetchWebSubmission: "FetchWebSubmission",
    FetchAllCategories: "FetchAllCategories",
    FetchAllWebForms: "FetchAllWebForms",
    SetFormFetchProps: "SetFormFetchProps",

    ChangeFormTab: "ChangeFormTab"
}

export const fetchAllForms = (args) => async (dispatch) => {
    try {
        const res = await pagedGridDefaultFetch('/formBuilder/fetchAllForms',
            args, FormsActionType.SetFormFetchProps,
            async () => api.get('/formBuilder/fetchAllForms')
        )(dispatch)

        if (res.data) {
            dispatch({type: FormsActionType.FetchAllForms, payload: res.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Form fetch failed\n')
        // console.log('Form fetch failed\n', e)
    }
    return false
}

export const fetchFormBySlug = (id, onLoad) => async (dispatch) => {
    try {
        const res = await api.get(`formviewer/view/${id}`)
        if (res.data?.rs) {
            dispatch({
                type: FormsActionType.FetchForm,
                payload: res.data.data
            })
            onLoad(true)
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Could not get form\n')
        // console.log('Could not get form\n', e)
    }
    onLoad(true)
    return false
}

export const fetchFormById = (id) => async (dispatch) => {
    try {
        const res = await api.get(`formBuilder/fetchForm/${id}`)
        if (res.data) {
            dispatch({
                type: FormsActionType.FetchForm,
                payload: res.data
            })
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Could not get form\n')
        // console.log('Could not get form\n', e)
    }
    return false
}
export const fetchWebFormById = (id) => async (dispatch) => {
    try {
        const res = await api.get(`formBuilder/webforms/${id}`)
        if (res.data) {
            dispatch({
                type: FormsActionType.FetchWebForm,
                payload: res.data
            })

            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Could not get form\n')
        // console.log('Could not get form\n', e)
    }
    return false
}

export const fetchWebFormResults = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/formBuilder/fetchWebFormSubmissions/${id}`)
        if (res?.data) {
            dispatch({
                type: FormsActionType.FetchWebFormResults,
                payload: res.data
            })
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Could not get form results\n', e)
        // console.log('Could not get form results\n', e)
    }
    return false
}

export const fetchWebSubmission = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/formBuilder/fetchWebSubmission/${id}`)
        if (res.data?.rs) {
            dispatch({
                type: FormsActionType.FetchWebSubmission,
                payload: res.data.data
            })
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Could not get form submission')
        // console.log('Could not get form submission\n', e)
    }
    return false
}

export const fetchFormResults = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/formBuilder/fetchFormSubmissions/${id}`)
        if (res?.data) {
            dispatch({
                type: FormsActionType.FetchFormResults,
                payload: res.data
            })
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'Could not get form results')
    }
    return false
}

export const fetchFormSubmission = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/formBuilder/fetchSubmission/${id}`)
        if (res.data?.rs) {
            dispatch({
                type: FormsActionType.FetchFormSubmission,
                payload: res.data.data
            })
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'Could not get form submission')
    }
    return false
}

export const submitFormHead = (formData, isEdit, onSuccess, onError) => async () => {

    // Create form data to submit to server
    const data = {
        name: formData.formName,
        slug: formData.formSlug,
        description: formData.formDescription,
        formCompleteTitle: formData.formCompleteTitle,
        formCompleteDescription: formData.formCompleteDescription,
        formCompleteRedirectNotice: formData.formCompleteRedirectNotice,
        formCompleteRedirectLink: formData.formCompleteRedirectLink,
        categoryId: formData.categoryId,
        alertEmail: formData.alertEmail || ''
    }
    try {
        let res
        if (isEdit) {
            // Edit form
            data.id = formData.id
            if (formData.formCategory.toLowerCase() === 'web form') {
                res = await api.put('formBuilder/updateWebForm', data)
            } else {
                res = await api.put('formBuilder/updateForm', data)
            }

        } else if(formData.formCategory.toLowerCase() === 'web form') {
            res = await api.post('formBuilder/createWebforms', data)
        }else{

            // Create a new form
            // if (formData.formCategory.toLowerCase() === 'web form') {
            //     res = await api.post('formBuilder/createWebforms', data)
            // } else {
                res = await api.post('formBuilder/createForm', data)
            }
        if (res.data?.rs) {
            return onSuccess(res.data.data.id)
        } 
            return onError(res.data?.message || 'Form cannot be updated')
        
    } catch (e) {
        // console.log('FormHead cannot be submitted:\n', e)
        return onError(`Failed: ${e}`)
    }
}

export const submitFormBody = (formData, onSuccess, onError) => async () => {
    try {
        const res = await api.post('formBuilder/addFormComponent', formData)
        if (res.data?.rs) {
            return onSuccess(res.data.message)
        } 
            return onError(res.data?.message || 'Form component cannot be created')
        
    } catch (e) {
        // console.log('FormBody cannot be submitted:\n', e)
        return onError('Form cannot be created.')
    }
}

export const submitFormResult = (formData, onSuccess, onError) => async () => {
    try {
        const res = await api.post('formviewer/submit', formData)
        if (res.data?.rs) {
            return onSuccess(res.data)
        } 
            return onError(res.data?.message || 'Form cannot be submitted')
        
    } catch (e) {
        // console.log('FormResult cannot be submitted:\n', e)
        return onError('Form cannot be submitted.')
    }
}

export const fetchFile = (fileId, onError) => async () => {
    try {
        const res = await api.get(`asset/form/file/${fileId}`, {responseType: 'blob'})
        if (res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data], {type: res.data.type}))
            const link = document.createElement('a')
            link.href = url
            document.body.appendChild(link);
            // If is mobile, open in same tab
            if (!isMobile()) {
                link.target = '_blank'
            }
            link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}));
            link.parentNode.removeChild(link);
        }
    } catch (e) {
        onError(e)
    }
}

export const deleteForm = (ids,currentTab, fetchProps, onSuccess, onError) => async (dispatch) => {
    try {
        let res
        if(currentTab === 0) {
            res = await api.post('formBuilder/deleteForm', {ids})
        }else {
            res = await api.post('collaboration/deleteForm', {ids})
        }
       
        if (res.data) {
            if(currentTab === 0) {
                await dispatch(fetchAllForms(fetchProps))
            }else {
                await dispatch(fetchAllWebForms(fetchProps))
            }
            
            return onSuccess('Forms Deleted')
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Forms delete error:\n')
        // console.log('Forms delete error:\n', e)
    }
    return onError('Forms cannot be deleted.')
}

export const changeFormStatus = (ids, isActive,currentTab, fetchProps, onSuccess, onError) => async (dispatch) => {
    try {
        let res
        if(currentTab === 0) {
            res = await api.post('formBuilder/updateFormStatus', {isActive, ids})
        }else {
            res = await api.post('collaboration/updateFormStatus', {isActive, ids})
        }
         
        if (res.data) {
            if(currentTab === 0) {
                await dispatch(fetchAllForms(fetchProps))
            }else {
                await dispatch(fetchAllWebForms(fetchProps))
            }
            return onSuccess(isActive ? 'Forms enabled' : 'Forms disabled')
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Form delete error:\n')
        // console.log('Form delete error:\n', e)
    }
    return onError('Form status cannot be changed')
}

export const deleteSubmission = (id, formId, onSuccess, onError) => async (dispatch) => {
    try {
        const res = await api.post('formBuilder/deleteFormSubmission', {id})
        if (res.data) {
            const refresh = await dispatch(fetchFormResults(formId))
            if (refresh) onSuccess('Submission Deleted')
            else {
                onError('Form refresh failed')
            }
        }
    } catch (e) {
        onError('Form cannot be deleted.')
    }
}

export const deleteWebSubmission = (id, formId, onSuccess, onError) => async (dispatch) => {
    try {
        const res = await api.put(`formBuilder/deleteWebFormSubmission/${id}`)
        if (res.data) {
            const refresh = await dispatch(fetchWebFormResults(formId))
            if (refresh)
                onSuccess('Submission Deleted')
        } else {
            onError('Form refresh failed')
        }

    } catch (e) {
        onError('Form cannot be deleted.')
    }
}

export const fetchFormCategories = () => async (dispatch) => {
    try {
        const res = await api.get(`formBuilder/category`)
        if (res.data) {
            if (res.data.rs) {
                dispatch({
                    type: FormsActionType.FetchAllCategories,
                    payload: res.data.data
                })
                return true
            } 
                // console.log(res.data.message)
            sendErrorMessage(dispatch,res.data.message)
                return false
            
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Could not get form categories\n')
        // console.log('Could not get form categories\n', e)
    }
    return false
}

export const deleteFormCategory = (id, onSuccess, onError) => async (dispatch) => {
    try {
        const res = await api.delete(`formBuilder/category/${id}`)
        if (res.data) {
            const refresh = await dispatch(fetchFormCategories())
            if (refresh) {
                if (res.data.rs) onSuccess('Category deleted')
                else onError(`Failed: ${res.data.message}`)
            } else onError('Cannot refresh data')
        }
    } catch (e) {
        onError('Category cannot be deleted.')
    }
}

export const updateFormCategory = (id, newName, onSuccess, onError) => async (dispatch) => {
    try {
        const res = await api.put('formBuilder/category', {categoryId: id, name: newName})
        if (res.data) {
            const refresh = await dispatch(fetchFormCategories())
            if (refresh) {
                if (res.data.rs) onSuccess('Category Updated')
                else onError(`Failed: ${res.data.message}`)
            } else onError('Cannot refresh data')
        }
    } catch (e) {
        onError('Category cannot be updated.')
    }
}

export const addFormCategory = (name, onSuccess, onError) => async (dispatch) => {
    try {
        const res = await api.post('formBuilder/category', {name})
        if (res.data) {
            const refresh = await dispatch(fetchFormCategories())
            if (refresh) {
                if (res.data.rs) onSuccess('Category created')
                else onError(`Failed: ${res.data.message}`)
            } else onError('Cannot refresh data')
        }
    } catch (e) {
        onError('Category cannot be created.')
    }


}

export const fetchAllWebForms = (args) => async (dispatch) => {
    try {
        const res = await pagedGridDefaultFetch('/formBuilder/webforms',
            args, FormsActionType.SetFormFetchProps,
            async () =>  api.get('/formBuilder/webforms')
        )(dispatch)
        if (res.data) {
            dispatch({type: FormsActionType.FetchAllWebForms, payload: res.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Form fetch failed\n')
        // console.log('Form fetch failed\n', e)
    }
    return false


}
export const updateReadStatus = async (id) => {
    try {
        const res = await api.put(`/formBuilder/updateWebFormSubmission/${id}`)
        if (res.data) {
            return true
        }
    } catch (e) {
        // console.log('Form fetch failed\n', e)
    }
    return false
}