import React from 'react'
import {Navigate} from 'react-router'
import InterviewReview from '../../../views/customer/interview/review'
import CreateInterviewView from '../../../views/customer/interview/editor/editInterviewView'
import InterviewPlannerView from '../../../views/customer/interview/interviewPlanner'
import InterviewListView from '../../../views/customer/interview/interviewList'
import AddInterviewView from '../../../views/customer/interview/editor/AddInterviewView'

const interviewRoutes = [
    {
        path: 'interview',
        routes: [
            {
                path: '',
                exact: 'true',
                component: <InterviewListView />
            },
            {
                path: 'planner',
                exact: 'true',
                component: <InterviewPlannerView />
            },
            {
                path: 'add/:id?',
                exact: 'true',
                component: <AddInterviewView />
            },
            {
                path: 'edit/:id',
                exact: 'true',
                component: <CreateInterviewView />
            },
            {
                path: 'review/:uid/:id',
                exact: 'true',
                component: <InterviewReview />
            },
            {
                component: <Navigate to="404" />
            }
        ]
    }
]

export default interviewRoutes
