import api from '../../apis/api'

export const BiActionType = {
    FetchBIDataByMatrix: 'FetchBIDataByMatrix'
}

export const fetchBIData = (requestBody, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post('bi/contractCountsAndSales', requestBody)
        if (res.data.rs) {
            dispatch({type: BiActionType.FetchBIDataByMatrix, payload: res.data.data})
            onSuccess('FetchBIDataByMatrix successfully')
            return true
        }
    } catch (e) {
        onError(e)
        return false
    }
    return false
}
