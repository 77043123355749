import _, {debounce, noop} from 'lodash'
import Grid from '@mui/material/Grid'
import {Button, ButtonGroup, IconButton, Tooltip} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Edit, Eye} from 'react-feather'
import {useDispatch, useSelector} from 'react-redux'
import GridCellExpand from '../../../components/mui/datagrid/GridCellExpand'
import {genHoverCell, useReviewListStyles} from '../reviewHelper'
import {fetchSelectedProductReviews, updateProductReviewsStatus} from '../../../actions/review/reviewActions'
import PageGrid from '../../../components/mui/datagrid/PageGrid'
import SearchBox from '../../../components/public/SearchBox'
import useAuthCheck from '../../../hooks/useAuthCheck'
import {Functions} from '../../../utils/Functions'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'

function ProductReviews({setOpenEdit, setOpenRead, setClickedRow, productId, setReadOnly = noop}) {
    const classes = useReviewListStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [checkboxSelected, setCheckboxSelected] = useState([])
    const dispatch = useDispatch()
    const allReviews = useSelector(state => state.review.productReviews)
    const reviewFetchProps = useSelector(state => state.review.reviewProductFetchProps)
    const [canEditReview] = useAuthCheck([Functions.Review_M2W_Edit])
    const [searchArgs, setSearchArgs] = useState({
        searchKey: undefined,
        pid: productId
    })

    useEffect(() => {
        if (productId > 0) {
            setSearchArgs(prevState => ({
                ...prevState,
                pid: productId
            }))
        }
    }, [productId])

    const onSearch = searchVal =>
        setSearchArgs(prevState => ({...prevState, searchKey: searchVal.toLowerCase().trim()}))

    const searchHandler = debounce(searchVal => {
        onSearch(searchVal)
    }, 500)

    const onSuccess = msg => sendSuccessMessage(dispatch, msg)
    const onError = msg => sendErrorMessage(dispatch, msg)

    const onStatusChange = isActive => {
        updateProductReviewsStatus(
            checkboxSelected.map(x => parseInt(x, 10)),
            isActive,
            reviewFetchProps,
            msg => {
                setCheckboxSelected([])
                onSuccess(msg)
            },
            msg => onError(msg)
        )(dispatch)
    }

    const renderSearchContainer = () => (
        <Grid item xs={12} className={classes.search}>
            <div className={classes.search}>
                <SearchBox
                    placeholder="Search..."
                    size="small"
                    type="search"
                    searchHandler={searchHandler}
                    isLoading={isLoading}
                />
            </div>
        </Grid>
    )

    const renderStatusButtons = () => (
        <Grid item className={classes.activityButtons} xs={12}>
            <ButtonGroup variant="contained" color="primary" size="small">
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(true)}>
                    Enable
                </Button>
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(false)}>
                    Disable
                </Button>
            </ButtonGroup>
        </Grid>
    )

    const renderGrid = () => {
        const columns = []

        if (canEditReview) {
            columns.push({
                field: '_editAction',
                headerName: 'Actions',
                resizable: false,
                disableClickEventBubbling: true,
                width: 95,
                filterable: false,
                renderCell: params => (
                    <div style={{position: 'relative', width: '100%'}}>
                        <Tooltip title="Read" placement="top">
                            <IconButton
                                color="primary"
                                component="span"
                                size="small"
                                onClick={async () => {
                                    // event.stopPropagation();
                                    await setClickedRow(params.row.id)
                                    // await setClickedRow(params.row.id);
                                    await setOpenRead(true)
                                    setReadOnly(true)
                                }}
                            >
                                <Eye size={20} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Edit" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={async () => {
                                    await setClickedRow(params.row.id)
                                    await setOpenEdit(true)
                                    setReadOnly(false)
                                }}
                            >
                                <Edit size={20} />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            })
        }

        columns.push(
            ...[
                {field: 'id', headerName: 'ID', width: 75},
                {
                    field: 'name',
                    headerName: 'Author Name',
                    width: 200,
                    renderCell: params =>
                        params.row.isActive ? (
                            _.startCase(_.lowerCase(params.row.name))
                        ) : (
                            <del>{_.startCase(_.lowerCase(params.row.name))}</del>
                        )
                },
                {field: 'score', headerName: 'Score', width: 100},
                {
                    field: 'text',
                    headerName: 'Review',
                    width: 400,
                    renderCell: ({value}) => (
                        <GridCellExpand
                            defaultValue={value}
                            expandValue={genHoverCell(value)}
                            maxWidth="800px"
                            minWidth="200px"
                        />
                    )
                },
                {
                    field: 'datePublishedAt',
                    headerName: 'Published At',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                },
                {
                    field: 'createdAt',
                    headerName: 'Created',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                }
            ]
        )

        return (
            <PageGrid
                columns={columns}
                datas={allReviews}
                fetchDatas={fetchSelectedProductReviews}
                searchArgs={searchArgs}
                defaultSortColumn="id"
                selected={checkboxSelected}
                setSelected={setCheckboxSelected}
                setIsLoading={setIsLoading}
            />
        )
    }

    const renderTopToolbar = () => (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            {renderSearchContainer()}
            {canEditReview ? renderStatusButtons() : <div />}
        </Grid>
    )

    return (
        <Grid container pl={2} pr={2}>
            {renderTopToolbar()}
            {renderGrid()}
        </Grid>
    )
}

export default ProductReviews
