import {useDispatch, useSelector} from 'react-redux'
import {
    Paper,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormHelperText,
    InputAdornment,
    Tooltip
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'
import {useForm} from 'react-hook-form'
import React, {useEffect, useState} from 'react'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import IconButton from '@mui/material/IconButton'
import {Clipboard, Edit} from 'react-feather'
import _, {noop} from 'lodash'
import {sendErrorMessage} from '../../../../actions'
import {copyToClipboard} from '../../../../utils/Scripts'
import {copyBoardStyle} from '../../../../constants'
import SubmitButton from '../../../../components/mui/button/SubmitButton'
import MyCkEditor from '../../../../components/ckeditor/MyCkEditor'
import {
    createContractTerm,
    fetchAllContractTerms,
    updateContractTerm
} from '../../../../actions/administrative/administrativeAction'

const useStyles = makeStyles(() => ({
    root: {},
    paper__form: {
        padding: '1rem 1rem',
        margin: '0 auto'
    },
    form__title: {
        marginBottom: '.5rem'
    },
    add__categories: {
        margin: '2em'
    },
    save__button: {
        margin: '3em 0em'
    },
    editor: {
        '& .ck-editor__editable_inline': {
            minHeight: '250px'
        }
    }
}))

const schema = yup.object().shape({
    title: yup.string().min(2).max(100).required(),
    slug: yup.string().min(2).max(100).required(),
    description: yup.string()
})
const defaultValue = {
    name: '',
    slug: '',
    description: '',
    text: '',
    isDefault: false
}

function ContractTermEditor({
    isReadOnly = false,
    onSubmitSuccess = noop,
    isEdit,
    originalData,
    cb = noop,
    saveAs = false
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const defaultValues = isEdit ? {...originalData, title: _.startCase(originalData.title)} : defaultValue
    const {register, errors, handleSubmit, setValue} = useForm({
        resolver: yupResolver(schema),
        defaultValues
    })
    const [isDefault, setIsDefault] = useState(isEdit ? originalData?.isDefault : false)

    const [IsSaving, setIsSaving] = useState(false)
    const [temp, setTemp] = useState(isEdit ? originalData?.text : '')
    const termProps = useSelector(state => state?.administrative?.contractTemplateFetchBody)

    const [currentTerm, setCurrentTerm] = useState(isEdit ? originalData : defaultValue)
    useEffect(() => setValue('isDefault', isDefault), [isDefault])

    const onCopy = targetName => {
        copyToClipboard(dispatch, currentTerm[targetName])
    }
    const onError = msg => {
        sendErrorMessage(dispatch, msg)
        setIsSaving(false)
    }
    const afterSaving = () => {
        dispatch(fetchAllContractTerms(termProps))
        setIsSaving(false)
    }
    const onSuccess = (msg, object) => {
        onSubmitSuccess(msg, {...object})
    }

    const onsubmit = data => {
        setIsSaving(true)
        const formData = new FormData()
        formData.append('title', data.title.trim())
        formData.append('slug', data.slug.trim())
        formData.append('description', data.description)
        formData.append('text', temp)
        formData.append('isDefault', isDefault)

        if (isEdit) {
            formData.append('id', originalData.id)
            updateContractTerm(formData, onSuccess, onError).then(() => afterSaving())
        } else {
            createContractTerm(formData, onSuccess, onError).then(() => afterSaving())
        }
    }

    const onSaveAs = data => {
        setIsSaving(true)
        const formData = new FormData()
        formData.append('title', data.title.trim())
        formData.append('slug', data.slug.trim())
        formData.append('description', data.description)
        formData.append('text', temp)
        formData.append('isDefault', isDefault)

        createContractTerm(formData, onSuccess, onError).then(() => afterSaving())
    }

    const setSlug = value => {
        if (value) {
            const slug = value.toLowerCase()
            const replaced = slug.replace(/ /g, '-')
            setValue('slug', replaced)
        }
    }

    return (
        <div>
            <Paper className={classes.paper__form}>
                <Grid container spacing={2}>
                    <Grid item>
                        {isEdit &&
                            (isReadOnly ? (
                                <Typography className={classes.form__title} variant="h2">
                                    {`${currentTerm?.title} ID:${currentTerm?.id}`}
                                </Typography>
                            ) : (
                                <Typography className={classes.form__title} variant="h2">
                                    {`${originalData?.title} ID:${originalData?.id}`}
                                </Typography>
                            ))}
                    </Grid>
                    {isReadOnly && (
                        <Grid item>
                            <div>
                                <Tooltip title="Edit" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => cb('edit')}
                                    >
                                        <Edit fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Grid>
                    )}
                </Grid>
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onsubmit)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="title"
                                label="Title"
                                size="small"
                                autoComplete="name"
                                autoFocus
                                required
                                inputRef={register({required: true, maxLength: 30})}
                                error={Boolean(errors.title?.message)}
                                helperText={errors.title?.message}
                                onChange={event => {
                                    setSlug(event.target.value)
                                    setCurrentTerm(v => ({...v, title: event.target.value}))
                                }}
                                InputLabelProps={{shrink: true}}
                                InputProps={
                                    isEdit
                                        ? {
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <Tooltip title="Copy Form Value" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => onCopy('title')}
                                                          >
                                                              <Clipboard {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </InputAdornment>
                                              ),
                                              readOnly: isReadOnly
                                          }
                                        : {}
                                }
                            />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="slug"
                                label="Slug "
                                size="small"
                                inputRef={register({required: true})}
                                required
                                error={Boolean(errors.slug?.message)}
                                helperText={errors.slug?.message}
                                InputLabelProps={{shrink: true}}
                                InputProps={
                                    isEdit
                                        ? {
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <Tooltip title="Copy Form Value" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => onCopy('slug')}
                                                          >
                                                              <Clipboard {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </InputAdornment>
                                              ),
                                              readOnly: isReadOnly
                                          }
                                        : {}
                                }
                                onChange={event => {
                                    setCurrentTerm(v => ({...v, slug: event.target.value}))
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="description"
                                id="name"
                                label="Description"
                                rows={3}
                                InputLabelProps={{shrink: true}}
                                multiline
                                inputRef={register}
                                InputProps={
                                    isEdit
                                        ? {
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <Tooltip title="Copy Form Value" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => onCopy('description')}
                                                          >
                                                              <Clipboard {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </InputAdornment>
                                              ),
                                              readOnly: isReadOnly
                                          }
                                        : {}
                                }
                                onChange={event => {
                                    setCurrentTerm(v => ({...v, description: event.target.value}))
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <FormControl>
                                <FormHelperText id="my-helper-text">Add Term Text</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={classes.editor}>
                            <MyCkEditor
                                data={isEdit ? originalData?.text : ''}
                                onReady={() => {
                                    setValue('text', isEdit ? originalData?.text : '')
                                }}
                                onChange={(event, editor) => {
                                    setTemp(editor.getData())
                                    setValue('text', editor.getData())
                                }}
                            />

                            {temp}
                        </Grid>

                        <Grid item xs={12} lg={7}>
                            <FormControlLabel
                                inputRef={register}
                                control={
                                    <Checkbox
                                        name="isDefault"
                                        id="isDefault"
                                        checked={isDefault}
                                        disabled={isReadOnly}
                                        onChange={() => setIsDefault(defaultV => !defaultV)}
                                    />
                                }
                                label="Is it default?"
                            />
                        </Grid>

                        {!isReadOnly && (
                            <Grid item container xs={12} spacing={3}>
                                {saveAs && (
                                    <Grid item container xs={6} className={classes.save__button}>
                                        <Grid xs={12} item>
                                            <SubmitButton
                                                text="Save As New Term"
                                                isSavingText="Saving"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                isSaving={IsSaving}
                                                onClick={handleSubmit(onSaveAs)}
                                            />
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid item container xs={6} className={classes.save__button}>
                                    <Grid xs={12} item>
                                        <SubmitButton
                                            text={isEdit ? 'Update' : 'Create'}
                                            isSavingText="Saving"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            isSaving={IsSaving}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </Paper>
        </div>
    )
}

export default ContractTermEditor
