import React from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'
import {Button} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck'
import {Functions} from '../../../../utils/Functions'
import history from '../../../../utils/history'
import ViewHead from '../../../../components/public/ViewHead'
import {sendSuccessMessage} from '../../../../actions'
import InterviewAdd from './InterviewAdd'

function CreateInterviewView() {
    useCurrentViewCheck(Functions.Interview_Create)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const fetchId = useParams().id
    const id = fetchId ? parseInt(fetchId, 10) : null

    const onSuccess = msg => {
        sendSuccessMessage(dispatch, msg)
        setTimeout(() => {
            navigate(`/app/customer/interview`)
        }, 1000)
    }

    const renderBackButton = () => (
        <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
            Back
        </Button>
    )

    return (
        <div>
            <ViewHead functionId={Functions.Interview_Create} topButtons={renderBackButton()}>
                <InterviewAdd isEdit={false} defaultSelectedUser={id} onSubmitSuccess={onSuccess} />
            </ViewHead>
        </div>
    )
}

export default CreateInterviewView
