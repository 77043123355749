import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { debounce } from 'lodash';
import io from 'socket.io-client';
import { fetchAllTopUpPosts } from '../../../../actions/matric/topupActions';
import LoadingScreen from '../../../../components/public/LoadingScreen';
import { BBS_SITE_LIST, MATRIC_SOCKET_FUNCTIONS, sortMonitorList, useMatricListView } from '../../matricHelper';
import TopUpSiteTabView from './TopUpSiteTabView';
import TopUpActivitySelectionView from '../TopUpActivitySelectionView';
import { ServerBaseURL } from '../../../../constants';
import { sendInfoMessage } from '../../../../actions';
import SearchBox from '../../../../components/public/SearchBox';


// 1. Main view for top up posts
function TopUpDataView() {

  const classes = useMatricListView()
  const dispatch = useDispatch()
  const allTopUpPosts = useSelector(state => state.matric.allTopUpPosts)

  const [isAllPostsLoaded, setIsAllPostsLoaded] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const [totalPosts, setTotalPosts] = useState({})
  const [allPostList, setAllPostList] = useState([])
  const [activityFilteredList, setActivityFilteredList] = useState([])
  const [displayList, setDisplayList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')


  const loadData = () => {
    (async() => {
      setIsAllPostsLoaded(await fetchAllTopUpPosts()(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    const sio = io.connect(ServerBaseURL + MATRIC_SOCKET_FUNCTIONS.MATRIC_TOP_UP_NAMESPACE)
    sio.on(MATRIC_SOCKET_FUNCTIONS.MATRIC_TOP_UP_CONNECTED_FUNC,
      (msg) => handleListenerUpdate(msg))

    return () => {
      // console.log('SIO:', sio)
      sio.disconnect()
    }
  }, [])

  useEffect(() => {
    if (isAllPostsLoaded) {
      const tempAllPosts = []
      const tempTotalPosts = {}
      BBS_SITE_LIST.forEach(site => {

        if (Object.prototype.hasOwnProperty.call(allTopUpPosts, site.symbol) && site.name !== '58') {
          const curSitePost = allTopUpPosts[site.symbol].map(post =>
            ({ ...post, site: site.symbol }))
          tempTotalPosts[site.symbol] = allTopUpPosts[site.symbol].length
          tempAllPosts.push(...curSitePost)
        }
      })
      const sortedTempPosts = sortMonitorList(tempAllPosts)
      setAllPostList(sortedTempPosts)
      setTotalPosts(tempTotalPosts)
      setIsLoaded(true)
      onStatusSearch(sortedTempPosts)
    }
  }, [isAllPostsLoaded, allTopUpPosts])

  const handleListenerUpdate = async (message) => {
    sendInfoMessage(dispatch, message)
    await fetchAllTopUpPosts()(dispatch)
  }

  const onStatusSearch = (listToSearch = null) => {
    const searchList = listToSearch || allPostList
    setActivityFilteredList(searchList)
    return onSearch(searchQuery, searchList)
  }

  const onSearch = (searchVal, listToSearch = null) => {
    const cleanSearchVal = searchVal.toLowerCase().trim()
    setSearchQuery(cleanSearchVal)

    const searchList = listToSearch || activityFilteredList
    const fuse = new Fuse(searchList, { keys: ['id', 'title', 'category', 'user', 'description'] })

    const filteredDisplay = cleanSearchVal === ''
      ? searchList
      : fuse.search(cleanSearchVal).map(f => f.item)
    setDisplayList(filteredDisplay)
  }

  const searchHandler = debounce((searchVal) => {
    onSearch(searchVal)
  }, 500)

  const renderView = () =>
    <Grid container>
      <Grid item xs={12} className={classes.headerItem}>

        <Grid container justifyContent='space-between' className={classes.searchContainer}>
          <Grid item xs={12} sm={4} md={7} xl={9} className='searchText'>
            <SearchBox
              placeholder='Search...'
              size='small'
              type='search'
              searchHandler={searchHandler} />
          </Grid>
          <Grid item className='searchDate'>
            <TopUpActivitySelectionView searchFunction={onStatusSearch} allPosts={allPostList} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TopUpSiteTabView data={displayList} totalPosts={totalPosts}/>
      </Grid>
    </Grid>

  return (
    <div>
      {
        isLoaded
          ? renderView()
          : <LoadingScreen message='Loading...' />
      }
    </div>
  )
}

export default TopUpDataView
