import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {AppBar, Box, Hidden, IconButton, Toolbar, SvgIcon} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {Menu as MenuIcon} from 'react-feather'
import Logo from '../../../components/public/Logo'
import {THEMES} from '../../../constants'
import Account from './Account'
import Search from './Search'
import Settings from './Settings'

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                  boxShadow: 'none',
                  backgroundColor: theme.palette.primary.main
              }
            : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                  backgroundColor: theme.palette.background.default
              }
            : {})
    },
    toolbar: {
        minHeight: 64
    }
}))

function TopBar({className, onMobileNavOpen, onDeskOpen, ...rest}) {
    const classes = useStyles()

    return (
        <AppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
                        <SvgIcon fontSize="small">
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                </Hidden>

                {/* NOTE: original value: xlDown; This fixed the bug appears when viewpoint is between 1200px to 1535px. */}
                <Hidden lgDown>
                    {/* <IconButton */}
                    {/*    color="inherit" */}
                    {/*    onClick={onDeskOpen} */}
                    {/*    style={{marginRight:20}} */}
                    {/* > */}
                    {/*  <SvgIcon fontSize="small"> */}
                    {/*    <MenuIcon /> */}
                    {/*  </SvgIcon> */}
                    {/* </IconButton> */}
                    <RouterLink to="/">
                        <Logo size="big" width="200" />
                    </RouterLink>
                    <IconButton color="inherit" onClick={onDeskOpen} style={{marginRight: 20}} size="large">
                        <SvgIcon fontSize="small">
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Box ml={2} flexGrow={1} />
                <Search />

                <Settings />
                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    )
}

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
}

TopBar.defaultProps = {
    className: '',
    onMobileNavOpen: () => {}
}

export default TopBar
