import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, FormControlLabel, FormHelperText, Grid, Paper} from '@mui/material'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import TextField from '@mui/material/TextField'
import * as yup from 'yup'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import {classActionsType, createClass, updateClass} from '../../../actions/class/classActions'
import SubmitButton from '../../../components/mui/button/SubmitButton'
import {fetchClasses, fetchSyllabus} from '../../../actions'
import ViewHead from '../../../components/public/ViewHead'
import {Functions} from '../../../utils/Functions'
import {CLASS_CREATE_VALIDATOR, useClassEditorStyle} from './classHelper'
import {SYLLABUS_DISPLAY_TYPE} from '../syllabus/syllabusHelper'
import SyllabusTreeView from '../syllabus/SyllabusTreeView'
import NotFound from '../../../components/public/NotFound'
import LoadingScreen from '../../../components/public/LoadingScreen'
import ClassSyllabusRearranger from './ClassSyllabusRearranger'
import {SearchAndSelect} from '../../../components/form/SearchAndSelect'

const schema = yup.object().shape(CLASS_CREATE_VALIDATOR)
function AddClass({edit, openEdit, clone, inDataBase}) {
    const classes = useClassEditorStyle()
    const dispatch = useDispatch()
    const syllabus = useSelector(state => state?.classes.syllabusList)
    const currentClass = useSelector(state => state?.classes.currentClass)

    const [isSaving, setIsSaving] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [isSyllabusLoaded, setIsSyllabusLoaded] = useState(null)
    const [className, setClassName] = useState(currentClass?.data?.name)
    const [description, setDescription] = useState(currentClass?.data?.description)
    const {register, errors, handleSubmit, setError, clearErrors} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        defaultValues:
            edit || clone
                ? edit
                    ? currentClass?.data
                    : {...currentClass?.data, name: `clone of ${currentClass?.data.name}`}
                : ''
    })

    const [selectedTeacherList, setSelectedTeacherList] = useState(currentClass?.data?.teachers)
    const [selectedStudentList, setSelectedStudentList] = useState(currentClass?.data?.students)
    const [selectedSyllabus, setSelectedSyllabus] = useState([])
    const [selectedSyllabusObject, setSelectedSyllabusObject] = useState([])
    const [syllabusOrder, setSyllabusOrder] = useState([])
    const [slug, setSlugName] = useState('')
    const loadData = () => {
        ;(async () => {
            setIsSyllabusLoaded(await fetchSyllabus()(dispatch))

            // await setSelectedStudentList(currentClass?.data?.students)
        })()
    }
    useEffect(loadData, [])
    useEffect(() => {
        if (isSyllabusLoaded !== null) {
            if (isSyllabusLoaded) {
                setIsLoaded(true)
            } else {
                setHasError(true)
            }
        }
    }, [isSyllabusLoaded])

    useEffect(() => {
        if (isLoaded && (edit || clone)) {
            const tempSelectedTeacherList = currentClass?.data?.teachers?.map(teacher => ({
                ...teacher,
                displayName: `${teacher.id}. ${teacher.firstName} ${teacher.lastName}`
            }))
            const tempSelectedStudentList = currentClass?.data?.students?.map(student => ({
                ...student,
                displayName: `${student.id}. ${student.firstName} ${
                    student.nickName === '' ? '' : `(${student.nickName})`
                } ${student.lastName}`
            }))
            setSelectedTeacherList(tempSelectedTeacherList.filter(teach => teach !== undefined))
            setSelectedStudentList(tempSelectedStudentList.filter(stu => stu !== undefined))
            const pickOutSyllabus = currentClass?.data?.syllabus?.map(syllabusEle => syllabusEle.id)
            setSelectedSyllabus(pickOutSyllabus)
            setClassName(currentClass?.data?.name)
            setDescription(currentClass?.data?.description)
            if (edit) {
                setSlugName(currentClass.data.slug)
            } else {
                setSlugName(`clone_of_${currentClass.data.slug}`)
                setClassName(`clone of ${currentClass.data.name}`)
            }
        }
    }, [isLoaded, edit, clone])

    useEffect(() => {
        // need to preserve order
        // remove syllabus that has been removed
        const tempList = selectedSyllabusObject?.filter(item => selectedSyllabus?.includes(item?.id))
        // console.log('selectedSyllabusObject', selectedSyllabusObject)
        // console.log('tempList', tempList)
        // console.log('selectedSyllabus', selectedSyllabus)

        // add in any new syllabus
        selectedSyllabus.forEach(newSyllabusID => {
            if (!tempList.find(s => s?.id === newSyllabusID)) {
                // a new item not in the old list
                tempList.push(syllabus.find(sy => sy.id === newSyllabusID))
            }
        })
        setSelectedSyllabusObject(tempList)
        // clear unselected syllabus error before form submission
        clearErrors('syllabus')
    }, [selectedSyllabus])

    useEffect(() => {
        if (!errors.name) {
            clearErrors('slug')
        }
    }, [errors.name])

    const catchError = () => setIsSaving(false)
    const afterSaving = () => setIsSaving(false)

    const handleSaveSubmit = formData => {
        const teachersList = selectedTeacherList.map(value => value.id)
        const studentsList = selectedStudentList.map(value => value.id)
        let isFilled = true

        const data = {
            ...formData,
            teachers: teachersList,
            students: studentsList,
            syllabus: selectedSyllabus,
            syllabusOrder: JSON.stringify(syllabusOrder)
        }

        if (teachersList.length === 0) {
            setError('teacher', {type: 'manual', message: 'Please select teacher'})
            isFilled = false
        }
        if (studentsList.length === 0) {
            setError('student', {type: 'manual', message: 'Please select student'})
            isFilled = false
        }
        if (selectedSyllabus.length === 0) {
            setError('syllabus', {type: 'manual', message: 'Please select syllabus'})
            isFilled = false
        }
        if (!isFilled || Object.keys(errors).length > 0) {
            return
        }

        if (edit) {
            updateClass({...data, id: currentClass?.data?.id}, afterSaving, catchError)(dispatch)
            setIsSaving(true)
        } else {
            createClass(data, afterSaving, catchError)(dispatch)
            setIsSaving(true)
        }
        setIsSaving(true)
        fetchClasses()(dispatch)
    }

    const handleChangeName = event => {
        let slugName = event.target.value
        slugName = slugName.trim().replace(/[ ,]/gi, '_').toLowerCase()
        if (inDataBase === undefined) {
            setSlugName(slugName)
        }
        setClassName(event.target.value)
    }

    const renderBasicClassDetailsView = () => (
        <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={6}>
                <Grid container gap={2}>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            name="name"
                            id="name"
                            label="Name"
                            autoFocus
                            variant="outlined"
                            inputRef={register}
                            required
                            className={classes.text}
                            error={Boolean(errors.name)}
                            helperText={errors.name?.message}
                            onChange={handleChangeName}
                            value={className}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            name="slug"
                            id="slug"
                            label="Slug"
                            variant="outlined"
                            inputRef={register}
                            required
                            className={classes.text}
                            error={Boolean(errors.slug)}
                            helperText={errors.slug?.message}
                            value={slug}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    name="description"
                    id="description"
                    label="Description"
                    variant="outlined"
                    inputRef={register}
                    multiline
                    rows={4}
                    className={classes.text}
                    error={Boolean(errors.description?.message)}
                    helperText={errors.description?.message}
                    value={description}
                />
            </Grid>
        </Grid>
    )

    const renderTeacherAndStudentSelectView = () => (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <FormControl className={classes.formControlSelect} fullWidth>
                    <InputLabel required error={Boolean(errors.teacher?.message)}>
                        Add Teacher
                    </InputLabel>
                    <div className="selector">
                        <SearchAndSelect
                            multiple
                            key="teacher"
                            fetchParams={{
                                url: '/customer/searchTeacherByName'
                            }}
                            renderLabel={option =>
                                `${option.id}. ${option?.firstName} ${
                                    option?.nickName !== '' ? `(${option?.nickName})` : ''
                                } ${option?.lastName}`
                            }
                            defaultSelected={selectedTeacherList}
                            callback={data => {
                                setSelectedTeacherList(data)
                                // clear unselected student error before form submission
                                clearErrors('teacher')
                            }}
                        />
                    </div>
                    <FormHelperText className={classes.redCheckBox}>
                        {errors.teacher?.message ? errors.teacher?.message : null}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl className={classes.formControlSelect} fullWidth>
                    <InputLabel required error={Boolean(errors.student?.message)}>
                        Add Student
                    </InputLabel>
                    <div className="selector">
                        <SearchAndSelect
                            multiple
                            key="student"
                            fetchParams={{
                                url: '/customer/searchStudentByName'
                            }}
                            renderLabel={option =>
                                `${option.id}. ${option?.firstName} ${
                                    option?.nickName !== '' ? `(${option?.nickName})` : ''
                                } ${option?.lastName}`
                            }
                            defaultSelected={selectedStudentList}
                            callback={data => {
                                setSelectedStudentList(data)
                                // clear unselected student error before form submission
                                clearErrors('student')
                            }}
                        />
                    </div>
                    <FormHelperText className={classes.redCheckBox}>
                        {errors.student?.message ? errors.student?.message : null}
                    </FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    )

    const renderSyllabusSelectView = () => (
        <Grid container spacing={3}>
            <Grid item xs={12} md={9} xl={10}>
                <FormControl fullWidth className={classes.formControlSyllabusSelect}>
                    <FormLabel component="legend" className="label" required error={Boolean(errors.syllabus?.message)}>
                        Select Syllabus
                    </FormLabel>
                    <SyllabusTreeView
                        data={syllabus}
                        selected={selectedSyllabus}
                        onSelected={setSelectedSyllabus}
                        displayType={SYLLABUS_DISPLAY_TYPE.SELECT_SYLLABUS}
                    />
                    <FormHelperText error={Boolean(errors.syllabus?.message)}>
                        {errors.syllabus?.message
                            ? errors.syllabus?.message
                            : 'Only syllabus (light violet) can be selected'}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
                <FormControl fullWidth className={classes.formControlSyllabusSelect}>
                    <FormLabel component="legend" className="label">
                        Set Syllabus Order
                    </FormLabel>
                    <ClassSyllabusRearranger data={selectedSyllabusObject} onSet={setSyllabusOrder} />
                    <FormHelperText>Drag and drop to set the syllabus order</FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    )

    const renderStatusSelectView = () => (
        <Grid container>
            <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">Status</FormLabel>
                    <RadioGroup
                        row
                        aria-label="status"
                        name="status"
                        defaultValue={edit || clone ? currentClass?.data?.status : 'NotStart'}
                    >
                        <FormControlLabel value="NotStart" control={<Radio />} label="Not Start" inputRef={register} />
                        <FormControlLabel
                            value="InProgress"
                            control={<Radio />}
                            label="In Progress"
                            inputRef={register}
                        />
                        <FormControlLabel value="Finished" control={<Radio />} label="Finished" inputRef={register} />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    )

    const renderButtonList = () => (
        <Grid container spacing={2} className={classes.button_save}>
            <Grid item sm={3}>
                <SubmitButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    isSaving={isSaving}
                    text="Save"
                    isSavingText="Saving"
                />
            </Grid>
            <Grid item sm={3}>
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        dispatch({type: classActionsType.SetClassTableStatus, payload: 'closed'})
                    }}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    )

    const topButtons = () => (
        <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => dispatch({type: classActionsType.SetClassTableStatus, payload: 'closed'})}
        >
            Back
        </Button>
    )

    const renderView = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper className={classes.paper__form}>
                    <form noValidate onSubmit={handleSubmit(handleSaveSubmit)}>
                        {renderBasicClassDetailsView()}
                        {renderTeacherAndStudentSelectView()}
                        {renderSyllabusSelectView()}
                        {renderStatusSelectView()}
                        {renderButtonList()}
                    </form>
                </Paper>
            </Grid>
        </Grid>
    )

    return (
        <div>
            {!openEdit ? (
                <ViewHead
                    functionId={
                        edit || clone
                            ? edit
                                ? Functions.Training_Class_Edit_Class
                                : Functions.Training_Class_Create_Class
                            : Functions.Training_Class_Create_Class
                    }
                    topButtons={topButtons()}
                    noMaxWidth
                >
                    {hasError ? (
                        <NotFound message="Something went wrong" />
                    ) : isLoaded ? (
                        renderView()
                    ) : (
                        <LoadingScreen message="Loading..." />
                    )}
                </ViewHead>
            ) : (
                <div>
                    {hasError ? (
                        <NotFound message="Something went wrong" />
                    ) : isLoaded ? (
                        renderView()
                    ) : (
                        <LoadingScreen message="Loading..." />
                    )}
                </div>
            )}
        </div>
    )
}

export default AddClass
