import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const TopUpActivitySelectionView = ({ searchFunction, allPosts }) => {

  const [selected, setSelected] = useState(0)

  const onDateSelected = (selectedValue) => {
    if (selectedValue === null) {
      // always want one, cant have null
      return
    }

    setSelected(selectedValue)

    let current = allPosts
    switch (selectedValue) {
      case 0:     // All posts
        break
      case 1:     // Running
        current = allPosts.filter(post => post.isAlive && post.isActive)
        break
      case 2:     // Stopped
        current = allPosts.filter(post => post.isAlive && !post.isActive)
        break
      case 3:     // Dead
        current = allPosts.filter(post => !post.isAlive)
        break
      default: break
    }
    searchFunction(current)
  }

  const renderView = () =>
    <ToggleButtonGroup
      exclusive
      value={selected}
      onChange={(e, v) => onDateSelected(v)}
    >
      <ToggleButton value={0}>All</ToggleButton>
      <ToggleButton value={1}>Running</ToggleButton>
      <ToggleButton value={2}>Stopped</ToggleButton>
      <ToggleButton value={3}>Dead</ToggleButton>
    </ToggleButtonGroup>

  return renderView()
}

export default TopUpActivitySelectionView
