import _ from 'lodash'
import {DEFAULT_SELECT_VALUE, VALID_INPUT_TYPES} from './formBuilderData'
import makeRandString from '../../../utils/RandString'
import {addInputToPreview} from './formBuilderFormManipulator'
import {sendErrorMessage} from '../../../actions'

const TYPE_PARAMS = {
    HEAD: [
        'name',
        'description',
        'slug',
        'formCompleteDescription',
        'formCompleteRedirectLink',
        'formCompleteRedirectNotice',
        'formCompleteTitle',
        'category'
    ],
    NORMAL: ['name', 'description', 'inputType', 'isRequired', 'label', 'maxLength', 'minLength'],
    CHOICES: ['name', 'description', 'inputType', 'isRequired', 'label', 'maxLength', 'minLength', 'values'],
    TEXT: [
        'name',
        'description',
        'inputType',
        'isRequired',
        'label',
        'maxLength',
        'minLength',
        'pattern',
        'patternMessage',
        'tag'
    ],
    FILE: [
        'name',
        'description',
        'fileType',
        'inputType',
        'isRequired',
        'label',
        'maxLength',
        'minLength',
        'pattern',
        'patternMessage',
        'tag'
    ],
    VALUES: ['name', 'value', 'isDefaultValue']
}

export const getInputName = () => makeRandString(10)

export const formClonerHelper = (form, callback) => {
    let formHeadData = {}
    const formData = []
    let formComponent = []

    if (form) {
        formHeadData = TYPE_PARAMS.HEAD.reduce((res, current) => {
            res[current] = current === 'slug' ? getInputName() : form[current]
            return res
        }, {})
        if (form?.components && !_.isEmpty(form.components)) {
            form.components.forEach((ele, index) => {
                const compIdx = index
                if (Object.prototype.hasOwnProperty.call(form.components, compIdx)) {
                    const component = form.components[compIdx]

                    try {
                        // Attempt to deconstruct
                        switch (component.inputType) {
                            case VALID_INPUT_TYPES.text:
                                formData.push(
                                    TYPE_PARAMS.TEXT.reduce((res, current) => {
                                        if (res[current] === 'name') {
                                            res[current] = getInputName()
                                        } else if (res[current] === 'tag') {
                                            res[current] = component[current] ?? DEFAULT_SELECT_VALUE
                                        } else {
                                            res[current] = component[current]
                                        }
                                        return res
                                    }, {})
                                )
                                break

                            case VALID_INPUT_TYPES.file:
                                formData.push(
                                    TYPE_PARAMS.FILE.reduce((res, current) => {
                                        if (current === 'name') {
                                            res[current] = getInputName()
                                        } else if (current === 'maxLength') {
                                            res.maxSize = component[current].toString()
                                            res[current] = 2
                                        } else if (current === 'minLength') {
                                            res.minSize = component[current].toString()
                                            res[current] = 2
                                        } else if (res[current] === 'tag') {
                                            res[current] = component[current] ?? DEFAULT_SELECT_VALUE
                                        } else {
                                            res[current] = component[current]
                                        }
                                        return res
                                    }, {})
                                )
                                break

                            case VALID_INPUT_TYPES.textarea:
                                formData.push(
                                    TYPE_PARAMS.NORMAL.reduce((res, current) => {
                                        res[current] = current === 'name' ? getInputName() : component[current]
                                        return res
                                    }, {})
                                )
                                break

                            case VALID_INPUT_TYPES.select:
                            case VALID_INPUT_TYPES.radio:
                            case VALID_INPUT_TYPES.check:
                                formData.push(
                                    TYPE_PARAMS.CHOICES.reduce((res, current) => {
                                        if (current === 'name') {
                                            res[current] = getInputName()
                                        } else if (current === 'values') {
                                            res[current] = component[current].map(tn => ({
                                                name: getInputName(),
                                                value: tn.value,
                                                isDefaultValue: tn.isDefaultValue
                                            }))
                                        } else {
                                            res[current] = component[current]
                                        }
                                        return res
                                    }, {})
                                )
                                break

                            default:
                            // console.log('The current component is not valid:\n', component)
                        }
                    } catch (e) {
                        sendErrorMessage(`Error when deconstructing component: ${e}`)
                        // console.log('Error when deconstructing component:\n', component, e)
                    }
                }
            })
        }
        formComponent = formData.map(data => addInputToPreview(data))
    }

    callback(formHeadData, formData, formComponent)
}
