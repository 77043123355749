import api from '../../apis/api';
import { sendErrorMessage, sendSuccessMessage } from '../message/messageActions';
import { isMobile } from '../../utils/Scripts'
import { pagedGridDefaultFetch } from '../../components/mui/datagrid/PagedGridAction';

export const employeeActions = {
    FetchEmployees: 'FetchEmployees',
    FetchEmployeesPerPage: 'FetchEmployeesPerPage',
    FetchOneEmployee: 'FetchOneEmployee',
    SetEmployeeFormStatus: 'SetEmployeeFormStatus',
    CreateEmployee: 'CreateEmployee',
    DeleteEmployee: 'DeleteEmployee',
    SetEditEmployeeStatus: 'SetEditEmployeeStatus',
    SetCurrentEditEmployee: 'SetCurrentEditEmployee',
    UpdateEmployee: 'UpdateEmployee',
    FetchQRCode: 'FetchQRCode',
    UpdateStaffWeChat: 'UpdateStaffWeChat',
    UpdateStaffInfo: 'UpdateStaffInfo',
    UpdateStaffRole: 'UpdateStaffRole',
    DeleteCategory: 'DeleteCategory',
    ActiveEmployees: 'ActiveEmployees',
    Searching:"Searching",
    ContinueCreate:"ContinueCreate",
    DuplicateEmail:"DuplicateEmail",
    FetchEmployeeProps: 'FetchEmployeeProps'
};

export const createEmployee = (data, success, error) => async dispatch => {
    try {
        const res = await api.post('/staff/createStaff', data);
        if (res.data) {
            if (res.data.rs) {
                sendSuccessMessage(dispatch, `Employee ${res.data.data.firstName} ${res.data.data.lastName} has been created.`);
                success();
                dispatch({ type: employeeActions.CreateEmployee, payload: res.data });
                return true;
            }
            sendErrorMessage(dispatch, res.data.message);
            error();
            return false;
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'Error in createEmployee')
        // console.log('this is error', e);
        error(e);
        return false
    }
    return false
};
export const emailCheck = (email,userId=null)=> async dispatch =>{
    const checkObj = {}
    checkObj.email = email
    if(userId){
        checkObj.userId = userId
    }
    dispatch({type: employeeActions.Searching, payload: true})
    try{
        const res = await api.post('customer/emailCheck',checkObj)
        if(res?.data?.rs === true){
            dispatch({type: employeeActions.DuplicateEmail, payload: res.data.message})
        }else {
            dispatch({type: employeeActions.ContinueCreate, payload: res.data})
        }
    }catch (e) {
        // error(e.message)
    }
}
// export const fetchEmployees = () => async (dispatch) => {
//     try {
//         const res = await api.get('/staff/fetchAllStaffWithStaffInfo')
//         dispatch({ type: employeeActions.FetchEmployees, payload: res.data })
//         return true
//     } catch (e) {
//         console.log(e)
//         return false
//     }
// }

export const fetchEmployees = args => async dispatch => {
    try {
        const employeeURL = '/staff/fetchAllStaffWithStaffInfo';
        const res = await pagedGridDefaultFetch(employeeURL, args, employeeActions.FetchEmployeeProps,
          async () => api.get(employeeURL))(dispatch)
        if (res.data?.rs) {
            dispatch({ type: employeeActions.FetchEmployeesPerPage, payload:  res.data});
            return true;
        } if(res.data.length > 0){
            dispatch({ type: employeeActions.FetchEmployees, payload: res.data });
            return true;
        }
    } catch (e) {
        sendErrorMessage(dispatch, e.message);
        return false;
    }
    return false
};

export const fetchEmployee = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/staff/fetchStaffWithStaffInfo/${id}`)

        dispatch({ type: employeeActions.FetchOneEmployee, payload: res.data })
    } catch (e) {
        sendErrorMessage(dispatch, 'Error in fetchEmployee');
        // console.log(e)
    }
}

export const updateEmployee = (data, success, error) => async (dispatch) => {
    try {
        const res = await api.put(`/staff/updateUser`, { ...data, userId: data.id });
        if (res.data) {
            if (res.data.rs) {
                sendSuccessMessage(dispatch, `Employee ${res.data.data.firstName} ${res.data.data.lastName} has been updated.`);
                success();
                dispatch({ type: employeeActions.UpdateEmployee, payload: res.data });
                return true;
            }
            sendErrorMessage(dispatch,  res.data.message);
            error();
            return false;
        }
    } catch (e) {
        sendErrorMessage(dispatch,  'Error in updateEmployee');
        // console.log('this is error', e);
        error();
        return false

    }
    return false
};

export const updateStaffInfo = (data, success) => async dispatch => {
    // console.log(data);
    try {
        const res = await api.post('/staff/updateStaffInfo', data);
        if (res.data) {
            sendSuccessMessage(dispatch, 'update success');
            success();
            dispatch({ type: employeeActions.UpdateStaffInfo, payload: res.data });
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in updateStaffInfo')
        // console.log(e);
    }
};

export const deleteEmployee = (id) => async dispatch => {
    try {
        const response = await api.post(`/staff/updateStaffsStatus`, { 'ids': [id], 'isActive': false });
        if (response.data.rs) {
            dispatch({ type: employeeActions.DeleteEmployee, payload: id });
            sendSuccessMessage(dispatch, `Employee ${id} has been deleted.`);
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in deleteEmployee ')
        // console.log(e);
    }
};

export const updateStaffWeChat = (data, uid, success, error) => async (dispatch) => {
    try {
        const res = await api.post(`/staff/updateStaffWeChat`, data);
        if (res.data?.rs) {
            await fetchEmployee(uid)(dispatch)
            success('Wechat QRCode has been updated')
            return true
        }
        error(res.data?.message || 'Wechat QRCode could not been updated due to inactivate')
        return false
    } catch (e) {
        // console.log('wechat error', e);
        error(e);
    }
    return false
}

export const deleteStaffWeChat = (uid, wid, id, success, error) => async (dispatch) => {
    try {
        const res = await api.delete(`/staff/deleteStaffWeChat/${uid}/${wid}`)
        if (res.data?.rs) {
            await fetchEmployee(id)(dispatch)
            success('Deleted')
            return true
        }
        error(res.data?.message || 'Cannot be deleted')
        return false
    } catch (e) {
        // console.log('wechat error', e);
        error(e);
    }
    return false
}

export const deleteCategory = (id) => async (dispatch) => {
    try {
        const res = await api.delete(`/staff/category/${id}`);
        if (res.data) {
            dispatch({ type: employeeActions.DeleteCategory, payload: id });
            sendSuccessMessage(dispatch, 'Category deleted');
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in deleteCategory')
        // console.log(e);
    }
};
export const activateEmployee = (obj, fetchProps) => async dispatch => {
    try {
        const res = await api.post('/staff/updateEmployeesStatus', obj);
        const {data} = res;
        if (data.rs) {
            const refresh = await dispatch(fetchEmployees(fetchProps));
            if (refresh) {
                dispatch({ type: employeeActions.ActiveEmployees, payload: res.data });
                if (obj.isActive) {
                    sendSuccessMessage(dispatch, `ID: ${obj.ids} have been activated`);
                } else {
                    sendSuccessMessage(dispatch, `ID: ${obj.ids} have been deactivated`);
                }

            } else {
                sendErrorMessage(dispatch,'Error in activateEmployee')
            }
        }
        // console.log(res.data);
    } catch (e) {
        sendErrorMessage(dispatch,'Error in activateEmployee')
        // console.log(e);
    }
    return false

};

export const uploadEmployeeFile=(file,id,onSuccess)=> async dispatch =>{
    try{
        const res= await api.post('/staff/uploadUserFile',file);

        if(res.data?.rs){
            await dispatch (fetchEmployee(id))
            return onSuccess("File uploaded successfully")
        }
    }
    catch (e) {
        // console.log('Cannot upload user file', e.message)
        sendErrorMessage(dispatch, 'Failed to get file')
    }
    return false

};

export const deleteEmployeeFile=(file,uid, onSuccess)=>async dispatch=>{

    try{
        const res= await api.post('/staff/deleteUserFile',file)
        if(res.data?.rs){
            await dispatch(fetchEmployee(uid))
            return onSuccess("File deleted successfully")
        }
    }catch(e){
        sendErrorMessage(dispatch, 'Failed to delete file')
    }

    return false
};

export const getEmployeeFile=(data)=>async dispatch=>{
    try{
        const res= await api.post('/staff/getUserFile',data,{responseType:'blob'})
        if(res.data){
            const url = window.URL.createObjectURL(new Blob([res.data], {type: res.data.type}))
            const link = document.createElement('a')
            link.href = url
            if (data.fileName) {
                link.download = data.fileName
            }
            document.body.appendChild(link)
            // If is mobile, open in same tab
            if (!isMobile()) {
                link.target = '_blank'
            }
            link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}));
            link.parentNode.removeChild(link)

        }

    }catch(e){
        // console.log('Error', e.message)
        sendErrorMessage(dispatch, 'Failed to get file')
    }
    // sendErrorMessage(dispatch, 'Failed to get file')
}

