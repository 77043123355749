import {useEffect, useState} from 'react';

const usePosition = () => {
    const [position, setPosition] = useState(null)
    const [errorMessage,setErrorMessage] = useState(null)

    const getUserLocation = async () => {
        window.navigator.geolocation.getCurrentPosition(
            p => {
                setPosition(p)
            }, err => setErrorMessage(err.message)
        )
    }
    useEffect(() => {
        getUserLocation();
    }, []) // firstTimeRun request use position
    return [position,errorMessage]
};

export default usePosition;
