import React, {useEffect, useState} from 'react'
import {Button, Grid, Paper, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {useNavigate} from 'react-router-dom'
import ViewHead from '../../../components/public/ViewHead'
import FormBuilderFormHead from './formBuilderFormHead'
import FormBuilderFormBody from './formBuilderFormBody'
import FormBuilderFormPreview from './formBuilderFormPreview'
import SubmitButton from '../../../components/mui/button/SubmitButton'
import {fetchFormById, fetchWebFormById, submitFormBody} from '../../../actions/forms/formActions'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'
import {Functions} from '../../../utils/Functions'
import {DEFAULT_SELECT_VALUE, FORM_BUILDER_HEAD_MODE, VALID_INPUT_TYPES} from './formBuilderData'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import LoadingScreen from '../../../components/public/LoadingScreen'
import {formClonerHelper} from './formClonerHelper'

const useStyles = makeStyles(() => ({
    paper: {
        padding: '3rem'
    },
    previewPaper: {
        padding: '3rem'
    },
    marginTop: {
        marginTop: '3rem'
    },
    pageTitle: {
        marginBottom: '1rem'
    },
    previewContainer: {
        width: '100%'
    }
}))

function FormBuilderView() {
    useCurrentViewCheck(Functions.Forms_Add_Form)
    const navigate = useNavigate()
    const oldFormId = useParams().id

    const formToBeLoaded = useSelector(state => state?.forms.currentForm)
    const currentWebForm = useSelector(state => state?.forms.currentWebForm)
    const currentTab = useSelector(state => state?.forms.currentFormTab)

    const classes = useStyles()
    const dispatch = useDispatch()
    const [formId, setFormId] = useState(-1)
    const [formData, setFormData] = useState([])
    const [formComponents, setFormComponents] = useState([])

    const [isLoading, setIsLoading] = useState(true)
    const [isFormLoaded, setIsFormLoaded] = useState(false)
    const [prevHeadData, setPrevHeadData] = useState([])

    const [isSaving, setIsSaving] = useState(false)

    const loadData = () => {
        ;(async () => {
            if (currentTab === 0) {
                setIsFormLoaded(await fetchFormById(oldFormId)(dispatch))
            } else {
                setIsFormLoaded(await fetchWebFormById(oldFormId)(dispatch))
            }
        })()
    }

    // Load Old Form
    useEffect(() => {
        if (isLoading && !isFormLoaded) {
            if (oldFormId) {
                loadData()
            } else {
                setIsLoading(false)
            }
        }
    }, [oldFormId, isLoading, dispatch, isFormLoaded])

    // Set up old form
    useEffect(() => {
        if (isFormLoaded) {
            formClonerHelper(currentTab === 0 ? formToBeLoaded : currentWebForm, renderOldFormCallback)
        }
    }, [isFormLoaded])

    const renderOldFormCallback = (oldFormHead, oldFormData, oldFormComponents) => {
        // Old form has loaded and has been initialized
        setPrevHeadData(oldFormHead)
        setFormData(oldFormData)
        setFormComponents(oldFormComponents)
        setIsLoading(false)
    }

    const formSubmit = e => {
        e.preventDefault()
        setIsSaving(true)

        const submitFormData = formData.map(form => {
            if (form.inputType === VALID_INPUT_TYPES.file) {
                if (form.tag === DEFAULT_SELECT_VALUE) {
                    // Not a special value
                    delete form.tag
                }
                form.minLength = form.minSize
                form.maxLength = form.maxSize
                return form
            }
            if (form.inputType === VALID_INPUT_TYPES.text) {
                if (form.tag === DEFAULT_SELECT_VALUE) {
                    // Not a special value
                    delete form.tag
                }
                return form
            }
            return form
        })

        submitFormBody(
            {formId, forms: submitFormData},
            msg => {
                sendSuccessMessage(dispatch, msg)

                // Reset
                setFormData([])
                setFormComponents([])
                setFormId(-1)
                setTimeout(() => {
                    setIsSaving(false)
                    navigate('/app/forms')
                }, 1000)
            },
            msg => {
                sendErrorMessage(dispatch, msg)
                setIsSaving(false)
            }
        )(dispatch)
    }

    const renderBackButton = () => (
        <div className={classes.pageTitle}>
            <Button color="secondary" variant="contained" onClick={() => navigate('/app/forms')}>
                Back to All Forms
            </Button>
        </div>
    )

    return (
        <ViewHead functionId={Functions.Forms_Add_Form} isShowTitle={false}>
            {oldFormId ? (
                <Typography variant="h3" className={classes.pageTitle} color="textPrimary">
                    Clone Form
                </Typography>
            ) : (
                <Typography variant="h3" className={classes.pageTitle} color="textPrimary">
                    Create Form
                </Typography>
            )}

            {renderBackButton()}

            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <FormBuilderFormHead
                                mode={oldFormId ? FORM_BUILDER_HEAD_MODE.CLONE : FORM_BUILDER_HEAD_MODE.NEW}
                                formId={formId}
                                setFormId={setFormId}
                                formToEdit={prevHeadData}
                            />

                            {formId !== -1 && currentTab !== 1 && (
                                <FormBuilderFormBody
                                    formId={formId}
                                    formData={formData}
                                    setFormData={setFormData}
                                    formComponents={formComponents}
                                    setFormComponents={setFormComponents}
                                />
                            )}
                        </Paper>
                    </Grid>

                    {formId !== -1 && currentTab !== 1 && (
                        <div className={classes.previewContainer}>
                            <Grid item xs={12}>
                                <Typography variant="h2" className={classes.marginTop} color="textPrimary">
                                    Form Preview
                                </Typography>
                                <Paper className={classes.paper}>
                                    <FormBuilderFormPreview
                                        formData={formData}
                                        setFormData={setFormData}
                                        formComponents={formComponents}
                                        setFormComponents={setFormComponents}
                                    />
                                </Paper>
                            </Grid>

                            <Grid item xs={10} />
                            <Grid item xs={2} className={classes.marginTop}>
                                <form onSubmit={e => formSubmit(e)}>
                                    <SubmitButton
                                        isSaving={isSaving}
                                        text="Save Form"
                                        isSavingText="Submitting"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    />
                                </form>
                            </Grid>
                        </div>
                    )}
                </Grid>
            )}
        </ViewHead>
    )
}

export default FormBuilderView
