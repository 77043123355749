import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete, Button, FormControl, FormHelperText, Grid, Paper, TextField } from '@mui/material'
import { REVIEW_SET_EDITOR_VALIDATOR, testUrl, useReviewEditorStyles } from '../../reviewHelper'
import SubmitButton from '../../../../components/mui/button/SubmitButton'
import { sendErrorMessage } from '../../../../actions'
import { createReviewSet, UpdateReviewSet } from '../../../../actions/review/reviewActions'

const schema = yup.object().shape(REVIEW_SET_EDITOR_VALIDATOR)
function ReviewSetEditor({isEdit, originalData, onSubmitSuccess, templateHeads, onClose}) {
    const {register, handleSubmit, errors, setError, clearErrors} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })

    const dispatch = useDispatch()
    const classes = useReviewEditorStyles()

    const [isSaving, setIsSaving] = useState(false)
    const [urlInput, setUrlInput] = useState(isEdit ? originalData.googleUrl : '')
    const [selectedHead, setSelectedHead] = useState(isEdit ? templateHeads.find(head => head.id === originalData.head?.id) : null)

    const onSubmit = async formData => {
        if (!selectedHead) {
            setError('headId', {
                type: 'manual',
                message: 'Please select a head'
            })
            return null
        }

        setIsSaving(true)
        if (isEdit) {
            return UpdateReviewSet({ ...formData, uid: originalData.uid, headId: selectedHead.id }, onSubmitSuccess, onError)(dispatch)
        }
        return createReviewSet({ ...formData, headId: selectedHead.id }, onSubmitSuccess, onError)(dispatch)
    }

    const onError = msg => {
        sendErrorMessage(dispatch, msg)
        setIsSaving(false)
    }

    const renderForm = () => (
        <Grid container spacing={4} alignItems="flex-start">
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    size="small"
                    label="Title"
                    required
                    disabled={isSaving}
                    defaultValue={isEdit ? originalData.name : ''}
                    name="name"
                    inputRef={register}
                    error={Boolean(errors.name?.message)}
                    helperText={errors.name?.message}
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.headId?.message)}>
                    <Autocomplete
                        value={selectedHead}
                        onChange={(_, data) => setSelectedHead(data)}
                        options={templateHeads}
                        noOptionsText="No Head"
                        getOptionLabel={option => option.name}
                        disabled={isSaving}
                        renderInput={params => (
                            <TextField {...params} label="Select a head..." variant="outlined" size="small" />
                        )}
                    />
                    <FormHelperText error={Boolean(errors.headId?.message)}>
                        {errors.headId?.message ? errors.headId?.message : ''}
                    </FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={8} md={10}>
                <TextField
                    fullWidth
                    size="small"
                    label="Google URL"
                    required
                    disabled={isSaving}
                    name="googleUrl"
                    inputRef={register}
                    error={Boolean(errors.googleUrl?.message)}
                    helperText={errors.googleUrl?.message}
                    value={urlInput}
                    onChange={e => setUrlInput(e.currentTarget.value)}
                />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
                <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        const isPass = testUrl(urlInput)
                        if (isPass) {
                            window.open(urlInput, '_blank')
                            clearErrors()
                        } else {
                            setError('googleUrl', {
                                type: 'manual',
                                message: 'Must be a valid URL starting with http(s)://'
                            })
                        }
                    }}
                >
                    Test
                </Button>
            </Grid>
        </Grid>
    )

    const renderSubmit = () => (
        <Grid container spacing={3} justifyContent="space-evenly" alignItems="center" className={classes.submitButton}>
            <Grid item>
                <Button color="primary" variant="contained" onClick={onClose}>
                    Cancel
                </Button>
            </Grid>
            <Grid item>
                <SubmitButton
                    isSaving={isSaving}
                    text="Save"
                    isSavingText="Saving"
                    fullWidth
                    variant="contained"
                    color="primary"
                />
            </Grid>
        </Grid>
    )

    return (
        <Paper className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {renderForm()}
                {renderSubmit()}
            </form>
        </Paper>
    )
}

export default ReviewSetEditor
