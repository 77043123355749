import React, { useEffect, useState } from 'react';
import {
    Toolbar,
    Grid,
    ButtonGroup,
    Button, debounce
} from '@mui/material';
import { lighten, alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { CATEGORY_ITEMS } from '../../../constants';
import { fetchEmployees } from '../../../actions';
import SearchBox from '../../../components/public/SearchBox';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        padding: '0!important',

    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    title: {
        flex: '1 1 100%'
    },
    inputRoot: {
        color: 'inherit',
        borderBottom: '2px solid #000'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch'
            }
        }
    },
    search: {
        // position: 'relative',
        padding: theme.spacing(1, 0),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        width: '100%',

    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        paddingLeft: 0,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    filterButton: {
        padding: theme.spacing(0, 2),
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        padding: theme.spacing(1, 0),
        width: "100%",
    },
    grid: {
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(3),
            width: 'auto'
        },
        marginBottom: theme.spacing(2),
        marginTop:theme.spacing(2)
    },
    ButtonGroup : {
        marginLeft:0
    }

}));


function EnhancedTableToolbar(props) {
    const classes = useToolbarStyles();
    const {numSelected, handleChange, category, handleChangeButton, onActive, isLoading} = props;
    const creatorRole = ["Teacher","Admin"];
    const [open, setOpen] = React.useState(false);
    const [isCreatorLoaded, setIsCreatorLoaded] = useState(false)
    const dispatch = useDispatch()
    const creator = useSelector(state => state.employee.employees)?.filter(
      employees => creatorRole.includes(employees.staffInfo?.userRole?.name));
    const loadData = () => {
        (async() => {
            setIsCreatorLoaded(await fetchEmployees()(dispatch))
        })()
    }
    useEffect(loadData, [])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleDisplay = () => (
                <ButtonGroup variant='contained' color='primary' size='small' className={classes.ButtonGroup}>
                    <Button
                        disabled={numSelected <= 0}
                        onClick={() => onActive(true)}>
                        Enable
                    </Button>
                    <Button
                        disabled={numSelected <= 0}
                        onClick={() => onActive(false)}>
                        Disable
                    </Button>
                </ButtonGroup>
        )
    const searchHandler = debounce((searchVal) => {
        handleChange(searchVal)
    }, 500)

    return (
        <div>
            {isCreatorLoaded &&
              <Toolbar className={classes.root}>
                  <Grid container spacing={1}>
                  <Grid item xs={12} sm={7} md={9} xl={10}>
                      <div className={classes.search}>
                          <SearchBox
                            placeholder='Search...'
                            size='small'
                            type='search'
                            searchHandler={searchHandler}
                            isLoading={isLoading}
                          />
                      </div>
                  </Grid>
                  <Grid item xs={12} sm={5} md={3} xl={2}>
                      <FormControl className={classes.formControl} size='small'>
                          <InputLabel id="demo-controlled-open-select-label">Select</InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            value={category}
                            onChange={event => handleChangeButton(event.target.value)}
                          >
                              <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
                              <MenuItem value={CATEGORY_ITEMS.none}>None</MenuItem>
                              {creator?.map((c,i) => <MenuItem key={i} value={`${c.firstName} ${c.lastName}`}>{`${c.firstName} ${c.lastName}`}</MenuItem>)}

                          </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                      {handleDisplay()}
                  </Grid>
              </Grid>
          </Toolbar>}
        </div>
    );
}

export default EnhancedTableToolbar;
