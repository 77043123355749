import React, { useEffect, useState } from 'react';
import {
  Button, ButtonGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Paper, Select, Tooltip
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { debounce } from 'lodash';
import FormControl from '@mui/material/FormControl';
import { Edit } from 'react-feather';
import { Delete, ListAlt } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { CATEGORY_ITEMS } from "../../../constants";
import PageGrid from '../../../components/mui/datagrid/PageGrid';
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog';
import { sendErrorMessage, sendSuccessMessage } from '../../../actions';
import {
  changeCampaignStatus,
  deleteCampaign,
  fetchAllCampaigns,
  fetchCampaignCategories
} from '../../../actions/campaign/campaignActions';
import { CAMPAIGN_STATUS, useCampaignListStyles } from '../campaignHelper';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import useAuthCheck from '../../../hooks/useAuthCheck';
import ViewHead from '../../../components/public/ViewHead';
import { Functions } from '../../../utils/Functions';
import DraggableDialog from "../../../components/mui/dialog/DraggableDialog";
import CampaignEditView from "./editCampaignView";
import CampaignDataView from "../campaignDetails";
import SearchBox from '../../../components/public/SearchBox';

function CampaignListView() {
  useCurrentViewCheck(Functions.Campaign_View_List)
  const  navigate = useNavigate();
  const [ canViewDetails, canAddCampaign, canUpdateCampaign, canDeleteCampaign ] = useAuthCheck(([
    Functions.Campaign_Details,
    Functions.Campaign_Create,
    Functions.Campaign_Edit,
    Functions.Campaign_Delete,
  ]))

  const classes = useCampaignListStyles()
  const dispatch = useDispatch()
  const allCampaigns = useSelector(state => state.campaign.allCampaigns)
  const allCategories = useSelector(state => state.campaign.allCategories)
  const campaignFetchProps = useSelector(state => state.campaign.campaignFetchProps)
  const auth = useSelector(state => state.auth)

  const [currentUserId, setCurrentUserId] = useState(-1)
  const [searchArgs, setSearchArgs] = useState({searchKey: undefined, category: CATEGORY_ITEMS.all})
  const [checkboxSelected, setCheckboxSelected] = useState([])

  const [hash, setHash] = useState('init')
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
  const [selectedCampaignForDel, setSelectedCampaignForDel] = useState(-1)


  const [clickedRow, setClickedRow] = useState("")
  const [openDetails, setOpenDetails] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const loadData = () => {
    fetchCampaignCategories()(dispatch)
  }
  useEffect(loadData, [])

  useEffect(() => {
    if (auth && auth.user && currentUserId === -1 && currentUserId !== auth.user.id) {
      setCurrentUserId(auth.user.id)
    }
  }, [auth, currentUserId])

  const handleSearchChange = searchVal => {
    const searchStr = searchVal.trim()
    setSearchArgs(prevState => ({ ...prevState, searchKey: searchStr }))
  }

  const handleCategoryChange = newCategory =>
    setSearchArgs(prevState => ({ ...prevState, category: newCategory }))

  const handleDeleteOpen = (id) => {
    setSelectedCampaignForDel(id)
    setOpenDeleteDialogue(true)
    setHash(Math.random().toString(16).substring(2, 10));
  }

  const onDeleteSuccess = (msg) => sendSuccessMessage(dispatch, msg)
  const onDeleteError = (msg) => sendErrorMessage(dispatch, msg)
  const onDeleteCampaign = () => {
    deleteCampaign(selectedCampaignForDel,
      campaignFetchProps,
      (msg) => onDeleteSuccess(msg),
      (msg) => onDeleteError(msg))
    (dispatch)
  }

  const onStatusChange = (isActive) => {
    changeCampaignStatus(checkboxSelected.map(x => parseInt(x,10)),
      campaignFetchProps, isActive,
      (msg) => {
        setCheckboxSelected([])
        onDeleteSuccess(msg)
      },
      (msg) => onDeleteError(msg)
    )(dispatch)
  }

  const searchHandler = debounce((searchVal) => {
    handleSearchChange(searchVal)
  }, 500)

  const renderSearchContainer = () => <Grid item xs={12} sm={7} md={9} lg={10}>
      <div className={classes.search}>
        <SearchBox
          placeholder='Search...'
          size='small'
          type='search'
          searchHandler={searchHandler}
          isLoading={isLoading}
        />
      </div>
    </Grid>

  const renderCategorySelector = () => (
      <Grid item xs={12} sm={5} md={3} lg={2}>
        <FormControl className={classes.formControl} size='small'>
          <InputLabel>Category</InputLabel>
          <Select
            value={searchArgs.category}
            onChange={(e) => {
              handleCategoryChange(e.target.value)
            }}
          >
            <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
            <MenuItem value={CATEGORY_ITEMS.none}>None</MenuItem>
            { allCategories.map( (c,index) => <MenuItem key={index} value={c.name}>{c.name}</MenuItem> ) }
          </Select>
        </FormControl>
      </Grid>
    )

  const renderStatusButtons = () => (
      <Grid item className={classes.activityButtons} xs={12}>
        <ButtonGroup variant='contained' color='primary' size='small'>
          <Button
            disabled={checkboxSelected.length <= 0}
            onClick={() => onStatusChange(true)}>
            Enable
          </Button>
          <Button
            disabled={checkboxSelected.length <= 0}
            onClick={() => onStatusChange(false)}>
            Disable
          </Button>
        </ButtonGroup>
      </Grid>
    )

  const renderTopToolbar = () => <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
      { renderSearchContainer() }
      { renderCategorySelector() }
      { canUpdateCampaign ? renderStatusButtons() : <div /> }
    </Grid>

  const renderGrid = () => {
    const columns = []

    if (canViewDetails) {
      columns.push({
        field: '_view', headerName: ' ', resizable: false,
        disableClickEventBubbling: true, width: 65,
        filterable: false, renderCell: (params) =>
          <Tooltip title='Details' placement='top'>
            <IconButton color='primary'
                        size='small'
                        component='span'
                        onClick={async () => {
                          await setClickedRow(params.row.id);
                          await setOpenDetails(true);
                        }

                            // navigate(`/app/campaign/view/${params.row.id}`)
            }
            >
              <ListAlt size={20} />
            </IconButton>
          </Tooltip>
      })
    }

    if (canDeleteCampaign || canUpdateCampaign) {
      columns.push({
        field: '_delete', headerName: ' ', resizable: false,
        disableClickEventBubbling: true, width: 85,
        filterable: false, renderCell: (params) => (
            <div>
              { canUpdateCampaign &&
              <Tooltip title='Edit' placement='top'>
                <IconButton color='primary'
                            size='small'
                            component='span'
                            onClick={async () =>
                            {
                              await setClickedRow(params.row.id);
                              await setOpenEdit(true);
                            }
                                // navigate(`/app/campaign/edit/${params.row.id}`)
                }>
                  <Edit size={20} />
                </IconButton>
              </Tooltip>
              }
              { canDeleteCampaign &&
              <Tooltip title='Delete' placement='top'>
                <IconButton color='primary'
                            size='small'
                            component='span'
                            onClick={() => handleDeleteOpen(params.row.id)}>
                  <Delete size={20} />
                </IconButton>
              </Tooltip>
              }
            </div>
          )
      })
    }
    columns.push(...[
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'name', headerName: 'Name', width: 270, renderCell: params => {
          const unDownPosLen = params.row.notDownloaded
          const campName = params.row.isActive ? params.row.name : <del>{params.row.name}</del>
          if (unDownPosLen > 0) {
            return <div className={classes.newPoster}>
              {campName} <sup>{unDownPosLen}</sup>
            </div>
          } 
            return campName
          
        } },
      { field: 'category', headerName: 'Category', width: 180, valueFormatter: ({ value }) => value ? value.name : ''},
      { field: 'status', headerName: 'Method', width: 150, valueFormatter: ({ value }) => CAMPAIGN_STATUS.find(s => s.symbol === value).name },
      { field: 'createdAt', headerName: 'Date', width: 200, valueFormatter: ({ value }) => moment(value).format('L LT')},
      { field: 'scans', headerName: 'Scans', width: 100 },
      { field: 'description', headerName: 'Description', width: 350 },
    ])

    return <PageGrid columns={columns}
                     datas={allCampaigns}
                     searchArgs={searchArgs}
                     fetchDatas={fetchAllCampaigns}
                     selected={checkboxSelected}
                     setSelected={setCheckboxSelected}
                     setIsLoading={setIsLoading}
                     defaultSortColumn='id'/>
  }

  const renderAddNewButton = () => (
      <div>
        {canAddCampaign
          ? <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate('/app/campaign/add')}>
            Add New
          </Button>
          : ''
        }
      </div>
    )

  return (
    <ViewHead functionId={Functions.Campaign_View_List} topButtons={renderAddNewButton()} noMaxWidth>
      <Paper elevation={0} className={classes.paper}>
        { renderTopToolbar() }
        { renderGrid() }
      </Paper>
      <ResponsiveDialog
        isOpen={openDeleteDialogue} openHash={hash}
        title="Delete Campaign" content={`Delete Campaign #${selectedCampaignForDel}?`}
        Buttons={
          [
            { name: "Yes", event: () => onDeleteCampaign() },
            { name: "Cancel", event: () => setSelectedCampaignForDel(-1) }
          ]
        }
      />
      <DraggableDialog open={Boolean(openDetails)} onClose={() => setOpenDetails(null)}
                       title='View Campaign' isLoaded={Boolean(openDetails)}>
        <Paper elevation={0}>
          <CampaignDataView campaignId={clickedRow}/>
        </Paper>

      </DraggableDialog>

      <DraggableDialog open={Boolean(openEdit)} onClose={() => setOpenEdit(null)}
                       title='Edit Campaign' isLoaded={Boolean(openEdit)}>
        <Paper elevation={0}>
          <CampaignEditView campaignId={clickedRow}/>
        </Paper>

      </DraggableDialog>
    </ViewHead>
  )
}

export default CampaignListView
