
import React from 'react';
import { connect} from 'react-redux';
import { addNewProduct } from '../../actions';
import ProductEditor from "./ProductEditor";



function AddProductForm({ addNewProduct}) {

    return(
     <ProductEditor isEdit={false} cb={addNewProduct} />
    );


}


export default connect(() => ({
}), { addNewProduct})(AddProductForm);
