import _ from 'lodash'


const makeRandString = (size)=>{
    if(!_.isInteger(size) || size>14 || size<1){
        throw new Error("Size error , make sure between 1 and 14")
    }
    return Math.random().toString(16).substring(2,size*2)
}

export default makeRandString
