import React, {useState} from 'react'
import {AppBar, Button, Paper, Tab, Tabs} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {AdministrativeActionType} from '../../../actions/administrative/administrativeAction'
import {Functions} from '../../../utils/Functions'
import LoadingScreen from '../../../components/public/LoadingScreen'
import ViewHead from '../../../components/public/ViewHead'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import useAuthCheck from '../../../hooks/useAuthCheck'
import TabPanel, {a11yProps} from '../../../components/mui/tabs/TabPanel'
import ContractTemplateTab from './templateTabs/BodyTab'
import {fetchPaymentReceiver} from '../../../actions/administrative/mmqJobsAction'
import EmailReceiverTable from './EmailReceiverTab/EmailReceiverTable'
import AddContractTermView from './contractTerm/AddContractTermView'
import DraggableDialog from '../../../components/mui/dialog/DraggableDialog'

const useStyles = makeStyles(theme => ({
    activityButtons: {
        paddingBottom: theme.spacing(2)
    },
    editor: {
        '& .cke_contents.cke_reset': {
            minHeight: '50vh'
        }
    }
}))

function ContractView() {
    useCurrentViewCheck(Functions.Administrative_Contract_Template_List)
    const navigate = useNavigate()
    const classes = useStyles()
    const dispatch = useDispatch()
    const currentTab = useSelector(state => state.administrative.currentTemplateTab)

    // const [isLoaded, setIsLoaded] = useState(false)
    const isLoaded = false

    const [canCreateContract] = useAuthCheck([Functions.Administrative_Contract_Template_Create])
    const [openDraggableDialog, setOpenDraggableDialog] = useState(false)

    const handleChange = (event, newValue) =>
        dispatch({type: AdministrativeActionType.ChangeTemplateTab, payload: newValue})

    const handleAddNewTerm = () => setOpenDraggableDialog(true)

    const renderTabs = () => (
        <Paper elevation={0}>
            <AppBar position="static" color="default">
                <Tabs value={currentTab} onChange={handleChange}>
                    <Tab label="Main" {...a11yProps(0)} />
                    <Tab label="Head" {...a11yProps(1)} />
                    {/* <Tab */}
                    {/*    label="MMQ Jobs" */}
                    {/*    {...a11yProps(3)} */}
                    {/*    onClick={() => { */}
                    {/*        fetchAllMMQJobs()(dispatch) */}
                    {/*    }} */}
                    {/* /> */}
                    <Tab
                        label="Payment Receiver"
                        {...a11yProps(2)}
                        onClick={() => {
                            fetchPaymentReceiver()(dispatch)
                        }}
                    />
                    <Tab label="Contract Term" {...a11yProps(3)} />
                </Tabs>
            </AppBar>

            <TabPanel value={currentTab} index={currentTab}>
                {currentTab === 2 ? (
                    <EmailReceiverTable />
                ) : (
                    <ContractTemplateTab classes={classes} canCreateContract={canCreateContract} />
                )}
            </TabPanel>
        </Paper>
    )

    // const renderAddNewTerm = () =>

    const renderAddNewButton = () => (
        <div>
            {currentTab === 3 &&
                (canCreateContract ? (
                    <div>
                        <Button color="secondary" variant="contained" onClick={handleAddNewTerm}>
                            Add New Contract Term
                        </Button>
                    </div>
                ) : (
                    ''
                ))}
            {currentTab === 2 && (
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => navigate(`/app/administrative/contractTemplate/addPaymentReceiver`)}
                >
                    Add New Payment Receiver
                </Button>
            )}
            {(currentTab === 0 || currentTab === 1) && (
                <div>
                    {canCreateContract ? (
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() =>
                                navigate(
                                    `/app/administrative/contractTemplate/add${currentTab === 1 ? '/head' : '/body'}`
                                )
                            }
                        >
                            Add New {currentTab === 1 ? 'Head' : 'Body'}
                        </Button>
                    ) : (
                        ''
                    )}{' '}
                </div>
            )}
        </div>
    )

    return (
        <ViewHead
            functionId={Functions.Administrative_Contract_Template_List}
            topButtons={renderAddNewButton()}
            noMaxWidth
        >
            <Paper elevation={0}>{!isLoaded ? renderTabs() : <LoadingScreen message="Loading..." />}</Paper>
            <DraggableDialog
                open={Boolean(openDraggableDialog)}
                onClose={() => setOpenDraggableDialog(null)}
                title="Create New Contract Term"
                isLoaded={Boolean(openDraggableDialog)}
            >
                <AddContractTermView />
            </DraggableDialog>
        </ViewHead>
    )
}

export default ContractView
