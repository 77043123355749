import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import ViewHead from '../../../../components/public/ViewHead';
import { fetchQRCode, fetchQRCodeSet } from '../../../../actions/qrcode/qrcodeAction';
import QRCodeEditor from './QRCodeEditor';
import { sendSuccessMessage } from '../../../../actions';

function CreateQRCodeView() {
  useCurrentViewCheck(Functions.QRCode_Code_Edit)
  const  navigate = useNavigate();
  const setId = parseInt(useParams().setId, 10)
  const codeId = parseInt(useParams().codeId, 10)

  const dispatch = useDispatch()
  const currentQRCodeSet = useSelector(state => state?.qrcode.currentQRCodeSet)
  const currentQRCode = useSelector(state => state?.qrcode.currentQRCode)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isCodeLoaded, setIsCodeLoaded] = useState(false)
  const [isSetLoaded, setIsSetLoaded] = useState(false)

  useEffect(() => {
    (async() => {
      setIsCodeLoaded(await fetchQRCode(setId, codeId)(dispatch))
      setIsSetLoaded(await fetchQRCodeSet(setId)(dispatch))
    })()
  }, [setId, codeId, dispatch])

  useEffect(() => {
    if (isCodeLoaded && isSetLoaded) {
      setIsLoaded(true)
    }
  }, [isCodeLoaded, isSetLoaded])

  const onSubmitSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/qr/view/${setId}`)
    }, 1000)
  }

  const renderBackButton = () => (
      <Button color='secondary'
              variant='contained'
              onClick={() => navigate(`/app/qr/view/${setId}`)}>
        Back
      </Button>
    )

  return (
    <ViewHead functionId={Functions.QRCode_Code_Edit} topButtons={renderBackButton()}>
      {
        isLoaded
          ?
          <QRCodeEditor isEdit qrCodeSet={currentQRCodeSet} originalData={currentQRCode} onSubmitSuccess={onSubmitSuccess}/>
          :
          <Typography variant='h2'>Loading...</Typography>
      }
    </ViewHead>
  )
}

export default CreateQRCodeView
