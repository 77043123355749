import React from "react";
import { Navigate } from 'react-router';
import Resources from '../../../views/training/resources';


const resources= [
    {
        path: 'resources',
        routes: [
            {
                path: "",
                exact: 'true',
                component: <Resources/>
            },
            {
                component:<Navigate to="404"/> // this must be the last one
            },

        ]
    },
]

export  default resources;
