import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import { WECHAT_URL_BASE } from '../../campaign/campaignHelper';
import { scanQRCode } from '../../../actions/qrcode/qrcodeAction';
import CenterDisplay from '../../../components/misc/CenterDisplay';
import { QRCODE_STATUS } from '../qrcodeHelper';

function ScanQRCodeView() {

  const setId = parseInt(useParams().sid,10)
  const codeId = parseInt(useParams().cid,10)
  const userId = parseInt(useParams().uid,10)
  const dispatch = useDispatch()

  const [isLoaded, setIsLoaded] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [displayImage, setDisplayImage] = useState('')
  const [displayMessage, setDisplayMessage] = useState('')

  const onScanError = () => {
    setIsLoaded(true)
    setHasError(true)
    // console.log(msg)
    document.title = "Unavailable"
  }

  const onScanSuccess = (data) => {
    if (data.status === QRCODE_STATUS[0].symbol) {
      window.location.replace(data.url)
    } else {
      document.title = data.name || "Scan QR Code"
      setDisplayImage(`${WECHAT_URL_BASE}/${data.user}/${data.wid}`)
      setDisplayMessage(data.message)
      setIsLoaded(true)
    }
  }

  useEffect(() => {
    scanQRCode({ userId, setId, codeId, source: window.location.href },
      onScanSuccess, onScanError)(dispatch)
    document.title = "Loading..."
  }, [])

  return (
    <CenterDisplay>
      { isLoaded ?
        hasError
          ? <Typography variant='h2'>QR Code Expired</Typography>
          : <div>
            <img src={displayImage} alt='scan the wechat qr code to get in touch'/>
            <Typography variant='h2'>{ displayMessage }</Typography>
          </div>
        : <Typography variant='h3'>Loading...</Typography>
      }
    </CenterDisplay>
  )

}

export default ScanQRCodeView
