import React from 'react';
import { Button, Grid, Paper, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100vh',
        paddingTop: '10%',
    },
    paper: {
        backgroundColor: theme.palette.background.dark,
        padding: "2rem 1rem",
        minWidth: 350,
        maxWidth: 600,
    },
    footerText:{
        textAlign:'center',
        marginTop:'2rem',
    }
}))

function ResetCodeExpiredView() {
    const classes = useStyles();
    const  navigate = useNavigate();
    return (
        <Paper className={classes.root}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                    <Paper className={classes.paper} elevation={5}>
                        <Typography variant="h3" color='error' style={{textAlign: 'center'}}>
                            Reset password link is invalid or expired!
                        </Typography>
                        <Grid container direction="row" spacing={2} justifyContent="center"
                              alignItems="center" style={{marginTop:'2rem'}}>
                            <Grid item >
                                <Button variant="contained" color='primary' onClick={()=>{
                                    navigate('/auth/forgot')
                                }}>
                                    Back to forget password page
                                </Button>
                            </Grid>
                        </Grid>


                    </Paper>
                </Grid>
            </Grid>
            <Typography variant='body2' color="textSecondary" className={classes.footerText} >
                Can&apos;t recover your account? Let us know and we&apos;ll help!
            </Typography>
        </Paper>
    );
}

export default ResetCodeExpiredView;
