import { InterviewActionType } from '../../actions/customer/interviewActions';

const defaultState = {
  allInterviews: [],
  interviewFetchBody: {},
  allCustomerInterviews: [],
  currentInterview: [],

  allInterviewers: [],
  allCustomers: [],
  allProducts: [],

  currentInterviewTab: 0,       // Saves Tab for Interview List
}

export const interviewReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case InterviewActionType.FetchAllInterviews:
      return { ...state, allInterviews: action.payload }
    case InterviewActionType.FetchInterviewProps:
      return { ...state, interviewFetchBody: action.payload }
    case InterviewActionType.FetchInterview:
      return { ...state, currentInterview: action.payload }
    case InterviewActionType.FetchCustomerInterviews:
      return { ...state, allCustomerInterviews: action.payload }

    case InterviewActionType.FetchAllInterviewers:
      return { ...state, allInterviewers: action.payload }
    case InterviewActionType.FetchAllCustomers:
      return { ...state, allCustomers: action.payload }
    case InterviewActionType.FetchAllProducts:
      return { ...state, allProducts: action.payload }

    case InterviewActionType.ChangeInterviewTab:
      return { ...state, currentInterviewTab: action.payload }

    default:
      return state
  }
}
export default interviewReducer