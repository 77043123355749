import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Search as SearchIcon,
  XCircle as XIcon
} from 'react-feather';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  }
}));

function Search() {
  const classes = useStyles();

  const [value, setValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  // const [isLoading, setLoading] = useState(false);
  // const [results, setResults] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async () => {

  };

  return <div>
    <Tooltip title="Search">
      <IconButton color="inherit" onClick={handleOpen} size="large">
        <SvgIcon fontSize="small">
          <SearchIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      ModalProps={{ BackdropProps: { invisible: true } }}
      onClose={handleClose}
      open={isOpen}
      variant="temporary"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h4"
              color="textPrimary"
            >
              Search
            </Typography>
            <IconButton onClick={handleClose} size="large">
              <SvgIcon fontSize="small">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Box mt={2}>
            <TextField
              size='small'
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              onChange={(event) => setValue(event.target.value)}
              placeholder="Search people &amp; places"
              value={value}
              variant="outlined"
            />
          </Box>
          <Box
            mt={2}
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
          <Box mt={4}>
            {

            //   1 ? (
            //   <Box
            //     display="flex"
            //     justifyContent="center"
            //   >
            //     <CircularProgress />
            //   </Box>
            // )
              // : (
            //   <div>
            //     {[].map((result, i) => (
            //       <Box
            //         key={i}
            //         mb={2}
            //       >
            //         <Link
            //           variant="h4"
            //           color="textPrimary"
            //           component={RouterLink}
            //           to="/app"
            //         >
            //           {result.title}
            //         </Link>
            //         <Typography
            //           variant="body2"
            //           color="textPrimary"
            //         >
            //           {result.description}
            //         </Typography>
            //       </Box>
            //     ))}
            //   </div>
            // )
              (
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              )
            }
          </Box>
        </Box>
      </PerfectScrollbar>
    </Drawer>
  </div>;
}

export default Search;
