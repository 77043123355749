import React, { useRef } from 'react';
import { Button, Grid, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useQRCodeModalDisplayStyles } from '../qrcodeHelper';
import { copyToClipboard, isMobile } from '../../../utils/Scripts';
import { generateQRCodeLink } from '../../../actions/qrcode/qrcodeAction';

function QRCodeModalDisplay({ image, sid, cid, uid,qrScode }) {





  const dispatch = useDispatch()
  const classes = useQRCodeModalDisplayStyles()
  const qrCanvasRef = useRef(null)

  const qrSelected =qrScode.filter( qr=>qr.id===cid)


  const onQRCodeDownload = () => {
    const link = document.createElement('a')
    link.href = image
    // link.download = 'qr.png'
    link.download=`${qrSelected[0].name}.png`
    document.body.appendChild(link)

    if (!isMobile()) { link.target = '_blank' }
    link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}));
    link.parentNode.removeChild(link)
  }

  const onLinkCopy = () => copyToClipboard(dispatch, generateQRCodeLink(sid, cid, uid))

  return (
    <Paper className={classes.paper}>
      <Grid container justifyContent='center' alignItems='center' spacing={2}>
        <Grid item xs={12}>
          <div className={classes.imageContainer}>
            <img
              alt='qr code'
              className={classes.qrImage}
              src={image}
              ref={qrCanvasRef}
            />
          </div>

        </Grid>
        <Grid item xs={12}>
          <Button color='primary'
                  variant='contained'
                  className={classes.button}
                  onClick={() => onLinkCopy()}>
            Copy Link
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button color='primary'
                  variant='contained'
                  className={classes.button}
                  onClick={() => onQRCodeDownload()}>
            Download QR Code

          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default QRCodeModalDisplay
