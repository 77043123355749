import React, {useState} from 'react'
import {Button} from '@mui/material'
import {connect, useDispatch} from 'react-redux'
import {ProductsActions} from '../../actions'
import {Functions} from '../../utils/Functions'
import ViewHead from '../../components/public/ViewHead'
import useAuthCheck from '../../hooks/useAuthCheck'
import ProductPage from './ProductList'

// const useStyles = makeStyles((theme) => ({
//
//     root: {
//         maxWidth: '100%',
//         [theme.breakpoints.down('xl')]: {
//             marginTop: ".5rem",
//         }
//     }
//
// }));

function ProductView() {
    // const classes=useStyles();
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const [isAdd, isEdit] = useAuthCheck([
        Functions.Product_Management_Add_Product,
        Functions.Product_Management_Edit_Product
    ])

    const renderAddNewButton = () => (
        <div>
            {isAdd ? (
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        dispatch({type: ProductsActions.SetProductFormStatus, payload: 'ADD'})
                        setIsOpen(true)
                    }}
                >
                    Add New Product
                </Button>
            ) : (
                ''
            )}
        </div>
    )

    function renderTable() {
        return <ProductPage isEdit={isEdit} isOpen={isOpen} setIsOpen={setIsOpen} />
    }

    return (
        <ViewHead noMaxWidth functionId={Functions.Product_Management_Product_List} topButtons={renderAddNewButton()}>
            {renderTable()}
        </ViewHead>
    )
}

export default connect(
    ({products}) => ({
        products: products?.products,
        ProductFormStatus: products.productFormStatus
    }),
    {}
)(ProductView)
