import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import ViewHead from "../../../components/public/ViewHead";
import {Functions} from "../../../utils/Functions";
import useCurrentViewCheck from "../../../hooks/useCurrentViewCheck";
import api from "../../../apis/api";
import LDataGrid from "../../../components/mui/datagrid";


function OnlineUsers() {
    useCurrentViewCheck(Functions.System_Management_Online_Users)
    const [list, setList] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            const response = await api.get('auth/fetchOnlineUsers');
            setList(response.data);
        }

        loadData();
    }, []);

    const columns = [
        {field: 'id', headerName: 'UserId', width: 90,},
        {field: 'name', headerName: 'Name', width: 150,},
        {field: 'device', headerName: 'Device', width: 250,},
        {field: 'ip', headerName: 'IP', width: 95,},
        {field: 'browser', headerName: 'Browser', width: 210,},
        {field: 'url', headerName: 'Last Action', width: 210,},
        {
           field: 'ts', headerName: 'Timestamp', width: 200,
           renderCell: params => (
               <Typography variant="body2" color="textPrimary">
               {params.value}
               </Typography>)
        },
    ];
    return (
        <ViewHead functionId={Functions.System_Management_Online_Users}>

            <LDataGrid totalHeight={500} rows={list} columns={columns} loading={list.length < 1}/>
        </ViewHead>

    )
}

export default OnlineUsers;