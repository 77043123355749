import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import { sendSuccessMessage } from '../../../../actions';
import ViewHead from '../../../../components/public/ViewHead';
import QRCodeSetEditor from './QRCodeSetEditor';

function CreateQRCodeSetView() {
  useCurrentViewCheck(Functions.QRCode_Set_Add)
  const  navigate = useNavigate();
  const dispatch = useDispatch()

  const onSuccess = (msg, id = -1) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/qr/view/${id}`)
    }, 1000)
  }

  const renderReturnButton = () => (
      <Button color='secondary'
              variant='contained'
              onClick={() => navigate('/app/qr/')}>
        Back
      </Button>
    )

  return (
    <ViewHead functionId={Functions.QRCode_Set_Add} topButtons={renderReturnButton()}>
      <QRCodeSetEditor isEdit={false} originalData={{}} onSubmitSuccess={onSuccess} />
    </ViewHead>
  )

}

export default CreateQRCodeSetView

