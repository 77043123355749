import React, { useEffect, useState } from 'react';
import { Button, FormHelperText, FormLabel, Grid, TextField, Typography , Autocomplete } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DEFAULT_FORM_TAGS,
  file2b64,
  MAX_LOGO_SIZE,
  MAX_POSTER_SIZE,
  POSTER_PLACEHOLDER,
  POSTER_TEMPLATE_CREATE_VALIDATOR,
  usePosterTemplateCreateStyles
} from '../../posterGenHelper';
import { fetchAllForms, fetchFormById } from '../../../../../actions/forms/formActions';
import { mbToBytes } from '../../../formBuilder/formBuilderData';

const schema = yup.object().shape(POSTER_TEMPLATE_CREATE_VALIDATOR)
function PosterEditBasicView({ handleStepComplete, originalData={} }) {

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const dispatch = useDispatch()
  const allForms = useSelector(state => state.forms.allForms)
  const currentForm = useSelector(state => state.forms.currentForm)
  const classes = usePosterTemplateCreateStyles()

  const [selectedForm, setSelectedForm] = useState(null)
  const [usableFormTags, setUsableFormTags] = useState(DEFAULT_FORM_TAGS)

  const [isLoading, setIsLoading] = useState(true)
  const [isFormsLoaded, setIsFormsLoaded] = useState(false)
  const [imageContainer, setImageContainer] = useState({
    base: originalData.baseImage || '',
    logo: originalData.logoImage || ''
  })
  const [isDirty, setIsDirty] = useState({ base: false, logo: false, form: false })

  const loadData = () => {
    (async() => {
      setIsFormsLoaded(await fetchAllForms()(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    if (isFormsLoaded && originalData.form) {
      // set selected form
      const foundForm = allForms.find(form => form.id === originalData.form)
      if (foundForm) {
        handleFormSelection(foundForm)
      }
    }
  }, [originalData, isFormsLoaded])

  const handleNext = (formData) => {

    if (isDirty.base) {
      // Check on actual file
      if (!formData.baseImage || (formData.baseImage && (formData.baseImage.length === 0))) {
        return setError('baseImage', { type: 'manual', message: 'Base Image is required' })
      }

      if (formData.baseImage[0].size > mbToBytes(MAX_POSTER_SIZE)) {
        return setError('baseImage', { type: 'manual', message: `Base Image must be less than ${MAX_POSTER_SIZE}mb` })
      }
    } else if (imageContainer.base?.length === 0) {
      // check if has base image
      return setError('baseImage', { type: 'manual', message: 'Base Image is required' })
    }

    if (isDirty.logo
      && formData.logoImage.length > 0
      && formData.logoImage[0].size > mbToBytes(MAX_LOGO_SIZE)
    ) {
      return setError('logoImage', { type: 'manual', message: `Logo be less than ${MAX_LOGO_SIZE}mb` })
    }

    return handleStepComplete({
      images: imageContainer,
      isDirty,
      qrText: formData.qrText,
      tags: usableFormTags,
      form: selectedForm.id,
      name: formData.name,
      copyText: formData.copyText,
      description: formData.description
    })
  }

  useEffect(() => {
    if (currentForm?.id === selectedForm?.id) {
      const wantedTags = Object.keys(POSTER_PLACEHOLDER)
      const tempTags = []
      if (currentForm.components) {
        currentForm.components.forEach(formComponent => {
          if (wantedTags.includes(formComponent?.tag)) {
            tempTags.push(POSTER_PLACEHOLDER[formComponent.tag])
          }
        })
      }
      if (tempTags.length > 0) {
        setIsLoading(false)
        setUsableFormTags(tempTags)
      } else {
        setIsLoading(true)
        setUsableFormTags(DEFAULT_FORM_TAGS)
      }
    }
  }, [currentForm, selectedForm])

  const renderImageFile = (file, type) => {

    // When image has been touched, it means it has to be revalidated
    setIsDirty({ ...isDirty, [type]: true })

    if (!file) {
      // when there is no file, set the b64 image to nothing
      return setImageContainer({ ...imageContainer, [type]: '' })
    }
    return file2b64(file, (b64Image) => {
      setImageContainer({
        ...imageContainer,
        [type]: b64Image
      })
    })

  }

  const renderImageContainer = (type) =>
    <div className={classes.formPreviewImage}>
      {
        imageContainer[type]?.length > 0
          ? <img alt='selected preview' src={imageContainer[type]} />
          : <div />
      }
    </div>

  const handleFormSelection = (selForm) => {
    setSelectedForm(selForm)
    if (selForm) {
      fetchFormById(selForm.id)(dispatch)
    } else {
      setUsableFormTags(DEFAULT_FORM_TAGS)
      setIsLoading(true)
    }
  }

  const renderView = () =>
    <Grid container spacing={5} direction='row' justifyContent='space-between' alignItems='flex-start'>

      <Grid item xs={12}>
        <Typography variant='h4' className={classes.formTitle}>Basic Details</Typography>
      </Grid>
      <Grid item xs={12}>

        <Grid container spacing={3} direction='row' justifyContent='space-between' alignItems='flex-start'>

          <Grid item xs={12} md={6}>
            <Grid container direction='column'>
              <Grid item>
                <FormControl fullWidth>
                  <TextField variant='outlined' fullWidth size='small' required
                             label='Name' name='name' className={classes.formInputMarginBottom}
                             inputRef={register}
                             defaultValue={originalData.name || ''}
                             error={Boolean(errors.name?.message)}
                             helperText={errors.name?.message}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl fullWidth>
                  <TextField variant='outlined' fullWidth size='small' required
                             label='Description' name='description' inputRef={register}
                             defaultValue={originalData.description || ''}
                             error={Boolean(errors.description?.message)}
                             helperText={errors.description?.message}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                variant='outlined'
                fullWidth
                size='small'
                label='Copy Text' name='copyText' inputRef={register}
                defaultValue={originalData.copyText || ''}
                multiline rows={4}
                error={Boolean(errors.copyText?.message)}
                helperText={errors.copyText?.message || 'Text to copy when downloading poster archive'}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h4' className={classes.formTitle}>Poster Files</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth required error={Boolean(errors.baseImage?.message)}>
          <FormLabel component='legend'>Base Image</FormLabel>
          <input type='file' name='baseImage' ref={register}
                 accept='image/png,image/jpeg'
                 onChange={e => renderImageFile(e.target.files[0], 'base')} />
          <FormHelperText className={classes.marginReset}>{errors.baseImage?.message ? errors.baseImage?.message : `Changing base image will reset previous settings; Max ${MAX_POSTER_SIZE}mb`}</FormHelperText>
        </FormControl>
        { renderImageContainer('base') }
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth error={Boolean(errors.logoImage?.message)}>
          <FormLabel component='legend'>Logo Image</FormLabel>
          <input type='file' name='logoImage' ref={register}
                 accept='image/png,image/jpeg'
                 onChange={e => renderImageFile(e.target.files[0], 'logo')} />
          <FormHelperText className={classes.marginReset}>{errors.logoImage?.message ? errors.logoImage?.message : `Max ${MAX_LOGO_SIZE}mb`}</FormHelperText>
        </FormControl>
        { renderImageContainer('logo') }
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h4' className={classes.formTitle}>QR Code Link</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField variant='outlined' fullWidth size='small'
                     label='Link' name='qrText' inputRef={register}
                     defaultValue={originalData.qrText || ''}
                     error={Boolean(errors.qrText?.message)}
                     helperText={errors.qrText?.message || 'The link to open after scanning poster'}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h4' className={classes.formTitle}>Form</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Autocomplete
            disabled={!isFormsLoaded}
            options={allForms}
            noOptionsText='No Forms'
            getOptionLabel={(option) => `${option.id}. ${option.name}`}
            value={selectedForm}
            onChange={(e, v) => {
              setIsDirty({ ...isDirty, form: true })
              handleFormSelection(v)
            }}
            renderInput={(params) =>
              <TextField {...params} label={isFormsLoaded ? 'Form' : 'Loading'} variant='outlined' size='small' required />
            }
          />
          <FormHelperText className={classes.marginReset}>Changing form will reset poster text settings on step 4</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h5'>Applicable form tags:</Typography>
        <ul className={classes.formTagList}>
          {
            usableFormTags.map((item,index) =>
              <li key={index}>
                <Typography variant='body2'>{ item.name }</Typography>
              </li>
            )
          }
        </ul>
      </Grid>

      <Grid container justifyContent='flex-end'>
        <Grid item>
          <Button
            type='submit'
            disabled={isLoading}
            color='primary'
            variant='contained'>
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      { renderView() }
    </form>
  )
}


export default PosterEditBasicView
