import LoginView from '../views/auth/LoginView';
import ForgotPasswordView from '../views/auth/ForgotPasswordView';
import ResetPasswordView from '../views/auth/ResetPasswordView';
import ResetCodeExpiredView from '../views/auth/ResetCodeExpiredView';



const authRoutes = [
  {
    exact: 'true',
    path: 'auth/login',
    component: <LoginView/>
  },
  {
    exact: 'true',
    path: 'auth/forgot',
    component: <ForgotPasswordView/>
  },
  {
    exact: 'true',
    path: 'auth/resetPassword',
    component: <ResetPasswordView/>
  },
  {
    exact: 'true',
    path:'auth/resetCodeExpired',
    component: <ResetCodeExpiredView/>
  }
]

export default authRoutes
