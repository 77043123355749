import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import {Functions} from '../../../../utils/Functions';
import {sendSuccessMessage} from '../../../../actions';
import ViewHead from '../../../../components/public/ViewHead';
import {fetchM2WReview} from '../../../../actions/review/reviewActions';
import LoadingScreen from '../../../../components/public/LoadingScreen';
import M2WReviewEditor from './M2WReviewEditor';

function M2WReviewEditView({rowId}) {
    useCurrentViewCheck(Functions.Review_M2W_Edit)
    const  navigate = useNavigate();
    const urlId = useParams().id
    const dispatch = useDispatch()
    const currentReview = useSelector(state => state.review.currentM2WReview)

    const [isLoaded, setIsLoaded] = useState(false)

    const loadData = () => {
        (async () => {
            setIsLoaded(await fetchM2WReview(urlId || rowId)(dispatch))
        })()
    }
    useEffect(loadData, [dispatch, urlId || rowId])

    const onSuccess = (msg) => {
        sendSuccessMessage(dispatch, msg)
        setTimeout(() => {
            navigate('/app/review/m2w')
        }, 1000)
    }

    return (
        <div>
            {urlId ?
                <ViewHead functionId={Functions.Review_M2W_Edit}>
                    {
                        isLoaded
                            ? <M2WReviewEditor
                                onSubmitSuccess={onSuccess}
                                isEdit
                                originalData={currentReview}/>
                            : <LoadingScreen message='Loading...'/>
                    }
                </ViewHead>
                :
                <div>
                    {
                        isLoaded
                            ? <M2WReviewEditor
                                onSubmitSuccess={onSuccess}
                                isEdit
                                originalData={currentReview}/>
                            : <LoadingScreen message='Loading...'/>
                    }
                </div>

            }
        </div>

    )
}

export default M2WReviewEditView
