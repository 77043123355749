import React from 'react';
import ViewHead from "../../../components/public/ViewHead";
import {Functions} from "../../../utils/Functions";
import PersonalInfo from "./PersonalInfo";




function PersonalInfoView() {
    return (
        <ViewHead functionId={Functions.My_Profile_Personal_Information}>
            <PersonalInfo/>
        </ViewHead>
    );
}

export default PersonalInfoView;