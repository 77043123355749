import React, { useState } from 'react';
import { Button, Collapse, Grid, Link, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownward, ExpandLess, ExpandMore } from '@mui/icons-material';
import Fuse from 'fuse.js'
import { useNavigate } from 'react-router-dom';
import SubmitButton from '../../../../components/mui/button/SubmitButton';
import { KEYWORD_CREATE_VALIDATOR, useMatricKeywordsView } from '../../matricHelper';
import ViewHead from '../../../../components/public/ViewHead';
import { Functions } from '../../../../utils/Functions';
import { addKeyword, sendErrorMessage, sendSuccessMessage } from '../../../../actions';

const schema = yup.object().shape(KEYWORD_CREATE_VALIDATOR)
function AddKeywordsView() {
  const  navigate = useNavigate();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const dispatch = useDispatch()
  const classes = useMatricKeywordsView()
  const auth = useSelector(state => state.auth)

  const [isSaving, setIsSaving] = useState(false)
  const [expandTest, setExpandTest] = useState(false)

  const [testKeyword, setTestKeyword] = useState('')
  const [testPostTitles, setTestPostTitles] = useState('')
  const [testPostMatches, setTestPostMatches] = useState('')

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/matric/monitor/keywords`)
    }, 1000)
  }

  const onError = (msg) => {
    sendErrorMessage(dispatch, msg)
    setIsSaving(false)
  }

  const onSubmit = async (formData) => {
    setIsSaving(true)

    formData.userId = auth.authUser.id
    await addKeyword(formData, onSuccess, onError)(dispatch)
  }

  const handleTestKeywordMatches = () => {
    const searchKeyword = testKeyword.trim()
    const titleList = testPostTitles.trim().split('\n')

    const fuse = new Fuse(titleList, {keys: [], useExtendedSearch: true})
    const result = fuse.search(searchKeyword)
    setTestPostMatches(result.map(rs => rs.item).join('\n'))
  }

  const renderForm = () =>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>

        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            className={classes.textField} required
            inputRef={register} fullWidth variant='outlined' size='small'
            name='keyword' label='Keyword' disabled={isSaving}
            error={Boolean(errors.keyword?.message)}
            helperText={errors.keyword?.message ? errors.keyword?.message : ''}
          />
          <Typography variant='body2' color='textSecondary'>
            Start with single quote to match a specific keyword, eg: &apos;keyword
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            <Link href='https://fusejs.io/examples.html#extended-search' target='_blank'>Click here</Link> for more information on advance searches
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            className={classes.textField}
            inputRef={register} fullWidth variant='outlined'  size='small'
            name='description' label='Description' disabled={isSaving}
            error={Boolean(errors.description?.message)}
            helperText={errors.description?.message ? errors.description?.message : ''}
          />
        </Grid>

        <Grid item xs={12} className={classes.testContainer}>
          <ListItem button onClick={() => setExpandTest(!expandTest)}>
            <ListItemText primary='Test Search' />
            { expandTest ? <ExpandLess /> : <ExpandMore /> }
          </ListItem>
          <Collapse in={expandTest} unmountOnExit>
            <Grid container className='testField' spacing={2} justifyContent='center'>

              <Grid item xs={12}>
                <Typography variant='body2' color='textSecondary'>
                  This area is for testing keyword search functionality only
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className={classes.textField} fullWidth
                  name='testKeyword' label='Keyword' variant='outlined' size='small'
                  value={testKeyword} onChange={(e) => setTestKeyword(e.currentTarget.value)}
                  helperText='Keyword to test'
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline rows={6} variant='outlined' size='small'
                  className={classes.textField} fullWidth
                  name='testPostTitles' label='Test Post Titles'
                  value={testPostTitles} onChange={(e) => setTestPostTitles(e.currentTarget.value)}
                  helperText='Enter list of test titles, one per line'
                />
              </Grid>

              <Grid item>
                <Button color='primary'
                        variant='contained'
                        startIcon={<ArrowDownward />}
                        onClick={() => handleTestKeywordMatches()}>
                  Test Keyword Filter
                </Button>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline rows={6} variant='outlined' size='small'
                  className={classes.textField} fullWidth
                  name='testPostMatches' label='Matched Posts'
                  InputProps={{ readOnly: true }} value={testPostMatches}
                  helperText='All matched posts from Test Post Titles with the given Keyword will appear here'
                />
              </Grid>


            </Grid>
          </Collapse>
        </Grid>

        <Grid item className={classes.marginTop}>
          <SubmitButton isSaving={isSaving} text='Submit' isSavingText='Submitting'
                        fullWidth variant='contained' color='primary'
          />
        </Grid>

      </Grid>


    </form>


  return (
    <ViewHead functionId={Functions.Matric_Keywords_Add}
              noMaxWidth>
      <Paper elevation={0} className={classes.paper}>
        { renderForm() }
      </Paper>
    </ViewHead>
  )
}

export default AddKeywordsView
