import React from 'react'
import {IconButton} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {PlusCircle, Edit as EditIcon, Delete as DeleteIcon, AlignJustify} from 'react-feather'
// import { SYLLABUS_TYPES_VALUES } from '../syllabusHelper';

export default function SyllabusEditCommandCell(
    isSaving,
    editField,
    onCellEdit,
    onCellAdd,
    onCellSave,
    onCellCancel,
    onCellDelete,
    onCellDrag
) {
    function SyllabusEditCommandCellClass(props) {
        const {row} = props

        return row[editField] ? (
            <div>
                <IconButton
                    color="save"
                    component="span"
                    size="small"
                    style={{marginLeft: 10}}
                    onClick={() => {
                        onCellSave(row)
                    }}
                >
                    <CheckCircleIcon size={20} />
                </IconButton>
                <IconButton
                    color="cancle"
                    component="span"
                    size="small"
                    style={{marginLeft: 10}}
                    onClick={() => {
                        onCellCancel(row)
                    }}
                >
                    <CancelIcon size={20} />
                </IconButton>
            </div>
        ) : (
            <div>
                <Tooltip title="add" placement="top">
                    <IconButton
                        color="primary"
                        component="span"
                        size="small"
                        style={{marginLeft: 10}}
                        disabled={isSaving || row.type === 'points'}
                        onClick={() => {
                            onCellAdd(row)
                        }}
                    >
                        <PlusCircle size={20} />
                    </IconButton>
                </Tooltip>

                <Tooltip title="modify" placement="top">
                    <IconButton
                        color="primary"
                        component="span"
                        size="small"
                        style={{marginLeft: 10}}
                        disabled={isSaving}
                        onClick={() => {
                            onCellEdit(row)
                        }}
                    >
                        <EditIcon size={20} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="delete" placement="top">
                    <IconButton
                        color="primary"
                        component="span"
                        size="small"
                        style={{marginLeft: 10}}
                        disabled={isSaving}
                        onClick={() => {
                            onCellDelete(row)
                        }}
                    >
                        <DeleteIcon size={20} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="drag sort" placement="top">
                    <IconButton
                        color="primary"
                        component="span"
                        size="small"
                        style={{marginLeft: 10}}
                        disabled={isSaving || row.type === 'syllabus'}
                        onMouseDown={() => {
                            onCellDrag(row)
                        }}
                    >
                        <AlignJustify size={20} />
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return SyllabusEditCommandCellClass
}
