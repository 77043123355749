import React from 'react';
import { connect, useDispatch } from 'react-redux';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Paper,
 Rating } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import { red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SubmitButton from '../../../components/mui/button/SubmitButton';
import {
    addClassHistory, addClassReview,
    classActionsType,
    fetchTeachers,
    updateClassHistory
} from '../../../actions/class/classActions';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginBottom: 10
    },
    paper__form: {
        padding: '1rem 1rem',
        margin: '0 auto',
    },
    form__title: {
        marginBottom: '.5rem'
    },
    redCheckBox: {
        color: red[400],
        '&checked': {
            color: red[600]
        }
    },
    text: {
        cursor: 'default'
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2)
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }

}));
function AddClassReview({ currentHistory, student, addClassReview}) {

    const classes = useStyles();
    const dispatch = useDispatch();
    // const theme = useTheme();
    const [anonymous, setAnonymous] = React.useState(false);
    const handleChange = (event) => {
        setAnonymous( event.target.checked);
        };

    const schema = yup.object().shape({
            pros: yup.string().max(255).required(),
            suggestions: yup.string().max(255).required(),
        });


        const { register, errors, handleSubmit} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        defaultValues: ''
    });

        const [value, setValue] = React.useState(5);
        const [hover, setHover] = React.useState(-1);


        const labels = {
            0.5: 'Useless',
            1: 'Useless+',
            1.5: 'Poor',
            2: 'Poor+',
            2.5: 'Ok',
            3: 'Ok+',
            3.5: 'Good',
            4: 'Good+',
            4.5: 'Excellent',
            5: 'Excellent+'
        };

        // const catchError = (error) => {
        //     // alert(error);
        //
        // };

        const handleSaveSubmit = (data) => {

            const historyId = currentHistory.oneHistory.id
            const userId = student.id
            const score = value

            const formValue = {...data, anonymous, historyId, userId, score}

            addClassReview(formValue)

        };

    return (
        <Grid container spacing={2}>

                <Paper className={classes.paper__form}>

                    <form noValidate  onSubmit={handleSubmit(handleSaveSubmit)}>
                    <div className={classes.rate}>
                        <Rating
                            name="hover-feedback"
                            value={value}
                            precision={0.5}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                                setHover(newHover);
                            }}
                        />
                        {value !== null && <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>}
                    </div>
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <Typography className={classes.form__title} variant="h2" align="center">Class
                                Review </Typography>
                        </Grid>
                    </Grid>

                        <Grid container>
                            <Grid container>
                                <Grid item xs={12} lg={7}>
                                    <TextField variant="outlined" margin="normal" fullWidth name="pros" id="name"
                                               label="Pros" multiline inputRef={register}
                                               error={Boolean(errors.pros?.message)}
                                               helperText={errors.pros?.message}/>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} lg={7}>
                                    <TextField variant="outlined" margin="normal" fullWidth name="suggestions" id="name"
                                               label="Suggestions"
                                               multiline inputRef={register}
                                               error={Boolean(errors.suggestions?.message)}
                                               helperText={errors.suggestions?.message}/>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.save__button}>
                                <Grid xs={6} item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={anonymous}
                                            onChange={handleChange}
                                            name="anonymous"
                                            color="primary"
                                        />
                                    }
                                    label="Anonymous"
                                />
                                </Grid>
                                <Grid xs={3} item>
                                    <SubmitButton text="Save" isSavingText="Saving"
                                                  variant="contained" color="primary"
                                    />
                                </Grid>

                                <Grid item sm={3}>
                                    <Button type="button" fullWidth variant="contained" color="primary"
                                            onClick={() => {
                                                dispatch({type: classActionsType.SetClassTableStatus, payload: "READ"})
                                            }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
        </Grid>
    );
}

const mapStateToProps = (state) =>

    // console.log('state', state);
     ({
        student: state?.auth.user
    })

;

export default connect(mapStateToProps,
{
    fetchTeachers, updateClassHistory, addClassHistory, addClassReview
}
)(AddClassReview);
