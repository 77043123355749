import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PosterStepper from './PosterStepper';
import { getTotalHeight } from '../../../../../utils/Scripts';
import '../../../../../utils/pixie-image-editor/pixie.min.css'
import {
  b642Image,
  cleanPixieScript,
  DEFAULT_PIXIE_CONFIG,
  PIXIE_HEIGHT_OFFSET,
  PIXIE_ID
} from '../../posterGenHelper';
import PosterStatusPanel from './PosterStatusPanel';

const GroupPosterView = ({ currentStep, setCurrentStep, config, setConfig,
                           submissions, savedPosters, setSavedPosters,
                           onPosterPop }) => {

  const [pixieEditor, setPixieEditor] = useState(null)
  const [isPixieLoaded, setIsPixieLoaded] = useState(false)
  const [isConfigLoaded, setIsConfigLoaded] = useState(false)
  const totalWindowHeight = getTotalHeight()

  // Load Pixie
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '/static/scripts/pixie-image-editor/pixie.min.js'
    script.async = true
    script.onload = () => onPixieLoaded()
    script.id = PIXIE_ID
    document.body.appendChild(script)
  }, [])

  // Change poster
  useEffect(() => {
    setIsConfigLoaded(false)
    if (isPixieLoaded) {
      pixieEditor.loadState(config[currentStep - 1])
        .then(() => setIsConfigLoaded(true))
    }
  }, [isPixieLoaded, currentStep, config])

  const onPixieLoaded = () => {
    if (config) {
      const pixie = new window.Pixie({
        ...DEFAULT_PIXIE_CONFIG,
        onLoad: () => {
          setPixieEditor(pixie)
          setIsPixieLoaded(true)
        },
        ui: {
          ...DEFAULT_PIXIE_CONFIG.ui,
          height: `${totalWindowHeight-PIXIE_HEIGHT_OFFSET}px`
        }
      })
    }
  }

  const handlePop = async () => {
    const canvasImage = pixieEditor.getTool('export').getDataUrl('png')

    // Compress the image size if larger than previewSize to prevent lag
    const previewSize = 500

    const image = await b642Image(canvasImage)
    const width = image.naturalWidth
    const height = image.naturalHeight
    const scale = (width > previewSize || height > previewSize)
      ? height > width ? height / previewSize : width / previewSize
      : 1

    const newWidth = Math.round(width / scale)
    const newHeight = Math.round(height / scale)

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = newWidth
    canvas.height = newHeight
    ctx.drawImage(image, 0, 0, newWidth, newHeight)
    const scaledImage = canvas.toDataURL('image/png')

    onPosterPop(scaledImage)
  }

  const onStepChange = (step) => {
    // Save currentPixieState
    const tempConfig = config
    tempConfig[currentStep-1] = pixieEditor.getState()
    setConfig(tempConfig)

    const tempPosters = savedPosters
    tempPosters[currentStep-1] = pixieEditor.getTool('export').getDataUrl('png')
    setSavedPosters(tempPosters)

    // Clean up Pixie Script
    if (step === 0 || step > submissions.length) {
      cleanPixieScript()
    }
    setCurrentStep(step)
  }

  const renderView = () =>
    <Grid container spacing={3}>
      {/* Display the current poster */}
      <Grid item xs={12}>
        <pixie-editor/>
      </Grid>

      {/* Bottom navigation and status */}
      <Grid item xs={12}>
        <PosterStepper
          onPop={handlePop}
          currentStep={currentStep}
          totalSteps={submissions.length}
          onStepChange={onStepChange}
          disabled={!isConfigLoaded}
        />
      </Grid>

      <Grid item xs={12}>
        <PosterStatusPanel posterList={savedPosters}
                           setCurrentStep={onStepChange}
                           currentStep={currentStep}/>
      </Grid>

    </Grid>

  return renderView()
}

export default GroupPosterView
