import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Chip, Stack } from '@mui/material';

function ChipInput({ value, onChange, ...rest }) {
    const [inputValue, setInputValue] = React.useState('');

    const handleDelete = (chipToDelete) => {
        onChange(value.filter((chip) => chip !== chipToDelete));
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const newValue = event.target.value.trim();

            if (newValue && !value.includes(newValue)) {
                onChange([...value, newValue]);
                setInputValue('');
            }
        }
    };

    return (
        <Stack direction="column" spacing={1}>
            <TextField
                label="Type and press enter"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                variant="outlined"
                fullWidth
                {...rest}
            />
            <Stack direction="row" spacing={1}>
                {value.map((chip, index) => (
                    <Chip
                        key={index}
                        label={chip}
                        onDelete={() => handleDelete(chip)}
                        color="primary"
                    />
                ))}
            </Stack>
        </Stack>
    );
}

ChipInput.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ChipInput;
