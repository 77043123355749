import Draggable from 'react-draggable'
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    keyframes,
    Paper,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import React, {useEffect, useRef, useState} from 'react'
import {makeStyles} from '@mui/styles'
import clsx from 'clsx'
import {Cancel, CloseFullscreen, OpenInFull} from '@mui/icons-material'
import {useDispatch, useSelector} from 'react-redux'
import {noop} from 'lodash'
import {UserSettingActionTypes} from '../../../actions'
import colors from '../../../theme/constants'

export const useStyles = makeStyles(theme => ({
    dialog: {
        '&.windowed': {
            maxHeight: '77vh',
            minWidth: '800px',
            '& .MuiDialog-paper': {
                maxWidth: 'clamp(300px, 80vw, 1000px)'
            }
        },
        '& .titleBar': {
            backgroundColor: colors.secondaryLight,
            padding: theme.spacing(0.5, 2),
            cursor: 'pointer',
            fontSize: '22px'
        },
        '& .content': {
            marginTop: theme.spacing(2),
            minWidth: '300px'
        },
        '& .titleBarButtons': {
            position: 'absolute',
            top: theme.spacing(0.5)
        },
        '& .closeButton': {
            right: 10,
            '&::before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '20px',
                height: '20px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                borderRadius: '50%'
            }
        },
        '& .windowButton': {
            right: 50
        }
    }
}))

const scrollAnimation = keyframes(`
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
`)

function DraggablePaperComponent(props) {
    const dispatch = useDispatch()
    const isFSTrigger = useSelector(state => state?.userSetting.draggableDialogTrigger)
    const [dragPosition, setDragPosition] = useState({x: 0, y: 0})

    useEffect(() => {
        if (isFSTrigger) {
            setDragPosition({x: 0, y: 0})
            dispatch({type: UserSettingActionTypes.ChangeDraggableDialogFS, payload: false})
        }
    }, [isFSTrigger])

    return (
        <Draggable
            handle=".draggableHandle"
            cancel={'[class*="MuiDialogContent-root"]'}
            position={dragPosition}
            onDrag={(e, ui) => {
                setDragPosition({x: ui.x, y: ui.y})
            }}
        >
            <Paper {...props} />
        </Draggable>
    )
}

// default dialogButtonData
export const dialogButtonData = {
    label: null,
    variant: 'contained',
    style: {},
    onClick: () => {},
    disabled: false
}

function DraggableDialog(props) {
    const {
        title,
        open,
        onClose,
        isLoaded = true,
        children,
        isFullscreen = noop,
        footerText,
        dialogCustomButtons = []
    } = props

    const dispatch = useDispatch()
    const classes = useStyles()
    const theme = useTheme()

    const [forceFS, setForceFS] = useState(false)
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const isFS = useSelector(state => state?.userSetting.draggableDialogFS)
    const fSHash = useSelector(state => state?.userSetting.draggableDialogFSHash)

    useEffect(() => {
        setForceFS(isFS)
    }, [fSHash])

    useEffect(() => {
        if (forceFS || fullScreen) {
            dispatch({type: UserSettingActionTypes.ChangeDraggableDialogFS, payload: true})
        }
        isFullscreen(forceFS)
    }, [forceFS, fullScreen])

    const [isOverflow, setIsOverflow] = useState(false)
    const [boxWidth, setBoxWidth] = useState(205)
    const boxRef = useRef(null)

    useEffect(() => {
        setIsOverflow(footerText?.length > 30)
    }, [footerText])

    useEffect(() => {
        const handleResize = () => {
            if (boxRef.current) {
                const newBoxWidth = boxRef.current.offsetWidth
                setBoxWidth(newBoxWidth)
            }
        }
        window.addEventListener('resize', handleResize)
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [footerText])

    return (
        <Dialog
            className={clsx(classes.dialog, {windowed: !(fullScreen || forceFS)})}
            disableScrollLock
            fullScreen={forceFS || fullScreen}
            open={open}
            onClose={onClose}
            PaperComponent={DraggablePaperComponent}
        >
            {isLoaded && (
                <div>
                    {
                        // mobile view don't need this
                        !fullScreen && (
                            <Box className="titleBarButtons windowButton">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    component="span"
                                    onClick={() => setForceFS(!forceFS)}
                                >
                                    {forceFS ? (
                                        <CloseFullscreen className="button" />
                                    ) : (
                                        <OpenInFull className="button" />
                                    )}
                                </IconButton>
                            </Box>
                        )
                    }
                    <Box className="titleBarButtons closeButton">
                        <IconButton color="primary" size="small" component="span" onClick={onClose}>
                            <Cancel className="button" />
                        </IconButton>
                    </Box>
                    <DialogTitle
                        className={clsx('titleBar', {
                            draggableHandle: !fullScreen
                        })}
                    >
                        {title}
                    </DialogTitle>
                    <DialogContent className="content">{children}</DialogContent>
                </div>
            )}
            <div
                style={{
                    position: 'sticky',
                    bottom: 0
                }}
            >
                {footerText && (
                    <DialogActions
                        className={classes.dialogActions}
                        sx={{
                            backgroundColor: 'rgba(235,235,235, 0.9)',
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            padding: '10px 30px',
                            borderRadius: '10px 0px'
                        }}
                    >
                        {footerText && (
                            <Box
                                ref={boxRef}
                                sx={{
                                    maxWidth: '205px',
                                    overflow: 'hidden'
                                }}
                            >
                                <Typography
                                    className="scrollingText"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        animation:
                                            isOverflow || boxWidth < 180
                                                ? `${scrollAnimation} 5s linear infinite`
                                                : 'none',
                                        paddingRight: '20px'
                                    }}
                                >
                                    {footerText}
                                </Typography>
                            </Box>
                        )}

                        {dialogCustomButtons.length > 0 && (
                            <Box
                                sx={{
                                    float: 'right'
                                }}
                            >
                                {dialogCustomButtons.map((button, index) => (
                                    <Button
                                        key={index}
                                        variant={button.variant}
                                        style={button.style}
                                        onClick={button.onClick}
                                        disabled={button.disabled}
                                    >
                                        {button.label}
                                    </Button>
                                ))}
                            </Box>
                        )}
                    </DialogActions>
                )}
            </div>
        </Dialog>
    )
}

export default DraggableDialog
