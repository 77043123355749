import React from 'react';
import {SvgIcon} from '@mui/material';
import Button from '@mui/material/Button';
import {PlusCircle as PlusIcon} from 'react-feather';
import {connect, useDispatch} from "react-redux";
import {employeeActions} from "../../actions"
import ViewHead from '../../components/public/ViewHead';
import {Functions} from '../../utils/Functions';
import AddUsersForm from './AddEmployeeForm';
import useAuthCheck from '../../hooks/useAuthCheck';
import EmployeeTable from './EmployeeTable';



function Employees({employeeFormStatus}) {
    const dispatch = useDispatch();
    // useCurrentViewCheck(Functions.System_Management_Users);
    const [isAdd, isEdit] = useAuthCheck(
        [Functions.Employee_Management_Employee_List_Add_Employee,
            Functions.Employee_Management_Employee_List_Edit_Employee,
        ])

    // for add button
    const topButtons = () => {
        if (isAdd) {
            return (
                <Button color="secondary"
                        variant="contained"
                        onClick={() => {
                            dispatch({type: employeeActions.SetEmployeeFormStatus, payload: "ADD"})
                        }}
                        startIcon={<SvgIcon fontSize="small"><PlusIcon/></SvgIcon>}>
                    Add Employees
                </Button>
            )
        }
            return <div />
        
    }

    const returnButton=()=>(   <Button type="button"  variant="contained" color="primary" onClick={() => {
            dispatch({type: employeeActions.SetEmployeeFormStatus, payload: "Close"})
        }}>
            Return
        </Button>
     )

    // determines what to render
    const renderTable = () => {
        switch (employeeFormStatus) {
            case "ADD":
                return <AddUsersForm/>
            // case "EDIT":
            //     return <EmployeeInfo/>;
            // case "READ":
            //     return <EmployeeDetails/>;

            default:
                return <EmployeeTable isEdit={isEdit}/>
        }
    }


    return (
        <ViewHead noMaxWidth
                  functionId={Functions.Employee_Management_Employee_List}
                  topButtons={employeeFormStatus === "Close" ? topButtons() : returnButton()} >
            {renderTable()}
        </ViewHead>

    )
}

export default connect(
    ({employee}) => ({employeeFormStatus: employee.employeeFormStatus}),
    {}
)(Employees);
