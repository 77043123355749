

export const userAvatarReducer =(state="123",action={})=>{



    switch(action.type){
        case 'changeTime':

            return{
            ...state,uploadTime:action.payload
    }

    default:
        return state;
}

}

export default userAvatarReducer