import React from "react";
import { Navigate } from 'react-router';
import CampaignEditPosterView from '../../../views/campaign/campaignDetails/poster/campaignEditPosterView';
import CampaignAddPosterView from '../../../views/campaign/campaignDetails/poster/campaignAddPosterView';
import CampaignDataView from '../../../views/campaign/campaignDetails';
import CampaignEditView from '../../../views/campaign/campaignList/editCampaignView';
import CreateCampaignView from '../../../views/campaign/campaignList/addNewCampaignView';
import CampaignCategoryManagerView from '../../../views/campaign/campaignCategory';
import CampaignListView from '../../../views/campaign/campaignList';

const campaignRoutes = [
  {
    path: 'campaign',
    routes: [
      {
        path:'',
        exact: 'true',
        component:<CampaignListView/>
      },
      {
        path:'category',
        exact: 'true',
        component:<CampaignCategoryManagerView/>
      },
      {
        path:'add',
        exact: 'true',
        component:<CreateCampaignView/>
      },
      {
        path:'edit/:id',
        exact: 'true',
        component:<CampaignEditView/>
      },
      {
        path:'view/:id',
        exact: 'true',
        component:<CampaignDataView/>
      },
      {
        path:'view/:campaignId/add',
        exact: 'true',
        component:<CampaignAddPosterView/>
      },
      {
        path:'view/:campaignId/edit/:posterId',
        exact: 'true',
        component:<CampaignEditPosterView/>
      },
      {
        path:'view/:campaignId/download/:posterId',
        exact: 'true',
        component:<CampaignEditPosterView/>
      },
      {
        component:<Navigate to="404"/>
      },
    ]
  }
]

export default campaignRoutes
