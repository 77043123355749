import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Button, Paper, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import ViewHead from '../../../../components/public/ViewHead';
import { InterviewActionType } from '../../../../actions/customer/interviewActions';
import { a11yProps } from '../../../../components/mui/tabs/TabPanel';
import InterviewListTabView from './InterviewListTabView';
import useAuthCheck from '../../../../hooks/useAuthCheck';

function InterviewListView() {
  useCurrentViewCheck(Functions.Interview_List)
  const  navigate = useNavigate();
  const [canCreateInterview] = useAuthCheck([Functions.Interview_Create])

  const dispatch = useDispatch()
  const currentTab = useSelector(state => state?.interview.currentInterviewTab)
  const handleChangeTab = (event, newValue) =>
    dispatch({ type: InterviewActionType.ChangeInterviewTab, payload: newValue })

  const renderTabs = () =>
    <Paper elevation={0} style={{paddingBottom: '16px'}}>
      <AppBar position='sticky' color='default'>
        <Tabs value={currentTab} onChange={handleChangeTab}>
          <Tab label="All" {...a11yProps(0)} />
          <Tab label="Upcoming" {...a11yProps(1)} />
          <Tab label="Finished" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <InterviewListTabView />

    </Paper>

  const renderTopButtons = () =>
    <div>
      {
        canCreateInterview &&
        <Button color='secondary'
                variant='contained'
                style={{ marginRight: '1rem' }}
                onClick={() => navigate('/app/customer/interview/add')}>
          Add Interview
        </Button>
      }
      <Button color='secondary'
              variant='contained'
              onClick={() => navigate('/app/customer/interview/planner')}>
        Schedule
      </Button>
    </div>

  return (
    <ViewHead functionId={Functions.Interview_List} noMaxWidth topButtons={renderTopButtons()} >
      {
        renderTabs()
      }
    </ViewHead>
  )
}

export default InterviewListView
