import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from 'react'
import {
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    Autocomplete,
    debounce,
    Box
} from '@mui/material'
import moment from 'moment'
import {useForm} from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
// import Box from "@mui/material/Box";
import {
    createInterview,
    // fetchAllCustomers,
    // fetchAllInterviewers,
    // fetchAllProducts,
    updateInterview
} from '../../../../actions/customer/interviewActions'
import {
    INTERVIEW_STATUS,
    INTERVIEW_STATUS_DICT,
    INVOICE_EDITOR_ITEM_VALIDATOR,
    useInterviewEditorStyles
} from '../interviewHelper'
import {fetchAllProductForSearchBar, ProductsActions, sendErrorMessage} from '../../../../actions'
import SubmitButton from '../../../../components/mui/button/SubmitButton'
import {
    AdministrativeActionType,
    fetchAllCustomersForSearchBar,
    fetchAllTeacherForSearchBar
} from '../../../../actions/administrative/administrativeAction'
import {DEFAULT_FETCH_PARAM} from '../../../../components/mui/datagrid/PageGrid'
import SearchBox from '../../../../components/public/SearchBox'

const schema = yup.object().shape(INVOICE_EDITOR_ITEM_VALIDATOR)
function InterviewAdd({
    isEdit,
    originalData = {},
    // defaultSelectedUser = null,
    onSubmitSuccess
}) {
    const {register, handleSubmit, errors, setError} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })

    const dispatch = useDispatch()
    const classes = useInterviewEditorStyles()
    const filteredCustomerList = useSelector(state => state?.administrative?.filteredCustomers)
    const filteredInterviewList = useSelector(state => state?.administrative?.filteredCustomers)
    const filterProducts = useSelector(state => state?.products?.filterProduct)
    const fetchProps = useSelector(state => state?.interview?.interviewFetchBody)
    // const [listOpen, setListOpen] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const [isLoaded, setIsLoaded] = useState(false)
    // const isLoaded = true
    const [isCustomersLoaded, setIsCustomersLoaded] = useState(false)
    const [isInterviewersLoaded, setIsInterviewersLoaded] = useState(false)
    const [isProductsLoaded, setIsProductsLoaded] = useState(false)

    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [selectedInterviewer, setSelectedInterviewer] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState('')

    const [customerSearchArgs, setCustomerSearchArgs] = useState({
        searchKey: undefined
    })

    const [interviewSearchArgs, setInterviewSearchArgs] = useState({
        searchKey: undefined
    })
    const [productSearchArgs, setProductSearchArgs] = useState({
        searchKey: undefined
    })

    // useEffect(async () => {
    //         setIsLoaded(true)
    //         if (isEdit) {
    //             // Fill default values
    //             setSelectedProduct(await fetchAllProducts()(dispatch))
    //             setSelectedCustomer(await fetchAllCustomers()(dispatch))
    //             setSelectedInterviewer(await fetchAllInterviewers()(dispatch))
    //             setSelectedStatus(originalData.status)
    //         }
    //
    //         // Set default selected customer
    //         if (defaultSelectedUser) {
    //             const foundCustomer = allCustomers.find(c => c.id === defaultSelectedUser)
    //             if (foundCustomer) {
    //                 setSelectedCustomer(foundCustomer)
    //             }
    //         }
    // }, [isCustomersLoaded, isInterviewersLoaded, isProductsLoaded])

    const {searchKey: customerSearchKey} = customerSearchArgs

    const loadAllCustomerData = async () => {
        setIsLoaded(true)
        if (customerSearchKey !== undefined) {
            setIsCustomersLoaded(false)
            await fetchAllCustomersForSearchBar({
                searchKey: customerSearchKey,
                ...DEFAULT_FETCH_PARAM
            })(dispatch).then(res => {
                setIsCustomersLoaded(res)
            })
        }
    }
    useEffect(loadAllCustomerData, [customerSearchArgs])

    const {searchKey: interviewSearchKey} = interviewSearchArgs
    const loadAllInterviewData = async () => {
        setIsLoaded(true)
        if (interviewSearchKey !== undefined) {
            setIsInterviewersLoaded(false)
            await fetchAllTeacherForSearchBar({
                searchKey: interviewSearchKey,
                ...DEFAULT_FETCH_PARAM
            })(dispatch).then(res => {
                setIsInterviewersLoaded(res)
            })
        }
    }
    useEffect(loadAllInterviewData, [interviewSearchArgs])
    //
    const {searchKey: productSearchKey} = productSearchArgs
    const loadAllProductData = async () => {
        setIsLoaded(true)
        if (productSearchKey !== undefined) {
            setIsProductsLoaded(false)
            await fetchAllProductForSearchBar({
                searchKey: productSearchKey,
                ...DEFAULT_FETCH_PARAM
            })(dispatch).then(res => {
                setIsProductsLoaded(res)
            })
        }
    }
    useEffect(loadAllProductData, [productSearchArgs])

    const handleCustomerSearchChange = searchVal => {
        const searchStr = searchVal?.trim()?.toLowerCase()
        if (searchStr === '' || searchStr === undefined) {
            dispatch({type: AdministrativeActionType.ResetCustomersFilterList})
            return
        }
        setCustomerSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }
    const handleCustomerSearch = debounce(searchVal => {
        handleCustomerSearchChange(searchVal)
    }, 500)

    const handleInterviewSearchChange = searchVal => {
        const searchStr = searchVal?.trim()?.toLowerCase()
        if (searchStr === '' || searchStr === undefined) {
            dispatch({type: AdministrativeActionType.ResetCustomersFilterList})
            return
        }
        setInterviewSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }
    const handleInterviewSearch = debounce(searchVal => {
        handleInterviewSearchChange(searchVal)
    }, 500)

    const handleProductSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase()
        if (searchStr === '' || searchStr === undefined) {
            dispatch({type: ProductsActions.ResetProductFilterList})
            return
        }
        setProductSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }
    const handleProductSearch = debounce(searchVal => {
        handleProductSearchChange(searchVal)
    }, 500)

    const onError = msg => {
        setIsSaving(false)
        sendErrorMessage(dispatch, msg)
    }

    const onSubmit = async formData => {
        setIsSaving(true)

        const momentStart = moment(formData.scheduledStartTime)
        const momentEnd = moment(formData.scheduledEndTime)

        if (!isEdit) {
            // Only check when creating
            const current = moment()
            if (momentStart.isBefore(current)) {
                setIsSaving(false)
                return setError('scheduledStartTime', {type: 'manual', message: 'Start time must be in the future'})
            }

            if (momentEnd.isBefore(current)) {
                setIsSaving(false)
                return setError('scheduledEndTime', {type: 'manual', message: 'End time must be in the future'})
            }
        }

        // The time cannot be equal
        if (momentStart.isSameOrAfter(momentEnd)) {
            setIsSaving(false)
            return setError('scheduledStartTime', {type: 'manual', message: 'Start time must be before end time'})
        }

        let data = {
            customerId: selectedCustomer?.id,
            interviewerId: selectedInterviewer?.id,
            productId: selectedProduct?.id,
            type: formData?.type,
            scheduledStartTime: momentStart?.toISOString(),
            scheduledEndTime: momentEnd?.toISOString()
        }

        if (isEdit) {
            data = {
                ...data,
                id: originalData.id,
                score: formData.score.trim() !== '' ? parseFloat(formData.score) : null,
                status: selectedStatus,
                startTime: formData.startTime !== '' ? moment(formData.startTime).toISOString() : null,
                endTime: formData.endTime !== '' ? moment(formData.endTime).toISOString() : null
            }
            return updateInterview(data, fetchProps, onSubmitSuccess, onError)(dispatch)
        }
        return createInterview(data, fetchProps, onSubmitSuccess, onError)(dispatch)
    }

    const renderForm = () => (
        <Grid container spacing={4} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h4">Customer</Typography>
                <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.customer?.message)}>
                    <Autocomplete
                        disabled={isSaving}
                        options={filteredCustomerList || []}
                        noOptionsText="No Customer"
                        getOptionLabel={option => `${option.id}. ${option.firstName} ${option.lastName}`}
                        value={selectedCustomer}
                        onChange={(e, v) => setSelectedCustomer(v)}
                        renderOption={(props, option) => (
                            <Box {...props} key={option.id}>
                                {option.isActive ? (
                                    <div>
                                        {`${option.firstName} ${
                                            option?.nickName !== '' ? `(${option?.nickName})` : ''
                                        } ${option.lastName} (${option.email})`}
                                    </div>
                                ) : (
                                    <del>
                                        {`${option.firstName} ${
                                            option?.nickName !== '' ? `(${option?.nickName})` : ''
                                        } ${option.lastName} (${option.email})`}
                                    </del>
                                )}
                            </Box>
                        )}
                        isOptionEqualToValue={(option, value) =>
                            option.firstName === value.firstName &&
                            option.nickName === value.nickName &&
                            option.lastName === value.lastName
                        }
                        renderInput={params => (
                            <div className={classes.searchBox}>
                                <SearchBox
                                    placeholder="Search..."
                                    size="small"
                                    type="search"
                                    searchHandler={handleCustomerSearch}
                                    isLoading={!isCustomersLoaded && isLoaded && customerSearchKey !== undefined}
                                    params={params}
                                    inputProps={params.InputProps}
                                />
                            </div>
                        )}
                    />
                    <FormHelperText error={Boolean(errors.customer?.message)}>
                        {errors.customer?.message ? errors.customer?.message : ''}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">Interviewer</Typography>
                <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.interviewer?.message)}>
                    <Autocomplete
                        disabled={isSaving}
                        options={filteredInterviewList || []}
                        noOptionsText="No Interviewer"
                        getOptionLabel={option => `${option.id}. ${option.firstName} ${option.lastName}`}
                        value={selectedInterviewer}
                        onChange={(e, v) => setSelectedInterviewer(v)}
                        renderOption={(props, option) => (
                            <Box {...props} key={option.id}>
                                {option.isActive ? (
                                    <div>
                                        {`${option.firstName} ${
                                            option?.nickName !== '' ? `(${option?.nickName})` : ''
                                        } ${option.lastName} (${option.email})`}
                                    </div>
                                ) : (
                                    <del>
                                        {`${option.firstName} ${
                                            option?.nickName !== '' ? `(${option?.nickName})` : ''
                                        } ${option.lastName} (${option.email})`}
                                    </del>
                                )}
                            </Box>
                        )}
                        isOptionEqualToValue={(option, value) =>
                            option.firstName === value.firstName &&
                            option.nickName === value.nickName &&
                            option.lastName === value.lastName
                        }
                        renderInput={params => (
                            <div className={classes.searchBox}>
                                <SearchBox
                                    placeholder="Search..."
                                    size="small"
                                    type="search"
                                    searchHandler={handleInterviewSearch}
                                    isLoading={!isInterviewersLoaded && isLoaded && interviewSearchKey !== undefined}
                                    params={params}
                                    inputProps={params.InputProps}
                                />
                            </div>
                        )}
                    />
                    <FormHelperText error={Boolean(errors.interviewer?.message)}>
                        {errors.interviewer?.message ? errors.interviewer?.message : ''}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">Product</Typography>
                <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.product?.message)}>
                    <Autocomplete
                        disabled={isSaving}
                        options={filterProducts || []}
                        noOptionsText="No Product"
                        getOptionLabel={option => option.name}
                        value={selectedProduct}
                        onChange={(e, v) => setSelectedProduct(v)}
                        renderOption={(props, option) => (
                            <Box {...props} key={option.id}>
                                {option.isActive ? <div>{`${option.name}`}</div> : <del>{`${option.name}`}</del>}
                            </Box>
                        )}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        renderInput={params => (
                            <div className={classes.searchBox}>
                                <SearchBox
                                    placeholder="Search..."
                                    size="small"
                                    type="search"
                                    searchHandler={handleProductSearch}
                                    isLoading={!isProductsLoaded && isLoaded && productSearchKey !== undefined}
                                    params={params}
                                    inputProps={params.InputProps}
                                />
                            </div>
                        )}
                    />
                    <FormHelperText error={Boolean(errors.interviewer?.message)}>
                        {errors.interviewer?.message ? errors.interviewer?.message : ''}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">Type</Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    inputRef={register}
                    label="Type"
                    name="type"
                    className={classes.autofillForm}
                    defaultValue={isEdit ? originalData.type : ''}
                    error={Boolean(errors.type?.message)}
                    helperText={errors.type?.message || 'The interview method; ie. onsite, zoom, etc'}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" className={classes.marginBottom}>
                    Time
                </Typography>
                <Grid container spacing={6} justifyContent="space-evenly">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            inputRef={register}
                            fullWidth
                            required
                            name="scheduledStartTime"
                            label="Scheduled Start Time"
                            type="datetime-local"
                            InputLabelProps={{shrink: true}}
                            disabled={isSaving}
                            defaultValue={
                                isEdit
                                    ? moment(originalData.scheduledStartTime).format('YYYY-MM-DDTHH:mm')
                                    : moment()
                                          .set({hour: 12, minute: 0, second: 0, millisecond: 0})
                                          .add(1, 'd')
                                          .format('YYYY-MM-DDTHH:mm')
                            }
                            error={Boolean(errors.scheduledStartTime?.message)}
                            helperText={errors.scheduledStartTime?.message ? errors.scheduledStartTime?.message : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            inputRef={register}
                            fullWidth
                            required
                            name="scheduledEndTime"
                            label="Scheduled End Time"
                            type="datetime-local"
                            InputLabelProps={{shrink: true}}
                            disabled={isSaving}
                            defaultValue={
                                isEdit
                                    ? moment(originalData.scheduledEndTime).format('YYYY-MM-DDTHH:mm')
                                    : moment()
                                          .set({hour: 13, minute: 0, second: 0, millisecond: 0})
                                          .add(1, 'd')
                                          .format('YYYY-MM-DDTHH:mm')
                            }
                            error={Boolean(errors.scheduledEndTime?.message)}
                            helperText={errors.scheduledEndTime?.message ? errors.scheduledEndTime?.message : ''}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    const renderEditOnlyForm = () => (
        <Grid container spacing={4} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h4" className={classes.detailsView}>
                    Details
                </Typography>
                <FormHelperText>
                    Change the following to manually override interview details; Eg. If there was a mistake
                </FormHelperText>
                <Grid container spacing={6} justifyContent="space-evenly" className={classes.detailsDate}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            inputRef={register}
                            fullWidth
                            name="startTime"
                            label="Interview Start Time"
                            type="datetime-local"
                            InputLabelProps={{shrink: true}}
                            disabled={isSaving}
                            defaultValue={
                                isEdit && originalData.startTime
                                    ? moment(originalData.startTime).format('YYYY-MM-DDTHH:mm')
                                    : ''
                            }
                            error={Boolean(errors.startTime?.message)}
                            helperText={errors.startTime?.message ? errors.startTime?.message : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            inputRef={register}
                            fullWidth
                            name="endTime"
                            label="Interview End Time"
                            type="datetime-local"
                            InputLabelProps={{shrink: true}}
                            disabled={isSaving}
                            defaultValue={
                                isEdit && originalData.endTime
                                    ? moment(originalData.endTime).format('YYYY-MM-DDTHH:mm')
                                    : ''
                            }
                            error={Boolean(errors.endTime?.message)}
                            helperText={errors.endTime?.message ? errors.endTime?.message : ''}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={6} justifyContent="space-evenly">
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required disabled={isSaving} className={classes.status}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                size="small"
                                fullWidth
                                name="status"
                                input={<Input />}
                                value={selectedStatus}
                                onChange={e => setSelectedStatus(e.target.value)}
                            >
                                {Object.keys(INTERVIEW_STATUS).map((u, index) => (
                                    <MenuItem key={index} value={INTERVIEW_STATUS[u]}>
                                        {INTERVIEW_STATUS_DICT[INTERVIEW_STATUS[u]]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth className={classes.score}>
                            <TextField
                                fullWidth
                                size="small"
                                name="score"
                                label="Score"
                                type="number"
                                disabled={isSaving}
                                inputRef={register}
                                defaultValue={originalData.score}
                                inputProps={{min: 0, max: 5, step: 0.5}}
                                error={Boolean(errors.score?.message)}
                                helperText={errors.score?.message}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    const renderSubmit = () => (
        <Grid container spacing={3} justifyContent="flex-end" alignItems="center" className={classes.submitButton}>
            <Grid item>
                <SubmitButton
                    isSaving={isSaving}
                    text="Save"
                    isSavingText="Saving"
                    fullWidth
                    variant="contained"
                    color="primary"
                />
            </Grid>
        </Grid>
    )

    return (
        <div>
            {isLoaded ? (
                <Paper className={classes.paper}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {renderForm()}
                        {isEdit && renderEditOnlyForm()}
                        {renderSubmit()}
                    </form>
                </Paper>
            ) : (
                <Typography variant="h3" color="textPrimary">
                    Loading...
                </Typography>
            )}
        </div>
    )
}

export default InterviewAdd
