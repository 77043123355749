import React from "react";
import { useSelector} from "react-redux";
import ViewHead from "../../../components/public/ViewHead";
import {Functions} from "../../../utils/Functions";
import {
   addProductCategory,
    deleteProductCategory,
    fetchProductCategory,
    updateProductCategory
} from "../../../actions";
import CategoryManager from "../../../components/form/CategoryManager";


function ProductCategoryManager() {

      const allCategories = useSelector(state => state.products.productCategories)


    return(
     <ViewHead functionId={Functions.Product_Product_Category}  isShowTitle={false} >
         <CategoryManager allCategories={allCategories} fetchCategoryAction={fetchProductCategory}
           updateCategoryAction={updateProductCategory}
                          addCategoryAction={addProductCategory}
                          deleteCategoryAction={deleteProductCategory}
                          TitleName="Product Category"
         />
     </ViewHead>
    );
}


export  default ProductCategoryManager;