import React from 'react'
import {connect} from 'react-redux'
import ProductEditor from './ProductEditor'
import {updateProduct} from '../../actions'

function ReadProductForm({id, editProduct, cb}) {
    return (
        <ProductEditor
            cb={cb}
            isEdit
            isReadOnly
            id={id}
            editProduct={{...editProduct, hosts: editProduct?.hosts?.join('\n')}}
        />
    )
}

export default connect(
    ({products}) => ({
        editProduct: products.readProduct,
        id: products.id,
        categories: products.categories
    }),
    {updateProduct}
)(ReadProductForm)
