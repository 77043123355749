import React, { useState } from 'react';
import { useParams } from 'react-router';
import {
  FormControl, FormHelperText,
  Grid,
  IconButton,
  InputAdornment, InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SubmitButton from '../../components/mui/button/SubmitButton';
import { activateNewCustomer, sendErrorMessage, sendSuccessMessage } from '../../actions';

const useStyles = makeStyles(() => ({
  centerGrid: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputField: {
    margin: '2rem 0'
  }
}))


const schema = yup.object().shape({
  password: yup.string().min(8).max(20).trim(),
})

function ActiveCustomerView() {
  const  navigate = useNavigate();
  const dispatch = useDispatch()
  const classes = useStyles()
  const uid = parseInt(useParams().uid,10)
  const {vid} = useParams()

  const [values, setValues] = useState({
    showPassword: false,
  });
  const [isSaving, setIsSaving] = useState(false)

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
  })

  const activateUser = (formData) => {
    setIsSaving(true)
    activateNewCustomer(
      { id: uid, vcode: vid, newPassword: formData.password }
      , onSuccess, onError)(dispatch)
  }

  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  const onError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    navigate('/auth/login')
  }

  return (
    <div className={classes.centerGrid}>
      <Grid item xs={11} md={6} lg={3}>
        <form onSubmit={handleSubmit(activateUser)}>
          <Typography variant='h3'>Enter a password</Typography>


          {/* <TextField variant='outlined' margin='normal' fullWidth name='password' id='password' */}
          {/*           label='Password' size='small' inputRef={register} */}
          {/*           error={Boolean(errors.password?.message)} */}
          {/*           helperText={errors.password?.message} */}
          {/* /> */}

          <FormControl fullWidth variant="outlined" size="small" className={classes.inputField}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              inputRef={register} fullWidth
              name="password" autoComplete="password"
              labelWidth={75} type={values.showPassword ? 'Text' : 'Password'}
              error={Boolean(errors.password?.message)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error={Boolean(errors.password?.message)}>
              {errors.password?.message}
            </FormHelperText>
          </FormControl>

          <SubmitButton isSaving={isSaving} text='Submit' isSavingText='Submitting'
                        fullWidth variant='contained' color='primary'
          />
        </form>
      </Grid>
    </div>
  );

}

export default ActiveCustomerView
