import React from 'react';
import { connect, useDispatch } from 'react-redux';
import Grid from "@mui/material/Grid";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {debounce} from "lodash"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SubmitButton from '../../components/mui/button/SubmitButton';
import { createEmployee, emailCheck, employeeActions } from '../../actions';
import AddressAutoComplete from "../../components/api/AddressAutoComplete";
import { EMPLOYEE_CREATE_VALIDATOR, employeeStatus } from './employeeHelper';
import { validateEmail } from '../customer/register/customerHelper';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper__form: {
    padding: "2rem",
    margin: "0 auto",
  },
  form__title: {
    marginBottom: ".5rem",
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 120,
  },
}))

const schema = yup.object().shape(EMPLOYEE_CREATE_VALIDATOR);

function AddEmployeeForm({createEmployee,error,isSearching,emailCheck}) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const {register, errors, handleSubmit,setValue} = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  })
  const [isSaving, setIsSaving] = React.useState(false);

  const [status,setStatus] =React.useState('fullTime');

  const catchError = () => {
    setIsSaving(false);
  }
  const afterSaving = () => {
    setIsSaving(false);
  }
  const handleSaveSubmit = (formData) => {

    const keys = Object.keys(formData);

    for(let i = 0;i<keys.length;i+=1){
      if(!formData[keys[i]]){
         delete formData[keys[i]]
      }
    }
    formData={...formData,staffStatus:status, roleIds: []}
    createEmployee(formData,afterSaving,catchError);
    setIsSaving(true);
  }

  const handleAddressCallback=(value)=>{
    setValue('country',value.country)
    setValue('city',value.city)
  }

  // // for select
  // const [role, setRole] = React.useState('');
  // const handleRole = event => setRole(event.target.value)
  // // for activity
  // const [status, setStatus] = React.useState('');
  // const handleStatus = event => setStatus(event.target.value)

  const changeHandler=debounce((val)=>{
    if(!val||!validateEmail(val)){
      dispatch({type:employeeActions.ContinueCreate})
    }
    if(val && validateEmail(val)){
      emailCheck(val)
    }
  },500)
  return (
    <Paper className={classes.paper__form}>
      <Typography className={classes.form__title} variant="h2" align="center">
        Creating a New Employee
      </Typography>
      <form noValidate onSubmit={handleSubmit(handleSaveSubmit)}>
        <Grid container spacing={2} justifyContent='flex-start'>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" margin="normal" name="firstName" id="firstName"
                       label="FirstName *" size="small" fullWidth
                       autoComplete="firstName" autoFocus inputRef={register}
                       error={Boolean(errors.firstName?.message)}
                       helperText={errors.firstName?.message}
                       InputLabelProps={{
                         shrink: true,
                       }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" margin="normal" name="nickName" id="nickName"
                       label="NickName *" size="small" fullWidth
                       autoComplete="nickName" inputRef={register}
                       error={Boolean(errors.nickName?.message)}
                       helperText={errors.nickName?.message}
                       InputLabelProps={{
                         shrink: true,
                       }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" margin="normal" name="lastName" id="lastName"
                       label="LastName *" size="small" fullWidth
                       autoComplete="lastName" inputRef={register}
                       error={Boolean(errors.lastName?.message)}
                       helperText={errors.lastName?.message}
                       InputLabelProps={{
                         shrink: true,
                       }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" margin="normal" name="email" id="email"
                       label="Email *" size="small" fullWidth
                       autoComplete="email" inputRef={register}
                       error={Boolean(errors.email?.message)}
                       helperText={errors.email?.message}
                       InputLabelProps={{
                         shrink: true,
                       }}
                       InputProps={{
                         endAdornment: (
                           <InputAdornment position="end">
                             {isSearching?<CircularProgress size="20px" />:''}
                           </InputAdornment>
                         )
                       }}
                       onChange={(e)=>changeHandler(e.target.value)}
            />
            <div style={{paddingLeft:"10px",color:"#F44336",fontSize:"12px"}}>{error}</div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" margin="normal" name="phone" id="phone"
                       label="Phone *" size="small" fullWidth
                       autoComplete="phone" inputRef={register}
                       error={Boolean(errors.phone?.message)}
                       helperText={errors.phone?.message}
                       InputLabelProps={{
                         shrink: true,
                       }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" margin="normal" name="wechat" id="wechat"
                       label="WeChat" size="small" fullWidth
                       autoComplete="wechat" inputRef={register}
                       InputLabelProps={{
                         shrink: true,
                       }}
                       error={Boolean(errors.wechat?.message)}
                       helperText={errors.wechat?.message}
            />
          </Grid>


          <Grid item xs={12} sm={12}>
            <AddressAutoComplete variant="outlined" margin="normal"
                                 label='Address' fullWidth
                                 register={register} TextComponent={TextField}
                                 handleAddressCallBack={handleAddressCallback} currentAddress=""  className={classes.addressAuto}
                                 error={
                                   Boolean(errors.address?.message)
                                 }    helperText={errors.address?.message}
                                 InputLabelProps={{
                                   shrink: true,
                                 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" margin="normal" name="city" id="city"
                       label="City" size="small" fullWidth
                       autoComplete="city" inputRef={register}
                       InputLabelProps={{
                         shrink: true,
                       }}
                       error={Boolean(errors.city?.message)}
                       helperText={errors.city?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" margin="normal" name="zipcode" id="zipcode"
                       label="Zipcode" size="small" fullWidth
                       autoComplete="zipcode" inputRef={register}
                       InputLabelProps={{
                         shrink: true,
                       }}
                       error={Boolean(errors.zipcode?.message)}
                       helperText={errors.zipcode?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" margin="normal" name="country" id="country"
                       label="Country" size="small" fullWidth
                       autoComplete="country" inputRef={register}
                       InputLabelProps={{
                         shrink: true,
                       }}
                       error={Boolean(errors.country?.message)}
                       helperText={errors.country?.message}
            />
          </Grid>
           <Grid item xs={12} sm={6}>
             <FormControl fullWidth>
                <TextField
                  select variant='outlined' fullWidth
                   margin="normal" size='small'
                   label='Status' name='status'
                   value={status}
                   error={Boolean(errors.status?.message)}
                   helperText={errors.status?.message}
                   id='status'
                   InputLabelProps={{
                     shrink: true,
                   }}
                   onChange={(e) => {
                     setStatus(e.target.value)
                   }}
                >
                { employeeStatus.map((value,index)=>(
                       <MenuItem key={index} value={value}>
                         {value}
                       </MenuItem>))
                  }
                </TextField>
             </FormControl>
           </Grid>

        <br/><br/>

        {/* <FormControl required component="fieldset" className={classes.formControl}> */}
        {/*  <FormLabel component="legend">Roles</FormLabel> */}
        {/*  <RadioGroup aria-label="role" name="role" value={role} onChange={handleRole}> */}
        {/*    <FormControlLabel value="interviewer" control={<Radio />} label="Interviewer" inputRef={register}/> */}
        {/*    <FormControlLabel value="sales" control={<Radio />} label="Sales" inputRef={register}/> */}
        {/*    <FormControlLabel value="teacher" control={<Radio />} label="Teacher" inputRef={register}/> */}
        {/*    <FormControlLabel value="pointedResponder" control={<Radio />} label="Pointed Responder" inputRef={register}/> */}
        {/*    <FormControlLabel value="partTime" control={<Radio />} label="Part Time" inputRef={register}/> */}
        {/*  </RadioGroup> */}
        {/*  /!*<FormHelperTe  xt>You can display an error</FormHelperText>*!/ */}
        {/* </FormControl> */}

        {/* <FormControl required component="fieldset" className={classes.formControl}> */}
        {/*  <FormLabel component="legend">Status</FormLabel> */}
        {/*  <RadioGroup aria-label="status" name="status" value={status} onChange={handleStatus}> */}
        {/*    <FormControlLabel value="active" control={<Radio />} label="Active" inputRef={register}/> */}
        {/*    <FormControlLabel value="inactive" control={<Radio />} label="Inactive" inputRef={register}/> */}
        {/*  </RadioGroup> */}
        {/* </FormControl> */}

        {/* <FormControl required component="fieldset" className={classes.formControl}> */}
        {/*  <FormLabel component="legend">Service Time</FormLabel> */}
        {/*  <FormGroup> */}
        {/*    <FormControlLabel */}
        {/*      control={<Checkbox name="mon" />} */}
        {/*      label="Mon 8:30AM-6:30PM" */}
        {/*      inputRef={register} */}
        {/*    /> */}
        {/*    <FormControlLabel */}
        {/*      control={<Checkbox name="tue" />} */}
        {/*      label="Tue 8:30AM-6:30PM" */}
        {/*      inputRef={register} */}
        {/*    /> */}
        {/*    <FormControlLabel */}
        {/*      control={<Checkbox name="wed" />} */}
        {/*      label="Wed 8:30AM-6:30PM" */}
        {/*      inputRef={register} */}
        {/*    /> */}
        {/*    <FormControlLabel */}
        {/*      control={<Checkbox name="thu" />} */}
        {/*      label="Thu 8:30AM-6:30PM" */}
        {/*      inputRef={register} */}
        {/*    /> */}
        {/*    <FormControlLabel */}
        {/*      control={<Checkbox name="fri" />} */}
        {/*      label="Fri 8:30AM-6:30PM" */}
        {/*      inputRef={register} */}
        {/*    /> */}
        {/*  </FormGroup> */}
        {/*  /!*<FormHelperText>You can display an error</FormHelperText>*!/ */}
        {/* </FormControl> */}

        <FormHelperText error={Boolean(errors.class?.message)}>
          {errors.class?.message}
        </FormHelperText>

          <Grid item sm={3}>
            <SubmitButton  fullWidth variant="contained" color="primary"
                           isSaving={isSaving} text="Save" isSavingText="Saving"/>
          </Grid>
          <Grid item sm={3}>
            <Button type="button" fullWidth variant="contained" color="primary" onClick={() => {
              dispatch({type: employeeActions.SetEmployeeFormStatus, payload: "Close"})
            }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>

    </Paper>
  );
}
const mapStateToProps=(state)=>({
  error:state.employee?.error,
  isSearching:state.employee?.isSearching
})
export default connect(mapStateToProps, {createEmployee,emailCheck})(AddEmployeeForm);
