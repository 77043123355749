import plugin from '@ckeditor/ckeditor5-core/src/plugin'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
class FileUploads extends plugin {
    init() {
        const editor = this.editor
        editor?.ui?.componentFactory?.add('fileUploads', () => {
            const button = new ButtonView()
            button?.set({
                label: '↑_↑',
                withText: true
            })
            //Execute a callback function when the button is clicked.
            //The callback function is to upload a file
            button?.on('execute', () => {
                const {handleVisibleDialog} = editor?.config?.get('fileUploads')
                handleVisibleDialog(true)
            })
            return button
        })
    }
}

export default FileUploads
