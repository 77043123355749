import React from "react";
import { Navigate } from 'react-router';
import ClassInfo from '../../../views/training/class';

const classRoutes = [
  {
    path: 'class',
    routes: [
      {
        path: "",
        exact: 'true',
        component:<ClassInfo/>
      },
      {
        component:<Navigate to="404"/> // this must be the last one
      },

    ]
  },
]
export default classRoutes;
