import React, {useEffect, useState} from 'react'
import {Badge, Button, ButtonGroup, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {Link, ListAlt} from '@mui/icons-material'
import FormControl from '@mui/material/FormControl'
import {Copy, Edit} from 'react-feather'
import {debounce} from 'lodash'
import Grid from '@mui/material/Grid'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import {useNavigate} from 'react-router-dom'
import {
    fetchFormById,
    fetchFormResults,
    changeFormStatus,
    deleteForm,
    fetchAllWebForms,
    fetchAllForms,
    fetchFormCategories
} from '../../../actions/forms/formActions'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog'
import {Functions} from '../../../utils/Functions'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import useAuthCheck from '../../../hooks/useAuthCheck'
import {CATEGORY_ITEMS} from '../../../constants'
import PageGrid from '../../../components/mui/datagrid/PageGrid'
import {copyToClipboard} from '../../../utils/Scripts'
import DraggableDialog from '../../../components/mui/dialog/DraggableDialog'
import FormEditView from './editFormView'
import CreateQrCodeView from './createQrCodeView'

import SearchBox from '../../../components/public/SearchBox'

const useStyles = makeStyles(theme => ({
    formControl: {
        padding: theme.spacing(1, 0),
        width: '100%',
        [theme.breakpoints.down(475)]: {
            marginTop: theme.spacing(3)
        }
    },
    search: {
        width: '100%',
        padding: theme.spacing(1, 0)
    },
    catSelector: {
        paddingTop: theme.spacing(2),
        width: '100%',
        paddingRight: `${theme.spacing(4)}!important`
    },
    activityButtons: {
        paddingBottom: `${theme.spacing(2)}!important`
    },
    content: {
        padding: theme.spacing(2)
    },
    badge: {
        '& .MuiBadge-badge': {
            transform: 'scale(0.8) translate(8px, -8px)'
        }
    }
}))

function FormsListTabView() {
    useCurrentViewCheck(Functions.Forms_View_All_Form)
    const navigate = useNavigate()
    const currentTab = useSelector(state => state?.forms.currentFormTab)
    const classes = useStyles()
    const dispatch = useDispatch()
    const allForms = useSelector(state => state?.forms.allForms)
    const allWebForms = useSelector(state => state?.forms.allWebForms)
    const [searchArgs, setSearchArgs] = useState({
        searchKey: undefined,
        category: CATEGORY_ITEMS.all
    })
    const allCategories = useSelector(state => state?.forms.allCategories)
    const formFetchProps = useSelector(state => state?.forms.formFetchProps)

    const auth = useSelector(state => state.auth)
    const [currentUserId, setCurrentUserId] = useState(-1)

    const [selectedCat, setSelectedCat] = useState(CATEGORY_ITEMS.all)

    // Store the rows the user selected
    const [checkboxSelected, setCheckboxSelected] = useState([])

    const [hash, setHash] = useState('init')
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)

    const [canViewSubmission, canEditForm] = useAuthCheck([
        Functions.Forms_View_Form_Submissions,
        Functions.Forms_Edit_Form
    ])

    const [clickedRow, setClickedRow] = useState('')
    const [openEdit, setOpenEdit] = useState(false)

    const [openViewQR, setOpenViewQR] = useState(false)
    const [qrLink, setQrLink] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const loadData = () => {
        fetchFormCategories()(dispatch)
    }
    useEffect(loadData, [])
    useEffect(() => {
        if (auth && auth.user) {
            setCurrentUserId(auth.user.id)
        }
    }, [auth])

    const handleDeleteOpen = () => {
        setOpenDeleteDialogue(true)
        setHash(Math.random().toString(16).substring(2, 10))
    }

    const onOperationError = msg => sendErrorMessage(dispatch, msg)
    const onOperationSuccess = msg => {
        setCheckboxSelected([])
        sendSuccessMessage(dispatch, msg)
    }

    const onDeleteForm = () => {
        deleteForm(
            checkboxSelected.map(x => parseInt(x, 10)),
            currentTab,
            formFetchProps,
            msg => onOperationSuccess(msg),
            msg => onOperationError(msg)
        )(dispatch)
    }
    const onStatusChange = isActive => {
        changeFormStatus(
            checkboxSelected.map(x => parseInt(x, 10)),
            isActive,
            currentTab,
            formFetchProps,
            msg => onOperationSuccess(msg),
            msg => onOperationError(msg)
        )(dispatch)
    }

    const filterResultsByCat = category => setSearchArgs(prevState => ({...prevState, category}))

    const onSearch = searchVal => setSearchArgs(prevState => ({...prevState, searchKey: searchVal.trim()}))

    const onLinkCopy = uid => copyToClipboard(dispatch, uid)

    const searchHandler = debounce(searchVal => {
        onSearch(searchVal)
    }, 500)

    const currentFormName = allForms?.data?.find(item => item.id === clickedRow)?.name

    const renderGrid = () => {
        const columns = []

        // For everyone
        columns.push({
            field: '_actions',
            headerName: ' ',
            resizable: false,
            disableClickEventBubbling: true,
            width: 140,
            filterable: false,
            renderCell: params => (
                <div>
                    {currentTab === 0 && (
                        <Tooltip title="Form Link" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() => {
                                    window.open(`/form/${currentUserId}/${params.row.slug}`, '_blank')
                                }}
                            >
                                <Link size={20} />
                            </IconButton>
                        </Tooltip>
                    )}

                    {currentTab === 0 && (
                        <Tooltip title="Copy Link" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() => {
                                    onLinkCopy(
                                        `https://${window.location.host}/form/${currentUserId}/${params.row.slug}`
                                    )
                                }}
                            >
                                <FileCopyOutlinedIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}

                    {currentTab === 0 && (
                        <Tooltip title="Scan QR Code" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() => {
                                    setClickedRow(params.row.id)
                                    setOpenViewQR(true)
                                    setQrLink(
                                        `https://${window.location.host}/form/${currentUserId}/${params.row.slug}/`
                                    )
                                }}
                            >
                                <QrCodeScannerIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}

                    {canViewSubmission && (
                        <Tooltip title="View Submissions" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={async () =>
                                    currentTab === 0
                                        ? (await fetchFormResults(params.row.id)(dispatch)) &&
                                          (await fetchFormById(params.row.id)(dispatch)) &&
                                          navigate(`/app/forms/data/${params.row.id}`)
                                        : navigate(`/app/forms/webFormData/${params.row.id}`)
                                }
                            >
                                {!params.row.totalUnread || params.row.totalUnread === 0 ? (
                                    <ListAlt size={20} />
                                ) : (
                                    <Badge
                                        color="secondary"
                                        overlap="circular"
                                        className={classes.badge}
                                        badgeContent={params.row.totalUnread}
                                    >
                                        <ListAlt size={20} />
                                    </Badge>
                                )}
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            )
        })

        // For People with editing perms
        if (canEditForm) {
            columns.push(
                ...[
                    {
                        field: '_delete',
                        headerName: ' ',
                        resizable: false,
                        disableClickEventBubbling: true,
                        width: 115,
                        filterable: false,
                        renderCell: params => (
                            <div>
                                <Tooltip title="Edit" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={
                                            async () => {
                                                await setClickedRow(params.row.id)
                                                await setOpenEdit(true)
                                            }

                                            // navigate(`/app/forms/edit/${params.row.id}`)
                                        }
                                    >
                                        <Edit size={20} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Clone" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => navigate(`/app/forms/formBuilder/${params.row.id}`)}
                                    >
                                        <Copy size={20} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )
                    }
                ]
            )
        }

        // For everyone
        columns.push(
            ...[
                {field: 'id', headerName: 'ID', width: 80},
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 280,
                    renderCell: params => (params.row.isActive ? params.row.name : <del>{params.row.name}</del>)
                },
                {
                    field: 'alertEmail',
                    headerName: 'Alert Email',
                    width: 250,
                    renderCell: ({value}) =>
                        value || <span style={{color: 'darkgrey', fontStyle: 'italic'}}>No alert</span>
                },
                {
                    field: 'category',
                    headerName: 'Category',
                    width: 200,
                    valueFormatter: ({value}) => (value ? value.name : '')
                },
                {field: 'totalSubmissions', headerName: 'Submissions', width: 140},
                {
                    field: 'lastSubmitDate',
                    headerName: 'Last Submitted',
                    width: 200,
                    valueFormatter: ({value}) => (value ? moment(value).format('L LT') : 'Never')
                },
                {field: 'description', headerName: 'Description', width: 300},
                {field: 'slug', headerName: 'Slug', width: 250},
                {
                    field: 'createdAt',
                    headerName: 'Created',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                },
                {field: 'uid', headerName: 'UID', width: 300}
            ]
        )

        return (
            <div>
                {currentTab === 0 && (
                    <PageGrid
                        columns={columns}
                        datas={allForms}
                        searchArgs={searchArgs}
                        fetchDatas={fetchAllForms}
                        selected={checkboxSelected}
                        setSelected={setCheckboxSelected}
                        setIsLoading={setIsLoading}
                        defaultSortColumn="id"
                    />
                )}
                {currentTab !== 0 && (
                    <PageGrid
                        columns={columns}
                        datas={allWebForms}
                        searchArgs={searchArgs}
                        fetchDatas={fetchAllWebForms}
                        selected={checkboxSelected}
                        setSelected={setCheckboxSelected}
                        setIsLoading={setIsLoading}
                        defaultSortColumn="id"
                    />
                )}
            </div>
        )
    }

    const renderSearchContainer = () => (
        <Grid item xs={12} sm={8} md={9} lg={10}>
            <div className={classes.search}>
                <SearchBox
                    placeholder="Search..."
                    size="small"
                    type="search"
                    searchHandler={searchHandler}
                    isLoading={isLoading}
                />
            </div>
        </Grid>
    )

    const renderCategorySelector = () => (
        <Grid item xs={12} sm={4} md={3} lg={2}>
            <FormControl className={classes.formControl} size="small">
                <InputLabel>Category</InputLabel>
                <Select
                    size="small"
                    value={selectedCat}
                    onChange={e => {
                        setSelectedCat(e.target.value)
                        filterResultsByCat(e.target.value)
                    }}
                >
                    <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
                    <MenuItem value={CATEGORY_ITEMS.none}>None</MenuItem>
                    {allCategories.map(c => (
                        <MenuItem key={c.id} value={c.name}>
                            {c.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    )

    const renderStatusButtons = () => (
        // <Grid item xs={2} sm={5} md={3} lg={2}>
        <Grid item className={classes.activityButtons} xs={12}>
            <ButtonGroup variant="contained" color="primary" size="small">
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(true)}>
                    Enable
                </Button>
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(false)}>
                    Disable
                </Button>
                <Button disabled={checkboxSelected.length <= 0} onClick={() => handleDeleteOpen()}>
                    Delete
                </Button>
            </ButtonGroup>
        </Grid>
    )

    const renderTopToolbar = () => (
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            {renderSearchContainer()}
            {renderCategorySelector()}
            {canEditForm ? renderStatusButtons() : <div />}
        </Grid>
    )

    return (
        <div>
            <Paper elevation={0} className={classes.content}>
                {renderTopToolbar()}
                {renderGrid()}
            </Paper>
            <ResponsiveDialog
                isOpen={openDeleteDialogue}
                openHash={hash}
                title="Delete Form"
                content={`Delete forms: ${checkboxSelected.join(', ')}?`}
                Buttons={[{name: 'Yes', event: () => onDeleteForm()}, {name: 'Cancel'}]}
            />

            <DraggableDialog
                open={Boolean(openEdit)}
                onClose={() => setOpenEdit(null)}
                title="Edit Form"
                isLoaded={Boolean(openEdit)}
            >
                <Paper elevation={0}>
                    <FormEditView rowId={clickedRow} tab={currentTab} />
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                open={Boolean(openViewQR)}
                onClose={() => setOpenViewQR(null)}
                title="View QR Code"
                isLoaded={Boolean(openViewQR)}
            >
                <Paper elevation={0}>
                    <CreateQrCodeView qrLink={qrLink} currentFormName={currentFormName} />
                </Paper>
            </DraggableDialog>
        </div>
    )
}

export default FormsListTabView
