import { InputLabel, MenuItem, Paper, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { debounce } from 'lodash';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';
import { CATEGORY_ITEMS } from '../../../constants';
import PageGrid from '../../../components/mui/datagrid/PageGrid';
import {
  trackingTableColumnsBuilder
} from './trackingHelper';
import { fetchAllTrackers } from '../../../actions/administrative/administrativeAction';
import SearchBox from '../../../components/public/SearchBox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(2, 0),

  },
  search: {
    width: '100%',
  },
  catSelector: {
    width: '100%',
  },
  topToolbar: {
    marginTop: '-3rem'
  }
}))

function TrackingAllListTabView({ searchArgs, setSearchArgs, allStaff }) {

  const classes = useStyles()
  const allTracking = useSelector(state => state.administrative.allTrackers)
  const [isLoading, setIsLoading] = useState(false)

  const allType = [
    { name: 'Form', value: 'formSubmission' },
    { name: 'Campaign', value: 'poster' },
    { name: 'QR', value: 'qrCode'}
  ]

  const onFilterType = (type) =>
    setSearchArgs(prevState => ({ ...prevState, category: type }))

  const onFilterUser = (user) =>
    setSearchArgs(prevState => ({ ...prevState, user: user.toString() }))


  const searchHandler = debounce((searchVal) => {
    onSearch(searchVal)
  }, 1000)

  const onSearch = (searchVal) => {
    setSearchArgs(prevState => ({
      ...prevState,
      searchKey: searchVal.toLowerCase().trim()
    }))
  }

  const renderSearchContainer = () => <Grid item xs={12} sm={6} md={8} xl={10}>
      <div className={classes.search}>
        <SearchBox
          placeholder='Search...'
          size='small'
          type='search'
          searchHandler={searchHandler}
          isLoading={isLoading}
        />
      </div>
    </Grid>

  const renderUserSelector = () => (
      <Grid item xs={6} sm={3} md={2} xl={1}>
        <FormControl className={`${classes.formControl} ${classes.catSelector}`} size="small">
          <InputLabel>User</InputLabel>
          <Select
            defaultValue={CATEGORY_ITEMS.all}
            onChange={(e) => {
              onFilterUser(e.target.value)
            }}
          >
            <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
            { allStaff?.map(c => <MenuItem key={c.id} value={c.id}>{ `${c.firstName} ${c.lastName}` }</MenuItem>) }
          </Select>
        </FormControl>
      </Grid>
    )

  const renderTypeSelector = () => (
      <Grid item xs={6} sm={3} md={2} xl={1}>
        <FormControl className={`${classes.formControl} ${classes.catSelector}`} size="small">
          <InputLabel>Type</InputLabel>
          <Select
            defaultValue={CATEGORY_ITEMS.all}
            onChange={(e) => {
              onFilterType(e.target.value)
            }}
          >
            <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
            { allType.map(c => <MenuItem key={c.value} value={c.value}>{c.name}</MenuItem> ) }
          </Select>
        </FormControl>
      </Grid>
    )

  const renderTopToolbar = () => <Grid container
                 className={classes.topToolbar}
                 direction='row'
                 justifyContent='space-between'
                 alignItems='center'
                 spacing={1}>
      { renderSearchContainer() }
      { renderUserSelector() }
      { renderTypeSelector() }
    </Grid>

  const renderGrid = () => {
    const columns = trackingTableColumnsBuilder()
    return <PageGrid
      showToolbar
      columns={columns}
      datas={allTracking}
      fetchDatas={fetchAllTrackers}
      searchArgs={searchArgs}
      defaultSortColumn='id'
      setIsLoading={setIsLoading}
    />
  }

  return (
    <Paper elevation={0}>
      { renderTopToolbar() }
      { renderGrid() }
    </Paper>
  )
}
export default TrackingAllListTabView
