import React from 'react'
import {Card, CardActions, Grid, IconButton, Tooltip, Typography} from '@mui/material'
import moment from 'moment/moment'
import Button from '@mui/material/Button'
import {Delete, Edit, SaveAlt} from '@mui/icons-material'
import {useDispatch, useSelector} from 'react-redux'
import UserAvatar from '../../../../components/user/UserAvatar'
import {useInterviewEditorStyles} from '../interviewHelper'
import {getInterviewFile} from '../../../../actions/customer/interviewActions'
import {USER_ROLE_ID} from '../../../../constants'

function DetailPanelContent({row: rowProp, currentUserId, handleModelOpen, handleDeleteOpen}) {
    // console.log('rowProp is', rowProp)
    // console.log('currentUserId', currentUserId)

    const dispatch = useDispatch()
    const imageContainer = useSelector(state => state.customer.customerNoteImages)
    const auth = useSelector(state => state.auth)

    const classes = useInterviewEditorStyles()
    const {notes} = rowProp
    return notes.map(note => (
        <Card key={note.id} className={classes.gridViewCard}>
            <Grid
                container
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="flex-start"
                alignContent="center"
            >
                <Grid item xs={12} md={2}>
                    <Grid container direction="row" spacing={1} justifyContent="center">
                        <Grid item xs={6} md={12}>
                            <div className="interviewerPic">
                                {/* <Avatar shape='circle' type='text'>{note.interviewer[0]}</Avatar> */}
                                <UserAvatar shape="circle" alt={note.interviewer[0]} uid={note.interviewerUid} avatar />
                                <Typography variant="body1">{note.interviewer}</Typography>
                            </div>
                        </Grid>

                        <Grid item xs={6} md={12}>
                            <div className="interviewerDate">
                                <Typography variant="body2">{moment(note.createdAt).format('L')}</Typography>
                                <Typography variant="body2">{moment(note.createdAt).format('LT')}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Typography variant="body2" className="textContainer">
                        {note.notes}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <div className="imageContainer">
                        {note.fileName && (
                            <div>
                                {imageContainer[note.id] ? (
                                    <Tooltip title="Click to download">
                                        <img
                                            aria-hidden="true"
                                            alt="download preview"
                                            src={imageContainer[note.id]}
                                            onClick={async () => {
                                                await getInterviewFile(
                                                    rowProp.id,
                                                    note.id,
                                                    note.fileMime,
                                                    imageContainer[note.id]
                                                )(dispatch)
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className="downloadButton"
                                        startIcon={<SaveAlt />}
                                        onClick={async () => {
                                            await getInterviewFile(rowProp.id, note.id, note.fileMime)(dispatch)
                                        }}
                                    >
                                        Download
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
            <CardActions>
                {(note.interviewerId === currentUserId || auth.user.roleId === USER_ROLE_ID.ADMIN) && (
                    <div className="editButtons">
                        <Tooltip title="Edit" placement="top">
                            <IconButton
                                size="small"
                                component="span"
                                onClick={() =>
                                    handleModelOpen({interviewId: rowProp.id, id: note.id, notes: note.notes})
                                }
                            >
                                <Edit size={20} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top">
                            <IconButton
                                size="small"
                                component="span"
                                onClick={() => handleDeleteOpen(rowProp.id, note.id)}
                            >
                                <Delete size={20} />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </CardActions>
        </Card>
    ))
}
export default DetailPanelContent
