import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Tooltip
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { debounce } from 'lodash';
import moment from 'moment';
import { Clipboard, Edit, Send } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../utils/Functions';
import useAuthCheck from '../../../hooks/useAuthCheck';
import ViewHead from '../../../components/public/ViewHead';
import { useReviewListStyles } from '../reviewHelper';
import { sendErrorMessage, sendSuccessMessage } from '../../../actions';
import { fetchAllReviewSets, updateReviewSetStatus } from '../../../actions/review/reviewActions';
import { frontEndUrl } from '../../../constants';
import { copyToClipboard } from '../../../utils/Scripts';
import PageGrid from '../../../components/mui/datagrid/PageGrid';
import DraggableDialog from "../../../components/mui/dialog/DraggableDialog";
import ReviewSetEditView from "./editor/editReviewSetView";
import SearchBox from '../../../components/public/SearchBox';

function ReviewSetListView() {
  useCurrentViewCheck(Functions.Review_Set)
  const [ canCreateReviewSet, canCreateReview ] = useAuthCheck(([
    Functions.Review_Set_Create,
    Functions.Review_Create,
  ]))
  const  navigate = useNavigate();
  const dispatch = useDispatch()
  const classes = useReviewListStyles()
  const allReviewSets = useSelector(state => state?.review.allReviewSets)
  const reviewSetFetchProps = useSelector(state => state?.review.reviewSetFetchProps)
  const [checkboxSelected, setCheckboxSelected] = useState([])
  const [searchArgs, setSearchArgs] = useState({
    searchKey: undefined
  })
  const [openEdit, setOpenEdit] = useState(false)
  const [clickedRow, setClickedRow] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const onSuccess = (msg) => sendSuccessMessage(dispatch, msg)
  const onError = (msg) => sendErrorMessage(dispatch, msg)

  const onStatusChange = (isActive) => {
    updateReviewSetStatus(checkboxSelected.map(x => parseInt(x,10)),
      isActive, reviewSetFetchProps,
      (msg) => {
        setCheckboxSelected([])
        onSuccess(msg)
      },
      (msg) => onError(msg)
    )(dispatch)
  }

  const onSearch = (searchVal) => setSearchArgs(prevState =>
    ({ ...prevState, searchKey: searchVal.toLowerCase().trim() }))

  const searchHandler = debounce((searchVal) => {
    onSearch(searchVal)
  }, 1000)
  const renderSearchContainer = () => <Grid item xs={12}>
      <div className={classes.search}>
        <SearchBox
          placeholder='Search...'
          size='small'
          type='search'
          searchHandler={searchHandler}
          isLoading={isLoading}
        />
      </div>
    </Grid>

  const renderGrid = () => {
    const columns = []

    if (canCreateReviewSet) {
      columns.push(
        { field: '_actions', headerName: ' ', resizable: false,
          disableClickEventBubbling: true, width: 115,
          filterable: false, renderCell: (params) =>
            <div>
              <Tooltip title='Edit' placement='top'>
                <IconButton color='primary'
                            size='small'
                            component='span'
                            onClick={async () =>
                            {
                              await setClickedRow(params.row.uid);
                              await setOpenEdit(true);
                            }
                              // navigate(`/app/review/set/edit/${params.row.uid}`)
                }
                >
                  <Edit size={20} />
                </IconButton>
              </Tooltip>
              { params.row.isActive && canCreateReview
              &&
              <>
                <Tooltip title='Send Review' placement='top'>
                  <IconButton color='primary'
                              size='small'
                              component='span'
                              onClick={() => {
                                navigate(`/app/review/set/send/${params.row.uid}`)
                              }}
                  >
                    <Send size={20} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Copy Link' placement='top'>
                  <IconButton color='primary'
                              size='small'
                              component='span'
                              onClick={() => copyToClipboard(dispatch,
                                `${frontEndUrl}/review/${params.row.uid}`,
                                'Link copied to clipboard',
                                'Link cannot be copied to clipboard'
                              )}
                  >
                    <Clipboard size={20} />
                  </IconButton>
                </Tooltip>
              </>
              }
            </div>
        })
    }

    columns.push(...[
      { field: 'id', headerName: 'ID', hide: true },
      { field: 'name', headerName: 'Name', width: 250,
        renderCell: (params) => params.row.isActive ? params.row.name : <del>{params.row.name}</del> },
        { field: 'head', headerName: 'Head', width: 250,
          valueFormatter: ({ value }) => value?.name ?? "N/A" },
      { field: 'googleUrl', headerName: 'Google URL', width: 250 },
      { field: 'uid', headerName: 'Internal URL', width: 250,
        valueFormatter: ({ value }) => `${frontEndUrl}/review/${value}` },
      { field: 'createdAt', headerName: 'Created', width: 200,
        valueFormatter: ({ value }) => moment(value).format('L LT')},
      { field: 'updatedAt', headerName: 'Updated', width: 200,
        valueFormatter: ({ value }) => moment(value).format('L LT')},
    ])


    return (
      <PageGrid
        columns={columns}
        datas={allReviewSets}
        fetchDatas={fetchAllReviewSets}
        searchArgs={searchArgs}
        defaultSortColumn='id'
        selected={checkboxSelected}
        setSelected={setCheckboxSelected}
        setIsLoading={setIsLoading}
      />
    )
  }

  const renderStatusButtons = () => (
      <Grid item className={classes.activityButtons} xs={12}>
        <ButtonGroup variant='contained' color='primary' size='small'>
          <Button
            disabled={checkboxSelected.length <= 0}
            onClick={() => onStatusChange(true)}>
            Enable
          </Button>
          <Button
            disabled={checkboxSelected.length <= 0}
            onClick={() => onStatusChange(false)}>
            Disable
          </Button>
        </ButtonGroup>
      </Grid>
    )

  const renderTopToolbar = () => <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
      { renderSearchContainer() }
      { canCreateReviewSet ? renderStatusButtons() : <div /> }
    </Grid>

  const renderAddNewButton = () => (
      <div>
        {canCreateReviewSet
          ? <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate('/app/review/set/create')}>
            Add New Set
          </Button>
          : ''
        }
      </div>
    )

  return (
    <ViewHead functionId={Functions.Review_Set}
              topButtons={renderAddNewButton()}
              noMaxWidth>
      <Paper elevation={0} className={classes.paper}>
        { renderTopToolbar() }
        { renderGrid() }
      </Paper>

      <DraggableDialog open={Boolean(openEdit)} onClose={() => setOpenEdit(false)}
                       title='Edit Review' isLoaded={Boolean(openEdit)}>
        <Paper elevation={0}>
          <ReviewSetEditView rowId={clickedRow} onClose={() => setOpenEdit(false)} />
        </Paper>
      </DraggableDialog>

    </ViewHead>
  )
}

export default ReviewSetListView
