import React, {useEffect, useState} from 'react'
import {CardMedia, Grid, Modal, Paper, Tooltip, Typography} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import {Edit} from 'react-feather'
import {AddCircle, Delete, InfoOutlined} from '@mui/icons-material'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'
import {deleteQRCode, fetchQRCodeSetCodes, generateQRCodeImage} from '../../../actions/qrcode/qrcodeAction'
import useAuthCheck from '../../../hooks/useAuthCheck'
import {Functions} from '../../../utils/Functions'
import {extraPostInfoModal} from '../../campaign/campaignDetails/poster/extraPostInfoModal'
import {useQRCodeTableStyles} from '../qrcodeHelper'
import QRCodeModalDisplay from './QRCodeModalDisplay'
import PageGrid from '../../../components/mui/datagrid/PageGrid'
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog'

function QRCodeTableView({qrSetId, userId, isSmallView, userMappingList}) {
    const navigate = useNavigate()
    const [canAddQRCode, canEditQRCode] = useAuthCheck([Functions.QRCode_Code_Add, Functions.QRCode_Code_Edit])

    const classes = useQRCodeTableStyles()
    const dispatch = useDispatch()

    const allCodes = useSelector(state => state.qrcode.currentQRCodeSetCodes)
    const imageContainer = useSelector(state => state.qrcode.qrCodeImages)
    const codeFetchProps = useSelector(state => state.qrcode.codeFetchProps)

    const [hash, setHash] = useState('init')
    const [selectedForDel, setSelectedForDel] = useState(-1)
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)

    const [infoModalOpen, setInfoModalOpen] = useState(false)
    const [infoModalInfo, setInfoModalInfo] = useState(<div />)

    const [qrModalOpen, setQrModalOpen] = useState(false)
    const [selectedQRCode, setSelectedQRCode] = useState(-1)
    // eslint-disable-next-line no-unused-vars
    const [searchArgs, setSearchArgs] = useState({
        searchKey: undefined,
        setId: qrSetId
    })

    useEffect(() => {
        // generate all qr code images
        if (Array.isArray(allCodes.data)) {
            allCodes.data.forEach(async qr => {
                if (!imageContainer[qr.id]) {
                    await generateQRCodeImage(qrSetId, qr.id, userId)(dispatch)
                }
            })
        }
    }, [allCodes])

    const handleDeleteOpen = id => {
        setSelectedForDel(id)
        setOpenDeleteDialogue(true)
        setHash(Math.random().toString(16).substring(2, 10))
    }

    const onDeleteQRCode = () => {
        deleteQRCode(
            qrSetId,
            selectedForDel,
            codeFetchProps,
            msg => {
                setSelectedForDel(-1)
                sendSuccessMessage(dispatch, msg)
            },
            msg => sendErrorMessage(dispatch, msg)
        )(dispatch)
    }

    const handleInfoModalOpen = userTracks => {
        setInfoModalInfo(
            extraPostInfoModal({
                allUsers: userMappingList,
                userTracks
            })
        )
        setInfoModalOpen(true)
    }

    const renderTableView = () => {
        const columns = []
        columns.push({field: 'id', headerName: 'ID', width: 70})

        if (canEditQRCode) {
            columns.push(
                ...[
                    {
                        field: '_actions',
                        headerName: ' ',
                        resizable: false,
                        disableClickEventBubbling: true,
                        width: 75,
                        filterable: false,
                        renderCell: params => (
                            <div>
                                <Tooltip title="Edit" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => navigate(`/app/qr/view/${qrSetId}/edit/${params.row.id}`)}
                                    >
                                        <Edit size={20} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => handleDeleteOpen(params.row.id)}
                                    >
                                        <Delete size={20} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )
                    },
                    {
                        field: '_info',
                        headerName: 'Info',
                        resizable: false,
                        disableClickEventBubbling: true,
                        width: 90,
                        filterable: false,
                        renderCell: params => (
                            <div>
                                <Tooltip title="Info" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => handleInfoModalOpen(params.row.tracks)}
                                    >
                                        <InfoOutlined size={20} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )
                    }
                ]
            )
        }

        columns.push(
            ...[
                {field: 'name', headerName: 'Name', width: 150},
                {
                    field: '__url',
                    headerName: 'QR Code',
                    width: 130,
                    renderCell: params => (
                        <CardMedia
                            className={classes.gridQRImage}
                            image={`${imageContainer[params.row.id]}`}
                            onClick={() => {
                                setSelectedQRCode(params.row.id)
                                setQrModalOpen(true)
                            }}
                        />
                    )
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    width: 250,
                    renderCell: params => (
                        <Typography style={{whiteSpace: 'pre-line'}} variant="body2">
                            {params.value}
                        </Typography>
                    )
                },
                {field: 'scans', headerName: 'Scans', width: 110},
                {
                    field: 'createdAt',
                    headerName: 'Date',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                }
            ]
        )

        return (
            <PageGrid
                columns={columns}
                datas={allCodes}
                fetchDatas={fetchQRCodeSetCodes}
                searchArgs={searchArgs}
                defaultSortColumn="id"
                rowHeight={150}
                totalHeight={isSmallView ? '86vh' : '46vh'}
            />
        )
    }

    return (
        <div className={classes.qrGrid}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h2" color="textPrimary">
                        QR Codes
                    </Typography>
                </Grid>

                <Grid item>
                    {canAddQRCode && (
                        <Tooltip title="Add QR Code" placement="top">
                            <IconButton onClick={() => navigate(`/app/qr/view/${qrSetId}/add`)} size="large">
                                <AddCircle style={{color: 'green'}} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>

            <Paper elevation={0}>{renderTableView()}</Paper>

            <ResponsiveDialog
                isOpen={openDeleteDialogue}
                openHash={hash}
                title="Delete QR Code"
                content={`Delete QR Code #${selectedForDel}?`}
                Buttons={[
                    {name: 'Yes', event: () => onDeleteQRCode()},
                    {name: 'Cancel', event: () => setSelectedForDel(-1)}
                ]}
            />

            <Modal
                open={infoModalOpen}
                onClose={() => setInfoModalOpen(false)}
                className={classes.modal}
                disablePortal
                disableRestoreFocus
                disableEnforceFocus
                disableAutoFocus
                closeAfterTransition
            >
                <Paper>{infoModalInfo}</Paper>
            </Modal>

            <Modal
                open={qrModalOpen}
                onClose={() => setQrModalOpen(false)}
                className={classes.modal}
                disablePortal
                disableRestoreFocus
                disableEnforceFocus
                disableAutoFocus
                closeAfterTransition
            >
                <div>
                    <QRCodeModalDisplay
                        image={imageContainer[selectedQRCode]}
                        sid={qrSetId}
                        cid={selectedQRCode}
                        uid={userId}
                        qrScode={allCodes.data}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default QRCodeTableView
