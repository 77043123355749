export const UserSettingActionTypes = {
    FetchUserSetting: "UserSetting_Fetch",
    ChangeUserSetting: "UserSetting_Change",
    SetToDefault: "SetUserSettingToDefault",
    ChangeDarkMode:"ChangeDarkMode",
    ChangeAutoSwitch:"ChangeAutoSwitch",
    CHANGE_ROWS_PER_PAGE:"CHANGE_ROWS_PER_PAGE",
    ChangeDraggableDialogFS: "ChangeDraggableDialogFS",
    SetDraggableDialogFS: "SetDraggableDialogFS",
    SetMenuSearchDialog: 'SetMenuSearchDialog'
}

// @isDarkMode:boolean
export const setDarkMode = (isDarkMode) => dispatch=>{
    dispatch({type: UserSettingActionTypes.ChangeDarkMode, payload: isDarkMode});
}

export const fetchUserSetting = () => dispatch=>{
    dispatch({type: UserSettingActionTypes.FetchUserSetting});
};

export const ChangeRowsPerPage = (rowPerPage) => dispatch=>{
    dispatch({
        type: UserSettingActionTypes.CHANGE_ROWS_PER_PAGE,
        payload: parseInt(rowPerPage,10)
    })
}