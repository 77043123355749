import React, { useState } from 'react';
import {
  Collapse,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, ExpandLess, ExpandMore, QueryBuilder, Remove } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';
import moment from 'moment';
import { PlusCircle as PlusIcon } from 'react-feather';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  buttonList: {
    textAlign: 'end'
  },
  paper: {
    padding: '2rem',
  },
  grid: {
    padding: '2rem',
    marginBottom: '2rem',
    '&.bg': {
      backgroundColor: theme.palette.background.dark
    }
  },
  button: {
    backgroundColor: theme.palette.background.dark,
  },
  daySelect: {
    backgroundColor: 'white',
  }
}))

function EmployeeServiceTimeEditor({ serviceTime, setServiceTime }) {

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const serviceStartTime="10:00"
  const serviceEndTime="18:00"

  const removeItem = (idx) => {
    const tempServiceTime = [ ...serviceTime ]
    tempServiceTime.splice(idx, 1)
    setServiceTime(tempServiceTime)
  }
  const addItem = (idx) => {
    const tempServiceTime = [ ...serviceTime ]
    tempServiceTime.splice(idx + 1, 0, ({ day: -1, startTime: serviceStartTime, endTime: serviceEndTime }))
    setServiceTime(tempServiceTime)
  }

  const renderList = () =>
    <Paper className={classes.paper}>
      {
        Array.isArray(serviceTime) && serviceTime.length > 0
          ?
          serviceTime.map((time, idx) =>
            <Grid container justifyContent='space-between'
                  alignItems='center' key={idx} spacing={2}
                  className={clsx(classes.grid, { 'bg': idx % 2 })}>

              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel className={classes.daySelect}>Day</InputLabel>
                  <Select
                    fullWidth
                    value={serviceTime[idx].day}
                    onChange={(e) => {
                      const tempServiceTime = [ ...serviceTime ]
                      tempServiceTime[idx].day = e.target.value
                      tempServiceTime[idx].dirty = true
                      setServiceTime(tempServiceTime)
                    }}
                  >
                    <MenuItem value={-1}>---</MenuItem>
                    { serviceTimeDayData.map( ({ word, value }) => <MenuItem key={value} value={value}>{word}</MenuItem> ) }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  label='Start Time'
                  type='time'
                  fullWidth
                  value={serviceTime[idx].startTime}
                  onChange={(e) => {
                    const tempServiceTime = [ ...serviceTime ]
                    tempServiceTime[idx].startTime = e.target.value
                    tempServiceTime[idx].dirty = true
                    setServiceTime(tempServiceTime)
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                />

              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  label='End Time'
                  type='time'
                  fullWidth
                  value={serviceTime[idx].endTime}
                  onChange={(e) => {
                    const tempServiceTime = [ ...serviceTime ]
                    tempServiceTime[idx].endTime = e.target.value
                    tempServiceTime[idx].dirty = true
                    setServiceTime(tempServiceTime)
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <div className={classes.buttonList}>
                  <IconButton onClick={() => removeItem(idx)} size="large">
                    <Remove />
                  </IconButton>
                  <IconButton onClick={() => addItem(idx)} size="large">
                    <Add />
                  </IconButton>
                </div>
              </Grid>

              <Grid item xs={12}>
                <FormHelperText error={serviceTime[idx]?.error?.length > 0 || false}>
                  {serviceTime[idx]?.error}
                </FormHelperText>
              </Grid>
            </Grid>
          )
          :
          <Grid container justifyContent='space-between' spacing={2} className={classes.grid}>
            <Grid item xs={12}>
              <Button color='primary'
                      variant='contained'
                      fullWidth
                      onClick={() => addItem(0)}
                      startIcon={<PlusIcon />}>
                Add New Time
              </Button>
            </Grid>
          </Grid>
      }
    </Paper>

  return (
    <List component='nav'>

      <ListItem button onClick={() => setOpen(!open)} className={classes.button}>
        <ListItemIcon>
          <QueryBuilder />
        </ListItemIcon>
        <ListItemText primary='Service Time' />
        {open ? <ExpandLess /> : <ExpandMore /> }
      </ListItem>

      <Collapse in={open} timeout='auto' unmountOnExit>
        { serviceTime && renderList() }
      </Collapse>
    </List>
  )
}

export const serviceTimeDayData = [
  { word: 'Sunday', value: 0 },
  { word: 'Monday', value: 1 },
  { word: 'Tuesday', value: 2 },
  { word: 'Wednesday', value: 3 },
  { word: 'Thursday', value: 4 },
  { word: 'Friday', value: 5 },
  { word: 'Saturday', value: 6 },
]

export const validateServiceTime = (serviceTime) => {

  let hasGlobalErr = false
  const result = []

  serviceTime.forEach(time => {
    let hasLocalErr = false
    let errorMsg = ''

    // check day
    if (time.day === -1) {
      hasLocalErr = true
      errorMsg += ' Day is required.'
    }

    const startTime = moment(time.startTime, 'HH:mm')
    const endTime = moment(time.endTime, 'HH:mm')

    if (!startTime.isValid()) {
      hasLocalErr = true
      errorMsg += ' Start Time is invalid.'
    }

    if (!endTime.isValid()) {
      hasLocalErr = true
      errorMsg += ' End Time is invalid.'
    }

    if (endTime.isSameOrBefore(startTime)) {
      hasLocalErr = true
      errorMsg += ' End Time must be after Start Time.'
    }

    if (!hasLocalErr) {
      // Local success
      time.error = ''
      result.push({
        day: time.day,
        startTime: time.startTime,
        endTime: time.endTime,
      })
    } else {
      // Error
      hasGlobalErr = true
      time.error = errorMsg
      result.push({
        day: time.day,
        startTime: time.startTime,
        endTime: time.endTime,
        error: errorMsg
      })
    }
  })

  if (!hasGlobalErr) {
    return ({ rs: true, result })
  } 
    return ({ rs: false, result })
  
}

export default EmployeeServiceTimeEditor
