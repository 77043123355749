import React, {useEffect, useState} from 'react'
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery} from '@mui/material'
import {useTheme} from '@mui/styles'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'

function ResponsiveDialog({title, content, Buttons, isOpen, openHash}) {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [open, setOpen] = useState(() => isOpen)
    const handleClose = () => {
        setOpen(false)
    }
    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen, openHash])
    return (
        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
            <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {Buttons &&
                    Buttons.map(button => (
                        <Button
                            key={button.name}
                            autoFocus
                            onClick={() => {
                                if (typeof button.event === 'function') {
                                    button.event()
                                }
                                handleClose()
                            }}
                            color="primary"
                        >
                            {button.name}
                        </Button>
                    ))}
            </DialogActions>
        </Dialog>
    )
}
ResponsiveDialog.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    openHash: PropTypes.string.isRequired,
    Buttons: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            event: PropTypes.func
        })
    )
}

ResponsiveDialog.defaultProps = {
    Buttons: {name: '', event: () => {}}
}

export default ResponsiveDialog
