import React, {useState} from 'react';
import { Grid, Paper, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import SubmitButton from "../../components/mui/button/SubmitButton";
import {reqResetPassword} from "../../actions";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100vh',
        paddingTop: '10%',
    },
    paper: {
        backgroundColor: theme.palette.background.dark,
        padding: "2rem 1rem",
        minWidth: 350,
        maxWidth: 600,

    },
    form: {
        width: '90%',
    },
    headsup:{
        textAlign: 'center',
        marginTop:'1rem',
    },
    footerText:{
        textAlign:'center',
        marginTop:'2rem',
    },
    success:{
        fontSize:"1rem",
        fontWeight:'bold',
        margin:"1.5rem 0"
    },
}))
const schema = yup.object().shape({
    email: yup.string().email("You may have entered an invalid email").required("Please enter an email").trim().lowercase(),
})
function ForgotPasswordView() {
    const classes = useStyles();
    const [isSaving, setIsSaving] = useState(false);

    const [sendState,setSendState] = useState(null);

    const {register, errors, handleSubmit, reset,setError} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: "onChange",

    });
    const formSubmit = (formData)=>{
        setIsSaving(true);
        reqResetPassword(formData,successCallback,errorCallback)

    }

    const successCallback = ()=>{
        setIsSaving(false);
        setSendState("Send");
        reset();
    }
    const errorCallback = (message) =>{
        setIsSaving(false);
        setError('email' ,{type:'manual',message,shouldFocus:true})
    }
    return (
        <Paper className={classes.root}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                    <Paper className={classes.paper} elevation={5}>
                        <Typography variant="h2" color='primary' style={{textAlign: 'center'}}>
                            Forgot your password?
                        </Typography>
                        {!sendState ?
                            <Typography variant='body1' color='textSecondary' style={{marginTop: '1rem'}}>
                                Fill out your email address, and we’ll send you instructions to reset your password.
                            </Typography>
                            :

                            <Typography variant='h4' color='error' className={classes.success}>
                            We just sent you an email with instructions to reset your password.
                            </Typography>
                        }


                        <div className={classes.form}>
                            <form onSubmit={handleSubmit(formSubmit)}>
                                <TextField
                                  size='small'
                                    variant="outlined" margin="normal" fullWidth
                                    inputRef={register}
                                    id="email" label="Email Address" name="email" autoComplete="email"
                                    autoFocus
                                    error={Boolean(errors.email?.message)}
                                    helperText={errors.email?.message}
                                />
                                <SubmitButton fullWidth variant="contained" color="primary" style={{marginTop:'1rem'}}
                                              text="Email me some help" isSaving={isSaving} isSavingText="Processing..."/>

                            </form>
                        </div>
                        <Typography variant='body2' color='textSecondary' className={classes.headsup}>
                            Heads up! You may need to check your spam folder.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Typography variant='body2' color="textSecondary" className={classes.footerText} >
                Can&apos;t recover your account? Let us know and we&apos;ll help!
            </Typography>
        </Paper>
    );
}

export default ForgotPasswordView;
