import React from "react";
import { Navigate } from 'react-router';
import ProductCategoryManager from '../../../views/product/productCategory';
import ProductView from '../../../views/product';

const productRoutes = [
    {
        path: 'product',
        routes: [
            {
                path:'list',
                exact: 'true',
                component:<ProductView/>
            },
            {
               path:'category',
               exact: 'true',
               component:<ProductCategoryManager/>
            }
            ,
            {
                component:<Navigate to="404"/> // this must be the last one
            },

        ]
    },
]

export default productRoutes;
