import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid, IconButton, InputAdornment,
  Paper,
  TextField, Tooltip,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import { InfoOutlined } from '@mui/icons-material';
import { sendErrorMessage } from '../../../actions';
import SubmitButton from '../../../components/mui/button/SubmitButton';
import {
  fetchAllEmailAccounts
} from '../../../actions/administrative/administrativeAction';
import LoadingScreen from '../../../components/public/LoadingScreen';
import { createMMQJob } from '../../../actions/administrative/mmqJobsAction';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '2rem'
  },
  editor: {
    '& .cke_contents.cke_reset': {
      minHeight: '50vh'
    }
  }
}))

// const schema = yup.object().shape(CONTRACT_TEMPLATE_CREATE_VALIDATOR)
function MMQJobTemplateEditor({onSubmitSuccess}) {

  const { register, handleSubmit} = useForm({
    // resolver: yupResolver(schema),
    reValidateMode: "onChange",
  })

  const classes = useStyles()
  const dispatch = useDispatch()
  const [isLoaded, setIsLoaded] = useState(null)
  const [isSaving, setIsSaving] = useState(false)


  const loadData = async () => {
    setIsLoaded(await fetchAllEmailAccounts()(dispatch))
  }
  useEffect(loadData, [])

  const onSubmit = async (formData) => {
    const res = {
      publishHeader:{
        channel:formData.channel,
        jobName : formData.jobName,
        id: formData.id,
        senderId:formData.senderId,
        receiverId:formData.receiverId,
        expireDate:formData.expireDate,
        type:formData.type,
        tid:formData.tid,
        maxTrialCount: parseInt(formData.maxTrialCount,10),
        trial:parseInt(formData.trial,10),
        delay: parseInt(formData.delay,10),
        trialInfo:[],
        backoffType: formData.backoffType,
        backoffDelay: parseInt(formData.backoffDelay,10),
        repeat: formData.repeat
      },
      publishBody:{
        purpose: formData.purpose,
        content: null,
        jobSuccessReturnURL: formData.jobSuccessReturnURL,
        jobFailReturnURL: formData.jobFailReturnURL
      }
    }
    return createMMQJob(res, onSubmitSuccess, onError)(dispatch)
  }

  const onError = (msg) => {
    sendErrorMessage(dispatch, msg)
    setIsSaving(false)
  }

  const renderForm = () => (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={5} direction='row' justifyContent='space-between' alignItems='flex-start'>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='mailBot'
                         label='Channel' required
                         disabled={isSaving} variant='outlined'
                         name='channel'
                         InputProps={{
                           endAdornment:<InputAdornment position='start'>
                             <Tooltip title= ' For determine what kind of job it is ' placement='top'>
                               <IconButton
                                 size = 'small'
                                 color='primary'>
                                 <InfoOutlined />
                               </IconButton>
                             </Tooltip>
                           </InputAdornment>
                         }}
              />
            </FormControl>
          </Grid>



          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='payment log create'
                         label='Job Name' required
                         disabled={isSaving} variant='outlined'
                         name='jobName'
                         InputProps={{
                           endAdornment:<InputAdornment position='start'>
                             <Tooltip title= ' For determine which job it is ' placement='top'>
                               <IconButton
                                 size = 'small'
                                 color='primary'>
                                 <InfoOutlined />
                               </IconButton>
                             </Tooltip>
                           </InputAdornment>
                         }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='10001'
                         label='Id' required
                         disabled={isSaving} variant='outlined'
                         name='id'
                         helperText="This should not be concern for now"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='0'
                         label='Expire Time' required
                         disabled={isSaving} variant='outlined'
                         name='expireDate'
                         helperText="This should not be concern for now"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='0'
                         label='Sender Id' required
                         disabled={isSaving} variant='outlined'
                         name='senderId'
                         helperText="This should not be concern for now"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='0'
                         label='Receiver Id' required
                         disabled={isSaving} variant='outlined'
                         name='receiverId'
                         helperText="This should not be concern for now"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='type1'
                         label='Type' required
                         disabled={isSaving} variant='outlined'
                         name='type'
                         helperText="This should not be concern for now"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='tid1'
                         label='tid' required
                         disabled={isSaving} variant='outlined'
                         name='tid'
                         helperText="This should not be concern for now"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='4'
                         label='Max Trial Count' required
                         disabled={isSaving} variant='outlined'
                         name='maxTrialCount'
                         helperText="This should not be concern for now"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='0'
                         label='Trial' required
                         disabled={isSaving} variant='outlined'
                         name='trial'
                         helperText="This should not be concern for now"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='0'
                         label='Delay' required
                         disabled={isSaving} variant='outlined'
                         name='delay'
                         helperText="This should not be concern for now"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='exponential'
                         label='Back Off Type' required
                         disabled={isSaving} variant='outlined'
                         name='backoffType'
                         helperText="This should not be concern for now, fixed or exponential"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='1000'
                         label='Back Off Delay' required
                         disabled={isSaving} variant='outlined'
                         name='backoffDelay'
                         helperText="This should not be concern for now"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='60000'
                         label='Repeat' required
                         disabled={isSaving} variant='outlined'
                         name='repeat'
                         helperText="This should not be concern for now, has standard input options"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         defaultValue='read payments from email'
                         label='Purpose' required
                         disabled={isSaving} variant='outlined'
                         name='purpose'
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} md={type === 'head' ? 6 : 12} className={classes.editor}> */}
          {/*  <CKEditor */}
          {/*    data={contractText} */}
          {/*    readOnly={isSaving} */}
          {/*    onChange={evt => setContractText(evt.editor.getData())}/> */}
          {/*  <FormHelperText error={Boolean(errors.text?.message)}> */}
          {/*    { Boolean(errors.text?.message) ? errors.text?.message : '' } */}
          {/*  </FormHelperText> */}
          {/* </Grid> */}

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         defaultValue=''
                         placeholder='http://xxx.com/abc'
                         inputRef={register}
                         label='Job Success IPN Url'
                         disabled={isSaving} variant='outlined'
                         name='jobSuccessReturnURL'
                         helperText="request when job done successfully"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         placeholder='http://xxx.com/abc'
                         inputRef={register}
                         label='Job Failed IPN Url'
                         disabled={isSaving} variant='outlined'
                         name='jobFailReturnURL'
                         helperText="request when job failed"
              />
            </FormControl>
          </Grid>



          {/* Bottom Submit Button */}
          <Grid item xs={12}>
            <Grid container spacing={1} direction='row' justifyContent='flex-end'>
              <Grid item xs={2}>
                <SubmitButton isSaving={isSaving} text='Submit' isSavingText='Submitting'
                              fullWidth variant='contained' color='primary'
                />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </form>
    )

  return (
    <Paper className={classes.paper}>
      {
        isLoaded === null
          ? <LoadingScreen message='Loading...' />
          : isLoaded
            ? renderForm()
            : <Typography variant='h3' color='error'>Data load error</Typography>
      }
    </Paper>
  )
}

export default MMQJobTemplateEditor
