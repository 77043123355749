import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  centerGrid: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& div, & img': {
      width: '300px'
    },
    [theme.breakpoints.down('xl')]: {
      '& div, & img': {
        width: '100%',
        maxWidth: '400px'
      }
    }
  },
}))

function CenterDisplay(props) {
  const {children} = props;
  const classes = useStyles()
  return (
    <div className={classes.centerGrid} {...props}>
      {children}
    </div>
  )
}

export default CenterDisplay
