import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';
import * as yup from 'yup';

export const CLASS_CREATE_VALIDATOR = {
  name: yup.string().required('Required')
    .min(2, 'Class name must be at least 2 characters')
    .max(30, 'Class name must be less than 30 characters')
    .trim(),
  slug: yup.string().required().trim(),
  description: yup.string().max(255, 'description must be less than 255 characters')
}

export const CLASS_DELIVERY_VALIDATOR = {
  extension: yup.string().required('Required')
    .min(2, 'Class name must be at least 2 characters')
    .max(30, 'Class name must be less than 30 characters')
    .trim(),
}

export const useClassEditorStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10
  },
  paper__form: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: '2rem',
  },
  form__title: {
    marginBottom: '.5rem'
  },
  redCheckBox: {
    color: red[400],
    '&checked': {
      color: red[600]
    }
  },
  text: {
    cursor: 'default',
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  formControlSelect: {
    margin: theme.spacing(2, 0),
    '& .selector': {
      marginTop: '3rem'
    }
  },
  formControlSyllabusSelect: {
    '& .label': {
      marginBottom: theme.spacing(1)
    },
    margin: theme.spacing(4, 0)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2,
    height: '100%'
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  button_save: {
    marginTop: theme.spacing(1)
  },

}))
