import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import { sendSuccessMessage } from '../../../../actions';
import ViewHead from '../../../../components/public/ViewHead';
import ReviewSetEditor from './ReviewSetEditor';
import { fetchReviewSet } from '../../../../actions/review/reviewActions';
import LoadingScreen from '../../../../components/public/LoadingScreen';
import { fetchAllInvoiceHeads } from '../../../../actions/administrative/administrativeAction';

function ReviewSetEditView({ rowId, onClose }) {
  useCurrentViewCheck(Functions.Review_Set_Edit)
  const  navigate = useNavigate();
  const urlId = useParams().uid
  const dispatch = useDispatch()
  const allTemplates = useSelector(state => state.administrative.allInvoiceHeads)
  const currentReviewSet = useSelector(state => state?.review.currentReviewSet)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isTemplatesLoaded, setIsTemplatesLoaded] = useState(false)

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchReviewSet(urlId || rowId)(dispatch))
      setIsTemplatesLoaded(await fetchAllInvoiceHeads()(dispatch))
    })()
  }
  useEffect(loadData, [dispatch, urlId || rowId])

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      if (onClose) {
        onClose()
      } else {
        navigate('/app/review/set')
      }
    }, 1000)
  }

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    } else {
      navigate('/app/review/set')
    }
  }

  return (
      <div>
        {urlId?
            <ViewHead functionId={Functions.Review_Set_Edit}>
              {
                isLoaded && isTemplatesLoaded
                    ?
                    <ReviewSetEditor
                        isEdit
                        originalData={currentReviewSet}
                        onSubmitSuccess={onSuccess}
                        templateHeads={(allTemplates ?? []).reverse()}
                        onClose={handleOnClose}
                    />
                    :
                    <LoadingScreen message='Loading...' />
              }
            </ViewHead>
            :
            <div>
              {
                isLoaded && isTemplatesLoaded
                    ?
                    <ReviewSetEditor
                        isEdit
                        originalData={currentReviewSet}
                        onSubmitSuccess={onSuccess}
                        templateHeads={(allTemplates ?? []).reverse()}
                        onClose={handleOnClose}
                    />
                    :
                    <LoadingScreen message='Loading...' />
              }
            </div>
        }
      </div>

  )
}

export default ReviewSetEditView
