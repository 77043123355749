import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { TextField, Tooltip } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';
import { NO_IMG, useEmployeeQRCodeStyles } from './EmployeeHelperData';
import {
  deleteStaffWeChat,
  sendErrorMessage,
  sendSuccessMessage,
  updateStaffWeChat
} from '../../../actions';
import { ServerBaseURL } from '../../../constants';

function EmployeeQRCode({ employeeInfo }) {

  const classes = useEmployeeQRCodeStyles()
  const {uid} = employeeInfo
  const dispatch = useDispatch()

  const [isSaving, setIsSaving] = useState(false)
  const [qrIndex, setQrIndex] = useState(0)
  const [totalSize, setTotalSize] = useState(employeeInfo.staffInfo.wechatQRCode ? employeeInfo.staffInfo.wechatQRCode.length + 1 : 1)

  const [hasErr, setHasErr] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [filename, setFilename] = useState(new Date().getTime().toString())

  const uploadRef = useRef(null)

  const getImageById = (id) => {
    if (id) {
      return `${ServerBaseURL}/asset/staff/wechat/${uid}/${id}?${new Date().getTime()}`
    } 
      return null
    
  }

  useEffect(() => {
    // This user updated, need to refresh all data
    setTotalSize(employeeInfo.staffInfo.wechatQRCode ? employeeInfo.staffInfo.wechatQRCode.length + 1 : 1)
  }, [employeeInfo])

  const catchError = (error) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, error)
  };
  const afterSaving = (msg) => {
    setIsSaving(false)
    sendSuccessMessage(dispatch, msg)
    setFilename(new Date().getTime().toString())
  }

  const handleDeleteWechat = () => {
    const toDel = employeeInfo.staffInfo.wechatQRCode[qrIndex]
    deleteStaffWeChat(uid, toDel.id, employeeInfo.id,
      (msg) => {
        sendSuccessMessage(dispatch, msg)
        setQrIndex(0)
      },
      (msg) => sendErrorMessage(dispatch, msg)
    )(dispatch)
  }

  const handleUpdateWechat = (fileImg) => {
    if (!fileImg || hasErr) {
      return
    }

    setIsSaving(true)

    const current = employeeInfo.staffInfo.wechatQRCode[qrIndex]

    const file = new FormData()
    file.append('userId', employeeInfo.id)
    file.append('image', fileImg)
    if (current?.id) {
      // replace old image
      file.append('oldId', current.id)
    } else {
      // upload new image
      file.append('name', filename)
    }

    updateStaffWeChat(file, employeeInfo.id, afterSaving, catchError)(dispatch)
  }

  const fileNameCheck = (tempFilename) => {
    let hasErrTemp = false
    if (tempFilename.length <= 0 || tempFilename.length > 30) {
      hasErrTemp = true
      setErrMsg('Name must be between 1 and 30 characters')
    }
    if (employeeInfo.staffInfo.wechatQRCode.find(qr => qr.name.toLowerCase() === tempFilename.toLowerCase())) {
      hasErrTemp = true
      setErrMsg('Name already exists')
    }
    setHasErr(hasErrTemp)
  }

  const handleNext = () => {
    setQrIndex((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setQrIndex((prevActiveStep) => prevActiveStep - 1)
  }

  const renderQRGallery = () =>
    <Grid container direction='column' alignContent='center'>
      <form>
        <input type='file' name='file' disabled={isSaving} hidden ref={uploadRef}
               accept='image/*' onChange={e => {
          handleUpdateWechat(e.target.files[0])
          e.target.value = null
        }} />
        <Grid item>
          <div className={classes.root}>
            <div className={classes.filenameWrapper}>
              {
                qrIndex < employeeInfo.staffInfo.wechatQRCode.length
                  ? <Typography>{employeeInfo.staffInfo.wechatQRCode[qrIndex].name}</Typography>
                  :  <TextField variant='outlined' name='name' label='Name' size='small'
                                error={hasErr} helperText={hasErr ? errMsg : 'Max 30 characters'}
                                value={filename} onChange={(e) => {
                                  setFilename(e.target.value)
                                  fileNameCheck(e.target.value)
                  }}
                  />
              }
            </div>
            { qrIndex < employeeInfo.staffInfo.wechatQRCode.length &&
            <Tooltip title='Delete' placement='top' className={classes.floatingDelBtn}>
              <IconButton color='primary'
                          size='small'
                          component='span'
                          style={{ color: 'red' }}
                          onClick={() => handleDeleteWechat()}>
                <Close fontSize='large' />
              </IconButton>
            </Tooltip>
            }
            <Tooltip title='Click to upload' placement='left'>
              <img
                aria-hidden="true"
                className={classes.img}
                src={getImageById(employeeInfo.staffInfo.wechatQRCode[qrIndex]?.id) || NO_IMG}
                alt='User QR Code'
                onClick={() => {
                  if (uploadRef.current) {
                    uploadRef.current.click()
                  }
                }}
              />
            </Tooltip>
            <MobileStepper
              steps={totalSize}
              position="static"
              variant="text"
              activeStep={qrIndex}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={qrIndex + 1 >= totalSize}>
                  Next
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={qrIndex <= 0}>
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
          </div>
        </Grid>
      </form>
    </Grid>

  if (employeeInfo) {
    return renderQRGallery()
  } 
    return <div>Loading</div>
  

}

export default connect(null, { updateStaffWeChat })(EmployeeQRCode);
