import {SyllabusActionType} from '../../actions'
import LocalStorage from '../../utils/LocalStorage'
import {LS_KEY_SYLLABUS_EXPANDED} from '../../views/training/syllabus/syllabusHelper'

const initialState = {
    allSyllabuses: [],
    allClasses: [],
    currentSyllabusTab: 0, // saves Tab for syllabus list

    syllabusExpandStatus: {}
}

export const syllabusReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SyllabusActionType.FetchSyllabus:
            return {...state, allSyllabuses: action.payload}
        case SyllabusActionType.FetchClasses:
            return {...state, allClasses: action.payload}

        case SyllabusActionType.ChangeSyllabusTab:
            return {...state, currentSyllabusTab: action.payload}

        case SyllabusActionType.InitSyllabusExpandStatus:
            return {...state, syllabusExpandStatus: action.payload}
        case SyllabusActionType.ChangeSyllabusExpand:
            LocalStorage.setObjectItem(LS_KEY_SYLLABUS_EXPANDED, action.payload) // save to local storage
            return {...state, syllabusExpandStatus: action.payload}

        default:
            return state
    }
}
export default syllabusReducer
