import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Typography } from '@mui/material';
import { useParams } from 'react-router';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import history from '../../../utils/history';
import { sendSuccessMessage } from '../../../actions';
import ContractTemplateEditor from './ContractTemplateEditor';
import CenterDisplay from '../../../components/misc/CenterDisplay';
import { fetchContractTemplate } from '../../../actions/administrative/administrativeAction';

function CreateContractView({rowId}) {
  useCurrentViewCheck(Functions.Administrative_Contract_Template_Edit)

  const urlId = useParams().id
  const dispatch = useDispatch()
  const currentContract = useSelector(state => state?.administrative.currentContractTemplate)

  const [isLoaded, setIsLoaded] = useState(false)

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchContractTemplate(urlId || rowId)(dispatch))
    })()
  }
  useEffect(loadData, [dispatch, urlId || rowId])

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      // navigate(`/app/administrative/contractTemplate`)
      window.location.reload()
    }, 1000)
  }

  const renderBackButton = () => (
      <Button color='secondary'
              variant='contained'
              onClick={() => history.goBack()}>
        Back
      </Button>
    )

  return (
      <div>
        {
          urlId ?
              <ViewHead functionId={Functions.Administrative_Contract_Template_Edit}
                        topButtons={renderBackButton()}
                        noMaxWidth>
                {
                  isLoaded
                      ?
                      <Container maxWidth='md'>
                        <ContractTemplateEditor
                            isEdit
                            originalData={currentContract}
                            onSubmitSuccess={onSuccess}
                            type={currentContract.type}
                        />
                      </Container>
                      :
                      <CenterDisplay><Typography variant='h1' color='textPrimary'>Loading...</Typography></CenterDisplay>
                }

              </ViewHead>
              :
              <div>
                {
                  isLoaded
                      ?
                      <Container maxWidth='md'>
                        <ContractTemplateEditor
                            isEdit
                            originalData={currentContract}
                            onSubmitSuccess={onSuccess}
                            type={currentContract.type}
                        />
                      </Container>
                      :
                      <CenterDisplay><Typography variant='h1' color='textPrimary'>Loading...</Typography></CenterDisplay>
                }
              </div>
        }

      </div>

  )
}

export default CreateContractView
