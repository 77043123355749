import api from "../../apis/api";
import {AuthActionType} from "../auth/authActions";

export const fetchUserRole = () => async dispatch => {
    const response = await api.get(`/staff/myRole`);
    if (response.data.rs) {
        dispatch({type: AuthActionType.FetchUserRole, payload: response.data.data})
    } else {
        dispatch({type: AuthActionType.FetchUserRole, payload: 'Unknown'})
        // console.log('Cannot fetch role')
    }
}
export default fetchUserRole