import React, { useMemo } from 'react';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, TextField } from '@mui/material';
import { debounce } from 'lodash';
import DropdownButton from '../../../components/mui/button/DropdownButton';
import { DATA_SELECTION_OPTION, DATE_FORMAT } from './trackingHelper';

export const useDateSearchViewStyles = makeStyles(() => ({
  dateSearchContainer: {
    marginBottom: '10px',
  },
}))

function TrackingDateSearchView({ startDateFilter, setStartDateFilter,
                                  endDateFilter, setEndDateFilter, searchFunction}) {

  const classes = useDateSearchViewStyles()
  const searchHandler = useMemo(() =>
    debounce((date) => searchFunction(date), 1000), [])

  const onDropdownSelected = (selectedValue) => {
    const today = moment()
    const startDate = moment()
    switch (selectedValue) {
      case 1:     // Past Week
        startDate.subtract(1, 'week')
        break;
      case 2:     // Past Month
        startDate.subtract(1, 'month')
        break;
      case 3:     // Past 3 Months
        startDate.subtract(3, 'month')
        break;
      default: break;
    }
    setStartDateFilter(startDate.format(DATE_FORMAT))
    setEndDateFilter(today.format(DATE_FORMAT))
    searchFunction({
      customStartDate: startDate.format(DATE_FORMAT),
      customEndDate: today.format(DATE_FORMAT)
    })
  }

  const onStartChange = (newDate) => {
    setStartDateFilter(newDate)
    searchHandler({
      customStartDate: newDate,
      customEndDate: endDateFilter
    })
  }

  const onEndChange = (newDate) => {
    setEndDateFilter(newDate)
    searchHandler({
      customStartDate: startDateFilter,
      customEndDate: newDate
    })
  }

  return (
    <Grid container spacing={1} alignItems='flex-end'
          direction="row"
          className={classes.dateSearchContainer}>
      <Grid item xs={12} sm={4} md={4}>
        <TextField type="date" name="date-start" label="From"
                   size='small'
                   InputLabelProps={{ shrink: true }}
                   value={startDateFilter} fullWidth
                   onChange={e => onStartChange(e.target.value)}/>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <TextField type="date" name="date-end" label="To"
                   size='small'
                   InputLabelProps={{ shrink: true }}
                   value={endDateFilter} fullWidth
                   onChange={e => onEndChange(e.target.value)}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DropdownButton
          onClick={searchFunction}
          onSelect={onDropdownSelected}
          label="Search Date" fullWidth
          options={DATA_SELECTION_OPTION}
        />
      </Grid>
    </Grid>
  )
}

export default TrackingDateSearchView
