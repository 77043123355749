import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import history from '../../../utils/history';
import { sendSuccessMessage } from '../../../actions';
import CenterDisplay from '../../../components/misc/CenterDisplay';
import { fetchContract } from '../../../actions/administrative/administrativeAction';
import ContractEditor from './ContractEditor';
import { useContractListStyles } from './contractHelper';

function CreateContractView() {
  useCurrentViewCheck(Functions.Administrative_Contract_Edit)
  const  navigate = useNavigate();
  const {uid} = useParams()
  const {cid} = useParams()
  const dispatch = useDispatch()
  const classes = useContractListStyles()
  const currentContract = useSelector(state => state.administrative.currentContract)

  const [isLoaded, setIsLoaded] = useState(false)

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchContract(uid, cid)(dispatch))
    })()
  }
  useEffect(loadData, [dispatch, uid, cid])

  const onSuccess = (msg, uidTemp, cidTemp) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/administrative/contract/view/${uidTemp}/${cidTemp}`)
    }, 1000)
  }

  const renderBackButton = () => (
      <div className={classes.pageTitle}>
        {
          !isLoaded || currentContract?.isMain
            ?
            <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate('/app/administrative/contract')}>
              Back to All Contracts
            </Button>
            :
            <Button color='secondary'
                    variant='contained'
                    onClick={() => history.goBack()}>
              Back
            </Button>
        }
      </div>
    )

  return (
    <ViewHead functionId={Functions.Administrative_Contract_Edit}
              isShowTitle={false}
              noMaxWidth>
      {
        isLoaded
          ?
          <div>
            <Typography variant='h3' className={classes.pageTitle} color='textPrimary'>
              Editing { currentContract?.isMain ? 'Contract' : 'Subcontract' }: { currentContract?.cid }
            </Typography>
            { renderBackButton() }
            <ContractEditor isEdit customerId={uid} originalData={currentContract} onSubmitSuccess={onSuccess} />
          </div>
          : <CenterDisplay><Typography variant='h1' color='textPrimary'>Loading...</Typography></CenterDisplay>
      }

    </ViewHead>
  )
}

export default CreateContractView
