import {TagActionType} from '../../actions/public/tagActions'

const defaultState = {
    allTags: [],
    tagByGroup: [],
    prefixMatchByTitle: [],
    prefixMatchByNote: [],

    tagMessage: {}
}

export const tagReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case TagActionType.FetchAllTags: {
            return {...state, allTags: action.payload}
        }
        case TagActionType.FetchTagsByGroup: {
            return {...state, tagByGroup: action.payload}
        }
        case TagActionType.SearchPrefixByTitle: {
            return {...state, prefixMatchByTitle: action.payload}
        }
        case TagActionType.SearchPrefixByNote: {
            return {...state, prefixMatchByNote: action.payload}
        }
        case TagActionType.UpdateTags:
            return {...state, tagMessage: action.payload}
        case TagActionType.DeleteTag:
            return {...state, tagMessage: action.payload}
        case TagActionType.AddTag:
            return {...state, tagMessage: action.payload}
        default: {
            return state
        }
    }
}

export default tagReducer
