import React, {useEffect, useState} from 'react'
import {
    Button,
    Card,
    CardActions,
    Grid,
    IconButton,
    Input,
    Modal,
    Paper,
    TextField,
    Tooltip,
    Typography
} from '@mui/material'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {Delete, Edit, SaveAlt} from '@mui/icons-material'
// import { Upload } from '@progress/kendo-react-upload';
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import {Functions} from '../../../utils/Functions'
import ViewHead from '../../../components/public/ViewHead'
import LoadingScreen from '../../../components/public/LoadingScreen'
import {
    createCustomerNote,
    deleteCustomerNote,
    fetchCustomerNoteFile,
    fetchCustomerNoteImage,
    fetchCustomerNotes,
    sendErrorMessage,
    sendSuccessMessage
} from '../../../actions'
import {useInterviewEditorStyles} from '../interview/interviewHelper'
import UserAvatar from '../../../components/user/UserAvatar'
import {USER_ROLE_ID} from '../../../constants'
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog'
import EditNoteDialogue from './EditNoteDialogue'
import SubmitButton from '../../../components/mui/button/SubmitButton'
import {noteMaxLength} from '../../../components/form/NoteData'

function InterviewViewerView() {
    useCurrentViewCheck(Functions.Customer_Management_Tracing)
    const navigate = useNavigate()
    const {id} = useParams()
    const dispatch = useDispatch()
    const classes = useInterviewEditorStyles()

    const auth = useSelector(state => state.auth)
    const allCustomerNotes = useSelector(state => state.customer.currentCustomerNotes)
    const imageContainer = useSelector(state => state.customer.customerNoteImages)

    const [isLoaded, setIsLoaded] = useState(false)
    const [isSaving, setIsSaving] = useState(null)
    const [noteValue, setNoteValue] = useState('')
    const [noteError, setNoteError] = useState(null)
    const [noteFile, setNoteFile] = useState(null)

    const [hash, setHash] = useState('init')
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
    const [selectedForDel, setSelectedForDel] = useState(-1)
    const handleDeleteOpen = noteId => {
        setSelectedForDel(noteId)
        setOpenDeleteDialogue(true)
        setHash(Math.random().toString(16).substring(2, 10))
    }

    const [selectedForEdit, setSelectedForEdit] = useState(null)
    const [openEditModal, setEditModalOpen] = useState(false)
    const handleEditModalClose = () => setEditModalOpen(false)
    const handleEditModalOpen = data => {
        setSelectedForEdit(data)
        setEditModalOpen(true)
    }

    const onDeleteNote = async () => {
        await deleteCustomerNote(
            selectedForDel,
            id,
            msg => sendSuccessMessage(dispatch, msg),
            msg => {
                setSelectedForDel(-1)
                sendErrorMessage(dispatch, msg)
            }
        )(dispatch)
    }

    const onEditCommentSuccess = () => {
        handleEditModalClose()
        setSelectedForEdit(null)
    }

    const loadData = () => {
        ;(async () => {
            setIsLoaded(await fetchCustomerNotes(id)(dispatch))
        })()
    }
    useEffect(loadData, [dispatch, id])

    useEffect(() => {
        allCustomerNotes.forEach(async note => {
            // Get images ready by preloading blobs
            if (note.fileName && note.fileMime.match('image/*') && !imageContainer[note.id]) {
                await fetchCustomerNoteImage(note.customer.id, note.id, note.fileMime)(dispatch)
            }
        })
    }, [dispatch, allCustomerNotes, imageContainer])

    const handleNoteCreate = async e => {
        e.preventDefault()
        setIsSaving(true)
        setNoteError(null)

        if (noteValue.length > noteMaxLength) {
            setIsSaving(false)
            setNoteError(`Comment cannot be longer than ${noteMaxLength} characters`)
            return
        }

        const file = new FormData()
        file.append('customerId', id)
        file.append('notes', noteValue)

        // Test if comment contains file
        const uploadFile = noteFile?.length > 0 ? noteFile[0]?.getRawFile() : null
        if (uploadFile) {
            file.append('file', uploadFile)
        }

        createCustomerNote(file, id, onNoteSuccess, onNoteError)(dispatch)
    }

    const onNoteSuccess = async msg => {
        setIsSaving(false)
        setNoteValue('')
        setNoteFile(null)
        sendSuccessMessage(dispatch, msg)
    }

    const onNoteError = msg => {
        setIsSaving(false)
        sendErrorMessage(dispatch, msg)
    }

    const renderTopButton = () => (
        <Button color="secondary" variant="contained" onClick={() => navigate('/app/customer/registered')}>
            Customer List
        </Button>
    )

    const renderNotesCreateView = () => (
        <form onSubmit={handleNoteCreate}>
            <Grid container className={classes.commentField}>
                <Grid item xs={12} sm={6} md={8}>
                    <TextField
                        fullWidth
                        multiline
                        rows={5}
                        variant="outlined"
                        name="comment"
                        label="Comment"
                        required
                        value={noteValue}
                        disabled={isSaving}
                        onChange={e => setNoteValue(e.target.value)}
                        error={!!noteError}
                        helperText={noteError || ''}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Input
                        type="file"
                        batch={false}
                        multiple={false}
                        withCredentials={false}
                        autoUpload={false}
                        showActionButtons={false}
                        disabled={isSaving}
                        inputProps={{
                            accept: '.pdf'
                        }}
                        className={clsx('fileUploader', {
                            full: !noteFile
                        })}
                        onChange={e => setNoteFile(e.target.files)}
                        underline={false}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container justifyContent="flex-end" alignItems="center" className={classes.marginTop}>
                    <Grid item>
                        <SubmitButton
                            isSaving={isSaving}
                            text="Submit Comment"
                            isSavingText="Submitting"
                            variant="contained"
                            color="primary"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )

    const renderNotesView = () =>
        allCustomerNotes.map(note => (
            <Card key={note.id} className={classes.gridViewCard}>
                <Grid
                    container
                    direction="row"
                    spacing={1}
                    justifyContent="center"
                    alignItems="flex-start"
                    alignContent="center"
                >
                    <Grid item xs={12} md={2}>
                        <Grid container direction="row" spacing={1} justifyContent="center">
                            <Grid item xs={6} md={12}>
                                <div className="interviewerPic">
                                    {/* <Avatar shape='circle' type='text'>{note.interviewer[0]}</Avatar> */}
                                    <UserAvatar shape="circle" alt={note.user.firstName} uid={note.user.uid} avatar />
                                    <Typography variant="body1">{note.user.firstName}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={12}>
                                <div className="interviewerDate">
                                    <Typography variant="body2">{moment(note.createdAt).format('L')}</Typography>
                                    <Typography variant="body2">{moment(note.createdAt).format('LT')}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography variant="body2" className="textContainer">
                            11111111111111
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <div className="imageContainer">
                            {note.fileName && (
                                <div>
                                    {imageContainer[note.id] ? (
                                        <Tooltip title="Click to download">
                                            <img
                                                aria-hidden="true"
                                                alt="user uploaded file preview"
                                                src={imageContainer[note.id]}
                                                onClick={async () => {
                                                    await fetchCustomerNoteFile(
                                                        note.customer.id,
                                                        note.id,
                                                        note.fileMime,
                                                        imageContainer[note.id]
                                                    )(dispatch)
                                                }}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className="downloadButton"
                                            startIcon={<SaveAlt />}
                                            onClick={async () => {
                                                await fetchCustomerNoteFile(
                                                    note.customer.id,
                                                    note.id,
                                                    note.fileMime
                                                )(dispatch)
                                            }}
                                        >
                                            Download
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <CardActions>
                    {(note.user === auth.user?.id ||
                        auth.user?.roleIds.some(role => role.toString() === USER_ROLE_ID.ADMIN.toString())) && (
                        <div className="editButtons">
                            <Tooltip title="Edit" placement="top">
                                <IconButton
                                    size="small"
                                    component="span"
                                    onClick={() =>
                                        handleEditModalOpen({customerId: id, noteId: note.id, notes: note.notes})
                                    }
                                >
                                    <Edit size={20} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" placement="top">
                                <IconButton size="small" component="span" onClick={() => handleDeleteOpen(note.id)}>
                                    <Delete size={20} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </CardActions>
            </Card>
        ))

    const renderView = () => (
        <div>
            <Paper className={classes.paper}>{renderNotesCreateView()}</Paper>
            <Paper className={classes.paper}>{renderNotesView()}</Paper>
        </div>
    )

    return (
        <ViewHead functionId={Functions.Customer_Management_Tracing} topButtons={renderTopButton()}>
            {isLoaded ? (
                <div>
                    {renderView()}
                    <Modal
                        open={openEditModal}
                        onClose={handleEditModalClose}
                        className={classes.modal}
                        disablePortal
                        disableRestoreFocus
                        disableEnforceFocus
                        disableAutoFocus
                        closeAfterTransition
                    >
                        <Paper className={classes.modalPaper}>
                            <EditNoteDialogue data={selectedForEdit} onSubmitSuccess={onEditCommentSuccess} />
                        </Paper>
                    </Modal>

                    <ResponsiveDialog
                        isOpen={openDeleteDialogue}
                        openHash={hash}
                        title="Delete"
                        content="Delete Note?"
                        Buttons={[
                            {name: 'Yes', event: () => onDeleteNote()},
                            {name: 'Cancel', event: () => setSelectedForDel(null)}
                        ]}
                    />
                </div>
            ) : (
                <LoadingScreen message="Loading..." />
            )}
        </ViewHead>
    )
}

export default InterviewViewerView
