import React, {useEffect, useState} from 'react';
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useForm} from "react-hook-form";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {useDispatch} from "react-redux";
import { useNavigate } from 'react-router-dom';
import SubmitButton from "../../components/mui/button/SubmitButton";
import {Functions} from "../../utils/Functions";
import ViewHead from "../../components/public/ViewHead";
import {changePassword, Logout} from "../../actions";

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        backgroundColor: theme.palette.background.dark,
        padding: "2rem 2rem",
        maxWidth: 600,
    },
    textField: {},
    success: {
        fontSize: "1rem",
        fontWeight: 'bold',
        marginBottom: "1rem",
    }
}))


function ChangePasswordView() {
    const  navigate = useNavigate();
    const classes = useStyles();
    const [isSaving, setIsSaving] = useState(false);
    const [second,setSecond] = useState(5);
    const [values, setValues] = useState({
        showPassword: false,
    });
    const [isChanged,setIsChanged] = useState(false);
    const dispatch = useDispatch();
    const cb = () => {
        navigate('/auth/login')
    }
    const setCountDown = ()=>{
        if(isChanged){
            const timerId = setInterval(()=>{
                setSecond(second-1)
                if(second===1){
                    Logout(cb)(dispatch);
                    navigate('/auth/login');
                }
            },1000)
            return ()=>clearInterval(timerId);
        }
        return ()=>{}
    }
    useEffect(setCountDown,[second,isChanged])

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };
    // const handleChange = (prop) => (event) => {
    //     setValues({...values, [prop]: event.target.value});
    // };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const {register, errors, handleSubmit, watch, setError } = useForm({
        mode:"onBlur",
        reValidateMode:"onChange",
    });
    const watchNewPassword = watch("newPassword");

    const handleSaveSubmit = (formData) => {
        if(Object.keys(errors).length>0){
            return;
        }

        setIsSaving(true);
        changePassword(formData,afterSaving,handleErrorBack);

    }
    const handleErrorBack = (error) => {
        setError("currentPassword",
            {type:"manual",message:error})
        setIsSaving(false);
    }
    const afterSaving = () => {
        setIsSaving(false);
        setIsChanged(true);
    }


    return (
        <ViewHead functionId={Functions.My_Profile_Personal_Information_Change_Password}>
            <Paper className={classes.paper}>
                <form noValidate onSubmit={handleSubmit(handleSaveSubmit)}>
                    {isChanged && (
                        <FormHelperText error className={classes.success}>
                            Password updated successfully! <br/>
                            You will be redirected to the login page in {second} seconds.
                        </FormHelperText>
                    )}
                    <Typography variant="body2" style={{marginBottom: ".6rem"}}>
                        You must provide your current password in order to change it.
                    </Typography>
                    <FormControl fullWidth className={classes.textField} variant="outlined" size="small">
                        <InputLabel htmlFor="currentPassword">Current Password</InputLabel>
                        <OutlinedInput inputRef={register({
                            minLength: {
                                value: 6,
                                message: 'Current Password must be at least 6 characters'
                            },
                            required: "Current Password is required"
                        })}
                                       name="currentPassword" autoComplete="password"
                                       labelWidth={130} type={values.showPassword ? 'text' : 'password'}
                                       error={Boolean(errors.currentPassword?.message)}
                                       endAdornment={
                                           <InputAdornment position="end">
                                               <IconButton
                                                   onClick={handleClickShowPassword}
                                                   onMouseDown={handleMouseDownPassword}
                                                   size="large">
                                                   {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                               </IconButton>
                                           </InputAdornment>
                                       }
                        />
                    </FormControl>
                    <FormHelperText error={Boolean(errors.currentPassword?.message)}>
                        {errors.currentPassword?.message}
                    </FormHelperText>
                    <Typography variant="body2" style={{marginTop: ".5rem", marginBottom: ".6rem"}}>
                        <b>The New password must contain all character categories among the following:</b>
                        <li>Uppercase characters (A-Z)</li>
                        <li>Lowercase characters (a-z)</li>
                        <li>Digits (0-9)</li>
                    </Typography>
                    <FormControl fullWidth className={classes.textField} variant="outlined" size="small"
                                 style={{marginTop: ".5rem", marginBottom: ".6rem"}}>
                        <InputLabel htmlFor="newPassword">New Password</InputLabel>
                        <OutlinedInput inputRef={register({
                            pattern: {
                                value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                                message: 'New Password is too simple'
                            },
                            maxLength: {
                                value: 20,
                                message: "New Password must be no more than 20 characters"
                            },
                            minLength: {
                                value: 8,
                                message: "New Password must be at least 8 characters"
                            },
                            required: "New Password is required",
                        })} name="newPassword" autoComplete="password" labelWidth={110}
                                       type={values.showPassword ? 'text' : 'password'}
                                       error={Boolean(errors.newPassword?.message)}
                                       endAdornment={
                                           <InputAdornment position="end">
                                               <IconButton
                                                   onClick={handleClickShowPassword}
                                                   onMouseDown={handleMouseDownPassword}
                                                   size="large">
                                                   {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                               </IconButton>
                                           </InputAdornment>
                                       }
                        />
                    </FormControl>
                    <FormHelperText error={Boolean(errors.newPassword?.message)}>
                        {errors.newPassword?.message}
                    </FormHelperText>
                    <FormControl fullWidth className={classes.textField} variant="outlined" size="small"
                                 style={{marginTop: ".5rem", marginBottom: ".6rem"}}>
                        <InputLabel htmlFor="confirmedPassword">Confirm Password</InputLabel>
                        <OutlinedInput inputRef={register({
                            minLength: {
                                value: 8,
                                message: "Confirm Password must be at least 8 characters"
                            },
                            required: "Confirm Password is required",
                            validate:{
                                isMatch: value => value===watchNewPassword || "Those passwords didn't match. Try again.",
                            },

                        })} name="confirmedPassword" autoComplete="password" labelWidth={130}
                                       type={values.showPassword ? 'text' : 'password'}
                                       error={Boolean(errors.confirmedPassword?.message)}
                                       endAdornment={
                                           <InputAdornment position="end">
                                               <IconButton
                                                   onClick={handleClickShowPassword}
                                                   onMouseDown={handleMouseDownPassword}
                                                   size="large">
                                                   {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                               </IconButton>
                                           </InputAdornment>
                                       }
                        />
                    </FormControl>
                    <FormHelperText error={Boolean(errors.confirmedPassword?.message)}>
                        {errors.confirmedPassword?.message}
                    </FormHelperText>


                    <Typography variant="h5" style={{marginBottom: "2rem"}}>
                        After a successful password update, you will be redirected to the login page where you can log
                        in with your new password.
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item sm={5}>
                            <SubmitButton isSaving={isSaving} text="Save Password" isSavingText="Saving Password..."
                                          fullWidth variant="contained" color="primary"/>
                        </Grid>

                        <Grid item sm={5}>

                                <Button type="button" fullWidth variant="contained" color="primary" onClick={() => {
                                    navigate('/app/personal/info');
                                }}>
                                    Cancel
                                </Button>


                        </Grid>
                    </Grid>
                </form>


            </Paper>
        </ViewHead>
    );
}

export default ChangePasswordView;
