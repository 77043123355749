import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AppBar, Button, Paper, Tab, Tabs} from '@mui/material'
import {fetchAllReviewedCategories, fetchProductReview, ReviewActionType} from '../../../actions/review/reviewActions'
import {a11yProps} from '../../../components/mui/tabs/TabPanel'
import ViewHead from '../../../components/public/ViewHead'
import {Functions} from '../../../utils/Functions'
import useAuthCheck from '../../../hooks/useAuthCheck'
import DraggableDialog from '../../../components/mui/dialog/DraggableDialog'
import ProductReviewEditor from './editor/ProductReviewEditor'
import ProductReviews from './ProductReviews'
import {fetchReviewedProductsByCategory, getReviewedProductById, ProductsActions} from '../../../actions'

function ProductReviewListView() {
    const dispatch = useDispatch()
    const reviewedProductsByCategory = useSelector(state => state.products?.reviewedProductsByCategory)
    const allReviewedCategories = useSelector(state => state.review?.reviewedCategories)
    const [canCreateReview] = useAuthCheck([Functions.Review_M2W_Add])
    const reviewDataById = useSelector(state => state?.review?.productReviewById)
    const selectProduct = useSelector(state => state?.products?.oneProduct)
    const currentCatTab = useSelector(state => state.review.currentCatTab)
    const currentTab = useSelector(state => state.review.currentReviewTab)
    const selectedProductId = useSelector(state => state?.review?.selectedProductId)
    const saved = useSelector(state => state?.review?.saved)

    const [isOpen, setIsOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [rowId, setRowId] = useState(null)

    const [openEdit, setOpenEdit] = useState(false)
    const [openRead, setOpenRead] = useState(false)
    const [isOpenRead, setIsOpenRead] = useState(false)
    const [clickedRow, setClickedRow] = useState('')
    const [readOnly, setReadOnly] = useState(null)

    useEffect(() => {
        if (readOnly === false) {
            setIsOpenRead(false)
        }
    }, [readOnly])

    useEffect(() => {
        dispatch(fetchAllReviewedCategories())
    }, [])
    useEffect(() => {
        if (openEdit && clickedRow !== '') {
            setIsOpen(true)
            setIsEdit(true)
            setRowId(clickedRow)
        }
    }, [openEdit, clickedRow])

    useEffect(() => {
        if (openRead && clickedRow !== '') {
            setIsOpen(true)
            setIsOpenRead(true)
            setIsEdit(true)
            setRowId(clickedRow)
        }
    }, [openRead, clickedRow])

    useEffect(() => {
        if (rowId && isEdit) {
            dispatch(fetchProductReview(rowId))
        }
    }, [rowId])

    useEffect(async () => {
        if (allReviewedCategories.length > 0) {
            await dispatch(fetchReviewedProductsByCategory(allReviewedCategories[currentCatTab]))

            if (!saved) {
                dispatch({type: ReviewActionType.ChangeReviewTab, payload: 0})
                dispatch({type: ProductsActions.FetchProductById, payload: null})
            }
        }
    }, [allReviewedCategories, currentCatTab])

    useEffect(() => {
        if (
            allReviewedCategories[currentCatTab]?.products.length > 0 &&
            allReviewedCategories[currentCatTab]?.products.filter(
                p => p.id === allReviewedCategories[currentCatTab].products[currentTab]?.id
            ).length > 0 &&
            !saved
        ) {
            dispatch({
                type: ReviewActionType.SetSelectedProductId,
                payload: allReviewedCategories[currentCatTab]?.products[currentTab]?.id
            })
        }
    }, [reviewedProductsByCategory, currentTab])

    useEffect(() => {
        if (selectedProductId > 0) {
            dispatch(getReviewedProductById(selectedProductId))
        }
    }, [selectedProductId])

    const renderAddNewButton = () => (
        <div>
            {canCreateReview ? (
                <Button color="secondary" variant="contained" onClick={() => setIsOpen(true)}>
                    Add Review
                </Button>
            ) : (
                ''
            )}
        </div>
    )

    const resetAllState = () => {
        setIsOpen(false)
        setIsOpenRead(false)
        setOpenRead(false)
        setRowId(null)
        setIsEdit(false)
        setOpenEdit(false)
        setClickedRow('')
        dispatch({type: ReviewActionType.FetchProductReviewById, payload: {}})
        dispatch({type: ReviewActionType.FormStatus, payload: false})
    }

    const handleChange = (event, newValue) => {
        dispatch({type: ReviewActionType.ChangeReviewTab, payload: newValue})
    }

    const handleCategoryChange = (event, newValue) => {
        dispatch({type: ReviewActionType.ChangeCategoryReviewTab, payload: newValue})
    }

    const renderTabs = () => (
        <Paper elevation={0} style={{paddingBottom: '16px'}}>
            <AppBar position="sticky" color="default">
                <Tabs value={currentCatTab} onChange={handleCategoryChange} variant="scrollable" scrollButtons>
                    {allReviewedCategories?.map((value, index) => (
                        <Tab key={index} label={`${value?.name}`} {...a11yProps(index)} />
                    ))}
                </Tabs>
                <Tabs value={currentTab} onChange={handleChange} variant="scrollable" scrollButtons>
                    {reviewedProductsByCategory?.map((value, index) => (
                        <Tab key={index} label={`${value?.name}`} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </AppBar>
            <ProductReviews
                setOpenRead={setOpenRead}
                setOpenEdit={setOpenEdit}
                setClickedRow={setClickedRow}
                productId={selectedProductId}
                setReadOnly={setReadOnly}
            />
        </Paper>
    )

    const renderReviewEditor = isEdit => (
        <DraggableDialog
            open={Boolean(isOpen)}
            onClose={() => resetAllState()}
            title={isEdit ? (isOpenRead ? 'View Mode' : 'Edit Review') : 'Create Review'}
            isLoaded={Boolean(isOpen)}
        >
            <Paper elevation={0}>
                <ProductReviewEditor
                    isEdit={isEdit}
                    originalData={reviewDataById}
                    selectProduct={selectProduct}
                    isOpenRead={isOpenRead}
                    setReadOnly={setReadOnly}
                />
            </Paper>
        </DraggableDialog>
    )

    return (
        <ViewHead functionId={Functions.Review_M2W_List} topButtons={renderAddNewButton()} noMaxWidth>
            {renderTabs()}
            {renderReviewEditor(isEdit)}
        </ViewHead>
    )
}
export default ProductReviewListView
