import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import {Button, FormControlLabel, Radio, RadioGroup, useMediaQuery} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import dayjs from 'dayjs'
import {DateRangeCalendar, SingleInputDateRangeField} from '@mui/x-date-pickers-pro'
import DraggableDialog, {dialogButtonData} from '../mui/dialog/DraggableDialog'
import {DATE_PICKER_FORMAT, defaultRanges} from './DatePickerComponent'

function DatePickerButton({
    defaultRange,
    dateRanges,
    onValueChange,
    updateCustomValue,
    dateRangesSelected,
    setSelectedValue,
    setHandleSelect
}) {
    const [value, setValue] = useState(null)
    const updateValue = val => setValue(val)

    // set default date range
    const [defaultRangeLabel, setDefaultRangeLabel] = useState('')
    const [datePickerDefaultRangeObj, setDatePickerDefaultRangeObj] = useState(null)
    const [datePickerDefaultRangeArr, setDatePickerDefaultRangeArr] = useState(null)
    const updateDefaultRangeLabel = val => setDefaultRangeLabel(val)
    const updateDefaultRangeObj = val => setDatePickerDefaultRangeObj(val)
    const updateDefaultRangeArr = val => setDatePickerDefaultRangeArr(val)
    const updateSelectedValue = val => setSelectedValue(val)
    // check if default range is given
    useEffect(() => {
        setHandleSelect(true)
        if (defaultRange) {
            updateDefaultRangeLabel(defaultRange)
        } else {
            updateDefaultRangeLabel(defaultRanges[2].label)
        }
    }, [])

    useEffect(() => {
        if (defaultRangeLabel !== '') {
            const rangeObj = dateRanges.filter(ele => ele.label.trim() === defaultRangeLabel)[0]
            if (!rangeObj) {
                throw new Error(
                    'Please set a default range if you are not using default ranges of date picker. Note: default range is a string value.'
                )
            }
            updateDefaultRangeObj(rangeObj)
        }
    }, [defaultRangeLabel])

    // find range by the label given
    useEffect(() => {
        updateDefaultRangeArr([datePickerDefaultRangeObj?.startDate, datePickerDefaultRangeObj?.endDate])
    }, [datePickerDefaultRangeObj])

    // assign default range to value
    useEffect(() => {
        updateValue(datePickerDefaultRangeArr)
        // update default selected date range
        updateSelectedValue(datePickerDefaultRangeArr)
    }, [datePickerDefaultRangeArr])

    // picker value is in dayjs format
    const [pickerValue, setPickerValue] = useState(null)
    const updatePickerValue = val => setPickerValue(val)

    useEffect(() => {
        if (value) {
            updatePickerValue([dayjs(value[0]), dayjs(value[1])])
            updateSelectedValue(value)
        }
    }, [value])

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setIsSaved(false)
        setOpen(true)
    }

    const handleClose = e => {
        setHandleSelect(e)
        setOpen(false)
        if (isCustomClicked && !isSaved) {
            setValue([dateRanges[5].startDate, dateRanges[5].endDate])
            setPickerValue([dayjs(value[0]), dayjs(value[1])])
            onValueChange([dateRanges[5].startDate, dateRanges[5].endDate])
        }
    }

    const handleRadioChange = e => {
        const selectedRange = JSON.parse(e.target.value)
        setValue(selectedRange)
        setPickerValue([dayjs(value[0]), dayjs(value[1])])
        onValueChange(selectedRange)
    }

    const handleCustomValueChange = val => {
        if (!val.includes(null)) {
            setPickerValue([val[0], val[1]])
            setValue([dayjs(val[0])?.format(DATE_PICKER_FORMAT), dayjs(val[1])?.format(DATE_PICKER_FORMAT)])
            updateCustomValue([dayjs(val[0])?.format(DATE_PICKER_FORMAT), dayjs(val[1])?.format(DATE_PICKER_FORMAT)])
            onValueChange(val)
        } else if (val[1] === null) {
            setPickerValue([val[0], null])
        } else if (val[0] === null) {
            setPickerValue([null, val[1]])
        }
    }

    // press enter to close the window
    const handleEnterKeyUp = evt => {
        if (evt.key === 'Enter') {
            handleClose()
        }
    }

    const handleResetButtonClick = () => {
        setValue(datePickerDefaultRangeArr)
        setPickerValue([dayjs(datePickerDefaultRangeArr[0]), dayjs(datePickerDefaultRangeArr[1])])
    }

    // if custom radio button clicked or not
    const [isCustomClicked, setIsCustomClicked] = useState(false)
    const handleCustomRadioButtonClicked = () => setIsCustomClicked(true)
    const handleCustomRadioButtonNotClicked = () => setIsCustomClicked(false)

    const isSmallScreen = useMediaQuery('(max-width: 1100px)')

    const resetButton = {
        ...dialogButtonData,
        label: 'reset',
        variant: 'text',
        style: {
            color: '#ccc',
            margin: '0 10px 0 0',
            border: 'solid 1px grey'
        },
        onClick: handleResetButtonClick
    }
    const [isSaved, setIsSaved] = useState(false)
    const handleSave = e => {
        setHandleSelect(e)
        setOpen(false)
        setIsSaved(true)
        const val = value.includes(null) || value.includes('Invalid Date')
        if (isCustomClicked && val) {
            setValue([dateRanges[5].startDate, dateRanges[5].endDate])
            setPickerValue([dayjs(value[0]), dayjs(value[1])])
            onValueChange([dateRanges[5].startDate, dateRanges[5].endDate])
        }
    }

    const submitButton = {
        ...dialogButtonData,
        label: 'submit',
        onClick: handleSave,
        style: {
            backgroundColor: 'rgba(80, 88, 236)',
            width: '100px'
        }
    }

    return (
        <Box>
            <Button
                onClick={handleOpen}
                sx={{
                    backgroundColor: 'rgba(0,0,0,0)',
                    color: '#000',
                    fontWeight: '400',
                    border: 'solid 1px grey'
                }}
            >
                Date Range: {dateRangesSelected}
            </Button>
            <DraggableDialog
                open={open}
                onClose={handleClose}
                title={`Date Range (${DATE_PICKER_FORMAT})`}
                footerText={`Date Range: ${dateRangesSelected}`}
                dialogCustomButtons={[resetButton, submitButton]}
            >
                <Box
                    onKeyUp={handleEnterKeyUp}
                    sx={{
                        background: '#fff',
                        padding: '0 1rem',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <FormControl>
                        <RadioGroup value={JSON.stringify(value)} onChange={handleRadioChange}>
                            {dateRanges.map((ele, inx) => (
                                <FormControlLabel
                                    key={inx}
                                    control={
                                        <Radio
                                            onChange={() => {
                                                if (ele.label === 'Custom') {
                                                    handleCustomRadioButtonClicked()
                                                } else {
                                                    handleCustomRadioButtonNotClicked()
                                                }
                                            }}
                                        />
                                    }
                                    label={ele.label}
                                    value={JSON.stringify([ele.startDate, ele.endDate])}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>

                    {isCustomClicked && (
                        <Box>
                            <SingleInputDateRangeField
                                value={pickerValue}
                                sx={{
                                    width: '250px',
                                    height: '40px',
                                    margin: '10px 0 10px 0'
                                }}
                                onChange={handleCustomValueChange}
                            />

                            {isSmallScreen ? (
                                <DateRangeCalendar
                                    calendars={1}
                                    value={pickerValue}
                                    sx={{
                                        margin: '10px'
                                    }}
                                    onChange={handleCustomValueChange}
                                />
                            ) : (
                                <DateRangeCalendar
                                    value={pickerValue}
                                    sx={{
                                        margin: '10px'
                                    }}
                                    onChange={handleCustomValueChange}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </DraggableDialog>
        </Box>
    )
}

export default DatePickerButton
