import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Functions } from '../../../../utils/Functions';
import ViewHead from '../../../../components/public/ViewHead';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import PosterMakerTemplateEditor from './PosterMakerTemplateEditor';
import { sendSuccessMessage } from '../../../../actions';

function CreatePosterTemplateView() {
  useCurrentViewCheck(Functions.Forms_Poster_Generator_Create)
  const  navigate = useNavigate();
  const dispatch = useDispatch()

  const onSuccess = (data) => {
    sendSuccessMessage(dispatch, 'Poster template created')
    setTimeout(() => {
      navigate(`/app/forms/poster/view/${data.id}`)
    }, 1000)
  }

  const renderBackButton = () =>
    <Button color='secondary'
            variant='contained'
            onClick={() => navigate(`/app/forms/poster`)}>
      Back
    </Button>

  return (
    <ViewHead functionId={Functions.Forms_Poster_Generator_Create} noMaxWidth
              topButtons={renderBackButton()}>
      <PosterMakerTemplateEditor onSuccess={onSuccess} />
    </ViewHead>
  )
}

export default CreatePosterTemplateView
