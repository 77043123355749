import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { Copy, Edit as EditIcon, Eye } from 'react-feather';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { CATEGORY_ITEMS } from "../../../constants";
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog';
import EnhancedTableToolbar from "./EnhancedTableToolBar";
import PageGrid from '../../../components/mui/datagrid/PageGrid';
import { activateClass, deleteClass, fetchClasses } from '../../../actions/class/classActions';
import DraggableDialog from "../../../components/mui/dialog/DraggableDialog";
import AddClass from "./AddClass";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    toolBar: {
        // width: '100%',
        // padding: 'inherit',

    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding:theme.spacing(2)
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    editIcon: {
        cursor: 'pointer'
    },
    tableCell: {
        width: 80,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px'
    },
    funcCell: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
}));
function DataTable({isEdit,isClone, handleRead, handleFetchClass}) {
    const classesTable = useStyles()
    // const [delId, setDelId] = useState(null)
    // const [open, setOpen] = useState(false)
    // const [hash, setHash] = useState("init")

    const delId = null
    const open = false
    const hash = 'init'

    const [searchArgs, setSearchArgs] = useState({searchKey: undefined, isActive: CATEGORY_ITEMS.all, status: CATEGORY_ITEMS.all})

    const [openEdit, setOpenEdit] = useState(false)
    const [openClone, setOpenClone] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const activationMessage = useSelector(state => state?.classes.activationMessage)
    const classes = useSelector(state => state?.classes.class)
    const fetchProps = useSelector(state => state?.classes.classesFetchBody)

    const dispatch = useDispatch();
    // const handleDelete = (id) => {
    //     setDelId(id);
    //     setOpen(true);
    //     setHash(Math.random().toString(16).substring(2, 10));
    // }

    const columns = [
        {
            field: "actions", headerName: 'Actions', resizable: false,
            disableClickEventBubbling: true, filterable: false, width: 105,
            renderCell: params => (
                <div style={{position: 'relative', width: '100%'}}>
                    <div className={classesTable.funcCell}>
                        <Tooltip title="Read" placement='top'>
                        <IconButton color="primary" component="span" size="small"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleRead(params.row.id)
                                    }
                                    }
                        >
                            <Eye size={20}/>
                        </IconButton>
                        </Tooltip>
                        {isEdit && (
                            <Tooltip title="Edit" placement='top'>
                            <IconButton color="primary" component="span" size="small"
                                        onClick={async (event) => {
                                            event.stopPropagation();
                                            await handleFetchClass(params.row.id);
                                            await setOpenEdit(true);
                                            // await setClickedRow(params.row.id);
                                        }
                                        }
                            >
                                <EditIcon size={20}/>
                            </IconButton>
                            </Tooltip>
                        )}
                        {isClone && <Tooltip title='Clone' placement='top'>
                            <IconButton color='primary'
                                        size='small'
                                        component='span'
                              // onClick={() => navigate(`/app/forms/formBuilder/${params.row.id}`)}
                                        onClick={async (event) => {
                                            event.stopPropagation();
                                            await handleFetchClass(params.row.id);
                                            await setOpenClone(true);
                                        }}
                            >
                                <Copy size={20} />
                            </IconButton>
                        </Tooltip>
                        }




                        {/* {isEdit && ( */}
                        {/*    <IconButton component="span" size="small" style={{marginLeft: 10}} onClick={(event) => { */}
                        {/*        event.stopPropagation(); */}
                        {/*        handleDelete(params.row.id) */}
                        {/*    }}> */}
                        {/*        <Trash2 size={15} color="red"/> */}
                        {/*    </IconButton>)} */}
                    </div>

                </div>
            ),
        },
        {field: 'id', headerName: 'Id', width: 50, sortable: false},
        {field: 'name', headerName: 'Name', width: 150,
            renderCell: (params) => params.row.isActive? params.row.name : <del>{params.row.name}</del>
        },
        {field: 'teacher', headerName: 'Teacher', width: 150},
        {field: 'student', headerName: 'Student Number', width: 165},
        // {field: 'isActive',headerName: 'IsActive',width:110},
        {field: 'status',headerName: 'Status',width:130},
        {field: 'createAt', headerName: 'Created', width: 200,
            valueFormatter: ({ value }) => moment(value).format('L LT')
        },
    ];

    const [selected, setSelected] = React.useState([]);
    // const handleClick = (event) => {
    //     const {id} = event.data
    //     const selectedIndex = selected.indexOf(id);
    //     let newSelected = [];
    //
    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, id);
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1),);
    //     }
    //     setSelected(newSelected)
    // };


    const handleStatusChange = newStatus => {setSearchArgs(prevState => ({
        ...prevState,
        status: newStatus
    }))}

    const handleSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase();
        setSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }

    const handleIsActiveChange = newIsActive => {
        setSearchArgs(prevState => ({
            ...prevState,
            isActive: newIsActive
        }))
    }

    const onActive = (isActive) => {
        const data = { ids: selected, isActive }
        activateClass(data, fetchProps)(dispatch)
    }

    useEffect(() => {
        if (activationMessage) {
            //  setMessage(true)
            setSelected([])
        }
    }, [activationMessage])

    const handleFetchClasses = data => data.map((item) => ({
            id: item.id,
            name: item.name,
            teacher: item.teachers.map(teacher => `${teacher?.firstName} (${!teacher?.nickName ? 'NA' : teacher?.nickName}) ${teacher?.lastName}`).join('|'),
            student: item.students.length,
            isActive: item.isActive,
            status: item.status,
            createAt: item.createdAt
        }))

    return (
        <div className={classesTable.root}>
            <Paper className={classesTable.paper}>
                { isEdit && (
                    <EnhancedTableToolbar
                      className={classesTable.toolBar}
                      numSelected={selected.length}
                      onActive={onActive}
                      handleChange={handleSearchChange}
                      status={searchArgs.status}
                      setStatus={handleStatusChange}
                      isActive={searchArgs.isActive}
                      setIsActive={handleIsActiveChange}
                      isLoading={isLoading}
                    />
                )}

                <PageGrid
                    columns={columns}
                    datas={classes}
                    handleFetchData={handleFetchClasses}
                    searchArgs={searchArgs}
                    fetchDatas={fetchClasses}
                    selected={selected}
                    setSelected={setSelected}
                    setIsLoading={setIsLoading}
                />

                <ResponsiveDialog
                  isOpen={open} openHash={hash}
                  title="Delete Parameter Confirm" content="Are you sure?"
                  Buttons={[
                      {
                          name: "Yes",
                          event: () => {
                              deleteClass(delId)(dispatch);
                          }
                      },
                      {
                          name: "Cancel"
                      }
                  ]}/>
            </Paper>

            <DraggableDialog open={Boolean(openEdit)} onClose={() => setOpenEdit(null)}
                             title='Edit Class' isLoaded={Boolean(openEdit)}>
                <Paper elevation={0}>
                    <AddClass edit inDataBase clone={false} openEdit={openEdit}/>
                </Paper>
            </DraggableDialog>
            <DraggableDialog open={Boolean(openClone)} onClose={() => setOpenClone(null)}
                             title='Clone Class' isLoaded={Boolean(openClone)}>
                <Paper elevation={0}>
                    <AddClass edit={false} clone openEdit={openClone}/>
                </Paper>
            </DraggableDialog>
        </div>
    )
}

export default DataTable
