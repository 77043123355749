import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {Button, ButtonGroup, Grid, IconButton, Paper, Tooltip, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import {debounce} from 'lodash'
import {AttachFile, Delete, ListAlt, PersonAdd} from '@mui/icons-material'
import Fuse from 'fuse.js'
import clsx from 'clsx'
import TableRowsIcon from '@mui/icons-material/TableRows'
import GridViewIcon from '@mui/icons-material/GridView'
import {useNavigate} from 'react-router-dom'
import {deleteSubmission} from '../../../actions/forms/formActions'
import ViewHead from '../../../components/public/ViewHead'
import LXGrid from '../../../components/mui/datagrid/XGrid'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog'
import GridCellExpand from '../../../components/mui/datagrid/GridCellExpand'
import {Functions} from '../../../utils/Functions'
import {VALID_INPUT_TYPES} from '../formBuilder/formBuilderData'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import useAuthCheck from '../../../hooks/useAuthCheck'
import {addCustomerFromForm, genHoverCell, getHeaderLabel, handleFileOpen} from './formDataHelper'
import {MULTILINE_SEPARATOR} from '../../../constants'
import FormDataCardList from './formDataCardList'
import DraggableDialog from '../../../components/mui/dialog/DraggableDialog'
import SubmissionDetails from './submissionDetails'
import SearchBox from '../../../components/public/SearchBox'

const useStyles = makeStyles(theme => ({
    search: {
        position: 'relative',
        margin: '20px 0 10px 0',
        width: '100%',
        // padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(3)
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2)
    },
    formGridTitle: {
        marginBottom: '1rem'
    },
    displaySwitcher: {
        // marginRight: theme.spacing(4),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    buttonSelected: {
        backgroundColor: '#0f1b65'
    },
    buttonUnselected: {
        backgroundColor: '#4c56a5'
    }
}))

function FormData() {
    useCurrentViewCheck(Functions.Forms_View_Form_Submissions)
    const navigate = useNavigate()
    const formId = useParams().id
    const dispatch = useDispatch()
    const classes = useStyles()
    const currentFormResults = useSelector(state => state?.forms.currentFormResults)
    const currentForm = useSelector(state => state?.forms.currentForm)
    const [isLoaded, setIsLoaded] = useState(false)

    const [formResults, setFormResults] = useState([])
    const [filteredResults, setFilteredResults] = useState([])
    const [tableHeaders, setTableHeaders] = useState([])

    const [clickedRow, setClickedRow] = useState('')
    const [openDetails, setOpenDetails] = useState(false)

    const [hash, setHash] = useState('init')
    const [forceRefresh, setForceRefresh] = useState(0)
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
    const [selectedSubmission, setSelectedSubmission] = useState(-1)

    const [tableDisplay, setTableDisplay] = useState(true)

    const [fuse, setFuse] = useState(null)

    const [canManageSubmission, canAddUser] = useAuthCheck([
        Functions.Forms_Manage_Form_Submissions,
        Functions.Customer_Management_Registered_Information_Add_Customer
    ])

    const handleDeleteOpen = id => {
        setSelectedSubmission(id)
        setOpenDeleteDialogue(true)
        setHash(Math.random().toString(16).substring(2, 10))
    }

    const onDeleteSubmission = () => {
        deleteSubmission(
            selectedSubmission,
            formId,
            msg => sendSuccessMessage(dispatch, msg),
            msg => sendErrorMessage(dispatch, msg)
        )(dispatch)
    }

    const loadData = () => {
        setFormResults([])
        setTableHeaders([])
    }
    useEffect(loadData, [dispatch, formId])

    useEffect(() => {
        const sortData = formSubmissions => {
            const tempHeadings = []
            const result = []

            const componentList = [] // If name is here, then it's a component
            // If name cannot be found above, then it's a value and needs to be looked up in this map
            const componentValueMap = {} // Map value name to component id

            if (!currentForm?.components) {
                return
            }

            currentForm.components.forEach(formComponent => {
                tempHeadings.push({
                    label: getHeaderLabel(formComponent.label, formComponent.isRequired, formComponent.tag !== null),
                    name: formComponent.name,
                    id: formComponent.id,
                    type: formComponent.inputType,
                    tag: formComponent.tag
                })

                // Select is special
                if (formComponent.inputType !== VALID_INPUT_TYPES.select) {
                    componentList.push(formComponent.name)
                } else {
                    componentValueMap[formComponent.name] = formComponent.id
                }

                formComponent.values.forEach(componentValue => {
                    componentValueMap[componentValue.name] = formComponent.id
                })
            })
            // Gather all the headings and extract the results
            formSubmissions.forEach(submission => {
                // Basic Info
                const tempSubmission = {
                    id: submission.id,
                    createdAt: submission.createdAt,
                    repUser: submission.user ? `${submission.user.firstName} ${submission.user.lastName}` : 'None',
                    repUserId: submission.user ? submission.user.id : null,
                    ip: submission?.tracking?.ip ?? '',
                    device: submission?.tracking?.device ?? '',
                    browser: submission?.tracking?.browser ?? '',
                    source: submission?.tracking?.source ?? '',
                    city: submission?.tracking?.city ?? '',
                    country: submission?.tracking?.country ?? '',
                    postalCode: submission?.tracking?.postalCode ?? '',
                    address: submission?.tracking?.address ?? '',
                    userSelected: []
                }

                submission.results.forEach(r => {
                    // Look for result name in componentList to determine if it's a toplevel component (ie, text)
                    // If it's not, then it's a value. Need to look for this value in componentValueMap to find its parent
                    if (r) {
                        if (componentList.includes(r.name)) {
                            // Is a toplevel component, directly add to results
                            const currentComponent = currentForm.components.find(c => c.name === r.name)
                            // If textarea, need to surround with multiline_separator
                            tempSubmission[r.name] =
                                currentComponent.inputType === 'textarea'
                                    ? `${MULTILINE_SEPARATOR}${r.value}${MULTILINE_SEPARATOR}`
                                    : r.value
                        } else {
                            // Look up this child in componentValueMap
                            const currentComponent = currentForm.components.find(
                                c => c.id === componentValueMap[r.name]
                            )
                            if (currentComponent) {
                                // Check and Radio's value are true and false and the name is the component's child's name
                                // Select value is the name of the component's child and the name is the parent component's name
                                let currentValue =
                                    currentComponent.inputType === VALID_INPUT_TYPES.select
                                        ? currentComponent.values.find(ccr => ccr.name === r.value)
                                        : currentComponent.values.find(ccr => ccr.name === r.name)

                                // Not selected
                                if (!currentValue) {
                                    currentValue = ''
                                }

                                tempSubmission[currentComponent.name] = tempSubmission[currentComponent.name]
                                    ? `${tempSubmission[currentComponent.name]}, ${currentValue.value}`
                                    : currentValue.value
                                tempSubmission.userSelected.push(currentValue.id)
                            }
                        }
                    }
                })
                result.push(tempSubmission)
            })
            setTableHeaders([...tempHeadings])
            setFuse(new Fuse(result, {keys: [...tempHeadings, 'repUser', 'ip', 'device', 'browser']}))
            setFormResults(result)
            setFilteredResults(result)
            setIsLoaded(true)
            setForceRefresh(forceRefresh + 1)
        }

        try {
            sortData(currentFormResults)
        } catch (e) {
            sendErrorMessage(dispatch, 'Logic Error! Cannot sort data!')
        }
    }, [currentFormResults, formId, currentForm, dispatch])
    const renderGrid = () => {
        const columns = []

        if (canManageSubmission || canAddUser) {
            columns.push({
                field: '_actions',
                headerName: ' ',
                resizable: false,
                disableClickEventBubbling: true,
                width: 110,
                filterable: false,
                renderCell: params => (
                    <>
                        {canAddUser && (
                            <div>
                                <Tooltip title="Create User" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => {
                                            addCustomerFromForm(dispatch, navigate, params.row, tableHeaders)
                                        }}
                                    >
                                        <PersonAdd size={20} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                        <div>
                            <Tooltip title="Submission Sheet" placement="top">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    component="span"
                                    onClick={
                                        async () => {
                                            await setClickedRow(params.row.id)
                                            await setOpenDetails(true)
                                        }
                                        // navigate(`/app/forms/submission/${params.row.id}`)
                                    }
                                >
                                    <ListAlt size={20} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        {canManageSubmission && (
                            <div>
                                <Tooltip title="Delete Submission" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => handleDeleteOpen(params.row.id)}
                                    >
                                        <Delete size={20} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </>
                )
            })
        }

        columns.push(
            ...[
                {field: 'id', headerName: 'ID', width: 80},
                {field: 'repUser', headerName: 'Rep', width: 200},
                {
                    field: 'createdAt',
                    headerName: 'Date',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                }
            ]
        )

        // Put in all the custom fields
        tableHeaders.forEach(header => {
            columns.push({
                field: header?.name,
                headerName: header.label,
                width: 250,
                renderCell: params => {
                    // Conditionally render Grid based on the result type
                    if (header.type === 'file') {
                        // If File, show download button
                        const fileName = params.row[header.name]
                        if (fileName) {
                            // Optional File would be empty, check if File UID exists
                            return (
                                <Tooltip title="Open File" placement="top">
                                    <IconButton size="small" onClick={() => handleFileOpen(fileName)(dispatch)}>
                                        <AttachFile fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                        return ''
                    }
                    // Render Grid normally
                    const cellValue =
                        header.type === 'textarea' && params.row[header.name]
                            ? params.row[header.name]?.replaceAll(MULTILINE_SEPARATOR, '')
                            : params.row[header.name]
                    return (
                        <GridCellExpand
                            defaultValue={cellValue}
                            expandValue={genHoverCell(
                                currentForm,
                                header.id,
                                header.type,
                                params.row.userSelected,
                                cellValue
                            )}
                            maxWidth="800px"
                            minWidth="200px"
                            forceExpand
                        />
                    )
                }
            })
        })

        // Put extra meta at the end
        columns.push(
            ...[
                {field: 'ip', headerName: 'IP', width: 200},
                {field: 'device', headerName: 'Device', width: 250},
                {field: 'browser', headerName: 'Browser', width: 250},
                {field: 'source', headerName: 'Source', width: 250},
                {field: 'city', headerName: 'City', width: 200},
                {field: 'country', headerName: 'Country', width: 200},
                {field: 'postalCode', headerName: 'Postal', width: 200},
                {field: 'address', headerName: 'Address', width: 350}
            ]
        )

        return (
            <div>
                {tableDisplay ? (
                    <LXGrid
                        rows={filteredResults}
                        columns={columns}
                        loading={!isLoaded}
                        showToolbar
                        checkboxSelection
                        disableSelectionOnClick
                        sortModel={[{field: 'createdAt', sort: 'desc'}]}
                        key={forceRefresh}
                    />
                ) : (
                    <FormDataCardList
                        datas={filteredResults}
                        columns={columns}
                        dispatch={dispatch}
                        tableHeaders={tableHeaders}
                        currentForm={currentForm}
                        addCustomerFromForm={addCustomerFromForm}
                        handleDeleteOpen={handleDeleteOpen}
                        handleFileOpen={handleFileOpen}
                        canManageSubmission={canManageSubmission}
                        canAddUser={canAddUser}
                    />
                )}
            </div>
        )
    }

    const onSearch = searchVal => {
        const cleanSearchVal = searchVal.toLowerCase().trim()
        const filteredDisplay = cleanSearchVal === '' ? formResults : fuse.search(cleanSearchVal).map(f => f.item)

        setFilteredResults(filteredDisplay)
    }

    const searchHandler = debounce(searchVal => {
        onSearch(searchVal)
    }, 500)
    const renderSearchContainer = () => (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
                <div className={classes.search}>
                    <SearchBox placeholder="Search..." size="small" type="search" searchHandler={searchHandler} />
                </div>
            </Grid>

            <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                <Grid item>{renderSwitchDisplayButton()}</Grid>
            </Grid>
        </Grid>
    )

    const renderSwitchDisplayButton = () => (
        <ButtonGroup className={classes.displaySwitcher} variant="contained" color="primary" size="small">
            <Button
                onClick={() => setTableDisplay(true)}
                className={clsx({
                    [classes.buttonSelected]: tableDisplay,
                    [classes.buttonUnselected]: !tableDisplay
                })}
            >
                <TableRowsIcon />
            </Button>
            <Button
                onClick={() => setTableDisplay(false)}
                className={clsx({
                    [classes.buttonSelected]: !tableDisplay,
                    [classes.buttonUnselected]: tableDisplay
                })}
            >
                <GridViewIcon />
            </Button>
        </ButtonGroup>
    )

    const renderBackButton = () => (
        <div className={classes.formGridTitle}>
            <Button color="secondary" variant="contained" onClick={() => navigate('/app/forms')}>
                Back to All Forms
            </Button>
        </div>
    )

    return (
        <ViewHead functionId={Functions.Forms_View_Form_Submissions} isShowTitle={false} noMaxWidth>
            {currentForm ? (
                <Typography variant="h3" className={classes.formGridTitle} color="textPrimary">
                    Submissions for {currentForm.name} [{currentForm.category?.name || 'No Category'}]
                </Typography>
            ) : (
                <Typography variant="h3" className={classes.formGridTitle} color="textPrimary">
                    Unknown
                </Typography>
            )}

            {renderBackButton()}

            <Paper elevation={0} className={classes.paper}>
                {renderSearchContainer()}
                {renderGrid()}
            </Paper>
            <ResponsiveDialog
                isOpen={openDeleteDialogue}
                openHash={hash}
                title="Delete Submission"
                content={`Delete Submission #${selectedSubmission}?`}
                Buttons={[
                    {name: 'Yes', event: () => onDeleteSubmission()},
                    {name: 'Cancel', event: () => setSelectedSubmission(-1)}
                ]}
            />

            <DraggableDialog
                open={Boolean(openDetails)}
                onClose={() => setOpenDetails(null)}
                title="View Submissions"
                isLoaded={Boolean(openDetails)}
            >
                <Paper elevation={0}>
                    <SubmissionDetails rowId={clickedRow} isPopUp={openDetails} />
                </Paper>
            </DraggableDialog>
        </ViewHead>
    )
}

export default FormData
