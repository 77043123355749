import React, {useEffect, useState} from 'react';
import withStyles from '@mui/styles/withStyles';
import { Switch } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {  useDispatch, useSelector } from 'react-redux';
import {setDarkMode} from "../../actions";


const AntSwitch = withStyles((theme) => ({
    root: {
        width: 47,
        height: 25,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 3,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(21px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 19,
        height: 19,
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: '12px',
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

const useStyles = makeStyles(() => ({
    box: {
        position: 'relative',
        width: 47
    },
    sun: {
        position: 'absolute',
        fontSize: '1.25rem',
        top: -1,
        right: 3,
    },
    moon: {
        position: 'absolute',
        fontSize: '1.25rem',
        top: -1,
        left: 3,
        transform: "rotateY(180deg)"
    }
}));

function DarkModeSwitch({...rest}) {
    const userSetting = useSelector(state => state.userSetting)
    const dispatch = useDispatch()
    const classes = useStyles();
    const [isDarkMode, setIsDarkMode] = useState(userSetting.darkMode);
    useEffect(() => {
        setDarkMode(isDarkMode)(dispatch);
    }, [setDarkMode, isDarkMode]);
    useEffect(() => {
        setIsDarkMode(userSetting.darkMode);
    }, [userSetting.darkMode])
    return (
        <div className={classes.box}>
            <AntSwitch checked={isDarkMode} {...rest} onChange={() => {
                setIsDarkMode(!isDarkMode);
            }} name="autoMode"/>
            {isDarkMode ? <span aria-label="dark mode" role="img" className={classes.moon}>🌛</span> :
                <span aria-label="light mode" role="img" className={classes.sun}>🌞</span>}
        </div>
    );
}
// const mapStateToProps = ({userSetting}) => ({userSetting})

// export default connect(mapStateToProps, {setDarkMode})(DarkModeSwitch);
export default DarkModeSwitch