import React from 'react';
import { Button, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {PlusCircle as PlusIcon} from "react-feather";
import Grid from "@mui/material/Grid";
import {connect, useDispatch} from "react-redux";
import ViewHead from "../../../components/public/ViewHead";
import FunctionTree from "./FunctionTree";
import AddFunctionForm from "./AddFunctionForm";
import {FunctionActions} from "../../../actions";
import EditFunctionForm from "./EditFunctionForm";
import AuthCheck from "../../../components/auth/AuthCheck";
import {Functions} from "../../../utils/Functions";
import useCurrentViewCheck from "../../../hooks/useCurrentViewCheck";


const useStyles = makeStyles(() => ({
    // 在这里定义页面的样式，不要使用css文件，所有mediaQuery使用 material UI 自带的break point和管理器，
    // 所有颜色用theme引用，网页单位使用rem
    root: {}
}));


function FunctionView({FunctionFormStatus}) {

    const classes = useStyles();
    const dispatch = useDispatch();
    useCurrentViewCheck(Functions.System_Management_Functions);
    const topButtons = () => {
        if (FunctionFormStatus !== "Add") {
            return (
                <AuthCheck functionId={Functions.System_Management_Functions_Add_Function}>
                    <Button color="secondary" variant="contained" onClick={() => {
                        dispatch({type: FunctionActions.SetFunctionFormStatus, payload: "Add"})
                    }}
                            startIcon={
                                <SvgIcon fontSize="small">
                                    <PlusIcon/>
                                </SvgIcon>
                            }
                    >
                        Add new Function
                    </Button>
                </AuthCheck>
            )
        } 
            return null;
        
    }


    return (
        <ViewHead functionId={Functions.System_Management_Functions} topButtons={topButtons()}>
            <Grid container direction="row" spacing={1} className={classes.root}>
                <Grid item sm={12} md={6}>
                    <FunctionTree/>
                </Grid>
                <Grid item sm={12} md={6}>
                    {FunctionFormStatus === "Add" ? <AddFunctionForm/> :
                        FunctionFormStatus === "Edit" ? <EditFunctionForm/> : null
                    }

                </Grid>
            </Grid>
        </ViewHead>
    );
}


export default connect(
    ({system}) => ({FunctionFormStatus: system.FunctionFormStatus}),
)(FunctionView);