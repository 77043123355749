import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import { sendSuccessMessage } from '../../../../actions';
import ViewHead from '../../../../components/public/ViewHead';
import ReviewSetEditor from './ReviewSetEditor';
import { fetchAllInvoiceHeads } from '../../../../actions/administrative/administrativeAction';
import LoadingScreen from '../../../../components/public/LoadingScreen';

function ReviewSetCreateView() {
    useCurrentViewCheck(Functions.Review_Set_Create)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const allTemplates = useSelector(state => state.administrative.allInvoiceHeads)

    const [isTemplatesLoaded, setIsTemplatesLoaded] = useState(false)

    const loadData = () => {
        (async() => {
            setIsTemplatesLoaded(await fetchAllInvoiceHeads()(dispatch))
        })()
    }
    useEffect(loadData, [dispatch])

    const onSuccess = (msg) => {
        sendSuccessMessage(dispatch, msg)
        setTimeout(() => {
            navigate('/app/review/set')
        }, 1000)
    }

    const handleOnClose = () => {
        navigate('/app/review/set')
    }

    return (
        <ViewHead functionId={Functions.Review_Set_Create}>
            {
                isTemplatesLoaded
                    ? <ReviewSetEditor
                        isEdit={false}
                        originalData={{}}
                        onSubmitSuccess={onSuccess}
                        templateHeads={(allTemplates ?? []).reverse()}
                        onClose={handleOnClose}
                    />
                    :
                    <LoadingScreen message='Loading...' />
            }
        </ViewHead>
    )
}

export default ReviewSetCreateView
