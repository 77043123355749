
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";


/*
functionIds is the all the function's id that you want to checked
return boolean array for your functionIds, and plus one at the end of the array is the flag
that tell you we finished check job.
 const [isAdd,isSearch,isEdit,isChecked] = useAuthCheck(AddF_id,SearchF_id,EditF_id);
 the isChecked is a optional.

 */

function useAuthCheck(functionIds) {
    const auth = useSelector(state=>state.auth);

    if(!functionIds){
        return []
    }
    if(auth.auth && auth.auth.length>0){
        return [...functionIds.map(fun=> auth.auth.includes(fun)),true]
    }
        return [...functionIds.map(()=>false),false]
    
}

useAuthCheck.propTypes = {
    functionIds:PropTypes.shape([]).isRequired,
}

export default useAuthCheck;