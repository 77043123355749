import api from '../../apis/api'
import LocalStorage from '../../utils/LocalStorage'
import {LocalStorageItems} from '../../constants'
import noErrorHandleApi from '../../apis/noErrorHandleApi'
import {sendErrorMessage} from '../message/messageActions'

export const AuthActionType = {
    Login: 'Auth_Login',
    Logout: 'Auth_Logout',
    AuthCheck: 'Auth_Check',
    AuthError: 'Auth_Error',
    FetchMenu: 'FetchMenu',
    FunctionCheck: 'FunctionCheck',
    FetchUserRole: 'FetchUserRole',
    FetchNavi: 'FetchBreadNav',
    AuthUser: 'AuthUser'
}

// default it will send token to server from localstorage, when token expires before 8hours ,
// server will send a new token , then replace the localstorage and api token
export const authCheck =
    (cb = undefined) =>
    async dispatch => {
        try {
            const response = await api.post('/auth/authCheck')

            if (response.data.token) {
                // console.warn('replace token for user');
                LocalStorage.setItem(LocalStorageItems.Token, response.data.token)
                __replaceApiToken()
            }
            dispatch({type: AuthActionType.AuthCheck, payload: response.data})
            if (typeof cb === 'function') {
                cb()
            }
        } catch (e) {
            if (e.response) {
                dispatch({type: AuthActionType.AuthError, payload: e.response.data})
            }
        }
    }

export const __replaceApiToken = () => {
    const token = LocalStorage.getItem(LocalStorageItems.Token)
    if (token) {
        api.defaults.headers.authorization = `Bearer ${token}`
    }
}

export const Logout = cb => async dispatch => {
    // const  navigate = useNavigate();
    LocalStorage.removeItem(LocalStorageItems.Token)
    __replaceApiToken()
    api.get('/auth/logout')
    dispatch({type: AuthActionType.Logout})
    cb()
    // navigate('/auth/login');
}

export const authLogin = (data, cb, handleError) => async dispatch => {
    try {
        const response = await noErrorHandleApi.post('/auth/login', data)

        dispatch({type: AuthActionType.Login, payload: response.data})
        if (response.data) {
            LocalStorage.setItem(LocalStorageItems.Token, response.data)
            __replaceApiToken()
            if (typeof cb === 'function') {
                cb()
            }
        }
    } catch (e) {
        if (e.response) {
            dispatch({type: AuthActionType.AuthError, payload: e.response.data})
        }
        if (typeof handleError === 'function') {
            handleError()
        }
    }
}

export const fetchSystemMenu = () => async dispatch => {
    try {
        const response = await api.get('/function/getRootMenu')

        if (response) {
            dispatch({type: AuthActionType.FetchMenu, payload: response.data})
        }
    } catch (e) {
        // console.log('Error when fetching system menu', e)
    }
}

export const fetchSystemNavi = id => async dispatch => {
    if (!id) {
        sendErrorMessage(dispatch, 'Must have Id')
        // console.log('must have id');
        return
    }
    let response = null
    try {
        response = await api.get(`/function/fetchParentChain/${id}`)
    } catch (e) {
        // console.log('get SystemNav Error', e)
        return
    }

    if (response.data) {
        dispatch({
            type: AuthActionType.FetchNavi,
            payload: {key: id, value: response.data}
        })
    }
}

export const changePassword = (data, cb, error) => {
    api.post('/auth/changePassword', data).then(response => {
        if (response.data) {
            const {rs, message} = response.data
            if (rs) {
                cb(message)
            } else {
                error(message)
            }
        }
    })
}

export const reqResetPassword = (data, cb, error) => {
    api.post('/auth/reqResetPassword', data).then(response => {
        if (response.data) {
            const {rs, message} = response.data
            if (rs) {
                cb(message)
            } else {
                error(message)
            }
            // rs ? cb(message) : error(message);
        }
    })
}
export const resetPassword = (data, cb, error) => {
    api.put('/auth/resetPassword', data).then(response => {
        if (response.data) {
            const {rs} = response.data
            if (rs) {
                cb()
            } else {
                error()
            }
            // rs ? cb() : error();
        }
    })
}

export const activateNewCustomer = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.post('newcustomer/activate', data)
        if (res.data?.rs) {
            return onSuccess('Successfully activated, you may now login')
        }
        return onError(res.data?.message || 'Cannot activate account')
    } catch (e) {
        return onError(e.message)
    }
}

export const fetchUser = id => async dispatch => {
    try {
        if (id) {
            const res = await api.get(`/auth/authUser/${id}`)
            if (res.data.data) {
                dispatch({type: AuthActionType.AuthUser, payload: res.data.data})
            }
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'Error fetching user')
        // console.log('this is the error', e);
    }
}
