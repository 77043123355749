import axios from 'axios';
import {LocalStorageItems, ServerBaseURL} from "../constants";
import LocalStorage from "../utils/LocalStorage";


const token = LocalStorage.getItem(LocalStorageItems.Token);
export const apiConfig = {
    // returnRejectedPromiseOnError: true,
    timeout: 3000,
    baseURL: ServerBaseURL,
    headers: {
        common: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            Pragma: "no-cache",
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        authorization : `Bearer ${  token}`
    }
}

const api = axios.create(apiConfig);

export default api;