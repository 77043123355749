import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button} from '@mui/material'
import {useParams} from 'react-router'
import {useNavigate} from 'react-router-dom'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import {Functions} from '../../../utils/Functions'
import ViewHead from '../../../components/public/ViewHead'
import {sendSuccessMessage} from '../../../actions'
import ContractTemplateEditor from './ContractTemplateEditor'
import LoadingScreen from '../../../components/public/LoadingScreen'
import {fetchContractTemplate} from '../../../actions/administrative/administrativeAction'

function CreateContractTemplateView() {
    useCurrentViewCheck(Functions.Administrative_Contract_Template_Create)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const templateToBeLoaded = useSelector(state => state.administrative.currentContractTemplate)

    const [isTemplateLoaded, setIsTemplateLoaded] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [type, setType] = useState(useParams().type)

    useEffect(() => {
        if (Number.isInteger(type)) {
            // console.log('111111')
            // attempt to load template by ID
            ;(async () => {
                setIsTemplateLoaded(await fetchContractTemplate(type)(dispatch))
            })()
        } else {
            setType(type === 'head' ? 'head' : 'body')
            setIsLoaded(true)
        }
    }, [])

    useEffect(() => {
        if (isTemplateLoaded && !isLoaded && templateToBeLoaded) {
            if (!Number.isNaN(type)) {
                // set type to whatever the fetched template to be
                setType(templateToBeLoaded.type)
                setIsLoaded(true)
            }
        }
    }, [isLoaded, isTemplateLoaded, templateToBeLoaded])

    const onSuccess = msg => {
        sendSuccessMessage(dispatch, msg)
        setTimeout(() => {
            // navigate(`/app/administrative/contractTemplate`)
            window.location.reload()
        }, 1000)
    }

    const renderBackButton = () => (
        <Button color="secondary" variant="contained" onClick={() => navigate('/app/administrative/contractTemplate')}>
            Back
        </Button>
    )

    return (
        <ViewHead
            functionId={Functions.Administrative_Contract_Template_Create}
            topButtons={renderBackButton()}
            noMaxWidth
        >
            {isLoaded ? (
                <ContractTemplateEditor
                    isEdit={false}
                    isClone={isTemplateLoaded}
                    originalData={templateToBeLoaded}
                    onSubmitSuccess={onSuccess}
                    type={type}
                />
            ) : (
                <LoadingScreen message="Loading..." />
            )}
        </ViewHead>
    )
}

export default CreateContractTemplateView
