import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import { fetchMonitorParams, sendErrorMessage, sendSuccessMessage, setMonitorParams } from '../../../../actions';
import LoadingScreen from '../../../../components/public/LoadingScreen';
import TextLine from '../../../personal/info/TextLine';
import {
  AntSwitch,
  MAX_MONITOR_INTERVAL, MAX_POST_REFRESH_INTERVAL,
  MIN_MONITOR_INTERVAL,
  MIN_POST_REFRESH_INTERVAL,
  useSettingsView
} from '../../matricHelper';

function MonitorSettingsView({ isEditing, setIsEditing }) {

  const classes = useSettingsView()
  const dispatch = useDispatch()
  const monitorParams = useSelector(state => state.matric.monitorParams)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [editActive, setEditActive] = useState(false)
  const [editInterval, setEditInterval] = useState('1440')
  const [editIntervalError, setEditIntervalError] = useState('')
  const [editPostTTRInterval, setEditPostTTRInterval] = useState('180')
  const [editPostTTRError, setEditPostTTRError] = useState('')

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchMonitorParams()(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    if (isLoaded && monitorParams) {
      setEditActive(monitorParams.isActive)
      setEditInterval(monitorParams.interval)
      setEditPostTTRInterval(monitorParams.postTTR.toString())
    }
  }, [isLoaded, monitorParams])

  const handleSubmit = async () => {
    setIsSaving(true)
    setEditIntervalError('')
    setEditPostTTRError('')

    if (Number.isNaN(Number(editInterval))) {
      setIsSaving(false)
      setEditIntervalError('Interval must be a positive number')
      return
    }

    const tempInterval = parseInt(editInterval,10)
    if (tempInterval < MIN_MONITOR_INTERVAL || tempInterval > MAX_MONITOR_INTERVAL) {
      setIsSaving(false)
      setEditIntervalError(`Interval must be between ${MIN_MONITOR_INTERVAL}-${MAX_MONITOR_INTERVAL} minutes`)
      return
    }

    if (Number.isNaN(Number(editPostTTRInterval))) {
      setIsSaving(false)
      setEditPostTTRError('Interval must be a positive number')
      return
    }
    const tempTTRInterval = parseInt(editPostTTRInterval,10)
    if (tempTTRInterval < MIN_POST_REFRESH_INTERVAL || tempTTRInterval > MAX_POST_REFRESH_INTERVAL) {
      setIsSaving(false)
      setEditPostTTRError(`PostTTR must be between ${MIN_POST_REFRESH_INTERVAL}-${MAX_POST_REFRESH_INTERVAL} minutes`)
      return
    }

    await setMonitorParams(
      { isActive: editActive, interval: parseInt(editInterval,10), postTTR: parseInt(editPostTTRInterval,10) },
      onSubmitSuccess, onSubmitError)(dispatch)
  }

  const onSubmitSuccess = (msg) => {
    setIsSaving(false)
    setIsEditing(false)
    sendSuccessMessage(dispatch, msg)
  }

  const onSubmitError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }

  const renderNormalView = () =>
    <Grid container direction='column' spacing={3} className={classes.settingsTable}>
      <Grid item>
        <TextLine title='Auto-fetch New Posts' value={monitorParams.isActive ? 'Yes' : 'No'}/>
      </Grid>
      <Grid item>
        <TextLine title='Fetch Interval' value={`Every ${monitorParams.interval} minutes`}/>
      </Grid>
      <Grid item>
        <TextLine title='Post Update Time Until Refresh' value={`Every ${monitorParams.postTTR} minutes`}/>
      </Grid>
      <Grid item>
        <TextLine title='Last Fetched' value={monitorParams.lastFetched
          ? moment(monitorParams.lastFetched).fromNow()
          : 'Unknown'}/>
      </Grid>
      <Grid item>
        <TextLine title='Last Cleared (Posts > 30 days)' value={monitorParams.lastReset
          ? moment(monitorParams.lastReset).fromNow()
          : 'Never'}/>
      </Grid>
    </Grid>

  const renderEditView = () =>
    <Grid container direction='column' spacing={3} className={classes.settingsTable}>

      <Grid item>
        <Grid container direction='row' spacing={1}
              justifyContent='flex-start' alignItems='baseline'>
          <Grid item xs={12} sm={3}>
            <Typography variant='body2' color='textSecondary' className={classes.title}>
              Is Active
            </Typography>
          </Grid>
          <Grid item xs={11} sm={8}>
            <Typography component='div' className={classes.text}>
              <Grid component='label' container alignItems='center' spacing={1}>
                <Grid item>Off</Grid>
                <Grid item>
                  <AntSwitch
                    checked={editActive}
                    onChange={(e) => setEditActive(e.target.checked)}
                    name='editActive' />
                </Grid>
                <Grid item>On</Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}><Divider/></Grid>

      <Grid item>
        <Grid container direction='row' spacing={1}
              justifyContent='flex-start' alignItems='baseline'>
          <Grid item xs={12} sm={3}>
            <Typography variant='body2' color='textSecondary' className={classes.title}>
              Fetch Interval
            </Typography>
          </Grid>
          <Grid item xs={11} sm={8}>
            <FormControl>
              <TextField size='small'
                         type='number'
                         value={editInterval}
                         disabled={isSaving}
                         onChange={(e) =>
                           setEditInterval(e.currentTarget.value)}
                         name='editInterval'
                         InputProps={{
                           endAdornment: <InputAdornment position='start'>minutes</InputAdornment>,
                         }}
                         error={!!editIntervalError}
                         helperText={editIntervalError || 'Will take effect after the previous interval finishes or upon restarting backend'}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}><Divider/></Grid>

      <Grid item>
        <Grid container direction='row' spacing={1}
              justifyContent='flex-start' alignItems='baseline'>
          <Grid item xs={12} sm={3}>
            <Typography variant='body2' color='textSecondary' className={classes.title}>
              Post Update Time Until Refresh
            </Typography>
          </Grid>
          <Grid item xs={11} sm={8}>
            <FormControl>
              <TextField size='small'
                         type='number'
                         value={editPostTTRInterval}
                         disabled={isSaving}
                         onChange={(e) =>
                           setEditPostTTRInterval(e.currentTarget.value)}
                         name='postTTR'
                         InputProps={{
                           endAdornment: <InputAdornment position='start'>minutes</InputAdornment>,
                         }}
                         error={!!editPostTTRError}
                         helperText={editPostTTRError || `How long it takes after a post's been found to test alive status`}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Button color='primary'
                variant='contained'
                disabled={!isEditing || isSaving}
                onClick={() => handleSubmit()}>
          { isSaving ? 'Saving' : 'Submit' }
        </Button>

        <Button color='primary'
                variant='contained'
                style={{ marginLeft: '1rem' }}
                disabled={isSaving}
                onClick={() => setIsEditing(false)}>
          Cancel
        </Button>
      </Grid>

    </Grid>

  return (
    <div>
      {
        isLoaded
          ? isEditing
            ? renderEditView()
            : renderNormalView()
          : <LoadingScreen message='Loading...' />
      }
    </div>
  )
}

export default MonitorSettingsView
