import React from "react";
import { Navigate } from 'react-router';
import ChangePasswordView from '../../../views/personal/ChangePasswordView';
import PersonalInfoView from '../../../views/personal/info';

const personalRoutes = [
    {
        path: 'personal',
        routes: [
            {
                path:'info',
                exact: 'true',
                component:<PersonalInfoView/>
            },
            {
                path:'changePassword',
                exact: 'true',
                component:<ChangePasswordView/>
            },
            {
                component:()=><Navigate to="404"/> // this must be the last one
            },

        ]
    },
]

export default personalRoutes;