import React, { useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import QRCode from 'qrcode';
import { useDispatch } from 'react-redux';
import {
  addImageToPixieSrc,
  POSTER_PLACEHOLDER,
  removeImageFromPixieSrc,
  usePosterTemplateCreateStyles
} from '../posterGenHelper';
import HorizontalLinearStepper from '../../../../components/mui/Stepper/HorizontalLinearStepper';
import PosterEditBasicView from './stepViews/posterEditBasicView';
import PosterSetImageView from './stepViews/posterSetImageView';
import PosterAddTextView from './stepViews/posterAddTextView';
import { dataURLtoBlob } from '../../../campaign/campaignDetails/campaignPosterTables';
import { createPosterTemplate, updatePosterTemplate } from '../../../../actions/forms/posterMakerAction';
import { sendErrorMessage } from '../../../../actions';
import { QR_CODE_CONFIG } from '../../../../constants';

function PosterMakerTemplateEditor({ originalData={}, isEdit=false, onSuccess }) {

  const dispatch = useDispatch()
  const classes = usePosterTemplateCreateStyles()

  const [imageContainer, setImageContainer] = useState({
    base: isEdit ? originalData.imageContainer.base : '',
    logo: isEdit ? originalData.imageContainer.logo : '',
    qr: isEdit ? originalData.imageContainer.qr : '',
    merged1: '',  // base + logo
    merged2: ''   // merged1 + qr
  })

  const [currentStep, setCurrentStep] = useState(0)
  const [qrText, setQrText] = useState(isEdit ? originalData.qrCode : '')
  const [copyText, setCopyText] = useState(isEdit ? originalData.copyText : '')
  const [logoImageCoords, setLogoImageCoords] = useState(isEdit ? originalData.logoCoords : null)
  const [qrImageCoords, setQrImageCoords] = useState(isEdit ? originalData.qrCoords : null)
  const [selectedForm, setSelectedForm] = useState(isEdit ? originalData.form : -1)
  const [usableFormTags, setUsableFormTags] = useState(null)
  const [pixieConfig, setPixieConfig] = useState(isEdit ? originalData.config : null)

  const [templateName, setTemplateName] = useState(isEdit ? originalData.name : '')
  const [templateDescription, setTemplateDescription] = useState(isEdit ? originalData.description : '')

  const [finalMessage, setFinalMessage] = useState('Please wait...')

  const [isSaving, setIsSaving] = useState(false)

  const steps = [
    'Basic Settings',
    'Set Logo',
    'Set QR Code',
    'Set Text',
    'Done'
  ]
  const stepContent = (step) => {
    switch (step) {
      case 0:
        return <PosterEditBasicView
          originalData={{  // To preserve filled data when going back a step
            name: templateName, description: templateDescription,
            copyText, qrText, form: selectedForm,
            baseImage: imageContainer.base,
            logoImage: imageContainer.logo
          }}
          handleStepComplete={async ({ images, isDirty, name, description, copyTextInner, qrTextInner, tags, form }) => {
            // reset image/config data
            if (isDirty.logo) {
              setLogoImageCoords(null)
            }

            if (isDirty.form) {
              setPixieConfig(null)
            }

            if (isDirty.base) {
              setLogoImageCoords(null)
              setQrImageCoords(null)
              setPixieConfig(null)
            }

            // Generate QR Code if set
            const qrCodeImage = qrTextInner?.length > 0
              ? await QRCode.toDataURL(qrTextInner, QR_CODE_CONFIG)
              : ''

            // Save all set fields
            setTemplateName(name)
            setTemplateDescription(description)
            setCopyText(copyTextInner)
            setQrText(qrTextInner)
            setSelectedForm(form)

            // filter usable form tags
            setUsableFormTags([
              ...tags.map(tag => tag.header),
              POSTER_PLACEHOLDER.postertitle.header,
              POSTER_PLACEHOLDER.jobid.header,
            ])

            if (images.logo.length > 0) {
              // This template has Logo image
              setImageContainer({ ...imageContainer, ...images, qr: qrCodeImage })
              handleStepChange(currentStep + 1)
            } else {
              // This template does not have Logo image
              setImageContainer({ ...imageContainer, ...images, qr: qrCodeImage, merged1: images.base })
              setLogoImageCoords(null)
              handleStepChange(currentStep + 2)
            }
          }}
        />

      case 1:
        // Logo Image
        return <PosterSetImageView
          loadImage={imageContainer.logo}
          baseImage={imageContainer.base}
          isEdit={!!logoImageCoords}
          originalData={logoImageCoords}
          handleStepBack={() => handleStepChange(currentStep - 1)}
          handleStepComplete={(data) => {
            const {coords, mergedImage} = data
            setLogoImageCoords(coords)
            if (imageContainer.qr.length > 0) {
              // This template has QR Code
              setImageContainer({ ...imageContainer, merged1: mergedImage })
              handleStepChange(currentStep + 1)
            } else {
              // No QR Code in this template
              setImageContainer({ ...imageContainer, merged1: mergedImage, merged2: mergedImage })
              setQrImageCoords(null)
              handleStepChange(currentStep + 2)
            }
          }}
        />

      case 2:
        // QR Code Image
        return <PosterSetImageView
          loadImage={imageContainer.qr}
          baseImage={imageContainer.merged1}
          isEdit={!!qrImageCoords}
          originalData={qrImageCoords}
          handleStepBack={() => imageContainer.logo?.length > 0
            ? handleStepChange(currentStep - 1)
            : handleStepChange(currentStep - 2)
          }
          handleStepComplete={(data) => {
            const {coords, mergedImage} = data
            setImageContainer({ ...imageContainer, merged2: mergedImage })
            setQrImageCoords(coords)
            handleStepChange(currentStep + 1)
          }}
        />

      case 3:
        // show image
        return <PosterAddTextView
          baseImage={imageContainer.merged2}
          pixieConfig={addImageToPixieSrc(pixieConfig, imageContainer.merged2)}
          tags={usableFormTags}
          isSaving={isSaving}
          handleStepBack={(configData) => {
            setPixieConfig(removeImageFromPixieSrc(configData))
            if (imageContainer.qr?.length > 0){
              handleStepChange(currentStep - 1) // Has QR Code, back 1 step
            }else if (imageContainer.logo?.length > 0) {
                handleStepChange(currentStep - 2)// Has Logo, back 2 steps
              }else {
                handleStepChange(currentStep - 3)// Has neither, back 3 steps
              }

          }}
          handleStepComplete={(configData) => {
            const tempConfig = removeImageFromPixieSrc(configData)
            setPixieConfig(tempConfig)
            setIsSaving(true)
            handleUpload(tempConfig)
            handleStepChange(currentStep + 1)
          }}
        />

      case 4:
        return <Typography variant='h2'>{ finalMessage }</Typography>

      default:
        return 'error'
    }
  }

  const onStepComplete = () => {}
  const handleStepChange = (step) => {
    if (step >= 0 && step < steps.length) {
      setCurrentStep(step)
    } else {
      sendErrorMessage(dispatch, 'Cannot change steps')
    }
  }
  const handleUpload = (configData) => {
    setIsSaving(true)




  const onError = (msg) => {
    sendErrorMessage(dispatch, msg)
    setIsSaving(false)
    handleStepChange(currentStep - 1)
  }
  const onSubmitSuccess = (data) => {
    setFinalMessage('Completed, please wait while you are being redirected')
    onSuccess(data)
  }



    const res = new FormData()
    res.append('name', templateName)
    res.append('description', templateDescription)
    res.append('copyText', copyText)
    res.append('basePoster', dataURLtoBlob(imageContainer.base))
    res.append('poster', dataURLtoBlob(imageContainer.merged2))
    res.append('formId', selectedForm.toString())
    res.append('config', configData)

    if (imageContainer.logo.length > 0) {
      res.append('logoImage', dataURLtoBlob(imageContainer.logo))
    }
    if (qrText?.length > 0) {
      res.append('qrCode', qrText)
      res.append('qrCoords', JSON.stringify(qrImageCoords))
    }
    if (logoImageCoords) {
      res.append('logoCoords', JSON.stringify(logoImageCoords))
    }

    if (isEdit) {
      res.append('id', originalData.id)
      updatePosterTemplate(res, onSubmitSuccess, onError)(dispatch)
    } else {
      createPosterTemplate(res, onSubmitSuccess, onError)(dispatch)
    }
  }

  const renderView = () =>
    <Grid container spacing={5} direction='row' justifyContent='space-between' alignItems='flex-start'>
      <Grid item xs={12}>
        <HorizontalLinearStepper
          steps={steps}
          stepContent={stepContent}
          onComplete={onStepComplete}
          showButtons={false}
          currentStep={currentStep}
        />
      </Grid>
    </Grid>

  return (
    <Paper className={classes.paper}>
      { renderView() }
    </Paper>
  )
}

export default PosterMakerTemplateEditor
