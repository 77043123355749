import api from "../../apis/api";
import { sendErrorMessage } from '../message/messageActions';

export const MatricActionType = {
  FetchMonitorParams: 'FetchMonitorParams',
  FetchAllAlertedPosts: 'FetchAllAlertedPosts',
  FetchAllKeywords: 'FetchAllKeywords',
  ChangeShowDisabledKeywords: 'ChangeShowDisabledKeywords',
  ChangeMonitorDataTab: 'ChangeMonitorDataTab',
  ChangeMonitorIndexTab: 'ChangeMonitorIndexTab',

  FetchTopUpParams: 'FetchTopUpParams',
  FetchTopUpUser: 'FetchTopUpUser',
  FetchAllTopUpUsers: 'FetchAllTopUpUsers',
  ChangeTopUpIndexTab: 'ChangeTopUpIndexTab',
  ChangeTopUpStatusTab: 'ChangeTopUpStatusTab',
  InitAccountExpandStatus: 'InitAccountExpandStatus',
  ChangeTopUpAccountExpanded: 'ChangeTopUpAccountExpanded',

  FetchTopUpPost: 'FetchTopUpPost',
  FetchTopUpPosts: 'FetchTopUpPosts',
}

// Params
export const fetchMonitorParams = () => async (dispatch) => {
  try {
    const res = await api.get('/matric/getMonitorParams')
    if (res.data?.rs) {
      dispatch({ type: MatricActionType.FetchMonitorParams, payload: res.data?.data })
      return true
    } 
      sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch params')
    
  } catch (e) {
    sendErrorMessage(dispatch,  'Monitor param fetch failed')
    // console.log('Monitor param fetch failed\n', e)
  }
  return false
}

export const setMonitorParams = (data, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/matric/setMonitorParams', data)
    if (res.data?.rs) {
      await dispatch(fetchMonitorParams())
      return onSuccess(res.data?.message || 'Success')
    } 
      return onError(res.data?.message || 'Cannot set params')
    
  } catch (e) {
    onError('Cannot set params')
    // console.log('Monitor param post failed\n', e)
  }
  return onError('Cannot set params')
}

// Keywords
export const fetchAllKeywords = () => async (dispatch) => {
  try {
    const res = await api.get('/matric/getAllKeywords/')
    if (res.data?.rs) {
      dispatch({ type: MatricActionType.FetchAllKeywords, payload: res.data?.data })
      return true
    } 
      sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch posts')
    
  } catch (e) {
    sendErrorMessage(dispatch, 'Cannot fetch posts')

    // console.log('Keyword fetch failed\n', e)
  }
  return false
}

export const updateKeywordStatus = (data, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/matric/updateKeywordStatus', data)
    if (res.data?.rs) {
      await dispatch(fetchAllKeywords())
      return onSuccess(`Keyword ${data.isActive ? 'activated' : 'disabled'}`)
    }
    return onError(res?.data?.message || 'Cannot update keyword status')
  } catch (e) {
    // console.log(e)
    return onError(e.message)
  }
}

export const addKeyword = (data, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/matric/addKeyword', data)
    if (res.data?.rs) {
      await dispatch(fetchAllKeywords())
      return onSuccess('Keyword added')
    }
    return onError(res?.data?.message || 'Cannot add keyword')
  } catch (e) {
    // console.log(e)
    return onError(e.message)
  }
}

export const deleteKeyword = (id, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.delete(`/matric/deleteKeyword/${id}`)
    if (res.data?.rs) {
      await dispatch(fetchAllKeywords())
      return onSuccess(res.data?.message || 'Keyword deleted')
    }
    return onError(res?.data?.message || 'Cannot delete keyword')
  } catch (e) {
    // console.log(e)
    return onError(e.message)
  }
}


// Posts
export const fetchAllAlertedPosts = (showDisabled) => async (dispatch) => {
  try {
    const res = await api.post('/matric/getAlertedPosts', { showDisabled })
    if (res.data?.rs) {
      dispatch({ type: MatricActionType.FetchAllAlertedPosts, payload: res.data?.data })
      return true
    } 
      sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch posts')
    
  } catch (e) {
    sendErrorMessage(dispatch, 'Cannot fetch posts')
    // console.log('Form posts failed\n', e)
  }
  return false
}

export const scrapePostsFromBBS = (showDisabled, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/matric/scrapePostFromBBS', { showDisabled })
    if (res.data?.rs) {
      await dispatch(fetchAllAlertedPosts(showDisabled))
      return onSuccess(res.data.data)
    } 
      return onError(res.data?.message || 'Cannot scrape posts')
    
  } catch (e) {
    // console.log('Form posts failed\n', e)
    return onError(e.message)
  }
}

export const markPostAsRead = (data, showDisabled, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/matric/markPostAsRead', data)
    if (res.data?.rs) {
      await dispatch(fetchAllAlertedPosts(showDisabled))
      return onSuccess('Post marked as read')
    } 
      return onError(res.data?.message || 'Cannot set post as read')
    
  } catch (e) {
    // console.log('Form posts failed\n', e)
    return onError(e.message)
  }
}

export const checkPostStatus = (data, showDisabled, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/matric/checkPostStatus', data)
    if (res.data?.rs) {
      await dispatch(fetchAllAlertedPosts(showDisabled))
      return onSuccess(res.data?.message || 'Done')
    } 
      return onError(res.data?.message || 'Cannot check post status')
    
  } catch (e) {
    // console.log('Form post failed\n', e)
    return onError(e.message)
  }
}

export const deletePost = (data, showDisabled, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/matric/deleteAlertedPost', data)
    if (res.data?.rs) {
      await dispatch(fetchAllAlertedPosts(showDisabled))
      return onSuccess('Post deleted')
    } 
      return onError(res.data?.message || 'Cannot delete post')
    
  } catch (e) {
    // console.log('Delete post failed\n', e)
    return onError(e.message)
  }
}
