import React from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ViewHead from '../../../components/public/ViewHead';
import { Functions } from '../../../utils/Functions';

import { sendSuccessMessage } from '../../../actions';
import CampaignEditor from './campaignEditor';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';

function CreateCampaignView() {
  useCurrentViewCheck(Functions.Campaign_Create)
  const  navigate = useNavigate();
  const dispatch = useDispatch()

  const onSuccess = (msg, id = -1) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/campaign/view/${id}`)
    }, 1000)
  }

  const renderReturnButton = () => (
      <Button color='secondary'
              variant='contained'
              onClick={() => navigate('/app/campaign/')}>
        Return
      </Button>
    )

  return (
    <ViewHead functionId={Functions.Campaign_Create} topButtons={renderReturnButton()}>
      <CampaignEditor isEdit={false} campData={{}} onSubmitSuccess={onSuccess} />
    </ViewHead>
  )

}

export default CreateCampaignView
