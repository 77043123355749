import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro'
import {useGridApiRef} from '@mui/x-data-grid'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import {PAGE_PARAMS, SORT_ORDER, LocalStorageItems} from '../../../constants'
import {ChangeRowsPerPage} from '../../../actions'
import LocalStorage from '../../../utils/LocalStorage'
import CustomLoadingOverlay from './XCustomLoadingOverlay'
import CustomFooterOverlay from './CustomFooterOverlay'

export const getLocalRowsPerPage = () => {
    try {
        const userSetting = LocalStorage.getObjectItem(LocalStorageItems.UserSetting)
        if (userSetting.rowsPerPage) {
            return userSetting.rowsPerPage
        }
        return PAGE_PARAMS.defaultPageSize
    } catch (e) {
        return PAGE_PARAMS.defaultPageSize
    }
}

export const DEFAULT_FETCH_PARAM = {
    page: 1,
    perPage: getLocalRowsPerPage(),
    previous: null,
    next: null,
    sortColumn: 'id',
    sortOrder: SORT_ORDER.desc
}

const useStyles = makeStyles({
    gridList: {
        '& div[style*="z-index: 100000"]': {
            display: 'none'
        }
    }
})

export default function PageGrid(props) {
    const classes = useStyles()
    const {
        height = '77vh',
        width = '100%',
        datas,
        handleFetchData, // Function to map the incoming data, each table is different
        searchArgs, // Search value and selected category from Search bar
        defaultSortColumn = 'id', // Default column the table sorted by
        defaultSortOrder = SORT_ORDER.desc,
        fetchDatas, // The fetch function for current table
        selected,
        setSelected, // Indicates which rows being selected, pass the value back to parent
        setIsLoading,
        setHoveredRow = null,
        ...rest
    } = props
    const apiRef = useGridApiRef()
    const dispatch = useDispatch()
    const [dataLoading, setDataLoading] = useState(true) // When fetch function executed
    const [pageData, setPageData] = useState([]) // All data for current page
    const [displayDatas, setDisplayDatas] = useState([]) // Currently displaying dataset on page
    const [pageCount, setPageCount] = useState(0) // Total number of pages
    const [pageArgs, setPageArgs] = useState({
        page: 1,
        perPage: getLocalRowsPerPage(),
        sortColumn: defaultSortColumn,
        sortOrder: defaultSortOrder, // Table data sort by column and order
        previous: null,
        next: null
    }) // Cursors for pagination
    const initiatePage = {page: 1, previous: null, next: null} // Initate page object
    const pageGridComponents = {
        Toolbar: GridToolbar,
        Footer: () =>
            CustomFooterOverlay(
                pageArgs.page,
                handlePageChange,
                pageArgs.perPage,
                handlePerPageChange,
                apiRef,
                pageCount
            ),
        LoadingOverlay: CustomLoadingOverlay
    }
    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(dataLoading)
        }
    }, [dataLoading])

    useEffect(() => {
        if (pageArgs.page === 1 && !dataLoading) {
            setDataLoading(true)
            fetchDatas({...searchArgs, ...pageArgs, ...initiatePage})(dispatch)
        } else {
            setPageArgs(prevState => ({...prevState, ...initiatePage}))
        }
    }, [searchArgs])

    useEffect(() => {
        setDataLoading(true)
        fetchDatas({...searchArgs, ...pageArgs})(dispatch)
    }, [pageArgs.page, pageArgs.perPage, pageArgs.sortColumn, pageArgs.sortOrder])

    useEffect(() => {
        if (datas?.total !== undefined) {
            setPageCount(Math.ceil(datas.total / pageArgs.perPage)) // Update total page number
            setPageData(handleFetchData ? handleFetchData(datas.data) : datas.data) // Format/Handle incoming data and update states
            setPageArgs(prevState => ({
                ...prevState,
                previous: datas.cursors.previous, // set Previous && next cursors
                next: datas.cursors.next
            }))
        }
    }, [datas])

    useEffect(() => {
        setDataLoading(false)
    }, [displayDatas])

    useEffect(() => {
        initialLoad() // call initial load when entering new page/ page refreshed
    }, [pageData])

    // subscribe rowMouseEnter to data grip pro
    useEffect(() => {
        if (setHoveredRow) apiRef.current.subscribeEvent('rowMouseEnter', handleRowMouseEnter)
    }, []) // Dependency array is empty to mimic componentDidMount and componentWillUnmount lifecycle methods

    // subscribe rowMouseLeave to data grip pro

    useEffect(() => {
        if (setHoveredRow) apiRef.current.subscribeEvent('rowMouseLeave', handleRowMouseLeave)
    }, [])

    const initialLoad = () => {
        setDataLoading(true)
        setDisplayDatas(pageData.slice(0, Math.min(PAGE_PARAMS.initRowsNum, pageData.length)))
    }

    const handlePageChange = (event, value) => {
        const prevPage = value === pageArgs.page - 1 ? pageArgs.previous : null // If going to the previous page Ex. page 3 -> 2
        const nextPage = value === pageArgs.page + 1 ? pageArgs.next : null // If going to the next page Ex. page 2 -> 3
        setPageArgs(prevState => ({
            ...prevState,
            page: value,
            previous: prevPage,
            next: nextPage
        }))
    }

    const handlePerPageChange = value => {
        ChangeRowsPerPage(value)(dispatch) // Change local storage 'rowsPerPage'
        setPageArgs(prevState => ({
            ...prevState,
            ...initiatePage,
            perPage: value
        }))
    }

    const handleScroll = params => {
        const pageDataLen = pageData.length
        const displayDataLen = displayDatas.length
        if (displayDataLen < pageDataLen && !dataLoading) {
            // Load more data for current page if exists
            setDataLoading(true)
            setDisplayDatas(pageData.slice(0, Math.min(displayDataLen + params.viewportPageSize, pageDataLen)))
        }
    }

    const handleRowMouseEnter = useCallback(
        _.debounce(params => {
            if (setHoveredRow) {
                setHoveredRow(params.row.cid)
            }
        }, 3),
        [setHoveredRow]
    )

    const handleRowMouseLeave = useCallback(() => {
        if (setHoveredRow) {
            setHoveredRow(null)
        }
    }, [])

    return (
        <div style={{height, width, minHeight: 400}}>
            <DataGridPro
                {...rest}
                className={classes.gridList}
                rows={displayDatas}
                checkboxSelection={!!selected}
                pagination
                disableDensitySelector
                disableColumnMenu
                density="compact"
                apiRef={apiRef}
                loading={dataLoading}
                rowsPerPageOptions={PAGE_PARAMS.rowPerPage}
                onRowsScrollEnd={handleScroll}
                scrollEndThreshold={50}
                components={pageGridComponents}
                disableSelectionOnClick
                sortModel={[{field: pageArgs.sortColumn, sort: pageArgs.sortOrder.toLowerCase()}]}
                selectionModel={selected}
                onSelectionModelChange={newSelection => setSelected(newSelection)}
                rowBuffer={25}
            />
        </div>
    )
}
