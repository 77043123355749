import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditStudentMessage from './StudentEditMessage';
import {
  fetchStudentMessage
} from '../../../actions';
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog';
import LXGrid from '../../../components/mui/datagrid/XGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  }
}));

function StudentMessageList({ messages }) {
  const classes = useStyles();
  const [message, setMessage] = useState(messages);
  const [singleMessage, chooseMessage] = useState('');
  // const [category, setCategory] = useState('');
  // const [searchStr, setSearchStr] = useState('');
  const category = ''
  const searchStr = ''

  const handleEdit = (id) => {
    chooseMessage(messages.filter((item) => item.id === id));
  };
  const columns = [
    {
      field: '_actionField', headerName: ' ', width: 70,
      renderCell: params => (
        <div style={{ position: 'relative', width: '100%' }}>
          <Tooltip title='read' placement='top'>
            <div className={classes.funcCell}>
              <IconButton color='primary' component='span' size='small' onClick={(event) => {
                event.stopPropagation();
                handleEdit(params.row.id);
              }}>
                <VisibilityIcon size={20} />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      )
    },
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'content', headerName: 'Content', width: 300 },
    { field: 'creator', headerName: 'Creator', width: 130 },
    {
      field: 'createAt',
      headerName: 'Create',
      width: 200,
      valueFormatter: ({ value }) => moment(value).format('L LT')
    },
    {
      field: 'read', headerName: 'Read', width: 100,
      renderCell: params => (
        <div style={{ position: 'relative', width: '100%' }}>
          <Tooltip title='message' placement='top'>
            <div className={classes.funcCell}>
              <IconButton color='primary' component='span' size='small'>
                {params.value === 'read' ? <LockOpenIcon size={15} /> : <LockIcon size={15} />}
              </IconButton>
            </div>
          </Tooltip>
        </div>
      )
    }
  ];

  const [selected, setSelected] = React.useState([]);
  const handleClick = (event) => {
    const {id} = event.data;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const rows = message?.map((item) => ({
      id: item.id,
      content: item.content,
      creator: item?.creator?.firstName,
      createAt: item.createdAt,
      read: item.submissions[0].status === 'sent' ? 'sent' : 'read'
    }));
  const data = { columns, rows };
  const checkStatus = (value) => {
    if (value) {
      const submissionRead = value.submissions.reduce((total, num) => total + (num.status === 'read' ? 1 : 0), 0);
      return submissionRead === 0;
    }
    return false
  };

  useEffect(() => {
    const classifyData = (categoryEle) => {
      const read = [];
      const sent = [];
      if (messages) {
        messages.forEach(value => {
          if (checkStatus(value)) {
            sent.push(value);
          } else {
            read.push(value);
          }
        });
        if (categoryEle === 'Read') {
          return read;
        }
        if (categoryEle === 'Sent') {
          return sent;
        } 
          return messages;
        
      }
      return []
    };
    const dataTemp = classifyData(category);
    if (dataTemp) {
      const searchStr1 = searchStr.trim().toLowerCase();
      const searchClasses = [];
      dataTemp.forEach((item) => {
        if (
          item.content.toLowerCase().includes(searchStr1) ||
          item.creator.firstName.toLowerCase().includes(searchStr1) ||
          item.creator.lastName.toLowerCase().includes(searchStr1)
        ) {
          searchClasses.push(item);
        }
      });
      setMessage(searchClasses);
    }
  }, [messages, category, searchStr]);


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <LXGrid
          {...data}
          totalHeight='500px'
          checkboxSelection
          onRowSelected={handleClick}
          disableSelectionOnClick
          density='compact'
          sortModel={[{ field: 'createAt', sort: 'desc' }]}
        />
        <ResponsiveDialog
          isOpen={false} openHash="init"
          title='Delete Parameter Confirm' content='Are you sure?'
          Buttons={[{ name: 'Cancel' }
          ]} />
      </Paper>
      {singleMessage ? <EditStudentMessage singleMessage={singleMessage[0]} /> : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
    deleteMessage: state.deleteId,
    studentMessage: state.trainingMessage?.studentMessage,
    currentStudentMessage: state.trainingMessage?.currentStudentMessage
  });

export default connect(mapStateToProps, { fetchStudentMessage })(StudentMessageList);

