import React, {useEffect, useState} from 'react'
import {debounce} from 'lodash'
import Grid from '@mui/material/Grid'
import {Paper, Tooltip} from '@mui/material'
import moment from 'moment'
import IconButton from '@mui/material/IconButton'
import {Edit} from 'react-feather'
import {ListAlt} from '@mui/icons-material'
import {useDispatch, useSelector} from 'react-redux'
import {DetailModal, INTERVIEW_STATUS_DICT, listStatusFormatter, useInterviewListStyles} from '../interviewHelper'
import {fetchAllInterviews} from '../../../../actions/customer/interviewActions'
import useAuthCheck from '../../../../hooks/useAuthCheck'
import {Functions} from '../../../../utils/Functions'
import PageGrid from '../../../../components/mui/datagrid/PageGrid'
import CheckCustomerFloat from '../../register/CheckCustomerFloat'
import DraggableDialog from '../../../../components/mui/dialog/DraggableDialog'
import CreateInterviewView from '../editor/editInterviewView'
import InterviewReview from '../review'
import Tags from '../../../../components/public/Tags'
import SearchBox from '../../../../components/public/SearchBox'
import {fetchTagsByGroup} from '../../../../actions/public/tagActions'

function InterviewListTabView() {
    const [canEditInterview] = useAuthCheck([Functions.Interview_Edit])

    const allInterviews = useSelector(state => state?.interview.allInterviews)
    const currentTab = useSelector(state => state?.interview.currentInterviewTab)

    const [clickedInterview, setClickedInterview] = useState('')
    const [clickedCustomer, setClickedCustomer] = useState('')
    const [openReview, setOpenReview] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const categoryName = {
        0: 'all',
        1: 'pending',
        2: 'finished'
    }
    const [searchArgs, setSearchArgs] = useState({searchKey: undefined, category: categoryName[currentTab]})
    const dispatch = useDispatch()
    const allTags = useSelector(state => state.tag.tagByGroup)

    useEffect(() => {
        setSearchArgs(prevState => ({
            ...prevState,
            category: categoryName[currentTab]
        }))
    }, [currentTab])

    const loadData = () => {
        ;(async () => {
            const group = 'Interview'
            setIsLoading(await fetchTagsByGroup(group)(dispatch))
        })()
    }

    useEffect(loadData, [])

    const handleSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase()
        setSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }

    const classes = useInterviewListStyles()

    const searchHandler = debounce(searchVal => {
        handleSearchChange(searchVal)
    }, 500)

    const renderSearchContainer = () => (
        <Grid item xs={12}>
            <div className={classes.search}>
                <SearchBox
                    placeholder="Search..."
                    size="small"
                    type="search"
                    searchHandler={searchHandler}
                    isLoading={isLoading}
                />
            </div>
        </Grid>
    )

    const renderGrid = () => {
        const columns = []

        columns.push({field: 'id', headerName: 'ID', width: 75})

        if (canEditInterview) {
            columns.push({
                field: '_actionField',
                headerName: ' ',
                resizable: false,
                disableClickEventBubbling: true,
                filterable: false,
                width: 80,
                renderCell: params => (
                    <div>
                        <Tooltip title="Edit" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={async () => {
                                    await setClickedInterview(params.row.id)
                                    await setOpenEdit(true)
                                    // navigate(`/app/customer/interview/edit/${params.row.id}`)
                                }}
                            >
                                <Edit size={20} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Review" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={async () => {
                                    await setClickedInterview(params.row.id)
                                    await setClickedCustomer(params.row.customer.uid)
                                    await setOpenReview(true)
                                    // navigate(`/app/customer/interview/review/${params.row.customer.uid}/${params.row.id}`)
                                }}
                            >
                                <ListAlt size={20} />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            })
        }

        columns.push(
            ...[
                {
                    field: 'customer',
                    headerName: 'Customer',
                    width: 230,
                    renderCell: params => (
                        // <Link
                        //   className={classes.link}
                        //   onClick={() => {
                        //     const win = window.open(`/app/customer/registered/check/${params.row.customer.id}`, '_blank')
                        //     win.focus()
                        //   }}>
                        //   {params.row.customer.nickName ? `${params.row.customer.firstName} (${params.row.customer.nickName}) ${params.row.customer.lastName}`
                        //       : `${params.row.customer.firstName} ${params.row.customer.lastName}`
                        //   }
                        // </Link>
                        <DetailModal
                            name={
                                params.row.customer.nickName
                                    ? `${params.row.customer.firstName} (${params.row.customer.nickName}) ${params.row.customer.lastName}`
                                    : `${params.row.customer.firstName} ${params.row.customer.lastName}`
                            }
                            details={<CheckCustomerFloat studentId={params.row.customer.id} />}
                        />
                    )
                },
                {
                    field: 'interviewer',
                    headerName: 'Interviewer',
                    width: 230,
                    valueFormatter: ({value}) => `${value.firstName} ${value.lastName}`
                },
                {
                    field: 'tag',
                    headerName: 'Tags',
                    width: 100,
                    renderCell: params => (
                        <Tags
                            datas={allTags}
                            current={params.row.id}
                            fetchData={fetchTagsByGroup}
                            canEdit={canEditInterview}
                            group="Interview"
                            loadData={loadData}
                        />
                    )
                },
                {
                    field: 'product',
                    headerName: 'Product',
                    width: 180,
                    valueFormatter: ({value}) => value.name
                },
                {field: 'type', headerName: 'Method', width: 125},
                {
                    field: 'status',
                    headerName: 'Status',
                    width: 100,
                    renderCell: ({value}) => (
                        <Tooltip title={INTERVIEW_STATUS_DICT[value]} placement="right">
                            <div>{listStatusFormatter(value)}</div>
                        </Tooltip>
                    )
                },
                {field: 'score', headerName: 'Score', width: 100},
                {
                    field: 'scheduledStartTime',
                    headerName: 'Scheduled Start',
                    width: 180,
                    valueFormatter: ({value}) => (value ? moment(value).format('L LT') : '')
                },
                {
                    field: 'scheduledEndTime',
                    headerName: 'Scheduled End',
                    width: 180,
                    valueFormatter: ({value}) => (value ? moment(value).format('L LT') : '')
                },
                {
                    field: 'createdAt',
                    headerName: 'Created',
                    width: 180,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                },
                {
                    field: 'updatedAt',
                    headerName: 'Updated',
                    width: 180,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                }
            ]
        )

        return (
            <PageGrid
                style={{margin: '8px'}}
                columns={columns}
                datas={allInterviews}
                searchArgs={searchArgs}
                fetchDatas={fetchAllInterviews}
                setIsLoading={setIsLoading}
            />
        )
    }

    const renderTopToolbar = () => (
        <Grid container className={classes.p2} direction="row" justifyContent="center" alignItems="center" spacing={1}>
            {renderSearchContainer()}
        </Grid>
    )

    return (
        <div>
            <Grid container className={classes.paper}>
                {renderTopToolbar()}
                {renderGrid()}
            </Grid>

            <DraggableDialog
                open={Boolean(openReview)}
                onClose={() => setOpenReview(null)}
                title="View Interview"
                isLoaded={Boolean(openReview)}
            >
                <Paper elevation={0}>
                    <InterviewReview interviewId={clickedInterview} uuid={clickedCustomer} />
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                open={Boolean(openEdit)}
                onClose={() => setOpenEdit(null)}
                title="Edit Interview"
                isLoaded={Boolean(openEdit)}
            >
                <Paper elevation={0}>
                    <CreateInterviewView interviewId={clickedInterview} />
                </Paper>
            </DraggableDialog>
        </div>
    )
}

export default InterviewListTabView
