import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton, List, ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import {ServerBaseURL} from "../../../../constants";

function ContractPaymentHistoryReferenceView(props) {
    const {formData} = props
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton color='primary'
                        size='small'
                        component='span'
                        onClick={handleClickOpen}
            >
                <StickyNote2OutlinedIcon />
            </IconButton>
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Payment History Reference
                </DialogTitle>
                <DialogContent>
                    <div>
                        <DialogContentText>
                            email:{formData?.email}
                        </DialogContentText>

                    </div>
                    <div>
                        <DialogContentText>
                            payer:{formData?.payerName}
                        </DialogContentText>
                    </div>
                    <div>
                        {/* pic */}
                        <div>
                            <div>
                                <DialogContentText>
                                    Pic uploaded:
                                </DialogContentText>
                                {formData?.contractPaymentHistoryPictures?.map((item)=>(
                                        <div key={item.fileName} >
                                            <div>
                                                {item?.fileName}
                                            </div>
                                            <div>
                                                <img width="150px" src={`${ServerBaseURL}/asset/images/referencePic/${item?.uid}`}  alt='reference Pic'/>

                                            </div>
                                            <div style={{marginBottom:"20px"}} />
                                        </div>
                                    ))}
                            </div>
                        </div>
                        {/* note */}
                        <div style={{marginTop:"20px"}}>
                            <DialogContentText>
                                Note:
                            </DialogContentText>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {formData?.contractPaymentHistoryNotes?.map((item,index)=>(
                                        <ListItem key={item.toString()}>
                                            {index + 1}.
                                            <ListItemText primary={item?.note} />
                                        </ListItem>
                                        ))}
                            </List>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ContractPaymentHistoryReferenceView