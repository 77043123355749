import React from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import { sendSuccessMessage } from '../../../../actions';
import history from '../../../../utils/history';
import { useInvoiceListStyles } from '../invoiceHelper';
import ViewHead from '../../../../components/public/ViewHead';
import InvoiceEditor from './InvoiceEditor';

function InvoiceCreateView() {
  useCurrentViewCheck(Functions.Administrative_Invoice_Create)
  const  navigate = useNavigate();
  const dispatch = useDispatch()
  const classes = useInvoiceListStyles()

  const onSuccess = (msg, uid, iid) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/administrative/invoice/view/${uid}/${iid}`)
    }, 1000)
  }

  const renderBackButton = () => (
      <div className={classes.pageTitle}>
        <Button color='secondary'
                variant='contained'
                onClick={() => history.goBack()}>
          Back
        </Button>
      </div>
    )

  return (
    <ViewHead functionId={Functions.Administrative_Invoice_Create} topButtons={renderBackButton()}>
      <InvoiceEditor
        originalData={{}}
        onSubmitSuccess={onSuccess}
        isEdit={false}
      />
    </ViewHead>
  )
}

export default InvoiceCreateView
