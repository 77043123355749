import React, { useState } from 'react';
import { Badge, IconButton, Paper, Tooltip , Stack, Typography } from '@mui/material';
import { Delete, ListAlt, PersonAdd } from '@mui/icons-material';
import makeStyles from "@mui/styles/makeStyles";
import history from '../../../utils/history';
import CardList from '../../../components/mui/cardList/CardList';
import DraggableDialog from "../../../components/mui/dialog/DraggableDialog";
import WebSubmissionDetails from "./webSubmissionDetails";

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        margin: '20px 0 10px 0',
        width: '100%',
        // padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2)
    },
    formGridTitle: {
        marginBottom: '1rem',
    },
    displaySwitcher: {
        // marginRight: theme.spacing(4),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    buttonSelected: {
        backgroundColor: '#0f1b65'
    },
    buttonUnselected: {
        backgroundColor: '#4c56a5'
    },
    badge: {
        '& .MuiBadge-badge': {
            transform: 'scale(0.8) translate(8px, -8px)',

        }
    },
}))
function FormDataCardList({
                              datas, dispatch, tableHeaders,
                              addCustomerFromForm, handleDeleteOpen,
                              canManageSubmission, canAddUser
                          }) {
    const classes = useStyles()
    const cardDatas = datas || []
    const cardListData = []
    const [clickedRow, setClickedRow] = useState("")
    const [openDetails, setOpenDetails] = useState(false)

    if (cardDatas?.length > 0) {
        cardDatas.forEach((item) => {
            const isActive = true
            const rows = []
            // Form Actions
            if (canManageSubmission || canAddUser) {
                rows.push({
                    title: '',
                    body: (
                        <Stack direction='row' spacing={1}>
                            {canAddUser &&
                                <Tooltip title='Create User' placement='top'>
                                    <IconButton color='primary'
                                                size='small'
                                                component='span'
                                                onClick={() => addCustomerFromForm(dispatch, history, item, tableHeaders)}>
                                        <PersonAdd size={20}/>
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title='Submission Sheet' placement='top'>
                                <IconButton color='primary'
                                            size='small'
                                            component='span'
                                            onClick={async () => {
                                                await setClickedRow(item.id);
                                                await setOpenDetails(true)
                                            }
                                            }>
                                    {item.isRead ? <ListAlt size={20}/> : <Badge color='secondary'
                                                                                    overlap='circular'
                                                                                    className={classes.badge}
                                    >
                                        <ListAlt size={20}/>
                                    </Badge>}

                                </IconButton>
                            </Tooltip>
                            {canManageSubmission &&
                                <Tooltip title='Delete Submission' placement='top'>
                                    <IconButton color='primary'
                                                size='small'
                                                component='span'
                                                onClick={() => handleDeleteOpen(item.id)}>
                                        <Delete size={20}/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </Stack>
                    )
                })
            }


            Object.keys(item).forEach((key) => {
                rows.push({
                    title: key,
                    body: <Typography variant='body2'>{item[key]}</Typography>
                })
            })
            cardListData.push({isActive, rows})
        })
    }

    return (
        <div>
            <CardList data={cardListData}/>
            <DraggableDialog open={Boolean(openDetails)} onClose={() => setOpenDetails(null)}
                             title='View Submissions' isLoaded={Boolean(openDetails)}>
                <Paper elevation={0}>
                    <WebSubmissionDetails rowId={clickedRow} isPopUp={openDetails}/>
                </Paper>
            </DraggableDialog>
        </div>)
}

export default FormDataCardList
