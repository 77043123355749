import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import history from '../../../../utils/history';
import ViewHead from '../../../../components/public/ViewHead';
import { sendSuccessMessage } from '../../../../actions';
import InterviewEditor from './InterviewEditor';
import { fetchInterview } from '../../../../actions/customer/interviewActions';
import LoadingScreen from '../../../../components/public/LoadingScreen';

function CreateInterviewView({interviewId}) {
  useCurrentViewCheck(Functions.Interview_Edit)
  const  navigate = useNavigate();
  const urlId = useParams().id
  const dispatch = useDispatch()
  const currentInterview = useSelector(state => state?.interview.currentInterview)

  const [isLoaded, setIsLoaded] = useState(false)

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchInterview(urlId || interviewId)(dispatch))
    })()
  }
  useEffect(loadData, [dispatch, urlId || interviewId])

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/customer/interview`)
    }, 1000)
  }

  const renderBackButton = () =>
    <Button color='secondary'
            variant='contained'
            onClick={() => history.goBack()}>
      Back
    </Button>

  return (
      <div>
        {urlId ?
            <ViewHead noMaxWidth functionId={Functions.Customer_Management_Registered_Information_Edit_Customer}
                      topButtons={renderBackButton()}
                      mes="Edit Interview"
            >
              {
                isLoaded
                    ?
                    <InterviewEditor isEdit originalData={currentInterview} onSubmitSuccess={onSuccess}/>
                    :
                    <LoadingScreen message='Loading...' />
              }
            </ViewHead>
            :
            <div>
              {
                isLoaded
                    ?
                    <InterviewEditor isEdit originalData={currentInterview} onSubmitSuccess={onSuccess}/>
                    :
                    <LoadingScreen message='Loading...' />
              }
            </div>
        }
      </div>

  )
}

export default CreateInterviewView
