import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid, IconButton, InputAdornment,
  Paper,
  TextField, Tooltip,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import { InfoOutlined } from '@mui/icons-material';
import { sendErrorMessage } from '../../../actions';
import SubmitButton from '../../../components/mui/button/SubmitButton';
import {
  fetchAllEmailAccounts
} from '../../../actions/administrative/administrativeAction';
import LoadingScreen from '../../../components/public/LoadingScreen';
import { createPaymentReceiver } from '../../../actions/administrative/mmqJobsAction';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '2rem'
  },
  editor: {
    '& .cke_contents.cke_reset': {
      minHeight: '50vh'
    }
  }
}))

// const schema = yup.object().shape(CONTRACT_TEMPLATE_CREATE_VALIDATOR)
function PaymentReceiverEditor({onSubmitSuccess}) {

  const { register, handleSubmit} = useForm({
    // resolver: yupResolver(schema),
    reValidateMode: "onChange",
  })

  const classes = useStyles()
  const dispatch = useDispatch()
  const [isLoaded, setIsLoaded] = useState(null)
  const [isSaving, setIsSaving] = useState(false)


  const loadData = async () => {
    setIsLoaded(await fetchAllEmailAccounts()(dispatch))
  }
  useEffect(loadData, [])

  const onSubmit = async (formData) => createPaymentReceiver(formData, onSubmitSuccess, onError)(dispatch)

  const onError = (msg) => {
    sendErrorMessage(dispatch, msg)
    setIsSaving(false)
  }

  const renderForm = () => (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={5} direction='row' justifyContent='space-between' alignItems='flex-start'>
          {/* user,password,bank,name,host,port */}
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         label='Email' required
                         disabled={isSaving} variant='outlined'
                         name='user'
              />
            </FormControl>
          </Grid>



          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         label='Password/Access Code' required
                         disabled={isSaving} variant='outlined'
                         name='password'
                         helperText="This should not be your login password"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         label='Bank Name(Just for reference)' required
                         disabled={isSaving} variant='outlined'
                         name='bank'
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         label='User Name in Bank File(Just for reference)' required
                         disabled={isSaving} variant='outlined'
                         name='name'
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         label='SMTP/IMAP server url' required
                         disabled={isSaving} variant='outlined'
                         name='host'
                         InputProps={{
                           endAdornment:<InputAdornment position='start'>
                             <Tooltip title= ' please contact your email provider , If you are not sure ' placement='top'>
                               <IconButton
                                 size = 'small'
                                 color='primary'>
                                 <InfoOutlined />
                               </IconButton>
                             </Tooltip>
                           </InputAdornment>
                         }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <TextField fullWidth size='small'
                         inputRef={register}
                         label='Port' required
                         defaultValue='993'
                         disabled={isSaving} variant='outlined'
                         name='port'
                         InputProps={{
                           endAdornment:<InputAdornment position='start'>
                             <Tooltip title= ' please contact your email provider , If you are not sure ' placement='top'>
                               <IconButton
                                 size = 'small'
                                 color='primary'>
                                 <InfoOutlined />
                               </IconButton>
                             </Tooltip>
                           </InputAdornment>
                         }}
              />
            </FormControl>
          </Grid>



          {/* Bottom Submit Button */}
          <Grid item xs={12}>
            <Grid container spacing={1} direction='row' justifyContent='flex-end'>
              <Grid item xs={2}>
                <SubmitButton isSaving={isSaving} text='Submit' isSavingText='Submitting'
                              fullWidth variant='contained' color='primary'
                />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </form>
    )

  return (
    <Paper className={classes.paper}>
      {
        isLoaded === null
          ? <LoadingScreen message='Loading...' />
          : isLoaded
            ? renderForm()
            : <Typography variant='h3' color='error'>Data load error</Typography>
      }
    </Paper>
  )
}

export default PaymentReceiverEditor
