import React, { useState } from 'react';
import { InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n'

const useStyles = makeStyles((theme) =>
    createStyles({
        button: {
            display: 'block',
            marginTop: theme.spacing(2),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        select:{
            zIndex: 15010,
        }
    }),
);
function LanguageSelect(props) {

    const {isShowLabel} = props;


    const [lang,setLang] = useState(()=>localStorage.getItem("language")||"zh")
    const {t} = useTranslation();
    const handleChange = (event)=>{
        setLang(event.target.value);
        i18n.changeLanguage(event.target.value)
        localStorage.setItem("language", event.target.value)
    }
    const classes = useStyles();
    return (
        <FormControl className={classes.formControl}>
                {isShowLabel&&<InputLabel id="select-label">{t('Public.SwitchLanguage')}</InputLabel>}
            <Select
              size='small'
              labelId="select-label"
              id="selectLanguage"
              value={lang}
              onChange={handleChange}
              label="Language"

            >
                <MenuItem value="zh">中文版</MenuItem>
                <MenuItem value="en">English</MenuItem>
            </Select>
            </FormControl>
    );
}

export default LanguageSelect;
