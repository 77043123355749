import React, {useEffect, useRef, useState} from 'react'
import QRCodeStyling from 'qr-code-styling'
import {Box, Button, InputLabel, NativeSelect, TextField} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import LogoImgQRCode from '../../../assets/images/logo-qrcode.jpeg'

function CreateQrCodeView({qrLink, currentFormName}) {
    const ref = useRef(null)
    const [qrCodeSize, setQrCodeSize] = useState(300)
    const [qrCodeTraditionColor, setQrCodeTraditionColor] = useState('#4267b2')
    const logoForQR = LogoImgQRCode
    // const [isLogo, setIsLogo] = useState(logoForQR)
    // const [qrCode, setQrCode] = useState(new QRCodeStyling({
    //     width: 300,
    //     height: 300,
    //     image:
    //       {logoForQR},
    //     dotsOptions: {
    //       color: '#4267b2',
    //       type: 'rounded'
    //     },
    //     imageOptions: {
    //       crossOrigin: 'anonymous',
    //       margin: 5
    //     }
    //   })

    const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        image: {logoForQR},
        dotsOptions: {
            color: '#4267b2',
            type: 'rounded'
        },
        imageOptions: {
            crossOrigin: 'anonymous',
            margin: 5
        }
    })

    useEffect(() => {
        qrCode.append(ref.current)
    }, [])

    useEffect(() => {
        qrCode.update({data: qrLink})
    }, [qrLink])

    useEffect(() => {
        qrCode.update({width: qrCodeSize, height: qrCodeSize})
    }, [qrCodeSize])

    useEffect(() => {
        qrCode.update({dotsOptions: {color: qrCodeTraditionColor}})
    }, [qrCodeTraditionColor])

    useEffect(() => {
        qrCode.update({image: logoForQR})
    }, [])

    const onQrCodeStyleChange = e => {
        if (Number(e.target.value) === 1) {
            setQrCodeTraditionColor('#000000')
        } else {
            setQrCodeTraditionColor('#4267b2')
        }
    }

    const onQrCodeSizeChange = event => {
        setQrCodeSize(event.target.value)
    }

    const onQrCodeLogoInclude = e => {
        if (Number(e.target.value) === 1) {
            qrCode.update({image: logoForQR})
            // setIsLogo(logoForQR)
        } else {
            // setIsLogo(undefined)
            qrCode.update({image: undefined})
        }
        // (Number(e.target.value) === 1) ? setIsLogo(logoForQR) : setIsLogo(undefined);
    }

    const onDownloadClick = () => {
        qrCode
            .download({
                name: currentFormName,
                extension: 'png'
            })
            .then(r => r)
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center'}}>
            <div ref={ref} />
            <TextField
                id="standard-number"
                label="Default Size: 300px"
                type="number"
                value={qrCodeSize}
                onChange={onQrCodeSizeChange}
                InputLabelProps={{
                    shrink: true
                }}
                variant="standard"
            />
            <Box sx={{minWidth: 120}}>
                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Color
                    </InputLabel>
                    <NativeSelect defaultValue={0} onChange={onQrCodeStyleChange}>
                        <option value={0}>Blue</option>
                        <option value={1}>Black</option>
                    </NativeSelect>
                </FormControl>
            </Box>
            <Box sx={{minWidth: 120}}>
                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Logo Included?
                    </InputLabel>
                    <NativeSelect defaultValue={1} onChange={onQrCodeLogoInclude}>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </NativeSelect>
                </FormControl>
            </Box>
            <Button variant="contained" onClick={onDownloadClick}>
                Download
            </Button>
            <a>URL:{qrLink}</a>
        </div>
    )
}

export default CreateQrCodeView
