import React, { useEffect, useState, useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {
    Accordion, AccordionDetails,
    AccordionSummary, Box,
    debounce,
    FormHelperText,
    FormLabel,
    Grid, IconButton, MenuItem,
    Paper, Select,
    TextField,
    Typography
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { DragIndicator } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import FormControl from '@mui/material/FormControl'
import {Eye} from 'react-feather'
import Chip from '@mui/material/Chip'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import ReactDraggable from 'react-draggable';
import DialogContentText from '@mui/material/DialogContentText'
import Autocomplete from '@mui/material/Autocomplete'
import {sendErrorMessage} from '../../../actions'
import SubmitButton from '../../../components/mui/button/SubmitButton'
import {CONTRACT_TEMPLATE_CREATE_VALIDATOR} from './contractHelper'
import {
    AdministrativeActionType,
    createContractTemplate,
    fetchAllEmailAccounts,
    fetchAllFilterTerms,
    fetchDefaultTerms,
    updateContractTemplate
} from '../../../actions/administrative/administrativeAction'
import {mbToBytes} from '../../forms/formBuilder/formBuilderData'
import { LANGUAGE, MAX_TEXT_LENGTH, ServerBaseURL } from '../../../constants';
import LoadingScreen from '../../../components/public/LoadingScreen'
import {FileUploader1} from '../../../components/fileUploader/FileUploader1'
import colors from '../../../theme/constants'
import MyCkEditor from '../../../components/ckeditor/MyCkEditor'
import DraggableDialog from '../../../components/mui/dialog/DraggableDialog'
import SearchBox from '../../../components/public/SearchBox'
import {DEFAULT_FETCH_PARAM} from '../../../components/mui/datagrid/PageGrid'
import TermPreviewView from './contractTerm/TermPreviewView'
import ChipInput from '../../../components/mui/input/ChipInput';
import { CONTRACT_AUTO_PLACEHOLDERS } from '../contract/contractHelper'

const useStyles = makeStyles(() => ({
    paper: {
        padding: '2rem'
    },
    editor: {
        '& .cke_contents.cke_reset': {
            minHeight: '50vh'
        },
        '& .ck-editor__editable_inline': {
            minHeight: '250px'
        }
    },
    searchBox: {
        width: '100%'
    },
}))

const schema = yup.object().shape(CONTRACT_TEMPLATE_CREATE_VALIDATOR)
function ContractTemplateEditor({isEdit, isClone, originalData, onSubmitSuccess, type = 'body'}) {
    const {register, handleSubmit, errors, setError} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })

    const classes = useStyles()
    const dispatch = useDispatch()

    const [replacementExpanded, setReplacementExpanded] = useState(false)
    const replacementDraggableRef = useRef(null);
    const [replacementPosition, setReplacementPosition] = useState({ x: 0, y: 0 });

    const fetchProps = useSelector(state => state.administrative.contractTemplateFetchBody)
    const allEmailAccounts = useSelector(state => state.administrative.allEmailAccounts)

    const [isLoaded, setIsLoaded] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const [emailAccount, setEmailAccount] = useState('')
    const [contractText, setContractText] = useState(isEdit || isClone ? originalData.text : '')
    const [touchedHeaderImg, setTouchedHeaderImg] = useState(!(isEdit || isClone))
    const [touchedSignatureImg, setTouchedSignatureImg] = useState(!(isEdit || isClone))
    const [referrals, setReferrals] = useState(isEdit || isClone ? originalData.referrals ?? [] : [])

    const [signatureImg, setSignatureImg] = useState([])
    const [headerImg, setHeaderImg] = useState([])
    // terms
    const [openModel, setOpenModel] = useState(false)
    const [currentTerm, setCurrentTerm] = useState(null)
    const [myEditor, setMyEditor] = useState(null)
    const [searchArgs, setSearchArgs] = useState({
        searchKey: undefined
    })
    const [listOpen, setListOpen] = useState(false)
    const [termLoaded, setTermLoaded] = useState(false)
    const [usedTermIndexesList, setUsedTermIndexesList] = useState([])

    const termList = useSelector(state => state.administrative.fixedTerms)
    const filterTerms = useSelector(state => state.administrative.filterTerms)

    const loadData = async () => {
        setIsLoaded(await fetchAllEmailAccounts()(dispatch))
        if (type === 'body') {
            dispatch(fetchDefaultTerms())
            setSearchArgs(prevState => ({
                ...prevState,
                searchKey: ''
            }))
        }
    }

    const loadAllTermsData = async () => {
        const {searchKey} = searchArgs
        if (searchKey !== undefined) {
            setTermLoaded(false)

            await fetchAllFilterTerms({
                searchKey,
                ...DEFAULT_FETCH_PARAM
            })(dispatch).then(res => {
                setTermLoaded(res)
            })
        }
    }
    useEffect(loadData, [])
    useEffect(loadAllTermsData, [searchArgs.searchKey])
    useEffect(() => {
        if (contractText) {
            const dom = new DOMParser().parseFromString(contractText, 'text/html')
            setUsedTermIndexesList(() => [
                ...Array.from(dom.getElementsByClassName('term'), term => Number(term.getAttribute('data-id')))
            ])
        }
        if (contractText.trim() === '') {
            setUsedTermIndexesList([])
        }
    }, [contractText])

    useEffect(() => {
        if (isLoaded && type === "head" && allEmailAccounts?.length > 0) {
            setEmailAccount(isEdit || isClone ? originalData.emailAccountId || allEmailAccounts[0].id : allEmailAccounts[0].id)
        }
    }, [type, isLoaded, allEmailAccounts])

    useEffect(() => {
        if (currentTerm && termList.filter(v => v.id === currentTerm.id).length === 0) {
            dispatch({type: AdministrativeActionType.FetchFixedTerms, payload: [...termList, currentTerm]})
        }
    }, [currentTerm])

    const handleSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase()
        setSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }

    const searchHandler = debounce(searchVal => {
        handleSearchChange(searchVal)
    }, 500)

    const onSubmit = async formData => {
        setIsSaving(true)

        formData.signature = [...signatureImg]
        formData.file = [...headerImg]

        const cleanedContractText = contractText.trim()
        if (cleanedContractText.length < 1) {
            setIsSaving(false)
            return setError('text', {type: 'manual', message: 'Contract cannot be blank'})
        }

        if (cleanedContractText.length > MAX_TEXT_LENGTH) {
            setIsSaving(false)
            return setError('text', {
                type: 'manual',
                message: `Contract length cannot be longer than ${MAX_TEXT_LENGTH} characters`
            })
        }
        // todo: change the validation to yup
        if (type === 'head') {
            if (formData?.cidPrefix.length < 1 || formData?.cidPrefix.length > 20) {
                setIsSaving(false)
                return setError('cidPrefix', {
                    type: 'manual',
                    message: 'Contract ID Prefix must be between 1 to 10 characters'
                })
            }
            if (formData?.partyBName.length < 1 || formData?.partyBName.length > 250) {
                setIsSaving(false)
                return setError('partyBName', {
                    type: 'manual',
                    message: 'Party B Name must be between 1 to 50 characters'
                })
            }
            if (touchedHeaderImg) {
                // Test file
                if (!formData.file || (formData.file && formData.file.length === 0)) {
                    setIsSaving(false)
                    return setError('file', {type: 'manual', message: 'Required'})
                }

                if (formData.file[0]?.size > mbToBytes(5)) {
                    setIsSaving(false)
                    return setError('file', {type: 'manual', message: `File must be less than 5mb`})
                }
            }
            if (touchedSignatureImg) {
                if (!formData.signature || (formData.signature && formData.signature.length === 0)) {
                    setIsSaving(false)
                    return setError('signature', {type: 'manual', message: 'Required'})
                }

                if (formData.signature[0]?.size > mbToBytes(1)) {
                    setIsSaving(false)
                    return setError('signature', {type: 'manual', message: `File must be less than 1mb`})
                }
            }
        }

        let res
        if (isEdit) {
            if (type === 'head') {
                res = new FormData()
                if (touchedHeaderImg) {
                    res.append('header', formData.file[0])
                }
                if (touchedSignatureImg) {
                    res.append('signature', formData.signature[0])
                }

                // Email Account
                if (emailAccount && !Number.isNaN(emailAccount)) {
                    res.append('emailAccountId', emailAccount)
                }

                res.append('name', formData.name)
                res.append('language', formData.language)
                res.append('cidPrefix', formData.cidPrefix)
                res.append('partyBName', formData.partyBName)
                res.append('description', formData.description)
                res.append('text', cleanedContractText)
                res.append('id', originalData.id)
                res.append('referrals', JSON.stringify(referrals))
            } else {
                res = {...formData, text: cleanedContractText, id: originalData.id?.toString()}
            }

            return updateContractTemplate(res, fetchProps, onSubmitSuccess, onError)(dispatch)
            // return false;
        }
        if (type === 'head') {
            res = new FormData()

            if (isClone) {
                res.append('cloneId', originalData.id?.toString())
            }

            // Email Account
            if (emailAccount && !Number.isNaN(emailAccount)) {
                res.append('emailAccountId', emailAccount)
            }

            if (formData.file[0]) {
                res.append('header', formData?.file[0])
            }
            if (formData.signature[0]) {
                res.append('signature', formData?.signature[0])
            }

            res.append('name', formData?.name)
            res.append('language', formData.language)
            res.append('partyBName', formData?.partyBName)
            res.append('cidPrefix', formData?.cidPrefix)
            res.append('description', formData?.description)
            res.append('text', cleanedContractText)
            res.append('type', 'head')
            res.append('referrals', JSON.stringify(referrals))
        } else {
            res = {...formData, text: cleanedContractText}
        }

        // return false;
        return createContractTemplate(res, fetchProps, onSubmitSuccess, onError)(dispatch)
    }

    const onError = msg => {
        sendErrorMessage(dispatch, msg)
        setIsSaving(false)
    }

    const handleOpenDetails = term => {
        setCurrentTerm(term)
        setOpenModel(true)
    }

    const onDragEnd = result => {
        myEditor.execute('insertContractTerm', Number(result.draggableId))
        myEditor.editing.view.focus()
    }


    const handleToggleReplacementExpand = (isExpanded) => {
        setReplacementExpanded(isExpanded)
        if (!isExpanded && replacementDraggableRef.current) {
            setReplacementPosition({ x: 0, y: 0 })
        }
    };

    const handleInsertReplacementText = (text) => {
        if (myEditor) {
            myEditor.model.change(writer => {
                const insertPosition = myEditor.model.document.selection.getFirstPosition();
                writer.insertText(text, insertPosition);
            });
            myEditor.editing.view.focus()
        }
    }

    const renderForm = () => (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={5} direction="row" justifyContent="space-between" alignItems="flex-start">
                <Grid item container spacing={5} xs={12} md={6}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                size="small"
                                label="Name"
                                required
                                disabled={isSaving}
                                variant="outlined"
                                name="name"
                                inputRef={register}
                                defaultValue={isEdit || isClone ? originalData.name : ''}
                                error={Boolean(errors.name?.message)}
                                helperText={
                                    errors.name?.message
                                        ? errors.name?.message
                                        : `${
                                              isClone
                                                  ? 'Please change the original name as it cannot be repeated. '
                                                  : ''
                                          }Max 250 characters`
                                }
                            />
                        </FormControl>
                    </Grid>
                    {
                        type === 'head' &&
                        <Grid item container spacing={5} xs={12}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="ID Prefix"
                                        required
                                        disabled={isSaving}
                                        variant="outlined"
                                        name="cidPrefix"
                                        inputRef={register}
                                        defaultValue={isEdit || isClone ? originalData.cidPrefix : 'M2W'}
                                        error={Boolean(errors.cidPrefix?.message)}
                                        helperText={
                                            errors.cidPrefix?.message
                                                ? errors.cidPrefix?.message
                                                : 'Max 10 characters'
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Party B Name"
                                    required
                                    disabled={isSaving}
                                    variant="outlined"
                                    name="partyBName"
                                    inputRef={register}
                                    multiline
                                    rows={2}
                                    defaultValue={isEdit || isClone ? originalData.partyBName : 'ITLabPro'}
                                    error={Boolean(errors.partyBName?.message)}
                                    helperText={
                                        errors.partyBName?.message
                                            ? errors.partyBName?.message
                                            : 'Name for Party B in contract'
                                    }
                                />
                            </Grid>
                        </Grid>
                    }
                </Grid>
                {type === 'head' && (
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth required={!isClone} error={Boolean(errors.signature?.message)}>
                            <FormLabel component="legend">Signature Image</FormLabel>
                            <FileUploader1
                                acceptDefaultTypes={false}
                                asFormInputComponent
                                fileType="jpe,jpeg,png"
                                maxKB={1024}
                                list={
                                    touchedSignatureImg
                                        ? []
                                        : [
                                              ...[],
                                              {
                                                  url: originalData?.partyBSignatureImage,
                                                  filename: originalData?.partyBSignature
                                              }
                                          ]
                                }
                                register={register}
                                color={{backgroundColor: colors.baseBackground, borderColor: colors.primaryLight}}
                                handleImageCallBack={fileList => {
                                    setSignatureImg(() => [...fileList])
                                    setTouchedSignatureImg(true)
                                }}
                                multipleFile={false}
                                onError={onError}
                            />
                            <FormHelperText>
                                {errors.signature?.message
                                    ? errors.signature?.message
                                    : `Preferably with transparent background; Max 1 mb${
                                          isClone ? '; Leave blank to not change' : ''
                                      }`}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                )}


                {
                    type === "head" &&
                    <>
                        <Grid item xs={12} md={8}>
                            <FormControl disabled={isSaving} fullWidth>
                                <FormLabel component='legend'>Email Account</FormLabel>
                                <Select
                                    native
                                    fullWidth
                                    size='small'
                                    value={emailAccount}
                                    onChange={(e) => setEmailAccount(e.target.value)}
                                >
                                    <MenuItem value=''><em>None</em></MenuItem>
                                    {
                                        allEmailAccounts.map(email =>
                                            <option value={email.id} key={email.id}>
                                                { `${email.name}: ${email.mailName} <${email.mailAccount}>` }
                                            </option>
                                        )
                                    }
                                </Select>
                                <FormHelperText>
                                    Email under this template will be sent from the selected address
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl disabled={isSaving} fullWidth>
                                <FormLabel component='legend'>Language</FormLabel>
                                <Select
                                    native
                                    fullWidth
                                    required
                                    disabled={isSaving}
                                    size='small'
                                    name='language'
                                    inputRef={register}
                                    defaultValue={isEdit || isClone ? originalData.language : ''}
                                    error={Boolean(errors.language?.message)}
                                >
                                    {
                                        Object.entries(LANGUAGE).map(([key, value]) =>
                                            <option value={value} key={value}>{key}</option>
                                        )
                                    }
                                </Select>
                                <FormHelperText error={Boolean(errors.language?.message)}>
                                    { errors.language?.message
                                        ?? 'Email language' }
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </>

                }

                <Grid item xs={12} md={type === 'head' ? 12 : 6}>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            size="small"
                            label="Description"
                            required
                            disabled={isSaving}
                            variant="outlined"
                            name="description"
                            inputRef={register}
                            defaultValue={isEdit || isClone ? originalData.description : ''}
                            error={Boolean(errors.description?.message)}
                            helperText={
                                errors.description?.message ? errors.description?.message : 'Max 250 characters'
                            }
                        />
                    </FormControl>
                </Grid>

                {
                    type === 'head' &&
                    <Grid item xs={12} md={type === 'head' ? 12 : 6}>
                        <FormControl fullWidth>
                            <ChipInput
                                fullWidth
                                size="small"
                                label="Representative"
                                disabled={isSaving}
                                variant="outlined"
                                name="referrals"
                                value={referrals}
                                onChange={value => setReferrals(value)}
                                error={Boolean(errors.referrals?.message)}
                                helperText={
                                    errors.referrals?.message ? errors.referrals?.message : 'Press enter to add representative'
                                }
                            />
                        </FormControl>
                    </Grid>
                }

                {type === 'body' && (
                    <>
                        <ReactDraggable
                            handle=".drag-handle"
                            disabled={!replacementExpanded}
                            position={replacementPosition}
                            onStop={(e, data) => setReplacementPosition({ x: data.x, y: data.y })}
                        >
                            <Grid
                                item
                                xs={12}
                                ref={replacementDraggableRef}
                                style={{ zIndex: 5 }}
                            >
                                <Accordion expanded={replacementExpanded} >
                                    <AccordionSummary expandIcon={null}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%'
                                            }}
                                        >
                                            <Box sx={{ display: 'flex' }}>
                                                <div className='drag-handle'>
                                                    <Typography><DragIndicator /></Typography>
                                                </div>
                                                <Typography>Replacement Text</Typography>
                                            </Box>
                                            <IconButton
                                                onClick={() => handleToggleReplacementExpand(!replacementExpanded)}
                                                sx={{
                                                    backgroundColor: 'transparent',
                                                    borderRadius: '50%',
                                                    height: '30px',
                                                    width: '30px'
                                                }}
                                            >
                                                {replacementExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                            </IconButton>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            item
                                            xs={12}
                                            container
                                            spacing={2}
                                            rowSpacing={1}
                                        >
                                            {
                                                CONTRACT_AUTO_PLACEHOLDERS.map((placeholder) => (
                                                    <Grid key={placeholder.key} item xs={12} sm={6} md={3} xl={2}>
                                                        <Chip
                                                            onClick={() => handleInsertReplacementText(placeholder.key)}
                                                            style={{ width: '100%' }}
                                                            color='primary'
                                                            label={placeholder.value}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </ReactDraggable>

                        <Grid item md={12}>
                            <DialogContentText sx={{paddingBottom: 1}}>Search for a Contract Term.</DialogContentText>

                            <Autocomplete
                                freeSolo
                                open={listOpen}
                                onOpen={() => {
                                    setListOpen(true)
                                }}
                                onClose={() => {
                                    setListOpen(false)
                                }}
                                id="term-select"
                                filterOptions={filteredList => filteredList}
                                options={filterTerms || []}
                                getOptionLabel={term => term.title}
                                isOptionEqualToValue={(option, value) =>
                                    option.title === value.title && option.slug === value.slug
                                }
                                onChange={(event, value) => {
                                    setCurrentTerm(value)
                                }}
                                renderInput={params => (
                                    <div className={classes.searchBox}>
                                        <SearchBox
                                            placeholder="Search..."
                                            size="small"
                                            type="search"
                                            searchHandler={searchHandler}
                                            isLoading={!termLoaded && listOpen && searchArgs.searchKey !== undefined}
                                            params={params}
                                            inputProps={params.InputProps}
                                        />
                                    </div>
                                )}
                            />
                        </Grid>
                    </>
                )}

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="terms" isDropDisabled>
                        {provided => (
                            <Grid
                                item
                                container
                                spacing={2}
                                rowSpacing={1}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {type === 'body' && (
                                    <Grid item container xs={12} spacing={1}>
                                        {termList?.map((term, index) => (
                                            <Draggable draggableId={String(term?.id)} index={index} key={term?.title}>
                                                {provided => (
                                                    <Grid item>
                                                        <div
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {termCard(term)}
                                                        </div>
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}
                                    </Grid>
                                )}

                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>

                    <Droppable droppableId='ck'>
                        {provided => (
                            <Grid
                                item
                                container
                                spacing={2}
                                rowSpacing={1}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <Grid item xs={12} md={type === 'head' ? 6 : 12} className={classes.editor}>
                                <MyCkEditor
                                        data={contractText}
                                        readOnly={isSaving}
                                        onChange={(evt, editor) => {
                                            setContractText(editor.getData())
                                            setMyEditor(editor)
                                        }}
                                        onReady={editor => setMyEditor(editor)}
                                        setModalOpen={x => setOpenModel(x)}
                                        setCurrentTerm={setCurrentTerm}
                                    />
                                    <FormHelperText error={Boolean(errors.text?.message)}>
                                        {errors.text?.message ? errors.text?.message : ''}
                                    </FormHelperText>
                                </Grid>
                                {type === 'head' && (
                                    <Grid item xs={12} md={6}>
                                        <FormControl
                                            fullWidth
                                            required={!isClone}
                                            error={Boolean(errors.file?.message)}
                                        >
                                            <FormLabel component="legend">Header Image</FormLabel>
                                            {/* <input type='file' name='file' ref={register} */}
                                            {/*       accept='image/png,image/jpeg' */}
                                            {/*       onChange={e => renderImageFile(e.target.files[0])} /> */}
                                            <FileUploader1
                                                acceptDefaultTypes={false}
                                                asFormInputComponent
                                                fileType="jpe,jpeg,png"
                                                maxKB={5 * 1024}
                                                list={
                                                    touchedHeaderImg
                                                        ? []
                                                        : [
                                                              ...[],
                                                              {
                                                                  url: `${ServerBaseURL}/asset/contractTemplate/headerImage/${originalData.id}.png`,
                                                                  filename: originalData?.filename
                                                              }
                                                          ]
                                                }
                                                register={register}
                                                color={{
                                                    backgroundColor: colors.baseBackground,
                                                    borderColor: colors.primaryLight
                                                }}
                                                handleImageCallBack={fileList => {
                                                    setHeaderImg(() => [...fileList])
                                                    setTouchedHeaderImg(true)
                                                }}
                                                multipleFile={false}
                                                onError={onError}
                                            />
                                            <FormHelperText>
                                                {errors.file?.message
                                                    ? errors.file?.message
                                                    : `Max 5 mb${isClone ? '; Leave blank to not change' : ''}`}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                )}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>

                {/* Bottom Submit Button */}
                <Grid item xs={12}>
                    <Grid container spacing={1} direction="row" justifyContent="flex-end">
                        <Grid item xs={2}>
                            <SubmitButton
                                isSaving={isSaving}
                                text="Submit"
                                isSavingText="Submitting"
                                fullWidth
                                variant="contained"
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )

    const termCard = term => (
        <Chip
            icon={<Eye />}
            label={term?.title}
            onClick={() => handleOpenDetails(term)}
            onDelete={() =>
                dispatch({
                    type: AdministrativeActionType.FetchFixedTerms,
                    payload: termList.filter(v => v !== term)
                })
            }
            color={usedTermIndexesList.includes(Number(term?.id)) ? 'primary' : 'default'}
        />
    )

    return (
        <Paper className={classes.paper}>
            {isLoaded === null ? (
                <LoadingScreen message="Loading..." />
            ) : isLoaded ? (
                renderForm()
            ) : (
                <Typography variant="h3" color="error">
                    Data load error
                </Typography>
            )}
            <DraggableDialog
                open={openModel}
                onClose={() => {
                    setOpenModel(false)
                }}
                title={currentTerm?.title}
            >
                {/* <MyCkEditor data={currentTerm?.text} /> */}
                <TermPreviewView currentTerm={currentTerm} myEditor={myEditor} />
            </DraggableDialog>
        </Paper>
    )
}

export default ContractTemplateEditor
