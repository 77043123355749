import React, {useEffect, useState} from 'react';
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useForm} from "react-hook-form";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {useDispatch} from "react-redux";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import SubmitButton from "../../components/mui/button/SubmitButton";
import history from "../../utils/history";
import {Logout, resetPassword} from "../../actions";
import api from "../../apis/api";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100vh',
        paddingTop: '10%',
    },
    paper: {
        backgroundColor: theme.palette.background.dark,
        padding: "2rem 2rem",
        maxWidth: 600,
    },
    textField: {},
    success: {
        color:'green',
        fontWeight: 'bold',
        margin: "1.5rem 1rem"
    },
    form: {
        width: '90%',
    },
    successIcon:{
        color: "green",
        marginTop:"1.85rem",
        fontSize: "2.5rem"
    },

}))


function ResetPasswordView() {
    const  navigate = useNavigate();
    const classes = useStyles();
    const [isSaving, setIsSaving] = useState(false);
    const [second, setSecond] = useState(5);
    const [values, setValues] = useState({
        showPassword: false,
    });
    const [isChanged, setIsChanged] = useState(false);
    const [checkedCode, setCheckedCode] = useState(null);

    const dispatch = useDispatch();
    const getCodeData = () => {
        const params = history.location.search.split('&');

        return {
            id: +params[0].split('=')[1],
            vcode: params[1].split('=')[1]
        };
    }
    const checkCode = () => {
        const data = getCodeData();
        api.post('/auth/checkResetPasswordCode', data).then(response => {
            if (response.data) {
                const {rs} = response.data;
                setCheckedCode(`${rs  }`);
            }
        })
    }
    useEffect(checkCode, []);
    useEffect(() => {
        if (checkedCode && checkedCode === "false") {
            navigate('/auth/resetCodeExpired')
        }
    }, [checkedCode])
    const cb = () => {
        navigate('/auth/login')
    }
    useEffect(()=>{
        let timerId = null
        if (isChanged) {
            timerId = setInterval(() => {
                setSecond(second - 1)
                if (second === 1) {
                    Logout(cb)(dispatch);
                    navigate('/auth/login');
                }
            }, 1000)
        }
        if(timerId){
            return () => clearInterval(timerId);
        }
            return ()=>{}
        

    }, [second, isChanged])

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };
    // const handleChange = (prop) => (event) => {
    //     setValues({...values, [prop]: event.target.value});
    // };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const {register, errors, handleSubmit, watch} = useForm({
        mode:"onBlur",
        reValidateMode:"onChange",
    });
    const watchNewPassword = watch("newPassword");

    const handleSaveSubmit = (formData) => {
        if (Object.keys(errors).length > 0) {
            return;
        }
        setIsSaving(true);
        const data = getCodeData();
        data.newPassword = formData.newPassword;
        resetPassword(data, afterSaving, handleErrorBack);

    }
    const handleErrorBack = () => {
        setIsSaving(false);
    }
    const afterSaving = () => {
        setIsSaving(false);
        setIsChanged(true);
    }




    return (
        <Paper className={classes.root}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                    <Paper className={classes.paper}>
                        <Typography variant="h2" color='primary' style={{textAlign: 'center'}}>
                            Reset your password!
                        </Typography>
                        <form noValidate onSubmit={handleSubmit(handleSaveSubmit)}>
                            {isChanged && (
                                <Grid container direction="row">
                                    <Grid item>
                                        <CheckCircleIcon classes={{root:classes.successIcon}}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" className={classes.success}>
                                            Password updated successfully! <br/>
                                            You will be redirected to the login page in {second} seconds.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                            <Typography variant="body2" style={{marginTop: ".5rem", marginBottom: ".6rem"}}>
                                <b>The New password must contain all character categories among the following:</b>
                                <li>Uppercase characters (A-Z)</li>
                                <li>Lowercase characters (a-z)</li>
                                <li>Digits (0-9)</li>
                            </Typography>
                            <FormControl fullWidth className={classes.textField} variant="outlined" size="small"
                                         style={{marginTop: ".5rem", marginBottom: ".6rem"}}>
                                <InputLabel htmlFor="newPassword">New Password</InputLabel>
                                <OutlinedInput inputRef={register({
                                    pattern: {
                                        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                                        message: 'New Password is too simple'
                                    },
                                    maxLength: {
                                        value: 20,
                                        message: "New Password must be no more than 20 characters"
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "New Password must be at least 8 characters"
                                    },
                                    required: "New Password is required",
                                })} name="newPassword" autoComplete="password" labelWidth={110}
                                               type={values.showPassword ? 'text' : 'password'}
                                               error={Boolean(errors.newPassword?.message)}
                                               endAdornment={
                                                   <InputAdornment position="end">
                                                       <IconButton
                                                           onClick={handleClickShowPassword}
                                                           onMouseDown={handleMouseDownPassword}
                                                           size="large">
                                                           {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                                       </IconButton>
                                                   </InputAdornment>
                                               }
                                />
                            </FormControl>
                            <FormHelperText error={Boolean(errors.newPassword?.message)}>
                                {errors.newPassword?.message}
                            </FormHelperText>
                            <FormControl fullWidth className={classes.textField} variant="outlined" size="small"
                                         style={{marginTop: ".5rem", marginBottom: ".6rem"}}>
                                <InputLabel htmlFor="confirmedPassword">Confirm Password</InputLabel>
                                <OutlinedInput inputRef={register({
                                    minLength: {
                                        value: 8,
                                        message: "Confirm Password must be at least 8 characters"
                                    },
                                    required: "Confirm Password is required",
                                    validate:{
                                        isMatch: value => value===watchNewPassword || "Those passwords didn't match. Try again.",
                                    },

                                })}
                                               name="confirmedPassword" autoComplete="password" labelWidth={130}
                                               type={values.showPassword ? 'text' : 'password'}
                                               error={Boolean(errors.confirmedPassword?.message)}
                                               endAdornment={
                                                   <InputAdornment position="end">
                                                       <IconButton
                                                           onClick={handleClickShowPassword}
                                                           onMouseDown={handleMouseDownPassword}
                                                           size="large">
                                                           {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                                       </IconButton>
                                                   </InputAdornment>
                                               }
                                />
                            </FormControl>
                            <FormHelperText error={Boolean(errors.confirmedPassword?.message)}>
                                {errors.confirmedPassword?.message}
                            </FormHelperText>


                            <Typography variant="h5" style={{marginBottom: "2rem"}}>
                                After a successful password update, you will be redirected to the login page where you
                                can log
                                in with your new password.
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item sm={5}>
                                    <SubmitButton isSaving={isSaving} text="Save Password"
                                                  isSavingText="Saving Password..."
                                                  fullWidth variant="contained" color="primary"/>
                                </Grid>
                                <Grid item sm={5}>
                                    <Button type="button" fullWidth variant="contained" color="primary" onClick={() => {
                                        navigate('/app/personal/info');
                                    }}>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>


                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default ResetPasswordView;
