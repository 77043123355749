import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select, SvgIcon, Switch,
  TextField,
  Typography
, Autocomplete } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from '@mui/icons-material';
import { debounce } from 'lodash';
import LoadingScreen from '../../../../../components/public/LoadingScreen';
import { sendErrorMessage } from '../../../../../actions';
import {
  BBS_SITE_LIST, getSiteTopUpAccountGroups,
  TOP_UP_POST_BASE_DOMAIN, TOP_UP_POST_VALIDATOR,
  useMatricAccountEditorView
} from '../../../matricHelper';
import { addTopUpPost, editTopUpPost, fetchAllTopUpUsers } from '../../../../../actions/matric/topupActions';
import SubmitButton from '../../../../../components/mui/button/SubmitButton';

const schema = yup.object().shape(TOP_UP_POST_VALIDATOR)
function TopUpPostEditor({ isEdit=false, originalData=null, site='', onSubmitSuccess=()=>{} }) {

  const { register, handleSubmit, errors, setError, clearErrors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const classes = useMatricAccountEditorView()
  const dispatch = useDispatch()
  const allTopUpUsers = useSelector(state => state.matric.allTopUpUsers)

  const [isSaving, setIsSaving] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const [allGroups, setAllGroups] = useState([])
  const [selectedSite, setSelectedSite] = useState(isEdit ? site : '')
  const [selectedGroup, setSelectedGroup] = useState(isEdit ? originalData.group : null)
  const [selectedInputGroup, setSelectedInputGroup] = useState(isEdit ? originalData.group : '')

  const [postURL, setPostURL] = useState(isEdit ? originalData.url : '')
  const [isEnabled, setIsEnabled] = useState(isEdit ? originalData.isActive : true)

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchAllTopUpUsers()(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    if (isLoaded && isEdit) {
      handleSiteURLFilled(originalData.url)
    }
  }, [isLoaded, isEdit])

  const onSuccess = (msg) => onSubmitSuccess(msg)
  const onError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }

  const onSubmit = async (formData) => {
    formData.url = postURL
    formData.group = selectedGroup
    formData.isActive = isEnabled
    formData.site = selectedSite

    setIsSaving(true)
    if (isEdit) {
      formData.id = originalData.id
      await editTopUpPost(formData, onSuccess, onError)(dispatch)
    } else {
      await addTopUpPost(formData, onSuccess, onError)(dispatch)
    }
  }

  const urlFieldHandler = debounce((textVal) => {
    handleSiteURLFilled(textVal)
  }, 500)

  const handleSiteURLFilled = (textVal) => {
    clearErrors('url')
    if (!textVal || textVal.trim().length < 1 || !textVal.match(/https?:\/\//)) {
      return setError('url', { type: 'manual', message: 'Please enter a valid URL beginning with https or http' })
    }

    const cleanedText = textVal.trim()
    // see which site it is and filter groups
    setPostURL(cleanedText)

    let found = false
    TOP_UP_POST_BASE_DOMAIN.forEach(bbsSite => {
      if (cleanedText.includes(bbsSite.domain)) {
        found = true

        // if entered address matches by domain then set site and filter groups by that site
        const siteUsers = allTopUpUsers[bbsSite.symbol]
        setAllGroups(getSiteTopUpAccountGroups(siteUsers))

        if (!isEdit) {
          // site shouldn't be changed after post is added
          setSelectedSite(bbsSite.symbol)
        }
      }
    })

    if (!found) {
      // wrong address entered
      return setError('url', { type: 'manual', message: `Only posts from York, CFC and VanPeople are supported` })
    }
    return ()=>{}
  }

  const renderURLField = () =>
    <Grid container alignContent='center' justifyContent='center' spacing={1} className={classes.marginBottom}>
      <Grid item xs={12}>
        <Typography variant='h5'>Post Address:</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name='url'
          variant='outlined'
          placeholder='Enter site URL...'
          defaultValue={isEdit ? originalData.url : ''}
          size='small'
          disabled={isSaving}
          onChange={ e => urlFieldHandler(e.currentTarget.value)}
          InputProps={{
            disabled: isEdit,
            startAdornment: <InputAdornment position='start'>
              <SvgIcon fontSize='small' color='action'>
                <Link />
              </SvgIcon>
            </InputAdornment>
          }}
          error={Boolean(errors.url?.message)}
          helperText={errors.url?.message ? errors.url?.message : 'Paste the post address to be topped up here'}
        />
      </Grid>
    </Grid>

  const renderSiteOptionsField = () =>
    <Grid container alignContent='center' justifyContent='center' spacing={3}>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          className={classes.toggleBox}
          control={
            <Switch
              disabled={isSaving}
              size='small'
              checked={isEnabled}
              onChange={() => setIsEnabled(!isEnabled)}
            />
          }
          label='Enabled'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField fullWidth size='small' variant='outlined'
                   label='TopUp Interval' type='number' required
                   name='timer' inputRef={register} disabled={isSaving}
                   defaultValue={ isEdit ? originalData?.timer : '12' }
                   InputProps={{
                     endAdornment: <InputAdornment position='start'>hours</InputAdornment>
                   }}
                   error={Boolean(errors.timer?.message)}
                   helperText={errors.timer?.message ? errors.timer?.message : 'Interval between top up attempts'}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant='outlined' size='small' fullWidth required disabled={isSaving}>
          <InputLabel>Site</InputLabel>
          <Select fullWidth name='site' label='Site *'
                  value={selectedSite} required disabled={isEdit}
                  onChange={(e) =>
                    setSelectedSite(e.target.value)}>
            {
              BBS_SITE_LIST.filter(s => s.name !== '58').map((u,i) =>
                <MenuItem key={i} value={u.symbol}>{ u.name }</MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth error={Boolean(errors.group?.message)}>
          <Autocomplete
            disabled={isSaving}
            options={allGroups}
            value={selectedGroup}
            onChange={(e, v) => setSelectedGroup(v)}
            inputValue={selectedInputGroup}
            onInputChange={(e, v) => setSelectedInputGroup(v)}
            renderInput={(params) =>
              <TextField {...params} label='Group' variant='outlined' size='small' required />
            }
          />
          <FormHelperText error={Boolean(errors.group?.message)}>
            { errors.group?.message ? errors.group?.message : 'Invalid groups will cause the top up process to fail' }
          </FormHelperText>
        </FormControl>
      </Grid>

      {/* Bottom Submit Button */}
      <Grid item xs={12}>
        <Grid container spacing={1} direction='row' justifyContent='flex-end'>
          <Grid item>
            <SubmitButton isSaving={isSaving} text='Submit' isSavingText='Submitting'
                          fullWidth variant='contained' color='primary'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  const renderView = () =>
    <Paper className={classes.paper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        { renderURLField() }
        { postURL && renderSiteOptionsField() }
      </form>
    </Paper>

  return (
    <div>
      {
        isLoaded
          ? renderView()
          : <LoadingScreen message='Loading...' />
      }
    </div>
  )
}

export default TopUpPostEditor
