import Tooltip from '@mui/material/Tooltip'
import {IconButton} from '@mui/material'
import React from 'react'
import {ListAlt} from '@mui/icons-material'
import {Copy, Edit} from 'react-feather'

function TabColumnEditComponent({editName, onClick}) {
    return (
        <Tooltip title={editName} placement="top">
            <IconButton color="primary" size="small" component="span" onClick={onClick}>
                {editName === 'Details' ? (
                    <ListAlt size={20} />
                ) : editName === 'Edit' ? (
                    <Edit size={20} />
                ) : (
                    <Copy size={20} />
                )}
            </IconButton>
        </Tooltip>
    )
}

export default TabColumnEditComponent
