// export const EMAIL = /[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9]+?\.[a-zA-Z]{2,}$/;
import * as yup from 'yup';

export const EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE = '^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'

export const EMPLOYEE_CREATE_VALIDATOR = {
    firstName: yup.string().required().min(1).max(20).trim(),
    lastName: yup.string().required().min(1).max(20).trim(),
    nickName:yup.string().required().min(1).max(20).trim(),
    email: yup.string().email().required().trim()
        .matches(new RegExp(EMAIL), "email must be a valid email"),
    phone: yup.string().required().trim()
        .min(10, "phone must be at least 10 numbers")
        .max(11, "phone must be at most 11 numbers")
        .matches(new RegExp(PHONE), "phone must be right type "),
    city: yup.lazy((value)=>value===''?yup.string():yup.string().min(2).max(20).trim()),
    zipcode:yup.lazy( (value)=>value===''?yup.string():yup.string().min(6).max(7).trim()),
    address:yup.lazy((value)=>value===''?yup.string():yup.string().min(5).max(100).trim()),
    wechat:yup.lazy((value)=>value===''?yup.string():yup.string().min(2).max(20).trim()),
    country:yup.lazy( (value)=>value===''?yup.string():yup.string().min(2).max(15).trim())
}

export const employeeStatus= ['fullTime', 'partTime', 'campusAmbassador']
