import { QRCodeActionType } from '../../actions/qrcode/qrcodeAction';

const defaultState = {
  allQRCodeSets: [],
  currentQRCodeSet: {},
  currentQRCodeSetCodes: [],
  currentQRCode: {},

  setFetchProps: {},
  codeFetchProps: {},

  allUsers: [],
  allCategories: [],

  qrCodeImages: {},       // Image cache storage by QR Code ID
}

export const qrcodeReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case QRCodeActionType.FetchAllQRCodeSets:
      return { ...state, allQRCodeSets: action.payload }
    case QRCodeActionType.FetchQRCodeSet:
      return { ...state, currentQRCodeSet: action.payload }
    case QRCodeActionType.FetchQRCodeSetCodes:
      return { ...state, currentQRCodeSetCodes: action.payload }
    case QRCodeActionType.FetchQRCodeDetails:
      return { ...state, currentQRCode: action.payload }

    case QRCodeActionType.FetchQRCodeUsers:
      return { ...state, allUsers: action.payload }
    case QRCodeActionType.FetchQRCodeCategories:
      return { ...state, allCategories: action.payload }

    case QRCodeActionType.GenerateQRCodeImage:
      return { ...state, qrCodeImages: { ...state.qrCodeImages, [action.payload.id]: action.payload.image } }

    case QRCodeActionType.SetSetFetchProps:
      return { ...state, setFetchProps: action.payload }
    case QRCodeActionType.SetListFetchProps:
      return { ...state, codeFetchProps: action.payload }

    default:
      return state
  }
}
export default qrcodeReducer