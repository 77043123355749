import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Cancel, Delete, Edit } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ResponsiveDialog from '../dialog/ResponsiveDialog';
import { sendErrorMessage, sendSuccessMessage } from '../../actions';
import AddCategoryInput from './CategoryInput';

const useStyles = makeStyles((theme) => ({
  headingRoot: {
    minHeight: '100%',
    marginTop: `calc(0px - ${theme.spacing(4)})`,
    paddingBottom: theme.spacing(3)
  },
  pageTitle: {
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('xl')]:{
      marginBottom: '.1rem'
    }
  },
  catName: {
    display: 'inline-block',
    marginRight: theme.spacing(1)
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
  marginLeft: {
    marginTop: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  closeButton: {
    marginBottom: `calc(0px - ${theme.spacing(6)})`,
  },
}))

function CategoryManager({ allCategories,
                           fetchCategoryAction,
                           addCategoryAction,
                           deleteCategoryAction,
                           updateCategoryAction,TitleName='Category Manager'}) {

  const classes = useStyles()
  const dispatch = useDispatch()

  const [isLoaded, setIsLoaded] = useState(false)
  const [isCatLoaded, setIsCatLoaded] = useState(false)

  const [categoriesMap, setCategoriesMap] = useState({})
  const [isShowEditInput, setIsShowEditInput] = useState(false)

  const [hash, setHash] = useState('init')
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
  const [selectedCategoryForDel, setSelectedCategoryForDel] = useState(-1)

  const loadData = () => {
    (async() => {
      setIsCatLoaded(await fetchCategoryAction()(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    if (allCategories && isCatLoaded) {
      // Map each category to an open or closed state to indicate whether the field is being edited
      const tempCatMap = {}
      allCategories.forEach(cat => {tempCatMap[cat.id] = false})
      setCategoriesMap(tempCatMap)
      setIsLoaded(true)
    }
  }, [isCatLoaded, allCategories])


  const handleDeleteOpen = (id) => {
    setSelectedCategoryForDel(id)
    setOpenDeleteDialogue(true)
    setHash(Math.random().toString(16).substring(2, 10));
  }

  const onOperationSuccess = (msg) => sendSuccessMessage(dispatch, msg)
  const onOperationError = (msg) => sendErrorMessage(dispatch, msg)
  const onDeleteCat = () => {
    deleteCategoryAction(selectedCategoryForDel,
      (msg) => onOperationSuccess(msg),
      (msg) => onOperationError(msg))
    (dispatch)
  }

  const onEditCB = (id, name) => {
    const cleanName = name.trim()
    // Toggle edit field off
    setCategoriesMap({ ...categoriesMap, [id]: false })
    // see if name changed
    const hasChanged = cleanName !== '' && allCategories.find(cat => cat.id === id).name !== cleanName
    if (hasChanged) {
      updateCategoryAction(id, cleanName,
        (msg) => onOperationSuccess(msg),
        (msg) => onOperationError(msg))
      (dispatch)
    }
  }

  const onAddCB = (id, name) => {
    const cleanName = name.trim()
    // see if name entered
    const hasChanged = cleanName !== ''
    if (hasChanged) {
      addCategoryAction(cleanName,
        (msg) => onOperationSuccess(msg),
        (msg) => onOperationError(msg))
      (dispatch)
    }
  }

  const renderTopButtons = () => (
      <div>
        {!isShowEditInput &&
        <Button color='secondary'
                variant='contained'
                onClick={() => {
                  setIsShowEditInput(true)
                  // Attempt to scroll into view
                  const bottomElem = document.getElementById('emptyDivAtTheBottom')
                  if (bottomElem) bottomElem.scrollIntoView({ block: 'end', behavior: 'smooth' })
                }}>
          Add Category
        </Button>
        }
      </div>
    )


  const renderTable = () => (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category Name</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allCategories.map((cat) => <TableRow key={cat.id}>
                <TableCell scope="row">
                  { categoriesMap[cat.id]
                    ? <AddCategoryInput cb={onEditCB} id={cat.id} name={cat.name}/>
                    : <div>
                      <div className={classes.catName}>{ cat.name }</div>
                      <Tooltip title='Edit' placement='top'>
                        <IconButton color='primary'
                                    size='small'
                                    component='span'
                                    onClick={() => setCategoriesMap({ ...categoriesMap, [cat.id]: true })}>
                          <Edit fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                </TableCell>
                <TableCell align="right">
                  <Tooltip title='Delete' placement='top'>
                    <IconButton color='primary'
                                size='small'
                                component='span'
                                onClick={() => handleDeleteOpen(cat.id)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
    )

  return (
    <div>
      <Grid alignItems="center" container className={classes.headingRoot}>
       <Typography variant="h3" color="textPrimary" className={classes.pageTitle}>
         {TitleName}
        </Typography>
        <Grid item xs={12}>
          { renderTopButtons() }
        </Grid>
      </Grid>

      { isLoaded && renderTable() }

      {isShowEditInput &&
      <div>
        <Grid alignItems="center" container justifyContent="flex-end" className={classes.closeButton}>
          <Grid item>
            <IconButton onClick={() => setIsShowEditInput(!isShowEditInput)} size="large">
              {isShowEditInput && <Tooltip title='Close' placement='left'><Cancel style={{ color: 'red' }} /></Tooltip>}
            </IconButton>
          </Grid>
        </Grid>
        <Paper className={classes.paper} id='new-cat-input'>
          <Typography variant='h4'>Add New Category:</Typography>
          <div className={classes.marginLeft}>
            <AddCategoryInput cb={onAddCB} id='' name='' />
          </div>
        </Paper>
      </div>
      }

      <ResponsiveDialog
        isOpen={openDeleteDialogue} openHash={hash}
        title="Delete Category" content={`Delete category "${ allCategories?.find(cat => cat.id === selectedCategoryForDel)?.name || 'NULL' }"?`}
        Buttons={
          [
            { name: "Yes", event: () => onDeleteCat() },
            { name: "Cancel", event: () => setSelectedCategoryForDel(-1) }
          ]
        }
      />

      <div className={classes.marginBottom} id='emptyDivAtTheBottom' />
    </div>
  );
}

export default CategoryManager
