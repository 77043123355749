import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { publicFetchDataForPoster } from '../../../../../actions/campaign/campaignActions';
import { WECHAT_URL_BASE } from '../../../campaignHelper';
import CenterDisplay from '../../../../../components/misc/CenterDisplay';

function ScanPosterView() {

  const cid = parseInt(useParams().cid,10)
  const pid = parseInt(useParams().pid,10)
  const uid = parseInt(useParams().uid,10)
  const dispatch = useDispatch()

  const [isLoaded, setIsLoaded] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [displayImage, setDisplayImage] = useState('')
  const [displayMessage, setDisplayMessage] = useState('')

  const onFetchUIDError = () => {
    setIsLoaded(true)
    setHasError(true)
    document.title = "Unavailable"
  }
  const onFetchUIDSuccess = (data) => {
    setDisplayImage(`${WECHAT_URL_BASE}/${data.user}/${data.wid}`)
    setDisplayMessage(data.message)
    setIsLoaded(true)
    document.title = data.title || "Scan Poster"
  }

  // Initial load to get the UID of poster and user

  useEffect(() => {
    publicFetchDataForPoster(cid, pid, uid, window.location.href,
      onFetchUIDSuccess, onFetchUIDError)(dispatch)
  }, [])

  return(
    <CenterDisplay>
      { isLoaded ?
        hasError
          ? <Typography variant='h2'>Campaign Expired</Typography>
          : <div>
            <img src={displayImage} alt='scan the wechat qr code to get in touch'/>
            <Typography variant='h2'>{ displayMessage }</Typography>
          </div>
        : <Typography variant='h3'>Loading...</Typography>
      }
    </CenterDisplay>
  )
}

export default ScanPosterView
