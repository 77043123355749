import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    Button, FormHelperText,
    Grid,
    Paper,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import FormLabel from '@mui/material/FormLabel';
import SubmitButton from '../../../components/mui/button/SubmitButton';
import {sendErrorMessage,
    sendSuccessMessage
} from '../../../actions';
import AddClassReview from './AddClassReview';
import ViewHead from "../../../components/public/ViewHead";
import {Functions} from "../../../utils/Functions";
import { CLASS_DELIVERY_VALIDATOR, useClassEditorStyle } from './classHelper';
import {
    addClassHistory,
    classActionsType, clearClassViewData,
    fetchTeachers,
    updateClassHistory
} from '../../../actions/class/classActions';
import SyllabusTreeView from '../syllabus/SyllabusTreeView';
import { SYLLABUS_DISPLAY_TYPE } from '../syllabus/syllabusHelper';
import MultiSelectByCallback from '../../../components/form/MultiSelectByCallback';
import {MAX_TEXT_LENGTH} from "../../../constants";

const schema = yup.object().shape(CLASS_DELIVERY_VALIDATOR)
function AddClassDeliver({ isEdit, edit, oneClass, currentHistory, updateClassHistory, teacher, addClassHistory }) {

    const classHistory = {
        extension: edit
          ? currentHistory.oneHistory.extension
          : '',
        teacher: edit
          ? `${currentHistory.oneHistory.teacher.firstName} ${currentHistory.oneHistory.teacher.lastName}`
          : `${teacher.firstName} ${teacher.lastName}`,
        notes: edit
          ? currentHistory.oneHistory.notes : ''
    }

    const { register, errors, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        defaultValues: classHistory
    })

    const classes = useClassEditorStyle()
    const dispatch = useDispatch()
    const classViewData = useSelector(state => state.classes.classViewData)

    const [isSaving, setIsSaving] = useState(false)

    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')

    const [selectedStudents, setSelectedStudents] = useState([])
    const [selectedSyllabus, setSelectedSyllabus] = useState([])
    const [completedMap, setCompletedMap] = useState({})
    const [syllabuses, setSyllabuses] = useState([])

    // const syllabuses = oneClass?.data?.syllabus

    useEffect(() => {
        if (oneClass?.data?.syllabus) {
            setSyllabuses(oneClass?.data?.syllabus)
        }
        if (oneClass?.data?.history?.length > 0) {
            // Add dates for completed points from all past histories
            const tempCompletedMap = {}
            oneClass.data.history.forEach(history => {
                if (history.points?.length > 0) {
                    history.points.forEach(point => {
                        tempCompletedMap[point.id] = moment(history.createdAt).format('L LT')
                    })
                }
            })
            setCompletedMap(tempCompletedMap)
        }
    }, [oneClass])

    const allStudents = oneClass?.data?.students?.map(student =>
      ({ ...student, displayName: `${student.id}: ${student.firstName} ${student.lastName}`}))

    useEffect(() => {
        const loadClear = async () => {
            if (classViewData && classViewData?.deliverId) {
                setSelectedSyllabus([classViewData.deliverId])
                await clearClassViewData()(dispatch)
            }
        }
        loadClear().then()
    }, [classViewData, dispatch])

    useEffect(() => {
        if (edit) {
            // set selected syllabus
            const selectedPoints = currentHistory.oneHistory.points.map(point => point.id)
            setSelectedSyllabus(selectedPoints)
            const startTimeNew = moment(currentHistory.oneHistory.startTime).format('YYYY-MM-DDTHH:mm')
            const endTimeNew = moment(currentHistory.oneHistory.endTime).format('YYYY-MM-DDTHH:mm')
            setStartTime(startTimeNew)
            setEndTime(endTimeNew)

            const tempAttendants = currentHistory.oneHistory.attendants.map(curStudent =>
              allStudents.find(student => curStudent.id === student.id))
            setSelectedStudents(tempAttendants.filter(student => student !== undefined))
        } else {
            const time = moment().format()
            const substring = time.substr(0, 16)
            setStartTime(substring)
            const endtime = moment().add(2, 'hours').format()
            const substring1 = endtime.substr(0, 16)
            setEndTime(substring1)

            // Default select all students
            const tempAttendants = oneClass.data.students.map(curStudent =>
              allStudents.find(student => curStudent.id === student.id))
            setSelectedStudents(tempAttendants)
        }
    }, [])

    const handleChangeStart = (event) => setStartTime(event.target.value)
    const handleChangeEnd = (event) => setEndTime(event.target.value)

    const handleSaveSubmit = (formData) => {



        const classId = oneClass.data.id
        const teacherId = edit ? currentHistory.oneHistory.teacher.id : teacher.id
        const {notes} = formData
        const attendants = selectedStudents.map(student => student.id)
        const extension1 = formData.extension

        if (selectedSyllabus.length === 0) {
            return setError('syllabus', { type: 'manual', message: 'Please select syllabus' })
        }
        if (attendants.length === 0) {
            return setError('student', { type: 'manual', message: 'Please select student' })
        }
        if (startTime === '') {
            return setError('startTime', { type: 'manual', message: 'Please select startTime' })
        }
        if (endTime === '') {
            return setError('endTime', { type: 'manual', message: 'Please select endTime' })
        }
        if (endTime.localeCompare(startTime) === -1 || endTime.localeCompare(startTime) === 0) {
            return setError('endTime', { type: 'manual', message: 'endTime should be after startTime' })
        }

        if(notes.length>MAX_TEXT_LENGTH){
            return setError('notes',{ type: 'manual', message: 'Notes length should not be greater than 50000' })
        }



        const formValue = {
            classId,
            teacherId,
            startTime,
            endTime,
            notes,
            attendants,
            points: selectedSyllabus,
            extension: extension1
        }

        if (edit) {
            setIsSaving(true)
            updateClassHistory({ ...formValue, historyId: currentHistory.oneHistory.id },
              (msg) => onOperationSuccess(msg), (msg) => onOperationError(msg) )
        } else {
            setIsSaving(true)
            addClassHistory(formValue, (msg) => onOperationSuccess(msg), (msg) => onOperationError(msg))
        }
        return ()=>{}

        
    }

    const onOperationSuccess = (msg) => {
        setIsSaving(false)
        sendSuccessMessage(dispatch, msg)
        dispatch({ type: classActionsType.SetClassTableStatus, payload: 'READ' })
    }
    const onOperationError = (msg) => {
        setIsSaving(false)
        sendErrorMessage(dispatch, msg)
    }

    const renderSyllabusView = () =>
      <Grid container spacing={3}>
          <Grid item xs={12}>
              {
                  syllabuses.length > 0 &&
                  <FormControl fullWidth className={classes.formControlSyllabusSelect}>
                      <FormLabel component='legend' className='label'>Select Syllabus</FormLabel>
                      <SyllabusTreeView data={syllabuses} selected={selectedSyllabus}
                                        onSelected={setSelectedSyllabus} completedMap={completedMap}
                                        displayType={SYLLABUS_DISPLAY_TYPE.SELECT_POINT} />
                      <FormHelperText error={Boolean(errors.syllabus?.message)}>
                          {errors.syllabus?.message ? errors.syllabus?.message : 'Only points (light teal) can be selected'}
                      </FormHelperText>
                  </FormControl>
              }
          </Grid>
      </Grid>

    const topButton = () =>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch({
              type: classActionsType.SetClassTableStatus,
              payload: 'READ'
          })
        }}
      >
          Back To Delivery
      </Button>

    return (
      <ViewHead functionId={Functions.Training_Class_Class_Details} topButtons={topButton()} noMaxWidth>
          <Grid container>
              {isEdit ?
                <Paper className={classes.paper__form}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h3" color="textPrimary">
                                Class Delivery
                            </Typography>
                            <br/><br/>
                        </Grid>
                        <Grid item xs={12}>
                            <form noValidate onSubmit={handleSubmit(handleSaveSubmit)}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField size="small" fullWidth margin="normal"
                                                   name="extension" id="id" required
                                                   label="Name" autoFocus variant="outlined"
                                                   inputRef={register}
                                                   className={classes.text}
                                                   error={Boolean(errors.extension?.message)}
                                                   helperText={errors.extension?.message} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField size="small" fullWidth margin="normal"
                                                   name="teacher" id="teacher" required
                                                   label="Teacher" variant="outlined"
                                                   inputRef={register}
                                                   className={classes.text}
                                                   error={Boolean(errors.teacher?.message)}
                                                   helperText={errors.teacher?.message}
                                                   disabled />
                                    </Grid>

                                    <Grid item xs={12}>
                                        { renderSyllabusView() }
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                          name="startTime"
                                          id="start"
                                          label="Start"
                                          type="datetime-local"
                                          required
                                          fullWidth
                                          InputLabelProps={{ shrink: true }}
                                          onChange={handleChangeStart}
                                          value={startTime}
                                          error={Boolean(errors.startTime?.message)}
                                          helperText={errors.startTime?.message}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                          name="endTime"
                                          id="end"
                                          label="End"
                                          type="datetime-local"
                                          required
                                          fullWidth
                                          InputLabelProps={{ shrink: true }}
                                          onChange={handleChangeEnd}
                                          value={endTime}
                                          error={Boolean(errors.endTime?.message)}
                                          helperText={errors.endTime?.message}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControlSelect} fullWidth>
                                            <InputLabel required error={Boolean(errors.student?.message)}>Attendants</InputLabel>
                                            <div className='selector'>
                                                <MultiSelectByCallback
                                                  displayField='displayName'
                                                  data={allStudents} defaultSelected={selectedStudents}
                                                  callback={(data) => setSelectedStudents(data)}/>
                                            </div>
                                            <FormHelperText
                                              className={classes.redCheckBox}>{errors.student?.message ? errors.student?.message : null}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField fullWidth margin="normal" name="notes" id="notes"
                                                   label="notes" variant="outlined" multiline
                                                   inputRef={register}
                                                   className={classes.text}
                                                   rows={4}
                                                   error={Boolean(errors.notes?.message)}
                                                   helperText={errors.notes?.message}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container spacing={2} className={classes.button_save}>
                                            <Grid item sm={3}>
                                                <SubmitButton fullWidth variant="contained" color="primary" isSaving={isSaving}
                                                              text="Save" isSavingText="Saving"/>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <Button type="button" fullWidth variant="contained"
                                                        color="primary" disabled={isSaving}
                                                        onClick={() => {
                                                            dispatch({
                                                                type: classActionsType.SetClassTableStatus,
                                                                payload: 'READ'
                                                            })
                                                        }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Paper>

                :
                <AddClassReview isEdit={isEdit} oneClass={oneClass} currentHistory={currentHistory}/>
              }
          </Grid>
      </ViewHead>
    )
}

const mapStateToProps = (state) => ({
        teacher: state?.auth.user
    })

export default connect(mapStateToProps,
  { fetchTeachers, updateClassHistory, addClassHistory }
)(AddClassDeliver)
