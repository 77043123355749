import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {Button} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import MyCkEditor from '../../../components/ckeditor/MyCkEditor'
import {confirmStudentMessage, fetchStudentMessage} from '../../../actions'

const useStyles = makeStyles(() => ({
    button: {
        margin: '1em 0em'
    }
}))

function EditStudentMessage({singleMessage, confirmStudentMessage}) {
    const classes = useStyles()

    const message = {
        messageId: singleMessage.id,
        submissionId: singleMessage.submissions[0].id,
        status: 'read'
    }

    const confirmMessage = () => {
        confirmStudentMessage(message)
    }

    useEffect(() => {
        // console.log('this is singleMessga',singleMessage)
    }, [singleMessage])

    return (
        <Grid containter="true">
            <Typography gutterBottom variant="body1">
                Message Content
            </Typography>

            <MyCkEditor data={singleMessage.content} readOnly />
            {singleMessage.submissions[0].status === 'sent' ? (
                <Grid item xs={12} sm={6}>
                    <Button className={classes.button} color="primary" variant="contained" onClick={confirmMessage}>
                        Read&Confirm
                    </Button>
                </Grid>
            ) : null}
        </Grid>
    )
}

export default connect(null, {confirmStudentMessage, fetchStudentMessage})(EditStudentMessage)
