import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {Clipboard} from "react-feather";
import {useDispatch} from "react-redux";
import { copyToClipboard } from '../../../utils/Scripts';

const useStyles = makeStyles((theme) => ({

    title: {
        [theme.breakpoints.down('xl')]: {
            transform: "translateY(10px)",
        }
    },
    text: {
        wordWrap: "break-word",
        wordBreak: "break-all"
    }

}));



function TextLine({title,value}) {

    const dispatch =useDispatch();

    const onCopy=(t,v) =>
        copyToClipboard(dispatch, v,
            `${t} ${v} added to clipboard`,
            `${t} ${v} cannot be copied `)


    const classes = useStyles();

return (
    <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="baseline">
        <Grid item xs={12} sm={3}>
            <Typography variant="body2" color="textSecondary" className={classes.title}>
                {title}
            </Typography>
        </Grid>
        <Grid item xs={11} sm={8}>
            <Typography variant="body1" color="textPrimary" className={classes.text}>
                <Tooltip title='Copy Value' placement='top'>
                    <IconButton color='primary'
                                size='small'
                                component='span'
                                onClick={() => onCopy(title,value)}
                    >
                        <Clipboard color="#ebebeb" size={20} style={{marginBottom:"5px"}} />
                    </IconButton>
                </Tooltip>
                {value==='null'?'':value}
            </Typography>
        </Grid>

        <Grid item xs={12}>
            <Divider/>
        </Grid>
    </Grid>
    );
}

TextLine.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
}

export default TextLine;
