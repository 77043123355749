import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';

import {HelpCircle, Edit as EditIcon} from 'react-feather';
import {connect, useDispatch} from 'react-redux';
import {IconButton, Tooltip} from "@mui/material";
import {FunctionActions} from "../../../../actions";
import useAuthCheck from "../../../../hooks/useAuthCheck";
import {Functions} from "../../../../utils/Functions";


const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[50]})`,
            color: 'var(--tree-view-color)',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
    labelTitle__notActive: {
        textDecoration: "line-through"
    },
    labelCenter: {
        marginLeft: ".5rem",
        flexGrow: 1,
    },
    labelRight: {
        marginLeft: ".5rem",

    }
}));


function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const {
        labelText, labelIcon: LabelIcon, labelInfo, labelMiddle, color, bgColor, currentFunction,
        ID, isActive, labelDesc, ...other
    } = props;
    const dispatch = useDispatch();
    const [isEdit] = useAuthCheck([Functions.System_Management_Functions_Edit_Function]);
    return (
        <TreeItem
            label={
                <Tooltip title={labelDesc} arrow placement="top-start" >
                    <div className={classes.labelRoot}>
                        {LabelIcon === null ?
                            <HelpCircle color="inherit" className={classes.labelIcon}/>
                            : <LabelIcon className={classes.labelIcon}/>
                        }
                        <Typography variant="body2"
                                    className={isActive ? classes.labelText : classes.labelTitle__notActive}>
                            {labelText}
                        </Typography>
                        <Typography variant="body2" className={classes.labelCenter}>
                            {labelMiddle}
                        </Typography>
                        <Typography variant="caption" color="inherit" className={classes.labelRight}>
                            {labelInfo}
                        </Typography>
                        {isEdit && currentFunction && currentFunction.id === ID ?
                            <IconButton color="primary" component="span" size="small" edge='end' onClick={(event) => {
                                event.stopPropagation();
                                dispatch({type: FunctionActions.SetFunctionFormStatus, payload: "Edit"})
                            }}>
                                <EditIcon size={20}/>
                            </IconButton> : null
                        }
                    </div>
                </Tooltip>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType,
    labelInfo: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    labelMiddle: PropTypes.string.isRequired,
    ID: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    labelDesc:PropTypes.string.isRequired,
};

StyledTreeItem.defaultProps = {
    color: '',
    bgColor: '',
    labelIcon: null,




}
const mapStateToProps = ({system}) => ({currentFunction: system.currentFunction})
export default connect(mapStateToProps, {})(StyledTreeItem);