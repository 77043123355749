import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, ButtonGroup, Grid, IconButton, Link, Modal, Paper, Tooltip, Typography} from '@mui/material'
import {useParams} from 'react-router'
import {Edit} from 'react-feather'
import {AddCircle, AttachFile, Check, Delete, Email, ListAlt, PictureAsPdf} from '@mui/icons-material'
import moment from 'moment'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import {useNavigate} from 'react-router-dom'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import {Functions} from '../../../utils/Functions'
import ViewHead from '../../../components/public/ViewHead'
import CenterDisplay from '../../../components/misc/CenterDisplay'
import {
    clearContractNotes,
    confirmContract,
    createContractNote,
    deleteContractFile,
    deleteContractNote,
    downloadContractFile,
    downloadContractPdf,
    fetchContract,
    fetchContractNotes,
    updateContractStatus
} from '../../../actions/administrative/administrativeAction'
import LXGrid from '../../../components/mui/datagrid/XGrid'
import useAuthCheck from '../../../hooks/useAuthCheck'
import {emailLinkGenerator, listStatusFormatter, useContractFileStyles} from './contractHelper'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog'
import AddContractFileDialogue from './uploadContractFileDialogue'
import NotesManager from '../../../components/NotesManager/NotesManager'
import {copyToClipboard} from '../../../utils/Scripts'

function CreateContractView() {
    useCurrentViewCheck(Functions.Administrative_Contract_View)

    const {uid} = useParams()
    const {cid} = useParams()
    const classes = useContractFileStyles()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const currentContract = useSelector(state => state.administrative.currentContract)
    const allNotes = useSelector(state => state.administrative.currentContractNotes)

    const [isLoaded, setIsLoaded] = useState(false)
    const [hashDone, setHashDone] = useState('init2')
    const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false)
    const [selectedForDone, setSelectedForDone] = useState('')

    const [openModal, setModalOpen] = useState(false)
    const handleModalOpen = () => setModalOpen(true)
    const handleModalClose = () => setModalOpen(false)

    const [checkboxSelected, setCheckboxSelected] = useState([])
    const [hash, setHash] = useState('init')
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
    const [selectedFileForDel, setSelectedFileForDel] = useState('')

    const [cid4Notes, setCid4Notes] = useState(null)
    const [openNotesModal, setNotesModalOpen] = useState(false)
    const handleNotesModalClose = () => {
        clearContractNotes()(dispatch)
        setCid4Notes(null)
        setNotesModalOpen(false)
    }

    const [canCreateContract] = useAuthCheck([Functions.Administrative_Contract_Create])

    const loadData = () => {
        ;(async () => {
            setIsLoaded(await fetchContract(uid, cid)(dispatch))
        })()
    }
    useEffect(loadData, [dispatch, uid, cid])

    const handleConfirmOpen = id => {
        setSelectedForDone(id)
        setOpenConfirmDialogue(true)
        setHashDone(Math.random().toString(16).substring(2, 10))
    }

    const handleDeleteOpen = id => {
        setSelectedFileForDel(id)
        setOpenDeleteDialogue(true)
        setHash(Math.random().toString(16).substring(2, 10))
    }

    const onSuccess = msg => sendSuccessMessage(dispatch, msg)
    const onError = msg => sendErrorMessage(dispatch, msg)

    const onConfirm = () => {
        confirmContract(
            selectedForDone,
            msg => onSuccess(msg),
            msg => onError(msg)
        )(dispatch)
    }

    const onDeleteFile = () => {
        const data = {
            fileUid: selectedFileForDel,
            cid,
            uid: currentContract.customer.id
        }
        deleteContractFile(
            data,
            msg => onSuccess(msg),
            msg => onError(msg)
        )(dispatch)
    }

    const handleFileOpen = (id, isPdf, productName = '') => {
        if (isPdf) {
            downloadContractPdf(
                id,
                productName,
                `${currentContract.customer?.firstName}_${currentContract.customer?.lastName}`,
                msg => {
                    onError(msg)
                }
            )(dispatch)
        } else {
            const data = {
                fileUid: id,
                cid,
                uid: currentContract.customer.id
            }
            downloadContractFile(data, msg => {
                onError(msg)
            })(dispatch)
        }
    }

    const onStatusChange = isActive => {
        updateContractStatus(
            checkboxSelected.map(x => parseInt(x, 10)),
            isActive,
            msg => {
                setCheckboxSelected([])
                onSuccess(msg)
            },
            msg => onError(msg),
            true,
            uid,
            cid
        )(dispatch)
    }

    const onFileUploadDone = msg => {
        sendSuccessMessage(dispatch, msg)
        handleModalClose()
    }

    const renderStatusButtons = () => (
        <Grid item className={classes.activityButtons} xs={12}>
            <ButtonGroup variant="contained" color="primary" size="small">
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(true)}>
                    Enable
                </Button>
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(false)}>
                    Disable
                </Button>
            </ButtonGroup>
        </Grid>
    )

    const renderContractGrid = () => {
        const columns = []

        if (canCreateContract) {
            columns.push({
                field: '_actions',
                headerName: ' ',
                resizable: false,
                disableClickEventBubbling: true,
                width: 80,
                filterable: false,
                renderCell: params => (
                    <div>
                        {!['signed', 'done'].includes(params.row.status) && (
                            <Tooltip title="Edit" placement="top">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    component="span"
                                    onClick={() =>
                                        navigate(
                                            `/app/administrative/contract/edit/${currentContract?.customer?.id}/${params.row.cid}`
                                        )
                                    }
                                >
                                    <Edit size={20} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {params.row.status === 'sent' && (
                            <Tooltip title="Copy Link" placement="top">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    component="span"
                                    onClick={() => {
                                        copyToClipboard(
                                            dispatch,
                                            `https://${window.location.host}/contract/${params.row.customer?.id}/${params.row.cid}`
                                        )
                                    }}
                                >
                                    <FileCopyIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                )
            })
        }

        columns.push(
            ...[
                {
                    field: 'cid',
                    headerName: 'ID',
                    width: 180,
                    renderCell: params => (
                        <Link
                            className={classes.link}
                            onClick={() =>
                                navigate(
                                    `/app/administrative/contract/view/${currentContract?.customer?.id}/${params.row.cid}`
                                )
                            }
                        >
                            {params.row.isActive ? params.value : <del>{params.value}</del>}
                        </Link>
                    )
                },
                {field: 'status', headerName: 'Status', width: 100, renderCell: ({value}) => listStatusFormatter(value)}
            ]
        )

        // Done => Show button to see Contract PDF File
        // Signed => Show button to see Contract PDF File and Confirm Button
        if (canCreateContract) {
            columns.push({
                field: '_contractActions',
                headerName: ' ',
                resizable: false,
                disableClickEventBubbling: true,
                width: 80,
                filterable: false,
                renderCell: params => (
                    <div>
                        {['signed', 'done'].includes(params.row.status) && (
                            <Tooltip title="Contract" placement="top">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    component="span"
                                    onClick={() => handleFileOpen(params.row.cid, true, params.row.name)}
                                >
                                    <PictureAsPdf size={20} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {params.row.status === 'signed' && (
                            <Tooltip title="Confirm" placement="top">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    component="span"
                                    style={{color: 'green'}}
                                    onClick={() => handleConfirmOpen(params.row.cid)}
                                >
                                    <Check size={20} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {params.row.status === 'done' && (
                            <a
                                href={emailLinkGenerator({
                                    productName: params.row.name,
                                    customerName: currentContract.customer.firstName,
                                    customerEmail: params.row.email,
                                    userName: auth.user.firstName
                                })}
                            >
                                <Tooltip title="Email Customer" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        // onClick={() => {
                                        //   console.log(params.row)
                                        // }}
                                        component="span"
                                    >
                                        <Email size={20} />
                                    </IconButton>
                                </Tooltip>
                            </a>
                        )}
                    </div>
                )
            })
        }

        columns.push(
            ...[
                {field: 'name', headerName: 'Product', width: 200},
                {
                    field: '_viewNotes',
                    headerName: 'Note',
                    resizable: false,
                    disableClickEventBubbling: true,
                    width: 90,
                    filterable: false,
                    renderCell: params => (
                        <Tooltip title="View Notes" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() => {
                                    setCid4Notes(params.row.cid)
                                    setNotesModalOpen(true)
                                }}
                            >
                                <ListAlt size={20} />
                            </IconButton>
                        </Tooltip>
                    )
                },
                {
                    field: 'createdAt',
                    headerName: 'Created Date',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                },
                {
                    field: 'signedDate',
                    headerName: 'Signed Date',
                    width: 200,
                    valueFormatter: ({value}) => (value ? moment(value).format('L LT') : '')
                },
                {
                    field: 'confirmedDate',
                    headerName: 'Confirmed Date',
                    width: 200,
                    valueFormatter: ({value}) => (value ? moment(value).format('L LT') : '')
                }
            ]
        )

        return (
            <div>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h4" color="textPrimary">
                            Subcontracts
                        </Typography>
                    </Grid>
                    <Grid>
                        {canCreateContract && (
                            <Tooltip title="Add Contract" placement="top">
                                <IconButton
                                    onClick={() =>
                                        navigate(`/app/administrative/contract/add/${uid}/${currentContract.id}`)
                                    }
                                    size="large"
                                >
                                    <AddCircle style={{color: 'green'}} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>

                <Paper elevation={0}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        {canCreateContract ? renderStatusButtons() : <div />}
                        <LXGrid
                            totalHeight="40vh"
                            selectionModel={checkboxSelected}
                            onSelectionModelChange={newSelection => setCheckboxSelected(newSelection)}
                            checkboxSelection
                            rows={currentContract.subContract ?? []}
                            columns={columns}
                            loading={!isLoaded}
                            disableSelectionOnClick
                        />
                    </Grid>
                </Paper>
            </div>
        )
    }

    const renderFileGrid = () => {
        const columns = []

        columns.push(
            ...[
                {
                    field: '_contractOpen',
                    headerName: 'Open',
                    resizable: false,
                    disableClickEventBubbling: true,
                    width: 90,
                    filterable: false,
                    renderCell: params => (
                        <div>
                            <Tooltip title="Open File" placement="top">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    component="span"
                                    onClick={() => handleFileOpen(params.row.uid, false)}
                                >
                                    <AttachFile size={20} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                },
                {
                    field: '_contractDelete',
                    headerName: 'Delete',
                    resizable: false,
                    disableClickEventBubbling: true,
                    width: 90,
                    filterable: false,
                    renderCell: params => (
                        <div>
                            <Tooltip title="Delete File" placement="top">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    component="span"
                                    onClick={() => handleDeleteOpen(params.row.uid)}
                                >
                                    <Delete size={20} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                },
                {field: 'name', headerName: 'Name', width: 180},
                {field: 'description', headerName: 'Description', width: 250},
                {field: 'mime', headerName: 'Type', width: 150},
                {
                    field: 'createdAt',
                    headerName: 'Created Date',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                }
            ]
        )

        return (
            <div>
                <Grid container justifyContent="space-between" alignItems="center" className={classes.marginTop}>
                    <Grid item>
                        <Typography variant="h4" color="textPrimary">
                            Files
                        </Typography>
                    </Grid>
                    <Grid>
                        {canCreateContract && (
                            <Tooltip title="Add File" placement="top">
                                <IconButton
                                    onClick={() =>
                                        // navigate(`/app/administrative/contract/add/${uid}/${currentContract.id}`)}>
                                        handleModalOpen()
                                    }
                                    size="large"
                                >
                                    <AddCircle style={{color: 'green'}} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>

                <Paper elevation={0}>
                    <LXGrid
                        totalHeight="40vh"
                        rows={currentContract.files ?? []}
                        columns={columns}
                        loading={!isLoaded}
                        disableSelectionOnClick
                    />
                </Paper>
            </div>
        )
    }

    const renderDetailsView = () => (
        <div>
            <Typography variant="h3" className={classes.pageTitle} color="textPrimary">
                Contract Details: {currentContract?.customer?.firstName} {currentContract?.customer?.lastName}
            </Typography>
            {renderBackButton()}
            {renderContractGrid()}
            {renderFileGrid()}
        </div>
    )

    const renderBackButton = () => (
        <Button color="secondary" variant="contained" onClick={() => navigate('/app/administrative/contract')}>
            Back to All Contracts
        </Button>
    )

    return (
        <ViewHead functionId={Functions.Administrative_Contract_View} isShowTitle={false} noMaxWidth>
            {isLoaded ? (
                renderDetailsView()
            ) : (
                <CenterDisplay>
                    <Typography variant="h1" color="textPrimary">
                        Loading...
                    </Typography>
                </CenterDisplay>
            )}
            <ResponsiveDialog
                isOpen={openConfirmDialogue}
                openHash={hashDone}
                title="Confirm Contract"
                content={`Confirm Contract ${selectedForDone}?`}
                Buttons={[
                    {name: 'Yes', event: () => onConfirm()},
                    {name: 'Cancel', event: () => setSelectedForDone('')}
                ]}
            />

            <NotesManager
                nid={cid4Notes}
                allNotes={allNotes}
                fetchNotesAction={fetchContractNotes}
                createNotesAction={createContractNote}
                deleteNotesAction={deleteContractNote}
                isModalOpen={openNotesModal}
                handleModalClose={handleNotesModalClose}
            />

            <Modal
                open={openModal}
                onClose={handleModalClose}
                className={classes.modal}
                disablePortal
                disableRestoreFocus
                disableEnforceFocus
                disableAutoFocus
                closeAfterTransition
            >
                <Paper className={classes.paper}>
                    <AddContractFileDialogue uid={uid} cid={cid} onSubmitSuccess={onFileUploadDone} />
                </Paper>
            </Modal>

            <ResponsiveDialog
                isOpen={openDeleteDialogue}
                openHash={hash}
                title="Delete File"
                content="Delete File?"
                Buttons={[
                    {name: 'Yes', event: () => onDeleteFile()},
                    {name: 'Cancel', event: () => setSelectedFileForDel('')}
                ]}
            />
        </ViewHead>
    )
}

export default CreateContractView
