import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress, Collapse,
  Grid,
  Popover,
  Typography
, Alert, AlertTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import Fuse from 'fuse.js';
import moment from 'moment';
import clsx from 'clsx';
import io from 'socket.io-client';
import MonitorDateSelectionView from '../MonitorDateSelectionView';
import MonitorTabDataView from "./MonitorTabDataView";
import LoadingScreen from '../../../../components/public/LoadingScreen';
import {
  fetchAllAlertedPosts,
  MatricActionType,
  scrapePostsFromBBS,
  sendErrorMessage, sendSuccessMessage
} from '../../../../actions';
import {
  BBS_SITE_LIST,
  DEFAULT_DATE_START,
  MATRIC_SOCKET_FUNCTIONS,
  sortMonitorList,
  useMatricListView
} from '../../matricHelper';
import { ServerBaseURL } from '../../../../constants';
import SearchBox from '../../../../components/public/SearchBox';

function MonitorDataView() {

  const classes = useMatricListView()
  const dispatch = useDispatch()

  const allAlertedPosts = useSelector(state => state.matric.allAlertedPosts)
  const showDisabledKeywords = useSelector(state => state.matric.showDisabledKeywords)

  const [isAllPostsLoaded, setIsAllPostsLoaded] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const [totalPosts, setTotalPosts] = useState({})
  const [allPostList, setAllPostList] = useState([])            // Step 1: All the posts
  const [dateFilteredList, setDateFilteredList] = useState([])  // Step 2: The posts filtered by date range
  const [displayList, setDisplayList] = useState([])            // Step 3: Posts filtered by text search

  const [postUpdateDate, setPostUpdateDate] = useState({})
  const [postUpdatePopEl, setPostUpdatePopEl] = useState(null)

  const [searchQuery, setSearchQuery] = useState('')
  const [searchDate, setDateSearch] = useState(DEFAULT_DATE_START)

  const [isUpdating, setIsUpdating] = useState(false)
  const [updateMessage, setUpdateMessage] = useState(null)

  const loadData = () => {
    (async() => {
      setIsAllPostsLoaded(await fetchAllAlertedPosts(showDisabledKeywords)(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    const sio = io.connect(ServerBaseURL + MATRIC_SOCKET_FUNCTIONS.MATRIC_WATCHER_NAMESPACE)
    sio.on(MATRIC_SOCKET_FUNCTIONS.MATRIC_WATCHER_CONNECTED_FUNC,
      (count) => handleListenerUpdate(count))

    return () => {
      // console.log('SIO:', sio)
      sio.disconnect()
    }
  }, [])

  useEffect(() => {
    if (isAllPostsLoaded) {
      const tempAllPosts = []
      const tempDateUpdated = {}
      const tempTotalPosts = {}
      BBS_SITE_LIST.forEach(site => {

        if (Object.prototype.hasOwnProperty.call(allAlertedPosts, site.symbol)) {

          // Save Date
          tempDateUpdated[site.symbol] = moment(allAlertedPosts[site.symbol].updated)
          tempTotalPosts[site.symbol] = allAlertedPosts[site.symbol].total
          tempTotalPosts.all = (tempTotalPosts.all || 0) + allAlertedPosts[site.symbol].total

          const curSitePost = allAlertedPosts[site.symbol].data.map(post =>
            ({ ...post, site: site.symbol }))

          tempAllPosts.push(...curSitePost)
        }
      })
      const sortedTempPosts = sortMonitorList(tempAllPosts)
      setAllPostList(sortedTempPosts)
      setPostUpdateDate(tempDateUpdated)
      setTotalPosts(tempTotalPosts)
      setIsLoaded(true)
      onDateSearch(searchDate, sortedTempPosts)
    }
  }, [isAllPostsLoaded, allAlertedPosts])

  const handleListenerUpdate = async (count) => {
    sendSuccessMessage(dispatch, `${count} new posts found`)
    await fetchAllAlertedPosts(showDisabledKeywords)(dispatch)
  }

  const handleDisableKeywordChange = async () => {
    dispatch({ type: MatricActionType.ChangeShowDisabledKeywords, payload: !showDisabledKeywords })
    await fetchAllAlertedPosts(!showDisabledKeywords)(dispatch)
  }

  const onDateSearch = (customStartDate, listToSearch = null) => {
    const searchList = listToSearch || allPostList

    // if there is no defined start date, then just use the entire list
    if (!customStartDate) {
      setDateSearch(null)
      setDateFilteredList(searchList)
      return onSearch(searchQuery, searchList)
    }

    setDateSearch(customStartDate)

    const startDate = customStartDate.startOf('day')
    const endDate = moment().endOf('day')

    const result = searchList.filter(post => {
      const foundTime = moment(post.foundTime)
      if (startDate <= foundTime && endDate >= foundTime) {
        return post
      }
      return null
    })
    setDateFilteredList(result)
    return onSearch(searchQuery, result)
  }

  const onSearch = (searchVal, listToSearch = null) => {
    const cleanSearchVal = searchVal.toLowerCase().trim()
    setSearchQuery(cleanSearchVal)

    const searchList = listToSearch || dateFilteredList
    const fuse = new Fuse(searchList, { keys: ['id', 'title', 'category', 'user', 'description'] })

    const filteredDisplay = cleanSearchVal === ''
      ? searchList
      : fuse.search(cleanSearchVal).map(f => f.item)
    setDisplayList(filteredDisplay)
  }

  const searchHandler = debounce((searchVal) => {
    onSearch(searchVal)
  }, 500)

  const onBBSUpdate = () => {
    setIsUpdating(true)
    scrapePostsFromBBS(
      showDisabledKeywords,
      (data) => {
        const tempUpdateMessage = BBS_SITE_LIST.map((site,index) =>
          <Typography variant='body2' key={index}>
            { `${site.name}: Found ${data[site.symbol].new} new posts ` }
          </Typography> )
        setUpdateMessage(tempUpdateMessage)
        setIsUpdating(false)
      },
      (msg) => {
        sendErrorMessage(dispatch, msg)
        setIsUpdating(false)
      }
    )(dispatch)
  }

  const renderSearchContainer = () =>
    <Grid container justifyContent='space-between' className={classes.searchContainer}>
      <Grid item xs={12} sm={6} md={8} xl={10} className='searchText'>
        <SearchBox
          placeholder='Search...'
          size='small'
          type='search'
          searchHandler={searchHandler} />
      </Grid>

      <Grid item className='searchDate'>
        <MonitorDateSelectionView searchFunction={onDateSearch} />
      </Grid>
    </Grid>

  const renderStatusContainer = () =>
    <Grid container justifyContent='space-between' alignItems='center' spacing={4}>
      <Grid item>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <Button variant='contained' size='small'
                    disabled={isUpdating}
                    className={clsx(classes.showHideButton, {
                      'disabled': showDisabledKeywords
                    })}
                    onClick={() => handleDisableKeywordChange()}
            >
              { showDisabledKeywords ? 'Showing Disabled' : 'Hiding Disabled' }
            </Button>
          </Grid>
          <Grid item>
            <Typography variant='body2'>Posts loaded: {displayList.length}/{allPostList.length}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button variant='text'
                onMouseEnter={(e) => setPostUpdatePopEl(e.currentTarget)}
                onMouseLeave={() => setPostUpdatePopEl(null)}
        >
          Status
        </Button>

        <Button
          variant='contained'
          disabled={isUpdating}
          className={classes.updateButton}
          onClick={() => onBBSUpdate()}
        >
          { isUpdating && <CircularProgress size={24} className='progress'/> }
          { isUpdating ? 'Updating' : 'Force Update Now' }
        </Button>

        <Popover
          className={classes.popover}
          classes={{
            paper: classes.popoverPaper,
          }}
          open={Boolean(postUpdatePopEl)}
          anchorEl={postUpdatePopEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Typography variant='h4'>Last Updated</Typography>
          {
            BBS_SITE_LIST.map(site =>
              <Typography variant='body2' key={site.symbol}>
                { `${site.name}: ${postUpdateDate[site.symbol]?.fromNow()}` }
              </Typography>)
          }
        </Popover>

      </Grid>
    </Grid>

  const renderView = () =>
    <Grid container>
      <Grid item xs={12} className={classes.headerItem}>
        <Collapse in={!!updateMessage}>
          <Alert onClose={() => setUpdateMessage(null)}
                 className={classes.alertBanner}>
            <AlertTitle>Posts Fetched</AlertTitle>
            { updateMessage }
          </Alert>
        </Collapse>
      </Grid>

      <Grid item xs={12} className={classes.headerItem}>
        { renderStatusContainer() }
      </Grid>

      <Grid item xs={12} className={classes.headerItem}>
        { renderSearchContainer() }
      </Grid>

      <Grid item xs={12}>
        <MonitorTabDataView data={displayList} totalPosts={totalPosts}/>
      </Grid>
    </Grid>

  return (
    <div>
      {
        isLoaded
          ? renderView()
          : <LoadingScreen message='Loading...' />
      }
    </div>
  )
}

export default MonitorDataView
