import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {Typography} from "@mui/material";
import useCurrentViewCheck from "../../../hooks/useCurrentViewCheck";
import {Functions} from "../../../utils/Functions";
import api from "../../../apis/api";
import LDataGrid from "../../../components/mui/datagrid";
import LocalStorage from "../../../utils/LocalStorage";
import {authCheck} from "../../../actions";
import ViewHead from "../../../components/public/ViewHead";

function SwitchUser() {
    useCurrentViewCheck(Functions.System_Management_Switch_User);
    const [list, setList] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            const response = await api.get('auth/fetchSwitchUserList');
            setList(response.data);
        }

        loadData();
    }, []);

    const dispatch = useDispatch();

    const SuperLogin = (id) => {
        api.get(`auth/superLogin/${id}`).then(response => {
            if (response.data) {
                LocalStorage.setToken(response.data);
                authCheck(() => {
                    window.location.replace(`${window.location.origin  }/app`);
                })(dispatch);

            }
        })
    }
    const columns = [
        {field: 'id', headerName: 'ID', width: 60,},
        {field: 'firstName', headerName: 'First Name', width: 120,},
        {field: 'lastName', headerName: 'Last Name', width: 120,},
        {field: 'email', headerName: 'email', width: 200,},
        {field: 'isStaff', headerName: 'Staff', width: 80,},
        {field: 'verified', headerName: 'Verified', width: 80,},
        {
            field: "company_name", headerName: 'Company', width: 100,
            renderCell: params => (
                <Typography variant="body2" color="textPrimary">
                    {params.row.company?.name}
                </Typography>
            )
        },
        {
            field: "button", headerName: 'Login', width: 300,
            renderCell: params =>
                params.row.verified > 0?
                        (
                            <Button color="primary" onClick={() => {
                                SuperLogin(params.row.id)
                            }}>
                                Login as {params.row.role?.name}
                            </Button>
                        ):<div>&nbsp;</div>
        }
    ];
    return (
        <ViewHead functionId={Functions.System_Management_Switch_User}>

            <LDataGrid totalHeight={500} rows={list} columns={columns} loading={list.length < 1}/>
        </ViewHead>

    )


}


export default SwitchUser;