import SystemMenu from './systemMenu'
import Auth from './auth'


export const en = {
    translation:{
        Public: {
            SwitchLanguage: "Language",
        },
        contract: "Contract",
        contractId: "Contract ID",
        productId: "Product ID",
        product: "Product",
        name: "Product Name",
        personName: "Name",
        firstName: "First Name",
        lastName: "Last Name",
        address: "Address",
        city: "City",
        country: "Country",
        zipCode: "Zip Code",
        phone: "Phone",
        email: "Email",
        wechat: "WeChat",
        price: "Price",
        taxAmount: "Tax Value",
        discount: "Discount",
        total: "Total Price",
        partyA: "Party A",
        partyB: "Party B",
        contractAgreementCheckboxText: "I confirm that I have read, understand and agree to the above contract",
        contractAgreementSignMessage: "This service agreement is entered into by both parties on an equal and voluntary basis after careful communication and full understanding of the contents of the agreement.",
        contractSignedMessage: "Thank you for choosing us. You may now close this tab.",
        date: "Date",
        signature: "Signature",
        signHere: "Sign Here",
        submitSignature: "Submit Signature",
        signatureError: "Please sign before submitting",
        signatureErrorSize: "Please sign your signature larger",
        signatureErrorGeneric: "Signature cannot be submitted'",
        clear: "Clear",
        cancel: "Cancel",
        confirm: "Confirm",
        submitContract: "Submit Contract",
        submitting: "Submitting",
        invoice: "Invoice",
        invoiceBillTo: "BILL TO",
        invoiceShipTo: "SHIP TO",
        invoiceInstructions: "INSTRUCTIONS",
        invoiceQuantity: "QUANTITY",
        invoiceDescription: "DESCRIPTION",
        invoiceUnitPrice: "UNIT PRICE",
        invoiceTaxAmount: "TAX AMOUNT",
        invoiceDiscount: "DISCOUNT",
        invoiceTotal: "TOTAL",
        invoiceTotalDueBy: "TOTAL DUE BY",
        invoiceSubtotal: "SUBTOTAL",
        invoiceSalesTax: "SALES TAX",
        invoiceShippingHandling: "SHIPPING & HANDLING",
        sameAsBillingAddress: "Same as billing address",
        invoiceThanksMessage: "Thank you for your business!",
        signAndConfirm: "Sign and confirm",
    },
    Auth,
    SystemMenu,
}

export default en;
