import { trainingMessageActionsTypes } from '../../actions';


export const trainingMessageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case trainingMessageActionsTypes.SetTrainingMessageFormStatus:
      return { ...state, trainingMessageFormStatus: payload };
    case trainingMessageActionsTypes.AddTrainingMessage:
      return { ...state, trainingMessageFormStatus: 'Close' };
    case trainingMessageActionsTypes.FetchTrainingMessage:
      // console.log("FetchTrainingMessage:", payload);
      return { ...state, trainingMessages: payload };
    case trainingMessageActionsTypes.FetchOneTrainingMessage:
      return { ...state, fetchedOneMessage: payload, trainingMessageFormStatus: 'EDIT' };
    case trainingMessageActionsTypes.FetchOneMessageInfo:
      return { ...state, fetchedOneMessageInfo: payload };

    case trainingMessageActionsTypes.UpdateOneTrainingMessage:
      return { ...state, trainingMessageFormStatus: 'Close' };
    case trainingMessageActionsTypes.FetchClass:
      return { ...state, classes: payload };

    case trainingMessageActionsTypes.FetchStudentMessage:
      return { ...state, studentMessage: payload };
    case trainingMessageActionsTypes.FetchCurrentStudentMessage:
      return { ...state, currentStudentMessage: payload, trainingMessageFormStatus: 'EDIT' };
    case trainingMessageActionsTypes.ConfirmStudentMessage:
      return { ...state };
    case trainingMessageActionsTypes.DeleteStudentMessage:
      return { ...state, deleteId: payload };
    case trainingMessageActionsTypes.ActiveMessage:
      return { ...state, activationMessage: payload };
    case trainingMessageActionsTypes.FetchTrainingMessageProps:
      return { ...state, trainingMessageFetchBody: payload };
    default:
      return state;
  }

};


const initialState = {
  trainingMessageFormStatus: 'Close',
  trainingMessages: {},
  fetchedOneMessage: '',
  fetchedOneMessageInfo: '',
  studentMessage: undefined,
  deleteId: undefined,
  currentStudentMessage: undefined,
  classes: [],
  activationMessage: undefined,
  trainingMessageFetchBody: {}
};

export default trainingMessageReducer