import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {cloneDeep, isEqual} from 'lodash'
import clsx from 'clsx'
import {DataGridPro} from '@mui/x-data-grid-pro'
import {useGridApiRef} from '@mui/x-data-grid'
// import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import {AppBar, Paper, Tab, Tabs} from '@mui/material'
import {
    generateColumnsForTreeList,
    mapTreeData,
    SYLLABUS_DISPLAY_TYPE,
    SYLLABUS_MAX_LENGTH,
    SYLLABUS_TYPES,
    SYLLABUS_TYPES_VALUES,
    TREE_LIST_CHILD_FIELD,
    TREE_LIST_EDIT_FIELD,
    TREE_LIST_EXPAND_FIELD,
    useTreeListStyles,
    treeNodeList,
    TREE_NODE_ID_FIELD
} from '../syllabusHelper'
import {
    changeExpandState,
    createSyllabusCell,
    deleteSyllabusCell,
    expandFieldState,
    initExpandState,
    reorderSyllabusCell,
    resetExpandState,
    sendErrorMessage,
    sendSuccessMessage,
    SyllabusActionType,
    updateSyllabusCell
} from '../../../../actions'
import SyllabusEditCommandCell from './SyllabusEditCommandCell'
import ResponsiveDialog from '../../../../components/dialog/ResponsiveDialog'
import {getBasicHash, getTotalHeight} from '../../../../utils/Scripts'
import SyllabusAddDeliveryCommandCell from './SyllabusAddDeliveryCommandCell'

function SyllabusTreeView({
    data,
    displayType,
    completed = [],
    completedMap = {},
    selected = [],
    onSelected = () => {},
    canEdit = false,
    onAddDelivery = () => {}
}) {
    const isSelectView =
        displayType === SYLLABUS_DISPLAY_TYPE.SELECT_SYLLABUS || displayType === SYLLABUS_DISPLAY_TYPE.SELECT_POINT

    const classes = useTreeListStyles()
    const dispatch = useDispatch()
    const expandStatus = useSelector(state => state.syllabuses.syllabusExpandStatus)
    const totalHeight = isSelectView ? Math.floor(getTotalHeight() / 1.5) : getTotalHeight()

    const currentTab = useSelector(state => state?.syllabuses?.currentSyllabusTab)
    const handleChangeTab = (event, newValue) =>
        dispatch({type: SyllabusActionType.ChangeSyllabusTab, payload: newValue})

    const [editId, setEditId] = useState(
        isSelectView || displayType === SYLLABUS_DISPLAY_TYPE.VIEW
            ? true
            : SYLLABUS_TYPES_VALUES.reduce((res, cur) => {
                  res[cur] = null
                  return res
              }, {})
    )

    const [mappedTreeData, setMappedTreeData] = useState([])
    const [displayData, setDisplayData] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const [useColorStyle, setUseColorStyle] = useState(false)

    const [selectedPoints, setSelectedPoints] = useState(selected)
    // console.log('waht is the select in index',selectedPoints)

    const [hash, setHash] = useState('init')
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
    const [selectedForDelete, setSelectedForDelete] = useState(null)
    const [rowData, setRowData] = useState([])
    const apiRef = useGridApiRef()
    const [selectedRowsData, setSelectedRowsData] = useState([])

    useEffect(() => {
        // This refresh when server data refresh
        setDisplayData(cloneDeep(data))
        if (Object.keys(expandStatus).length <= 3) {
            // load expand state from localstorage
            initExpandState()(dispatch)
        }
    }, [data, dispatch])

    useEffect(() => {
        // Local data refresh
        setMappedTreeData(
            mapTreeData(displayData, selectedPoints, completed, completedMap, expandStatus, editId, displayType)
        )
    }, [displayData, expandStatus, editId, selectedPoints])

    useEffect(() => {
        // Update when selected changes
        if (!isEqual(selected, selectedPoints)) {
            setSelectedPoints(selected)
        }
    }, [selected, selectedPoints])

    useEffect(() => {
        setRowData(
            treeNodeList(
                [],
                mappedTreeData,
                0,
                SYLLABUS_TYPES_VALUES.length,
                TREE_NODE_ID_FIELD,
                SYLLABUS_TYPES_VALUES,
                ''
            )
        )
    }, [mappedTreeData])

    useEffect(() => {
        const selectedPointNodeId = selectedPoints.map(value => mappedTreeData.find(e => e.id === value)?.nodeId)
        setSelectedRowsData(selectedPointNodeId)
    }, [selectedPoints])

    const resetEditField = () => {
        // reset the fields being edited
        setEditId(
            isSelectView || displayType === SYLLABUS_DISPLAY_TYPE.VIEW
                ? true
                : SYLLABUS_TYPES_VALUES.reduce((res, cur) => {
                      res[cur] = null
                      return res
                  }, {})
        )
    }

    const onSuccessNoMessage = () => {
        setIsSaving(false)
        resetEditField()
        setSelectedForDelete(null)
    }
    const onSuccess = msg => {
        onSuccessNoMessage()
        sendSuccessMessage(dispatch, msg)
    }

    const onError = msg => {
        setIsSaving(false)
        sendErrorMessage(dispatch, msg)
        setDisplayData(cloneDeep(data))
    }

    const handleExpandChange = event => {
        changeExpandState(event, expandStatus)(dispatch)
    }

    const handleCollapseAll = () => {
        resetExpandState()(dispatch)
    }

    const onSyllabusAdd = async () => {
        const newRecord = {
            name: '',
            description: '',
            notes: '',
            type: SYLLABUS_TYPES.SYLLABUS,
            [TREE_LIST_EDIT_FIELD]: true,
            isNew: true
        }
        let tempData = cloneDeep(data)
        // Put syllabus at the start after the add button, since it's hard to see at the end
        tempData = [newRecord, ...tempData]
        setDisplayData(tempData)
    }

    // Called when an item is being edited and user is inputting text
    const onItemChange = e => {
        if (displayType === SYLLABUS_DISPLAY_TYPE.VIEW) {
            // just viewing
            return
        }

        // Changes when user is trying to add syllabus into class delivery
        if (isSelectView) {
            if (
                (SYLLABUS_DISPLAY_TYPE.SELECT_SYLLABUS && e.level.length === 1) ||
                (SYLLABUS_DISPLAY_TYPE.SELECT_SYLLABUS && e.level.length === 4)
            ) {
                // make sure the user is selecting what they're supposed to select and not anything else
                if (e.value) {
                    // Selecting
                    const newSelection = [...selectedPoints, e.dataItem.id]
                    setSelectedPoints(newSelection)
                    onSelected(newSelection)
                } else {
                    // Deselecting
                    const newSelection = selectedPoints.filter(id => id !== e.dataItem.id)
                    setSelectedPoints(newSelection)
                    onSelected(newSelection)
                }
            } else {
                // protect against illegal usage; eg. change removing display none in css will land here
                sendErrorMessage(dispatch, 'Invalid selection')
                return
            }
        }

        // Changes under normal circumstances
        const levelKey = SYLLABUS_TYPES_VALUES
        const tempData = [...displayData]
        // e.level - Contains the index of the item from root to each individual child,
        //           each index corresponds to each depth
        // e.field - the field being edited; ie. name, description, etc
        // e.value - what the user typed
        try {
            switch (e.level.length) {
                case 1:
                    tempData[e.level[0]][e.field] = e.value
                    break
                case 2:
                    tempData[e.level[0]][levelKey[1]][e.level[1]][e.field] = e.value
                    break
                case 3:
                    tempData[e.level[0]][levelKey[1]][e.level[1]][levelKey[2]][e.level[2]][e.field] = e.value
                    break
                case 4:
                    tempData[e.level[0]][levelKey[1]][e.level[1]][levelKey[2]][e.level[2]][levelKey[3]][e.level[3]][
                        e.field
                    ] = e.value
                    break
                default:
                    break
            }
        } catch (error) {
            sendErrorMessage(dispatch, 'Edit failed due to malformed data', error.message)
        }

        setDisplayData(tempData)
    }

    const onCellEdit = dataItem => {
        // reset previously editing field if any
        const tempEditObject = SYLLABUS_TYPES_VALUES.map(type => ({[type]: null}))
        tempEditObject[dataItem.type] = dataItem.id
        setEditId(tempEditObject)
    }

    const onCellSave = async editedItem => {
        const {isNew, id, name, description, notes, type, parentId} = editedItem
        setIsSaving(true)

        if (name.length > SYLLABUS_MAX_LENGTH) {
            setIsSaving(false)
            return sendErrorMessage(dispatch, `Name cannot be longer than ${SYLLABUS_MAX_LENGTH} characters`)
        }

        if (description.length > SYLLABUS_MAX_LENGTH) {
            setIsSaving(false)
            return sendErrorMessage(dispatch, `Description cannot be longer than ${SYLLABUS_MAX_LENGTH} characters`)
        }

        if (notes.length > SYLLABUS_MAX_LENGTH) {
            setIsSaving(false)
            return sendErrorMessage(dispatch, `Notes cannot be longer than ${SYLLABUS_MAX_LENGTH} characters`)
        }

        // Upload data
        if (isNew) {
            // Usually the last entry in parentId is undefined
            // since it's the newly created object from onCellAdd()
            // so pop() it twice to get the real parentId
            const newParent = parentId.pop() || parentId.pop()
            const newData = {id: newParent, name, description, notes}
            return createSyllabusCell(newData, type, onSuccess, onError)(dispatch)
        }
        const updateData = {id, name, description, notes}
        return updateSyllabusCell(updateData, type, onSuccess, onError)(dispatch)
    }

    // Add a child to the current dataItem
    const onCellAdd = async dataItem => {
        apiRef.current.setRowChildrenExpansion(dataItem[TREE_NODE_ID_FIELD], true)
        const levelKey = SYLLABUS_TYPES_VALUES
        // This dataItem's type in the SYLLABUS_TYPE object
        const levelIndex = levelKey.findIndex(type => type === dataItem.type)
        // Want to add the one after the current index; ie the child of the current object
        const typeToAdd = levelKey[levelIndex + 1]
        const newRecord = {
            name: '',
            description: '',
            notes: '',
            type: typeToAdd,
            [TREE_LIST_EDIT_FIELD]: true,
            isNew: true
        }
        // Contains the index of the item from root to each individual child,
        // each index corresponds to each depth
        const indexKey = dataItem.parent
        const tempData = cloneDeep(data)
        let currentData = []

        // Want to know what the parent is, so can expand it after clicking add
        let parentContainer = {id: null, type: null}
        try {
            switch (indexKey.length) {
                case 1:
                    // Adding Topic
                    parentContainer = {
                        id: tempData[indexKey[0]].id,
                        type: SYLLABUS_TYPES.SYLLABUS
                    }
                    // indexKey[0] - the first level of the actual data we're using
                    // levelKey[1] - start from SYLLABUS_TYPES.TOPICS since we do not add SYLLABUS_TYPES.SYLLABUS this way
                    currentData = tempData[indexKey[0]][levelKey[1]]
                    tempData[indexKey[0]][levelKey[1]] = currentData // Test if this item has child
                        ? [...currentData, newRecord] // It does, so add to the end of the child array
                        : [newRecord] // It does not, so make a new array with it
                    break
                case 2:
                    // Adding Chapter
                    parentContainer = {
                        id: tempData[indexKey[0]][levelKey[1]][indexKey[1]].id,
                        type: SYLLABUS_TYPES.TOPICS
                    }
                    currentData = tempData[indexKey[0]][levelKey[1]][indexKey[1]][levelKey[2]]
                    tempData[indexKey[0]][levelKey[1]][indexKey[1]][levelKey[2]] = currentData
                        ? [...currentData, newRecord]
                        : [newRecord]
                    break
                case 3:
                    // Adding Point
                    parentContainer = {
                        id: tempData[indexKey[0]][levelKey[1]][indexKey[1]][levelKey[2]][indexKey[2]].id,
                        type: SYLLABUS_TYPES.CHAPTERS
                    }
                    currentData = tempData[indexKey[0]][levelKey[1]][indexKey[1]][levelKey[2]][indexKey[2]][levelKey[3]]
                    tempData[indexKey[0]][levelKey[1]][indexKey[1]][levelKey[2]][indexKey[2]][levelKey[3]] = currentData
                        ? [...currentData, newRecord]
                        : [newRecord]
                    break
                default:
                    break
            }

            // open row if not expanded
            await expandFieldState(parentContainer.id, parentContainer.type, expandStatus)(dispatch)
        } catch (e) {
            sendErrorMessage(dispatch, 'Edit failed due to malformed data')
        }
        setDisplayData(tempData)
    }

    const onCellCancel = () => {
        const tempEditObject = SYLLABUS_TYPES_VALUES.map(type => ({[type]: null}))
        setEditId(tempEditObject)
        setDisplayData(cloneDeep(data))
    }

    const onCellDelete = dataItem => {
        setSelectedForDelete(dataItem)
        setOpenDeleteDialogue(true)
        setHash(getBasicHash())
    }

    const handleDelete = async () => {
        await deleteSyllabusCell(selectedForDelete.id, selectedForDelete.type, onSuccess, onError)(dispatch)
    }

    const onCellMove = async (dataItem, isMoveUp) => {
        const levelKey = SYLLABUS_TYPES_VALUES

        // Current depth
        const indexKey = dataItem.parent
        const tempData = cloneDeep(data)

        // Data required to update order
        let allCurrentLevelItems = []
        let parentId
        let orderType

        try {
            switch (indexKey.length) {
                case 1:
                    // Syllabus
                    // Should not be here
                    break
                case 2:
                    // Topic
                    allCurrentLevelItems = tempData[indexKey[0]][levelKey[1]]
                    parentId = tempData[indexKey[0]].id
                    orderType = SYLLABUS_TYPES.TOPICS
                    break
                case 3:
                    // Chapter
                    allCurrentLevelItems = tempData[indexKey[0]][levelKey[1]][indexKey[1]][levelKey[2]]
                    parentId = tempData[indexKey[0]][levelKey[1]][indexKey[1]].id
                    orderType = SYLLABUS_TYPES.CHAPTERS
                    break
                case 4:
                    // Point
                    allCurrentLevelItems =
                        tempData[indexKey[0]][levelKey[1]][indexKey[1]][levelKey[2]][indexKey[2]][levelKey[3]]
                    parentId = tempData[indexKey[0]][levelKey[1]][indexKey[1]][levelKey[2]][indexKey[2]].id
                    orderType = SYLLABUS_TYPES.POINTS
                    break
                default:
                    break
            }

            allCurrentLevelItems = allCurrentLevelItems.filter(item => item.id !== dataItem.id)

            // Should already be in order
            const targetOrder = isMoveUp ? dataItem.order - 1 : dataItem.order + 1
            const newLevelOrdering = []
            newLevelOrdering.push({id: dataItem.id, order: targetOrder})

            let isPast = false
            allCurrentLevelItems.forEach((item, idx) => {
                if (idx === targetOrder) {
                    // where the new item should be, toggle flag on
                    isPast = true
                }
                newLevelOrdering.push({id: item.id, order: isPast ? idx + 1 : idx})
            })

            await reorderSyllabusCell(
                {order: newLevelOrdering},
                parentId,
                orderType,
                onSuccessNoMessage,
                onError
            )(dispatch)
        } catch (e) {
            sendErrorMessage(dispatch, 'Edit failed due to malformed data')
        }
    }

    let sortableContent = document.getElementsByClassName('table-each-row')
    const onCellDrag = dataItem => {
        setUseColorStyle(true)
        sortableContent = document.getElementsByClassName(`table-row-${dataItem.type}`)
        Array.prototype.map.call(sortableContent, list => {
            enableDragList(list)
        })
        setUseColorStyle(false)
    }

    function enableDragList(row) {
        row.setAttribute('draggable', true)
        row.ondragend = handleDrop
    }

    function disableDragList(row) {
        row.setAttribute('draggable', false)
    }

    function handleDrop(row) {
        const selectedRow = row.target
        const selectedItemId = selectedRow.getAttribute('data-id').toString()
        const list = selectedRow.parentNode
        const x = row.clientX
        const y = row.clientY
        try {
            // select target row by the position of pointer
            let swapItem = document.elementFromPoint(x, y) === null ? selectedRow : document.elementFromPoint(x, y)
            while (swapItem.parentNode !== list && swapItem.parentNode !== null) {
                swapItem = swapItem.parentNode
            }
            // if it sits inside of the target
            const checkMatch = swapItem.getAttribute('draggable')
            const swapItemId = swapItem.getAttribute('data-id')
            // if it the target is not inside of the grid
            if (swapItem.parentNode === null || checkMatch === null || !checkMatch || swapItem === selectedRow) {
                Array.prototype.map.call(sortableContent, list => {
                    disableDragList(list)
                    sendErrorMessage(dispatch, 'Not correct position')
                })
            }
            // append the select row into the target position
            else if (list === swapItem.parentNode) {
                swapItem = swapItem !== selectedRow.nextSibling ? swapItem : swapItem.nextSibling
                list.insertBefore(selectedRow, swapItem)
                onCellReorder(swapItemId, selectedItemId)
                Array.prototype.map.call(sortableContent, list => {
                    disableDragList(list)
                })
            }
        } catch (e) {
            sendErrorMessage(dispatch, 'Not correct position')
        }
    }

    function onCellReorder(targetId, originId) {
        const currentLevelItems = []
        const newLevelOrdering = []
        const originItem = apiRef.current.getRow(originId)
        const targetItem = apiRef.current.getRow(targetId)
        const relativePath = targetItem.parentPath.substring(0, targetItem.parentPath.lastIndexOf('/'))
        const parentId = relativePath.substring(relativePath.lastIndexOf('-') + 1, relativePath.length)
        rowData.forEach(item => {
            if (item.parentPath.substring(0, item.parentPath.lastIndexOf('/')) === relativePath) {
                currentLevelItems.push({id: item.id, order: item.order})
            }
        })
        // remove the selected row from current level
        // put it back to the position it goes
        currentLevelItems.splice(originItem.order, 1)
        currentLevelItems.splice(targetItem.order, 0, {id: originItem.id, order: 0})
        // regenerate a new list of current level for updating order
        currentLevelItems.forEach((item, index) => {
            newLevelOrdering.push({id: item.id, order: index})
            item.order = index
        })
        updateOrder(newLevelOrdering, parentId, targetItem.type)
    }

    async function updateOrder(newOrder, parentId, type) {
        try {
            setIsSaving(true)
            await reorderSyllabusCell({order: newOrder}, parentId, type, onSuccessNoMessage, onError)(dispatch)
        } catch (e) {
            sendErrorMessage(dispatch, 'Edit failed due to malformed data')
        }
        setIsSaving(false)
    }

    const onCellAddDelivery = dataItem => onAddDelivery(dataItem.id)

    const CommandEditCell = SyllabusEditCommandCell(
        isSaving,
        TREE_LIST_EDIT_FIELD,
        onCellEdit,
        onCellAdd,
        onCellSave,
        onCellCancel,
        onCellDelete,
        onCellDrag,
        onCellMove
    )

    const CommandAddDeliveryCell = SyllabusAddDeliveryCommandCell(isSaving, TREE_LIST_EDIT_FIELD, onCellAddDelivery)

    const columns = generateColumnsForTreeList(displayType, canEdit, CommandAddDeliveryCell, CommandEditCell)

    const handleSelectionModelChange = event => {
        const selectedRows = event.map(selectedId => mappedTreeData.find(row => row.nodeId === selectedId))
        const selectedId = selectedRows.map(item => item?.id)
        setSelectedPoints(selectedId)
        onSelected(selectedId)
        setSelectedRowsData(event)
    }

    return (
        <Paper elecation={0} style={{paddingBottom: '16px'}}>
            <AppBar position="sticky" color="default">
                <Tabs value={currentTab} onChange={handleChangeTab}>
                    {displayType === SYLLABUS_DISPLAY_TYPE.EDIT && canEdit && (
                        <Tab label="Add new syllabus" className="k-button k-primary" onClick={onSyllabusAdd} />
                    )}
                    <Tab
                        label="Collapse All"
                        className="k-button"
                        onClick={e => {
                            e.preventDefault()
                            handleCollapseAll()
                        }}
                    />
                    <Tab
                        label="Colour Code"
                        className="k-button"
                        onClick={e => {
                            e.preventDefault()
                            setUseColorStyle(!useColorStyle)
                        }}
                    />
                </Tabs>
            </AppBar>
            <Box
                sx={{
                    height: `${totalHeight - 80}px`,
                    width: 'auto',
                    m: 2,
                    '& .MuiDataGrid-cell--editable': {
                        bgcolor: theme => (theme.palette.mode === 'dark' ? '#95bfb4' : 'rgb(217 243 190)')
                    },
                    '& .table-row-type-syllabus': {
                        bgcolor: () => (useColorStyle ? 'rgb(224,224,255)' : 'rgb(255 255 255 )')
                    },
                    '& .table-row-type-topics': {
                        bgcolor: () => (useColorStyle ? 'rgb(254,255,235)' : 'rgb(255 255 255)')
                    },
                    '& .table-row-type-chapters': {
                        bgcolor: () => (useColorStyle ? 'rgb(255,233,233)' : 'rgb(255 255 255 )')
                    },
                    '& .table-row-type-points': {
                        bgcolor: () => (useColorStyle ? 'rgb(231,255,254)' : 'rgb(255 255 255 )')
                    },
                    '& .MuiDataGrid-row': {
                        border: 'none',
                        marginTop: theme => theme.spacing(0.2),
                        marginBottom: theme => theme.spacing(0.2)
                    }
                }}
            >
                <DataGridPro
                    style={{
                        height: `${totalHeight - 100}px`,
                        overflow: 'auto'
                    }}
                    className={clsx(classes.normalTree, {
                        [classes.colorTree]: useColorStyle,
                        [classes.syllabusTree]: displayType === SYLLABUS_DISPLAY_TYPE.SELECT_SYLLABUS,
                        [classes.pointTree]: displayType === SYLLABUS_DISPLAY_TYPE.SELECT_POINT
                    })}
                    // disableSelectionOnClick
                    checkboxSelection
                    treeData
                    data={mappedTreeData}
                    rows={rowData}
                    apiRef={apiRef}
                    getTreeDataPath={row => row.parentPath?.split('/')}
                    getRowId={row => row[TREE_NODE_ID_FIELD]}
                    getRowClassName={params => `table-row-type-${params.row.type}
                    table-row-${params.row.type}`}
                    isCellEditable={dataItem => dataItem.row[TREE_LIST_EDIT_FIELD]}
                    editField={TREE_LIST_EDIT_FIELD}
                    expandField={TREE_LIST_EXPAND_FIELD}
                    subItemsField={TREE_LIST_CHILD_FIELD}
                    onItemChange={onItemChange}
                    columns={columns}
                    onExpandChange={handleExpandChange}
                    scrollable="virtual"
                    rowHeight={40}
                    onSelectionModelChange={handleSelectionModelChange}
                    selectionModel={selectedRowsData}
                />
            </Box>

            <ResponsiveDialog
                isOpen={openDeleteDialogue}
                openHash={hash}
                title="Are you sure?"
                content={`Delete ${
                    selectedForDelete?.type === SYLLABUS_TYPES.SYLLABUS
                        ? selectedForDelete?.type
                        : selectedForDelete?.type.slice(0, -1)
                } "${selectedForDelete?.name}"?`}
                Buttons={[
                    {name: 'Yes', event: () => handleDelete()},
                    {name: 'Cancel', event: () => setSelectedForDelete(null)}
                ]}
            />
        </Paper>
    )
}

export default SyllabusTreeView
