import React, {useEffect, useRef, useState} from 'react'
import * as echarts from 'echarts/core'
import {LineChart, BarChart, PieChart} from 'echarts/charts'
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    ToolboxComponent
} from 'echarts/components'
import {LabelLayout, UniversalTransition} from 'echarts/features'
import {CanvasRenderer} from 'echarts/renderers'
// import IconButton from '@mui/material/IconButton'
// import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import {Box} from '@mui/material'
import {useSelector} from 'react-redux'
import BIStackedLineDiagramOptions from '../configOptions'
import BIChartContainer from '../../mui/bi/BIChartContainer'
import LocalStorage from '../../../utils/LocalStorage'
import {LocalStorageItems} from '../../../constants'

const styles = {
    BIContainer: {
        width: '100%',
        height: '80vh',
        position: 'relative'
    },
    refreshButton: {
        position: 'absolute',
        top: '-4px',
        right: '30px',
        zIndex: '100'
    }
}

function BIContractStackedLineDiagram({diagramTitle, yAxisValueName}) {
    const [eChartsInstance, setEChartsInstance] = useState(null)
    const biDiagramRef = useRef()

    // store and return eCharts true-legends
    const legendsLocalStorage = allLegends => {
        localStorage.setItem('legendObject', JSON.stringify(allLegends))
    }

    // store current chart type
    const chartTypeLocalStorage = chartType => {
        localStorage.setItem('chartType', chartType)
    }

    // load eCharts tooltips
    useEffect(() => {
        echarts.use([
            BarChart,
            PieChart,
            LineChart,
            TitleComponent,
            TooltipComponent,
            GridComponent,
            DatasetComponent,
            TransformComponent,
            LabelLayout,
            UniversalTransition,
            CanvasRenderer,
            LegendComponent,
            ToolboxComponent
        ])
    }, [])

    const [isPieChart, setIsPieChart] = useState(false)

    useEffect(() => {
        setEChartsInstance(echarts.init(biDiagramRef.current))
    }, [])

    const allBIData = useSelector(state => state.bi.allBIData)

    // load eCharts data config
    useEffect(() => {
        if (allBIData && eChartsInstance) {
            eChartsInstance.on('legendselectchanged', params => {
                legendsLocalStorage(params.selected)
                loadEChats()
            })
            eChartsInstance.on('magictypechanged', params => {
                setIsPieChart(false)
                chartTypeLocalStorage(params.currentType)
                loadEChats()
            })
            eChartsInstance.on('updateAxisPointer', event => {
                const xAxisInfo = event.axesInfo[0]
                if (xAxisInfo) {
                    const dimension = xAxisInfo.value
                    eChartsInstance.setOption({
                        series: {
                            id: 'pie',
                            label: {
                                formatter: `{b}: {@[${dimension}]} ({d}%)`
                            },
                            encode: {
                                value: dimension,
                                tooltip: dimension
                            }
                        }
                    })
                }
            })

            if (isPieChart) {
                chartTypeLocalStorage('pie')
                loadEChats()
            }

            eChartsInstance.clear()
            const biConfig = BIStackedLineDiagramOptions(diagramTitle, allBIData, yAxisValueName, setIsPieChart)
            eChartsInstance.setOption(biConfig)
            eChartsInstance.on('legendselectchanged', e => {
                LocalStorage.setObjectItem(LocalStorageItems.BiLegendSelected, e?.selected)
            })
        }
    }, [allBIData, yAxisValueName, isPieChart])

    // load eCharts data config on legend selected
    const loadEChats = () => {
        if (allBIData && eChartsInstance) {
            eChartsInstance.clear()
            const biConfig = BIStackedLineDiagramOptions(diagramTitle, allBIData, yAxisValueName, setIsPieChart)
            eChartsInstance.setOption(biConfig, true)
        }
    }

    // set eCharts to be responsive
    useEffect(() => {
        if (eChartsInstance) {
            eChartsInstance.resize()
            window.addEventListener(
                'resize',
                () => {
                    eChartsInstance.resize()
                },
                false
            )
            return () => window.removeEventListener('resize', () => {})
        }
        return undefined
    }, [eChartsInstance])

    return (
        <Box sx={styles.BIContainer}>
            {/* <IconButton sx={styles.refreshButton}> */}
            {/*    <RefreshOutlinedIcon /> */}
            {/* </IconButton> */}
            <BIChartContainer ref={biDiagramRef} />
        </Box>
    )
}

export default BIContractStackedLineDiagram
