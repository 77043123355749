import React, { useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import NavBar from "./NavBar";
import TopBar from "./TopBar";



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        },
        transition: theme.transitions.create('padding', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    wrapperShift:{
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        transition: theme.transitions.create('padding', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

function DashboardLayout({ children }) {
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const [isDeskNavOpen,setDeskOpen]=useState(true);



    return (
        <div className={classes.root}>
            <TopBar
                onMobileNavOpen={() => setMobileNavOpen(true)}
                onDeskOpen={()=>setDeskOpen(pre=>!pre)}
            />
            <NavBar
                onMobileNavOpen={() => setMobileNavOpen(true)}
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
                openDesk={isDeskNavOpen}
            />
            <div className={clsx({[classes.wrapperShift]:!isDeskNavOpen,[classes.wrapper]:isDeskNavOpen})}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default DashboardLayout
