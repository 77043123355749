import {useEffect} from 'react';
import NProgress from "nprogress";
import 'nprogress/nprogress.css';
import './LoadingProgress.css';

function LoadingProgress() {
    useEffect(() => {
        NProgress.start();
        // let i = 0;
        // const timeid = setInterval(()=>{
        //     i = i+0.01
        //     NProgress.set(i);
        //     if(i>1){
        //         i=0;
        //     }
        // },50)
        return () => {
            // clearInterval(timeid)
            NProgress.done()
        };
    }, [])

    return null;
}

export default LoadingProgress;