import React from 'react';
import { Button, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ViewHead from '../../../../../components/public/ViewHead';
import { Functions } from '../../../../../utils/Functions';
import { sendSuccessMessage } from '../../../../../actions';
import TopUpAccountEditor from './TopUpAccountEditor';

function AddAccountView() {
  const  navigate = useNavigate();
  const dispatch = useDispatch()

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/matric/topup/accounts`)
    }, 1000)
  }

  const renderBackButton = () =>
    <Button color='secondary'
            variant='contained'
            onClick={() => navigate('/app/matric/topup/accounts')}>
      Back
    </Button>

  return (
    <ViewHead functionId={Functions.Matric_TopUp_Add_Account} noMaxWidth topButtons={renderBackButton()}>
      <Paper elevation={0}>
        <TopUpAccountEditor onSubmitSuccess={onSuccess} />
      </Paper>
    </ViewHead>
  )
}

export default AddAccountView
