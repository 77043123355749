import _ from 'lodash';
import { employeeActions } from '../../actions';

export const employeeReducer = (state = initialState, action = {}) => {
  const {type, payload} = action;
  switch (type) {
    case employeeActions.SetEmployeeFormStatus:
      if (payload === "Close") {
        return {
          ...state,
          employeeFormStatus: payload,
          currentEditEmployee: undefined
        }
      } 
        return { ...state, employeeFormStatus: payload }
      
    case employeeActions.CreateEmployee: // create one
      return {...state, success: true, employeeFormStatus: 'Close',error: undefined }
    case employeeActions.Searching:
      return {...state,isSearching: action.payload,error: undefined}
    case employeeActions.ContinueCreate:
      return {...state,error: undefined,isSearching: false}
    case employeeActions.DuplicateEmail:
      return {...state,success: false,error:action.payload,isSearching: false}
    case employeeActions.FetchEmployees: // read ALL
      return {...state, employees: payload, error: undefined}
    case employeeActions.FetchEmployeesPerPage:
      return {...state, employeesPerPage: payload, error: undefined}
    case employeeActions.FetchOneEmployee:
      return {...state, currentEmployee: payload,error: undefined}
    case employeeActions.UpdateEmployee:
      return {...state, employeeFormStatus: 'Close',error: undefined}
    case employeeActions.UpdateStaffWeChat:
      return {...state, staffInfo: action.payload, employeeFormStatus: 'Close'}
    case employeeActions.UpdateStaffInfo:
      return {...state, employeeFormStatus: 'Close'}
    case employeeActions.UpdateStaffRole:
      return {...state, employeeFormStatus: 'Close'}
    case employeeActions.DeleteEmployee:
      return {...state, delStaffId: payload, employeeFormStatus: 'Close'}
    case employeeActions.DeleteCategory:
      return {...state, deleteId: payload}
    case employeeActions.ActiveEmployees:
      return {...state, activationMessage: payload }
    case employeeActions.FetchEmployeeProps:
      return {...state, employeeFetchBody: payload}
    case employeeActions.SetCurrentEditEmployee:
      return _.chain(state)
        .cloneDeep()
        .set("currentEditEmployee", action.payload)
        .value()
    // case employeeActions.UpdateEmployee:
    default:
      return state
  }
}

const initialState = {
  // newEmployee:  undefined,
  isSearching: undefined,
  error: undefined,
  success: undefined,
  employees: undefined,
  employeesPerPage:{},
  employeeFormStatus: "Close",
  currentEditEmployee: undefined,
  currentEmployee: undefined,
  staffInfo: {},
  deleteId:'',
  delStaffId: '',
  activationMessage: undefined,
  employeeFetchBody: {}
}
export default employeeReducer