const colors = {
    black: '#000000',
    white: '#FFFFFF',
    primaryLight: '#6298CE',
    primaryLightHighlight: '#427DB6',
    primaryMain: '#245461',
    primaryDark: '#1A414B',
    secondaryLight: '#F5F7F8',
    secondaryHighlight: '#E9EAEA',
    secondaryMain: '#ACACAC',
    secondaryDark: '#C5C5C5',
    tertiaryMain: '#6298CE',
    tertiaryHighlight: '#5380c9',
    tertiaryLight: '#427DB6',
    tertiaryDark: '#72abe5',
    tertiaryBackground: '#F3F3F3',
    baseBackground: '#E8E7E7',
    inputBackground: '#FFFFFF',
    bannerBackground: 'linear-gradient(20deg, #5299D3 0%, #6298CE 30%, #4B9E7C 100%)',
    bannerBackgroundTop: 'linear-gradient(to top, #4B9E7C 0%, #6298CE 60%, #5299D3 100%)',
    bannerBackgroundDown: 'linear-gradient(to bottom, #4B9E7C 0%, #6298CE 60%, #5299D3 100%)',
    dividerBackground: 'linear-gradient(to left, #6095CA, #245461)'
};

export default colors
