export const UserValidator = {
    password: {
        minLength: {
            value: 6,
            message: 'Current Password must be at least 6 characters'
        },
        required: "Current Password is required"
    },
    newPassword:{
        pattern: {
            value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
            message: 'New Password is too simple'
        },
        maxLength:{
            value:20,
            message:"New Password must be no more than 20 characters"
        },
        minLength:{
            value:8,
            message: "New Password must be at least 8 characters"
        },
        required:"New Password is required",
    },
    confirmedPassword: {
        required:"Confirm Password is required",
    }
}


export const SMSPhoneChecker = (value) =>
  value && value.match(/^(00|\+)[1-9]{1}([0-9][\s]*){9,16}$/)

export const EmailChecker = (value) =>
  value && value.match(/^\S+@\S+\.\S+$/)
