import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import ViewHead from '../../../../components/public/ViewHead';
import {
  downloadUnsignedContractPdf,
  fetchContract, fetchEmailAccount,
  sendContractEmail,
} from '../../../../actions/administrative/administrativeAction';
import ContractDisplayLoader from './ContractDisplayLoader';
import LoadingScreen from '../../../../components/public/LoadingScreen';
import { sendErrorMessage, sendSuccessMessage } from '../../../../actions';
import { useContractViewStyles } from '../contractHelper';
import { ServerBaseURL } from '../../../../constants';

function OneContractView({isDialog= false,loaded = false}) {

  useCurrentViewCheck(Functions.Administrative_Contract_View)
  const currentContract =useSelector(state=>state.administrative.currentContract)
  // const currentContract = !!currentContractPassedIn ? currentContractPassedIn : currentContractFromSelector
  const  navigate = useNavigate();

  const {uid} = useParams()
  const {cid} = useParams()
  const dispatch = useDispatch()
  const classes = useContractViewStyles()
  const currentEmail = useSelector(state => state.administrative.currentEmailAccount)

  const [isLoaded, setIsLoaded] = useState(loaded)
  const [isContractLoaded, setIsContractLoaded] = useState(false)
  const [isEmailLoaded, setIsEmailLoaded] = useState(false)
  const [emailText, setEmailText] = useState('Send to Customer as Default LMS')
  const [isSending, setIsSending] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const loadData = async () => {
    if(!!uid && !!cid){
     setIsContractLoaded(await fetchContract(uid, cid)(dispatch))
    }else{
      setIsContractLoaded( !!currentContract)
    }
  }
  useEffect(loadData, [])

  useEffect(() => {
    const onRun = async () => {
      setIsContractLoaded(!!currentContract)
      if (isContractLoaded ) {
        if (currentContract?.head?.emailAccountId) {
          setIsEmailLoaded(await fetchEmailAccount(currentContract?.head?.emailAccountId)(dispatch))
        } else {
          setIsEmailLoaded(true)
        }
      }
    }
    onRun().then()
  }, [isContractLoaded,currentContract])

  useEffect(() => {
    if (isEmailLoaded) {
      if (currentContract?.head?.emailAccountId) {
        setEmailText(`Send to Customer as ${currentEmail.name}`)
      } else {
        setEmailText('Send to Customer as Default LMS')
      }
    }
  }, [isEmailLoaded, currentEmail])

  useEffect(() => {
     setIsLoaded(!!currentContract)
  }, [currentContract])

  const onEmail = () => {
    setIsSending(true)
    sendContractEmail({ uid: parseInt(currentContract?.customer?.id,10), cid: currentContract?.cid, serverUrl: ServerBaseURL },
      (data) => {
        sendSuccessMessage(dispatch, `Contract sent to ${data?.data?.to}`)
        setIsSending(false)
        setIsSent(true)
      },
      (msg) => {
        sendErrorMessage(dispatch, msg)
        setIsSending(false)
      },
    )(dispatch)
  }

  const onDownloadUnsigned = () => {
    setIsSending(true)
    downloadUnsignedContractPdf(currentContract?.cid, parseInt(currentContract?.customer?.id,10), currentContract.name,
      `${currentContract.customer.firstName}_${currentContract.customer.lastName}`,
      () => setIsSending(false),
      (msg) => {
        sendErrorMessage(dispatch, msg)
        setIsSending(false)
      },
    )(dispatch)
  }

  const renderBackButton = () => (
      <div className={classes.marginBottom}>
        {
          !isLoaded || currentContract?.isMain
            ?
            <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate('/app/administrative/contract')}>
              Back to All Contracts
            </Button>
            :
            <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate(`/app/administrative/contract/detail/${currentContract?.customer?.id}/${currentContract.mainContract.cid}`)}>
              Back to contract detail
            </Button>
        }
      </div>
    )

  const buttons = () => (
      <div>
        { ['created', 'sent'].includes(currentContract?.status)
          ?
          <Grid container justifyContent='center' spacing={4} className={classes.submitButtonArea}>
            <Grid item>
              <Button color='primary'
                      variant='outlined'
                      disabled={isSending}
                      onClick={() => navigate(`/app/administrative/contract/edit/${currentContract?.customer?.id}/${currentContract?.cid}`)}>
                Edit
              </Button>
            </Grid>
            <Grid item>
              <Button color='primary'
                      variant='outlined'
                      disabled={isSending}
                      onClick={() => onDownloadUnsigned()}>
                Download Unsigned PDF
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title={currentContract?.head?.emailAccountId
                ? `${currentEmail.mailAccount} → ${currentContract.email}`
                : `Send to ${currentContract.email}`} placement='top'>
                <Button color='secondary'
                        variant='outlined'
                        disabled={isSending || isSent}
                        onClick={() => onEmail()}>
                  { isSent ? 'Email Sent' : isSending ? 'Sending Contract' : emailText }
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          :
          <Grid container justifyContent='center' spacing={4} className={classes.submitButtonArea}>
            <Grid item>
              <Button color='primary'
                      variant='contained'
                      disabled={isSending}
                      onClick={() => onDownloadUnsigned()}>
                Download Unsigned PDF
              </Button>
            </Grid>
          </Grid>
        }
      </div>


    )

  const renderContract = () =>
    <div>
      {!isDialog ? <Typography variant='h3' className={classes.marginBottom} color='textPrimary'>
        Viewing { currentContract?.isMain ? 'Contract' : 'Subcontract' }: { currentContract?.cid }
      </Typography>: <div />}

      { !isDialog ? renderBackButton() :''}
      {buttons()}
      <Container className={classes.container}>
        <ContractDisplayLoader contract={currentContract} />
        <Typography variant='body2' style={{ color: 'gray' }}>
          The above Party B signature is for verification purposes only and will not be sent to the customer in email.
        </Typography>
      </Container>
      {buttons()}
    </div>

  return (
    <div>
      {!isDialog ?
        <ViewHead functionId={Functions.Administrative_Contract_View}
                  isShowTitle={false}>
          {
            !!currentContract && isLoaded ?
              renderContract()
              : <LoadingScreen message="Loading..." />
          }
        </ViewHead>
        :
        <div>
          {
            !!currentContract && isLoaded  ?
              renderContract()
              :<LoadingScreen message="Loading..." />

          }
        </div>

      }

    </div>

  )
}

export default OneContractView
