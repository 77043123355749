import React, { useEffect, useState } from 'react';
import { Divider, FormControl, Grid, NativeSelect, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { addInputToPreview } from './formBuilderFormManipulator';
import {
  FormCreatorValidator,
  VALID_INPUT_TYPES
} from './formBuilderData';
import AddInputDialogue from './formBuilderAddInputDialogue';
import { getInputName } from './formClonerHelper';

const useStyles = makeStyles(() => ({
  inputAddButton: {
    transform: 'translate(0,-6px)'
  },
  formControl: {
    marginBottom: '2rem'
  },
  formDivider: {
    margin: '2rem'
  },
  inputName: {
    display: 'inline-block',
    marginTop: '5px',
    marginRight: '10px'
  }
}))

const schema = yup.object().shape(FormCreatorValidator);
function FormBuilderFormBody({formData, setFormData, formComponents, setFormComponents}) {

  const classes = useStyles()
  const [inputSelected, setInputSelected] = useState('text')
  const [forceReset, setForceReset] = useState(0)
  const [currentAddress,setCurrentAddress] = useState({city:'',country:''})
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  })

  const handleAddressCallBack = data =>{
    setCurrentAddress(()=>data)
  }
  const setCurrentCity = e => {
    setCurrentAddress({...currentAddress,city:e.target.value})
  }

  const setCurrentCountry = e => {
    setCurrentAddress({...currentAddress,country:e.target.value})
  }

  useEffect(()=> {

    const temp = []
    formData.forEach((v,i)=> {
      if( v.tag === 'city' || v.tag === 'country'){
      temp.push(i)}
    })
    const component = [...formComponents]
    temp.forEach(v=>{ component[v] = addInputToPreview(formData[v],handleAddressCallBack,currentAddress,setCurrentCity,setCurrentCountry)})
    setFormComponents(()=>[...component])
  },[currentAddress])



  const onCreateInputSubmit = (formDetails) => {

    const tempFormDetails = formDetails
    const temp = []

    if (tempFormDetails.minLength || tempFormDetails.maxLength) {
      if (tempFormDetails.minLength === '') tempFormDetails.minLength = 0
      if (tempFormDetails.maxLength === '') tempFormDetails.maxLength = 250

      if (tempFormDetails.minLength > tempFormDetails.maxLength) {
        setError('minLength', { type: 'manual', message: 'Min length must be smaller than max length' })
        return
      }

      // Only test for normal text field inputs
      if (tempFormDetails.inputType === VALID_INPUT_TYPES.text) {
        if (tempFormDetails.minLength > 255) {
          setError('minLength', { type: 'manual', message: 'Min length must be less than 255' })
          return
        }
        if (tempFormDetails.maxLength > 255) {
          setError('maxLength', { type: 'manual', message: 'Max length must be less than 255' })
          return
        }
      }
    }

    // File Min/Max Size
    if (tempFormDetails.inputType === VALID_INPUT_TYPES.file) {
      if (tempFormDetails.minSize === '') tempFormDetails.minSize = 0
      if (tempFormDetails.maxSize === '') tempFormDetails.maxSize = 5

      if (tempFormDetails.minSize > tempFormDetails.maxSize) {
        setError('minSize', { type: 'manual', message: 'Min size must be smaller than max size' })
        return
      }

      if (tempFormDetails.minSize > 20) {
        setError('minSize', { type: 'manual', message: 'Min size cannot be larger than 20mb' })
        return
      }

      if (tempFormDetails.maxSize > 20) {
        setError('maxSize', { type: 'manual', message: 'Max size cannot be larger than 20mb' })
        return
      }
    }


    // Add name using label, uniqueId to keep the inputs in order
    tempFormDetails.name = getInputName()

    if(tempFormDetails.inputType === 'address'){


      if(formDetails.isAddressAdded){
        temp.push({
          inputType:'text',
          name:getInputName(),
          isRequired:formDetails.isAddressRequired,
          label:formDetails.addressLabel,
          description:formDetails.addressDescription,
          tag:'address',
          patternMessage:'Not valid',
          pattern:"",
          maxLength: 250,
          minLength: 1

        })
      }
      if(formDetails.isCountryAdded){
        temp.push({
          inputType:'text',
          name:getInputName(),
          isRequired:formDetails.isCountryRequired,
          label:formDetails.countryLabel,
          description:formDetails.countryDescription,
          tag:'country',
          patternMessage:'Not valid',
          pattern:"",
          maxLength: 250,
          minLength: 1
        })
      }
      if(formDetails.isCityAdded){
        temp.push({
          inputType:'text',
          name:getInputName(),
          isRequired:formDetails.isCityRequired,
          label:formDetails.cityLabel,
          description:formDetails.cityDescription,
          tag:'city',
          patternMessage:'Not valid',
          pattern:"",
          maxLength: 250,
          minLength: 1
        })
      }

    }

    // If this form has value components (ie. select, radio, check),
    // then generate values objects
    if ('values' in formDetails) {
      const tempNames = formDetails.values.split('\n')
      const tempValues = []

      // Ignore if line is empty
      tempNames.forEach(tn => {
        if (tn.trim() !== '') {
          let isDefault = false
          let tempName = tn.trim();
          if (tempName.substring(0, 1) === '-') {
            isDefault = true
            tempName = tn.substring(1).trim()
          }

          tempValues.push({
            name: getInputName(),
            value: tempName,
            isDefaultValue: isDefault
          })
        }
      })
      tempFormDetails.values = tempValues
    }


    if(formDetails.inputType === 'address'){

      setFormData([...formData, ...temp])
      setFormComponents([...formComponents, ...(temp.map(detail=>addInputToPreview(detail,handleAddressCallBack,currentAddress,setCurrentCity,setCurrentCountry)))])
      setForceReset(forceReset + temp.length)

    }else{
      setFormData([...formData, tempFormDetails])
      setFormComponents([...formComponents, addInputToPreview(tempFormDetails)])
      setForceReset(forceReset + 1)
    }



  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}><Divider className={classes.formDivider}/></Grid>

      <Grid item xs={12}>
        <Typography variant='body1' className={classes.inputName}>Input:</Typography>
        <FormControl className={classes.formControl}>
          <NativeSelect
            value={inputSelected}
            onChange={(e) => setInputSelected(e.target.value)}
          >
            <option value='text'>Text</option>
            <option value='address'>AddressWayTwo</option>
            <option value='textarea'>Text Area</option>
            <option value='select'>Select</option>
            <option value='check'>Check</option>
            <option value='radio'>Radio</option>
            <option value='file'>File</option>
          </NativeSelect>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <AddInputDialogue
          key={forceReset}
          formType={inputSelected}
          onCreateInputSubmit={onCreateInputSubmit}
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          isEdit={false}
        />
      </Grid>
    </Grid>
  )
}

export default FormBuilderFormBody
