
import {LinearProgress} from "@mui/material";
import React from "react";
import {GridOverlay} from "@mui/x-data-grid";

export default function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress/>
            </div>
            {/* Add bottom linear progressor, ZIndex set to 1 to ensure it on top of the horizontal scroll bar */}
            <div style={{ position: 'absolute', bottom: 0, zIndex:1, width: '100%' }}>
                <LinearProgress/>
            </div>
        </GridOverlay>

    );
}
