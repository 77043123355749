import React, {useEffect, useRef, useState} from 'react'
import {useLocation, matchPath, Link as RouterLink} from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {Box, debounce, Divider, Drawer, Hidden, InputAdornment, List, SvgIcon, TextField} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {Users as UsersIcon} from 'react-feather'
import {useDispatch, useSelector} from 'react-redux'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import {ExpandLess, ExpandMore} from '@mui/icons-material'
import Collapse from '@mui/material/Collapse'
import SearchIcon from '@mui/icons-material/Search'
import Logo from '../../../components/public/Logo'
import {Functions} from '../../../utils/Functions'
import getMenuIcon from '../../../utils/MenuIcon'
import {fetchSystemMenu} from '../../../actions'
import NavUserInfoBox from './NavUserInfoBox'
import HelpBox from './HelpBox'
import NavItem from './NavItem'

function renderNavItems({items, pathname, depth = 0, inputValue}) {
    return <List>{items.reduce((acc, item) => reduceChildRoutes({acc, item, pathname, depth, inputValue}), [])}</List>
}

function reduceChildRoutes({acc, pathname, item, depth, inputValue}) {
    const key = item.title + depth

    let Icon = UsersIcon
    if (item.icon) {
        Icon = getMenuIcon(item.icon)
    }
    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: 'false'
        })

        acc.push(
            <NavItem
                depth={depth}
                icon={Icon}
                info={item.info}
                key={key}
                open={Boolean(open)}
                title={item.title}
                searchValue={inputValue}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        )
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={Icon}
                info={item.info}
                key={key}
                title={item.title}
                searchValue={inputValue}
                active={item.href === pathname}
            />
        )
    }
    return acc
}

const useStyles = makeStyles(theme => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    itemText: {
        fontWeight: 'bolder',
        color: theme.palette.text.secondary
    },
    searchBackground: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        zIndex: '100000',
        position: 'fixed',
        top: '0',
        left: '0',
        backgroundColor: 'rgba(0,0,0,0.7)',
        [theme.breakpoints.down('lg')]: {
            display: 'none'
        }
    },
    searchBar: {
        backgroundColor: 'rgb(255,255,255)',
        borderRadius: '4px',
        height: '200px',
        paddingTop: '60px',
        boxSizing: 'border-box'
    },
    searchRes: {
        backgroundColor: 'rgb(255,255,255)',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        minWidth: '234px'
    },
    transparentPaper: {
        backgroundColor: 'transparent',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: -1
    },
    sideBarSearch: {
        display: 'none',
        [theme.breakpoints.down('xl')]: {
            display: 'block'
        }
    },
    searchContainer: {
        width: '700px',
        height: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '10px',
        backgroundColor: 'rgb(255,255,255)'
    }
}))

function NavBar({onMobileClose, onMobileNavOpen, openDesk, openMobile}) {
    const classes = useStyles()
    const location = useLocation()
    const iRef = useRef(null)
    const dispatch = useDispatch()

    const [collapse, setCollapse] = useState({})
    const [iDisplay, setIDisplay] = useState(false)
    const [textValue, setTextValue] = useState('')
    const [filterMenu, setFilterMenu] = useState([])

    const [displayMenu, setDisplayMenu] = useState([])

    const menu = useSelector(state => state.auth?.menu)
    const functionValues = Object.values(Functions)

    useEffect(() => {
        fetchSystemMenu()(dispatch)
    }, [fetchSystemMenu])

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose()
        }
    }, [location.pathname])

    useEffect(() => {
        // only want to display the menu that are within Functions
        const tempDisplayMenu = menu.filter(item => functionValues.includes(item.functionId))
        setDisplayMenu(tempDisplayMenu)

        const collapseLocal = localStorage.getItem('collapse')
        const obj = JSON.parse(collapseLocal)
        if (obj) {
            setCollapse(obj)
        } else if (Array.isArray(tempDisplayMenu)) {
            const constructor = {}
            tempDisplayMenu.forEach(item => {
                constructor[item.subheader] = false
            })
            setCollapse(constructor)
        }
    }, [menu])

    const handleCollapse = subheader => {
        const newObj = {...collapse, [subheader]: !collapse[subheader]}
        setCollapse(newObj)
        localStorage.setItem('collapse', JSON.stringify(newObj))
    }

    let pressCount = 0
    let escPress = 0

    const resetSearch = () => {
        pressCount = 0
        escPress = 0
        setFilterMenu(displayMenu)
        setIDisplay(false)
        setTextValue('')
    }

    useEffect(() => {
        const handleCtrl = e => {
            if (pressCount !== 0 && e.shiftKey) {
                pressCount += 1
            } else if (e.shiftKey) {
                pressCount += 1
                setTimeout(() => {
                    pressCount = 0
                }, 300)
            }
            if (e.key !== 'Shift') {
                pressCount = 0
            }

            if (pressCount === 2) {
                if (!openMobile) {
                    onMobileNavOpen()
                    setIDisplay(true)
                }

                if (iDisplay) {
                    const textLength = textValue.length * 2

                    if (iRef.current != null) {
                        iRef.current.focus()
                        iRef.current.setSelectionRange(textLength, textLength)
                    }
                } else setIDisplay(true)
                // return true;
            }
            if (e.key === 'Escape') {
                // pressCount = 0
                escPress += 1
                // setFilterMenu(displayMenu)
                // onMobileClose()
                // setIDisplay(false)
                setTextValue('')
                setTimeout(() => {
                    escPress = 0
                }, 500)
                if (escPress === 2 && e.key === 'Escape') {
                    resetSearch()
                    onMobileClose()
                    // return false;
                }
            }
            // } else
            //   return false;
        }

        window.addEventListener('keydown', handleCtrl)
        // window.addEventListener('keydown', handleInput);

        return () => {
            window.removeEventListener('keydown', handleCtrl)
            // window.removeEventListener('keydown', handleInput);
        }
    })

    useEffect(() => {
        searchTitle(textValue)
    }, [displayMenu])

    const searchTitle = temptText => {
        if (displayMenu.length > 0) {
            // todo: commenting out this for now
            // const filterMenuTemp = displayMenu.filter(item => item.subheader.toLowerCase().includes(temptText.toLowerCase()));

            const subMenu = []
            for (let i = 0; i < displayMenu.length; i += 1) {
                const sub = displayMenu[i].items.filter(item =>
                    item.title.toLowerCase().includes(temptText.toLowerCase())
                )
                if (sub.length > 0) {
                    subMenu.push(displayMenu[i])
                }
            }

            // todo commenting out this for now
            // subMenu.map(item => {
            //     if (!filterMenuTemp.includes(item)) {
            //         return [...filterMenuTemp, item]
            //     }
            // })
            setFilterMenu(subMenu)
        }
    }

    const onSearch = debounce(tempt => {
        setTextValue(tempt)
        searchTitle(tempt)
    }, 500)

    const handleDisplay = header => {
        if (iDisplay) {
            for (let i = 0; i < displayMenu.length; i += 1) {
                if (displayMenu[i].subheader === header) {
                    const filterItem = displayMenu[i].items.filter(item =>
                        item.title.toLowerCase().includes(textValue.toLowerCase())
                    )
                    if (displayMenu[i].items.length >= filterItem.length) {
                        return true
                    }
                }
            }
        }
        return false
    }

    function enterPress() {
        if (document.getElementById('enterId') != null) {
            document.getElementById('enterId').click()
        }
    }

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{suppressScrollX: true}}>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="center">
                        <RouterLink to="/">
                            <Logo size="small" width="50" />
                        </RouterLink>
                    </Box>
                </Hidden>
                <NavUserInfoBox />
                <Divider />
                <Box p={2}>
                    <TextField
                        className={classes.sideBarSearch}
                        type="search"
                        inputRef={iRef}
                        variant="outlined"
                        size="small"
                        autoFocus
                        onChange={e => onSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SvgIcon fontSize="small" color="action">
                                        <SearchIcon size={20} />
                                    </SvgIcon>
                                </InputAdornment>
                            )
                        }}
                    />

                    {filterMenu.length > 0 &&
                        filterMenu
                            .filter(
                                item =>
                                    // only show not matrix function
                                    item.appId !== 1
                            )
                            .map(section => (
                                <List key={section.subheader}>
                                    <ListItem
                                        button
                                        onClick={() => {
                                            handleCollapse(section.subheader)
                                        }}
                                    >
                                        <ListItemText
                                            className={classes.itemText}
                                            secondary={section.subheader}
                                            id="listItemText666"
                                        />
                                        {collapse[section.subheader] || handleDisplay(section.subheader) ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        )}
                                    </ListItem>
                                    <Collapse
                                        in={collapse[section.subheader] || handleDisplay(section.subheader)}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List>
                                            {renderNavItems({
                                                items: section.items,
                                                pathname: location.pathname,
                                                inputValue: textValue
                                            })}
                                        </List>
                                    </Collapse>
                                </List>
                            ))}
                </Box>
                <Divider />
                <HelpBox />
            </PerfectScrollbar>
        </Box>
    )

    const search = iDisplay ? (
        <Box className={classes.searchBackground}>
            <div
                aria-hidden="true"
                onClick={() => {
                    resetSearch()
                }}
                className={classes.transparentPaper}
            />
            <Box className={classes.searchContainer}>
                <Box className={classes.searchBar}>
                    <TextField
                        size="small"
                        type="search"
                        inputRef={iRef}
                        variant="outlined"
                        autoFocus
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                enterPress()
                            }
                        }}
                        onChange={e => onSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SvgIcon fontSize="small" color="action">
                                        <SearchIcon size={80} />
                                    </SvgIcon>
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
                <Box className={classes.searchRes}>
                    {filterMenu.length > 0 &&
                        filterMenu
                            .filter(
                                item =>
                                    // only show not matrix function
                                    item.appId !== 1
                            )
                            .map(section => (
                                <List key={section.subheader}>
                                    <ListItem
                                        button
                                        onClick={() => {
                                            handleCollapse(section.subheader)
                                        }}
                                    >
                                        <ListItemText className={classes.itemText} secondary={section.subheader} />
                                        {collapse[section.subheader] || handleDisplay(section.subheader) ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        )}
                                    </ListItem>
                                    <Collapse
                                        in={collapse[section.subheader] || handleDisplay(section.subheader)}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List
                                            onClick={() => {
                                                resetSearch()
                                            }}
                                        >
                                            {renderNavItems({
                                                items: section.items,
                                                pathname: location.pathname,
                                                inputValue: textValue
                                            })}
                                        </List>
                                    </Collapse>
                                </List>
                            ))}
                </Box>
            </Box>
        </Box>
    ) : undefined

    return (
        <div>
            {search}
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.mobileDrawer}}
                    onClose={() => {
                        onMobileClose()
                        setTextValue('')
                        setFilterMenu(displayMenu)
                    }}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>

            {/* NOTE: original value: xlDown; This fixed the bug appears when viewpoint is between 1200px to 1535px. */}
            <Hidden lgDown>
                <Drawer anchor="left" classes={{paper: classes.desktopDrawer}} open={openDesk} variant="persistent">
                    {content}
                </Drawer>
            </Hidden>
        </div>
    )
}

// NavBar.propTypes = {
//     onMobileClose: PropTypes.func,
//     openMobile: PropTypes.bool
// };

export default NavBar
