import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import ViewHead from '../../../../components/public/ViewHead';
import { fetchQRCodeSet } from '../../../../actions/qrcode/qrcodeAction';
import QRCodeEditor from './QRCodeEditor';
import { sendSuccessMessage } from '../../../../actions';

function CreateQRCodeView() {
  useCurrentViewCheck(Functions.QRCode_Code_Add)
  const  navigate = useNavigate();
  const setId = parseInt(useParams().setId,10)

  const dispatch = useDispatch()
  const currentQRCodeSet = useSelector(state => state.qrcode.currentQRCodeSet)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    (async() => {
      setIsLoaded(await fetchQRCodeSet(setId)(dispatch))
    })()
  }, [setId])

  const onSubmitSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/qr/view/${setId}`)
    }, 1000)
  }

  const renderBackButton = () =>
    <Button color='secondary'
            variant='contained'
            onClick={() => navigate(`/app/qr/view/${setId}`)}>
      Back
    </Button>


  return (
    <ViewHead functionId={Functions.QRCode_Code_Add} topButtons={renderBackButton()}>
      {
        isLoaded
          ?
          <QRCodeEditor isEdit={false} qrCodeSet={currentQRCodeSet} onSubmitSuccess={onSubmitSuccess}/>
          :
          <Typography variant='h2'>Loading...</Typography>
      }
    </ViewHead>
  )
}

export default CreateQRCodeView
