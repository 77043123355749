import React, {useEffect, useState} from 'react'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Hidden,
    Link,
    Typography,
    useMediaQuery,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import InstagramIcon from '@mui/icons-material/Instagram';
import {Image} from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import facebookIcon from "../../assets/images/social-facebook.svg";
import wechatIcon from "../../assets/images/social-wechat.svg";
import linkedInIcon from "../../assets/images/social-in.svg";



const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.primary.dark,
        marginTop: 50,
        padding: "3rem 3rem 5rem 1rem"
    },
    gridRoot: {
        margin: 10,
    },
    media: {
        height: 84,
        width: 300,
    },
    logoContent: {
        textAlign: 'left',

    },
    socialMedia: {
        textAlign: 'left',

        lineHeight: "1.5",
        marginLeft: 10,
    },
    text: {
        color: theme.palette.primary.dark,
        textAlign: 'left',

    },
    textButton: {
        textTransform: "none",
        "&:hover": {
            cursor: 'pointer',
        }
    },
    aboutTitle: {
        fontWeight: "normal",

    },
    smGutter: {
        [theme.breakpoints.down('xl')]: {
            marginTop: "2rem"
        }
    },
    icons: {
        transform: "translateX(-10px)",

    },
    divider: {
        backgroundColor: theme.palette.primary.dark,
        margin: "20px 0",
    },
    contactUs: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 10,
        }
    },
    popTitle: {
        color: theme.palette.primary.dark,
        fontSize: "1.5rem"
    },
    popBg:{
        position: "absolute",
        left:0,
        width:"100%",
        height:"100vh",
        backgroundColor:'rgba(0,0,0,.6)',
        zIndex:3000,
    },
    popWindow:{
        zIndex:3001,
        position: "absolute",
        top:"50%",
        left:"50%",
        transform:"translate(-50%,-50%)",
        width:600,
        backgroundColor:'white',
        boxShadow:theme.shadows[3],
        borderRadius:5,
        [theme.breakpoints.down('xl')]:{
            width:"90%",
        },
    }
}));

function Footer() {
    const  navigate = useNavigate();
    const matchesXs = useMediaQuery('(max-width:600px)');
    const classes = useStyles();
    const [counter, setCounter] = useState(0);
    const [open, setOpen] = useState(false);

    const goLogin = () => {
        if (counter > 5) {
            navigate('/auth/login')
        }
    }
    useEffect(goLogin, [counter])
    const openDisclaimer = () => {
        setOpen(true)
    }


    return (
        <div className={classes.root}>
            <Divider variant="middle" className={classes.divider}/>
            <Hidden smUp>
                <Image animationDuration={0} src="/static/images/cslogo.svg" aspectRatio={(310 / 58.2)}
                       imageStyle={{width: 240, height: 'auto', position: 'relative'}}
                       style={{margin: "10px 10px", paddingTop: 0,}}
                />
            </Hidden>

            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}
                  className={classes.gridRoot}>
                <Grid item xs={12} sm={2} md={2} className={classes.smGutter}>
                    <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start" display="rows">
                        <Grid item xs={10} sm={12}>
                            <Typography variant='h3' className={classes.aboutTitle} gutterBottom>
                                Quick Links
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={12}>
                            <Typography variant='body1' className={classes.text}>
                                <Link href="#" target="_blank" underline="none">
                                    Home Page
                                </Link>
                            </Typography>
                            <Typography variant='body1' className={classes.text}>
                                <Link href="#" target="_blank" underline="none">
                                    Projects
                                </Link>
                            </Typography>
                            <Typography variant='body1' className={classes.text}>
                                <Link href="#" target="_blank" underline="none">
                                    Core Services
                                </Link>
                            </Typography>
                            <Typography variant='body1' className={classes.text}>
                                <Link href="#" target="_blank" underline="none">
                                    About Us
                                </Link>
                            </Typography>
                            <Typography variant='body1' className={classes.text}>
                                <Link href="#" target="_blank" underline="none">
                                    Our Team
                                </Link>
                            </Typography>
                            <Typography variant='body1' className={classes.text}>
                                <Link href="#" target="_blank" underline="none">
                                    Newsfeed
                                </Link>
                            </Typography>
                            <Typography variant='body1' className={classes.text}>
                                <Link href="#" target="_blank" underline="none">
                                    Contact Us
                                </Link>
                            </Typography>
                            <Typography variant='body1' className={classes.text}>
                                <Link href="https://www.insiderbroker.com/" target="_blank" underline="none">
                                    Inside Broker
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={3} md={3} className={classes.smGutter}>
                    <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start" display="rows">
                        <Grid item xs={10} sm={12}>
                            <Typography variant='h3' className={classes.aboutTitle} gutterBottom>
                                About ITLabPro
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={12}>
                            <Typography variant='body2' className={classes.text} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.smGutter}>
                    <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start" display="rows"
                          className={classes.contactUs}>
                        <Grid item xs={10} sm={12}>
                            <Typography variant='h3' className={classes.aboutTitle} gutterBottom>
                                Contact Us
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={12}>
                            <Typography variant='body2' className={classes.text} />
                            <Typography variant='body2' className={classes.text}>
                                Markham 
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={12}>
                            <Typography variant='body2' className={classes.text}>
                                Tel: 
                            </Typography>
                            <Typography variant='body2' className={classes.text}>
                                <Link color="inherit" href='mailto://info@ITLabPro.io' target="_blank"
                                      underline="none">
                                    info@ITLabPro.io
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>


                </Grid>
                <Hidden xlDown>
                    <Grid item sm={3} md={3} className={classes.smGutter}>

                        <img src="/static/images/google.svg" width="90%" alt="logo" style={{minWidth: 166}}/>

                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent={matchesXs ? 'flex-start' : 'flex-end'}
                          style={{marginTop: 25, paddingRight: 15}}>
                        <Grid item>
                            <Grid container direction="row" justifyContent="flex-start">
                                <Grid item>
                                    <Link color="inherit" href="#"
                                          target="_blank" underline="none">
                                        <InstagramIcon className={classes.socialMedia} fontSize="small"/>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link color="inherit" href="#"
                                          target="_blank"
                                          underline="none">
                                        <img className={classes.socialMedia} src={facebookIcon} alt="facebook"
                                             style={{height: 18}}/>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link color="inherit" href="/static/images/qr-wechat.png" target="_blank"
                                          underline="none">
                                        <img className={classes.socialMedia} src={wechatIcon} alt="wechat"
                                             style={{height: 18}}/>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link color="inherit"
                                          href="#"
                                          target="_blank" underline="none">
                                        <img className={classes.socialMedia} src={linkedInIcon} alt="LinkedIn"
                                             style={{height: 18}}/>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Divider variant="middle" className={classes.divider}/>

            <Grid container direction="row" justifyContent="space-between" width="100%">
                <Grid item xs={6}>
                    <Typography variant='body2' className={classes.text} onClick={() => {
                        setCounter(counter + 1);
                    }}>
                        Copyright © ITLabPro
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Typography variant='body2' align="right" className={classes.text}>
                                <Link color="inherit" href='#' target="_blank" underline="none">
                                    Privacy Policy
                                </Link>
                                {'  '}
                                <span onClick={openDisclaimer} role="presentation" className={classes.textButton}>
                                    Disclaimer
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            {open &&
            <div className={classes.popBg} style={{top:window.scrollY}}>
                <div className={classes.popWindow}>
                    <DialogTitle classes={{root: classes.popTitle}}>
                        Disclaimer
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant='body2' className={classes.text} />
                        <Typography variant='body2' className={classes.text} />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={() => {
                                setOpen(false)
                            }}
                            disableRipple
                            className={classes.text}>
                            Close
                        </Button>
                    </DialogActions>
                </div>
            </div>
            }
        </div>
    );

}


export default Footer


