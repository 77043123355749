import QRCode from 'qrcode';
import api from "../../apis/api";
import { sendErrorMessage } from '../message/messageActions';
import { QR_CODE_CONFIG, frontEndUrl } from '../../constants';
import { pagedGridDefaultFetch } from '../../components/mui/datagrid/PagedGridAction';

export const QRCodeActionType = {
  FetchAllQRCodeSets: "FetchAllQRCodeSets",
  FetchQRCodeSet: "FetchQRCodeSet",
  FetchQRCodeSetCodes: "FetchQRCodeSetCodes",
  FetchQRCodeCategories: "FetchQRCodeCategories",
  FetchQRCodeUsers: "FetchQRCodeUsers",
  FetchQRCodeDetails: "FetchQRCodeDetails",
  GenerateQRCodeImage: "GenerateQRCodeImage",

  SetSetFetchProps: "SetSetFetchProps",
  SetListFetchProps: "SetListFetchProps",
}

export const fetchQRCodeCategories = () => async (dispatch) => {
  try {
    const res = await api.get(`/qr/category`)
    if (res.data?.rs) {
      dispatch({
        type: QRCodeActionType.FetchQRCodeCategories,
        payload: res.data.data
      })
      return true
    }
    // console.log(res.data?.message)
    return false
  } catch (e) {
    sendErrorMessage(dispatch,'Error in fetchQRCodeCategories')
    // console.log('Could not get qr code categories\n', e)
    return false
  }
}

export const deleteQRCodeCategory = (id, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.delete(`/qr/category/${id}`)
    if (res.data?.rs) {
      await dispatch(fetchQRCodeCategories())
      return onSuccess('Category deleted')
    }
    return onError(`Failed: ${res.data?.message}`)
  } catch (e) {
    // console.log('Category delete error:\n', e)
    return onError('Category cannot be deleted.')
  }
}

export const updateQRCodeCategory = (id, newName, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.put('/qr/category', { id, name: newName })
    if (res.data?.rs) {
      await dispatch(fetchQRCodeCategories())
      return onSuccess('Category Updated')
    }
    return onError(`Failed: ${res.data?.message}`)
  } catch (e) {
    // console.log('Category update error:\n', e)
    return onError('Category cannot be updated.')
  }
}

export const addQRCodeCategory = (name, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/qr/category', { name })
    if (res.data?.rs) {
      await dispatch(fetchQRCodeCategories())
      return onSuccess('Category created')
    }
    return onError(`Failed: ${res.data.message}`)
  } catch (e) {
    // console.log('Category create error:\n', e)
    onError('Category cannot be created.')
  }
  return false
}

export const fetchQRCodeUsers = () => async (dispatch) => {
  try {
    const res = await api.get('/qr/users')
    if (res.data?.rs) {
      dispatch({ type: QRCodeActionType.FetchQRCodeUsers, payload: res.data.data })
      return true
    }
  } catch (e) {
    // console.log('Failed to fetch users\n', e)
  }
  sendErrorMessage(dispatch, 'Failed to fetch users')
  return false
}

export const fetchQRCodeSet = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/qr/set/${id}`)
    if (res.data?.rs) {
      dispatch({ type: QRCodeActionType.FetchQRCodeSet, payload: res.data.data })
      return true
    }
  } catch (e) {
    // console.log('QR Code Set fetch failed\n', e)
  }
  sendErrorMessage(dispatch, 'Cannot fetch QR Code Set')
  return false
}

export const fetchQRCodeSetCodes = (args) => async (dispatch) => {
  try {
    const res = await pagedGridDefaultFetch(`/qr/set/codes/${args.setId}`, args,
      QRCodeActionType.SetListFetchProps)(dispatch)
    if (res.data?.rs) {
      dispatch({ type: QRCodeActionType.FetchQRCodeSetCodes, payload: res.data })
      return true
    }
  } catch (e) {
    // console.log('QR Code Set fetch failed\n', e)
  }
  sendErrorMessage(dispatch, 'Cannot fetch QR Code Set')
  return false
}

export const fetchAllQRCodeSets = (args) => async (dispatch) => {
  try {
    const res = await pagedGridDefaultFetch('/qr/fetchAllSets', args,
      QRCodeActionType.SetSetFetchProps)(dispatch)
    if (res.data?.rs) {
      dispatch({ type: QRCodeActionType.FetchAllQRCodeSets, payload: res.data })
      return true
    }
  } catch (e) {
    // console.log('QR Code Sets fetch failed\n', e)
  }
  sendErrorMessage(dispatch, 'Cannot fetch QR Code Sets')
  return false
}

export const editQRCodeSet = (data, onSuccess, onError) => async () => {
  try {
    const res = await api.post('/qr/set', data)
    if (res.data?.rs) {
      return onSuccess(res.data)
    }
    return onError(res.data?.message || 'QR Code Set cannot be created')
  } catch (e) {
    // console.log('QR Code Set cannot be created\n', e)
    return onError(e.message)
  }
}

export const updateQRCodeSet = (setId, data, onSuccess, onError) => async () => {
  try {
    const res = await api.put(`/qr/set/${setId}`, data)
    if (res.data?.rs) {
      return onSuccess(res.data)
    }
    return onError(res.data?.message || 'QR Code Set cannot be updated')
  } catch (e) {
    // console.log('QR Code Set cannot be updated\n', e)
    return onError(e.message)
  }
}

export const deleteQRCodeSet = (id, fetchProps, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.delete(`/qr/set/${id}`)
    if (res.data?.rs) {
      await dispatch(fetchAllQRCodeSets(fetchProps))
      return onSuccess('QR Code Set Deleted')
    }
    return onError('QR Code list cannot be refreshed')
  } catch (e) {
    // console.log('QR Code Set delete error:\n', e)
    return onError('QR Code Set cannot be deleted.')
  }
}

export const changeSetStatus = (ids, isActive, fetchProps, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/qr/updateStatus', { isActive, ids })
    if (res.data) {
      await dispatch(fetchAllQRCodeSets(fetchProps))
      return onSuccess(isActive ? 'QR Code Set(s) activated' : 'QR Code Set(s) disabled')
    }
  } catch (e) {
    // console.log('QR Code Set update error:\n', e)
    return onError('QR Code Set status cannot be changed')
  }
  return false
}

export const generateQRCodeImage = (sid, cid, uid) => async (dispatch) => {
  try {
    const destUrl = `${frontEndUrl}/qr/${sid}/${cid}/${uid}`
    const image = await QRCode.toDataURL(destUrl, QR_CODE_CONFIG)
    await dispatch({ type: QRCodeActionType.GenerateQRCodeImage, payload: { id: cid, image } })
    return true
  } catch (e) {
    // console.log('Unable to generate QR Code', e)
    sendErrorMessage(dispatch,'Error in generateQRCodeImage')
  }
  return false
}

export const generateQRCodeLink = (sid, cid, uid) => `${frontEndUrl}/qr/${sid}/${cid}/${uid}`

export const fetchQRCode = (setId, codeId) => async (dispatch) => {
  try {
    const res = await api.get(`/qr/code/${setId}/${codeId}`)
    if (res.data?.rs) {
      dispatch({ type: QRCodeActionType.FetchQRCodeDetails, payload: res.data.data })
      return true
    }
  } catch (e) {
    // console.log('QR Code fetch failed\n', e)
  }
  sendErrorMessage(dispatch, 'Cannot fetch QR Code')
  return false
}

export const createQRCode = (data, onSuccess, onError) => async () => {
  try {
    const res = await api.post(`/qr/code`, data)
    if (res.data?.rs) {
      return onSuccess('QR Code created')
    }
    return onError(res.data?.message || 'QR Code cannot be created')
  } catch (e) {
    // console.log('Failed to create QR Code', e)
    return onError(e.message)
  }
}

export const updateQRCode = (data, onSuccess, onError) => async () => {
  try {
    const res = await api.put(`/qr/code`, data)
    if (res.data?.rs) {
      return onSuccess('QR Code updated')
    }
    return onError(res.data?.message || 'QR Code cannot be updated')
  } catch (e) {
    // console.log('Failed to update QR Code', e)
    return onError(e.message)
  }
}

export const deleteQRCode = (sid, cid, fetchProps, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.delete(`/qr/code/${sid}/${cid}`)
    if (res.data?.rs) {
      await dispatch(fetchQRCodeSetCodes(fetchProps))
      return onSuccess('QR Code deleted')
    }
    return onError(res.data?.message || 'QR Code cannot be deleted')
  } catch (e) {
    // console.log('Failed to delete QR Code', e)
    return onError(e.message)
  }
}

export const scanQRCode = (data, onSuccess, onError) => async () => {
  try {
    const res = await api.post(`/asset/qr/scan`, data)
    if (res.data?.rs) {
      return onSuccess(res.data.data)
    }
    return onError(res.data?.message || 'QR Code Unavailable')
  } catch (e) {
    return onError(e.message)
  }
}
