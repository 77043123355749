import React, {useState, useEffect} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import lodash, {debounce} from 'lodash'
import Autocomplete from '@mui/material/Autocomplete'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import {
    Grid,
    Paper,
    Typography,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Switch,
    TextField
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import IconButton from '@mui/material/IconButton'
import {Edit as EditIcon, Eye, Clipboard} from 'react-feather'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import {useNavigate} from 'react-router-dom'
import {copyToClipboard} from '../../../utils/Scripts'
import {
    deleteHistory,
    fetchClassHistory,
    fetchOneClassHistory,
    readOneClassHistory,
    fetchClass,
    fetchClasses,
    updateClass,
    fetchCurrentClassForEdit,
    addClassTransferRecord,
    fetchClassTransferByClassId,
    transferStudent
} from '../../../actions/class/classActions'
import useAuthCheck from '../../../hooks/useAuthCheck'
import {Functions} from '../../../utils/Functions'
import LXGrid from '../../../components/mui/datagrid/XGrid'
import CheckCustomerFloat from '../../customer/register/CheckCustomerFloat'
import EditCustomerFloat from '../../customer/register/EditCustomerFloat'
import AddClass from './AddClass'
import DraggableDialog from '../../../components/mui/dialog/DraggableDialog'
import SearchBox from '../../../components/public/SearchBox'

let studentDetails = ''

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    icons: {
        zIndex: '10'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    dataGrid: {
        width: '100%',
        marginBottom: theme.spacing(2),
        height: '80vh'
    },
    table: {
        minWidth: 750
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    editIcon: {
        cursor: 'pointer'
    },
    tableCell: {
        width: 80,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px'
    },
    funcCell: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    add: {
        '& > span': {
            margin: theme.spacing(2)
        }
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalPaper: {
        width: '80%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    modalTitle: {
        padding: theme.spacing(1, 0)
    },
    floatingWindow: {
        width: '80%',
        height: '80%',
        overflow: 'scroll',
        backgroundColor: 'white',
        border: '1px solid black',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '9999'
    },
    link: {
        '&:hover': {
            textDecoration: 'none'
        }
    },
    search: {
        position: 'relative',
        marginButton: theme.spacing(1),
        width: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    groupButton: {
        marginLeft: theme.spacing(1)
    },
    buttons: {
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            '& .MuiGrid-item': {flexGrow: '1', padding: '0', paddingTop: '10px'}
        }
    }
}))

function StudentIncluded({isEdit, oneClass, customers, classesData, fetchCurrentClassForEdit}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isRead] = useAuthCheck([Functions.Training_Class_Class_Details_Read_Feedback])
    const isLoading = false
    const [openEdit, setOpenEdit] = useState(false)
    const handleCopy = async () => {
        copyStudentsInfo()
    }

    const [isopen, setOpen] = useState(false)
    const [isOpenChangeClass, setIsOpenChangeClass] = useState(false)
    const [checkedAll, setCheckedAll] = useState(true)
    const [showStudentDetail, setShowStudentDetail] = useState(null) // the id of student details in floating window
    const [customerFloatOpen, setCustomerFloatOpen] = useState(false)
    const [customerEditFloatOpen, setCustomerEditFloatOpen] = useState(false)
    const allClassesInfo = classesData?.map(classInfo => ({
        ...classInfo,
        displayName: `${classInfo.id}. ${classInfo.name}`
    }))
    const [selectedStudentsInfo, setSelectedStudentsInfo] = useState([])
    const currentClassStudentsInfo = oneClass?.data?.students.map(student => ({
        ...student,
        displayName: `${student.id}. ${student.firstName} ${student.lastName}`
    }))
    // const [isSaving, setIsSaving] = useState(false)
    // const catchError = () => setIsSaving(false)
    // const afterSaving = () => setIsSaving(false)

    const catchError = () => {}
    const afterSaving = () => {}

    const [selectedClassToChange, setSelectedClassToChange] = useState()
    const [oneClassStudentList, setOneClassStudentList] = useState(oneClass?.data?.students)
    const otherClassInfo = allClassesInfo?.filter(classInfo => classInfo.id !== oneClass?.data?.id)
    const [changeClassReason, setChangeClassReason] = useState(null)
    const [hasFieldToCopy, setHasfieldToCopy] = useState(true)

    const updateClassStudentList = async (stuListToRemove, classIdToTransfer) => {
        if (stuListToRemove && stuListToRemove.length > 0) {
            const currentClass = lodash.find(classesData, {id: oneClass?.data?.id})
            let currentStudentList = lodash.map(currentClass?.students, 'id')

            stuListToRemove.forEach(eachRecordToRemove => {
                currentStudentList = currentStudentList.filter(id => id !== eachRecordToRemove.id)
            })

            if (classIdToTransfer && classIdToTransfer?.id !== oneClass?.data?.id) {
                const classToTransferInfo = lodash.find(classesData, {id: classIdToTransfer.id})
                let classToTransferStudentList = lodash.map(classToTransferInfo?.students, 'id')
                classToTransferStudentList = lodash.concat(
                    classToTransferStudentList,
                    lodash.map(stuListToRemove, 'id')
                )

                const studentTransferData = {
                    currentClassId: currentClass?.id,
                    currentStudentList,
                    classToTransferId: classToTransferInfo?.id,
                    classToTransferStudentList,
                    selectedStudentsInfo,
                    notes: changeClassReason,
                    repId: auth?.user?.id
                }
                transferStudent(studentTransferData, afterSaving, catchError)(dispatch)
            }
        }
    }

    const changeToOnHold = async stuListToRemove => {
        if (stuListToRemove && stuListToRemove.length > 0) {
            const currentClass = lodash.find(classesData, {id: oneClass?.data?.id})
            let currentStudentList = lodash.map(currentClass?.students, 'id')

            stuListToRemove.forEach(eachRecordToRemove => {
                currentStudentList = currentStudentList.filter(id => id !== eachRecordToRemove.id)
            })

            const studentTransferData = {
                currentClassId: currentClass?.id,
                currentStudentList,
                selectedStudentsInfo,
                notes: changeClassReason,
                repId: auth?.user?.id,
                changeToOnHold: true
            }
            transferStudent(studentTransferData, afterSaving, catchError)(dispatch)
        }
    }

    const setChildStudentId = id => {
        setShowStudentDetail(id)
        setCustomerFloatOpen(true)
    }
    const setEditChildStudentId = id => {
        setShowStudentDetail(id)
        setCustomerEditFloatOpen(true)
    }
    const [selectedStudents, setSelectedStudents] = useState([])
    const [selectedStudent, setSelectedStudent] = useState([])
    const [searchArgs, setSearchArgs] = useState({searchKey: undefined})

    useEffect(() => {
        const selectedStudentArr = []
        selectedStudents.forEach(studentId => {
            selectedStudentArr.push(currentClassStudentsInfo.find(eachStudent => eachStudent.id === studentId))
        })
        setSelectedStudentsInfo(selectedStudentArr)
    }, [selectedStudents])

    const initializeSelectedStudentInfo = () => {
        const selectedStudentArr = []
        selectedStudents.forEach(studentId => {
            selectedStudentArr.push(currentClassStudentsInfo.find(eachStudent => eachStudent.id === studentId))
        })
        setSelectedStudentsInfo(selectedStudentArr)
    }
    const auth = useSelector(state => state.auth)

    let template = []
    const defaultValue = {}
    const defaultCheckAll = {}
    const defaultUncheckAll = {}
    const toRemove = ['files', 'category', 'notes', 'vcode', 'updateAt', 'isCustomer', 'userStatus']
    let filteredTemplate = []
    const customerData = useSelector(state => state?.customer?.customers)
    if (customerData && customerData.length > 0) {
        template = Object.keys(customerData[0])
        filteredTemplate = template.filter(trashColumn => !toRemove.includes(trashColumn))
        filteredTemplate = filteredTemplate.filter(trashColumn => trashColumn.slice(0, 3) !== 'reg')
        filteredTemplate.sort()
        const changePosition = (arr, value, indexToSwitch) => {
            let tempData = ''
            const valueIndex = arr.indexOf(value)
            if (arr.indexOf(value) !== -1) {
                tempData = arr[indexToSwitch]
                arr[indexToSwitch] = value
                arr[valueIndex] = tempData
            }
        }
        const usefulKeys = [
            'id',
            'firstName',
            'lastName',
            'nickName',
            'age',
            'email',
            'gmail',
            'phone',
            'wechat',
            'gitName',
            'city',
            'address',
            'country'
        ]

        usefulKeys.map((key, index) => changePosition(filteredTemplate, key, index))
        changePosition(filteredTemplate, 'createdAt', filteredTemplate.length - 2)
        changePosition(filteredTemplate, 'updatedAt', filteredTemplate.length - 1)

        filteredTemplate.forEach(key => {
            if (usefulKeys.indexOf(key) !== -1) {
                defaultValue[key] = true
            } else {
                defaultValue[key] = false
            }
            defaultCheckAll[key] = true
            defaultUncheckAll[key] = false
        })
    }
    const handleSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase()
        setSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }

    const handleFetchClass = async id => {
        await fetchCurrentClassForEdit(id)
    }

    const copyStudentsInfo = () => {
        const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

        if (selectedStudent.length > 0) {
            studentDetails = customers
                ? selectedStudent?.map(studentId => customers.find(customer => customer.id === studentId))
                : null
        } else {
            studentDetails = customers
                ? selectedStudents?.map(studentId => customers.find(customer => customer.id === studentId))
                : null
        }

        const caseKeys = Object.keys(cases)
        const chosenAttribute = []
        caseKeys.forEach(value => {
            if (cases[value] === true) {
                chosenAttribute.push(value)
            }
        })
        let string = ''
        if (studentDetails) {
            studentDetails.forEach(detail => {
                chosenAttribute.forEach(value => {
                    if (columnNameNeeded === true) {
                        if (value === 'sales') {
                            string = `${string} ${capitalizeFirstLetter(value)} : ${detail[value]?.firstName}${
                                detail[value]?.lastName
                            },`
                        } else if (value === 'category') {
                            string = `${string} ${capitalizeFirstLetter(value)} : ${detail[value]?.name},`
                        } else {
                            string = `${string} ${capitalizeFirstLetter(value)} : ${detail[value]},`
                        }
                    } else {
                        if (detail[value] === null) {
                            return
                        }
                        if (value === 'sales') {
                            string = `${string} ${detail[value]?.firstName}${detail[value]?.lastName},`
                        } else if (value === 'category') {
                            string = `${string} ${detail[value]?.name},`
                        } else {
                            string = `${string} ${detail[value]},`
                        }
                    }
                })
                string = `${string.slice(0, string.length - 1)};\n`
                if (chosenAttribute.length === 0) {
                    string = ''
                }
            })
        }
        setSelectedStudent([])
        setOpen(false)
        copyToClipboard(dispatch, string, 'students value added to clipboard', 'value cannot be copied')
    }
    const handleCheckAll = () => {
        if (checkedAll === false) {
            setCheckedAll(!checkedAll)
            setCases(defaultCheckAll)
        } else {
            setCheckedAll(!checkedAll)
            setCases(defaultUncheckAll)
        }
    }
    const handleCaseChange = event => {
        setHasfieldToCopy(false)
        const object = {...cases}
        object[event.target.name] = event.target.checked
        lodash.mapValues(object, value => {
            if (value === true) {
                setHasfieldToCopy(true)
            }
        })
        setCases(object)
    }

    const searchHandler = debounce(searchVal => {
        handleSearchChange(searchVal)
        // onSearch(searchVal)
    }, 500)

    useEffect(() => {
        let searchResult = []
        setOneClassStudentList(oneClass?.data?.students)
        searchResult = oneClass?.data?.students.filter(stu => {
            const info = (
                stu.id +
                stu.firstName +
                stu.nickName +
                stu.lastName +
                stu.wechat +
                stu.phone +
                stu.email
            ).trim()
            return lodash.includes(info, searchArgs.searchKey)
        })
        if (searchArgs?.searchKey !== undefined && searchArgs?.searchKey !== '') {
            setOneClassStudentList(searchResult)
        }
    }, [searchArgs])

    const [cases, setCases] = useState(defaultValue)
    const [columnNameNeeded, setNameNeeded] = useState(true)
    const handleSwitchChange = event => {
        setNameNeeded(event.target.checked)
    }
    const columns = [
        {
            field: '_actionField',
            headerName: 'Actions',
            resizable: false,
            disableClickEventBubbling: true,
            filterable: false,
            width: 110,
            renderCell: params => (
                <div style={{position: 'relative', width: '100%'}}>
                    <div className={classes.funcCell}>
                        {isRead && (
                            <Tooltip title="Read" placement="top">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    size="small"
                                    onClick={() => {
                                        setChildStudentId(params.row.id)
                                    }}
                                >
                                    <Eye size={15} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isEdit && (
                            <Tooltip title="Edit" placement="top">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    size="small"
                                    onClick={() => {
                                        setEditChildStudentId(params.row.id)
                                    }}
                                >
                                    <EditIcon size={15} />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title="Copy Form Value" placement="bottom">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() => {
                                    setSelectedStudent([])
                                    setSelectedStudent([params.row.id])
                                    setOpen(true)
                                }}
                            >
                                <Clipboard size={15} className={classes.copyButton} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            )
        },
        {field: 'id', headerName: 'Id', width: 150, sortable: true},
        {field: 'name', headerName: 'Name', width: 250, sortable: false},
        {field: 'email', headerName: 'Email', width: 250, sortable: false},
        {field: 'phone', headerName: 'Phone', width: 250, sortable: false},
        {field: 'wechat', headerName: 'WeChat', width: 250, sortable: false}
    ]

    const rows = oneClass
        ? oneClassStudentList?.map(student => {
              let name = ''
              if (student.nickName !== '') {
                  name = `${student.firstName}(${student.nickName}) ${student.lastName}`
              } else {
                  name = `${student.firstName} ${student.lastName}`
              }
              return {
                  id: student.id,
                  name,
                  email: student.email,
                  phone: student.phone,
                  wechat: student.wechat
              }
          })
        : []
    const data = {columns, rows}

    useEffect(() => {
        setHasfieldToCopy(false)
        lodash.mapValues(cases, value => {
            if (value === true) {
                setHasfieldToCopy(true)
            }
        })
    }, [cases])

    if (isLoading) {
        return <div />
    }

    return (
        <div>
            <div className={classes.root}>
                <Typography variant="h5" color="textPrimary">
                    Student Included &nbsp;&nbsp;
                </Typography>
                {isEdit && (
                    <Tooltip title="Add Student" placement="top">
                        <IconButton
                            size="small"
                            onClick={async () => {
                                await handleFetchClass(oneClass?.data?.id)
                                await setOpenEdit(true)
                            }}
                        >
                            <AddCircleIcon color="primary" fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title="Copy Form Value" placement="bottom">
                    <IconButton
                        color="primary"
                        size="small"
                        component="span"
                        disabled={selectedStudents.length <= 0}
                        onClick={() => setOpen(true)}
                    >
                        <Clipboard size={20} className={classes.copyButton} />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Change Class" placement="bottom">
                    <IconButton
                        color="primary"
                        size="small"
                        component="span"
                        disabled={selectedStudents.length <= 0}
                        onClick={() => {
                            initializeSelectedStudentInfo()
                            setIsOpenChangeClass(true)
                            setSelectedClassToChange(null)
                            setChangeClassReason(null)
                        }}
                    >
                        <TransferWithinAStationIcon size={15} className={classes.copyButton} />
                    </IconButton>
                </Tooltip>
            </div>
            <Grid item>
                <Paper className={classes.paper}>
                    <Grid item xs={12}>
                        <div className={classes.search}>
                            <SearchBox
                                placeholder="Search..."
                                size="small"
                                type="search"
                                searchHandler={searchHandler}
                            />
                        </div>
                    </Grid>
                    {data ? (
                        <div>
                            <LXGrid
                                {...data}
                                pagination
                                totalHeight={800}
                                checkboxSelection
                                searchArgs={searchArgs}
                                disableSelectionOnClick
                                sortModel={[{field: 'id', sort: 'asc'}]}
                                onCellDoubleClick={GridCellParams => {
                                    navigate(`/app/customer/registered/edit/${GridCellParams.id}`)
                                    setOpen(false)
                                }}
                                onSelectionModelChange={newSelectionModel => {
                                    setSelectedStudents(newSelectionModel)
                                }}
                            />
                        </div>
                    ) : null}
                </Paper>
            </Grid>
            <DraggableDialog
                open={Boolean(isopen)}
                onClose={() => {
                    setOpen(false)
                }}
                title="Copy Student Infomation &nbsp;&nbsp;"
            >
                <Paper>
                    {/* <Typography variant='h2' color='textPrimary'>Copy Student Infomation &nbsp;&nbsp;</Typography> */}
                    <FormControl component="fieldset">
                        <FormLabel className={classes.modalTitle} component="legend">
                            Choose Columns:
                        </FormLabel>
                        <FormGroup>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <Grid xs={6} item>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={checkedAll} />}
                                        label="Check All"
                                        onChange={handleCheckAll}
                                    />
                                </Grid>
                                <Grid xs={6} item>
                                    {' '}
                                </Grid>
                                {filteredTemplate.map(value => {
                                    const label = value.charAt(0).toUpperCase() + value.substring(1)
                                    return (
                                        <Grid xs={6} item key={value}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color="primary" checked={cases[value]} name={value} />
                                                }
                                                label={label}
                                                onChange={handleCaseChange}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </FormGroup>
                    </FormControl>
                    <Grid container>
                        <Grid item>
                            <FormControl>
                                <FormLabel component="legend" className={classes.modalTitle}>
                                    Column Name Needed :{' '}
                                </FormLabel>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={columnNameNeeded}
                                            color="primary"
                                            inputProps={{'aria-label': 'primary checkbox'}}
                                        />
                                    }
                                    onChange={handleSwitchChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" space={1} className={classes.buttons}>
                        <Grid item className={classes.groupButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                size="large"
                                onClick={() => {
                                    setCheckedAll(true)
                                    setHasfieldToCopy(true)
                                    setCases(defaultValue)
                                }}
                            >
                                Reset
                            </Button>
                        </Grid>
                        <Grid item className={classes.groupButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                size="large"
                                startIcon={<Clipboard size={20} />}
                                onClick={handleCopy}
                                disabled={!hasFieldToCopy}
                            >
                                Copy
                            </Button>
                        </Grid>
                        <Grid item className={classes.groupButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                size="large"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid />
                    </Grid>
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                open={Boolean(isOpenChangeClass)}
                onClose={() => {
                    setIsOpenChangeClass(false)
                }}
                title="Change Class &nbsp;&nbsp;"
            >
                <Paper>
                    {/* <Typography variant='h2' color='textPrimary'>Change Class &nbsp;&nbsp;</Typography> */}
                    <Grid
                        container
                        xs={12}
                        direction="row"
                        justifyContent="space-between"
                        sx={{alignItems: 'center', marginTop: '20px', flexWrap: 1}}
                        spacing={3}
                    >
                        <Grid item xs={12} md={12} sx={{alignItems: 'center'}}>
                            <Autocomplete
                                onChange={(event, value) => setSelectedStudentsInfo(value)}
                                value={selectedStudentsInfo}
                                multiple
                                autoHighlight
                                autoComplete
                                selectOnFocus
                                filterSelectedOptions
                                id="tags-outlined"
                                options={currentClassStudentsInfo}
                                getOptionLabel={option => option.displayName}
                                defaultValue={selectedStudentsInfo}
                                renderInput={params => (
                                    <TextField {...params} label="Students Selected" placeholder="Student Lists" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{alignItems: 'center'}}>
                            <TextField
                                sx={{minWidth: '50%'}}
                                id="filled-basic"
                                label="Current Class Info"
                                disabled
                                variant="filled"
                                value={`${oneClass?.data?.id}. ${oneClass?.data?.name}`}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Autocomplete
                                onChange={(event, value) => setSelectedClassToChange(value)}
                                value={selectedClassToChange}
                                autoComplete
                                id="tags-outlined"
                                options={otherClassInfo}
                                getOptionLabel={option => `${option.id}. ${option.name}`}
                                filterSelectedOptions
                                renderInput={params => (
                                    <TextField {...params} label="Class To Transfer" placeholder="Class To Transfer" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Reason"
                                multiline
                                fullWidth
                                maxRows={4}
                                value={changeClassReason}
                                onChange={event => {
                                    setChangeClassReason(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{alignItems: 'center'}}>
                            <TextField
                                sx={{minWidth: '50%'}}
                                id="filled-basic"
                                label="Admin Name"
                                disabled
                                variant="filled"
                                value={`${auth?.user?.firstName} ${auth?.user?.lastName}`}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        spacing={4}
                        className={classes.buttons}
                        sx={{marginTop: '10px'}}
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                className={classes.button}
                                size="large"
                                disabled={
                                    selectedClassToChange !== null ||
                                    selectedStudentsInfo.length <= 0 ||
                                    changeClassReason == null
                                }
                                onClick={() => {
                                    changeToOnHold(selectedStudentsInfo)
                                }}
                            >
                                Change to onHold
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                className={classes.button}
                                size="large"
                                disabled={
                                    selectedClassToChange === null ||
                                    selectedStudentsInfo.length <= 0 ||
                                    changeClassReason === null
                                }
                                onClick={() => {
                                    updateClassStudentList(selectedStudentsInfo, selectedClassToChange)
                                }}
                            >
                                Change
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                size="large"
                                onClick={() => setIsOpenChangeClass(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid />
                    </Grid>
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                open={Boolean(customerFloatOpen)}
                onClose={() => {
                    setCustomerFloatOpen(false)
                }}
                title="Read Class &nbsp;&nbsp;"
            >
                <Paper>
                    {showStudentDetail && (
                        <CheckCustomerFloat // floating window of student details
                            studentId={showStudentDetail}
                            setChildStudentId={setChildStudentId}
                        />
                    )}
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                open={Boolean(openEdit)}
                onClose={() => setOpenEdit(null)}
                title="Edit Class"
                isLoaded={Boolean(openEdit)}
            >
                <Paper elevation={0}>
                    <AddClass edit clone={false} openEdit={openEdit} />
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                open={Boolean(customerEditFloatOpen)}
                onClose={() => {
                    setCustomerEditFloatOpen(false)
                }}
                title="Edit Class"
            >
                <Paper>
                    {showStudentDetail && (
                        <EditCustomerFloat // floating window of student details
                            studentId={showStudentDetail}
                            setChildStudentId={setChildStudentId}
                        />
                    )}
                </Paper>
            </DraggableDialog>
        </div>
    )
}

const mapStateToProps = state => ({
    oneClass: state.classes?.oneClass,
    classHistory: state.classes?.classHistory,
    homework: state.classes?.homework,
    customers: state.customer?.customers,
    classViewData: state.classes?.classViewData,
    classesData: state.classes?.class?.data,
    currentClass: state.classes?.currentClass,
    fetchedClasses: state.classes?.class,
    classTransferDataByClassId: state.classTransferDataByClassId
})

export default connect(mapStateToProps, {
    fetchOneClassHistory,
    deleteHistory,
    fetchClassHistory,
    readOneClassHistory,
    fetchClass,
    fetchClasses,
    updateClass,
    fetchCurrentClassForEdit,
    addClassTransferRecord,
    fetchClassTransferByClassId
})(StudentIncluded)
