import api from '../../apis/api';
import { sendErrorMessage, sendSuccessMessage } from '..';
import { isMobile } from '../../utils/Scripts';
import LocalStorage from '../../utils/LocalStorage';
import { LS_KEY_NOTE_EXPANDED } from '../../views/customer/interview/interviewHelper';
import { pagedGridDefaultFetch } from '../../components/mui/datagrid/PagedGridAction';
import { noteMaxLength, noteMinLength } from '../../components/form/NoteData';

export const customerActionsType = {
  Register: 'Register',
  actionError: 'Action_Error',
  FetchCustomers: 'FetchCustomers',
  FetchCustomersPerPage: 'FetchCustomersPerPage',
  SetCustomersTableStatus: 'SetCustomerTableStatus', // Old customer edit/check/add table design, reconstruct with route design
  FetchCurrentCustomer: 'FetchCurrentCustomer',
  ActiveCustomers: 'ActiveCustomers',
  UpdateCustomer: 'UpdateCustomer',
  CreateCustomer: 'CreateCustomer',

  FetchAllRoles: 'FetchAllRoles',
  FetchCurrentCustomerCheck: 'FetchCurrentCustomerCheck',
  Fetch_Products_In_Customer: 'Fetch_Products_In_Customer',
  FetchCustomerCategories: 'FetchCustomerCategories',
  FetchCustomerNotes: 'FetchCustomerNotes',
  FetchCustomerPayment: 'FetchCustomerPayment',
  FetchCurrentCustomerContract: 'FetchCurrentCustomerContract',
  FetchCurrentCustomerInterview: 'FetchCurrentCustomerInterview',

  FetchCustomerImageFile: 'FetchCustomerImageFile',
  InitNoteExpand: 'InitNoteExpand',
  ChangeNoteExpand: 'ChangeNoteExpand',
  DuplicateEmail: 'DuplicateEmail',
  ContinueCreate: 'ContinueCreate',
  Searching: 'Searching',
  FetchCustomerProps: 'FetchCustomerProps',
  FetchAllContracts: 'FetchAllContractsForCustomer',
  // ValidEmailError: 'ValidationError'
  MapClassAndContract: 'MapClassAndContract',


  FetchClasses: 'FetchClassesForCustomer',
  copyCurrentCustomerInfo: 'copyCurrentCustomerInfo',

};


export const customerSignup = (data) => async (dispatch) => {
  try {
    const res = await api.post('/newcustomer/signup', data); // temporary set the path, need to double confirm ???
    dispatch({ type: customerActionsType.Register, payload: res.data });
  } catch (e) {
    sendErrorMessage(dispatch,'Error in customer Sign up')
    // console.log(e.response.data); // console e first to see the results
    if (e.response) {
      dispatch({ type: customerActionsType.actionError, payload: e.response.data });
    }
  }
};

export const fetchAllRoles = () => async (dispatch) => {
  try {
    const res = await api.get('/user/fetchAllRoles');
    if (res.data) {
      dispatch({ type: customerActionsType.FetchAllRoles, payload: res.data })
      return true
    }
  } catch (e) {
    sendErrorMessage(dispatch,'Error in fetch All Roles')
    // console.log(e.message)
  }
  sendErrorMessage(dispatch, 'Cannot fetch user roles')
  return false
}

export const fetchCustomers = args => async dispatch => {

  try {
    const customerURL = '/customer/fetchAllCustomers'
    const res = await pagedGridDefaultFetch(customerURL,
      args, customerActionsType.FetchCustomerProps,
      async () => api.get('/customer/fetchAllCustomers'))(dispatch)
    if (res.data?.rs) {
      dispatch({ type: customerActionsType.FetchCustomersPerPage, payload:  res.data});
      dispatch({type:customerActionsType.MapClassAndContract})
      // dispatch(fetchClasses())
      // dispatch(fetchAllContract())
      return true;
    } if(res.data.length > 0){
      dispatch({ type: customerActionsType.FetchCustomers, payload: res.data });
      return true;
    }
  } catch (e) {
    sendErrorMessage(dispatch, e.message);
    return false;
  }
  return false
};

// export const mapClassAndContract = () => async dispatch => {
//   try {
//
//   } catch (e) {
//
//   }
// };

export const fetchClasses = () => async (dispatch) => {
  try {
    const res = await api.post('/classes/fetchClass')
    if (res.data?.rs) {
      dispatch({ type: customerActionsType.FetchClasses, payload: res.data.data })
      return true
    }
    return false
  } catch (e) {
    sendErrorMessage(dispatch,'Error fetching syllabus')
    // console.log('Error fetching syllabus', e)
    return false
  }
}

export const fetchAllContractForCustomer = () => async (dispatch) => {
  // todo removed , use pagination version in stead
  try {
    const contractUrl = '/administrative/contract/getAllContracts'
    const res = await api.get(contractUrl)
    if (res.data?.rs) {
      dispatch({ type: customerActionsType.FetchAllContracts, payload: res.data.data})
      return true;
    } 
      sendErrorMessage(dispatch, res.data?.message)
      // throw (res.data.message)
    
  }
  catch (e) {
    sendErrorMessage(dispatch, e.message)
    return false
  }
  return false
}

export const fetchCustomer = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/customer/fetchOneCustomer/${id}`);
    dispatch({ type: customerActionsType.FetchCurrentCustomer, payload: res.data.data });
  } catch (e) {
    sendErrorMessage(dispatch, 'Error in fetch Customer')
    if (e.response) {
      dispatch({ type: customerActionsType.actionError, payload: e.response.data });
    }
  }
};

export const fetchCustomerProducts = () => async (dispatch) => {
  try {
    const res = await api.get('/product/getProduct');
    return dispatch({ type: customerActionsType.Fetch_Products_In_Customer, payload: res.data.data });
  } catch (e) {
    // console.log(e);
    return sendErrorMessage(dispatch, e.message);
  }
};

export const fetchCustomerContracts = (id) => async (dispatch) => {
  try {
    const res = await api.get(`administrative/contract/getContract/${id}`);
    dispatch({ type: customerActionsType.FetchCurrentCustomerContract, payload: res.data.data });
  } catch (e) {
    // console.log(e);
    sendErrorMessage(dispatch,'Error in fetch Customer Contract')
    if (e.response) {
      dispatch({ type: customerActionsType.actionError, payload: e.response.data });
    }
  }
};

export const fetchCustomerInterview = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/customer/interview/fetchCustomerLatestInterview/${id}`);
    dispatch({ type: customerActionsType.FetchCurrentCustomerInterview, payload: res.data.data });
  } catch (e) {
    sendErrorMessage(dispatch,'Error in fetch Customer Interview')
    // console.log(e);
    if (e.response) {
      dispatch({ type: customerActionsType.actionError, payload: e.response.data });
    }
  }
};

export const fetchCustomerCheck = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/customer/fetchOneCustomer/${id}`);
    if (res.data?.rs) {
      dispatch({ type: customerActionsType.FetchCurrentCustomerCheck, payload: res.data.data });
      return true;
    }
  } catch (e) {
    sendErrorMessage(dispatch,'Error in fetch Customer Check')
    // console.log(e);
    if (e.response) {
      dispatch({ type: customerActionsType.actionError, payload: e.response.data });
    }
  }
  return false;
};


export const updateCustomer = (data, success, error) => async dispatch => {
  try {
    const res = await api.put('customer/updateCustomer', data);
    if (res.data) {
      if (res.data.rs) {
        success();
        dispatch({ type: customerActionsType.UpdateCustomer, payload: res.data });
        sendSuccessMessage(dispatch, `${data.firstName.concat(' ', data.lastName)} information has been updated`);
        return true;
      }
      sendErrorMessage(dispatch, res.data.message);
      error();
      return false;
    }
  } catch (e) {
      error(e);
  }
  return false
};

export const updateCustomerCompany = (data, customerTableStatus, success, error) => async (dispatch) => {
  try {
    const res = await api.put('customer/updateCustomerCompany', data);
    if (res.data?.rs) {
      success();
      if (customerTableStatus === 'CHECK') {
        await dispatch(fetchCustomerCheck(data.id));
        return true;
      } 
        await dispatch(fetchCustomer(data.id));
        return true;
      
    }
    return error(res.data?.message || 'User company details cannot be updated');
  } catch (e) {
    // console.log(e);
    return error(e.message);
  }
};

export const addCustomer = (data, success, error) => async dispatch => {
  if (data?.email.search("@gmail") && data?.gmail === ""){
    data.gmail = data?.email
  }
  // change notes data type to string to fit backend
  if (data.notes) {
    const noteDealer = data.notes
    data.notes = []
    data.notes.push(noteDealer)
  }
  try {
    const res = await api.post('customer/createCustomer', data);
    if (res?.data?.rs) {
      const refresh = await dispatch(fetchCustomers());
      if (refresh) {
        success();
        dispatch({ type: customerActionsType.CreateCustomer, payload: res.data });
        sendSuccessMessage(dispatch, `Customer ${res.data.data.firstName} ${res.data.data.lastName} has been created`);
      }
      return res.data.data.id;
    } 
      error(res?.data?.message || 'User cannot be created');
    
  } catch (e) {
    // console.log('there is a error', e);
    error(e.message);
  }
  return null;
};

export const actEmailCheck = (email, userId = null) => async dispatch => {
  const checkObj = {};
  checkObj.email = email;
  if (userId) {
    checkObj.userId = userId;
  }
  dispatch({ type: customerActionsType.Searching, payload: true });
  try {
    const res = await api.post('customer/emailCheck', checkObj);
    if (res?.data?.rs === true) {
      dispatch({ type: customerActionsType.DuplicateEmail, payload: res.data.message });
    } else {
      dispatch({ type: customerActionsType.ContinueCreate, payload: res.data });
    }
  } catch (e) {
    // error(e.message)
  }
};

export const activateCustomer = (obj, fetchProps) => async dispatch => {
  try {
    const res = await api.post('customer/updateCustomersStatus', obj);
    const {data} = res;
    if (data) {
      const refresh = await dispatch(fetchCustomers(fetchProps));
      if (refresh) {
        dispatch({ type: customerActionsType.ActiveCustomers, payload: res.data });
        if (obj.isActive) {
          sendSuccessMessage(dispatch, `ID: ${obj.ids} have been activated`);
        } else {
          sendSuccessMessage(dispatch, `ID: ${obj.ids} have been deactivated`);
        }

      } else {
        sendErrorMessage(dispatch, 'Error in activate Customer, not Active')
        // console.log('there is some errors');
      }
    }
  } catch (e) {
    sendErrorMessage(dispatch, 'Error in activate Customer')
    // console.log(e);
  }
};


export const getCustomerLatestResume = (id) => async (dispatch) => {
  try {
    const res = await api.post(
      `customer/getLatestResumeFile/`,
      { userId: id },
      { responseType: 'blob' }
    );
    if (res.data) {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      // If is mobile, open in same tab
      if (!isMobile()) {
        link.target = '_blank';
      }
      link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: false, view: window }));
      link.parentNode.removeChild(link);
      return;
    }
  } catch (e) {
    // console.log('Error', e.message);
  }
  sendErrorMessage(dispatch, 'Failed to get file');
};

export const uploadCustomerFile = (file, uid, onSuccess) => async (dispatch) => {
  try {
    const res = await api.post('/customer/uploadUserFile', file);
    if (res.data?.rs) {
      // await dispatch(fetchCustomers())
      await dispatch(fetchCustomer(uid));
      return onSuccess('File successfully uploaded');
    }
  } catch (e) {
    // console.log('Cannot upload user file', e.message);
  }
  return sendErrorMessage(dispatch, 'Failed to get file');
};

export const deleteCustomerFile = (file, uid, onSuccess) => async (dispatch) => {
  try {
    const res = await api.post('/customer/deleteCustomerFile', file);
    if (res.data?.rs) {
      // await dispatch(fetchCustomers())
      await dispatch(fetchCustomer(uid));
      return onSuccess('File successfully deleted');
    }
  } catch (e) {
    // console.log('Cannot delete user file', e.message);
  }
  return sendErrorMessage(dispatch, 'Failed to delete file');
};

export const getCustomerFile = (data) => async (dispatch) => {
  try {
    const res = await api.post(
      `customer/getCustomerFile/`,
      data,
      { responseType: 'blob' }
    );
    if (res.data) {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
      const link = document.createElement('a');
      link.href = url;
      if (data.fileName) {
        link.download = data.fileName;
      }
      document.body.appendChild(link);
      // If is mobile, open in same tab
      if (!isMobile()) {
        link.target = '_blank';
      }
      link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: false, view: window }));
      link.parentNode.removeChild(link);
      return;
    }
  } catch (e) {
    // console.log('Error', e.message);
  }
  sendErrorMessage(dispatch, 'Failed to get file');
};

export const editCustomerNote = (data, onSuccess, onError) => async (dispatch) => {
  try {
    if (data.notes?.length > noteMaxLength || data.notes?.length < noteMinLength) {
      return onError(`Note length should be between ${noteMinLength} and ${noteMaxLength} characters`);
    }
    const res = await api.put(`/customer/editNote`, data);
    if (res.data?.rs) {
      await dispatch(fetchCustomerNotes(data.customerId));
      return onSuccess('Note updated');
    }
  } catch (e) {
    // console.log('Note update error:\n', e);
    return onError(e.message);
  }
  return onError('Something went wrong');
};

export const deleteCustomerNote = (noteId, customerId, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.delete(`/customer/deleteNote/${noteId}/${customerId}`);
    if (res.data?.rs) {
      await dispatch(fetchCustomerNotes(customerId));
      return onSuccess('Note deleted');
    }
  } catch (e) {
    // console.log('Note delete error:\n', e);
    return onError(e.message);
  }
  return onError('Something went wrong');
};

export const fetchCustomerNotes = (customerId) => async (dispatch) => {
  try {
    const res = await api.get(`/customer/fetchNotes/${customerId}`);
    if (res.data?.rs) {
      dispatch({ type: customerActionsType.FetchCustomerNotes, payload: res.data.data });
      return true;
    } 
      sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch notes');
    
  } catch (e) {
    // console.log('Error fetching notes: ', e);
    sendErrorMessage(dispatch, 'Cannot fetch notes');
  }
  return false;
};

export const fetchCustomerNoteFile = (customerId, noteId, mime, loadedData = null) => async (dispatch) => {
  if (loadedData) {
    // For images that are already loaded
    const link = document.createElement('a');
    link.href = loadedData;
    document.body.appendChild(link);
    if (!isMobile()) {
      link.target = '_blank';
    }
    link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: false, view: window }));
    link.parentNode.removeChild(link);
  } else {
    // Files that must be fetched from server
    try {
      const res = await api.post(`/customer/fetchNoteFile`, { customerId, noteId }, { responseType: 'blob' });
      if (res.data) {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: mime }));
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        if (!isMobile()) {
          link.target = '_blank';
        }
        link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: false, view: window }));
        link.parentNode.removeChild(link);
      }
    } catch (e) {
      sendErrorMessage(dispatch, 'Unable to fetch file');
    }
  }
};

export const fetchCustomerNoteImage = (customerId, noteId, mime) => async (dispatch) => {
  try {
    const res = await api.post(`/customer/fetchNoteFile`, { customerId, noteId }, { responseType: 'blob' });
    if (res.data) {
      const image = window.URL.createObjectURL(new Blob([res.data], { type: mime }));
      await dispatch({ type: customerActionsType.FetchCustomerImageFile, payload: { id: noteId, image } });
      return true;
    }
  } catch (e) {
    // console.log('Unable to fetch image', e.message);
  }
  return false;
};

export const initCustomerNote = () => async (dispatch) => {
  const storageVal = LocalStorage.getObjectItem(LS_KEY_NOTE_EXPANDED) || [];
  await dispatch({ type: customerActionsType.InitNoteExpand, payload: storageVal });
};

export const createCustomerNote = (data, customerId, onSuccess, onError) => async (dispatch) => {
  try {
    if (data.notes?.length > noteMaxLength || data.notes?.length < noteMinLength) {
      return onError('Note length should be between 1 and 5000 characters');
    }
    const res = await api.post(`/customer/createNote/`, data);
    if (res.data?.rs) {
      await dispatch(fetchCustomerNotes(customerId));
      return onSuccess('Note added');
    }
  } catch (e) {
    // console.log('Note creation error:\n', e);
    return onError(e.message);
  }
  return onError('Something went wrong');
};


export const deleteCustomerPayment = (paymentId, customerId, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.delete(`/customer/deletePayment/${paymentId}/${customerId}`);
    if (res.data?.rs) {
      await dispatch(fetchCustomerPayment(customerId));
      return onSuccess('Payment deleted');
    }
  } catch (e) {
    return onError(e.message);
  }
  return onError('Something went wrong');
};

export const fetchCustomerPayment = (customerId) => async (dispatch) => {
  try {
    const res = await api.get(`/customer/fetchPayment/${customerId}`);
    if (res.data?.rs) {
      dispatch({ type: customerActionsType.FetchCustomerPayment, payload: res.data.data });
      return true;
    } 
      sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch payments');
    
  } catch (e) {
    // console.log('Error fetching notes: ', e);
    sendErrorMessage(dispatch, 'Cannot fetch payment');
  }
  return false;
};


export const createCustomerPayment = (customerId, payment, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post(`/customer/createPayment/`, { customerId, payment });
    if (res.data?.rs) {
      await dispatch(fetchCustomerPayment(customerId));
      return onSuccess('Payment added');
    }
  } catch (e) {
    return onError(e.message);
  }
  return onError('Something went wrong');
};

export const fetchCustomerContract = (uid) => async (dispatch) => {

  try {
    const res = await api.get(`/administrative/contract/fetchLatestContract/${uid}`);
    if (res.data?.rs) {
      dispatch({ type: customerActionsType.FetchCurrentCustomerContract, payload: res.data.data });
      return true;
    }
  } catch (e) {
    // console.log('Error fetching customer contract', e);
  }
  sendErrorMessage(dispatch, 'Cannot fetch customer contract');
  return false;
};

export const copyCustomerInfo = (onSuccess, info) => (dispatch) => {
  dispatch({type: customerActionsType.copyCurrentCustomerInfo, payload: info})
  return onSuccess('Email & Name Copied Successfully');
}
