import React, {useEffect, useState} from 'react'
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
    Autocomplete
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
    addCampaignCategory,
    createCampaign,
    fetchCampaignCategories,
    fetchCampaignUsers,
    updateCampaign
} from '../../../actions/campaign/campaignActions'
import {CAMPAIGN_CREATE_VALIDATOR, CAMPAIGN_STATUS} from '../campaignHelper'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'
import SubmitButton from '../../../components/mui/button/SubmitButton'

const useStyles = makeStyles(() => ({
    formControl: {
        marginBottom: '2rem'
    },
    userSelectorFormControl: {
        width: '100%'
    },
    marginLeft: {
        marginLeft: '2rem'
    },
    marginTop: {
        marginTop: '2rem'
    },
    paper: {
        padding: '2rem'
    },
    submitButton: {
        marginTop: '5rem'
    },
    marginReset: {
        marginLeft: '0!important'
    }
}))

const schema = yup.object().shape(CAMPAIGN_CREATE_VALIDATOR)
function CampaignEditor({isEdit, campData, onSubmitSuccess}) {
    const {register, handleSubmit, errors, setError} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })

    const classes = useStyles()
    const dispatch = useDispatch()

    const allCategories = useSelector(state => state.campaign.allCategories)
    const allCampaignUsers = useSelector(state => state.campaign.allCampaignUsers)

    const [categories, setCategories] = useState([])
    const [allUsers, setAllUsers] = useState([])

    const [isSaving, setIsSaving] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const [isCatLoaded, setIsCatLoaded] = useState(false)
    const [isUsersLoaded, setIsUsersLoaded] = useState(false)

    const [catSelectedValue, setCatSelectedValue] = useState(null)
    const [catInputValue, setCatInputValue] = useState('')
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedPointedUser, setSelectedPointedUser] = useState('')

    const [selectedUserQR, setSelectedUserQR] = useState({})

    const loadData = () => {
        ;(async () => {
            setIsCatLoaded(await fetchCampaignCategories()(dispatch))
            setIsUsersLoaded(await fetchCampaignUsers()(dispatch))
        })()
    }
    useEffect(loadData, [])

    const testUserQRMappingData = data => {
        const result = {}
        if (!data) {
            return result
        }

        Object.keys(data).forEach(qrUser => {
            // data check
            const user = allCampaignUsers.find(usr => usr.id.toString() === qrUser)
            if (user) {
                const hasQrCode = user.staffInfo.wechatQRCode.find(qr => qr.id === data[qrUser])
                if (hasQrCode) {
                    result[qrUser] = data[qrUser]
                }
            }
        })
        return result
    }

    useEffect(() => {
        if (!isLoaded) {
            // Set each as they load
            if (isCatLoaded && allCategories && allCategories.length > 0) {
                setCategories(allCategories)
            }
            if (isUsersLoaded && allCampaignUsers && allCampaignUsers.length > 0) {
                setAllUsers(allCampaignUsers)
            }
            if (isCatLoaded && isUsersLoaded) {
                if (isEdit) {
                    // Load Campaign Details
                    setSelectedUsers(campData.users.filter(usr => usr.isActive).map(usr => usr.id))
                    setSelectedPointedUser(campData.pointedUser.id)
                    const findCat = campData.category
                        ? allCategories.find(cat => cat.id === campData.category.id)
                        : null
                    setCatSelectedValue(findCat)
                    setCatInputValue(findCat?.name || '')
                    setSelectedUserQR(testUserQRMappingData(JSON.parse(campData.userQRMapping)))
                }
                setIsLoaded(true)
            }
        }
    }, [isCatLoaded, isUsersLoaded, allCategories, allCampaignUsers, isLoaded])

    useEffect(() => {
        // Refresh category when user adds more categories
        setCategories(allCategories)
    }, [allCategories])

    const onSubmit = async formData => {
        setIsSaving(true)

        let hasErr = false
        let categoryId = null

        // Validate Category

        if (Object.prototype.hasOwnProperty.call(catSelectedValue, 'id')) {
            categoryId = catSelectedValue.id
        }

        // Validate Users
        if (selectedUsers.length < 1) {
            hasErr = true
            setError('users', {type: 'manual', message: 'Required'})
        }
        if (!selectedUsers.includes(selectedPointedUser)) {
            hasErr = true
            setError('pointedUser', {type: 'manual', message: 'Pointed user is required and must be included in users'})
        }

        if (!hasErr) {
            // Only submit if there are no errors
            try {
                // Create submit data
                const res = {
                    name: formData.name,
                    description: formData.description,
                    guide: formData.guide,
                    categoryId,
                    status: formData.type,
                    users: selectedUsers,
                    pointedUser: parseInt(selectedPointedUser.toString(), 10),
                    userQRMapping: JSON.stringify(selectedUserQR)
                }
                if (isEdit) {
                    await updateCampaign(
                        campData.id,
                        res,
                        msg => onSubmitSuccess(msg.message, msg.data.id),
                        msg => onError(msg)
                    )(dispatch)
                } else {
                    await createCampaign(
                        res,
                        msg => onSubmitSuccess(msg.message, msg.data.id),
                        msg => onError(msg)
                    )(dispatch)
                }
            } catch (e) {
                onError(e.message)
            }
        } else {
            setIsSaving(false)
        }
    }

    const onSuccess = msg => sendSuccessMessage(dispatch, msg)
    const onError = msg => {
        sendErrorMessage(dispatch, msg)
        setIsSaving(false)
    }

    const onAddCatCB = name => {
        addCampaignCategory(
            name,
            msg => {
                // setCatSelectedValue(categories.find(c => c.name === name))
                onSuccess(msg)
            },
            msg => onError(msg)
        )(dispatch)
    }

    const renderUserSelectDialogue = () => (
        <FormControl
            className={classes.userSelectorFormControl}
            required
            disabled={isSaving}
            error={Boolean(errors.users?.message)}
        >
            <InputLabel>Users</InputLabel>
            <Select
                size="small"
                name="users"
                inputRef={register}
                fullWidth
                onChange={e => setSelectedUsers(e.target.value)}
                multiple
                value={selectedUsers}
                input={<Input />}
            >
                {allUsers.map(u => (
                    <MenuItem key={u.id} value={u.id}>
                        {`${u.id}. ${u?.firstName} ${u?.lastName}`}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText className={classes.marginReset}>
                {errors.users?.message ? errors.users?.message : 'Select all the users to include in this campaign'}
            </FormHelperText>
        </FormControl>
    )

    const renderPointedUserSelectDialogue = () => {
        const filteredUsers = allUsers.filter(u => selectedUsers.includes(u.id))
        return (
            <FormControl
                className={classes.userSelectorFormControl}
                required
                disabled={isSaving}
                error={Boolean(errors.pointedUser?.message)}
            >
                <InputLabel>Pointed User</InputLabel>
                <Select
                    size="small"
                    name="pointedUser"
                    inputRef={register}
                    fullWidth
                    onChange={e => setSelectedPointedUser(e.target.value)}
                    value={selectedPointedUser}
                    input={<Input />}
                >
                    {filteredUsers.map(u => (
                        <MenuItem key={u.id} value={u.id}>
                            {`${u.id}. ${u?.firstName} ${u?.lastName}`}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText className={classes.marginReset}>
                    {errors.pointedUser?.message
                        ? errors.pointedUser?.message
                        : 'Pointed User must be in the selected users'}
                </FormHelperText>
            </FormControl>
        )
    }

    const renderUserQRCodeSelection = () => (
        <>
            {selectedUsers.map((selUsr, index) => {
                const userData = allUsers.find(usr => usr.id === selUsr)
                return (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <FormControl className={classes.userSelectorFormControl} disabled={isSaving}>
                            <InputLabel>{`${userData?.firstName} ${userData?.lastName}`}</InputLabel>
                            <Select
                                size="small"
                                fullWidth
                                // onChange={(e) => setUserQRArray(e.target.value, selUsr)}
                                onChange={e => setSelectedUserQR({...selectedUserQR, [selUsr]: e.target.value})}
                                value={selectedUserQR[selUsr] ?? ''}
                                input={<Input />}
                                disabled={isSaving || userData?.staffInfo.wechatQRCode.length <= 0}
                            >
                                {userData?.staffInfo.wechatQRCode.length > 0 ? (
                                    userData?.staffInfo.wechatQRCode.map(u => (
                                        <MenuItem key={u.id} value={u.id}>
                                            {`${u.name} `}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="">N/A</MenuItem>
                                )}
                            </Select>
                            {userData?.staffInfo.wechatQRCode.length <= 0 && (
                                <FormHelperText className={classes.marginReset}>
                                    This user has no QR codes
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                )
            })}
        </>
    )

    const renderForm = () => (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={5} direction="row" justifyContent="space-between" alignItems="flex-start">
                {/* Left */}
                <Grid item xs={12} md={6}>
                    <Grid container spacing={1} direction="column">
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Name"
                                    required
                                    disabled={isSaving}
                                    variant="outlined"
                                    name="name"
                                    inputRef={register}
                                    defaultValue={isEdit ? campData.name : ''}
                                    error={Boolean(errors.name?.message)}
                                    helperText={errors.name?.message ? errors.name?.message : 'Max 30 characters'}
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Scan Guide"
                                    required
                                    disabled={isSaving}
                                    variant="outlined"
                                    name="guide"
                                    inputRef={register}
                                    defaultValue={isEdit ? campData.guide : '请扫码联系'}
                                    error={Boolean(errors.guide?.message)}
                                    helperText={errors.guide?.message ? errors.guide?.message : 'Max 30 characters'}
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Description"
                                    required
                                    multiline
                                    rows={5}
                                    disabled={isSaving}
                                    variant="outlined"
                                    name="description"
                                    inputRef={register}
                                    defaultValue={isEdit ? campData.description : ''}
                                    error={Boolean(errors.description?.message)}
                                    helperText={errors.description?.message}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Right */}
                <Grid item xs={12} md={6}>
                    <Grid container spacing={1} direction="column">
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <Autocomplete
                                    options={categories}
                                    noOptionsText="Category not found. Press Enter to add."
                                    getOptionLabel={option => (option ? option.name : '')}
                                    autoSelect
                                    onChange={(e, v) => setCatSelectedValue(v)}
                                    name="category"
                                    disabled={isSaving}
                                    value={catSelectedValue}
                                    inputValue={catInputValue}
                                    onInputChange={(e, v) => setCatInputValue(v)}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Categories"
                                            size="small"
                                            variant="outlined"
                                            inputRef={register}
                                            name="categoryInput"
                                            onKeyPress={e => {
                                                // Disable default submit
                                                if (e.key === 'Enter') {
                                                    e.preventDefault()
                                                    // Post value as new
                                                    const cleanName = catInputValue.trim()
                                                    // Min 2 letters & do not post already existing names
                                                    if (
                                                        cleanName.length < 2 ||
                                                        categories.some(c => c.name === cleanName)
                                                    )
                                                        return
                                                    onAddCatCB(catInputValue)
                                                }
                                            }}
                                            helperText="Press enter when the entered category doesn't exist to create it"
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl
                                fullWidth
                                className={classes.formControl}
                                required
                                error={Boolean(errors.type?.message)}
                                disabled={isSaving}
                            >
                                <FormLabel component="legend">Responding Type</FormLabel>
                                <div className={classes.marginLeft}>
                                    <RadioGroup defaultValue={isEdit ? campData.status : CAMPAIGN_STATUS[0].symbol}>
                                        {CAMPAIGN_STATUS.map((s, index) => (
                                            <FormControlLabel
                                                key={index}
                                                inputRef={register}
                                                value={s.symbol}
                                                control={<Radio name="type" />}
                                                label={s.name}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                                <FormHelperText>{Boolean(errors.type?.message) && errors.type?.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                {/* User Selector */}
                <Grid item xs={12}>
                    <Grid container spacing={5} direction="row" justifyContent="flex-start">
                        <Grid item xs={12} md={6}>
                            {renderUserSelectDialogue()}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {renderPointedUserSelectDialogue()}
                        </Grid>

                        <Grid item xs={12} className={classes.marginTop}>
                            <Grid container spacing={4} direction="row">
                                <Grid item xs={12}>
                                    <Typography variant="h3" color="textPrimary">
                                        Default QR Code
                                    </Typography>
                                </Grid>

                                {renderUserQRCodeSelection()}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Bottom Submit Button */}
                <Grid item xs={12}>
                    <Grid container spacing={1} direction="row" justifyContent="flex-end">
                        <Grid item xs={2} className={classes.submitButton}>
                            <SubmitButton
                                isSaving={isSaving}
                                text="Submit"
                                isSavingText="Submitting"
                                fullWidth
                                variant="contained"
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )

    return (
        <Paper className={classes.paper}>
            {isLoaded ? renderForm() : <Typography variant="h2">Loading</Typography>}
        </Paper>
    )
}

export default CampaignEditor
