import * as React from 'react'
//
// import {
//   Grid,
//   GridColumn as Column,
//   GridToolbar
// } from '@progress/kendo-react-grid';
import {Grid, Box} from '@mui/material'
import {DataGridPro} from '@mui/x-data-grid-pro'
import {useGridApiRef} from '@mui/x-data-grid'

import {useEffect, useState} from 'react'
import {gapi} from 'gapi-script'
import {useDispatch, useSelector} from 'react-redux'
// import DetailComponent from './ResourcesTreeView/DetailComponent'
import {ApiKey, CLIENT_ID, DISCOVERY_DOCS, insertItem, scope, updateItem} from './resourcesHelper'

import ViewHead from '../../../components/public/ViewHead'
import {Functions} from '../../../utils/Functions'
import ListDocuments from './ResourcesTreeView/ListDocuments'
import {addGmail, addResource, deleteResource, editResource, listAll} from '../../../actions/resources/resActions'
import ResourcesCommandCell from './ResourcesTreeView/CommandCell'
import {sendErrorMessage} from '../../../actions'

function Resources() {
    const [forceUpdate, setForceUpdate] = useState(0)
    const [showGoogleLogin, setShowGoogleLogin] = useState(true)
    // const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = React.useState('false');
    const [isFetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] = React.useState('false')
    const [signedInUser, setSignedInUser] = React.useState('')
    // const [listDocumentsVisible, setListDocumentsVisibility] = React.useState(false);
    const [documents, setDocuments] = React.useState([])
    const dispatch = useDispatch()
    const resources = useSelector(state => state.resReducer.resources)
    const [data, setData] = React.useState([])
    const apiRef = useGridApiRef()

    useEffect(() => {
        setData(resources || [])
    }, [resources])

    const itemChange = event => {
        const newData = data.map(item =>
            item.id === event.dataItem.id ? {...item, [event.field || '']: event.value} : item
        )
        setData(newData)
    }

    const remove = async dataItem => {
        const newData = data.filter(res => res.id !== dataItem.id)
        const result = await deleteResource(dataItem)(dispatch)
        if (result) {
            setData(newData)
        }
        setForceUpdate(forceUpdate + 1)
    }

    const add = async dataItem => {
        dataItem.inEdit = true
        const newData = insertItem(dataItem, data)
        const newItem = {name: dataItem.name, url: dataItem.url, user: signedInUser}

        await addResource(newItem)(dispatch)
        setData(newData)
        setForceUpdate(forceUpdate + 1)
    }

    const update = async dataItem => {
        dataItem.inEdit = false
        const result = await editResource(dataItem.url, dataItem.name)(dispatch)
        if (result) {
            await listAll(signedInUser)(dispatch)
        }
        const newData = updateItem(dataItem, data)
        setData(newData)
        setForceUpdate(forceUpdate + 1)
    }

    const discard = () => {
        const newData = [...data]
        newData.splice(0, 1)
        setData(newData)
    }

    const addRecord = () => {
        const newRecord = {
            expanded: false,
            inEdit: true,
            Discontinued: false,
            child: []
        }
        setData([newRecord, ...data])
    }

    const enterEdit = async dataItem => {
        setData(data.map(item => (item.id === dataItem.id ? {...item, inEdit: true} : item)))
    }

    const expandChange = event => {
        const newData = data.map(item => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded
            }
            return item
        })
        setData(newData)

        setForceUpdate(forceUpdate + 1)
    }

    const cancel = () => {
        // console.log('this is event', event);
    }

    function CommandCell(props) {
        return (
            <ResourcesCommandCell
                // gtoken={gtoken}
                // gmail={gmail}
                // data={data}
                {...props}
                edit={enterEdit}
                remove={remove}
                add={add}
                discard={discard}
                update={update}
                cancel={cancel}
                editField="inEdit"
            />
        )
    }

    const columns = [
        {field: 'id', title: 'Id', width: 150, editable: true},
        {field: 'name', title: 'File Name', width: 150},
        {field: 'Google File id', title: 'url', width: 250, editable: true},
        {cell: CommandCell, width: 240}
    ]

    const handleClientLoad = () => {
        gapi.load('client:auth2', initClient)
    }

    const initClient = () => {
        // setIsLoadingGoogleDriveApi(true);
        gapi.client
            .init({
                apiKey: ApiKey,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope
            })
            .then(
                () => {
                    // Listen for sign-in state changes.
                    gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus)

                    // Handle the initial sign-in state.
                    updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
                }
                // ,
                // (error) => {
                //   console.log(error);
                // }
            )
    }
    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */
    const updateSigninStatus = async isSignedIn => {
        if (isSignedIn) {
            // Set the signed in user
            setSignedInUser(gapi.auth2.getAuthInstance().currentUser.le.wt.cu)
            // setIsLoadingGoogleDriveApi(false);
            setShowGoogleLogin(false)
            // list files if user is authenticated
            listFiles()
        } else {
            // prompt user to sign in
            handleAuthClick()
            setShowGoogleLogin(true)
        }
    }

    useEffect(() => {
        if (signedInUser) {
            addGmail(signedInUser)(dispatch)
        }
    }, [signedInUser])
    /**
     * List files.
     */
    const listFiles = () => {
        setIsFetchingGoogleDriveFiles(true)
        gapi.client.drive.files
            .list({
                includeItemsFromAllDrives: false,
                supportsAllDrives: false,
                pageSize: 10,
                fields: 'nextPageToken, files(id, name, mimeType, modifiedTime)',
                // q: searchTerm
                q: `'me' in owners and mimeType = 'application/vnd.google-apps.folder'`
            })
            .then(response => {
                setIsFetchingGoogleDriveFiles(false)
                // setListDocumentsVisibility(true);
                const res = JSON.parse(response.body)
                setDocuments(res.files)
            })
            .catch(e => sendErrorMessage(dispatch, `files error${e}`))
    }

    /**
     *  Sign in the user upon button click.
     */
    const handleAuthClick = () => {
        gapi.auth2.getAuthInstance().signIn()
    }

    return (
        <div>
            <ViewHead functionId={Functions.Training_Resources}>
                <Grid
                    key={forceUpdate}
                    style={{
                        height: '800px'
                    }}
                    data={data}
                    editField="inEdit"
                    expandField="expanded"
                    onItemChange={itemChange}
                    onExpandChange={expandChange}
                    // detail={props => <DetailComponent {...props} setData={setData} data={data}/>}
                >
                    <Box sx={{padding: '20px 0'}}>
                        <button
                            type="button"
                            title="google"
                            className="k-button k-primary"
                            onClick={handleClientLoad}
                            style={{padding: '10px', marginRight: '20px'}}
                        >
                            {showGoogleLogin ? 'Google Login' : `Welcome ${signedInUser}`}
                        </button>
                        <button
                            type="button"
                            title="Add new"
                            className="k-button k-primary"
                            onClick={addRecord}
                            disabled={showGoogleLogin}
                            style={{padding: '10px'}}
                        >
                            Add new
                        </button>
                    </Box>
                    <DataGridPro apiRef={apiRef} columns={columns} rows={[]} />
                </Grid>
            </ViewHead>
            <ListDocuments
                // visible={listDocumentsVisible}
                // onClose={onClose}
                documents={documents}
                onSearch={listFiles}
                // signedInUser={signedInUser}
                // onSignOut={handleSignOutClick}
                isLoading={isFetchingGoogleDriveFiles}
            />
        </div>
    )
}

export default Resources
