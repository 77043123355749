import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {Button, SvgIcon} from '@mui/material';
import {PlusCircle as PlusIcon} from "react-feather";
import {
  classActionsType,
  cloneClass,
  fetchClass,
  fetchClasses,
  fetchClassHistory,
  fetchHomework,
  fetchOneClass
} from '../../../actions/class/classActions';
import DataTable from './DataTable';
import ViewHead from "../../../components/public/ViewHead";
import {Functions} from "../../../utils/Functions";
import useAuthCheck from "../../../hooks/useAuthCheck";
import LoadingScreen from "../../../components/public/LoadingScreen";
import { fetchCustomers } from '../../../actions';


function TablePage({fetchClass, fetchOneClass,
                     fetchClassHistory, fetchHomework}) {

  const [isLoading, setLoading] = useState(false)
  // const [isLoaded, setIsLoaded] = useState(false)

  const [isClassLoaded, setIsClassLoaded] = useState(false)
  const [isHistoryLoaded, setIsHistoryLoaded] = useState(false)
  const [isHomeworkLoaded, setIsHomeworkLoaded] = useState(false)
  const [isCustomerLoaded, setIsCustomerLoaded] = useState(false)

  const dispatch = useDispatch()
  const [isAdd,isEdit] = useAuthCheck([
    Functions.Training_Class_Create_Class,
    Functions.Training_Class_Edit_Class
  ])

  const handleEdit = (id) => {
    setLoading(true);
    fetchClass(id
        , () => {setLoading(false)}
    );
  }

  // const handleClone = (id) => {
  //   setLoading(true);
  //   cloneClass(id, () => {setLoading(false)});
  // }

  const handleFetchClass = async (id) => {
    await fetchClass(id);
  }
  const handleRead = async (id) => {
    setLoading(true)
    if(isEdit) {
      setIsCustomerLoaded(await fetchCustomers()(dispatch))
    }
    setIsClassLoaded(await fetchOneClass(id))
    setIsHistoryLoaded(await fetchClassHistory(id))
    // await fetchHomework(id, () => {setLoading(false)}
    setIsHomeworkLoaded(await fetchHomework(id))
  }

  useEffect(() => {
    if (isClassLoaded && isHistoryLoaded && isHomeworkLoaded && isCustomerLoaded) {
      setLoading(false)
    }
  }, [isClassLoaded, isHistoryLoaded, isHomeworkLoaded, isCustomerLoaded])

  const topButtons = () => {
    if(isAdd) {
      return (
          <Button color="secondary" variant="contained" style={{marginBottom: "0.5rem"}}
                  onClick={() => {
                    dispatch({type: classActionsType.SetClassTableStatus, payload: "ADDCLASS"})
                  }}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon/>
                    </SvgIcon>
                  }
          >
            Add Classes
          </Button>
      )
    }
      return null;
    
  }

  return (
    <ViewHead functionId={Functions.Training_Class} topButtons={topButtons()} noMaxWidth>
      {!isLoading ? <DataTable isEdit={isEdit} handleEdit={handleEdit}
                               isClone={isAdd}
                               handleRead={handleRead} handleFetchClass={handleFetchClass}/> : <LoadingScreen message='Loading...' />}
      </ViewHead>

  );
}

const mapStateToProps = (state) => ({
  // classes: state.classes?.class?.data,
  classViewData: state.classes?.classViewData
})

export default connect(mapStateToProps, {fetchClass, fetchClasses, fetchOneClass, fetchClassHistory, fetchHomework,cloneClass})(TablePage);
