import React from 'react';
import Typography from "@mui/material/Typography";

function NotFound({message}) {

    return (
        <Typography variant="body2" color="textSecondary">
            {message || "Not Found"}
        </Typography>
    )
}

export default NotFound;

