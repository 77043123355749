import React, { useEffect, useState } from 'react';
import { Button, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import ViewHead from '../../../../../components/public/ViewHead';
import { Functions } from '../../../../../utils/Functions';
import { sendSuccessMessage } from '../../../../../actions';
import history from '../../../../../utils/history';
import TopUpPostEditor from './TopUpPostEditor';
import { fetchTopUpPost } from '../../../../../actions/matric/topupActions';
import LoadingScreen from '../../../../../components/public/LoadingScreen';

function EditTopUpPostView() {
  const  navigate = useNavigate();
  const dispatch = useDispatch()
  const currentPost = useSelector(state => state.matric.currentTopUpPost)

  const {site} = useParams()
  const {id} = useParams()

  const [isLoaded, setIsLoaded] = useState(false)

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchTopUpPost(site, id)(dispatch))
    })()
  }
  useEffect(loadData, [])

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/matric/topup`)
    }, 1000)
  }

  const renderBackButton = () =>
    <Button color='secondary'
            variant='contained'
            onClick={() => history.goBack()}>
      Back
    </Button>

  return (
    <ViewHead functionId={Functions.Matric_TopUp_Edit_TopUp_Post} noMaxWidth topButtons={renderBackButton()}>
      <Paper elevation={0}>
        {
          isLoaded
            ? <TopUpPostEditor isEdit originalData={currentPost} site={site} onSubmitSuccess={onSuccess} />
            : <LoadingScreen message='Loading...' />
        }
      </Paper>
    </ViewHead>
  )
}

export default EditTopUpPostView
