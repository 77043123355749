import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button, FormControl, FormControlLabel, FormHelperText, Grid, Paper, Switch, TextareaAutosize, TextField, Typography, Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../utils/Functions';
import { checkReviewUid, fetchAllCustomers, sendReviewLinks } from '../../../actions/review/reviewActions';
import { sendErrorMessage, sendSuccessMessage } from '../../../actions';
import ViewHead from '../../../components/public/ViewHead';
import { useReviewEditorStyles } from '../reviewHelper';
import { EmailChecker, SMSPhoneChecker } from '../../../utils/FormValidator';
import { fetchEmailAccount } from '../../../actions/administrative/administrativeAction';
import { ServerBaseURL } from '../../../constants';

function ReviewSenderView() {
  useCurrentViewCheck(Functions.Review_Set_Send)
  const navigate = useNavigate();
  const {uid} = useParams()
  const dispatch = useDispatch()
  const classes = useReviewEditorStyles()

  const allCustomers = useSelector(state => state.review.allCustomers)
  const currentReviewCheck = useSelector(state => state.review.currentReviewCheck)
  const currentEmail = useSelector(state => state.administrative.currentEmailAccount)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isReviewChecked, setIsReviewChecked] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [displayMessage, setDisplayMessage] = useState('Loading...')

  const [smsList, setSMSList] = useState('')
  const [smsListError, setSMSListError] = useState('')
  const [emailList, setEmailList] = useState('')
  const [emailListError, setEmailListError] = useState('')
  const [isDebug, setIsDebug] = useState(true)

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/review/set`)
    }, 1000)
  }
  const onError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }

  useEffect(async () => {
    if (isReviewChecked) {
      if (currentReviewCheck.type === 'set') {
        setIsLoaded(true)
        if (currentReviewCheck.set.head?.emailAccountId) {
          await fetchEmailAccount(currentReviewCheck.set.head.emailAccountId)(dispatch)
        }
      } else {
        setDisplayMessage('Invalid Review Set')
      }
    }
  }, [isReviewChecked, currentReviewCheck])

  const fetchCustomerCb = (result) => {
    if (!result) { onError('Failed to fetch customer list') }
  }

  const loadData = () => {
    checkReviewUid(uid, setIsReviewChecked)(dispatch)
    fetchAllCustomers(fetchCustomerCb)(dispatch)
  }
  useEffect(loadData, [dispatch, uid])

  const selectUser = (usr, type) => {
    // When the user clicks clear on the autocomplete
    if (!usr) { return }

    let res
    if (type === 'sms') {
      const cleanOriginalList = smsList.trim()
      res = cleanOriginalList
      if (cleanOriginalList.length > 0) { res += '\n' }
      if (usr?.phone) {
        if (usr.phone.length === 10) {
          res += `+1${usr.phone}\n`
        } else if (usr.phone.length === 11) {
          res += `+86${usr.phone}\n`
        } else {
          res += `${usr.phone}\n`
        }
      } else {
        onError('Selected user has no phone')
      }
      setSMSList(res)
    } else if (type === 'email') {
      const cleanOriginalList = emailList.trim()
      res = cleanOriginalList
      if (cleanOriginalList.length > 0) { res += '\n' }
      if (usr?.email) {
        res += `${usr.email}\n`
      } else {
        onError('Selected user has no email')
      }
      setEmailList(res)
    }
  }

  const onSubmit = () => {
    setIsSaving(true)

    let tempSMSList = [];
      let tempEmailList = []

    if (smsList.trim().length > 0) {
      // check phone numbers
      tempSMSList = smsList.trim().split('\n')
      let wrongNumber = ''
      const isSMSPass = tempSMSList.every(num => {
        const check = SMSPhoneChecker(num)
        if (!check) { wrongNumber = num }
        return check
      })
      if (!isSMSPass) {
        setIsSaving(false)
        return setSMSListError(`Entry: ${wrongNumber} is invalid`)
      }
    }

    if (emailList.trim().length > 0) {
      // check emails
      tempEmailList = emailList.trim().split('\n')
      let wrongEmail = ''
      const isEmailPass = tempEmailList.every(email => {
        const check = EmailChecker(email)
        if (!check) { wrongEmail = email }
        return check
      })
      if (!isEmailPass) {
        setIsSaving(false)
        return setEmailListError(`Entry: ${wrongEmail} is invalid`)
      }
    }

    if (tempSMSList.length === 0 && tempEmailList.length === 0) {
      setIsSaving(false)
      return onError('Please fill in at least 1 recipient before sending')
    }

    sendReviewLinks(
      {
        uid,
        smsList: JSON.stringify(tempSMSList),
        emailList: JSON.stringify(tempEmailList),
        isOnDebug: isDebug,
        serverUrl: ServerBaseURL
      },
      onSuccess,
      onError)(dispatch)

    return ()=>{}
  }

  const renderSMSView = () =>
    <Paper className={classes.paper} elevation={0}>
      <Grid container justifyContent='space-between'>
        <Grid item>
          <Typography variant='h3' className={classes.marginBottom}>Via SMS</Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            className={classes.switchToggle}
            control={
              <Switch
                disabled={isSaving}
                size="small"
                checked={isDebug}
                onChange={(e) => setIsDebug(e.target.checked)}
              />
            }
            label="Test Mode"
          />
          <FormHelperText>SMS will actually be sent when switched off</FormHelperText>
        </Grid>
      </Grid>
      <FormControl fullWidth className={classes.autofillForm}>
        <Autocomplete
          disabled={isSaving}
          options={allCustomers}
          noOptionsText='No Customers'
          getOptionLabel={(option) => `${option.id}. ${option.firstName} ${option.lastName}`}
          onChange={(e, v) => selectUser(v, 'sms')}
          renderInput={(params) =>
            <TextField {...params} label='Select a customer' variant='outlined' size='small'/>
          }
        />
      </FormControl>
      <FormControl fullWidth className={classes.marginBottom}>
        <TextareaAutosize
          minRows={3} disabled={isSaving}
          className={classes.customTextArea}
          value={smsList}
          placeholder='List of phone numbers to send here...'
          onChange={e => setSMSList(e.currentTarget.value)}
        />
        <FormHelperText error={smsListError.length > 0}>
          { smsListError.length > 0 ? smsListError : 'Phone number must begin with a country code prefixed by \'+\'. Eg: +16471234567' }
        </FormHelperText>
      </FormControl>
    </Paper>

  const renderEmailView = () =>
    <Paper className={classes.paper} elevation={0}>
      <Typography variant='h3'>Via Email</Typography>
      <FormHelperText>Email will be sent from { currentEmail && currentEmail.id === currentReviewCheck.set?.head?.emailAccountId ? `${currentEmail.name} (${currentEmail?.mailAccount})` : "Default LMS" }</FormHelperText>
      <FormControl fullWidth className={classes.autofillForm}>
        <Autocomplete
          disabled={isSaving}
          options={allCustomers}
          noOptionsText='No Customers'
          getOptionLabel={(option) => `${option.id}. ${option.firstName} ${option.lastName}`}
          onChange={(e, v) => selectUser(v, 'email')}
          renderInput={(params) =>
            <TextField {...params} label='Select a customer' variant='outlined' size='small'/>
          }
        />
      </FormControl>
      <FormControl fullWidth className={classes.marginBottom}>
        <TextareaAutosize
          minRows={3} disabled={isSaving}
          className={classes.customTextArea}
          value={emailList}
          placeholder='List of emails to send here...'
          onChange={e => setEmailList(e.currentTarget.value)}
        />
        <FormHelperText error={emailListError.length > 0}>{ emailListError }</FormHelperText>
      </FormControl>
    </Paper>

  const renderForm = () =>
    <Grid container justifyContent='flex-end'>
      <Grid item xs={12}>{ renderSMSView() }</Grid>
      <Grid item xs={12}>{ renderEmailView() }</Grid>
      <Grid item>
        <Button color='primary'
                variant='contained'
                disabled={isSaving}
                onClick={() => onSubmit()}>
          { isSaving ? 'Sending Links' : 'Send Review Links' }
        </Button>
      </Grid>
    </Grid>

  const renderBackButton = () =>
    <Button color='secondary'
            variant='contained'
            onClick={() => navigate('/app/review/set')}>
      Back to Review Sets
    </Button>


  return (
    <ViewHead functionId={Functions.Review_Set_Send} topButtons={renderBackButton()}>
      {
        isLoaded
          ?
          renderForm()
          :
          <Typography variant='h3'>{displayMessage}</Typography>
      }
    </ViewHead>
  )

}

export default ReviewSenderView
