import React, {useState} from 'react'
import {Box, Button, FormControlLabel, Radio, RadioGroup} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import DraggableDialog, {dialogButtonData} from '../../mui/dialog/DraggableDialog'
import timeSpanGroupByUnit from './timeSpanGroupByUnit'

function GroupUnitSelection({defaultGroupBy, setGroupUnitFromPicker, groupBy}) {
    const GROUP_UNITS = Object.fromEntries(timeSpanGroupByUnit.map(item => [item.label, item.value]))

    const [open, setOpen] = useState(false)
    let capGroupBy = null
    if (groupBy) {
        capGroupBy = groupBy.charAt(0).toUpperCase() + groupBy.slice(1)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleResetButtonClick = () => {
        setGroupUnitFromPicker(defaultGroupBy)
    }

    const resetButton = {
        ...dialogButtonData,
        label: 'reset',
        variant: 'text',
        style: {
            color: '#ccc',
            margin: '0 10px 0 0',
            border: '1px solid grey'
        },
        onClick: handleResetButtonClick
    }

    const submitButton = {
        ...dialogButtonData,
        label: 'submit',
        onClick: handleClose,
        style: {
            backgroundColor: 'rgba(80, 88, 236)',
            width: '100px'
        }
    }

    return (
        <Box>
            <Button
                onClick={handleOpen}
                sx={{
                    backgroundColor: 'rgba(0,0,0,0)',
                    color: '#000',
                    fontWeight: '400',
                    border: 'solid 1px grey'
                }}
            >
                Group Unit: {groupBy}
            </Button>
            <DraggableDialog
                open={open}
                onClose={handleClose}
                title={`Group Unit: ${capGroupBy}`}
                footerText={`Group Unit: ${capGroupBy}`}
                dialogCustomButtons={[resetButton, submitButton]}
            >
                <FormControl>
                    <RadioGroup value={capGroupBy}>
                        {Object.keys(GROUP_UNITS).map(key => (
                            <FormControlLabel
                                key={key}
                                value={key}
                                control={<Radio />}
                                label={key}
                                onClick={() => {
                                    setGroupUnitFromPicker(GROUP_UNITS[key])
                                }}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </DraggableDialog>
        </Box>
    )
}

export default GroupUnitSelection
