import React, { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useInterviewEditorStyles } from '../interview/interviewHelper';
import { editCustomerNote, sendErrorMessage } from '../../../actions';
import SubmitButton from '../../../components/mui/button/SubmitButton';
import { noteMaxLength } from '../../../components/form/NoteData';

function EditNoteDialogue({ data, onSubmitSuccess }) {

  const dispatch = useDispatch()
  const classes = useInterviewEditorStyles()

  const [isSaving, setIsSaving] = useState(false)
  const [noteContent, setNoteContent] = useState(data.notes || '')
  const [noteError, setNoteError] = useState(null)

  const onError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }

  const onSuccess = (msg) => {
    setIsSaving(false)
    onSubmitSuccess(msg)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSaving(true)
    setNoteError(null)

    if (noteContent.length > noteMaxLength) {
      setIsSaving(false)
      setNoteError(`Comment cannot be longer than ${noteMaxLength} characters`)
      return
    }

    await editCustomerNote({
      customerId: data.customerId.toString(),
      noteId: data.noteId,
      notes: noteContent
    }, onSuccess, onError)(dispatch)
  }

  const renderTextField = () =>
    <Grid container>
      <Grid item xs={12}>
        <TextField variant='outlined' fullWidth size='small'
                   multiline rows={7} required
                   label='Comment' name='notes'
                   value={noteContent}
                   onChange={(e) => setNoteContent(e.target.value)}
                   error={!!noteError}
                   helperText={noteError || ''}
        />
      </Grid>
    </Grid>

  const renderSubmit = () =>
    <Grid container spacing={3} justifyContent='flex-end' alignItems='center'
          className={classes.marginTop}>
      <Grid item>
        <SubmitButton isSaving={isSaving} text='Save' isSavingText='Saving'
                      fullWidth variant='contained' color='primary'
        />
      </Grid>
    </Grid>

  return (
    <form onSubmit={onSubmit}>
      <Typography variant='h4' className={classes.marginBottom}>Edit Comment</Typography>
      { renderTextField() }
      { renderSubmit() }
    </form>
  )
}

export default EditNoteDialogue
