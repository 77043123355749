import React, { useEffect, useState } from 'react';
import { TablePagination, Typography, useTheme , Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import MonitorListItem from './monitor/data/MonitorListItem';
import { ChangeRowsPerPage } from '../../actions';
import TopUpListItem from './topup/data/TopUpListItem';
import { PAGE_PARAMS } from '../../constants';

const usePagedTableStyles = makeStyles((theme) => ({
  paginationAction: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  totalCount: {
    textAlign: 'end',
    color: 'gray',
    margin: theme.spacing(-2, 2, 0 , 0),
  }
}))

function TablePaginationActions(props) {
  const classes = usePagedTableStyles()
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0)
  const handleBackButtonClick = (event) => onPageChange(event, page - 1)
  const handleNextButtonClick = (event) => onPageChange(event, page + 1)
  const handleLastPageButtonClick = (event) => onPageChange(event,
    Math.max(0, Math.ceil(count / rowsPerPage) - 1))

  return (
    <div className={classes.paginationAction}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} size="large">
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} size="large">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        size="large">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        size="large">
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired
}

function PagedMatricTable(props) {
  const {rows,isMonitor,total,handleDelete,handleRead,handleRefresh,handleOpenImage,handleEdit,handleTopUp} = props
  const classes = usePagedTableStyles()
  const dispatch = useDispatch()
  const userRowsSetting = parseInt(useSelector(state => state.userSetting.rowsPerPage),10)

  const [page, setPage] = useState(0)
  const [rpp, setRPP] = useState(userRowsSetting || PAGE_PARAMS.defaultPageSize)
  const [showRows, setShowRows] = useState([])

  const handleChangePage = (event, newPage) => setPage(newPage)
  const handleChangeRowsPerPage = (event) => {
    const newRPP = parseInt(event.target.value, 10)
    ChangeRowsPerPage(newRPP)(dispatch)
    setRPP(newRPP)
    setPage(0)
  }

  useEffect(() => {
    const tempRows = rpp > 0
      ? rows.slice(page * rpp, page * rpp + rpp)
      : rows
    setShowRows(tempRows)
  }, [rows, page, rpp])

  return (
    <div>
      <Typography variant='body2' className={classes.totalCount}>
        Posts: {rows.length} matches {isMonitor ? `, ${total} total results` : '' }
      </Typography>
      <Divider />
      {
        showRows.map((row,index) => (
          <div key={index}>
            {
              isMonitor
                ? <MonitorListItem postData={row}
                                   handleDelete={handleDelete}
                                   handleRead={handleRead}
                                   handleRefresh={handleRefresh}
                                   handleOpenImage={handleOpenImage}
                />
                : <TopUpListItem postData={row}
                                 handleEdit={handleEdit}
                                 handleDelete={handleDelete}
                                 handleRefresh={handleRefresh}
                                 handleOpenImage={handleOpenImage}
                                 handleTopUp={handleTopUp}
                />
            }
            <Divider />
          </div>
        ))
      }
      <TablePagination
        rowsPerPageOptions={PAGE_PARAMS.rowsPerPageOptions}
        count={rows.length}
        rowsPerPage={rpp}
        page={page}
        component='div'
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  )
}

PagedMatricTable.propTypes = {
  rows: PropTypes.shape([]).isRequired
}

export default PagedMatricTable
