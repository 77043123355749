import React from 'react';
import {connect} from 'react-redux'
import { Functions } from '../../../utils/Functions';
import useAuthCheck from '../../../hooks/useAuthCheck';

import TablePage from './TablePage';
import AddClass from './AddClass';
import AddClassDeliver from './AddClassDeliver';
import AddHomework from './AddHomework';
import ReadHomework from './ReadHomework';
import ReadClass from './ReadClass';
import ReadClassReview from './ReadClassReview';
import SyllabusProgressView from './SyllabusProgressView';


function ClassInfo({classTableStatus, oneClass, oneHistory, currentHomework, oneHomework}) {
  const [isEdit] = useAuthCheck(
    [
      Functions.Training_Class_Edit_Class,
    ])

  const renderTable = ()=>{
    switch(classTableStatus){
      case "ADDCLASS":
        return <AddClass edit={false} clone={false} currentClass={{}}/>
      // case "UPDATE":
      //   return <AddClass edit={true} currentClass={currentClass}/>
      // case "CLONE":
      //   return <AddClass edit={false} clone={true} currentClass={currentClass}/>
      case "READ":
        return <ReadClass isEdit={isEdit}/>;
      case "AddClassDeliver":
        return <AddClassDeliver isEdit={isEdit} edit={false} oneClass={oneClass} currentHistory={{}}/>
      case "UpdateClassDeliver":
        return <AddClassDeliver isEdit={isEdit} edit oneClass={oneClass} currentHistory={{oneHistory}}/>
      case "ReadReview":
        return <ReadClassReview isEdit={isEdit} oneClass={oneClass} currentHistory={{oneHistory}}/>
      case "AddHomework":
        return <AddHomework edit={false} oneClass={oneClass} currentHomework={{}}/>
      case "UpdateHomework":
        return <AddHomework edit oneClass={oneClass} currentHomework={currentHomework}/>
      case "ReadHomework":
        return <ReadHomework isEdit={isEdit} oneClass={oneClass} oneHomework={oneHomework}/>
      case "SyllabusProgress":
        return <SyllabusProgressView data={oneClass}/>
      default:
        return <TablePage isEdit={isEdit}/>
    }
  }

  return (
        <div>
      {renderTable()}
      </div>
  )
}

const mapStateToProps = (state) => ({

  classTableStatus: state.classes?.classTableStatus,
  homework: state.homework?.homework?.data,
  currentClass: state.classes?.currentClass,
  oneClass: state.classes?.oneClass,
  oneHistory: state.classes?.oneHistory?.data,
  currentHomework: state.classes?.currentHomework?.data?.homework,
  oneHomework: state.classes?.oneHomework?.data?.homework
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ClassInfo);
