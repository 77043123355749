import { CampaignActionType } from '../../actions/campaign/campaignActions';

const defaultState = {
  allCampaigns: [],

  allCategories: [],
  allCampaignUsers: [],

  currentCampaign: {},
  currentCampaignPosters: {},
  currentPoster: {},

  campaignFetchProps: {},
  posterFetchProps: {},
}

export const campaignReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case CampaignActionType.FetchAllCampaigns:
      return { ...state, allCampaigns: action.payload }
    case CampaignActionType.FetchCampaign:
      return { ...state, currentCampaign: action.payload }
    case CampaignActionType.FetchCampaignPosters:
      return { ...state, currentCampaignPosters: action.payload }

    case CampaignActionType.FetchCampaignCategories:
      return { ...state, allCategories: action.payload }
    case CampaignActionType.FetchCampaignUsers:
      return { ...state, allCampaignUsers: action.payload }
    case CampaignActionType.FetchCampaignPosterDetails:
      return { ...state, currentPoster: action.payload }

    case CampaignActionType.FetchCampaignProps:
      return { ...state, campaignFetchProps: action.payload }
    case CampaignActionType.FetchCampaignPosterProps:
      return { ...state, posterFetchProps: action.payload }

    default:
      return state
  }
}
export default campaignReducer