import React, { memo, useEffect, useRef, useState } from 'react';
import { Paper, Popper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import {isOverflown}  from '../../../utils/Scripts';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
    lineHeight: "24px",
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    "& .cellValue": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  wrapCell: {
    "& .cellValue": {
      whiteSpace: "pre-line",
      wordBreak: "break-word"
    }
  },
  hide: {
    display: "none"
  }
}));

const GridCellExpand = memo((props) => {
  const { minWidth, maxWidth, defaultValue, expandValue, wrapCell = false, forceExpand = false } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    // When forceExpand, always expand; when not, expand when text overflow
    setShowPopper(forceExpand ? true : isOverflown(cellValue.current))
    setAnchorEl(cellDiv.current)
    setShowFullCell(true)
  };

  const handleMouseLeave = () => setShowFullCell(false)

  useEffect(() => {
    if (!showFullCell) return undefined

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      className={clsx(classes.root, {
        [classes.wrapCell]: wrapCell
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          maxWidth,
          minWidth,
          maxHeight: '70vh',
          display: "block",
          position: "absolute",
          top: 0
        }}
      />
      <div ref={cellValue} className="cellValue">
        { defaultValue }
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ maxWidth, minWidth, marginLeft: -17 }}
        >
          <Paper
            className={clsx({
              [classes.hide]: !expandValue
            })}>{ expandValue }</Paper>
        </Popper>
      )}
    </div>
  )
})

export default GridCellExpand
