import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import {
  Table, TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
, Autocomplete } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { genUserDataByCatData } from './trackingChartsGenerator';
import { useChartStyles } from './trackingHelper';
import LoadingScreen from '../../../components/public/LoadingScreen';

function TrackingUserTabView({ data, isLoaded, allUsers, isShowActive, selectedUser, onSelectedUserChange }) {

  const classes = useChartStyles()
  const [maxTotalResult, setMaxTotalResult] = useState(0)
  const [organizedUserData, setOrganizedUserData] = useState([])

  const barGraphOptions = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false
      }
    },
  }

  useEffect(() => {
    // Organize table data upon selection
    const userData = []
    if (data) {
      const tempFormData = data.filter(list => !!list.form_id)
      const tempCampaignData = data.filter(list => !!list.campaign_id)
      const tempQRCodeData = data.filter(list => !!list.qrCode_id)

      if (selectedUser) {
        const totalCampaignCount = tempCampaignData
          .filter(c => c.user_fullName === selectedUser?.fullName && (!isShowActive || c.campaign_isActive))
          .length
        const totalFormCount = tempFormData
          .filter(c => c.user_fullName === selectedUser?.fullName && (!isShowActive || c.form_isActive))
          .length
        const totalQRCount = tempQRCodeData
          .filter(c => c.user_fullName === selectedUser?.fullName && (!isShowActive || c.qrCode_isActive))
          .length
        let lastSeen = data.find(c => c.user_fullName === selectedUser.fullName)
        if (lastSeen) {
          lastSeen = moment(lastSeen?.tracker_createdAt).format('L LT')
        }
        userData.push({ name: 'Campaign Scans', data: totalCampaignCount })
        userData.push({ name: 'QR Code Scans', data: totalQRCount })
        userData.push({ name: 'Form Submissions', data: totalFormCount })
        userData.push({ name: 'Last Seen', data: lastSeen ?? 'Never' })
      }

      setOrganizedUserData(userData)
    }

  }, [data, selectedUser, isShowActive])

  const renderSelectUserField = () =>
    <Grid item xs={12}>
      <FormControl fullWidth className={classes.marginBottom}>
        <Autocomplete
          options={allUsers}
          noOptionsText='No User'
          getOptionLabel={(option) => `${option.id}. ${option.fullName}`}
          onChange={(e, v) => onSelectedUserChange(v)}
          value={ selectedUser }
          renderInput={(params) =>
            <TextField {...params} label='Select a user...' variant='outlined' size='small'/>
          }
        />
      </FormControl>
    </Grid>

  const userStatTable = () =>
    <Grid item xs={12} className={classes.marginBottom}>
      <Typography variant='h4' className={classes.title}>Overview</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell align="right">Detail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { organizedUserData.map((d) =>
              <TableRow key={d.name}>
                <TableCell>{ d.name }</TableCell>
                <TableCell align="right">{ d.data }</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>


  const generateCharts = () => {
    const graphData = genUserDataByCatData(selectedUser, data,
      ['campaignCategory_name', 'formCategory_name', 'qrCodeCategory_name'],
      isShowActive, ['campaign_isActive', 'form_isActive', 'qrCode_isActive'],
      maxTotalResult, setMaxTotalResult)

    return graphData.labels.length > 0 &&
      <Grid item xs={12}>
        <Typography variant='h4' className={classes.title}>Category</Typography>
        <Bar type='pie'
             className={classes.hBarGraph}
             data={graphData}
             options={barGraphOptions}
        />
      </Grid>
  }

  return (
    <div>
      {
        isLoaded
          ? <Grid container>
            { renderSelectUserField() }
            { selectedUser ? userStatTable() : '' }
            { selectedUser ? generateCharts() : '' }
          </Grid>
          : <LoadingScreen message='Loading...' />
      }
    </div>
  )
}

export default TrackingUserTabView
