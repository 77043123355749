import Button from '@mui/material/Button';
import { Download } from 'react-feather';
import React from 'react';
import { MULTILINE_SEPARATOR } from '../../../constants';
import { isMobile } from '../../../utils/Scripts';

function CustomGridExportButton({ apiRef }) {
  return <Button
    variant='text'
    color='primary'
    size='small'
    startIcon={<Download size='17' />}
    onClick={() => {
      let csvData = apiRef?.current?.getDataAsCsv()
      if (csvData) {
        // kind of buggy
        csvData = csvData.replaceAll(`"${MULTILINE_SEPARATOR}`, '"')
        csvData = csvData.replaceAll(`${MULTILINE_SEPARATOR}"`, '"')
        csvData = csvData.replaceAll(MULTILINE_SEPARATOR, '"')

        const url = window.URL.createObjectURL(new Blob([`\ufeff${  csvData}`], { type: 'text/csv;charset=utf-8;' }))
        const link = document.createElement('a')
        link.href = url
        link.download = 'data.csv'
        document.body.appendChild(link)

        if (!isMobile()) { link.target = '_blank' }
        link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}));
        link.parentNode.removeChild(link)
      }
    }}
  >
    Export
  </Button>
}

export default CustomGridExportButton