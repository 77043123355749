import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'
import {fetchProducts, fetchCategories} from "../../actions";
import ProductGrid from "./Product";
import LoadingScreen from "../../components/public/LoadingScreen";

function ProductPage({isEdit, fetchCategories ,isOpen ,setIsOpen}) {
    const [isLoading,setLoading]=useState(false);

    useEffect(()=>{
      fetchCategories();
        },[fetchCategories]
    )
    
    return (
        <div>
            {!isLoading ? <ProductGrid isEdit={isEdit} setLoading={setLoading} isOpen={isOpen} setIsOpen={setIsOpen}/>:
            <LoadingScreen message="Loading..."/>}
        </div>
    );
}

const mapStateToProps = (state) => ({
        products: state.products?.products
    })

export default connect(mapStateToProps, {fetchProducts, fetchCategories})(ProductPage);
