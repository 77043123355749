import {ReviewActionType} from '../../actions/review/reviewActions'

const defaultState = {
    allReviewSets: [],
    currentReviewSet: {},
    reviewFetchProps: {},
    reviewSetFetchProps: {},
    reviewM2WFetchProps: {},
    reviewProductFetchProps: {},

    allReviews: [],
    currentReviewCheck: {},

    allM2WReviews: [],
    currentM2WReview: {},

    currentReviewTab: 0,
    allCustomers: [],

    allReviewedProducts: [],
    productReviews: [],

    productReviewById: {},
    reviewedCategories: [],
    currentCatTab: 0,
    selectedProductId: -1,
    saved:false
}

export const reviewReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case ReviewActionType.FetchAllReviewSets:
            return {...state, allReviewSets: action.payload}
        case ReviewActionType.FetchReviewSet:
            return {...state, currentReviewSet: action.payload}

        case ReviewActionType.SetReviewFetchProps:
            return {...state, reviewFetchProps: action.payload}
        case ReviewActionType.SetReviewSetFetchProps:
            return {...state, reviewSetFetchProps: action.payload}
        case ReviewActionType.SetReviewM2WListFetchProps:
            return {...state, reviewM2WFetchProps: action.payload}
        case ReviewActionType.SetReviewProductFetchProps:
            return {...state, reviewProductFetchProps: action.payload}

        case ReviewActionType.FetchAllReviews:
            return {...state, allReviews: action.payload}
        case ReviewActionType.FetchReviewCheck:
            return {...state, currentReviewCheck: action.payload}

        case ReviewActionType.FetchAllM2WReviews:
            return {...state, allM2WReviews: action.payload}
        case ReviewActionType.FetchM2WReview:
            return {...state, currentM2WReview: action.payload}

        case ReviewActionType.ChangeReviewTab:
            return {...state, currentReviewTab: action.payload}
        case ReviewActionType.FetchAllCustomerForReview:
            return {...state, allCustomers: action.payload}

        case ReviewActionType.FetchReviewProductList:
            return {...state, allReviewedProducts: action.payload}

        case ReviewActionType.FetchProductReviews:
            return {...state, productReviews: action.payload}

        case ReviewActionType.FetchProductReviewById:
            return {...state, productReviewById: action.payload}

        case ReviewActionType.FetchAllReviewedCategories:
            return {...state, reviewedCategories: action.payload}

        case ReviewActionType.ChangeCategoryReviewTab:
            return {...state, currentCatTab: action.payload}

        case ReviewActionType.SetSelectedProductId:
            return {...state, selectedProductId: action.payload}

        case ReviewActionType.FormStatus:
            return {...state, saved: action.payload}
        default:
            return state
    }
}
export default reviewReducer
