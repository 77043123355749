import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from "../components/public/LoadingScreen";
import LocalStorage from "../utils/LocalStorage";

function RootView() {
    const  navigate = useNavigate();
    const checkToken = ()=>{
        const token = LocalStorage.getToken();
        if(token){
            navigate('/app');
        }else{
            navigate('/auth/login')
        }
    }

    useEffect(checkToken,[]);


    return (
        <LoadingScreen message="正在验证身份！"/>
    );
}

export default RootView;