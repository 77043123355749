import React from 'react';
import { FormHelperText, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { usePosterTemplateStatusStyles } from '../../posterGenHelper';

function PosterStatusPanel({ posterList, setCurrentStep, currentStep }) {

  const classes = usePosterTemplateStatusStyles()

  return (
    <Grid container spacing={1} justifyContent='center'>
      <Grid item xs={12}>
        <FormHelperText className={classes.statusTextHelper}>
          Poster Editing Progress
        </FormHelperText>
      </Grid>
      {
        posterList.map((poster, idx) =>
          <Grid item key={idx}>
            <Typography variant='body2'
                        onClick={() => setCurrentStep(idx + 1)}
                        className={clsx(classes.statusText, {
                          'active': !!poster[idx],
                          'current': idx + 1 === currentStep
                        })}>{ idx + 1 }</Typography>
          </Grid>
        )
      }
    </Grid>
  )
}

export default PosterStatusPanel
