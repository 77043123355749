import React, {useState} from 'react';
import {  useSelector } from 'react-redux';
import TableRender from "./TableRender";
import LoadingScreen from "../../components/public/LoadingScreen";



function EmployeeTable({isEdit}) {
  const employees = useSelector(state => state.employee?.employeesPerPage)

    const [isLoading,setIsLoading]=useState(false)

    return (<div>
        {!isLoading
            ?
            <TableRender employees={employees} setIsLoading={setIsLoading} isEdit={isEdit}/>
            :
            <LoadingScreen message='Loading...' />
        }
    </div>)
}

// const mapStateToProps = (state) => ({
//         employees: state.employee?.employeesPerPage
//     })

// export default connect(mapStateToProps, {fetchEmployees})(EmployeeTable)
export default EmployeeTable
