import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import CardListCard from './CardListCard';

function CardList({ data }) {
  return <Grid container spacing={2}>
    {
      data.map((card,index) =><Grid item xs={12} md={6} key={index}>
          <CardListCard
            rows={card.rows}
            disabled={!card.isActive}
          />
        </Grid>

      )
    }
  </Grid>
}

CardList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      rows: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        body: PropTypes.node.isRequired,
      })).isRequired,
      isActive: PropTypes.bool,
    })
  ).isRequired
};

export default CardList
