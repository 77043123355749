import React, {useEffect} from 'react'
import {Container, Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {useDispatch, useSelector} from 'react-redux'
import clsx from 'clsx'
import BreadNav from '../Nav/BreadNav'
import {fetchSystemNavi} from '../../actions'
import Page from './Page'
import {DEFAULT_APP_TITLE} from '../../constants'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    pageTitle: {
        marginTop: '1rem',
        marginBottom: '-0.5rem'
    },
    pageTopButton: {
        marginBottom: '1rem'
    },
    noPageButton: {
        padding: '0!important'
    }
}))

function ViewHead({functionId, topButtons, children, isShowTitle = true, isShowNav = true, noMaxWidth = false}) {
    const classes = useStyles()
    const navi = useSelector(state => state?.auth?.navi)
    const dispatch = useDispatch()
    const loadNavi = () => {
        if (navi && functionId && functionId !== 0) {
            if (!navi[functionId]) {
                fetchSystemNavi(functionId)(dispatch)
            }
        }
        document.title = navi[functionId] ? `${navi[functionId][1].name}-${DEFAULT_APP_TITLE}` : DEFAULT_APP_TITLE
    }
    useEffect(loadNavi, [navi])
    return (
        <Page className={`${classes.root} viewContainer`}>
            <Container maxWidth={noMaxWidth ? false : 'lg'}>
                <Grid alignItems="center" container justifyContent="space-between" spacing={3}>
                    <Grid item xs={12}>
                        {isShowNav ? <BreadNav id={functionId} /> : null}
                        {isShowTitle && (
                            <Typography variant="h3" color="textPrimary" className={classes.pageTitle}>
                                {navi[functionId] && navi[functionId][navi[functionId].length - 1].name}
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={clsx({
                            [classes.pageTopButton]: topButtons,
                            [classes.noPageButton]: !topButtons && !isShowTitle
                        })}
                    >
                        {topButtons}
                    </Grid>
                </Grid>
                {children}
            </Container>
        </Page>
    )
}

export default ViewHead
