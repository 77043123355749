import React, {useEffect, useState} from 'react';
import {IconButton, Paper, Tooltip} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {InfoOutlined} from '@mui/icons-material';
import {Eye} from "react-feather";
import {
    fetchTrainingMessage,
    fetchOneMessage,
    deleteMessage,
    fetchMessageInfo,
    activateMessages
} from '../../../actions';
import EnhancedTableToolbar from './EnhancedTableToolBar';
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog';
import PageGrid from '../../../components/mui/datagrid/PageGrid';
import {CATEGORY_ITEMS} from '../../../constants';
import DraggableDialog from "../../../components/mui/dialog/DraggableDialog";
import EditMessage from "./EditMessage";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    editIcon: {
        cursor: 'pointer'
    },
    tableCell: {
        width: 80,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px'
    },
    funcCell: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    }
}));


function DataTable({isEdit, handleModalOpen}) {

    const messages = useSelector(state => state.trainingMessage?.trainingMessages)
    const activationMessage = useSelector(state => state.trainingMessage?.activationMessage)

    const classesTable = useStyles();
    const [selected, setSelected] = React.useState([])
    // const [isLoading, setIsLoading] = React.useState(false)

    const [openRead, setOpenRead] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    // const [open, setOpen] = React.useState(false);
    // const [hash, setHash] = React.useState('init');
    // const [delObj, setDelObj] = React.useState(null);

    const open = false
    const hash = 'init'
    const delObj = null
    const dispatch = useDispatch();

    const columns = [
        {
            field: '_actionField',
            headerName: ' ',
            resizable: false,
            disableClickEventBubbling: true,
            filterable: false,
            width: 50,
            renderCell: params => (
                <div style={{position: 'relative', width: '100%'}}>
                    {isEdit && (<div className={classesTable.funcCell}>
                            <Tooltip title="Read" placement="top">
                                <IconButton color="primary" component="span" size="small"
                                            onClick={async (event) => {
                                                event.stopPropagation();
                                                // setIsLoading(true)
                                                await fetchOneMessage(params.row.id, )(dispatch);
                                                await setOpenRead(true);
                                            }}
                                >
                                    <Eye size={20}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </div>
            )
        },
        {field: 'id', headerName: 'Id', width: 50, sortable: false},
        {
            field: '_info', headerName: 'Info', resizable: false,
            disableClickEventBubbling: true, width: 95,
            filterable: false, renderCell: (params) =>
                <div>
                    <Tooltip title="Info" placement="top">
                        <IconButton color="primary"
                                    size="small"
                                    component="span"
                                    onClick={async (event) => {
                                        event.stopPropagation();
                                        await fetchMessageInfo(params.row.id)(dispatch);
                                        handleModalOpen();
                                    }}
                        >
                            <InfoOutlined size={20}/>
                        </IconButton>
                    </Tooltip>
                </div>
        },

        {
            field: 'content', headerName: 'Content', width: 350, sortable: false,
            renderCell: (params) => params.row.isActive ? params.row.content : <del>{params.row.content}</del>
        },
        {
            field: 'submissions', headerName: 'Read/Sent', width: 100, sortable: false,
            renderCell: (params) => calculateReadSent(params.row.submissions)
        },
        // { field: 'isActive', headerName: 'IsActive', width: 130 },
        {
            field: 'creator', headerName: 'Creator', width: 150,
            valueFormatter: ({value}) => `${value.firstName} ${value.lastName}`
        },
        {
            field: 'created',
            headerName: 'Created',
            width: 180,
            sortable: false,
            valueFormatter: ({value}) => moment(value).format('L LT')
        }
    ];

    const [searchArgs, setSearchArgs] = useState({
        searchKey: undefined,
        creator: CATEGORY_ITEMS.all
    });
    const fetchProps = useSelector(state => state.trainingMessage.trainingMessageFetchBody)
    const handleSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase();
        setSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }));
    };
    const handleCreatorChange = newCreator => setSearchArgs(prevState => ({
        ...prevState,
        creator: newCreator
    }));

    const calculateReadSent = submissions => {
        const sent = submissions.length;
        const read = submissions.filter(submission => submission.status === 'read').length;
        return `${read}/${sent}`
    }

    const onActive = isActive => {
        const data = {ids: selected, isActive};
        activateMessages(data, fetchProps)(dispatch);
    };

    useEffect(() => {
        if (activationMessage) {
            setSelected([]);
        }
    }, [activationMessage]);

    return (
            // !isLoading?

            <div className={classesTable.root}>
                <Paper className={classesTable.paper}>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        handleChange={handleSearchChange} onActive={onActive}
                        handleChangeButton={handleCreatorChange}
                        category={searchArgs.creator}
                        isLoading={isLoading}
                    />

                    <PageGrid
                        columns={columns}
                        datas={messages}
                        searchArgs={searchArgs}
                        fetchDatas={fetchTrainingMessage}
                        selected={selected}
                        setSelected={setSelected}
                        setIsLoading={setIsLoading}
                    />

                    <ResponsiveDialog
                        isOpen={open} openHash={hash}
                        title="Delete Parameter Confirm"
                        content="Are you sure?"
                        Buttons={[
                            {
                                name: 'Yes',
                                event: () => {
                                    deleteMessage(delObj, fetchProps)(dispatch);
                                }
                            },
                            {
                                name: 'Cancel'
                            }
                        ]}/>

                </Paper>
                <DraggableDialog open={Boolean(openRead)} onClose={() => setOpenRead(null)}
                                 title='View Message' isLoaded={Boolean(openRead)}>
                    <Paper elevation={0}>
                        <EditMessage />
                    </Paper>
                </DraggableDialog>

            </div>

                // :<LoadingScreen message={'Loading...'}/>

    )
}

export default DataTable;

