import {useDispatch, useSelector} from 'react-redux'
import React from 'react'
import {sendSuccessMessage} from '../../../../actions'
import ContractTermEditor from './ContractTermEditor'
import {AdministrativeActionType} from '../../../../actions/administrative/administrativeAction'

function TermPreviewView({currentTerm, myEditor}) {
    const dispatch = useDispatch()
    const termList = useSelector(state => state.administrative.fixedTerms)

    const onSuccess = async (msg, term) => {
        sendSuccessMessage(dispatch, msg)
        if (term.id !== currentTerm?.id) {
            dispatch({type: AdministrativeActionType.FetchFixedTerms, payload: [...termList, term]})
            myEditor.execute('insertContractTerm', term.id)
            myEditor.editing.view.focus()
        } else {
            myEditor.execute('insertContractTerm', term.id)
            myEditor.editing.view.focus()
        }
    }

    return (
        <div>
            <ContractTermEditor onSubmitSuccess={onSuccess} isEdit originalData={currentTerm} saveAs />
        </div>
    )
}

export default TermPreviewView
