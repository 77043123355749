import React from 'react';
import { Navigate } from 'react-router';
import EditQRCodeView from '../../../views/qrcode/qrcodeDetails/editor/editQRCodeView';
import CreateQRCodeView from '../../../views/qrcode/qrcodeDetails/editor/createQRCodeView';
import QRCodeDetailsView from '../../../views/qrcode/qrcodeDetails';
import EditQRCodeSetViw from '../../../views/qrcode/qrcodeList/editor/editQRCodeSetView';
import CreateQRCodeSetView from '../../../views/qrcode/qrcodeList/editor/createQRCodeSetView';
import QRCodeCategoryManagerView from '../../../views/qrcode/qrcodeCategory';
import QRCodeListView from '../../../views/qrcode/qrcodeList';

const qrcodeRoutes = [
  {
    path: 'qr',
    routes: [
      {
        path:'',
        exact: 'true',
        component:<QRCodeListView/>
      },
      {
        path:'category',
        exact: 'true',
        component:<QRCodeCategoryManagerView/>
      },
      {
        path:'add',
        exact: 'true',
        component:<CreateQRCodeSetView/>
      },
      {
        path:'edit/:id',
        exact: 'true',
        component:<EditQRCodeSetViw/>
      },
      {
        path:'view/:id',
        exact: 'true',
        component:<QRCodeDetailsView/>
      },
      {
        path:'view/:setId/add',
        exact: 'true',
        component:<CreateQRCodeView/>
      },
      {
        path:'view/:setId/edit/:codeId',
        exact: 'true',
        component:<EditQRCodeView/>
      },
      {
        component:<Navigate to="404"/>
      },
    ]
  }
]

export default qrcodeRoutes
