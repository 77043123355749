import * as yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';

export const QRCODE_STATUS = [
  { name: 'Website', symbol: 'W' },
  { name: 'Pointed', symbol: 'P' },
  { name: 'Scheduled', symbol: 'S' },
  { name: 'Random', symbol: 'R' },
]


export const QRCODE_SET_VALIDATOR = {
  name: yup.string().required("Required")
    .min(1, "Set name must be at least 1 character")
    .max(30, "Set name must be less than 30 characters")
    .trim(),
  guide: yup.string().required("Required")
    .min(1, "Scan guide must be at least 1 character")
    .max(30, "Scan guide must be less than 30 characters")
    .trim(),
  description: yup.lazy((value) => !value ? yup.string() : yup.string()
    .max(255, "Set description must be less than 255 characters")
    .trim())
}

export const QRCODE_VALIDATOR = {
  name: yup.string().required("Required")
    .min(1, "Set name must be at least 1 character")
    .max(30, "Set name must be less than 30 characters")
    .trim(),
  description: yup.lazy((value) => !value ? yup.string() : yup.string()
    .max(255, "Set description must be less than 255 characters")
    .trim()),
  url: yup.lazy((value) => !value ? yup.string() : yup.string()
    .required().url("Must be a valid URL starting with http(s)://").trim())
}

export const useQRCodeListStyles = makeStyles((theme) => ({
  search: {
    padding: theme.spacing(1, 0),
    width: '100%',
  },
  formControl: {
    padding: theme.spacing(1, 0),
    width: '100%',
    [theme.breakpoints.down(475)]: {
      marginTop: theme.spacing(3)
    },
  },
  catSelector: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  activityButtons: {
    paddingBottom: `${theme.spacing(2)}!important`,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding:theme.spacing(2)
  },
}))

export const useQRCodeTableStyles = makeStyles(() => ({
  qrGrid: {
    marginTop: '2rem'
  },
  gridQRImage: {
    width: '100px',
    height: '100px',
    backgroundSize: 'contain',
    cursor:'pointer'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '800px',
    margin: '0 auto',
    '& .modalRoot': {
      padding: '2rem',
      maxWidth: '800px',
      lineHeight: '1.50rem',
      '& h3': {
        margin: '0 0 .25rem 0'
      }
    }
  },
}))

export const useQRCodeModalDisplayStyles = makeStyles(() => ({
  paper: {
    padding: '2rem',
    textAlign: 'center'
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  qrImage: {
    width: '250px',
    height: '250px',
    backgroundSize: 'contain',
  },
  button: {
    width: '100%',
    maxWidth: '300px'
  }
}))

export const useQRCodeDetailsStyles = makeStyles(() => ({
  marginBottom: {
    marginBottom: '0.5rem'
  },
  pageTitle: {
    marginBottom: '0.5rem',
    display: 'inline-block',
  },
  editButton: {
    marginTop: '-0.5rem'
  },
  pageStatus: {
    fontSize: '0.85rem',
    margin: '0 0.25rem'
  },
}))

export const useQRCodeEditorStyles = makeStyles(() => ({
  formControl: {
    marginBottom: '2rem'
  },
  userSelectorFormControl: {
    width: '100%'
  },
  marginLeft: {
    marginLeft: '2rem'
  },
  marginTop: {
    marginTop: '2rem'
  },
  paper: {
    padding: '2rem'
  },
  submitButton: {
    marginTop: '5rem'
  },
  marginReset: {
    marginLeft: '0!important',
  }
}))
