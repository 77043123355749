import React from "react";
import PropTypes from 'prop-types';
import {DataGrid} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import CustomLoadingOverlay from "./CustomLoadingOverlay";

export const CustomDataGridOverlays = {
    loadingOverlay: CustomLoadingOverlay,
    noRowsOverlay: CustomNoRowsOverlay,
}

export default function LDataGrid(props) {
    const {checkbox, totalHeight, toolBar,...rest} = props;

    const customComponents = toolBar? {...CustomDataGridOverlays,header:toolBar}: { ...CustomDataGridOverlays };

    return (
        <div style={{height: totalHeight, width: '100%'}}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid {...rest}
                              showToolbar = {!!toolBar}
                              density="compact" autoScroll
                              rowsPerPageOptions={[25,50,100]} components= {customComponents} checkboxSelection={checkbox}/>
                </div>
            </div>
        </div>
    )
}

LDataGrid.propTypes = {
    totalHeight:PropTypes.number.isRequired,
    toolBar:PropTypes.func
}
LDataGrid.defaultProps = {
    toolBar:()=>{}
}
