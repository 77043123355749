import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Grid,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip,
  Typography
} from '@mui/material';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import { useNotesStyles,  } from '../customerData';
import {
    createCustomerPayment, deleteCustomerPayment,
    fetchCustomerPayment,
    sendErrorMessage,
    sendSuccessMessage
} from '../../../actions';
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog';
import LoadingScreen from '../../../components/public/LoadingScreen';
import AddCategoryInput from '../../../components/form/CategoryInput';

function EditCustomerPayment({
                           currentCustomer,
                           fetchCustomerPayment,
                           createCustomerPayment,
                           deleteCustomerPayment
                         }) {
  const classes = useNotesStyles()
  const dispatch = useDispatch()
  const allPayment = useSelector(state => state.customer.currentCustomerPayment)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [hash, setHash] = useState(Math.random().toString(16).substring(2, 10))
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
  const [selectedForDel, setSelectedForDel] = useState(-1)


  useEffect(() => {
    if (currentCustomer) {
      (async () => {
        setIsLoaded(fetchCustomerPayment(currentCustomer.id))
      })()
    } else {
      setIsLoaded(false)
    }
  }, [currentCustomer])

  const handleDeleteOpen = (id) => {
    setSelectedForDel(id)
    setOpenDeleteDialogue(true)
    setHash(Math.random().toString(16).substring(2, 10));
  }

  const onAddCB = (id, payment) => {
    setIsSaving(true)
    const cleanPayment = payment.trim()
    const hasChanged = cleanPayment !== ''
    if (hasChanged) {
      createCustomerPayment(currentCustomer.id, cleanPayment,
          (msg) => onOperationSuccess(msg),
          (msg) => onOperationError(msg))
    }
  }
  const onOperationSuccess = (msg) => {
    setIsSaving(false)
    sendSuccessMessage(dispatch, msg)
  }
  const onOperationError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }

  const onDeletePayment = () => {
    deleteCustomerPayment(selectedForDel, currentCustomer.id, onOperationSuccess, onOperationError)
    setSelectedForDel(-1)
    setOpenDeleteDialogue(false)
  }

  const addNewPaymentDialogue = () =>
      <Paper className={classes.notePaperAdd}>
        <Typography variant='h4'>Add New Payment:</Typography>
        <div>
          <AddCategoryInput cb={onAddCB} id='' name='' label='Payment'
                            placeholder='Enter payment here' disabled={isSaving}/>
        </div>
      </Paper>

  const renderTable = () =>
      <TableContainer component={Paper} className={classes.notePaper}>
        <Table size="small">

          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>By</TableCell>
              <TableCell>Payment</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>

            {allPayment.map((payment) =>
                <TableRow key={payment.id}>
                  <TableCell scope="row">
                    { moment(payment.createdAt).format('L LT') }
                  </TableCell>

                  <TableCell scope="row">
                    { payment.user.firstName } { payment.user.lastName }
                  </TableCell>

                  <TableCell scope="row">
                    { payment.payment }
                  </TableCell>

                  <TableCell align="right">
                    <Tooltip title='Delete' placement='top'>
                      <IconButton color='primary'
                                  size='small'
                                  component='span'
                                  onClick={() => handleDeleteOpen(payment.id)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>

                </TableRow>
            ) }

          </TableBody>
        </Table>
      </TableContainer>
  const renderForm = () =>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h3'>Payment</Typography>
        </Grid>
        { isLoaded
            ? <div>
              {allPayment?.length > 0
                  ?
                  <Grid item xs={12}>
                    {renderTable()}
                  </Grid>
                  : <Typography variant='body1' className={classes.marginTop}>No Payment</Typography>
              }

              <Grid item xs={12}>
                { addNewPaymentDialogue() }
              </Grid>
            </div>
            : <Grid item xs={12}><LoadingScreen message='Loading...' /></Grid>
        }

      </Grid>

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper__form}>
          <Paper className={classes.paper}>
            <Container maxWidth="lg">
              { renderForm() }

              <ResponsiveDialog
                  isOpen={openDeleteDialogue} openHash={hash}
                  title="Delete Payment" content="Delete this payment?"
                  Buttons={
                    [
                      { name: "Yes", event: () => onDeletePayment() },
                      { name: "Cancel", event: () => setSelectedForDel(-1) }
                    ]
                  }
              />

              <div className={classes.marginBottom} id='emptyDivAtTheBottom' />

            </Container>
          </Paper>


      </Paper>
    </Grid>

  );
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
  fetchCustomerPayment,
  createCustomerPayment,
  deleteCustomerPayment
})(EditCustomerPayment);
