const getMenuIcon=(name)=>{
    let Icon = null;
    try{
        Icon = require(`react-feather/dist/icons/${name}`).default;
    }catch(e){
        return e
    }
    return Icon
}

export default getMenuIcon