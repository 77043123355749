import React, { useEffect, useState } from 'react';
import {
  FormControl, FormControlLabel,
  FormHelperText, FormLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper, Radio, RadioGroup,
  Select, TextField, Typography
} from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { QRCODE_SET_VALIDATOR, QRCODE_STATUS, useQRCodeEditorStyles } from '../../qrcodeHelper';
import {
  editQRCodeSet,
  fetchQRCodeCategories,
  fetchQRCodeUsers,
  updateQRCodeSet
} from '../../../../actions/qrcode/qrcodeAction';
import { sendErrorMessage } from '../../../../actions';
import SubmitButton from '../../../../components/mui/button/SubmitButton';

const schema = yup.object().shape(QRCODE_SET_VALIDATOR)
function QRCodeSetEditor({ isEdit, originalData, onSubmitSuccess }) {

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const classes = useQRCodeEditorStyles()
  const dispatch = useDispatch()

  const allCategories = useSelector(state => state.qrcode.allCategories)
  const allUsers = useSelector(state => state.qrcode.allUsers)

  const [isSaving, setIsSaving] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const [isCatLoaded, setIsCatLoaded] = useState(false)
  const [isUsersLoaded, setIsUsersLoaded] = useState(false)

  const [selectedType, setSelectedType] = useState(isEdit ? originalData.status : null)
  const [selectedCat, setSelectedCat] = useState(isEdit ? originalData.category?.id || -1 : -1)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedPointedUser, setSelectedPointedUser] = useState('')

  const [selectedUserQR, setSelectedUserQR] = useState({})
  const [isWebsite, setIsWebsite] = useState(!selectedType || selectedType === QRCODE_STATUS[0].symbol)

  const loadData = () => {
    (async() => {
      setIsCatLoaded(await fetchQRCodeCategories()(dispatch))
      setIsUsersLoaded(await fetchQRCodeUsers()(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    setIsWebsite(!selectedType || selectedType === QRCODE_STATUS[0].symbol)
  }, [selectedType])


  const testUserQRMappingData = (data) => {
    const result = {}
    if (!data) {
      return result
    }

    for (let i = 0; i < Object.keys(data).length ; i+=1) {
      // data check
      const user = allUsers.find(usr => usr.id.toString() === Object.keys(data)[i])
      if (user) {
        const hasQrCode = user?.staffInfo?.wechatQRCode.find(qr => qr.id === data[Object.keys(data)[i]])
        if (hasQrCode) {
          result[Object.keys(data)[i]] = data[Object.keys(data)[i]]
        }
      }
    }

    return result
  }

  useEffect(() => {
    if (!isLoaded) {
      // Set each as they load
      if (isCatLoaded && isUsersLoaded) {
        if (isEdit) {
          // Load all data
          setSelectedUsers(originalData.users.filter(usr => usr.isActive).map(usr => usr.id))
          setSelectedPointedUser(originalData.pointedUser.id)
          setSelectedUserQR(testUserQRMappingData(JSON.parse(originalData.userQRMapping)))
        }
        setIsLoaded(true)
      }
    }
  }, [isCatLoaded, isUsersLoaded, allCategories, allUsers, isLoaded])

  const onSubmit = async (formData) => {
    setIsSaving(true)
    let tempSelectedPointedUser = selectedPointedUser

    // Validate Category
    if (selectedCat === -1) {
      setIsSaving(false)
      return setError('category', { type: 'manual', message: 'Required' })
    }

    // Validate Type
    if (!selectedType) {
      setIsSaving(false)
      return setError('type', { type: 'manual', message: 'Required' })
    }

    // Validate users
    if (selectedUsers.length < 1) {
      setIsSaving(false)
      return setError('users', { type: 'manual', message: 'Required' })
    }
    if (!selectedUsers.includes(tempSelectedPointedUser)) {
      if (isWebsite) {
        [tempSelectedPointedUser] = selectedUsers
      } else {
        setIsSaving(false)
        return setError('pointedUser', {
          type: 'manual',
          message: 'Pointed user is required and must be included in users'
        })
      }
    }

    // Passed all tests
    try {
      const res = {
        name: formData.name,
        description: formData.description,
        guide: formData.guide,
        categoryId: selectedCat,
        status: selectedType,
        users: selectedUsers,
        pointedUser: parseInt(tempSelectedPointedUser.toString(),10),
        userQRMapping: JSON.stringify(selectedUserQR),
        url: formData.url || originalData.url
      }

      if (isEdit) {
        await updateQRCodeSet(originalData.id, res,
          (msg) => onSubmitSuccess('QR Code Set updated', msg.data.id),
          (msg) => onError(msg))(dispatch)
      } else {
        await editQRCodeSet(res,
          (msg) => onSubmitSuccess('QR Code Set created', msg.data.id),
          (msg) => onError(msg))(dispatch)
      }
    } catch (e) {
      // console.log('Something went wrong when submitting form\n', e)
      onError(e.message)
    }
    return ()=>{}
  }

  const onError = (msg) => {
    sendErrorMessage(dispatch, msg)
    setIsSaving(false)
  }

  const renderUserSelectDialogue = () => (
      <FormControl className={classes.userSelectorFormControl} required disabled={isSaving}
                   error={Boolean(errors.users?.message)}>
        <InputLabel>Users</InputLabel>
        <Select
          size='small'
          name='users'
          inputRef={register}
          fullWidth
          onChange={(e) => setSelectedUsers(e.target.value)}
          multiple
          value={selectedUsers}
          input={<Input />}
        >
          { allUsers.map((u, ) => (
            <MenuItem key={u.id} value={u.id}>
              { `${u.id}. ${u?.firstName} ${u?.lastName}` }
            </MenuItem>
          )) }
        </Select>
        <FormHelperText className={classes.marginReset}>{
          errors.users?.message
            ? errors.users?.message
            : 'Select all the users to include in this QR Code Set'
        }</FormHelperText>
      </FormControl>
    )

  const renderPointedUserSelectDialogue = () => {
    const filteredUsers = allUsers.filter(u => selectedUsers.includes(u.id))
    return (
      <FormControl className={classes.userSelectorFormControl} required disabled={isSaving}
                   error={Boolean(errors.pointedUser?.message)}>
        <InputLabel>Pointed User</InputLabel>
        <Select
          size='small'
          name='pointedUser'
          inputRef={register}
          fullWidth
          onChange={(e) => setSelectedPointedUser(e.target.value)}
          value={selectedPointedUser}
          input={<Input />}
        >
          { filteredUsers.map((u) => (
            <MenuItem key={u.id} value={u.id}>
              { `${u.id}. ${u?.firstName} ${u?.lastName}` }
            </MenuItem>
          )) }
        </Select>
        <FormHelperText className={classes.marginReset}>
          { errors.pointedUser?.message ? errors.pointedUser?.message : 'Pointed User must be in the selected users' }
        </FormHelperText>
      </FormControl>
    )
  }

  const renderUserQRCodeSelection = () =>
    <>
      { selectedUsers.map((selUsr,index) => {
        const userData = allUsers.find(usr => usr.id === selUsr)
        return (
          <Grid item xs={12} sm={6} md={4} key={index} >
            <FormControl className={classes.userSelectorFormControl} disabled={isSaving}>
              <InputLabel>{ `${userData?.firstName} ${userData?.lastName}` }</InputLabel >
              <Select
                size='small'
                fullWidth
                onChange={(e) => setSelectedUserQR({...selectedUserQR, [selUsr]: e.target.value } )}
                value={selectedUserQR[selUsr] ?? ''}
                input={<Input />}
                disabled={isSaving || userData?.staffInfo?.wechatQRCode.length <= 0}
              >
                { userData?.staffInfo?.wechatQRCode.length > 0
                  ? userData?.staffInfo?.wechatQRCode.map((u) => (
                    <MenuItem key={u.id} value={u.id}>
                      {`${u.name} `}
                    </MenuItem>
                  ))
                  : <MenuItem value=''>N/A</MenuItem>
                }
              </Select>
              {
                userData?.staffInfo?.wechatQRCode.length <= 0
                && <FormHelperText className={classes.marginReset}>This user has no QR codes</FormHelperText>
              }
            </FormControl>
          </Grid>
        )
      })}
    </>

  const renderForm = () =>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5} direction='row' justifyContent='space-between' alignItems='flex-start'>

        {/* Left */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={1} direction='column'>
            <Grid item xs={12}>
              <FormControl fullWidth className={classes.formControl}>
                <TextField fullWidth size='small'
                           label='Name' required
                           disabled={isSaving} variant='outlined'
                           name='name' inputRef={register}
                           defaultValue={ isEdit ? originalData.name : '' }
                           error={Boolean(errors.name?.message)}
                           helperText={errors.name?.message ? errors.name?.message : 'Max 30 characters'}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField fullWidth size='small'
                           label='Scan Guide' required
                           disabled={isSaving} variant='outlined'
                           name='guide' inputRef={register}
                           defaultValue={ isEdit ? originalData.guide : '请扫码联系' }
                           error={Boolean(errors.guide?.message)}
                           helperText={errors.guide?.message ? errors.guide?.message : 'Only used when redirecting to wechat code; Max 30 characters'}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField fullWidth size='small'
                           label='Description'
                           multiline rows={5}
                           disabled={isSaving} variant='outlined'
                           name='description' inputRef={register}
                           defaultValue={ isEdit ? originalData.description : '' }
                           error={Boolean(errors.description?.message)}
                           helperText={errors.description?.message}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Right */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={1} direction='column'>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} required disabled={isSaving} fullWidth
                           error={Boolean(errors.category?.message)}>
                <InputLabel>Category</InputLabel>
                <Select
                  size='small'
                  name='category'
                  inputRef={register}
                  value={selectedCat}
                  onChange={(e) => setSelectedCat(e.target.value)}
                  input={<Input />}
                >
                  <MenuItem value={-1}>---</MenuItem>
                  { allCategories.map((c) => (
                    <MenuItem key={c.id} value={c.id}>{ c.name }</MenuItem>
                  )) }
                </Select>
                <FormHelperText className={classes.marginReset}>{
                  errors.category?.message
                    ? errors.category?.message
                    : 'Category for this QR Code Set'
                }</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth className={classes.formControl} required
                           error={Boolean(errors.type?.message)}>
                <FormLabel component='legend'>Responding Type</FormLabel>
                <div className={classes.marginLeft}>
                  <RadioGroup defaultValue={isEdit ? originalData.status : null}
                              value={selectedType}
                              onChange={(e) => setSelectedType(e.target.value)}>
                    { QRCODE_STATUS.map((s, i) => (
                      <FormControlLabel
                        key={i}
                        disabled={(isWebsite && isEdit) || (!isWebsite && isEdit && i === 0)}
                        value={s.symbol}
                        control={<Radio name='type' />}
                        label={s.name}
                      />
                    )) }
                  </RadioGroup>
                </div>
                <FormHelperText>{Boolean(errors.type?.message) && errors.type?.message}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* User Selector */}
        <Grid item xs={12}>
          <Grid container spacing={5} direction='row' justifyContent='flex-start'>
            <Grid item xs={12} sm={isWebsite ? 12 : 6}>
              { renderUserSelectDialogue() }
            </Grid>
            {
              !isWebsite &&
              <Grid item xs={12} sm={6}>
                { renderPointedUserSelectDialogue() }
              </Grid>
            }
          </Grid>
        </Grid>

        {/* Conditional Display */}
        <Grid item xs={12}>
          {
            selectedType !== QRCODE_STATUS[0].symbol &&
            <Grid container spacing={4} direction='row'>
              <Grid item xs={12}>
                <Typography variant='h3' color='textPrimary'>Default QR Code</Typography>
              </Grid>
              { renderUserQRCodeSelection() }
            </Grid>
          }
        </Grid>

        {/* Bottom Submit Button */}
        <Grid item xs={12}>
          <Grid container spacing={1} direction='row' justifyContent='flex-end'>
            <Grid item xs={2} className={classes.submitButton}>
              <SubmitButton isSaving={isSaving} text='Submit' isSavingText='Submitting'
                            fullWidth variant='contained' color='primary'
              />

            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </form>

  return (
    <Paper className={classes.paper}>
      { isLoaded ? renderForm() : <Typography variant='h2'>Loading</Typography> }
    </Paper>
  )
}

export default QRCodeSetEditor
