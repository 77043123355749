import {combineReducers} from 'redux'
import {userSettingReducer as userSetting} from './public/userSettingReducer'
import {authReducer as auth} from './auth/authReducer'
import {messageReducer as message} from './message/MessageReducer'
import {systemReducer as system} from './system/systemReducers'
import {customerReducer as customer} from './customer/customerReducer'
import {interviewReducer as interview} from './customer/interviewReducer'
import {employeeReducer as employee} from './hr/employeeReducer'
import {formReducer as forms} from './forms/formReducer'
import {posterMakerReducer as posterMaker} from './forms/posterMakerReducer'
import {campaignReducer as campaign} from './campaign/campaignReducer'
import {qrcodeReducer as qrcode} from './qrcode/qrcodeReducer'
import {reviewReducer as review} from './review/reviewReducer'
import {classReducer as classes} from './class/classReducer'
import {administrativeReducer as administrative} from './administrative/administrativeReducer'
import {mmqJobsReducer as mmqJobs} from './administrative/mmqJobsReducer'
import {productReducer as products} from './product/productReducer'
import {syllabusReducer as syllabuses} from './syllabus/syllabusReducer'
import {trainingMessageReducer as trainingMessage} from './trainingMessage/traingMessageReducer'
import {userAvatarReducer as userAvatar} from './userAvatar/userAvatarReducer'
import {resReducer} from './resources/resReducer'
import {matricReducer as matric} from './matric/matricReducer'
import {biReducer as bi} from './bi/biReducer'
import { tagReducer as tag } from './public/tagReducer'

const reducer = combineReducers({
    userSetting,
    auth,
    message,
    system,
    customer,
    interview,
    employee,
    forms,
    posterMaker,
    campaign,
    qrcode,
    review,
    classes,
    administrative,
    mmqJobs,
    matric,
    products,
    syllabuses,
    trainingMessage,
    userAvatar,
    resReducer,
    tag,
    bi
})

export default reducer
