import React from 'react'
import {Button} from '@mui/material'
import {SYLLABUS_TYPES} from '../syllabusHelper'

export default function SyllabusAddDeliveryCommandCell(isSaving, editField, onCellAddDelivery) {
    function SyllabusAddDeliveryCommandCellClass(dataItem) {
        const {row} = dataItem
        return row[editField] && row.type === SYLLABUS_TYPES.POINTS ? (
            <div>
                <Button icon="redo" disabled={isSaving} onClick={() => onCellAddDelivery(dataItem)} />
            </div>
        ) : (
            <td />
        )
    }
    return SyllabusAddDeliveryCommandCellClass
}
