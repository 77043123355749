import React from "react";
import { Navigate } from 'react-router';
import classRoutes from './training';
import syllabusRoutes from "./syllabus";
import resources from "./resources";
import messageRoutes from "./message";

const trainingRoutes = [
    {
        path: 'training',
        routes: [
            ...classRoutes,
            ...syllabusRoutes,
            ...messageRoutes,
            ...resources,
            {
                component: <Navigate to="404"/> // this must be the last one
            },
        ]
    },
]

export default trainingRoutes;
