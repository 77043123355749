import React, {useEffect, useState} from 'react'
import {CardMedia, Grid, IconButton, Stack, Tooltip, Typography} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {makeStyles} from '@mui/styles'
import axios from 'axios'
import {FileIcon} from 'react-file-icon'
import ClearIcon from '@mui/icons-material/Clear'
import moment from 'moment'
import {noop} from 'lodash'
import CircularProgressWithLabel from '../mui/progressBar/circularProgress'
import api from '../../apis/api'
import DraggableDialog from '../mui/dialog/DraggableDialog'

const actionType = {
    UPLOAD: 'upload',
    DELETE: 'delete'
}

export const fileTypes = {
    PNG: 'png',
    JPG: 'jpg',
    JPEG: 'jpeg',
    DOC: 'doc',
    DOCX: 'docx',
    PDF: 'pdf',
    TXT: 'txt',
    RAR: 'rar',
    ZIP: 'zip',
    excel: 'csv',
    svg: 'svg',
    ppt: 'ppt',
    pptx: 'pptx',
    gif: 'gif'
}

export function FileUploader1({
    acceptDefaultTypes = true,
    baseFileURL = '',
    onSuccess = noop,
    onError = noop,
    fileType,
    maxKB = 5 * 1024,
    list,
    color,
    asFormInputComponent = false,
    handleImageCallBack = noop,
    multipleFile = true,
    register
}) {
    const useStyles = makeStyles(() => ({
        container: {
            backgroundColor: `${color.backgroundColor}`,
            padding: '1rem',
            maxWidth: '500px'
        },
        uploaderContainer: {
            padding: '2rem',
            border: `2px dashed ${color.borderColor}`,
            textAlign: 'center'
        },

        displayFiles: {
            marginTop: '1rem'
        },

        fileGrid: {
            border: `1px dashed ${color.borderColor}`,
            backgroundColor: `${color.backgroundColor}`,
            padding: '1rem',
            marginBottom: '5px',
            maxHeight: '6rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center'
        },
        mediaGrid: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '5px'
        },
        object: {
            backgroundColor: `${color.backgroundColor}`,
            cursor: 'pointer'
        },

        loading: {
            width: '100%',
            height: '100%'
        },
        loadingHidden: {
            display: 'none'
        },
        cardMediaCursorIn: {
            width: '50px',
            height: '50px',
            cursor: 'pointer'
        }
    }))

    const [fileList, setFileList] = useState([])
    const [uploaded, setUploaded] = useState(null)
    const [progress, setProgress] = useState(0)
    const [supportedType, setSupportedType] = useState([])
    const [previewUrl, setPreviewUrl] = useState('')
    const [modelFile, setModelFile] = useState({})
    const [modelOpen, setModelOpen] = useState(false)
    const [resourceCancelToken, setResourceCancelToken] = useState(axios.CancelToken.source())
    const classes = useStyles()

    useEffect(() => {
        if (list.length !== 0) {
            setFileList(() => [...list])
        } else {
            !asFormInputComponent && setFileList(fileList => fileList.filter(v => v.beforeUploaded === false))
        }
    }, [list])

    useEffect(() => {
        let tempArr = []
        if (acceptDefaultTypes) {
            for (const k in fileTypes) {
                tempArr = [...tempArr, fileTypes[k]]
            }
            setSupportedType([...tempArr])
        } else {
            setSupportedType([...fileType.split(',')])
        }
    }, [])

    useEffect(() => {
        if (uploaded) {
            fileList[0] = uploaded
            setProgress(0)
        }
    }, [uploaded])

    useEffect(() => {
        handleImageCallBack(fileList.map(({file, ...left}) => file))
        setProgress(0)
        setUploaded(null)
    }, [fileList])

    const convertAcceptTypeToString = (typeArr, char) => {
        let typeStr = ''
        typeArr.forEach((v, i) => {
            i === 0 ? (typeStr += v) : (typeStr = typeStr + char + v)
        })
        return typeStr
    }

    const convertToInputAccept = typeArr => {
        let typeStr = ''
        typeArr.forEach(v => (typeStr = `${typeStr}.${v},`))
        return typeStr
    }

    const uploadConfig = {
        onUploadProgress: progressEvent => {
            const progress = Math.trunc((progressEvent.loaded / progressEvent.total) * 100)
            setProgress(progress)
        },
        cancelToken: resourceCancelToken?.token
    }

    const uploadHandler = async event => {
        const file = event?.target?.files[0]
        if (file === undefined) {
            return
        }
        const name = event?.target?.files[0]?.name
        const title = name.substring(0, name.indexOf('.'))
        if (file.size > 1024 * maxKB) {
            onError(`file cannot over ${maxKB}KB`)
            return
        }

        // create new formData
        const formData = new FormData()
        formData.append('title', title)
        formData.append('filename', name)
        formData.append('file', file)
        setPreviewUrl(URL.createObjectURL(file))

        // upload new file
        let currentUploading
        if (asFormInputComponent) {
            currentUploading = {
                title: title,
                filename: name,
                file: file,
                isUploading: false,
                url: URL.createObjectURL(file)
            }
            multipleFile
                ? setFileList(() => [currentUploading, ...fileList])
                : setFileList(() => [...[], currentUploading])
        } else {
            multipleFile
                ? setFileList(() => [currentUploading, ...fileList])
                : setFileList(() => [...[], currentUploading])
            currentUploading = {title: title, filename: name, file: file, isUploading: true}
            try {
                const res = await api.post(baseFileURL, formData, uploadConfig)

                if (res.data?.rs) {
                    setUploaded({
                        ...res?.data?.data,
                        beforeUploaded: false,
                        isUploading: false,
                        url: URL.createObjectURL(file)
                    })
                    setProgress(0)
                    return onSuccess(res.data?.message, actionType.UPLOAD, res?.data?.data)
                }
                return onError(res.data?.message || 'Error uploading resource file', actionType.UPLOAD)
            } catch (e) {
                if (axios.isCancel(e)) {
                    return onSuccess('upload request cancel', actionType.UPLOAD)
                }

                return onError('Error in uploading file', actionType.UPLOAD)
            }
        }
    }

    const deleteResource = (file, uploading, onSuccess, onError) => async () => {
        if (asFormInputComponent) {
            setFileList(fileList.filter(v => v !== file))
            return
        }
        if (uploading) {
            resourceCancelToken.cancel()
            setResourceCancelToken(axios.CancelToken.source())
            fileList.shift()
        } else {
            try {
                const url = `${baseFileURL}/${file?.id}`
                const res = await api.delete(url)
                if (res.data?.rs) {
                    setFileList(fileList.filter(v => v.id !== file?.id))
                    setProgress(0)
                    return onSuccess(res.data?.message || 'Resource is deleted successfully', actionType.DELETE, {
                        id: file?.id,
                        filename: file?.filename
                    })
                }
                return onError(res.data?.message || 'Error in deleting resource', actionType.DELETE)
            } catch (e) {
                console.log('Error in deleting resources: ', e.message)
                onError('Error in deleting resource', actionType.DELETE, {id: file?.id, filename: file?.filename})
            }
        }
    }

    const downloadFile = file => {
        const link = document.createElement('a')
        link.setAttribute('target', '_blank')
        link.href = file?.url
        link.download = file?.filename
        document.body.appendChild(link)
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: false, view: window}))
        link.parentNode.removeChild(link)
    }

    const fileGrid = (file, progress, isUploading = false) => {
        const ext = file?.filename?.split('.').pop()
        const image = ['png', 'jpg', 'jpeg', 'svg', 'gif']
        const showingImage = image.includes(file?.filename?.split('.').pop())
        const previewObject = ['pdf']
        const showingObject = previewObject.includes(file?.filename?.split('.').pop())

        return (
            <Grid className={classes.fileGrid} container>
                <Grid className={classes.mediaGrid} item xs={2} container>
                    {showingImage ? (
                        <CardMedia
                            component="img"
                            image={isUploading ? previewUrl : file.url}
                            alt={file?.filename}
                            className={classes.cardMediaCursorIn}
                            onClick={() => {
                                setModelOpen(true)
                                setModelFile(isUploading ? {...file, url: previewUrl} : file)
                            }}
                        />
                    ) : showingObject ? (
                        <div
                            onClick={() => {
                                setModelOpen(true)
                                setModelFile(isUploading ? {...file, url: previewUrl} : file)
                            }}
                            className={classes.object}
                        >
                            <object
                                width="50"
                                height="50"
                                style={{pointerEvents: 'none'}}
                                data={isUploading ? previewUrl : file?.url}
                            />
                        </div>
                    ) : (
                        <div style={{width: '50px', height: '100%', padding: '0px'}}>
                            <FileIcon extension={ext} color={color.borderColor} />
                        </div>
                    )}
                </Grid>
                <Grid
                    item
                    container
                    xs={9}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{textAlign: 'start', height: '100%', padding: '5px'}}
                >
                    <Grid item container xs={10} flexDirection="column">
                        <Grid item xs={12} onClick={() => downloadFile(file)} style={{cursor: 'pointer'}}>
                            {file?.filename}
                        </Grid>
                        <Grid item xs={12}>
                            <h6 style={{color: 'grey'}}>{moment(file?.createdAt).format('LLL')}</h6>
                        </Grid>
                    </Grid>
                    {!asFormInputComponent && (
                        <Grid item xs={2}>
                            <CircularProgressWithLabel value={isUploading ? progress : 100} />
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title="Delete">
                        <IconButton onClick={deleteResource(file, isUploading, onSuccess, onError)}>
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }

    return (
        <Stack className={classes.container}>
            <Grid container>
                <Grid item container xs={12} className={classes.uploaderContainer}>
                    <Grid xs={12} item className={classes.button}>
                        <IconButton color="primary" aria-label="upload picture" component="label">
                            <input
                                hidden
                                accept={convertToInputAccept(supportedType)}
                                type="file"
                                onChange={uploadHandler}
                                ref={register}
                            />
                            <CloudUploadIcon fontSize="large" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" xs={12}>
                            {' '}
                            Max {maxKB}kb
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" xs={12}>
                            Supported Types: {convertAcceptTypeToString(supportedType, ', ')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.displayFiles}>
                {fileList.map((value, index) => (
                    <div key={index}>{fileGrid(value, progress, value?.isUploading)}</div>
                ))}
            </Grid>
            <DraggableDialog open={modelOpen} onClose={() => setModelOpen(false)} title={modelFile?.filename}>
                <div style={{width: '100%', height: '100%'}}>
                    <object data={modelFile?.url} width="100%" height="100%" style={{objectFit: 'fill'}} />
                </div>
            </DraggableDialog>
        </Stack>
    )
}
