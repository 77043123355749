import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchCustomer, fetchCustomerCheck } from '../../../actions';
import DataTable from './DataTable';
import LoadingScreen from '../../../components/public/LoadingScreen';


function TablePage({ isEdit }) {
  const customers = useSelector(state => state.customer.customersPerPage)

  const [isLoading, setLoading] = useState(false);

  const handleEdit = (id) => {
    setLoading(true);
    // const customerId = customers[id].id;
    fetchCustomer(id, () => {
      setLoading(false);
    });
  };
  const handleCheck = (id) => {
    // const customerId=customers[id].id;
    fetchCustomerCheck(id);
  };

  return (
    <div>
      {
        (!isLoading)
        ? <DataTable handleCheck={handleCheck}
                     customers={customers}
                     isEdit={isEdit}
                     handleEdit={handleEdit}
        />
        :
        <LoadingScreen message="Loading..." />
      }
    </div>

  );
}

export default TablePage
