import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router';
import IconButton from '@mui/material/IconButton';
import { Edit as EditIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import { fetchQRCodeSet } from '../../../actions/qrcode/qrcodeAction';
import { getTotalHeight } from '../../../utils/Scripts';
import { QRCODE_STATUS, useQRCodeDetailsStyles } from '../qrcodeHelper';
import { renderUsersTable } from '../../campaign/campaignDetails/campaignPosterTables';
import LoadingScreen from '../../../components/public/LoadingScreen';
import QRCodeTableView from './QRCodeTableView';

function QRCodeDetailsView({qrId}) {
  useCurrentViewCheck(Functions.QRCode_Code)
  const  navigate = useNavigate();
  const setID = parseInt(useParams().id,10)
  const classes = useQRCodeDetailsStyles()
  const dispatch = useDispatch()

  const auth = useSelector(state => state?.auth)
  const currentQRCodeSet = useSelector(state => state?.qrcode.currentQRCodeSet)

  const [isLoaded, setIsLoaded] = useState(false)
  const [sortedQRSet, setSortedQRSet] = useState({})
  const [userMappingList, setUserMappingList] = useState({})

  const isSmallUserView = getTotalHeight() < 800
  const isSmallQRView = getTotalHeight() < 1200

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchQRCodeSet(setID || qrId)(dispatch))
    })()
  }
  useEffect(loadData, [setID || qrId, dispatch])

  useEffect(() => {
    if (currentQRCodeSet.id === setID) {
      const tempUserList = []
      const tempUserMapping = {}
      for (let i = 0; i < currentQRCodeSet.users.length; i+=1) {
        tempUserList.push({
          ...currentQRCodeSet.users[i],
          isPointed: currentQRCodeSet.users[i].id === currentQRCodeSet.pointedUser.id
        })
        tempUserMapping[currentQRCodeSet.users[i].id] = { firstName: currentQRCodeSet.users[i].firstName, lastName: currentQRCodeSet.users[i].lastName }

      }


      setUserMappingList(tempUserMapping)
      // setSortedQRSet({ ...currentQRCodeSet, users: tempUserList, qrCodes: currentQRCodeSet.qrCodes })
      setSortedQRSet({ ...currentQRCodeSet, users: tempUserList })
      setIsLoaded(true)
    }
  }, [currentQRCodeSet, setID, dispatch])

  const renderReturnButton = () => (
      <Button color='secondary'
              variant='contained'
              onClick={() => navigate('/app/qr/')}>
        Back
      </Button>
    )

  const renderView = () =>
    <div>
      <Grid container direction='row' className={classes.marginBottom} spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h3' color='textPrimary' className={classes.pageTitle}>{currentQRCodeSet.name}
            <span className={classes.pageStatus}>[{QRCODE_STATUS.find(s => s.symbol === currentQRCodeSet.status).name}]</span>
          </Typography>

          <Tooltip title='Edit' placement='top'>
            <IconButton color='primary' component='span' size='small' className={classes.editButton}
                        onClick={() => navigate(`/app/qr/edit/${currentQRCodeSet.id}`)}>
              <EditIcon size={23} />
            </IconButton>
          </Tooltip>
        </Grid>
        {setID?
        <Grid item xs={12}>
          { renderReturnButton() }
        </Grid>
            : ""}
      </Grid>

      { renderUsersTable(
        isSmallUserView,
        currentQRCodeSet.status === QRCODE_STATUS[0].symbol
          ? currentQRCodeSet.users
          : sortedQRSet.users,
        isLoaded
      ) }
      <QRCodeTableView qrSetId={setID || qrId}
                       userId={auth.user?.id}
                       userMappingList={userMappingList}
                       isSmallView={isSmallQRView}
      />
    </div>

  return (
      <div>
        {
          setID ?  <ViewHead functionId={Functions.QRCode_Code}
                             noMaxWidth isShowTitle={false}>
            { isLoaded ? renderView() : <LoadingScreen message='Loading...' /> }
          </ViewHead>
              :
              <div>
                { isLoaded ? renderView() : <LoadingScreen message='Loading...' /> }
              </div>
        }
      </div>

  )
}

export default QRCodeDetailsView

