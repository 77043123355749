import React from 'react';
import {
  Toolbar,
  Grid, ButtonGroup, Button, debounce
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useToolbarStyles } from '../customerData';
import { CATEGORY_ITEMS } from '../../../constants';
import SearchBox from '../../../components/public/SearchBox';


function EnhancedTableToolbar(props) {
  const classes = useToolbarStyles();
  const {
    numSelected, onActive, handleChange,
    selectedStatus, setSelectedCate, setSelectedStatus,isLoading
  } = props;

  // const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    // setOpen(false);
  };

  const handleOpen = () => {
    // setOpen(true);
  };
  const searchHandler = debounce((searchVal) => {
    handleChange(searchVal);
  }, 500);
  return (
    <Toolbar className={classes.root}>
      <Grid container
            direction='row'
            justifyContent="space-between"
            alignItems='center'
            spacing={1}>
        <Grid item xs={12} sm={12} md={6} xl={8}>
          <div className={classes.search}>
            <SearchBox
              placeholder='Search...'
              size='small'
              type='search'
              searchHandler={searchHandler}
              isLoading={isLoading}
            />
          </div>
        </Grid>

        <>
          <Grid item xs={12} sm={12} md={3} xl={2}>
            <FormControl className={classes.formControl} size='small'>
              <InputLabel>Category</InputLabel>
              <Select
                size='small'
                onClose={handleClose}
                onOpen={handleOpen}
                defaultValue={CATEGORY_ITEMS.all}
                onChange={event => {
                  setSelectedCate(event.target.value);
                  // handleStatusChange(searchValue, event.target.value, selectedStatus);
                }}
              >
                <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
                <MenuItem value="Staff">Staff</MenuItem>
                <MenuItem value="Customer">Customer</MenuItem>
              </Select>
            </FormControl>

          </Grid>
          <Grid item xs={12} sm={12} md={3} xl={2}>
            <FormControl className={classes.formControl} size='small'>
              <InputLabel>isActive</InputLabel>
              <Select
                size='small'
                onClose={handleClose}
                onOpen={handleOpen}
                value={selectedStatus}
                onChange={event => {
                  setSelectedStatus(event.target.value);
                  // handleStatusChange(searchValue, selectedCate, event.target.value);
                }}
              >
                <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </>

        <Grid item
              xs={12}>
          <ButtonGroup variant='contained' color='primary' size='small' className={classes.activityButtons}>
            <Button
              disabled={numSelected <= 0}
              onClick={() => onActive(true)}
            >
              Enable
            </Button>
            <Button
              disabled={numSelected <= 0}
              onClick={() => onActive(false)}
            >
              Disable
            </Button>
          </ButtonGroup>
        </Grid>

      </Grid>

    </Toolbar>
  );
}

export default EnhancedTableToolbar;
