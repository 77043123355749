import React, { useEffect, useState } from 'react';
import { AppBar, FormControlLabel, Paper, Switch, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { Functions } from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import TrackingAllListTabView from './TrackingAllListTabView';
import TabPanel, { a11yProps } from '../../../components/mui/tabs/TabPanel';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { DATE_ENTITY_PROPERTY, DEFAULT_END_DATE, DEFAULT_START_DATE } from './trackingHelper';
import TrackingDateSearchView from './TrackingDateSearchView';
import { CATEGORY_ITEMS } from '../../../constants';
import LoadingScreen from '../../../components/public/LoadingScreen';
import { fetchAllStaff, fetchAllTrackersByDate } from '../../../actions/administrative/administrativeAction';
import TrackingOverviewTabView from './TrackingOverviewTabView';
import TrackingUserTabView from './TrackingUserTabView';

const useStyles = makeStyles((theme) => ({
  menuBar: {
    marginTop: '1rem',
    padding: theme.spacing(0, 3)
  },
}))

function TrackingList() {
  useCurrentViewCheck(Functions.Administrative_Tracking_List)

  const classes = useStyles()
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState(0)

  // For All Tracking list
  const allTracking = useSelector(state => state.administrative.allTrackersByDate)
  const allStaff = useSelector(state => state.administrative.allStaff)

  const [isTabLoaded, setIsTabLoaded] = useState(false)
  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false)

  const [allSortedStaff, setAllSortedStaff] = useState([])

  const [isShowLegend, setIsShowLegend] = useState(true)
  const [isShowActive, setIsShowActive] = useState(true)
  const [selectedUser, setSelectedUser] = useState(null)

  // For searching by date
  const [startDateFilter, setStartDateFilter] = useState(DEFAULT_START_DATE)
  const [endDateFilter, setEndDateFilter] = useState(DEFAULT_END_DATE)

  // fetch params
  const [searchArgs, setSearchArgs] = useState({
    searchKey: undefined,
    category: CATEGORY_ITEMS.all,
    user: CATEGORY_ITEMS.all,
    upperBound: { [DATE_ENTITY_PROPERTY]:
        moment(DEFAULT_END_DATE).endOf('day').toISOString() },
    lowerBound: { [DATE_ENTITY_PROPERTY]:
        moment(DEFAULT_START_DATE).startOf('day').toISOString() }
  })

  const loadData = () => {
    if (!isAllDataLoaded) {
      (async () => {
        setIsAllDataLoaded(await fetchAllStaff()(dispatch))
      })()
    }
  }
  useEffect(loadData, [])

  useEffect(() => {
    const tempUsers = allStaff?.map(usr => ({
      id: usr.id,
      firstName: usr.firstName,
      lastName: usr.lastName,
      fullName: `${usr.firstName} ${usr.lastName}`
    }))
    setAllSortedStaff(tempUsers)
  }, [allStaff])


  const handleChange = (event, newValue) => setTabValue(newValue)
  const handleSelectedUserChange = (user) => setSelectedUser(user)

  const onSearchByDate = ({ customStartDate = null, customEndDate = null }) => {
    const startDate = moment(customStartDate || startDateFilter)
      .startOf('day').toISOString()
    const endDate = moment(customEndDate || endDateFilter)
      .endOf('day').toISOString()

    // only for all data
    setSearchArgs(prevState => ({ ...prevState,
      upperBound: { 'createdAt': endDate },
      lowerBound: { 'createdAt': startDate } }))
  }

  useEffect(() => {
    const fetchDate = async () => {
      setIsTabLoaded(false)

      let tempUser = selectedUser?.id
      if (tabValue === 1) {
        // overview tab don't need user
        tempUser = null
      }
      const startDate = moment(startDateFilter)
      const endDate = moment(endDateFilter)

      // re-fetch data
      await fetchAllTrackersByDate(tempUser, startDate, endDate, setIsTabLoaded)(dispatch)
    }

    if (tabValue !== 0) {
      // re-fetch data upon param change for Overview and User tabs
      fetchDate().then()
    }
  }, [selectedUser, startDateFilter, endDateFilter, tabValue])

  const renderTabs = () => (
      <Paper elevation={0}>
        <AppBar position='sticky' color='default'>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label="Data" {...a11yProps(0)} />
            <Tab label="Overview" {...a11yProps(1)} />
            <Tab label="User" {...a11yProps(2)} />
          </Tabs>
        </AppBar>

        <Grid container
              spacing={3}
              justifyContent='space-around'
              alignItems='center'
              className={classes.menuBar}>

          <Grid item xs={12} md={7} lg={6} xl={4} >
            <TrackingDateSearchView
              searchFunction={onSearchByDate}
              startDateFilter={startDateFilter}
              setStartDateFilter={setStartDateFilter}
              endDateFilter={endDateFilter}
              setEndDateFilter={setEndDateFilter}
            />
          </Grid>

          <Grid item xs={12} md={5} lg={6} xl={8}>
            <Grid container spacing={3} justifyContent='flex-end'>
              {
                tabValue === 1 &&
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isShowLegend}
                      onChange={() => setIsShowLegend(!isShowLegend)}
                    />
                  }
                  label="Legend"
                />
              }

              {
                (tabValue === 1 || tabValue === 2) &&
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isShowActive}
                      onChange={() => setIsShowActive(!isShowActive)}
                    />
                  }
                  label="Active"
                />
              }
            </Grid>
          </Grid>
        </Grid>


        <TabPanel value={tabValue} index={0}>
          <TrackingAllListTabView
            allStaff={allSortedStaff}
            searchArgs={searchArgs}
            setSearchArgs={setSearchArgs}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <TrackingOverviewTabView
            data={allTracking}
            isLoaded={isTabLoaded}
            isShowActive={isShowActive}
            isShowLegend={isShowLegend}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <TrackingUserTabView
            data={allTracking}
            isLoaded={isTabLoaded}
            allUsers={allSortedStaff}
            selectedUser={selectedUser}
            onSelectedUserChange={handleSelectedUserChange}
            isShowActive={isShowActive}
          />
        </TabPanel>

      </Paper>
    )

  return (
    <ViewHead functionId={Functions.Administrative_Tracking_List}  noMaxWidth>
      {
        isAllDataLoaded
          ? renderTabs()
          : <LoadingScreen message='Loading...' />
      }
    </ViewHead>
  )
}

export default TrackingList
