import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  Paper, Tab, Tabs,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendErrorMessage, sendSuccessMessage } from '../../../actions';
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog';
import { Functions } from '../../../utils/Functions';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import useAuthCheck from '../../../hooks/useAuthCheck';
import {
  deleteForm,
  fetchFormCategories,
  FormsActionType as FormActionType
} from '../../../actions/forms/formActions';
import ViewHead from '../../../components/public/ViewHead';
import DraggableDialog from "../../../components/mui/dialog/DraggableDialog";
import FormEditView from "./editFormView";
import {a11yProps} from "../../../components/mui/tabs/TabPanel";
import FormsListTabView from "./formListTabView";

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: theme.spacing(1, 0),
    width: '100%',
    [theme.breakpoints.down(475)]: {
      marginTop: theme.spacing(3)
    },
  },
  search: {
    width: '100%',
    padding: theme.spacing(1,0)
  },
  catSelector: {
    paddingTop: theme.spacing(2),
    width: '100%',
    paddingRight: `${theme.spacing(4)}!important`
  },
  activityButtons: {
    paddingBottom: `${theme.spacing(2)}!important`
  },
  content: {
    padding: theme.spacing(2),
  }
}));

function FormsIndexView() {
  useCurrentViewCheck(Functions.Forms_View_All_Form);
  const  navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const formFetchProps = useSelector(state => state?.forms.formFetchProps);

  const auth = useSelector(state => state.auth);
  // const [currentUserId, setCurrentUserId] = useState(-1);




  // Store the rows the user selected
  const [checkboxSelected, setCheckboxSelected] = useState([]);

  // const [hash, setHash] = useState('init');
  const hash = 'init'
  // const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const openDeleteDialogue = false
  const [canAddForm] = useAuthCheck([
    Functions.Forms_Add_Form
  ]);
  const clickedRow = ""
  // const [clickedRow, setClickedRow] = useState("")
  const [openEdit, setOpenEdit] = useState(false)
  const currentTab = useSelector(state => state?.forms.currentFormTab)

  const loadData = () => {
    fetchFormCategories()(dispatch);
  };
  useEffect(loadData, []);

  useEffect(() => {
    if (auth && auth.user) {
      // setCurrentUserId(auth.user.id);
    }
  }, [auth]);


  const onOperationError = (msg) => sendErrorMessage(dispatch, msg);
  const onOperationSuccess = (msg) => {
    setCheckboxSelected([]);
    sendSuccessMessage(dispatch, msg);
  };

  const onDeleteForm = () => {
    deleteForm(checkboxSelected.map(x => parseInt(x,10)),
      formFetchProps,
      (msg) => onOperationSuccess(msg),
      (msg) => onOperationError(msg))
    (dispatch);
  };
  const renderAddNewButton = () => (
      <div>
        {canAddForm ?
          <Button color='secondary'
                  variant='contained'
                  onClick={() => navigate('/app/forms/formBuilder')}>
            {currentTab === 0 ? "Add New From" : "Add New Web Form"}
          </Button>
          : <div />
        }
      </div>
    );

  const handleChangeTab = (event, newValue) =>
      dispatch({ type: FormActionType.ChangeFormTab, payload: newValue })
  const renderTabs = () =>
      <Paper elevation={0} style={{paddingBottom: '16px'}}>
        <AppBar position='sticky' color='default'>
          <Tabs value={currentTab} onChange={handleChangeTab}>
            <Tab label="Dynamic Forms" {...a11yProps(0)} />
            <Tab label="Web Forms" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <FormsListTabView/>


          </Paper>
  return (
    <ViewHead functionId={Functions.Forms_View_All_Form} topButtons={renderAddNewButton()} noMaxWidth>
      <Paper elevation={0} className={classes.content}>
        {renderTabs()}
      </Paper>
      <ResponsiveDialog
        isOpen={openDeleteDialogue} openHash={hash}
        title='Delete Form' content={`Delete forms: ${checkboxSelected.join(', ')}?`}
        Buttons={
          [
            { name: 'Yes', event: () => onDeleteForm() },
            { name: 'Cancel' }
          ]
        }
      />


      <DraggableDialog open={Boolean(openEdit)} onClose={() => setOpenEdit(null)}
                       title='Edit Form' isLoaded={Boolean(openEdit)}>
        <Paper elevation={0}>
          <FormEditView rowId={clickedRow}/>
        </Paper>

      </DraggableDialog>

    </ViewHead>
  );
}

export default FormsIndexView;
