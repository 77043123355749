import React from "react";
import { Navigate } from 'react-router';
import Employees from '../../../views/hr';

const employeeRoutes = [
    {
        path: 'employment',
        routes: [
            {
                path:'employmentList',
                exact: 'true',

                component:<Employees/>
            },
            {
                component:<Navigate to="404"/>
            },

        ]
    },
]

export default employeeRoutes;

