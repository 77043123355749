import React from 'react'
import {Navigate} from 'react-router'
import InvoicePreview from '../../../views/administrative/invoice/view/invoicePreview'
import InvoiceEditView from '../../../views/administrative/invoice/editor/editInvoiceView'
import InvoiceCreateView from '../../../views/administrative/invoice/editor/createInvoiceView'
import InvoiceListView from '../../../views/administrative/invoice'
import OneContractView from '../../../views/administrative/contract/contractPreview'
import EditContractView from '../../../views/administrative/contract/editContractView'
import CreateContractTemplateView from '../../../views/administrative/contractTemplate/createContractTemplateView'
import CreateContractView from '../../../views/administrative/contract/createContractView'
import ContractMoreView from '../../../views/administrative/contract/contractMoreView'
import ContractView from '../../../views/administrative/contract'
import EditContractTemplateView from '../../../views/administrative/contractTemplate/editContractTemplateView'
import CreatePaymentReceiverView from '../../../views/administrative/contractTemplate/createPaymentReceiverView'
import CreateMMQJobTemplateView from '../../../views/administrative/contractTemplate/createMMQJobTemplateView'
import ContractTemplateView from '../../../views/administrative/contractTemplate/contractTemplateView'
import ContractTemplate from '../../../views/administrative/contractTemplate'
import LoginHistory from '../../../views/administrative/loginHistory'
import TrackingList from '../../../views/administrative/tracking'

const administrativeRoutes = [
    {
        path: 'administrative',
        routes: [
            {
                path: 'tracking',
                exact: 'true',
                component: <TrackingList />
            },
            {
                path: 'login',
                exact: 'true',
                component: <LoginHistory />
            },
            {
                path: 'contractTemplate',
                exact: 'true',
                component: <ContractTemplate />
            },
            {
                path: 'contractTemplate/view/:id',
                exact: 'true',
                component: <ContractTemplateView />
            },
            {
                path: 'contractTemplate/add/:type?',
                exact: 'true',
                component: <CreateContractTemplateView />
            },
            {
                path: 'contractTemplate/addMMQJob',
                exact: 'true',
                component: <CreateMMQJobTemplateView />
            },
            {
                path: 'contractTemplate/addPaymentReceiver',
                exact: 'true',
                component: <CreatePaymentReceiverView />
            },

            {
                path: 'contractTemplate/edit/:id',
                exact: 'true',
                component: <EditContractTemplateView />
            },
            {
                path: 'contract',
                exact: 'true',
                component: <ContractView />
            },
            {
                path: 'contract/detail/:uid/:cid',
                exact: 'true',
                component: <ContractMoreView />
            },
            {
                path: 'contract/add/:id',
                exact: 'true',
                component: <CreateContractView />
            },
            {
                path: 'contract/add/:id/:cid?',
                exact: 'true',
                component: <CreateContractView />
            },
            {
                path: 'contract/edit/:uid/:cid',
                exact: 'true',
                component: <EditContractView />
            },
            {
                path: 'contract/view/:uid/:cid',
                exact: 'true',
                component: <OneContractView />
            },
            {
                path: 'invoice',
                exact: 'true',
                component: <InvoiceListView />
            },
            {
                path: 'invoice/add',
                exact: 'true',
                component: <InvoiceCreateView />
            },
            {
                path: 'invoice/edit/:uid/:iid',
                exact: 'true',
                component: <InvoiceEditView />
            },
            {
                path: 'invoice/view/:uid/:iid',
                exact: 'true',
                component: <InvoicePreview />
            },
            {
                component: <Navigate to="404" />
            }
        ]
    }
]

export default administrativeRoutes
