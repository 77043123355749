import React, {useEffect, useState} from 'react';
import { IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useDispatch, useSelector} from "react-redux";
import {CheckCircle} from "@mui/icons-material";
import {Edit as EditIcon, Trash2} from 'react-feather';
import WaitingOnly from "../../../components/public/WaitingOnly";
import {deleteSysParam, fetchSettings, SystemSettingsActionTypes} from "../../../actions";
import ResponsiveDialog from "../../../components/dialog/ResponsiveDialog";
import LXGrid from "../../../components/mui/datagrid/XGrid";

const useStyles = makeStyles(() => ({
    funcCell: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    statusCell: {
        margin: '0 auto'
    }
}))
function SystemParamsDataGrid() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [hash,setHash] = useState("init");
    // const [isLoading, setIsLoading] = useState(false);
    const setting = useSelector(state => state.system.setting)
    const dispatch = useDispatch();
    const [delId,setDelId] = useState(null);
    const loadSettings = () => {
        if (!setting.data) {
            fetchSettings()(dispatch);
        }
    }


    useEffect(loadSettings, []);
    const editSystem = (id) => {
        dispatch({type: SystemSettingsActionTypes.SetSettingFormStatus, payload: "EDIT"});
        dispatch({type: SystemSettingsActionTypes.SetCurrentId, payload: id});
    }
    const columns = [
        {field: 'id', headerName: 'ID', width: 80,},
        {field: 'key', headerName: 'Key', width: 200,},
        {field: 'val', headerName: 'Value', width: 300, sortable: false,},
        {
            field: 'isEncrypted', headerName: 'Encrypted', width: 120,
            renderCell: params => (
                    params.value ?
                        <CheckCircle className={classes.statusCell} style={{color: 'green'}}/> : <div>&nbsp;</div>
                )
        },
        {
            field: '_actionField', headerName: ' ', width: 70,
            renderCell: params => (
                <div style={{position: 'relative', width: '100%'}}>
                    <div className={classes.funcCell}>
                        <IconButton color="primary" component="span" size="small" onClick={(event) => {
                            event.stopPropagation();
                            editSystem(params.row.id)
                        }}>
                            <EditIcon size={15}/>
                        </IconButton>
                        <IconButton component="span" size="small" style={{marginLeft: 10}} onClick={(event) => {
                            event.stopPropagation();
                            handleDelete(params.row.id)
                        }}>
                            <Trash2 size={15} color="red"/>
                        </IconButton>
                    </div>
                </div>
            ),
        }
    ];
    const handleDelete = (id) => {
        setDelId(id);
        setOpen(true);
        setHash(Math.random().toString(16).substring(2,10));
    }

    return (
        <div>
            {!setting.data ? <WaitingOnly/> : null}
            <LXGrid rows={setting.data || []} columns={columns}
                       loading={!setting.data}/>
            <ResponsiveDialog isOpen={open} openHash={hash}
                              title="Delete Parameter Confirm" content="Are you sure?"
                              Buttons={[
                                  {
                                      name: "Yes",
                                      event:()=>{
                                        deleteSysParam(delId)(dispatch);
                                      }
                                  },
                                  {
                                      name: "Cancel"
                                  }
                              ]}/>
        </div>
    )
}

export default SystemParamsDataGrid;