import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Paper from '@mui/material/Paper';
import {Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Button, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {Functions} from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import {
    fetchWebSubmission,
    updateReadStatus
} from '../../../actions/forms/formActions';
import LoadingScreen from '../../../components/public/LoadingScreen';
import {addCustomerFromForm, useDisplaySubmissionStyles} from './formDataHelper';
import history from '../../../utils/history';
import {sendErrorMessage} from '../../../actions';
import {capitalizeFirstChar} from '../../../utils/Scripts';
import {webFormConsts} from "../../../constants";

function WebSubmissionDetails({rowId, isPopUp}) {
    useCurrentViewCheck(Functions.Forms_View_Form_Submissions)
    const  navigate = useNavigate();
    const classes = useDisplaySubmissionStyles()

    // const submissionId = useParams().id
    const dispatch = useDispatch()
    const currentWebSubmission = useSelector(state => state?.forms.currentWebSubmission)

    const [tableData, setTableData] = useState({})
    // const [tableHeader, setTableHeader] = useState({})
    const tableHeader = {}
    const [customerDetails, setCustomerDetails] = useState({})
    const [isLoaded, setIsLoaded] = useState(true)
    const loadData = () => {
        (async () => {
            setIsLoaded(await fetchWebSubmission(rowId)(dispatch));
            await updateReadStatus(rowId)
        })()
    }
    useEffect(loadData, [dispatch, rowId])
    useEffect(() => {
        if (isLoaded) {
            try {
                const componentDisplayInner = {}
                const customerDetailsInner = {}


                // set tracking details
                const tracking = currentWebSubmission?.[webFormConsts.tracking] || []
                const trackingHeaders = ['ip', 'device', 'browser', 'city', 'country', 'address', 'postalCode', 'latitude', 'longitude', 'source']
                trackingHeaders.forEach(header => {
                    componentDisplayInner[header] = {
                        label: header,
                        result: [tracking[header]]
                    }
                    customerDetailsInner[header] = tracking[header]
                })
                setCustomerDetails(customerDetailsInner)
                setTableData(componentDisplayInner)


            } catch (error) {
                sendErrorMessage(dispatch, "Error formatting submission results")
            }
        }
        delete currentWebSubmission?.[webFormConsts.tracking]
    }, [currentWebSubmission])
    const renderView = () =>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Label</TableCell>
                        <TableCell>Result</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentWebSubmission && Object.keys(currentWebSubmission).map(key =>
                        key === webFormConsts.description ?
                            Object.keys(currentWebSubmission[key]).map(innerKey =>
                                currentWebSubmission[key][innerKey] !== "" && innerKey.toLowerCase().trim() !== webFormConsts.displayotherinput  &&
                                <TableRow key={innerKey}>
                                    <TableCell style={{verticalAlign: 'top'}}>
                                        <Typography variant='body2'>
                                            {capitalizeFirstChar(innerKey.replace(/([A-Z])/g, ' $1').trim())}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='body2' style={{whiteSpace: 'pre-line'}}>
                                                {innerKey.toLowerCase().trim() === webFormConsts.subscribe ? currentWebSubmission[key][innerKey][0].toString()
                                                    :
                                                    currentWebSubmission[key][innerKey].toString()}
                                            </Typography>
                                    </TableCell>
                                </TableRow>)
                            :
                            <TableRow key={key}>
                                <TableCell style={{verticalAlign: 'top'}}>
                                    <Typography variant='body2'>
                                        {capitalizeFirstChar(key)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='body2' style={{whiteSpace: 'pre-line'}}>
                                            {currentWebSubmission[key].toString()}
                                        </Typography>
                                </TableCell>
                            </TableRow>
                    )}

                    {Object.keys(tableData).map(key =>
                        <TableRow key={key}>
                            <TableCell style={{verticalAlign: 'top'}}>
                                <Typography variant='body2'>
                                    {capitalizeFirstChar(tableData[key].label)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='body2' style={{whiteSpace: 'pre-line'}}>
                                    {tableData[key].result.join(', ')}
                                </Typography>

                            </TableCell>
                        </TableRow>
                    )}

                </TableBody>
            </Table>
        </TableContainer>


    const renderBackButton = () =>
        <Stack spacing={2} direction='row'>
            <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate(`/app/forms/data/${currentWebSubmission.form?.id}`)}>
                All Submissions
            </Button>
            <Button color='secondary'
                    variant='contained'
                    disabled={!isLoaded}
                    onClick={() => addCustomerFromForm(dispatch, history, customerDetails, tableHeader)}>
                Create User
            </Button>
        </Stack>

    return (
        <div>
            {!isPopUp ?
                <ViewHead functionId={Functions.Forms_View_Form_Submissions} isShowTitle={false}>
                    {
                        isLoaded &&
                        <Stack spacing={2} className={classes.pageTitle}>
                            <Typography variant='h3' color='textPrimary'>
                                Submission
                                for {currentWebSubmission.form?.name} [{currentWebSubmission.form?.category?.name || 'No Category'}]
                            </Typography>
                            {renderBackButton()}
                        </Stack>
                    }
                    <Paper elevation={0} className={classes.paper}>
                        {
                            isLoaded
                                ? renderView()
                                : <LoadingScreen message='Loading...'/>
                        }
                    </Paper>
                </ViewHead>
                :
                <div>
                    {
                        isLoaded &&
                        <Stack spacing={2} className={classes.pageTitle}>
                            <Typography variant='h3' color='textPrimary'>
                                Submission
                                for {currentWebSubmission?.form?.name} [{currentWebSubmission?.form?.category?.name || 'No Category'}]
                            </Typography>
                            {/* {renderBackButton()} */}
                        </Stack>
                    }
                    <Paper elevation={0} className={classes.paper}>
                        {
                            isLoaded
                                ? renderView()
                                : <LoadingScreen message='Loading...'/>
                        }
                    </Paper>
                </div>}
        </div>

    )
}
export default WebSubmissionDetails
