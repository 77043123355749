import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useReducer, useState} from 'react'
import {CardMedia, Paper, TextField, Tooltip, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {debounce} from 'lodash'
import IconButton from '@mui/material/IconButton'
import {Edit} from 'react-feather'
import {AddCircle, Delete, GetApp, InfoOutlined, Textsms} from '@mui/icons-material'
import Button from '@mui/material/Button'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import QRCode from 'qrcode'
import * as imageConversion from 'image-conversion'
import {useNavigate} from 'react-router-dom'
import {downloadPosterData, fetchCampaignPosters} from '../../../actions/campaign/campaignActions'
import {DEFAULT_SIZE, DEFAULT_WIDTH} from './index'
import GridCellExpand from '../../../components/mui/datagrid/GridCellExpand'
import PageGrid from '../../../components/mui/datagrid/PageGrid'
import useAuthCheck from '../../../hooks/useAuthCheck'
import {Functions} from '../../../utils/Functions'
import {frontEndUrl, QR_CODE_CONFIG} from '../../../constants'
import {getRenderedImage, IMG_URL_BASE, useCampaignDetailsStyles} from '../campaignHelper'
import {sendErrorMessage} from '../../../actions'
import {dataURLtoBlob} from './campaignPosterTables'

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch'
        }
    },
    download: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'end'
        // justifyContent: 'center',
    },
    downloadSize: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',
        height: '30px'
    },
    sizeInput: {
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            'moz-appearance': 'none',
            margin: 0
        },
        '& input:invalid': {
            border: 'red solid 5px'
        },
        width: '55%'
    },
    widthInput: {
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            'moz-appearance': 'none',
            margin: 0
        },
        '& input:invalid': {
            border: 'red solid 5px'
        }
    },
    downloadWidth: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    downloadButton: {
        display: 'flex',
        flexDirection: 'column'
    },
    downloadFormat: {
        // padding: '1px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    format: {
        height: 24
    }
}))

const forceUpdateReducer = i => i + 1

const useForceUpdate = () => {
    const [, forceUpdate] = useReducer(forceUpdateReducer, 0)
    return forceUpdate
}

function CampaignPosterTable({
    campaignId,
    currentUserId,
    isSmallView,
    isUserInCampaign,
    handleDeleteOpen,
    handleModalOpen,
    handleSMSOpen,
    handlePosterClick
}) {
    const navigate = useNavigate()
    const [canAddPoster, canUpdatePoster, canDeletePoster, canDownloadPoster] = useAuthCheck([
        Functions.Campaign_Add_Poster,
        Functions.Campaign_Edit_Poster,
        Functions.Campaign_Delete_Poster,
        Functions.Campaign_Download_Poster
    ])

    const allPosters = useSelector(state => state.campaign.currentCampaignPosters)

    const styles = useCampaignDetailsStyles()
    const classes = useStyles()

    const forceUpdate = useForceUpdate()

    const dispatch = useDispatch()
    const [imgSize, setImgSize] = useState({})
    const [imgWidth, setImgWidth] = useState({})
    const [imgFormat, setImgFormat] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [searchArgs, setSearchArgs] = useState({
        searchKey: undefined,
        campaignId
    })

    //   const searchArgs = {
    //     searchKey: undefined,
    //     campaignId
    // }

    const [displayList, setDisplayList] = useState(allPosters)

    const [postersSize, setPostersSize] = useState({})
    // const [defaultSize, setDefaultSize] = useState({})
    const [postersWidth, setPostersWidth] = useState({})
    // const [defaultWidth, setDefaultWidth] = useState({})
    const [postersHeight, setPostersHeight] = useState({})
    const [initRatio, setInitRatio] = useState({})
    // const [defaultFormat, setDefaultFormat] = useState({})

    useEffect(() => {
        // Download stuff?
        const postersSizeTemp = {}
        const defaultSizeTemp = {}
        const postersWidthTemp = {}
        const defaultWidthTemp = {}
        const postersHeightTemp = {}
        const initRatioTemp = {}
        const defaultFormatTemp = {}

        // used to display
        const tempPosterList = []

        if (allPosters.data) {
            const posterList = allPosters.data
            // Compute the users downloaded
            posterList.forEach(tempPoster => {
                tempPosterList.push({
                    ...tempPoster,
                    downloadStatus: `${tempPoster.userDownloaded.length}/${
                        tempPoster.userDownloaded.length + tempPoster.userNotDownloaded.length
                    }`
                })
            })

            for (let i = 0; i < posterList.length; i += 1) {
                postersSizeTemp[posterList[i].id] = posterList[i].size
                defaultSizeTemp[posterList[i].id] = DEFAULT_SIZE

                postersWidthTemp[posterList[i].id] = posterList[i].width
                defaultWidthTemp[posterList[i].id] = DEFAULT_WIDTH

                postersHeightTemp[posterList[i].id] = posterList[i].height
                initRatioTemp[posterList[i].id] = posterList[i].height / posterList[i].width

                defaultFormatTemp[posterList[i].id] = 'JPEG'
            }
        }
        allPosters.data = tempPosterList
        setDisplayList(allPosters)

        setPostersSize(postersSizeTemp)
        // setDefaultSize(defaultSizeTemp)
        setPostersWidth(postersWidthTemp)
        // setDefaultWidth(defaultWidthTemp)
        setPostersHeight(postersHeightTemp)
        setInitRatio(initRatioTemp)
        // setDefaultFormat(defaultFormatTemp)

        setImgSize(defaultSizeTemp)
        setImgWidth(defaultWidthTemp)
        setImgFormat(defaultFormatTemp)
    }, [allPosters])

    const genHoverCell = value => {
        const stylesTemp = {
            hoverCell: {
                width: '100%',
                padding: '1rem',
                fontFamily: 'Sans-Serif',
                maxHeight: '50vh',
                overflow: 'auto'
            }
        }
        return <div style={stylesTemp.hoverCell}>{value}</div>
    }

    const onFetchUIDSuccess = async (dispatchProp, data, campaignIdProp, currentUserIdProp) => {
        const qrCodeImage = await QRCode.toDataURL(
            `${frontEndUrl}/poster/${campaignIdProp}/${data.posterId}/${currentUserIdProp}`,
            QR_CODE_CONFIG
        )

        const formattedData = {
            poster: `${IMG_URL_BASE}/${data.poster}`,
            qrcode: qrCodeImage,
            coordinates: data.coordinates,
            format: data.format,
            size: data.size.toString(),
            width: data.width.toString(),
            height: data.height.toString()
        }

        try {
            const imageData = await getRenderedImage(formattedData)
            if (imageData) {
                const link = document.createElement('a')
                const img = await imageConversion.compressAccurately(dataURLtoBlob(imageData), {
                    size: formattedData.size,
                    type: formattedData.format,
                    width: formattedData.width,
                    height: formattedData.height
                })
                link.href = URL.createObjectURL(img)
                link.download = data.fileName
                document.body.appendChild(link)
                link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}))
                link.parentNode.removeChild(link)
            }
        } catch (e) {
            onError(dispatchProp, e.message)
        }
    }

    const onError = (d, msg) => {
        sendErrorMessage(d, msg)
    }

    const handleSize = debounce((e, id) => {
        Object.keys(imgSize).forEach(idx => {
            if (idx === id.toString()) {
                if (parseInt(e, 10) > postersSize[idx]) {
                    imgSize[idx] = postersSize[idx]
                } else {
                    imgSize[idx] = e
                }
            }
        })
        setImgSize(imgSize)
        forceUpdate()
    }, 500)

    const handleWidth = debounce((e, id) => {
        Object.keys(imgWidth).forEach(idx => {
            if (idx === id.toString()) {
                imgWidth[idx] = e
            }
        })
        setImgWidth(imgWidth)
        forceUpdate()
    }, 500)

    const handleFormat = (e, id) => {
        Object.keys(imgFormat).forEach(idx => {
            if (idx === id.toString()) {
                imgFormat[idx] = e.target.value
            }
        })
        setImgFormat(imgFormat)
        forceUpdate()
    }

    const renderGrid = () => {
        const columns = []

        // For everyone to see
        columns.push({field: 'id', headerName: 'ID', width: 70})

        // Manage perms
        if (canUpdatePoster || canDeletePoster) {
            columns.push(
                ...[
                    {
                        field: '_actions',
                        headerName: ' ',
                        resizable: false,
                        disableClickEventBubbling: true,
                        width: 115,
                        filterable: false,
                        renderCell: params => (
                            <div>
                                {canUpdatePoster && (
                                    <Tooltip title="Edit" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            onClick={() =>
                                                navigate(`/app/campaign/view/${campaignId}/edit/${params.row.id}`)
                                            }
                                        >
                                            <Edit size={20} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {canDeletePoster && (
                                    <Tooltip title="Delete" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            onClick={() => handleDeleteOpen(params.row.id)}
                                        >
                                            <Delete size={20} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {canUpdatePoster && (
                                    <Tooltip title="Send SMS" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            onClick={() => handleSMSOpen(params.row.id)}
                                        >
                                            <Textsms size={20} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        )
                    },
                    {
                        field: '_info',
                        headerName: 'Info',
                        resizable: false,
                        disableClickEventBubbling: true,
                        width: 90,
                        filterable: false,
                        renderCell: params => (
                            <div>
                                <Tooltip title="Info" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() =>
                                            handleModalOpen(
                                                params.row.userDownloaded,
                                                params.row.userNotDownloaded,
                                                params.row.tracks
                                            )
                                        }
                                    >
                                        <InfoOutlined size={20} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )
                    }
                ]
            )
        }

        // Downloading perms
        if (canDownloadPoster && isUserInCampaign) {
            columns.push(
                {
                    field: '_download',
                    headerName: 'DL',
                    resizable: false,
                    disableClickEventBubbling: true,
                    width: 75,
                    filterable: false,
                    renderCell: params => {
                        const hasDownloaded = params.row?.userDownloaded?.includes(currentUserId)
                        return (
                            <Tooltip title="Download" placement="top">
                                <IconButton
                                    style={{color: hasDownloaded ? 'green' : 'red'}}
                                    size="small"
                                    component="span"
                                    onClick={() => {
                                        downloadPosterData(
                                            campaignId,
                                            params.row.id,
                                            currentUserId,
                                            params.row.coordinates,
                                            onFetchUIDSuccess,
                                            onError,
                                            imgFormat[params.row.id],
                                            imgSize[params.row.id],
                                            imgWidth[params.row.id],
                                            imgWidth[params.row.id] * initRatio[params.row.id]
                                        )(dispatch)
                                        const elementsSize = document.querySelectorAll(`#sizeInput`)
                                        const elementsWidth = document.querySelectorAll('#widthInput')
                                        for (let index = 0; index < elementsSize.length; index += 1) {
                                            elementsSize[index].value = DEFAULT_SIZE
                                            elementsWidth[index].value = DEFAULT_WIDTH
                                        }
                                        forceUpdate()
                                    }}
                                >
                                    <GetApp size={20} />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                },
                {
                    field: ' ',
                    headerName: 'DL Option',
                    resizable: true,
                    disableClickEventBubbling: true,
                    width: 180,
                    filterable: false,
                    renderCell: params => (
                        <form className={classes.download}>
                            <div className={classes.downloadSize}>
                                <div>Size:&nbsp;&nbsp; </div>
                                <TextField
                                    variant="outlined"
                                    id="sizeInput"
                                    type="number"
                                    className={classes.sizeInput}
                                    placeholder={DEFAULT_SIZE}
                                    size="small"
                                    onChange={e => handleSize(e.target.value, params.row.id)}
                                    title="Please input a positive number"
                                />
                                <div>K</div>
                            </div>

                            <div className={classes.downloadWidth}>
                                <TextField
                                    variant="outlined"
                                    id="widthInput"
                                    type="number"
                                    className={classes.widthInput}
                                    placeholder={DEFAULT_WIDTH}
                                    size="small"
                                    onChange={e => handleWidth(e.target.value, params.row.id)}
                                    title="Please input a positive number"
                                />
                                <div className={classes.downloadButton}>
                                    {imgWidth[params.row.id] ? (
                                        <Button>
                                            {imgWidth[params.row.id]}*
                                            {Math.round(imgWidth[params.row.id] * initRatio[params.row.id])}
                                        </Button>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                            </div>
                            <div className={classes.downloadFormat}>
                                <Button>
                                    {postersWidth[params.row.id]}*{postersHeight[params.row.id]}
                                </Button>
                                <select
                                    className={classes.format}
                                    value={imgFormat[params.row.id]}
                                    onChange={e => handleFormat(e, params.row.id)}
                                >
                                    <option value="JPEG">JPEG</option>
                                    <option value="PNG">PNG</option>
                                </select>
                            </div>
                        </form>
                    )
                }
            )
        }

        columns.push(
            ...[
                {field: 'name', headerName: 'Name', width: 150},
                {
                    field: 'uid',
                    headerName: 'Poster',
                    width: 110,
                    renderCell: params => (
                        <CardMedia
                            className={styles.posterFile}
                            image={`${IMG_URL_BASE}/${params.value}`}
                            onClick={() => handlePosterClick(`${IMG_URL_BASE}/${params.value}`)}
                        />
                    )
                },
                {field: 'title', headerName: 'Title', width: 150},
                {
                    field: 'description',
                    headerName: 'Description',
                    width: 250,
                    renderCell: ({value}) => (
                        <GridCellExpand
                            defaultValue={value}
                            expandValue={genHoverCell(
                                <Typography style={{whiteSpace: 'pre-line'}} variant="body2">
                                    {value}
                                </Typography>
                            )}
                            maxWidth="800px"
                            minWidth="200px"
                        />
                    )
                },
                {field: 'downloadStatus', headerName: 'Downloads', width: 135},
                {field: 'scans', headerName: 'Scans', width: 110},
                {
                    field: 'smsDate',
                    headerName: 'SMS Sent',
                    disableClickEventBubbling: true,
                    width: 200,
                    filterable: false,
                    valueFormatter: ({value}) => (value ? moment(value).format('L LT') : 'Never')
                },
                {
                    field: 'createdAt',
                    headerName: 'Date',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                }
            ]
        )

        return (
            <Paper elevation={0}>
                <PageGrid
                    columns={columns}
                    datas={displayList}
                    fetchDatas={fetchCampaignPosters}
                    searchArgs={searchArgs}
                    defaultSortColumn="id"
                    rowHeight={200}
                    totalHeight={isSmallView ? '86vh' : '46vh'}
                />
            </Paper>
        )
    }

    return (
        <div className={styles.posterList}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h2" color="textPrimary">
                        Posters
                    </Typography>
                </Grid>

                <Grid item>
                    {canAddPoster && (
                        <Tooltip title="Add Poster" placement="top">
                            <IconButton onClick={() => navigate(`/app/campaign/view/${campaignId}/add`)} size="large">
                                <AddCircle style={{color: 'green'}} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>

            {renderGrid()}
        </div>
    )
}

export default CampaignPosterTable
