import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
    useGridApiRef
} from "@mui/x-data-grid";
import {DataGridPro} from "@mui/x-data-grid-pro";
import CustomLoadingOverlay from "./XCustomLoadingOverlay";
import CustomNoRowsOverlay from "./XCustomNoRowsOverlay";
import CustomFooterOverlay from './CustomFooterOverlay';
import CustomGridExportButton  from './CustomGridExportButton';
import { PAGE_PARAMS } from '../../../constants';
import { ChangeRowsPerPage } from '../../../actions';
import { getLocalRowsPerPage } from './PageGrid'


const CustomDataGridOverlays = {
    LoadingOverlay: CustomLoadingOverlay,
    NoRowsOverlay: CustomNoRowsOverlay,
}

function CustomToolbar(apiRef) {
  return <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <CustomGridExportButton apiRef={apiRef}/>
    </GridToolbarContainer>
}


const useStyles = makeStyles({
    gridList: {
        '& .multiline': {
            lineHeight: 'normal'
        },
        '& div[style*="z-index: 100000"]': {
            display: 'none'
        }
    }
})

export default function LXGrid(props) {

    const classes = useStyles()
    const apiRef = useGridApiRef()
    const [page, setPage] = useState(1)

    const handlePageChange = (event, value) => setPage(value)

    const {totalHeight = '77vh', showToolbar,
        // pageSize = PAGE_PARAMS.rowsPerPageOptions,
        ...rest} = props;
    const customComponents = showToolbar
        ? { ...CustomDataGridOverlays, Toolbar: () => CustomToolbar(apiRef), Footer: () => CustomFooterOverlay(page, handlePageChange, rowPerPage, setRowPerPage, apiRef) }
        : { ...CustomDataGridOverlays, Footer: () => CustomFooterOverlay(page, handlePageChange, rowPerPage, setRowPerPage, apiRef) }
    const gridRef = useRef()
    const dispatch = useDispatch()
    const [rowPerPage, setRowPerPage] = useState(getLocalRowsPerPage())

    useEffect(()=>{
        ChangeRowsPerPage(rowPerPage)(dispatch)
    },[rowPerPage])

    return (
        <div style={{height: totalHeight, width: '100%',minHeight:400}} ref={gridRef} id="xgrid">
            <div style={{display: 'flex', height: '100%'}}>
                <div style={{flexGrow: 1}}>
                    <DataGridPro
                        className={classes.gridList}
                        apiRef={apiRef}
                        pageSize={rowPerPage}
                        page={page - 1}
                        pagination
                        rowsPerPageOptions={PAGE_PARAMS.rowsPerPageOptions}
                        density="compact"
                        disableDensitySelector
                        disableColumnMenu
                        // NOTE: in Mui5, isCellEditable is expecting a function that returns a boolean
                        isCellEditable={()=>false}
                        components={customComponents}
                        {...rest}
                    />
                </div>
            </div>
        </div>
    )
}

LXGrid.propTypes = {
    toolBar: PropTypes.func
}
LXGrid.defaultProps = {
    toolBar: ()=>{}
}
