export const APP_VERSION = process.env.REACT_APP_VERSION || '21.0.0'

export const ENABLE_REDUX_DEV_TOOLS = process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'YES'

export const LocalStorageItems = {
    Token: 'token',
    UserSetting: 'user_setting',
    BiLegendSelected: 'bi_legend_selected',
    YAxisValueName: 'y_axis_value_name'
}
export const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK'
}

export const LANGUAGE = {
    ENGLISH: 'en',
    CHINESE: 'zh'
}

export const ServerBaseURL = process.env.REACT_APP_ServerBaseURL || 'http://localhost:8201'

export const DEFAULT_APP_TITLE = 'Learning Management System v2.0'
export const frontEndUrl = `https://${window.location.host}`

export const USER_ROLE_ID = {
    ADMIN: 3,
    STUDENT: 7
}
export const MULTILINE_SEPARATOR = '{$multilineSep$}'
export const QR_CODE_CONFIG = {width: 250, height: 250, margin: 2}
export const MAX_TEXT_LENGTH = 50000 // For storing text in backend database

export const MorningTime = 10
export const AfternoonTime = 16

export const PAGE_PARAMS = {
    initRowsNum: 30,
    defaultPageSize: 50,
    rowsPerPageOptions: [25, 50, 100]
}

export const SORT_ORDER = {
    asc: 'ASC',
    desc: 'DESC'
}

export const CATEGORY_ITEMS = {
    all: 'all',
    none: 'none'
}
export const copyBoardStyle = {
    color: '#ebebeb',
    size: '20',
    style: {marginBottom: '5px'}
}

export const webFormConsts = {
    description: 'description',
    displayotherinput: 'displayotherinput',
    subscribe: 'subscribe',
    tracking: 'tracking'
}

export const NONE = 'None'

export const TimePeriodUnit = {
    YEARS: 'years',
    QUARTERS: 'quarters',
    MONTHS: 'months',
    WEEKS: 'weeks',
    DAYS: 'days'
}

export const GroupByTimeUnit = {
    YEAR: 'year',
    MONTH: 'month',
    DAY: 'day'
}

export const colorsOptions = ['default', 'primary', 'success', 'warning', 'secondary', 'info']

export const colorsValues = [
    'default.sucess',
    'primary.main',
    'success.main',
    'warning.main',
    'secondary.main',
    'info.main'
]

export const svgPie =
    '<path d="M45,95c22.056,0,40-17.944,40-40c0-1.381-1.119-2.5-2.5-2.5h-35v-35c0-1.381-1.119-2.5-2.5-2.5C22.944,15,5,32.944,5,55   S22.944,95,45,95z M42.5,20.088V55c0,1.381,1.119,2.5,2.5,2.5h34.912C78.625,75.636,63.458,90,45,90c-19.299,0-35-15.701-35-35   C10,36.542,24.364,21.375,42.5,20.088z" stroke="none"></path><path d="M55,5c-1.381,0-2.5,1.119-2.5,2.5V45c0,1.381,1.119,2.5,2.5,2.5h37.5c1.381,0,2.5-1.119,2.5-2.5C95,22.944,77.056,5,55,5z    M57.5,42.5V10.088C74.81,11.316,88.684,25.19,89.912,42.5H57.5z" stroke="none"></path>'
