import React from 'react'
import LogoImgSmall from '../../assets/images/logo-small.png'
import LogoImgBig from '../../assets/images/itlabprologo-icon-white.svg'

function Logo(props) {
    const {size} = props

    const LogoImg = size === 'big' ? LogoImgBig : LogoImgSmall
    return <img alt="Logo" src={LogoImg} {...props} />
}

export default Logo
