import React from 'react'
import { Tooltip, Typography, IconButton } from '@mui/material';
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import { Cached as CachedIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {TOP_UP_STATUS_COLOUR_DICT} from '../../matricHelper'

export function AccountStatusCell(props) {
    const { row } = props
    const userActive = row?.isActive
    const userStatus = row?.status
    return userStatus !== undefined ? (
        <div>
            <Typography variant="body2" style={{color: TOP_UP_STATUS_COLOUR_DICT[+userActive].colour}}>
                {userActive ? 'Enabled' : 'Disabled'}
            </Typography>
            <Typography variant="body2" style={{color: TOP_UP_STATUS_COLOUR_DICT[userStatus].colour}}>
                Status: {TOP_UP_STATUS_COLOUR_DICT[userStatus].status}
            </Typography>
        </div>
    ) : (
        <div />
    )
}

export function AccountTimeCell(props) {
    const { row } = props
    const userStatus = row?.status
    if (userStatus === undefined) return <div />

    const addTime = row?.createTime
    const checkTime = row?.checkTime
    const offlineTime = row?.offlineTime
    return (
        <div>
            <Tooltip title={moment(addTime).format('L LT')} placement="top">
                <Typography variant="body2">{`Added: ${moment(addTime).fromNow()}`}</Typography>
            </Tooltip>
            <Tooltip title={moment(checkTime).format('L LT')} placement="top">
                <Typography variant="body2">{`Checked: ${moment(checkTime).fromNow()}`}</Typography>
            </Tooltip>
            {userStatus !== 1 && (
                <Tooltip style={{color: "red" }} title={moment(offlineTime).format('L LT')} placement="top">
                    <Typography variant="body2">{`Offline: ${moment(offlineTime).fromNow()}`}</Typography>
                </Tooltip>
            )}
        </div>
    )
}

export function AccountEditCommandCell(props) {
    const navigate = useNavigate()
    const {row, handleUserDelete, handleUserRefresh, loading} = props
    if (row?.status === undefined) return <div />
    return (
        <div>
            <Tooltip title="Check" placement="top">
                <IconButton onClick={() => handleUserRefresh(row.site, row.username)} disabled={loading}>
                    <CachedIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Edit" placement="top">
                <IconButton onClick={() => navigate(`/app/matric/topup/accounts/edit/${row.site}/${row.username}`)} disabled={loading}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Remove" placement="top">
                <IconButton onClick={() => handleUserDelete(row.site, row.username)} disabled={loading}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </div>
    )
}
