import * as React from 'react';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select, Table,
  TableBody,
  TableCell, TableHead,
  TableRow
} from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Tooltip from '@mui/material/Tooltip';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import Grid from '@mui/material/Grid';
import { debounce } from 'lodash';
import DraggableDialog from '../../../../components/mui/dialog/DraggableDialog';
import { DEFAULT_FETCH_PARAM } from '../../../../components/mui/datagrid/PageGrid';
import LXGrid from '../../../../components/mui/datagrid/XGrid';
import { SORT_ORDER } from '../../../../constants';
import { ContractPaymentLogActs } from './ContractPaymentLogActs';
import { centsToDollarString } from '../../../../utils/PaymentHelper';
import SearchBox from '../../../../components/public/SearchBox';
import { LogLoading } from './loading/Loading';
import {
  fetchAllPaymentLogsByPage
} from '../../../../actions/administrative/administrativeAction';
import { copyCustomerInfo, sendSuccessMessage } from '../../../../actions';
import api from '../../../../apis/api';
import LoadingScreen from '../../../../components/public/LoadingScreen';




export function ContractPaymentLogView(props) {



  const allPaymentLogs = useSelector(state => state?.administrative.allPaymentLogs);
  const { allContracts, isPaymentLogView, setIsPaymentLogView } = props
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [htmlView, setHtmlView] = useState(false);
  const [paymentsTrackView, setPaymentsTrackView] = useState(false);

  const [html, setHtml] = useState('');
  const [paymentsTrack, setPaymentsTrack] = useState([]);

  const [processStatus,setProcessStatus] = useState('all')
  const [searchArgs, setSearchArgs] = useState({
    searchKey: undefined
  });

  const [isPaymentLogActView,setIsPaymentLogActView] = useState(false)
  const [currentPaymentLog , setCurrentPaymentLog] = useState(null)
  const [isSearchLoading,setIsSearchLoading] = useState(false)
  const [htmlIsLoading, setHtmlIsLoading] = useState(false)


  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg);
  };

  const handleChangeProcessStatus = (event)=>{
    setProcessStatus(event.target.value);
    setIsLoading(true)
  }
  const searchPaymentLogHandler = debounce(async (searchVal) => {
    const searchStr = searchVal.trim().toLowerCase();
    if (searchStr !== '') {
      setIsSearchLoading(true);
      setSearchArgs(prevState => ({
        ...prevState,
        searchKey: searchStr
      }));
      setIsSearchLoading(false);
    } else {
      setSearchArgs(prevState => ({
        ...prevState,
        searchKey: undefined
      }));
    }

  }, 500);

  const loadData =()=>{
    fetchAllPaymentLogsByPage({
      ...searchArgs,
      ...DEFAULT_FETCH_PARAM,
      sortColumn: 'date', sortOrder: SORT_ORDER.desc,
      processStatus
    })(dispatch).then(()=>{
      setIsLoading(false)
    })
  }
  useEffect(()=>{
    loadData()
  },[processStatus,searchArgs])
  function PaymentHtmlViewDialog() {
    const handleClose = () => {
      setHtmlView(false);
    };

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={htmlView}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Viewing Payment Email Content
        </DialogTitle>
        <DialogContent>
          {!htmlIsLoading ?  <iframe title='PaymentEmailContent' srcDoc={html.data} width='100%' height="800px" /> : <LoadingScreen/> }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  function PaymentsTrackViewDialog() {
    const handleClose = () => {
      setPaymentsTrackView(false);
    };

    const rows = []
    for (let i = 0;i < paymentsTrack?.length;i+=1){
      const data = paymentsTrack[i]
      rows.push({...data,name:null})
    }
    for (let i = 0;i < allContracts?.data?.length;i+=1){
      for (let j = 0;j < rows?.length;j+=1)
      if (rows[j].cid === allContracts?.data[i].cid ){
        rows[j].name = allContracts.data[i].customer_fullName
      }
    }


    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={paymentsTrackView}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            Viewing Payments track Content
          </DialogTitle>
          <DialogContent>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Contract Id</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">Currency</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">receiveTime</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  let dateString = null
                  if (typeof(row.receiveTime) === "object"){
                    dateString = JSON.stringify(row.receiveTime)
                  }else {
                    dateString = row.receiveTime
                  }
                  dateString = dateString.substring(1, dateString.length - 1)
                  const dateView = new Date( Date.parse(dateString) )
                  return(<TableRow
                    key={row.cid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.cid}
                    </TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">{row.currency}</TableCell>
                    <TableCell align="right">{ centsToDollarString(row.amount,row.currency)}</TableCell>
                    <TableCell align="right"> {dateView.getFullYear()}/{dateView.getMonth() + 1}/{dateView.getDate()}</TableCell>
                  </TableRow>)
                  }


                )}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>close</Button>
          </DialogActions>
        </Dialog>
    );
  }


  const columns = [
    {
      field: 'replyTo',
      headerName: 'Email From',
      width: 220,
      renderCell: (params) =>{

        const payerNameArray = params.row.payerName.trim().split(' ')
        const lastNameString = payerNameArray.pop()
        const firstNameString = payerNameArray.join(' ')

        return <div style={{display: 'flex', flexDirection: 'row'}}>
          <Tooltip title='Copy Email & Name' placement='top'>
            <IconButton color='primary'
                        size='small'
                        component='span'
                        onClick={() =>{
                          dispatch(copyCustomerInfo(onSuccess ,{'email': params.row.replyTo, 'lastName': lastNameString, 'firstName': firstNameString }))
                        }}
            >
              <FileCopyOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Box m='auto'>
            {params.row.replyTo}
          </Box>
        </div>
      }



    },
    {
      field: 'payerName',
      headerName: 'Payer',
      width: 150
    },
    {
      field: 'editInfo',
      headerName: 'Actions',
      width: '80',
      filterable: false, renderCell: (params) => 
        // let filterContracts = [];
        // for (let i = 0; i < allContracts?.data?.length; i+=1) {
        //   for (let j = 0; j < allContracts?.data[i]?.paymentEmails?.length; j+=1) {
        //     if (allContracts?.data[i]?.paymentEmails[j]?.email === params?.row.replyTo) {
        //       filterContracts.push(allContracts?.data[i]);
        //     }
        //   }
        // }

         (
          <div>
            <LogActions setIsLoading={setIsLoading} params={params}
              // filterContracts={filterContracts}
                        setIsPaymentLogActView={setIsPaymentLogActView}
                        isPaymentLogActView={isPaymentLogActView}
                        setCurrentPaymentLog={setCurrentPaymentLog}
                        allPaymentLogs={allPaymentLogs} />
          </div>
        )
      
    },
    {
      field: 'date',
      headerName: 'Moment',
      width: 120,
      filterable: false, renderCell: (params) => <div>{moment(params.row.date).fromNow()}</div>
    },
    // {
    //   field: 'transferCurrency',
    //   headerName: 'Currency',
    //   width: 100
    // },
    {
      field: 'transferAmount',
      headerName: 'Amount',
      width: 110,
      renderCell: (param)=>centsToDollarString(param.row.transferAmount,param.row.transferCurrency)
    },

    {
      field: 'unusedAmount',
      headerName: 'Balance',
      width: 110,
      renderCell: (param)=>centsToDollarString(param.row.unusedAmount,param.row.transferCurrency)
    },
    {
      field: 'processStatus',
      headerName: 'Status',
      width: 150,
      renderCell: (params) =>{
        switch (params.row.processStatus){
          case 'partially_processed':
            return <Chip icon={<HourglassEmptyOutlinedIcon />} label="partially_processed" variant="outlined" color="secondary"/>
          case 'processed':
            return <Chip icon={<CheckIcon />} label="processed" variant="outlined" color="success"/>
          // case '':
          //   return <Chip icon={<ErrorOutlineOutlinedIcon />} label="Failed" variant="outlined" color={'error'}/>
          case 'no_contract':
            return <Chip icon={<WarningAmberOutlinedIcon />} label="no_contract" variant="outlined" color="warning"/>
          case 'unprocessed':
            return <Chip icon={<FiberNewOutlinedIcon />} label="unprocessed" variant="outlined" color="default"/>
          case 'manual':
            return <Chip icon={<DirectionsRunOutlinedIcon />} label="manual" variant="outlined" color="primary"/>
          case 'pending':
            return <Chip icon={<PauseOutlinedIcon />} label="pending" variant="outlined" color="info"/>
          default:
            return params.row.status
        }
      }


    },
    {
      field: 'html',
      headerName: 'Reference',
      width: 110,
      filterable: false, renderCell: (params) => (
          <div>
            <IconButton color='primary'
                        size='small'
                        component='span'
                        onClick={async () => {
                            setHtmlIsLoading(true)
                            setHtmlView(true)
                            const res = await api.post('administrative/contract/getPaymentLogHtml',{referenceNumber:params.row.referenceNumber})
                            setHtml(res)
                            setHtmlIsLoading(false)
                        }}>
              <RemoveRedEyeOutlinedIcon />
            </IconButton>

            <IconButton color='primary'
                        size='small'
                        component='span'
                        onClick={() => {
                          setPaymentsTrackView(true);
                          setPaymentsTrack(params.row.paymentHistories);
                        }}>
              <HistoryOutlinedIcon />
            </IconButton>
          </div>
        )
    },


    // {
    //   field: 'date',
    //   headerName: 'Date',
    //   width: 400,
    //   filterable: false, renderCell: (params) => {
    //     return <div>{moment(params.row.date).format('YYYY-MM-DD HH:mm:ss')}</div>
    //   }
    // },
  ];
  const handleClose = () => {
    setIsPaymentLogView(false);
  };
  return (
    <div>
      <DraggableDialog open={Boolean(isPaymentLogView)} onClose={() => setIsPaymentLogView(false)}
                       title='View Payment Logs' isLoaded={Boolean(isPaymentLogView)}>

        <DialogContent sx={{height:"90%"}}>

          <div
            style={{height:"95%"}}
          >
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
                <Grid container alignItems='center' justifyContent='flex-start' sx = {{mb:1}}>
                  <Grid item xs={6}>
                    <SearchBox
                      isLoading={isSearchLoading}
                      placeholder='Search...'
                      size='small'
                      type='search'
                      searchHandler={searchPaymentLogHandler} />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container alignItems='center' >
                      <Grid item display='flex' justifyContent='flex-start'>
                        <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                          <InputLabel id="processStatus-helper-label">Process Status</InputLabel>
                          <Select
                            labelId="processStatus-helper-label"
                            value={processStatus}
                            label="Process Status"
                            onChange={handleChangeProcessStatus}
                          >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="no_contract">No Contract</MenuItem>
                            <MenuItem value="partially_processed">Partially Processed</MenuItem>
                            <MenuItem value="pending">Pending</MenuItem>
                            <MenuItem value="manual">Manual</MenuItem>
                            <MenuItem value="unprocessed">Unprocessed</MenuItem>
                            <MenuItem value="processed">Processed</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <IconButton color='primary'
                                    size='small'
                                    component='span'
                                    onClick={loadData}>
                          <SyncOutlinedIcon />
                        </IconButton>
                      </Grid>

                    </Grid>

                  </Grid>
                </Grid>


            </div>
            <Box style={{ width:'100%', minWidth:800,height:'100%'}}  >
              <LXGrid rows={allPaymentLogs}
                      columns={columns}
                      sx={{minHeight:'50vh',maxHeight:'77vh', height:'100%'}}
                      totalHeight="100%"
                      rowHeight={65}
                // loading={!isLoaded}
                //       showToolbar
                // checkboxSelection
                // disableSelectionOnClick
                // sortModel={[{field: 'date', sort: 'desc',}]}
                // key={forceRefresh}
              />

            </Box>
            <PaymentHtmlViewDialog />
            <PaymentsTrackViewDialog />
            {isLoading && <LogLoading />}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </DraggableDialog>

      {isPaymentLogActView &&  !!currentPaymentLog && <ContractPaymentLogActs isPaymentLogActView={isPaymentLogActView} setIsPaymentLogActView={setIsPaymentLogActView} paymentLogId={currentPaymentLog.id} loadData = {loadData}/>}
    </div>


  );
}


function LogActions(props) {

  const { params,setIsPaymentLogActView,setCurrentPaymentLog } = props
  // const [formData, setFormData] = useState({
  //   pid: params.row.id,
  //   transferCurrency: params.row.transferCurrency,
  //   conversionRate: 1,
  //   spend: 0,
  //   cid: ''
  // });
  // const handleChange = (event) => {
  //   setFormData(
  //     { ...formData, cid: event.target.value }
  //   );
  // };

  // const onSuccess = (msg) => {
  //   sendSuccessMessage(dispatch, msg);
  // };
  //
  // const onError = (msg) => {
  //   sendErrorMessage(dispatch, msg);
  // };
  // const onSetIsLoading = () => {
  //   setIsLoading()
  // };
  // const onSubmit = async () => {
  //   const submitFormData = { ...formData };
  //   props.setIsLoading(true);
  //   await processPaymentLogManually(submitFormData.pid,
  //     submitFormData.transferCurrency,
  //     submitFormData.conversionRate,
  //     submitFormData.spend,
  //     submitFormData.cid,
  //     onSuccess, onError, onSetIsLoading)(dispatch);
  // };




  return (
    <div>
      <IconButton color='primary'
                  size='small'
                  component='span'
                  onClick={()=>{
                    setIsPaymentLogActView(true)
                    setCurrentPaymentLog(params.row)
                  }}
                  disabled={(!!(params.row.isActive === false || params.row.processStatus === "processed"|| params.row.processStatus === "unprocessed"))}
      >
        <PersonAddAltOutlinedIcon/>
      </IconButton>




      {/* <TextField style={{ width: '80px', marginRight: '20px' }} size='small' label='Ex.Rate' variant='outlined' */}
      {/*           defaultValue={formData.conversionRate} */}
      {/*           type='number' */}
      {/*           onChange={(e) => { */}
      {/*             setFormData( */}
      {/*               { ...formData, conversionRate: e.target.value } */}
      {/*             ); */}
      {/*           }} */}
      {/*  disabled={(props.params.row.isActive === false || props.params.row.processStatus === "processed"||props.params.row.transferCurrency === "CAD"? true: false)} */}
      {/* /> */}
      {/* <TextField style={{ width: '80px', marginRight: '20px' }} size='small' label='Spend' variant='outlined' */}
      {/*           defaultValue={formData.spend} */}
      {/*           type='number' */}
      {/*           onChange={(e) => { */}
      {/*             setFormData( */}
      {/*               { ...formData, spend: e.target.value } */}
      {/*             ); */}
      {/*           }} */}
      {/*  disabled={(props.params.row.isActive === false || props.params.row.processStatus === "no_contract" || props.params.row.processStatus === "processed"|| props.params.row.processStatus === "unprocessed"? true: false)} */}
      {/* /> */}


      {/* <FormControl size='small'> */}
      {/*  <InputLabel id='demo-simple-select-label'>Contract ID</InputLabel> */}
      {/*  <Select */}
      {/*    sx={{ width: '200px' }} */}
      {/*    labelId='demo-simple-select-label' */}
      {/*    id='demo-simple-select' */}
      {/*    value={formData.cid} */}
      {/*    label='Contract ID' */}
      {/*    onChange={handleChange} */}
      {/*    disabled={(props.params.row.isActive === false|| props.params.row.processStatus === "no_contract" || props.params.row.processStatus === "processed"|| props.params.row.processStatus === "unprocessed"? true: false)} */}

      {/*  > */}
      {/*    {filterContracts?.map((ele, index) => { */}
      {/*      return ( */}
      {/*        <MenuItem key={index} value={ele.cid}> */}
      {/*          <div style={{ */}
      {/*            width:"100%", */}
      {/*            display:"flex", */}
      {/*            justifyContent:"space-between" */}
      {/*          }}> */}
      {/*            <div> */}
      {/*              {ele.customer_fullName} */}
      {/*            </div> */}
      {/*            <div style={{color:"#3849AB"}}> */}
      {/*              {ele.cid} */}
      {/*            </div> */}
      {/*          </div> */}
      {/*          </MenuItem> */}
      {/*      ); */}
      {/*    })} */}


      {/*  </Select> */}
      {/* </FormControl> */}
      {/* <IconButton color='primary' */}
      {/*            size='small' */}
      {/*            component='span' */}
      {/*  disabled={(props.params.row.isActive === false|| props.params.row.processStatus === "no_contract"  || props.params.row.processStatus === "processed"|| props.params.row.processStatus === "unprocessed"? true: false)} */}
      {/*            onClick={() => { */}
      {/*              onSubmit(); */}
      {/*            }}> */}
      {/*  <CheckOutlinedIcon /> */}
      {/* </IconButton> */}
    </div>
  );
}

export default ContractPaymentLogView