import React, { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import SubmitButton from '../../../../components/mui/button/SubmitButton';
import { useInterviewEditorStyles } from '../interviewHelper';
import { sendErrorMessage } from '../../../../actions';
import { updateInterviewNotes } from '../../../../actions/customer/interviewActions';
import { MAX_TEXT_LENGTH } from '../../../../constants';

function EditCommentDialogue({ data, uid, onSubmitSuccess }) {

  const dispatch = useDispatch()
  const classes = useInterviewEditorStyles()

  const [isSaving, setIsSaving] = useState(false)
  const [noteContent, setNoteContent] = useState(data.notes || '')
  const [noteError, setNoteError] = useState(null)

  const onError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }

  const onSuccess = (msg) => {
    setIsSaving(false)
    onSubmitSuccess(msg)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSaving(true)
    setNoteError(null)

    if (noteContent.length > MAX_TEXT_LENGTH) {
      setIsSaving(false)
      setNoteError(`Comment cannot be longer than ${MAX_TEXT_LENGTH} characters`)
      return
    }

    await updateInterviewNotes({
      id: data.id,
      interviewId: data.interviewId,
      notes: noteContent
    }, uid, onSuccess, onError)(dispatch)
  }

  const renderTextField = () =>
    <Grid container>
      <Grid item xs={12}>
        <TextField variant='outlined' fullWidth size='small'
                   multiline rows={7} required
                   label='Comment' name='comment'
                   value={noteContent}
                   onChange={(e) => setNoteContent(e.target.value)}
                   error={!!noteError}
                   helperText={noteError || ''}
        />
      </Grid>
    </Grid>

  const renderSubmit = () =>
    <Grid container spacing={3} justifyContent='flex-end' alignItems='center'
          className={classes.marginTop}>
      <Grid item>
        <SubmitButton isSaving={isSaving} text='Save' isSavingText='Saving'
                      fullWidth variant='contained' color='primary'
        />
      </Grid>
    </Grid>

  return (
    <form onSubmit={onSubmit}>
      <Typography variant='h4' className={classes.marginBottom}>Edit Comment</Typography>
      { renderTextField() }
      { renderSubmit() }
    </form>
  )
}

export default EditCommentDialogue
