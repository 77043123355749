// Given base64 data url, return size of the image file
export const getImageSizeFromDataUrl = (dataUrl) => {
  const imageFileType = `${dataUrl.split(',')[0]  },`
  const stringLength = dataUrl.length - imageFileType.length
  return 4 * Math.ceil(stringLength / 3) * 0.5624896334383812
}

// Remove whitespace from the signature
// From: https://github.com/szimek/signature_pad/issues/49#issuecomment-260976909
export const cropSignatureCanvas = (canvas) => {
  // First duplicate the canvas to not alter the original
  const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d')

  croppedCanvas.width  = canvas.width
  croppedCanvas.height = canvas.height
  croppedCtx.drawImage(canvas, 0, 0)

  // Next do the actual cropping
  let w = croppedCanvas.width;
    let h = croppedCanvas.height;
    const pix = {x:[], y:[]};
    const imageData = croppedCtx.getImageData(0,0,croppedCanvas.width,croppedCanvas.height);
    let x; let y; let index

  for (y = 0; y < h; y+=1) {
    for (x = 0; x < w; x+=1) {
      index = (y * w + x) * 4
      if (imageData.data[index+3] > 0) {
        pix.x.push(x)
        pix.y.push(y)
      }
    }
  }
  pix.x.sort((a,b) =>a-b)
  pix.y.sort((a,b) =>a-b)
  const n = pix.x.length - 1

  w = pix.x[n] - pix.x[0]
  h = pix.y[n] - pix.y[0]
  const cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h)

  croppedCanvas.width = w
  croppedCanvas.height = h
  croppedCtx.putImageData(cut, 0, 0)

  return croppedCanvas.toDataURL()
}
