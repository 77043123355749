import api from '../../apis/api';
import { file2b64 } from '../../views/forms/posterGenerator/posterGenHelper';
import { sendErrorMessage } from '../message/messageActions';
import { pagedGridDefaultFetch } from '../../components/mui/datagrid/PagedGridAction';

export const PosterMakerActionType = {
  FetchAllPosterTemplates: 'FetchAllPosterTemplates',
  FetchPosterTemplate: 'FetchPosterTemplate',

  FetchPosterFile: 'FetchPosterFile',
  FetchLogoFile: 'FetchLogoFile',
  FetchPosterBaseFile: 'FetchPosterBaseFile',
  ClearPosterFiles: 'ClearPosterFiles',

  PosterTemplateFetchProps: 'PosterTemplateFetchProps',
}

export const fetchAllPosterTemplates = (args) => async (dispatch) => {
  try {
    const res = await pagedGridDefaultFetch('/posterMaker/getAllTemplate',
      args, PosterMakerActionType.PosterTemplateFetchProps)(dispatch)

    if (res.data?.rs) {
      dispatch({ type: PosterMakerActionType.FetchAllPosterTemplates, payload: res.data })
      return true
    }
  } catch (e) {
    sendErrorMessage(dispatch,'Can not fetchAllPosterTemplates')
    return false
  }
  return false
}

export const clearPosterTemplateFiles = () => async (dispatch) => {
  dispatch({ type: PosterMakerActionType.ClearPosterFiles, payload: null })
}

export const fetchPosterTemplate = (id, cb) => async (dispatch) => {
  try {
    const res = await api.get(`/posterMaker/getTemplate/${id}`)
    if (res.data?.rs) {
      dispatch({ type: PosterMakerActionType.FetchAllPosterTemplates, payload: res.data.data })
      return cb(true)
    } 
      sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch template')
      return cb(false)
    
  } catch (e) {
    sendErrorMessage(dispatch,'Cannot fetch template')
    return cb(false)
  }
}

export const fetchPosterFile = (id, cb) => async (dispatch) => {
  try {
    const res = await api.get(`/posterMaker/getTemplateFile/poster/${id}`, { responseType: 'blob' })
    if (res.data) {
      // convert to base64
      const image = new Blob([res.data], { type: 'image/png' })
      file2b64(image, (imageData) => {
        dispatch({ type: PosterMakerActionType.FetchPosterFile, payload: imageData })
        return cb(true)
      })
    } else {
      sendErrorMessage(dispatch,'Cannot fetch poster file')
      // console.log('Cannot fetch poster file')
      return cb(false)
    }
  } catch (e) {
    sendErrorMessage(dispatch,'Cannot fetch poster file')
    // console.log('Cannot fetch poster file', e)
    return cb(false)
  }
  return cb(false)
}

export const fetchPosterLogoFile = (id, cb) => async (dispatch) => {
  try {
    const res = await api.get(`/posterMaker/getTemplateFile/logo/${id}`, { responseType: 'blob' })
    if (res.data) {
      // convert to base64
      const image = new Blob([res.data], { type: 'image/png' })
      file2b64(image, (imageData) => {
        dispatch({ type: PosterMakerActionType.FetchLogoFile, payload: imageData })
        return cb(true)
      })
    } else {
      sendErrorMessage(dispatch,'Cannot fetch logo file')
      // console.log('Cannot fetch logo file')
      return cb(false)
    }
  } catch (e) {
    sendErrorMessage(dispatch,'Cannot fetch logo file')
    // console.log('Cannot fetch logo file', e)
    return cb(false)
  }
  return cb(false)
}

export const fetchPosterBaseFile = (id, cb) => async (dispatch) => {
  try {
    const res = await api.get(`/posterMaker/getTemplateFile/basePoster/${id}`, { responseType: 'blob' })
    if (res.data) {
      // convert to base64
      const image = new Blob([res.data], { type: 'image/png' })
      file2b64(image, (imageData) => {
        dispatch({ type: PosterMakerActionType.FetchPosterBaseFile, payload: imageData })
        return cb(true)
      })
    } else {
      sendErrorMessage(dispatch,'Cannot fetch poster base file')
      // console.log('Cannot fetch poster base file')
      return cb(false)
    }
  } catch (e) {
    sendErrorMessage(dispatch,'Cannot fetch poster base file')
    // console.log('Cannot fetch poster base file', e)
    return cb(false)
  }
  return cb(false)
}

export const createPosterTemplate = (data, onSuccess, onError) => async () => {
  try {
    const res = await api.post('/posterMaker/createTemplate', data)
    if (res.data?.rs) {
      return onSuccess(res.data.data)
    } 
      return onError(res.data?.message || 'Cannot create poster template')
    
  } catch (e) {
    // console.log(e)
    return onError(e.message)
  }
}

export const updatePosterTemplate = (data, onSuccess, onError) => async () => {
  try {
    const res = await api.put('/posterMaker/updateTemplate', data)
    if (res.data?.rs) {
      return onSuccess(res.data.data)
    } 
      return onError(res.data?.message || 'Cannot update poster template')
    
  } catch (e) {
    // console.log(e)
    return onError(e.message)
  }
}
