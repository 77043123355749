import React from 'react'
import {IconButton, Tooltip, Stack, Typography} from '@mui/material'
import {AttachFile, Delete, ListAlt, PersonAdd} from '@mui/icons-material'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import CardList from '../../../components/mui/cardList/CardList'
import {MULTILINE_SEPARATOR} from '../../../constants'
import {genHoverCell} from './formDataHelper'
import GridCellExpand from '../../../components/mui/datagrid/GridCellExpand'

function FormDataCardList({
    datas,
    columns,
    dispatch,
    tableHeaders,
    currentForm,
    addCustomerFromForm,
    handleDeleteOpen,
    handleFileOpen,
    canManageSubmission,
    canAddUser
}) {
    const cardDatas = datas || []
    const cardListData = []
    const navigate = useNavigate()
    if (cardDatas?.length > 0) {
        cardDatas.forEach(item => {
            let fieldIndex = 0
            const isActive = true
            const rows = []

            // Form Actions
            if (canManageSubmission || canAddUser) {
                fieldIndex += 1
                rows.push({
                    title: '',
                    body: (
                        <Stack direction="row" spacing={1}>
                            {canAddUser && (
                                <Tooltip title="Create User" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => addCustomerFromForm(dispatch, navigate, item, tableHeaders)}
                                    >
                                        <PersonAdd size={20} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="Submission Sheet" placement="top">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    component="span"
                                    onClick={() => navigate(`/app/forms/submission/${item.id}`)}
                                >
                                    <ListAlt size={20} />
                                </IconButton>
                            </Tooltip>
                            {canManageSubmission && (
                                <Tooltip title="Delete Submission" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => handleDeleteOpen(item.id)}
                                    >
                                        <Delete size={20} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    )
                })
            }

            // ID
            rows.push({
                title: columns[fieldIndex].headerName,
                body: <Typography variant="body2">{item.id}</Typography>
            })
            fieldIndex += 1
            // Rep
            rows.push({
                title: columns[fieldIndex].headerName,
                body: <Typography variant="body2">{item.repUser}</Typography>
            })
            fieldIndex += 1

            // Date
            rows.push({
                title: columns[fieldIndex].headerName,
                body: (
                    <Tooltip title={moment(item.createdAt).fromNow()} placement="top">
                        <Typography variant="body2">{moment(item.createdAt).format('L LT')}</Typography>
                    </Tooltip>
                )
            })
            fieldIndex += 1

            // Custom Fields
            tableHeaders.forEach(header => {
                let body = <div />
                // Conditionally render Grid based on the result type
                if (header.type === 'file') {
                    // If File, show download button
                    const fileName = item[header.name]
                    if (fileName) {
                        // Optional File would be empty, check if File UID exists
                        body = (
                            <Tooltip title="Open File" placement="top">
                                <IconButton size="small" onClick={() => handleFileOpen(fileName)(dispatch)}>
                                    <AttachFile fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                } else {
                    // Render Grid normally
                    const cellValue =
                        header.type === 'textarea' && item[header.name]
                            ? item[header.name]?.replaceAll(MULTILINE_SEPARATOR, '')
                            : item[header.name]
                    body = (
                        <GridCellExpand
                            defaultValue={cellValue}
                            expandValue={genHoverCell(
                                currentForm,
                                header.id,
                                header.type,
                                item.userSelected,
                                cellValue
                            )}
                            maxWidth="100%"
                            minWidth="200px"
                            forceExpand
                            wrapCell
                        />
                    )
                }

                rows.push({title: header.label, body})
            })

            cardListData.push({isActive, rows})
        })
    }

    return <CardList data={cardListData} />
}

export default FormDataCardList
