import styled from '@emotion/styled'
import {Switch} from '@mui/material'
import React from 'react'

const EqualSwitch = styled(props => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
    ({theme}) => ({
        '& .MuiSwitch-switchBase': {
            color: theme.palette.primary.main,
            '&.Mui-checked': {
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.primary.main,
                    opacity: '0.7'
                }
            }
        },
        '& .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.main,
            opacity: '0.7'
        }
    })
)

export default EqualSwitch
