import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Grid, Paper, TextField, Typography, Rating, Input} from '@mui/material'
import moment from 'moment'
// import { Upload } from '@progress/kendo-react-upload';
import clsx from 'clsx'
import {
    createInterviewNotes,
    fetchCustomerInterviews,
    updateInterview
} from '../../../../actions/customer/interviewActions'
import {sendErrorMessage, sendSuccessMessage} from '../../../../actions'
import {INTERVIEW_STATUS, useInterviewEditorStyles} from '../interviewHelper'
import ResponsiveDialog from '../../../../components/dialog/ResponsiveDialog'
import SubmitButton from '../../../../components/mui/button/SubmitButton'
import {capitalizeFirstChar} from '../../../../utils/Scripts'
import {MAX_TEXT_LENGTH} from '../../../../constants'

function ReviewControllerContainer({id, uid, interviewData}) {
    const dispatch = useDispatch()
    const classes = useInterviewEditorStyles()

    const [hash, setHash] = useState('init')
    const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false)
    const [interviewScore, setInterviewScore] = useState(interviewData.score ? parseFloat(interviewData.score) : 0)
    const [interviewScoreHover, setInterviewScoreHover] = useState(
        interviewData.score ? parseFloat(interviewData.score) : 0
    )

    const [isSaving, setIsSaving] = useState(false)
    const [interviewComment, setInterviewComment] = useState('')
    const [commentError, setCommentError] = useState(null)
    const [interviewFile, setInterviewFile] = useState(null)

    const [statusData, setStatusData] = useState({})
    const [confirmDialogueMsg, setConfirmDialogueMsg] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const fetchProps = useSelector(state => state.interview.interviewFetchBody)

    const handleSubmitStatus = async () => updateInterview(statusData, fetchProps, onStatusSuccess, onError)(dispatch)

    const handleConfirmOpen = (data, displayMsg, successMsgProp) => {
        setOpenConfirmDialogue(true)
        setConfirmDialogueMsg(displayMsg)
        setStatusData(data)
        setSuccessMsg(successMsgProp)
        setHash(Math.random().toString(16).substring(2, 10))
    }

    const handleScoreChange = async score => {
        if (score > 0) {
            const data = {id, score}
            await updateInterview(data, onStatusSuccess, onError)(dispatch)
            setInterviewScore(score)
        }
    }

    const handleCommentSubmit = e => {
        e.preventDefault()
        setIsSaving(true)
        setCommentError(null)

        if (interviewComment.length > MAX_TEXT_LENGTH) {
            setIsSaving(false)
            setCommentError(`Comment cannot be longer than ${MAX_TEXT_LENGTH} characters`)
            return
        }

        const file = new FormData()
        file.append('interviewId', id)
        file.append('notes', interviewComment)

        // Test if comment contains file
        const uploadFile = interviewFile?.length > 0 ? interviewFile[0]?.getRawFile() : null
        if (uploadFile) {
            file.append('file', uploadFile)
        }

        createInterviewNotes(file, uid, onCommentSuccess, onError)(dispatch)
    }

    const onStatusSuccess = async () => {
        await dispatch(fetchCustomerInterviews(uid))
        if (successMsg !== '') {
            sendSuccessMessage(dispatch, successMsg)
        }
        clearStatusData()
    }

    const onCommentSuccess = async msg => {
        await dispatch(fetchCustomerInterviews(uid))
        setIsSaving(false)
        setInterviewComment('')
        setInterviewFile(null)
        sendSuccessMessage(dispatch, msg)
    }

    const onError = msg => {
        setIsSaving(false)
        clearStatusData()
        sendErrorMessage(dispatch, msg)
    }

    const clearStatusData = () => {
        setSuccessMsg('')
        setStatusData({})
        setConfirmDialogueMsg('')
    }

    const renderForm = () => (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                {/* Top Button Array */}
                <Grid container spacing={2} className={classes.formContainer}>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            disabled={!!interviewData.endTime}
                            className={clsx('otStart', {
                                [classes.actionButton]: !interviewData.endTime
                            })}
                            onClick={async () => {
                                const data = {
                                    id,
                                    startTime: new Date().toISOString(),
                                    status: INTERVIEW_STATUS.ON_TIME
                                }
                                const msg = interviewData.startTime
                                    ? 'This interview has previously been started. Are you sure you want to override the start time?'
                                    : 'Start interview?'
                                handleConfirmOpen(data, msg, 'Interview started on time')
                            }}
                        >
                            OT Start
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            disabled={!!interviewData.endTime}
                            className={clsx('lStart', {
                                [classes.actionButton]: !interviewData.endTime
                            })}
                            onClick={async () => {
                                const data = {
                                    id,
                                    startTime: new Date().toISOString(),
                                    status: INTERVIEW_STATUS.LATE
                                }
                                const msg = interviewData.startTime
                                    ? 'This interview has previously been started. Are you sure you want to override the start time and mark it as late?'
                                    : 'Start interview and mark it as late?'
                                handleConfirmOpen(data, msg, 'Interview started late')
                            }}
                        >
                            Late Start
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} lg={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            disabled={!interviewData.startTime}
                            className={clsx('end', {
                                [classes.actionButton]: interviewData.startTime
                            })}
                            onClick={async () => {
                                const data = {id, endTime: new Date().toISOString()}
                                const msg = interviewData.endTime
                                    ? 'This interview has already concluded. Are you sure you want to update the end time?'
                                    : 'End interview now?'
                                handleConfirmOpen(data, msg, 'Interview ended')
                            }}
                        >
                            End
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} lg={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            className={`absent ${classes.actionButton}`}
                            onClick={async () => {
                                const data = {
                                    id,
                                    status: INTERVIEW_STATUS.ABSENT,
                                    startTime: new Date().toISOString(),
                                    endTime: new Date().toISOString()
                                }
                                const msg =
                                    interviewData.startTime || interviewData.endTime
                                        ? 'This interview has either been started or completed. Are you sure you want to mark the interviewee as absent?'
                                        : 'Mark the interviewee as absent?'
                                handleConfirmOpen(data, msg, 'Customer was absent')
                            }}
                        >
                            Absent
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} lg={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            className={`cancel ${classes.actionButton}`}
                            onClick={async () => {
                                const data = {id, status: INTERVIEW_STATUS.CANCELLED}
                                const msg =
                                    interviewData.startTime || interviewData.endTime
                                        ? 'This interview has either been started or completed. Are you sure you want to cancel this interview?'
                                        : 'Cancel this interview?'
                                handleConfirmOpen(data, msg, 'Interview cancelled')
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                {/* Score and Details Panel */}
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={6}>
                        <Typography component="legend">Score</Typography>
                        <div className={classes.scoreBox}>
                            <Rating
                                name="score"
                                value={interviewScore}
                                precision={0.5}
                                onChange={(e, v) => handleScoreChange(v)}
                                onChangeActive={(e, v) => {
                                    setInterviewScoreHover(v)
                                }}
                            />
                            {interviewScore !== null && (
                                <Typography className="scoreDisplay">
                                    {interviewScoreHover !== -1 ? interviewScoreHover : interviewScore}
                                </Typography>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6}>
                        <Paper className={classes.legend} elevation={0}>
                            <Typography component="legend" className="title">
                                Details
                            </Typography>
                            <Typography variant="body2">
                                Method:{' '}
                                {`${
                                    interviewData?.type.length > 0 ? capitalizeFirstChar(interviewData.type) : 'Unknown'
                                }`}
                            </Typography>
                            <Typography variant="body2">
                                Schedule:{' '}
                                {`${moment(interviewData.scheduledStartTime).format('L LT')} - ${moment(
                                    interviewData.scheduledEndTime
                                ).format('LT')}`}
                            </Typography>
                            <Typography variant="body2">
                                Start Time:{' '}
                                {interviewData.startTime ? moment(interviewData.startTime).format('L LT') : ''}
                            </Typography>
                            <Typography variant="body2">
                                End Time: {interviewData.endTime ? moment(interviewData.endTime).format('L LT') : ''}
                            </Typography>
                            <Typography variant="body2">Score: {interviewData.score}</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                {/* Comment Panel */}
                <form onSubmit={handleCommentSubmit}>
                    <Grid container className={classes.commentField}>
                        <Grid item xs={12} sm={6} md={8}>
                            <TextField
                                fullWidth
                                multiline
                                rows={5}
                                variant="outlined"
                                name="comment"
                                label="Comment"
                                required
                                value={interviewComment}
                                disabled={isSaving}
                                onChange={e => setInterviewComment(e.target.value)}
                                error={!!commentError}
                                helperText={commentError || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                type="file"
                                disabled={isSaving}
                                multiple={false}
                                className={clsx('fileUploader', {
                                    full: !interviewFile
                                })}
                                inputProps={{
                                    accept: '.pdf'
                                }}
                                onChange={e => setInterviewFile(e.target.files)}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-end" alignItems="center" className={classes.marginTop}>
                            <Grid item>
                                <SubmitButton
                                    isSaving={isSaving}
                                    text="Submit Comment"
                                    isSavingText="Submitting"
                                    variant="contained"
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )

    return (
        <div>
            {renderForm()}

            <ResponsiveDialog
                isOpen={openConfirmDialogue}
                openHash={hash}
                title="Confirm"
                content={confirmDialogueMsg}
                Buttons={[
                    {name: 'Yes', event: () => handleSubmitStatus()},
                    {name: 'Cancel', event: () => clearStatusData()}
                ]}
            />
        </div>
    )
}

export default ReviewControllerContainer
