import api from '../../apis/api'
import {sendErrorMessage} from '../message/messageActions'
import {isMobile} from '../../utils/Scripts'
import {pagedGridDefaultFetch} from '../../components/mui/datagrid/PagedGridAction'
import {customerActionsType} from './customerActions'

export const InterviewActionType = {
    FetchAllInterviews: 'FetchAllInterviews',
    FetchInterviewProps: 'FetchInterviewProps',
    FetchInterview: 'FetchInterview',
    FetchCustomerInterviews: 'FetchCustomerInterviews',
    FetchCustomerContract: 'FetchCustomerContract',

    FetchAllInterviewers: 'FetchAllInterviewers',
    FetchAllCustomers: 'FetchAllCustomers',
    FetchAllProducts: 'FetchAllProducts',

    ChangeInterviewTab: 'ChangeInterviewTab',
    ChangePlannerExpand: 'ChangePlannerExpand'
}

export const fetchAllInterviewers = () => async dispatch => {
    try {
        const res = await api.get(`/customer/interview/interviewers`)
        if (res.data?.rs) {
            dispatch({type: InterviewActionType.FetchAllInterviewers, payload: res.data.data})
            return true
        }
    } catch (e) {
        // console.log('Error fetching interviewers: ', e)
    }
    sendErrorMessage(dispatch, 'Cannot fetch interviewers')
    return false
}

export const fetchAllCustomers = () => async dispatch => {
    try {
        const res = await api.get(`/customer/fetchAllCustomers?isActive=true`)
        if (res.data) {
            dispatch({type: InterviewActionType.FetchAllCustomers, payload: res.data})
            return true
        }
    } catch (e) {
        // console.log('Error fetching customers: ', e)
    }
    sendErrorMessage(dispatch, 'Cannot fetch customers')
    return false
}

export const fetchAllInterviews = args => async dispatch => {
    try {
        const interviewUrl = '/customer/interview/fetchInterview'
        const res = await pagedGridDefaultFetch(interviewUrl, args, InterviewActionType.FetchInterviewProps, async () =>
            api.get(interviewUrl)
        )(dispatch)
        if (res?.data?.rs) {
            dispatch({type: InterviewActionType.FetchAllInterviews, payload: res?.data})
            return true
        }
        throw res?.data?.message
    } catch (e) {
        // console.log('Cannot fetch interview: ', e)
        sendErrorMessage(dispatch, 'Cannot fetch interviews')
        return false
    }
}

export const fetchAllProducts = () => async dispatch => {
    try {
        const res = await api.get('/product/getProduct')
        if (res.data?.rs) {
            const payloadData = res.data.data?.filter(prod => prod.isActive)
            dispatch({type: InterviewActionType.FetchAllProducts, payload: payloadData})
            return true
        }
    } catch (e) {
        // console.log('Error fetching products: ', e)
    }
    sendErrorMessage(dispatch, 'Cannot fetch products')
    return false
}

export const fetchInterview = id => async dispatch => {
    try {
        const res = await api.get(`/customer/interview/fetchInterview/${id}`)
        if (res.data?.rs) {
            dispatch({type: InterviewActionType.FetchInterview, payload: res.data.data})
            return true
        }
    } catch (e) {
        // console.log('Error fetching interview: ', e)
    }
    sendErrorMessage(dispatch, 'Cannot fetch interview')
    return false
}

export const fetchCustomerInterviews = uid => async dispatch => {
    try {
        const res = await api.get(`/customer/interview/fetchCustomerInterview/${uid}`)
        if (res.data?.rs) {
            dispatch({type: InterviewActionType.FetchCustomerInterviews, payload: res.data.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch customer interviews')
    }

    return false
}

export const createInterview = (data, fetchProps, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post(`/customer/interview/create`, data)
        if (res.data?.rs) {
            await dispatch(fetchAllInterviews(fetchProps))
            return onSuccess('Interview created')
        }
        return onError(res.data?.message || 'Cannot create interview')
    } catch (e) {
        // console.log('Error creating interview', e)
        return onError(e.message)
    }
}

export const updateInterview =
    (data, fetchProps, onSuccess, onError, msg = 'Interview updated') =>
    async dispatch => {
        try {
            const res = await api.put(`/customer/interview/edit`, data)
            if (res.data?.rs) {
                await dispatch(fetchAllInterviews(fetchProps))
                return onSuccess(msg)
            }
            return onError(res.data?.message || 'Cannot update interview')
        } catch (e) {
            // console.log('Error updating interview', e)
            return onError(e?.message)
        }
    }

export const createInterviewNotes = (data, uid, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post(`/customer/interview/notes`, data)
        if (res.data?.rs) {
            await dispatch(fetchCustomerInterviews(uid))
            return onSuccess('Comment saved')
        }
        return onError(res.data?.message || 'Cannot create comment')
    } catch (e) {
        // console.log('Error creating comment', e)
        return onError(e.message)
    }
}

export const updateInterviewNotes = (data, uid, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.put(`/customer/interview/notes`, data)
        if (res.data?.rs) {
            await dispatch(fetchCustomerInterviews(uid))
            return onSuccess('Comment updated')
        }
        return onError(res.data?.message || 'Cannot update comment')
    } catch (e) {
        // console.log('Error updating comment', e)
        return onError(e.message)
    }
}

export const deleteInterviewNotes = (iid, nid, uid, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.delete(`/customer/interview/notes/${iid}/${nid}`)
        if (res.data?.rs) {
            await dispatch(fetchCustomerInterviews(uid))
            return onSuccess('Comment deleted')
        }
        return onError(res.data?.message || 'Cannot delete comment')
    } catch (e) {
        // console.log('Error deleting comment', e)
        return onError(e.message)
    }
}

export const getInterviewFile =
    (iid, nid, mime, loadedData = null) =>
    async dispatch => {
        if (loadedData) {
            // For images that are already loaded
            const link = document.createElement('a')
            link.href = loadedData
            document.body.appendChild(link)
            if (!isMobile()) {
                link.target = '_blank'
            }
            link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}))
            link.parentNode.removeChild(link)
        } else {
            // Files that must be fetched from server
            try {
                const res = await api.post(`/customer/interview/noteFile`, {iid, nid}, {responseType: 'blob'})
                if (res.data) {
                    const url = window.URL.createObjectURL(new Blob([res.data], {type: mime}))
                    const link = document.createElement('a')
                    link.href = url
                    document.body.appendChild(link)
                    if (!isMobile()) {
                        link.target = '_blank'
                    }
                    link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}))
                    link.parentNode.removeChild(link)
                }
            } catch (e) {
                sendErrorMessage(dispatch, 'Unable to fetch file')
            }
        }
    }

export const getInterviewImage = (iid, nid, mime) => async dispatch => {
    try {
        const res = await api.post(`/customer/interview/noteFile`, {iid, nid}, {responseType: 'blob'})
        if (res.data) {
            const image = window.URL.createObjectURL(new Blob([res.data], {type: mime}))
            await dispatch({type: customerActionsType.FetchCustomerImageFile, payload: {id: nid, image}})
            return true
        }
    } catch (e) {
        // console.log('Unable to fetch image', e.message)
        sendErrorMessage(dispatch, 'Unable to fetch file')
    }
    return false
}
