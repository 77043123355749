import {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthCheck from "./useAuthCheck";

const useCurrentViewCheck = (id) => {
    const [isPass,isChecked] = useAuthCheck([id])
    const  navigate = useNavigate();
    useEffect(() =>{
        if(isChecked) {
            if(!isPass){
                navigate('/app')
            }
        }
    },[isChecked,isPass])

    return isPass;
};

export default useCurrentViewCheck;