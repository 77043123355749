import React from 'react';
import { connect, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { classActionsType } from '../../../actions/class/classActions';
import GridCellExpand from '../../../components/mui/datagrid/GridCellExpand';
import ViewHead from "../../../components/public/ViewHead";
import {Functions} from "../../../utils/Functions";
import LXGrid from '../../../components/mui/datagrid/XGrid';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    chip: {
        margin: theme.spacing(0.5)
    },
    section1: {
        margin: theme.spacing(3, 2)
    },
    section2: {
        margin: theme.spacing(2)
    },
    section3: {
        margin: theme.spacing(3, 1, 1)
    },
    text: {
        padding: '10px',
        fontSize: '20px'
    }
}));


function ReadClassReview({ isEdit, currentHistory}) {

    const classes = useStyles()
    const dispatch = useDispatch()

    const columns = [
        { field: 'id', headerName: 'Id', width: 50, sortable: false },
        { field: 'student', headerName: 'Student', width: 150},
        { field: 'score', headerName: 'Score', width: 100 },
        { field: 'pros', headerName: 'Pros', width: 300,
        renderCell: (params) => (
                <GridCellExpand
                    defaultValue={params.row.pros}
                    expandValue={params.row.pros}
                    maxWidth='400px'
                    minWidth='200px'
                    forceExpand
                    />
            )

        },
        {
            field: 'suggestions', headerName: 'Suggestions', width: 300, sortable: false,
            renderCell: (params) => (
                    <GridCellExpand
                        defaultValue={params.row.suggestions}
                        expandValue={params.row.suggestions}
                        maxWidth='400px'
                        minWidth='200px'
                        forceExpand
                    />
                )
        },
        { field: 'createdAt', headerName: 'Created', width: 200,
            valueFormatter: ({ value }) => moment(value).format('L LT')
        }
    ];
    const rows = isEdit? currentHistory?.oneHistory?.feedback?.map((item) => ({
            id: item.id,
            student: item.extension === 'yes' ? `${item.user.firstName} ${item.user.lastName}` : null,
            score: item.score,
            pros: item.pros,
            suggestions: item.suggestions,
            createdAt: item.createdAt
        })) : []
    const data = { columns, rows };

    const renderButton = () => (
            <Button type="button"  variant="contained" color="primary" onClick={() => {
                dispatch({ type: classActionsType.SetClassTableStatus, payload: 'READ' })
            }}>
                Back To Delivery
            </Button>
        )

    return (
      <ViewHead functionId={Functions.Training_Class_Class_Details} topButtons={renderButton()} noMaxWidth>
          <Paper color='white'>
              <div className={classes.root}>
                  <br/>
                  <Typography variant="h5" color="textPrimary">Class Delivery Review</Typography>
              </div>
              <LXGrid
                {...data}
                checkboxSelection
                disableSelectionOnClick
                sortModel={[{ field: 'createdAt', sort: 'desc' }]}
              />
          </Paper>
      </ViewHead>
    );
}


const mapStateToProps = () => ({

});

export default connect(mapStateToProps, {})(ReadClassReview);

