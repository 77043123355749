import { resActionType } from '../../actions/resources/resActions';

const initState = {
  resources: [],
  students:[]
}

export const resReducer = (state=initState,action = {}) => {
  const {type, payload} = action
  switch (type){
    case resActionType.FetchResources:
      return {...state, resources: payload}
    case resActionType.AddResources:
      return {...state, resources: payload}
    case resActionType.AddStudent:
      return {...state, students: [...state.students, payload]}
    case resActionType.FetchOneResource:
      return {...state, students: payload}
    default:
      return state
  }
}

export default resReducer