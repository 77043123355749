import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import { Button, Grid, Input, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSyllabusViewStyles } from '../syllabusHelper';
import { fetchClassesForSyllabus, fetchCustomers, sendErrorMessage } from '../../../../actions';
import LoadingScreen from '../../../../components/public/LoadingScreen';
import {
  classActionsType,
  fetchClassHistory, fetchHomework,
  fetchOneClass,
  setClassViewData
} from '../../../../actions/class/classActions';

const SelectClassView = forwardRef(({ selectedPoint }, ref) => {

  const dispatch = useDispatch()
  const classes = useSyllabusViewStyles()

  const allClasses = useSelector(state => state.syllabuses.allClasses)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isClassLoaded, setIsClassLoaded] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const [selectedClass, setSelectedClass] = useState('')
  const [availableClasses, setAvailableClasses] = useState([])

  const loadData = () => {
    (async() => {
      setIsClassLoaded(await fetchClassesForSyllabus()(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    if (!isLoaded && isClassLoaded) {
      const availableClass = []
      allClasses.forEach(curClass => {
        if (curClass.syllabus.some(syllabus =>
          syllabus.topics.some(topic =>
            topic.chapters.some(chapter =>
              chapter.points.some(point =>
                point.id === selectedPoint)
            )))) {
          availableClass.push(curClass)
        }
      })
      setAvailableClasses(availableClass)
      setIsLoaded(true)
    }
  }, [isClassLoaded, isLoaded])

  const handleCreateDelivery = async () => {
    setIsSaving(true)
    const  navigate = useNavigate();
    const getClass = await fetchOneClass(selectedClass)(dispatch)
    const getHistory = await fetchClassHistory(selectedClass)(dispatch)
    const getHomework = await fetchHomework(selectedClass)(dispatch)
    const getStudents = await fetchCustomers(selectedClass)(dispatch)

    if (getClass && getHistory && getHomework && getStudents) {
      await setClassViewData({
        classId: selectedClass,
        deliverId: selectedPoint
      })(dispatch)

      dispatch({
        type: classActionsType.SetClassTableStatus,
        payload: 'AddClassDeliver'
      })

      navigate(`/app/training/class`)
    } else {
      sendErrorMessage(dispatch, 'Cannot retrieve class data')
    }
  }

  const renderView = () =>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h4'>Pick the class to add this point to:</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel>Class</InputLabel>
          <Select fullWidth name='class' input={<Input />}
                  value={selectedClass}
                  onChange={(e) => setSelectedClass(e.target.value)}>
            { availableClasses.map(curClass =>
              <MenuItem key={curClass.id} value={curClass.id}>
                { `${curClass.id}: ${curClass.name}` }
              </MenuItem>
            ) }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography>{ `Class ID: ${selectedClass}` }</Typography>
        <Typography>{ `Point ID: ${selectedPoint}` }</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent='flex-end'>
          <Button color='primary'
                  variant='contained'
                  disabled={!selectedClass || !selectedPoint || isSaving}
                  onClick={() => handleCreateDelivery()}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Grid>

  return (
    <Paper className={classes.modalPaper} ref={ref}>
      {
        isLoaded
          ? renderView()
          : <LoadingScreen message='Loading...' />
      }
    </Paper>
  )
})

export default SelectClassView
