import React, { useState } from 'react';
import {FormHelperText, Input, InputAdornment} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Done } from '@mui/icons-material';
import {categoryMaxLength, categoryMinLength} from "./CategoryData";

function AddCategoryInput({ id, name, cb,
                            label='Category Name',
                            placeholder='Enter a category name',
                            disabled=false
}) {


  const [fieldValue, setFieldValue] = useState(name)
  const [error, setError] = useState('')
  const [isError, setIsError] = useState(false)

  const onFieldSubmit = (e) => {
    e.preventDefault()
    if (fieldValue.trim().length > categoryMaxLength
      ||fieldValue.trim().length < categoryMinLength) {
      setIsError(true)
      setError(`${label} should be between 1 and 30 characters`)
    } else {
      cb(id, fieldValue)
      setFieldValue('')
    }
  }

  const handleChange = (e) => {
    const {value} = e.target
    if (value.length > categoryMaxLength
      || value.length < categoryMinLength) {
      setIsError(true)
      setError(`${label} should be between 1 and 30 characters`)
      setFieldValue(value)
    } else {
      setFieldValue(value)
      setIsError(false)
      setError('')
    }
  }

  return (
    <form onSubmit={onFieldSubmit}>
      <Input fullWidth id="category-field" disabled={disabled}
             name="categoryName" label={label}
             size="small" value={fieldValue}
             onChange={handleChange}
             placeholder={placeholder}
             endAdornment={
               <InputAdornment position="end">
                 <IconButton type='submit' size="large">
                   <Done style={{ color: 'green' }}/>
                 </IconButton>
               </InputAdornment>
             }
      />
      <FormHelperText error={isError}>{error}</FormHelperText>
    </form>
  );
}

export default AddCategoryInput
