import parse from 'html-react-parser'
import {noop} from 'lodash'

function Term({term, setModalOpen = noop, setCurrentTerm = noop}) {
    const clickHandler = () => {
        setModalOpen(true)
        setCurrentTerm(term)
    }
    return (
        <div onDoubleClick={clickHandler}>
            <div>{term?.text ? parse(term?.text) : ''}</div>
        </div>
    )
}

export default Term
