import React, {useEffect} from 'react';
import { Box, Link, Typography } from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {fetchUserRole} from "../../../actions";
import LoadingScreen from "../../../components/public/LoadingScreen";

function NavUserInfoBox() {
    const dispatch = useDispatch()

    const user = useSelector(state =>state.auth.user)
    const role = useSelector(state => state.auth.role)

    const loadRole = ()=>{
        if (user && !role) {
            fetchUserRole()(dispatch);
        }
    }
    useEffect(loadRole,[user]);

    if(!user){
        return <LoadingScreen/>
    }
    return (
        <Box p={2}>
            {/* <Box display="flex" justifyContent="center" > */}
            {/*    <RouterLink to="/app/personal/info"> */}
            {/*        <UserAvatar uid={user?.uid} alt="User" className={classes.avatar} /> */}
            {/*    </RouterLink> */}
            {/* </Box> */}
            <Box textAlign="center" >
                <Link component={RouterLink} to="/app/personal/info" variant="h5" color="textPrimary" underline="none" >
                    {user.firstName}
                </Link>
                <Typography variant="body2"  color="textSecondary" >
                    Your tier:
                    {' '}
                    { Array.isArray(role) ? role.join(', ') : role || 'Unknown' }
                </Typography>
            </Box>
        </Box>
    );
}

export default NavUserInfoBox;
