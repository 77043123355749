// Review Product Helper Validator is to develop required constrains used for it.
import * as yup from 'yup'

export const REVIEW_PRODUCT_VALIDATOR = {
    name: yup
        .string()
        .required('Required')
        .min(1, 'Product name must be no less than 1 character.')
        .max(250, 'Product name must be no less than 1 character.')
        .trim(),
    reviewText: yup
        .string()
        .required('Required')
        .min(1, 'review details must be at least 1 character')
        .max(250, 'review details must be less than 250 characters'),
    score: yup
        .number()
        .required('This field is required')
        .test('The number must be between 0 to 10', value => value > 0 && value < 10)
}

export default REVIEW_PRODUCT_VALIDATOR
