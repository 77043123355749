import React from 'react';
import {
  Toolbar,
  Grid,
  debounce, ButtonGroup, Button
} from '@mui/material';
import { lighten, alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { connect } from 'react-redux';
import { CATEGORY_ITEMS } from '../../../constants';
import SearchBox from '../../../components/public/SearchBox';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '0!important'
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  title: {
    flex: '1 1 100%'
  },
  // inputRoot: {
  // color: 'inherit',
  // borderBottom: '2px solid #000'
  // },
  // inputInput: {
  // padding: theme.spacing(1, 1, 1, 0),
  // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  // transition: theme.transitions.create('width'),
  // width: '100%',
  // [theme.breakpoints.up('sm')]: {
  //     width: '12ch',
  //     '&:focus': {
  //         width: '20ch'
  //     }
  // }
  // },
  search: {
    padding: theme.spacing(1, 0),
    // position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    width: '100%',
  },
  // searchIcon: {
  //     padding: theme.spacing(0, 2),
  // height: '100%',
  // position: 'absolute',
  // pointerEvents: 'none',
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center'
  // },
  // filterButton: {
  //     padding: theme.spacing(0, 2)
  // },
  // button: {
  //     display: 'block',
  //     marginTop: theme.spacing(2)
  // },
  formControl: {
    padding: theme.spacing(1, 0),
    // margin: theme.spacing(2, 0),
    width: '100%'
    // [theme.breakpoints.up(768)]: {
    // width:'95%',
    // paddingLeft: theme.spacing(1),
    // },
  },
  activityButtons: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));


function EnhancedTableToolbar(props) {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleChange,
    selectedRole,
    selectedActive,
    selectedStatus,
    setSelectedRole,
    setSelectedActive,
    setSelectedStatus,
    onActive,
    isLoading
  } = props;
  // const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    // setOpen(false);
  };

  const handleOpen = () => {
    // setOpen(true);
  };

  const searchHandler = debounce((searchVal) => {
    handleChange(searchVal);
  }, 500);

  return (
    <Toolbar className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} xl={6}>

          <div className={classes.search}>
            <SearchBox
              placeholder='Search...'
              size='small'
              type='search'
              searchHandler={searchHandler}
              isLoading={isLoading}
            />
          </div>
        </Grid>

        <>
          <Grid item xs={12} sm={6} md={2} xl={2}>
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="demo-controlled-open-select-label">Select</InputLabel> */}
              <InputLabel>Role</InputLabel>
              <Select
                // labelId="demo-controlled-open-select-label"
                // id="demo-controlled-open-select"
                // open={open}
                size='small'
                onClose={handleClose}
                onOpen={handleOpen}
                value={selectedRole}
                onChange={e => {
                  setSelectedRole(e.target.value);
                }}
              >
                <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
                <MenuItem value="Staff">Staff</MenuItem>
                <MenuItem value="Customer">Customer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2} xl={2}>
            <FormControl className={classes.formControl}>
              <InputLabel>isActive</InputLabel>
              <Select
                size='small'
                onClose={handleClose}
                onOpen={handleOpen}
                value={selectedActive}
                onChange={e => {
                  setSelectedActive(e.target.value);
                }}
              >
                <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={2} xl={2}>
            <FormControl className={classes.formControl}>
              <InputLabel>Status</InputLabel>
              <Select
                size='small'
                onClose={handleClose}
                onOpen={handleOpen}
                value={selectedStatus}
                onChange={e => {
                  setSelectedStatus(e.target.value);
                }}
              >
                <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
                <MenuItem value="fullTime">Full Time</MenuItem>
                <MenuItem value="partTime">Part Time</MenuItem>
                <MenuItem value="campusAmbassador">Campus Ambassador</MenuItem>
                <MenuItem value={CATEGORY_ITEMS.none}>None</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </>

        <Grid item className={classes.activityButtons}
              xs={12}>
          <ButtonGroup variant='contained' color='primary' size='small'>
            <Button
              disabled={numSelected <= 0}
              onClick={() => onActive(true)}
            >
              Enable
            </Button>
            <Button
              disabled={numSelected <= 0}
              onClick={() => onActive(false)}
            >
              Disable
            </Button>
          </ButtonGroup>
        </Grid>

      </Grid>

    </Toolbar>

  );
}

export default connect(() => ({}), {})(EnhancedTableToolbar);
