import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {fetchEmployee} from "../../../actions";

import EditEmployeeForm from './EditEmployeeForm';

function EmployeeInfo({fetchEmployee}) {

    const employeesInfo = useSelector(state => state.employee.employeesPerPage.data);
    const id = useSelector(state => state.employee.currentEditEmployee);
    const [employeeInfo, setEmployee] = useState(employeesInfo.find(employee => employee.id === id));

    useEffect(() => {
        setEmployee(employeesInfo.find(employee => employee.id === id));
        fetchEmployee(id)
    }, [employeesInfo]);

    // const loadData = () => {
    //     fetchEmployee(id)(dispatch)
    // }
    // useEffect(loadData, [])

    return <EditEmployeeForm employeeInfo={employeeInfo}/>

}

const mapStateToProps = (state) => ({
        categories: state.employee?.categories,
        staffInfo: state.employee?.staffInfo
    });

export default connect(mapStateToProps,{fetchEmployee})(EmployeeInfo);
