import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import {
  cleanPixieScript,
  DEFAULT_PIXIE_CONFIG, PIXIE_HEIGHT_OFFSET, PIXIE_ID,
  PIXIE_TEXT_STYLES, POSTER_PLACEHOLDER
} from '../../posterGenHelper';
import '../../../../../utils/pixie-image-editor/pixie.min.css';
import { getTotalHeight } from '../../../../../utils/Scripts';
import LoadingScreen from '../../../../../components/public/LoadingScreen';

function PosterAddTextView({ baseImage, pixieConfig, tags, handleStepBack, handleStepComplete, isSaving }) {

  const [pixieEditor, setPixieEditor] = useState(null)
  const [isPixieLoaded, setIsPixieLoaded] = useState(false)
  const totalWindowHeight = getTotalHeight()

  // Load Pixie
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '/static/scripts/pixie-image-editor/pixie.min.js'
    script.onload = () => onPixieScriptLoaded()
    script.async = true
    script.id = PIXIE_ID
    document.body.appendChild(script)
  }, [])

  const onPixieScriptLoaded = () => {
    const pixie = new window.Pixie({
      ...DEFAULT_PIXIE_CONFIG,
      image: baseImage,
      onLoad: () => {
        if (pixie !== null) {
          setPixieEditor(pixie)
          if (pixieConfig !== null) {
            pixie.loadState(pixieConfig)
          }
          setIsPixieLoaded(true)
        }
      },
      ui: {
        ...DEFAULT_PIXIE_CONFIG.ui,
        height: `${totalWindowHeight-PIXIE_HEIGHT_OFFSET}px`
      }
    })
  }

  const handlePrevious = () => {
    const state = pixieEditor.getState()
    cleanPixieScript()
    handleStepBack(state)
  }

  const handleNext = () => {
    const state = pixieEditor.getState()
    cleanPixieScript()
    handleStepComplete(state)
  }

  const addText = (phObject) => {
    pixieEditor.getTool('text').add(phObject.text, PIXIE_TEXT_STYLES[phObject.header] || PIXIE_TEXT_STYLES.default)
  }

  const renderButtonField = () =>
    <Grid container spacing={2}>
      { Object.keys(POSTER_PLACEHOLDER).map((textLabel,index) =>
        <Grid item xs={6} sm={12} key={index}>
          <Button color='primary' fullWidth
                  variant='contained'
                  disabled={!tags.includes(textLabel)}
                  onClick={() => addText(POSTER_PLACEHOLDER[textLabel])}>
            {POSTER_PLACEHOLDER[textLabel].name}
          </Button>
        </Grid>
      ) }
    </Grid>

  const renderView = () =>
    <Grid container spacing={5} direction='row' justifyContent='space-between' alignItems='flex-start'>

      <Grid item xs={12}>
        <Grid container spacing={5} justifyContent='space-between'>
          <Grid item xs={12} sm={4} md={3} xl={2}>
            { renderButtonField() }
          </Grid>

          <Grid item xs={12} sm={8} md={9} xl={10}>
            <pixie-editor/>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent='space-between'>
        <Grid item>
          <Button
            disabled={isSaving || !isPixieLoaded}
            color='primary'
            variant='contained'
            onClick={handlePrevious}>
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={isSaving || !isPixieLoaded}
            color='primary'
            variant='contained'
            onClick={handleNext}>
            Next
          </Button>
        </Grid>
      </Grid>

    </Grid>

  return isSaving ? <LoadingScreen/> : renderView()
}

export default PosterAddTextView
