import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ViewHead from '../../../../components/public/ViewHead';
import { Functions } from '../../../../utils/Functions';
import CampaignPosterEditor from './campaignPosterEditor';
import { sendSuccessMessage } from '../../../../actions';
import { fetchCampaignPosterDetails } from '../../../../actions/campaign/campaignActions';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';

function CampaignEditPosterView() {
  useCurrentViewCheck(Functions.Campaign_Edit_Poster)
  const  navigate = useNavigate();
  const campaignId = parseInt(useParams().campaignId,10)
  const posterId = parseInt(useParams().posterId,10)
  const dispatch = useDispatch()
  const currentPoster = useSelector(state => state.campaign.currentPoster)

  const [isPosterDetailsLoaded, setIsPosterDetailsLoaded] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    (async() => {
      setIsPosterDetailsLoaded(await fetchCampaignPosterDetails(campaignId, posterId)(dispatch))
    })()
  }, [campaignId, posterId, dispatch])

  useEffect(() => {
    if (!isLoaded && isPosterDetailsLoaded) {
      if (currentPoster && currentPoster.campaign.id === campaignId)
        setIsLoaded(true)
    }
  }, [currentPoster, isPosterDetailsLoaded, isLoaded, campaignId])

  const onSubmitSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/campaign/view/${campaignId}`)
    }, 1000)
  }

  const renderReturnButton = () => (
      <Button color='secondary'
              variant='contained'
              onClick={() => navigate(`/app/campaign/view/${campaignId}`)}>
        Back To Campaign
      </Button>
    )

  return (
    <ViewHead noMaxWidth functionId={Functions.Campaign_Edit_Poster} topButtons={renderReturnButton()}>
      { isPosterDetailsLoaded === null
        ? <Typography variant='h2'>Loading</Typography>
        : isPosterDetailsLoaded && isLoaded
          ? <CampaignPosterEditor
            isEdit
            campaignId={campaignId}
            posterData={ { ...currentPoster, coordinates: currentPoster.coordinates }}
            onSubmitSuccess={onSubmitSuccess}
          />
          : <Typography variant='h2'>Invalid Poster</Typography>
      }

    </ViewHead>
  )
}

export default CampaignEditPosterView
