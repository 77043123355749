import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Button, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import { MatricActionType } from '../../../actions';
import { TOP_UP_TAB_DICT, useMatricIndexView } from '../matricHelper';
import TabPanel, { a11yProps } from '../../../components/mui/tabs/TabPanel';
import TopUpAccountView from './accounts/TopUpAccountView';
import TopUpDataView from './data/TopUpDataView';
import TopUpSettingsView from './settings/TopUpSettingsView';

function TopUpIndexView() {
  useCurrentViewCheck(Functions.Matric_TopUp_List)
  const  navigate = useNavigate();
  const hasTabParams = useParams().tab
  const dispatch = useDispatch()
  const classes = useMatricIndexView()
  const currentTab = useSelector(state => state.matric.currentTopUpIndexTab)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isSettingsEdit, setIsSettingsEdit] = useState(false)

  useEffect(() => {
    if (!isLoaded) {
      // Only check on first load
      const isTab = TOP_UP_TAB_DICT.find(tab => tab.slug === hasTabParams)
      if (!isTab) {
        return setIsLoaded(true)
      }

      if (currentTab !== isTab.index) {
        handleTabChange(null, isTab.index)
      } else {
        setIsLoaded(true)
      }
    }
    return ()=>{}
  }, [isLoaded, currentTab])

  const handleTabChange = (event, newValue) =>
    dispatch({ type: MatricActionType.ChangeTopUpIndexTab, payload: newValue })

  const renderPageTitle = () =>
    <Grid container justifyContent='space-between'>
      <Grid item>
        <Typography variant='h3' className={classes.pageTitle} color='textPrimary'>
          { TOP_UP_TAB_DICT[currentTab].name }
        </Typography>
      </Grid>

      <Grid item>
        {
          currentTab === 0 ?
            <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate('/app/matric/topup/post/add')}>
              Add Post
            </Button> : ''
        }
        {
          currentTab === 1 ?
            <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate('/app/matric/topup/accounts/add')}>
              Add Account
            </Button> : ''
        }
        {
          currentTab === 2 ?
            <Button color='secondary'
                    variant='contained'
                    onClick={() => setIsSettingsEdit(true)}>
              Edit Settings
            </Button> : ''
        }
      </Grid>
    </Grid>

  const renderTabs = () =>
    <div>
      <AppBar position='relative' color='default'>
        <Tabs value={currentTab} onChange={handleTabChange}>
          { TOP_UP_TAB_DICT.map(tab =>
            <Tab key={tab.index} label={tab.name} {...a11yProps(tab.index)} />) }
        </Tabs>
      </AppBar>

      <TabPanel value={currentTab} index={0} className={classes.mainTabContainer}>
        <TopUpDataView />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <TopUpAccountView />
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <TopUpSettingsView isEditing={isSettingsEdit} setIsEditing={setIsSettingsEdit} />
      </TabPanel>
    </div>

  return (
    <ViewHead functionId={Functions.Matric_TopUp_List} noMaxWidth isShowTitle={false}>
      { renderPageTitle() }
      <Paper elevation={0}>
        {
          isLoaded && renderTabs()
        }
      </Paper>
    </ViewHead>
  )
}

export default TopUpIndexView
