import React, { useEffect } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Fade
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import parse from 'html-react-parser';
import { ServerBaseURL } from '../../../../constants';
import { useInvoiceViewStyles } from '../invoiceHelper';


function InvoiceDisplayLoader({ invoice , paid}) {

  const { t, i18n } = useTranslation()
  
  const classes = useInvoiceViewStyles()
  const invoiceItems = invoice?.items ? JSON.parse(invoice.items) : []
  const subTotal = invoiceItems.reduce((total, cur) => total + (+(cur?.price || 0) * +(cur?.quantity || 0)), 0)
  const salesTax = invoice?.tax ? subTotal * 0.13 : 0
  const discount = invoiceItems.reduce((totalDiscount, cur) => totalDiscount + +((cur?.discount) || 0), 0)
  const totalPrice = subTotal + salesTax - discount

  useEffect(() => {
    if (invoice?.head?.language && i18n.language !== invoice.head.language) {
      i18n.changeLanguage(invoice?.head?.language)
    }
  }, [invoice]);

  const renderHeadAsk = () =>
    <Grid item xs={12} md={6}>
      <div className={classes.headerText}>
        <Grid item xs={12}>
          { invoice?.isCompany
            ?
            // <div dangerouslySetInnerHTML={{ __html: invoice?.customer?.companyDetails }} />
            <div>{parse(invoice?.customer?.companyDetails || '')}</div>
            :
            <>
              <Typography variant='body1'>{invoice?.customer?.firstName} {invoice?.customer?.lastName}</Typography>
              <Typography variant='body1'>{invoice?.customer?.email}</Typography>
              <Typography variant='body1'>{invoice?.customer?.phone}</Typography>
              <Typography variant='body1'>{invoice?.customer?.address}</Typography>
              <Typography variant='body1'>{invoice?.customer?.city}, {invoice?.customer?.country}</Typography>
              <Typography variant='body1'>{invoice?.customer?.zipcode}</Typography>
            </>
          }
        </Grid>
      </div>
    </Grid>


  const renderHeadIssue = () =>
    <>
      <Grid item xs={12} sm={6}>
        <div className={classes.headerText}>{parse(invoice?.head?.text || '')}</div>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.headerImage}>
        <img
          alt='header logo for company'
          src={`${ServerBaseURL}/asset/contractTemplate/headerImage/${invoice?.head?.id}.png`} />
      </Grid>
    </>

  const renderInvoiceTitle = () =>
    <Grid item xs={12} style={{ overflowX: 'auto' }}>
      <Grid container justifyContent='space-between'  className={` ${classes.titleRow} ${classes.marginExtraTop}`}>
        <Grid item><Typography className={classes.titleText}>{ invoice?.iid }</Typography></Grid>
        <Grid item><Typography className={classes.titleText}>{ invoice?.dateCode }</Typography></Grid>
      </Grid>
    </Grid>

  const renderBilling = () =>
    <Grid item xs={12} style={{ overflowX: 'auto' }}>
      <TableContainer style={{ minWidth: 600 }}>
        <Table size="small">

          <TableHead>
            <TableRow>
              <TableCell width={250}>{t('invoiceBillTo')}</TableCell>
              <TableCell width={250}>{t('invoiceShipTo')}</TableCell>
              <TableCell>{t('invoiceInstructions')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell className={classes.tableNoBottomBorder}>
                {
                  invoice?.type === 'asked'
                    ?
                    // <div className={classes.normalText} dangerouslySetInnerHTML={{ __html: invoice?.head?.text }} />
                    <div className={classes.normalText}>{parse(invoice?.head?.text || '')}</div>
                    :
                    invoice?.isCompany
                      ?
                      // <div dangerouslySetInnerHTML={{ __html: invoice?.customer?.companyDetails }} />
                      <div>{parse(invoice?.customer?.companyDetails || '')}</div>
                      :
                      <>
                        <div>{ invoice?.customer?.firstName } { invoice?.customer?.lastName }</div>
                        <div>{ invoice?.customer?.email }</div>
                        <div>{ invoice?.customer?.phone }</div>
                        <div>{ invoice?.customer?.address }</div>
                        <div>{ invoice?.customer?.city }, { invoice?.customer?.country }</div>
                        <div>{ invoice?.customer?.zipcode }</div>
                      </>
                }
              </TableCell>

              <TableCell className={classes.tableNoBottomBorder}>
                <Typography className={classes.normalText}>{t('sameAsBillingAddress')}</Typography>
              </TableCell>

              <TableCell className={classes.tableNoBottomBorder}>
                <Typography className={classes.normalText}>{ invoice?.instructions }</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>

  const renderItems = () =>
    <Grid item xs={12} style={{ overflowX: 'auto' }}>
      <TableContainer style={{ minWidth: 600 }}>
        <Table size="small">

          <TableHead>
            <TableRow className={classes.titleRow}>
              <TableCell>{t('invoiceQuantity')}</TableCell>
              <TableCell>{t('invoiceDescription')}</TableCell>
              <TableCell align='right'>{t('invoiceUnitPrice')}</TableCell>
              <TableCell align='right'>{t('invoiceTaxAmount')}</TableCell>
              <TableCell align='right'>{t('invoiceDiscount')}</TableCell>
              <TableCell align='right'>{t('invoiceTotal')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              invoiceItems.map((item,index) =>
                <TableRow key={index}>
                  <TableCell>
                    <Typography className={classes.normalText}>{ item?.quantity }</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography className={classes.normalText}>{ item?.description }</Typography>
                  </TableCell>

                  <TableCell align='right'>
                    <Typography className={classes.normalText}>{ item?.price?.toFixed(2) }</Typography>
                  </TableCell>

                  <TableCell align='right'>
                    <Typography className={classes.normalText}>
                      {
                        invoice?.tax?
                          ((+(item?.price || 0) * +(item?.quantity || 0))*0.13).toFixed(2):`0.00`

                      }
                    </Typography>
                  </TableCell>

                  <TableCell align='right'>
                    <Typography className={classes.normalText}>
                      { item?.discount === undefined ? '0.00':item?.discount.toFixed(2)}
                    </Typography>
                  </TableCell>

                  <TableCell align='right'>
                    <Typography className={classes.normalText}>
                      {
                        invoice?.tax?
                          (item?.discount === undefined ?
                            ((+(item?.price || 0) * +(item?.quantity || 0) )*1.13.toFixed(2)).toFixed(2)
                            :((+(item?.price || 0) * +(item?.quantity || 0) )*1.13.toFixed(2)- +(item?.discount || 0).toFixed(2)).toFixed(2))
                          :
                          (item?.discount === undefined ?
                            ((+(item?.price || 0) * +(item?.quantity || 0)).toFixed(2)).toFixed(2)
                            :((+(item?.price || 0) * +(item?.quantity || 0)).toFixed(2)- +(item?.discount || 0).toFixed(2)).toFixed(2))
                      }
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>

  const renderPic = () =>
      <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center">
        <Fade in={paid}>
          <img width="60%" src={`${ServerBaseURL}/asset/images/paid`} alt='paid'/>
        </Fade>
      </Grid>



  const renderItemsTotal = () =>
    <Grid item xs={12}>
      <Grid container direction='row' justifyContent='flex-end'>

        <Grid item xs={12} sm={6} md={8}>
          {renderPic()}
        </Grid>
        <Grid container item direction='column' xs={12} sm={6} md={4}>
          <Grid item>
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalName}`}>{t('invoiceSubtotal')}</div>
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalPrice}`}>{ subTotal.toFixed(2) }</div>
          </Grid>
          <Grid item>
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalName}`}>{t('invoiceSalesTax')}</div>
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalPrice}`}>{ salesTax.toFixed(2) }</div>
          </Grid>
          <Grid item >
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalName}`}>{t('invoiceShippingHandling')}</div>
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalPrice}`}>0.00</div>
          </Grid>
          <Grid item >
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalName}`}>{t('invoiceDiscount')}</div>
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalPrice}`}>{discount.toFixed(2)}</div>
          </Grid>
          <Grid item >
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalTitle}`}>{t('invoiceTotal')}</div>
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalPrice}`}>{ totalPrice.toFixed(2) }</div>
          </Grid>
          <Grid item >
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalTitle}`}>{t('invoiceTotalDueBy')}</div>
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalPrice}`}>{ moment(invoice?.dueDate).format('ll') }</div>
          </Grid>
          <Grid item >
            <div className={`${classes.invoiceTotal} ${classes.invoiceTotalMessage}`}>{t('invoiceThanksMessage')}</div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>


  return (
    <Paper className={classes.paper}>

      <Grid container spacing={4} justifyContent='space-between'>
        {
          invoice?.type &&
          <>
            { invoice.type === 'asked' ? renderHeadAsk() : renderHeadIssue() }
            { renderInvoiceTitle() }
            { renderBilling() }
            { renderItems() }
            { renderItemsTotal() }
          </>
        }
      </Grid>

    </Paper>
  )
}

export default InvoiceDisplayLoader
