import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel, FormHelperText,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SignaturePad from 'react-signature-pad-wrapper'
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import CenterDisplay from '../../../../components/misc/CenterDisplay';
import Page from '../../../../components/public/Page';
import ContractDisplayLoader from '../contractPreview/ContractDisplayLoader';
import {
  fetchContractPublic,
  signContract,
  updateContractReadDate
} from '../../../../actions/administrative/administrativeAction';
import { contractFooter, useContractViewStyles } from '../contractHelper';
import { sendErrorMessage } from '../../../../actions';
import { cropSignatureCanvas, getImageSizeFromDataUrl } from '../../../../utils/ImageUtils';

function ContractView() {

  const {uid} = useParams()
  const {cid} = useParams()
  const dispatch = useDispatch()
  const classes = useContractViewStyles()
  const currentContract = useSelector(state => state.administrative.currentContract)

  const signatureRef = useRef(null)

  const { t, i18n } = useTranslation()

  const [isSaving, setIsSaving] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [message, setMessage] = useState('Loading...')
  const [sigErr, setSigErr] = useState('')

  const [isAgreed, setIsAgreed] = useState(false)

  const [isSigned, setIsSigned] = useState(false)
  const [signature, setSignature] = useState(null)
  const [dateUpdate, setDateUpdate] = useState(false)

  useEffect(() => {
    if (isLoaded) {
      if (currentContract?.head?.language && i18n.language !== currentContract.head.language) {
        i18n.changeLanguage(currentContract.head.language)
      }
      document.title = `${t("contract")}: ${cid}`
      if (document.documentElement.clientHeight + document.documentElement.scrollTop >= document.getElementById("contractPage").clientHeight) {
        updateContractReadDate('readDate', uid, cid, window.location.href)(dispatch)
      }
    } else {
      document.title = t("contract")
    }
  }, [isLoaded, cid])

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchContractPublic(uid, cid, setMessage, t('contractSignedMessage'))(dispatch))
    })()

    // reset dateUpdate and remove listener when contract data change
    setDateUpdate(false)
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }
  useEffect(loadData, [dispatch, uid, cid])

  useEffect(()=>{
    updateContractReadDate("openDate", uid, cid, window.location.href)(dispatch)

    // add listener to window to make scroll detection work
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  },[])

  useEffect(()=>{
    if(dateUpdate){
      updateContractReadDate("readDate", uid, cid, window.location.href)(dispatch)
    }
  }, [dateUpdate])

  const scrollToBottom = () => {
    setTimeout(() => {
      const bottomElem = document.getElementById('emptyDivAtTheBottom')
      if (bottomElem) {
        bottomElem.scrollIntoView({ block: 'end', behavior: 'smooth' })
      }
    }, 250)
  }

  // detect when scroll to the bottom, update readDate of contract
  const handleScroll= (e)=>{
    const bottom = e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop === e.target.scrollingElement.clientHeight;
    if (bottom && !dateUpdate) {
      setDateUpdate(true)
    }
  }

  const renderSignature = () => (
      <div className={classes.marginTop}>
        <Typography variant='h3'>{t('signHere')}</Typography>
        <div className={classes.signatureArea}>
          <SignaturePad ref={signatureRef} />
          <FormHelperText error={Boolean(sigErr)}>
            { sigErr || '' }
          </FormHelperText>
        </div>
        <Grid container justifyContent='flex-start'>
          <Grid item>
            <Tooltip title={t('clear')} placement='top'>
              <IconButton color='primary'
                          size='small'
                          component='span'
                          onClick={() => signatureRef?.current?.clear()}>
                <Delete size={20} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container justifyContent='flex-end'>
          <Grid item>
            <Button color='primary'
                    variant='contained'
                    className={classes.marginExtraTop}
                    onClick={() => convertSigToImg()}>
                {t('submitSignature')}
            </Button>
          </Grid>
        </Grid>
      </div>
    )

  const convertSigToImg = () => {
    setSigErr('')

    try {
      // fail if empty
      if (signatureRef?.current?.isEmpty()) {
        setSigErr(t('signatureError'))
        return
      }

      // Signature data
      const data = signatureRef.current.toDataURL('image/png')

      // Crop out the space and calculate actual signature size
      const signatureData = cropSignatureCanvas(signatureRef.current.canvas)
      const imageSizeSig = getImageSizeFromDataUrl(signatureData)

      if (imageSizeSig < 1000) {
        // if the signature is too small
        setSigErr(t('signatureErrorSize'))
        return
      } 
        setIsSigned(true)
        setSignature(data)
        scrollToBottom()
      
    } catch (e) {
      sendErrorMessage(dispatch, t('signatureErrorGeneric'))
    }
  }

  const renderSubmit = () => (
      <Grid container justifyContent='space-around' spacing={1}>
        <Grid item>
          <Button color='primary'
                  variant='contained'
                  disabled={isSaving}
                  style={{ backgroundColor: 'indianred' }}
                  onClick={() => {
                    setSignature(null)
                    setIsSigned(false)
                  }}>
            {t('cancel')}
          </Button>
        </Grid>

        <Grid item>
          <Button color='primary'
                  variant='contained'
                  disabled={isSaving}
                  onClick={() => onSubmit()}>
            { isSaving ? t('submitting') : t('submitContract') }
          </Button>
        </Grid>
      </Grid>
    )

  const renderAgreement = () =>
    <FormControlLabel
      control={
        <Checkbox
          name='agreement'
          checked={isAgreed}
          onChange={(e) => {
            setIsAgreed(e.target.checked)
            scrollToBottom()
          } }
        />}
      label={t('contractAgreementCheckboxText')}
    />

  const renderContract = () =>
    <Page className={classes.root} id="contractPage">
      <Container className={classes.container} maxWidth='md'>
        <ContractDisplayLoader contract={currentContract} isCustomerView/>
        <Paper className={classes.paper}>
          { !isSigned && renderAgreement() }
          { isAgreed &&
          <div className={classes.marginTop}>
            { contractFooter(t, currentContract?.customerName, signature, true,
              { name: currentContract?.head?.partyBName }) }
            { !isSigned && renderSignature() }
          </div>
          }
        </Paper>

        { isSigned && <div className={classes.submitButtonArea}>{ renderSubmit() }</div> }
      </Container>
      <div className={classes.marginBottom} id='emptyDivAtTheBottom' />
    </Page>

  const onSubmitError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }
  const onSubmitSuccess = () => {
    setIsLoaded(false)
    setMessage(t('contractSignedMessage'))
  }

  const onSubmit = async () => {
    setIsSaving(true)
    const encodeSignature = signature.replace('data:image/png;base64,', '')
    const data = {
      signature: encodeSignature,
      cid: currentContract.cid,
      uid: parseInt(uid,10),
    }

    await signContract(data, onSubmitSuccess, onSubmitError)(dispatch)
  }

  return (
    <div>
      {
        isLoaded
          ?
          renderContract()
          :
          <CenterDisplay>
            <Typography variant='h1' color='textPrimary'>{ message }</Typography>
          </CenterDisplay>
      }
    </div>
  )
}

export default ContractView
