import React, {useEffect, useState} from 'react'
import {Grid, Paper} from '@mui/material'
import parse from 'html-react-parser'
import {useDispatch, useSelector} from 'react-redux'
import { useTranslation } from 'react-i18next';
import {contractFooter, useContractViewStyles} from '../contractHelper'
import {ServerBaseURL} from '../../../../constants'
import {fetchTermsBeSelected} from '../../../../actions/administrative/administrativeAction'


function ContractDisplayLoader({contract, isCustomerView = false}) {
    const classes = useContractViewStyles()
    const dispatch = useDispatch()
    const [ckTxt, setCkTxt] = useState('')
    const selectedTerms = useSelector(state => state?.administrative.selectedTerms)
    const { t, i18n } = useTranslation()

    const fetchSelectedTerms = async str => {
        const dom = new DOMParser().parseFromString(str, 'text/html')
        const indexes = Array.from(dom.getElementsByClassName('term'), term => Number(term.getAttribute('data-id')))

        if (indexes.length > 0) {
            await dispatch(fetchTermsBeSelected(indexes))
        }
    }

    useEffect(() => {
        if (contract) {
            fetchSelectedTerms(contract.text)
            if (contract?.head?.language && i18n.language !== contract.head.language) {
                i18n.changeLanguage(contract.head.language)
            }
        }
    }, [contract])

    useEffect(() => {
        if (contract) {
            const dom = new DOMParser().parseFromString(contract?.text, 'text/html')
            const elements = dom.querySelectorAll('.term')
            const indexes = Array.from(dom.getElementsByClassName('term'), term => Number(term.getAttribute('data-id')))
            elements.forEach((v, i) => {
                v.innerHTML = selectedTerms.find(value => value.id === indexes[i])?.text
            })
            const e = dom.getElementsByTagName('body')[0].outerHTML
            setCkTxt(e.substring(6, e.length - 7))
        }
    }, [selectedTerms, contract])

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} justifyContent="space-between">
                <Grid item xs={12} md={6}>
                    <div className={classes.headerText}>{parse(contract?.head?.text || '')}</div>
                </Grid>
                <Grid item xs={12} md={6} className={classes.headerImage}>
                    <img
                        alt="header logo for company"
                        src={`${ServerBaseURL}/asset/contractTemplate/headerImage/${contract?.head?.id}.png`}
                    />
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.ck}>{parse(ckTxt) || ''}</div>
                </Grid>

                <Grid item xs={12}>
                    {!isCustomerView &&
                        contractFooter(
                            t,
                            `${contract?.customer?.firstName} ${contract?.customer?.lastName}`,
                            null,
                            isCustomerView,
                            {
                                name: contract?.head?.partyBName,
                                signature: contract?.head?.partyBSignatureImage
                            }
                        )}
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ContractDisplayLoader
