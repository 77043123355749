import React from 'react';
import { useSelector } from 'react-redux';
import { Functions } from '../../../utils/Functions';
import CategoryManager from '../../../components/form/CategoryManager';
import ViewHead from '../../../components/public/ViewHead';
import {
  addCampaignCategory,
  deleteCampaignCategory,
  fetchCampaignCategories, updateCampaignCategory
} from '../../../actions/campaign/campaignActions';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';


function CampaignCategoryManagerView() {
  useCurrentViewCheck(Functions.Campaign_Category_Manager)

  const allCategories = useSelector(state => state.campaign.allCategories)
  return (
    <ViewHead functionId={Functions.Campaign_Category_Manager} isShowTitle={false} >
      <CategoryManager
        allCategories={allCategories}
        fetchCategoryAction={fetchCampaignCategories}
        addCategoryAction={addCampaignCategory}
        deleteCategoryAction={deleteCampaignCategory}
        updateCategoryAction={updateCampaignCategory}
      />
    </ViewHead>
  )
}

export default CampaignCategoryManagerView
