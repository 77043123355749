import api from "../../apis/api";
import {sendSuccessMessage} from "../message/messageActions";

export const SystemSettingsActionTypes = {
    FetchSystemSettings :"FetchSystemSettings",
    CreateSystemSetting :'CreateSystemSetting',
    UpdateSystemSetting :'UpdateSystemSetting',
    DeleteSystemSetting :'DeleteSystemSetting',
    SetSettingFormStatus:"SetSettingFormStatus",
    SetCurrentId: "SetCurrentSystemParamId",

}


export const fetchSettings = ()=>async dispatch=>{
    const response = await api.get('/systemParams/fetchAll');
    if(response.data){
        dispatch({type:SystemSettingsActionTypes.FetchSystemSettings, payload:response.data});
    }
}

export const addSysParam = (data,success,error)=>async dispatch=>{
    try {
        const response = await api.post('/systemParams/createOne',data);
        if (response.data) {
            dispatch({type: SystemSettingsActionTypes.CreateSystemSetting, payload: response.data});
            sendSuccessMessage(dispatch,`System Parameter ${response.data.key} saved.`);
            success(response.data)
        }
    }catch(e){
        // console.log(e);
        error(e.message);
    }
}
export const editSysParam = (data,success,error)=>async dispatch=>{
    try {
        const response = await api.put('/systemParams/update',data);
        if (response.data) {
            dispatch({type: SystemSettingsActionTypes.UpdateSystemSetting, payload: response.data});
            sendSuccessMessage(dispatch,`System Parameter ${response.data.key} saved.`);
            success(response.data)
        }
    }catch(e){
        // console.log(e);
        error(e.message);
    }
}
export const deleteSysParam = (id)=>async dispatch=>{

    try {
        const response = await api.post('/systemParams/delete', {id});
        if (response.data) {
            dispatch({type: SystemSettingsActionTypes.DeleteSystemSetting, payload: id});
            sendSuccessMessage(dispatch,`System Parameter ${response.data.key} has been deleted.`);

        }
    }catch(e){
        // console.log(e);
    }
}