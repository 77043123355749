import React, { useState } from 'react';
import { Button, FormControl, Grid, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ExternalLink } from 'react-feather';
import ResponsiveDialog from '../../../../../components/dialog/ResponsiveDialog';
import { usePosterTemplateStepperStyles } from '../../posterGenHelper';

function PosterStepper({ currentStep, totalSteps, onStepChange, onPop, disabled }) {

  const classes = usePosterTemplateStepperStyles()

  const [hash, setHash] = useState('init')
  const [openWarningDialogue, setOpenWarningDialogue] = useState(false)

  const setCurrentStep = (step) => onStepChange(step)

  const handleOnChangeStep = (step) => {
    if (step === 0) {
      setHash(Math.random().toString(16).substring(2, 10))
      setOpenWarningDialogue(true)
    }else {
      setCurrentStep(step)
    }
  }

  const renderSelector = () => {
    const menuItems = []
    for (let curIdx = 1; curIdx <= totalSteps; curIdx+=1) {
      menuItems.push(<MenuItem key={curIdx} value={curIdx}>{ curIdx }</MenuItem>)
    }

    return (
      <Grid container>
        <Grid item>
          Posters:
        </Grid>
        <Grid item>
          <FormControl className={classes.selectorFormController}>
            <Select
              value={currentStep}
              onChange={(e) => setCurrentStep(e.target.value)}
              displayEmpty>
              { menuItems }
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {`/${totalSteps}`}
        </Grid>
        <Grid item>
          <Tooltip title='Pop Out' placement='top'>
            <IconButton color='primary'
                        size='small'
                        className={classes.popoutButton}
                        component='span'
                        onClick={() => onPop(currentStep)}>
              <ExternalLink />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    )
  }

  const renderStepper = () =>
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item>
        <Button color='primary'
                variant='contained'
                disabled={disabled}
                onClick={() => handleOnChangeStep(currentStep - 1)}>
          { currentStep === 1 ? 'Reset' : 'Back' }
        </Button>
      </Grid>

      <Grid item>
        <Grid item xs={12}>
          <Typography variant='h4'>
            {/* { `Poster ${currentStep}/${totalSteps}` } */}
            { renderSelector() }
          </Typography>
        </Grid>
      </Grid>

      <Grid item>
        <Button color='primary'
                disabled={disabled}
                variant='contained'
                onClick={() => handleOnChangeStep(currentStep + 1)}>
          { currentStep === totalSteps ? 'Finish' : 'Next' }
        </Button>
      </Grid>
    </Grid>

  return (
    <div>
      { renderStepper() }
      <ResponsiveDialog
        isOpen={openWarningDialogue} openHash={hash}
        title='Reset' content='Are you sure you want to reset everything and start again?'
        Buttons={
          [
            { name: 'Yes', event: () => setCurrentStep(0) },
            { name: 'Cancel' }
          ]
        }
      />
    </div>
  )
}

export default PosterStepper
