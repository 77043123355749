import makeStyles from '@mui/styles/makeStyles';
import * as yup from 'yup';
import moment from 'moment';
import { getTotalHeight, isMobile, slashTrimmer, spaceTrimmer } from '../../../utils/Scripts';
import { MAX_TEXT_LENGTH } from '../../../constants';

export const DEFAULT_COORDS = ({ 1: { x: 0, y: 0 }, 2: { x: 0, y: 0 } })

export const POSTER_PLACEHOLDER = {
  postertitle: { name: 'Poster Title', text: '%postertitle%', header: 'postertitle' },
  address: { name: 'Address', text: '%address%', header: 'address' },
  city: { name: 'City', text: '%city%', header: 'city' },
  country: { name: 'Country', text: '%country%', header: 'country' },
  postal: { name: 'Postal', text: '%postal%', header: 'postal' },
  jobsource: { name: 'Job Source', text: '%jobsource%', header: 'jobsource' },
  jobposition: { name: 'Job Position', text: '%jobposition%', header: 'jobposition' },
  jobdescription: { name: 'Job Description', text: '%jobdescription%', header: 'jobdescription' },
  jobrequirement: { name: 'Job Requirement', text: '%jobrequirement%', header: 'jobrequirement' },
  employer: { name: 'Employer', text: '%employer%', header: 'employer' },
  salary: { name: 'Salary', text: '%salary%', header: 'salary' },
  workhours: { name: 'Work Hours', text: '%workhours%', header: 'workhours' },
  jobid: { name: 'Job ID', text: '%jobid%', header: 'jobid' },
}

export const PIXIE_ID = 'pixieScript'
export const PIXIE_IMAGE_SRC = '%imagesrc%'
export const MAX_POSTER_SIZE = 5
export const MAX_LOGO_SIZE = 3
export const EMPTY_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
export const PIXIE_HEIGHT_OFFSET = 200
export const DEFAULT_FORM_TAGS = [{ name: 'Current form cannot be used' }]

export const PIXIE_TEXT_STYLES = {
  default: {
    "fontWeight": "normal",
    "fontFamily": "Arial",
    "fontSize": 8,
    "fill": "rgba(0,0,0,1)",
    "shadow": {}
  },
  postertitle: {
    "text": POSTER_PLACEHOLDER.postertitle.text,
    "fontWeight": "bold",
    "fontFamily": "Arial",
    "fill": "rgba(5,104,224,1)",
    "stroke": "#000",
    "strokeWidth": 0.05,
    "strokeDashArray": null,
    "strokeLineCap": "butt",
    "strokeDashOffset": 0,
    "strokeLineJoin": "miter",
    "strokeUniform": false,
    "strokeMiterLimit": 4,
    "shadow":{
      "color":" rgba(199,228,253,1)",
      "blur": 1,
      "offsetX": 3,
      "offsetY": 3,
      "affectStroke": false,
      "nonScaling": false
    },
  }
}

export const DEFAULT_PIXIE_CONFIG = {
  // blankCanvasSize: {width: 500, height: 500},
  baseUrl: `${process.env.PUBLIC_URL}/static/scripts/pixie-image-editor`,
  ui: {
    height: '100%',
    nav: {
      compact: true,
      // position: 'bottom',
      replaceDefault: true,
      items: [
        { name: 'text', icon: 'text-box-custom', action: 'text' },
      ],
    },
    openImageDialog: {
      show: false
    },
    toolbar: {
      replaceDefaultLeftItems: true,
      replaceDefaultCenterItems: true,
      leftItems: [{ type: 'zoomWidget', condition: { 'tools.zoom.allowUserZoom': true } }],
      centerItems: []
    },
  },
  tools: {
    zoom: {
      disableMinimumZoom: false
    }
  },
  objectDefaults: {
    text: {
      textAlign: 'left',
      fontFamily: 'Arial'
    }
  },
  // objectControls: {
  //   text: {
  //     hideFloatingControls: true
  //   },
  // }
}

export const cleanPixieScript = () => {
  const pixieScript = document.getElementById(PIXIE_ID)
  if (pixieScript) {
    pixieScript.remove()
  }
}

export const addImageToPixieSrc = (config, b64Image) => config && b64Image
  ? config.replace(PIXIE_IMAGE_SRC, b64Image)
  : config
export const removeImageFromPixieSrc = (config) => {
  if (config) {
    const jsonConfigData = JSON.parse(config)
    const objectIndex = jsonConfigData.canvas.objects.findIndex(o => o.type === 'image')
    jsonConfigData.canvas.objects[objectIndex].src = PIXIE_IMAGE_SRC
    return JSON.stringify(jsonConfigData)
  } 
    return config
  
}

export const posterFileNameGenerator = (headers, submission) => {
  try {
    let jobPosition = null
    Object.keys(headers).forEach(header => {
      if (headers[header].text === POSTER_PLACEHOLDER.jobposition.text) {
        jobPosition = submission[header]
      }
    })
    let fileName = 'YC-'
    fileName += `${moment(submission.createdAt).format('YYYYMMDD')  }-`
    fileName += jobPosition ? `${slashTrimmer(spaceTrimmer(jobPosition))}-` : ''
    fileName += (submission.id || '').toString().slice(-4).padStart(4, '0')
    return fileName
  } catch (e) {
    return ''
  }
}

export const b642Image = (b64) => new Promise(resolve => {
  const img = new Image()
  img.src = b64
  img.onerror = () => resolve(null)
  img.onload = () => resolve(img)
})

export const file2b64 = (file, cb) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = ((e) => cb(e.target.result))
}

export const replaceStringForPoster = (input) => {
  let tempStr = input || ''
  tempStr = tempStr.replace(/\r\n|\r|\n/g, '\\n')
  tempStr = tempStr.replace(/"/g, '\\"')
  return tempStr
}

export const onFileDownload = (downloadFile, filename) => {
  const link = document.createElement('a')
  link.href = downloadFile
  link.download = filename
  document.body.appendChild(link)

  if (!isMobile()) { link.target = '_blank' }
  link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}));
  link.parentNode.removeChild(link)
}

export const POSTER_TEMPLATE_CREATE_VALIDATOR = {
  name: yup.string().required('Required')
    .min(1, 'Template name must be at least 1 character')
    .max(30, 'Template name must be less than 30 characters')
    .trim(),
  description: yup.string().required('Required')
    .min(1, 'Template description must be at least 1 character')
    .max(255, 'Template description must be less than 255 characters')
    .trim(),
  copyText: yup.lazy((value) => !value ? yup.string() : yup.string()
    .min(1, 'Copiable text must be at least 1 character')
    .max(MAX_TEXT_LENGTH, `Copiable text must be less than ${MAX_TEXT_LENGTH} characters`)
    .trim()),
  qrText: yup.lazy((value) => !value ? yup.string() : yup.string()
    .min(1, 'QR code link must be at least 1 character')
    .max(255, 'QR code link must be less than 255 characters')
    .url("Must be a valid URL starting with http(s)://")
    .trim()),
}

export const usePosterTemplateCreateStyles = makeStyles((theme) => ({
  marginReset: {
    margin: '0!important',
  },
  formTitle: {
    marginBottom: -theme.spacing(5),
  },
  formTagList: {
    marginLeft: theme.spacing(5)
  },
  formInputMarginBottom: {
    marginBottom: theme.spacing(3)
  },
  formPreviewImage: {
    maxHeight: '100px',
    '& img': {
      width: 'auto',
      height: 'auto',
      maxHeight: '100px',
      maxWidth: '100%'
    }
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: '2rem',
    marginBottom: '2rem'
  },
  stiffPoster: {
    height: 'auto',
    width: '100%',
    position: 'relative',
    display: 'block'
  },
  posterQRBox: {
    position: 'absolute',
    backgroundSize: '100% 100%',
    outline: 'black solid 1px',
    display: 'none'
  },
}))

export const usePosterTemplateDisplayStyles = makeStyles((theme) => ({
  paper: {
    padding: '2rem',
    marginBottom: '2rem'
  },
  gridHeightFix: {
    height: '100%'
  },
  pageTitleText: {
    marginBottom: '20px',
    textAlign: 'center'
  },
  marginTop: {
    marginTop: theme.spacing(3)
  },
  link: {
    cursor: 'pointer'
  },
  previewPoster: {
    margin: '0 auto',
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: `${getTotalHeight() - 100}px`,
      width: 'auto',
      height: 'auto',
      objectFit: 'contain'
    }
  }
}))

export const usePosterTemplateStepperStyles = makeStyles(() => ({
  selectorFormController: {
    bottom: '5px',
    margin: '0 0.5rem'
  },
  popoutButton: {
    marginLeft: '0.5rem',
    bottom: '3px'
  }
}))

export const usePosterTemplateStatusStyles = makeStyles((theme) => ({
  statusText: {
    cursor: 'pointer',
    color: 'darkgrey',
    borderBottom: '2px solid darkgrey',
    '&.active': {
      color: 'green',
      borderColor: 'green'
    },
    '&.current': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      borderColor: theme.palette.primary.main
    }
  },
  statusTextHelper: {
    textAlign: 'center'
  }
}))
