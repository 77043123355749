import {InputAdornment, SvgIcon, TextField} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import React from 'react'
import Loading from './Loading'

function SearchBox(props) {
    const {isLoading, searchHandler, placeholder, size, type, params, inputProps} = props
    return (
        <TextField
            // autoFocus={!!params}
            {...params}
            fullWidth
            placeholder={placeholder}
            size={size}
            type={type}
            onChange={event => searchHandler(event.target.value)}
            onMouseEnter={event => {
                if (event.target.type === type) {
                    event.target.select()
                }
                if (params) {
                    params.inputProps.ref.current.select()
                }
            }}
            InputProps={{
                ...inputProps,
                startAdornment: (
                    <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                            <SearchIcon size={20} />
                        </SvgIcon>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end" style={{width: 0, position: 'relative', right: '60px', top: '3px'}}>
                        {isLoading && <Loading />}
                    </InputAdornment>
                )
            }}
        />
    )
}

export default SearchBox
