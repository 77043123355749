import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Box, Grid, Modal, Paper} from '@mui/material'
import moment from 'moment'
import {DataGridPro} from '@mui/x-data-grid-pro'
import {deleteInterviewNotes, getInterviewImage} from '../../../../actions/customer/interviewActions'
import {useInterviewEditorStyles} from '../interviewHelper'
import EditCommentDialogue from './EditCommentDialogue'
import ResponsiveDialog from '../../../../components/dialog/ResponsiveDialog'
import {sendErrorMessage, sendSuccessMessage} from '../../../../actions'
import DetailPanelContent from './DetailPanelContent'

const ReviewHistoryContainer = ({uid, interviewData}) => {
    const dispatch = useDispatch()
    const classes = useInterviewEditorStyles()
    const imageContainer = useSelector(state => state.customer.customerNoteImages)
    const currentExpanded = useSelector(state => state.customer.customerNoteExpand)
    const auth = useSelector(state => state.auth)

    const [currentUserId, setCurrentUserId] = useState(-1)

    const [displayData, setDisplayData] = useState([])

    const [hash, setHash] = useState('init')
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
    const [selectedForDel, setSelectedForDel] = useState(null)
    const handleDeleteOpen = (iid, nid) => {
        setSelectedForDel({iid, nid})
        setOpenDeleteDialogue(true)
        setHash(Math.random().toString(16).substring(2, 10))
    }

    const [selectedForEdit, setSelectedForEdit] = useState(null)

    const [openEditModal, setEditModalOpen] = useState(false)
    const handleEditModalClose = () => setEditModalOpen(false)
    const handleEditModalOpen = data => {
        setSelectedForEdit(data)
        setEditModalOpen(true)
    }

    useEffect(() => {
        if (auth && auth.user) {
            setCurrentUserId(auth.user.id)
        }
    }, [auth])

    useEffect(() => {
        interviewData.forEach(interview => {
            // Get images ready by preloading blobs
            interview.notes.forEach(async note => {
                // Ignore already loaded images on refresh
                if (note.fileName && note.fileMime.match('image/*') && !imageContainer[note.id]) {
                    await getInterviewImage(interview.id, note.id, note.fileMime)(dispatch)
                }
            })
        })

        const result = interviewData.map(interview => ({
            id: interview.id,
            status: interview.status,
            interviewer: `${interview.interviewer.firstName} ${interview.interviewer.lastName}`,
            interviewerUid: interview.interviewer.uid,
            product: interview.product.name,
            score: interview.score,
            booked: moment(interview.scheduledStartTime).format('L LT'),
            started: interview.startTime ? moment(interview.startTime).format('L LT') : '',
            duration:
                interview.startTime && interview.endTime
                    ? Math.round(
                          moment.duration(moment(interview.endTime).diff(moment(interview.startTime))).asMinutes()
                      )
                    : '',
            notes: interview.notes,
            expanded: currentExpanded.includes(interview.id)
        }))

        setDisplayData(result)
    }, [uid, interviewData, dispatch])

    const onDeleteComment = async () => {
        const {iid, nid} = selectedForDel
        if (iid && nid) {
            await deleteInterviewNotes(
                iid,
                nid,
                uid,
                msg => sendSuccessMessage(dispatch, msg),
                msg => {
                    setSelectedForDel(null)
                    sendErrorMessage(dispatch, msg)
                }
            )(dispatch)
        }
    }

    const onEditCommentSuccess = () => {
        handleEditModalClose()
        setSelectedForEdit(null)
    }

    const columns = [
        {field: 'id', headerName: 'ID', width: 90},
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            editable: true
        },
        {
            field: 'interviewer',
            headerName: 'Interviewer',
            width: 150,
            editable: true
        },
        {
            field: 'product',
            headerName: 'Product',
            editable: true,
            width: 150
        },
        {
            field: 'score',
            headerName: 'Score',
            editable: true,
            width: 150
        },
        {
            field: 'booked',
            headerName: 'Booked',
            editable: true,
            width: 150
        },
        {
            field: 'started',
            headerName: 'Started',
            editable: true,
            width: 150
        },
        {
            field: 'lasted(m)',
            headerName: 'Lasted(m)',
            editable: true,
            width: 150
        }
    ]

    const getDetailPanelContent = React.useCallback(
        ({row}) => (
            <DetailPanelContent
                row={row}
                currentUserId={currentUserId}
                handleModelOpen={handleEditModalOpen}
                handleDeleteOpen={handleDeleteOpen}
            />
        ),
        []
    )

    const getDetailPanelHeight = React.useCallback(() => 180, [])

    const renderView = () => (
        <Box sx={{width: '100%'}}>
            <DataGridPro
                columns={columns}
                rows={displayData}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
            />
            <Grid item xs={12}>
                <div className={classes.emptyDiv} />
            </Grid>

            <Modal
                open={openEditModal}
                onClose={handleEditModalClose}
                className={classes.modal}
                disablePortal
                disableRestoreFocus
                disableEnforceFocus
                disableAutoFocus
                closeAfterTransition
            >
                <Paper className={classes.modalPaper}>
                    <EditCommentDialogue data={selectedForEdit} uid={uid} onSubmitSuccess={onEditCommentSuccess} />
                </Paper>
            </Modal>

            <ResponsiveDialog
                isOpen={openDeleteDialogue}
                openHash={hash}
                title="Delete"
                content="Delete comment?"
                Buttons={[
                    {name: 'Yes', event: () => onDeleteComment()},
                    {name: 'Cancel', event: () => setSelectedForDel(null)}
                ]}
            />
        </Box>
    )

    return renderView()
}

export default ReviewHistoryContainer
