import { FormsActionType } from '../../actions/forms/formActions';

const defaultState = {
    allForms: {},
    allCategories: [],
    currentForm: {},
    currentFormResults: [],
    currentFormSubmission: {},
    currentPosterMakerFormResults: [],
    allWebForms: {},
    currentWebForm: {},
    currentWebFormResults: [],
    currentWebFormSubmission: {},
    formFetchProps: {},
    currentFormTab: 0,
}

export const formReducer = (state = defaultState, action = {}) => {

    switch (action.type) {
        case FormsActionType.FetchAllForms:
            return {...state, allForms: action.payload}
        case FormsActionType.FetchForm:
            return {...state, currentForm: action.payload}
        case FormsActionType.FetchFormResults:
            return {...state, currentFormResults: action.payload}
        case FormsActionType.FetchFormSubmission:
            return {...state, currentFormSubmission: action.payload}
        case FormsActionType.FetchPosterMakerFormResults:
            return {...state, currentPosterMakerFormResults: action.payload}
        case FormsActionType.FetchAllCategories:
            return {...state, allCategories: action.payload}
        case FormsActionType.FetchAllWebForms:
            return {...state, allWebForms: action.payload}
        case FormsActionType.FetchWebForm:
            return {...state, currentWebForm: action.payload}
        case FormsActionType.FetchWebFormResults:
            return {...state, currentWebFormResults: action.payload}
        case FormsActionType.FetchWebFormSubmission:
            return {...state, currentWebFormSubmission: action.payload}
        case FormsActionType.FetchWebSubmission:
            return {...state, currentWebSubmission: action.payload}
        case FormsActionType.SetFormFetchProps:
            return {...state, formFetchProps: action.payload}

        case FormsActionType.ChangeFormTab:
            return {...state, currentFormTab: action.payload}

        default:
            return state
    }
}
export default formReducer