import React from 'react';
import { useSelector } from 'react-redux';
import { Functions } from '../../../utils/Functions';
import {
  deleteFormCategory,
  fetchFormCategories, updateFormCategory,
  addFormCategory
} from '../../../actions/forms/formActions';
import CategoryManager from '../../../components/form/CategoryManager';
import ViewHead from '../../../components/public/ViewHead';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';

function FormCategoryManagerView() {
  useCurrentViewCheck(Functions.Forms_Category_Manager)

  const allCategories = useSelector(state => state.forms.allCategories)
  return (
    <ViewHead functionId={Functions.Forms_Category_Manager} isShowTitle={false} >
      <CategoryManager
        allCategories={allCategories}
        fetchCategoryAction={fetchFormCategories}
        addCategoryAction={addFormCategory}
        deleteCategoryAction={deleteFormCategory}
        updateCategoryAction={updateFormCategory}
      />
    </ViewHead>
  )
}

export default FormCategoryManagerView
