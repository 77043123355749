import api from '../../apis/api'
import LocalStorage from '../../utils/LocalStorage'
import {LS_KEY_SYLLABUS_EXPANDED, SYLLABUS_TYPES} from '../../views/training/syllabus/syllabusHelper'
import {sendErrorMessage} from '../message/messageActions'

export const SyllabusActionType = {
    FetchSyllabus: 'FetchSyllabus',
    FetchClasses: 'FetchClasses',

    ChangeSyllabusTab: 'ChangeSyllabusTab',

    InitSyllabusExpandStatus: 'InitSyllabusExpandStatus',
    ChangeSyllabusExpand: 'ChangeSyllabusExpand'
}

export const initExpandState = () => async dispatch => {
    // Get previous data from localstorage, if dne, then init new state
    const storageVal =
        LocalStorage.getObjectItem(LS_KEY_SYLLABUS_EXPANDED) ||
        Object.values(SYLLABUS_TYPES).reduce((res, cur) => {
            res[cur] = []
            return res
        }, {})
    await dispatch({type: SyllabusActionType.InitSyllabusExpandStatus, payload: storageVal})
}

export const resetExpandState = () => async dispatch => {
    const storageVal = Object.values(SYLLABUS_TYPES).reduce((res, cur) => {
        res[cur] = []
        return res
    }, {})
    await dispatch({type: SyllabusActionType.ChangeSyllabusExpand, payload: storageVal})
}

export const changeExpandState = (e, expanded) => async dispatch => {
    // e.value is the current expand state of the field
    // if it's true, then it's expanded so it must be closed; else we want to open it
    const clickedData = e.dataItem
    const newStatusObject = e.value
        ? // Take the current ID out of the expand list it belongs to
          {...expanded, [clickedData.type]: expanded[clickedData.type].filter(id => id !== clickedData.id)}
        : // Put the current ID into the expand list it belongs to
          {...expanded, [clickedData.type]: [...expanded[clickedData.type], clickedData.id]}
    await dispatch({type: SyllabusActionType.ChangeSyllabusExpand, payload: newStatusObject})
}

export const expandFieldState = (id, type, expanded) => async dispatch => {
    if (id && type) {
        const newStatusObject = {...expanded, [type]: [...expanded[type], id]}
        await dispatch({type: SyllabusActionType.ChangeSyllabusExpand, payload: newStatusObject})
    } else {
        sendErrorMessage(dispatch, 'Cannot expand field')
        // console.log('Cannot expand field')
    }
}

export const fetchClassesForSyllabus = () => async dispatch => {
    try {
        const res = await api.post('/classes/fetchClass')
        if (res.data?.rs) {
            dispatch({type: SyllabusActionType.FetchClasses, payload: res.data.data.filter(c => c.isActive)})
            return true
        }
        return false
    } catch (e) {
        sendErrorMessage(dispatch, 'Error fetching syllabus')
        // console.log('Error fetching syllabus', e)
        return false
    }
}

export const fetchSyllabus = () => async dispatch => {
    try {
        const res = await api.get('/classes/syllabus/syllabus')
        if (res.data?.rs) {
            dispatch({type: SyllabusActionType.FetchSyllabus, payload: res.data.data})
            return true
        }
        return false
    } catch (e) {
        sendErrorMessage(dispatch, 'Error fetching syllabus')
        // console.log('Error fetching syllabus', e)
        return false
    }
}

export const updateSyllabusCell = (data, type, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.put(`/classes/syllabus/${type}`, data)
        if (res.data?.rs) {
            await dispatch(fetchSyllabus())
            return onSuccess(res.data?.message || 'Updated')
        }
        return onError(res.data?.message || 'Update failed')
    } catch (e) {
        sendErrorMessage(dispatch, 'Error updating syllabus cell')
        // console.log('Error updating syllabus cell', e)
        return onError(e.message)
    }
}

export const createSyllabusCell = (data, type, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post(`/classes/syllabus/${type}`, data)
        if (res.data?.rs) {
            await dispatch(fetchSyllabus())
            return onSuccess(res.data?.message || 'Created')
        }
        return onError(res.data?.message || 'Add failed')
    } catch (e) {
        // console.log('Error adding syllabus cell', e)
        return onError(e.message)
    }
}

export const deleteSyllabusCell = (id, type, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.delete(`/classes/syllabus/${type}/${id}`)
        if (res.data?.rs) {
            await dispatch(fetchSyllabus())
            return onSuccess(res.data?.message || 'Deleted')
        }
        return onError(res.data?.message || 'Delete failed')
    } catch (e) {
        // console.log('Error deleting syllabus cell', e)
        return onError(e.message)
    }
}

export const reorderSyllabusCell = (data, id, type, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.put(`/classes/syllabus/${type}Order/${id}`, data)
        if (res.data?.rs) {
            await dispatch(fetchSyllabus())
            return onSuccess(res.data?.message || 'Updated')
        }
        return onError(res.data?.message || 'Update failed')
    } catch (e) {
        // console.log('Error rearranging syllabus cell', e)
        return onError(e.message)
    }
}
