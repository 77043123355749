import React from 'react';
import {
  AppBar,
  Paper, Tab, Tabs
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import { ReviewActionType } from '../../../actions/review/reviewActions';
import { a11yProps } from '../../../components/mui/tabs/TabPanel';
import ReviewListTab from './ReviewTabView';
import { REVIEW_LIST_TABS } from '../reviewHelper';

function ReviewListView() {
  useCurrentViewCheck(Functions.Review_List)

  const dispatch = useDispatch()
  const currentTab = useSelector(state => state.review.currentReviewTab)

  const handleChange = (event, newValue) =>
    dispatch({ type: ReviewActionType.ChangeReviewTab, payload: newValue })

  const renderTabs = () =>
    <Paper elevation={0} style={{paddingBottom: '16px'}}>
      <AppBar position='sticky' color='default'>
        <Tabs value={currentTab} onChange={handleChange}>
          {
            Object.values(REVIEW_LIST_TABS).map((tab, idx) =>
              <Tab key={tab.key} label={tab.title} {...a11yProps(idx)} />
            )
          }
        </Tabs>
      </AppBar>
      <ReviewListTab />
    </Paper>

  return (
    <ViewHead functionId={Functions.Review_List} noMaxWidth>
      { renderTabs() }
    </ViewHead>
  )
}

export default ReviewListView
