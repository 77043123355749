
import React from 'react';
import { Navigate } from 'react-router';
import registerRoutes from './registered';
import interviewRoutes from './interview';
import InterviewViewerView from '../../../views/customer/trace';

const customerRoutes = [
  {
    path: 'customer',
    routes: [
      ...registerRoutes,
      ...interviewRoutes,
      {
        path: 'tracing/:id',
        exact: 'true',
        component:<InterviewViewerView/>
      },
      {
        component:()=><Navigate to="404"/> // this must be the last one
      },

    ]
  },
]

export default customerRoutes;