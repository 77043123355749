
import { sendSuccessMessage } from "../message/messageActions";
import api from "../../apis/api";
// import _ from 'lodash';

export const FunctionActions = {
    SearchFunctions: "SearchFunctions",
    SearchFunctionsByParent: "SearchFunctionsByParent",
    CancelSelectParent: "CancelSelectParent",
    CreateOne: "CreateOne",
    SetFunctionFormStatus: "SetFunctionFormStatus",
    FetchFunction: "FetchFunction",
    CancelEditSelectParent:"CancelEditSelectParent",
    FetchEditParentFunction:"FetchEditParentFunction",
    EditFunction:"EditFunction",
    LoadFunctionTree:"LoadFunctionTree",
    functionTreeSelectorChange:"functionTreeSelectorChange",
    LoadFunctionSource:"LoadFunctionSource",
}

export const fetchFunctionTree = (cb)=>async dispatch =>{
    const response = await api.get('/function/fetchFunctionTree');
    dispatch({
        type:FunctionActions.LoadFunctionSource,
        payload:response.data
    })

    if(typeof cb === "function"){
        cb();
    }
}

export const searchFunctions = search => async dispatch => {
    const response = await api.post('/function/searchForTree', search)
    dispatch({type: FunctionActions.SearchFunctions, payload: response.data})
}

export const searchFunctionsByParentId = search => async dispatch => {
    const response = await api.post('/function/searchForTree', search)
    dispatch({
        type: FunctionActions.SearchFunctionsByParent,
        payload: {
            parent: search.parent,
            children: response.data
        }
    })
}

export const fetchFunction = (id,mode="Add") => async dispatch => {
    const response = await api.get(`/function/fetchFunction/${id}`);
    if(mode==="Edit"){
        dispatch({type: FunctionActions.FetchEditParentFunction, payload: response.data});
    }else{
        dispatch({type: FunctionActions.FetchFunction, payload: response.data});

    }

}
export const updateFunction = (id,data,cb,handleError)=>async dispatch => {
    try{
        const response = await api.put(`/function/updateFunction/${id}`,data);

        if(response.data){
            dispatch({type: FunctionActions.EditFunction, payload: response.data})
            sendSuccessMessage(dispatch,`Function ${response.data.name} update successfully`)
            cb(response.data);
        }
    }catch(e){
        handleError(e);
    }

}
export const createOneFunction = (data, cb) => async dispatch => {
    try {
        const response = await api.post('/function/createOne', data);
        if (response.data) {
            sendSuccessMessage(dispatch,`Function ${response.data.name} create successfully`);
        }
        cb(response.data)
    } catch (e) {
        // console.log("error",e);
        // setError(e.response)
    }

}

export default createOneFunction