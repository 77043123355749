import {BiActionType} from '../../actions/bi/biActions'

const defaultState = {
    allBIData: []
}

export const biReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case BiActionType.FetchBIDataByMatrix:
            return {...state, allBIData: action.payload}

        default:
            return state
    }
}

export default biReducer
