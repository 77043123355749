import React, { useState } from 'react';
import { Box, Fade, Grid, IconButton, ListItem, Popper, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Clipboard, ExternalLink, Eye, RefreshCw, Trash } from 'react-feather';
import { InfoOutlined } from '@mui/icons-material';
import { copyToClipboard } from '../../../../utils/Scripts';
import { BBS_SITE_LIST, BROKEN_IMAGE_SRC, POST_NEW_STATUS_TIME, useMatricListItemView } from '../../matricHelper';

function MonitorListItem({ postData, handleDelete,
                           handleRead, handleRefresh, handleOpenImage }) {

  const dispatch = useDispatch()
  const classes = useMatricListItemView()

  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget)
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  let keywords = 'kw: '                                       // Keywords base
  keywords += `[${Array.isArray(postData.keywords)            // keywords
    ? postData.keywords.join(', ').trim()
    : '?'}] > `
  keywords += `${ BBS_SITE_LIST.find(c =>
    c.symbol === postData.site).name } > `                    // site name
  keywords += `${ postData.category } > ${ postData.user }`   // category & poster

  const updateTime = moment(postData.updateTime)
  const updateTimeSince = updateTime.fromNow()
  const updateTimeLocal = updateTime.format('L LT')

  const foundTime = moment(postData.foundTime)
  const foundTimeSince = foundTime.fromNow()
  const foundTimeLocal = foundTime.format('L LT')

  const postPosition = postData.position || '?'
  const postPage = postData.page || '?'
  const postViews = postData.views || '?'
  const postReplies = postData.replies || '?'

  return (
    <ListItem className={clsx(classes.listRoot, {
      [classes.newPostEntrance]: moment().diff(foundTime, 'seconds') <= POST_NEW_STATUS_TIME
    })}>
      <Grid container justifyContent='space-between'>

        {/* Left */}
        <Grid item xs={12} md={8} xl={5}>
          <Tooltip title={postData.isAlive ? 'Alive' : 'Dead'} placement='top'>
            <div className={clsx(classes.aliveCircle, {
              'alive': postData.isAlive,
              'dead': !postData.isAlive
            })} />
          </Tooltip>
          <div className={classes.leftPanel}>
            <Typography
              variant='h5'
              aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
              onMouseEnter={(e) => handlePopoverOpen(e)}
              onMouseLeave={() => handlePopoverClose()}
            >
              { postData.title }
            </Typography>

            <Typography variant='body1'>{ keywords }</Typography>

            <Tooltip title={foundTimeLocal} placement='top'>
              <Typography variant='body2' component='span'>Found:  {foundTimeSince} | </Typography>
            </Tooltip>

            <Tooltip title={updateTimeLocal} placement='top'>
              <Typography variant='body2' component='span'>Last:  {updateTimeSince}</Typography>
            </Tooltip>
          </div>
        </Grid>

        {/* Right */}
        <Grid item xs={12} md={4} xl={2}>
          <Grid container direction='column' justifyContent='flex-end' alignItems='flex-end'>
            <Grid item>
              {
                postData.readTime
                  ?
                  <Tooltip title={`Read by ${postData.readTime.userName} ${moment(postData.readTime.time).fromNow()}`} placement='top'>
                    <IconButton size='small'
                                component='span'
                                style={{ color: 'green' }}
                    >
                      <Eye />
                    </IconButton>
                  </Tooltip>
                  :
                  <Tooltip title='Mark Post As Read' placement='top'>
                    <IconButton size='small'
                                component='span'
                                style={{ color: 'red' }}
                                onClick={() => handleRead({ site: postData.site, id: postData.id })}
                    >
                      <Eye />
                    </IconButton>
                  </Tooltip>
              }

              <Tooltip title='Delete Post' placement='top'>
                <IconButton size='small'
                            component='span'
                            onClick={() => handleDelete({ site: postData.site, id: postData.id })}
                >
                  <Trash />
                </IconButton>
              </Tooltip>

              <Tooltip title='Open Post' placement='top'>
                <IconButton size='small'
                            component='span'
                            onClick={() => window.open(postData.url, '_blank')}
                >
                  <ExternalLink />
                </IconButton>
              </Tooltip>

              <Tooltip title='Copy Post Link' placement='top'>
                <IconButton size='small'
                            component='span'
                            onClick={() => copyToClipboard(dispatch, postData.url)}
                >
                  <Clipboard />
                </IconButton>
              </Tooltip>

              <Tooltip title='Update' placement='top'>
                <IconButton size='small'
                            component='span'
                            onClick={() => handleRefresh({ site: postData.site, id: postData.id })}
                >
                  <RefreshCw />
                </IconButton>
              </Tooltip>

            </Grid>

            <Grid item>
              <div className={classes.rightPanel}>
                <Typography variant='body2' component='span'>Pos: </Typography>
                <Tooltip title={`Position: ${postPosition}; Page: ${postPage}`} placement='top'>
                  <Typography variant='body2' component='span'>
                    {postPosition}/{postPage}
                  </Typography>
                </Tooltip>

                <Typography variant='body2' component='span'> | Total: </Typography>
                <Tooltip title={`Replies ${postReplies}; Views: ${postViews}`} placement='top'>
                  <Typography variant='body2' component='span'>
                    {postReplies}/{postViews}
                  </Typography>
                </Tooltip>

                <div className={classes.infoButton}>
                  <Tooltip title={postData.lastAction || 'Something weird happened'} placement='top'>
                    <InfoOutlined fontSize='small'/>
                  </Tooltip>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Right - QR */}
        <Grid item xs={12} xl={4} className={classes.qrPanel}>
          { postData.qr.map((code,index) => <div
            key={index}
            aria-hidden="true"
            onClick={() => handleOpenImage(code)}
          >
            <img src={code}
                 alt='qr code found within the post'
                 onError={(e) => {
                   e.target.onerror = null
                   e.target.src = BROKEN_IMAGE_SRC
                 }}
            />
          </div>) }
        </Grid>
      </Grid>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement='top'
        disablePortal
        style={{
          zIndex: 9999
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box className={classes.descriptionHover}>
              { postData?.description
                ? postData?.description
                : <Typography className='empty'>No content was found on this post</Typography>
              }
            </Box>
          </Fade>
        )}
      </Popper>
    </ListItem>
  )
}

export default MonitorListItem
