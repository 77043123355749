import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import * as yup from 'yup';

export const genHoverCell = (rowValue) => {
  const styles = {
    width: '100%',
    padding: '.5rem',
    fontFamily: 'Sans-Serif',
    maxHeight: '50vh',
    overflow: 'auto'
  }
  return (
    <div style={styles}>
      {rowValue}
    </div>
  )
}

export const REVIEW_LIST_TABS = {
  0: { title: 'All', key: 'all' },
  1: { title: 'Pending', key: 'pending' },
  2: { title: 'Internal', key: 'internal' },
  3: { title: 'Google', key: 'google' },
}

export const testUrl = (value) =>
  value.match(/^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/)

export const REVIEW_SET_EDITOR_VALIDATOR = {
  name: yup.string().required('Required')
    .min(1, 'Name must be at least 1 character')
    .max(255, 'Name must be less than 255 characters')
    .trim(),
  googleUrl: yup.string().required('Required')
    .min(1, 'URL must be at least 1 character')
    .max(255, 'URL must be less than 255 characters')
    .url('Must be a valid URL starting with http(s)://').trim()
}

export const REVIEW_M2W_EDITOR_VALIDATOR = {
  name: yup.string().required('Required')
    .min(1, 'Name must be at least 1 character')
    .max(255, 'Name must be less than 255 characters')
    .trim(),
  text: yup.string().required('Required')
    .min(1, 'Review must be at least 1 character')
    .max(1000, 'Review must be less than 1000 characters')
    .trim(),
  score: yup.number().required()
    .min(1, 'Score must be greater than or equal to 1')
    .max(5, 'Score must be less than or equal to 5')
    .typeError('Please input a valid score between 1 to 5'),
}

export const useReviewListStyles = makeStyles((theme) => ({
  activityButtons: {
    paddingLeft: `${theme.spacing(1)}!important`,
    paddingBottom: `${theme.spacing(2)}!important`,
  },
  root: {
    margin:theme.spacing(2), 
  },
  search: {
    position: 'relative',
    margin: '20px 0 10px 0',
    width: '100%',
    // padding: theme.spacing(0, 4),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding:theme.spacing(2)

  },
  dataGrid: {
    marginRight:`${theme.spacing(4)}px!important`,
  }
}))

export const useReviewEditorStyles = makeStyles((theme) => ({
  paper: {
    padding: '2rem',
    marginBottom: '2rem'
  },
  marginBottom: {
    marginBottom: '1rem'
  },
  customTextArea: {
    padding: '18.5px 14px',
    color: '#263238',
    fontSize: '1rem',
    boxSizing: 'border-box',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    outlineColor: theme.palette.primary.main,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '5px'
  },
  switchToggle: {
    marginLeft: 0
  },
  autofillForm: {
    marginBottom: '1rem',
    marginTop: '1.5rem'
  },
  submitButton: {
    marginTop: '5rem',
    '& button': {
      minWidth: '200px'
    }
  }
}))

export const useReviewViewStyle = makeStyles(() => ({
  centerMessageWrapper: {
    boxShadow: '2px 2px 8px 2px rgba(0,0,0,.2)',
    position: 'absolute',
    minHeight: '100%',
    padding: '3rem',
    width: '100%',
    maxWidth: '800px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperWrapper: {
    position: 'absolute',
    minHeight: '100%',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    marginTop: '-1px',
    maxWidth: '800px',
    width: '100%',
    boxShadow: '2px 2px 8px 2px rgba(0,0,0,.2)'
  },
  redirectMask: {
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,.75)',
    zIndex: '999',
    visibility: 'hidden',
    opacity: 0,
    transition: 'all 1s linear',
    '&.show': {
      visibility: 'visible',
      opacity: 1,
    },
    '& h3': {
      color: 'white'
    }
  },
  topBanner: {
    height: '54px',
    lineHeight: '54px',
    marginLeft: '9px',
    '& .backArrow': {
      width: '24px',
      height: '24px',
      opacity: '0.6',
      color: 'black'
    },
    '& .title': {
      color: 'rgba(0,0,0,0.7)',
      fontSize: '20px',
      marginLeft: '9px',
      fontWeight: '400',
      overflow: 'hidden',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      letterSpacing: '0px',
      overflowWrap: 'break-word'
    },
    '& .post': {
      textTransform: 'none',
      letterSpacing: '0px',
      color: 'rgba(60,64,67,0.38)',
      marginTop: '-2px',
      marginRight: '9px',
      '&.active': {
        color: '#1A73E8',
      },
      '&:hover': {
        backgroundColor: 'rgba(26, 115, 232, .15)'
      }
    },
  },
  profileSpacer: {
    height: '58px',
    backgroundColor: 'transparent'
  },
  ratingsContainer: {
    padding: '16px',
    height: '92px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    '& .MuiRating-label': {
      fontSize: '34px',
      padding: '13px',
      '&:hover': {
        fontSize: '34px',
      }
    }
  },
  reviewTextFormHelper: {
    paddingLeft: '16px',
    marginBottom: '-16px'
  },
  reviewText: {
    marginTop: '16px',
    padding: '2px',
    '& .MuiInputBase-input, & .MuiInputBase-root': {
      fontSize: '15.7px',
      letterSpacing: '0px',
      color: 'rgba(0,0,0,0.72)',
      boxSizing: 'border-box',
      fontWeight: '400',
      margin: 0,
      minHeight: '90px',
      whiteSpace: 'pre-wrap',
      width: '100%',
    },
    '& fieldset': {
      border: 'none'
    }
  }

}))
