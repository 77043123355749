import React, {useEffect, useState} from "react";
import LanguageSelect from "../../components/public/LanguageSelect";

function HooksTrainingView() {
    const [value, setValue] = useState(1);

    console.log("value=",value);

    const [debouncedValue, setDebouncedValue] = useState("");
    const [result, setResult] = useState('');

    console.log("current Value:",value);

    useEffect(()=>{
        const timeId = setTimeout(()=>{
            setDebouncedValue(value);
        },500)

        return ()=>{  // componentWillUnmount()
            console.log("clear code");
            clearTimeout(timeId);
        }

    },[value])

    useEffect(()=>{
        console.log("send Api");
        if(debouncedValue.length>0) {
            setResult(Math.random().toString(16).substring(2, 8))
        }

    },[debouncedValue])

    return (
        <div style={{padding:"10px"}}>
            <h2>
                Hooks Training
            </h2>
            <div>
                Init Value {value}
            </div>
            <div>
                <button onClick={()=>{

                    setValue((old)=>{
                        console.log("old value", value);
                        return Math.random()
                    });

                }}> ChangeValue</button>
            </div>

            <div>
                <input type="text" value={value} onChange={event=>{setValue(event.target.value)}} size={20}/>
            </div>
            <div>
                {result}
            </div>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <p>
                sldkjfldskjf
            </p>
            <LanguageSelect/>
            <p>
                sldkjfldskjf
            </p>

        </div>
    );
}

export default HooksTrainingView;