import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material'
import Paper from '@mui/material/Paper'
import {AddCircle, Check, Close, DeleteForever, ListAlt} from '@mui/icons-material'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import FormControl from '@mui/material/FormControl'
import {noop} from 'lodash'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import dayjs from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ContractPaymentHistoryReference from './ContractPaymentHistoryReference'
import ContractPaymentHistoryReferenceView from './ContractPaymentHistoryReferenceView'
import {centsToDollarString, centsToDollarStringCAD} from '../../../../utils/PaymentHelper'
import api from '../../../../apis/api'
import LoadingScreen from '../../../../components/public/LoadingScreen'
import {sendErrorMessage, sendSuccessMessage} from '../../../../actions'
import {
    addContractPaymentHistory,
    deleteContractPaymentEmails,
    updateContractRemaining
} from '../../../../actions/administrative/administrativeAction'
import {Functions} from '../../../../utils/Functions'

const useCommissionStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '1000px',
        margin: '0 auto'
    },
    paper: {
        padding: '2rem',
        width: 'clamp(300px, 80vw, 1000px)',
        maxHeight: '77vh',
        overflow: 'auto',
        '& h3': {
            fontWeight: 'bold',
            fontSize: '1.25rem'
        },
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        }
    },
    table: {
        minWidth: '500px'
    },
    modalTitle: {
        marginBottom: '1rem'
    },
    notes: {
        whiteSpace: 'pre-line',
        wordBreak: 'break-word'
    }
}))

const EmailRow = ({item, setIsAdd, isAdd, setEditing, cid, isNew, fetchProps, contract, onCancel = noop}) => {
    const dispatch = useDispatch()
    const [isDeleting, setIsDeleting] = useState(false)
    const [htmlView, setHtmlView] = useState(false)
    const [isEdit, setIsEdit] = useState(isNew)
    const [isSaving, setIsSaving] = useState(false)
    // const [errors, setErrors] = useState({});
    const [html, setHtml] = useState('')
    const [htmlIsLoading, setHtmlIsLoading] = useState(false)
    const [formData, setFormData] = useState(
        isNew
            ? {
                  email: '',
                  payer: '',
                  date: dayjs(new Date()),
                  type: '',
                  amount: '',
                  pic: [],
                  note: []
              }
            : {
                  email: item?.email,
                  payer: item?.payerName,
                  date: item?.receiveTime,
                  type: item?.paymentType,
                  amount: item?.amount,
                  pic: item?.pic,
                  note: item?.note
              }
    )
    const onSubmit = async () => {
        const submitFormData = {...formData, cid, contract}
        submitFormData.date = JSON.stringify(submitFormData.date)
        // setErrors({});
        setIsSaving(true)

        try {
            if (isNew) {
                // create new email
                await addContractPaymentHistory(
                    submitFormData?.email,
                    submitFormData?.contract.currency.currency,
                    submitFormData?.payer,
                    submitFormData?.date,
                    submitFormData?.type,
                    submitFormData?.amount,
                    submitFormData?.cid,
                    submitFormData?.pic,
                    submitFormData?.note,
                    onSuccess,
                    onError,
                    fetchProps
                )(dispatch)
            }
        } catch (err) {
            // display error to the user
            setIsSaving(false)
            // setErrors(err.inner.reduce((res, cur) => ({ ...res, [cur.path]: cur.message }), {}));
        }
    }

    const onDelete = async () => {
        const submitFormData = {...formData, cid}
        // setErrors({});
        setIsSaving(true)
        try {
            // delete existing email
            await deleteContractPaymentEmails(
                submitFormData.fname,
                submitFormData.lname,
                submitFormData.email,
                item.id,
                submitFormData.cid,
                onSuccess,
                onError,
                fetchProps
            )(dispatch)
        } catch (err) {
            // display error to the user
            setIsSaving(false)
            // setErrors(err.inner.reduce((res, cur) => ({ ...res, [cur.path]: cur.message }), {}));
        }
    }

    const onSuccess = msg => {
        sendSuccessMessage(dispatch, msg)
        setIsSaving(false)
        setIsEdit(false)
        onCancel()
    }

    const onError = msg => {
        sendErrorMessage(dispatch, msg)
        setIsSaving(false)
    }

    const onCancelEdit = () => {
        // reset form data and the like
        setIsEdit(false)
        setFormData(
            isNew
                ? {
                      email: '',
                      payer: '',
                      date: '',
                      type: '',
                      amount: '',
                      pic: [],
                      note: []
                  }
                : {
                      email: item?.email,
                      payer: item?.payerName,
                      date: item?.date,
                      type: item?.type,
                      amount: item?.amount,
                      pic: item?.pic,
                      note: item?.note
                  }
        )
        onCancel()
    }
    function DeleteAlertDialog() {
        const handleClose = () => {
            setIsDeleting(false)
        }
        return (
            <Dialog
                open={isDeleting}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Deleting Email</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please make sure to delete the email from this payment email list.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>cancel</Button>
                    <Button
                        onClick={() => {
                            onDelete()
                            handleClose()
                        }}
                        autoFocus
                    >
                        confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    function PaymentHtmlViewDialog() {
        const handleClose = () => {
            setHtmlView(false)
        }
        return (
            <Dialog
                fullWidth
                maxWidth="md"
                open={htmlView}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Viewing Payment Email Content</DialogTitle>
                <DialogContent>
                    {!htmlIsLoading ? (
                        <iframe srcDoc={html.data} width="100%" height="800px" title="Reference" />
                    ) : (
                        <LoadingScreen />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>close</Button>
                </DialogActions>
            </Dialog>
        )
    }
    const renderEditView = () => (
        <TableRow>
            <TableCell scope="row">
                <FormControl fullWidth>
                    <InputLabel size="small" sx={{width: '100px'}} id="demo-simple-select-label">
                        Type
                    </InputLabel>
                    <Select
                        size="small"
                        sx={{width: '100px'}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.type}
                        label="Type"
                        required
                        disabled={isSaving}
                        onChange={e => setFormData({...formData, type: e.target.value})}
                    >
                        <MenuItem value="email_transfer">email_transfer</MenuItem>
                        <MenuItem value="cash">cash</MenuItem>
                        <MenuItem value="cheque">cheque</MenuItem>
                        <MenuItem value="credit_card">credit_card</MenuItem>
                        <MenuItem value="debit_card">debit_card</MenuItem>
                        <MenuItem value="wechat_pay">wechat_pay</MenuItem>
                        <MenuItem value="alipay">alipay</MenuItem>
                        <MenuItem value="paypal">paypal</MenuItem>
                        <MenuItem value="wire_transfer">wire_transfer</MenuItem>
                        <MenuItem value="bitcoin">bitcoin</MenuItem>
                        <MenuItem value="other">other</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell scope="row">
                <FormControl fullWidth>
                    <LocalizationProvider fullWidth size="small" dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            fullWidth
                            size="small"
                            label="Date desktop"
                            inputFormat="MM/DD/YYYY"
                            value={formData.date}
                            onChange={newValue => {
                                setFormData({...formData, date: newValue})
                            }}
                            renderInput={params => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>
                </FormControl>
            </TableCell>

            <TableCell scope="row">
                <FormControl fullWidth>
                    <TextField
                        fullWidth
                        size="small"
                        sx={{width: '100px'}}
                        label="Amount"
                        required
                        name="amount"
                        disabled={isSaving}
                        value={formData.amount}
                        type="number"
                        onChange={e => setFormData({...formData, amount: e.target.value})}
                    />
                </FormControl>
            </TableCell>
            <TableCell scope="row">
                <FormControl fullWidth>
                    <ContractPaymentHistoryReference setFormData={setFormData} formData={formData} />
                </FormControl>
            </TableCell>
            <TableCell align="right">
                <Tooltip title="Save" placement="top">
                    <IconButton
                        color="primary"
                        size="small"
                        disabled={isSaving || !contract?.currency?.currency}
                        component="span"
                        onClick={() => onSubmit()}
                    >
                        <Check style={{color: contract?.currency?.currency ? 'green' : 'grey'}} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancel" placement="top">
                    <IconButton
                        color="primary"
                        size="small"
                        disabled={isSaving}
                        component="span"
                        onClick={() => {
                            onCancelEdit()
                            setEditing(false)
                        }}
                    >
                        <Close style={{color: 'red'}} />
                    </IconButton>
                </Tooltip>
                {!isAdd && (
                    <Tooltip title="Delete" placement="top">
                        <IconButton
                            color="primary"
                            size="small"
                            disabled={isSaving}
                            component="span"
                            onClick={() => {
                                setIsDeleting(true)
                            }}
                        >
                            <DeleteForever style={{color: 'black'}} />
                        </IconButton>
                    </Tooltip>
                )}
            </TableCell>
            <DeleteAlertDialog />
        </TableRow>
    )

    const renderViewView = () => {
        let dateString = null
        if (typeof formData?.date === 'object') {
            dateString = JSON.stringify(formData?.date)
        } else {
            dateString = formData?.date
        }
        dateString = dateString.substring(1, dateString.length - 1)
        const dateView = new Date(Date.parse(dateString))
        return (
            <TableRow>
                <TableCell scope="row">{formData?.type?.toUpperCase()}</TableCell>

                <TableCell scope="row">
                    {dateView.getFullYear()}/{dateView.getMonth() + 1}/{dateView.getDate()}
                </TableCell>
                <TableCell scope="row">
                    {item?.currency
                        ? centsToDollarString(formData?.amount, item?.currency)
                        : centsToDollarStringCAD(formData?.amount)}
                </TableCell>
                <TableCell scope="row">
                    <FormControl fullWidth>
                        <ContractPaymentHistoryReferenceView formData={item} />
                    </FormControl>
                </TableCell>
                <TableCell align="right">
                    <Tooltip title="Email HTML View" placement="top">
                        <IconButton
                            color="primary"
                            size="small"
                            component="span"
                            onClick={async () => {
                                setHtmlIsLoading(true)
                                setHtmlView(true)
                                const res = await api.post('administrative/contract/getPaymentLogHtml', {
                                    referenceNumber: item.referenceNumber
                                })
                                setHtml(res)
                                setHtmlIsLoading(false)
                            }}
                        >
                            <RemoveRedEyeOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top">
                        <IconButton
                            color="primary"
                            size="small"
                            component="span"
                            disabled
                            onClick={() => {
                                setEditing(true)
                                setIsEdit(true)
                                setIsAdd(false)
                            }}
                        >
                            <ListAlt />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <PaymentHtmlViewDialog />
            </TableRow>
        )
    }

    const renderRow = () => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{isEdit ? renderEditView() : renderViewView()}</>
    )

    return renderRow()
}

function ContractPaymentHistoryManagement({contract, fetchProps, handleModalClose}) {
    const [editing, setEditing] = useState(false)
    const auth = useSelector(state => state.auth.auth)
    useEffect(() => {
        if (contract) {
            setRemainingValue(contract.remaining)
        }
        setEditing(false)
    }, [contract])

    const dispatch = useDispatch()

    const classes = useCommissionStyles()

    const [isAdd, setIsAdd] = useState(false)
    const [remainingValue, setRemainingValue] = useState(0)
    useEffect(() => {
        if (contract) {
            setRemainingValue(contract.remaining / 100)
        }
    }, [contract])

    const renderTable = () => (
        <TableContainer>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        {/* <TableCell sx={{ minWidth: 160 }}>Status</TableCell> */}
                        <TableCell>Type</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Reference</TableCell>
                        <TableCell align="right">Edit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contract?.contractPaymentHistory?.map(ele => (
                        <EmailRow
                            setEditing={setEditing}
                            setIsAdd={setIsAdd}
                            isAdd={isAdd}
                            contract={contract}
                            item={ele}
                            key={ele.id}
                            cid={contract.cid}
                            isNew={false}
                            fetchProps={fetchProps}
                            onCancel={() => setIsAdd(false)}
                        />
                    ))}

                    {isAdd && (
                        <EmailRow
                            setEditing={setEditing}
                            cid={contract.cid}
                            contract={contract}
                            setIsAdd={setIsAdd}
                            isAdd={isAdd}
                            isNew
                            fetchProps={fetchProps}
                            onCancel={() => setIsAdd(false)}
                        />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )

    return (
        <Modal
            open={contract !== null}
            onClose={() => {
                handleModalClose()
                setIsAdd(false)
            }}
            className={classes.modal}
            disablePortal
            disableRestoreFocus
            disableEnforceFocus
            disableAutoFocus
            closeAfterTransition
        >
            <Paper className={classes.paper}>
                {contract?.cid && Array.isArray(contract?.commissions) && (
                    <div>
                        <Stack
                            justifyContent="space-between"
                            direction="row"
                            justifyItems="center"
                            className={classes.modalTitle}
                        >
                            <Typography variant="h3" gutterBottom>
                                {`Payment History for ${contract.cid} for ${contract?.sales_fullName}`}
                            </Typography>
                            <Tooltip title="Add new payment" placement="top">
                                <IconButton disabled={editing} onClick={() => setIsAdd(!isAdd)} size="large">
                                    <AddCircle style={{color: isAdd ? 'red' : editing ? 'gray' : 'green'}} />
                                </IconButton>
                            </Tooltip>
                        </Stack>

                        {auth.indexOf(Functions.Administrative_Contract_Create) !== -1 && (
                            <Accordion sx={{mb: 1}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography variant="h5" gutterBottom>
                                        Edit Remaining
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                                >
                                    <div>
                                        <TextField
                                            sx={{mr: 1, mb: 1}}
                                            label="Total"
                                            size="small"
                                            disabled
                                            color="primary"
                                            value={centsToDollarStringCAD(contract?.total)}
                                        />
                                        <TextField
                                            sx={{mr: 1, mb: 1}}
                                            label="Current Remaining"
                                            size="small"
                                            disabled
                                            color="primary"
                                            value={centsToDollarStringCAD(contract?.remaining)}
                                        />
                                        <TextField
                                            sx={{mr: 1, mb: 1}}
                                            label="New Remaining"
                                            size="small"
                                            color="primary"
                                            type="number"
                                            value={remainingValue}
                                            onChange={e => {
                                                if (e.currentTarget.value !== '') {
                                                    setRemainingValue(
                                                        JSON.stringify(Math.abs(parseFloat(e.currentTarget.value)))
                                                    )
                                                } else {
                                                    setRemainingValue('0')
                                                }
                                            }}
                                        />
                                    </div>

                                    <Tooltip title="Save" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            disabled={!contract?.currency?.currency}
                                            component="span"
                                            onClick={() => {
                                                updateContractRemaining(
                                                    remainingValue,
                                                    contract.cid,
                                                    fetchProps
                                                )(dispatch)
                                                // rs && !!contract && setRemainingValue( contract.remaining/100))
                                            }}
                                        >
                                            <Check style={{color: contract?.currency?.currency ? 'green' : 'grey'}} />
                                        </IconButton>
                                    </Tooltip>
                                </AccordionDetails>
                            </Accordion>
                        )}

                        {renderTable()}
                    </div>
                )}
            </Paper>
        </Modal>
    )
}

export default ContractPaymentHistoryManagement
