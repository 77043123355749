import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ViewHead from '../../../../components/public/ViewHead';
import { Functions } from '../../../../utils/Functions';
import CampaignPosterEditor from './campaignPosterEditor';
import { sendSuccessMessage } from '../../../../actions';
import { fetchCampaign } from '../../../../actions/campaign/campaignActions';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';

function CampaignAddPosterView() {
  useCurrentViewCheck(Functions.Campaign_Add_Poster)
  const  navigate = useNavigate();
  const campaignId = parseInt(useParams().campaignId,10)
  const dispatch = useDispatch()
  const [isRealCampaign, setIsRealCampaign] = useState(-1)

  useEffect(() => {
    (async() => {
      const isReal = await fetchCampaign(campaignId)(dispatch)
      setIsRealCampaign(isReal ? 1 : 0)
    })()
  }, [campaignId, dispatch])

  const onSubmitSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/campaign/view/${campaignId}`)
    }, 1000)
  }

  const renderReturnButton = () => (
      <Button color='secondary'
              variant='contained'
              onClick={() => navigate(`/app/campaign/view/${campaignId}`)}>
        Back To Campaign
      </Button>
    )

  return (
    <ViewHead noMaxWidth functionId={Functions.Campaign_Add_Poster} topButtons={renderReturnButton()}>
      { isRealCampaign === 1 ?
        <CampaignPosterEditor
          isEdit={false}
          campaignId={campaignId}
          posterData={{}}
          onSubmitSuccess={onSubmitSuccess}
        />
        :
        <Typography variant='h2'>{ isRealCampaign === -1 ? 'Loading' : 'Invalid Campaign ID' }</Typography>
      }
    </ViewHead>
  )
}

export default CampaignAddPosterView
