import React, {useState,useEffect} from 'react';
import { Avatar, Badge, CircularProgress, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {PhotoCameraRounded as PhotoCameraIcon} from '@mui/icons-material/';
import {useDispatch, useSelector,connect} from "react-redux";
import Button from "@mui/material/Button";
import {
    sendErrorMessage,
    sendInfoMessage,
    sendSuccessMessage,
    sendWarningMessage,
    changeUploadTime,
} from "../../actions";
import {uploadApi} from "../../apis/uploadApi";
import {ServerBaseURL} from "../../constants";



const useStyles = makeStyles(() => ({

    avatar: {
        width: 60,
        height: 60,

    },
    avatarBox: {
        transform: "translate(-50px,-10px)",

    },
    avatarRoot: {
        "&::after": {
            content: "''",
            display: "block",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: 100,
            height: 22,
            backgroundColor: '#000',
            opacity: .25,
            zIndex: 1200,
        }
    },


    changePhotoIcon: {
        color: '#000000',
        marginLeft: 61,
        marginBottom: 21,
        opacity: .3,
        zIndex: 1201,
    },

}));
function UserAvatarWithUploader({changeUploadTime}) {




    const classes = useStyles();
    const [imageHash,setImageHash] = useState("");
    const [isUpload,setIsUpload] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>changeUploadTime(imageHash),[imageHash])




    const handleClick = () => {
        if(isUpload){
            return;
        }
        // eslint-disable-next-line func-names
         ('<input type="file" id="avatar" name="avatar" accept="image/*" >').on('change', function () {
             // eslint-disable-next-line react/no-this-in-sfc
            const file = this.files[0];
            if (file.size > 300 * 1024) {
                sendWarningMessage(dispatch, "User Avatar must be no more than 300Kb");
                return;
            }
            setIsUpload(()=>true);
            const formData = new FormData();
            formData.append("avatar",file);
            uploadApi.post('/user/uploadAvatar',formData).then(response=>{
                const {rs,message} = response.data;
                setIsUpload(()=>false);

                if(rs){
                    sendSuccessMessage(dispatch,message);
                    setTimeout(()=>sendInfoMessage(dispatch,"Reload Page to apply all the changes."),1000)
                    setImageHash(new Date().getMilliseconds())

                }else{
                    sendErrorMessage(dispatch,message);
                }
            })
        }).click();
    }

    const user = useSelector(state => state.auth.user);
    return (
        <Tooltip title="Avatar file must be no more than 300KB" placement="bottom-end">
            <Button disableRipple className={classes.avatarBox} onClick={handleClick}>
                <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                       badgeContent={<PhotoCameraIcon className={classes.changePhotoIcon}/>}
                >
                    {isUpload?<CircularProgress/>:
                        <Avatar src={`${ServerBaseURL}/asset/images/avatar/${user.uid}?${imageHash}`}
                        classes={{root: classes.avatarRoot}} className={classes.avatar}/>
                        }
                </Badge>
            </Button>
        </Tooltip>
    );
}

const mapStateToProps=(state)=>({ uploadTime:state.userAvatar?.uploadTime})



export default connect(mapStateToProps,{changeUploadTime})(UserAvatarWithUploader);
