import SystemMenu from './systemMenu';
import Auth from './auth'

export const zh = {
    translation: {
        Public:{
            SwitchLanguage: "选择语言",
        },
        contract: "合同",
        contractId: "合同ID",
        productId: "产品ID",
        product: "产品",
        name: "产品名",
        personName: "姓名",
        firstName: "名",
        lastName: "姓",
        address: "地址",
        city: "城市",
        country: "国家",
        zipCode: "邮编",
        phone: "电话",
        email: "邮箱",
        wechat: "微信",
        price: "价格",
        taxAmount: "税额",
        discount: "折扣",
        total: "总计",
        partyA: "甲方",
        partyB: "乙方",
        contractAgreementCheckboxText: "我确认已阅读、理解并同意上述合同",
        contractAgreementSignMessage: "本服务协议由双方在经过仔细沟通并充分理解协议内容后，在平等自愿的基础上签订。",
        contractSignedMessage: "感谢您选择我们。您现在可以关闭此页。",
        date: "日期",
        signature: "签名",
        signHere: "请在此签名",
        submitSignature: "提交签名",
        signatureError: "请在提交前签名",
        signatureErrorSize: "请将签得更大一些",
        signatureErrorGeneric: "签名无法提交",
        clear: "清除",
        cancel: "取消",
        confirm: "确认",
        submitContract: "提交合同",
        submitting: "提交中",
        invoice: "发票",
        invoiceBillTo: "账单收件人",
        invoiceShipTo: "送货至",
        invoiceInstructions: "说明",
        invoiceQuantity: "数量",
        invoiceDescription: "描述",
        invoiceUnitPrice: "单价",
        invoiceTaxAmount: "税额",
        invoiceDiscount: "折扣",
        invoiceTotal: "总计",
        invoiceTotalDueBy: "截止日期应付总额",
        invoiceSubtotal: "小计",
        invoiceSalesTax: "销售税",
        invoiceShippingHandling: "运费及手续费",
        sameAsBillingAddress: "与账单地址相同",
        invoiceThanksMessage: "感谢您的惠顾！",
        signAndConfirm: "签名并确认",
    },
    Auth,
    SystemMenu,

}
export default zh;
