import React from "react";
import { Navigate } from 'react-router';
import PosterGenerator from '../../../views/forms/posterGenerator/generator';
import EditPosterTemplateView from '../../../views/forms/posterGenerator/editor/editPosterTemplateView';
import PosterMakerView from '../../../views/forms/posterGenerator/view';
import CreatePosterTemplateView from '../../../views/forms/posterGenerator/editor/createPosterTemplateView';
import PosterGeneratorList from '../../../views/forms/posterGenerator';
import FormCategoryManagerView from '../../../views/forms/formCategory';
import WebFormData from '../../../views/forms/WebFormData';
import FormData from '../../../views/forms/formData';
import SubmissionDetails from '../../../views/forms/formData/submissionDetails';
import FormEditView from '../../../views/forms/formList/editFormView';
import FormViewerView from '../../../views/forms/formList/formViewer';
import FormBuilderView from '../../../views/forms/formBuilder';
import FormsIndexView from '../../../views/forms/formList';

const formsRoutes = [
    {
        path: 'forms',
        routes: [
            {
                path: '',
                exact: 'true',
                component:<FormsIndexView/>
            },
            {
                path: 'formBuilder/:id?',
                exact: 'true',
                component: <FormBuilderView/>
            },
            {
                path: 'submit/:id',
                exact: 'true',
                component: <FormViewerView/>
            },
            {
                path: 'edit/:id',
                exact: 'true',
                component:<FormEditView/>
            },
            {
                path: 'submission/:id',
                exact: 'true',
                component:<SubmissionDetails/>
            },
            {
                path: 'data/:id',
                exact: 'true',
                component:<FormData/>
            },
            {
                path: 'webFormData/:id',
                exact: 'true',
                component:<WebFormData/>
            },
            {
                path: 'category',
                exact: 'true',
                component: <FormCategoryManagerView/>
            },
            {
                path: 'poster',
                exact: 'true',
                component: <PosterGeneratorList/>
            },
            {
                path: 'poster/add',
                exact: 'true',
                component:<CreatePosterTemplateView/>
            },
            {
                path: 'poster/view/:id',
                exact: 'true',
                component: <PosterMakerView/>
            },
            {
                path: 'poster/edit/:id',
                exact: 'true',
                component: <EditPosterTemplateView/>
            },
            {
                path: 'poster/generate/:id',
                exact: 'true',
                component: <PosterGenerator/>
            },
            {
                component: <Navigate to="404"/>
            },
        ]
    }
]

export default formsRoutes
