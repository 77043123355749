// import styled from '@emotion/styled';
// import Box from '@mui/material/Box';
import {svgPie} from '../../constants'

const BIStackedLineDiagramOptions = (diagramTitle, biData, yAxisValueName, setIsPieChart) => {
    const {timeSpan, details} = biData
    const legendObject = JSON.parse(localStorage.getItem('legendObject') || '[]')
    const chartType = localStorage.getItem('chartType')
    const legends = []
    const allLegends = details.map(item => item.product)

    allLegends.forEach(legend => {
        if (legendObject[legend] || !Object.prototype.hasOwnProperty.call(legendObject, legend)) {
            legends.push(legend)
        }
    })

    const myChartType = localStorage.getItem('chartType') === null ? 'line' : chartType
    // calculate total
    const calculateTotal = details => {
        const total = new Array(timeSpan.length).fill(0)
        for (let i = 0; i < timeSpan.length; i += 1) {
            for (let j = 0; j < details.length; j += 1) {
                total[i] += details[j].timeStep[i][yAxisValueName]
            }
        }
        return total
    }

    // eslint-disable-next-line no-unused-vars
    const mySeries = details => {
        let temp
        if (details.length === 0) {
            temp = []
        } else {
            temp = details.map(item => ({
                name: item.product,
                type: myChartType,
                data: item.timeStep.map(item => item[yAxisValueName])
                // data: [120, 132, 101, 134, 90, 230, 210]
            }))

            let total
            if (localStorage.getItem('legendObject') === null) {
                total = calculateTotal(details)
            } else if (details.filter(item => legends.includes(item.product))[0]) {
                const detailArr = details.filter(item => legends.includes(item.product))
                total = calculateTotal(detailArr)
            } else {
                total = details[0] ? new Array(details[0].timeStep.length).fill(0) : []
            }
            temp.push({
                name: 'total',
                type: myChartType,
                data: total,
                label: {
                    show: true,
                    color: '#5A5A5A',
                    fontWeight: 'bold',
                    position: 'top'
                }
            })

            // push pie element in the array to make pie chart
            temp.push({
                type: 'pie',
                id: 'pie',
                radius: '20%',
                center: ['40%', '30%'],
                emphasis: {
                    focus: 'self'
                },
                label: {
                    formatter: '{b}: {@2023} ({d}%)'
                },
                encode: {
                    itemName: 'product',
                    value: details.map(item => item.timeStep.map(item => item[yAxisValueName])),
                    tooltip: '2023'
                },
                data: details.map(item => ({
                    value: item.timeStep.map(item => item[yAxisValueName]),
                    name: item.product
                }))
            })
        }
        return temp
    }

    // calculate the sum data for pie Chart
    const newDetail = details.map(item => {
        const timeStepValues = item.timeStep.map(item => item[yAxisValueName])
        const sum = timeStepValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        return {
            name: item.product,
            value: sum
        }
    })

    if (myChartType === 'pie') {
        return {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                selected: legendObject
            },
            toolbox: {
                feature: {
                    saveAsImage: {},
                    magicType: {
                        type: ['line', 'bar']
                    },
                    myPieButton: {
                        show: true,
                        title: 'Switch to Pie Chart',
                        icon: svgPie,
                        onclick: () => {
                            setIsPieChart(true)
                        }
                    },
                    triggerEvent: true
                },
                top: '20%',
                orient: 'vertical'
            },
            label: {
                formatter: '{b}: {@2023} ({d}%)'
            },
            series: {
                // name: newDetail.map(item=>item.product),
                type: myChartType,
                data: newDetail,
                radius: '50%',
                center: ['50%', '50%'],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        }
    }

    return {
        // title: {
        //   top: '3%',
        //   left: '1%',
        //   text: diagramTitle
        // },
        animationEasing: 'quarticOut',
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            // top: '4%',
            data: details.map(item => item.product),
            selected: legendObject
            // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        },
        // grid: {
        //     top: '10%',
        //     bottom: '10%',
        //     containLabel: true
        // },
        grid: {top: '50%'},
        toolbox: {
            feature: {
                saveAsImage: {},
                magicType: {
                    type: ['line', 'bar']
                },
                myPieButton: {
                    show: true,
                    title: 'Switch to Pie Chart',
                    icon: svgPie,
                    onclick: () => {
                        // console.log('change to pie')
                        setIsPieChart(true)
                    }
                },
                triggerEvent: true
            },
            top: '20%',
            orient: 'vertical'
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: timeSpan,
            axisTick: {
                alignWithLabel: true
            }
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: mySeries(details)
    }
}

export default BIStackedLineDiagramOptions
