import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {Button, CardMedia, Grid, Modal, Paper, Tooltip, Typography} from '@mui/material'
import {Edit as EditIcon} from 'react-feather'
import IconButton from '@mui/material/IconButton'
import {useNavigate} from 'react-router-dom'
import {Functions} from '../../../utils/Functions'
import ViewHead from '../../../components/public/ViewHead'
import {deleteCampaignPoster, fetchCampaign, sendSMS} from '../../../actions/campaign/campaignActions'
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'
import useAuthCheck from '../../../hooks/useAuthCheck'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import {extraPostInfoModal} from './poster/extraPostInfoModal'
import {getTotalHeight, getTotalWidth} from '../../../utils/Scripts'
import {renderUsersTable} from './campaignPosterTables'
import {SMSPhoneChecker} from '../../../utils/FormValidator'
import {useCampaignDetailsStyles} from '../campaignHelper'
import CampaignPosterTable from './CampaignPosterTable'

export const DEFAULT_WIDTH = '768'
export const DEFAULT_SIZE = '500'

function CampaignDataView({campaignId}) {
    useCurrentViewCheck(Functions.Campaign_Details)
    const navigate = useNavigate()
    const [canEditCampaign] = useAuthCheck([Functions.Campaign_Edit])

    const urlId = parseInt(useParams().id, 10)
    const classes = useCampaignDetailsStyles()
    const dispatch = useDispatch()

    const auth = useSelector(state => state?.auth)
    const storageCampaign = useSelector(state => state?.campaign.currentCampaign)
    const posterFetchProps = useSelector(state => state?.campaign.posterFetchProps)

    const [currentUserId, setCurrentUserId] = useState(-1)
    const [currentCampaign, setCurrentCampaign] = useState({})
    const [campaignUserMapping, setCampaignUserMapping] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)

    const [hash, setHash] = useState('init')
    const [smsDialogueHash, setSMSDialogueHash] = useState('init')
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
    const [openSMSDialogue, setOpenSMSDialogue] = useState(false)
    const [selectedForDel, setSelectedForDel] = useState(-1)
    const [selectedForSMS, setSelectedForSMS] = useState(-1)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalInfo, setModalInfo] = useState(<div />)
    const [imageModalOpen, setImageModal] = useState(false)
    const [imageStyle, setImageStyle] = useState({})
    const [imageUrl, setImageUrl] = useState('')

    const isSmallUserView = getTotalHeight() < 800
    const isSmallPosterView = getTotalHeight() < 1200

    const loadData = () => {
        ;(async () => {
            setIsLoaded(await fetchCampaign(urlId || campaignId)(dispatch))
        })()
    }
    useEffect(loadData, [urlId, campaignId, dispatch])

    useEffect(() => {
        if (storageCampaign && urlId ? urlId : campaignId === storageCampaign.id) {
            // Locate the pointed user
            let isUserInCampaign = false
            const tempUserList = []
            const tempUserMapping = {}
            // const tempPosterList = []
            storageCampaign?.users?.forEach(tempUser => {
                tempUserList.push({
                    ...tempUser,
                    isPointed: tempUser.id === storageCampaign.pointedUser.id
                })
                tempUserMapping[tempUser.id] = {firstName: tempUser.firstName, lastName: tempUser.lastName}

                // Test if user is in campaign
                if (tempUser.id === currentUserId) {
                    isUserInCampaign = true
                }
            })

            setCampaignUserMapping(tempUserMapping)
            setCurrentCampaign({...storageCampaign, users: tempUserList, isUserInCampaign})
            setIsLoaded(true)
        }
    }, [storageCampaign, campaignId])

    useEffect(() => {
        if (auth && auth.user) {
            setCurrentUserId(auth.user.id)
        }
    }, [auth])

    const handleSendText = isOnDebug => {
        // Check users
        const userPhoneList = currentCampaign.users.reduce((res, usr) => {
            let tempPhone = usr.phone
            if (usr.phone?.length === 10) {
                tempPhone = `+1${usr.phone}`
            } else if (usr.phone?.length === 11) {
                tempPhone = `+86${usr.phone}`
            }

            if (usr.id !== 1) {
                // Do not send SMS to Mark
                res.push({
                    name: `${usr.firstName} ${usr.lastName}`,
                    phone: tempPhone,
                    id: usr.id
                })
            }
            return res
        }, [])

        let errUsr = {usr: 'No one', phone: 'N/A'}
        const isSMSPass = userPhoneList.every(usr => {
            const check = SMSPhoneChecker(usr.phone)
            if (!check) {
                errUsr = usr
            }
            return check
        })

        if (!isSMSPass) {
            return sendErrorMessage(dispatch, `User: ${errUsr.name} contains invalid phone: "${errUsr.phone}"`)
        }

        const message = `[ITLabPro]A Poster in Campaign: "${currentCampaign.name}" has been issued. Please download it at: ${window.location.href}`
        return sendSMS(
            {pid: selectedForSMS, users: userPhoneList.map(usr => usr.id), message, isOnDebug},
            campaignId,
            msg => {
                sendSuccessMessage(dispatch, msg)
                setSelectedForSMS(-1)
            },
            msg => sendErrorMessage(dispatch, msg)
        )(dispatch)
    }

    const handleDeleteOpen = id => {
        setSelectedForDel(id)
        setOpenDeleteDialogue(true)
        setHash(Math.random().toString(16).substring(2, 10))
    }

    const handleSMSOpen = id => {
        setSelectedForSMS(id)
        setOpenSMSDialogue(true)
        setSMSDialogueHash(Math.random().toString(16).substring(2, 10))
    }

    const onDeletePoster = () => {
        deleteCampaignPoster(
            campaignId,
            selectedForDel,
            posterFetchProps,
            msg => {
                setSelectedForDel(-1)
                sendSuccessMessage(dispatch, msg)
            },
            msg => sendErrorMessage(dispatch, msg)
        )(dispatch)
    }

    useEffect(() => {
        const height = getTotalHeight()
        const width = getTotalWidth()
        if (imageUrl !== '') {
            const url = imageUrl
            const newImage = new Image()
            newImage.src = url
            newImage.onload = () => {
                const scala = (newImage.naturalWidth / newImage.naturalHeight).toFixed(2)
                if (scala > 1) {
                    const heightNew = ((width * 0.8) / scala).toFixed(2)
                    const widthNew = (width * 0.8).toFixed(2)
                    const style = {height: `${heightNew}px`, width: `${widthNew}px`}
                    setImageStyle(style)
                } else if (width < 800) {
                    const heightNew = (height * 0.8).toFixed(2)
                    const widthNew = (height * 0.8 * scala).toFixed(2)
                    const style = {height: `${heightNew}px`, width: `${widthNew}px`}
                    setImageStyle(style)
                } else {
                    const heightNew = (height * 0.7).toFixed(2)
                    const widthNew = (height * 0.7 * scala).toFixed(2)
                    const style = {height: `${heightNew}px`, width: `${widthNew}px`}
                    setImageStyle(style)
                }
                setImageModal(true)
            }
        }
    }, [imageUrl])

    const handleModalOpen = (userDownloaded, userNotDownloaded, userTracks) => {
        setModalInfo(
            extraPostInfoModal({
                allUsers: campaignUserMapping,
                userDownloaded,
                userNotDownloaded,
                userTracks
            })
        )
        setModalOpen(true)
    }

    const handlePosterClick = url => {
        if (url === imageUrl) {
            setImageModal(true)
        } else {
            setImageUrl(url)
        }
    }

    const handleModalClose = () => setModalOpen(false)
    const handleImageModalClose = () => setImageModal(false)

    const renderReturnButton = () => (
        <div className={classes.marginBottom}>
            <Button color="secondary" variant="contained" onClick={() => navigate('/app/campaign/')}>
                Back To List
            </Button>
        </div>
    )

    return (
        <div>
            {urlId ? (
                <ViewHead functionId={Functions.Campaign_Details} noMaxWidth isShowTitle={false}>
                    {isLoaded ? (
                        <div>
                            <Grid container direction="row" className={classes.marginBottom} spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" color="textPrimary" className={classes.pageTitle}>
                                        {currentCampaign.name}
                                        <span className={classes.pageStatus}>
                                            [
                                            {currentCampaign.status === 'P'
                                                ? 'Pointed'
                                                : currentCampaign.status === 'S'
                                                ? 'Scheduled'
                                                : 'Random'}
                                            ]
                                        </span>
                                    </Typography>

                                    {canEditCampaign ? (
                                        <Tooltip title="Edit" placement="top">
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                size="small"
                                                className={classes.editButton}
                                                onClick={() => navigate(`/app/campaign/edit/${currentCampaign.id}`)}
                                            >
                                                <EditIcon size={23} />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <div />
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    {renderReturnButton()}
                                </Grid>
                            </Grid>

                            {renderUsersTable(isSmallUserView, currentCampaign.users, isLoaded)}
                            <CampaignPosterTable
                                campaignId={urlId || campaignId}
                                currentUserId={currentUserId}
                                isSmallPosterView={isSmallPosterView}
                                isUserInCampaign={currentCampaign.isUserInCampaign}
                                handleDeleteOpen={handleDeleteOpen}
                                handleModalOpen={handleModalOpen}
                                handleSMSOpen={handleSMSOpen}
                                handlePosterClick={handlePosterClick}
                            />
                        </div>
                    ) : (
                        <Typography variant="h2" color="textPrimary">
                            Loading
                        </Typography>
                    )}
                    <ResponsiveDialog
                        isOpen={openDeleteDialogue}
                        openHash={hash}
                        title="Delete Poster"
                        content={`Delete Poster #${selectedForDel}?`}
                        Buttons={[
                            {name: 'Yes', event: () => onDeletePoster()},
                            {name: 'Cancel', event: () => setSelectedForDel(-1)}
                        ]}
                    />
                    <ResponsiveDialog
                        isOpen={openSMSDialogue}
                        openHash={smsDialogueHash}
                        title="Send SMS"
                        content={`Do you want to send Poster ${selectedForSMS} to everyone in the Campaign? Please use "Send Test SMS" for testing as it will not be sent to the actual phone numbers.`}
                        Buttons={[
                            {name: 'Send Test SMS', event: () => handleSendText(true)},
                            {name: 'Send Real SMS', event: () => handleSendText(false)},
                            {name: 'Cancel', event: () => setSelectedForSMS(-1)}
                        ]}
                    />

                    <Modal
                        open={modalOpen}
                        onClose={handleModalClose}
                        className={classes.modal}
                        disablePortal
                        disableRestoreFocus
                        disableEnforceFocus
                        disableAutoFocus
                        closeAfterTransition
                    >
                        <Paper>{modalInfo}</Paper>
                    </Modal>
                    <Modal
                        open={imageModalOpen}
                        disablePortal
                        disableRestoreFocus
                        disableEnforceFocus
                        disableAutoFocus
                        onClose={handleImageModalClose}
                        closeAfterTransition
                        className={classes.modal}
                    >
                        <Paper>
                            <CardMedia image={imageUrl} style={imageStyle} onClick={handleImageModalClose} />
                        </Paper>
                    </Modal>
                </ViewHead>
            ) : (
                <div>
                    {isLoaded ? (
                        <div>
                            <Grid container direction="row" className={classes.marginBottom} spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" color="textPrimary" className={classes.pageTitle}>
                                        {currentCampaign.name}
                                        <span className={classes.pageStatus}>
                                            [
                                            {currentCampaign.status === 'P'
                                                ? 'Pointed'
                                                : currentCampaign.status === 'S'
                                                ? 'Scheduled'
                                                : 'Random'}
                                            ]
                                        </span>
                                    </Typography>

                                    {canEditCampaign ? (
                                        <Tooltip title="Edit" placement="top">
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                size="small"
                                                className={classes.editButton}
                                                onClick={() => navigate(`/app/campaign/edit/${currentCampaign.id}`)}
                                            >
                                                <EditIcon size={23} />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <div />
                                    )}
                                </Grid>

                                {/* <Grid item xs={12}> */}
                                {/*    {renderReturnButton()} */}
                                {/* </Grid> */}
                            </Grid>

                            {renderUsersTable(isSmallUserView, currentCampaign.users, isLoaded)}
                            <CampaignPosterTable
                                campaignId={urlId || campaignId}
                                currentUserId={currentUserId}
                                isSmallPosterView={isSmallPosterView}
                                isUserInCampaign={currentCampaign.isUserInCampaign}
                                handleDeleteOpen={handleDeleteOpen}
                                handleModalOpen={handleModalOpen}
                                handleSMSOpen={handleSMSOpen}
                                handlePosterClick={handlePosterClick}
                            />
                        </div>
                    ) : (
                        <Typography variant="h2" color="textPrimary">
                            Loading
                        </Typography>
                    )}
                    <ResponsiveDialog
                        isOpen={openDeleteDialogue}
                        openHash={hash}
                        title="Delete Poster"
                        content={`Delete Poster #${selectedForDel}?`}
                        Buttons={[
                            {name: 'Yes', event: () => onDeletePoster()},
                            {name: 'Cancel', event: () => setSelectedForDel(-1)}
                        ]}
                    />
                    <ResponsiveDialog
                        isOpen={openSMSDialogue}
                        openHash={smsDialogueHash}
                        title="Send SMS"
                        content={`Do you want to send Poster ${selectedForSMS} to everyone in the Campaign? Please use "Send Test SMS" for testing as it will not be sent to the actual phone numbers.`}
                        Buttons={[
                            {name: 'Send Test SMS', event: () => handleSendText(true)},
                            {name: 'Send Real SMS', event: () => handleSendText(false)},
                            {name: 'Cancel', event: () => setSelectedForSMS(-1)}
                        ]}
                    />

                    <Modal
                        open={modalOpen}
                        onClose={handleModalClose}
                        className={classes.modal}
                        disablePortal
                        disableRestoreFocus
                        disableEnforceFocus
                        disableAutoFocus
                        closeAfterTransition
                    >
                        <Paper>{modalInfo}</Paper>
                    </Modal>
                    <Modal
                        open={imageModalOpen}
                        disablePortal
                        disableRestoreFocus
                        disableEnforceFocus
                        disableAutoFocus
                        onClose={handleImageModalClose}
                        closeAfterTransition
                        className={classes.modal}
                    >
                        <Paper>
                            <CardMedia image={imageUrl} style={imageStyle} onClick={handleImageModalClose} />
                        </Paper>
                    </Modal>
                </div>
            )}
        </div>
    )
}

export default CampaignDataView
