import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {uniqBy} from 'lodash'
import {Button, Card, Grid, Typography} from '@mui/material'
import Paper from '@mui/material/Paper'
import {ViewState, EditingState} from '@devexpress/dx-react-scheduler'
import {
    Scheduler,
    WeekView,
    MonthView,
    Appointments,
    ViewSwitcher,
    Toolbar,
    DragDropProvider,
    EditRecurrenceMenu,
    AppointmentTooltip
} from '@devexpress/dx-react-scheduler-material-ui'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck'
import {Functions} from '../../../../utils/Functions'
import {fetchAllInterviews, updateInterview} from '../../../../actions/customer/interviewActions'
import ViewHead from '../../../../components/public/ViewHead'
import LoadingScreen from '../../../../components/public/LoadingScreen'
import {getColorForGraph} from '../../../../utils/Scripts'
import {sendErrorMessage, sendSuccessMessage} from '../../../../actions'
import ResponsiveDialog from '../../../../components/dialog/ResponsiveDialog'
import {INTERVIEW_STATUS, listColor, useInterviewScheduleStyles, listTime, today} from '../interviewHelper'
import UserAvatar from '../../../../components/user/UserAvatar'
import useAuthCheck from '../../../../hooks/useAuthCheck'

function InterviewPlannerView() {
    useCurrentViewCheck(Functions.Interview_List_Schedule)
    const navigate = useNavigate()
    const [canEditInterview] = useAuthCheck([Functions.Interview_Edit])
    const currentLogin = useSelector(state => state.auth)
    const classes = useInterviewScheduleStyles()
    const dispatch = useDispatch()
    const allInterviews = useSelector(state => state?.interview?.allInterviews?.data)
    // const isSmallUserView = getTotalHeight() < 800

    const [isLoaded, setIsLoaded] = useState(false)
    const [hash, setHash] = useState('init')
    const [updateObject, setUpdateObject] = useState([])
    const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false)
    const [interviewList, setInterviewList] = useState([])
    const [interviewerList, setInterviewerList] = useState([])
    const [interviewFilterList, setInterviewFilterList] = useState([])
    const [interviewerFilterList, setInterviewerFilterList] = useState({})
    const [colorGraph, setColorGraph] = useState([])
    const [localDate, setLocalDate] = useState({})
    const [schedulerView, setSchedulerView] = useState({})
    const [colorStatus, setColorStatus] = useState([])
    const scheduleRef = useRef(null)

    const loadData = () => {
        if (!isLoaded) {
            ;(async () => setIsLoaded(await fetchAllInterviews()(dispatch)))()
        }
    }
    useEffect(loadData, [])

    useEffect(() => {
        if (isLoaded) {
            const tempInterviewerList = {}
            // Get all interviewer
            const allInterviewerList = uniqBy(
                allInterviews.map(iv => iv.interviewer),
                'id'
            )
            setInterviewerList(allInterviewerList)
            if (Object.keys(interviewerFilterList).length === 0) {
                allInterviewerList?.forEach(iv => {
                    tempInterviewerList[iv.id] = true
                })
                const colorMapIndex = getColorForGraph(allInterviewerList.length)
                setColorGraph(allInterviewerList.map((it, idx) => ({interviewerId: it.id, color: colorMapIndex[idx]})))
                setColorStatus(
                    allInterviews.map(iv => {
                        switch (iv.status) {
                            case INTERVIEW_STATUS.PENDING:
                                return {id: iv.id, color: listColor.P}
                            case INTERVIEW_STATUS.ON_TIME:
                                return {id: iv.id, color: listColor.OT}
                            case INTERVIEW_STATUS.LATE:
                                return {id: iv.id, color: listColor.L}
                            case INTERVIEW_STATUS.ABSENT:
                                return {id: iv.id, color: listColor.ABS}
                            case INTERVIEW_STATUS.CANCELLED:
                                return {id: iv.id, color: listColor.CXL}
                            default:
                                return {id: iv.id, color: 'lightBlue'}
                        }
                    })
                )
                setInterviewerFilterList(tempInterviewerList)
            }

            // Get interview data ready for scheduler
            setInterviewList(
                allInterviews.map(interview => ({
                    ...interview,
                    scheduledStartTime: new Date(interview.scheduledStartTime),
                    scheduledEndTime: new Date(interview.scheduledEndTime),
                    interviewerId: interview.interviewer.id,
                    title: `[${interview.status}] ${interview.product.name} - ${interview.customer.firstName} ${interview.customer.lastName}`
                }))
            )
            setInterviewFilterList(allInterviews.filter(iv => moment(iv.scheduledStartTime).isSame(today, 'week')))
        }
    }, [isLoaded, allInterviews])

    const onSuccess = msg => sendSuccessMessage(dispatch, msg)
    const onError = msg => sendErrorMessage(dispatch, msg)

    const onConfirmModalOpen = ({updated}) => {
        if (updated.length > 0) {
            setUpdateObject(updated)
            setOpenConfirmDialogue(true)
            setHash(Math.random().toString(16).substring(2, 10))
        }
    }

    const onConfirmModalClose = () => {
        setUpdateObject([])
        setOpenConfirmDialogue(false)
    }

    const handleDateChange = () => {
        if (canEditInterview && updateObject?.length > 0) {
            // update date
            updateObject.map(async item =>
                updateInterview(
                    {
                        id: item.id,
                        scheduledStartTime: item.scheduledStartTime.toISOString(),
                        scheduledEndTime: item.scheduledEndTime.toISOString()
                    },
                    onSuccess,
                    onError
                )(dispatch)
            )
        }
    }

    const onInterviewerClick = id =>
        setInterviewerFilterList({...interviewerFilterList, [id]: !interviewerFilterList[id]})

    useEffect(() => {
        setInterviewFilterList(() => {
            let currentDay
            if (localDate) {
                currentDay = moment(localDate._localDate)
            } else currentDay = today
            let tempList = {}
            switch (schedulerView) {
                case listTime.D:
                    tempList = interviewList.filter(iv => moment(iv.scheduledStartTime).isSame(currentDay, listTime.D))
                    return tempList
                case listTime.WW:
                    tempList = interviewList.filter(iv => moment(iv.scheduledStartTime).isSame(currentDay, listTime.W))
                    tempList = tempList.filter(
                        iv => iv.scheduledStartTime.toString().substring(0, 3) !== ('Sat' || 'Sun')
                    )
                    return tempList
                case listTime.M:
                    tempList = interviewList.filter(iv => moment(iv.scheduledStartTime).isSame(currentDay, listTime.M))
                    return tempList
                default:
                    tempList = interviewList.filter(iv => moment(iv.scheduledStartTime).isSame(currentDay, listTime.W))
                    return tempList
            }
        })
    }, [localDate, schedulerView])

    useEffect(() => {
        if (isLoaded) {
            const currentList = allInterviews?.filter(
                interview => interview?.interviewer?.id === currentLogin?.authUser?.id
            )
            let hour = currentList?.filter(value => moment(value.scheduledStartTime).isAfter(moment()))
            hour = hour.map(value => parseInt(moment(value.scheduledStartTime).format('YYYYMMDDHH').slice(4, 10), 10))
            let result = Math.min(...hour)
            result = result.toString().slice(-2)
            if (scheduleRef?.current) {
                try {
                    // Get all the time and their elements
                    const scheduler = scheduleRef.current.element
                    const contentDiv = scheduler?.querySelector('.k-scheduler-body')
                    const rows = contentDiv?.querySelectorAll('.k-scheduler-row div')
                    const timeRows = []
                    rows?.forEach(row => {
                        if (row.hasAttribute('date')) {
                            timeRows.push(row)
                        }
                    })

                    // look for the specific time and scroll to it
                    timeRows?.forEach(timeCell => {
                        // find by attribute date
                        const currentElTime = new Date(timeCell.getAttribute('date')).getHours()
                        if (currentElTime === parseInt(result, 10) - 1) {
                            timeCell.scrollIntoView({block: 'start', behavior: 'smooth'})
                        }
                    })
                } catch (e) {
                    sendErrorMessage(dispatch, `Scroll to time error: ${e.message}`)
                }
            }
        }
    }, [isLoaded, allInterviews])

    const renderInterviewerPicker = () => (
        <Grid container className={classes.cardContainer} spacing={3}>
            {interviewerList.map(interviewer => {
                const isAvatar = !!interviewList.filter(iv => iv.interviewerId === interviewer.id)[0].interviewer.avatar
                const pInterviews = interviewFilterList.filter(
                    iv => iv.interviewer.id === interviewer.id && iv.status === 'P'
                ).length
                const allInterviewsInner = interviewFilterList.filter(iv => iv.interviewer.id === interviewer.id).length
                const interviewerColor = colorGraph.find(({interviewerId}) => interviewerId === interviewer.id).color
                return (
                    <Grid item xs={12} sm={6} md={3} xl={2} key={interviewer.id}>
                        <div
                            aria-hidden="true"
                            onClick={() => onInterviewerClick(interviewer.id)}
                            style={!interviewerFilterList[interviewer.id] ? {opacity: 0.5} : {}}
                        >
                            <Card className="card">
                                <Grid container direction="row" alignItems="center" wrap="nowrap">
                                    <Grid item>
                                        <UserAvatar
                                            alt={interviewer.firstName}
                                            uid={interviewer.uid}
                                            avatar={isAvatar}
                                            style={{
                                                backgroundColor: interviewerColor,
                                                border: `${interviewerColor} 2px solid`,
                                                marginRight: '.5rem'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{color: interviewerColor}}>
                                            {`${interviewer.firstName} ${interviewer.lastName}(${pInterviews}/${allInterviewsInner})`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </div>
                    </Grid>
                )
            })}
        </Grid>
    )

    const dataBd = interviewList.filter(event => interviewerFilterList[event.interviewer.id])
    const appointments = dataBd.map(dataItem => ({
        ...dataItem,
        startDate: new Date(dataItem.scheduledStartTime),
        endDate: new Date(dataItem.scheduledEndTime),
        title: dataItem.title,
        id: dataItem.id
    }))

    const handleAppointmentClick = appointment => {
        const {customer, id} = appointment
        const url = `/app/customer/interview/review/${customer.uid}/${id}`
        navigate(url)
    }

    const renderCalendarView = () => (
        <Paper>
            <Scheduler
                data={appointments}
                resources={[
                    {
                        data: colorStatus,
                        field: 'id',
                        valueField: 'id',
                        colorField: 'color'
                    }
                ]}
            >
                <ViewState
                    currentDate={localDate}
                    onCurrentDateChange={date => {
                        onConfirmModalOpen()
                        setLocalDate(date)
                    }}
                />
                <EditingState
                    onCommitChanges={changes => {
                        setSchedulerView(changes.value)
                    }}
                />
                <WeekView startDayHour={8} endDayHour={24} />
                <MonthView />
                <Appointments
                    appointmentComponent={props => (
                        <Appointments.Appointment {...props} onClick={() => handleAppointmentClick(props.data)} />
                    )}
                />
                <Toolbar />
                <ViewSwitcher />
                <EditRecurrenceMenu />
                <DragDropProvider />
                <AppointmentTooltip showOpenButton />
            </Scheduler>
        </Paper>
    )

    const renderDisplay = () => (
        <div>
            {renderInterviewerPicker()}
            {renderCalendarView()}
            <ResponsiveDialog
                isOpen={openConfirmDialogue}
                openHash={hash}
                title="Update Schedule"
                content={
                    canEditInterview ? 'Are you sure you want to update the schedule?' : 'You cannot edit interviews'
                }
                Buttons={[
                    {name: 'Confirm', event: () => handleDateChange()},
                    {name: 'Cancel', event: () => onConfirmModalClose()}
                ]}
            />
        </div>
    )

    const renderTopButtons = () => (
        <div>
            <Button
                color="secondary"
                variant="contained"
                style={{marginRight: '1rem'}}
                onClick={() => navigate('/app/customer/interview/add')}
            >
                Add Interview
            </Button>
            <Button color="secondary" variant="contained" onClick={() => navigate('/app/customer/interview')}>
                List
            </Button>
        </div>
    )

    return (
        <ViewHead functionId={Functions.Interview_List_Schedule} noMaxWidth topButtons={renderTopButtons()}>
            {isLoaded ? (
                <Paper className={classes.paper}>{renderDisplay()}</Paper>
            ) : (
                <LoadingScreen message="Loading..." />
            )}
        </ViewHead>
    )
}

export default InterviewPlannerView
