import React, {useRef, useState} from 'react';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Popover,
    SvgIcon,
    Tooltip,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Settings as SettingsIcon} from 'react-feather';
import {useDispatch} from "react-redux";
import DarkModeSwitch from "../../../components/public/DarkModeSwitch";
import LocalStorage from "../../../utils/LocalStorage";
import {LocalStorageItems} from "../../../constants";
import LanguageSelect from "../../../components/public/LanguageSelect";
import {UserSettingActionTypes} from "../../../actions";

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 320,
        padding: theme.spacing(2)
    }
}));

function Settings() {
    const classes = useStyles();
    const ref = useRef(null);
    const [isAutoSwitch, setIsAutoSwitch] = useState(
        () => LocalStorage.getObjectItem(LocalStorageItems.UserSetting)?.autoSwitchBackgroundMode || true
    );
    const [isOpen, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeAutoSwitch = () => {
        setIsAutoSwitch(!isAutoSwitch)
        dispatch({type:UserSettingActionTypes.ChangeAutoSwitch,payload:!isAutoSwitch})
    }
    return <div>
        <Tooltip title="Settings">
            <IconButton color="inherit" onClick={handleOpen} ref={ref} size="large">
                <SvgIcon fontSize="small">
                    <SettingsIcon/>
                </SvgIcon>
            </IconButton>
        </Tooltip>
        <Popover anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                 classes={{paper: classes.popover}} anchorEl={ref.current}
                 onClose={handleClose} open={isOpen}>
            <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5" color="textPrimary">
                        Theme
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel control={
                        <Checkbox name="autoSwitch" color="primary" size="small" checked={isAutoSwitch}
                                  onChange={handleChangeAutoSwitch}/>
                    } label={
                        <Typography variant="body2" color="textPrimary">
                            Auto Switch
                        </Typography>
                    }/>
                </Grid>
                <Grid item>
                    <DarkModeSwitch disabled={isAutoSwitch}/>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                <Grid item>
                    <Typography variant="h5" color="textPrimary">
                        Language
                    </Typography>
                </Grid>
                <Grid item>
                    <LanguageSelect/>
                </Grid>
                <Grid item>
                    &nbsp;
                </Grid>
            </Grid>

        </Popover>
    </div>;
}

export default Settings;
