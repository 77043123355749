import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {Clipboard} from "react-feather";
import LoadingScreen from '../../../components/public/LoadingScreen';
import EmployeeQRCode from './EmployeeQRCode';
import {fetchEmployee} from '../../../actions';
import TextLine from '../../personal/info/TextLine';
import { copyToClipboard } from '../../../utils/Scripts';
import { copyBoardStyle } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '2rem'
  },
  infoContainer: {
    marginTop: '1rem'
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  WechatTitle: {
    [theme.breakpoints.down('xl')]: {
      transform: 'translateY(28px)'
    }
  },
  info: {
    marginTop: '1rem'
  },
  label__styles: {
    padding: '20px 10px'
  },
  copyButton:{
      paddingBottom:'5px',
      color:'#ebebeb',
  }
}));

function EmployeeDetails() {

  const dispatch = useDispatch();
  const classes = useStyles();
  const employeeInfo = useSelector(state => state.employee.currentEmployee)
  const [employeeHours, setEmployeeHours] = useState([])
  const id = useSelector(state => state.employee.currentEditEmployee)
  const [isLoaded, setIsLoaded] = useState(false)


  const loadData = () => {
    fetchEmployee(id)(dispatch)
  }
  useEffect(loadData, [])

  // useEffect(()=>{
  //   console.log('this is employee',employeeInfo)
  // },[employeeInfo])


  useEffect(() => {
    if (employeeInfo?.id === id) {
      setEmployeeHours(employeeInfo?.staffInfo?.serviceTime ? JSON.parse(employeeInfo?.staffInfo?.serviceTime) : [])
      setIsLoaded(true)
    }
  }, [employeeInfo])

  const onCopy=()=>{
     const {firstName,lastName,address,city,country,zipcode,email,wechat}=employeeInfo
     const obj={firstName,lastName,address,city,country,zipcode,email,wechat}
     const keys = Object.keys(obj)
     let str=''
     keys.forEach(value => {
       str=`${str}${capitalizeFirstLetter(value)}: ${obj[value]}\n`
     })
    copyToClipboard(dispatch, str, `Information added to clipboard`, `Information cannot be copied `)
  }

  // const onCopyTarget=(targetName)=>{
  //   if(employeeInfo){
  //     copyToClipboard(dispatch ,employeeInfo[targetName])
  //   }
  // }

  const capitalizeFirstLetter=(string)=> string.charAt(0).toUpperCase() + string.slice(1)


  const renderGrid = () =>
    <Grid container direction='row' justifyContent='space-between' spacing={4}>

      <Grid item xs={12}>
        <Typography variant="h2" align="center">
          Employee Info
          { '     '}
          <Tooltip title='Copy Form Value' placement='top' >
            <IconButton color='primary'
                        size='small'
                        component='span'
                        onClick={() =>onCopy()}
            >
              <Clipboard {...copyBoardStyle}/>
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Grid container direction='column' spacing={3} className={classes.infoContainer}>
          <Grid item>
            <TextLine title='Name' value={`${employeeInfo.firstName} ${employeeInfo.lastName} ${employeeInfo.nickName && `(${employeeInfo.nickName})`}`} />
          </Grid>
          <Grid item>
            <TextLine title='Wechat' value={`${employeeInfo.wechat}`} />
          </Grid>
          <Grid item>
            <TextLine title='Email' value={`${employeeInfo.email}`} icon/>
          </Grid>
          <Grid item>
            <TextLine title='Phone' value={`${employeeInfo.phone}`} icon />
          </Grid>
          <Grid item>
            <TextLine title='Address' value={`${employeeInfo.address ? `${employeeInfo.address  },` : ''}
                    ${employeeInfo.city ? `${employeeInfo.city  },` : ''}
                    ${employeeInfo.zipcode}`} />
          </Grid>
          <Grid item>
            <TextLine title='Country' value={`${employeeInfo?.country}`} />
          </Grid>


          <Grid item>

            <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="baseline">
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" color="textSecondary">
                  Service Time
                </Typography>
              </Grid>
              <Grid item xs={10} sm={5}>
                <div>
                  {employeeHours.map((time,index) =>
                    <Typography variant="body2" color="textPrimary" key={index}>
                      {` ${moment(time.day, "d").format("dddd")} @ ${time.startTime} - ${time.endTime} `}
                    </Typography>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <Divider/>
              </Grid>
            </Grid>

          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <EmployeeQRCode employeeInfo={employeeInfo} />
      </Grid>

    </Grid>


  return (
      <Paper className={classes.paper}>
        { isLoaded
          ? renderGrid()
          : <LoadingScreen message='Loading...' />
        }
      </Paper>
  )
}

export default EmployeeDetails
