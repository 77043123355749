import { Button, Divider, Grid, Link, Paper, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ViewHead from '../../../../components/public/ViewHead';
import { Functions } from '../../../../utils/Functions';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { usePosterTemplateDisplayStyles } from '../posterGenHelper';
import { fetchPosterFile, fetchPosterTemplate } from '../../../../actions/forms/posterMakerAction';
import NotFound from '../../../../components/public/NotFound';
import LoadingScreen from '../../../../components/public/LoadingScreen';
import { copyToClipboard } from '../../../../utils/Scripts';
import TextLine from '../../../personal/info/TextLine';

function PosterMakerView({rowId, isPopUp}) {
  useCurrentViewCheck(Functions.Forms_Poster_Generator_View)
  const  navigate = useNavigate();
  const {id} = useParams()
  const dispatch = useDispatch()
  const classes = usePosterTemplateDisplayStyles()

  const currentPosterTemplate = useSelector(state => state?.posterMaker.allPosterTemplates)
  const currentPosterFile = useSelector(state => state?.posterMaker.currentPosterFile)

  const [hasError, setHasError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPosterLoaded, setIsPosterLoaded] = useState(null)
  const [isPosterTemplateLoaded, setIsPosterTemplateLoaded] = useState(null)
  const [isFormAvailable, setIsFormAvailable] = useState(false)

  const loadData = () => {
    fetchPosterTemplate(id || rowId, setIsPosterTemplateLoaded)(dispatch)
    fetchPosterFile(id || rowId, setIsPosterLoaded)(dispatch)
  }
  useEffect(loadData, [dispatch, id || rowId])

  useEffect(() => {
    if (isPosterLoaded !== null && isPosterTemplateLoaded !== null) {
      if (isPosterLoaded && isPosterTemplateLoaded) {
        if (currentPosterFile && id === currentPosterTemplate?.id?.toString()) {
          setIsLoaded(true)
          setIsFormAvailable(currentPosterTemplate.form && !currentPosterTemplate.form?.isDelete)
        }
      } else {
        setHasError(true)
        setIsLoaded(true)
      }
    }
  }, [id, currentPosterTemplate, currentPosterFile, isPosterLoaded, isPosterTemplateLoaded])

  const renderView = () =>
    <Paper className={classes.paper} elevation={0}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h3' className={classes.pageTitleText}>{ currentPosterTemplate.name }</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction='row' justifyContent='space-evenly' spacing={3}>
            {/* left */}
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <TextLine title='Description' value={currentPosterTemplate.description?currentPosterTemplate.description:""} />
                </Grid>
                <Grid item xs={12}>
                  <TextLine title='Form' value={
                    isFormAvailable
                      ? <Link
                        target='_blank'
                        className={classes.link}
                        onClick={() => {
                          const win = window.open(`/app/forms/data/${currentPosterTemplate.form?.id}`, '_blank')
                          win.focus()
                        }}>
                        { `${currentPosterTemplate.form?.id}. ${ currentPosterTemplate.form?.name }` }
                      </Link>
                      : <del>{`${currentPosterTemplate.form?.id}. ${ currentPosterTemplate.form?.name }`}</del>
                  } />
                </Grid>
                <Grid item xs={12}>
                  <TextLine title='Scan Link' value={currentPosterTemplate.qrCode || 'N/A'} />
                </Grid>

                <Grid item xs={12}>
                  <TextField variant='outlined' fullWidth size='small'
                             label='Copy Text' className={classes.marginTop}
                             name='copyText' multiline rows={5}
                             helperText='Click to copy'
                             inputProps={{ readOnly: true }}
                             defaultValue={currentPosterTemplate?.copyText}
                             onClick={() => {
                               if (currentPosterTemplate?.copyText && currentPosterTemplate.copyText.length > 0) {
                                 copyToClipboard(dispatch, currentPosterTemplate?.copyText)
                               }
                             }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={6} md={12}>
                  <Button color='primary'
                          fullWidth
                          variant='contained'
                          startIcon={<Edit />}
                          onClick={() => navigate(`/app/forms/poster/edit/${id || rowId}`)}>
                    Edit Template
                  </Button>
                </Grid>

                <Grid item xs={6} md={12}>
                  <Tooltip title={isFormAvailable ? 'Click to start' : 'Current form has been deleted'} placement='bottom'>
                    <span>
                      <Button color='primary'
                              fullWidth
                              disabled={!isFormAvailable}
                              variant='contained'
                              startIcon={<Image />}
                              onClick={() => navigate(`/app/forms/poster/generate/${id || rowId}`)}>
                        Make Posters
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>

              </Grid>
            </Grid>

            {/* right */}
            <Grid item className={classes.gridHeightFix} xs={12} md={7}>
              <div className={classes.previewPoster}>
                <img src={currentPosterFile} alt='preview poster with qr and logo' />
              </div>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Paper>

  const renderBackButton = () =>
    <Button color='secondary'
            variant='contained'
            onClick={() => navigate('/app/forms/poster')}>
      Back to Template
    </Button>

  return (
      <div>
        {
          !isPopUp?
              <ViewHead functionId={Functions.Forms_Poster_Generator_View} topButtons={renderBackButton()}>
                {
                  isLoaded
                      ? hasError
                          ? <NotFound message='Template cannot be loaded' />
                          : renderView()
                      : <LoadingScreen message='Loading...' />
                }
              </ViewHead>
              :
              <div>
                {
                    hasError
                          ? <NotFound message='Template cannot be loaded' />
                          : renderView()
                }
              </div>
        }
  </div>

  )
}

export default PosterMakerView
