import api from '../../apis/api';
import { sendErrorMessage, sendSuccessMessage } from '../message/messageActions';
import { MatricActionType } from './matricAction';


// Params
export const fetchTopUpParams = () => async (dispatch) => {
  try {
    const res = await api.get('/matric/getTopUpParams')
    if (res.data?.rs) {
      dispatch({ type: MatricActionType.FetchTopUpParams, payload: res.data?.data })
      return true
    } 
      sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch params')
    
  } catch (e) {
    sendErrorMessage(dispatch,'Cannot fetch params')
    // console.log('TopUp param fetch failed\n', e)
  }
  return false
}

export const setTopUpParams = (data, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/matric/setTopUpParams', data)
    if (res.data?.rs) {
      await dispatch(fetchTopUpParams())
      return onSuccess(res.data?.message || 'Success')
    } 
      return onError(res.data?.message || 'Cannot set params')
    
  } catch (e) {
    onError('Cannot set params')
    // console.log('TopUp param post failed\n', e)
  }
  return false
}


// Accounts
export const fetchTopUpUser = (site, username) => async (dispatch) => {
  try {
    const res = await api.get(`/matric/getTopUpAccount/${site}/${username}`)
    if (res.data?.rs) {
      dispatch({ type: MatricActionType.FetchTopUpUser, payload: res.data?.data })
      return true
    } 
      sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch user')
    
  } catch (e) {
    sendErrorMessage(dispatch, 'Cannot fetch user')
    // console.log('User fetch failed\n', e)
  }
  return false
}

export const addTopUpUser = (data, onSuccess, onError) => async () => {
  try {
    const res = await api.post(`/matric/addTopUpAccount`, data)
    if (res.data?.rs) {
      return onSuccess(res.data?.message || 'User added')
    } 
      return onError(res.data?.message || 'Cannot add user')
    
  } catch (e) {
    onError('Cannot add user')
    // console.log('User add failed\n', e)
  }
  return onError('Cannot add user')
}

export const editTopUpUser = (data, onSuccess, onError) => async () => {
  try {
    const res = await api.put(`/matric/editTopUpAccount`, data)
    if (res.data?.rs) {
      return onSuccess(res.data?.message || 'User edited')
    } 
      return onError(res.data?.message || 'Cannot edit user')
    
  } catch (e) {
    onError('Cannot edit user')
    // console.log('User edit failed\n', e)
  }
  return onError('Cannot edit user')
}

export const refreshTopUpUser = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/matric/testTopUpAccount`, data)
    if (res.data) {
      await dispatch(fetchAllTopUpUsers())
      if (res.data?.rs){
        return sendSuccessMessage(dispatch, res.data?.message || 'Successfully checked')
      } 
        return sendErrorMessage(dispatch, res.data?.message || 'Cannot check user')
      
    }
  } catch (e) {
    sendErrorMessage(dispatch, 'User check failed')
    // console.log('User check failed\n', e)
  }
  return sendErrorMessage(dispatch, 'Cannot check user')
}

export const deleteTopUpUser = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/matric/deleteTopUpAccount`, data)
    if (res.data?.rs) {
      await dispatch(fetchAllTopUpUsers())
      return sendSuccessMessage(dispatch, res.data?.message || 'User deleted')
    } 
      return sendErrorMessage(dispatch, res.data?.message || 'Cannot delete user')
    
  } catch (e) {
      sendErrorMessage(dispatch, 'Cannot delete user')
    // console.log('User delete failed\n', e)
  }
  return false
}

export const fetchAllTopUpUsers = () => async (dispatch) => {
  try {
    const res = await api.get('/matric/getTopUpAccounts')
    if (res.data?.rs) {
      dispatch({ type: MatricActionType.FetchAllTopUpUsers, payload: res.data?.data })
      return true
    } 
      sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch users')
    
  } catch (e) {
    sendErrorMessage(dispatch, 'User fetch failed')
    // console.log('User fetch failed\n', e)
  }
  return false
}


// Posts
export const fetchTopUpPost = (site, id) => async (dispatch) => {
  try {
    const res = await api.get(`/matric/getTopUpPost/${site}/${id}`)
    if (res.data?.rs) {
      dispatch({ type: MatricActionType.FetchTopUpPost, payload: res.data?.data })
      return true
    } 
      sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch top up post')
    
  } catch (e) {
    sendErrorMessage(dispatch, 'Top up post fetch failed')

    // console.log('Top up post fetch failed\n', e)
  }
  return false
}

export const fetchAllTopUpPosts = () => async (dispatch) => {
  try {
    const res = await api.get('/matric/getTopUpPosts')
    if (res.data?.rs) {
      dispatch({ type: MatricActionType.FetchTopUpPosts, payload: res.data?.data })
      return true
    } 
      sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch top up posts')
    
  } catch (e) {
    sendErrorMessage(dispatch, 'Error in fetchAllTopUpPosts')
    // console.log('Top up posts fetch failed\n', e)
  }
  return false
}

export const addTopUpPost = (data, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post(`/matric/addTopUpPost`, data)
    if (res.data?.rs) {
      await dispatch(fetchAllTopUpPosts())
      return onSuccess(res.data?.message || 'Post added')
    } 
      return onError(res.data?.message || 'Cannot add post')
    
  } catch (e) {
    onError('Cannot add post')
    // console.log('Post add failed\n', e)
  }
  return false
}

export const editTopUpPost = (data, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.put(`/matric/editTopUpPost`, data)
    if (res.data?.rs) {
      await dispatch(fetchAllTopUpPosts())
      return onSuccess(res.data?.message || 'Post edited')
    } 
      return onError(res.data?.message || 'Cannot edit post')
    
  } catch (e) {
    onError('Cannot edit post')
    // console.log('Post edit failed\n', e)
  }
  return false
}

export const deleteTopUpPost = (data, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/matric/deleteTopUpPost', data)
    if (res.data?.rs) {
      await dispatch(fetchAllTopUpPosts())
      return onSuccess('Post deleted')
    } 
      return onError(res.data?.message || 'Cannot delete post')
    
  } catch (e) {
    // console.log('Delete post failed\n', e)
    return onError(e.message)
  }
}

export const checkTopUpPostStatus = (data, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/matric/checkTopUpPost', data)
    if (res.data?.rs) {
      await dispatch(fetchAllTopUpPosts())
      return onSuccess(res.data?.message || 'Done')
    } 
      return onError(res.data?.message || 'Cannot check post status')
    
  } catch (e) {
    // console.log('Form post failed\n', e)
    return onError(e.message)
  }
}

export const doSinglePostTopUp = (data, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/matric/topUpPost', data)
    if (res.data?.rs) {
      await dispatch(fetchAllTopUpPosts())
      return onSuccess(res.data?.message || 'Done')
    } 
      return onError(res.data?.message || 'Cannot top up post')
    
  } catch (e) {
    // console.log('Top up post failed\n', e)
    return onError(e.message)
  }
}
