export const TREE_LIST_EXPAND_FIELD = 'expanded'            // flag prop for row expansion
export const TREE_LIST_EDIT_FIELD = 'inEdit'                // flag prop for row edit



export const defaultData={
    id:11,
    url:'wwewd',
    date: new Date(2021,1,2)
}



const generateId = (data) =>{
    if(data.length===1){
        return 1
    }
    
        const {id} = data[1]
        return id+1;
    
}

export const insertItem = (itemTemp,dataTemp) => {
    const data = [...dataTemp]
    const item = {...itemTemp}
    item.id = generateId(data);
    item.inEdit = false;
    const index=data.findIndex((record)=>!Object.prototype.hasOwnProperty.call(record,'ProductID'))
    data[index]=item;
    return data;
};

export const updateItem = (item,dataTemp) => {
    const data = [...dataTemp]

    const index = data.findIndex((record) => record.id=== item.id);
    data[index] = item;
    return data;
};

export const category=['writer','commenter','reader']

export const CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;

export const CLIENT_SECRET = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_SECRET

export const scope ='https://www.googleapis.com/auth/drive'


export const ApiKey = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY

export const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
