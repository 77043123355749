import React, {useEffect, useState} from 'react'
import {Badge, Button, IconButton, Paper, Stack, Tooltip, Typography} from '@mui/material'
import {Check, CheckCircle, List, ListAlt} from '@mui/icons-material'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import moment from 'moment'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import {useSelector} from 'react-redux'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import {useNavigate} from 'react-router-dom'
import {
    commissionStatusFormatter,
    emailLinkGenerator,
    listStatusFormatter,
    useContractListStyles
} from './contractHelper'
import CardList from '../../../components/mui/cardList/CardList'
import CreateQrCodeView from '../../forms/formList/createQrCodeView'
import DraggableDialog from '../../../components/mui/dialog/DraggableDialog'
import {closeContract, fetchContract} from '../../../actions/administrative/administrativeAction'
import CheckCustomerFloat from '../../customer/register/CheckCustomerFloat'
import {DetailModal} from '../../customer/interview/interviewHelper'
import {copyToClipboard} from '../../../utils/Scripts'
import OneContractView from './contractPreview'
import {centsToDollarString, centsToDollarStringCAD} from '../../../utils/PaymentHelper'
import {fetchTagsByGroup} from '../../../actions/public/tagActions'
import Tags from '../../../components/public/Tags'
import useAuthCheck from '../../../hooks/useAuthCheck'
import {Functions} from '../../../utils/Functions'

const useStyles = makeStyles({
    contractHover: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
})

function ContractCardList({
    datas,
    columns,
    handleNotesClicked,
    handleFileOpen,
    handleConfirmOpen,
    handleForceDoneOpen,
    handleContractCommission,
    auth,
    canCreateContract,
    canCommission,
    setSelectContractEmailsManagement,
    setSelectedContractPaymentHistory,
    viewAmount,
    dispatch
}) {
    const classesMui = useStyles()
    const navigate = useNavigate()
    const [singleContract, setSingleContract] = useState(datas)

    useEffect(() => {
        setSingleContract(datas)
    }, [datas])

    const classes = useContractListStyles()
    const cardDatas = singleContract?.data || []
    const cardListData = []
    const currentContract = useSelector(state => state.administrative.currentContract)

    const calculateTotalPaid = contractPaymentHistory => {
        let total = 0
        for (let i = 0; i < contractPaymentHistory?.length; i += 1) {
            total += parseFloat(contractPaymentHistory[i]?.amount)
        }
        return total
    }

    const [openViewQR, setOpenViewQR] = useState(false)
    const [qrLink, setQrLink] = useState('')
    // const [clickedRow, setClickedRow] = useState("")

    const [openContract, setOpenContract] = useState(false)
    const [isContractLoaded, setIsContractLoaded] = useState(false)

    const allTags = useSelector(state => state.tag.tagByGroup)

    const [canEditContract] = useAuthCheck([Functions.Interview_Edit])

    const loadDataTag = () => {
        ;(async () => {
            const group = 'Contract'
            setIsContractLoaded(await fetchTagsByGroup(group)(dispatch))
        })()
    }

    useEffect(loadDataTag, [])

    if (cardDatas?.length > 0 && cardDatas[0]?.customer) {
        // get all data into list
        cardDatas.forEach(item => {
            let fieldIndex = 0
            const {isActive} = item
            // get current data into list of rows
            const rows = []

            // Contract Link Buttons
            if (canCreateContract) {
                rows.push({
                    title: '',
                    body: (
                        <div style={{display: 'flex'}}>
                            {`${columns[fieldIndex].headerName}  `}

                            <div>
                                <Tooltip title="More" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() =>
                                            navigate(
                                                `/app/administrative/contract/detail/${item.customer?.id}/${item.cid}`
                                            )
                                        }
                                    >
                                        <List size={20} />
                                    </IconButton>
                                </Tooltip>
                                {item.status === 'sent' && (
                                    <Tooltip title="Copy Link" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            onClick={() => {
                                                copyToClipboard(
                                                    dispatch,
                                                    `${window.location.protocol}//${window.location.host}/contract/${item.customer?.id}/${item.cid}`
                                                )
                                            }}
                                        >
                                            <FileCopyOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {item.status === 'sent' && (
                                    <Tooltip title="Scan QR Code" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            onClick={() => {
                                                // setClickedRow(item.cid)
                                                setOpenViewQR(true)
                                                setQrLink(
                                                    `${window.location.protocol}//${window.location.host}/contract/${item.customer?.id}/${item.cid}`
                                                )
                                            }}
                                        >
                                            <QrCodeScannerIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    )
                })
            }
            fieldIndex += 1
            // ID
            rows.push({
                title: columns[fieldIndex].headerName,
                body: <Typography variant="body2">{item.id}</Typography>
            })
            fieldIndex += 1

            // Contract ID
            rows.push({
                title: columns[fieldIndex].headerName,
                body: (
                    <Box
                        className={classesMui.contractHover}
                        onClick={async () => {
                            setIsContractLoaded(
                                await (!!item?.customer?.id && fetchContract(item?.customer?.id, item?.cid)(dispatch))
                            )
                            setOpenContract(true)
                        }}
                    >
                        {item.isActive ? item.cid : <del>{item.cid}</del>}
                    </Box>
                )
            })

            fieldIndex += 1

            // Customer Name
            rows.push({
                title: columns[fieldIndex].headerName,
                body: (
                    <DetailModal
                        name={`${item.customer?.firstName}${
                            item.customer?.nickName && ` (${item.customer?.nickName}) `
                        }${item.customer?.lastName}`}
                        details={<CheckCustomerFloat studentId={item.customer.id} />}
                    />
                )
            })

            fieldIndex += 1

            // Country
            rows.push({
                title: columns[fieldIndex].headerName,
                body: <Typography variant="body2">{item.customer.country}</Typography>
            })

            fieldIndex += 1

            // Notes
            rows.push({
                title: columns[fieldIndex].headerName,
                body: (
                    <div>
                        <Tooltip title="View Notes" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() => handleNotesClicked(item.cid)}
                            >
                                {item?.notes !== 0 ? (
                                    <Badge
                                        color="secondary"
                                        overlap="circular"
                                        className={classes.badge}
                                        badgeContent={item?.notes || 0}
                                    >
                                        <ListAlt size={20} />
                                    </Badge>
                                ) : (
                                    <ListAlt size={20} />
                                )}
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            })

            fieldIndex += 1

            // Payer info
            rows.push({
                title: columns[fieldIndex].headerName,
                body: (
                    <div>
                        <Tooltip title="View Payment Emails" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() => setSelectContractEmailsManagement(item)}
                            >
                                {item?.paymentEmails.length !== 0 ? (
                                    <Badge
                                        color="secondary"
                                        overlap="circular"
                                        className={classes.badge}
                                        badgeContent={item?.paymentEmails.length || 0}
                                    >
                                        <AccountBoxOutlinedIcon size={20} />
                                    </Badge>
                                ) : (
                                    <AccountBoxOutlinedIcon size={20} />
                                )}
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            })

            fieldIndex += 1

            // Payment Status

            let percentage = 0
            if (item?.total) {
                percentage = Math.round(((+(item?.total || 0) - +(item?.remaining || 0)) * 100) / +(item?.total || 0))
            } else {
                percentage = Math.round((calculateTotalPaid(item?.contractPaymentHistory) / +(item.price || 0)) * 100)
            }

            const amountInDollar = item?.currency?.currency
                ? centsToDollarString(+(item?.total || 0) - +(item?.remaining || 0), item?.currency?.currency)
                : centsToDollarStringCAD(+(item?.total || 0) - +(item?.remaining || 0))
            const buttonWidth = '100px'
            rows.push({
                title: columns[fieldIndex].headerName,
                body: (
                    <div
                        style={{
                            position: 'relative',
                            outline: '1px solid #D7D7D7',
                            borderRadius: '2px',
                            width: buttonWidth
                        }}
                    >
                        {item?.contractPaymentHistory.length === 0 && Number.isNaN(percentage) ? (
                            <div />
                        ) : (
                            <div
                                style={{
                                    background: `${percentage < 100 ? '#F1D094' : '#6FAB69'}`,
                                    position: 'absolute',
                                    height: '100%',
                                    width: `${percentage}%`,
                                    borderRadius: '2px'
                                    // width:"50%"
                                }}
                            />
                        )}

                        {/* payment emails management */}

                        <Tooltip title={amountInDollar} placement="top">
                            <Button
                                style={{overflow: 'hidden', width: buttonWidth}}
                                variant="text"
                                size="small"
                                className={classes.commissionButton}
                                onClick={() => setSelectedContractPaymentHistory(item)}
                            >
                                {viewAmount ? (
                                    <div
                                        style={{
                                            fontWeight: '150',
                                            overflow: 'hidden',
                                            width: '100%'
                                        }}
                                    >
                                        {item?.currency?.currency
                                            ? centsToDollarString(
                                                  +(item?.total || 0) - +(item?.remaining || 0),
                                                  item?.currency?.currency
                                              )
                                            : centsToDollarStringCAD(+(item?.total || 0) - +(item?.remaining || 0))}
                                    </div>
                                ) : (
                                    <div style={{fontWeight: '150'}}>
                                        {!Number.isNaN(percentage) ? `${percentage}%` : 'N/A'}
                                    </div>
                                )}
                            </Button>
                        </Tooltip>
                    </div>
                )
            })
            fieldIndex += 1

            // Status
            rows.push({
                title: columns[fieldIndex].headerName,
                body: (
                    <Stack direction="row" spacing={2} alignItems="center">
                        {listStatusFormatter(item.status, item.isForceDone)}
                        {canCreateContract && (
                            <div>
                                {['signed', 'done'].includes(item.status) && (
                                    <Tooltip title="Contract" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            onClick={() => handleFileOpen(item.cid, item.name, item.customer_fullName)}
                                        >
                                            <PictureAsPdfOutlinedIcon size={20} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {item.status === 'signed' && (
                                    <Tooltip title="Confirm" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            style={{color: 'green'}}
                                            onClick={() => handleConfirmOpen(item.cid)}
                                        >
                                            <Check size={20} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {item.status === 'done' && (
                                    <a
                                        href={emailLinkGenerator({
                                            productName: item.name,
                                            customerName: item.customer?.firstName,
                                            customerEmail: item.email,
                                            userName: auth.user.firstName
                                        })}
                                    >
                                        <Tooltip title="Email Customer" placement="top">
                                            <IconButton color="primary" size="small" component="span">
                                                <EmailOutlinedIcon size={20} />
                                            </IconButton>
                                        </Tooltip>
                                    </a>
                                )}
                                {['created', 'sent'].includes(item.status) && !item.isForceDone && (
                                    <div>
                                        <Tooltip title="Force done" placement="top">
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                component="span"
                                                style={{color: 'green'}}
                                                onClick={() => handleForceDoneOpen(item.cid)}
                                            >
                                                <CheckCircle size={20} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        )}
                    </Stack>
                )
            })

            // Actions
            fieldIndex += 2

            // Commissions
            if (canCommission) {
                rows.push({
                    title: columns[fieldIndex].headerName,
                    body: (
                        <Tooltip title="View Commissions" placement="top">
                            <Button
                                variant="text"
                                size="small"
                                className={classes.commissionButton}
                                onClick={() => handleContractCommission(item)}
                            >
                                {commissionStatusFormatter(item?.commissions)}
                            </Button>
                        </Tooltip>
                    )
                })
            }
            fieldIndex += 1

            // Product
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: <Typography variant="body2">{item.name}</Typography>
            })
            fieldIndex += 1

            // Tag

            rows.push({
                title: columns[fieldIndex].headerName,
                body: (
                    <div style={{display: 'flex', width: '100%', flexWrap: 'wrap'}}>
                        <Tags
                            datas={allTags}
                            current={item.id}
                            fetchData={fetchTagsByGroup}
                            canEdit={canEditContract}
                            group="Contract"
                            loadData={loadDataTag}
                        />
                    </div>
                )
            })
            fieldIndex += 1

            // Create Date
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: (
                    <Tooltip title={moment(item.createdAt).fromNow()} placement="top">
                        <Typography variant="body2">{`${moment(item.createdAt).format('L LT')} (${moment(
                            item.createdAt
                        ).fromNow()})`}</Typography>
                    </Tooltip>
                )
            })
            fieldIndex += 1

            // Sign Date
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: (
                    <Tooltip title={item?.signedDate ? moment(item.signedDate).fromNow() : ''} placement="top">
                        <Typography variant="body2">
                            {item?.signedDate
                                ? `${moment(item.signedDate).format('L LT')} (${moment(item.signedDate).fromNow()})`
                                : ''}
                        </Typography>
                    </Tooltip>
                )
            })
            fieldIndex += 1

            // Confirm Date
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: (
                    <Tooltip title={item?.confirmedDate ? moment(item.confirmedDate).fromNow() : ''} placement="top">
                        <Typography variant="body2">
                            {item?.confirmedDate
                                ? `${moment(item.confirmedDate).format('L LT')} (${moment(
                                      item.confirmedDate
                                  ).fromNow()})`
                                : ''}
                        </Typography>
                    </Tooltip>
                )
            })
            fieldIndex += 1

            // Force Done Date
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: (
                    <Tooltip title={item?.forceDoneDate ? moment(item.forceDoneDate).fromNow() : ''} placement="top">
                        <Typography variant="body2">
                            {item?.forceDoneDate
                                ? `${moment(item.forceDoneDate).format('L LT')} (${moment(
                                      item.forceDoneDate
                                  ).fromNow()})`
                                : ''}
                        </Typography>
                    </Tooltip>
                )
            })
            fieldIndex += 1

            // Open Date
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: (
                    <Tooltip title={item?.openDate ? moment(item.openDate).fromNow() : ''} placement="top">
                        <Typography variant="body2">
                            {item?.openDate
                                ? `${moment(item.openDate).format('L LT')} (${moment(item.openDate).fromNow()})`
                                : ''}
                        </Typography>
                    </Tooltip>
                )
            })
            fieldIndex += 1

            // Read Date
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: (
                    <Tooltip title={item?.readDate ? moment(item.readDate).fromNow() : ''} placement="top">
                        <Typography variant="body2">
                            {item?.readDate
                                ? `${moment(item.readDate).format('L LT')} (${moment(item.readDate).fromNow()})`
                                : ''}
                        </Typography>
                    </Tooltip>
                )
            })
            fieldIndex += 1

            // Rep
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: <Typography variant="body2">{item?.sales_fullName}</Typography>
            })
            fieldIndex += 1

            // IP
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: <Typography variant="body2">{item?.readTracking?.ip}</Typography>
            })
            fieldIndex += 1

            // Device
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: <Typography variant="body2">{item?.readTracking?.device}</Typography>
            })
            fieldIndex += 1

            // Browser
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: <Typography variant="body2">{item?.readTracking?.browser}</Typography>
            })
            fieldIndex += 1

            // Source
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: <Typography variant="body2">{item?.readTracking?.source}</Typography>
            })
            fieldIndex += 1

            // Location
            fieldIndex += 2
            rows.push({
                title: 'Read Location',
                body: (
                    <Typography variant="body2">
                        {item?.readTracking?.city || item?.readTracking?.country
                            ? `${item?.readTracking?.city}, ${item?.readTracking?.country}`
                            : ''}
                    </Typography>
                )
            })
            fieldIndex += 1

            // Postal
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: <Typography variant="body2">{item?.readTracking?.postalCode}</Typography>
            })
            fieldIndex += 1

            // Address
            rows.push({
                title: columns[fieldIndex]?.headerName,
                body: <Typography variant="body2">{item?.readTracking?.address}</Typography>
            })

            cardListData.push({isActive, rows})
        })
    }
    return (
        <div>
            <CardList data={cardListData} />
            <DraggableDialog
                open={Boolean(openViewQR)}
                onClose={() => setOpenViewQR(null)}
                title="View QR Code"
                isLoaded={Boolean(openViewQR)}
            >
                <Paper elevation={0}>
                    <CreateQrCodeView qrLink={qrLink} />
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                open={Boolean(openContract)}
                onClose={() => {
                    setIsContractLoaded(closeContract()(dispatch))
                    setOpenContract(false)
                }}
                title={`Viewing ${currentContract?.isMain ? 'Contract' : 'Subcontract'} :  ${currentContract?.cid}`}
                isLoaded={Boolean(openContract)}
            >
                <Paper elevation={0}>
                    <OneContractView isDialog loaded={isContractLoaded} />
                </Paper>
            </DraggableDialog>
        </div>
    )
}

export default ContractCardList
