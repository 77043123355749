import React from 'react';
import {Breadcrumbs, Link, Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const useStyles = makeStyles((theme)=>({
    root:{
        marginBottom: theme.spacing(2)
    }
}))


function BreadNav({id}) {

    const classes = useStyles();
    const navi = useSelector(state=>state.auth.navi)

    return (

        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} maxItems={5} className={classes.root}>
            <Link variant="body2" color="inherit" to="/app" component={RouterLink} >
                Dashboard
            </Link>
            {navi && navi[id] && navi[id].map((item,index) => {
                if(!item.router || index === navi[id].length-1 ){
                    return (
                        <Typography variant="body2" key={item.id} color="inherit">
                            {item.name}
                        </Typography>
                    )
                }
                    return (
                        <Link variant="body2" key={item.id} color="inherit" to={item.router} component={RouterLink} >
                            {item.name}
                        </Link>
                    )
                

            })}

        </Breadcrumbs>
    );
}




BreadNav.propTypes = {
    id:PropTypes.number.isRequired,
}
export default BreadNav;
