import api from '../../apis/api'
import {sendErrorMessage} from '../message/messageActions'
import {pagedGridDefaultFetch} from '../../components/mui/datagrid/PagedGridAction'

export const ReviewActionType = {
    FetchAllReviewSets: 'FetchAllReviewSets',
    FetchReviewSet: 'FetchReviewSet',

    SetReviewFetchProps: 'SetReviewFetchProps',
    SetReviewSetFetchProps: 'SetReviewSetFetchProps',
    SetReviewM2WListFetchProps: 'SetReviewM2WListFetchProps',
    SetReviewProductFetchProps: 'SetReviewProductFetchProps',

    FetchAllReviews: 'FetchAllReviews',
    FetchReviewCheck: 'FetchReviewCheck',

    FetchAllM2WReviews: 'FetchAllM2WReviews',
    FetchM2WReview: 'FetchM2WReview',

    ChangeReviewTab: 'ChangeReviewTab',
    FetchAllCustomerForReview: 'FetchAllCustomerForReview',

    FetchProductReviews: 'FetchProductReviews',
    FetchReviewProductList: 'FetchReviewProductList',

    FetchProductReviewById: 'FetchProductReviewById',
    FetchAllReviewedCategories: 'FetchAllReviewedCategories',
    ChangeCategoryReviewTab: 'ChangeCategoryReviewTab',
    SetSelectedProductId: 'SetSelectedProductId',
    FormStatus : 'FormStatus'
}

export const fetchAllCustomers = cb => async dispatch => {
    try {
        const res = await api.get(`/customer/fetchAllCustomers?isActive=true`)
        if (res.data) {
            dispatch({type: ReviewActionType.FetchAllCustomerForReview, payload: res.data})
            return cb(true)
        }
        sendErrorMessage(dispatch, 'Cannot fetch customers')
    } catch (e) {
        // console.log('Error fetching customer: ', e)
        sendErrorMessage(dispatch, 'Cannot fetch customers')
    }
    return cb(false)
}

export const fetchAllReviewSets = args => async dispatch => {
    try {
        const res = await pagedGridDefaultFetch(
            '/review/reviewSet/all',
            args,
            ReviewActionType.SetReviewSetFetchProps
        )(dispatch)

        if (res.data?.rs) {
            dispatch({type: ReviewActionType.FetchAllReviewSets, payload: res.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'Review set fetch failed')
        // console.log('Review set fetch failed', e)
    }
    return false
}

export const fetchReviewSet = uid => async dispatch => {
    try {
        const res = await api.get(`/review/reviewSet/get/${uid}`)
        if (res.data?.rs) {
            dispatch({type: ReviewActionType.FetchReviewSet, payload: res.data.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'Review set fetch failed')
        // console.log('Review set fetch failed', e)
    }
    return false
}

export const checkReviewUid = (uid, cb) => async dispatch => {
    try {
        const res = await api.get(`/public/review/check/${uid}`)
        if (res.data?.rs) {
            dispatch({type: ReviewActionType.FetchReviewCheck, payload: res.data.data})
            return cb(true)
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'Review fetch failed')
        // console.log('Review fetch failed', e)
    }
    return cb(false)
}

export const createReviewSet = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.post('/review/reviewSet/create', data)
        if (res.data?.rs) {
            return onSuccess('Review set created')
        }
        return onError(res.data?.message || 'Cannot create review set')
    } catch (e) {
        // console.log('Error creating review set: ', e)
        return onError(e.message)
    }
}

export const UpdateReviewSet = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.put('/review/reviewSet/update', data)
        if (res.data?.rs) {
            return onSuccess('Review set updated')
        }
        return onError(res.data?.message || 'Cannot update review set')
    } catch (e) {
        // console.log('Error updating review set: ', e)
        return onError(e.message)
    }
}

export const updateReviewSetStatus = (ids, isActive, fetchProps, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post('/review/reviewSet/updateStatus', {isActive, ids})
        if (res.data?.rs) {
            await dispatch(fetchAllReviewSets(fetchProps))
            return onSuccess(isActive ? 'Review sets enabled' : 'Review sets disabled')
        }
        return onError(res?.data?.message || 'Cannot update review set status')
    } catch (e) {
        // console.log('Error updating review set status: ', e)
        return onError(e.message)
    }
}

export const sendReviewLinks = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.post('/review/reviewSet/send', data)
        if (res.data?.rs) {
            return onSuccess('Review links sent')
        }
        return onError(res.data?.message || 'Cannot send review links')
    } catch (e) {
        // console.log('Error sending review links: ', e)
        return onError(e.message)
    }
}

export const fetchAllReviews = args => async dispatch => {
    try {
        const res = await pagedGridDefaultFetch('/review/all', args, ReviewActionType.SetReviewFetchProps)(dispatch)
        if (res.data?.rs) {
            dispatch({type: ReviewActionType.FetchAllReviews, payload: res.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'Review set fetch failed')
        // console.log('Review set fetch failed', e)
    }
    return false
}

export const createReview = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.post('/public/review/create', data)
        if (res.data?.rs) {
            return onSuccess()
        }
        return onError(res.data?.message || 'Cannot post review')
    } catch (e) {
        // console.log('Review cannot be created\n', e)
        return onError(e.message)
    }
}

export const fillReview = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.post('/public/review/fill', data)
        if (res.data?.rs) {
            return onSuccess()
        }
        return onError(res.data?.message || 'Cannot post review')
    } catch (e) {
        // console.log('Review cannot be created\n', e)
        return onError(e.message)
    }
}

export const fetchAllM2WReviews = args => async dispatch => {
    try {
        const res = await pagedGridDefaultFetch(
            '/review/m2w/all',
            args,
            ReviewActionType.SetReviewM2WListFetchProps
        )(dispatch)
        if (res.data?.rs) {
            dispatch({type: ReviewActionType.FetchAllM2WReviews, payload: res.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'error in fetchAllM2WReviews')
        // console.log('Review fetch error: \n', e.message)
    }
    return false
}

export const fetchM2WReview = id => async dispatch => {
    try {
        const res = await api.get(`/review/m2w/review/${id}`)
        if (res.data?.rs) {
            dispatch({type: ReviewActionType.FetchM2WReview, payload: res.data.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'error in fetchM2WReview')
        // console.log('Review fetch error: \n', e.message)
    }
    return false
}

export const updateM2WReviewsStatus = (ids, isActive, fetchProps, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post('/review/m2w/updateStatus', {isActive, ids})
        if (res.data?.rs) {
            await dispatch(fetchAllM2WReviews(fetchProps))
            return onSuccess(isActive ? 'Reviews enabled' : 'Reviews disabled')
        }
        return onError(res?.data?.message || `Cannot update reviews' status`)
    } catch (e) {
        // console.log('Error updating review status: ', e)
        return onError(e.message)
    }
}

export const createM2WReview = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.post('/review/m2w/create', data)
        if (res.data?.rs) {
            return onSuccess('Review created')
        }
        return onError(res.data?.message || 'Cannot create review')
    } catch (e) {
        // console.log('Review cannot be created\n', e)
        return onError(e.message)
    }
}

export const updateM2WReview = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.put('/review/m2w/edit', data)
        if (res.data?.rs) {
            return onSuccess('Review updated')
        }
        return onError(res.data?.message || 'Cannot update review')
    } catch (e) {
        // console.log('Error updating review: ', e)
        return onError(e.message)
    }
}

export const fetchAllReviewProduct = () => async dispatch => {
    try {
        const res = await api.get('/review/product/allProducts')
        if (res.data?.rs) {
            dispatch({type: ReviewActionType.FetchReviewProductList, payload: res.data.data})
            return true
        }
        return false
    } catch (e) {
        return false
    }
}

export const fetchSelectedProductReviews = args => async dispatch => {
    try {
        if (args.pid === undefined) return

        const res = await pagedGridDefaultFetch(
            '/review/product/reviews',
            args,
            ReviewActionType.SetReviewProductFetchProps
        )(dispatch)
        if (res.data?.rs) {
            dispatch({type: ReviewActionType.FetchProductReviews, payload: res.data})
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'error in fetch product reviews')
    }
}

export const updateProductReviewsStatus = (ids, isActive, fetchProps, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post('/review/product/updateStatus', {isActive, ids})
        if (res.data?.rs) {
            await dispatch(fetchSelectedProductReviews(fetchProps))
            return onSuccess(res.data.message)
        }
        return onError(res?.data?.message || `Cannot update reviews' status`)
    } catch (e) {
        // console.log('Error updating review status: ', e)
        return onError(e.message)
    }
}

export const fetchProductReview = id => async dispatch => {
    try {
        const res = await api.get(`/review/product/fetchProductReview/${id}`)
        if (res.data?.rs) {
            dispatch({type: ReviewActionType.FetchProductReviewById, payload: res.data.data})
            return true
        }
    } catch (e) {
        return false
    }
    return false
}

export const fetchAllReviewedCategories = () => async dispatch => {
    try {
        const res = await api.get('/review/product/fetchAllReviewedCategories')
        if (res.data?.rs) {
            dispatch({type: ReviewActionType.FetchAllReviewedCategories, payload: res.data.data})
            return true
        }
    } catch (e) {
        return false
    }
    return false
}
