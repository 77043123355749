import { debounce } from 'lodash';
import Grid from '@mui/material/Grid';
import { IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Clipboard } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import GridCellExpand from '../../../components/mui/datagrid/GridCellExpand';
import { genHoverCell, REVIEW_LIST_TABS, useReviewListStyles } from '../reviewHelper';
import { frontEndUrl } from '../../../constants';
import { copyToClipboard } from '../../../utils/Scripts';
import { fetchAllReviews } from '../../../actions/review/reviewActions';
import PageGrid from '../../../components/mui/datagrid/PageGrid';
import SearchBox from '../../../components/public/SearchBox';

function ReviewListTab() {
  const classes = useReviewListStyles()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const allReviews = useSelector(state => state.review.allReviews)
  const currentTab = useSelector(state => state.review.currentReviewTab)
  const [searchArgs, setSearchArgs] = useState({
    searchKey: undefined,
    type: REVIEW_LIST_TABS[currentTab].key
  })

  const onLinkCopy = (uid) =>
    copyToClipboard(dispatch, `${frontEndUrl}/review/${uid}`)

  useEffect(() => {
    setSearchArgs(prevState => ({
      ...prevState,
      type: REVIEW_LIST_TABS[currentTab].key
    }))
  }, [currentTab])

  const onSearch = (searchVal) => setSearchArgs(prevState =>
    ({ ...prevState, searchKey: searchVal.toLowerCase().trim() }))

  const searchHandler = debounce((searchVal) => {
    onSearch(searchVal)
  }, 500)
  const renderSearchContainer = () => <Grid item xs={12} className={classes.search}>
      <div className={classes.search}>
        <SearchBox
          placeholder='Search...'
          size='small'
          type='search'
          searchHandler={searchHandler}
          isLoading={isLoading}
        />
      </div>
    </Grid>

  const renderGrid = () => {
    const columns = []

    columns.push(...[
      { field: 'id', headerName: 'ID', width: 75 },
      { field: 'uid', headerName: 'Link', width: 90, renderCell: (params) =>
          <div>
            {
              params.row.type === 'pending' &&
              <Tooltip title='Copy Link' placement='top'>
                <IconButton color='primary'
                            size='small'
                            component='span'
                            onClick={() => onLinkCopy(params.row.uid)}
                >
                  <Clipboard size={20} />
                </IconButton>
              </Tooltip>
            }
          </div>
      },
      { field: 'type', headerName: 'Type', width: 100,
        valueFormatter: ({value}) => `${value[0].toUpperCase()}${value.slice(1)}` },
      { field: 'score', headerName: 'Score', width: 100,
        valueFormatter: ({ value }) => value === 0 ? ' ' : value },
      { field: 'email', headerName: 'Email', width: 200 },
      { field: 'phone', headerName: 'Phone', width: 150 },
      { field: 'text', headerName: 'Review', width: 200,
        renderCell: ({value}) =>
          <GridCellExpand
            defaultValue={value}
            expandValue={genHoverCell(value)}
            maxWidth='800px'
            minWidth='200px'
          />
      },
      { field: 'set', headerName: 'Set', width: 210,
        valueFormatter: ({ value }) => value.name },
      { field: 'createdAt', headerName: 'Created', width: 200,
        valueFormatter: ({ value }) => moment(value).format('L LT')},
      { field: 'updatedAt', headerName: 'Updated', width: 200,
        valueFormatter: ({ value }) => moment(value).format('L LT')},
    ])

    return (
      <PageGrid
        className={classes.dataGrid}
        showToolbar
        columns={columns}
        datas={allReviews}
        fetchDatas={fetchAllReviews}
        searchArgs={searchArgs}
        setIsLoading={setIsLoading}
        defaultSortColumn='id'
      />
    )
  }

  const renderTopToolbar = () => <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
      { renderSearchContainer() }
    </Grid>

  return (
    <Grid container pl={2} pr={2}>
      { renderTopToolbar() }
      { renderGrid() }
    </Grid>
  )

}

export default ReviewListTab
