import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Paper } from '@mui/material';
import { alpha } from '@mui/material/styles';
import DraggableDialog from '../../../components/mui/dialog/DraggableDialog';

export const LS_KEY_NOTE_EXPANDED = 'customer_note_expanded';

export const INTERVIEW_STATUS = {
  PENDING: 'P',
  ON_TIME: 'OT',
  LATE: 'L',
  ABSENT: 'ABS',
  CANCELLED: 'CXL'
};

export const INTERVIEW_STATUS_DICT = {
  P: 'Pending',
  OT: 'On Time',
  L: 'Late',
  ABS: 'Absent',
  CXL: 'Cancelled'
};

export const listTime = {
  D: 'day',
  M: 'month',
  Y: 'year',
  W: 'week',
  WW: 'work-week'
};

export const today = moment();

export const listColor = {
  P: '#36629E',
  OT: '#619E36',
  L: '#e87435',
  ABS: '#c70b19',
  CXL: '#9E9E9E'
};
export const listStatusFormatter = (status) => {
  let result = status;
  try {
    result = <div style={{ color: listColor[status] }}>
      {status}
    </div>;
  } catch (e) {
    // console.log('Formatting error:', e.message);
  }
  return result;
};

export const INVOICE_EDITOR_ITEM_VALIDATOR = {
  type: yup.lazy((value) => !value ? yup.string() : yup.string()
    .max(30, 'Interview type must be less than 30 characters')
    .trim())
};

export const useInterviewScheduleStyles = makeStyles((theme) => ({
  paper: {
    padding: '2rem',
    marginBottom: '2rem'
  },
  cardContainer: {
    '& .card': {
      cursor: 'pointer',
      boxShadow: 'none',
      backgroundColor: 'transparent'
    },
    marginBottom: '2rem'
  },
  scheduler: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    '& .k-toolbar': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.dark,
      '& .k-button': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper
      }
    },
    '& .k-nonwork-hour, & .k-scheduler-head': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default
    },
    '& .k-scheduler-cell': {
      color: `${theme.palette.text.primary}!important`
    },
    '& .k-scheduler-footer': {
      display: 'none'
    }
  }
}));

export const useInterviewListStyles = makeStyles((theme) => ({
  p2: {
    padding: '8px 8px 0 8px',
  },
  search: {
    padding: theme.spacing(1, 0),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    width: '100%',
  },
  link: {
    cursor: 'pointer'
  },
  paper: {
    padding: theme.spacing(2),
  }
}));

export const useInterviewEditorStyles = makeStyles((theme) => ({
  paper: {
    padding: '2rem',
    marginBottom: '2rem'
  },
  modalPaper: {
    padding: '2rem',
    marginBottom: '2rem',
    width: '100%'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '800px',
    margin: '0 auto'
  },
  formContainer: {
    [theme.breakpoints.down('xl')]: {
      justifyContent: 'space-evenly'
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start'
    }
  },
  legend: {
    [theme.breakpoints.down('xl')]: {
      borderLeft: '5px solid slategray'
    },
    [theme.breakpoints.up('sm')]: {
      borderLeft: '1px solid slategray'
    },
    padding: '1rem',
    borderRadius: 0,
    '& .title': {
      marginTop: '-.5rem',
      marginBottom: '.5rem'
    }
  },
  scoreBox: {
    display: 'flex',
    alignItems: 'center',
    '& .scoreDisplay': {
      marginLeft: theme.spacing(2)
    }
  },
  commentField: {
    borderRadius: '0',
    backgroundColor: 'rgba(235, 235, 235, 0.1)!important',
    border: 'rgba(0, 0, 0, 0.23) solid 1px',
    '& .fileUploader': {
      height: '100%',
      [theme.breakpoints.down('xl')]: {
        border: 'none',
        borderTop: 'rgba(0, 0, 0, 0.23) solid 1px'
      },
      [theme.breakpoints.up('sm')]: {
        border: 'none',
        borderLeft: 'rgba(0, 0, 0, 0.23) solid 1px'
      },
      backgroundColor: 'transparent',
      '& .k-dropzone': {
        height: '78px',
        backgroundColor: 'transparent',
        '& .k-dropzone-hint': {
          color: theme.palette.text.hint
        }
      },
      '&.full': {
        '& .k-dropzone': {
          height: '148px'
        }
      },
      '& .k-file, & .k-icon, & .k-file-size': {
        color: theme.palette.text.primary
      },
      '& .k-progressbar': {
        display: 'none'
      }
    },
    '& .MuiTextField-root': {
      marginTop: theme.spacing(2),
      '& .MuiInputBase-root': {
        '& fieldset': {
          border: 'none'
        }
      }
    }
  },
  marginTop: {
    marginTop: '1rem'
  },
  marginBottom: {
    marginBottom: '1rem'
  },
  autofillForm: {
    marginBottom: '2.5rem',
    marginTop: '1.5rem'
  },
  detailsView: {
    marginTop: '3rem'
  },
  detailsDate: {
    marginTop: '.5rem'
  },
  submitButton: {
    marginTop: '5rem'
  },
  actionButton: {
    '&.otStart': { backgroundColor: 'forestgreen' },
    '&.lStart': { backgroundColor: 'forestgreen' },
    '&.end': { backgroundColor: 'indianred' },
    '&.absent': { backgroundColor: 'slategray' },
    '&.cancel': { backgroundColor: 'slategray' }
  },
  gridView: {
    backgroundColor: 'transparent',
    '& .k-grid-header, & .k-grid-content': {
      color: `${theme.palette.text.primary}!important`,
      backgroundColor: theme.palette.background.default
    },
    '& .k-master-row, & .k-link, & .k-icon': {
      color: `${theme.palette.text.primary}!important`
    }
  },
  gridViewCard: {
    [theme.breakpoints.down('xl')]: {
      '& .interviewerPic': {
        textAlign: 'left',
        marginBottom: theme.spacing(2)
      },
      '& .interviewerDate': {
        textAlign: 'right',
        opacity: '.8'
      },
      '& .textContainer': {
        marginBottom: theme.spacing(2)
      }
    },
    [theme.breakpoints.up('md')]: {
      '& .interviewerPic': {
        textAlign: 'center',
        '& .MuiAvatar-circular': {
          margin: '0 auto',
          textAlign: 'center'
        }
      },
      '& .interviewerDate': {
        textAlign: 'center',
        opacity: '.8'
      }
    },
    padding: '1rem',
    margin: '.5rem',
    width: '100%',
    '& .textContainer': {
      whiteSpace: 'pre-line',
      wordBreak: 'break-word'
    },
    '& .imageContainer': {
      textAlign: 'center',
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        cursor: 'pointer'
      }
    },
    '& .downloadButton': {
      marginBottom: theme.spacing(3)
    },
    '& .editButtons': {
      marginLeft: 'auto',
      '& > span': {
        marginLeft: theme.spacing(3)
      }
    }
  },
  emptyDiv: {
    height: '40vh'
  },
  status: {
    marginTop: theme.spacing(3),
  },
  score: {
    marginTop: theme.spacing(4),
  }
}));


export function DetailModal({ name, details, disabled }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [hover, setHover] = useState(false);
  const [nameStatus, setNameStatus] = useState(undefined)

  const judgeNameStatus = () => {
    if(typeof name === 'string'){
      setNameStatus(true)
    } else {
      setNameStatus(false)
    }
  }

  useEffect(()=> judgeNameStatus(),[])

  // const style = {
  //   width: '80%',
  //   height: '80%',
  //   overflow: 'scroll',
  //   backgroundColor: 'grey',
  //   border: '1px solid black',
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   zIndex: '9999'
  // };
  const hoverStyle = {
    textDecoration: 'underline',
    cursor:'pointer'
  };
  const normalStyle = {
    textDecoration: 'none',
    cursor: 'auto'
  };

  return (
    <div>
      {
        nameStatus?
            <div onDoubleClick={handleOpen}
                 onFocus={()=>{}}
                 onBlur={()=>{}}
                 onMouseOver={() => setHover(true)}
                 onMouseOut={() => setHover(false)}
                 style={hover ? hoverStyle : normalStyle}>
              {
                disabled
                    ? <del>{ name }</del>
                    : name
              }
            </div>
            :
            <div aria-hidden='true' onClick={handleOpen}
                 onFocus={()=>{}}
                 onBlur={()=>{}}
                 onMouseOver={() => setHover(true)}
                 onMouseOut={() => setHover(false)}
                 style={hover ? hoverStyle : normalStyle}>
              {
                disabled
                    ? <del>{ name }</del>
                    : name
              }
            </div>
      }


      <DraggableDialog open={Boolean(open)} onClose={handleClose}
                       title='View Customer'
                       isLoaded={Boolean(open)}>
        <Paper elevation={0}>
            {details}
        </Paper>
      </DraggableDialog>

      {/* <Modal */}
      {/*  open={open} */}
      {/*  onClose={handleClose} */}
      {/* > */}
      {/*  <div style={style}> */}
      {/*    {details} */}
      {/*  </div> */}

      {/* </Modal> */}

    </div>
  );

}
