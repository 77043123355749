import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import Paper from '@mui/material/Paper'
import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Typography
} from '@mui/material'
import {Download} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'
import {Functions} from '../../../utils/Functions'
import ViewHead from '../../../components/public/ViewHead'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import {fetchFormSubmission} from '../../../actions/forms/formActions'
import LoadingScreen from '../../../components/public/LoadingScreen'
import {addCustomerFromForm, handleFileOpen, useDisplaySubmissionStyles} from './formDataHelper'
import {VALID_INPUT_TYPES} from '../formBuilder/formBuilderData'
import {sendErrorMessage} from '../../../actions'
import {capitalizeFirstChar} from '../../../utils/Scripts'

function SubmissionDetails({rowId, isPopUp}) {
    useCurrentViewCheck(Functions.Forms_View_Form_Submissions)
    const navigate = useNavigate()
    const classes = useDisplaySubmissionStyles()

    const submissionId = useParams().id
    const dispatch = useDispatch()
    const currentFormSubmission = useSelector(state => state?.forms.currentFormSubmission)

    const [tableData, setTableData] = useState({})
    const [tableHeader, setTableHeader] = useState({})
    const [customerDetails, setCustomerDetails] = useState({})
    const [isLoaded, setIsLoaded] = useState(true)

    const loadData = () => {
        ;(async () => {
            setIsLoaded(await fetchFormSubmission(rowId || submissionId)(dispatch))
        })()
    }
    useEffect(loadData, [dispatch, submissionId || rowId])

    useEffect(() => {
        if (isLoaded) {
            try {
                const customerDetailsTemp = {}
                const headerComponents = new Set()
                const componentDisplay = {}

                // set each result
                const results = currentFormSubmission.results || []
                results.forEach(result => {
                    const {component} = result
                    // bookkeeping starts
                    headerComponents.add({
                        label: `${component.tag ? '[U] ' : ''}${component.label}`,
                        name: component.name,
                        id: component.id,
                        type: component.inputType,
                        tag: component.tag
                    })
                    customerDetailsTemp[result.name] = result.value
                    // bookkeeping ends

                    componentDisplay[component.id] = {
                        label: `${component.tag ? '[U] ' : ''}${component.label}`,
                        result: componentDisplay[component.id] ? componentDisplay[component.id].result : [],
                        type: component.inputType
                    }

                    switch (component.inputType) {
                        case VALID_INPUT_TYPES.check:
                        case VALID_INPUT_TYPES.radio: {
                            // check and radio type's submission result's name will be used to match the component's value's name
                            const prevResult = componentDisplay[component.id].result
                            componentDisplay[component.id].result = [
                                ...prevResult,
                                component.values.find(value => value.name === result.name)?.value
                            ]
                            break
                        }

                        case VALID_INPUT_TYPES.select:
                            // select type's submission result's value is the same as the component's value's name
                            componentDisplay[component.id].result = [
                                component.values.find(value => value.name === result.value)?.value
                            ]
                            break

                        default:
                            componentDisplay[component.id].result = [result.value]
                    }
                })
                // set tracking details
                const tracking = currentFormSubmission.tracking || []
                const trackingHeaders = [
                    'ip',
                    'device',
                    'browser',
                    'city',
                    'country',
                    'address',
                    'postalCode',
                    'latitude',
                    'longitude',
                    'source'
                ]
                trackingHeaders.forEach(header => {
                    componentDisplay[header] = {
                        label: header,
                        result: [tracking[header]]
                    }
                    customerDetailsTemp[header] = tracking[header]
                })

                componentDisplay.rep = {
                    label: 'rep',
                    result: [`${currentFormSubmission.user?.firstName} ${currentFormSubmission.user?.lastName}`]
                }
                customerDetailsTemp.repName = `${currentFormSubmission.user?.firstName} ${currentFormSubmission.user?.lastName}`
                customerDetailsTemp.repUserId = currentFormSubmission.user?.id

                setTableData(componentDisplay)
                setTableHeader([...headerComponents])
                setCustomerDetails(customerDetailsTemp)
            } catch (error) {
                sendErrorMessage(dispatch, 'Error formatting submission results')
            }
        }
    }, [currentFormSubmission])

    const renderView = () => (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Label</TableCell>
                        <TableCell>Result</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(tableData).map(key => (
                        <TableRow key={key}>
                            <TableCell style={{verticalAlign: 'top'}}>
                                <Typography variant="body2">{capitalizeFirstChar(tableData[key].label)}</Typography>
                            </TableCell>
                            <TableCell>
                                {tableData[key].type === VALID_INPUT_TYPES.file ? (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        startIcon={<Download />}
                                        onClick={() => handleFileOpen(tableData[key].result.pop())(dispatch)}
                                    >
                                        Download File
                                    </Button>
                                ) : (
                                    <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
                                        {tableData[key].result.join(', ')}
                                    </Typography>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

    const renderBackButton = () => (
        <Stack spacing={2} direction="row">
            <Button
                color="secondary"
                variant="contained"
                onClick={() => navigate(`/app/forms/data/${currentFormSubmission.form?.id}`)}
            >
                All Submissions
            </Button>
            <Button
                color="secondary"
                variant="contained"
                disabled={!isLoaded}
                onClick={() => addCustomerFromForm(dispatch, navigate, customerDetails, tableHeader)}
            >
                Create User
            </Button>
        </Stack>
    )

    return (
        <div>
            {!isPopUp ? (
                <ViewHead functionId={Functions.Forms_View_Form_Submissions} isShowTitle={false}>
                    {isLoaded && (
                        <Stack spacing={2} className={classes.pageTitle}>
                            <Typography variant="h3" color="textPrimary">
                                Submission for {currentFormSubmission.form?.name} [
                                {currentFormSubmission.form?.category?.name || 'No Category'}]
                            </Typography>
                            {renderBackButton()}
                        </Stack>
                    )}
                    <Paper elevation={0} className={classes.paper}>
                        {isLoaded ? renderView() : <LoadingScreen message="Loading..." />}
                    </Paper>
                </ViewHead>
            ) : (
                <div>
                    {isLoaded && (
                        <Stack spacing={2} className={classes.pageTitle}>
                            <Typography variant="h3" color="textPrimary">
                                Submission for {currentFormSubmission.form?.name} [
                                {currentFormSubmission.form?.category?.name || 'No Category'}]
                            </Typography>
                            {/* {renderBackButton()} */}
                        </Stack>
                    )}
                    <Paper elevation={0} className={classes.paper}>
                        {isLoaded ? renderView() : <LoadingScreen message="Loading..." />}
                    </Paper>
                </div>
            )}
        </div>
    )
}
export default SubmissionDetails
