import _ from 'lodash';
import {FunctionActions, SystemSettingsActionTypes} from "../../actions";
// import Debug from "../../utils/Debug";

export const systemReducer = (state = defaultState, action = {}) => {

    switch (action.type) {
        case FunctionActions.SearchFunctions:
            return {...state, functions: action.payload}
        case FunctionActions.FetchFunction:
            return {...state, currentFunction: action.payload}
        case FunctionActions.SearchFunctionsByParent:{
            const newState = _.cloneDeep(state);
            const {parent, children} = action.payload;
            if (!_.isEmpty(children)) {
                __AppendChild(newState.functions, parent, children);
            }
            return newState}
        case FunctionActions.CancelSelectParent:
            return {...state, currentFunction: null};
        case FunctionActions.SetFunctionFormStatus:
            return {...state, FunctionFormStatus: action.payload}
        case FunctionActions.CancelEditSelectParent:
            return _.chain(state)
                .cloneDeep()
                .set("currentFunction.parent", null)
                .value()
        case FunctionActions.FetchEditParentFunction:
            return _.chain(state)
                .cloneDeep()
                .set("currentFunction.parent", action.payload)
                .value();
        case FunctionActions.EditFunction:{
            const editState = _.cloneDeep(state)
            __deleteNode(editState.functions, action.payload.id);
            return editState;}
        case FunctionActions.LoadFunctionSource:
            return _.chain(state)
                .cloneDeep()
                .set("FT.FunctionSource.data", action.payload)
                .set("FT.FunctionSource.selected", [])
                .set("FT.FunctionSource.diff", [])
                .set("FT.FunctionTreeFromRole.diff", [])
                .set("FT.Action", "ReloadFunctionSource")
                .value();
        case FunctionActions.functionTreeSelectorChange:{
            const {key, selected} = action.payload;
            return _.chain(state)
                .cloneDeep()
                .set(["FT", key, "selected"], selected)
                .value();
        }

        //= ==============System Setting=============
        case SystemSettingsActionTypes.FetchSystemSettings:
            return _.chain(state)
                .cloneDeep()
                .set("setting.data", action.payload)
                .value();
        case SystemSettingsActionTypes.SetSettingFormStatus:
            return _.chain(state)
                .cloneDeep()
                .set("setting.Action", action.payload)
                .value();
        case SystemSettingsActionTypes.CreateSystemSetting:
            return _.chain(state)
                .cloneDeep()
                .update("setting.data", data=>{
                    if (Array.isArray(data)) {data.push(action.payload)}
                    return data;
                })
                .value();
        case SystemSettingsActionTypes.UpdateSystemSetting:
            return _.chain(state)
                .cloneDeep()
                .update("setting.data", data=>(
                    Array.isArray(data) ? data.map(item=> item.id===action.payload.id? action.payload:item):[]
                ))
                .value();
        case SystemSettingsActionTypes.DeleteSystemSetting:
            return _.chain(state)
                .cloneDeep()
                .update("setting.data", data=>(
                    Array.isArray(data) ? data.filter(item=> item.id!==action.payload):[]
                ))
                .value();
        case SystemSettingsActionTypes.SetCurrentId:
            return _.chain(state)
                .cloneDeep()
                .set("setting.currentId", action.payload)
                .value();
        default:
            return state;
    }
}
const defaultState = {
    FunctionFormStatus: "Close",
    RolesFormStatus: "Close",
    currentRole: undefined,
    FT: {
        FunctionSource: {data: [], selected: [], diff: []},
        FunctionTreeFromRole: {data: [], selected: [], diff: []},
        Action: "Ready"
    },
    RU: {
        UsersFromRole: undefined,
        AvailableUsers: undefined,
        Action: "Ready"
    },
    setting:{
        data:undefined,
        Action:"Ready",
    }

}


function __deleteNode(functions, id) {
    _.remove(functions, (node) => {
        if (node.id === id) {
            return true
        }
            if (!_.isEmpty(node.child)) {
                return __deleteNode(node.child, id)
            }
                return false
            
        
    });
}

function __AppendChild(functions, parentId, children) {

    functions.forEach ((node)=> {
        if (node.id === parentId) {
            node.child = children;
        }
        if (!_.isEmpty(node.child)) {
            __AppendChild(node.child, parentId, children);
        }
    })

}
export default systemReducer