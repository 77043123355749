import {sendErrorMessage, sendSuccessMessage} from '../message/messageActions'
import api from '../../apis/api'

export const mmqJobsActionType = {
    fetchAllMMQJobs: 'fetchAllMMQJobs',
    fetchPaymentReceiver: 'fetchPaymentReceiver'
}

export const fetchAllMMQJobs = () => async dispatch => {
    try {
        const res = await api.get('/msg-queue/fetchALLMMQJobs')
        if (res.data?.rs) {
            dispatch({type: mmqJobsActionType.fetchAllMMQJobs, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch all MMQ Jobs')
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch all MMQ Jobs')
    }
    return false
}

export const createMMQJob = (postData, onSuccess, onError) => async () => {
    try {
        await api.post(`msg-queue/publish`, postData).then(async res => {
            if (res.data?.code === 200) {
                onSuccess('MMQ Job created')
                return
            }
            onError(res?.data?.message || 'Cannot create MMQ Jobs')
        })
    } catch (e) {
        onError('Cannot create MMQ Jobs')
    }
}

export const removeRepeatableJobs = (postData, onSuccess, onError) => async () => {
    try {
        const queueList = postData.map(ele => ele.substring(24))
        const res = {
            queueNames: queueList
        }
        await api.post('/msg-queue/removeRepeatableJobs', res).then(async r => {
            if (r.data?.code === 200) {
                onSuccess('repeatable jobs removed')
                return
            }
            onError(r?.data?.message || 'Cannot remove repeatable jobs')
        })
    } catch (e) {
        onError('Cannot remove repeatable jobs')
    }
}

export const createPaymentReceiver = (postData, onSuccess, onError) => async () => {
    try {
        const res = {
            dto: postData
        }
        await api.post(`administrative/contract/createPaymentReceiver`, res).then(async r => {
            if (r.data?.rs) {
                onSuccess('Payment Email created')
                return
            }
            onError(r?.data?.message || 'Cannot create Payment Email')
        })
    } catch (e) {
        onError('Cannot create Payment Email')
    }
}

export const fetchPaymentReceiver = () => async dispatch => {
    try {
        await api.get(`administrative/contract/fetchPaymentReceiver`).then(async res => {
            if (res.data?.rs) {
                dispatch({type: mmqJobsActionType.fetchPaymentReceiver, payload: res.data.data})
                return true
            }
            sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch Payment Email')
            return false
        })
    } catch (e) {
        sendErrorMessage('Cannot fetch Payment Email')
    }
    return false
}

export const updatePaymentReceiver = (ids, isActive) => async dispatch => {
    try {
        const dto = {
            ids,
            isActive
        }
        const rbody = {
            dto
        }
        const res = await api.post(`administrative/contract/updatePaymentReceiver`, rbody)
        if (res.data?.rs) {
            sendSuccessMessage(dispatch, 'Update Payment Email Successfully')
            await fetchPaymentReceiver()(dispatch)
            return true
        }
        sendErrorMessage(dispatch, res?.data?.message || 'Cannot update Payment Email')
        return false
    } catch (e) {
        sendErrorMessage('Cannot update Payment Email')
    }
    return false
}
