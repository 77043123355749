import React from 'react';
import {Box, Link, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

function HelpBox() {
    return (
        <Box p={2}>
            <Box p={2} borderRadius="1px" bgcolor="background.dark" >
                <Typography variant="h6" color="textPrimary" >
                    Need Help?
                </Typography>
                <Link variant="subtitle1" color="secondary" component={RouterLink} to="/docs" >
                    Check our docs
                </Link>
            </Box>
        </Box>
    );
}

export default HelpBox;