import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ViewHead from '../../../components/public/ViewHead';
import { Functions } from '../../../utils/Functions';
import CampaignEditor from './campaignEditor';
import { sendSuccessMessage } from '../../../actions';
import { fetchCampaign } from '../../../actions/campaign/campaignActions';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';

function CampaignEditView({campaignId}) {
  useCurrentViewCheck(Functions.Campaign_Edit)
  const  navigate = useNavigate();
  const urlId = useParams().id
  const dispatch = useDispatch()
  const storageCampaign = useSelector(state => state?.campaign.currentCampaign)

  const [isCampaignLoaded, setIsCampaignLoaded] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const [currentCampaign, setCurrentCampaign] = useState([])

  const loadData = () => {
    (async() => {
      setIsCampaignLoaded(await fetchCampaign(urlId || campaignId)(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    if (isCampaignLoaded && storageCampaign) {
      setCurrentCampaign(storageCampaign)
      setIsLoaded(true)
    }
  }, [storageCampaign, isCampaignLoaded, isLoaded])

  const onSuccess = (msg, id = -1) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/campaign/view/${id}`)
    }, 1000)
  }

  const renderReturnButton = () => (
      <Button color='secondary'
              variant='contained'
              onClick={() => navigate('/app/campaign/')}>
        Return
      </Button>
    )

  return (
      <div>
      {urlId ?
            <ViewHead functionId={Functions.Campaign_Edit} topButtons={renderReturnButton()}>
              { isLoaded && <CampaignEditor isEdit campData={currentCampaign} onSubmitSuccess={onSuccess} /> }
            </ViewHead>
            :
            <div>
              { isLoaded && <CampaignEditor isEdit campData={currentCampaign} onSubmitSuccess={onSuccess} /> }
            </div>
      }
      </div>
  )
}

export default CampaignEditView
