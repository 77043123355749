import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Container, InputAdornment, SvgIcon, TextField} from "@mui/material";
import {Search as SearchIcon} from "react-feather";
import {connect} from 'react-redux';
import {searchFunctions, searchFunctionsByParentId, fetchFunction} from "../../../actions";
import LoadingScreen from "../../../components/public/LoadingScreen";
import NotFound from "../../../components/public/NotFound";
import StyledTreeItem from "./tree/StyledTreeItem";
import getMenuIcon from "../../../utils/MenuIcon";


const useStyles = makeStyles(theme => ({
    root: {
        // height: '50vh',
        marginBottom: "1rem",
        maxWidth: '100%',
        [theme.breakpoints.down('xl')]: {
            marginTop: ".5rem",
        }
    },
    tree: {
        height: '100%',
        flexGrow: 1,
        maxWidth: '100%',
    },
    search: {
        margin: '.1rem .3rem',
        width: '90%'
    },

}));

function FunctionTree({
        searchFunctions,
        searchFunctionsByParentId,
        fetchFunction, functions,
        FunctionFormStatus
    }) {
    const [term, setTerm] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState(term)
    const classes = useStyles();



    const renderTree = (myFunctions) => {
        if (!_.isEmpty(myFunctions)) {
            return myFunctions.map(nodes => (
                    <StyledTreeItem key={nodes.id} nodeId={`${nodes.id  }`} labelText={`${nodes.id  }.${  nodes.name}`}
                                    labelIcon={getMenuIcon(nodes.icon)} labelInfo={nodes.orderKey} ID={nodes.id}
                                    isActive={nodes.isActive} labelMiddle={nodes.router} labelDesc={nodes.description}
                    >
                        {Array.isArray(nodes.child) ? renderTree(nodes.child) : null}
                    </StyledTreeItem>
                )
            );
        }
        return []

    }
    const handleSelect = (event, value) => {
        event.stopPropagation();

        if (value) {
            searchFunctionsByParentId({parent: +value});
            fetchFunction(value,FunctionFormStatus);
        }
    }

    const searchKey = () => {
        if (!Number.isNaN(debouncedTerm)) {
            searchFunctions({search: debouncedTerm})
        } else if (debouncedTerm.length > 2) {
            searchFunctions({search: debouncedTerm})
        }
    }
    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedTerm(term)
        }, 500)
        return () => {
            clearTimeout(timerId);
        }
    }, [term])
    useEffect(() => {
        searchKey();
    }, [debouncedTerm])


    useEffect(() => {
        searchFunctions({})
    }, [searchFunctions])
    return (
        <Container className={classes.root}>
            <TextField fullWidth InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                            <SearchIcon size={20} color="grey"/>
                        </SvgIcon>
                    </InputAdornment>
                )
            }} placeholder="Search id, name or router" value={term} size="small" type="search"
                       className={classes.search}
                       onChange={(event) => {
                           setTerm(event.currentTarget.value)
                       }}
            />
            <TreeView
                className={classes.tree}
                defaultCollapseIcon={<ExpandMoreIcon/>}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon/>}
                onNodeSelect={handleSelect}
            >
                {functions ?
                    _.isEmpty(functions) ? <NotFound/> : renderTree(functions)
                    : <LoadingScreen/>
                }
            </TreeView>
        </Container>
    )


}
const mapStateToProps = ({system}) => ({
        functions: system?.functions,
        FunctionFormStatus:system.FunctionFormStatus

    })
export default connect(mapStateToProps,
    {searchFunctions, searchFunctionsByParentId, fetchFunction})(FunctionTree);
