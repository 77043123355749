import React, {useEffect, useState} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles'
import {AppRoutes} from './routes'
import history from './utils/history'
import GlobalStyles from './theme/GlobalStyles'
import {darkTheme, lightTheme} from './theme'
import SystemMessage from './components/message/SystemMessage'
import {setDarkMode} from './actions'
import {AfternoonTime, MorningTime} from './constants'

const isNight = () => moment().hours() < MorningTime || moment().hours() > AfternoonTime
function App() {
    const userSetting = useSelector(state => state?.userSetting)
    const dispatch = useDispatch()
    const [isDarkMode, setIsDarkMode] = useState(userSetting.darkMode)
    useEffect(() => {
        setIsDarkMode(userSetting.darkMode)
    }, [userSetting])

    useEffect(() => {
        if (!userSetting.autoSwitchBackgroundMode) {
            return
        }
        setInterval(() => {
            if (isDarkMode !== isNight()) {
                setDarkMode(isNight)(dispatch)
            }
        }, 1000 * 60)
    }, [isDarkMode, setDarkMode, userSetting.autoSwitchBackgroundMode])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
                <BrowserRouter history={history}>
                    <GlobalStyles />
                    <AppRoutes />
                    {/* {renderRoutes(routes)} */}
                    <SystemMessage />
                </BrowserRouter>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

// const mapStateToProps = ({userSetting}) => ({userSetting})
// export default connect(mapStateToProps, {setDarkMode})(App);
export default App
