import {Autocomplete, CircularProgress, Grid, Paper, TextField, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import {useDispatch, useSelector} from 'react-redux'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'
import moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import {Clipboard, Edit as EditIcon} from 'react-feather'
import _, {noop} from 'lodash'
import {REVIEW_PRODUCT_VALIDATOR} from './ProductReviewHelper'
import SubmitButton from '../../../../components/mui/button/SubmitButton'
import LoadingScreen from '../../../../components/public/LoadingScreen'
import {createProductReview, editProductReview, sendErrorMessage, sendSuccessMessage} from '../../../../actions'
import {fetchSelectedProductReviews, ReviewActionType} from '../../../../actions/review/reviewActions'
import {copyToClipboard} from '../../../../utils/Scripts'
import {copyBoardStyle} from '../../../../constants'

function ProductReviewEditor({
    isEdit = false,
    originalData = {},
    selectProduct = {},
    isOpenRead = false,
    setReadOnly = noop
}) {
    // selectProduct is the product that user clicked onto. It will be used when creating a new review.
    // originalData passing into when the user would like to edit a value.
    const [isSaving, setIsSaving] = useState(false)
    const schema = yup.object().shape(REVIEW_PRODUCT_VALIDATOR)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLoadedInitial, setIsLoadedInitial] = useState(false)
    const [loadingReviewedProducts, setLoadingReviewedProducts] = useState(true)
    const [loadingCategory, setLoadingCategory] = useState(true)
    const allReviewedCategories = useSelector(state => state.review?.reviewedCategories)
    const reviewProductFetchProps = useSelector(state => state.review?.reviewProductFetchProps)
    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })
    const dispatch = useDispatch()
    // reviewedProductsByCategory: an array of objects for reviewed products based on the category provided.
    const [reviewedProductsByCategory, setReviewedProductsByCategory] = useState(
        !isEdit ? selectProduct?.data?.category?.products : []
    )
    const [pasteObj, setPasteObj] = useState(
        isEdit
            ? {...originalData, category: originalData?.product?.category?.name, product: originalData?.product?.name}
            : {...selectProduct, category: selectProduct?.data?.category?.name, product: selectProduct?.data?.name}
    )

    const [selectedReviewedProducts, setSelectedReviewedProducts] = useState(
        isEdit ? originalData?.product : selectProduct?.data
    )
    const [selectedCate, setSelectedCate] = useState(
        !isEdit ? selectProduct?.data?.category : originalData?.data?.product?.category
    )
    const [dateValue, setDateValue] = useState(moment())
    const [loadedTime, setLoadedTime] = useState(0)
    const loadReviewedProductsData = cats => {
        if (cats) {
            setReviewedProductsByCategory(cats?.products)
        }
    }

    const onCopy = objName => {
        const value = pasteObj[objName]
        if (value) {
            copyToClipboard(
                dispatch,
                value,
                `${objName} : ${value} added to clipboard`,
                `${objName} : ${value} cannot be copied `
            )
        } else {
            sendErrorMessage(dispatch, `Null ${objName} cannot be copied `)
        }
    }

    useEffect(() => {
        if (isEdit) {
            setPasteObj(originalData)
        }
    }, [originalData])
    useEffect(() => {
        if (isEdit && Object.keys(originalData).length !== 0) {
            setReviewedProductsByCategory(originalData?.product?.category?.products)
        }
    }, [originalData])

    useEffect(() => {
        if (isEdit && originalData !== {}) {
            setPasteObj({
                ...originalData,
                category: originalData?.product?.category?.name,
                product: originalData?.product?.name
            })
        } else if (!isEdit && selectProduct !== {}) {
            setPasteObj({
                ...selectProduct,
                category: selectProduct?.data?.category?.name,
                product: selectProduct?.data?.name
            })
        }
    }, [originalData, selectProduct])
    // load data to ensure initial data must be non-empty before passing into renderForm below.

    useEffect(() => {
        // phase1: data loading... originalData and select product only change once.
        if (isEdit && Object.keys(originalData).length === 0) {
            setIsLoaded(false)
            setLoadingCategory(true)
            setLoadingReviewedProducts(true)
            setSelectedReviewedProducts(null)
            return
        }
        if (allReviewedCategories && reviewedProductsByCategory) {
            if (isEdit && Object.keys(originalData).length !== 0 && !selectedReviewedProducts) {
                setSelectedCate(originalData?.product?.category)
                setSelectedReviewedProducts(originalData?.product)
                setIsLoadedInitial(true)
                setLoadedTime(loadedTime + 1)
            }
            if (selectProduct && !isEdit && Object.keys(selectProduct).length !== 0) {
                setSelectedReviewedProducts(selectProduct?.data)
                setIsLoadedInitial(true)
            }
            setIsLoaded(true)
            setLoadingReviewedProducts(false)
            setLoadingCategory(false)
            setIsLoadedInitial(true)
        }
    }, [originalData, selectProduct])

    // Phase2: when category change, the product reset to null.
    useEffect(() => {
        if (selectedCate) {
            // case of initial loading finished, when user change select category, re-load loading reviewed products and loading category.
            if (isLoadedInitial && !isEdit) {
                setSelectedReviewedProducts(null)
                // loading reviewed products and category.
                setLoadingReviewedProducts(true)
                setLoadingCategory(true)
                loadReviewedProductsData(selectedCate)
                // after category and reviewed products finished setup.
                setLoadingCategory(false)
                setLoadingReviewedProducts(false)
            } else if (isLoadedInitial && isEdit) {
                setLoadedTime(loadedTime + 1)
                if (loadedTime > 1) {
                    setSelectedReviewedProducts(null)
                    setLoadingReviewedProducts(true)
                    loadReviewedProductsData(selectedCate)
                    setLoadingReviewedProducts(false)
                }
            }
        }
    }, [selectedCate])

    const onError = msg => {
        sendErrorMessage(dispatch, msg)
        setIsSaving(false)
    }

    const onSuccess = (msg, review) => {
        dispatch({type: ReviewActionType.FormStatus, payload: true})
        sendSuccessMessage(dispatch, msg)
        const catIndex = allReviewedCategories.indexOf(
            allReviewedCategories.filter(cat => cat.id === review?.product?.category?.id)[0]
        )

        if (reviewProductFetchProps.pid === review?.product?.id) {
            dispatch(fetchSelectedProductReviews({...reviewProductFetchProps, pid: review?.product?.id}))
        } else {
            dispatch({type: ReviewActionType.SetSelectedProductId, payload: review?.product?.id})
            dispatch({type: ReviewActionType.ChangeCategoryReviewTab, payload: catIndex})
            const productList = allReviewedCategories[catIndex]?.products
            const tabIndex = productList.indexOf(productList.filter(product => product.id === review?.product?.id)[0])
            dispatch({type: ReviewActionType.ChangeReviewTab, payload: tabIndex})
        }
    }
    const onSubmit = async formData => {
        const res = new FormData()
        res.append('name', formData?.name.trim().toLowerCase())
        res.append('score', formData?.score.toFixed(1))
        res.append('review', formData?.reviewText.trim())
        res.append('productId', selectedReviewedProducts?.id)
        res.append('datePublished', dateValue)
        if (isEdit) {
            if (originalData) {
                res.append('id', originalData?.id)
            }
            return editProductReview(res, onSuccess, onError)
        }
        return createProductReview(res, onSuccess, onError)
    }
    const renderForm = () => (
        <Grid container>
            <Grid item style={{padding: '2vh 0 0 1vw'}}>
                <Typography variant="h2" align="left">
                    {_.startCase(_.toLower(originalData?.name))}
                    {isOpenRead && (
                        <Tooltip title="Edit">
                            <IconButton
                                component="span"
                                onClick={() => {
                                    setReadOnly(false)
                                }}
                            >
                                <EditIcon size={25} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Typography>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)} style={{margin: '2vh 0 10vh 0', padding: '1px'}}>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Author Name"
                            required
                            disabled={isSaving}
                            variant="outlined"
                            defaultValue={isEdit ? _.startCase(_.toLower(originalData?.name)) : ''}
                            name="name"
                            inputRef={register}
                            error={Boolean(errors.name?.message)}
                            helperText={errors.name?.message}
                            InputProps={
                                isEdit
                                    ? {
                                          startAdornment: (
                                              <Tooltip title="Copy Link" placement="top">
                                                  <IconButton
                                                      color="primary"
                                                      size="small"
                                                      component="span"
                                                      onClick={() => onCopy('name')}
                                                  >
                                                      <Clipboard {...copyBoardStyle} />
                                                  </IconButton>
                                              </Tooltip>
                                          ),
                                          readOnly: isOpenRead,
                                          style: {cursor: 'default'}
                                      }
                                    : {}
                            }
                            onChange={e => setPasteObj({...pasteObj, name: e.target.value})}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Score"
                            required
                            disabled={isSaving}
                            InputProps={
                                isEdit
                                    ? {
                                          startAdornment: (
                                              <Tooltip title="Copy Link" placement="top">
                                                  <IconButton
                                                      color="primary"
                                                      size="small"
                                                      component="span"
                                                      onClick={() => onCopy('score')}
                                                  >
                                                      <Clipboard {...copyBoardStyle} />
                                                  </IconButton>
                                              </Tooltip>
                                          ),
                                          readOnly: isOpenRead,
                                          style: {cursor: 'default'}
                                      }
                                    : {}
                            }
                            variant="outlined"
                            defaultValue={isEdit ? originalData?.score : ''}
                            name="score"
                            inputRef={register}
                            error={Boolean(errors.score?.message)}
                            helperText={errors.score?.message}
                            onChange={e => setPasteObj({...pasteObj, score: e.target.value})}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            readOnly={Boolean(isOpenRead)}
                            // getOptionSelected={(option, value) => option?.id === value?.id}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            options={allReviewedCategories}
                            getOptionLabel={
                                // getLabel
                                option => (option ? option?.name : '')
                            }
                            autoSelect
                            disableClearable
                            onChange={(e, v) => {
                                setSelectedCate(v)
                                setPasteObj({...pasteObj, category: v?.name})
                            }}
                            value={selectedCate}
                            noOptionsText="no matching option exists"
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size="small"
                                    label="Category"
                                    name="category"
                                    InputLabelProps={{shrink: true}}
                                    required
                                    InputProps={
                                        isEdit
                                            ? {
                                                  ...params.InputProps,
                                                  startAdornment: (
                                                      <Tooltip title="Copy Link" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => onCopy('category')}
                                                          >
                                                              <Clipboard {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  ),
                                                  endAdornment: (
                                                      <>
                                                          {loadingCategory ? (
                                                              <CircularProgress color="inherit" size={20} />
                                                          ) : null}
                                                          {params.InputProps.endAdornment}
                                                      </>
                                                  )
                                              }
                                            : {...params.InputProps}
                                    }
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            readOnly={Boolean(isOpenRead)}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            options={reviewedProductsByCategory}
                            // getOptionSelected={(option, value) => option?.id === value?.id}
                            getOptionLabel={option => option?.name || ''}
                            autoSelect
                            onChange={(e, v) => {
                                setSelectedReviewedProducts(v)
                                setPasteObj({...pasteObj, product: v?.name})
                            }}
                            // inputRef={register}
                            clearOnBlur
                            selectOnFocus
                            value={selectedReviewedProducts}
                            noOptionsText="no matching options exists"
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    required
                                    size="small"
                                    label="Product Name"
                                    InputLabelProps={{shrink: true}}
                                    inputRef={register}
                                    name="productName"
                                    InputProps={
                                        isEdit
                                            ? {
                                                  ...params.InputProps,
                                                  startAdornment: (
                                                      <Tooltip title="Copy Link" placement="top">
                                                          <IconButton
                                                              color="primary"
                                                              size="small"
                                                              component="span"
                                                              onClick={() => onCopy('product')}
                                                          >
                                                              <Clipboard {...copyBoardStyle} />
                                                          </IconButton>
                                                      </Tooltip>
                                                  ),
                                                  endAdornment: (
                                                      <>
                                                          {loadingReviewedProducts ? (
                                                              <CircularProgress color="inherit" size={20} />
                                                          ) : null}
                                                          {params.InputProps.endAdornment}
                                                      </>
                                                  )
                                              }
                                            : {...params.InputProps}
                                    }
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Published Date"
                                value={dateValue}
                                readOnly={Boolean(isOpenRead)}
                                onChange={newValue => setDateValue(newValue)}
                                renderInput={params => <TextField required fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Review"
                            required
                            inputRef={register}
                            multiline
                            rows={5}
                            disabled={isSaving}
                            variant="outlined"
                            InputProps={
                                isEdit
                                    ? {
                                          startAdornment: (
                                              <Tooltip title="Copy Link">
                                                  <IconButton
                                                      color="primary"
                                                      size="small"
                                                      component="span"
                                                      onClick={() => onCopy('text')}
                                                  >
                                                      <Clipboard {...copyBoardStyle} />
                                                  </IconButton>
                                              </Tooltip>
                                          ),
                                          readOnly: isOpenRead,
                                          style: {cursor: 'default'}
                                      }
                                    : {}
                            }
                            defaultValue={isEdit ? originalData?.text : ''}
                            name="reviewText"
                            error={Boolean(errors.text?.message)}
                            helperText={errors.text?.message}
                            onChange={e => setPasteObj({...pasteObj, text: e.target.value})}
                        />
                    </Grid>

                    {!isOpenRead ? (
                        <Grid item xs={12} md={12}>
                            <Grid item>
                                <SubmitButton
                                    isSaving={isSaving}
                                    text="Submit"
                                    isSavingText="Submitting"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <div />
                    )}
                </Grid>
            </form>
        </Grid>
    )

    return <Paper>{isLoaded ? renderForm() : <LoadingScreen message="Loading...." />}</Paper>
}

export default ProductReviewEditor
