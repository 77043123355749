import {Autocomplete, debounce, TextField} from '@mui/material'
import {useEffect, useState} from 'react'
import api from '../../apis/api'

export const SearchAndSelect = ({fetchParams, renderLabel, callback, defaultSelected}) => {
    const config = {
        multiple: false,
        displayOnly: false,
        textFieldLabel: 'Enter to search'
    }
    const {multiple, displayOnly, textFieldLabel} = config
    const [args, setArgs] = useState({searchKey: ''})
    const [fetchedData, setFetchedData] = useState([])
    const [selected, setSelected] = useState(defaultSelected)
    const {url} = fetchParams

    const handleSearch = debounce(searchVal => {
        const searchStr = searchVal.trim().toLowerCase()
        // Once the input value cleared, clear the search result
        if (searchStr === '' || searchStr === undefined) {
            setFetchedData([])
            return
        }

        setArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }, 500)

    useEffect(() => {
        fetchData()
    }, [args])

    const fetchData = () => {
        api.post(url, {
            ...args
        }).then(res => {
            if (res.data?.rs) {
                setFetchedData(res.data.data)
            }
        })
    }

    const renderSelect = () => (
        <Autocomplete
            fullWidth
            filterOptions={a => a}
            multiple
            getOptionLabel={renderLabel}
            disableCloseOnSelect
            onChange={async (e, v) => {
                const uniqueArr = v.filter((obj, index, self) => index === self.findIndex(t => t.id === obj.id))
                uniqueArr.reverse()
                await Promise.all([setSelected(uniqueArr), setArgs({searchKey: ''}), callback(uniqueArr)])
            }}
            onBlur={() => {
                setFetchedData([])
            }}
            // Process the fetched data as desired before display
            options={fetchedData.reverse()}
            value={selected}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={textFieldLabel}
                    multiple={multiple}
                    disabled={displayOnly}
                    size="small"
                    required
                    onChange={e => {
                        handleSearch(e.currentTarget.value)
                    }}
                />
            )}
        />
    )

    return renderSelect()
}

export default SearchAndSelect
