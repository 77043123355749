import React, {useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {FormControlLabel, FormGroup, FormHelperText, Paper, TextField} from '@mui/material'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import ReplyIcon from '@mui/icons-material/Reply'
import moment from 'moment'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import MyCkEditor from '../../../components/ckeditor/MyCkEditor'
import {sendSMS, classActionsType, homeworkReview, sendEmail} from '../../../actions/class/classActions'
import LXGrid from '../../../components/mui/datagrid/XGrid'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'
import ViewHead from '../../../components/public/ViewHead'
import {Functions} from '../../../utils/Functions'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    chip: {
        margin: theme.spacing(0.5)
    },
    section1: {
        margin: theme.spacing(3, 2)
    },
    section2: {
        margin: theme.spacing(2)
    },
    section3: {
        margin: theme.spacing(3, 1, 1)
    },
    text: {
        width: '50%'
    }
}))

function ReadHomework({isEdit, oneClass, oneHomework, homeworkReview, sendSMS, user, sendEmail}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [suggestion, setSuggestion] = useState('')
    const [score, setScore] = useState(5)
    const [status, setStatus] = useState(false)
    const [submissionId, setSubmissionId] = useState(-1)
    const [isLoaded, setIsLoaded] = useState(false)
    const [review, setReview] = useState(false)
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: false
    })

    const handleChange = event => {
        setState({...state, [event.target.name]: event.target.checked})
    }

    const handleEdit = id => {
        setStatus(true)
        setSubmissionId(id)
        setSuggestion(`${oneHomework[0].submissions.find(item => item.id === id).studentNotes}`)
    }

    const handleEdit2 = id => {
        setStatus(true)
        setSubmissionId(id)
        setSuggestion(`${oneHomework[0].requirements}`)
    }

    const handleRead = id => {
        setStatus(true)
        setReview(true)
        setSubmissionId(id)
        setSuggestion(oneHomework[0].submissions.find(item => item.id === id).teacherNotes)
        setScore(oneHomework[0].submissions.find(item => item.id === id).score)
    }

    const columns = [
        {
            field: '_actionField',
            headerName: ' ',
            resizable: false,
            disableClickEventBubbling: true,
            filterable: false,
            width: 100,
            renderCell: params => (
                <div style={{position: 'relative', width: '100%'}}>
                    <div className={classes.funcCell}>
                        {isEdit && params.row.status === 'submitted' && (
                            <Tooltip title="Edit" placement="top">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    size="small"
                                    onClick={event => {
                                        event.stopPropagation()
                                        handleEdit(params.row.id)
                                    }}
                                >
                                    <BorderColorIcon size={15} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {!isEdit && params.row.status === 'unsubmitted' && (
                            <Tooltip title="Reply" placement="top">
                                <IconButton
                                    component="span"
                                    size="small"
                                    style={{marginLeft: 10}}
                                    onClick={event => {
                                        event.stopPropagation()
                                        handleEdit2(params.row.id)
                                    }}
                                >
                                    <ReplyIcon size={15} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {params.row.status === 'reviewed' && (
                            <Tooltip title="Read" placement="top">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    size="small"
                                    style={{marginLeft: 10}}
                                    onClick={event => {
                                        event.stopPropagation()
                                        handleRead(params.row.id)
                                    }}
                                >
                                    <VisibilityIcon size={15} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </div>
            )
        },
        {field: 'id', headerName: 'Id', width: 50, sortable: false},
        {field: 'student', headerName: 'Student Name', width: 150},
        {field: 'status', headerName: 'Status', width: 150},
        {
            field: 'updated',
            headerName: 'Last Update',
            width: 200,
            valueFormatter: ({value}) => moment(value).format('L LT')
        },
        {field: 'score', headerName: 'Score', width: 100},
        {
            field: 'alertDate',
            headerName: 'alertDate',
            width: 200,
            valueFormatter: ({value}) => value && moment(value).format('L LT')
        }
    ]
    const rows = oneHomework
        ? oneHomework[0]?.submissions?.map(item => ({
              id: item.id,
              student: `${item.student.firstName} ${item.student.lastName}`,
              status: item.status,
              updated: item.updatedAt,
              score: item.score,
              alertDate: item.alertDate
          }))
        : []
    const data = {columns, rows}
    const handleSuggestion = async () => {
        const message = `Please read your homework review`

        const suggestionData = {
            classId: oneClass.data.id,
            homeworkId: oneHomework[0].id,
            studentId: oneHomework[0].submissions.find(item => item.id === submissionId).student.id,
            submissionId,
            status: user.roleId === 7 ? 'submitted' : 'reviewed',
            teacherNotes: suggestion,
            studentNotes: suggestion,
            score: parseInt(score, 10)
        }

        await homeworkReview(suggestionData)
        if (isEdit && !review && state.checkedA) {
            sendSMS(
                {
                    id: submissionId,
                    users: [oneHomework[0].submissions.find(item => item.id === submissionId).student.id],
                    message,
                    isOnDebug: state.checkedC
                },
                oneClass.data.id,
                oneHomework[0].id,
                msg => {
                    setIsLoaded(false)
                    sendSuccessMessage(dispatch, msg)
                },
                msg => sendErrorMessage(dispatch, msg)
            )
        }

        if (isEdit && !review && state.checkedB) {
            sendEmail(
                {
                    id: submissionId,
                    user: oneHomework[0].submissions.find(item => item.id === submissionId).student.id,
                    message: suggestion,
                    isOnDebug: state.checkedC
                },
                oneClass.data.id,
                oneHomework[0].id,
                msg => {
                    setIsLoaded(false)
                    sendSuccessMessage(dispatch, msg)
                },
                msg => sendErrorMessage(dispatch, msg)
            )
        }
    }

    const renderButton = () => (
        <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => {
                dispatch({type: classActionsType.SetClassTableStatus, payload: 'READ'})
            }}
        >
            Back To Delivery
        </Button>
    )

    if (isLoaded) {
        return <div />
    }
    return (
        <ViewHead
            functionId={Functions.Training_Management_Homework_Information}
            topButtons={renderButton()}
            noMaxWidth
        >
            <div>
                <div className={classes.root}>
                    <Typography variant="h5" color="textPrimary">
                        Homework Delivered
                    </Typography>
                </div>

                <Paper className={classes.paper}>
                    <LXGrid
                        {...data}
                        totalHeight={400}
                        checkboxSelection
                        disableSelectionOnClick
                        sortModel={[{field: 'updated', sort: 'desc'}]}
                    />
                </Paper>

                {status ? (
                    <Grid containter="true">
                        <Typography gutterBottom variant="body1">
                            Homework Suggestions
                        </Typography>

                        <MyCkEditor
                            data={suggestion}
                            readOnly={review}
                            onChange={(evt, editor) => {
                                setSuggestion(editor.getData())
                            }}
                        />
                        <br />
                        <br />
                        {isEdit && !review ? (
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        className={classes.text}
                                        name="score"
                                        type="number"
                                        id="outlined-helperText"
                                        label="Input Score"
                                        inputProps={{min: 1, max: 5}}
                                        value={score}
                                        variant="outlined"
                                        onChange={evt => {
                                            setScore(evt.target.value)
                                        }}
                                    />
                                    <FormHelperText>Score should be more than 0</FormHelperText>
                                </Grid>
                                <FormGroup column="true">
                                    <FormControlLabel
                                        control={
                                            <Switch checked={state.checkedC} onChange={handleChange} name="checkedC" />
                                        }
                                        label="Test Mode"
                                    />
                                    <FormHelperText>SMS will actually be sent when switched off</FormHelperText>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.checkedA}
                                                onChange={handleChange}
                                                name="checkedA"
                                                color="primary"
                                            />
                                        }
                                        label="Send Message by SMS"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.checkedB}
                                                onChange={handleChange}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Send Email to Student"
                                    />
                                </FormGroup>
                            </Grid>
                        ) : null}

                        {!review ? (
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSuggestion}
                                    >
                                        Save
                                    </Button>
                                </Grid>

                                <Grid item sm={3}>
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            setStatus(false)
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Grid>
                ) : null}
            </div>
        </ViewHead>
    )
}

const mapStateToProps = state => ({
    currentHomework: state.homework?.currentHomework,
    user: state.auth.user
})

export default connect(mapStateToProps, {
    homeworkReview,
    sendSMS,
    sendEmail
})(ReadHomework)
