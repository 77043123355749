import {useDispatch, connect} from 'react-redux'
import {useForm} from 'react-hook-form'
import React, {useState} from 'react'
import {makeStyles} from '@mui/styles'
import {Button, Grid, Paper, Typography} from '@mui/material'
import parse from 'html-react-parser'
import MyCkEditor from '../../../components/ckeditor/MyCkEditor'
import {sendErrorMessage, sendSuccessMessage, updateCustomerCompany} from '../../../actions'
import useAuthCheck from '../../../hooks/useAuthCheck'
import {Functions} from '../../../utils/Functions'
import SubmitButton from '../../../components/mui/button/SubmitButton'

const useStyles = makeStyles({
    paper: {
        padding: '2rem',
        '& h2': {
            marginBottom: '2rem'
        }
    },
    marginTop: {
        marginTop: '1.5rem'
    },
    headerText: {
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: '16px',
        lineHeight: '25px'
    }
})

function EditCustomerCompany({currentCustomer, customerTableStatus}) {
    const {handleSubmit} = useForm()
    const [canEdit] = useAuthCheck([Functions.Customer_Management_Registered_Information_Edit_Customer])

    const dispatch = useDispatch()
    const classes = useStyles()

    const [isSaving, setIsSaving] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [companyDetails, setCompanyDetails] = useState(currentCustomer.companyDetails || '')

    const onSubmit = () => {
        setIsSaving(true)
        updateCustomerCompany(
            {id: currentCustomer.id, companyDetails: companyDetails.trim()},
            customerTableStatus,
            onSuccess,
            onError
        )(dispatch)
    }

    const onSuccess = () => {
        setIsEdit(false)
        setIsSaving(false)
        sendSuccessMessage(dispatch, 'User company updated')
    }

    const onError = msg => {
        setIsSaving(false)
        sendErrorMessage(dispatch, msg)
    }

    const renderForm = () => (
        <form onSubmit={handleSubmit(onSubmit)}>
            <MyCkEditor
                data={companyDetails}
                readOnly={isSaving}
                onChange={(evt, editor) => setCompanyDetails(editor.getData())}
            />

            <Grid container spacing={3} direction="row" justifyContent="flex-end" className={classes.marginTop}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={isSaving}
                        style={{backgroundColor: 'indianred'}}
                        onClick={() => setIsEdit(false)}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <SubmitButton
                        isSaving={isSaving}
                        text="Submit"
                        isSavingText="Submitting"
                        fullWidth
                        variant="contained"
                        color="primary"
                    />
                </Grid>
            </Grid>
        </form>
    )

    const renderContent = () => {
        let compRes
        if (companyDetails === '') {
            compRes = <Typography variant="h3">No company details</Typography>
        } else {
            compRes = (
                // <div className={classes.headerText}
                //      dangerouslySetInnerHTML={{ __html: currentCustomer.companyDetails }} />
                <div className={classes.headerText}>{parse(currentCustomer.companyDetails || '')}</div>
            )
        }
        return (
            <div>
                {compRes}
                {canEdit && (
                    <Grid container spacing={3} direction="row" justifyContent="flex-end" className={classes.marginTop}>
                        <Grid item>
                            <Button color="primary" variant="contained" onClick={() => setIsEdit(true)}>
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </div>
        )
    }

    return <Paper className={classes.paper}>{isEdit ? renderForm() : renderContent()}</Paper>
}

const mapStateToProps = state => ({
    customerTableStatus: state.customer?.customerTableStatus
})

export default connect(mapStateToProps, {})(EditCustomerCompany)
