import React from 'react'
import {Button} from '@mui/material'
import ViewHead from '../../../components/public/ViewHead'
import {Functions} from '../../../utils/Functions'
import AddCustomer from './AddCustomer'
import history from '../../../utils/history'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'

function AddCustomerPage() {
    useCurrentViewCheck(Functions.Customer_Management_Registered_Information_Add_Customer)
    const returnButtons = () => (
        <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => {
                history.goBack()
            }}
        >
            Return
        </Button>
    )

    const renderTable = () => <AddCustomer />

    return (
        <ViewHead
            noMaxWidth
            functionId={Functions.Customer_Management_Registered_Information_Add_Customer}
            topButtons={returnButtons()}
            mes="Add Customer"
        >
            {renderTable()}
        </ViewHead>
    )
}

export default AddCustomerPage
