import React from 'react';
import { Card, Typography } from '@mui/material';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useCardListStyles = makeStyles((theme) => ({
  cardRoot: {
    ...theme.typography.body2,
    textAlign: 'left',
    padding: '1rem 2rem',
    lineHeight: '30px'
  },
  disabledCard: {
    '& > *': {
      opacity: '0.7',
      '& p': {
        color: 'grey'
      }
    },
    backgroundColor: `${theme.palette.background.dark}7d!important`
  },
  cardRow: {
    flexFlow: 'row wrap',
    display: 'flex',
    alignItems: 'center',
    margin: '0.25rem 0',
    '& .title': {
      fontWeight: 'bold',
      marginRight: '0.5rem'
    }
  }
}));

function CardRow({ title, body }) {
  const classes = useCardListStyles()
  return (
    <Box className={classes.cardRow}>
      {
        title &&
        <Typography variant='body2' className='title'>{title}:</Typography>
      }
      <Box>{body}</Box>
    </Box>
  )
}

function CardListCard({ rows, disabled = false }) {
  const classes = useCardListStyles()
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        lineHeight: 2
      }}
    >
      <Card elevation={2} className={clsx(classes.cardRoot, {
        [classes.disabledCard]: disabled
      })}>
        {
          rows.map((row,index) =><CardRow key={index} title={row.title} body={row.body} />)

        }
      </Card>
    </Box>
  )
}

CardListCard.propTypes = {
  rows:  PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.node.isRequired,
  })).isRequired,
  disabled: PropTypes.bool
}
CardListCard.defaultProps = {
  disabled: false
}

export default CardListCard
