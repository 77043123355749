import React, { useState } from 'react';
import {
  FormControlLabel,
  Grid, IconButton,
  Modal,
  Paper, Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip,
  Typography
} from '@mui/material';
import { PlusCircle as PlusIcon } from 'react-feather';
import Button from '@mui/material/Button';
import { Delete, Edit } from '@mui/icons-material';
import { useInvoiceEditorStyles } from '../invoiceHelper';
import InvoiceEditorItemsDialogue from './InvoiceEditorItemsDialogue';

function InvoiceEditorItems({ tax, setTax, items, setItems, disabled }) {

  const classes = useInvoiceEditorStyles()
  const [openModal, setModalOpen] = useState(false)
  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => {
    setEditIndex(-1)
    setModalOpen(false)
  }

  const [editIndex, setEditIndex] = useState(-1)

  const editItem = (idx) => {
    setEditIndex(idx)
    handleModalOpen()
  }


  const deleteItem = (idx) => setItems(items.filter((e, i) => i !== idx))

  const renderTable = () =>
    <TableContainer component={Paper}>
      <Table size="small">

        <TableHead>
          <TableRow>
            <TableCell>Quantity</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Unit Price</TableCell>
            <TableCell>Tax Amount</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell align='right' />
          </TableRow>
        </TableHead>

        <TableBody>
          { items.map((item, idx) =>
            <TableRow key={item.toString()}>
              <TableCell>
                { item?.quantity }
              </TableCell>

              <TableCell>
                { item?.description }
              </TableCell>

              <TableCell>
                { item?.price.toFixed(2) }
              </TableCell>

              <TableCell>
                {
                  tax?
                    ((+(item?.price || 0) * +(item?.quantity || 0))*0.13).toFixed(2):`0.00`

                }
              </TableCell>

              <TableCell>
                { item?.discount === undefined ? '0.00':item?.discount.toFixed(2)}
              </TableCell>


              <TableCell>
                {
                  tax?
                    (item?.discount === undefined ?
                      ((+(item?.price || 0) * +(item?.quantity || 0 ) )*1.13.toFixed(2))
                      :((+(item?.price || 0) * +(item?.quantity || 0 ) )*1.13.toFixed(2)- +(item?.discount || 0).toFixed(2)).toFixed(2))
                    :
                    (item?.discount === undefined ?
                      ((+(item?.price || 0) * +(item?.quantity || 0 )).toFixed(2))
                      :((+(item?.price || 0) * +(item?.quantity || 0 )).toFixed(2)- +(item?.discount || 0).toFixed(2)).toFixed(2))
                }
              </TableCell>


              <TableCell>
                { item?.notes }
              </TableCell>

              <TableCell align='right'>
                <Tooltip title='Edit' placement='top'>
                  <IconButton color='primary'
                              disabled={disabled}
                              size='small'
                              component='span'
                              onClick={() => editItem(idx)}>
                    <Edit fontSize='small' />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Delete' placement='top'>
                  <IconButton color='primary'
                              disabled={disabled}
                              size='small'
                              component='span'
                              onClick={() => deleteItem(idx)}>
                    <Delete fontSize='small' />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>

  const renderAddButton = () =>
    <Grid container justifyContent='center' alignItems='center' spacing={3}>
      <Grid item>
        <FormControlLabel
          className={classes.marginTop}
          control={
            <Switch
              disabled={disabled}
              size="small"
              checked={tax}
              onChange={() => setTax()}
            />
          }
          label="Tax"
        />
      </Grid>
      <Grid item>
        <Button color="primary"
                variant="text"
                disabled={disabled}
                style={{ color: disabled ? 'lightgray' : 'forestgreen' }}
                className={classes.marginTop}
                onClick={() => handleModalOpen()}
                startIcon={<PlusIcon />}>
          Add Item
        </Button>
      </Grid>
    </Grid>

  return (
    <Grid container justifyContent='flex-end'>
      <Grid item xs={12}>
        { renderTable() }
      </Grid>
      <Grid item>
        { renderAddButton() }
      </Grid>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        className={classes.modal}
        disablePortal
        disableRestoreFocus
        disableEnforceFocus
        disableAutoFocus
        closeAfterTransition
      >
        <Paper className={classes.itemAdder}>
          <Typography variant='h2' className={classes.marginBottom}>Item</Typography>
          <InvoiceEditorItemsDialogue
            itemIdx={editIndex}
            items={items}
            setItems={setItems}
            onClose={handleModalClose} />
        </Paper>
      </Modal>
    </Grid>
  )
}

export default InvoiceEditorItems
