import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Bar, Pie } from 'react-chartjs-2';
import Grid from '@mui/material/Grid';
import {
  genOverviewByBrowserData,
  genOverviewByCatData,
  genOverviewByDeviceData,
  genOverviewByUserChartData
} from './trackingChartsGenerator';
import { useChartStyles } from './trackingHelper';
import LoadingScreen from '../../../components/public/LoadingScreen';

function TrackingOverviewTabView({ data, isLoaded, isShowActive, isShowLegend }) {

  const classes = useChartStyles()

  const pieGraphOptions = {
    plugins: {
      legend: {
        display: isShowLegend,
        position: 'right'
      }
    }
  }

  const barGraphOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }

  const [overviewByUserAData, setOverviewByUserAData] = useState(null)
  const [overviewByUserCData, setOverviewByUserCData] = useState(null)
  const [overviewByUserFData, setOverviewByUserFData] = useState(null)
  const [overviewByUserQData, setOverviewByUserQData] = useState(null)

  const [overviewByCData, setOverviewByCData] = useState(null)
  const [overviewByFData, setOverviewByFData] = useState(null)
  const [overviewByQData, setOverviewByQData] = useState(null)
  const [overviewByUDData, setOverviewByUDData] = useState(null)
  const [overviewByUBData, setOverviewByUBData] = useState(null)
  const [overviewByUCoData, setOverviewByUCoData] = useState(null)
  const [overviewByUCiData, setOverviewByUCiData] = useState(null)


  useEffect(() => {
    if (data) {
      const tempFormData = data.filter(list => !!list.form_id)
      const tempCampaignData = data.filter(list => !!list.campaign_id)
      const tempQRCodeData = data.filter(list => !!list.qrCode_id)

      const tempOverviewByUserAData = genOverviewByUserChartData(data, isShowActive, ['user_isActive'])
      const tempOverviewByUserCData = genOverviewByUserChartData(tempCampaignData, isShowActive, ['user_isActive', 'campaign_isActive'])
      const tempOverviewByUserFData = genOverviewByUserChartData(tempFormData, isShowActive, ['user_isActive', 'form_isActive'])
      const tempOverviewByUserQData = genOverviewByUserChartData(tempQRCodeData, isShowActive, ['user_isActive', 'qrCode_isActive'])

      const tempOverviewByCData = genOverviewByCatData(tempCampaignData, 'campaignCategory_name', isShowActive, ['campaign_isActive'])
      const tempOverviewByFData = genOverviewByCatData(tempFormData, 'formCategory_name', isShowActive, ['form_isActive'])
      const tempOverviewByQData = genOverviewByCatData(tempQRCodeData, 'qrCodeCategory_name', isShowActive, ['qrCode_isActive'])
      const tempOverviewByUDData = genOverviewByDeviceData(tempFormData, 'tracker_device', isShowActive, ['form_isActive', 'campaign_isActive'])
      const tempOverviewByUBData = genOverviewByBrowserData(tempFormData, 'tracker_browser', isShowActive, ['form_isActive', 'campaign_isActive'])
      const tempOverviewByUCoData = genOverviewByCatData(tempFormData, 'tracker_country', isShowActive, ['form_isActive', 'campaign_isActive'])
      const tempOverviewByUCiData = genOverviewByCatData(tempFormData, 'tracker_city', isShowActive, ['form_isActive', 'campaign_isActive'])

      setOverviewByUserAData(tempOverviewByUserAData)
      setOverviewByUserCData(tempOverviewByUserCData)
      setOverviewByUserFData(tempOverviewByUserFData)
      setOverviewByUserQData(tempOverviewByUserQData)

      setOverviewByCData(tempOverviewByCData)
      setOverviewByFData(tempOverviewByFData)
      setOverviewByQData(tempOverviewByQData)
      setOverviewByUDData(tempOverviewByUDData)
      setOverviewByUBData(tempOverviewByUBData)
      setOverviewByUCoData(tempOverviewByUCoData)
      setOverviewByUCiData(tempOverviewByUCiData)
    }
  }, [data])

  return (
    <div>
      {
        isLoaded
          ?  <div>
            {
              overviewByUserAData?.labels?.length > 0 &&
              <div>
                <Typography variant='h3' className={classes.title}>User</Typography>
                <Grid container spacing={1} justifyContent='space-around' className={classes.marginBottom}>
                  <Grid item xs={12} sm={6} xl={3}>
                    <Typography variant='h4'>All</Typography>
                    <Pie type='pie'
                         className={classes.pieGraph}
                         data={overviewByUserAData}
                         options={pieGraphOptions}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={3}>
                    <Typography variant='h4'>Campaign</Typography>
                    <Pie type='pie'
                         className={classes.pieGraph}
                         data={overviewByUserCData}
                         options={pieGraphOptions}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={3}>
                    <Typography variant='h4'>Form</Typography>
                    <Pie type='pie'
                         className={classes.pieGraph}
                         data={overviewByUserFData}
                         options={pieGraphOptions}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={3}>
                    <Typography variant='h4'>QR Code</Typography>
                    <Pie type='pie'
                         className={classes.pieGraph}
                         data={overviewByUserQData}
                         options={pieGraphOptions}
                    />
                  </Grid>
                </Grid>
              </div>
            }

            {
              overviewByCData?.labels?.length > 0 &&
              <div>
                <Typography variant='h3' className={classes.title}>Campaign</Typography>
                <Grid container spacing={1} className={classes.marginBottom}>
                  <Grid item xs={12}>
                    <Bar type='bar'
                         className={classes.barGraph}
                         data={overviewByCData}
                         options={barGraphOptions}
                    />
                  </Grid>
                </Grid>
              </div>
            }

            {
              overviewByFData?.labels?.length > 0 &&
              <div>
                <Typography variant='h3' className={classes.title}>Form</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Bar type='bar'
                         className={classes.barGraph}
                         data={overviewByFData}
                         options={barGraphOptions}
                    />
                  </Grid>
                </Grid>
              </div>
            }

            {
              overviewByQData?.labels?.length > 0 &&
              <div>
                <Typography variant='h3' className={classes.title}>QR Code</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Bar type='bar'
                         className={classes.barGraph}
                         data={overviewByQData}
                         options={barGraphOptions}
                    />
                  </Grid>
                </Grid>
              </div>
            }

            {
              overviewByUDData?.labels?.length > 0 &&
              <div>
                <Typography variant='h3' className={classes.title}>Customers</Typography>
                <Grid container spacing={1} justifyContent='space-around' className={classes.marginBottom}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant='h4'>Device</Typography>
                    <Pie type='pie'
                         className={classes.pieGraph}
                         data={overviewByUDData}
                         options={pieGraphOptions}
                    />
                  </Grid>


                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant='h4'>Browser</Typography>
                  <Pie type='pie'
                       className={classes.pieGraph}
                       data={overviewByUBData}
                       options={pieGraphOptions}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant='h4'>Country</Typography>
                  <Pie type='pie'
                       className={classes.pieGraph}
                       data={overviewByUCoData}
                       options={pieGraphOptions}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='h4'>City</Typography>
                  <Bar type='bar'
                       className={classes.barGraph}
                       data={overviewByUCiData}
                       options={barGraphOptions}
                  />
                </Grid>
              </Grid>
            </div>
          }
          </div>
          : <LoadingScreen message='Loading...' />
      }
    </div>
  )
}

export default TrackingOverviewTabView
