import _ from 'lodash';
import {AuthActionType} from "../../actions";


export const authReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case AuthActionType.Login:
            return {...state, success: true, error: undefined};
        case AuthActionType.Logout:
            return defaultState;
        case AuthActionType.AuthCheck:
            return {
                ...state, success: true,
                user: action.payload.user,
                auth: action.payload.auth,
                error: undefined
            }
        case AuthActionType.AuthError:
            return {...state, success: false, user: null, error: action.payload, responseAt: new Date()}
        case AuthActionType.FetchMenu:
            return {...state, menu: action.payload.menu}
        case AuthActionType.FetchUserRole:
            return {...state, role: action.payload}
        case AuthActionType.AuthUser:
            return {...state, authUser: action.payload}
        case AuthActionType.FetchNavi:
            return _.chain(state)
                .cloneDeep()
                .set(["navi", action.payload.key], action.payload.value)
                .value();
        default:
            return state;
    }

}

const defaultState = {
    user: undefined,
    error: undefined,
    success: undefined,
    menu: [],
    auth: [],
    role: undefined,
    navi: {},
    authUser: undefined
}
export default authReducer