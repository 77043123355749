import { sendErrorMessage, sendSuccessMessage } from '../actions';

// For charts
const colorArray = ["#619ED6", "#6BA547", "#C6A516", "#E48F1B", "#B77EA3", "#E64345", "#60CEED", "#9CF168", "#F7EA4A", "#FBC543", "#FFC9ED", "#E6696E"]
export const getColorForGraph = (length) => Array.from({length}, (e, i) => colorArray[i % colorArray.length])


// Scripts related to testing/obtaining browser features
export const getTotalHeight = () =>
  (document.height !== undefined)
    ? document.height
    : document.body.offsetHeight



export const getTotalWidth = () =>
  (document.width !== undefined)
    ? document.width
    : document.body.offsetWidth

export const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)


// String manipulation
export const capitalizeFirstChar = (str) => typeof str === 'string' && str.length > 0 ? str[0].toUpperCase() + str.slice(1) : ''

export const spaceTrimmer = (str) => typeof str === 'string' ? str.replace(/ /g, '_') : ''
export const slashTrimmer = (str) =>
  typeof str === 'string' ? str.replace(/[\\|]/g, '_') : ''

export const getBasicHash = () => Math.random().toString(16).substring(2, 10)

// General utilities
export const copyToClipboard = (dispatch, textToCopy,
                                successMessage = 'Copied to clipboard',
                                errorMessage = 'Cannot be copied to clipboard') => {
  try {
    if (navigator.clipboard) {
      // For most modern browsers
      navigator.clipboard.writeText(textToCopy)
        .then(
          () => sendSuccessMessage(dispatch, successMessage),
          () => sendErrorMessage(dispatch, errorMessage)
        )
    } else {
      // For old browsers and iOS
      const copyTextArea = document.createElement('textarea')
      copyTextArea.value = textToCopy
      document.body.appendChild(copyTextArea)
      copyTextArea.select()
      document.execCommand('copy')
      document.body.removeChild(copyTextArea)
      sendSuccessMessage(dispatch, successMessage)
    }
  } catch (e) {
    sendErrorMessage(dispatch, errorMessage)
  }
}

export const isOverflown = (e) => e
  ? (e.scrollHeight > e.clientHeight || e.scrollWidth > e.clientWidth)
  : false
