import api, {api2} from "../../apis/api";
import { sendErrorMessage, sendSuccessMessage } from '..';
import { pagedGridDefaultFetch } from '../../components/mui/datagrid/PagedGridAction';

export const trainingMessageActionsTypes={
    SetTrainingMessageFormStatus:'SetTrainingMessageFormStatus',
    AddTrainingMessage:'AddTrainingMessage',
    FetchTrainingMessage:'FetchTrainingMessage',
    FetchOneTrainingMessage:'FetchOneTrainingMessage',
    UpdateOneTrainingMessage:'UpdateOneTrainingMessage',
    FetchClass: 'FetchClass',
    FetchOneMessageInfo: 'FetchOneMessageInfo',
    ActiveMessage: 'ActiveMessage',

    FetchStudentMessage: 'FetchStudentMessage',
    DeleteStudentMessage: 'DeleteStudentMessage',
    ConfirmStudentMessage: 'ConfirmStudentMessage',
    FetchCurrentStudentMessage: 'FetchCurrentStudentMessage',
    FetchTrainingMessageProps: 'FetchTrainingMessageProps'

}

export const createTrainingMessage=(data,success,error)=>async dispatch=>{
   try{
       const res = await api.post('/message/createMessage',data)
       if(res.data){
           sendSuccessMessage(dispatch, `Message ${res.data.data.content} has been Created`);
           success()
           dispatch({type:trainingMessageActionsTypes.AddTrainingMessage,payload:res.data})
       }
   }
   catch (e) {
      // console.log('this is  error',e)
       error(e)
   }
}
export const fetchTrainingMessage = args => async dispatch => {

    try {

        const trainingMessageUrl = '/message/messageWithSubmissions'
        const res = await pagedGridDefaultFetch(trainingMessageUrl, args, trainingMessageActionsTypes.FetchTrainingMessageProps)(dispatch)
        if (res.data?.rs) {
            dispatch({ type: trainingMessageActionsTypes.FetchTrainingMessage, payload: res.data })
            return true;
        } 
            throw (res.data.message)
        
    }
    catch (e) {
        sendErrorMessage(dispatch, e.message)
        return false
    }
}

export const fetchClass=()=>async dispatch=>{
    try{
        const res=await api.post('/classes/fetchclass', {"isActive": true})
        if(res.data.data){
            dispatch({type:trainingMessageActionsTypes.FetchClass,payload:res.data.data})
        }
        return true;
    }
    catch (e) {
        // console.log('this is the error',e)
        return false;
    }
}
export const fetchOneMessage=(id,cb)=>async dispatch=>{
    try{
       const res=await api.get(`/message/messageWithSubmissions/${id}`)
        if(res.data.data){
            dispatch({type:trainingMessageActionsTypes.FetchOneTrainingMessage,payload:res.data.data})
        }
        cb()
    }
    catch (e) {
       // console.log(e)
    }
}

export const fetchMessageInfo=(id,cb)=>async dispatch=>{
    try{
        const res = await api.get(`/message/messageWithSubmissions/${id}`)
        if(res.data.data){
            dispatch({type:trainingMessageActionsTypes.FetchOneMessageInfo,payload:res.data.data})
        }
        cb()
    }
    catch (e) {
        // console.log(e)
    }
}

export const updateOneMessage=(id,data,success,error)=>async dispatch=>{
   try{
       const res=await api.put(`/message/updateMessage/${id}`,data)
       if(res.data){
           sendSuccessMessage(dispatch, `Message ${res.data.data.content} has been updated.`);
           success()
           dispatch({type:trainingMessageActionsTypes.UpdateOneTrainingMessage,payload:res.data})
       }
   }
   catch (e) {
       error(e)
      // console.log(e)
   }

}

export const fetchStudentMessage = () => async (dispatch) => {
    try {
        const res = await api.get('/message/messageSubmissionStudent');
        dispatch({type: trainingMessageActionsTypes.FetchStudentMessage, payload: res.data.data})
        return true
    }
    catch (e) {
        // console.log(e);
        return false
    }
}

export const deleteMessage=(obj,fetchProps)=> async dispatch=>{
    try{
        // console.log('this is the obj',obj)
        const res=await api.delete('/message/deleteMessage',{data:obj})
        if(res.data){
            const refresh=await dispatch(fetchTrainingMessage(fetchProps))
            if(refresh){
                sendSuccessMessage(dispatch,"Message has been deleted")
            }
        }
    }
    catch (e) {
       // console.error('Error from deleteMessage',e)
    }
}

export const deleteStudentMessage = (id) => async dispatch=> {
    try {
        const response = await api2.delete(`/studentMessage/${id}`);
        if (response.data) {
            dispatch({type: trainingMessageActionsTypes.DeleteStudentMessage, payload: id});
            sendSuccessMessage(dispatch, `Message ${response.data.key} has been deleted.`)
        }
    } catch (e) {
        // console.log(e);
    }
}

export const confirmStudentMessage = (message) => async dispatch=> {
    try {
        const response = await api.put(`/message/submitMessageSubmission`, message);
        if (response.data) {
            const refresh=await dispatch(fetchStudentMessage())
            if(refresh) {
                sendSuccessMessage(dispatch, `Message has been confirmed.`)
                dispatch({type: trainingMessageActionsTypes.ConfirmStudentMessage, payload: response.data})
            }
        else{
            // console.log('something went wrong')
            }
        }
    } catch (e) {
        // console.log(e);
    }
}
export const activateMessages = (obj, fetchProps) => async dispatch => {
    try {
        const res = await api.post('message/updateStatus', obj);
        const {data} = res
        if (data) {
            const refresh=await dispatch(fetchTrainingMessage(fetchProps))
            if(refresh){
                dispatch({type: trainingMessageActionsTypes.ActiveMessage, payload: res.data})
                if(obj.isActive) {
                    sendSuccessMessage(dispatch,`ID: ${obj.ids} have been activated`)
                } else {
                    sendSuccessMessage(dispatch,`ID: ${obj.ids} have been deactivated`)
                }

            }
            else{
                // console.log('there is some errors')
            }
        }
        // console.log(res.data);
    }
    catch (e) {
        // console.log(e);
    }
}
