import React, { useEffect, useRef, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {
    FormControlLabel,
    FormHelperText,
    Grid,
    Paper,
    Switch,
    TextField,
    Typography,
    Autocomplete,
    Box,
    Checkbox,
    FormGroup,
    IconButton,
    Select,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { useTranslation } from 'react-i18next'
import { DragIndicator } from '@mui/icons-material'
import Chip from '@mui/material/Chip'
import ReactDraggable from 'react-draggable'
import clsx from 'clsx'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import FormControl from '@mui/material/FormControl'
import parse from 'html-react-parser'
import {sendErrorMessage} from '../../../actions'
import SubmitButton from '../../../components/mui/button/SubmitButton'
import {
    CONTRACT_AUTO_PLACEHOLDERS,
    CONTRACT_CREATE_VALIDATOR,
    CONTRACT_FIELDS_IN_CKEDITOR,
    CONTRACT_USER_INFO_FIELD,
    PRODUCT_DETAIL_FIELDS_IN_CKEDITOR,
    useContractEditorStyles,
    useContractViewStyles,
    USER_FIELDS_IN_CKEDITOR
} from './contractHelper';
import {
    createContract,
    fetchAllContractTemplates,
    fetchCurrency,
    fetchCustomer,
    fetchProducts,
    updateContract
} from '../../../actions/administrative/administrativeAction'
import {MAX_TEXT_LENGTH, ServerBaseURL} from '../../../constants'
import MyCkEditor from '../../../components/ckeditor/MyCkEditor'

const schema = yup.object().shape(CONTRACT_CREATE_VALIDATOR)
function ContractTemplateEditor({isEdit, customerId, originalData, onSubmitSuccess, mainContract = -1}) {
    const {register, handleSubmit, errors, setError} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })

    const { t, i18n } = useTranslation()

    const classes = useContractEditorStyles()
    const viewClasses = useContractViewStyles()
    const dispatch = useDispatch()

    const allProducts = useSelector(state => state.administrative.allProducts)
    const currentCustomer = useSelector(state => state.administrative.currentCustomer)
    const allTemplates = useSelector(state => state.administrative?.allContractTemplates?.data || [])
    // currency from reducer
    const allCurrency = useSelector(state => state.administrative.allCurrency)

    const [isLoaded, setIsLoaded] = useState(false)
    const [isProductsLoaded, setIsProductsLoaded] = useState(false)
    const [isCustomerLoaded, setIsCustomerLoaded] = useState(false)
    const [isTemplatesLoaded, setIsTemplatesLoaded] = useState(false)
    const [partyARep, setPartyARep] = useState(currentCustomer?.sales?.firstName ?? "")
    const [partyBRep, setPartyBRep] = useState( "")

    const [headTemplates, setHeadTemplates] = useState([])
    const [bodyTemplates, setBodyTemplates] = useState([])
    const [headHeader, setHeadHeader] = useState({partyA: '', partyB: ''})

    const [myEditor, setMyEditor] = useState(null)
    const [replacementExpanded, setReplacementExpanded] = useState(false)
    const replacementDraggableRef = useRef(null);
    const [replacementPosition, setReplacementPosition] = useState({ x: 0, y: 0 });

    const [isSaving, setIsSaving] = useState(false)

    const [selectedProduct, setSelectedProduct] = useState(null)
    const [isSelectProductDirty, setIsSelectProductDirty] = useState(false)
    const [taxedPrice, setTaxedPrice] = useState('0.00')
    const [taxAmount, setTaxAmount] = useState('0.00')
    const [totalPrice, setTotalPrice] = useState('0.00')
    const [fieldState, setFieldState] = useState({
        all: true,
        contractID: true,
        name: true,
        firstName: true,
        lastName: true,
        country: true,
        phone: true,
        email: true,
        wechat: true,
        address: true,
        price: true,
        taxAmount: true,
        discount: true,
        total: true
    })
    const [contractFields, setContractFields] = useState('')
    const [isFieldOpen, setIsFieldOpen] = useState(false)
    const [isFieldOpenButtonOpen, setIsFieldOpenButtonOpen] = useState(false)

    const handleFieldOpen = () => {
        setIsFieldOpen(!isFieldOpen)
        setIsFieldOpenButtonOpen(!isFieldOpenButtonOpen)
    }

    const handleCurrencyChange = (event, value) => {
        setProductDetails({...productDetails, currency: value ? value.id : null})
    }

    const handleChange = event => {
        setFieldState({
            ...fieldState,
            [event.target.name]: event.target.checked
        })
    }

    const [productDetails, setProductDetails] = useState(
        isEdit
            ? {
                  name: originalData.name,
                  price: originalData.price,
                  tax: originalData.tax,
                  notes: originalData.notes,
                  taxRate: (+originalData.taxRate / 100).toString(),
                  taxAmount: +originalData.taxAmount / 100,
                  discount: +originalData.discount / 100,
                  total: +originalData.total / 100,
                  currency: originalData?.currency?.id ? originalData.currency.id : 1
              }
            : {
                  name: '',
                  price: '',
                  tax: true,
                  notes: '',
                  taxRate: '1.13',
                  taxAmount: '0',
                  discount: '0',
                  total: '0',
                  currency: 1
              }
    )

    const [selectedContract, setSelectedContract] = useState(null)
    const [selectedHead, setSelectedHead] = useState(null)
    // const [contractText, setContractText] = useState(isEdit ? originalData.text : '')
    const [contractBody, setContractBody] = useState(isEdit ? originalData.modifiedContractBody : '')

    const loadData = () => {
        ;(async () => {
            await fetchCurrency()(dispatch)
            setIsProductsLoaded(await fetchProducts()(dispatch))
            setIsCustomerLoaded(await fetchCustomer(customerId)(dispatch))
            setIsTemplatesLoaded(await fetchAllContractTemplates()(dispatch))
        })()
    }
    useEffect(loadData, [])
    const convertFieldsState = () => {
        const tempFields = fieldState
        Object.keys(fieldState).forEach(k => {
            if (!originalData?.fields?.includes(k)) {
                tempFields[k] = false
                tempFields.all = false
            }
        })
        setFieldState(tempFields)
        setChosenFields(Object.keys(Object.fromEntries(Object.entries(fieldState).filter(([, val]) => val === true))))
    }

    useEffect(() => {
        // Load all details
        if (isProductsLoaded && isCustomerLoaded && isTemplatesLoaded) {
            setHeadTemplates(allTemplates?.filter(template => template.type === 'head' && template.isActive).reverse())
            setBodyTemplates(allTemplates?.filter(template => template.type === 'body' && template.isActive).reverse())
            setIsLoaded(true)
            const tempPartyAHeader = `${currentCustomer?.firstName} ${currentCustomer?.lastName} (Email: ${currentCustomer?.email}, Phone: ${currentCustomer?.phone})`

            if (isEdit) {
                // load old head
                const selHead = allTemplates.find(template => template.id === originalData.head?.id)
                setSelectedHead(selHead)
                setHeadHeader({partyA: tempPartyAHeader, partyB: selHead?.partyBName})
                setSelectedProduct(allProducts.find(product => product.id === originalData.product?.id))
                setPartyBRep(selHead?.referrals && selHead?.referrals.length > 0 ? selHead?.referrals[0] : "")

                if (selHead?.language && i18n.language !== selHead.language) {
                    i18n.changeLanguage(selHead?.language)
                }
            } else {
                setHeadHeader({...headHeader, partyA: tempPartyAHeader})
            }
        }
    }, [isProductsLoaded, isCustomerLoaded, isTemplatesLoaded])

    useEffect(() => {
        // On change product
        if (isLoaded && isEdit) convertFieldsState()
        else
            setChosenFields(
                Object.keys(Object.fromEntries(Object.entries(fieldState).filter(([, val]) => val === true)))
            )
        if (isLoaded && selectedProduct && isSelectProductDirty) {
            setProductDetails({
                ...productDetails,
                name: selectedProduct.name,
                price: selectedProduct.price
            })
        }
    }, [selectedProduct, isLoaded])

    // to update product details
    useEffect(() => {
        // On tax or price change
        if (productDetails.price) {
            try {
                const price = productDetails.tax
                    ? productDetails.price * parseFloat(productDetails.taxRate)
                    : parseFloat(productDetails.price)
                setTaxedPrice(price.toFixed(2))
                const taxPartOnly = productDetails.price * (parseFloat(productDetails.taxRate) - 1)
                setTaxAmount(taxPartOnly.toFixed(2))
                const total = price - parseFloat(productDetails.discount)
                setTotalPrice(total.toFixed(2))
                if (productDetails.total !== total) {
                    setProductDetails({...productDetails, total, taxAmount: taxPartOnly.toFixed(2)})
                }
            } catch (e) {
                setTaxedPrice('0.00')
            }
        }
    }, [productDetails])

    useEffect(() => {
        if (isLoaded && selectedContract?.text) {
            setContractBody(selectedContract.text)
        }
    }, [selectedContract, isLoaded])

    const onSubmit = async formData => {
        setIsSaving(true)

        const cleanedContractText = `${contractFields}  <p>&nbsp;</p>  ${contractBody?.trim()}`
        if (cleanedContractText.length < 1) {
            setIsSaving(false)
            return setError('text', {type: 'manual', message: 'Contract cannot be blank'})
        }

        if (cleanedContractText.length > MAX_TEXT_LENGTH) {
            setIsSaving(false)
            return setError('text', {
                type: 'manual',
                message: `Contract length cannot be longer than ${MAX_TEXT_LENGTH} characters`
            })
        }

        if (!selectedHead) {
            setIsSaving(false)
            return setError('head', {type: 'manual', message: 'Contract Head is required'})
        }

        if (!selectedProduct || !selectedProduct?.id) {
            setIsSaving(false)
            return setError('product', {type: 'manual', message: 'Product is required'})
        }

        const res = {
            ...productDetails,
            productId: selectedProduct?.id,
            email: formData.email,
            text: cleanedContractText,
            modifiedContractBody: contractBody?.trim(),
            customerId: currentCustomer.id,
            fields: fieldState,
            headId: selectedHead.id
        }
        res.taxAmount = taxAmount
        res.total = totalPrice
        res.discount = parseFloat(res.discount).toFixed(2)

        // This is a sub contract
        if (mainContract !== -1) {
            res.mainId = mainContract
            res.isMain = false
        }

        if (res.tax === false) {
            res.taxAmount = '0.00'
        }
        if (isEdit) {
            res.id = originalData.id
            return updateContract(res, onSubmitSuccess, onError, customerId)(dispatch)
        }
        return createContract(res, onSubmitSuccess, onError, customerId)(dispatch)
    }

    const onError = msg => {
        sendErrorMessage(dispatch, msg)
        setIsSaving(false)
    }

    const currentCurrency = allCurrency?.filter(item => item.id === productDetails?.currency)[0]

    const [chosenFields, setChosenFields] = useState([])

    useEffect(() => {
        if (isLoaded)
            setChosenFields(
                Object.keys(Object.fromEntries(Object.entries(fieldState).filter(([, val]) => val === true)))
            )
    }, [fieldState])

    // to get productDetailFields userFields contractFields
    const productDetailFieldsInCkeditor =
        productDetails &&
        PRODUCT_DETAIL_FIELDS_IN_CKEDITOR?.map(val =>
            chosenFields?.includes(val.key)
                ? `<p>${t(val.key)}: ${currentCurrency?.symbol}${Number(productDetails[val.key])?.toFixed(2)} ${currentCurrency?.currency}</p>`
                : ''
        )?.join('')
    const userFieldsInCkeditor =
        currentCustomer &&
        USER_FIELDS_IN_CKEDITOR?.map(val =>
            chosenFields?.includes(val.key) ? `<p>${t(val.key)}: ${currentCustomer[val.key]}</p>` : ''
        )?.join('')
    const productFieldsInCkeditor =
        productDetails &&
        CONTRACT_FIELDS_IN_CKEDITOR?.map(val =>
            chosenFields?.includes(val.key) ? `<p>${t(val?.key)}: ${productDetails[val.key]}</p>` : ''
        )?.join('')
    const contractFieldsInCkeditor =
        chosenFields?.includes('contractID') ? `<p>${t('contractId')}: ${originalData?.cid ?? 'TBD'}</p>` : ''


    const renderAllSelectedFields = () =>
        `${contractFieldsInCkeditor} ${productFieldsInCkeditor} ${userFieldsInCkeditor} ${productDetailFieldsInCkeditor} <p>&nbsp;</p>`

    const renderDefaultCommomAgreement = `
      <p>${t("contractAgreementSignMessage")}</p>
      <p>&nbsp;</p>
      <p>${t("partyA")}: ${headHeader.partyA}</p>
      <p>${t("partyB")}: ${headHeader.partyB}</p>
    `

    useEffect(() => {
        setContractFields(`${renderAllSelectedFields()}<p>&nbsp;</p>${renderDefaultCommomAgreement}`)
    }, [isSelectProductDirty, productDetails, headHeader, selectedContract, chosenFields, fieldState])


    const handleAutoFillPlaceholders = () => {
        const contractAutoFillDetails = {
            "##PARTY_A_FIRST_NAME##": currentCustomer.firstName ?? "",
            "##PARTY_A_LAST_NAME##": currentCustomer.lastName ?? "",
            "##PARTY_A_EMAIL##": currentCustomer.email ?? "",
            "##PARTY_A_PHONE##": currentCustomer.phone ?? "",
            "##PARTY_A_ADDR##": currentCustomer.address ?? "",
            "##PARTY_A_CITY##": currentCustomer.city ?? "",
            "##PARTY_A_COUNTRY##": currentCustomer.country ?? "",
            "##PARTY_A_ZIPCODE##": currentCustomer.zipcode ?? "",
            "##PARTY_A_REP##": partyARep ?? "",
            "##PARTY_B_NAME##": selectedHead?.partyBName ?? "",
            "##PARTY_B_REP##": partyBRep ?? "",
            "##PRODUCT_NAME##": productDetails.name ?? "",
            "##PRODUCT_PRICE##": productDetails.price ?? "",
            "##PRODUCT_TAX##": productDetails.tax ?? "",
            "##PRODUCT_DISCOUNT##": productDetails.discount ?? "",
            "##PRODUCT_TOTAL##": productDetails.total ?? "",
        }
        const regex = new RegExp(Object.keys(contractAutoFillDetails).join('|'), 'g');
        const filledContractBody = contractBody.replace(regex, (matched) => contractAutoFillDetails[matched]);
        setContractBody(filledContractBody);
    }

    const handleToggleReplacementExpand = (isExpanded) => {
        setReplacementExpanded(isExpanded)
        if (!isExpanded && replacementDraggableRef.current) {
            setReplacementPosition({ x: 0, y: 0 })
        }
    };

    const handleInsertReplacementText = (text) => {
        if (myEditor) {
            myEditor.model.change(writer => {
                const insertPosition = myEditor.model.document.selection.getFirstPosition();
                writer.insertText(text, insertPosition);
            });
            myEditor.editing.view.focus()
        }
    }

    const renderUserInfo = () => (
        <Paper className={classes.paper}>
            <Typography variant="h2" className={classes.title}>
                User Info
            </Typography>
            <div className={clsx(classes.customerHeaderField, classes.marginBottom)}>
                {CONTRACT_USER_INFO_FIELD.map(val => (
                    <div key={val.key}>
                        <Typography variant="body1" className="name">
                            {val.value}:{' '}
                        </Typography>
                        <Typography variant="body1" className="value">
                            {currentCustomer[val.key]}
                        </Typography>
                    </div>
                ))}
            </div>
            <div>
                <FormControl fullWidth>
                    <TextField
                        fullWidth
                        size="small"
                        label="Customer Representative"
                        value={partyARep}
                        onChange={(e) => setPartyARep(e.target.value)}
                        disabled={isSaving}
                        helperText="Enter a customer rep if applicable"
                    />
                </FormControl>
            </div>
        </Paper>
    )

    const renderProduct = () => (
        <Paper className={classes.paper}>
            <Typography variant="h2" className={classes.title}>
                Product
            </Typography>
            <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.product?.message)}>
                <Autocomplete
                    disabled={isSaving}
                    options={allProducts}
                    noOptionsText="No Product"
                    getOptionLabel={option => `${option.name} ($ ${option.price})`}
                    value={selectedProduct}
                    onChange={(e, v) => {
                        setIsSelectProductDirty(true)
                        setSelectedProduct(v)
                    }}
                    renderInput={params => (
                        <TextField {...params} label="Select a product..." variant="outlined" size="small" required />
                    )}
                />
                <FormHelperText error={Boolean(errors.product?.message)}>
                    {errors.product?.message ? errors.product?.message : ''}
                </FormHelperText>
            </FormControl>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.marginBottom}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Name"
                            required
                            value={productDetails.name}
                            onChange={e => setProductDetails({...productDetails, name: e.currentTarget.value})}
                            disabled={isSaving}
                            name="name"
                            inputRef={register}
                            error={Boolean(errors.name?.message)}
                            helperText={errors.name?.message}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.marginBottom}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Notes"
                            value={productDetails.notes}
                            disabled={isSaving}
                            onChange={e => setProductDetails({...productDetails, notes: e.currentTarget.value})}
                            name="notes"
                            inputRef={register}
                            error={Boolean(errors.notes?.message)}
                            helperText={errors.notes?.message}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={3}>
                            <FormControl fullWidth className={classes.marginBottom}>
                                <Autocomplete
                                    disableClearable
                                    value={allCurrency.find(currency => currency.id === productDetails.currency)}
                                    options={allCurrency}
                                    getOptionLabel={option => option.currency}
                                    onChange={handleCurrencyChange}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Currency"
                                            variant="outlined"
                                            size="small"
                                            name="currency"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth className={classes.marginBottom}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Price"
                                    required
                                    value={productDetails.price}
                                    onChange={e => setProductDetails({...productDetails, price: e.currentTarget.value})}
                                    disabled={isSaving}
                                    type="number"
                                    name="price"
                                    inputRef={register}
                                    error={Boolean(errors.price?.message)}
                                    helperText={errors.price?.message}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth className={classes.marginBottom}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    disabled
                                    label="Total"
                                    name="price-total"
                                    value={totalPrice}
                                    onChange={e =>
                                        setProductDetails({...productDetails, total: e.currentTarget.value, taxAmount})
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={1}>
                            <FormControlLabel
                                className={classes.marginBottom}
                                control={
                                    <Switch
                                        disabled={isSaving}
                                        size="small"
                                        checked={productDetails.tax}
                                        onChange={() =>
                                            setProductDetails({...productDetails, tax: !productDetails.tax})
                                        }
                                    />
                                }
                                label="Tax"
                            />
                        </Grid>
                        {/* extended tax options */}
                        <Grid item xs={4}>
                            <FormControl fullWidth className={classes.marginBottom}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Discount"
                                    name="price-discount"
                                    value={productDetails.discount}
                                    type="number"
                                    onChange={e =>
                                        e.currentTarget.value !== ''
                                            ? setProductDetails({
                                                  ...productDetails,
                                                  discount: JSON.stringify(Math.abs(parseFloat(e.currentTarget.value)))
                                              })
                                            : setProductDetails({...productDetails, discount: '0'})
                                    }
                                />
                            </FormControl>
                        </Grid>
                        {productDetails.tax && (
                            <Grid item xs={4}>
                                <FormControl fullWidth className={classes.marginBottom}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Tax Rate"
                                        name="price-tax-rate"
                                        value={productDetails.taxRate}
                                        type="number"
                                        onChange={e =>
                                            setProductDetails({...productDetails, taxRate: e.currentTarget.value})
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        )}

                        {productDetails.tax && (
                            <Grid item xs={4}>
                                <FormControl fullWidth className={classes.marginBottom}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        disabled
                                        label="Tax Amount"
                                        name="price-taxed"
                                        value={Number.parseFloat(taxedPrice - productDetails.price).toFixed(2)}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )

    const {contractID, name, firstName, lastName, country, phone, email, wechat, address, price, discount, total} = fieldState
    const checkAll =
        contractID &&
        name &&
        firstName &&
        lastName &&
        country &&
        phone &&
        email &&
        wechat &&
        address &&
        price &&
        fieldState?.taxAmount &&
        total &&
        discount

    const renderFields = () => (
        <Paper className={classes.paper}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Typography variant="h2" className={classes.title}>
                    Fields
                </Typography>

                <IconButton
                    onClick={handleFieldOpen}
                    sx={{
                        backgroundColor: 'transparent',
                        borderRadius: '50%',
                        height: '30px',
                        width: '30px'
                    }}
                >
                    {isFieldOpenButtonOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            </Box>

            {isFieldOpen && (
                <Box sx={{display: 'flex'}}>
                    <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                        <FormGroup>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkAll}
                                                // onChange={handleChange}
                                                onChange={() => {
                                                    if (checkAll) {
                                                        setFieldState({
                                                            all: false,
                                                            contractID: false,
                                                            name: false,
                                                            firstName: false,
                                                            lastName: false,
                                                            country: false,
                                                            phone: false,
                                                            email: false,
                                                            wechat: false,
                                                            address: false,
                                                            price: false,
                                                            taxAmount: false,
                                                            total: false,
                                                            discount: false
                                                        })
                                                    } else {
                                                        setFieldState({
                                                            all: true,
                                                            contractID: true,
                                                            name: true,
                                                            firstName: true,
                                                            lastName: true,
                                                            country: true,
                                                            phone: true,
                                                            email: true,
                                                            wechat: true,
                                                            address: true,
                                                            price: true,
                                                            taxAmount: true,
                                                            discount: true,
                                                            total: true
                                                        })
                                                    }
                                                }}
                                                name="all"
                                            />
                                        }
                                        label="All"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={contractID} onChange={handleChange} name="contractID" />
                                        }
                                        label="Contract ID"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={name} onChange={handleChange} name="name" />}
                                        label="Product"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={firstName} onChange={handleChange} name="firstName" />
                                        }
                                        label="First Name"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={lastName} onChange={handleChange} name="lastName" />
                                        }
                                        label="Last Name"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={country} onChange={handleChange} name="country" />}
                                        label="Country"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={phone} onChange={handleChange} name="phone" />}
                                        label="Phone"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={email} onChange={handleChange} name="email" />}
                                        label="Email"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={wechat} onChange={handleChange} name="wechat" />}
                                        label="Wechat"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={address} onChange={handleChange} name="address" />}
                                        label="Address"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={price} onChange={handleChange} name="price" />}
                                        label="Price"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={fieldState?.taxAmount}
                                                onChange={handleChange}
                                                name="taxAmount"
                                            />
                                        }
                                        label="Tax Value"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={discount} onChange={handleChange} name="discount" />
                                        }
                                        label="Discount"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={total} onChange={handleChange} name="total" />}
                                        label="Total"
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                        <FormHelperText>You can uncheck fields to hide them in contract </FormHelperText>
                        <Grid item xs={12} sm={4} md={3}>
                            <>{parse(contractFields || '')}</>
                        </Grid>
                    </FormControl>
                </Box>
            )}
        </Paper>
    )

    const renderFormHead = () => (
        <Paper className={classes.paper}>
            <Typography variant="h2" className={classes.title}>
                Head
            </Typography>
            <FormControl fullWidth className={classes.autofillForm} error={Boolean(errors.head?.message)}>
                <Autocomplete
                    disabled={isSaving}
                    options={headTemplates}
                    noOptionsText="No Head"
                    getOptionLabel={option => option.name}
                    onChange={(e, v) => {
                        setHeadHeader({...headHeader, partyB: v?.partyBName || ''})
                        setSelectedHead(v)
                        setPartyBRep(v?.referrals && v?.referrals.length > 0 ? v?.referrals[0] : "")

                        if (v?.language && i18n.language !== v.language) {
                            i18n.changeLanguage(v?.language)
                        }
                    }}
                    renderInput={params => (
                        <TextField {...params} label="Select a head..." variant="outlined" size="small" />
                    )}
                />
                <FormHelperText error={Boolean(errors.head?.message)}>
                    {errors.head?.message ? errors.head?.message : ''}
                </FormHelperText>
            </FormControl>
            {selectedHead && (
                <Grid container spacing={5} direction="row" alignItems="flex-start">
                    <Grid item xs={12} className={classes.editor}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                {/* <div className={viewClasses.headerText} */}
                                {/*     dangerouslySetInnerHTML={{ __html: selectedHead?.text }} /> */}
                                <div className={viewClasses.headerText}>{parse(selectedHead?.text || '')}</div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={viewClasses.headerImage}>
                                <img
                                    alt="header logo for company"
                                    src={`${ServerBaseURL}/asset/contractTemplate/headerImage/${selectedHead?.id}.png`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        selectedHead.referrals &&
                        <Grid item xs={12}>
                            <FormHelperText>
                                Representative(s): {selectedHead.referrals.join(", ")}.
                                <br/>
                                Select representative for Party B below if applicable.
                            </FormHelperText>
                            <FormControl fullWidth>
                                <Select
                                    native
                                    fullWidth
                                    size="small"
                                    value={partyBRep}
                                    onChange={(e) => setPartyBRep(e.target.value)}
                                >
                                    {selectedHead.referrals.map((ref) => (
                                        <option key={ref} value={ref}>{ref}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                </Grid>
            )}
        </Paper>
    )

    const renderForm = () => (
        <Paper className={classes.paper}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h2" className={classes.title}>
                    Contract
                </Typography>
                <Button size='small' variant='contained' onClick={handleAutoFillPlaceholders}>
                    AutoFill Placeholders
                </Button>
            </Box>

            <FormControl fullWidth className={classes.autofillForm}>
                <Autocomplete
                    disabled={isSaving}
                    options={bodyTemplates}
                    noOptionsText="No Contract"
                    getOptionLabel={option => option.name}
                    onChange={(e, v) => {
                        setSelectedContract(v)
                    }}
                    renderInput={params => (
                        <TextField {...params} label="Select a contract..." variant="outlined" size="small" />
                    )}
                />
            </FormControl>
            <Grid container spacing={5} direction="row" alignItems="flex-start">
                <Grid item xs={12}>
                    <ReactDraggable
                        handle=".drag-handle"
                        disabled={!replacementExpanded}
                        position={replacementPosition}
                        onStop={(e, data) => setReplacementPosition({ x: data.x, y: data.y })}
                    >
                        <Grid
                            item
                            xs={12}
                            ref={replacementDraggableRef}
                            style={{ position: "relative", zIndex: 20 }}
                        >
                            <Accordion expanded={replacementExpanded} >
                                <AccordionSummary expandIcon={null}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}
                                    >
                                        <Box sx={{ display: 'flex' }}>
                                            <div className='drag-handle'>
                                                <Typography><DragIndicator /></Typography>
                                            </div>
                                            <Typography>Replacement Text</Typography>
                                        </Box>
                                        <IconButton
                                            onClick={() => handleToggleReplacementExpand(!replacementExpanded)}
                                            sx={{
                                                backgroundColor: 'transparent',
                                                borderRadius: '50%',
                                                height: '30px',
                                                width: '30px'
                                            }}
                                        >
                                            {replacementExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                        </IconButton>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        spacing={2}
                                        rowSpacing={1}
                                    >
                                        {
                                            CONTRACT_AUTO_PLACEHOLDERS.map((placeholder) => (
                                                <Grid key={placeholder.key} item xs={12} sm={6} md={3} xl={2}>
                                                    <Chip
                                                        onClick={() => handleInsertReplacementText(placeholder.key)}
                                                        style={{ width: '100%' }}
                                                        color='primary'
                                                        label={placeholder.value}
                                                    />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </ReactDraggable>
                </Grid>
                <Grid item xs={12} className={classes.editor}>
                    <MyCkEditor
                        data={contractBody}
                        readOnly={isSaving}
                        onChange={(evt, editor) => setContractBody(editor.getData())}
                        onReady={editor => setMyEditor(editor)}
                    />
                    <FormHelperText error={Boolean(errors.text?.message)}>
                        {errors.text?.message ? errors.text?.message : ''}
                    </FormHelperText>
                </Grid>
            </Grid>
        </Paper>
    )

    const renderSubmit = () => (
        <Paper className={classes.paper}>
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Customer Email"
                        required
                        defaultValue={isEdit ? originalData.email : currentCustomer.email}
                        disabled={isSaving}
                        name="email"
                        inputRef={register}
                        error={Boolean(errors.email?.message)}
                        helperText={errors.email?.message}
                    />
                </Grid>
                <Grid item>
                    <SubmitButton
                        isSaving={isSaving}
                        text="Save Contract"
                        isSavingText="Saving"
                        fullWidth
                        variant="contained"
                        color="primary"
                    />
                </Grid>
            </Grid>
        </Paper>
    )

    return (
        <div>
            {isLoaded ? (
                <div>
                    {renderUserInfo()}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {renderProduct()}
                        {renderFormHead()}
                        {renderFields()}
                        {renderForm()}
                        {renderSubmit()}
                    </form>
                </div>
            ) : (
                <Typography variant="h2">Loading.. .</Typography>
            )}
        </div>
    )
}

export default ContractTemplateEditor
