import React, {useState} from 'react';
import {FormHelperText, Input, InputAdornment} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Done} from '@mui/icons-material';
import {noteMaxLength, noteMinLength} from "./NoteData";

function AddNoteInput({
                          id, name, cb,
                          label = 'Note Name',
                          placeholder = 'Enter a Note name',
                          disabled = false
                      }) {


    const [fieldValue, setFieldValue] = useState(name)
    const [error, setError] = useState('')
    const [isError, setIsError] = useState(false)

    const onFieldSubmit = (e) => {
        e.preventDefault()
        if (fieldValue.trim().length > noteMaxLength
            || fieldValue.trim().length < noteMinLength) {
            setIsError(true)
            setError(`Note length should be between ${noteMinLength} and ${noteMaxLength} characters`)
            // setError('Note length should be between 1 and 30 characters')
        } else {
            cb(id, fieldValue)
            setFieldValue('')
        }
    }

    const handleChange = (e) => {
        const {value} = e.target
        if (value.length > noteMaxLength
            || value.length < noteMinLength) {
            setIsError(true)
            setError(`Note length should be between ${noteMinLength} and ${noteMaxLength} characters`)
            setFieldValue(value)
        } else {
            setFieldValue(value)
            setIsError(false)
            setError('')
        }
    }

    return (
        <form onSubmit={onFieldSubmit}>
            <Input fullWidth id="note-field" disabled={disabled}
                   name="noteName" label={label}
                   size="small" value={fieldValue}
                   onChange={handleChange}
                   placeholder={placeholder}
                   endAdornment={
                       <InputAdornment position="end">
                           <IconButton type='submit' size="large">
                               <Done style={{color: 'green'}}/>
                           </IconButton>
                       </InputAdornment>
                   }
            />
            <FormHelperText error={isError}>{error}</FormHelperText>
        </form>
    );
}

export default AddNoteInput
