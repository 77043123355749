import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { Check } from '@mui/icons-material';
import { fetchAllRoles } from '../../../actions';
import ViewHead from '../../../components/public/ViewHead';
import PageGrid from '../../../components/mui/datagrid/PageGrid';
import { fetchAllLoginHistory } from '../../../actions/administrative/administrativeAction';
import { Functions } from '../../../utils/Functions';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import SearchBox from '../../../components/public/SearchBox';

const useHistoryLoginStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  search: {
    position: 'relative',
    margin: '20px 0 10px 0',
    width: '100%',
    padding: theme.spacing(1, 0)
  },
}));

function YesSymbol() {
  return <Check style={{ color: '#546e7a'}} />
}
// function NoSymbol() {
//   return <Close style={{ color: 'rgba(0, 0, 0, 0.38)'}} />
// }
function LoginHistory() {
  useCurrentViewCheck(Functions.Administrative_Login_History)

  const classes = useHistoryLoginStyles()
  const dispatch = useDispatch()
  const allLoginHistory = useSelector(state => state.administrative.allLoginHistory)
  const allUserRoles = useSelector(state => state.customer.allUserRoles)
  const [isLoading, setIsLoading] = useState(false)

  const loadData = () => {
    // only attempt to fetch roles if there are no roles
    if (allUserRoles.length <= 0) {
      fetchAllRoles()(dispatch)
    }
  };
  useEffect(loadData, [dispatch, allUserRoles])

  const [searchArgs, setSearchArgs] = useState({
    searchKey: undefined
  });

  const onSearch = (searchVal) => setSearchArgs(prevState =>
    ({ ...prevState, searchKey: searchVal.toLowerCase().trim() }))

  const searchHandler = debounce((searchVal) => {
    onSearch(searchVal)
  }, 500)
  const renderSearchContainer = () => <Grid item xs={12}>
      <div className={classes.search}>
        <SearchBox
          placeholder='Search...'
          size='small'
          type='search'
          searchHandler={searchHandler}
          isLoading={isLoading}
        />
      </div>
    </Grid>

  const renderGrid = () => {
    const columns = [
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'user', headerName: 'User', width: 280,
        valueFormatter: ({ value }) =>
          `${value?.firstName}${value?.nickName ? ` (${value.nickName}) ` : ' '}${value?.lastName}` },
      { field: 'createdAt', headerName: 'Date', width: 200,
        valueFormatter: ({ value }) => moment(value).format('L LT')},
      { field: 'isStaff', headerName: 'Staff', width: 120,
        renderCell: (params) => params.row?.user?.isStaff ? <YesSymbol /> : ''},
      { field: 'isCustomer', headerName: 'Customer', width: 120,
        renderCell: (params) => params.row?.user?.isCustomer ? <YesSymbol /> : ''},
      {
        field: 'roleIds', headerName: 'Roles', width: 180,
        valueFormatter: ({ value }) => value.map(roleId =>
            allUserRoles.find(role => role.id.toString() === roleId.toString())?.name
            || 'Unknown')
            .join(', ')
      },
      { field: 'ip', headerName: 'IP', width: 200 },
      { field: 'device', headerName: 'Device', width: 250 },
      { field: 'browser', headerName: 'Browser', width: 250 },
      { field: 'city', headerName: 'City', width: 200 },
      { field: 'country', headerName: 'Country', width: 200 },
      { field: 'postalCode', headerName: 'Postal', width: 200 },
      { field: 'address', headerName: 'Address', width: 350 },
    ]

    return (
      <PageGrid
        columns={columns}
        datas={allLoginHistory}
        searchArgs={searchArgs}
        fetchDatas={fetchAllLoginHistory}
        setIsLoading={setIsLoading}
      />
    )
  }

  return (
    <ViewHead functionId={Functions.Administrative_Login_History} noMaxWidth>
      <Paper elevation={0} className={classes.paper}>
        { renderSearchContainer() }
        { renderGrid() }
      </Paper>
    </ViewHead>
  )
}

export default LoginHistory
