import Editor from 'ckeditor5-custom-build/build/ckeditor'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import {useEffect, useState} from 'react'
import {noop} from 'lodash'
import ckEditorConfig from './ckEditor-config'
import ReactDOM from 'react-dom'
import Term from './Term'
import api from '../../apis/api'
import {sendErrorMessage} from '../../actions'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
    editor: {
        '& ol': {
            paddingLeft: '16px'
        }
    }
}))

function MyCkEditor({
    data,
    onChange,
    onReady = noop,
    sendUploadFileStatus = noop,
    status,
    setModalOpen = noop,
    setCurrentTerm = noop
}) {
    const [uploadFileStatus, setUploadFileStatus] = useState(status)

    useEffect(() => sendUploadFileStatus(uploadFileStatus), [uploadFileStatus])
    useEffect(() => setUploadFileStatus(status), [status])
    const classes = useStyles()

    return (
        <div className={classes.editor}>
            <CKEditor
                editor={Editor}
                config={{
                    ...ckEditorConfig,
                    fileUploads: {
                        handleVisibleDialog: bool => {
                            setUploadFileStatus(bool)
                        }
                    },
                    terms: {
                        termRenderer: async (id, domElement) => {
                            try {
                                const res = await api.get(`administrative/contract/term/${id}`)
                                if (res.data.rs) {
                                    ReactDOM.render(
                                        <Term
                                            term={res.data.data}
                                            setModalOpen={setModalOpen}
                                            setCurrentTerm={setCurrentTerm}
                                        />,
                                        domElement
                                    )
                                    return true
                                }
                            } catch (e) {
                                sendErrorMessage(`cannot insert term - ${id} `)
                                return false
                            }
                        }
                    }
                }}
                data={data}
                onReady={editor => {
                    // 1.to make sure ckeditor balloon panel work well with draggable dialog mui,
                    // resetting ckeditor balloon panel class' zIndex to 9999 when editor instance
                    // was ready
                    if (editor) {
                        document.querySelector('.ck-balloon-panel').style.zIndex = 999999
                    }
                    onReady(editor)
                }}
                onChange={onChange}
            />
        </div>
    )
}

export default MyCkEditor
