import React, {useEffect, useRef, useState} from 'react'
import {Button, Grid, ListItemIcon, Menu, MenuItem, Paper} from '@mui/material'
import {Archive, Edit as EditIcon, Eye, PenTool, Video} from 'react-feather'
import {connect, useDispatch, useSelector} from 'react-redux'
import IconButton from '@mui/material/IconButton'
import moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import {AttachFile, MoreVert} from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import {useNavigate} from 'react-router-dom'
import EnhancedTableToolbar from './EnhancedTableToolBar'
import {
    activateCustomer,
    fetchAllContractForCustomer,
    fetchAllRoles,
    fetchCustomer,
    fetchCustomerCheck,
    fetchCustomers,
    getCustomerLatestResume,
    sendErrorMessage
} from '../../../actions'
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog'
import {dataTableStyles} from '../customerData'
import useAuthCheck from '../../../hooks/useAuthCheck'
import {Functions} from '../../../utils/Functions'
import {CATEGORY_ITEMS, NONE} from '../../../constants'
import PageGrid from '../../../components/mui/datagrid/PageGrid'
import {DetailModal} from '../interview/interviewHelper'
import CheckCustomerFloat from './CheckCustomerFloat'
import DraggableDialog from '../../../components/mui/dialog/DraggableDialog'
import CheckCustomerPage from './CheckCustomerPage'
import EditCustomerPage from './EditCustomerPage'
import {fetchClasses} from '../../../actions/customer/customerActions'
import ContractDisplayLoader from '../../administrative/contract/contractPreview/ContractDisplayLoader'
import {
    closeContract,
    downloadUnsignedContractPdf,
    fetchContract
} from '../../../actions/administrative/administrativeAction'
import LoadingScreen from '../../../components/public/LoadingScreen'
import Tags from '../../../components/public/Tags'
import {fetchTagsByGroup} from '../../../actions/public/tagActions'

function DataTable({customers, allUserRoles, activationMessage, getCustomerLatestResume}) {
    const navigate = useNavigate()
    const classes = dataTableStyles()
    const dispatch = useDispatch()

    const allTags = useSelector(state => state.tag.tagByGroup)
    const [canEditCustomerTag] = useAuthCheck([Functions.Interview_Edit])

    const [isContractLoaded, setIsContractLoaded] = useState(false)

    const [clickedCustomer, setClickedCustomer] = useState('')
    const [openView, setOpenView] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)

    const [selected, setSelected] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [rowMoreButton, setRowMoreButton] = useState(null)

    const [clickedClass, setClickedClass] = useState({})
    const [clickedContract, setClickedContract] = useState({})
    // const [classDisplayList, setClassDisplayList] = useState([])
    // const [contractDisplayList, setContractDisplayList] = useState([])

    const contractDisplayList = []
    const classDisplayList = []

    // const [checkboxSelected, setCheckboxSelected] = useState([])
    const [isLoading, setIsLoading] = React.useState(false)

    const [openContract, setOpenContract] = useState(false)
    const currentContract = useSelector(state => state.administrative.currentContract)

    // const eachClassChipWidth = useRef(null)
    // const[maxClassWidth,setMaxClassWidth] = useState(0)

    // const [maxContractCellWidth, setMaxContractCellWidth] = useState(0);
    // const[maxClassCellWidth,setMaxClassCellWidth] = useState(0)

    const [eachColumnMaxWidth, setEachColumnMaxWidth] = useState({contractColumnMaxWidth: 0, classColumnMaxWidth: 0})

    // useEffect(() => {
    //     if(customers.data){
    //         // setMaxClassCellWidth(0)
    //         // setMaxContractCellWidth(0)
    //     }
    // }, [customers.data]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!customers) return
            const contractColumnMaxWidth = calculateMaxWidthForGivenColumn('contractCellContainer')
            const classColumnMaxWidth = calculateMaxWidthForGivenColumn('classCellContainer')
            if (contractColumnMaxWidth === undefined || classColumnMaxWidth === undefined) return
            setEachColumnMaxWidth(prev => ({...prev, contractColumnMaxWidth, classColumnMaxWidth}))
        }, 0)

        return () => clearTimeout(timer)
    }, [customers.data])

    const calculateMaxWidthForGivenColumn = columnClassName => {
        const nodeList = document.querySelectorAll(`.${columnClassName}`)
        if (nodeList.length !== 0) {
            let maxWidth = nodeList[0]?.clientWidth

            // get the max width for given column
            nodeList.forEach(eachCell => {
                if (eachCell?.clientWidth > maxWidth) maxWidth = eachCell?.clientWidth
            })
            return maxWidth
        }
        return 0
    }

    const oneClassChipRef = useRef(null)

    const [canEditCustomer, canCreateContract, canTraceCustomer, canCreateInterview] = useAuthCheck([
        Functions.Customer_Management_Registered_Information_Edit_Customer,
        Functions.Administrative_Contract_Create,
        Functions.Customer_Management_Tracing,
        Functions.Interview_Create
    ])

    const [searchArgs, setSearchArgs] = useState({
        searchKey: undefined,
        category: CATEGORY_ITEMS.all,
        isActive: undefined
    })
    const fetchProps = useSelector(state => state?.customer.customerFetchBody)
    const [activation, setActivation] = useState(CATEGORY_ITEMS.all)
    const handleSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase()
        setSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }

    // const allContracts = useSelector(state => state?.customer.allContracts)

    const loadData = () => {
        // only attempt to fetch roles if there are no roles
        if (allUserRoles.length <= 0) {
            fetchAllRoles()(dispatch)
        }
    }

    useEffect(loadData, [dispatch, allUserRoles])

    const loadDataTag = () => {
        ;(async () => {
            const group = 'Customer'
            setIsLoading(await fetchTagsByGroup(group)(dispatch))
        })()
    }

    useEffect(loadDataTag, [])

    useEffect(() => {
        fetchClasses()(dispatch)
        fetchAllContractForCustomer()(dispatch)
    }, [])

    const [isSending, setIsSending] = useState(false)
    const onDownloadUnsigned = () => {
        setIsSending(true)
        downloadUnsignedContractPdf(
            currentContract.cid,
            parseInt(currentContract.customer.id, 10),
            currentContract.name,
            `${currentContract.customer.firstName}_${currentContract.customer.lastName}`,
            () => setIsSending(false),
            msg => {
                sendErrorMessage(dispatch, msg)
                setIsSending(false)
            }
        )(dispatch)
    }

    const handleCategoryChange = newCategory =>
        setSearchArgs(prevState => ({
            ...prevState,
            category: newCategory === 'Staff' ? true : newCategory === 'Customer' ? false : CATEGORY_ITEMS.all
        }))

    const setActiveStatus = status =>
        setSearchArgs(prevState => ({
            ...prevState,
            isActive: status
        }))

    const handleActiveChange = isActive => {
        if (isActive === 'Active') {
            setActiveStatus(true)
            setActivation('Active')
        } else if (isActive === 'Inactive') {
            setActiveStatus(false)
            setActivation('Inactive')
        } else {
            setActiveStatus(undefined)
            setActivation(CATEGORY_ITEMS.all)
        }
    }

    const handleMoreButtonClick = (e, usr) => {
        setSelectedUser(usr)
        setRowMoreButton(e.currentTarget)
    }

    const onActive = isActive => {
        const data = {ids: selected, isActive}
        activateCustomer(data, fetchProps)(dispatch)
    }

    useEffect(() => {
        if (activationMessage) {
            //  setMessage(true)
            setSelected([])
        }
    }, [activationMessage])

    // const [open, setOpen] = useState(false);
    // const [hash, setHash] = useState('init');

    const open = false
    const hash = 'init'

    // const handleDelete = () => {
    //   setOpen(true);
    //   setHash(Math.random().toString(16).substring(2,10));
    // }
    const handleClassNameClickForClass = name => {
        let sameClass = clickedClass
        const classChecked = []
        if (name in sameClass) {
            if (delete sameClass[name]) {
                setClickedClass(sameClass)
            }
            // delete sameClass[name] && setClickedClass(sameClass)
        } else {
            setClickedClass(() => {
                sameClass = {
                    ...sameClass,
                    [name]: name
                }
                return sameClass
            })
        }
        // name in sameClass ? delete sameClass[name] && setClickedClass(sameClass) : setClickedClass(() => {
        //     sameClass = {
        //         ...sameClass,
        //         [name]: name
        //     }
        //     return sameClass
        // })
        Object.keys(sameClass).forEach(label =>
            classDisplayList.forEach(data =>
                label === NONE
                    ? data.class.length <= 0 && classChecked.push(data.id)
                    : data.class.forEach(
                          classInfo =>
                              classInfo.name === label &&
                              classChecked.indexOf(data.id) <= -1 &&
                              classChecked.push(data.id)
                      )
            )
        )
        setSelected(classChecked)
    }

    const handleClassNameClickForContract = name => {
        let sameClass = clickedContract
        const classChecked = []
        if (name in sameClass) {
            if (delete sameClass[name]) {
                setClickedContract(sameClass)
            }
            // delete sameClass[name] && setClickedContract(sameClass)
        } else {
            setClickedContract(() => {
                sameClass = {
                    ...sameClass,
                    [name]: name
                }
                return sameClass
            })
        }
        // name in sameClass ? delete sameClass[name] && setClickedContract(sameClass) : setClickedContract(() => {
        //     sameClass = {
        //         ...sameClass,
        //         [name]: name
        //     }
        //     return sameClass
        // })
        Object.keys(sameClass).forEach(label =>
            contractDisplayList.forEach(data =>
                label === NONE
                    ? data.class.length <= 0 && classChecked.push(data.id)
                    : data.class.forEach(
                          classInfo =>
                              classInfo.name === label &&
                              classChecked.indexOf(data.id) <= -1 &&
                              classChecked.push(data.id)
                      )
            )
        )
        setSelected(classChecked)
    }

    const fetchLatestResume = id => {
        getCustomerLatestResume(id)
    }

    const columns = []

    if (canCreateContract || canCreateInterview || canTraceCustomer) {
        columns.push({
            field: '__moreButton',
            headerName: ' ',
            resizable: false,
            disableClickEventBubbling: true,
            width: 50,
            filterable: false,
            renderCell: params => (
                <Tooltip title="More" placement="top">
                    <IconButton
                        color="primary"
                        size="small"
                        component="span"
                        onClick={e => handleMoreButtonClick(e, params.row)}
                    >
                        <MoreVert size={20} />
                    </IconButton>
                </Tooltip>
            )
        })
    }

    if (canEditCustomer) {
        columns.push({
            field: '_actionField',
            headerName: ' ',
            resizable: false,
            disableClickEventBubbling: true,
            filterable: false,
            width: 100,
            renderCell: params => (
                <div style={{position: 'relative', width: '100%'}}>
                    <div className={classes.funcCell}>
                        <Tooltip title="Read" placement="top">
                            <IconButton
                                color="primary"
                                component="span"
                                size="small"
                                style={{marginRight: 10}}
                                onClick={async event => {
                                    event.stopPropagation()
                                    await setClickedCustomer(params.row.id)
                                    await setOpenView(true)
                                }}
                            >
                                <Eye size={20} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit" placement="top">
                            <IconButton
                                color="primary"
                                component="span"
                                size="small"
                                onClick={async event => {
                                    event.stopPropagation()
                                    await setClickedCustomer(params.row.id)
                                    await setOpenEdit(true)
                                    // navigate(`/app/customer/registered/edit/${params.row.id}`);
                                }}
                            >
                                <EditIcon size={20} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            )
        })
    }

    columns.push(
        ...[
            {field: 'id', headerName: 'ID', width: 70, sortable: true},
            {
                field: 'sales',
                headerName: 'Sales',
                width: 90,
                valueFormatter: ({value}) => `${value?.firstName} ${value?.lastName}`
            },
            {
                field: 'firstName',
                headerName: 'First Name',
                width: 120,
                renderCell: params => (
                    <DetailModal
                        disabled={!params.row.isActive}
                        name={`${params.row.firstName}${params.row?.nickName ? ` (${params.row.nickName})` : ''}`}
                        details={<CheckCustomerFloat studentId={params.row.id} />}
                    />
                )
            },
            {
                field: 'lastName',
                headerName: 'Last Name',
                width: 120,
                renderCell: params => (
                    <DetailModal
                        disabled={!params.row.isActive}
                        name={params.row.lastName}
                        details={<CheckCustomerFloat studentId={params.row.id} />}
                    />
                )
            },

            // Tag for customer
            {
                field: 'tag',
                headerName: 'Tags',
                width: 100,
                renderCell: params => (
                    <Tags
                        datas={allTags}
                        current={params.row.id}
                        fetchData={fetchTagsByGroup}
                        canEdit={canEditCustomerTag}
                        group="customer"
                        loadData={loadDataTag}
                    />
                )
            },

            {field: 'email', headerName: 'Email', width: 200},
            {field: 'phone', headerName: 'Phone', width: 120},
            {
                field: 'class',
                headerName: 'Class',
                width: eachColumnMaxWidth.classColumnMaxWidth + 50,

                minWidth: 100,
                renderCell: params => (
                    <Box className="classCellContainer">
                        {params.row?.class?.length > 0 ? (
                            params?.row?.class?.map((c, index) => (
                                <Chip
                                    ref={oneClassChipRef}
                                    label={c.name}
                                    size="small"
                                    variant={clickedClass?.[c.name] ? 'filled' : 'outlined'}
                                    color={clickedClass?.[c.name] ? 'primary' : 'default'}
                                    key={index}
                                    onClick={() => handleClassNameClickForClass(c.name)}
                                />
                            ))
                        ) : (
                            <Chip
                                label={NONE}
                                size="small"
                                sx={{display: 'none'}}
                                variant={clickedClass?.[NONE] ? 'filled' : 'outlined'}
                                color={clickedClass?.[NONE] ? 'primary' : 'default'}
                                onClick={() => handleClassNameClickForClass(NONE)}
                            />
                        )}
                    </Box>
                )
            },
            {
                field: 'contract',
                headerName: 'Contract',
                width: eachColumnMaxWidth.contractColumnMaxWidth + 50,

                minWidth: 100,
                renderCell: params => (
                    <Box className="contractCellContainer">
                        {params.row?.contract?.length > 0 ? (
                            params?.row?.contract?.map((c, index) => (
                                <Chip
                                    label={c.contract_cid}
                                    size="small"
                                    variant={clickedContract?.[c.contract_cid] ? 'filled' : 'outlined'}
                                    color={clickedContract?.[c.contract_cid] ? 'primary' : 'default'}
                                    key={index}
                                    onClick={async () => {
                                        handleClassNameClickForContract(c.contract_cid)
                                        await setClickedCustomer(params.row.id)
                                        await setOpenContract(true)
                                        setIsContractLoaded(
                                            await fetchContract(c.contract_customerId, c.contract_cid)(dispatch)
                                        )
                                    }}
                                />
                            ))
                        ) : (
                            <Chip
                                label={NONE}
                                size="small"
                                sx={{display: 'none'}}
                                variant={clickedContract?.[NONE] ? 'filled' : 'outlined'}
                                color={clickedContract?.[NONE] ? 'primary' : 'default'}
                                onClick={() => handleClassNameClickForContract(NONE)}
                            />
                        )}
                    </Box>
                )
            },
            {field: 'isStaff', headerName: 'Staff', width: 80, type: 'boolean'},
            {field: 'isCustomer', headerName: 'Customer', width: 120, type: 'boolean'},
            {
                field: 'roleIds',
                headerName: 'Roles',
                width: 100,
                valueFormatter: ({value}) =>
                    value
                        .map(
                            roleId =>
                                allUserRoles.find(role => role.id.toString() === roleId.toString())?.name || 'Unknown'
                        )
                        .join(', ')
            },
            {
                field: 'createdAt',
                headerName: 'Join Date',
                width: 200,
                valueFormatter: ({value}) => moment(value).format('L LT')
            },
            {
                field: 'file',
                headerName: 'Resume',
                width: 105,
                sortable: false,
                renderCell: params => {
                    if (params.row.resume?.length > 0) {
                        return (
                            <Tooltip title="Resume" placement="top">
                                <IconButton size="small" onClick={() => fetchLatestResume(params.row.id)}>
                                    <AttachFile fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                    return ''
                }
            },
            {
                field: 'status',
                headerName: 'Status',
                width: 100,
                renderCell: params => params.row.userStatus
            }
        ]
    )

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    isLoading={isLoading}
                    numSelected={selected.length}
                    onActive={onActive}
                    setSelectedCate={handleCategoryChange}
                    setSelectedStatus={handleActiveChange}
                    selectedCate={searchArgs.category}
                    selectedStatus={activation}
                    handleChange={handleSearchChange}
                />

                <div style={{width: '100%'}}>
                    <PageGrid
                        columns={columns}
                        datas={customers}
                        searchArgs={searchArgs}
                        fetchDatas={fetchCustomers}
                        selected={selected}
                        setSelected={setSelected}
                        setIsLoading={setIsLoading}
                    />

                    <ResponsiveDialog
                        isOpen={open}
                        openHash={hash}
                        title="Delete Parameter Confirm"
                        content="Are you sure?"
                        Buttons={[
                            {
                                name: 'Yes',
                                event: () => {
                                    // deleteCustomer(delId)(dispatch);
                                }
                            },
                            {
                                name: 'Cancel'
                            }
                        ]}
                    />

                    <Menu
                        keepMounted
                        className={classes.moreMenu}
                        anchorEl={rowMoreButton}
                        open={!!rowMoreButton}
                        onClose={() => setRowMoreButton(null)}
                    >
                        {canCreateContract && (
                            <MenuItem onClick={() => navigate(`/app/administrative/contract/add/${selectedUser.id}`)}>
                                <ListItemIcon>
                                    <PenTool size={20} />
                                </ListItemIcon>
                                <Typography>Create Contract</Typography>
                            </MenuItem>
                        )}
                        {canCreateInterview && (
                            <MenuItem onClick={() => navigate(`/app/customer/interview/add/${selectedUser.id}`)}>
                                <ListItemIcon>
                                    <Video size={20} />
                                </ListItemIcon>
                                <Typography>Create Interview</Typography>
                            </MenuItem>
                        )}
                        {canTraceCustomer && (
                            <MenuItem onClick={() => navigate(`/app/customer/tracing/${selectedUser.id}`)}>
                                <ListItemIcon>
                                    <Archive size={20} />
                                </ListItemIcon>
                                <Typography>Trace Customer</Typography>
                            </MenuItem>
                        )}
                    </Menu>
                </div>
            </Paper>

            <DraggableDialog
                open={Boolean(openView)}
                onClose={() => setOpenView(null)}
                title="View Customer"
                isLoaded={Boolean(openView)}
            >
                <Paper elevation={0}>
                    <CheckCustomerPage userId={clickedCustomer} />
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                open={Boolean(openEdit)}
                onClose={() => setOpenEdit(null)}
                title="Edit Customer"
                isLoaded={Boolean(openEdit)}
            >
                <Paper elevation={0}>
                    <EditCustomerPage userId={clickedCustomer} />
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                open={Boolean(openContract)}
                onClose={() => {
                    setIsContractLoaded(closeContract()(dispatch))
                    setOpenContract(null)
                }}
                title="View Contract"
                isLoaded={Boolean(openContract)}
            >
                {isContractLoaded ? (
                    <div>
                        <ContractDisplayLoader contract={currentContract} />
                        <Typography variant="body2" style={{color: 'gray', textAlign: 'center'}}>
                            The above Party B signature is for verification purposes only and will not be sent to the
                            customer in email.
                        </Typography>
                        <Grid
                            container
                            justifyContent="center"
                            spacing={4}
                            style={{margin: '2rem 0 8rem 0', width: '100%'}}
                        >
                            <Grid item style={{padding: '0'}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={isSending}
                                    onClick={() => onDownloadUnsigned()}
                                >
                                    Download Unsigned PDF
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <LoadingScreen message="Loading..." />
                )}
            </DraggableDialog>
        </div>
    )
}

const mapStateToProps = state => ({
    sales: state.auth.user,
    activationMessage: state.customer.activationMessage,
    allUserRoles: state.customer.allUserRoles
})

export default connect(mapStateToProps, {
    activateCustomer,
    fetchCustomerCheck,
    getCustomerLatestResume,
    fetchCustomers,
    fetchCustomer
})(DataTable)
