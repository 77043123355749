import React, { useState } from 'react';
import moment from 'moment';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const MonitorDateSelectionView = ({ searchFunction }) => {

  const [selected, setSelected] = useState(0)

  const onDateSelected = (selectedValue) => {
    if (selectedValue === null) {
      // always want one, cant have null
      return
    }

    setSelected(selectedValue)

    let current = moment()
    switch (selectedValue) {
      case 0:     // Past 24 hours
        current.subtract(24, 'hour')
        break
      case 1:     // Past Week
        current.subtract(1, 'week')
        break
      case 2:     // Past Month
        current.subtract(1, 'month')
        break
      case 3:     // All
        current = null
        break
      default: break
    }
    searchFunction(current)
  }

  const renderView = () =>
    <ToggleButtonGroup
      exclusive
      value={selected}
      onChange={(e, v) => onDateSelected(v)}
    >
      <ToggleButton value={0}>Day</ToggleButton>
      <ToggleButton value={1}>Week</ToggleButton>
      <ToggleButton value={2}>Month</ToggleButton>
      <ToggleButton value={3}>All</ToggleButton>
    </ToggleButtonGroup>

  return renderView()
}

export default MonitorDateSelectionView
