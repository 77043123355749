
import React from 'react';
import {connect} from "react-redux";
import {updateProduct} from "../../actions";
import ProductEditor from "./ProductEditor";



function EditProductForm({updateProduct,id,editProduct}) {

    return (
        <ProductEditor isEdit cb={updateProduct} id={id} editProduct={{...editProduct , hosts:editProduct?.hosts?.join('\n')}}/>

    );
}


export default connect(({products})=>({
    editProduct:products.editProduct,
    id:products.id,
    categories:products.categories
}),{updateProduct})(EditProductForm);
