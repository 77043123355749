import React, {useState} from 'react';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Link,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {red} from '@mui/material/colors';
import AccountTree from '@mui/icons-material/AccountTree';
import {connect, useDispatch} from "react-redux";
import _ from 'lodash';
import {FunctionActions, searchFunctions, searchFunctionsByParentId, updateFunction} from "../../../actions";
import SubmitButton from "../../../components/mui/button/SubmitButton";



const useStyles = makeStyles(()=> ({
    root: {},
    paper__form: {
        padding: "1rem 1rem",
        margin: "0 auto",
    },
    form__title: {
        marginBottom: ".5rem",
    },
    redCheckBox: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
}))


const schema = yup.object().shape({
    name: yup.string().required().min(3).trim(),
    router: yup.string().trim(),
    icon: yup.string().trim(),
});


function EditFunctionForm({
                              currentFunction, updateFunction,
                              searchFunctionsByParentId, searchFunctions
                          }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [radioValues, setRadioValues] = useState({
        operationType: currentFunction.operationType,
    });
    const [checkValues, setCheckValue] = useState({
        directlyAccess: currentFunction.directlyAccess,
        isActive: currentFunction.isActive
    })
    const [oldParent] = useState(currentFunction.parent?.id);
    const [parentError,setParentError] = useState(null);
    const [isSaving,setIsSaving] = useState(false);
    const {
        register, errors, handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
        reValidateMode: "onChange",
        defaultValues: currentFunction
    });


    const handleChangeCheckbox = (event) => {
        setCheckValue({...checkValues, [event.target.name]: event.target.checked})
    }
    const handleRadioBoxChange = (event) => {
        setRadioValues({...radioValues, [event.target.name]: event.target.value})
    }

    const handleSaveSubmit = (formData) => {

        const data = {...formData}
        data.parent = currentFunction.parent ? currentFunction.parent.id : null

        if (data.parent && data.parent === currentFunction.id) {
            setParentError("You can't set Parent to yourself!")
        }else{
            setIsSaving(true);
            updateFunction(currentFunction.id, data, afterSaving,handleError)
        }
    }
    const renderParentError=()=>{
        if(parentError){
            return(
                <Typography color="error" variant="body2" >
                    {parentError}
                </Typography>
            )
        }
            return null;
        
    }
    const handleError = (e)=>{
        setParentError(e.response.data.message);
        setIsSaving(false);
    }
    const afterSaving = (data) => {
        const newParent = data.parent;
        const needTobeReLoad = _.uniq([newParent, oldParent]);
        for (let i = 0; i < needTobeReLoad.length; i+=1) {
            if (needTobeReLoad[i]) {
                searchFunctionsByParentId({parent: needTobeReLoad[i]})
            } else {
                searchFunctions({})
            }
        }
        setIsSaving(false);
        closeEditForm();

    }
    const closeEditForm = () => {
        dispatch({type: FunctionActions.SetFunctionFormStatus, payload: "Close"})
    }

    const handleDeleteParent = () => {
        dispatch({type: FunctionActions.CancelEditSelectParent})
    }
    const renderParentChip = () => {
        if (currentFunction.parent) {
            return <Chip variant="outlined" color="primary" icon={<AccountTree/>} label={currentFunction.parent.name}
                         onDelete={handleDeleteParent}/>
        } 
            return <Chip variant="outlined" color="primary" icon={<AccountTree/>} label="Root"
                         style={{marginLeft: "1rem"}}/>
        
    }

    return (

        <Paper className={classes.paper__form}>
            <Typography className={classes.form__title} variant="h4" align="center">
                Update Function - {currentFunction.id} - {currentFunction.name}
            </Typography>
            <form noValidate onSubmit={handleSubmit(handleSaveSubmit)}>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" align="left">
                            Parent:
                        </Typography>
                    </Grid>
                    <Grid item>
                        {renderParentChip()}
                    </Grid>
                    <Grid item>
                        {renderParentError()}
                    </Grid>
                </Grid>


                <TextField variant="outlined" margin="normal" fullWidth name="name" id="name"
                           label="Name *" size="small"
                           autoComplete="name" autoFocus inputRef={register}
                           error={Boolean(errors.name?.message)}
                           helperText={errors.name?.message}
                />
                <TextField variant="outlined" margin="normal" fullWidth name="description" id="description"
                           label="Description" size="small"
                           autoComplete="description" inputRef={register}
                           error={Boolean(errors.description?.message)}
                           helperText={errors.description?.message}
                />
                <TextField variant="outlined" margin="normal" fullWidth name="router" id="router"
                           label="Router" size="small"
                           autoComplete="router" inputRef={register}
                           error={Boolean(errors.router?.message)}
                           helperText={errors.router?.message}
                />


                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField variant="outlined" margin="normal" fullWidth name="icon" id="phone"
                                           label="Icon" size="small"
                                           autoComplete="icon" inputRef={register}
                                           error={Boolean(errors.icon?.message)}
                                           helperText={errors.icon?.message}
                                />

                                <Typography variant="body2" color="textSecondary">
                                    <Link href="https://feathericons.com/" target="_blank">Icon only choose from
                                        www.feathericons.com </Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField variant="outlined" margin="normal" fullWidth name="orderKey" id="orderKey"
                                           label="Sort by keyword" size="small"
                                           autoComplete="orderKey" inputRef={register}
                                />
                                <Typography variant="body2" color="textSecondary">
                                    This keyword is to help the menu to be arranged in an
                                    orderly without depending on the creation time
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>

                                <FormControlLabel control={
                                    <Checkbox name="directlyAccess" color="primary" onChange={handleChangeCheckbox}
                                              checked={checkValues.directlyAccess} inputRef={register}/>
                                } label="Directly Access"/>

                                <Typography variant="body2" color="textSecondary">
                                    True means functions that can be accessed directly by the user through the router.
                                    Functions like Delete and Approve are generally False
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Operations</FormLabel>
                                    <RadioGroup name="operationType" value={radioValues.operationType}>

                                        <Grid container>
                                            <Grid><FormControlLabel value="r" control={<Radio inputRef={register}/>}
                                                                    onChange={handleRadioBoxChange}
                                                                    label="Read"/></Grid>
                                            <Grid><FormControlLabel value="w" control={<Radio inputRef={register}/>}
                                                                    onChange={handleRadioBoxChange}
                                                                    label="Write"/></Grid>
                                            <Grid><FormControlLabel value="d" control={<Radio inputRef={register}/>}
                                                                    onChange={handleRadioBoxChange}
                                                                    label="Delete"/></Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>

                                <FormControlLabel control={
                                    <Checkbox name="isActive" onChange={handleChangeCheckbox}
                                              className={classes.redCheckBox}
                                              checked={checkValues.isActive} inputRef={register}/>
                                } label="Enabled Function"/>
                                <Typography variant="body2" color="textSecondary">
                                    Unchecking this option will make Function no longer available.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>


                <FormHelperText error={Boolean(errors.class?.message)}>
                    {errors.class?.message}
                </FormHelperText>
                <Grid container spacing={2}>
                    <Grid item sm={3} >
                        <SubmitButton isSaving={isSaving} text="Save" isSavingText = "Saving"
                                      fullWidth variant="contained" color="primary"
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Button type="button" fullWidth variant="contained" color="primary" onClick={closeEditForm}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>


            </form>
        </Paper>

    );
}
const mapStateToProps = ({system}) => ({
        currentFunction: system.currentFunction,
        functions: system.functions
    })
export default connect(mapStateToProps,
    {
        updateFunction,
        searchFunctionsByParentId,
        searchFunctions
    }
)(EditFunctionForm);
