import React, { useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import {
  Button,
  ButtonGroup,
  debounce,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  DataGrid
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  updatePaymentReceiver
} from '../../../../actions/administrative/mmqJobsAction';
import ResponsiveDialog from '../../../../components/dialog/ResponsiveDialog';



const EmailReceiverTable = () => {
  //  TODO: add fetch jobs
  const allPaymentReceiver = useSelector(state => state.mmqJobs.allPaymentReceiver)
  const dispatch = useDispatch()
  const [checkboxSelected, setCheckboxSelected] = useState([])
  // const [clickedRow, setClickedRow] = useState("")
  // const [openDetails, setOpenDetails] = useState(false)
  // const [openEdit, setOpenEdit] = useState(false)

  // const onDeleteSuccess = (msg) => {
  //   sendSuccessMessage(dispatch, msg)
  //   setTimeout(() => {
  //     // navigate(`/app/administrative/contractTemplate`)
  //     window.location.reload()
  //   }, 1000)
  // }
  // const onDeleteError = (msg) => sendErrorMessage(dispatch, msg)

  // const [hash, setHash] = useState('init')
  const hash ='init'
  // const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
  const openDeleteDialogue =false
  const [hidePassword,setHidePassword] = useState(false)
  // const fetchProps = useSelector(state => state.administrative.contractTemplateFetchBody)
  // const currentTab = useSelector(state => state.administrative.currentTemplateTab)
  // const categoryName = {
  //   0: "body",
  //   1: "head",
  //   2: "MMQ Jobs",
  //   3: "Payment Receiver",
  // }

  // const [searchArgs, setSearchArgs] = useState({searchKey: undefined, category: categoryName[currentTab]})
  //
  // useEffect(() => {
  //   setSearchArgs(prevState => ({
  //     ...prevState,
  //     category: categoryName[currentTab]
  //   }))
  // }, [currentTab])


  const handleSearchChange = () => {
    // const searchStr = searchVal.trim().toLowerCase();
    // setSearchArgs(prevState => ({
    //   ...prevState,
    //   searchKey: searchStr
    // }));
  };

  const searchHandler = debounce((searchVal) => {
    handleSearchChange(searchVal)
  }, 500);
  const renderSearchBar = () => <Grid item xs={12}>
      <div>
        <TextField
          disabled
          fullWidth
          placeholder='Search...'
          size='small'
          type='search'
          onChange={event => searchHandler(event.currentTarget.value)}
          InputProps={{
            startAdornment: <InputAdornment position='start'>
              <SvgIcon fontSize='small' color='action'>
                <SearchIcon size={20}/>
              </SvgIcon>
            </InputAdornment>
          }}/>
      </div>
    </Grid>
  const renderStatusButtons = () => (
      <Grid item xs={12}>
        <ButtonGroup variant='contained' color='primary' size='small' disabled={checkboxSelected.length === 0}>
          <Button onClick={()=>{
            updatePaymentReceiver(checkboxSelected,false)(dispatch)
          }
          }>
            Disable
          </Button>
          <Button onClick={()=>{
            updatePaymentReceiver(checkboxSelected,true)(dispatch)
          }
          }>
            Enable
          </Button>
        </ButtonGroup>
      </Grid>
    )

  const renderContent = () => (
      <div>
        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
          <Grid item xs={12}>
            {renderSearchBar()}
          </Grid>
          <Grid item xs={12}>
            {renderStatusButtons()}
          </Grid>
          <Grid item xs={12}>
            {renderGrid()}
          </Grid>
        </Grid>
        <ResponsiveDialog
          isOpen={openDeleteDialogue} openHash={hash}
          title="Delete Template" content={`Delete Templates: ${checkboxSelected.join(', ')}?`}
          Buttons={
            [
              {name: "Yes"},
              {name: "Cancel"}
            ]
          }
        />
      </div>
    )

  const renderGrid = () => {
    const columns = []
    columns.push(...[
      { field: 'isActive', headerName: 'Active', width: 100,
        type:'boolean'
      },
      {
        field: 'user', headerName: 'User', width: 200,
        renderCell: (params) => params.row.user
      },
      {
        field: 'password', headerName: 'Access Code', width: 200,
        renderCell: (params) =>
          // const generatePasswordDots = (password) => {
          //   let len = new Array(password.length)
          //   let res = len.map(ele => <FiberManualRecordIcon sx={{fontSize:"12px"}}/>)
          // }
            <div style={{width:"100%",display:'flex',justifyContent:"space-between",alignItems:"center"}}
                       onMouseLeave={()=>{
                         setHidePassword(false)
                       }}
          >
            <div>
              {!hidePassword?<div>
                  {new Array(params.row.password.length).fill(0).map((value,index)=><FiberManualRecordIcon key={value + index} sx={{fontSize:"8px"}}/>)}
              </div>:<div>{params.row.password}</div>}
            </div>
            <IconButton color='primary'
                        size='small'
                        component='span'
                        onClick={()=>{
                          setHidePassword(!hidePassword)
                        }
                        }
            >
              { hidePassword?<VisibilityOffOutlinedIcon/>: <VisibilityOutlinedIcon />}
            </IconButton>
          </div>
        

      },
      {
        field: 'bank', headerName: 'Bank', width: 200,
        renderCell: (params) => params.row.bank
      },
      {
        field: 'name', headerName: 'Name', width: 200,
        renderCell: (params) => params.row.Name
      },
      {
        field: 'host', headerName: 'Host', width: 200,
        renderCell: (params) => params.row.host
      },
      {
        field: 'port', headerName: 'Port', width: 200,
        renderCell: (params) => params.row.port
      }
    ])

    const rows = allPaymentReceiver;




    return (
      <Box sx={{ height: 800, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            selectionModel={checkboxSelected}
            onSelectionModelChange={setCheckboxSelected}
          />
        </Box>

    )
  }

  return (renderContent())
}

export default EmailReceiverTable
