import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
    Stack,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material'
import Paper from '@mui/material/Paper'
import {AddCircle, Check, Close, DeleteForever} from '@mui/icons-material'
import FormControl from '@mui/material/FormControl'
import {Edit} from 'react-feather'
import {noop} from 'lodash'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import {sendErrorMessage, sendSuccessMessage} from '../../../../actions'
import {
    addContractPaymentEmails,
    deleteContractPaymentEmails,
    updateContractPaymentEmails
} from '../../../../actions/administrative/administrativeAction'
import DraggableDialog from '../../../../components/mui/dialog/DraggableDialog'

const useCommissionStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '1000px',
        margin: '0 auto'
    },
    paper: {
        padding: '2rem',
        // width: 'clamp(300px, 80vw, 1000px)',
        maxHeight: '77vh',
        overflow: 'auto',
        '& h3': {
            fontWeight: 'bold',
            fontSize: '1.25rem'
        },
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        }
    },
    table: {
        minWidth: '500px'
    },
    modalTitle: {
        marginBottom: '1rem'
    },
    notes: {
        whiteSpace: 'pre-line',
        wordBreak: 'break-word'
    }
}))

const EmailRow = ({item, setIsAdd, isPaste, isAdd, setEditing, cid, isNew, contract, fetchProps, onCancel = noop}) => {
    const errorMsg = useSelector(state => state.message.message)
    const dispatch = useDispatch()
    // const classes = useCommissionStyles();
    const [isDeleting, setIsDeleting] = useState(false)

    const [isUpdateEmail, setUpdatingEmail] = useState(false)

    const [isEdit, setIsEdit] = useState(isNew)
    const [isSaving, setIsSaving] = useState(false)
    const [errors, setErrors] = useState({})
    const [isOverWrite, setIsOverWrite] = useState(false)
    const [overWriteFname, setOverWriteFname] = useState('')
    const [overWriteLname, setOverWriteLname] = useState('')
    const [overWriteEmail, setOverWriteEmail] = useState('')
    const defaultValue = useSelector(state => state.customer.currentCustomerInfo)

    // declare formData for only add new email button
    // let formData= (
    // isNew
    //   ? {
    //     fname: defaultValue.firstName,
    //     lname: defaultValue.lastName,
    //     email: defaultValue.email,
    //
    //   }
    //   : {
    //     fname: item?.fname,
    //     lname: item?.lname,
    //     email: item?.email
    //
    //   }

    // declare formData for existing add new email button and paste button
    let formData = {}
    if (isNew) {
        formData = {
            fname: '',
            lname: '',
            email: ''
        }
        if (isPaste) {
            formData = {
                fname: defaultValue.firstName,
                lname: defaultValue.lastName,
                email: defaultValue.email
            }
        }
    } else {
        formData = {
            fname: item?.fname,
            lname: item?.lname,
            email: item?.email
        }
    }

    const onSubmit = async () => {
        const submitFormData = {...formData, cid}
        setErrors({})
        setIsSaving(true)

        try {
            if (isNew) {
                // create new email

                if (isOverWrite !== true) {
                    await addContractPaymentEmails(
                        submitFormData.fname,
                        submitFormData.lname,
                        submitFormData.email,
                        submitFormData.cid,
                        onSuccess,
                        onError,
                        fetchProps
                    )(dispatch)
                } else {
                    await updateContractPaymentEmails(
                        overWriteFname,
                        overWriteLname,
                        overWriteEmail,
                        null,
                        submitFormData.cid,
                        onSuccess,
                        onError,
                        fetchProps
                    )(dispatch)
                }
            } else {
                // update existing email
                await updateContractPaymentEmails(
                    submitFormData.fname,
                    submitFormData.lname,
                    submitFormData.email,
                    item.id,
                    submitFormData.cid,
                    onSuccess,
                    onError,
                    fetchProps
                )(dispatch)
            }

            for (let i = 0; i < errorMsg?.length; i += 1) {
                if (errorMsg[i]?.message.includes('do you mean to update the names')) {
                    setIsOverWrite(true)
                    setOverWriteFname(submitFormData.fname)
                    setOverWriteLname(submitFormData.lname)
                    setOverWriteEmail(submitFormData.email)
                }
            }
        } catch (err) {
            // display error to the user
            setIsSaving(false)
            setErrors(err.inner.reduce((res, cur) => ({...res, [cur.path]: cur.message}), {}))
        }
    }

    const onDelete = async () => {
        const submitFormData = {...formData, cid}
        setErrors({})
        setIsSaving(true)
        try {
            // delete existing email
            await deleteContractPaymentEmails(
                submitFormData.fname,
                submitFormData.lname,
                submitFormData.email,
                item.id,
                submitFormData.cid,
                onSuccess,
                onError,
                fetchProps
            )(dispatch)
        } catch (err) {
            // display error to the user
            setIsSaving(false)
            setErrors(err.inner.reduce((res, cur) => ({...res, [cur.path]: cur.message}), {}))
        }
    }

    const onSuccess = msg => {
        sendSuccessMessage(dispatch, msg)
        setIsSaving(false)
        setIsEdit(false)
        onCancel()
    }

    const onError = msg => {
        sendErrorMessage(dispatch, msg)
        setIsSaving(false)
    }

    const onCancelEdit = () => {
        // reset form data and the like
        setIsEdit(false)
        formData = isNew
            ? {
                  fname: '',
                  lname: '',
                  email: ''
              }
            : {
                  fname: item?.fname,
                  lname: item?.lname,
                  email: item?.email
              }
        onCancel()
    }
    function DeleteAlertDialog() {
        // const handleClickOpen = () => {
        //   setIsDeleting(true);
        // };
        const handleClose = () => {
            setIsDeleting(false)
        }
        return (
            <Dialog
                open={isDeleting}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Deleting Email</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please make sure to delete the email from this payment email list.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>cancel</Button>
                    <Button
                        onClick={() => {
                            onDelete()
                            handleClose()
                        }}
                        autoFocus
                    >
                        confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    function UpdateAlertDialog() {
        const handleClose = () => {
            setUpdatingEmail(false)
        }
        return (
            <Dialog
                open={isUpdateEmail}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Updating Email</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please make sure to update this email for all payments.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>cancel</Button>
                    <Button
                        onClick={() => {
                            onSubmit()
                            handleClose()
                        }}
                        autoFocus
                    >
                        confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const renderEditView = () => (
        <TableRow>
            <TableCell scope="row">
                <FormControl fullWidth>
                    <TextField
                        fullWidth
                        size="small"
                        label="First Name"
                        name="fname"
                        multiline
                        disabled={isSaving}
                        defaultValue={formData.fname}
                        onChange={e => {
                            formData = {...formData, fname: e.target.value.toLowerCase()}
                        }}
                        error={Boolean(errors.notes)}
                        helperText={errors.notes ? errors.notes : ''}
                    />
                </FormControl>
            </TableCell>
            <TableCell scope="row">
                <FormControl fullWidth>
                    <TextField
                        fullWidth
                        size="small"
                        label="Last Name"
                        name="lname"
                        multiline
                        disabled={isSaving}
                        defaultValue={formData.lname}
                        onChange={e => {
                            formData = {...formData, lname: e.target.value.toLowerCase()}
                        }}
                        error={Boolean(errors.notes)}
                        helperText={errors.notes ? errors.notes : ''}
                    />
                </FormControl>
            </TableCell>

            <TableCell scope="row">
                <FormControl fullWidth>
                    <TextField
                        fullWidth
                        size="small"
                        label="Email"
                        type="email"
                        required
                        name="email"
                        disabled={isSaving || !isNew}
                        defaultValue={formData.email}
                        // disabled={ !isNew }
                        onChange={e => {
                            formData = {...formData, email: e.target.value.toLowerCase()}
                        }}
                    />
                </FormControl>
            </TableCell>

            <TableCell align="right">
                <Tooltip title="Save" placement="top">
                    <IconButton
                        color="primary"
                        size="small"
                        disabled={isSaving || !contract?.currency?.currency}
                        component="span"
                        onClick={() => {
                            if (isOverWrite) {
                                setUpdatingEmail(true)
                            } else {
                                onSubmit()
                            }
                        }}
                    >
                        <Check style={{color: contract?.currency?.currency ? 'green' : 'grey'}} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancel" placement="top">
                    <IconButton
                        color="primary"
                        size="small"
                        disabled={isSaving}
                        component="span"
                        onClick={() => {
                            onCancelEdit()
                            setEditing(false)
                        }}
                    >
                        <Close style={{color: 'red'}} />
                    </IconButton>
                </Tooltip>
                {!isAdd && (
                    <Tooltip title="Delete" placement="top">
                        <IconButton
                            color="primary"
                            size="small"
                            disabled={isSaving}
                            component="span"
                            onClick={() => {
                                setIsDeleting(true)
                            }}
                        >
                            <DeleteForever style={{color: 'black'}} />
                        </IconButton>
                    </Tooltip>
                )}
            </TableCell>
            <DeleteAlertDialog />
            <UpdateAlertDialog />
        </TableRow>
    )

    const renderViewView = () => (
        <TableRow>
            <TableCell scope="row">{formData?.fname?.toLowerCase()}</TableCell>

            <TableCell scope="row">{formData?.lname?.toLowerCase()}</TableCell>

            <TableCell scope="row">{formData?.email?.toLowerCase()}</TableCell>

            <TableCell align="right">
                <Tooltip title="Edit" placement="top">
                    <IconButton
                        color="primary"
                        size="small"
                        component="span"
                        onClick={() => {
                            setEditing(true)
                            setIsEdit(true)
                            setIsAdd(false)
                        }}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )

    const renderRow = () => (isEdit ? renderEditView() : renderViewView())

    return renderRow()
}

function ContractEmailsManagement({contract, fetchProps, handleModalClose}) {
    const [editing, setEditing] = useState(false)
    useEffect(() => {
        setEditing(false)
    }, [contract])

    const classes = useCommissionStyles()

    const [isAdd, setIsAdd] = useState(false)
    const [isPaste, setIsPaste] = useState(false)
    // const [commissionStats, setCommissionStats] = useState({
    //   paid: 0,
    //   partial: 0,
    //   unpaid: 0,
    //   total: 0
    // });

    const defaultValue = useSelector(state => state.customer.currentCustomerInfo)

    // useEffect(() => {
    //   const newStats = contract?.commissions?.reduce((res, cur) => {
    //     if (cur.status !== 'cancelled') {
    //       res.total += parseFloat(cur.price);
    //       switch (cur.status) {
    //         case 'paid':
    //           res.paid += parseFloat(cur.price);
    //           break;
    //         case 'partial':
    //           res.partial += parseFloat(cur.price);
    //           break;
    //         case 'unpaid':
    //           res.unpaid += parseFloat(cur.price);
    //           break;
    //         default:
    //           break;
    //       }
    //     }
    //     return res;
    //   }, {
    //     paid: 0,
    //     partial: 0,
    //     unpaid: 0,
    //     total: 0
    //   });
    //   // setCommissionStats(newStats);
    // }, [contract]);

    const renderTable = () => (
        <TableContainer>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        {/* <TableCell sx={{ minWidth: 160 }}>Status</TableCell> */}
                        <TableCell sx={{minWidth: 160}}>First Name</TableCell>
                        <TableCell sx={{minWidth: 160}}>Last Name</TableCell>
                        <TableCell sx={{minWidth: 160}}>Email</TableCell>
                        <TableCell sx={{minWidth: 160}} align="right">
                            Edit
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {contract?.paymentEmails?.map(item => (
                        <EmailRow
                            contract={contract}
                            setEditing={setEditing}
                            setIsPaste={setIsPaste}
                            isPaste={isPaste}
                            setIsAdd={setIsAdd}
                            isAdd={isAdd}
                            item={item}
                            key={item.id}
                            cid={contract.cid}
                            isNew={false}
                            fetchProps={fetchProps}
                            onCancel={() => setIsAdd(false)}
                        />
                    ))}

                    {isAdd && (
                        <EmailRow
                            setIsPaste={setIsPaste}
                            isPaste={isPaste}
                            contract={contract}
                            setEditing={setEditing}
                            cid={contract.cid}
                            setIsAdd={setIsAdd}
                            isAdd={isAdd}
                            isNew
                            fetchProps={fetchProps}
                            onCancel={() => setIsAdd(false)}
                        />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )

    return (
        <DraggableDialog
            open={contract !== null}
            onClose={() => {
                handleModalClose()
                setIsAdd(false)
            }}
            title={`Emails for ${contract?.cid} for ${contract?.sales_fullName}`}
            isLoaded={Boolean(contract !== null)}
        >
            <Paper className={classes.paper} style={{maxWidth: '800px'}}>
                {contract?.cid && Array.isArray(contract?.commissions) && (
                    <div>
                        <Stack
                            justifyContent="flex-end"
                            direction="row"
                            justifyItems="center"
                            className={classes.modalTitle}
                        >
                            {Object.keys(defaultValue).length > 0 && (
                                <Tooltip title="Paste Email & Name" placement="top">
                                    <IconButton
                                        disabled={isAdd}
                                        onClick={() => {
                                            setIsAdd(true)
                                            setIsPaste(true)
                                        }}
                                        size="large"
                                    >
                                        <ContentPasteOutlinedIcon
                                            style={{color: isAdd ? 'gray' : editing ? 'gray' : 'green'}}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}

                            <Tooltip title="Add new payment email" placement="top">
                                <IconButton
                                    disabled={isAdd}
                                    onClick={() => {
                                        setIsAdd(true)
                                        setIsPaste(false)
                                    }}
                                    size="large"
                                >
                                    <AddCircle style={{color: isAdd ? 'gray' : editing ? 'gray' : 'green'}} />
                                </IconButton>
                            </Tooltip>
                        </Stack>

                        {renderTable()}
                    </div>
                )}
            </Paper>
        </DraggableDialog>
    )
}

export default ContractEmailsManagement
