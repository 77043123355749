import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {debounce} from 'lodash'
import Grid from '@mui/material/Grid'
import {Button, ButtonGroup, IconButton, Link, Tooltip} from '@mui/material'
import moment from 'moment'
import {Email, Link as LinkIcon, ListAlt, PictureAsPdf} from '@mui/icons-material'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import {useNavigate} from 'react-router-dom'
import {listStatusFormatter, useInvoiceListStyles} from './invoiceHelper'
import {
    downloadInvoicePdf,
    fetchAllInvoices,
    updateInvoiceStatus
} from '../../../actions/administrative/administrativeAction'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'
import {emailLinkGenerator} from '../contract/contractHelper'
import PageGrid from '../../../components/mui/datagrid/PageGrid'
import {DetailModal} from '../../customer/interview/interviewHelper'
import CheckCustomerFloat from '../../customer/register/CheckCustomerFloat'
import {copyToClipboard} from '../../../utils/Scripts'
import SearchBox from '../../../components/public/SearchBox'

function InvoiceTabView({canCreateInvoice, openNotesHandler}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const classes = useInvoiceListStyles()
    const auth = useSelector(state => state.auth)
    const allInvoices = useSelector(state => state.administrative.allInvoices)
    const currentTab = useSelector(state => state.administrative.currentInvoiceTab)
    const fetchProps = useSelector(state => state.administrative.invoiceFetchProps)

    const categoryName = {
        0: 'all',
        1: 'asked',
        2: 'issuing'
    }
    const [searchArgs, setSearchArgs] = useState({searchKey: undefined, category: categoryName[currentTab]})
    const [checkboxSelected, setCheckboxSelected] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const onSuccess = msg => sendSuccessMessage(dispatch, msg)
    const onError = msg => sendErrorMessage(dispatch, msg)

    const onLinkCopy = uid => copyToClipboard(dispatch, uid)

    useEffect(() => {
        setSearchArgs(prevState => ({
            ...prevState,
            category: categoryName[currentTab]
        }))
    }, [currentTab])

    const handleSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase()
        setSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }

    const onStatusChange = isActive => {
        updateInvoiceStatus(
            checkboxSelected.map(x => parseInt(x, 10)),
            fetchProps,
            isActive,
            msg => {
                setCheckboxSelected([])
                onSuccess(msg)
            },
            msg => onError(msg)
        )(dispatch)
    }

    const handleFileOpen = (uid, iid, customerName) => {
        downloadInvoicePdf(uid, iid, customerName, msg => {
            onError(msg)
        })(dispatch)
    }

    const searchHandler = debounce(searchVal => {
        handleSearchChange(searchVal)
        // onSearch(searchVal)
    }, 500)

    const renderSearchContainer = () => (
        <Grid item xs={12}>
            <div className={classes.search}>
                <SearchBox
                    placeholder="Search..."
                    size="small"
                    type="search"
                    searchHandler={searchHandler}
                    isLoading={isLoading}
                />
            </div>
        </Grid>
    )

    const renderStatusButtons = () => (
        <Grid item className={classes.activityButtons} xs={12}>
            <ButtonGroup variant="contained" color="primary" size="small">
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(true)}>
                    Enable
                </Button>
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(false)}>
                    Disable
                </Button>
            </ButtonGroup>
        </Grid>
    )

    const renderTopToolbar = () => (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.root}>
            {renderSearchContainer()}
            {canCreateInvoice ? renderStatusButtons() : <div />}
        </Grid>
    )

    const renderGrid = () => {
        const columns = []

        if (canCreateInvoice) {
            columns.push({
                field: '_actions',
                headerName: ' ',
                resizable: false,
                disableClickEventBubbling: true,
                width: 200,
                filterable: false,
                renderCell: params => (
                    <div>
                        {params.row.status !== 'done' && params.row.isActive && (
                            <div>
                                <Tooltip title="Invoice Link" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() =>
                                            window.open(
                                                `/invoice/${params.row.customer.id}/${params.row.iid}`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        <LinkIcon size={20} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Copy Link" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => {
                                            onLinkCopy(
                                                `https://${window.location.host}/invoice/${params.row.customer.id}/${params.row.iid}`
                                            )
                                        }}
                                    >
                                        <FileCopyOutlinedIcon size={10} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                )
            })
        }

        columns.push(
            ...[
                {field: 'id', headerName: 'ID', width: 90},
                {
                    field: 'iid',
                    headerName: 'InvoiceID',
                    width: 180,
                    renderCell: params => (
                        <Link
                            className={classes.link}
                            onClick={() => {
                                navigate(`/app/administrative/invoice/view/${params.row.customer.id}/${params.row.iid}`)
                            }}
                        >
                            {params.row.isActive ? `${params.row.iid}` : <del>{`${params.row.iid}`}</del>}
                        </Link>
                    )
                },
                {
                    field: 'type',
                    headerName: 'Type',
                    width: 90,
                    valueFormatter: ({value}) => `${value[0].toUpperCase()}${value.slice(1)}`
                },
                {
                    field: 'customer',
                    headerName: 'Customer Name',
                    width: 230,
                    renderCell: params => (
                        // <Link
                        //   className={classes.link}
                        //   target="_blank"
                        //   onClick={() => {
                        //     const win = window.open(`/app/customer/registered/check/${params.value.id}`, '_blank')
                        //     win.focus()
                        //   }}>
                        //   {
                        //     params.row.isActive && params.value.nickName ? `${params.value.firstName} (${params.value.nickName}) ${params.value.lastName}`
                        //         : !params.row.isActive && params.value.nickName ? <del>{`${params.value.firstName} (${params.value.nickName}) ${params.value.lastName}`}</del>
                        //         : params.row.isActive && !params.value.nickName ? `${params.value.firstName} ${params.value.lastName}`
                        //             : <del>{`${params.value.firstName} ${params.value.lastName}`}</del>
                        //   }
                        // </Link>
                        <DetailModal
                            disabled={!params.row.isActive}
                            name={`${params.value.firstName}${
                                params.value?.nickName ? ` (${params.value?.nickName}) ` : ' '
                            }${params.value.lastName}`}
                            details={<CheckCustomerFloat studentId={params.value.id} />}
                        />
                    )
                },
                {
                    field: '_viewNotes',
                    headerName: 'Note',
                    resizable: false,
                    disableClickEventBubbling: true,
                    width: 90,
                    filterable: false,
                    renderCell: params => (
                        <Tooltip title="View Notes" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() => openNotesHandler(params.row.iid)}
                            >
                                <ListAlt size={20} />
                            </IconButton>
                        </Tooltip>
                    )
                },
                {
                    field: 'status',
                    headerName: 'Status',
                    width: 100,
                    renderCell: ({value}) => listStatusFormatter(value)
                },
                {
                    field: '_contractActions',
                    headerName: ' ',
                    resizable: false,
                    disableClickEventBubbling: true,
                    width: 80,
                    filterable: false,
                    renderCell: params => (
                        <div>
                            {params.row.status === 'done' && (
                                <div>
                                    <Tooltip title="Contract" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            onClick={() =>
                                                handleFileOpen(
                                                    params.row.customer?.id,
                                                    params.row.iid,
                                                    `${params.row.customer?.firstName}_${params.row.customer?.lastName}`
                                                )
                                            }
                                        >
                                            <PictureAsPdf size={20} />
                                        </IconButton>
                                    </Tooltip>
                                    <a
                                        href={emailLinkGenerator({
                                            productName: params.row.iid,
                                            customerName: params.row.customer.firstName,
                                            customerEmail: params.row.email,
                                            userName: auth.user.firstName
                                        })}
                                    >
                                        <Tooltip title="Email Customer" placement="top">
                                            <IconButton color="primary" size="small" component="span">
                                                <Email size={20} />
                                            </IconButton>
                                        </Tooltip>
                                    </a>
                                </div>
                            )}
                        </div>
                    )
                },
                {
                    field: 'createdAt',
                    headerName: 'Created',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                },
                {
                    field: 'updatedAt',
                    headerName: 'Updated',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                }
            ]
        )

        return (
            <PageGrid
                style={{
                    margin: '0 8px'
                }}
                columns={columns}
                datas={allInvoices}
                searchArgs={searchArgs}
                fetchDatas={fetchAllInvoices}
                selected={checkboxSelected}
                setSelected={setCheckboxSelected}
                setIsLoading={setIsLoading}
            />
        )
    }

    return (
        <Grid container className={classes.root}>
            {renderTopToolbar()}
            {renderGrid()}
        </Grid>
    )
}

export default InvoiceTabView
