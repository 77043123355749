import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import makeStyles from '@mui/styles/makeStyles';
import { Popover } from '@mui/material';

export default function DropdownButton({ onSelect, onClick, options, label }) {

  const useStyles = makeStyles(() => ({
    firstButton: {
      width: '150px',
    },
    secondButton: {
      width: '30px'
    },
    searchDateBtn: {
      // position: 'relative',
    },
    popperStyle: {
      // position: 'absolute',
      // bottom: '100px',
      // right: '200px',

      zIndex: 99,
    }
  }));

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(2)

  const classes = useStyles()

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
    onSelect(index)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false)
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <ButtonGroup
          variant="contained"
          color="primary"
          ref={anchorRef}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          aria-label="split button"
        >
          <Button onClick={onClick} className={classes.firstButton}>
            { label }
          </Button>
          <Button
            color="primary"
            size="small"
            aria-controls={ open ? 'split-button-menu' : undefined }
            aria-expanded={ open ? 'true' : undefined }
            aria-haspopup="menu"
            onClick={handleToggle}
            className={classes.secondButton}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Popover
          open={open}
          // role={undefined} transition disablePortal
          // className={classes.popperStyle}
          anchororigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                {options.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)} >
                    {option}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popover>


      </Grid>
    </Grid>
  );
}
