import makeStyles from '@mui/styles/makeStyles';
import * as yup from 'yup';
import React from 'react';
import moment from 'moment';

export const EMAIL =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const INVOICE_EDITOR_VALIDATOR = {
  iid: yup.lazy((value) => !value ? yup.string() : yup.string().required("Required")
    .min(10, "Invoice ID must be at least 10 characters")
    .max(30, "Invoice ID must be less than 30 characters")
    .trim()),
  dateCode: yup.string().required("Required")
    .matches(/^\d{8}$/, 'Date Code must follow: YYYYMMDD')
    .trim(),
  email: yup.string().email().required().trim().matches(EMAIL,'email must be a valid email'),
}

export const INVOICE_EDITOR_ITEM_VALIDATOR = {
  quantity: yup.number().required().positive().integer().typeError("Please input a valid quantity"),
  description: yup.string().required("Required"),
  price: yup.number().required().positive().typeError("Please input a valid price"),
  discount:yup.number().min(0).typeError("Please input a valid discount")
}

const listColor = {
  asked: '#9E9E9E',
  sent: '#36629E',
  done: '#619E36',
}
export const listStatusFormatter = (status) => {
  let result = status
  try {
    result = <div style={{ color: listColor[status] }}>
      {status[0].toUpperCase()}{status.slice(1)}
    </div>
  } catch (e) {
    // console.log('Formatting error:', e.message)
  }
  return result
}

export const genInvoiceID = (prefix='M2W') => prefix + moment().format('YYYYMMDD')
    + Math.random().toString(16).substring(2, 5).toUpperCase()

export const useInvoiceListStyles = makeStyles((theme) => ({
  root:{
    marginLeft: theme.spacing(1),
    marginRight:theme.spacing(3),
  },
  pageTitle: {
    marginBottom: '1rem',
  },
  link: {
    cursor: 'pointer'
  },
  topButton: {
    marginBottom: theme.spacing(1)
  },
  activityButtons: {
    paddingLeft: `${theme.spacing(1)}!important`,
    paddingBottom: `${theme.spacing(2)}!important`,
  },
  search: {
    position: 'relative',
    margin: '20px 0 20px 0',
    width: '100%',
    padding: theme.spacing(0, 1),
    paddingRight:theme.spacing(1),
  },
  paper: {
    padding: '2rem',
    marginBottom: '2rem'
  },
  submitButtonArea: {
    marginTop: '2rem',
    marginBottom: '8rem',
    width: '100%'
  },
}))

export const useInvoiceEditorStyles = makeStyles(() => ({
  paper: {
    padding: '2rem',
    marginBottom: '2rem'
  },
  itemAdder: {
    maxHeight: '100vh',
    overflow: 'auto',
    padding: '2rem 4rem',
    marginBottom: '2rem'
  },
  editor: {
    '& .cke_contents.cke_reset': {
      minHeight: '50vh'
    }
  },
  title: {
    marginBottom: '1rem'
  },
  marginTop: {
    marginTop: '2rem'
  },
  marginBottom: {
    marginBottom: '2.5rem'
  },
  companyToggle: {
    marginRight: '.5rem'
  },
  autofillForm: {
    marginBottom: '2.5rem',
    marginTop: '1.5rem'
  },
  buttonSelected: {
    backgroundColor: '#0f1b65'
  },
  buttonUnselected: {
    backgroundColor: '#4c56a5'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '800px',
    margin: '0 auto'
  },
}))

export const useInvoiceViewStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '14px'
  },
  title: {
    textAlign: 'center',
    marginBottom: '2rem'
  },
  marginBottom: {
    marginBottom: '1rem'
  },
  marginTop: {
    marginTop: '2rem'
  },
  marginExtraTop: {
    marginTop: '4.5rem'
  },
  paper: {
    padding: '2rem',
    marginBottom: '20px',
    width: '100%'
  },
  signatureArea: {
    width: '100%',
    backgroundColor: '#F4F6F8',
    '& canvas': {
      minHeight: '300px',
      maxHeight: '600px',
    }
  },
  floatingSignature: {
    position: 'absolute',
    right: '0',
  },
  submitButtonArea: {
    marginTop: '2rem',
    marginBottom: '8rem',
    width: '100%'
  },
  titleRow: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '& .MuiTableCell-head': {
      color: 'white'
    }
  },
  titleText: {
    fontSize: '18px',
    margin: '1rem',
  },
  normalText: {
    fontSize: '14px',
  },
  tableColInstructions: {
    width: '50vw'
  },
  tableNoBottomBorder: {
    borderBottom: 'none',
    verticalAlign: 'top'
  },
  invoiceTotal: {
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '14px',
    width: '50%',
    display: 'inline-block',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: '8px'
  },
  invoiceTotalName: {
    color: theme.palette.primary.main
  },
  invoiceTotalTitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  invoiceTotalPrice: {
    textAlign: 'end'
  },
  invoiceTotalMessage: {
    width: '100%',
    borderBottom: 'none',
    marginTop: '40px'
  },
  headerImage: {
    textAlign: 'end',
    '& img': {
      width: 'auto',
      height: 'auto',
      maxHeight: '80px',
      maxWidth: '100%'
    },
    [theme.breakpoints.down('xl')]: {
      textAlign: 'start'
    }
  },
  headerText: {
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '16px',
    lineHeight: '25px'
  },
}))
