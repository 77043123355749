import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {
    Badge,
    Button,
    ButtonGroup, Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import {debounce} from 'lodash';
import { Delete, ListAlt, PersonAdd} from '@mui/icons-material';
import Fuse from 'fuse.js';
import clsx from 'clsx';
import TableRowsIcon from '@mui/icons-material/TableRows';
import GridViewIcon from '@mui/icons-material/GridView';
import { useNavigate } from 'react-router-dom';
import { deleteWebSubmission, fetchWebFormById, fetchWebFormResults
} from '../../../actions/forms/formActions';
import ViewHead from '../../../components/public/ViewHead';
import LXGrid from '../../../components/mui/datagrid/XGrid';
import {sendErrorMessage, sendSuccessMessage} from '../../../actions';
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog';
import {Functions} from '../../../utils/Functions';
import history from '../../../utils/history';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import useAuthCheck from '../../../hooks/useAuthCheck';
import {addCustomerFromForm, handleFileOpen} from './formDataHelper';
import FormDataCardList from './formDataCardList';
import DraggableDialog from "../../../components/mui/dialog/DraggableDialog";
import WebSubmissionDetails from "./webSubmissionDetails";
import SearchBox from '../../../components/public/SearchBox';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        margin: '20px 0 10px 0',
        width: '100%',
        // padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2)
    },
    formGridTitle: {
        marginBottom: '1rem',
    },
    displaySwitcher: {
        // marginRight: theme.spacing(4),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    buttonSelected: {
        backgroundColor: '#0f1b65'
    },
    buttonUnselected: {
        backgroundColor: '#4c56a5'
    },
    badge: {
        '& .MuiBadge-badge': {
            transform: 'scale(0.8) translate(8px, -8px)',

        }
    },
}))

function FormData() {
    useCurrentViewCheck(Functions.Forms_View_Form_Submissions)
    const  navigate = useNavigate();
    const formId = useParams().id
    const dispatch = useDispatch()
    const classes = useStyles()
    // const currentFormResults = useSelector(state => state?.forms.currentFormResults)
    const currentWebFormResults = useSelector(state => state?.forms.currentWebFormResults)
    const currentWebForm = useSelector(state => state?.forms.currentWebForm)
    // const currentForm = useSelector(state => state?.forms.currentForm)
    const [isLoaded, setIsLoaded] = useState(false)
    // const currentTab = useSelector(state => state?.forms.currentFormTab)

    const [formResults, setFormResults] = useState([])
    const [filteredResults, setFilteredResults] = useState([])
    const [tableHeaders, setTableHeaders] = useState([])

    const [clickedRow, setClickedRow] = useState("")
    const [openDetails, setOpenDetails] = useState(false)

    const [hash, setHash] = useState('init')
    const [forceRefresh, setForceRefresh] = useState(0)
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
    const [selectedSubmission, setSelectedSubmission] = useState(-1)

    const [tableDisplay, setTableDisplay] = useState(true)

    const [fuse, setFuse] = useState(null)

    const [canManageSubmission, canAddUser] = useAuthCheck([
        Functions.Forms_Manage_Form_Submissions,
        Functions.Customer_Management_Registered_Information_Add_Customer
    ])

    const handleDeleteOpen = (id) => {
        setSelectedSubmission(id)
        setOpenDeleteDialogue(true)
        setHash(Math.random().toString(16).substring(2, 10));
    }

    const onDeleteSubmission = () => {
        deleteWebSubmission(selectedSubmission, formId,
            (msg) => sendSuccessMessage(dispatch, msg),
            (msg) => sendErrorMessage(dispatch, msg)
        )(dispatch)
    }
    const loadData = () => {
        setFormResults([])
        setTableHeaders([])
        fetchWebFormById(formId)(dispatch)
        fetchWebFormResults(formId)(dispatch)
    }
    useEffect(loadData, [dispatch, formId,openDetails])

    useEffect(() => {
        const sortData = (formSubmissions) => {
            const tempHeadings = []
            const result = []
            if (currentWebFormResults){
                Object.keys(currentWebFormResults).forEach(key => {

                    tempHeadings.push({
                        label: key.toString(),
                        name: "webFormName",
                        id: "",
                        type: "",
                        tag: key.toString().toLowerCase()
                    })
                })

            }
            formSubmissions.forEach ((submission)=> {

                // Basic Info
                const tempSubmission = {
                    id: submission.id,
                    repUser: submission.user ? `${submission.user.firstName} ${submission.user.lastName}` : 'None',
                    createdAt: submission.createdAt,
                    FirstName: submission?.firstName,
                    LastName: submission?.lastName,
                    phone: submission?.phone,
                    repUserId: submission.user ? submission.user.id : null,
                    ip: submission?.tracking?.ip ?? '',
                    device: submission?.tracking?.device ?? '',
                    browser: submission?.tracking?.browser ?? '',
                    source: submission?.tracking?.source ?? '',
                    city: submission?.tracking?.city ?? '',
                    country: submission?.tracking?.country ?? '',
                    postalCode: submission?.tracking?.postalCode ?? '',
                    address: submission?.tracking?.address ?? '',
                    location: submission?.location ?? '',
                    channel: submission?.channel ?? '',
                    email: submission?.email ?? '',
                    isRead: submission?.isRead

                }


                result.push(tempSubmission)
            })
            setTableHeaders([...tempHeadings])
            setFuse(new Fuse([...tempHeadings, 'repUser', 'ip', 'device', 'browser']))
            setFormResults(result)
            setFilteredResults(result)
            setIsLoaded(true)
            setForceRefresh(forceRefresh + 1)
        }

        try {
            sortData(currentWebFormResults)
        } catch (e) {
            sendErrorMessage(dispatch, 'Logic Error! Cannot sort data!')
        }
    }, [currentWebFormResults, formId, currentWebForm, dispatch])

    const renderGrid = () => {
        const columns = []

        if (canManageSubmission || canAddUser) {
            columns.push({
                field: '_actions', headerName: ' ', resizable: false,
                disableClickEventBubbling: true, width: 110,
                filterable: false, renderCell: (params) => (
                        <div>
                            {canAddUser &&
                                <div>
                                    <Tooltip title='Create User' placement='top'>
                                        <IconButton color='primary'
                                                    size='small'
                                                    component='span'
                                                    onClick={() => addCustomerFromForm(dispatch, history, params.row, tableHeaders)}>
                                            <PersonAdd size={20}/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }
                            <div>
                                <Tooltip title='Submission Sheet' placement='top'>
                                    <IconButton color='primary'
                                                size='small'
                                                component='span'
                                                onClick={async () => {
                                                    await setClickedRow(params.row.id);
                                                    await setOpenDetails(true);

                                                }
                                                }>
                                        {params.row.isRead ? <ListAlt size={20}/> : <Badge color='secondary'
                                                                                              overlap='circular'
                                                                                              className={classes.badge}
                                        >
                                            <ListAlt size={20}/>
                                        </Badge>}

                                    </IconButton>
                                </Tooltip>
                            </div>
                            {canManageSubmission &&
                                <div>
                                    <Tooltip title='Delete Submission' placement='top'>
                                        <IconButton color='primary'
                                                    size='small'
                                                    component='span'
                                                    onClick={() => handleDeleteOpen(params.row.id)}>
                                            <Delete size={20}/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }
                        </div>
                    )
            })
        }

        columns.push(...[
            {field: 'id', headerName: 'ID', width: 80},
            {field: 'repUser', headerName: 'Rep', width: 200},
            {
                field: 'createdAt', headerName: 'Date', width: 200,
                valueFormatter: ({value}) => moment(value).format('L LT')
            },
            {field: 'FirstName', headerName: 'First Name', width: 150},
            {field: 'LastName', headerName: 'Last Name', width: 150},
            {field: 'phone', headerName: 'Phone', width: 200},
            {field: 'email', headerName: 'Email', width: 200},
            {field: 'channel', headerName: 'Channel', width: 120},
            {field: 'ip', headerName: 'IP', width: 200},
            {field: 'device', headerName: 'Device', width: 250},
            {field: 'browser', headerName: 'Browser', width: 250},
            {field: 'source', headerName: 'Source', width: 250},
            {field: 'city', headerName: 'City', width: 200},
            {field: 'country', headerName: 'Country', width: 200},
            {field: 'postalCode', headerName: 'Postal', width: 200},
            {field: 'address', headerName: 'Address', width: 350},
        ])

        return (
            <div>
                {
                    tableDisplay
                        ? <LXGrid rows={filteredResults}
                                  columns={columns}
                                  loading={!isLoaded}
                                  showToolbar
                                  checkboxSelection
                                  disableSelectionOnClick
                                  sortModel={[{field: 'createdAt', sort: 'desc',}]}
                                  key={forceRefresh}
                        />
                        : <FormDataCardList datas={filteredResults}
                                            columns={columns}
                                            dispatch={dispatch}
                                            tableHeaders={tableHeaders}
                                            currentForm={currentWebForm}
                                            addCustomerFromForm={addCustomerFromForm}
                                            handleDeleteOpen={handleDeleteOpen}
                                            handleFileOpen={handleFileOpen}
                                            canManageSubmission={canManageSubmission}
                                            canAddUser={canAddUser}
                        />
                }
            </div>
        )
    }

    const onSearch = (searchVal) => {
        const cleanSearchVal = searchVal.toLowerCase().trim()
        const filteredDisplay = cleanSearchVal === ''
            ? formResults
            : fuse.search(cleanSearchVal).map(f => f.item)

        setFilteredResults(filteredDisplay)
    }

    const searchHandler = debounce((searchVal) => {
        onSearch(searchVal)
    }, 500)
    const renderSearchContainer = () =>
        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
            <Grid item xs={12}>
                <div className={classes.search}>
                    <SearchBox
                      placeholder='Search...'
                      size='small'
                      type='search'
                      searchHandler={searchHandler} />
                </div>
            </Grid>

            <Grid container justifyContent='space-between' alignItems='center' spacing={1}>
                <Grid item>
                    {renderSwitchDisplayButton()}
                </Grid>
            </Grid>
        </Grid>


    const renderSwitchDisplayButton = () =>
        <ButtonGroup className={classes.displaySwitcher}
                     variant='contained' color='primary' size='small'>
            <Button
                onClick={() => setTableDisplay(true)}
                className={clsx({
                    [classes.buttonSelected]: tableDisplay,
                    [classes.buttonUnselected]: !tableDisplay,
                })}>
                <TableRowsIcon/>
            </Button>
            <Button
                onClick={() => setTableDisplay(false)}
                className={clsx({
                    [classes.buttonSelected]: !tableDisplay,
                    [classes.buttonUnselected]: tableDisplay,
                })}
            >
                <GridViewIcon/>
            </Button>
        </ButtonGroup>

    const renderBackButton = () =>
        <div className={classes.formGridTitle}>
            <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate('/app/forms')}>
                Back to All Forms
            </Button>
        </div>


    return (
        <ViewHead functionId={Functions.Forms_View_Form_Submissions}
                  isShowTitle={false} noMaxWidth>
            {currentWebForm ?
                <Typography variant='h3' className={classes.formGridTitle} color='textPrimary'>
                    Submissions for {currentWebForm.name} [{currentWebForm.category?.name || 'No Category'}]
                </Typography>
                :
                <Typography variant='h3' className={classes.formGridTitle} color='textPrimary'>
                    Unknown
                </Typography>
            }

            {renderBackButton()}

            <Paper elevation={0} className={classes.paper}>
                {renderSearchContainer()}
                {renderGrid()}
            </Paper>
            <ResponsiveDialog
                isOpen={openDeleteDialogue} openHash={hash}
                title="Delete Submission" content={`Delete Submission #${selectedSubmission}?`}
                Buttons={
                    [
                        {name: "Yes", event: () => onDeleteSubmission()},
                        {name: "Cancel", event: () => setSelectedSubmission(-1)}
                    ]
                }
            />


            <DraggableDialog open={Boolean(openDetails)} onClose={() => setOpenDetails(null)}
                             title='View Submissions' isLoaded={Boolean(openDetails)}>
                <Paper elevation={0}>
                    <WebSubmissionDetails rowId={clickedRow} isPopUp={openDetails}/>
                </Paper>

            </DraggableDialog>
        </ViewHead>
    )
}

export default FormData