import React from 'react';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Link,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AccountTree from '@mui/icons-material/AccountTree';
import {connect, useDispatch} from "react-redux";
import Grid from "@mui/material/Grid";
import {createOneFunction, FunctionActions,
    searchFunctions, searchFunctionsByParentId} from "../../../actions";
import SubmitButton from "../../../components/mui/button/SubmitButton";

const useStyles = makeStyles(()=> ({
    root: {},
    paper__form: {
        padding: "1rem 1rem",
        margin: "0 auto",
    },
    form__title: {
        marginBottom: ".5rem",
    },

}))


const schema = yup.object().shape({
    name: yup.string().required().min(3).trim(),
    router: yup.string().trim(),
    icon: yup.string().trim(),
});
function AddFunctionForm({currentFunction, createOneFunction, searchFunctionsByParentId, searchFunctions}) {
    const [opValue, setOpValue] = React.useState('r');
    const [afterSavingValue, setAfterSavingValue] = React.useState('continue');
    const [isSaving, setIsSaving] = React.useState(false);
    const classes = useStyles();
    const {register, errors, handleSubmit, reset, setError} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: "onChange",
    })
    const dispatch = useDispatch();

    const handleSaveSubmit = (formData) => {
        const data = {...formData}
        data.parent = currentFunction ? currentFunction.id : null;
        setIsSaving(true);
        createOneFunction(data, afterSaving, catchError)
    }
    const catchError = (error) => {
        setError(error) // todo not working yet
        setIsSaving(false);
    }
    const afterSaving = (data) => {

        if (afterSavingValue === "continue") {
            reset();
            if (data.parent) {
                searchFunctionsByParentId({parent: data.parent.id})
            } else {
                searchFunctions({})
            }
            setIsSaving(false);
        } else {
            dispatch({type: FunctionActions.SetFunctionFormStatus, payload: "Close"})
        }
    }

    const handleDeleteParent = () => {
        dispatch({type: FunctionActions.CancelSelectParent})
    }
    const renderParentChip = () => {
        if (currentFunction) {
            return <Chip variant="outlined" color="primary" icon={<AccountTree/>} label={currentFunction.name}
                         onDelete={handleDeleteParent}/>
        } 
            return <Chip variant="outlined" color="primary" icon={<AccountTree/>} label="Root"
                         style={{marginLeft: "1rem"}}/>
        
    }
    return (

        <Paper className={classes.paper__form}>
            <Typography className={classes.form__title} variant="h2" align="center">
                Create a new Function
            </Typography>
            <form noValidate onSubmit={handleSubmit(handleSaveSubmit)}>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" align="left">
                            Parent:
                        </Typography>
                    </Grid>
                    <Grid item>
                        {renderParentChip()}
                    </Grid>
                </Grid>

                <TextField variant="outlined" margin="normal" fullWidth name="name" id="name"
                           label="Name *" size="small"
                           autoComplete="name" autoFocus inputRef={register}
                           error={Boolean(errors.name?.message)}
                           helperText={errors.name?.message}
                />
                <TextField variant="outlined" margin="normal" fullWidth name="description" id="description"
                           label="Description" size="small"
                           autoComplete="description" inputRef={register}
                           error={Boolean(errors.description?.message)}
                           helperText={errors.description?.message}
                />
                <TextField variant="outlined" margin="normal" fullWidth name="router" id="router"
                           label="Router" size="small"
                           autoComplete="router" inputRef={register}
                           error={Boolean(errors.router?.message)}
                           helperText={errors.router?.message}
                />

                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField variant="outlined" margin="normal" fullWidth name="icon" id="phone"
                                           label="Icon" size="small"
                                           autoComplete="icon" inputRef={register}
                                           error={Boolean(errors.icon?.message)}
                                           helperText={errors.icon?.message}
                                />
                                <Typography variant="body2" color="textSecondary">
                                    <Link href="https://feathericons.com/" target="_blank">Icon only choose from
                                        www.feathericons.com </Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField variant="outlined" margin="normal" fullWidth name="orderKey" id="orderKey"
                                           label="Sort by keyword" size="small"
                                           autoComplete="orderKey" inputRef={register}
                                />
                                <Typography variant="body2" color="textSecondary">
                                    This keyword is to help the menu to be arranged in an
                                    orderly without depending on the creation time
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel control={
                                    <Checkbox name="directlyAccess" color="primary"
                                              inputRef={register}/>
                                } label="Directly Access"/>
                                <Typography variant="body2" color="textSecondary">
                                    True means functions that can be accessed directly by the user through the router.
                                    Functions like Delete and Approve are generally False
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Operations</FormLabel>
                                    <RadioGroup name="operationType" value={opValue}
                                                onChange={(event) => {
                                                    setOpValue(event.target.value)
                                                }}>
                                        <Grid container>
                                            <Grid><FormControlLabel value="r" control={<Radio inputRef={register}/>}
                                                                    label="Read"/></Grid>
                                            <Grid><FormControlLabel value="w" control={<Radio inputRef={register}/>}
                                                                    label="Write"/></Grid>
                                            <Grid><FormControlLabel value="d" control={<Radio inputRef={register}/>}
                                                                    label="Delete"/></Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">After Saving</FormLabel>
                                    <RadioGroup name="afterSaving" value={afterSavingValue}
                                                onChange={(event) => {
                                                    setAfterSavingValue(event.target.value)
                                                }}>
                                        <Grid container>
                                            <Grid><FormControlLabel value="continue"
                                                                    control={<Radio inputRef={register}/>}
                                                                    label="Create another one"/></Grid>
                                            <Grid><FormControlLabel value="close" control={<Radio inputRef={register}/>}
                                                                    label="Close Form"/></Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>


                <FormHelperText error={Boolean(errors.class?.message)}>
                    {errors.class?.message}
                </FormHelperText>
                <Grid container spacing={2}>
                    <Grid item sm={3}>
                        <SubmitButton isSaving={isSaving} text="Save" isSavingText="Saving"
                                      fullWidth variant="contained" color="primary"
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Button type="button" fullWidth variant="contained" color="primary" onClick={() => {
                            dispatch({type: FunctionActions.SetFunctionFormStatus, payload: "Close"})
                        }}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>


            </form>
        </Paper>

    );
}
const mapStateToProps = ({system}) => ({
        currentFunction: system.currentFunction,
        functions: system.functions
    })
export default connect(mapStateToProps,
    {
        createOneFunction,
        searchFunctionsByParentId,
        searchFunctions
    }
)(AddFunctionForm);
