import React from 'react'
import { Paper, Typography } from '@mui/material';

const extraMessageInfoModal = (messageDetail) => {

  try {
    const sorted = messageDetail?.submissions?.reduce((res, cur) => {
      if (['sent', 'read'].includes(cur.status)) {
        res[cur.status].push(`${cur.student.firstName} ${cur.student.lastName}`)
      }
      // else {
      //   console.log('Unmatched status:', cur.status)
      // }
      return res
    }, {"sent": [], "read": []})

    return (
      <Paper className='modalRoot'>
        <Typography variant='h3'>Read Message List:</Typography>
        <div>
          { sorted?.read.join(', ') }
        </div>

        <Typography variant='h3'>Not Read Message List:</Typography>
        <div>
          { sorted?.sent.join(', ') }
        </div>
      </Paper>
    )
  } catch (e) {
    // console.log('Sorting error:', e)
    return <Paper>Error</Paper>
  }
}
export default extraMessageInfoModal
