import { PosterMakerActionType } from '../../actions/forms/posterMakerAction';

const defaultState = {
  allPosterTemplates: {},
  currentPosterTemplate: {},
  currentPosterFile: null,
  currentLogoFile: null,
  currentPosterBaseFile: null,

  posterFetchProps: {},
}

export const posterMakerReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case PosterMakerActionType.FetchAllPosterTemplates:
      return { ...state, allPosterTemplates: action.payload }
    case PosterMakerActionType.FetchPosterTemplate:
      return { ...state, currentPosterTemplate: action.payload }

    case PosterMakerActionType.FetchPosterFile:
      return { ...state, currentPosterFile: action.payload }
    case PosterMakerActionType.FetchLogoFile:
      return { ...state, currentLogoFile: action.payload }
    case PosterMakerActionType.FetchPosterBaseFile:
      return { ...state, currentPosterBaseFile: action.payload }
    case PosterMakerActionType.ClearPosterFiles:
      return { ...state, currentPosterFile: null, currentLogoFile: null, currentPosterBaseFile: null }

    case PosterMakerActionType.PosterTemplateFetchProps:
      return { ...state, posterFetchProps: action.payload }

    default:
      return state
  }
}
export default posterMakerReducer