import {Button, Paper} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Functions} from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import {fetchFormById, fetchWebFormById} from '../../../actions/forms/formActions';
import FormBuilderFormHead from '../formBuilder/formBuilderFormHead';
import {FORM_BUILDER_HEAD_MODE} from '../formBuilder/formBuilderData';

const useStyles = makeStyles(() => ({
    paper: {
        padding: '3rem',
    }
}))

function FormEditView({rowId, tab}) {
    const  navigate = useNavigate();
    const slugParam = useParams().id
    const classes = useStyles()
    const dispatch = useDispatch()
    const currentFormInStorage = useSelector(state => state?.forms.currentForm)
    const currentWebForm = useSelector(state => state?.forms.currentWebForm)
    const [form, setForm] = useState(null)
    const [formId, setFormId] = useState(-1)
    const [isFormLoaded, setIsFormLoaded] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const currentTab = useSelector(state => state?.forms.currentFormTab)

    const loadData = () => {
        (async () => {
            if(tab === 0){
                setIsFormLoaded(await fetchFormById(slugParam || rowId)(dispatch))
            }else {
                setIsFormLoaded(await fetchWebFormById(slugParam || rowId)(dispatch))
            }
        })()
    }
    useEffect(loadData, [dispatch, slugParam || rowId])

    useEffect(() => {
        if (isFormLoaded) {
            setForm(currentTab === 0? currentFormInStorage : currentWebForm)
            setIsLoaded(true)
        }
    }, [currentTab,currentFormInStorage, currentWebForm,isFormLoaded])

    const renderBackButton = () => (
            <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate('/app/forms')}>
                Back to All Forms
            </Button>
        )

    return (
        <div>
            {slugParam ? <ViewHead functionId={Functions.Forms_Edit_Form} topButtons={renderBackButton()}>
                <Paper className={classes.paper}>
                    {isLoaded &&
                        <FormBuilderFormHead formId={formId} setFormId={setFormId} mode={FORM_BUILDER_HEAD_MODE.EDIT}
                                             formToEdit={form}/>}
                </Paper>
            </ViewHead> : <div>{isLoaded &&
                <FormBuilderFormHead formId={formId} setFormId={setFormId} mode={FORM_BUILDER_HEAD_MODE.EDIT}
                                     formToEdit={form}/>}
            </div>}
        </div>

    )
}

export default FormEditView
