import React, {useEffect} from 'react';
import {
    Toolbar,
    debounce,
    Grid,
    ButtonGroup,
    Button
} from '@mui/material';
import { lighten, alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {connect} from "react-redux";
import { CATEGORY_ITEMS } from "../../constants";
import {fetchCurrentCategories} from "../../actions";
import SearchBox from '../../components/public/SearchBox';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        padding: '0!important'
    },
    ButtonGroup: {
        marginRight:theme.spacing(1),
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    title: {
        flex: '1 1 100%'
    },
    inputRoot: {
        color: 'inherit',
        borderBottom: '2px solid #000'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch'
            }
        }
    },
    search: {
        padding: theme.spacing(1, 0),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        // marginLeft: 0,
        width: '100%',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    filterButton: {
        padding: theme.spacing(0, 2),
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        padding: theme.spacing(1, 0),
        width:"100%",

    },
    grid: {
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(4),
            width: 'auto'
        },
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    }

}));

function SearchProducts(props) {
    const {currentCategory, numSelected, onActive, handleChange, selectedCategory, setSelectedCategory, isLoading} = props;
    // const allCategories = useSelector(state => state.products.categories)
    const classes = useToolbarStyles();
    const [open, setOpen] = React.useState(false);
    // const [isPCLoaded, setIsPCLoaded] = useState(false)


    const loadData = () => {
        (async () => {
            // setIsPCLoaded(await fetchProductCategory()(dispatch))
        })()
    }
    useEffect(loadData, [])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const searchHandler = debounce((searchVal) => {
        handleChange(searchVal)
    }, 500)

    const handleDisplay = () => (
            <ButtonGroup variant='contained' color='primary' size='small' className={classes.ButtonGroup}>
                <Button
                    disabled={numSelected <= 0}
                    onClick={() => onActive(true)}>
                    Enable
                </Button>
                <Button
                    disabled={numSelected <= 0}
                    onClick={() => onActive(false)}>
                    Disable
                </Button>
            </ButtonGroup>
        )
    return (
        <div>
            <Toolbar className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={7} md={9} xl={10}>
                        <div className={classes.search}>
                            <SearchBox
                              placeholder='Search...'
                              size='small'
                              type='search'
                              searchHandler={searchHandler}
                              isLoading={isLoading}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={5} md={3} xl={2}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-controlled-open-select-label">Select</InputLabel>
                            <Select
                                size='small'
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                open={open}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                value={selectedCategory}
                                onChange={(e) => {
                                    setSelectedCategory(e.target.value)
                                    // filterResultsByCategory(e.target.value)
                                }}
                            >
                                <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
                                <MenuItem value={CATEGORY_ITEMS.none}>None</MenuItem>
                                {currentCategory.map((c,i) => <MenuItem key={i} value={c.name}>{c.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={6}>
                        {handleDisplay()}
                    </Grid>
           
                </Grid>
            </Toolbar>
            
        </div>

    );
}

export default connect(({products}) => ({
    currentCategory: products.productCategories,
}), {fetchCurrentCategories})(SearchProducts);
