import { useDispatch } from 'react-redux';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import { sendSuccessMessage } from '../../../../actions';
import ViewHead from '../../../../components/public/ViewHead';
import M2WReviewEditor from './M2WReviewEditor';

function M2WReviewCreateView() {
  useCurrentViewCheck(Functions.Review_M2W_Add)
  const  navigate = useNavigate();
  const dispatch = useDispatch()

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate('/app/review/m2w')
    }, 1000)
  }

  return (
    <ViewHead functionId={Functions.Review_M2W_Add}>
      <M2WReviewEditor onSubmitSuccess={onSuccess}
                       isEdit={false}
                       originalData={{}}/>
    </ViewHead>
  )
}

export default M2WReviewCreateView
