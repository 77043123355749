import React from 'react'
import {Navigate} from 'react-router'
import M2WReviewEditView from '../../../views/review/m2w/editor/editReviewView'
import M2WReviewCreateView from '../../../views/review/m2w/editor/createReviewView'
import ReviewListView from '../../../views/review/reviewList'
import ReviewSetListView from '../../../views/review/reviewSet'
import ReviewSetCreateView from '../../../views/review/reviewSet/editor/createReviewSetView'
import ReviewSetEditView from '../../../views/review/reviewSet/editor/editReviewSetView'
import ReviewSenderView from '../../../views/review/reviewSet/reviewSenderView'
import ProductReviewListView from '../../../views/review/product'

const reviewRoutes = [
    {
        path: 'review',
        routes: [
            {
                path: '',
                exact: 'true',
                component: <ReviewListView />
            },
            {
                path: 'set',
                exact: 'true',
                component: <ReviewSetListView />
            },
            {
                path: 'set/create',
                exact: 'true',
                component: <ReviewSetCreateView />
            },
            {
                path: 'set/edit/:uid',
                exact: 'true',
                component: <ReviewSetEditView />
            },
            {
                path: 'set/send/:uid',
                exact: 'true',
                component: <ReviewSenderView />
            },
            {
                path: 'm2w',
                exact: 'true',
                component: <ProductReviewListView />
            },
            {
                path: 'm2w/create',
                exact: 'true',
                component: <M2WReviewCreateView />
            },
            {
                path: 'm2w/edit/:id',
                exact: 'true',
                component: <M2WReviewEditView />
            },

            {
                component: <Navigate to="404" />
            }
        ]
    }
]

export default reviewRoutes
