import React, {useEffect, useState} from 'react'
import {Button, Divider, Paper, Typography} from '@mui/material'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid'
import parse from 'html-react-parser'
import {useNavigate} from 'react-router-dom'
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck'
import {Functions} from '../../../../utils/Functions'
import ViewHead from '../../../../components/public/ViewHead'
import {fetchContractTemplate, fetchTermsBeSelected} from '../../../../actions/administrative/administrativeAction'
import CenterDisplay from '../../../../components/misc/CenterDisplay'
import {contractFooter, useContractViewStyles} from '../../contract/contractHelper'
import {ServerBaseURL} from '../../../../constants'

function ContractView({rowId}) {
    useCurrentViewCheck(Functions.Administrative_Contract_Template_List)
    const navigate = useNavigate()
    const urlId = useParams().id
    const dispatch = useDispatch()
    const classes = useContractViewStyles()

    const currentContract = useSelector(state => state?.administrative.currentContractTemplate)
    const selectedTerms = useSelector(state => state?.administrative.selectedTerms)

    const { t, i18n } = useTranslation()

    const [isLoaded, setIsLoaded] = useState(false)
    const [isTermsLoaded, setIsTermsLoaded] = useState(false)
    const [ckText, setCkText] = useState('')

    const loadData = () => {
        (async () => {
            setIsLoaded(await fetchContractTemplate(urlId || rowId)(dispatch))
        })()
    }
    useEffect(loadData, [dispatch, urlId || rowId])

    useEffect(() => {
        if (currentContract && currentContract.type === 'head') {
            setIsTermsLoaded(true)
            if (currentContract?.language && i18n.language !== currentContract.language) {
                i18n.changeLanguage(currentContract.language)
            }
        }
        if (currentContract?.text !== '') {
            const dom = new DOMParser().parseFromString(currentContract?.text, 'text/html')
            const indexes = Array.from(dom.getElementsByClassName('term'), term => Number(term.getAttribute('data-id')))
            dispatch(fetchTermsBeSelected(indexes))
        }
    }, [currentContract])

    useEffect(() => {
        if (selectedTerms && currentContract?.text) {
            const dom = new DOMParser().parseFromString(currentContract?.text, 'text/html')
            const indexes = Array.from(dom.getElementsByClassName('term'), term => Number(term.getAttribute('data-id')))
            const elements = dom.querySelectorAll('.term')
            elements.forEach((v, i) => {
                v.innerHTML = selectedTerms.find(value => value.id === indexes[i])?.text
            })

            const e = dom.getElementsByTagName('body')[0].outerHTML
            setCkText(e.substring(6, e.length - 7))
            setIsTermsLoaded(true)
        }
    }, [selectedTerms])

    const renderContent = () => (
        <Grid container>
            {currentContract.type === 'head' ? (
                <div>
                    <Grid item xs={12} md={6}>
                        {/* <div className={classes.headerText} */}
                        {/*     dangerouslySetInnerHTML={{__html: currentContract.text}}/> */}
                        <div className={classes.headerText}>{parse(currentContract?.text || '')}</div>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.headerImage}>
                        <img
                            alt="header logo for contract"
                            src={`${ServerBaseURL}/asset/contractTemplate/headerImage/${currentContract.id}.png`}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.marginExtraTop}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className={classes.marginTop}>
                        {contractFooter(t, '', null, false, {
                            name: currentContract.partyBName,
                            signature: currentContract.partyBSignatureImage
                        })}
                    </Grid>
                </div>
            ) : (
                <Grid item xs={12}>
                    {/* <div className={classes.normalText} */}
                    {/*     dangerouslySetInnerHTML={{__html: currentContract.text}}/> */}
                    <div className={classes.ck}>{parse(ckText || '')}</div>
                </Grid>
            )}
        </Grid>
    )

    const renderBackButton = () => (
        <div>
            <Button
                color="secondary"
                variant="contained"
                onClick={() => navigate('/app/administrative/contractTemplate')}
            >
                Back
            </Button>
            <Button
                color="primary"
                variant="contained"
                style={{margin: '0 1rem'}}
                onClick={() => navigate(`/app/administrative/contractTemplate/add/${urlId}`)}
            >
                Clone
            </Button>
            <Button
                color="primary"
                variant="contained"
                onClick={() => navigate(`/app/administrative/contractTemplate/edit/${urlId}`)}
            >
                Edit
            </Button>
        </div>
    )

    return (
        <div>
            {urlId ? (
                <ViewHead functionId={Functions.Administrative_Contract_Template_List} topButtons={renderBackButton()}>
                    <Paper className={classes.paper}>
                        {isLoaded && isTermsLoaded ? (
                            <div className={classes.container}>
                                <Typography variant="h2" className={classes.pageTitleText}>
                                    {currentContract.name}
                                </Typography>
                                {renderContent()}
                            </div>
                        ) : (
                            <CenterDisplay>
                                <Typography variant="h1" color="textPrimary">
                                    Loading...
                                </Typography>
                            </CenterDisplay>
                        )}
                    </Paper>
                </ViewHead>
            ) : (
                <Paper className={classes.paper}>
                    {isLoaded ? (
                        <div className={classes.container}>
                            <Typography variant="h2" className={classes.pageTitleText}>
                                {currentContract.name}
                            </Typography>
                            {renderContent()}
                        </div>
                    ) : (
                        <CenterDisplay>
                            <Typography variant="h1" color="textPrimary">
                                Loading...
                            </Typography>
                        </CenterDisplay>
                    )}
                </Paper>
            )}
        </div>
    )
}

export default ContractView
