import _ from 'lodash';
import {
    ErrorActionTypes,
    MessageActionTypes,
    ReadMessageTypes
} from "../../actions";


const DefaultMessageState = {
    error: [],
    message: [],
    notification: [],
    TrainingData:{
        questions:{}
    }
}

export const messageReducer = (
    state = DefaultMessageState,
    action = {}) => {
    switch (action.type) {
        case ErrorActionTypes.NetworkError:
        case ErrorActionTypes.NotAcceptable:
        case ErrorActionTypes.UnknownError:
        case ErrorActionTypes.InternalServerError:
        case ErrorActionTypes.NotFound:
        case ErrorActionTypes.Unauthorized:{
            const errorState = {...state};
            // repoint to the message not error.
            if (!Array.isArray(errorState.message)) {
                errorState.message = [];
            }
            errorState.message.push({
                id: action.payload.id,
                type: "error",
                message: `${action.payload.error  } - ${  action.payload.message}`,
                errorType: action.type,
            });
            return errorState;}

        case MessageActionTypes.SendMessage:{
            const messageState = {...state};
            if (!Array.isArray(messageState.message)) {
                messageState.message = []
            }
            messageState.message.push(action.payload)
            return messageState;}

        case ReadMessageTypes.ReadMessage:
        case ReadMessageTypes.ReadError:
        case ReadMessageTypes.ReadNotification:
            return __omitMessageFromState(state, action)

        case MessageActionTypes.ClearAll:
            return DefaultMessageState;
            /// // Training
        case 'loadTrainingData':
            return {...state,TrainingData:{questions:action.payload}}
        default:
            return state;

    }

}
const __omitMessageFromState = (state, action) => {
    const newState = _.cloneDeep(state);
    switch (action.type) {
        case ReadMessageTypes.ReadMessage:
            if (newState.message) {
                _.remove(newState.message, item => item.id === action.payload.id)
            }
            return newState;
        case ReadMessageTypes.ReadError:
            if (newState.error) {
                _.remove(newState.error, item => item.id === action.payload.id)
            }
            return newState;
        case ReadMessageTypes.ReadNotification:
            if (newState.notification) {
                _.remove(newState.notification, item => item.id === action.payload.id)
            }
            return newState;
        default:
            return newState;
    }

}
export default messageReducer