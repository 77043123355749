import axios from "axios";
import {LocalStorageItems, ServerBaseURL} from "../constants";
import LocalStorage from "../utils/LocalStorage";


const token = LocalStorage.getItem(LocalStorageItems.Token);
export const uploadConfig = {
    timeout: 50000,
    baseURL: ServerBaseURL,
    headers: {
        "Content-Type": "multipart/form-data",
        authorization : `Bearer ${  token}`
    }
}
export const uploadApi = axios.create(uploadConfig);