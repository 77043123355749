import React from 'react';
import { useSelector } from 'react-redux';
import { Functions } from '../../../utils/Functions';
import CategoryManager from '../../../components/form/CategoryManager';
import ViewHead from '../../../components/public/ViewHead';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import {
  addQRCodeCategory,
  deleteQRCodeCategory,
  fetchQRCodeCategories,
  updateQRCodeCategory
} from '../../../actions/qrcode/qrcodeAction';

function QRCodeCategoryManagerView() {
  useCurrentViewCheck(Functions.QRCode_Category)

  const allCategories = useSelector(state => state.qrcode.allCategories)
  return (
    <ViewHead functionId={Functions.QRCode_Category} isShowTitle={false} >
      <CategoryManager
        allCategories={allCategories}
        fetchCategoryAction={fetchQRCodeCategories}
        addCategoryAction={addQRCodeCategory}
        deleteCategoryAction={deleteQRCodeCategory}
        updateCategoryAction={updateQRCodeCategory}
      />
    </ViewHead>
  )
}

export default QRCodeCategoryManagerView
