import React from 'react'
import { Paper, Typography } from '@mui/material';

export const extraPostInfoModal = ({ allUsers,
                                     userDownloaded=[],
                                     userNotDownloaded=[],
                                     userTracks }) => (
    <Paper className='modalRoot'>
      {
        userDownloaded.length > 0 &&
        <div>
          <Typography variant='h3'>Downloaded:</Typography>
          <div>
            { userDownloaded.map((usr, idx) => {
              const currentUser = allUsers[usr]
              return currentUser ? `${currentUser.firstName} ${currentUser.lastName}${idx < userDownloaded.length - 1 ? ', ' : ''}` : ''
            })}
          </div>
        </div>
      }

      {
        userNotDownloaded.length > 0 &&
        <div>
          <Typography variant='h3'>Not Downloaded:</Typography>
          <div>
            { userNotDownloaded.map((usr, idx) => {
              const currentUser = allUsers[usr]
              return currentUser ? `${currentUser.firstName} ${currentUser.lastName}${idx < userNotDownloaded.length - 1 ? ', ' : ''}` : ''
              }
            )}
          </div>
        </div>
      }

      <Typography variant='h3'>Scans:</Typography>
      { Object.keys(allUsers).map((usr,index) => {
        const usrScanCount = userTracks[usr]
        return <div key={index}>
          { allUsers[usr].firstName } { allUsers[usr].lastName }: { usrScanCount || 0 }
        </div>
      })}
    </Paper>
  )
export default extraPostInfoModal