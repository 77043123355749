import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {cloneDeep} from 'lodash'
import {
    deleteTopUpUser,
    fetchAllTopUpUsers,
    refreshTopUpUser
} from '../../../../actions/matric/topupActions'
import {getBasicHash, getTotalHeight} from '../../../../utils/Scripts'
import {
    BBS_SITE_LIST,
    topUpAccountMapTreeData,
    TREE_LIST_CHILD_FIELD,
} from '../../matricHelper'
import ResponsiveDialog from '../../../../components/dialog/ResponsiveDialog'
import {AccountEditCommandCell, AccountStatusCell, AccountTimeCell} from './AccountCommandCells'
import LXGrid from '../../../../components/mui/datagrid/XGrid';


function TopUpAccountView() {
    const dispatch = useDispatch()
    const data = useSelector(state => state.matric.allTopUpUsers)
    const expandStatus = useSelector(state => state.matric.accountExpandStatus)

    const [mappedTreeData, setMappedTreeData] = useState([])
    const [displayData, setDisplayData] = useState([])
    const totalHeight = getTotalHeight()

    const [hash, setHash] = useState('init')
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
    const [selectedForDelete, setSelectedForDelete] = useState(null)

    const [isLoaded, setIsLoaded] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)

    const loadData = () => {
        (async () => {
            setIsLoaded(await fetchAllTopUpUsers()(dispatch))
        })()
    }
    useEffect(loadData, [])

    useEffect(() => {
        // This refresh when server data refresh
        setDisplayData(cloneDeep(data))
    }, [data, dispatch])

    useEffect(() => {
        setMappedTreeData(topUpAccountMapTreeData(data, expandStatus))
    }, [displayData, expandStatus])

    const onUserDelete = async () => {
        setDataLoading(true)
        await deleteTopUpUser(selectedForDelete)(dispatch)
        setDataLoading(false)
    }

    const handleUserDelete = async (site, username) => {
        setSelectedForDelete({site, username})
        setOpenDeleteDialogue(true)
        setHash(getBasicHash())
    }

    const handleUserRefresh = async (site, username) => {
        setDataLoading(true)
        await refreshTopUpUser({site, username})(dispatch)
        setDataLoading(false)
    }

    const columns = [
        {
            headerName: 'Status',
            field: 'status',
            renderCell: props => <AccountStatusCell {...props} />,
            width: 200
        },
        {
            field: 'notes',
            headerName: 'Description',
            width: 250
        },
        {
            headerName: 'Time',
            field: 'createTime',
            renderCell: props => <AccountTimeCell {...props} />,
            width: 250
        },
        {
            field: 'owner',
            headerName: 'Owner',
            width: 150
        },
        {
            headerName: '',
            field: '',
            renderCell: props => (
                <AccountEditCommandCell
                    {...props}
                    loading={dataLoading}
                    handleUserRefresh={handleUserRefresh}
                    handleUserDelete={handleUserDelete}
                />
            ),
            width: 160
        }
    ]

    return (
        <div>
            {isLoaded && (
                <div>
                    <LXGrid
                        treeData
                        totalHeight={`${totalHeight - 100}px`}
                        rows={mappedTreeData}
                        columns={columns}
                        getTreeDataPath={row => row[TREE_LIST_CHILD_FIELD]}
                        expandedRowIds={expandStatus}
                        defaultGroupingExpansionDepth={2}
                        density="comfortable"
                    />
                    <br />
                    <ResponsiveDialog
                        isOpen={openDeleteDialogue}
                        openHash={hash}
                        title="Are you sure?"
                        content={`Delete user [${selectedForDelete?.username}] from ${
                                BBS_SITE_LIST.find(s => s.symbol === selectedForDelete?.site)?.name
                            }?`}
                        Buttons={[
                            {name: 'Yes', event: () => onUserDelete()},
                            {name: 'No', event: () => setSelectedForDelete(null)}
                        ]}
                    />
                </div>
            )}
        </div>
    )
}

export default TopUpAccountView
