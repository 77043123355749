import * as yup from 'yup';
import {
  Button,
  Card, CardContent,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import interact from 'interactjs'
import { sendErrorMessage } from '../../../../actions';
import SubmitButton from '../../../../components/mui/button/SubmitButton';
import { formatByteSize } from '../../../../utils/FormData';
import { createCampaignPoster, editCampaignPoster } from '../../../../actions/campaign/campaignActions';
import {
  CAMPAIGN_POSTER_CREATE_VALIDATOR,
  checkPosterSubmitData,
  DEFAULT_POSTER_IMG, editPosterStyles, IMG_URL_BASE,
  testFile
} from '../../campaignHelper';
import setupInteractable from '../../../../components/misc/InteractableComponent';

const schema = yup.object().shape(CAMPAIGN_POSTER_CREATE_VALIDATOR);
function CampaignPosterEditor({ isEdit, campaignId, posterData, onSubmitSuccess }) {

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  })

  const classes = editPosterStyles()
  const dispatch = useDispatch()

  const posterCanvasRef = useRef(null)
  const posterWrapperRef = useRef(null)
  const qrBoxRef = useRef(null)

  const [expandHeight, setExpandHeight] = useState(800)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [posterImageDetails, setPosterImageDetails] = useState({ width: 0, height: 0, size: 0, file: '' })
  const [imageScale, setImageScale] = useState(1.0)
  const [qrCoords, setQrCoords] = useState(isEdit ? posterData.coordinates : { 1: { x: 0, y: 0 }, 2: { x: 0, y: 0 } })

  // For Preview Div
  const [isPreview, setIsPreview] = useState(false)
  const [previewStyle, setPreviewStyle] = useState({ top: 0, left: 0, width: 0, height: 0 })

  const onSubmit = async (formData) => {
    setIsSaving(true)

    const constFormTestRes = checkPosterSubmitData(dispatch, isEdit, formData, setError, posterImageDetails, loadDefaultImage)

    // Contains error
    if (!constFormTestRes) {
      setIsSaving(false)
      return
    }

    // Passed all the test
    if (isEdit) {
      // Upload poster details
      const putData = {
        id: posterData.id.toString(),
        campaignId: campaignId.toString(),
        coordinates: JSON.stringify(qrCoords),
        name: formData.name,
        title: formData.title,
        description: formData.description,
        width: posterImageDetails.width.toString(),
        height: posterImageDetails.height.toString()
      }
      await editCampaignPoster(putData, onSubmitSuccess, onError)(dispatch)
    } else {
      // Upload poster file
      const posterPackage = new FormData()
      posterPackage.append('poster', formData.file[0])
      posterPackage.append('campaignId', campaignId)
      posterPackage.append('coordinates', JSON.stringify(qrCoords))
      posterPackage.append('name', formData.name)
      posterPackage.append('title', formData.title)
      posterPackage.append('description', formData.description)
      posterPackage.append('width', posterImageDetails.width)
      posterPackage.append('height', posterImageDetails.height)
      await createCampaignPoster(posterPackage, onSubmitSuccess, onError)(dispatch)
    }
  }

  const onError = (msg) => {
    sendErrorMessage(dispatch, msg)
    setIsSaving(false)
  }

  const locSetDrag = (e) => {

    // Canvas location
    const canvasBounds = posterCanvasRef.current.getBoundingClientRect()
    // QR Code location
    const bounds = e.currentTarget.getBoundingClientRect()

    // Top Left
    const pos1X = Math.round((bounds.left - canvasBounds.left) / imageScale)
    const pos1Y = Math.round((bounds.top - canvasBounds.top) / imageScale)

    // Bottom Right
    const pos2X = Math.round((bounds.left + bounds.width - canvasBounds.left) / imageScale)
    const pos2Y = Math.round((bounds.top + bounds.height - canvasBounds.top) / imageScale)

    // Reset QR moved transformation and data (data save upon releasing pointer)
    e.target.setAttribute('data-x', 0)
    e.target.setAttribute('data-y', 0)
    e.target.style.webkitTransform = `translate(${  0  }px,${  0  }px)`
      e.target.style.transform = `translate(${  0  }px,${  0  }px)`

    setQrCoords({ 1: { x: pos1X, y: pos1Y }, 2: { x: pos2X, y: pos2Y } })

    showPreviewCoordinates({ 1: { x: pos1X, y: pos1Y }, 2: { x: pos2X, y: pos2Y } })
  }

  const showPreviewCoordinates = (forceData = null) => {

    let width
    let height
    let top
    let left

    // ForceData
    // true = sent by moving qr code; false = clicked preview button
    if (forceData) {
      width = ((forceData['2'].x - forceData['1'].x) * imageScale)
      height = ((forceData['2'].y - forceData['1'].y) * imageScale)
      top = (forceData['1'].y * imageScale)
      left = (forceData['1'].x * imageScale)
    } else {
      width = ((qrCoords['2'].x - qrCoords['1'].x) * imageScale)
      height = ((qrCoords['2'].y - qrCoords['1'].y) * imageScale)
      top = (qrCoords['1'].y * imageScale)
      left = (qrCoords['1'].x * imageScale)

      setIsPreview(!isPreview)
    }

    if (width <= 0 || height <= 0) {
      setIsPreview(false)
      sendErrorMessage(dispatch, 'Invalid QR coordinates')
      return
    }

    // console.log('imageScale', imageScale)
    // console.log('width', width)
    // console.log('height', height)
    setPreviewStyle({ top, left, width, height })
  }

  useEffect(() => {
    if (!isLoaded) {
      // Obtain refs
      if (posterCanvasRef) {
        loadDefaultImage( isEdit ? `${IMG_URL_BASE}/${posterData.uid}` : DEFAULT_POSTER_IMG)
      }
    }
  }, [posterCanvasRef, isLoaded, isEdit, posterData])

  const renderImageFile = (file) => {
    // Test file type
    if (!testFile(dispatch, file, true, loadDefaultImage)){
      return
    }

    setIsPreview(false)

    // Load image into canvas
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = ((e) => {
      const img = new Image()
      img.src = e.target.result
      img.onload = (() => {
        const canvas = posterCanvasRef.current
        const context = canvas.getContext('2d')

        // Draw canvas to match scale
        // Get max width of element & calc scale
        const maxWidth = posterWrapperRef.current.getBoundingClientRect().width - 50
        const scale = maxWidth / img.naturalWidth
        setImageScale(scale)

        canvas.width = maxWidth
        canvas.height = img.naturalHeight * scale
        context.drawImage(img, 0, 0, img.naturalWidth * scale, img.naturalHeight * scale)

        setExpandHeight(canvas.height)

        setPosterImageDetails({ file: file.name, size: file.size, height: img.naturalHeight, width: img.naturalWidth })

        if (!isEdit) {
          // Set initial size for QR Code when no image is given

          const initQrSize = Math.max(img.naturalWidth, img.naturalHeight)

          const initParams = {
            1: { x: 0, y: 0 },
            2: { x: Math.round(initQrSize * .2), y: Math.round(initQrSize * .2) }
          }
          setQrCoords(initParams)
        }
      })
    })
  }

  useEffect(() => {
    // when QR code div is loaded
    if (qrBoxRef?.current && posterCanvasRef.current && isLoaded) {
      const c = interact(qrBoxRef?.current)
      setupInteractable(c, locSetDrag)
    }
  }, [qrBoxRef, posterCanvasRef, isLoaded, imageScale, locSetDrag])


  const loadDefaultImage = (posterSrc = null) => {
    // Load in placeholder
    const img = new Image()
    img.src = posterSrc ?? DEFAULT_POSTER_IMG
    img.onload = (() => {
      const canvas = posterCanvasRef.current
      const context = canvas.getContext('2d')

      // Draw canvas to match scale
      // Get max width of element & calc scale
      const maxWidth = posterWrapperRef.current.getBoundingClientRect().width - 50

      const scale = maxWidth / img.naturalWidth
      setImageScale(scale)

      canvas.width = maxWidth
      canvas.height = img.naturalHeight * scale
      context.drawImage(img, 0, 0, img.naturalWidth * scale, img.naturalHeight * scale)

      // Calculate the height of the canvas => how much the container div should expand
      posterWrapperRef.current.height = canvas.height
      setExpandHeight(canvas.height)

      setPosterImageDetails({ file: 'N/A', size: 0, height: img.naturalHeight, width: img.naturalWidth })

      // Everything is done setting up
      setIsLoaded(true)
    })
  }

  const renderImageDetails = () => (
      <Grid container alignContent='center' justifyContent='center'>
        {/* Image File Details */}
        <Grid item xs={12} sm={6}>
          <Card className={classes.posterCardDetails}>
            <CardContent>
              <Typography variant='h3' className='cardHeader'>Poster Details</Typography>
              <Typography variant='body2' component='p'>Width: { posterImageDetails.width }px</Typography>
              <Typography variant='body2' component='p'>Height: { posterImageDetails.height }px</Typography>
              <Typography variant='body2' component='p'>Size: { formatByteSize( isEdit ? posterData.size : posterImageDetails.size) }</Typography>
              <Typography variant='body2' component='p'>File: { posterImageDetails.file }</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* QR Code Coordinates */}
        <Grid item xs={12} sm={6}>
          <Card className={classes.posterCardDetails}>
            <CardContent>
              <Typography variant='h3' className='cardHeader'>Set QR Coords</Typography>

              <Grid container justifyContent='space-between' direction='row' alignItems='center'>
                <Grid item xs={12} className='coordsHeading'>
                  {/* <IconButton color="primary" component="span" size="small" disabled={isSaving} */}
                  {/*            onClick={() => { */}
                  {/*              setIsPreview(false) */}
                  {/*              setIsSettingPos(1) */}
                  {/*            }}> */}
                  {/*  <Crosshair style={{ color: 'green' }}/> */}
                  {/* </IconButton> */}
                  <Typography variant='h5' component='span'>Top Left:</Typography>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.coordsSelector}>
                    <TextField fullWidth size='small'
                               label='X' required type='number'
                               disabled={isSaving} variant='outlined'
                               name='qr1x' inputRef={register}
                               InputLabelProps={{ shrink: true }}
                               value={qrCoords['1'].x}
                               onChange={(e) =>
                                 e.target.value
                                   ? setQrCoords({ ...qrCoords, 1: { x: parseInt(e.target.value,10), y: qrCoords['1'].y } })
                                   : setQrCoords({ ...qrCoords, 1: { x: 0, y: qrCoords['1'].y } })
                               }
                               error={Boolean(errors.qr1x?.message)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.coordsSelector}>
                    <TextField fullWidth size='small'
                               label='Y' required type='number'
                               disabled={isSaving} variant='outlined'
                               name='qr1y' inputRef={register}
                               InputLabelProps={{ shrink: true }}
                               value={qrCoords['1'].y}
                               onChange={(e) =>
                                 e.target.value
                                   ? setQrCoords({ ...qrCoords, 1: { x: qrCoords['1'].x, y: parseInt(e.target.value,10) } })
                                   : setQrCoords({ ...qrCoords, 1: { x: qrCoords['1'].x, y: 0 } })
                               }
                               error={Boolean(errors.qr1y?.message)}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' direction='row' alignItems='center'>
                <Grid item xs={12} className='coordsHeading'>
                  {/* <IconButton color="primary" component="span" size="small" disabled={isSaving} */}
                  {/*            onClick={() => { */}
                  {/*              setIsPreview(false) */}
                  {/*              setIsSettingPos(2) */}
                  {/*            }}> */}
                  {/*  <Crosshair style={{ color: 'green' }}/> */}
                  {/* </IconButton> */}
                  <Typography variant='h5' component='span'>Bottom Right:</Typography>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.coordsSelector}>
                    <TextField fullWidth size='small'
                               label='X' required type='number'
                               disabled={isSaving} variant='outlined'
                               name='qr2x' inputRef={register}
                               InputLabelProps={{ shrink: true }}
                               value={qrCoords['2'].x}
                               onChange={(e) => {
                                 if (e.target.value){
                                   setQrCoords({ ...qrCoords, 2: { x: parseInt(e.target.value,10), y: qrCoords['2'].y } })
                                 }else{
                                   setQrCoords({ ...qrCoords, 2: { x: 0, y: qrCoords['2'].y } })
                                 }
                               }}
                               error={Boolean(errors.qr2x?.message)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.coordsSelector}>
                    <TextField fullWidth size='small'
                               label='Y' required type='number'
                               disabled={isSaving} variant='outlined'
                               name='qr2y' inputRef={register}
                               InputLabelProps={{ shrink: true }}
                               value={qrCoords['2'].y}
                               onChange={(e) => {
                                 if (e.target.value){
                                   setQrCoords({ ...qrCoords, 2: { x: qrCoords['2'].x, y: parseInt(e.target.value,10) } })
                                 }else{
                                   setQrCoords({ ...qrCoords, 2: { x: qrCoords['2'].x, y: 0 } })
                                 }
                               }}
                               error={Boolean(errors.qr2y?.message)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormHelperText>{errors.qr?.message ? errors.qr?.message : ''}</FormHelperText>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => showPreviewCoordinates()}
                  className={isPreview ? classes.buttonActiveStyle : classes.buttonInactiveStyle}
                  startIcon={isPreview ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                >
                  { isPreview ? 'Hide Preview' : 'Show Preview' }
                </Button>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )

  const renderForm = () => (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={5} direction='row' justifyContent='space-between' alignItems='flex-start'>

          {/* Left */}
          <Grid item xs={12} md={6}>

            <Grid container spacing={1} direction='column'>

              <Grid item xs={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField fullWidth size='small'
                             label='Name' required
                             disabled={isSaving} variant='outlined'
                             name='name' inputRef={register}
                             defaultValue={ isEdit ? posterData.name : '' }
                             error={Boolean(errors.name?.message)}
                             helperText={errors.name?.message ? errors.name?.message : 'Max 30 characters'}
                  />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                  <TextField fullWidth size='small'
                             label='Title' required
                             disabled={isSaving} variant='outlined'
                             name='title' inputRef={register}
                             defaultValue={ isEdit ? posterData.title : '' }
                             error={Boolean(errors.title?.message)}
                             helperText={errors.title?.message ? errors.title?.message : 'Max 30 characters'}
                  />
                </FormControl>

                <FormControl fullWidth className={classes.fileInput} required disabled={isSaving || isEdit}
                             error={Boolean(errors.file?.message)}>
                  <FormLabel component='legend'>Mother Poster</FormLabel>
                  <div>
                    <input type="file" name='file' accept='image/png,image/jpeg' disabled={isSaving || isEdit} ref={register}
                           onChange={e => renderImageFile(e.target.files[0])}
                    />
                  </div>
                  <FormHelperText className={classes.marginReset}>{errors.file?.message ? errors.file?.message : 'JPG or PNG format, max 5M'}</FormHelperText>
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                  <TextField fullWidth size='small'
                             label='Description' required
                             multiline rows={5}
                             disabled={isSaving} variant='outlined'
                             name='description' inputRef={register}
                             defaultValue={ isEdit ? posterData.description : '' }
                             error={Boolean(errors.description?.message)}
                             helperText={errors.description?.message}
                  />
                </FormControl>

                { renderImageDetails() }

              </Grid>
            </Grid>
          </Grid>


          {/* Right */}
          <Grid item xs={12} md={6} ref={posterWrapperRef} style={{ height: expandHeight }}>

            <Grid container spacing={1} direction='column' alignItems='center'>

              <Grid item xs={12}>
                <div className={classes.stiffPoster}>
                  <div
                    ref={qrBoxRef}
                    className={classes.posterQRBox}
                    style={{ display: isPreview ? 'block' : 'none', ...previewStyle }}
                  />
                  <canvas id='posterCanvas' width={300} height={500} ref={posterCanvasRef}  style={{ zIndex: 1 }}/>
                </div>

              </Grid>
            </Grid>
          </Grid>

          {/* Bottom Submit Button */}
          <Grid item xs={12}>
            <Grid container spacing={1} direction='row' justifyContent='flex-end'>
              <Grid item xs={2} className={classes.submitButton}>
                <SubmitButton isSaving={isSaving} text='Submit' isSavingText='Submitting'
                              fullWidth variant='contained' color='primary'
                />

              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </form>
    )

  return (
    <Paper className={classes.paper}>
      { renderForm() }
    </Paper>
  )

}

export default CampaignPosterEditor
