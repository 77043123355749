import * as React from 'react';
import {
  Button,
  Fab, LinearProgress,
  Paper, Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import * as _ from 'lodash';
import Chip from '@mui/material/Chip';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { debounce } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import { useTheme } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import SwipeableViews from 'react-swipeable-views';
import { Alert } from '@mui/lab';
import { centsToDollarString } from '../../../../utils/PaymentHelper';
import { useContractViewStyles } from '../contractHelper';
import SearchBox from '../../../../components/public/SearchBox';
import ContractDisplayLoader from '../contractPreview/ContractDisplayLoader';
import {
  assignPaymentEmailToOneContract, createPaymentHistoryFromLog, fetchSinglePaymentLog,
  searchContractsNoPage
} from '../../../../actions/administrative/administrativeAction';
import DraggableDialog from '../../../../components/mui/dialog/DraggableDialog';
import { sendErrorMessage } from '../../../../actions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0.1 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
TabPanel.defaultProps = {
  children:<div />
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}


export function ContractPaymentLogActs(props) {
  const { isPaymentLogActView, setIsPaymentLogActView, paymentLogId,loadData:loadParentData } = props;
  const dispatch = useDispatch();
  const classes = useContractViewStyles();
  const theme = useTheme();
  const currentPaymentLog = useSelector(state => state?.administrative.currentPaymentLog);
  const [paymentlogName, setPaymentlogName] = useState({ firstName: '', lastName: '' });
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchContractResult, setSearchContractResult] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [currentContract, setCurrentContract] = useState(null);
  const [amountPaying,setAmountPaying] = useState(`${+(currentPaymentLog?.paymentLog?.unusedAmount || 0)/100}`)
  const [value, setValue] = React.useState(0);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const loadData = () =>{
    setIsLoaded(false);
    fetchSinglePaymentLog(paymentLogId)(dispatch).then((res) => {
      setSearchContractResult([])
      setIsLoaded(res);

    });


  }
  useEffect(() => {
    loadData()
  }, [paymentLogId]);

  useEffect(()=>{
    setAmountPaying(`${+(currentPaymentLog?.paymentLog?.unusedAmount || 0)/100}`)
  },[currentPaymentLog])


  const getPayerName = (payerName) => {
    const temp = _.takeRight(payerName.trim().replace(/\s\s+/g, ' ').split(' '), 2);
    if (temp.length === 2) {
      return { firstName: temp[0], lastName: temp[1] };
    }
    if (temp.length === 1) {
      return { firstName: temp[0], lastName: '' };
    }
    return{firstName: '',lastName: ''}
  };

  useEffect(() => {
      if (!_.isEmpty(currentPaymentLog) && _.isEmpty(currentPaymentLog?.paymentEmail)) {
        const temp = _.takeRight(currentPaymentLog?.paymentLog?.payerName.trim().replace(/\s\s+/g, ' ').split(' '), 2);
        if (temp.length === 2) {
          setPaymentlogName({ firstName: temp[0], lastName: temp[1] });
        }
        if (temp.length === 1) {
          setPaymentlogName({ firstName: temp[0], lastName: '' });
        }
      }else if (!_.isEmpty(currentPaymentLog) && !_.isEmpty(currentPaymentLog?.paymentEmail)){
        setPaymentlogName({firstName: currentPaymentLog?.paymentEmail.fname,lastName: currentPaymentLog?.paymentEmail.lname})
      }

  }, [currentPaymentLog]);


  const cidFromEmail = currentPaymentLog?.paymentEmail?.contracts?.map((contract) => contract.cid) ?? [];


  const filtedRecommend = currentPaymentLog?.recommendContracts?.filter((ele) => cidFromEmail?.indexOf(ele?.cid) < 0);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  };
  function ChipLegend() {
    const StyledChip = withStyles({
      root: {
        MaxWidth: '25%',
        fontSize: '12px',
        height: '17px'
      }
    })(Chip);
    return <Stack direction='row' spacing={0.2} sx={{ opacity: '0.8', width: '100%', mb: 1, mt: 1 }}>
      <StyledChip variant="filled" label='Linked' color='primary' />
      <StyledChip variant="filled" label='Completed' color='success' />
      <StyledChip variant="filled" label='Recommend' color='info' />
      <StyledChip variant="filled" label='Searched' color='default' />
    </Stack>;

  }


  function ContractChip(propsTemp) {
    const {contract,color} = propsTemp
    const StyledChip = withStyles({
      root: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start'
      }
    })(Chip);
    const textValue = `${contract.customer.firstName} 
                 ${contract?.customer.nickName !== '' ? `(${contract?.customer.nickName})` : ''} 
                 ${contract.customer.lastName}
                  ${contract.cid}`;
    return (
      <Tooltip title={textValue} placement="right">
        <StyledChip
          sx={{ width: '270px', mb: 1 }}
          label={textValue}
          color={color}
          onClick={() => {
            setCurrentContract(
              contract
            );
          }}
          variant="outlined"

          icon={<RemoveRedEyeOutlinedIcon
            style={currentContract?.cid === contract?.cid ? { fontSize: '25px' } : {
              fontSize: '25px',
              opacity: '0'
            }} />}
        />
      </Tooltip>

    );
  }

  const handleLinkNow = () => {
    setIsConfirmationView(true);
    setConfirmationAlert('The system will process the payment automatically, no future action is required.')
    if(!_.isEmpty(currentPaymentLog?.paymentEmail)){
      setIsConfirmationContent(
        `Are you sure you want to link contract : ${currentContract.cid} to payment Email : ${currentPaymentLog.paymentEmail.email} ?`)
    } else{
      setIsConfirmationContent(
        `Creating a new payment email for ${currentPaymentLog.paymentLog.replyTo} and link with contract ${currentContract.cid}`)
    }
  };

  const handlePayNow = () => {
    if(amountPaying <= 0){
      sendErrorMessage(dispatch,'The payment amount has to be greater than 0.')
    }else{
      setIsConfirmationView(true);
      setIsConfirmationContent(`Are you sure you want to pay CA$ ${amountPaying} from this email transfer to ${currentContract.cid}?`)
      setConfirmationAlert('This payment will be processed immediately.')
    }


  };


  const handleConfirmSubmit=async ()=> {

    if (value === 0) {
        await assignPaymentEmailToOneContract(
          currentPaymentLog?.paymentLog?.replyTo,
          paymentlogName.firstName,
          paymentlogName.lastName,
          currentContract.cid
        )(dispatch).then(() => {
          setIsConfirmationView(false);
          loadData()
        });
    } else {
        await createPaymentHistoryFromLog(currentContract.cid, currentPaymentLog.paymentLog.id, amountPaying)(dispatch).then(() => {
          setIsConfirmationView(false);
          loadData()
        })
    }
  }
  const fabs = [
    {
      color: 'primary',
      sx: { position: 'absolute', bottom: '50px', right: '50px' },
      icon: <AddLinkOutlinedIcon sx={{ mr: 1 }} />,
      label: 'Add',
      text: 'Link Now',
      function: handleLinkNow
    },
    {
      color: 'secondary',
      sx: { position: 'absolute', bottom: '50px', right: '50px' },
      icon: <CreditScoreOutlinedIcon sx={{ mr: 1 }} />,
      label: 'Edit',
      text: 'Pay Now',
      function: handlePayNow
    }];


  const searchContractNoPageHandler = debounce(async (searchVal) => {
    const searchStr = searchVal.trim().toLowerCase();
    if (searchStr !== '') {
      setIsSearchLoading(true);
      const res = await searchContractsNoPage(searchStr)(dispatch);
      setIsSearchLoading(false);
      setSearchContractResult(res);
    } else {
      setSearchContractResult([]);
    }

  }, 500);

  // for confirmation dialog
  const [isConfirmationView, setIsConfirmationView] = useState(false);
  const [confirmationContent, setIsConfirmationContent] = useState('');
  const [confirmationAlert, setConfirmationAlert] = useState('')

  return <DraggableDialog open={Boolean(isPaymentLogActView)}
                          onClose={() => {
                            setIsPaymentLogActView(false)
                            loadParentData()

                          }}
                          title='Payment Log Process' isLoaded={Boolean(isPaymentLogActView)}>
    {isLoaded ?
      <div>
        <DraggableDialog open={Boolean(isConfirmationView)} onClose={() => setIsConfirmationView(false)}
                         title='Confirmation'>
          <div>
            <Alert severity="success" sx={{mb:1}}>{confirmationAlert}</Alert>
            <Alert icon={false} severity="info" variant='outlined' sx={{border:'none'}}>
              {confirmationContent}
            </Alert>
            {/* <Typography variant='body2'> */}
            {/*  {confirmationContent} */}
            {/* </Typography> */}
          </div>

          <Grid container alignItems='center' justifyContent='flex-end' sx={{mt:2}}>
            <Grid item>
              <Button onClick={handleConfirmSubmit}
                      variant='contained'
                      size='small'
              >Confirm</Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                setIsConfirmationView(false);}}
                size='small'
              >cancel</Button>
            </Grid>

          </Grid>

        </DraggableDialog>


        <div style={{ display: 'flex', minWidth: '900px' }}>
          <div style={{
            display: 'flex',
            width: '35%',
            height: 'fit-content',
            flexDirection: 'column',
            position: 'sticky',
            top: 0
          }}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                  <Tab label='Link Contract' {...a11yProps(0)} />
                  <Tab label='Create Payment' {...a11yProps(1)} />
                </Tabs>
              </Box>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0}>
                  <Paper sx={{ p: 1 }}>
                    <Typography sx={{ mb: 1 }} variant='h5'>
                      {!_.isEmpty(currentPaymentLog.paymentEmail)  ? 'Payment Email Existed' : 'Creating Payment Email'}
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField label='email'
                                   name='email'
                                   size='small'
                                   sx={{ width: '100%' }}
                                   defaultValue={currentPaymentLog?.paymentLog?.replyTo}
                                   disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label='First Name'
                                   size='small'
                                   name='fname'
                                   disabled={!_.isEmpty(currentPaymentLog.paymentEmail)}
                                   onChange={(e) => {
                                     setPaymentlogName(prevState => ({ ...prevState, firstName: e.target.value }));
                                   }}
                                   defaultValue={!_.isEmpty(currentPaymentLog.paymentEmail) ? currentPaymentLog.paymentEmail.fname : getPayerName(currentPaymentLog.paymentLog.payerName).firstName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label='Last Name'
                                   size='small'
                                   name='lname'
                                   disabled={!_.isEmpty(currentPaymentLog.paymentEmail)}
                                   onChange={(e) => {
                                     setPaymentlogName(prevState => ({ ...prevState, lastName: e.target.value }));
                                   }}
                                   defaultValue={!_.isEmpty(currentPaymentLog.paymentEmail) ? currentPaymentLog.paymentEmail.lname : getPayerName(currentPaymentLog.paymentLog.payerName).lastName}
                        />
                      </Grid>
                    </Grid>


                    <div style={{ marginTop: '30px' }}>

                      <Typography
                        sx={{ mb: 1 }}
                        variant='h5'
                      >Please select one contract to link</Typography>
                      <ChipLegend />
                    </div>
                    {_.isEmpty(currentPaymentLog?.paymentEmail)||_.isEmpty(currentPaymentLog?.paymentEmail.contracts)|| currentPaymentLog?.paymentEmail?.contracts?.filter(c=>c.remaining !== 0).length > 0 ?
                    <div />:
                      <Alert severity="info" sx={{mb:1}}>
                        The current linked contract(s) is fully paid, please select another contract.
                      </Alert>
                    }

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {currentPaymentLog?.paymentEmail?.contracts?.map((contract) => (
                        <div key={contract.toString()}
                             style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <ContractChip contract={contract} color={
                            contract.remaining === 0 ? 'success' : 'primary'
                          } />
                        </div>
                      ))}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '20px' }}>
                      {filtedRecommend?.length > 0 ? filtedRecommend?.map((contract) => <div key={contract.toString()}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <ContractChip contract={contract} color="info" />
                              </div>
                            </div>
                        )
                        :
                        ''
                      }
                    </div>


                    <Typography
                      variant='h5'
                      sx={{ mb: 1 }}>
                      Search Contracts
                    </Typography>
                    <div style={{ marginBottom: '10px' }}>
                      <SearchBox
                        isLoading={isSearchLoading}
                        placeholder='Search...'
                        size='small'
                        type='search'
                        searchHandler={searchContractNoPageHandler} />
                    </div>

                    <div style={{ minHeight: '200px' }}>
                      {searchContractResult.length > 0 ? searchContractResult.map((contract) => (
                            <div key={contract.toString()}>
                              <ContractChip contract={contract} color="default" />
                            </div>
                          )) :
                        <Typography variant='overline'>
                          No Search Result
                        </Typography>
                      }
                    </div>

                  </Paper>
                </TabPanel>
                <TabPanel value={value} index={1}>

                  <Paper sx={{ p: 1 }}>
                    <Typography
                      variant='h5'
                      sx={{ mb: 1 }}>
                      From payment Log
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth label='Email'
                          size='small'
                          defaultValue={currentPaymentLog?.paymentLog?.replyTo} disabled />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label='Name'
                          size='small'
                          defaultValue={currentPaymentLog?.paymentLog?.payerName} disabled />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label='Balance'
                          size='small'
                          defaultValue={ centsToDollarString(currentPaymentLog?.paymentLog?.unusedAmount,currentPaymentLog?.paymentLog?.transferCurrency) } disabled />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label='*Amount Paying'
                          size='small'
                          defaultValue={`${ +(currentPaymentLog?.paymentLog?.unusedAmount || 0)/100}`}
                          color='primary' type='number'
                          onChange={(e) => {
                            setAmountPaying(e.target.value);
                          }}

                        />
                      </Grid>
                    </Grid>
                    <div style={{ marginTop: '30px' }}>
                      <Typography
                        sx={{ mb: 1 }}
                        variant='h5'
                      >Please select one contract to pay</Typography>
                      <ChipLegend />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {currentPaymentLog?.paymentEmail?.contracts?.map((contract) => (
                          <div key={contract.toString()}
                               style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ContractChip contract={contract} color={
                              contract.remaining === 0 ? 'success' : 'primary'
                            } />
                          </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '20px' }}>
                      {filtedRecommend?.length > 0 ? filtedRecommend?.map((contract) => <div key={contract.toString()}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <ContractChip contract={contract} color="info" />
                              </div>
                            </div>
                        )
                        :
                        ''
                      }
                    </div>


                    <Typography
                      variant='h5'
                      sx={{ mb: 1 }}>
                      Search Contracts
                    </Typography>
                    <div style={{ marginBottom: '10px' }}>
                      <SearchBox
                        isLoading={isSearchLoading}
                        placeholder='Search...'
                        size='small'
                        type='search'
                        searchHandler={searchContractNoPageHandler} />
                    </div>

                    <div style={{ minHeight: '200px' }}>
                      {searchContractResult.length > 0 ? searchContractResult.map((contract) => (
                            <div key={contract.toString()}>
                              <ContractChip contract={contract} color="default" />
                            </div>
                          )) :
                        <Typography variant='overline'>
                          No Search Result
                        </Typography>
                      }
                    </div>

                  </Paper>
                </TabPanel>
              </SwipeableViews>

            </Box>
          </div>
          <div style={{
            display: 'flex', width: '63%', flexDirection: 'column', height: 'fit-content',
            marginLeft: '2%', top: '50px', position: 'relative'
          }}>
            <div style={{ display: 'flex', maxWidth: '100%', flexDirection: 'column' }}>
              <Paper className={classes.paper} style={{
                alignContent: 'center', justifyContent: 'center', width: '100%', height: '600px',
                display: `${currentContract ? 'none' : 'flex'}`
              }}>
                <Typography sx={{ alignSelf: 'center' }}>
                  No Contract is selected.
                </Typography>
              </Paper>
              <div style={currentContract ? { height: 'auto' } : { height: '0px', overflowY: 'hidden' }}>
                <Paper elevation={8} sx={{
                  position: 'sticky',
                  top: '0',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  background: 'blur(1px)',
                  opacity: '0.8'
                }}>
                  <Typography
                    variant='h5'
                    sx={{ alignSelf: 'center' }}
                  >
                    {`Current Selected Contract : ${currentContract?.cid ?? ''}`}
                  </Typography>
                </Paper>
                {!!currentContract && <ContractDisplayLoader contract={currentContract} />}
              </div>

            </div>
          </div>
        </div>
        {/* <div style={{border:'1px solid red',width:'100%',height:'50px',position:'absolute',bottom:'0',left:'0',background:'red'}}> */}

        {/* </div> */}
        {fabs.map((fab, index) => (
          <Zoom
            key={fab.color}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`
            }}
            unmountOnExit
          >
            <Fab
              onClick={fab.function}
              disabled={!currentContract} sx={fab.sx} variant='extended' color={fab.color}>
              {fab.icon}
              {fab.text}
            </Fab>
          </Zoom>
        ))}

        {/* {value === 0? */}
        {/*  <Fab disabled={!currentContract} color="primary" variant="extended" sx={{position:'absolute',bottom:'50px',right:'50px'}}> */}
        {/*    <AddLinkOutlinedIcon sx={{ mr: 1 }} /> */}
        {/*    Link Now */}
        {/*  </Fab> */}
        {/*:*/}
        {/*  <Fab  disabled={!currentContract} color="secondary" variant="extended" sx={{position:'absolute',bottom:'50px',right:'50px'}}> */}
        {/*    <CreditScoreOutlinedIcon sx={{ mr: 1 }} /> */}
        {/*    Pay Now */}
        {/*  </Fab> */}
        {/* } */}

      </div>
      :
      <div style={{ display: 'flex', minWidth: '900px', justifyContent: 'center', alignContent: 'center' }}>

        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{ alignSelf: 'center' }}
          >
            Loading...
          </Typography>
          <LinearProgress />
        </Box>
      </div>

    }

  </DraggableDialog>;


}


export default ContractPaymentLogActs


