import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useParams } from 'react-router';
import QRCode from 'qrcode';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import ViewHead from '../../../../components/public/ViewHead';
import PosterMakerTemplateEditor from './PosterMakerTemplateEditor';
import { sendSuccessMessage } from '../../../../actions';
import history from '../../../../utils/history';
import {
  clearPosterTemplateFiles,
  fetchPosterBaseFile,
  fetchPosterLogoFile,
  fetchPosterTemplate
} from '../../../../actions/forms/posterMakerAction';
import { QR_CODE_CONFIG } from '../../../../constants';
import LoadingScreen from '../../../../components/public/LoadingScreen';
import NotFound from '../../../../components/public/NotFound';

function EditPosterTemplateView() {
  useCurrentViewCheck(Functions.Forms_Poster_Generator_Edit)
  const  navigate = useNavigate();
  const {id} = useParams()
  const dispatch = useDispatch()

  const currentPosterTemplate = useSelector(state => state.posterMaker.allPosterTemplates)
  const currentPosterBaseFile = useSelector(state => state.posterMaker.currentPosterBaseFile)
  const currentLogoFile = useSelector(state => state.posterMaker.currentLogoFile)

  const [isLoaded, setIsLoaded] = useState(false)
  const [oldData, setOldData] = useState({})
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('Error')

  const [isPosterTemplateLoaded, setIsPosterTemplateLoaded] = useState(null)
  const [isPosterBaseLoaded, setIsPosterBaseLoaded] = useState(null)
  const [isPosterLogoLoaded, setIsPosterLogoLoaded] = useState(null)

  const loadData = () => {
    fetchPosterTemplate(id, setIsPosterTemplateLoaded)(dispatch)
    fetchPosterBaseFile(id, setIsPosterBaseLoaded)(dispatch)
  }
  useEffect(loadData, [dispatch, id])

  useEffect(() => {
    // Base Template and poster file loaded
    if (isPosterTemplateLoaded !== null && isPosterBaseLoaded !== null) {
      if (isPosterTemplateLoaded && isPosterBaseLoaded
        && currentPosterTemplate?.id?.toString() === id && currentPosterBaseFile) {
        if (currentPosterTemplate.logoCoords) {
          fetchPosterLogoFile(id, setIsPosterLogoLoaded)(dispatch)
        } else {
          setIsPosterLogoLoaded(true)
        }
      } else {
        setErrorMessage('Poster template cannot be loaded')
        setHasError(true)
        setIsLoaded(true)
      }
    }
  }, [id, dispatch, currentPosterTemplate, currentPosterBaseFile,
    isPosterTemplateLoaded, isPosterBaseLoaded])

  useEffect(() => {
    const runOnLoad = async () => {
      if (!isLoaded && isPosterTemplateLoaded && isPosterBaseLoaded && isPosterLogoLoaded) {
        const imageContainer = {
          base: currentPosterBaseFile,
          logo: currentPosterTemplate.logoCoords ? currentLogoFile : '',
          qr: !!currentPosterTemplate.qrCoords && !!currentPosterTemplate.qrText ? await QRCode.toDataURL(currentPosterTemplate.qrText, QR_CODE_CONFIG) : ''
        }

        // compile old data
        const oldDataInner = { ...currentPosterTemplate, imageContainer, form: currentPosterTemplate?.form?.id }
        if (currentPosterTemplate.logoCoords) {
          oldDataInner.logoCoords = JSON.parse(currentPosterTemplate.logoCoords)
        }
        if (currentPosterTemplate.qrCoords) {
          oldDataInner.qrCoords = JSON.parse(currentPosterTemplate.qrCoords)
        }
        setOldData(oldDataInner)
        setIsLoaded(true)
      }
    }
    runOnLoad().then()
  }, [isLoaded, isPosterTemplateLoaded, isPosterBaseLoaded, isPosterLogoLoaded])

  const onSuccess = (data) => {
    sendSuccessMessage(dispatch, 'Poster template updated')
    setTimeout(() => {
      clearPosterTemplateFiles()(dispatch)
      navigate(`/app/forms/poster/view/${data.id}`)
    }, 1000)
  }

  const renderBackButton = () =>
    <Button color='secondary'
            variant='contained'
            onClick={() => history.goBack()}>
      Back
    </Button>

  return (
    <ViewHead functionId={Functions.Forms_Poster_Generator_Edit} noMaxWidth
              topButtons={renderBackButton()}>
      {
        isLoaded
          ? hasError
            ? <NotFound message={errorMessage} />
            : <PosterMakerTemplateEditor onSuccess={onSuccess} isEdit originalData={oldData}/>
          : <LoadingScreen message='Loading...' />
      }
    </ViewHead>
  )
}

export default EditPosterTemplateView
