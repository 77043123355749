import * as yup from 'yup';

export const CONTRACT_TEMPLATE_CREATE_VALIDATOR = {
  name: yup.string().required("Required")
    .min(1, "Contract name must be at least 1 character")
    .max(250, "Contract name must be less than 250 characters")
    .trim(),
  description: yup.string().required("Required")
    .min(1, "Contract description must be at least 1 character")
    .max(250, "Contract description must be less than 250 characters")
    .trim(),
}

export default CONTRACT_TEMPLATE_CREATE_VALIDATOR
