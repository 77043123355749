import {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers-pro'
import DatePickerButton from './DatePickerButton'

// ⚠️ IMPORTANT: Please make sure all date format is in ISO format: MM/DD/YYYY
// Please use following function to format your date

const today = new Date()

export const DATE_PICKER_FORMAT = 'MM/DD/YYYY'

// convert date format to MM/DD/YYYY
export const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1)?.padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${month}/${day}/${year}`
}

export const defaultRanges = [
    {
        label: 'Most Recent date',
        startDate: formatDate(today),
        endDate: formatDate(today)
    },
    {
        label: 'Last 7 Days',
        startDate: formatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)),
        endDate: formatDate(today)
    },
    {
        label: 'Last 28 Days',
        startDate: formatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 28)),
        endDate: formatDate(today)
    },
    {
        label: 'Last 3 Months',
        startDate: formatDate(new Date(today.getFullYear(), today.getMonth() - 2, today.getDate())),
        endDate: formatDate(today)
    },
    {
        label: 'Last 6 Months',
        startDate: formatDate(new Date(today.getFullYear(), today.getMonth() - 5, today.getDate())),
        endDate: formatDate(today)
    },
    {
        label: 'Last 12 Months',
        startDate: formatDate(new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())),
        endDate: formatDate(today)
    },
    {
        label: 'Last 16 Months',
        startDate: formatDate(new Date(today.getFullYear() - 1, today.getMonth() - 3, today.getDate())),
        endDate: formatDate(today)
    }
]

function DatePickerComponent({labelName, setDateRangeFromDatePicker, props, defaultRange}) {
    // retrieve the default range when the page is loaded, use the value
    // from the local storage if it exists, otherwise, use defaultRange
    const localStorageDefaultRange =
        localStorage.getItem(`${labelName}-TimeSpan`) || defaultRange || defaultRanges[1].label
    const [startDefaultRange, setStartDefaultRange] = useState(localStorageDefaultRange)

    // if there's data passed in, use passed-in data, otherwise use defaultRanges
    const dateRanges = props?.length > 0 ? props : defaultRanges

    // custom
    const [customValue, setCustomValue] = useState([null, null])
    const updateCustomValue = val => {
        setCustomValue(val)
    }

    if (!dateRanges.find(ele => ele.label === 'Custom')) {
        dateRanges.push({
            label: 'Custom',
            startDate: customValue[0],
            endDate: customValue[1]
        })
    }

    const [selectedValue, setSelectedValue] = useState(null)

    const [dateRangesSelected, setDateRangesSelected] = useState(localStorageDefaultRange)

    const handleValueChange = value => {
        const formattedValue = value.map(date => dayjs(date, DATE_PICKER_FORMAT).format(DATE_PICKER_FORMAT))
        setSelectedValue(formattedValue)

        const labelObj = dateRanges.filter(
            ele => ele?.startDate === formattedValue[0] && ele?.endDate === formattedValue[1]
        )[0]
        if (labelObj) {
            setDateRangesSelected(labelObj.label)
        } else {
            const dateShown = `${formattedValue[0]} - ${formattedValue[1]}`
            setDateRangesSelected(dateShown)
        }
    }

    // Submit information update
    const [handleSelect, setHandleSelect] = useState(null)

    const updateSelectedValue = val => setDateRangeFromDatePicker(val)
    useEffect(() => {
        updateSelectedValue(selectedValue)
    }, [handleSelect])

    useEffect(() => {
        // store to local storage on change
        let newStartDefaultRange = null
        if (selectedValue) {
            newStartDefaultRange = dateRanges.filter(
                ele => ele.startDate === selectedValue[0] && ele.endDate === selectedValue[1]
            )[0]?.label
            if (
                newStartDefaultRange === 'Custom' ||
                newStartDefaultRange === undefined ||
                newStartDefaultRange === null ||
                (Array.isArray(newStartDefaultRange) &&
                    newStartDefaultRange[0] === null &&
                    newStartDefaultRange[1] === null)
            ) {
                localStorage.removeItem(`${labelName}-TimeSpan`)
            } else {
                localStorage.setItem(`${labelName}-TimeSpan`, newStartDefaultRange)
                setStartDefaultRange(newStartDefaultRange)
            }
        }
    }, [labelName, defaultRange, startDefaultRange, selectedValue])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerButton
                defaultRange={startDefaultRange}
                dateRanges={dateRanges}
                onValueChange={handleValueChange}
                updateCustomValue={updateCustomValue}
                dateRangesSelected={dateRangesSelected}
                setSelectedValue={setSelectedValue}
                setHandleSelect={setHandleSelect}
            />
        </LocalizationProvider>
    )
}

export default DatePickerComponent
