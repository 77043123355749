import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';
import {
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Link,
  NativeSelect,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  DEFAULT_FORM_COMPLETE_MESSAGES, DEFAULT_SELECT_VALUE, mbToBytes,
  VALID_INPUT_TYPES,
  WRONG_INPUT_SYNTAX_MESSAGE
} from '../../views/forms/formBuilder/formBuilderData';
import { submitFormResult } from '../../actions/forms/formActions';
import SubmitButton from '../mui/button/SubmitButton';
import { uploadApi } from '../../apis/uploadApi';
import { sendErrorMessage } from '../../actions';
import CenterDisplay from '../misc/CenterDisplay';
import LoadingProgress from '../public/LoadingProgress';
import AddressAutoComplete from '../api/AddressAutoComplete';

const useStyles = makeStyles(() => ({
  text:{
    marginBottom: '2rem'
  },
  paper: {
    padding: '2rem',
    margin:'2rem 0',

  },
  formControl: {
    marginBottom: '2rem'
  },
  marginLeft: {
    marginLeft: '2rem'
  },
  endCard: {
    padding: '5rem 0',
    textAlign: 'center',
    '& h1': {
      marginBottom: '2rem'
    },
    '& a': {
      display: 'block',
      marginTop: '2rem'
    }
  },
  formDivider: {
    margin: '2rem'
  },
  selectionInput: {
    minWidth: '150px'
  },
  fileInput: {
    '& input': {
      marginTop: '10px'
    }
  }
}));

function FormDisplay({ form, uid, onSuccess, onError, isLoaded }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [isSaving, setIsSaving] = useState(false)
  const [allFormInputs, setAllFormInputs] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  const [schema, setSchema] = useState({})


  const { register, handleSubmit, setValue,errors, setError } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
  });

  const handleAddressCallback = (value) => {
    form.components.forEach((c,i)=>{
      if(c.tag === 'city'){
        setValue(form.components[i].name, value?.city);
      }
      if( c.tag === 'country'){
        setValue(form.components[i].name, value?.country);
      }
    })


  };

  const genFormAndSchema = () => {
    const tempFormInputs = []  // Reset Form

    setAllFormInputs([])
    setSchema({})

    if (form && form.components && !_.isEmpty(form.components)) {
      const tempSchema = {}   // Build a new schema

      for (const compIdx in form.components) {
        if (form.components.hasOwnProperty(compIdx)) {

          const component = form.components[compIdx]
          let defaultSelection = {}   // Not fully implemented

          switch (component.inputType) {

            case VALID_INPUT_TYPES.text:
              tempSchema[component.name] = yup.string().trim()
              if (component.isRequired) tempSchema[component.name] = tempSchema[component.name].required('Required')
              else tempSchema[component.name] = tempSchema[component.name].transform(value => value === '' ? undefined : value)

              if (component.pattern && component.pattern !== '') {
                try {
                  tempSchema[component.name] = tempSchema[component.name]
                    .matches(new RegExp(component.pattern), component.patternMessage || WRONG_INPUT_SYNTAX_MESSAGE)
                } catch (e) {
                  // console.log('Not a valid regex\n', e)
                }
              }
              tempSchema[component.name] = tempSchema[component.name]
                .min(component.minLength, `This must be longer than ${component.minLength} characters`)
                .max(component.maxLength, `This must be shorter than ${component.maxLength} characters`)


              switch (component.tag) {
                case 'address':
                case 'city':
                  tempFormInputs.push(
                    <AddressAutoComplete key={compIdx}
                                         label={`${component.label}`}
                                         className={classes.text}
                                         name={component.name}
                                         helperText={errors[component.name]?.message ? errors[component.name]?.message : component.description}
                                         error={Boolean(errors[component.name]?.message)}
                                         TextComponent={TextField}
                                         id={component.name}
                                         handleAddressCallBack={handleAddressCallback}
                                         required = {component.isRequired}
                                         InputLabelProps={{
                                           shrink: true
                                         }}
                                         register={register}/>)
                    break
                case 'university':
                  tempFormInputs.push(
                    <AddressAutoComplete key={compIdx}
                                         label={`${component.label}`}
                                         className={classes.text}
                                         name={component.name}
                                         helperText={errors[component.name]?.message ? errors[component.name]?.message : component.description}
                                         error={Boolean(errors[component.name]?.message)}
                                         TextComponent={TextField}
                                         id={component.name}
                                         handleAddressCallBack= {()=>null}
                                         required = {component.isRequired}
                                         InputLabelProps={{
                                           shrink: true
                                         }}
                                         register={register}/>)
                  break
                default:
                  tempFormInputs.push(
                    <FormControl key={compIdx} fullWidth className={classes.formControl}>
                      <TextField variant='outlined' fullWidth size='small'
                                 label={`${component.label}`} required={component.isRequired}
                                 name={component.name} inputRef={register}
                                 error={Boolean(errors[component.name]?.message)}
                                 helperText={errors[component.name]?.message ? errors[component.name]?.message : component.description}
                                 InputLabelProps={{
                                   shrink: true
                                 }}
                      />
                    </FormControl>
                  )
              }


              break;

            case VALID_INPUT_TYPES.textarea:
              tempSchema[component.name] = yup.string().trim()
              if (component.isRequired) tempSchema[component.name] = tempSchema[component.name].required('Required')
              else tempSchema[component.name] = tempSchema[component.name].transform(value => value === '' ? undefined : value)

              tempSchema[component.name] = tempSchema[component.name]
                .min(component.minLength, `This must be longer than ${component.minLength} characters`)
                .max(component.maxLength, `This must be shorter than ${component.maxLength} characters`)

              tempFormInputs.push(
                <FormControl key={compIdx} fullWidth className={classes.formControl}>
                  <TextField variant='outlined' fullWidth size='small'
                             multiline rows={6}
                             label={`${component.label}`} required={component.isRequired}
                             name={component.name} inputRef={register}
                             error={Boolean(errors[component.name]?.message)}
                             helperText={errors[component.name]?.message ? errors[component.name]?.message : component.description}
                  />
                </FormControl>
              );
              break;

            case VALID_INPUT_TYPES.select:
              defaultSelection = component.values.find(c => c.isDefaultValue === true);
              tempFormInputs.push(
                <FormControl key={compIdx} fullWidth className={classes.formControl} required={component.isRequired}
                             error={Boolean(errors[component.name]?.message)}>
                  <FormLabel component='legend'>{component.label}</FormLabel>
                  <div className={classes.marginLeft}>
                    <NativeSelect
                      className={classes.selectionInput}
                      defaultValue={defaultSelection && defaultSelection.value}
                      inputRef={register({ required: component.isRequired })}
                      inputProps={{ name: component.name }}
                    >
                      <option value={DEFAULT_SELECT_VALUE}/>
                      {component.values.map((c) => <option key={c.name} value={c.name}>{c.value}</option>)}
                    </NativeSelect>
                    <FormHelperText>{errors[component.name]?.message ? errors[component.name]?.message : component.description}</FormHelperText>
                  </div>
                </FormControl>
              )
              break;

            case VALID_INPUT_TYPES.check:
              tempFormInputs.push(
                <FormControl key={compIdx} fullWidth className={classes.formControl} required={component.isRequired}
                             error={Boolean(errors[component.name]?.message)}>
                  <FormLabel component='legend'>{component.label}</FormLabel>
                  <div className={classes.marginLeft}>
                    <FormGroup>
                      {component.values.map((c) => (
                        <FormControlLabel
                          key={c.name}
                          inputRef={register}
                          control={<Checkbox name={c.name} />}
                          label={c.value}
                        />
                      ))}
                    </FormGroup>
                    <FormHelperText>{errors[component.name]?.message ? errors[component.name]?.message : component.description}</FormHelperText>
                  </div>
                </FormControl>
              )
              break;

            case VALID_INPUT_TYPES.radio:
              tempFormInputs.push(
                <FormControl key={compIdx} fullWidth className={classes.formControl} required={component.isRequired}
                             error={Boolean(errors[component.name]?.message)}>
                  <FormLabel component='legend'>{component.label}</FormLabel>
                  <div className={classes.marginLeft}>
                    <RadioGroup>
                      {component.values.map((c) => (
                        <FormControlLabel
                          key={c.name}
                          inputRef={register({ required: component.isRequired })}
                          value={c.name}
                          control={<Radio name={c.name} />}
                          label={c.value}
                        />
                      ))}
                    </RadioGroup>
                    <FormHelperText>{errors[component.name]?.message ? errors[component.name]?.message : component.description}</FormHelperText>
                  </div>
                </FormControl>
              )
              break

            case VALID_INPUT_TYPES.file:
              tempFormInputs.push(
                <FormControl key={compIdx} fullWidth className={`${classes.formControl} ${classes.fileInput}`} required={component.isRequired}
                             error={Boolean(errors[component.name]?.message)}>
                  <FormLabel component='legend'>{component.label}</FormLabel>
                  <div className={classes.marginLeft}>
                    {component.fileType
                      ? <input type="file" name={component.name} accept={component.fileType} ref={register} />
                      : <input type="file" name={component.name} ref={register}/>
                    }
                    <FormHelperText>{errors[component.name]?.message ? errors[component.name]?.message : component.description}</FormHelperText>
                  </div>
                </FormControl>
              )
              break;

            default:
              // console.log('Component is not valid:\n', component)
          }
        }
        setAllFormInputs(tempFormInputs)
      }
      setSchema(yup.object().shape(tempSchema))
    }
  }
  useEffect(genFormAndSchema, [form, errors, register])

  const formSubmit = async (formData) => {
    const res = []
    const filesToUpload = []
    let hasErr = false
    const formDataList = Object.keys(formData)
    // Sort data and check for errors with inputs containing children
    if (form && form.components && !_.isEmpty(form.components)) {
      for (const compIdx in form.components) {
        if (form.components.hasOwnProperty(compIdx)) {
          const component = form.components[compIdx]

          switch (component.inputType) {

            case VALID_INPUT_TYPES.text:
            case VALID_INPUT_TYPES.textarea:
              res.push({
                componentId: component.id,
                name: component.name,
                value: formData[component.name],
                oQuestion: component.label,
                oAnswer: formData[component.name] || 'Null',
              })
              break;

            case VALID_INPUT_TYPES.select:
            {
              let selectedVal = formData[component.name]
              if (selectedVal === DEFAULT_SELECT_VALUE) {
                if (component.isRequired) {
                  hasErr = true
                  setError(component.name, { type: 'manual', message: 'Required' })
                } else {
                  selectedVal = null
                }
              }

              res.push({
                componentId: component.id,
                name: component.name,
                // value: component.values.find(val => val.name === formData[component.name]).value
                value: selectedVal,
                oQuestion: component.label,
                oAnswer: component.values.find(val => val.name === formData[component.name])?.value || 'Null',
              })
              break;}

            case VALID_INPUT_TYPES.check:
            case VALID_INPUT_TYPES.radio:{
              const optionChildren = component.values.map(val => val.name)
              // Need to see if at least 1 of its child is inside formData
              const found = optionChildren.some(child => formDataList.includes(child) && formData[child])
              if (!found) {
                if (component.isRequired) {
                  // Only show error if required
                  hasErr = true
                  setError(component.name, { type: 'manual', message: 'Required' })
                }
              } else {
                // Add all the selected options to result
                for (const child of optionChildren) {
                  if (formData[child]) {
                    if (VALID_INPUT_TYPES.radio) {
                      // set radio's value to true
                      res.push({
                        componentId: component.id,
                        name: child,
                        value: true,
                        oQuestion: component.label,
                        oAnswer: component.values.find(val => val.name === child)?.value || 'Null',
                      })
                    } else {
                      // Checkboxes are true by default
                      res.push({
                        componentId: component.id,
                        name: child,
                        value: formData[child],
                        oQuestion: component.label,
                        oAnswer: component.values.find(val => val.name === child)?.value || 'Null',
                      })
                    }
                  }
                }
              }
              break;}

            case VALID_INPUT_TYPES.file:
              // Check Error
              if (component.isRequired) {
                if (!formData[component.name] || (formData[component.name] && (formData[component.name].length === 0))) {
                  // Required but not filled
                  hasErr = true
                  setError(component.name, { type: 'manual', message: 'Required' })
                } else {
                  // Check size constraints
                  if (formData[component.name][0].size < mbToBytes(component.minLength)) {
                    hasErr = true
                    setError(component.name, {
                      type: 'manual',
                      message: `File must be larger than ${component.minLength}mb`
                    })
                  }
                  if (formData[component.name][0].size > mbToBytes(component.maxLength)) {
                    hasErr = true
                    setError(component.name, {
                      type: 'manual',
                      message: `File must be less than ${component.maxLength}mb`
                    })
                  }

                  try {
                    // Check file type constraints
                    const submittedType = formData[component.name][0].type
                    const requiredType = component.fileType.split(',')

                    if (!requiredType.some(type => submittedType.match(type.trim()))) {
                      hasErr = true
                      setError(component.name, {
                        type: 'manual',
                        message: component.patternMessage || WRONG_INPUT_SYNTAX_MESSAGE
                      })
                    }
                  } catch (e) {
                    // console.log('File upload error\n', e)
                    sendErrorMessage(dispatch, 'The Form is set up incorrectly, please contact the site admin!')
                  }
                }
              }
              // Extract Files
              if (!hasErr) {
                if (formData[component.name] && (formData[component.name].length > 0)) {
                  filesToUpload.push({ id: component.id, name: component.name, data: formData[component.name][0] })
                }
              }
              break;

            default:
              // console.log('Something went from in FormViewer')
          }
        }
      }
    }

    if (!hasErr) {
      // Only submit form or upload files when there are no errors
      setIsSaving(true)

      if (filesToUpload.length > 0) {
        // Form With Files
        // Upload all the files and get their fileUid
        for (const tempFileToUpload of filesToUpload) {
          const file = new FormData()
          file.append('formFile', tempFileToUpload.data)
          file.append('formId', form.id)
          file.append('componentId', tempFileToUpload.id)

          // Upload
          const uploadRes = await uploadFile(file, tempFileToUpload.id, tempFileToUpload.name)
          if (uploadRes) res.push(uploadRes)
          else hasErr = true
        }
      }
      // Submit Form
      if (!hasErr) {
        await submitFormResult({ fid: form.id, uid, forms: res, source: window.location.href },
          (data) => onSubmitSuccess(data),
          (err) => onSubmitError(err)
        )(dispatch)
      }
    }
  }

  const uploadFile = (file, id, name) => new Promise((resolve) => {
      uploadApi.post(`/formviewer/file`, file)
        .then(response => {
          const { rs, message, data } = response.data
          if (rs) {
            resolve({
              componentId: id,
              name,
              value: data
            })
          } else {
            // console.log('File upload error!\n', message)
            onSubmitError(message)
            resolve(false)
          }
        })
        .catch(e => {
          // console.log('File upload error!\n', e)
          onSubmitError(`File upload failed!${e}`)
          resolve(false)
        })
    })

  const onSubmitSuccess = (data) => {
    setIsSubmit(true);
    onSuccess(data.message)
  }

  const onSubmitError = (errMsg) => {
    // console.log('fail');
    onError(errMsg)
    setIsSaving(false)
  }

  return (
    <div>
      {
        isLoaded ?
          <div>
            {!isSubmit ?
              _.isEmpty(form) ?
                <CenterDisplay>
                  <Typography variant='h1' color='textPrimary'>
                    Form not found
                  </Typography>
                </CenterDisplay>
                :
                <Container>
                    <Paper className={classes.paper}>
                      <Typography variant='h1'>{form.name ?? ''}</Typography>
                      <Typography variant='subtitle1'>{form.description ?? ''}</Typography>

                      <Divider className={classes.formDivider}/>

                      <form onSubmit={handleSubmit(formSubmit)}>
                        {allFormInputs.map((component) => component)}

                        <SubmitButton isSaving={isSaving} text='Submit' isSavingText='Submitting'
                                      fullWidth variant='contained' color='primary'
                        />
                      </form>
                    </Paper>
                  </Container>
              :
              <Container>
                <Paper className={`${classes.paper} ${classes.endCard}`}>
                  <Typography variant='h1'>{form.formCompleteTitle ?? DEFAULT_FORM_COMPLETE_MESSAGES.formCompleteTitle}</Typography>
                  { ((form && form.formCompleteDescription) || DEFAULT_FORM_COMPLETE_MESSAGES.formCompleteDescription)
                    .split('\\n').map((e) => <Typography key={e} variant='body1'>{e}</Typography>) }

                  <Typography>
                    <Link href={form.formCompleteRedirectLink ?? DEFAULT_FORM_COMPLETE_MESSAGES.formCompleteRedirectLink}>
                      {form.formCompleteRedirectNotice ?? DEFAULT_FORM_COMPLETE_MESSAGES.formCompleteRedirectNotice}
                    </Link>
                  </Typography>
                </Paper>
              </Container>
            }
          </div>
          :
          <LoadingProgress />
      }
    </div>
  )
}

export default FormDisplay;
