import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Button, Grid, IconButton, Modal, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { BBS_SITE_LIST, useMatricIndexView } from '../../matricHelper';
import {
  MatricActionType,
  sendErrorMessage
} from '../../../../actions';
import TabPanel, { a11yProps } from '../../../../components/mui/tabs/TabPanel';
import ResponsiveDialog from '../../../../components/dialog/ResponsiveDialog';
import { getBasicHash } from '../../../../utils/Scripts';
import PagedMatricTable from '../../PagedMatricTable';
import { checkTopUpPostStatus, deleteTopUpPost, doSinglePostTopUp } from '../../../../actions/matric/topupActions';

// 2. Filters data by site into each tab
const TopUpSiteTabView = ({ data, totalPosts }) => {

  const classes = useMatricIndexView()
  const dispatch = useDispatch()
  const currentTab = useSelector(state => state.matric.currentMonitorDataTab)
  const  navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false)

  const [selectedForDel, setSelectedForDel] = useState(null)
  const [hash, setHash] = useState(getBasicHash())

  const [selectedForTopUp, setSelectedForTopUp] = useState(null)
  const [topUpModalOpen, setTopUpModalOpen] = useState(false)
  const [topUpMessage, setTopUpMessage] = useState('')
  const [topUpMessageError, setTopUpMessageError] = useState('')

  const [imageModalSrc, setImageModalSrc] = useState(null)

  const handleTabChange = (event, newValue) =>
    dispatch({ type: MatricActionType.ChangeMonitorDataTab, payload: newValue })

  const combinedData = {}
  for (let i = 0; i < Object.keys(BBS_SITE_LIST).length; i+=1) {
    const siteSymbol = BBS_SITE_LIST[Object.keys(BBS_SITE_LIST)[i]].symbol
    combinedData[siteSymbol] = data.filter(post => post.site === siteSymbol)
  }

  const onError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }

  const onSuccess = async () => {
    // sendSuccessMessage(dispatch, msg)
    setSelectedForDel(null)
    handleTopUpClose()
    setIsSaving(false)
  }

  const handleDeleteOpen = (dataDelete) => {
    setSelectedForDel(dataDelete)
    setHash(getBasicHash())
  }

  const handleEditPost = ({ site, id }) => {
    navigate(`/app/matric/topup/post/edit/${site}/${id}`)
  }

  const handleDeletePost = async () => {
    await deleteTopUpPost(selectedForDel, onSuccess, onError)(dispatch)
  }

  const handleRefresh = async (dataRefresh) => {
    await checkTopUpPostStatus(dataRefresh, onSuccess, onError)(dispatch)
  }

  const handleTopUpOpen = async (dataTopUp) => {
    setSelectedForTopUp(dataTopUp)
    setTopUpModalOpen(true)
  }

  const handleTopUpClose = () => {
    setTopUpModalOpen(false)
    setSelectedForTopUp(null)
  }

  const handleTopUp = async () => {
    setIsSaving(true)
    setTopUpMessageError('')

    const replyMessage = topUpMessage.trim()

    // test message
    if (replyMessage.length > 250) {
      setIsSaving(false)
      return setTopUpMessageError('Message must be under 250 characters')
    }

    await doSinglePostTopUp({ ...selectedForTopUp, replyMessage }, onSuccess, onError)(dispatch)
    return ()=>{}
  }


  const renderTabs = () => (
      <Paper elevation={0}>
        <AppBar position='sticky' color='default'>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label='All' {...a11yProps(0)} />)
            {
              BBS_SITE_LIST.map((site, idx) =>
                <Tab key={idx} label={site.name} {...a11yProps(idx + 1)} />)
            }
          </Tabs>
        </AppBar>

        <TabPanel value={currentTab} index={0}>
          <PagedMatricTable rows={data}
                            total={totalPosts.all}
                            handleDelete={handleDeleteOpen}
                            handleEdit={handleEditPost}
                            handleRefresh={handleRefresh}
                            handleOpenImage={(src) => setImageModalSrc(src)}
                            handleTopUp={handleTopUpOpen}
          />
        </TabPanel>
        {
          BBS_SITE_LIST.map((site, idx) =>
            <TabPanel key={idx} value={currentTab} index={idx + 1}>
              <PagedMatricTable rows={combinedData[site.symbol]}
                                total={totalPosts[site.symbol]}
                                handleDelete={handleDeleteOpen}
                                handleEdit={handleEditPost}
                                handleRefresh={handleRefresh}
                                handleOpenImage={(src) => setImageModalSrc(src)}
                                handleTopUp={handleTopUpOpen}
              />
            </TabPanel>
          )
        }

        <Modal open={!!imageModalSrc}
               disablePortal
               disableRestoreFocus
               disableEnforceFocus
               disableAutoFocus
               onClose={() => setImageModalSrc(null)}
               closeAfterTransition
               className={classes.modal}
        >
          <Paper>
            <div className={classes.closeButton}>
              <IconButton color='primary'
                          size='small'
                          component='span'
                          onClick={() => setImageModalSrc(null)}>
                <Cancel className='button'/>
              </IconButton>
            </div>
            <img src={imageModalSrc} alt='found within post'/>
          </Paper>
        </Modal>

        <Modal
          open={topUpModalOpen}
          onClose={() => handleTopUpClose()}
          className={classes.inputModal}
          disableRestoreFocus
          disableEnforceFocus
          disableAutoFocus
          closeAfterTransition
        >
          <Paper className='paper'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h4'>Enter top up message:</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField variant='outlined' fullWidth size='small'
                           label='Message' name='topUpMessage'
                           value={topUpMessage}
                           onChange={e => setTopUpMessage(e.target.value)}
                           placeholder='UP'
                           error={Boolean(topUpMessageError)}
                           helperText={topUpMessageError || 'This will be posted as a reply to the selected post'} />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent='flex-end'>
                  <Button color='primary'
                          variant='contained'
                          disabled={isSaving}
                          onClick={() => handleTopUp()}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Modal>

        <ResponsiveDialog
          isOpen={!!selectedForDel} openHash={hash}
          title='Delete Post' content='Are you sure you want to delete this post?'
          Buttons={
            [
              { name: 'Yes', event: () => handleDeletePost() },
              { name: 'Cancel', event: () => setSelectedForDel(null) }
            ]
          }
        />
      </Paper>
    )

  return renderTabs()
}

export default TopUpSiteTabView
