import { mmqJobsActionType } from '../../actions/administrative/mmqJobsAction';

const defaultState = {
  allMMQJobs: [],
  allPaymentReceiver: []
}

export const mmqJobsReducer = (state = defaultState, action = {}) => {

  switch (action.type) {
    case mmqJobsActionType.fetchAllMMQJobs:
      return { ...state, allMMQJobs: action.payload }
    case mmqJobsActionType.fetchPaymentReceiver:
      return { ...state, allPaymentReceiver: action.payload }
    default:
      return state
  }

}

export default mmqJobsReducer