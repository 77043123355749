import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import history from '../../../utils/history';
import { sendSuccessMessage } from '../../../actions';
import ContractEditor from './ContractEditor';
import { useContractListStyles } from './contractHelper';

function CreateContractTemplateView() {
  useCurrentViewCheck(Functions.Administrative_Contract_Create)
  const  navigate = useNavigate();
  const classes = useContractListStyles()
  const dispatch = useDispatch()

  const customerId = parseInt(useParams().id,10)
  const contractId = parseInt(useParams().cid,10)
  const isSub = !Number.isNaN(contractId)

  const onSuccess = (msg, uid, cid) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/administrative/contract/view/${uid}/${cid}`)
    }, 1000)
  }

  const renderBackButton = () => (
      <div className={classes.pageTitle}>
        { isSub
          ?
          <Button color='secondary'
                  variant='contained'
                  onClick={() => history.goBack()}>
            Back
          </Button>
          :
          <Button color='secondary'
                  variant='contained'
                  onClick={() => navigate('/app/customer/registered')}>
            Back to customers
          </Button>
        }
      </div>
    )

  return (
    <ViewHead functionId={Functions.Administrative_Contract_Create}
              isShowTitle={false}>
      { isSub
        ?
        <Typography variant='h3' className={classes.pageTitle} color='textPrimary'>
          Create Subcontract
        </Typography>
        :
        <Typography variant='h3' className={classes.pageTitle} color='textPrimary'>
          Create New Contract
        </Typography>
      }
      { renderBackButton() }

      <ContractEditor
        isEdit={false}
        customerId={customerId}
        originalData={{}}
        onSubmitSuccess={onSuccess}
        mainContract={ isSub ? contractId : -1 }
      />
    </ViewHead>
  )
}

export default CreateContractTemplateView
