import { alpha, lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';

export const RoleMap = {
  7: 'Student',
  10: 'Customer',
  11: 'Referrer',
  12: 'Cooperator'
};


export const dataTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  editIcon: {
    cursor: 'pointer'
  },
  tableCell: {
    width: 80,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px'
  },
  funcCell: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  },
  moreMenu: {
    '& .MuiListItemIcon-root': {
      minWidth: '30px'
    }
  },
  cardRow: {
    flexFlow: 'row wrap',
    display: 'flex',
    alignItems: 'center',
    margin: '0.25rem 0',
    '& .title': {
      fontWeight: 'bold',
      marginRight: '0.5rem'
    }
  }
}));

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '0!important'
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  // inputRoot: {
  //   color: 'inherit',
  //   borderBottom: '2px solid #000'
  // },
  // inputInput: {
  //   padding: theme.spacing(1, 1, 1, 0),
  //   paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //   transition: theme.transitions.create('width'),
  //   width: '100%',
  //   [theme.breakpoints.up('sm')]: {
  //     width: '12ch',
  //     '&:focus': {
  //       width: '20ch'
  //     }
  //   }
  // },
  search: {
    padding: theme.spacing(1, 0),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    width: '100%',
  },
  // searchIcon: {
  //   padding: theme.spacing(0, 2),
  //   height: '100%',
  //   position: 'absolute',
  //   pointerEvents: 'none',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center'
  // },
  // filterButton: {
  //   padding: theme.spacing(0, 2),
  // },
  // button: {
  //   display: 'block',
  //   marginTop: theme.spacing(2),
  // },
  formControl: {
    padding: theme.spacing(1, 0),
    width: '100%',
    [theme.breakpoints.down(475)]: {
      marginTop: theme.spacing(3)
    },
  },
  activityButtons: {
    // paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

export const useCustomerTabStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10
  },
  paper__form: {
    padding: '1rem 1rem',
    margin: '0 auto'
  },
  form__title: {
    marginBottom: '.5rem'
  },
  redCheckBox: {
    color: red[400],
    '&checked': {
      color: red[600]
    }
  },
  text: {
    cursor: 'default'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  marginBottom: {
    marginBottom: '1rem'
  },
  downloadText: {
    display: 'inline-block',
    color: 'grey'
  },
  deleteButton: {
    display: 'inline-block',
    marginLeft: '2rem'
  }
}));

export const useEditCustomerStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10
  },
  paper__form: {
    padding: '1rem 1rem',
    margin: '0 auto'
  },
  form__title: {
    marginBottom: '.5rem'
  },
  redCheckBox: {
    color: red[400],
    '&checked': {
      color: red[600]
    }
  },
  text: {
    cursor: 'default'
  },
  input: {
    height: '40px'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  hidden: {
    display: 'none'
  },
  categorySelector: {
    paddingTop: theme.spacing(1),
    marginBottom: '1.5rem'
  },
  repSelector: {
    marginTop: '0.8rem'
  },
  topButtons: {
    marginLeft: '.5rem',
    marginBottom: '.5rem'
  },
  inactiveInterview: {
    color: 'gray',
    cursor: 'default'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  noteField: {
    marginBottom: '1.5rem',
  }

}));

export const useNotesStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '800px',
    margin: '0 auto'
  },
  paper: {
    padding: '2rem',
    '& h2': {
      marginBottom: '2rem'
    }
  },
  marginTop: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  marginBottom: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  marginLeft: {
    marginLeft: '2rem'
  },
  notePaper: {
    maxHeight: '50vh'
  },
  notePaperAdd: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3)
  },
  p2: {
    paddingLeft: theme.spacing(1),
  }
}));

export const textField = [
  { name: 'firstName', label: 'FirstName' },
  { name: 'lastName', label: 'LastName' },
  { name: 'nickName', label: 'NickName' },
  { name: 'email', label: 'Email' },
  { name: 'phone', label: 'Phone' },
  { name: 'gmail', label: 'Gmail' },
  { name: 'gitName', label: 'GitName' },
  { name: 'address', label: 'Address' },
  { name: 'city', label: 'City' },
  { name: 'country', label: 'Country' },
  { name: 'zipcode', label: 'Postal Code' },
  { name: 'wechat', label: 'Wechat' }
];

export const noteField = [{ name: 'notes', label: 'Note' }];
