import React, { useEffect, useRef, useState } from 'react';
import { Box, ButtonBase, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUser, Logout } from "../../../actions";
import UserAvatar from "../../../components/user/UserAvatar";


const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

function Account() {
  const authUser = useSelector(state =>state.auth?.authUser)
  const user = useSelector(state => state.auth.user);
  const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const  navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
        fetchUser(user?.id)(dispatch)

    }, [user?.id]);



  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
const cb = () => {
  navigate('/auth/login')
}
  const handleLogout = () => {
      Logout(cb)(dispatch);
  };







    return (
    <div>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref} >
        <UserAvatar alt="User" className={classes.avatar} uid={user?.uid} avatar={authUser?.avatar}/>

      </Box>
      <Menu
          onClose={handleClose}
          // anchorOrigin={
          //   vertical: 'bottom',
          //   horizontal: 'center'
          // }
          keepMounted
          PaperProps={{ className: classes.popover }}
          // getContentAnchorEl={null}
          anchorEl={ref.current}
          open={isOpen}
      >
          <MenuItem onClick={()=>{navigate('/app/personal/info')}} >
                  My Profile
          </MenuItem>
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>

    </div>
  );
}

// const mapStateToProps = (state) => ({
//         user: state.auth?.user,
//         authUser: state.auth?.authUser
//     })

export default Account
