import {v1 as uuid} from 'uuid';

/*
default | error | success | warning | info
 */
export const MessageTypes = {
    Default: 'Default',
    Error: 'Error',
    Success: 'Success',
    Warning:'Warning',
    Info:'Info'

}

export const VariantType = {
    default : "default",
    error :"error",
    success :"success",
    warning : "warning",
    info:"info",
}
export const ErrorActionTypes = {
    Unauthorized: 'Error_Unauthorized',
    InternalServerError: "Error_InternalServerError",
    NotAcceptable: "Error_NotAcceptable",
    NotFound: "Error_NotFound",
    NetworkError: "Error_Network",
    UnknownError: "UnknownError",
}
export const MessageActionTypes = {
    SendMessage: "SendMessage",
    ClearMessage: "ClearMessage",
    SendNotification: "SendNotification",
    ClearNotification: "ClearNotification",
    SendError: "SendError",
    ClearError: "ClearError",
    ClearAll: "ClearAll"
}
export const ReadMessageTypes = {
    ReadError: "ReadError",
    ReadMessage: "ReadMessage",
    ReadNotification: "ReadNotification",
}


export const readMessage = (type, id) => (dispatch) => {
        dispatch({ type, payload: {id} })
}

export const sendMessage = (type, message, messageType) =>(dispatch) => {
        dispatch({
            type:MessageActionTypes.SendMessage,
            payload:{
                id:uuid(),
                message,
                type:messageType,
            }
        })
}
export const sendSuccessMessage= (dispatch,message) =>{
    sendMessage(
        MessageActionTypes.SendMessage,
        message,
        VariantType.success
        )(dispatch)
}
export const sendWarningMessage= (dispatch,message) =>{
    sendMessage(
        MessageActionTypes.SendMessage,
        message,
        VariantType.warning
    )(dispatch)
}
export const sendInfoMessage= (dispatch,message) =>{
    sendMessage(
        MessageActionTypes.SendMessage,
        message,
        VariantType.info
    )(dispatch)
}
export const sendErrorMessage= (dispatch,message) =>{
    sendMessage(
        MessageActionTypes.SendMessage,
        message,
        VariantType.error
    )(dispatch)
}


