import {UserSettingActionTypes} from "../../actions";
import LocalStorage from "../../utils/LocalStorage";
import {LocalStorageItems} from "../../constants";
import {getBasicHash} from "../../utils/Scripts";

export const userSettingReducer = (
    state = __loadDefaultUserSetting(),
    action = {}
) => {
    switch (action.type) {
        case UserSettingActionTypes.FetchUserSetting:
            return state;
        case UserSettingActionTypes.ChangeDarkMode:{
            const newState = {...state, darkMode: action.payload};
            LocalStorage.setObjectItem(LocalStorageItems.UserSetting, newState);
            return newState;}
        case UserSettingActionTypes.ChangeAutoSwitch:{
            const s1 = {...state, autoSwitchBackgroundMode:action.payload}
            LocalStorage.setObjectItem(LocalStorageItems.UserSetting, s1);
            return s1;}
        case UserSettingActionTypes.CHANGE_ROWS_PER_PAGE:{
            const setRow = {...state, rowsPerPage:action.payload}
            LocalStorage.setObjectItem(LocalStorageItems.UserSetting, setRow)
            return setRow}
        case UserSettingActionTypes.ChangeDraggableDialogFS:
            return {...state, draggableDialogTrigger: action.payload }

        case UserSettingActionTypes.SetDraggableDialogFS:
            return {...state, draggableDialogFS: action.payload, draggableDialogFSHash: getBasicHash(action.payload) }

        case UserSettingActionTypes.SetMenuSearchDialog:
            return {...state, toggleMenuModal: action.payload}
        default:
            return state;
    }

}


const __loadDefaultUserSetting = () => {
    const userSetting = LocalStorage.getObjectItem(LocalStorageItems.UserSetting)
    if (userSetting) {
        return userSetting;
    } 
        return {
            autoSwitchBackgroundMode: false,
            darkMode: false,
            // rowsPerPage: 30, // cannot be 30, must be 25, 50 or 100 as defined ahead
            rowsPerPage: 25,
        }
    
}

export default userSettingReducer