import React from 'react';
import { Button, SvgIcon } from '@mui/material';
import { PlusCircle as PlusIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import ViewHead from '../../../components/public/ViewHead';
import { Functions } from '../../../utils/Functions';
import useAuthCheck from '../../../hooks/useAuthCheck';
import TablePage from './TablePage';
import useCurrentViewCheck from "../../../hooks/useCurrentViewCheck";

function RegisteredInfo() {
  useCurrentViewCheck(Functions.Customer_Management_Registered_Information);
  const [isAdd] = useAuthCheck([Functions.Customer_Management_Registered_Information_Add_Customer]);
  // const loadData = () => {
  //   if (!isLoaded) { (async () => setIsLoaded(await fetchCustomers()(dispatch)))() }
  // }
  // useEffect(loadData, [])
  const  navigate = useNavigate();
  const topButtons = () => {
    if (isAdd) {
      return (
        <Button color='secondary' variant='contained' onClick={() => {
          navigate(`/app/customer/registered/add`);
        }}
                startIcon={
                  <SvgIcon fontSize='small'>
                    <PlusIcon />
                  </SvgIcon>
                }
        >
          Add Customers
        </Button>
      );
    } 
      return null;
    
  };

  const renderTable = () => <TablePage />;

  return (
    <ViewHead noMaxWidth functionId={Functions.Customer_Management_Registered_Information} topButtons={topButtons()}
              mes="Customer List">
      {renderTable()}
    </ViewHead>
  );
}

export default RegisteredInfo;
