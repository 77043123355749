import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button} from '@mui/material'
import {useParams} from 'react-router'
import ViewHead from '../../../components/public/ViewHead'
import {fetchCustomerCheck} from '../../../actions'
import {Functions} from '../../../utils/Functions'
import EditCustomer from './EditCustomer'
import history from '../../../utils/history'
import LoadingScreen from '../../../components/public/LoadingScreen'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'

function EditCustomerPage({userId}) {
    useCurrentViewCheck(Functions.Customer_Management_Registered_Information_Edit_Customer)
    const dispatch = useDispatch()
    const urlId = useParams().id
    const currentCustomer = useSelector(state => state?.customer.currentCustomer)
    const [isCustomerLoaded, setIsCustomerLoaded] = useState(false)
    const loadData = () => {
        ;(async () => {
            setIsCustomerLoaded(await fetchCustomerCheck(urlId || userId)(dispatch))
        })()
    }
    useEffect(loadData, [])
    const returnButtons = () => (
        <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => {
                history.goBack()
            }}
        >
            Return
        </Button>
    )

    const renderTable = () => <EditCustomer currentCustomer={currentCustomer} />
    const renderLoading = () => <LoadingScreen message="Loading..." />
    return (
        <div>
            {urlId ? (
                <ViewHead
                    noMaxWidth
                    functionId={Functions.Customer_Management_Registered_Information_Edit_Customer}
                    topButtons={returnButtons()}
                    mes="Edit Customer"
                >
                    {isCustomerLoaded ? renderTable() : renderLoading()}
                </ViewHead>
            ) : (
                <div>{isCustomerLoaded ? renderTable() : renderLoading()}</div>
            )}
        </div>
    )
}

export default EditCustomerPage
