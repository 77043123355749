import React, {useEffect, useState} from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import FormDisplay from '../../../../components/form/FormDisplay'
import {fetchFormBySlug} from '../../../../actions/forms/formActions'
import {sendErrorMessage, sendSuccessMessage} from '../../../../actions'

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.background.default,
        height: 'max-content',
        minHeight: '100%',
        width: '100%'
    }
}))

function FormViewerView({rowId}) {
    const formId = useParams().fid
    const userId = useParams().uid || '-1'
    const classes = useStyles()
    const dispatch = useDispatch()
    const currentFormInStorage = useSelector(state => state?.forms.currentForm)
    const [isLoaded, setIsLoaded] = useState(false)

    const loadData = () => {
        fetchFormBySlug(formId || rowId, setIsLoaded)(dispatch)
    }
    useEffect(loadData, [dispatch, formId || rowId])

    useEffect(() => {
        if (isLoaded) {
            document.title = currentFormInStorage.name || 'Unavailable'
        } else {
            document.title = 'Loading'
        }
    }, [isLoaded, currentFormInStorage])

    const onSuccess = data => sendSuccessMessage(dispatch, `Success:${data}`)
    const onError = msg => sendErrorMessage(dispatch, `Error:${msg}`)

    return (
        <div className={classes.container}>
            <FormDisplay
                form={currentFormInStorage}
                uid={userId}
                isLoaded={isLoaded}
                onSuccess={onSuccess}
                onError={onError}
            />
        </div>
    )
}

export default FormViewerView
