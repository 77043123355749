import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormHelperText, Grid, IconButton, Paper, TextField, Typography , Rating } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import clsx from 'clsx';
import FormControl from '@mui/material/FormControl';
import { useReviewViewStyle } from '../reviewHelper';
import CenterDisplay from '../../../components/misc/CenterDisplay';
import { checkReviewUid, createReview, fillReview } from '../../../actions/review/reviewActions';
import { ReactComponent as StarFullIcon } from './starFilled.svg';
import { ReactComponent as StarEmptyIcon } from './starEmpty.svg';
import { MAX_TEXT_LENGTH } from '../../../constants';

function ReviewPublicView() {

  const {uid} = useParams()
  const dispatch = useDispatch()
  const classes = useReviewViewStyle()

  const currentReviewCheck = useSelector(state => state.review.currentReviewCheck)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isReviewPosted, setIsReviewPosted] = useState(false)
  const [displayMessage, setDisplayMessage] = useState('Loading...')
  const [isReviewChecked, setIsReviewChecked] = useState(null)

  const [rating, setRating] = useState(0)
  const [ratingPrev, setRatingPrev] = useState(0)
  const [reviewText, setReviewText] = useState('')
  const [reviewTextError, setReviewTextError] = useState(null)
  const [deBouncedRating, setDebouncedRating] = useState(0)

  const loadData = () => {
    checkReviewUid(uid, setIsReviewChecked)(dispatch)
  }
  useEffect(loadData, [dispatch, uid])

  useEffect(() => {
    if (isReviewChecked !== null) {
      if (currentReviewCheck.type === 'set' || currentReviewCheck.type === 'review') {
        setIsLoaded(true)
        document.title = `Review: ${currentReviewCheck.set?.name}`
      } else {
        setDisplayMessage('Invalid Review')
        document.title = "Invalid Review"
      }
    }
  }, [isReviewChecked, currentReviewCheck])

  useEffect(() => {
    // Allow toggling the same rating value when repeat clicking
    if (!rating) {
      setRating(ratingPrev - 1)
      setRatingPrev(ratingPrev - 1)
    }
  }, [rating])

  useEffect(() => {
    // Debounce rating changing
    const timeId = setTimeout(() => {
      setDebouncedRating(rating)
    },3000)
    return () => clearTimeout(timeId);
  },[rating])

  useEffect(() => {
    if (deBouncedRating >= 4) {
      // Only change URL if rating above 4
      onGoogleSubmit()
    }
  }, [deBouncedRating])


  const onGoogleSuccess = () => {
    // redirect to actual review site
    window.location.replace(currentReviewCheck.set.googleUrl)
  }

  const onInternalSuccess = () => {
    setDisplayMessage('Thank you for your review and feedback to us. We will try our best to offer a better user experience for our customers :)')
    setIsReviewPosted(true)
  }

  const onError = () => {
    setIsSaving(false)
    // sendErrorMessage(dispatch, msg)
    // console.log(msg)
  }

  const onInternalSubmit = (e) => {
    e.preventDefault()
    setIsSaving(true)
    setReviewTextError(null)

    if (reviewText.length > MAX_TEXT_LENGTH) {
      setIsSaving(false)
      setReviewTextError(`Review cannot be longer than ${MAX_TEXT_LENGTH} characters`)
      return
    }

    const postData = { type: 'internal', score: rating, text: reviewText }
    if (currentReviewCheck.type === 'review') {
      // A review has already been pre-initialized, so need to update it
      fillReview({ ...postData, uid }, onInternalSuccess, onError)(dispatch)
    } else {
      // Create a brand new review entry
      createReview({ ...postData, setUid: uid }, onInternalSuccess, onError)(dispatch)
    }
  }

  const onGoogleSubmit = () => {
    setIsSaving(true)
    const postData = { type: 'google', score: rating, text: reviewText }
    if (currentReviewCheck.type === 'review') {
      // A review has already been pre-initialized, so need to update it
      fillReview({ ...postData, uid }, onGoogleSuccess, onError)(dispatch)
    } else {
      // Create a brand new review entry
      createReview({ ...postData, setUid: uid }, onGoogleSuccess, onError)(dispatch)
    }
  }

  const renderTopBar = () =>
    <Grid container className={classes.topBanner} direction='row'
          justifyContent='space-between' alignItems='center'>
      <Grid item>
        <IconButton size='small' onClick={()=> window.location.replace('https://google.com')}>
          <ArrowBack className='backArrow'/>
        </IconButton>
      </Grid>
      <Grid item>
        <Typography className='title'>{ currentReviewCheck.set?.name }</Typography>
      </Grid>
      <Grid item>
        <Button variant='text' type='submit'
                disabled={rating <= 0 || isSaving} disableFocusRipple disableRipple
                className={clsx('post', { 'active': rating > 0 })}>
          Post
        </Button>
      </Grid>
    </Grid>

  const renderRatingsContainer = () =>
    <div className={classes.ratingsContainer}>
      <Rating max={5} name="rating"
              icon={<StarFullIcon />}
              emptyIcon={<StarEmptyIcon />}
              value={rating}
              disabled={isSaving}
              onChange={(e, r) => {
                if (r) { setRatingPrev(r) }
                setRating(r)
              }}
              onChangeActive={(e) => { e.preventDefault() }}
      />
    </div>

  const renderTextReviewView = () =>
    <div>
      <FormControl fullWidth>
        <FormHelperText error={!!reviewTextError} className={classes.reviewTextFormHelper}>
          {reviewTextError || ''}
        </FormHelperText>
        <TextField fullWidth size='small' variant='outlined' multiline
                   disabled={isSaving} className={classes.reviewText}
                   name='name' placeholder='Share details of your own experience at this place'
                   value={reviewText} onChange={e => setReviewText(e.currentTarget.value)}
                   error={!!reviewTextError}
                   helperText={reviewTextError || ''}
        />
      </FormControl>
    </div>

  const renderRatingView = () =>
    <Paper elevation={2} className={classes.paper}>
      <div className={clsx(classes.redirectMask, {
        'show': rating > 3
      })}><CenterDisplay>
        <Typography variant='h3'>Please wait while you are being redirected...</Typography>
      </CenterDisplay>
      </div>
      <form onSubmit={onInternalSubmit}>
        <Grid container direction='row'>
          <Grid item xs={12}>{ renderTopBar() }</Grid>
          <Grid item xs={12}><div className={classes.profileSpacer}/></Grid>
          <Grid item xs={12}>{ renderRatingsContainer() }</Grid>
          { rating > 0 && rating < 4 && <Grid item xs={12}>{ renderTextReviewView() }</Grid> }
        </Grid>
      </form>
    </Paper>

  return (
    <div className={classes.paperWrapper}>
      {
        isLoaded && !isReviewPosted
          ? renderRatingView()
          :
          <CenterDisplay className={classes.centerMessageWrapper}>
            <Typography variant='h1'
                        color='textPrimary'>
              { displayMessage }
            </Typography>
          </CenterDisplay>
      }
    </div>
  )
}

export default ReviewPublicView
