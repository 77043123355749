import React from 'react';
import {
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Grid
} from '@mui/material';
import clsx from 'clsx';
import { lighten, alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CheckCircleOutline } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import HowToRegIcon from "@mui/icons-material/HowToReg";
import {debounce} from "lodash";
import { CATEGORY_ITEMS } from "../../../constants";
import SearchBox from '../../../components/public/SearchBox';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '0!important'
  },
  highlight:
      theme.palette.mode === 'light'
          ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85)
          }
          : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark
          },
  title: {
    flex: '1 1 100%'
  },
  search: {
    padding: theme.spacing(1, 0),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    width: '100%',
  },
  formControl: {
    padding: theme.spacing(1, 0),
    width: '100%',
    [theme.breakpoints.down(475)]: {
      marginTop: theme.spacing(3)
    },
  }
}));



function EnhancedTableToolbar(props) {
  const classes = useToolbarStyles();
  const {numSelected, handleChange, onActive, status, setStatus, isActive, setIsActive, isLoading} = props
  // const [open, setOpen] = React.useState(false)

  const searchHandler = debounce((searchVal) => {
    handleChange(searchVal)
  }, 500)

  const handleClose = () => {
    // setOpen(false);
  };

  const handleOpen = () => {
    // setOpen(true);
  };

  const handleDisplay=()=><div>
      <Tooltip title="Activate Selected" onClick={() => onActive(true)}>
        <IconButton aria-label="Activate" size="large">
          <CheckCircleOutline/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Deactivate Selected" onClick={() => onActive(false)}>
        <IconButton aria-label="Deactivate" size="large">
          <BlockIcon/>
        </IconButton>
      </Tooltip>
    </div>




  return (
    <Toolbar  className={clsx(classes.root, {
             [classes.highlight]: numSelected > 0
          })}>
      <Grid container spacing={1}>

        <Grid item xs={12} sm={6} md={8} xl={10}>
          {numSelected > 0 ? (
              <Tooltip title="Activation">
                <IconButton aria-label="activation" size="large">
                  <HowToRegIcon/>
                </IconButton>
              </Tooltip>
          ) : (
              <div className={classes.search}>
                <SearchBox
                  placeholder='Search...'
                  size='small'
                  type='search'
                  searchHandler={searchHandler}
                  isLoading={isLoading}
                />
              </div>
          )}
        </Grid>

        {numSelected > 0 ?
            (<Grid item xs={12} sm={6} md={8} xl={10}>
              <Grid container justifyContent='space-between'>
                <Grid item xs={6} ml={1.5}>
                  <Typography color="inherit" variant="subtitle1" component="span">
                    {numSelected} Selected
                  </Typography>
                  {handleDisplay()}
                </Grid>

              </Grid>
            </Grid>)
            :
            <>
              <Grid item xs={6} sm={3} md={2} xl={1}>
                <FormControl
                  className={classes.formControl}
                  size='small'
                >
                  {/* <InputLabel id="demo-controlled-open-select-label">Select</InputLabel> */}
                  <InputLabel>Status</InputLabel>
                  <Select

                      onClose={handleClose}
                      onOpen={handleOpen}
                      value={status}
                      onChange={event=>{setStatus(event.target.value)}}
                                         >
                    <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
                    <MenuItem value="notStart">Not Start</MenuItem>
                    <MenuItem value="inProgress">In Progress</MenuItem>
                    <MenuItem value="finished">Finished</MenuItem>


                  </Select>

                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} md={2} xl={1}>
                <FormControl
                  className={classes.formControl}
                  size='small'
                >
                  <InputLabel>IsActive</InputLabel>
                  <Select
                      onClose={handleClose}
                      onOpen={handleOpen}
                      value={isActive}
                      onChange={event=>{setIsActive(event.target.value)}}
                                        >
                    <MenuItem value={CATEGORY_ITEMS.all}>All</MenuItem>
                    <MenuItem value>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
        }

        </Grid>

    </Toolbar>
  );
}

export default EnhancedTableToolbar;
