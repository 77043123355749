import {v1 as uuid} from 'uuid';
import {ErrorActionTypes} from "./messageActions";

export const HttpStatusCode = {
    UNAUTHORIZED: 401,
    NOTFOUND: 404,
    BADREQUEST: 400,
    NOTACCEPTABLE: 406,
    UNKNOWN: 520
};

export const axiosErrorInterceptor = reduxStore => error => {
    // console.log(JSON.stringify(error));
    if(error.message==="Network Error"){
        reduxStore.dispatch({
            type: ErrorActionTypes.NetworkError,
            payload: {
                id: uuid(),
                error: "Network Connection Error",
                message: "Please check your network connection and try later.",
                statusCode: 400,
                isNeedGoErrorPage: false,
            }
        })
        return Promise.reject(error);
    }

    const status = error?.response ? error.response.status : HttpStatusCode.UNKNOWN
    // console.warn("Interceptor catch code", status);
    switch (status) {
        case HttpStatusCode.BADREQUEST:
            reduxStore.dispatch({
                type: ErrorActionTypes.NetworkError,
                payload: {
                    id: uuid(),
                    error: "Network Connection Error",
                    message: "Please check your network connection and try later.",
                    statusCode: 400,
                    isNeedGoErrorPage: true
                }
            })
        break;
        case HttpStatusCode.UNAUTHORIZED:
            reduxStore.dispatch({
                type: ErrorActionTypes.Unauthorized,
                payload: {
                    id: uuid(),
                    error: "Invalid Credentials",
                    message: "Please Login again",
                    statusCode: status,
                    isNeedGoErrorPage: true
                }
            })
            break;
        case HttpStatusCode.NOTFOUND:
            reduxStore.dispatch({
                type: ErrorActionTypes.NotFound,
                payload: {
                    id: uuid(),
                    error: "The requested resource is not found.",
                    message: "Please contact support!",
                    statusCode: status,
                    isNeedGoErrorPage: false
                }
            })
            break;
        case HttpStatusCode.NOTACCEPTABLE:
            reduxStore.dispatch({
                type: ErrorActionTypes.NotAcceptable,
                payload: {
                    id: uuid(),
                    error: "The Data format is not Acceptable.",
                    message: JSON.stringify(error?.response?.data?.message),
                    statusCode: status,
                    isNeedGoErrorPage: false
                }
            })
            // console.log(JSON.stringify(error?.response?.data))
            break;
        default:
            reduxStore.dispatch({
                type: ErrorActionTypes.UnknownError,
                payload: {
                    id: uuid(),
                    error: "Unknown Error",
                    message: error.message,
                    statusCode: status,
                    isNeedGoErrorPage: false
                }
            })
    }

    return Promise.reject(error);
}
