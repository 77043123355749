import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { Grid, Paper, TextField } from '@mui/material';
import {
  createM2WReview,
  updateM2WReview
} from '../../../../actions/review/reviewActions';
import { sendErrorMessage } from '../../../../actions';
import { REVIEW_M2W_EDITOR_VALIDATOR, useReviewEditorStyles } from '../../reviewHelper';
import SubmitButton from '../../../../components/mui/button/SubmitButton';

const schema = yup.object().shape(REVIEW_M2W_EDITOR_VALIDATOR)
function M2WReviewEditor({ isEdit, originalData, onSubmitSuccess }) {

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  })

  const dispatch = useDispatch()
  const classes = useReviewEditorStyles()

  const [isSaving, setIsSaving] = useState(false)

  const onError = (msg) => {
    sendErrorMessage(dispatch, msg)
    setIsSaving(false)
  }

  const onSubmit = async (formData) => {
    setIsSaving(true)

    if (isEdit) {
      return updateM2WReview({ ...formData, id: originalData.id }, onSubmitSuccess, onError)(dispatch)
    } 
      return createM2WReview(formData, onSubmitSuccess, onError)(dispatch)

    
  }

  const renderForm = () =>
    <Grid container spacing={4} alignItems='center'>
      <Grid item xs={12} md={6}>
        <TextField fullWidth size='small'
                   label='Name' required
                   disabled={isSaving} variant='outlined'
                   defaultValue={isEdit ? originalData.name : ''}
                   name='name' inputRef={register}
                   error={Boolean(errors.name?.message)}
                   helperText={errors.name?.message}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField fullWidth size='small'
                   label='Score' required
                   disabled={isSaving} variant='outlined'
                   defaultValue={isEdit ? originalData.score : ''}
                   name='score' inputRef={register}
                   error={Boolean(errors.score?.message)}
                   helperText={errors.score?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth size='small'
                   label='Review' required
                   multiline rows={3}
                   disabled={isSaving} variant='outlined'
                   defaultValue={isEdit ? originalData.text : ''}
                   name='text' inputRef={register}
                   error={Boolean(errors.text?.message)}
                   helperText={errors.text?.message}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3} justifyContent='flex-end' alignItems='center'
              className={classes.submitButton}>
          <Grid item>
            <SubmitButton isSaving={isSaving} text='Save' isSavingText='Saving'
                          fullWidth variant='contained' color='primary'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  return (
    <Paper className={classes.paper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        { renderForm() }
      </form>
    </Paper>
  )
}

export default M2WReviewEditor
