import React,{useState,useEffect} from 'react';
import {Avatar} from "@mui/material";
import {useSelector} from "react-redux";
import {ServerBaseURL} from "../../constants";

function UserAvatar({uid, avatar, ...rest}) {

    const[refresh, setRefresh]=useState(false)
    const userAvatar =useSelector(state=>state.userAvatar.uploadTime)


    useEffect(() => refreshData(),[userAvatar])

    const refreshData=()=>{
        setRefresh(!refresh)
    }




    return (


        <Avatar alt="User" src={uid && avatar ? `${ServerBaseURL}/asset/images/avatar/${uid}?${userAvatar}`:''} {...rest} />



    );
}

// UserAvatar.propTypes = {
//     uid: PropTypes.string
// }
export default UserAvatar;
