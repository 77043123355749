import * as React from 'react'
import {Button} from '@mui/material'

function ResourcesCommandCell(props) {
    const {dataItem, editField} = props
    const {add, update, discard, edit, remove} = props
    const inEdit = dataItem[editField]
    const isNewItem = dataItem.id === undefined
    return inEdit ? (
        <td className="k-command-cell">
            <Button
                icon="checkmark"
                onClick={() => {
                    if (isNewItem) {
                        add(dataItem)
                    } else {
                        update(dataItem)
                    }
                }}
            />
            <Button icon="close" onClick={() => discard(dataItem)} />
        </td>
    ) : (
        <td className="k-command-cell">
            <Button icon="pencil" onClick={() => edit(dataItem)} />

            <Button icon="delete" onClick={() => remove(dataItem)} />
        </td>
    )
}

export default ResourcesCommandCell
