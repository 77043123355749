import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import { sendSuccessMessage } from '../../../../actions';
import history from '../../../../utils/history';
import ViewHead from '../../../../components/public/ViewHead';
import QRCodeSetEditor from './QRCodeSetEditor';
import { fetchQRCodeSet } from '../../../../actions/qrcode/qrcodeAction';

function EditQRCodeSetViw({qrId}) {
  useCurrentViewCheck(Functions.QRCode_Set_Edit)
  const  navigate = useNavigate();
  const setID = useParams().id
  const dispatch = useDispatch()

  const currentQRCodeSet = useSelector(state => state.qrcode.currentQRCodeSet)
  const [isLoaded, setIsLoaded] = useState(false)

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchQRCodeSet(setID || qrId)(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    if (isLoaded && currentQRCodeSet) {
      setIsLoaded(true)
    }
  }, [isLoaded, isLoaded])

  const onSuccess = (msg, id = -1) => {
    sendSuccessMessage(dispatch, msg)
    setTimeout(() => {
      navigate(`/app/qr/view/${id}`)
    }, 1000)
  }

  const renderReturnButton = () => (
      <Button color='secondary'
              variant='contained'
              onClick={() => history.goBack()}>
        Back
      </Button>
    )

  return (
      <div>
        {setID?
            <ViewHead functionId={Functions.QRCode_Set_Edit} topButtons={renderReturnButton()}>
              { isLoaded && <QRCodeSetEditor isEdit originalData={currentQRCodeSet} onSubmitSuccess={onSuccess} /> }
            </ViewHead>
            :
            <div>
              { isLoaded && <QRCodeSetEditor isEdit originalData={currentQRCodeSet} onSubmitSuccess={onSuccess} /> }
            </div>
        }
      </div>

  )

}

export default EditQRCodeSetViw

