import {GroupByTimeUnit, TimePeriodUnit} from '../../../constants'

const timeSpanOptionsMenu = [
    {
        label: 'Yesterday',
        value: {
            timePeriod: '1',
            timePeriodUnit: TimePeriodUnit.DAYS,
            groupBy: GroupByTimeUnit.DAY
        }
    },
    {
        label: '3 days ago',
        value: {
            timePeriod: '3',
            timePeriodUnit: TimePeriodUnit.DAYS,
            groupBy: GroupByTimeUnit.DAY
        }
    },
    {
        label: 'Last week',
        value: {
            timePeriod: '1',
            timePeriodUnit: TimePeriodUnit.WEEKS,
            groupBy: GroupByTimeUnit.DAY
        }
    },
    {
        label: 'Last 3 weeks',
        value: {
            timePeriod: '3',
            timePeriodUnit: TimePeriodUnit.WEEKS,
            groupBy: GroupByTimeUnit.DAY
        }
    },
    {
        label: 'Last month',
        value: {
            timePeriod: '1',
            timePeriodUnit: TimePeriodUnit.MONTHS,
            groupBy: GroupByTimeUnit.DAY
        }
    },
    {
        label: 'Last 3 months',
        value: {
            timePeriod: '3',
            timePeriodUnit: TimePeriodUnit.MONTHS,
            groupBy: GroupByTimeUnit.MONTH
        }
    },
    {
        label: 'Last quarter',
        value: {
            timePeriod: '1',
            timePeriodUnit: TimePeriodUnit.QUARTERS,
            groupBy: GroupByTimeUnit.MONTH
        }
    },
    {
        label: 'Last 3 quarters',
        value: {
            timePeriod: '3',
            timePeriodUnit: TimePeriodUnit.QUARTERS,
            groupBy: GroupByTimeUnit.MONTH
        }
    },
    {
        label: 'Last half year',
        value: {
            timePeriod: '6',
            timePeriodUnit: TimePeriodUnit.MONTHS,
            groupBy: GroupByTimeUnit.MONTH
        }
    },
    {
        label: 'Last year',
        value: {
            timePeriod: '1',
            timePeriodUnit: TimePeriodUnit.YEARS,
            groupBy: GroupByTimeUnit.MONTH
        }
    },
    {
        label: 'Last 3 years',
        value: {
            timePeriod: '3',
            timePeriodUnit: TimePeriodUnit.YEARS,
            groupBy: GroupByTimeUnit.YEAR
        }
    }
]

export default timeSpanOptionsMenu
