import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import { Input, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';


function ListDocuments({ documents = [], onSearch }) {
  const delayedQuery = useCallback(
    debounce((q) => onSearch(q), 500),
    []
  );
  const search = (value) => {
    delayedQuery(`name contains '${value}'`);
  };



  return (
    <div style={{marginLeft: '10px',marginTop:'-120px' ,width: '80vw', display:'flex', justifyContent:'center',flexDirection:'column'}}
    >
      {/* <Row gutter={16}> */}
      {/*  <Col span={24}> */}


          <div className="table-card-actions-container">
            <div className="table-search-container">
              <Input
                placeholder="Search Google Drive"
                onChange={(e) => search(e.target.value)}
                // onSearch={(value) => search(value)}
                className="table-search-input"
                size="large"
                // enterButton
              />
            </div>
          </div>
          {/* <Table */}
          {/*  className="table-striped-rows" */}
          {/*  columns={columns} */}
          {/*  dataSource={documents} */}
          {/*  pagination={{ simple: true }} */}
          {/*  loading={isLoading} */}
          {/* /> */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left'>File Name</TableCell>
                <TableCell align='left'>File ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((item)=>(
                <TableRow key={item.id}>
                  <TableCell align='left'>{item.name}</TableCell>
                  <TableCell align='left'>{item.id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      {/*  /!*</Col>*!/ */}
      {/* </Row> */}
    </div>
  );
}

export default ListDocuments;
