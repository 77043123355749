import interact from 'interactjs';
import { debounce } from 'lodash';

const moveHandler = debounce((e, onMove) => onMove(e), 500)

const dragMoveListener = (event) => {
  const {target} = event
  // keep the dragged position in the data-x/data-y attributes
  const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
  const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

  // translate the element
  target.style.webkitTransform =`translate(${  x  }px, ${  y  }px)`
  target.style.transform = `translate(${  x  }px, ${  y  }px)`

  // update the position attributes
  target.setAttribute('data-x', x)
  target.setAttribute('data-y', y)
}

const resizeListener = (event) => {
  const {target} = event
  let x = (parseFloat(target.getAttribute('data-x')) || 0)
  let y = (parseFloat(target.getAttribute('data-y')) || 0)

  // update the element's style
  target.style.width = `${event.rect.width  }px`
  target.style.height = `${event.rect.height  }px`

  // translate when resizing from top or left edges
  x += event.deltaRect.left
  y += event.deltaRect.top

  target.style.webkitTransform =`translate(${  x  }px,${  y  }px)`
  target.style.transform = `translate(${  x  }px,${  y  }px)`

  target.setAttribute('data-x', x)
  target.setAttribute('data-y', y)
  // target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
}

const callbackListener = (event, onMove) => {
  moveHandler(event, onMove)
}

const setupInteractable = (item, onMove, restriction='parent',
                           edges={ left: true, right: true, bottom: true, top: true }) => {

  item.resizable({
    margin: 5,
    // resize from all edges and corners
    edges,
    listeners: {
      move: resizeListener,
    },
    modifiers: [
      // keep the edges inside the parent
      interact.modifiers.restrictEdges({
        outer: restriction
      }),

      // minimum size
      interact.modifiers.restrictSize({
        min: {
          width: 25,
          height: 25
        }
      }),

      // force aspect ratio
      interact.modifiers.aspectRatio({
        ratio: 'preserve'
      })
    ],

    inertia: false
  })
    .draggable({
      listeners: {
        move: dragMoveListener,
      },
      inertia: false,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction
        })
      ],
    })
    .on(['dragend', 'resizeend'], (event) => callbackListener(event, onMove))
}

export default setupInteractable
