import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {Grid, Paper, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import lodash, {debounce} from 'lodash'
import {useNavigate} from 'react-router-dom'
import {
    deleteHistory,
    fetchClassHistory,
    fetchOneClassHistory,
    readOneClassHistory,
    fetchClass,
    fetchClasses,
    updateClass,
    fetchCurrentClassForEdit,
    addClassTransferRecord
} from '../../../actions/class/classActions'
import LXGrid from '../../../components/mui/datagrid/XGrid'
import SearchBox from '../../../components/public/SearchBox'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    icons: {
        zIndex: '10'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    dataGrid: {
        width: '100%',
        marginBottom: theme.spacing(2),
        height: '80vh'
    },
    table: {
        minWidth: 750
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    editIcon: {
        cursor: 'pointer'
    },
    tableCell: {
        width: 80,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px'
    },
    funcCell: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    add: {
        '& > span': {
            margin: theme.spacing(2)
        }
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalPaper: {
        width: '80%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    modalTitle: {
        padding: theme.spacing(1, 0)
    },
    floatingWindow: {
        width: '80%',
        height: '80%',
        overflow: 'scroll',
        backgroundColor: 'white',
        border: '1px solid black',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '9999'
    },
    link: {
        '&:hover': {
            textDecoration: 'none'
        }
    },
    search: {
        position: 'relative',
        marginButton: theme.spacing(1),
        width: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    groupButton: {
        marginLeft: theme.spacing(1)
    },
    buttons: {
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            '& .MuiGrid-item': {flexGrow: '1', padding: '0', paddingTop: '10px'}
        }
    }
}))

function ClassChangeHistory({classTransferDataByClassId, customerId, classTransferDataByCustomerId, classesData}) {
    const classes = useStyles()
    // const dispatch = useDispatch();
    const [searchArgs, setSearchArgs] = useState({searchKey: undefined})
    const [transferRecords, setTransferRecords] = useState(
        customerId ? classTransferDataByCustomerId : classTransferDataByClassId
    )
    const [isLoaded, setIsLoaded] = useState(false)
    const [tempData, setTempData] = useState(undefined)
    const [dataForDisplay, setDataForDisplay] = useState(undefined)
    const navigate = useNavigate()

    const handleSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase()
        setSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }
    const searchHandler = debounce(searchVal => {
        handleSearchChange(searchVal)
    }, 500)

    useEffect(() => {
        fetchClasses()
        if (customerId) {
            if (classTransferDataByCustomerId.length !== 0) {
                setTransferRecords(classTransferDataByCustomerId)
            }
        } else if (classTransferDataByClassId.length !== 0) {
            setTransferRecords(classTransferDataByClassId)
        }
    }, [classTransferDataByClassId, classTransferDataByCustomerId, customerId])

    useEffect(() => {
        const data = []
        let currentClass = ''

        if (transferRecords && Array.isArray(transferRecords) && transferRecords.length !== 0) {
            transferRecords.forEach(eachRecord => {
                let tempRecord = {}
                let foundInClass = false
                if (eachRecord?.currentClassId === null) {
                    data.push(eachRecord)
                } else if (eachRecord?.currentClassId) {
                    if (classesData) {
                        classesData.forEach(eachClass => {
                            eachClass.students.forEach(eachStu => {
                                if (eachStu?.id === eachRecord.transferStudentId) {
                                    foundInClass = true
                                    currentClass = `${eachClass?.id}.${eachClass?.name}`
                                    tempRecord = {...eachRecord, currentClass}
                                    data.push(tempRecord)
                                }
                            })
                        })
                    }
                    if (!foundInClass) {
                        data.push({...eachRecord, foundInClass: false})
                    }
                }
            })
        }

        if (customerId && currentClass !== '') {
            data.forEach(eachRecord => {
                eachRecord.currentClass = currentClass
            })
        } else if (customerId && currentClass === '') {
            data.forEach(eachRecord => {
                eachRecord.currentClass = 'onhold'
            })
        }

        setTempData(data)
        setDataForDisplay(data)
        setIsLoaded(true)
    }, [transferRecords, customerId, classesData])

    useEffect(() => {
        setIsLoaded(false)
    }, [customerId])

    useEffect(() => {
        let searchResult = []

        searchResult =
            isLoaded &&
            tempData?.filter(record => {
                let info = `${record?.transferStudentInfo?.id}${record?.transferStudentInfo?.firstName}${
                    record?.transferStudentInfo?.lastName
                }${record?.transferStudentInfo?.nickName}
       ${record?.prevClassesTransferInfo?.id}${record?.prevClassesTransferInfo?.name}${
                    record?.currentClassesTransferInfo?.id
                }${record?.currentClassesTransferInfo?.name}
       ${record?.repInfo?.id}${record?.repInfo?.firstName}${record?.repInfo?.lastName}${new Date(
                    Date.parse(record?.createdAt)
                ).toLocaleString('sv')}
       ${record?.notes}${record?.transferStudentInfo?.email}${record?.transferStudentInfo?.phone}${
                    record?.transferStudentInfo?.wechat
                }`
                    .trim()
                    .toLowerCase()
                if (
                    record?.currentClassId === null ||
                    record?.currentClass === 'onhold' ||
                    record?.currentClass === null ||
                    record?.prevClassesTransferInfo === null ||
                    record?.foundInClass !== undefined
                ) {
                    info += 'onhold'
                }
                return lodash.includes(info, searchArgs?.searchKey)
            })
        if (searchArgs?.searchKey !== undefined && searchArgs?.searchKey !== '') {
            setDataForDisplay(searchResult)
        } else {
            setDataForDisplay(tempData)
        }
    }, [searchArgs, isLoaded])

    const columns = [
        {field: 'id', headerName: 'Id', width: 100, sortable: true},
        {field: 'studentID', headerName: 'StudentID', width: 100, sortable: true},
        {field: 'name', headerName: 'Name', width: 150, sortable: false},
        {field: 'previousClass', headerName: 'From Class', width: 250, sortable: false},
        {field: 'toClass', headerName: 'To Class', width: 250, sortable: false},
        {field: 'currentClass', headerName: 'Current Class', width: 250, sortable: false},
        {field: 'notes', headerName: 'Transfer Reason', width: 300, sortable: false},
        {field: 'transferDate', headerName: 'Transfer Date', width: 300, sortable: false},
        {field: 'rep', headerName: 'Rep', width: 150, sortable: false},
        {field: 'email', headerName: 'Email', width: 250, sortable: false},
        {field: 'phone', headerName: 'Phone', width: 250, sortable: false},
        {field: 'wechat', headerName: 'Wechat', width: 250, sortable: false}
    ]

    let id = 0
    const rows =
        isLoaded &&
        dataForDisplay?.map(record => {
            id += 1
            let name = ''
            if (record?.transferStudentInfo?.nickName !== '') {
                name = `${record?.transferStudentInfo?.firstName}(${record?.transferStudentInfo?.nickName}) ${record?.transferStudentInfo?.lastName}`
            } else {
                name = `${record?.transferStudentInfo?.firstName} ${record?.transferStudentInfo?.lastName}`
            }
            let currentClass = ''
            if (record?.currentClass === undefined) {
                currentClass = 'onhold'
            } else {
                currentClass = record?.currentClass
            }

            let previousClass = ''
            if (record?.prevClassesTransferInfo?.id === undefined) {
                previousClass = 'onhold'
            } else {
                previousClass = `${record?.prevClassesTransferInfo?.id}.${record?.prevClassesTransferInfo?.name}`
            }
            let toClass = ''
            if (record?.currentClassesTransferInfo?.id === undefined) {
                toClass = 'onhold'
            } else {
                toClass = `${record?.currentClassesTransferInfo?.id}.${record?.currentClassesTransferInfo?.name}`
            }
            let rep = ''
            rep = `${record?.repInfo?.firstName} ${record?.repInfo?.lastName}`
            let date = ''
            date = new Date(Date.parse(record?.createdAt))?.toLocaleString('sv')
            return {
                id,
                studentID: record?.transferStudentInfo?.id,
                name,
                previousClass,
                toClass,
                currentClass,
                notes: record?.notes,
                rep,
                email: record?.transferStudentInfo?.email,
                phone: record?.transferStudentInfo?.phone,
                wechat: record?.transferStudentInfo?.wechat,
                transferDate: date
            }
        })

    const data = {columns, rows}
    return (
        <Grid item>
            <Typography variant="h5" color="textPrimary">
                Class Change History &nbsp;&nbsp;
            </Typography>
            <Paper className={classes.paper}>
                <Grid item xs={12}>
                    <div className={classes.search}>
                        <SearchBox placeholder="Search..." size="small" type="search" searchHandler={searchHandler} />
                    </div>
                </Grid>
                {isLoaded && (
                    <div>
                        <LXGrid
                            {...data}
                            pagination
                            totalHeight={600}
                            disableSelectionOnClick
                            sortModel={[{field: 'id', sort: 'desc'}]}
                            // searchArgs={searchArgs}
                            onCellDoubleClick={GridCellParams => {
                                navigate(`/app/customer/registered/edit/${GridCellParams?.row?.studentID}`)
                            }}
                        />
                    </div>
                )}
            </Paper>
        </Grid>
    )
}

const mapStateToProps = state => ({
    oneClass: state.classes?.oneClass,
    classHistory: state.classes?.classHistory,
    homework: state.classes?.homework,
    customers: state.customer?.customers,
    classViewData: state.classes?.classViewData,
    classesData: state.classes?.class?.data,
    currentClass: state.classes?.currentClass,
    fetchedClasses: state.classes?.class,
    classTransferDataByClassId: state?.classes?.classTransferDataByClassId,
    classTransferDataByCustomerId: state?.classes?.classTransferDataByCustomerId
})

export default connect(mapStateToProps, {
    fetchOneClassHistory,
    deleteHistory,
    fetchClassHistory,
    readOneClassHistory,
    fetchClass,
    fetchClasses,
    updateClass,
    fetchCurrentClassForEdit,
    addClassTransferRecord
})(ClassChangeHistory)
