import {ProductsActions} from '../../actions'

export const productReducer = (state = initialState, action = {}) => {
    const {type, payload} = action
    switch (type) {
        case ProductsActions.SetProductFormStatus:
            return {...state, productFormStatus: payload}
        case ProductsActions.SetCurrentProduct:
            return {...state}
        case ProductsActions.FetchProducts:
            return {...state, products: payload}
        case ProductsActions.SetEditProductStatus:
            return {...state, editProduct: payload.data, productFormStatus: 'EDIT', id: payload.id}
        case ProductsActions.prepareReadProduct:
            return {...state, readProduct: payload.data, productFormStatus: 'READ', id: payload.id}
        case ProductsActions.UpdateProduct:
            return {...state}
        case ProductsActions.DeleteProduct:
            return {...state, deleteId: payload}
        case ProductsActions.FetchCategories:
            return {...state, categories: payload}
        case ProductsActions.AddProductCategories:
            return {...state, productFormStatus: 'EDIT'}
        case ProductsActions.AddProductCategories2:
            return {...state, productFormStatus: 'ADD'}
        case ProductsActions.FetchCurrentCategories:
            return {...state, currentCategory: payload}
        case ProductsActions.ActiveProducts:
            return {...state, activationMessage: payload}
        case ProductsActions.FetchProductCategory:
            return {...state, productCategories: payload}
        case ProductsActions.FetchProductProps:
            return {...state, productFetchBody: payload}
        case ProductsActions.FetchReviewedProductsByCategory:
            return {...state, reviewedProductsByCategory: payload}
        case ProductsActions.FetchProductById:
            return {...state, oneProduct: payload}

        case ProductsActions.FetchProductsFilterList:
            return {...state, filterProduct: action?.payload, error: undefined}
        case ProductsActions.ResetProductFilterList:
            return {...state, filterProduct: [], error: undefined}

        default:
            return state
    }
}

const initialState = {
    productFormStatus: 'Close',
    currentProduct: '',
    products: {},
    editProduct: '',
    readProduct: '',
    deleteId: '',
    categories: [],
    currentCategory: [],
    activationMessage: undefined,
    productCategories: [],
    productFetchBody: {},
    reviewedProductsByCategory: [],
    oneProduct: null,
    filterProduct: []
}
export default productReducer
