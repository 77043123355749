import React, {useEffect} from 'react'
import {connect, useSelector} from 'react-redux'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
// import {useHistory} from "react-router-dom";
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import {FormHelperText} from '@mui/material'
import {useNavigate} from 'react-router'
import {authLogin} from '../../actions'
import DarkModeSwitch from '../../components/public/DarkModeSwitch'
import CopyRight from '../../components/public/CopyRight'
import LanguageSelect from '../../components/public/LanguageSelect'
import {Background} from './Background'
import video from '../../assets/video/itlab-back-video.mp4'
import {darkTheme} from '../../theme'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh'
    },
    image: {
        // backgroundImage: `url()`, //set main bg img
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    link: {
        color: theme.palette.primary.main
    },
    switchGrid: {
        padding: '.5rem 1rem'
    }
}))

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(6).required()
})

function LoginView({authLogin, auth}) {
    const classes = useStyles()
    const navigate = useNavigate()
    const [t] = useTranslation(['translation', 'Auth'])

    const {register, errors, handleSubmit, setValue, setError} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        defaultValues: {
            email: '',
            password: ''
        }
    })

    const handleSignin = data => {
        authLogin(data, null, handleError)
    }
    const handleError = () => {
        setError('login', {type: 'manual', message: 'Login Failed: Your email or password is incorrect!'})
    }
    const curTheme = useSelector(state => state?.userSetting?.darkMode)
    // const [MaskColor,setMaskColor] = useState( darkTheme.palette.secondary.main)
    const MaskColor = darkTheme.palette.secondary.main
    useEffect(() => {
        if (auth.success !== undefined) {
            // only for init value, delete when public
            if (auth.success) {
                navigate('/app/')
            } else {
                setValue('password', '')
            }
        }
    }, [auth.success, auth.responseAt, setValue])

    return (
        <Background
            url={video}
            type="video"
            endOpacity="0.38"
            maskColor={MaskColor}
            makeStyle={
                !curTheme && {
                    background:
                        'linear-gradient(180deg, rgba(2, 11, 34, 0.8) 0%, rgba(16, 50, 141, 0.76) 38%, rgba(135, 154, 206, 0.7) 100%)'
                }
            }
        >
            <Grid container component="main" className={classes.root} justifyContent="flex-end">
                <CssBaseline />
                {/* <Grid item xs={false} sm={4} md={7} className={classes.image}/> */}
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.switchGrid}
                    >
                        <Grid item>
                            <LanguageSelect isShowLabel />
                        </Grid>
                        <Grid item>
                            <DarkModeSwitch />
                        </Grid>
                    </Grid>
                    <div className={classes.paper}>
                        <Typography variant="h5">{t('Auth:Welcome To LMS')}</Typography>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {t('Auth:Login')}
                        </Typography>
                        <FormHelperText
                            error={!!errors?.login?.message}
                            style={{fontSize: '1rem', textAlign: 'center'}}
                        >
                            {errors?.login?.message}
                        </FormHelperText>
                        <form className={classes.form} noValidate onSubmit={handleSubmit(handleSignin)}>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                inputRef={register}
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                error={Boolean(errors?.email?.message)}
                                helperText={errors?.email?.message}
                            />
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                inputRef={register}
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="password"
                                error={Boolean(errors?.password?.message)}
                                helperText={errors?.password?.message}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                {t('Auth:Login')}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link
                                        href="#"
                                        onClick={() => {
                                            navigate('/auth/forgot')
                                        }}
                                        color="inherit"
                                    >
                                        {t('Auth:Forgot password')}?
                                    </Link>
                                </Grid>
                            </Grid>

                            <Box mt={5}>
                                <CopyRight />
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </Background>
    )
}

export default connect(({auth}) => ({auth}), {authLogin})(LoginView)
