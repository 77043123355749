import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Grid, IconButton, Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip, Typography
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import { sendErrorMessage, sendSuccessMessage } from '../../actions';
import AddCategoryInput from '../form/CategoryInput';
import ResponsiveDialog from '../dialog/ResponsiveDialog';
import LoadingScreen from '../public/LoadingScreen';
import { getBasicHash } from '../../utils/Scripts';

export const useNotesStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '800px',
    margin: '0 auto'
  },
  paper: {
    padding: '2rem',
    '& h2': {
      marginBottom: '2rem'
    }
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  marginLeft: {
    marginLeft: '2rem',
  },
  notePaper: {
    maxHeight: '50vh'
  },
  notePaperAdd: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  }
}))

function ContractNotesController({ nid, allNotes,
                                   fetchNotesAction, createNotesAction, deleteNotesAction,
                                   isModalOpen, handleModalClose
}) {

  const dispatch = useDispatch()
  const classes = useNotesStyles()

  const [isLoaded, setIsLoaded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [hash, setHash] = useState(getBasicHash())
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)
  const [selectedForDel, setSelectedForDel] = useState(-1)

  useEffect(() => {
    if (nid) {
      (async () => {
        setIsLoaded(fetchNotesAction(nid)(dispatch))
      })()
    } else {
      setIsLoaded(false)
    }
  }, [nid, dispatch])

  const handleDeleteOpen = (id) => {
    setSelectedForDel(id)
    setOpenDeleteDialogue(true)
    setHash(getBasicHash())
  }

  const handleModalCloseBuffer = () => {
    setIsLoaded(false)
    handleModalClose()
  }

  const onOperationSuccess = (msg) => {
    setIsSaving(false)
    sendSuccessMessage(dispatch, msg)
  }
  const onOperationError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }

  const onDeleteNote = () => {
    deleteNotesAction(selectedForDel, nid, onOperationSuccess, onOperationError)(dispatch)
    setSelectedForDel(-1)
    setOpenDeleteDialogue(false)
  }

  const onAddCB = (id, notes) => {
    setIsSaving(true)
    const cleanNote = notes.trim()
    // see if name entered
    const hasChanged = cleanNote !== ''
    if (hasChanged) {
      createNotesAction(nid, cleanNote,
        (msg) => onOperationSuccess(msg),
        (msg) => onOperationError(msg))
      (dispatch)
    }
  }

  const renderTable = () =>
    <TableContainer component={Paper} className={classes.notePaper}>
      <Table size="small">

        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>By</TableCell>
            <TableCell>Note</TableCell>
            <TableCell align="right">Delete</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>

          {allNotes.map((note) =>
            <TableRow key={note.id}>
              <TableCell scope="row">
                { moment(note.createdAt).format('L LT') }
              </TableCell>

              <TableCell scope="row">
                { note.user.firstName } { note.user.lastName }
              </TableCell>

              <TableCell scope="row">
                { note.notes }
              </TableCell>

              <TableCell align="right">
                <Tooltip title='Delete' placement='top'>
                  <IconButton color='primary'
                              size='small'
                              component='span'
                              onClick={() => handleDeleteOpen(note.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>

            </TableRow>
          ) }

        </TableBody>
      </Table>
    </TableContainer>

  const addNewNoteDialogue = () =>
    <Paper className={classes.notePaperAdd}>
      <Typography variant='h4'>Add New Note:</Typography>
      <div className={classes.marginLeft}>
        <AddCategoryInput cb={onAddCB} id='' name='' label='Notes'
                          placeholder='Enter notes here' disabled={isSaving}/>
      </div>
    </Paper>

  const renderForm = () =>
    <Grid container>

      <Grid item xs={12}>
        <Typography variant='h3'>Notes</Typography>
      </Grid>
      { isLoaded
        ? <div>
          {allNotes?.length > 0
            ?
            <Grid item xs={12}>
              {renderTable()}
            </Grid>
            : <Typography variant='body1' className={classes.marginTop}>No notes</Typography>
          }

          <Grid item xs={12}>
            { addNewNoteDialogue() }
          </Grid>
        </div>
        : <Grid item xs={12}><LoadingScreen message='Loading...' /></Grid>
      }

    </Grid>

  return (
    <Modal
      open={isModalOpen}
      onClose={() => handleModalCloseBuffer()}
      className={classes.modal}
      disablePortal
      disableRestoreFocus
      disableEnforceFocus
      disableAutoFocus
      closeAfterTransition
    >
      <Paper className={classes.paper}>
        <Container maxWidth="lg">
          { renderForm() }

          <ResponsiveDialog
            isOpen={openDeleteDialogue} openHash={hash}
            title="Delete Note" content="Delete this note?"
            Buttons={
              [
                { name: "Yes", event: () => onDeleteNote() },
                { name: "Cancel", event: () => setSelectedForDel(-1) }
              ]
            }
          />

          <div className={classes.marginBottom} id='emptyDivAtTheBottom' />

        </Container>
      </Paper>
    </Modal>
  )

}

export default ContractNotesController

