import api from "../../apis/api";
import { sendErrorMessage } from '../message/messageActions';
import { pagedGridDefaultFetch } from '../../components/mui/datagrid/PagedGridAction';

export const CampaignActionType = {
  FetchAllCampaigns: "FetchAllCampaigns",
  FetchCampaign: "FetchCampaign",
  FetchCampaignPosters: "FetchCampaignPosters",
  FetchCampaignCategories: "FetchCampaignCategories",
  FetchCampaignUsers: "FetchCampaignUsers",
  FetchCampaignPosterDetails: "FetchCampaignPosterDetails",

  FetchCampaignProps: "FetchCampaignProps",
  FetchCampaignPosterProps: "FetchCampaignPosterProps",
}

export const fetchAllCampaigns = (args) => async (dispatch) => {
  try {
    const res = await pagedGridDefaultFetch('/campaign/fetchAllCampaign',
      args, CampaignActionType.FetchCampaignProps)(dispatch)

    if (res.data?.rs) {
      dispatch({ type: CampaignActionType.FetchAllCampaigns, payload: res.data })
      return true;
    } 
      throw (res.data.message)
    
  } catch (e) {
    // console.log('Cannot fetch campaign: ', e)
    sendErrorMessage(dispatch, 'Cannot fetch campaign')
    return false
  }
}

export const fetchCampaign = (campaignId) => async (dispatch) => {
  try {
    const res = await api.get(`/campaign/main/${campaignId}`)
    if (res.data) {
      if (res.data.rs) {
        // console.log(res.data);
        dispatch({ type: CampaignActionType.FetchCampaign, payload: res.data.data })
        return true
      }
    }
  } catch (e) {
    sendErrorMessage('Campaign fetch failed\n')
    return false
    // console.log('Campaign fetch failed\n', e)
  }
  sendErrorMessage(dispatch, 'Cannot fetch campaign')
  return false
}

export const fetchCampaignPosters = (args) => async (dispatch) => {
  try {
    const res = await pagedGridDefaultFetch(`/campaign/main/${args.campaignId}/posters`,
      args, CampaignActionType.FetchCampaignPosterProps)(dispatch)

    if (res.data?.rs) {
      dispatch({ type: CampaignActionType.FetchCampaignPosters, payload: res.data })
      return true
    }
  } catch (e) {
    sendErrorMessage(dispatch,'Error in Fetch Campaign Posters')
    return false
    // console.log('Campaign poster fetch failed\n', e)
  }
  sendErrorMessage(dispatch, 'Cannot fetch campaign posters')
  return false
}

export const deleteCampaign = (campaignId, fetchProps, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.delete(`/campaign/main/${campaignId}`)
    if (res.data) {
      if (res.data.rs) {
        const refresh = await dispatch(fetchAllCampaigns(fetchProps))
        if (refresh) onSuccess('Campaign Deleted')
      } else {
        onError('Campaign list cannot be refreshed')
      }
    }
  } catch (e) {
    // console.log('Campaign delete error:\n', e)
    onError('Campaign cannot be deleted.')
  }
}

export const changeCampaignStatus = (ids, fetchProps, isActive, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('campaign/updateStatus', { isActive, ids })
    if (res.data) {
      const refresh = await dispatch(fetchAllCampaigns(fetchProps))
      if (refresh) onSuccess(isActive ? 'Campaigns activated' : 'Campaigns disabled')
    }
  } catch (e) {
    // console.log('Campaigns update error:\n', e)
    onError('Campaigns status cannot be changed')
  }
}

export const createCampaign = (formData, onSuccess, onError) => async () => {
  try {
    const res = await api.post('/campaign/main', formData)
    if (res.data && res.data.rs) {
      onSuccess(res.data)
    } else {
      onError(res.data.message)
    }
  } catch (e) {
    // console.log('Campaign cannot be created\n', e)
    onError(e.message)
  }
}

export const updateCampaign = (campaignId, formData, onSuccess, onError) => async () => {
  try {
    const res = await api.put(`/campaign/main/${campaignId}`, formData)
    if (res.data && res.data.rs) {
      onSuccess(res.data)
    } else {
      onError(res.data.message)
    }
  } catch (e) {
    // console.log('Campaign cannot be updated\n', e)
    onError(e.message)
  }
}

export const fetchCampaignCategories = () => async (dispatch) => {
  try {
    const res = await api.get(`/campaign/category`)
    if (res.data?.rs) {
      dispatch({
        type: CampaignActionType.FetchCampaignCategories,
        payload: res.data.data
      })
      return true
    }
    sendErrorMessage(dispatch,res.data?.message)
      // console.log(res.data?.message)
      return false
    
  } catch (e) {
    sendErrorMessage(dispatch,'Could not get campaign categories')
    // console.log('Could not get campaign categories\n', e)
  }
  return false
}

export const deleteCampaignCategory = (id, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.delete(`/campaign/category/${id}`)
    if (res.data) {
      const refresh = await dispatch(fetchCampaignCategories())
      if (refresh) {
        if (res.data.rs) {
          onSuccess('Category deleted')
        }
        else onError(`Failed: ${res.data.message}`)
      }
      else onError('Cannot refresh data')
    }
  } catch (e) {
    // console.log('Category delete error:\n', e)
    onError('Category cannot be deleted.')
  }
}

export const updateCampaignCategory = (id, newName, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.put('/campaign/category', { id, name: newName })
    if (res.data) {
      const refresh = await dispatch(fetchCampaignCategories())
      if (refresh) {
        if (res.data.rs) onSuccess('Category Updated')
        else onError(`Failed: ${res.data.message}`)
      }
      else onError('Cannot refresh data')
    }
  } catch (e) {
    // console.log('Category update error:\n', e)
    onError('Category cannot be updated.')
  }
}

export const addCampaignCategory = (name, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post('/campaign/category', { name })
    if (res.data) {
      const refresh = await dispatch(fetchCampaignCategories())
      if (refresh) {
        if (res.data.rs) onSuccess('Category created')
        else onError(`Failed: ${res.data.message}`)
      } else onError('Cannot refresh data')
    }
  } catch (e) {
    // console.log('Category create error:\n', e)
    onError('Category cannot be created.')
  }
}

export const fetchCampaignUsers = () => async (dispatch) => {
  try {
    const res = await api.get('/campaign/users')
    if (res.data) {
      if (res.data.rs) {
        dispatch({ type: CampaignActionType.FetchCampaignUsers, payload: res.data.data })
        return true
      }
    }
  } catch (e) {
    sendErrorMessage(dispatch,'Error in fetchCampaignUsers')
    return false
    // console.log('Failed to fetch users\n', e)
  }
  sendErrorMessage(dispatch, 'Failed to fetch users')
  return false
}

export const createCampaignPoster = (formData, onSuccess, onError) => async () => {
  try {
    const res = await api.post('/campaign/poster', formData)
    if (res.data) {
      if (res.data.rs) {
        return onSuccess('Poster uploaded')
      }
    }
  } catch (e) {
    // console.log('Cannot upload poster', e)
    return onError('Error in create Campaign Poster')
  }
  return onError('Unable to upload poster')
}

export const editCampaignPoster = (formData, onSuccess, onError) => async () => {
  try {
    const res = await api.put('/campaign/poster', formData)
    if (res.data) {
      if (res.data.rs) {
        return onSuccess('Poster updated')
      }
    }
  } catch (e) {
    return onError('Uable to update Poster')
    // console.log('Cannot update poster', e)
  }
  return onError('Unable to update poster')
}

export const fetchCampaignPosterDetails = (cid, pid) => async (dispatch) => {
  try {
    const res = await api.get(`/campaign/poster/${cid}/${pid}`)
    if (res.data) {
      if (res.data.rs) {
        dispatch({ type: CampaignActionType.FetchCampaignPosterDetails, payload: res.data.data })
        return true
      }
    }
  } catch (e) {
    return sendErrorMessage(dispatch,'Error in fetch poster details')
    // console.log('Failed to fetch poster details')
  }
  return false
}

export const deleteCampaignPoster = (cid, pid, fetchProps, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.delete(`/campaign/poster/${cid}/${pid}`)
    if (res.data) {
      if (res.data.rs) {
        await dispatch(fetchCampaignPosters(fetchProps))
        return onSuccess('Poster deleted')
      } 
        return onError(res.data.message)
      
    }
  } catch (e) {
    return sendErrorMessage(dispatch,'Failed to delete poster')
    // console.log('Failed to delete poster')
  }
  return onError('Poster cannot be deleted')
}

export const publicFetchDataForPoster = (cid, pid, uid, source, onSuccess, onError) => async () => {
  try {
    const res = await api.post(`/asset/poster/scan`, { cid, pid, uid, source })
    if (res.data) {
      if (res.data.rs) {
        return onSuccess(res.data.data)
      }
      return onError(res.data.message)
    }
    return onError('Poster does not exist')
  } catch (e) {
    return onError(e.message)
  }
}

export const downloadPosterData = (cid, pid, uid, coords, onSuccess, onError, imgFormat, imgSize, imgWidth, imgHeight) => async (dispatch) => {
  try {
    const res = await api.post(`/campaign/downloadPoster`, { cid, pid, uid })
    const format = imgFormat? `image/${  imgFormat.toLowerCase()}` : undefined
    if (res.data) {
      if (res.data.rs) {
        await dispatch(fetchCampaign(cid))
        return onSuccess(dispatch, { ...res.data.data, coordinates: coords, format, size: imgSize, width: imgWidth, height: imgHeight }, cid, uid)
      }
      return onError(dispatch, res.data.message)
    }
    return onError(dispatch, 'Poster does not exist')
  } catch (e) {
    return onError(dispatch, e.message)
  }
}

export const sendSMS = (data, cid, onSuccess, onError) => async (dispatch) => {
  try {
    const res = await api.post(`/campaign/sendSMS`, data)
    if (res.data?.rs) {
      const refresh = await dispatch(fetchCampaign(cid))
      if (refresh) {
        return onSuccess(res.data?.message || 'SMS sent to users in current campaign')
      }
    }
    return onError(res.data?.message || 'Unable to send SMS')
  } catch (e) {
    return onError(e.message)
  }
}
