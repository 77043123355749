import api from '../../apis/api';
import { sendErrorMessage, sendSuccessMessage } from '..';

export const classActionsType = {
    Register: 'Register',
    actionError: 'Action_Error',
    FetchClasses: 'FetchClasses',
    FetchClassesProps: "FetchClassesProps",
    SetClassTableStatus: 'SetClassTableStatus',
    FetchCurrentClass: 'FetchCurrentClass',
    ActiveClass: 'ActiveClass',
    UpdateClass: 'UpdateClass',
    FetchTeachers: 'FetchTeachers',
    FetchStudents: 'FetchStudents',
    FetchSyllabus: 'FetchSyllabus',
    CreateClass: 'CreateClass',
    CloneCurrentClass:'CloneCurrentClass',
    DeleteClass: 'DeleteClass',
    FetchClassHistory: 'FetchClassHistory',
    FetchOneClass: 'FetchOneClass',
    FetchOneClassHistory: 'FetchOneClassHistory',
    UpdateClassHistory: 'UpdateClassHistory',
    AddClassHistory: 'AddClassHistory',
    DeleteHistory: 'DeleteHistory',
    FetchHomework: 'FetchHomework',
    FetchCurrentHomework: 'FetchCurrentHomework',
    UpdateHomework: 'UpdateHomework',
    AddHomework: 'AddHomework',
    DeleteHomework: 'DeleteHomework',
    FetchOneHomework: 'FetchOneHomework',
    AddClassReview: 'AddClassReview',
    ReadOneClassHistory: 'ReadOneClassHistory',
    UpdateSubmission: 'UpdateSubmission',
    FetchCurrentClassForEdit:'FetchCurrentClassForEdit',
    SetClassViewData: 'SetClassViewData',
    ClearClassViewData: 'ClearClassViewData',
    FetchClassTransferByClassId:'fetchClassTransferByClassId',
    FetchClassTransferByCustomerId:'fetchClassTransferByCustomerId'
};

export const fetchTeachers = () => async dispatch => {
    try {
        const res = await api.post('/classes/teacherList', { 'isActive': true });
        if (res.data.data) {
            dispatch({ type: classActionsType.FetchTeachers, payload: res.data.data });
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Fetch teachers')
    }
    return false
};

export const fetchStudents = () => async dispatch => {
    try {
        const res = await api.post('/classes/studentList', { 'isActive': true });
        if (res.data.data) {
            dispatch({ type: classActionsType.FetchStudents, payload: res.data.data });
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Fetch Students')
    }
    return false
};

export const fetchSyllabus = () => async dispatch => {
    try {
        const res = await api.get('/classes/syllabus/getSyllabus');
        if (res.data.data) {
            dispatch({ type: classActionsType.FetchSyllabus, payload: res.data.data });
        }
        return true
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Fetch Syllabus')
    }
    return false
};





export const createClass = (formValues, success, error) => async (dispatch) => {
    try {
        const res = await api.post('/classes/class', formValues);
        if (res.data) {
            if (res.data.rs) {
                success();
                dispatch({ type: classActionsType.CreateClass, payload: res.data });
                sendSuccessMessage(dispatch, `class has been created`);
                return true;
            }
            sendErrorMessage(dispatch, 'Cannot create class due to name duplication');
            error();
            return false;
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Create Class')
        error();
    }
    return false
};

export const fetchClasses = (args) => async (dispatch) => {
    try {
        let res = null
        const classesUrl = '/classes/fetchCourses'
        if (args?.fetchProps) {
            res = await api.post(classesUrl, args.fetchProps)
        } else if (args?.page) {
            args.isActive = args.isActive === 'all' ? undefined : args.isActive
            dispatch({ type: classActionsType.FetchClassesProps, payload: args })
            res = await api.post(classesUrl, args)
        } else {
            res = await api.post('/classes/fetchClass', { 'isActive': true })
        }

        if (res.data?.rs) {
            dispatch({ type: classActionsType.FetchClasses, payload: res.data })
            return true;
        } 
            throw (res.data.message)
        
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch classes!!')
        return false
    }
};

export const fetchClass = (id) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/class/${id}`);
        if (res.data?.rs) {
            dispatch({ type: classActionsType.FetchCurrentClass, payload: res.data });
            return true
        } 
            sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch class')
        
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch class')
    }
    return false
};

export const fetchCurrentClassForEdit = (id) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/class/${id}`);
        if (res.data?.rs) {
            dispatch({ type: classActionsType.FetchCurrentClassForEdit, payload: res.data });
            return true
        } 
            sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch class')
        
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Fetch Current class for Edit')
    }
    return false
};

export const cloneClass = (id) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/class/${id}`);
        if (res.data?.rs) {
            dispatch({ type: classActionsType.CloneCurrentClass, payload: res.data });
            return true
        } 
            sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch class')
        
    } catch (e) {
        sendErrorMessage(dispatch, 'Error in Clone Class')
    }
    return false
};

export const fetchOneClass = (id) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/class/${id}`,
          { 'isHistoryActive': false, 'isHomeworkActive': true, 'isAnnouncementActive': true });
        if (res.data?.rs) {
            dispatch({ type: classActionsType.FetchOneClass, payload: res.data });
            return true
        } 
            sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch class')
        
    } catch (e) {
        sendErrorMessage(dispatch,'Error in fetch One Class')
    }
    return false
};

export const updateClass = (data, success, error) => async dispatch => {
    try {
        const res = await api.put('classes/class', data);
        if (res.data) {
            if (res.data.rs) {
                success();
                dispatch({ type: classActionsType.UpdateClass, payload: res.data });
                sendSuccessMessage(dispatch, `class has been updated`);
                return true;
            }
            error();
            sendErrorMessage(dispatch, 'Cannot update class due to name duplication');
            return false;
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in update Class')
        error();
    }
    return false
};

export const deleteClass = (id) => async dispatch => {


    try {
        const response = await api.delete(`/classes/class/${id}`);
        if (response.data) {
            dispatch({ type: classActionsType.DeleteClass, payload: id });
            sendSuccessMessage(dispatch, `Class has been deleted.`);
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in DeleteClass')
    }
};

export const homeworkReview = (submitData) => async dispatch => {

    try {
        const response = await api.put(`classes/homeworkSubmission`, submitData);
        if (response.data) {
            if (response.data.rs) {
                dispatch({ type: classActionsType.UpdateSubmission, payload: submitData });
                sendSuccessMessage(dispatch, `homework has been finished.`);
                return true;
            }
            return false;
        }
    } catch (e) {
        sendErrorMessage(dispatch, 'Error in Home Work Review')
    }
    return false;
};


export const fetchClassHistory = (id) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/history/${id}`, { 'isActive': true });
        if (res.data?.rs) {
            dispatch({ type: classActionsType.FetchClassHistory, payload: res.data })
            return true
        } 
            sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch class history')
        
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Fetch Class History')
    }
    return false
};

export const fetchOneClassHistory = (classId, historyId) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/onehistory/${classId}`, { 'isActive': true, 'historyId': historyId });
        if (res.data?.rs) {
            dispatch({ type: classActionsType.FetchOneClassHistory, payload: res.data });
            return true
        } 
            sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch class history')
        
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Fetch One Class History')
    }
    return false
};

export const readOneClassHistory = (classId, historyId) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/onehistory/${classId}`, { 'isActive': true, 'historyId': historyId });
        if (res.data?.rs) {
            dispatch({ type: classActionsType.ReadOneClassHistory, payload: res.data });
            return true
        } 
            sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch class history')
        
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Read One Class History')
    }
    return false
};
export const addClassHistory = (formValue, onSuccess, onError) => async (dispatch) => {

    try {
        const res = await api.post(`/classes/history`, formValue);
        if (res.data.rs) {
            await fetchOneClass(formValue.classId)(dispatch)
            // dispatch({ type: classActionsType.AddClassHistory, payload: res.data });
            return onSuccess('add delivery successfully');
        }
        return onError(res.data.message);
    } catch (e) {
        return onError(e.message);
    }
};

export const updateClassHistory = (formValue, onSuccess, onError) => async (dispatch) => {
    try {
        const res = await api.put(`/classes/history`, formValue);
        if (res.data.rs) {
            await fetchOneClass(formValue.classId)(dispatch)
            // dispatch({ type: classActionsType.UpdateClassHistory, payload: res.data });
            return onSuccess('update delivery successfully');
        }
        return onError(res.data.message);
    } catch (e) {
        return onError(e.message);
    }
};

export const deleteHistory = (id) => async dispatch => {

    try {
        const response = await api.delete(`/classes/history/${id}`);
        if (response.data) {
            dispatch({ type: classActionsType.DeleteHistory, payload: id });
            sendSuccessMessage(dispatch, `History has been deleted.`);
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in delete History')
    }
};

export const fetchHomework = (classId) => async (dispatch) => {

    try {
        const res = await api.post(`/classes/fetchHomework/${classId}`, { 'isActive': true })
        if (res.data?.rs) {
            dispatch({ type: classActionsType.FetchHomework, payload: res.data });
            return true
        } 
            sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch homework')
        
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Fetch Homework')
    }
    return false
}

export const fetchCurrentHomework = (classId, homeworkId) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/fetchOneHomework/${classId}`, { 'isActive': true, homeworkId });
        if (res.data?.rs) {
            dispatch({ type: classActionsType.FetchCurrentHomework, payload: res.data })
            return true
        } 
            sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch homework')
        
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Fetch Current Homework')
    }
    return false
}

export const fetchOneHomework = (classId, homeworkId) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/fetchOneHomework/${classId}`, { 'isActive': true, homeworkId });
        if (res.data?.rs) {
            dispatch({ type: classActionsType.FetchOneHomework, payload: res.data });
            return true
        } 
            sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch homework')
        
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Fetch One Homework')
    }
    return false;
}

export const updateHomework = (formValue,isSendEmail,isSendSMS,testMode,messageData) => async (dispatch) => {
    try {
        const res = await api.put(`/classes/homework`, formValue);

        let result={result:false}
        let result2={result:false}
        if(isSendEmail){
          result= await sendEmailHomework(messageData)
        }
        if(isSendSMS){
            const messageNewData={...messageData,isOnDebug:testMode}
            result2= await sendSMSHomework(messageNewData);
        }
        if(isSendEmail&&!isSendSMS){
            if(result.result){
                sendSuccessMessage(dispatch,result.message)
                dispatch({ type: classActionsType.UpdateHomework, payload: res.data });
            }
            else {
                sendErrorMessage(dispatch,result.message);
            }
        }
        if(isSendSMS&&!isSendEmail){
            if(result2.result){
                sendSuccessMessage(dispatch,result2.message)
                dispatch({ type: classActionsType.UpdateHomework, payload: res.data });
            }
            else {
                sendErrorMessage(dispatch,result2.message)
            }
        }
        if(isSendSMS&&isSendEmail){
            if(result.result&&result2.result){
                sendSuccessMessage(dispatch,result.message)
                sendSuccessMessage(dispatch,result2.message)
                dispatch({ type: classActionsType.UpdateHomework, payload: res.data });
            }
            else {
                sendErrorMessage(dispatch,result.message);
                sendErrorMessage(dispatch,result2.message)
            }
        }
        if(!isSendSMS&&!isSendEmail){
            dispatch({ type: classActionsType.UpdateHomework, payload: res.data });
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in update Homework')
    }
};
export const addHomework = (formValue,isSendEmail,isSendSMS,testMode,messageData) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/homework`, formValue);
        let result={result:false}
        let result2={result:false}
        if(isSendEmail){
            result= await sendEmailHomework(messageData)
        }
        if(isSendSMS){
            const messageNewData={...messageData,isOnDebug:testMode}
            result2= await sendSMSHomework(messageNewData);
        }
        if(isSendEmail&&!isSendSMS){
            if(result.result){
                sendSuccessMessage(dispatch,result.message)
                dispatch({ type: classActionsType.AddHomework, payload: res.data });
            }
            else {
                sendErrorMessage(dispatch,result.message)
            }
        }
        if(isSendSMS&&!isSendEmail){
            if(result2.result){
                sendSuccessMessage(dispatch,result2.message)
                dispatch({ type: classActionsType.AddHomework, payload: res.data });
            }
            else{
                sendErrorMessage(dispatch,result2.message)
            }
        }
        if(isSendSMS&&isSendEmail){
            if(result.result&&result2.result){
                sendSuccessMessage(dispatch,result.message)
                sendSuccessMessage(dispatch,result2.message)
                dispatch({ type: classActionsType.AddHomework, payload: res.data });
            }
            else{
                sendErrorMessage(dispatch,result.message);
                sendErrorMessage(dispatch,result2.message)
            }
        }
        if(!isSendSMS&&!isSendEmail){
            dispatch({ type: classActionsType.AddHomework, payload: res.data });
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in add Homework')
    }
};

export const deleteHomework = (id) => async dispatch => {

    try {
        const response = await api.delete(`/classes/homework/${id}`);
        if (response.data) {
            dispatch({ type: classActionsType.DeleteHomework, payload: id });
            sendSuccessMessage(dispatch, `Homework has been deleted.`);
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error in Delete Homework')
    }
};

export const addClassReview = (formValue) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/historyFeedback`, formValue);
        dispatch({ type: classActionsType.AddClassReview, payload: res.data });
        sendSuccessMessage(dispatch, `Review has been created.`);
    } catch (e) {
        sendErrorMessage(dispatch,'Error in add Class Review')
    }
};

export const activateClass = (obj, fetchProps) => async dispatch => {
    try {
        const res = await api.post('classes/updateStatus', obj);
        const data = res.data?.rs;
        if (data) {
            const refresh = await dispatch(fetchClasses(fetchProps));
            if (refresh) {
                dispatch({ type: classActionsType.ActiveClass, payload: res.data });
                if (obj.isActive) {
                    sendSuccessMessage(dispatch, `ID: ${obj.ids} have been activated`);
                } else {
                    sendSuccessMessage(dispatch, `ID: ${obj.ids} have been deactivated`);
                }
            }
        } else {
            sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch class')
            // console.log(res.data?.message || 'Cannot fetch class')
        }
        // console.log(res.data);
    } catch (e) {
        sendErrorMessage(dispatch,'Error in activate Class')
        // console.log(e);
    }
};

export const sendSMS = (data, classId, homeworkId, onSuccess, onError) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/sendSMS`, data);
        if (res.data?.rs) {
            const refresh = await dispatch(fetchOneHomework(classId, homeworkId));
            if (refresh) {
                return onSuccess(res.data?.message || 'SMS sent to students');
            }
        }
        return onError(res.data?.message || 'Unable to send SMS');
    } catch (e) {
        return onError(e.message);
    }
};

export const sendEmail = (data, classId, homeworkId, onSuccess, onError) => async (dispatch) => {
    try {
        const res = await api.post(`/classes/sendEmail`, data);
        if (res.data?.rs) {
            const refresh = await dispatch(fetchOneHomework(classId, homeworkId));
            if (refresh) {
                return onSuccess('Sent email to student');
            }
        }
        return onError('Unable to send email');
    } catch (e) {
        return onError(e.message);
    }
};

export const sendEmailHomework=async (data)=>{
    try {
      const res =await api.post('/classes/sendNewHomeworkEmail',data)
      if(res.data?.rs){
          return  ({result:true,message:`Email ${res.data.message}`})
      }
      
          return  ({result:false,message:`${res.data.message}`})
      
    }
    catch (e) {
        // console.log('errors from sending email',e)
      return ({result:false,message:'Unable to send Email message'})
    }
}


export const sendSMSHomework=async (data)=>{
    try{
       const res= await api.post('/classes/homework/sendSMS',data)
       if(res.data?.rs){
        return ({result:true,message:`SMS ${res.data.message}`})
       }
       return ({result:false,message:`${res.data.message}`})
    }
    catch (e) {
        // console.log('error from sending SMS',e)
        return ({result:false,message:'Unable to send SMS message'})
    }
}

export const setClassViewData = (formViewData) => async (dispatch) => {
    dispatch({ type: classActionsType.SetClassViewData, payload: formViewData })
}

export const clearClassViewData = () => async (dispatch) => {
    dispatch({ type: classActionsType.ClearClassViewData })
}

export const addClassTransferRecord=async (data)=>{
    try {
      const res =await api.post(`/classes/addClassTransferRecord`,data)
      if(res.data?.rs){
          return  (res.data)
      }
      
          return  (res)
      
    }
    catch (e) {
        // console.log('errors from sending email',e)
        return false
    }
}

export const fetchClassTransferByClassId = (id) => async (dispatch) =>{
    try {
      const res =await api.post(`/classes/fetchClassTransferByClassId/${id}`)
      if(res?.data){
        dispatch({ type: classActionsType.FetchClassTransferByClassId, payload: res?.data });
        return true;
      }

    }
    catch (e) {
        sendErrorMessage(dispatch,'errors fetch Class Transfer By ClassId',)

    }
    return false
}

export const fetchClassTransferByCustomerId = (id) => async (dispatch) =>{
    try {
      const res =await api.post(`/classes/fetchClassTransferByCustomerId/${id}`)
      if(res?.data){
        dispatch({ type: classActionsType.FetchClassTransferByCustomerId, payload: res?.data });
        return true;
      }

    }
    catch (e) {
        sendErrorMessage(dispatch,'error in fetch Class Transfer By CustomerId',)
        // console.log('errors from sending email',e)

    }
    return false
}

export const transferStudent = (data, success, error) => async dispatch => {
    try {
        const res = await api.put('classes/transferStudent', data);
        if (res.data) {
            if (res.data.rs) {
                success();
                dispatch({ type: classActionsType.UpdateClass, payload: res.data })
                sendSuccessMessage(dispatch, res.data.message);
                return true;
            }
            error();
            sendErrorMessage(dispatch, res.data.message);
            return false;
        }
    } catch (e) {
        sendErrorMessage(dispatch,'Error transferStudent')
        error();
        return false
    }
    return false
};
