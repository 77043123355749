import React from 'react';
import {Button, SvgIcon} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {PlusCircle as PlusIcon} from "react-feather";
import useCurrentViewCheck from "../../../hooks/useCurrentViewCheck";
import {Functions} from "../../../utils/Functions";
import ViewHead from "../../../components/public/ViewHead";
import {SystemSettingsActionTypes as SettingsActionTypes} from "../../../actions";
import SystemParamsDataGrid from "./SystemParamsDataGrid";
import AddSysParamForm from "./AddSysParamForm";
import EditSysParamForm from "./EditSysParamForm";


function Index() {
    useCurrentViewCheck(Functions.System_Management_System_Setting);

    const dispatch = useDispatch();
    const setting = useSelector(state=>state.system.setting)

    const renderTopButton = () => (
            <Button color="secondary" variant="contained" onClick={() => {
                dispatch({type: SettingsActionTypes.SetSettingFormStatus, payload: "ADD"})
            }}
                    startIcon={
                        <SvgIcon fontSize="small">
                            <PlusIcon/>
                        </SvgIcon>
                    }
            >
                Add Parameter
            </Button>
        )
    const renderDataTable =()=>{
        if(setting.Action==='Ready'){
            return <SystemParamsDataGrid/>
        }if(setting.Action==="ADD"){
            return <AddSysParamForm/>
        }if(setting.Action==="EDIT"){
            return <EditSysParamForm/>
        }
        return <div />

    }
    return (
        <ViewHead functionId={Functions.System_Management_System_Setting} topButtons={renderTopButton()}>
            {renderDataTable()}
        </ViewHead>
    );
}

export default Index;