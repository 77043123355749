import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchFormById, fetchFormResults } from '../../../../../actions/forms/formActions';
import { POSTER_PLACEHOLDER } from '../../posterGenHelper';
import LXGrid from '../../../../../components/mui/datagrid/XGrid';
import LoadingScreen from '../../../../../components/public/LoadingScreen';
import { sendErrorMessage } from '../../../../../actions';

function FormSelectorView({ formId, selectedId, onComplete }) {

  const dispatch = useDispatch()
  const currentForm = useSelector(state => state.forms.currentForm)
  const currentFormResults = useSelector(state => state.forms.currentFormResults)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isFormLoaded, setIsFormLoaded] = useState(null)
  const [isFormResultLoaded, setIsFormResultLoaded] = useState(null)
  const [selectedSubmission, setSelectedSubmission] = useState(selectedId)

  const [formResults, setFormResults] = useState({})
  const [tableHeaders, setTableHeaders] = useState([])
  const [headerMapping, setHeaderMapping] = useState({})

  const loadData = async () => {
    setIsFormLoaded(await fetchFormById(formId)(dispatch))
    setIsFormResultLoaded(await fetchFormResults(formId)(dispatch))
  }
  useEffect(loadData, [dispatch, formId])

  useEffect(() => {
    if (currentForm.id === formId) {
      if (isFormLoaded !== null && isFormResultLoaded !== null) {
        if (isFormLoaded && isFormResultLoaded) {
          setIsLoaded(true)
          filterHeader()
        } else {
          sendErrorMessage(dispatch, "Form data cannot be loaded")
        }
      }
    }
  }, [formId, currentForm, isFormLoaded, isFormResultLoaded, dispatch])

  const onSubmit = () => {
    const selected = formResults.filter(res => selectedSubmission.includes(res.id))
    onComplete({ selected, headers: headerMapping, form: currentForm })
  }

  const filterHeader = () => {


    const tableHeadersInner = []
    const usableTags = []
    const usableComponents = []
    const rowData = []

    // Fetch all the applicable tags
    const wantedTags = Object.keys(POSTER_PLACEHOLDER)
    if (currentForm.components) {
      currentForm.components.forEach(formComponent => {
        if (wantedTags.includes(formComponent?.tag)) {
          usableTags.push(POSTER_PLACEHOLDER[formComponent.tag].header)
        }
      })
    }

    // Filter the current form components down to only the usable tags
    const headerMap = currentForm.components.reduce((res, cur) => {
      if (usableTags.includes(cur.tag)) {
        res[cur.name] = { ...POSTER_PLACEHOLDER[cur.tag] }
        usableComponents.push(cur.name)
        tableHeadersInner.push({ ...POSTER_PLACEHOLDER[cur.tag], componentName: cur.name })
      }
      return res
    }, {})

    // Flatten for display in XGrid
    currentFormResults.forEach(submission => {
      const currentSubmissionFilter = submission.results.filter(result =>
        usableComponents.includes(result.name)
      )
      if (currentSubmissionFilter.length > 0) {
        const tempResult = {
          id: submission.id,
          createdAt: submission.createdAt
        }
        currentSubmissionFilter.forEach(curSub =>{ tempResult[curSub.name] = curSub.value})
        rowData.push(tempResult)
      }
    })

    // Save data
    setHeaderMapping(headerMap)
    setTableHeaders(tableHeadersInner)
    setFormResults(rowData.reverse())
  }

  const renderGrid = () => {
    const columns = []

    columns.push({ field: 'id', headerName: 'ID', width: 80 })
    tableHeaders.forEach(header => columns.push({ field: header.componentName, headerName: header.name, width: 200 }))
    columns.push({ field: 'createdAt', headerName: 'Date', width: 200,
      valueFormatter: ({ value }) => moment(value).format('L LT') })

    return <LXGrid rows={formResults}
                   columns={columns}
                   loading={!isLoaded}
                   showToolbar
                   checkboxSelection
                   disableSelectionOnClick
                   selectionModel={selectedSubmission}
                   onSelectionModelChange={(newSelection) => setSelectedSubmission(newSelection)}
    />
  }

  const renderView = () =>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h4'>Select the form submissions to use</Typography>
      </Grid>

      <Grid item xs={12}>
        { renderGrid() }
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <Button color='primary'
                    variant='contained'
                    disabled={selectedSubmission.length < 1}
                    onClick={() => onSubmit()}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  return (
    <div>
      {isLoaded
        ? renderView()
        : <LoadingScreen message='Loading...' />
      }
    </div>
  )
}

export default FormSelectorView
