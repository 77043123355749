import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import {useHistory} from "react-router-dom";
import MuiAlert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import {Snackbar} from "@mui/material";
import {SnackbarProvider, useSnackbar} from "notistack";
import { useNavigate } from 'react-router';
import {readMessage,ReadMessageTypes} from "../../actions";
import LocalStorage from "../../utils/LocalStorage";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: "2rem",
        },
    },
}));

function BaseMessageComponent() {
    const dispacth = useDispatch()
    const message = useSelector(state=>state?.message)
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const handleClose = (type, id) => {
        setTimeout(() => {
            readMessage(type, id)(dispacth)
        }, 200)
        setOpen(false);
    };


    useEffect(() => {


        const pushMessage = () => {
            if (message.message && message.message.length > 0) {
                for (let i = 0; i < message.message.length; i+=1) {
                    //
                    // switch(message.message[i].errorType){
                    //     case ErrorActionTypes.NotAcceptable:
                    //     case ErrorActionTypes.UnknownError:
                    //     case ErrorActionTypes.InternalServerError:
                    //         break;
                    //     case ErrorActionTypes.NetworkError:
                    //         navigate('/error');
                    //         return;
                    //     case ErrorActionTypes.NotFound:
                    //     case ErrorActionTypes.Unauthorized:
                    //         navigate('404');
                    //         return;
                    //     default:
                    // }
                    enqueueSnackbar(message.message[i].message, {
                        anchorOrigin: {
                            horizontal: "right", vertical: "bottom"
                        },
                        variant: message.message[i].type,
                    })
                    readMessage(ReadMessageTypes.ReadMessage, message.message[i].id)(dispacth);
                }
            }
        }
        setOpen(true);
        pushMessage();
    }, [enqueueSnackbar, message, readMessage])

    const renderCenter = () => {
        if (message.error && message.error.length > 0) {
            return renderError(message.error[0])
        }
            return <div />
        

    }
    const handleCloseError = (error) => {
        handleClose(ReadMessageTypes.ReadError, error.id)
        if (error.isNeedGoErrorPage) {
            LocalStorage.removeItem("token");
            navigate('/auth/login');
        }
    }
    const renderError = (error) => (
            <Snackbar open={open} classes={{root: classes.root}}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={() => {
                    handleCloseError(error)
                }} severity="error">
                    <b>{error.error}</b> - {error.message}
                </Alert>
            </Snackbar>
        )


    return (
        <div style={{zIndex: 10000}}>

            {renderCenter()}

        </div>
    );
}

// const mapStateToProps = ({message}) => ({message})

// const MessageComponent = connect(mapStateToProps,
//     {readMessage}(BaseMessageComponent);

const MessageComponent = BaseMessageComponent

function SystemMessage() {
    return (
        <SnackbarProvider maxSnack={3}>
            <MessageComponent/>
        </SnackbarProvider>
    )
}

export default SystemMessage
