import React, {useEffect, useState} from 'react';
import { ClickAwayListener, MenuItem, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {capitalize} from "../../utils/FormData";
import api from "../../apis/api";


const useStyles = makeStyles(() => ({
    autoSelects: {
        position: 'absolute',
        zIndex: 1300,
        top: 60,
        left: 0,
    },
    optionPaper: {
        marginTop: ".5rem",
        padding: ".6rem 0",
    },

}))


function AddressAutoComplete({name="address",register, handleAddressCallBack, error, className, TextComponent,currentAddress,...rest}) {
    const [searchKey, setSearchKey] = useState("")
    const [debouncedKey, setDebouncedKey] = useState("")
    const [result, setResult] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [address, setAddress] = useState(currentAddress);
    const [isSearch, setIsSearch] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        // todo check
        if (!searchKey && searchKey.length < 1) {
            return false
        }
            const timeid = setTimeout(() => {
                setIsSearch(()=>true);
                setDebouncedKey(searchKey)

            }, 100)
            return () => {
                clearTimeout(timeid);
            }
        
    }, [searchKey])

    useEffect(()=>setAddress(currentAddress),[currentAddress])

    const sendSearch = () => {

        if (debouncedKey.trim().length < 2 || !isSearch) {
            setResult([])
            return;
        }
        if (result && result.find(item => item.address === debouncedKey)) {
            return;
        }
        const input = debouncedKey.trim().replace(/ /g, '+');

        // const header=new Headers()

        api.get('/google/predictions', {
             params:{
                 search:input
             }
        }).then(response => {
            setResult(response.data);
            setIsSearch(false);
        })

    }

    useEffect(() => {
        if (result.length > 0) {
            setIsOpen(true)

        } else {
            setIsOpen(false);
        }
    }, [result])
    useEffect(sendSearch, [debouncedKey])



    const handleInputKeyUp = (event) => {
        setAddress(capitalize(event.target.value))
        setSearchKey(capitalize(event.target.value))
    }
    const handleSelectItem = (value) => () => {
        if (value && value.address) {
            setIsOpen(false);
            setAddress(capitalize(value.address));
            handleAddressCallBack(value)
        }
    }


    return (
        <ClickAwayListener onClickAway={() => {
            setIsOpen(false);
        }}>
            <div style={{position: 'relative'}}>
                <TextComponent fullWidth name={name} id={name}
                    margin='normal' size="small" inputRef={register}
                    error={!!error} className={className}
                    value={address || ''} onChange={handleInputKeyUp}
                    {...rest} autoComplete="off"
                />

                {isOpen ? <Paper className={classes.autoSelects}>
                    {result.length > 0 && result.map(item => (
                            <MenuItem key={item.key} id={item.key}
                                      onClick={handleSelectItem(item)}>{item.title}</MenuItem>
                        ))}
                </Paper>:null
                }

            </div>
        </ClickAwayListener>
    );
}

export default AddressAutoComplete;
