import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, Paper} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import TextField from '@mui/material/TextField'
import * as yup from 'yup'
import {red} from '@mui/material/colors'
import moment from 'moment'
import Switch from '@mui/material/Switch'
import SubmitButton from '../../../components/mui/button/SubmitButton'
import {addHomework, classActionsType, updateHomework} from '../../../actions/class/classActions'
import ViewHead from '../../../components/public/ViewHead'
import {Functions} from '../../../utils/Functions'
import {MAX_TEXT_LENGTH} from '../../../constants'
import MyCkEditor from '../../../components/ckeditor/MyCkEditor'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginBottom: 10
    },
    paper__form: {
        padding: '1rem 1rem',
        margin: '0 auto'
    },
    form__title: {
        marginBottom: '.5rem'
    },
    redCheckBox: {
        color: red[400],
        '&checked': {
            color: red[600]
        }
    },
    text: {
        cursor: 'default'
    },
    textField: {
        marginBottom: '1rem'
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2)
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}))
function AddHomework({edit, oneClass, currentHomework, addHomework, updateHomework}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [requirements, setRequirements] = useState(edit ? currentHomework[0].requirements : '')

    const schema = yup.object().shape({
        extension: yup
            .string()
            .required('Required')
            .min(2, 'homework Id must be at least 2 characters')
            .max(30, 'homework Id must be less than 30 characters')
            .trim(),
        name: yup
            .string()
            .required('Required')
            .min(2, 'homework name must be at least 2 characters')
            .max(30, 'homework name must be less than 30 characters')
            .trim()
    })

    const homework = {
        extension: edit ? currentHomework[0].extension : '',
        name: edit ? currentHomework[0].name : ''
    }

    const {register, errors, handleSubmit, setError} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        defaultValues: homework
    })
    const [dueDate, setDueDate] = React.useState('')

    // const [isSaving, setIsSaving] = useState(false);
    const isSaving = false
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: false
    })
    const handleChange = event => {
        setState({...state, [event.target.name]: event.target.checked})
    }

    useEffect(() => {
        if (edit) {
            setDueDate(moment(currentHomework[0].dueDate).format().substring(0, 16))
        } else {
            setDueDate(moment().add(24, 'hours').format().substring(0, 16))
        }
    }, [])

    const handleChangeStart = event => {
        setDueDate(event.target.value)
    }
    const handleSaveSubmit = formData => {
        if (dueDate === '') {
            setError('dueDate', {type: 'manual', message: 'Please select dueDate'})
            return
        }
        if (moment(dueDate).format() < moment(Date()).format()) {
            setError('dueDate', {type: 'manual', message: 'dueDate should be later than now'})
            return
        }
        if (requirements === '') {
            setError('requirements', {type: 'manual', message: 'requirement could not be null'})
            return
        }

        if (requirements.length > MAX_TEXT_LENGTH) {
            // console.log('this is requirements',requirements)
            setError('requirements', {type: 'manual', message: 'requirement should be within 50000 characters'})
            return
        }

        const classId = oneClass.data.id
        const studentId = oneClass.data.students.map(item => item.id)
        const {checkedA, checkedB, checkedC} = state
        const isSendEmail = checkedB
        const isSendSMS = checkedA
        const testMode = checkedC
        const message = 'Please Read the New Homework'
        const messageData = {id: classId, users: studentId, message}
        const formValue = {...formData, requirements, dueDate, classId, alertBySMS: false, alertByEmail: false}
        if (edit) {
            const homeworkId = currentHomework[0].id
            updateHomework({...formValue, homeworkId}, isSendEmail, isSendSMS, testMode, messageData)
        } else {
            addHomework(formValue, isSendEmail, isSendSMS, testMode, messageData)
        }
    }

    const renderButton = () => (
        <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => {
                dispatch({type: classActionsType.SetClassTableStatus, payload: 'READ'})
            }}
        >
            Back To Delivery
        </Button>
    )

    return (
        <ViewHead
            functionId={
                edit
                    ? Functions.Training_Management_Homework_Information_Add_Homework
                    : Functions.Training_Management_Homework_Information_Edit_Homework
            }
            topButtons={renderButton()}
            noMaxWidth
        >
            <Grid container spacing={2}>
                <Grid item>
                    <Paper className={classes.paper__form}>
                        <form noValidate onSubmit={handleSubmit(handleSaveSubmit)}>
                            <Grid container spacing={1} justifyContent="space-between">
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        margin="normal"
                                        name="extension"
                                        id="extension"
                                        label="DCID *"
                                        autoFocus
                                        variant="outlined"
                                        inputRef={register}
                                        className={classes.text}
                                        error={Boolean(errors.extension?.message)}
                                        helperText={errors.extension?.message}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        margin="normal"
                                        name="name"
                                        id="name"
                                        label="Name *"
                                        variant="outlined"
                                        inputRef={register}
                                        className={classes.text}
                                        error={Boolean(errors.name?.message)}
                                        helperText={errors.name?.message}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MyCkEditor
                                        data={requirements}
                                        onChange={(evt, editor) => {
                                            setRequirements(editor.getData())
                                        }}
                                        name="requirements"
                                    />

                                    <FormHelperText error={Boolean(errors.requirements?.message)}>
                                        {errors.requirements?.message ? errors.requirements?.message : ''}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <br />
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        size="small"
                                        id="start"
                                        label="DueDay"
                                        type="datetime-local"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={handleChangeStart}
                                        value={dueDate}
                                        error={Boolean(errors.dueDate?.message)}
                                        helperText={errors.dueDate?.message}
                                    />
                                </Grid>
                                <FormGroup column="true">
                                    <FormControlLabel
                                        control={
                                            <Switch checked={state.checkedC} onChange={handleChange} name="checkedC" />
                                        }
                                        label="Test Mode"
                                    />
                                    <FormHelperText>SMS will actually be sent when switched off</FormHelperText>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.checkedA}
                                                onChange={handleChange}
                                                name="checkedA"
                                                color="primary"
                                            />
                                        }
                                        label="Send Message by SMS"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.checkedB}
                                                onChange={handleChange}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Send Email to Student"
                                    />
                                </FormGroup>
                            </Grid>
                            <br />
                            <br />
                            <br />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <SubmitButton
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        isSaving={isSaving}
                                        text="Save"
                                        isSavingText="Saving"
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            dispatch({type: classActionsType.SetClassTableStatus, payload: 'READ'})
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </ViewHead>
    )
}

const mapStateToProps = () =>
    // console.log('state', state)
    ({})

export default connect(mapStateToProps, {updateHomework, addHomework})(AddHomework)
