import axios from 'axios';
import {LocalStorageItems, ServerBaseURL} from "../constants";
import LocalStorage from "../utils/LocalStorage";
import {axiosErrorInterceptor} from "../actions";

const token = LocalStorage.getItem(LocalStorageItems.Token);
export const apiConfig = {
    // returnRejectedPromiseOnError: true,
    timeout: 30000,
    baseURL: ServerBaseURL,
    headers: {
        common: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            Pragma: "no-cache",
            Accept: "application/json",
        },
        authorization : `Bearer ${  token}`
    }
}

const api = axios.create(apiConfig);
export const api2 = axios.create({
    baseURL: "http://localhost:3000"
})


// call from index
export const setInterceptor = (axiosProps,store) => {

    axiosProps.interceptors.response.use(
        (response) => Promise.resolve(response),
        axiosErrorInterceptor(store)
    );
    return axiosProps
};


export default api;

