import React, { useState } from 'react';
import { AppBar, IconButton, Modal, Paper, Tab, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Cancel } from '@mui/icons-material';
import * as PropTypes from 'prop-types';
import TabPanel, { a11yProps } from '../../../../components/mui/tabs/TabPanel';
import { BBS_SITE_LIST, useMatricIndexView } from '../../matricHelper';
import {
  checkPostStatus,
  deletePost,
  markPostAsRead,
  MatricActionType,
  sendErrorMessage,
  sendSuccessMessage
} from '../../../../actions';
import { getBasicHash } from '../../../../utils/Scripts';
import ResponsiveDialog from '../../../../components/dialog/ResponsiveDialog';
import PagedMatricTable from '../../PagedMatricTable';

PagedMatricTable.propTypes = {
  isMonitor: PropTypes.bool,
  handleOpenImage: PropTypes.func,
  handleRead: PropTypes.func,
  handleRefresh: PropTypes.func,
  handleDelete: PropTypes.func
};
const MonitorTabDataView = ({ data, totalPosts }) => {

  const classes = useMatricIndexView()
  const dispatch = useDispatch()
  const currentTab = useSelector(state => state.matric.currentMonitorDataTab)
  const showDisabledKeywords = useSelector(state => state.matric.showDisabledKeywords)
  const auth = useSelector(state => state.auth)

  const [selectedForDel, setSelectedForDel] = useState(null)
  const [hash, setHash] = useState(getBasicHash())

  const [imageModalSrc, setImageModalSrc] = useState(null)

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setSelectedForDel(null)
  }
  const onError = (msg) => sendErrorMessage(dispatch, msg)

  const handleDeleteOpen = (dataDelete) => {
    setSelectedForDel(dataDelete)
    setHash(getBasicHash())
  }

  const handleDeletePost = async () => {
    await deletePost(selectedForDel, showDisabledKeywords, onSuccess, onError)(dispatch)
  }

  const handleRead = async (dataRead) => {
    dataRead.userId = auth.authUser.id.toString()
    dataRead.userName = `${auth.authUser.firstName} ${auth.authUser.lastName}`
    await markPostAsRead(dataRead, showDisabledKeywords, onSuccess, onError)(dispatch)
  }

  const handleRefresh = async (dataRefresh) => {
    await checkPostStatus(dataRefresh, showDisabledKeywords, onSuccess, onError)(dispatch)
  }

  const handleTabChange = (event, newValue) =>
    dispatch({ type: MatricActionType.ChangeMonitorDataTab, payload: newValue })

  const combinedData = {}
  for (let i = 0; i < Object.keys(BBS_SITE_LIST).length; i+=1) {
    const siteSymbol = BBS_SITE_LIST[Object.keys(BBS_SITE_LIST)[i]].symbol
    combinedData[siteSymbol] = data.filter(post => post.site === siteSymbol)
  }

  const renderTabs = () => (
      <Paper elevation={0}>
        <AppBar position='sticky' color='default'>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label='All' {...a11yProps(0)} />)
            {
              BBS_SITE_LIST.map((site, idx) =>
                <Tab key={idx} label={site.name} {...a11yProps(idx + 1)} />)
            }
          </Tabs>
        </AppBar>

        <TabPanel value={currentTab} index={0}>
          <PagedMatricTable isMonitor
                            rows={data}
                            total={totalPosts.all}
                            handleDelete={handleDeleteOpen}
                            handleRead={handleRead}
                            handleRefresh={handleRefresh}
                            handleOpenImage={(src) => setImageModalSrc(src)}
          />
        </TabPanel>
        {
          BBS_SITE_LIST.map((site, idx) =>
            <TabPanel key={idx} value={currentTab} index={idx + 1}>
              <PagedMatricTable isMonitor
                                rows={combinedData[site.symbol]}
                                total={totalPosts[site.symbol]}
                                handleDelete={handleDeleteOpen}
                                handleRead={handleRead}
                                handleRefresh={handleRefresh}
                                handleOpenImage={(src) => setImageModalSrc(src)}
              />
            </TabPanel>
          )
        }

        <Modal open={!!imageModalSrc}
               disablePortal
               disableRestoreFocus
               disableEnforceFocus
               disableAutoFocus
               onClose={() => setImageModalSrc(null)}
               closeAfterTransition
               className={classes.modal}
        >
          <Paper>
            <div className={classes.closeButton}>
              <IconButton color='primary'
                          size='small'
                          component='span'
                          onClick={() => setImageModalSrc(null)}>
                <Cancel className='button'/>
              </IconButton>
            </div>
            <img src={imageModalSrc} alt='found within post'/>
          </Paper>
        </Modal>

        <ResponsiveDialog
          isOpen={!!selectedForDel} openHash={hash}
          title='Delete Post' content='Are you sure you want to delete this post?'
          Buttons={
            [
              { name: 'Yes', event: () => handleDeletePost() },
              { name: 'Cancel', event: () => setSelectedForDel(null) }
            ]
          }
        />
      </Paper>
    )

  return renderTabs()
}

export default MonitorTabDataView
