import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Modal, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowDownwardRounded, ArrowUpwardRounded, Delete, Edit } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { addInputToPreview } from './formBuilderFormManipulator';
import AddInputDialogue from './formBuilderAddInputDialogue';
import { FormCreatorValidator, VALID_INPUT_TYPES } from './formBuilderData';
import { getInputName } from './formClonerHelper';
import colors from '../../../theme/constants';


const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '800px',
    margin: '0 auto'
  },
  formControl: {
    marginBottom: '2rem'
  },
  buttonList: {
    textAlign: 'end'
  },
  paper: {
    padding: '2rem',
    '& h2': {
      marginBottom: '2rem'
    }
  },
  submitCheckBox: {
    marginLeft: '0px'
  },
  dragging:{
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px',
  },
  componentItem:{
    padding:'8px',
    marginBottom:'20px',
    border:`1px dashed ${colors.primaryLight}`,
    borderRadius:'4px'
  }
}))


const schema = yup.object().shape(FormCreatorValidator);
function FormBuilderFormPreview({ formData, setFormData, formComponents, setFormComponents }) {

  const classes = useStyles()
  const [openModal, setModalOpen] = useState(false)
  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  const [editType, setEditType] = useState(null)
  const [editIndex, setEditIndex] = useState(-1)
  const [componentObjects,setComponentObjects] = useState([])




  useEffect(()=>{
    setComponentObjects(formComponents.map((value,index)=>({'id':formData[index]?.name,'index':index,'component':value})))
  },[formComponents])


  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const editItem = (idx) => {
    setEditIndex(idx)
    setEditType(formData[idx].inputType ?? null)
    handleModalOpen()
  }

  const onEditCB = (formValues) => {
    if (editIndex !== -1 && editType) {
      const tempFormDetails = formValues

      // Text Min/Max Length
      if (tempFormDetails.minLength || tempFormDetails.maxLength) {
        if (tempFormDetails.minLength === '') tempFormDetails.minLength = 0
        if (tempFormDetails.maxLength === '') tempFormDetails.maxLength = 250

        if (tempFormDetails.minLength > tempFormDetails.maxLength) {
          setError('minLength', { type: 'manual', message: 'Min length must be smaller than max length' })
          return
        }

        if (tempFormDetails.inputType === VALID_INPUT_TYPES.text) {
          if (tempFormDetails.minLength > 255) {
            setError('minLength', { type: 'manual', message: 'Min length must be less than 255' })
            return
          }
          if (tempFormDetails.maxLength > 255) {
            setError('maxLength', { type: 'manual', message: 'Max length must be less than 255' })
            return
          }
        }
      }

      // File Min/Max Size
      if (tempFormDetails.inputType === VALID_INPUT_TYPES.file) {
        if (tempFormDetails.minSize === '') tempFormDetails.minSize = 0
        if (tempFormDetails.maxSize === '') tempFormDetails.maxSize = 5

        if (tempFormDetails.minSize > tempFormDetails.maxSize) {
          setError('minSize', { type: 'manual', message: 'Min size must be smaller than max size' })
          return
        }

        if (tempFormDetails.minSize > 20) {
          setError('minSize', { type: 'manual', message: 'Min size cannot be larger than 20mb' })
          return
        }

        if (tempFormDetails.maxSize > 20) {
          setError('maxSize', { type: 'manual', message: 'Max size cannot be larger than 20mb' })
          return
        }
      }

      tempFormDetails.name = formData[editIndex].name

      if ('values' in formValues) {
        const tempNames = formValues.values.trim().split('\n')
        tempFormDetails.values = tempNames.map(tn => {
          let isDefault = false
          let tempName = tn
          if (tn.substring(0, 1) === '-') {
            isDefault = true
            tempName = tn.substring(1).trim()
          }

          return {
            name: getInputName(),
            value: tempName,
            isDefaultValue: isDefault
          }
        })
      }

      const tempFormData = [...formData]
      const tempFormComponents = [...formComponents]

      tempFormData.splice(editIndex, 1, tempFormDetails)
      tempFormComponents.splice(editIndex, 1, addInputToPreview(tempFormDetails))
      setFormData(tempFormData)
      setFormComponents(tempFormComponents)

      handleModalClose()
      setEditIndex(-1)
      setEditType(null)
    }
  }

  const deleteItem = (idx) => {
    setFormData(formData.filter((e, i) => i !== idx))
    setFormComponents(formComponents.filter((e, i) => i !== idx))
  }

  const moveItem = (curIdx, offset) => {
    const oldFormData = formData[curIdx]
    const oldFormComponent = formComponents[curIdx]

    const tempFormData = [...formData]
    const tempFormComponents = [...formComponents]

    tempFormData.splice(curIdx, 1)
    tempFormComponents.splice(curIdx, 1)

    tempFormData.splice(curIdx + offset, 0, oldFormData)
    tempFormComponents.splice(curIdx + offset, 0, oldFormComponent)

    setFormData(tempFormData)
    setFormComponents(tempFormComponents)
  }

  const handleOnDragEnd = (res)=>{
    if(!res.destination ) return;
    if ( res.destination.index !== res.source.index){
      moveItem(res.source.index,(res.destination.index - res.source.index))
    }
  }


  return (
    <Grid container direction='column'>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        className={classes.modal}
      >
        <Paper className={classes.paper}>
          <Typography variant='h2'>Editing</Typography>

          <AddInputDialogue
            formType={editType}
            onCreateInputSubmit={onEditCB}
            register={register}
            errors={errors}
            handleSubmit={handleSubmit}
            isEdit
            inputValues={formData[editIndex]}
          />
        </Paper>
      </Modal>
      <DragDropContext onDragEnd = {handleOnDragEnd} >
        <Droppable droppableId = 'components'>
          {(providedOutter  )=>(
            <div {...providedOutter.droppableProps} ref={providedOutter.innerRef}>

            { componentObjects?.map((c, i) => (
                <Draggable key={c?.id} draggableId={c?.id} index={i}>
                  {(provided, snapshot )=>(
                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}
                         className={snapshot.isDragging ? classes.dragging :classes.dropping} style={{
                      ...provided.draggableProps.style}}>
                      <Grid item xs={12} className={classes.componentItem}>
                        <div className={classes.formControl}>
                          <Grid container direction='row' justifyContent='space-between'>
                            <Grid item xs={8}>
                              { c?.component }
                            </Grid>
                            <Grid item xs={4}>
                              <div className={classes.buttonList}>
                                <IconButton onClick={() => moveItem(i, -1)} disabled={i <= 0} size="large">
                                  <ArrowUpwardRounded />
                                </IconButton>
                                <IconButton
                                  onClick={() => moveItem(i, 1)}
                                  disabled={i >= formComponents.length - 1}
                                  size="large">
                                  <ArrowDownwardRounded />
                                </IconButton>
                                <IconButton onClick={() => editItem(i)} size="large">
                                  <Edit />
                                </IconButton>
                                <IconButton onClick={() => deleteItem(i)} size="large">
                                  <Delete />
                                </IconButton>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </div>

                  )}

                </Draggable>
              )
            ) }
              {providedOutter.placeholder}
          </div>)}

        </Droppable>
      </DragDropContext>
    </Grid>
  );
}

export default FormBuilderFormPreview
