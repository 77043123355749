import api from '../../apis/api'
import {pagedGridDefaultFetch} from '../../components/mui/datagrid/PagedGridAction'
import {sendErrorMessage, sendSuccessMessage} from '..'

export const ProductsActions = {
    SetProductFormStatus: 'SetProductFormStatus',
    SetActionError: 'SetActionError',
    SetCurrentProduct: 'SetCurrentProduct',
    FetchProducts: 'FetchProducts',
    SetEditProductStatus: 'SetEditProductStatus',
    UpdateProduct: 'UpdateProduct',
    DeleteProduct: 'DeleteProduct',
    FetchCategories: 'FetchCategories',
    AddProductCategories: 'AddProductCategories',
    FetchCurrentCategories: 'FetchCurrentCategories',
    FetchProductsByCategory: 'FetchProductsByCategory',
    AddProductCategories2: 'AddProductCategories2',
    prepareReadProduct: 'prepareReadProduct',
    ActiveProducts: 'ActiveProducts',
    FetchProductCategory: 'FetchProductCategory',
    FetchProductProps: 'FetchProductProps',
    FetchReviewedProductsByCategory: 'FetchReviewedProductsByCategory',
    FetchProductById: 'FetchProductById',
    FetchProductsPros: 'FetchProductsPros',
    FetchProductsFilterList: 'FetchProductsFilterList',
    ResetProductFilterList: 'ResetProductFilterList'
}

export const fetchCategories = () => async dispatch => {
    try {
        const res = await api.get('categoryMap/fetchCategory/training')
        if (res.data) {
            dispatch({type: ProductsActions.FetchCategories, payload: res.data})
            return true
        }

        throw res.data.message
    } catch (e) {
        sendErrorMessage(dispatch, e)
        return false
    }
}

export const fetchAllProductForSearchBar = args => async dispatch => {
    const {searchKey} = args
    try {
        const ProductUrl = '/product/searchProductByName'
        const res = await api.post(ProductUrl, {searchKey})
        if (res.data) {
            dispatch({type: ProductsActions.FetchProductsFilterList, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, 'Cannot fetch product')
        return false
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch product')
        return false
    }
}

export const fetchCurrentCategories = () => async dispatch => {
    try {
        const res = await api.get(`product/getAllCategory`)
        dispatch({
            type: ProductsActions.FetchCurrentCategories,
            payload: res.data.data
        })
    } catch (e) {
        sendErrorMessage(dispatch, 'Error in fetchCurrentCategories')
    }
    return false
}

export const fetchProductsByCategory = () => async dispatch => {
    try {
        const res = await api.post('/product/getProductByCategory')
        dispatch({
            type: ProductsActions.FetchProductsByCategory,
            payload: res.data.data
        })
    } catch (e) {
        sendErrorMessage(dispatch, 'Error in fetchProductsByCategory')
        // console.log('Could not get categories\n', e)
    }
    return false
}
export const addProductCategories = (data, success, error) => async dispatch => {
    try {
        const res = await api.post('/categoryMap/createCategory', data)

        if (res.data.rs) {
            sendSuccessMessage(dispatch, `Category has been created.`)
            success()
            dispatch({type: ProductsActions.AddProductCategories, payload: res.data})
        } else {
            sendErrorMessage(dispatch, res.data.message)
            return false
        }
    } catch (e) {
        error(e)
    }
    return false
}

export const addNewProduct = (data, success, error) => async dispatch => {
    try {
        const res = await api.post('/product/createProduct', data)
        if (res.data) {
            if (res.data.rs) {
                sendSuccessMessage(dispatch, `New product has been created.`)
                success()
                dispatch({type: ProductsActions.SetCurrentProduct, payload: res.data})
                return true
            }
            sendErrorMessage(dispatch, res.data.message)
            error()
            return false
        }
        return false
    } catch (e) {
        error(e)
    }
    return false
}

export const fetchProducts = args => async dispatch => {
    try {
        const productUrl = '/product/getProduct'
        const res = await pagedGridDefaultFetch(productUrl, args, ProductsActions.FetchProductProps, async () =>
            api.get(productUrl)
        )(dispatch)
        if (res.data?.rs) {
            dispatch({type: ProductsActions.FetchProductProps, payload: args})
            dispatch({type: ProductsActions.FetchProducts, payload: res.data})
            return true
        }
        throw res.data.message
    } catch (e) {
        sendErrorMessage(dispatch, e.message)
        return false
    }
}

export const prepareEditProduct = (id, cb) => async dispatch => {
    try {
        const res = await api.get(`product/getProduct/${id}`)
        // console.log(res.data)
        if (res.data.rs) {
            dispatch({type: ProductsActions.SetEditProductStatus, payload: {data: res.data.data, id}})
            cb()
            return true
        }

        sendErrorMessage(dispatch, res.data.message)
        return false
    } catch (e) {
        sendErrorMessage(dispatch, 'Error in prepareEditProduct')
        // console.log(e)
    }
    return false
}

export const prepareReadProduct = (id, cb) => async dispatch => {
    try {
        const res = await api.get(`product/getProduct/${id}`)
        dispatch({type: ProductsActions.prepareReadProduct, payload: {data: res.data.data, id}})
        cb()
    } catch (e) {
        // console.log(e)
        sendErrorMessage(dispatch, 'Error in prepareReadProduct')
    }
}

export const updateProduct = (data, success, error, id) => async dispatch => {
    try {
        const response = await api.put(`/product/updateProduct/${id}`, data)
        // console.log(response.data.data.name)
        if (response.data) {
            if (response.data.rs) {
                dispatch({type: ProductsActions.UpdateProduct, payload: response.data})
                sendSuccessMessage(dispatch, `${response.data.data.name} had been updated`)
                success()
                return true
            }
            sendErrorMessage(dispatch, response.data.message)
            error()
            return false
        }
    } catch (e) {
        error(e)
    }
    return false
}

export const deleteProduct = (id, error, fetchProps) => async dispatch => {
    try {
        const response = await api.delete(`/product/deleteProduct/${id}`)
        if (response.data) {
            if (response.data.rs) {
                const refresh = await dispatch(fetchProducts(fetchProps))
                if (refresh) {
                    dispatch({type: ProductsActions.DeleteProduct, payload: id})
                    sendSuccessMessage(dispatch, `Product id: ${id} has been deleted.`)
                    return true
                }
            }
            sendErrorMessage(dispatch, response.data.message)
            return false
        }
    } catch (e) {
        error(e)
    }
    return false
}

export const activateProduct = (obj, fetchProps) => async dispatch => {
    try {
        const res = await api.post('product/updateProductStatus', obj)
        const {data} = res
        if (data) {
            const refresh = await dispatch(fetchProducts(fetchProps))
            if (refresh) {
                dispatch({type: ProductsActions.ActiveProducts, payload: res.data})
                if (obj.isActive) {
                    sendSuccessMessage(dispatch, `ID: ${obj.ids} have been activated`)
                } else {
                    sendSuccessMessage(dispatch, `ID: ${obj.ids} have been deactivated`)
                }
            } else {
                sendErrorMessage(dispatch, res.data.message)
            }
        }
        // console.log(res.data);
    } catch (e) {
        // console.log(e);
        sendErrorMessage(dispatch, 'Error in activateProduct')
    }
}
// export const ReviewProductByCategory = () => async dispatch => {
//     try {
//         const res = await api.get('/review/getReviewedProductByCategory')
//         if (res.data.rs) {
//             dispatch({ type: ProductsActions.FetchProductCategory, payload: res.data.data })
//         }
//     } catch (e) {
//         console.log(e)
//     }
// }
export const fetchProductCategory = () => async dispatch => {
    try {
        const res = await api.get('/product/category')

        if (res.data.rs) {
            dispatch({type: ProductsActions.FetchProductCategory, payload: res.data.data})
            return true
        }

        sendErrorMessage(dispatch, 'This is no related category!')
        return false
    } catch (e) {
        // console.log('this is error', e)
        sendErrorMessage(dispatch, 'Error in fetchProductCategory')
        return false
    }
}

export const updateProductCategory = (id, newName, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.put('/product/category', {id, name: newName})
        if (res.data) {
            const refresh = await dispatch(fetchProductCategory())
            if (refresh) {
                if (res.data.rs) onSuccess('Category Updated')
                else onError(`Failed: ${res.data.message}`)
            } else onError('Cannot refresh data')
        }
    } catch (e) {
        // console.log('Category update error:\n', e)
        onError('Category cannot be updated.')
    }
}

export const addProductCategory = (name, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post('/product/category', {name})

        if (res.data) {
            const refresh = await dispatch(fetchProductCategory())
            if (refresh) {
                if (res.data.rs) onSuccess('Category created')
                else onError(`Failed: ${res.data.message}`)
            } else onError('Cannot refresh data')
        }
    } catch (e) {
        // console.log('Category create error:\n', e)
        onError('Category cannot be created.')
    }
}

export const deleteProductCategory = (id, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.delete(`/product/category/${id}`)
        if (res.data) {
            const refresh = await dispatch(fetchProductCategory())
            if (refresh) {
                if (res.data.rs) {
                    onSuccess('Category deleted')
                } else onError(`Failed: ${res.data.message}`)
            } else onError('Cannot refresh data')
        }
    } catch (e) {
        // console.log('Category delete error:\n', e)
        onError('Category cannot be deleted.')
    }
}

export const fetchReviewedProductsByCategory = data => async dispatch => {
    try {
        const res = await api.post('/review/product/getReviewedProductsByCategory', data)
        dispatch({
            type: ProductsActions.FetchReviewedProductsByCategory,
            payload: res.data.data
        })
        return true
    } catch (e) {
        sendErrorMessage(dispatch, 'Error in fetchProductsByCategory')
    }
    return false
}

export const createProductReview = async (data, onSuccess, onError) => {
    try {
        const res = await api.post('/review/product/createProductReview', data)
        if (res.data?.rs) {
            return onSuccess(res?.data?.message || 'create product review successfully', res?.data?.data)
        }
        return onError(res?.data?.message || 'Cannot create product reviews')
    } catch (e) {
        return onError(e.message)
    }
}

export const editProductReview = async (data, onSuccess, onError) => {
    try {
        const res = await api.put('review/product/editProductReview', data)
        if (res.data?.rs) {
            return onSuccess(res?.data?.message || 'edit product review successfully', res.data.data)
        }
        return onError(res?.data?.message || 'cannot edit product reviews')
    } catch (e) {
        return onError(e.message)
    }
}

export const getReviewedProductById = id => async dispatch => {
    try {
        const res = await api.get(`review/getProduct/${id}`)
        if (res.data.rs) {
            dispatch({
                type: ProductsActions.FetchProductById,
                payload: {data: res.data.data, categoryId: res.data?.data?.category?.id, id}
            })
            return true
        }
        return false
    } catch (e) {
        return false
    }
}
