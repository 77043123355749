
// Defaults
const defaultOptions = {
  format: 'image/jpeg',
  quality: 1,
  width: undefined,
  height: undefined,
  Canvas: undefined,
  crossOrigin: undefined
};

const getX = (image, width) => {
  if (image.right !== undefined)
    return width - (image.right + (image.width || image.img.width));
  return image.left || image.x || 0;
}

const getY = (image, height) => {
  if (image.bottom !== undefined)
    return height - (image.bottom + (image.height || image.img.height));
  return image.top || image.y || 0;
}

// Return Promise
const mergeImages = (sources = [], options = {}) => new Promise(resolve => {
  options = { ...defaultOptions, ...options};

  // Setup browser/Node.js specific variables
  const canvas = options.Canvas ? new options.Canvas() : window.document.createElement('canvas');
  const Image = options.Image || window.Image;

  // Load sources
  const images = sources.map(source => new Promise((resolveInner, rejectInner) => {
    // Convert sources to objects
    if (source.constructor.name !== 'Object') {
      source = { src: source };
    }

    // Resolve source and img when loaded
    const img = new Image();
    img.crossOrigin = options.crossOrigin;
    img.onerror = () => rejectInner(new Error('Couldn\'t load image'));
    img.onload = () => resolveInner({ ...source, img});
    img.src = source.src;
  }));

  // Get canvas context
  const ctx = canvas.getContext('2d');

  // When sources have loaded
  resolve(Promise.all(images)
    .then(i => {
      // console.log(images);
      // Set canvas dimensions
      const getSize = dim => options[dim] || Math.max(...i.map(image => image.img[dim]));
      canvas.width = getSize('width');
      canvas.height = getSize('height');

      // Draw images to canvas
      i.forEach(image => {
        ctx.globalAlpha = image.opacity ? image.opacity : 1;
        return ctx.drawImage(image.img, getX(image, canvas.width), getY(image, canvas.height), image.width || image.img.width, image.height || image.img.height);
      });

      if (options.Canvas && options.format === 'image/jpeg') {
        // Resolve data URI for node-canvas jpeg async
        return new Promise((res, rej) => {
          canvas.toDataURL(options.format, {
            quality: options.quality,
            progressive: false
          }, (err, jpeg) => {
            if (err) {
              rej(err);
              return;
            }
            res(jpeg);
          });
        });
      }

      // Resolve all other data URIs sync
      return canvas.toDataURL(options.format, options.quality);
    }));
});

export default mergeImages
