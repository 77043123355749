import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Button, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { MatricActionType } from '../../../actions';
import TabPanel, { a11yProps } from '../../../components/mui/tabs/TabPanel';
import MonitorDataView from './data/MonitorDataView';
import { Functions } from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import { MONITOR_TAB_DICT, useMatricIndexView } from '../matricHelper';
import MonitorKeywordsListView from './keywords/KeywordsListView';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import MonitorSettingsView from './settings/MonitorSettingsView';

function MonitorIndexView() {
  useCurrentViewCheck(Functions.Matric_Monitor_List)
  const  navigate = useNavigate();
  const hasTabParams = useParams().tab
  const dispatch = useDispatch()
  const classes = useMatricIndexView()
  const currentTab = useSelector(state => state.matric.currentMonitorIndexTab)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isSettingsEdit, setIsSettingsEdit] = useState(false)

  useEffect(() => {
    if (!isLoaded) {
      // Only check on first load
      const isTab = MONITOR_TAB_DICT.find(tab => tab.slug === hasTabParams)
      if (!isTab) {
        setIsLoaded(true)
        return
      }

      if (currentTab !== isTab.index) {
        handleTabChange(null, isTab.index)
      } else {
        setIsLoaded(true)
      }
    }
  }, [isLoaded, currentTab])

  const handleTabChange = (event, newValue) =>
    dispatch({ type: MatricActionType.ChangeMonitorIndexTab, payload: newValue })

  const renderPageTitle = () =>
    <Grid container justifyContent='space-between'>
      <Grid item>
        <Typography variant='h3' className={classes.pageTitle} color='textPrimary'>
          { MONITOR_TAB_DICT[currentTab].name }
        </Typography>
      </Grid>
      <Grid item>
        {
          // Show corresponding buttons
          currentTab === 1 ?
            <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate('/app/matric/monitor/keywords/add')}>
              Add Keyword
            </Button> : ''
        }
        {
          currentTab === 2 ?
            <Button color='secondary'
                    variant='contained'
                    onClick={() => setIsSettingsEdit(true)}>
              Edit Settings
            </Button> : ''
        }
      </Grid>
    </Grid>

  const renderTabs = () =>
    <div>
      <AppBar position='relative' color='default'>
        <Tabs value={currentTab} onChange={handleTabChange}>
          { MONITOR_TAB_DICT.map(tab => <Tab key={tab.index} label={tab.name} {...a11yProps(tab.index)} />) }
        </Tabs>
      </AppBar>

      <TabPanel value={currentTab} index={0} className={classes.mainTabContainer}>
        <MonitorDataView />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <MonitorKeywordsListView />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <MonitorSettingsView
          isEditing={isSettingsEdit}
          setIsEditing={setIsSettingsEdit}
        />
      </TabPanel>
    </div>

  return (
    <ViewHead functionId={Functions.Matric_Monitor_List} noMaxWidth isShowTitle={false}>
      { renderPageTitle() }
      <Paper elevation={0}>
        {
          isLoaded && renderTabs()
        }
      </Paper>
    </ViewHead>
  )
}

export default MonitorIndexView
