import {AdministrativeActionType} from '../../actions/administrative/administrativeAction'

const defaultState = {
    allLoginHistory: [],
    loginHistoryProps: {},

    allTrackers: [],
    allTrackersByDate: [],
    trackerFetchProps: {},

    allStaff: [],

    allContractTemplates: [],
    contractTemplateFetchBody: {},
    currentContractTemplate: {},
    currentTemplateTab: 0,

    allContracts: [],
    currentContract: {},
    currentContractNotes: [],
    contractFetchBody: {},

    currentInvoiceTab: 0,
    allInvoices: [],
    invoiceFetchProps: {},
    currentInvoice: {},
    allInvoiceHeads: [],
    currentInvoiceNotes: [],

    allProducts: [],
    allCustomers: [],
    currentCustomer: {},
    allCurrency: [],

    allEmailAccounts: [],
    allPaymentLogs: [],
    allPaymentLogsPros: {},
    currentEmailAccount: {},
    currentPaymentLog: {},
    filteredCustomers: [],
    filteredTeachers: [],

    allContractTerms: [],
    currentContractTerm: {},
    fixedTerms: [],
    selectedTerms: [],
    filterTerms: []
}

export const administrativeReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case AdministrativeActionType.FetchAllTrackers:
            return {...state, allTrackers: action.payload}
        case AdministrativeActionType.FetchAllTrackersByDate:
            return {...state, allTrackersByDate: action.payload}
        case AdministrativeActionType.SetTrackerFetchProps:
            return {...state, trackerFetchProps: action.payload}

        case AdministrativeActionType.FetchAllStaff:
            return {...state, allStaff: action.payload}

        case AdministrativeActionType.FetchAllContractTemplates:
            return {...state, allContractTemplates: action.payload}
        case AdministrativeActionType.FetchContractTemplateProps:
            return {...state, contractTemplateFetchBody: action.payload}
        case AdministrativeActionType.FetchContractTemplate:
            return {...state, currentContractTemplate: action.payload}
        case AdministrativeActionType.ChangeTemplateTab:
            return {...state, currentTemplateTab: action.payload}

        case AdministrativeActionType.FetchAllLoginHistory:
            return {...state, allLoginHistory: action.payload}
        case AdministrativeActionType.FetchLoginHistoryProps:
            return {...state, loginHistoryProps: action.payload}

        case AdministrativeActionType.FetchAllContracts:
            return {...state, allContracts: action.payload}
        case AdministrativeActionType.FetchContract:
            return {...state, currentContract: action.payload}
        case AdministrativeActionType.ClearContractNotes:
            return {...state, currentContractNotes: []}
        case AdministrativeActionType.FetchContractNotes:
            return {...state, currentContractNotes: action.payload}
        case AdministrativeActionType.FetchContractProps:
            return {...state, contractFetchBody: action.payload}

        case AdministrativeActionType.FetchAllProducts:
            return {...state, allProducts: action.payload}
        case AdministrativeActionType.FetchCustomer:
            return {...state, currentCustomer: action.payload}
        case AdministrativeActionType.FetchAllCustomerAdministrative:
            return {...state, allCustomers: action.payload}
        case AdministrativeActionType.FetchCustomersFilterList:
            return {...state, filteredCustomers: action.payload, error: undefined}

        case AdministrativeActionType.FetchTeacherFilterList:
            return {...state, filteredCustomers: action.payload, error: undefined}

        case AdministrativeActionType.ResetCustomersFilterList:
            return {...state, filteredCustomers: [], error: undefined}

        case AdministrativeActionType.FetchCurrency:
            return {...state, allCurrency: action.payload}

        case AdministrativeActionType.ChangeInvoiceTab:
            return {...state, currentInvoiceTab: action.payload}
        case AdministrativeActionType.FetchAllInvoices:
            return {...state, allInvoices: action.payload}
        case AdministrativeActionType.FetchInvoicesProps:
            return {...state, invoiceFetchProps: action.payload}
        case AdministrativeActionType.FetchInvoice:
            return {...state, currentInvoice: action.payload}
        case AdministrativeActionType.FetchAllInvoiceHeads:
            return {...state, allInvoiceHeads: action.payload}
        case AdministrativeActionType.ClearInvoiceNotes:
            return {...state, currentInvoiceNotes: []}
        case AdministrativeActionType.FetchInvoiceNotes:
            return {...state, currentInvoiceNotes: action.payload}

        case AdministrativeActionType.FetchAllEmailAccounts:
            return {...state, allEmailAccounts: action.payload}
        case AdministrativeActionType.FetchEmailAccount:
            return {...state, currentEmailAccount: action.payload}
        case AdministrativeActionType.FetchAllPaymentLogs:
            return {...state, allPaymentLogs: action.payload}
        case AdministrativeActionType.FetchAllPaymentLogsProps:
            return {...state, allPaymentLogsPros: action.payload}
        case AdministrativeActionType.FetchSignlePaymentLog:
            return {...state, currentPaymentLog: action.payload}

        case AdministrativeActionType.FetchAllContractTerms:
            return {...state, allContractTerms: action.payload}
        case AdministrativeActionType.FetchContractTerm:
            return {...state, currentContractTerm: action.payload}

        case AdministrativeActionType.FetchFixedTerms:
            return {...state, fixedTerms: action.payload}

        case AdministrativeActionType.FetchTermFilterList:
            return {...state, filterTerms: action.payload}
        case AdministrativeActionType.FetchTermsBeSelected:
            return {...state, selectedTerms: action.payload}
        default:
            return state
    }
}
export default administrativeReducer
