import api from '../../apis/api'
import {sendErrorMessage} from '../message/messageActions'
// import {sendErrorMessage} from "../message/messageActions";

export const TagActionType = {
    FetchAllTags: 'FetchAllTags',
    FetchTagsByGroup: 'FetchTagsByGroup',
    SearchPrefixByTitle: 'SearchPrefixByTitle',
    SearchPrefixByNote: 'SearchPrefixByNote',
    UpdateTags: 'UpdateTags',
    DeleteTag: 'DeleteTag',
    AddTag: 'AddTag'
}

export const searchPrefixByTitle = data => async dispatch => {
    try {
        const res = await api.get(`/tag/searchByTitle/${data}`)
        if (res.data?.rs) {
            dispatch({type: TagActionType.SearchPrefixByTitle, payload: res.data.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch(), 'Search failued due to malformed data')
    }
    return false
}

export const searchPrefixByNote = data => async dispatch => {
    try {
        const res = await api.get(`/tag/searchByNote/${data}`)
        if (res.data?.rs) {
            dispatch({type: TagActionType.SearchPrefixByNote, payload: res.data.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch(), `Search failure due to malformed data, ${e}`)
    }
    return false
}

export const fetchTagsByGroup = data => async dispatch => {
    try {
        const res = await api.get(`/tag/fetchAll/${data}`)
        if (res.data?.rs) {
            dispatch({type: TagActionType.FetchTagsByGroup, payload: res.data.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch(), `Fetch failure due to malformed data, ${e}`)
    }
    return false
}

export const updateTag = data => async dispatch => {
    try {
        const res = await api.put('/tag/updateTag', data)
        if (res.data?.rs) {
            fetchTagsByGroup(data.group)(dispatch)
            dispatch({type: TagActionType.UpdateTags, payload: res.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch(), `Update failure due to malformed data, ${e}`)
    }
    return false
}

export const deleteTag = data => async dispatch => {
    try {
        const res = await api.put('/tag/deleteTag', data)
        if (res.data?.rs) {
            await fetchTagsByGroup(data.group)(dispatch)
            dispatch({type: TagActionType.UpdateTags, payload: res.data.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch(), `Delete failure due to malformed data, ${e}`)
    }
    return false
}

export const addTag = data => async dispatch => {
    try {
        const res = await api.post('/tag/createTag', data)
        if (res.data?.rs) {
            await fetchTagsByGroup(data.group)(dispatch)
            dispatch({type: TagActionType.AddTag, payload: res.data.data})
            return true
        }
    } catch (e) {
        sendErrorMessage(dispatch(), `Add failure due to malformed data, ${e}`)
    }
    return false
}
