import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { FormHelperText, InputAdornment, Modal, Stack, Typography ,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { AddCircle, Check, Close } from '@mui/icons-material';
import * as yup from 'yup';
import FormControl from '@mui/material/FormControl';
import { Edit } from 'react-feather';
import { noop } from 'lodash';
import {
  createContractCommission,
  updateContractCommission
} from '../../../../actions/administrative/administrativeAction';
import { sendErrorMessage, sendSuccessMessage } from '../../../../actions';
import { commissionStatusFormatter } from '../contractHelper';
import { capitalizeFirstChar } from '../../../../utils/Scripts';

const useCommissionStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '1000px',
    margin: '0 auto'
  },
  paper: {
    padding: '2rem',
    width: 'clamp(300px, 80vw, 1000px)',
    maxHeight: '77vh',
    overflow: 'auto',
    '& h3': {
      fontWeight: 'bold',
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    }
  },
  table: {
    minWidth: '500px'
  },
  modalTitle: {
    marginBottom: '1rem'
  },
  notes: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word'
  }
}))

const schema = yup.object().shape({
  price: yup.number().required().positive().typeError('Please input a valid price'),
  notes: yup.lazy((value) => !value ? yup.string() : yup.string()
    .min(1, 'Notes must be at least 1 character')
    .max(250, `Notes must be less than 250 characters`)
    .trim())
})

const CommissionRow = ({ commission, cid, isNew, fetchProps, onCancel=noop }) => {

  const dispatch = useDispatch()
  const classes = useCommissionStyles()

  const [isEdit, setIsEdit] = useState(isNew)
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState({})

  const [formData, setFormData] = useState(
    isNew
      ? {
        price: '0',
        notes: '',
        status: 'unpaid'
      }
      : {
        price: commission.price,
        notes: commission.notes,
        status: commission.status
    })

  const onSubmit = async () => {
    // validate data
    setErrors({})
    setIsSaving(true)

    try {
      await schema.validate(formData, { abortEarly: false })
      const submitFormData = { ...formData, cid }

      if (isNew) {
        // create new commission
        await createContractCommission(submitFormData, fetchProps, onSuccess, onError)(dispatch)
      } else {
        // update this commission
        await updateContractCommission(commission.id, submitFormData, fetchProps,
          onSuccess, onError)(dispatch)
      }
    } catch (err) {
      // display error to the user
      setIsSaving(false)
      setErrors(err.inner.reduce((res, cur) => ({ ...res, [cur.path]: cur.message }), {}))
    }
  }

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setIsSaving(false)
    setIsEdit(false)
    onCancel()
  }

  const onError = (msg) => {
    sendErrorMessage(dispatch, msg)
    setIsSaving(false)
  }

  const onCancelEdit = () => {
    // reset form data and the like
    setIsEdit(false)
    setFormData(isNew
      ? {
        price: '0',
        notes: '',
        status: 'unpaid'
      }
      : {
        price: commission.price,
        notes: commission.notes,
        status: commission.status
      })
    onCancel()
  }

  const renderEditView = () =>
    <TableRow>
      <TableCell scope='row'>
        <FormControl fullWidth error={Boolean(errors.status)} disabled={isSaving}>
          <InputLabel error={Boolean(errors.status)}>Status</InputLabel>
          <Select
            label='Status'
            fullWidth
            value={formData.status}
            onChange={(e) =>
              setFormData({ ...formData, status: e.target.value })}
          >
            <MenuItem value='cancelled'>Cancelled</MenuItem>
            <MenuItem value='unpaid'>Unpaid</MenuItem>
            <MenuItem value='partial'>Partial</MenuItem>
            <MenuItem value='paid'>Paid</MenuItem>
          </Select>
          <FormHelperText error={Boolean(errors.status)}>
            { errors.status ? errors.status : '' }
          </FormHelperText>
        </FormControl>
      </TableCell>

      <TableCell scope='row'>
        <FormControl fullWidth>
          <TextField fullWidth size='small' label='Notes'
                     name='notes' multiline disabled={isSaving}
                     value={ formData.notes }
                     onChange={(e) =>
                       setFormData({ ...formData, notes: e.target.value })}
                     error={Boolean(errors.notes)}
                     helperText={errors.notes ? errors.notes : ''}
          />
        </FormControl>
      </TableCell>

      <TableCell scope='row'>
        <FormControl fullWidth>
          <TextField fullWidth size='small'
                     label='Price' type='number' required
                     name='price' disabled={isSaving}
                     value={ formData.price }
                     onChange={(e) =>
                       setFormData({ ...formData, price: e.target.value })}
                     error={Boolean(errors.price)}
                     helperText={errors.price ? errors.price : ''}
                     InputProps={{
                       startAdornment: <InputAdornment position='start'>$</InputAdornment>
                     }}
          />
        </FormControl>
      </TableCell>

      <TableCell align='right'>
        <Tooltip title='Save' placement='top'>
          <IconButton color='primary'
                      size='small'
                      disabled={isSaving}
                      component='span'
                      onClick={() => onSubmit()}>
            <Check style={{ color: 'green' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title='Cancel' placement='top'>
          <IconButton color='primary'
                      size='small'
                      disabled={isSaving}
                      component='span'
                      onClick={() => onCancelEdit()}>
            <Close style={{ color: 'red' }} />
          </IconButton>
        </Tooltip>
      </TableCell>
  </TableRow>

  const renderViewView = () =>
    <TableRow>
      <TableCell scope='row'>
        { commissionStatusFormatter(formData.status) }
      </TableCell>

      <TableCell scope='row'>
        <Typography variant='body2' className={classes.notes}>
          { formData.notes }
        </Typography>
      </TableCell>

      <TableCell scope='row'>
        ${ formData.price }
      </TableCell>

      <TableCell align='right'>
        <Tooltip title='Edit' placement='top'>
          <IconButton color='primary'
                      size='small'
                      component='span'
                      onClick={() => setIsEdit(true)}>
            <Edit />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>

  const renderRow = () =>
    <div>
      {
        isEdit
          ? renderEditView()
          : renderViewView()
      }
    </div>

  return renderRow()
}

function CommissionManager({ contract, fetchProps, handleModalClose }) {

  const classes = useCommissionStyles()

  const [isAdd, setIsAdd] = useState(false)
  const [commissionStats, setCommissionStats] = useState({
    paid: 0,
    partial: 0,
    unpaid: 0,
    total: 0,
  })

  useEffect(() => {
    const newStats = contract?.commissions?.reduce((res, cur) => {
      if (cur.status !== 'cancelled') {
        res.total += parseFloat(cur.price)
        switch (cur.status) {
          case 'paid':
            res.paid += parseFloat(cur.price)
            break
          case 'partial':
            res.partial += parseFloat(cur.price)
            break
          case 'unpaid':
            res.unpaid += parseFloat(cur.price)
            break
          default:
            break
        }
      }
      return res
    }, {
      paid: 0,
      partial: 0,
      unpaid: 0,
      total: 0,
    })
    setCommissionStats(newStats)
  }, [contract])

  const renderTable = () =>
    <TableContainer>
      <Table className={classes.table} size='small'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 160 }}>Status</TableCell>
            <TableCell sx={{ minWidth: 160 }}>Notes</TableCell>
            <TableCell sx={{ minWidth: 160 }}>Price</TableCell>
            <TableCell align='right'>Edit</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {contract?.commissions?.map(commission =>
            <CommissionRow key={commission.id}
                           cid={contract.cid}
                           commission={commission}
                           isNew={false}
                           fetchProps={fetchProps} />
          ) }

          {
            isAdd &&
            <CommissionRow cid={contract.cid}
                           isNew
                           fetchProps={fetchProps}
                           onCancel={() => setIsAdd(false)} />
          }

          {
            commissionStats && Object.keys(commissionStats).map(stat =>
              <TableRow key={stat}>
                <TableCell colSpan={2} align='right'><b>{ capitalizeFirstChar(stat) }</b></TableCell>
                <TableCell colSpan={2}>
                  ${ commissionStats[stat].toFixed(2) }
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>

  return (
    <Modal
      open={contract !== null}
      onClose={handleModalClose}
      className={classes.modal}
      disablePortal
      disableRestoreFocus
      disableEnforceFocus
      disableAutoFocus
      closeAfterTransition
    >
      <Paper className={classes.paper}>
        {
          contract?.cid && Array.isArray(contract?.commissions) &&
          <div>
            <Stack justifyContent='space-between'
                   direction='row'
                   justifyItems='center'
                   className={classes.modalTitle}
            >
              <Typography variant='h3' gutterBottom>
                {`Commissions for ${ contract.cid } for ${ contract?.sales_fullName}`}
              </Typography>
              <Tooltip title='Add Commission' placement='top'>
                <IconButton onClick={() => setIsAdd(!isAdd)} size="large">
                  <AddCircle style={{ color: isAdd ? 'red' : 'green' }} />
                </IconButton>
              </Tooltip>
            </Stack>

            { renderTable() }
          </div>
        }
      </Paper>
    </Modal>
  );


}

export default CommissionManager
