import React, { useEffect } from 'react';
import {
    TextField,
    InputLabel,
    Grid,
    Button,
    Paper,
    Typography,
    FormControlLabel,
    Checkbox,
    FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTheme } from '@mui/styles';
import TreeView from "@mui/lab/TreeView";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {TreeItem} from "@mui/lab";
import SubmitButton from '../../../components/mui/button/SubmitButton';
import {
    createTrainingMessage, fetchClasses,
    trainingMessageActionsTypes
} from '../../../actions';

const schema = yup.object().shape({
    content: yup.string().required('Required')
    .min(2, 'message must be at least 2 characters')
    .max(255, 'message must be less than 255 characters')
    .trim(),
});

const useStyles = makeStyles((theme) => ({
    smallGrid: {
        margin: '0.5em'
    }, form__title: {
        marginBottom: '.5rem'
    },
    paper__form: {
        padding: '1rem 1rem',
        margin: '0 auto',
    },
    name__margin: {
        // margin: '20px'
    },
    select__margin: {
        paddingTop: '20px'
    },
    label__styles: {
        padding: '20px 10px'
    },
    formControl: {
        // margin: theme.spacing(2),
        minWidth: 120,
        maxWidth: 300,
    },
    formControl1:{
        // margin: theme.spacing(2),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    studentGrid:{
      height:200,
      overflowY:'scroll',
      outline:'1px  grey dotted'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


function AddMessage({ createTrainingMessage }) {
    const theme = useTheme();

    const { register, errors, handleSubmit, setError, clearErrors} = useForm(
        {
            resolver: yupResolver(schema)
        }
    );
    const classes = useSelector(state => state.classes.class)

    const loadData = () => {
        (async() => {
            await fetchClasses()(dispatch)
        })()
    }
    useEffect(loadData, [])
    const classList = classes?.filter(cl => cl.isActive === true).map(value => value.name);
    const [className, setClasses] = React.useState([]);
    const [studentArray, setStudentArray] = React.useState([]);
    const [IsSaving, setIsSaving] = React.useState(false);
    const dispatch = useDispatch();
    const classStyle = useStyles();

    useEffect(() => {
        let tempArray = [];
        const studentLIst = [];
        for (let i = 0; i < className.length; i+=1) {
            tempArray = [...tempArray, ...classes.find(value => value.name === className[i]).students];
        }

        for (let i = 0; i < tempArray.length; i+=1) {
            if (!studentLIst.includes(tempArray[i].id)) {
                studentLIst.push(tempArray[i].id);
            }
        }
        setStudentArray(studentLIst);
    }, [className,classes]);

    const handleChangeClasses = (event) => {
        clearErrors('classes')
        setClasses(event.target.value);
    };

    const handleSelectChange=(checked,value1)=>{
        clearErrors('classes')
        if(checked) {
            if (!studentArray.includes(value1.id)) {
                setStudentArray(array => [...array, value1.id])
            }
        }
        else{
               setStudentArray(array=>array.filter(item=>item!==value1.id))
        }
    }

    const onSubmit = (data) => {
        const data1 = { ...data, students: studentArray };
        if (studentArray.length === 0) {
            setError('classes', { type: 'manual', message: 'Please select classes' });
            return;
        }
        setIsSaving(true);
        createTrainingMessage(data1, afterSaving, catchError);
    };

    const catchError = () => {
        setIsSaving(false);
    };
    const afterSaving = () => {
        setIsSaving(false);
    };

    return (
        <div>
            <Paper className={classStyle.paper__form}>
                <Button variant='contained' color='primary' onClick={() => {
                    dispatch({ type: trainingMessageActionsTypes.SetTrainingMessageFormStatus, payload: 'Close' });
                }}>Back</Button>

                <Grid container>
                    <Grid item xs={12} md={8}>
                        <Typography className={classStyle.form__title} variant="h2" align="center">Add
                            Message</Typography>
                    </Grid>
                </Grid>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item xs={12} md={6} className={classStyle.name__margin}>
                            <TextField multiline rows={5} variant="outlined" name='content' label='Message *'
                                       inputRef={register}
                                       fullWidth autoFocus error={Boolean(errors.content?.message)}
                                       helperText={errors.content?.message}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid container justifyContent="flex-start" className={classStyle.formControl}>
                            <InputLabel className={classStyle.label__styles}>
                                {'Add Classes '}
                            </InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={className}
                                onChange={handleChangeClasses}
                                input={<Input id="select-multiple-chip"/>}
                                renderValue={(selected) => (
                                    <div className={classStyle.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classStyle.chip}/>
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                                error={Boolean(errors.classes?.message)} fullWidth
                            >
                                {classList?.map((name) => (
                                    <MenuItem key={name} value={name} style={getStyles(name, className, theme)}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error>{errors.classes?.message}</FormHelperText>
                        </Grid>
                        <Grid container  className={classStyle.formControl1}>
                            <Grid md={6} xs={12} item>
                            <InputLabel className={classStyle.label__styles}>
                                Add Students
                            </InputLabel>
                               <div className={classStyle.studentGrid}>
                            <TreeView    defaultCollapseIcon={<ExpandLess />}
                                              defaultExpandIcon={<ExpandMore/>}>
                                     {classes?.filter(cl => cl.isActive === true).map((value,index)=>( <TreeItem  label={value.name} key={value.name} nodeId = {`${index+1000}`} >
                                         {value.students?.map((value1,innerIndex)=>(<TreeItem label={
                                                 <FormControlLabel
                                                     control={<Checkbox color="primary" disableRipple size="small"
                                                                       onChange={(e,checked)=>handleSelectChange(checked,value1)}
                                                                         onClick={e => e.stopPropagation()}
                                                                         checked={studentArray.some(item => item === value1.id)}
                                                     />}
                                                     label={
                                                         <Typography variant="body2" >
                                                                 {value1.id.toString(10).concat('.',value1.firstName,' ',value1.lastName)}
                                                             </Typography>
                                                     }
                                                 />} key={value1.id} nodeId = {`${innerIndex+100}`} />))
                                         }
                                         </TreeItem>))}
                                 </TreeView>
                               </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classStyle.select__margin}>
                        <Grid item xs={4}>
                            <SubmitButton text="Save" isSavingText="Saving"
                                          fullWidth variant="contained" color="primary" isSaving={IsSaving}
                            />
                        </Grid>
                    </Grid>

                </form>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => ({
        classes: state.trainingMessage?.classes
    });

export default connect(mapStateToProps, { createTrainingMessage })(AddMessage);
