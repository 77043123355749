import React, {useEffect, useState} from 'react'

import {connect, useDispatch} from 'react-redux'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'
import PublishIcon from '@mui/icons-material/Publish'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import ClassDeliver from './ClassDeliver'
import Homework from './Homework'
import CheckCustomerFloat from '../../customer/register/CheckCustomerFloat'
import {
    classActionsType,
    fetchClass,
    fetchClassHistory,
    fetchHomework,
    fetchClassTransferByClassId
} from '../../../actions/class/classActions'
import ViewHead from '../../../components/public/ViewHead'
import {Functions} from '../../../utils/Functions'
import StudentIncluded from './StudentIncluded'
import ClassChangeHistory from './ClassChangeHistory'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    chip: {
        margin: theme.spacing(0.5),
        height: '100%'
    },

    section1: {
        margin: theme.spacing(3, 2)
    },
    section2: {
        margin: theme.spacing(2)
    },
    section3: {
        margin: theme.spacing(3, 1, 1)
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    paper__form: {
        padding: '1rem 1rem',
        margin: '0 auto'
    },
    table: {
        minWidth: 750
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    editIcon: {
        cursor: 'pointer'
    },
    tableCell: {
        width: 80,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px'
    },
    funcCell: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    add: {
        '& > span': {
            margin: theme.spacing(2)
        }
    },
    rate: {
        width: 200,
        display: 'flex',
        alignItems: 'center'
    },
    paper_padding: {
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    },
    paper_new: {
        marginBottom: theme.spacing(3)
    },
    classes_grid: {
        overflowX: 'scroll'
    },
    chip_label: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalPaper: {
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    modalTitle: {
        padding: theme.spacing(1, 0)
    },
    button: {
        margin: theme.spacing(1)
    },
    copyButton: {
        paddingBottom: '2px'
    },
    floatingWindow: {
        width: '80%',
        height: '80%',
        overflow: 'scroll',
        backgroundColor: 'grey',
        border: '1px solid black',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '9999'
    },
    link: {
        '&:hover': {
            textDecoration: 'none'
        }
    }
}))

const defaultValue = {}

function ReadClass({isEdit, oneClass, homework, classHistory, customers, classViewData}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isLoading = false
    const [studentArray, setStudentArr] = useState([])
    const [isDataReady, setIsDataReady] = useState(false)
    const [totalClassTime, setTotalClassTime] = useState(0)
    const cases = defaultValue
    const columnNameNeeded = false
    // const [copyString, setCopyString] = useState('')
    // const [checkedAll, setCheckedAll] = useState(true);
    const [showStudentDetail, setShowStudentDetail] = useState(null) // the id of student details in floating window
    const [customerFloatOpen, setCustomerFloatOpen] = useState(false)
    const setChildStudentId = id => {
        setShowStudentDetail(id)
        setCustomerFloatOpen(true)
    } // to set the floating window of student id in Child component

    const getDataReady = isVisible => {
        if (!isVisible) return
        fetchClassTransferByClassId(oneClass?.data?.id)(dispatch)
        setIsDataReady(true)
    }

    useEffect(() => {
        if (classViewData && classViewData?.deliverId) {
            dispatch({
                type: classActionsType.SetClassTableStatus,
                payload: 'AddClassDeliver'
            })
        }
    }, [classViewData])

    // useEffect(() => {
    //   setCheckedAll(JSON.stringify(cases) === JSON.stringify(defaultValue))
    // }, [cases]);

    useEffect(() => {
        const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

        const studentDetails = customers
            ? oneClass?.data?.students.map(student => customers.find(customer => customer.id === student.id))
            : null

        const caseKeys = Object.keys(cases)
        const chosenAttribute = []
        caseKeys.forEach(value => {
            if (cases[value] === true) {
                chosenAttribute.push(value)
            }
        })
        let string = ''
        if (studentDetails) {
            studentDetails.forEach(detail => {
                chosenAttribute.forEach(value => {
                    if (columnNameNeeded === true) {
                        if (value === 'sales') {
                            string = `${string}${capitalizeFirstLetter(value)} : ${detail[value]?.firstName}${
                                detail[value]?.lastName
                            },`
                        } else if (value === 'category') {
                            string = `${string}${capitalizeFirstLetter(value)} : ${detail[value]?.name},`
                        } else {
                            string = `${string}${capitalizeFirstLetter(value)} : ${detail[value]},`
                        }
                    } else {
                        if (detail[value] === null) {
                            return
                        }
                        if (value === 'sales') {
                            string = `${string}${detail[value]?.firstName}${detail[value]?.lastName},`
                        } else if (value === 'category') {
                            string = `${string}${detail[value]?.name},`
                        } else {
                            string = `${string}${detail[value]},`
                        }
                    }
                })
                string = `${string};\r\n`
            })
        }

        // setCopyString(string)
    }, [cases, columnNameNeeded, oneClass, customers])

    useEffect(() => {
        const constructorObj = async (
            id,
            firstName,
            lastName,
            score,
            scoreTime,
            homeworkTime,
            homeworkFinish,
            attNum
        ) => {
            const obj = {id, firstName, lastName, score, scoreTime, homeworkTime, homeworkFinish, attNum}
            voidArray.push(obj)
        }
        const voidArray = []
        if (homework.data.length > 0) {
            homework.data[0].homework.forEach(homeworkNew =>
                homeworkNew.submissions?.forEach(submission => {
                    const studentid = submission.student.id
                    if (!voidArray || !voidArray.some(item => item.id === studentid)) {
                        if (submission.status === 'reviewed') {
                            if (submission.score) {
                                constructorObj(
                                    studentid,
                                    submission.student.firstName,
                                    submission.student.lastName,
                                    submission.score,
                                    1,
                                    1,
                                    1,
                                    0
                                )
                            } else {
                                constructorObj(
                                    studentid,
                                    submission.student.firstName,
                                    submission.student.lastName,
                                    0,
                                    0,
                                    1,
                                    1,
                                    0
                                )
                            }
                        } else if (submission.status === 'submitted') {
                            constructorObj(
                                studentid,
                                submission.student.firstName,
                                submission.student.lastName,
                                0,
                                0,
                                1,
                                1,
                                0
                            )
                        } else {
                            constructorObj(
                                studentid,
                                submission.student.firstName,
                                submission.student.lastName,
                                0,
                                0,
                                1,
                                0,
                                0
                            )
                        }
                    } else {
                        const index = voidArray.findIndex(item => item.id === studentid)
                        voidArray[index].homeworkTime += 1
                        if (submission.status === 'reviewed') {
                            if (submission.score) {
                                voidArray[index].score += submission.score
                                voidArray[index].scoreTime += 1
                                voidArray[index].homeworkFinish += 1
                            } else {
                                voidArray[index].homeworkFinish += 1
                            }
                        } else if (submission.status === 'submitted') {
                            voidArray[index].homeworkFinish += 1
                        }
                    }
                })
            )
        }

        if (classHistory.data) {
            const {length} = classHistory.data
            setTotalClassTime(length)
            classHistory.data.forEach(oneclass => {
                oneclass.attendants.forEach(attendant => {
                    const studentid = attendant.id
                    if (!voidArray || !voidArray.some(item => item.id === studentid)) {
                        constructorObj(attendant.id, attendant.firstName, attendant.lastName, 0, 0, 0, 0, 1)
                    } else {
                        const index = voidArray.findIndex(item => item.id === studentid)
                        voidArray[index].attNum += 1
                    }
                })
            })
        }

        if (oneClass) {
            const students = oneClass?.data?.students
            students.forEach(item => {
                const studentid = item.id
                if (!voidArray || !voidArray.some(innerItem => innerItem.id === studentid)) {
                    constructorObj(item.id, item.firstName, item.lastName, 0, 0, 0, 0, 0)
                }
            })
        }
        const studentsForFilter = oneClass?.data?.students
        voidArray.filter(value => studentsForFilter?.some(item => item.id === value.id))
        setStudentArr(voidArray)
    }, [homework, classHistory])

    const renderMap = () => {
        let newArr
        if (studentArray.length !== 0) {
            newArr = studentArray.map(item => {
                const {id} = item
                const studentName = `${item.firstName} ${item.lastName}`
                let HomeWorkRate = 'N/A'
                let averageScore = 'N/A'
                let averageAttendantRate = 'N/A'
                if (item.homeworkTime !== 0) {
                    HomeWorkRate = `${((item.homeworkFinish / item.homeworkTime) * 100).toFixed(3)}%`
                }
                if (item.scoreTime !== 0) {
                    averageScore = (item.score / item.scoreTime).toFixed(3)
                }
                if (totalClassTime !== 0) {
                    averageAttendantRate = `${((item.attNum / totalClassTime) * 100).toFixed(3)}%`
                }
                return {id, studentName, HomeWorkRate, averageScore, averageAttendantRate}
            })
        } else {
            newArr = [
                {
                    id: 'N/A',
                    studentName: 'N/A',
                    HomeWorkRate: 'N/A',
                    averageScore: 'N/A',
                    averageAttendantRate: 'N/A'
                }
            ]
        }
        return newArr?.map(row => (
            <TableRow key={row.id}>
                <TableCell component="th" scope="row" align="center">
                    {row.id}
                </TableCell>
                <TableCell align="center">{row.studentName}</TableCell>
                <TableCell align="center">{row.HomeWorkRate}</TableCell>
                <TableCell align="center">{row.averageScore}</TableCell>
                <TableCell align="center">{row.averageAttendantRate}</TableCell>
            </TableRow>
        ))
    }

    if (isLoading) {
        return <div />
    }

    const topButtons = () => (
        <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => {
                dispatch({type: classActionsType.SetClassTableStatus, payload: 'closed'})
                navigate(`/app/training/class`)
            }}
        >
            Back
        </Button>
    )

    return (
        <ViewHead functionId={Functions.Training_Class_Class_Details} isShowTitle={false} noMaxWidth>
            <div>
                <Grid container gap={1} alignItems="center" justifyContent="space-between">
                    <Grid item xs={12}>
                        <Typography variant="h3" color="textPrimary" className={classes.pageTitle}>
                            {oneClass?.data?.name}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={clsx({
                            [classes.pageTopButton]: topButtons,
                            [classes.noPageButton]: !topButtons
                        })}
                    >
                        {topButtons()}
                    </Grid>
                </Grid>
                <br />
                <br />
                <StudentIncluded isEdit={isEdit} classHistory={classHistory} oneClass={oneClass} />
                <br />
                <br />
                <ReactVisibilitySensor partialVisibility active={!isDataReady} onChange={getDataReady}>
                    <ClassChangeHistory oneClass={oneClass} />
                </ReactVisibilitySensor>

                <Grid container>
                    <Box boxShadow={1} bgcolor="background.paper" p={1} mt={1} mr={1} style={{width: '20rem'}}>
                        <div className={classes.root}>
                            <div className={classes.section1}>
                                <Grid container alignItems="center">
                                    <Grid item xs>
                                        <Typography gutterBottom variant="h4" color="textPrimary">
                                            Info
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider variant="middle" />
                            <div className={classes.section2}>
                                <Typography gutterBottom variant="body1" color="textPrimary">
                                    Created At: {oneClass?.data?.createdAt?.substring(0, 10)}
                                </Typography>
                            </div>
                            {isEdit ? (
                                <div className={classes.section2}>
                                    <Typography gutterBottom variant="body1" color="textPrimary">
                                        Owner: {oneClass?.data?.owner?.firstName} {oneClass?.data?.owner?.lastName}
                                    </Typography>
                                </div>
                            ) : null}

                            <div className={classes.section2}>
                                <Typography gutterBottom variant="body1" color="textPrimary">
                                    Teacher:{' '}
                                    {oneClass?.data?.teachers
                                        .map(teacher => `${teacher.firstName} ${teacher.lastName}`)
                                        .join('| ')}
                                </Typography>
                            </div>
                        </div>
                    </Box>

                    <Box boxShadow={2} bgcolor="background.paper" mt={1} mr={1} p={1} style={{width: '20rem'}}>
                        <div className={classes.root}>
                            <div className={classes.section1}>
                                <Grid container alignItems="center">
                                    <Grid item xs>
                                        <Typography gutterBottom variant="h4" color="textPrimary">
                                            Files
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider variant="middle" />
                            <div className={classes.section2}>
                                <Typography gutterBottom variant="body1" color="textPrimary">
                                    Resume
                                    <input
                                        accept="*"
                                        className={classes.input}
                                        style={{display: 'none'}}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                    />
                                    <Button component="span" className={classes.button}>
                                        <PublishIcon />
                                        upload
                                    </Button>
                                </Typography>
                            </div>
                            <div className={classes.section2}>
                                <Typography gutterBottom variant="body1" color="textPrimary">
                                    Contract
                                </Typography>
                            </div>
                            <div className={classes.section2}>
                                <Typography gutterBottom variant="body1" color="textPrimary">
                                    Picture
                                    <input
                                        accept="*"
                                        className={classes.input}
                                        style={{display: 'none'}}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                    />
                                    <Button component="span" className={classes.button}>
                                        <PublishIcon />
                                        upload
                                    </Button>
                                </Typography>
                            </div>
                            <div className={classes.section2}>
                                <Typography gutterBottom variant="body1" color="textPrimary">
                                    Invoice 1
                                </Typography>
                            </div>
                        </div>
                    </Box>
                </Grid>
                <br />
                <br />

                {isEdit ? (
                    <Paper color="white" className={classes.paper_new}>
                        <Grid container className={classes.classes_grid}>
                            <Grid item xs={12}>
                                <div className={classes.paper_padding}>
                                    <div className={classes.section1}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12}>
                                                <Typography gutterBottom variant="h4" color="textPrimary">
                                                    Student Homework and Course Attendance Summary
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider variant="middle" />
                                    </div>
                                    <TableContainer component={Paper} className={classes.table}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Student ID</TableCell>
                                                    <TableCell align="center">Student Name</TableCell>
                                                    <TableCell align="center">Homework Submission Rate</TableCell>
                                                    <TableCell align="center">Homework Average Score</TableCell>
                                                    <TableCell align="center">Course Attendance Rate</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>{renderMap()}</TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                ) : null}
                <ClassDeliver isEdit={isEdit} classHistory={classHistory} oneClass={oneClass} />
                <br />
                <br />

                <Homework isEdit={isEdit} homework={homework} />

                {/* NOTE: In mui5, we cannot conditionally render content inside the Modal by using "circuit operation" */}
                <Modal
                    open={customerFloatOpen}
                    onClose={() => {
                        setCustomerFloatOpen(false)
                    }}
                >
                    {showStudentDetail ? (
                        <Box className={classes.floatingWindow}>
                            <CheckCustomerFloat // floating window of student details
                                studentId={showStudentDetail}
                                setChildStudentId={setChildStudentId}
                            />
                        </Box>
                    ) : (
                        <Box />
                    )}
                </Modal>
            </div>
        </ViewHead>
    )
}

const mapStateToProps = state => ({
    oneClass: state.classes?.oneClass,
    classHistory: state.classes?.classHistory,
    homework: state.classes?.homework,
    customers: state.customer?.customers,
    classViewData: state.classes?.classViewData
})
export default connect(mapStateToProps, {
    fetchClassHistory,
    fetchHomework,
    fetchClass
})(ReadClass)
