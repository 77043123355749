import React from "react";
import { Navigate } from 'react-router';
import SyllabusMainView from '../../../views/training/syllabus';

const syllabusRoutes = [
    {
        path: 'syllabus',
        routes: [
            {
                path: "",
                exact: 'true',
                component:<SyllabusMainView/>
            },
            {
                component:<Navigate to="404"/> // this must be the last one
            },

        ]
    },
]

export default syllabusRoutes;