import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Button, Paper, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import { a11yProps } from '../../../components/mui/tabs/TabPanel';
import {
  AdministrativeActionType,
  clearInvoiceNotes, createInvoiceNote, deleteInvoiceNote,
  fetchInvoiceNotes
} from '../../../actions/administrative/administrativeAction';
import useAuthCheck from '../../../hooks/useAuthCheck';
import InvoiceTabView from './InvoiceTabView';
import DraggableDialog from "../../../components/mui/dialog/DraggableDialog";
import ContractNotesController from "../../../components/NotesManager/NotesManager";

function InvoiceListView() {
  useCurrentViewCheck(Functions.Administrative_Invoice_List)
  const  navigate = useNavigate();
  const [ canCreateInvoice ] = useAuthCheck(([
    Functions.Administrative_Invoice_Create,
  ]))

  const dispatch = useDispatch()
  const allNotes = useSelector(state => state?.administrative.currentInvoiceNotes)
  const currentTab = useSelector(state => state?.administrative.currentInvoiceTab)

  const [cid4Notes, setCid4Notes] = useState(null)
  const [openNotesModal, setNotesModalOpen] = useState(false)

  const handleChange = (event, newValue) =>
    dispatch({ type: AdministrativeActionType.ChangeInvoiceTab, payload: newValue })

  const handleNotesClicked = (e) => {
    setCid4Notes(e)
    setNotesModalOpen(true)
  }

  const handleNotesClosed = () => {
    clearInvoiceNotes()(dispatch)
    setCid4Notes(null)
    setNotesModalOpen(false)
  }

  const renderTabs = () => (
      <Paper elevation={0} style={{paddingBottom: '16px'}}>
        <AppBar position='sticky' color='default'>
          <Tabs value={currentTab} onChange={handleChange}>
            <Tab label="All Invoices" {...a11yProps(0)} />
            <Tab label="Asked" {...a11yProps(1)} />
            <Tab label="Issuing" {...a11yProps(2)} />
          </Tabs>
        </AppBar>

          <InvoiceTabView
            canCreateInvoice={canCreateInvoice}
            openNotesHandler={handleNotesClicked}
          />
      </Paper>
    )

  const renderAddNewButton = () => (
      <div>
        {canCreateInvoice
          ? <Button color='secondary'
                    variant='contained'
                    onClick={() => navigate('/app/administrative/invoice/add')}>
            Add New
          </Button>
          : ''
        }
      </div>
    )

  return (
    <ViewHead functionId={Functions.Administrative_Invoice_List} noMaxWidth topButtons={renderAddNewButton()} >
      {
        renderTabs()
      }

      <DraggableDialog open={Boolean(openNotesModal)} onClose={handleNotesClosed}
                       title='Edit Note' isLoaded={Boolean(openNotesModal)}>
        <Paper elevation={0}>
          <ContractNotesController nid={cid4Notes}
                                   allNotes={allNotes}
                                   fetchNotesAction={fetchInvoiceNotes}
                                   createNotesAction={createInvoiceNote}
                                   deleteNotesAction={deleteInvoiceNote}/>
        </Paper>

      </DraggableDialog>
    </ViewHead>
  )
}

export default InvoiceListView
