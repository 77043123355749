import React, { useEffect, useState } from 'react';
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select, Switch,
  TextField
, Autocomplete } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import {
  BBS_SITE_LIST,
  getTopUpAccountGroups,
  TOP_UP_USER_VALIDATOR,
  useMatricAccountEditorView
} from '../../../matricHelper';
import SubmitButton from '../../../../../components/mui/button/SubmitButton';
import { addTopUpUser, editTopUpUser, fetchAllTopUpUsers } from '../../../../../actions/matric/topupActions';
import LoadingScreen from '../../../../../components/public/LoadingScreen';
import { sendErrorMessage } from '../../../../../actions';

const schema = yup.object().shape(TOP_UP_USER_VALIDATOR)
function TopUpAccountEditor({ isEdit=false, originalData=null, site='', onSubmitSuccess }) {

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const classes = useMatricAccountEditorView()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const allTopUpUsers = useSelector(state => state.matric.allTopUpUsers)

  const [isSaving, setIsSaving] = useState(false)
  const [isAllUsersLoaded, setIsAllUsersLoaded] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const [allGroups, setAllGroups] = useState([])
  const [selectedSite, setSelectedSite] = useState(isEdit ? site : '')
  const [selectedGroup, setSelectedGroup] = useState(isEdit ? originalData.group : '')
  const [selectedInputGroup, setSelectedInputGroup] = useState(isEdit ? originalData.group : '')
  const [isEnabled, setIsEnabled] = useState(isEdit ? originalData.isActive : true)

  const loadData = () => {
    (async() => {
      setIsAllUsersLoaded(await fetchAllTopUpUsers()(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    if (isAllUsersLoaded && !isLoaded) {
      // get list of all groups for free solo
      setAllGroups(getTopUpAccountGroups(allTopUpUsers))
      setIsLoaded(true)
    }
  }, [isAllUsersLoaded, allTopUpUsers])

  const onSuccess = (msg) => onSubmitSuccess(msg)
  const onError = (msg) => {
    setIsSaving(false)
    sendErrorMessage(dispatch, msg)
  }

  const onSubmit = async (formData) => {

    // test group
    const group = selectedInputGroup.trim()
    if (!group || group.length < 1 || group.length > 200) {
      setError('group', { type: 'manual', message: 'Group must be between 1 to 200 characters' })
      return
    }

    // Fill in required info
    formData.group = group
    formData.site = selectedSite
    formData.isActive = isEnabled

    if (!isEdit) {
      // when adding a new user, save the user who's adding it
      formData.owner = `${auth.user.firstName} ${auth.user.lastName}`
    }

    if (!isEdit && !formData?.password) {
      // Do not want to change password
      delete formData.password
    }

    setIsSaving(true)
    if (isEdit) {
      await editTopUpUser(formData, onSuccess, onError)(dispatch)
      
    } else {
      await addTopUpUser(formData, onSuccess, onError)(dispatch)
      
    }
  }

  const renderView = () =>
    <Paper className={classes.paper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignContent='center' justifyContent='center' spacing={3}>
          {/* Select Site */}
          <Grid item xs={12} sm={6}>
            <FormControl variant='outlined' size='small' fullWidth required disabled={isSaving}>
              <InputLabel>Site</InputLabel>
              <Select fullWidth name='site' label='Site *'
                      value={selectedSite} required
                      onChange={(e) =>
                        setSelectedSite(e.target.value)}>
                {
                  BBS_SITE_LIST.filter(s => s.name !== '58').map((u,i) =>
                    <MenuItem key={i} value={u.symbol}>{ u.name }</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={Boolean(errors.group?.message)}>
              <Autocomplete
                freeSolo
                disabled={isSaving}
                options={allGroups}
                value={selectedGroup}
                onChange={(e, v) => setSelectedGroup(v)}
                inputValue={selectedInputGroup}
                onInputChange={(e, v) => setSelectedInputGroup(v)}
                renderInput={(params) =>
                  <TextField {...params} label='Group' variant='outlined' size='small' required />
                }
              />
              <FormHelperText error={Boolean(errors.group?.message)}>
                { errors.group?.message ? errors.group?.message : 'Non-matching input will create a new group' }
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField fullWidth size='small'
                       label='Username' required
                       disabled={isSaving} variant='outlined'
                       name='username' inputRef={register}
                       defaultValue={ isEdit ? originalData.username : '' }
                       error={Boolean(errors.username?.message)}
                       helperText={errors.username?.message ? errors.username?.message : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth size='small'
                       label='Password' required={!isEdit}
                       disabled={isSaving} variant='outlined'
                       name='password' inputRef={register}
                       error={Boolean(errors.password?.message)}
                       helperText={errors.password?.message
                         ? errors.password?.message
                         :  isEdit
                           ? 'Leave blank to not change password'
                           : ''
                       }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField fullWidth size='small' label='Description'
                       disabled={isSaving} variant='outlined'
                       name='notes' inputRef={register}
                       defaultValue={ isEdit ? originalData.notes : '' }
                       error={Boolean(errors.notes?.message)}
                       helperText={errors.notes?.message ? errors.notes?.message : ''}
            />
          </Grid>

          {/* Bottom Submit Button */}
          <Grid item xs={12}>
            <Grid container spacing={1} direction='row' justifyContent='space-between'>
              <Grid item>
                <FormControlLabel control={
                  <Switch
                    checked={isEnabled}
                    onChange={e => setIsEnabled(e.target.checked)}
                  />
                } label='Enabled' />
              </Grid>
              <Grid item>
                <SubmitButton isSaving={isSaving} text='Submit' isSavingText='Submitting'
                              fullWidth variant='contained' color='primary'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>

  return (
    <div>
      {
        isLoaded
          ? renderView()
          : <LoadingScreen message='Loading...' />
      }
    </div>
  )
}

export default TopUpAccountEditor
