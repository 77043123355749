import React from 'react';
import { useSelector} from 'react-redux';

import PropTypes from 'prop-types';

function AuthCheck({children,functionId}) {
    const auth = useSelector(state=>state.auth)
    if(!functionId){
        return null;
    }

    if(auth && auth.auth && auth.auth.length >0 && auth.auth.includes(functionId)) {
        return (
            <div>
                {children}
            </div>
        )
    }
        return null;
    
}

AuthCheck.propTypes = {
    functionId:PropTypes.number.isRequired
};
export default AuthCheck;