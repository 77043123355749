import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Container, FormControlLabel, Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../../utils/Functions';
import ViewHead from '../../../../components/public/ViewHead';
import LoadingScreen from '../../../../components/public/LoadingScreen';
import {
  downloadUnsignedInvoicePdf, fetchEmailAccount,
  fetchInvoice,
  sendInvoiceEmail
} from '../../../../actions/administrative/administrativeAction';
import { useInvoiceListStyles } from '../invoiceHelper';
import InvoiceDisplayLoader from './InvoiceDisplayLoader';
import { sendErrorMessage, sendSuccessMessage } from '../../../../actions';
import { ServerBaseURL } from '../../../../constants';

function InvoicePreview() {

  useCurrentViewCheck(Functions.Administrative_Invoice_Details)
  const  navigate = useNavigate();
  const {uid} = useParams()
  const {iid} = useParams()
  const dispatch = useDispatch()
  const classes = useInvoiceListStyles()
  const currentInvoice = useSelector(state => state.administrative.currentInvoice)
  const currentEmail = useSelector(state => state.administrative.currentEmailAccount)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isInvoiceLoaded, setIsInvoiceLoaded] = useState(false)
  const [isEmailLoaded, setIsEmailLoaded] = useState(false)
  const [emailText, setEmailText] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [paid,setPaid] = useState(false)
  const [checked, setChecked] = React.useState(false);

  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(()=>setPaid(checked),[checked])

  const loadData = () => {
    (async() => {
      setIsInvoiceLoaded(await fetchInvoice(uid, iid)(dispatch))
    })()
  }
  useEffect(loadData, [dispatch, uid, iid])

  useEffect(() => {
    const onRun = async () => {
      if (isInvoiceLoaded) {
        if (currentInvoice?.head?.emailAccountId) {
          setIsEmailLoaded(await fetchEmailAccount(currentInvoice?.head?.emailAccountId)(dispatch))
        } else {
          setIsEmailLoaded(true)
        }
      }
    }
    onRun().then()
  }, [isInvoiceLoaded])

  useEffect(() => {
    if (isEmailLoaded) {
      if (currentInvoice?.head?.emailAccountId) {
        setEmailText(`Send to Customer as ${currentEmail.name}`)
      } else {
        setEmailText('Send to Customer as Default LMS')
      }
      setIsLoaded(true)
    }
  }, [isEmailLoaded, currentEmail])

  const onEmail = () => {
    setIsSending(true)
    sendInvoiceEmail({ uid: parseInt(uid,10), iid, serverUrl: ServerBaseURL },
      (data) => {
        sendSuccessMessage(dispatch, `Invoice sent to ${data?.data?.to}`)
        setIsSending(false)
        setIsSent(true)
      },
      (msg) => {
        sendErrorMessage(dispatch, msg)
        setIsSending(false)
      },
    )(dispatch)
  }


  const onDownloadUnsigned = () => {
    setIsSending(true)
    let productName = 'No Product'
    try {
      productName = JSON.parse(currentInvoice.items)[0].description || 'Invoice'
    } catch (e) {
      // console.log('Error renaming file\n', e)
      sendErrorMessage(dispatch,`Error renaming file\n${e}`)
    }
    downloadUnsignedInvoicePdf(paid,iid, parseInt(uid,10), productName,
      `${currentInvoice.customer.firstName}_${currentInvoice.customer.lastName}`,
      () => setIsSending(false),
      (msg) => {
        sendErrorMessage(dispatch, msg)
        setIsSending(false)
      },
    )(dispatch)
  }

  const renderInvoice = () =>
    <Container style={{
      padding: 0
    }}>
      <InvoiceDisplayLoader invoice={currentInvoice} paid={paid}/>

      { currentInvoice?.status !== 'done' ?
        <Grid container justifyContent='center' spacing={4} className={classes.submitButtonArea}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckedChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                /> }
              label="invoice paid"
            />
          </Grid>
          <Grid item>
            <Button color='primary'
                    variant='contained'
                    disabled={isSending}
                    onClick={() => navigate(`/app/administrative/invoice/edit/${uid}/${iid}`)}>
              Edit
            </Button>
          </Grid>
          <Grid item>
            <Button color='primary'
                    variant='contained'
                    disabled={isSending}
                    onClick={() => onDownloadUnsigned()}>
              Download Unsigned PDF
            </Button>
          </Grid>
          <Grid item>
            <Tooltip title={currentInvoice?.head?.emailAccountId
              ? `${currentEmail.mailAccount} → ${currentInvoice.email}`
              : `Send to ${currentInvoice.email}`} placement='top'>
              <Button color='secondary'
                      variant='contained'
                      disabled={isSending || isSent}
                      onClick={() => onEmail()}>
                { isSent ? 'Email Sent' : isSending ? 'Sending Invoice' : emailText }
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        :
        <Grid container justifyContent='center' spacing={4} className={classes.submitButtonArea}>
          <Grid item>
            <FormControlLabel
              control={
              <Checkbox
                checked={checked}
                onChange={handleCheckedChange}
                inputProps={{ 'aria-label': 'controlled' }}
              /> }
              label="invoice paid"
            />
          </Grid>
          <Grid item>
            <Button color='primary'
                    variant='contained'
                    disabled={isSending}
                    onClick={() => onDownloadUnsigned()}>
              Download Unsigned PDF
            </Button>
          </Grid>
        </Grid>
      }
    </Container>


  const renderBackButton = () =>
    <div className={classes.topButton}>
      <Button color='secondary'
              variant='contained'
              onClick={() => navigate('/app/administrative/invoice')}>
        Back to Invoice List
      </Button>
    </div>

  return (
    <ViewHead functionId={Functions.Administrative_Invoice_Details} topButtons={renderBackButton()}>
      {
        isLoaded
          ? renderInvoice()
          :
          <LoadingScreen message="Loading..." />
      }
    </ViewHead>
  )
}

export default InvoicePreview
