import {useDispatch} from 'react-redux'
import React from 'react'
import useCurrentViewCheck from '../../../../hooks/useCurrentViewCheck'
import {Functions} from '../../../../utils/Functions'
import {sendSuccessMessage} from '../../../../actions'
import ContractTermEditor from './ContractTermEditor'

function AddContractTermView() {
    useCurrentViewCheck(Functions.Review_M2W_Add)
    const dispatch = useDispatch()

    const onSuccess = msg => {
        sendSuccessMessage(dispatch, msg)
    }

    return <ContractTermEditor onSubmitSuccess={onSuccess} isEdit={false} originalData={{}} />
}

export default AddContractTermView
