import React, {useEffect, useState} from 'react'
import {
    Button,
    ButtonGroup,
    debounce,
    DialogActions,
    DialogContent,
    IconButton,
    Paper,
    SvgIcon,
    Tooltip,
    Badge,
    keyframes
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {useDispatch, useSelector} from 'react-redux'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import moment from 'moment'
import {Check, List, ListAlt, CheckCircle} from '@mui/icons-material'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import Grid from '@mui/material/Grid'
import TableRowsIcon from '@mui/icons-material/TableRows'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import MoneyOffOutlinedIcon from '@mui/icons-material/MoneyOffOutlined'
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined'
import GridViewIcon from '@mui/icons-material/GridView'
import clsx from 'clsx'
import {cloneDeep} from 'lodash'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import DialogContentText from '@mui/material/DialogContentText'
import Autocomplete from '@mui/material/Autocomplete'
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined'
import {useNavigate} from 'react-router-dom'
import {Functions} from '../../../utils/Functions'
import LoadingScreen from '../../../components/public/LoadingScreen'
import ViewHead from '../../../components/public/ViewHead'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import useAuthCheck from '../../../hooks/useAuthCheck'
import ResponsiveDialog from '../../../components/dialog/ResponsiveDialog'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'
import {
    commissionStatusFormatter,
    emailLinkGenerator,
    listStatusFormatter,
    useContractListStyles
} from './contractHelper'
import NotesManager from '../../../components/NotesManager/NotesManager'
import PageGrid, {DEFAULT_FETCH_PARAM} from '../../../components/mui/datagrid/PageGrid'
import {DetailModal} from '../../customer/interview/interviewHelper'
import CheckCustomerFloat from '../../customer/register/CheckCustomerFloat'
import {copyToClipboard} from '../../../utils/Scripts'
import CommissionManager from './contractCommission/CommissionManager'
import ContractEmailsManagement from './contractEmailsView/ContractEmailsManagement'
import ContractPaymentHistoryManagement from './contractPaymentHistory/ContractPaymentHistoryManagement'
import {ContractPaymentLogView} from './contractPaymentLogView/ContractPaymentLogView'
import CreateQrCodeView from '../../forms/formList/createQrCodeView'
import DraggableDialog from '../../../components/mui/dialog/DraggableDialog'
import SearchBox from '../../../components/public/SearchBox'

import OneContractView from './contractPreview'

import {
    clearContractNotes,
    closeContract,
    confirmContract,
    createContractNote,
    deleteContractNote,
    downloadContractPdf,
    fetchAllContract,
    fetchContract,
    fetchContractNotes,
    updateContractStatus,
    fetchAllCustomersForSearchBar,
    AdministrativeActionType,
    forceDoneContract
} from '../../../actions/administrative/administrativeAction'
import ContractCardList from './ContractCardList'
import {centsToDollarString, centsToDollarStringCAD} from '../../../utils/PaymentHelper'
import Tags from '../../../components/public/Tags'
import {fetchTagsByGroup} from '../../../actions/public/tagActions'

// import Tags from "../../../components/public/Tags";
// import {fetchTagsByGroup} from "../../../actions/public/tagActions";

const useStyles = makeStyles({
    contractHover: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
})

function ContractView() {
    const classesMui = useStyles()
    useCurrentViewCheck(Functions.Administrative_Contract_List)
    const classes = useContractListStyles()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const navigate = useNavigate()
    const allContracts = useSelector(state => state?.administrative.allContracts)
    const filteredCustomerList = useSelector(state => state?.administrative.filteredCustomers)
    const allNotes = useSelector(state => state?.administrative.currentContractNotes)
    // const [isLoaded, setIsLoaded] = useState(false)
    const isLoaded = false
    const [hashDone, setHashDone] = useState('init2')
    const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false)
    const [selectedForDone, setSelectedForDone] = useState('')
    const [selectedForceDone, setSelectedForceDone] = useState('')
    const [checkboxSelected, setCheckboxSelected] = useState([])
    const [tableDisplay, setTableDisplay] = useState(false)
    const [openForceDoneDialogue, setOpenForceDoneDialogue] = useState(false)
    const [cid4Notes, setCid4Notes] = useState(null)
    const [openModal, setModalOpen] = useState(false)
    const [hashForceDone, setHashForceDone] = useState('init3')
    const [open, setOpen] = useState(false)
    const [listOpen, setListOpen] = useState(false)
    const [customerID, setCustomerID] = useState([])
    const [canSubmit, setCanSubmit] = useState(false)
    const [customerLoaded, setCustomerLoaded] = useState(false)
    const [refreshLoaded, setRefreshLoaded] = useState(true)

    const [selectedContractCommission, setSelectedContractCommission] = useState(null)
    const [selectContractEmailsManagement, setSelectContractEmailsManagement] = useState(null)
    const [selectedContractPaymentHistory, setSelectedContractPaymentHistory] = useState(null)
    const [canCreateContract, canCommission] = useAuthCheck([
        Functions.Administrative_Contract_Create,
        Functions.Administrative_Contract_Commission
    ])

    const [viewAmount, setViewAmount] = useState(false)
    const [isPaymentLogView, setIsPaymentLogView] = useState(false)
    const [openViewQR, setOpenViewQR] = useState(false)
    const [qrLink, setQrLink] = useState('')
    const fetchProps = useSelector(state => state.administrative.contractFetchBody)
    // const [clickedRow, setClickedRow] = useState("")
    const [customerSearchArgs, setCustomerSearchArgs] = useState({
        searchKey: undefined
    })
    const [isLoading, setIsLoading] = useState(false)

    const [openContract, setOpenContract] = useState(false)
    const currentContract = useSelector(state => state.administrative.currentContract)
    const [isContractLoaded, setIsContractLoaded] = useState(false)

    // Tag
    const allTags = useSelector(state => state.tag.tagByGroup)

    const [canEditContract] = useAuthCheck([Functions.Interview_Edit])

    const loadDataTag = () => {
        ;(async () => {
            const group = 'Contract'
            setIsLoading(await fetchTagsByGroup(group)(dispatch))
        })()
    }

    // if mouse moved into the row
    const [hoveredRow, setHoveredRow] = useState(null)

    useEffect(loadDataTag, [])
    const shake = keyframes`
  0% { transform: rotate(0deg); }    
  100% { transform: rotate(359deg); }
`

    const loadData = () => {
        fetchAllContract({
            searchArgs,
            ...DEFAULT_FETCH_PARAM
        })(dispatch)
    }
    const refreshData = async () => {
        setRefreshLoaded(false)
    }

    useEffect(async () => {
        if (!refreshLoaded) {
            await fetchAllContract(fetchProps)(dispatch)
                .then(() => setRefreshLoaded(true))
                .catch(() => {
                    sendErrorMessage(dispatch, 'Refresh Failed')
                    setRefreshLoaded(true)
                })
        }
    }, [refreshLoaded])

    const {searchKey} = customerSearchArgs
    const loadAllCustomerData = async () => {
        if (searchKey !== undefined) {
            setCustomerLoaded(false)

            await fetchAllCustomersForSearchBar({
                searchKey,
                ...DEFAULT_FETCH_PARAM
            })(dispatch).then(res => {
                setCustomerLoaded(res)
            })
        }
    }
    useEffect(loadData, [])
    useEffect(loadAllCustomerData, [customerSearchArgs])

    useEffect(() => {
        // called so we can update the contract commission when the commission is updated/added
        if (Array.isArray(allContracts?.data) && selectedContractCommission) {
            // find the selected contract inside allContracts
            const foundContract = allContracts.data.find(contract => contract.id === selectedContractCommission.id)
            setSelectedContractCommission(cloneDeep(foundContract))
        }
        // called so we can update the contract payments emails when the email list is updated/added
        if (Array.isArray(allContracts?.data) && selectContractEmailsManagement) {
            // find the selected contract inside allContracts
            const foundContract = allContracts.data.find(contract => contract.id === selectContractEmailsManagement.id)
            setSelectContractEmailsManagement(cloneDeep(foundContract))
        }

        if (Array.isArray(allContracts?.data) && selectedContractPaymentHistory) {
            // find the selected contract inside allContracts
            const foundContract = allContracts.data.find(contract => contract.id === selectedContractPaymentHistory.id)
            setSelectedContractPaymentHistory(cloneDeep(foundContract))
        }
    }, [allContracts])

    const handleConfirmOpen = id => {
        setSelectedForDone(id)
        setOpenConfirmDialogue(true)
        setHashDone(Math.random().toString(16).substring(2, 10))
    }

    const handleForceDoneOpen = id => {
        setSelectedForceDone(id)
        setOpenForceDoneDialogue(true)
        setHashForceDone(Math.random().toString(16).substring(2, 10))
    }

    const onSuccess = msg => sendSuccessMessage(dispatch, msg)
    const onError = msg => sendErrorMessage(dispatch, msg)

    const onConfirm = () => {
        confirmContract(
            selectedForDone,
            msg => onSuccess(msg),
            msg => onError(msg),
            fetchProps
        )(dispatch)
    }
    const onForceDone = () => {
        forceDoneContract(
            selectedForceDone,
            msg => onSuccess(msg),
            msg => onError(msg),
            fetchProps
        )(dispatch)
    }
    const handleFileOpen = (cid, productName, customerName) => {
        downloadContractPdf(cid, productName, customerName, msg => {
            onError(msg)
        })(dispatch)
    }

    const handleNotesClicked = e => {
        setCid4Notes(e)
        setModalOpen(true)
    }

    const handleNotesClosed = () => {
        clearContractNotes()(dispatch)
        setCid4Notes(null)
        setModalOpen(false)
    }
    const handleNewContractOpen = () => {
        setOpen(true)
    }
    const handleNewContractClose = () => {
        setOpen(false)
        dispatch({type: AdministrativeActionType.ResetCustomersFilterList})
    }

    const onStatusChange = isActive => {
        updateContractStatus(
            checkboxSelected.map(x => parseInt(x, 10)),
            isActive,
            fetchProps,
            msg => {
                setCheckboxSelected([])
                onSuccess(msg)
            },
            msg => onError(msg)
        )(dispatch)
    }

    const [searchArgs, setSearchArgs] = useState({
        searchKey: undefined
    })

    const handleSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase()
        setSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }
    const searchHandler = debounce(searchVal => {
        handleSearchChange(searchVal)
    }, 500)

    const onLinkCopy = uid => copyToClipboard(dispatch, uid)

    const renderSearchContainer = handleChange => (
        <Grid item xs={12}>
            <div className={classes.search}>
                <SearchBox
                    placeholder="Search..."
                    size="small"
                    type="search"
                    searchHandler={handleChange}
                    isLoading={isLoading}
                />
            </div>
        </Grid>
    )

    const handleCustomerSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase()

        if (searchStr === '' || searchStr === undefined) {
            dispatch({type: AdministrativeActionType.ResetCustomersFilterList})
            return
        }
        setCustomerSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }
    const handleCustomerSearch = debounce(searchVal => {
        handleCustomerSearchChange(searchVal)
    }, 500)

    // Add Contract Button & Customer Search Bar
    const renderTopButtons = () => (
        <div>
            <Button
                color="secondary"
                variant="contained"
                startIcon={
                    <SvgIcon fontSize="small">
                        <AddCircleOutlineIcon />
                    </SvgIcon>
                }
                onClick={handleNewContractOpen}
            >
                {' '}
                Add New Contract
            </Button>
            <DraggableDialog open={open} onClose={handleNewContractClose} isLoaded={open} title="Add New Contract">
                <Grid container spacing={5} direction="column" justifyContent="center" alignItems="center">
                    <DialogContent
                        sx={{
                            paddingLeft: 9,
                            paddingRight: 5,
                            paddingTop: 5
                        }}
                    >
                        <DialogContentText sx={{paddingBottom: 2}}>Search for a Customer.</DialogContentText>

                        <Grid item>
                            <Autocomplete
                                freeSolo
                                open={listOpen}
                                onOpen={() => {
                                    setListOpen(true)
                                }}
                                onClose={() => {
                                    setListOpen(false)
                                }}
                                id="customer-select-combo-box"
                                filterOptions={filteredList => filteredList}
                                options={filteredCustomerList || []}
                                getOptionLabel={option =>
                                    `${option.firstName} ${option?.nickName !== '' ? `(${option?.nickName})` : ''} ${
                                        option.lastName
                                    }`
                                }
                                renderOption={(props, option) => (
                                    <Box {...props} key={option.id}>
                                        {option.isActive ? (
                                            <div>
                                                {`${option.firstName} ${
                                                    option?.nickName !== '' ? `(${option?.nickName})` : ''
                                                } ${option.lastName} (${option.email})`}
                                            </div>
                                        ) : (
                                            <del>
                                                {`${option.firstName} ${
                                                    option?.nickName !== '' ? `(${option?.nickName})` : ''
                                                } ${option.lastName} (${option.email})`}
                                            </del>
                                        )}
                                    </Box>
                                )}
                                isOptionEqualToValue={(option, value) =>
                                    option.firstName === value.firstName &&
                                    option.nickName === value.nickName &&
                                    option.lastName === value.lastName
                                }
                                onInputChange={() => {
                                    setCanSubmit(false)
                                }}
                                onChange={(event, value) => {
                                    setCustomerID(value?.id)
                                    if (value === '' || value === undefined || value === null) {
                                        setCanSubmit(false)
                                    } else {
                                        setCanSubmit(true)
                                    }
                                }}
                                sx={{width: 350}}
                                renderInput={params => (
                                    <div className={classes.searchBox}>
                                        <SearchBox
                                            placeholder="Search..."
                                            size="small"
                                            type="search"
                                            searchHandler={handleCustomerSearch}
                                            isLoading={!customerLoaded && listOpen && searchKey !== undefined}
                                            params={params}
                                            inputProps={params.InputProps}
                                        />
                                    </div>
                                )}
                            />
                        </Grid>

                        <Grid item xs={15}>
                            <DialogActions sx={{paddingTop: 2}}>
                                <Button onClick={handleNewContractClose}>Cancel</Button>
                                <Tooltip
                                    title={canSubmit ? '' : 'Please Select A Customer From the List'}
                                    placement="bottom"
                                >
                                    <span>
                                        <Button
                                            disabled={!canSubmit}
                                            onClick={() => {
                                                if (canSubmit) {
                                                    navigate(`/app/administrative/contract/add/${customerID}`)
                                                }
                                                dispatch({type: AdministrativeActionType.ResetCustomersFilterList})
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </span>
                                </Tooltip>
                            </DialogActions>
                        </Grid>
                    </DialogContent>
                </Grid>
            </DraggableDialog>
        </div>
    )

    const renderGrid = () => {
        const columns = []

        const calculateTotalPaid = contractPaymentHistory => {
            let total = 0
            for (let i = 0; i < contractPaymentHistory?.length; i += 1) {
                total += parseFloat(contractPaymentHistory[i]?.amount)
            }
            return total
        }

        if (canCreateContract) {
            columns.push({
                field: '_actions',
                headerName: ' ',
                resizable: false,
                disableClickEventBubbling: true,
                width: 200,
                filterable: false,
                renderCell: params => (
                    <div key={`actions-${params.row.cid}`}>
                        <Tooltip title="More" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() =>
                                    navigate(
                                        `/app/administrative/contract/detail/${params.row.customer?.id}/${params.row.cid}`
                                    )
                                }
                            >
                                <List size={20} />
                            </IconButton>
                        </Tooltip>
                        {params.row.status === 'sent' && (
                            <Tooltip title="Copy Link" placement="top">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    component="span"
                                    onClick={() => {
                                        onLinkCopy(
                                            `${window.location.protocol}//${window.location.host}/contract/${params.row.customer?.id}/${params.row.cid}`
                                        )
                                    }}
                                >
                                    <FileCopyOutlinedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {params.row.status === 'sent' && (
                            <Tooltip title="Scan QR Code" placement="top">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    component="span"
                                    onClick={() => {
                                        // setClickedRow(params.row.cid)
                                        setOpenViewQR(true)
                                        setQrLink(
                                            `${window.location.protocol}//${window.location.host}/contract/${params.row.customer?.id}/${params.row.cid}`
                                        )
                                    }}
                                >
                                    <QrCodeScannerIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                )
            })
        }

        columns.push(
            ...[
                {field: 'id', headerName: 'ID', width: 80},

                {
                    field: 'cid',
                    headerName: 'Contract ID',
                    width: 180,
                    renderCell: params => (
                        <Box
                            key={`contractId-${params.row.cid}`}
                            className={classesMui.contractHover}
                            onClick={async () => {
                                setOpenContract(true)
                                setIsContractLoaded(
                                    await (!!params?.row?.customer?.id &&
                                        fetchContract(params?.row?.customer?.id, params?.row?.cid)(dispatch))
                                )
                            }}
                        >
                            {params.row.isActive ? params.value : <del>{params.value}</del>}
                        </Box>
                    )
                },
                {
                    field: 'customer_fullName',
                    headerName: 'Customer Name',
                    width: 230,
                    renderCell: params => (
                        <div key={`contractName-${params.row.cid}`}>
                            <DetailModal
                                disabled={!params.row.isActive}
                                name={`${params.row.customer.firstName}${
                                    params.row.customer?.nickName ? ` (${params.row.customer?.nickName}) ` : ' '
                                }${params.row.customer.lastName}`}
                                details={<CheckCustomerFloat studentId={params.row.customer.id} />}
                            />
                        </div>
                    )
                },
                {
                    field: 'customer.country',
                    headerName: 'Country',
                    width: 80,
                    renderCell: params => params.row.customer.country
                },
                {
                    field: '_viewNotes',
                    headerName: 'Note',
                    resizable: false,
                    disableClickEventBubbling: true,
                    width: 80,
                    filterable: false,
                    renderCell: params => (
                        <div key={`note-${params.row.cid}`}>
                            {params.row?.notes !== 0 ? (
                                <Tooltip title="View Notes" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => handleNotesClicked(params.row.cid)}
                                    >
                                        <Badge
                                            color="secondary"
                                            overlap="circular"
                                            className={classes.badge}
                                            badgeContent={params.row?.notes || 0}
                                        >
                                            <ListAlt size={20} />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <div key={`note-${params.row.cid}`}>
                                    {hoveredRow === params.row.cid && (
                                        <Tooltip title="View Notes" placement="top">
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                component="span"
                                                onClick={() => handleNotesClicked(params.row.cid)}
                                            >
                                                <ListAlt size={20} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                        </div>
                    )
                },
                {
                    field: '_viewPayerManagement',
                    headerName: 'Payer Info',
                    resizable: false,
                    disableClickEventBubbling: true,
                    width: 120,
                    filterable: false,
                    renderCell: params => (
                        <div key={`payerInfo-${params.row.cid}`}>
                            {params.row?.paymentEmails.length !== 0 ? (
                                <Tooltip title="View Payment Emails" placement="top">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        component="span"
                                        onClick={() => {
                                            setSelectContractEmailsManagement(params.row)
                                        }}
                                    >
                                        <Badge
                                            color="secondary"
                                            overlap="circular"
                                            className={classes.badge}
                                            badgeContent={params.row?.paymentEmails.length || 0}
                                        >
                                            <AccountBoxOutlinedIcon size={20} />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <div key={`payerInfo-${params.row.cid}`}>
                                    {hoveredRow === params.row.cid && (
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            onClick={() => {
                                                setSelectContractEmailsManagement(params.row)
                                            }}
                                        >
                                            <AccountBoxOutlinedIcon size={20} />
                                        </IconButton>
                                    )}
                                </div>
                            )}
                        </div>
                    )
                },
                {
                    field: '_viewPaymentManagement',
                    headerName: 'Payment Info',
                    resizable: false,
                    disableClickEventBubbling: true,
                    width: 120,
                    filterable: false,
                    renderCell: params => {
                        let percentage = 0
                        if (params?.row?.total) {
                            percentage = Math.round(
                                ((+(params?.row?.total || 0) - +(params?.row?.remaining || 0)) * 100) /
                                    +(params?.row?.total || 0)
                            )
                        } else {
                            percentage = Math.round(
                                (calculateTotalPaid(params.row?.contractPaymentHistory) / +(params.row?.price || 0)) *
                                    100
                            )
                        }

                        const amountInDollar = params.row?.currency?.currency
                            ? centsToDollarString(
                                  +(params.row?.total || 0) - +(params.row?.remaining || 0),
                                  params.row?.currency?.currency
                              )
                            : centsToDollarStringCAD(+(params.row?.total || 0) - +(params.row?.remaining || 0))

                        return (
                            <div
                                key={`paymentInfo-${params.row.cid}`}
                                style={{
                                    position: 'relative',
                                    outline: '1px solid #D7D7D7',
                                    borderRadius: '2px',
                                    width: '64px'
                                }}
                            >
                                {params.row?.contractPaymentHistory.length === 0 && Number.isNaN(percentage) ? (
                                    <div />
                                ) : (
                                    <div
                                        style={{
                                            borderRadius: '2px',
                                            background: `${percentage < 100 ? '#F1D094' : '#6FAB69'}`,
                                            position: 'absolute',
                                            height: '100%',
                                            width: `${percentage}%`
                                        }}
                                    />
                                )}

                                {/* payment emails management */}

                                <Tooltip title={amountInDollar} placement="top">
                                    <Button
                                        style={{overflow: 'hidden', width: '64px'}}
                                        variant="text"
                                        size="small"
                                        className={classes.commissionButton}
                                        onClick={() => setSelectedContractPaymentHistory(params.row)}
                                    >
                                        {viewAmount ? (
                                            <div
                                                style={{
                                                    fontWeight: '150',
                                                    overflow: 'hidden',
                                                    width: '100%'
                                                }}
                                            >
                                                {params.row?.currency?.currency
                                                    ? centsToDollarString(
                                                          +(params.row?.total || 0) - +(params.row?.remaining || 0),
                                                          +(params.row?.currency?.currency || 0)
                                                      )
                                                    : centsToDollarStringCAD(
                                                          +(params.row?.total || 0) - +(params.row?.remaining || 0)
                                                      )}
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    fontWeight: '150'
                                                }}
                                            >
                                                {!Number.isNaN(percentage) ? `${percentage}%` : 'N/A'}
                                            </div>
                                        )}
                                    </Button>
                                </Tooltip>
                            </div>
                        )
                    }
                },
                {
                    field: 'contract_status',
                    headerName: 'Status',
                    width: 120,
                    renderCell: params => listStatusFormatter(params.row.status, params.row.isForceDone)
                }
            ]
        )

        // Done => Show button to see Contract PDF File
        // Signed => Show button to see Contract PDF File and Confirm Button
        if (canCreateContract) {
            columns.push({
                field: '_contractActions',
                headerName: ' ',
                resizable: false,
                disableClickEventBubbling: true,
                width: 80,
                filterable: false,
                renderCell: params => (
                    <Box
                        key={`contractActions-${params.row.cid}`}
                        sx={{
                            display: 'flex',
                            flexWrap: 'noWrap'
                        }}
                    >
                        {['signed', 'done'].includes(params.row.status) && (
                            <div>
                                {hoveredRow === params.row.cid && (
                                    <Tooltip title="Contract" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            onClick={() =>
                                                handleFileOpen(
                                                    params.row.cid,
                                                    params.row.name,
                                                    params.row.customer_fullName
                                                )
                                            }
                                        >
                                            <PictureAsPdfOutlinedIcon size={20} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                        {params.row.status === 'signed' && (
                            <div>
                                {hoveredRow === params.row.cid && (
                                    <Tooltip title="Confirm" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            style={{color: 'green'}}
                                            onClick={() => handleConfirmOpen(params.row.cid)}
                                        >
                                            <Check size={20} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                        {params.row.status === 'done' && (
                            <div>
                                {hoveredRow === params.row.cid && (
                                    <a
                                        href={emailLinkGenerator({
                                            productName: params.row.name,
                                            customerName: params.row.customer?.firstName,
                                            customerEmail: params.row.email,
                                            userName: auth.user.firstName
                                        })}
                                    >
                                        <Tooltip title="Email Customer" placement="top">
                                            <IconButton color="primary" size="small" component="span">
                                                <EmailOutlinedIcon size={20} />
                                            </IconButton>
                                        </Tooltip>
                                    </a>
                                )}
                            </div>
                        )}
                        {['created', 'sent'].includes(params.row.status) && !params.row.isForceDone && (
                            <div>
                                {hoveredRow === params.row.cid && (
                                    <Tooltip title="Force done" placement="top">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            component="span"
                                            style={{color: 'green'}}
                                            onClick={() => handleForceDoneOpen(params.row.cid)}
                                        >
                                            <CheckCircle size={20} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    </Box>
                )
            })
        }

        if (canCommission) {
            columns.push({
                field: 'commissions',
                headerName: 'Commissions',
                width: 150,
                renderCell: params => (
                    <div key={`commissions-${params.row.cid}`}>
                        <Tooltip title="View Commissions" placement="top">
                            <Button
                                variant="text"
                                size="small"
                                className={classes.commissionButton}
                                onClick={() => setSelectedContractCommission(params.row)}
                            >
                                {commissionStatusFormatter(params.row?.commissions)}
                            </Button>
                        </Tooltip>
                    </div>
                )
            })
        }

        columns.push(
            ...[
                {field: 'contract_name', headerName: 'Product', width: 250, renderCell: params => params.row.name},

                // tag
                {
                    field: 'tag',
                    headerName: 'Tags',
                    width: 150,
                    renderCell: params => (
                        <div key={params?.row?.id} style={{display: 'flex', flexDirection: 'row'}}>
                            <Tags
                                datas={allTags}
                                current={params.row.id}
                                fetchData={fetchTagsByGroup}
                                canEdit={canEditContract}
                                group="Contract"
                                loadData={loadDataTag}
                            />
                        </div>
                    )
                },

                {
                    field: 'createdAt',
                    headerName: 'Created Date',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                },
                {
                    field: 'signedDate',
                    headerName: 'Signed Date',
                    width: 200,
                    valueFormatter: ({value}) => (value ? moment(value).format('L LT') : '')
                },
                {
                    field: 'confirmedDate',
                    headerName: 'Confirmed Date',
                    width: 200,
                    valueFormatter: ({value}) => (value ? moment(value).format('L LT') : '')
                },
                {
                    field: 'forceDoneDate',
                    headerName: 'ForceDone Date',
                    width: 200,
                    valueFormatter: ({value}) => (value ? moment(value).format('L LT') : '')
                },
                {
                    field: 'openDate',
                    headerName: 'Open Date',
                    width: 200,
                    valueFormatter: ({value}) => (value ? moment(value).format('L LT') : '')
                },
                {
                    field: 'readDate',
                    headerName: 'Read Date',
                    width: 200,
                    valueFormatter: ({value}) => (value ? moment(value).format('L LT') : '')
                },
                {field: 'sales_fullName', headerName: 'Sales Name', width: 200},
                {field: 'ip', headerName: 'Read IP', width: 200, renderCell: params => params.row.readTracking?.ip},
                {
                    field: 'device',
                    headerName: 'Read Device',
                    width: 250,
                    renderCell: params => params.row.readTracking?.device
                },
                {
                    field: 'browser',
                    headerName: 'Read Browser',
                    width: 250,
                    renderCell: params => params.row.readTracking?.browser
                },
                {
                    field: 'source',
                    headerName: 'Read Source',
                    width: 250,
                    renderCell: params => params.row.readTracking?.source
                },
                {
                    field: 'city',
                    headerName: 'Read City',
                    width: 200,
                    renderCell: params => params.row.readTracking?.city
                },
                {
                    field: 'country',
                    headerName: 'Read Country',
                    width: 200,
                    renderCell: params => params.row.readTracking?.country
                },
                {
                    field: 'postalCode',
                    headerName: 'Read Postal',
                    width: 200,
                    renderCell: params => params.row.readTracking?.postalCode
                },
                {
                    field: 'address',
                    headerName: 'Read Address',
                    width: 350,
                    renderCell: params => params.row.readTracking?.address
                }
            ]
        )

        return (
            <div>
                <div style={{display: tableDisplay ? '' : 'none'}}>
                    <PageGrid
                        setHoveredRow={setHoveredRow}
                        columns={columns}
                        datas={allContracts}
                        searchArgs={searchArgs}
                        fetchDatas={fetchAllContract}
                        selected={checkboxSelected}
                        setSelected={setCheckboxSelected}
                        setIsLoading={setIsLoading}
                    />
                </div>
                <div style={{display: tableDisplay ? 'none' : ''}}>
                    <ContractCardList
                        dispatch={dispatch}
                        datas={allContracts}
                        columns={columns}
                        auth={auth}
                        handleNotesClicked={handleNotesClicked}
                        handleFileOpen={handleFileOpen}
                        handleConfirmOpen={handleConfirmOpen}
                        handleForceDoneOpen={handleForceDoneOpen}
                        handleContractCommission={setSelectedContractCommission}
                        canCreateContract={canCreateContract}
                        canCommission={canCommission}
                        setSelectContractEmailsManagement={setSelectContractEmailsManagement}
                        setSelectedContractPaymentHistory={setSelectedContractPaymentHistory}
                        viewAmount={viewAmount}
                    />
                </div>
            </div>
        )
    }

    const renderStatusButtons = () => (
        <Grid item className={classes.activityButtons} xs={12}>
            <ButtonGroup variant="contained" color="primary" size="small">
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(true)}>
                    Enable
                </Button>
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(false)}>
                    Disable
                </Button>
            </ButtonGroup>

            <IconButton
                color="primary"
                size="small"
                component="span"
                sx={
                    refreshLoaded
                        ? {marginLeft: '10px'}
                        : {marginLeft: '10px', animation: `${shake} .7s linear infinite;`}
                }
                onClick={refreshData}
            >
                <SyncOutlinedIcon />
            </IconButton>
        </Grid>
    )

    const renderSwitchDisplayButton = () => (
        <ButtonGroup className={classes.displaySwitcher} variant="contained" color="primary" size="small">
            <Button onClick={() => setIsPaymentLogView(true)}>
                <PaymentOutlinedIcon />
            </Button>
            <Button onClick={() => setViewAmount(!viewAmount)}>
                {viewAmount ? <MoneyOffOutlinedIcon /> : <AttachMoneyOutlinedIcon />}
            </Button>
            <Button
                onClick={() => setTableDisplay(true)}
                className={clsx({
                    [classes.buttonSelected]: tableDisplay,
                    [classes.buttonUnselected]: !tableDisplay
                })}
            >
                <TableRowsIcon />
            </Button>
            <Button
                onClick={() => setTableDisplay(false)}
                className={clsx({
                    [classes.buttonSelected]: !tableDisplay,
                    [classes.buttonUnselected]: tableDisplay
                })}
            >
                <GridViewIcon />
            </Button>
        </ButtonGroup>
    )

    const renderTopToolbar = handleChange => (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            {renderSearchContainer(handleChange)}
            <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                <Grid item>{canCreateContract ? renderStatusButtons() : <div />}</Grid>
                <Grid item>{renderSwitchDisplayButton()}</Grid>
            </Grid>
        </Grid>
    )

    return (
        <ViewHead topButtons={renderTopButtons()} functionId={Functions.Administrative_Contract_List} noMaxWidth>
            <Paper elevation={0} className={classes.paper}>
                {!isLoaded ? (
                    <div>
                        {renderTopToolbar(searchHandler)}
                        {renderGrid()}
                    </div>
                ) : (
                    <LoadingScreen message="Loading..." />
                )}
            </Paper>

            <NotesManager
                nid={cid4Notes}
                allNotes={allNotes}
                fetchNotesAction={fetchContractNotes}
                createNotesAction={createContractNote}
                deleteNotesAction={deleteContractNote}
                isModalOpen={openModal}
                handleModalClose={handleNotesClosed}
            />

            <CommissionManager
                contract={selectedContractCommission}
                fetchProps={fetchProps}
                handleModalClose={() => setSelectedContractCommission(null)}
            />
            <ContractEmailsManagement
                contract={selectContractEmailsManagement}
                fetchProps={fetchProps}
                handleModalClose={() => setSelectContractEmailsManagement(null)}
            />

            {!!selectedContractPaymentHistory && (
                <ContractPaymentHistoryManagement
                    contract={selectedContractPaymentHistory}
                    fetchProps={fetchProps}
                    handleModalClose={() => setSelectedContractPaymentHistory(null)}
                />
            )}

            <ResponsiveDialog
                isOpen={openConfirmDialogue}
                openHash={hashDone}
                title="Confirm Contract"
                content={`Confirm Contract ${selectedForDone}?`}
                Buttons={[
                    {name: 'Yes', event: () => onConfirm()},
                    {name: 'Cancel', event: () => setOpenConfirmDialogue(false)}
                ]}
            />
            <ResponsiveDialog
                isOpen={openForceDoneDialogue}
                openHash={hashForceDone}
                title="Confirm Force Done Contract"
                content={`Confirm Force Done Contract ${selectedForceDone}?`}
                Buttons={[
                    {name: 'Yes', event: () => onForceDone()},
                    {name: 'Cancel', event: () => setOpenForceDoneDialogue(false)}
                ]}
            />
            <DraggableDialog
                open={Boolean(openViewQR)}
                onClose={() => setOpenViewQR(null)}
                title="View QR Code"
                isLoaded={Boolean(openViewQR)}
            >
                <Paper elevation={0}>
                    <CreateQrCodeView qrLink={qrLink} />
                </Paper>
            </DraggableDialog>

            <DraggableDialog
                open={Boolean(openContract)}
                onClose={async () => {
                    setOpenContract(null)
                    setIsContractLoaded(await closeContract()(dispatch))
                }}
                title={`Viewing ${currentContract?.isMain ? 'Contract' : 'Subcontract'} :  ${currentContract?.cid}`}
                isLoaded={Boolean(openContract)}
            >
                <Paper elevation={0}>
                    <OneContractView isDialog loaded={isContractLoaded} />
                </Paper>
            </DraggableDialog>
            <ContractPaymentLogView
                setIsPaymentLogView={setIsPaymentLogView}
                isPaymentLogView={isPaymentLogView}
                allContracts={allContracts}
            />
        </ViewHead>
    )
}

export default ContractView
