import * as yup from 'yup';

export const PHONE = '^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'
export const GMAIL = '^[\\w.+\\-]+@gmail\\.com$';
export const EMAIL =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email) {
    return EMAIL.test(String(email).toLowerCase());
}

export const CUSTOMER_VALIDATOR = {
    firstName: yup.string().required().min(1).max(30).trim(),
    lastName:yup.string().required().min(1).max(30).trim(),
    nickName:yup.string().required().min(1).max(50).trim(),
    country: yup.string().required('Country is required').min(2).max(15).trim(),
    address: yup.string().required('Address is required').min(5).max(150).trim(),
    email: yup.string().email().required().trim().matches(EMAIL,'email must be a valid email'),
    gmail: yup.lazy( (value)=>value===''?yup.string():yup.string().email().trim().lowercase().matches(new RegExp(GMAIL),"gmail must be formatted as XXX@gmail.com")),
    gitName: yup.string().trim(),
    city: yup.string().required('City is required').min(2).max(15).trim(),
    zipcode:yup.lazy( (value)=>value===''?yup.string():yup.string().min(6).max(7).trim()),
    phone: yup.string().required().trim()
        .min(10, "phone must be at least 10 numbers")
        .max(11, "phone must be at most 11 numbers")
        .matches(new RegExp(PHONE), "phone must be right type "),
    class: yup.array().min(1).max(3),
    wechat:yup.lazy((value)=>value===''?yup.string():yup.string().min(2).max(20).trim())
}
