import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Grid from '@mui/material/Grid'
import {Button, ButtonGroup, debounce, IconButton, Paper, Tooltip} from '@mui/material'
import {ListAlt} from '@mui/icons-material'
import {Copy, Edit} from 'react-feather'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import ResponsiveDialog from '../../../../components/dialog/ResponsiveDialog'
import PageGrid from '../../../../components/mui/datagrid/PageGrid'
import {
    fetchAllContractTemplates,
    fetchAllContractTerms,
    updateContractTemplateStatus,
    updateContractTermStatus
} from '../../../../actions/administrative/administrativeAction'
import {sendErrorMessage, sendSuccessMessage} from '../../../../actions'
import DraggableDialog from '../../../../components/mui/dialog/DraggableDialog'
import CreateContractView from '../editContractTemplateView'
import ContractView from '../contractTemplateView'
import SearchBox from '../../../../components/public/SearchBox'
import TabColumnEditComponent from './renderTab/tabColumnEditComponent'
import EditContractTermView from '../contractTerm/EditContractTermView'
import ReadContractTermView from '../contractTerm/ReadContractTermView'

const CONTRACT_TERM_HEADER_WIDTH = {
    id: 75,
    title: 200,
    description: 300,
    isDefault: 100
}

const ContractTemplateTab = ({classes, canCreateContract}) => {
    const allContracts = useSelector(state => state.administrative.allContractTemplates)
    const allContractTerms = useSelector(state => state.administrative.allContractTerms)
    const dispatch = useDispatch()
    const [checkboxSelected, setCheckboxSelected] = useState([])
    const navigate = useNavigate()
    const [clickedRow, setClickedRow] = useState('')
    const [openDetails, setOpenDetails] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [mode, setMode] = useState(null)

    const onDeleteSuccess = msg => sendSuccessMessage(dispatch, msg)
    const onDeleteError = msg => sendErrorMessage(dispatch, msg)

    const [hash, setHash] = useState('init')
    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)

    const fetchProps = useSelector(state => state.administrative.contractTemplateFetchBody)
    const currentTab = useSelector(state => state.administrative.currentTemplateTab)

    const categoryName = {
        0: 'body',
        1: 'head',
        3: 'term'
    }

    const [searchArgs, setSearchArgs] = useState({searchKey: undefined, category: categoryName[currentTab]})
    const [isLoading, setIsLoading] = useState(false)
    // id, details, edit column width
    const idColumnWidth = CONTRACT_TERM_HEADER_WIDTH.id
    const titleColumnWidth = CONTRACT_TERM_HEADER_WIDTH.title
    const descriptionColumnWidth = CONTRACT_TERM_HEADER_WIDTH.description
    const isDefaultWidth = CONTRACT_TERM_HEADER_WIDTH.isDefault

    // true: is contract tab; otherwise: is term tab
    const isContractOrTerm = currentTab !== 3
    const editTitle = isContractOrTerm ? 'Edit Templates' : `Edit Terms`
    const viewTitle = isContractOrTerm ? 'View Templates' : 'View Terms'

    // define term column array, which in charge of the column name's display
    const termColumnArr = [
        {
            field: 'id',
            headerName: 'ID',
            width: idColumnWidth
        },
        {
            field: '_view',
            headerName: 'Details',
            resizeable: false,
            disableClickEventBubbling: true,
            width: idColumnWidth,
            filterable: false,
            renderCell: params => (
                <TabColumnEditComponent
                    editName="Details"
                    onClick={() => {
                        setClickedRow(params?.row?.id)
                        setMode('detail')
                    }}
                />
            )
        },
        canCreateContract
            ? {
                  field: '_edit',
                  headerName: 'Edit',
                  resizeable: false,
                  disableClickEventBubbling: true,
                  width: idColumnWidth,
                  filterable: false,
                  renderCell: params => (
                      <div>
                          <TabColumnEditComponent
                              editName="Edit"
                              onClick={() => {
                                  setClickedRow(params?.row?.id)
                                  setMode('edit')
                              }}
                          />
                      </div>
                  )
              }
            : '',
        {
            field: 'title',
            headerName: 'Title',
            width: titleColumnWidth,
            renderCell: params => (params.row.isActive ? params.row.title : <del>{params.row.title}</del>)
        },
        {
            field: 'slug',
            headerName: 'Slug',
            width: titleColumnWidth
        },
        {
            field: 'isDefault',
            headerName: 'Default',
            width: isDefaultWidth
        },
        {
            field: 'text',
            headerName: 'Text',
            width: descriptionColumnWidth
        },
        {
            field: 'description',
            headerName: 'Description',
            width: descriptionColumnWidth
        },

        {
            field: 'createdAt',
            headerName: 'Created Date',
            width: titleColumnWidth,
            valueFormatter: ({value}) => moment(value).format('L LT')
        },
        {
            field: 'updatedAt',
            headerName: 'Updated Date',
            width: titleColumnWidth,
            valueFormatter: ({value}) => moment(value).format('L LT')
        }
    ]

    useEffect(() => {
        if (mode === 'edit') {
            setOpenEdit(true)
            setOpenDetails(false)
        } else if (mode === 'detail') {
            setOpenEdit(false)
            setOpenDetails(true)
        }
    }, [mode])

    useEffect(() => {
        setSearchArgs(prevState => ({
            ...prevState,
            category: categoryName[currentTab]
        }))
    }, [currentTab])

    const onStatusChange = (isActive, isDelete = undefined) =>
        dispatch(
            isContractOrTerm
                ? updateContractTemplateStatus(
                      checkboxSelected.map(x => parseInt(x, 10)),
                      fetchProps,
                      isActive,
                      isDelete,
                      msg => {
                          setCheckboxSelected([])
                          onDeleteSuccess(msg)
                      },
                      msg => onDeleteError(msg)
                  )
                : updateContractTermStatus(
                      checkboxSelected.map(x => parseInt(x, 10)),
                      fetchProps,
                      isActive,
                      isDelete,
                      msg => {
                          setCheckboxSelected([])
                          onDeleteSuccess(msg)
                      },
                      msg => onDeleteError(msg)
                  )
        )

    const handleSearchChange = searchVal => {
        const searchStr = searchVal.trim().toLowerCase()
        setSearchArgs(prevState => ({
            ...prevState,
            searchKey: searchStr
        }))
    }

    const searchHandler = debounce(searchVal => {
        handleSearchChange(searchVal)
    }, 500)
    const renderSearchBar = () => (
        <Grid item xs={12}>
            <div>
                <SearchBox
                    placeholder="Search..."
                    size="small"
                    type="search"
                    searchHandler={searchHandler}
                    isLoading={isLoading}
                />
            </div>
        </Grid>
    )
    const renderStatusButtons = () => (
        <Grid item className={classes.activityButtons} xs={12}>
            <ButtonGroup variant="contained" color="primary" size="small">
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(true)}>
                    Enable
                </Button>
                <Button disabled={checkboxSelected.length <= 0} onClick={() => onStatusChange(false)}>
                    Disable
                </Button>
                <Button
                    disabled={checkboxSelected.length <= 0}
                    onClick={() => {
                        setOpenDeleteDialogue(true)
                        setHash(Math.random().toString(16).substring(2, 10))
                    }}
                >
                    Delete
                </Button>
            </ButtonGroup>
        </Grid>
    )

    const renderContent = () => (
        <div>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                <Grid item xs={12}>
                    {renderSearchBar()}
                </Grid>
                <Grid item xs={12}>
                    {canCreateContract ? renderStatusButtons() : <div />}
                </Grid>
                <Grid item xs={12}>
                    {renderGrid()}
                </Grid>
            </Grid>
            <ResponsiveDialog
                isOpen={openDeleteDialogue}
                openHash={hash}
                title="Delete Template"
                content={`Delete Templates: ${checkboxSelected.join(', ')}?`}
                Buttons={[{name: 'Yes', event: () => onStatusChange(false, true)}, {name: 'Cancel'}]}
            />
        </div>
    )

    const renderGrid = () => {
        const columns = []
        columns.push(
            ...[
                {field: 'id', headerName: 'ID', width: 80},
                {
                    field: '_view',
                    headerName: ' ',
                    resizable: false,
                    disableClickEventBubbling: true,
                    width: 65,
                    filterable: false,
                    renderCell: params => (
                        <Tooltip title="Details" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={
                                    async () => {
                                        await setClickedRow(params.row.id)
                                        await setOpenDetails(true)
                                    }
                                    // navigate(`/app/administrative/contractTemplate/view/${params.row.id}`)
                                }
                            >
                                <ListAlt size={20} />
                            </IconButton>
                        </Tooltip>
                    )
                }
            ]
        )

        if (canCreateContract) {
            columns.push({
                field: '_edit',
                headerName: ' ',
                resizable: false,
                disableClickEventBubbling: true,
                width: 75,
                filterable: false,
                renderCell: params => (
                    <div>
                        <Tooltip title="Edit" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={
                                    async () => {
                                        await setClickedRow(params.row.id)
                                        await setOpenEdit(true)
                                    }
                                    // navigate(`/app/administrative/contractTemplate/edit/${params.row.id}`)
                                }
                            >
                                <Edit size={20} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clone" placement="top">
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() => navigate(`/app/administrative/contractTemplate/add/${params.row.id}`)}
                            >
                                <Copy size={20} />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            })
        }

        if (currentTab === 1) {
            columns.push({field: 'language', headerName: 'Lang', width: 60, valueFormatter: ({value}) => value.toUpperCase()})
            columns.push({field: 'cidPrefix', headerName: 'ID Prefix', width: 130})
            columns.push({
                field: 'referrals',
                headerName: 'Reps',
                width: 60,
                align: 'center',
                renderCell: params => (
                    <div>
                        {
                            params.row?.referrals?.length > 0
                                ? <Tooltip title={params.row.referrals.join(", ")} placement="top">
                                    { params.row.referrals.length }
                                </Tooltip>
                                : 0
                        }
                    </div>
                )
            })
        }

        columns.push(
            ...[
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 250,
                    renderCell: params => (params.row.isActive ? params.row.name : <del>{params.row.name}</del>)
                },
                {field: 'description', headerName: 'Description', width: 300},
                {
                    field: 'createdAt',
                    headerName: 'Date',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                },
                {
                    field: 'updatedAt',
                    headerName: 'Last Updated',
                    width: 200,
                    valueFormatter: ({value}) => moment(value).format('L LT')
                }
            ]
        )
        return (
            <div>
                <PageGrid
                    columns={isContractOrTerm ? columns : termColumnArr}
                    datas={isContractOrTerm ? allContracts : allContractTerms}
                    searchArgs={searchArgs}
                    fetchDatas={isContractOrTerm ? fetchAllContractTemplates : fetchAllContractTerms}
                    selected={checkboxSelected}
                    setSelected={setCheckboxSelected}
                    setIsLoading={setIsLoading}
                />

                <DraggableDialog
                    open={Boolean(openDetails)}
                    onClose={() => {
                        setOpenDetails(null)
                        setMode(null)
                    }}
                    title={viewTitle}
                    isLoaded={Boolean(openDetails)}
                >
                    <Paper elevation={0}>
                        {isContractOrTerm ? (
                            <ContractView rowId={clickedRow} />
                        ) : (
                            <ReadContractTermView rowId={clickedRow} cb={setMode} />
                        )}
                    </Paper>
                </DraggableDialog>

                <DraggableDialog
                    open={Boolean(openEdit)}
                    onClose={() => {
                        setOpenEdit(null)
                        setMode(null)
                    }}
                    title={editTitle}
                    isLoaded={Boolean(openEdit)}
                >
                    <Paper elevation={0}>
                        {isContractOrTerm ? (
                            <CreateContractView rowId={clickedRow} />
                        ) : (
                            <EditContractTermView rowId={clickedRow} />
                        )}
                    </Paper>
                </DraggableDialog>
            </div>
        )
    }

    return renderContent()
}

export default ContractTemplateTab
