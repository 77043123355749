import React from 'react'
import {useDispatch} from 'react-redux'
import {Button} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck'
import {Functions} from '../../../utils/Functions'
import ViewHead from '../../../components/public/ViewHead'
import {sendSuccessMessage} from '../../../actions'
import PaymentReceiverEditor from './PaymentReceiverEditor'
import {fetchPaymentReceiver} from '../../../actions/administrative/mmqJobsAction'

function CreatePaymentReceiverView() {
    useCurrentViewCheck(Functions.Administrative_Contract_Template_Create)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const templateToBeLoaded = useSelector(state => state.administrative.currentContractTemplate)
    //
    // const [isTemplateLoaded, setIsTemplateLoaded] = useState(false)
    // const [isLoaded, setIsLoaded] = useState(false)
    // const [type, setType] = useState(useParams().type)

    // useEffect(() => {
    //     if (!Number.isNaN(type)) {
    //         // attempt to load template by ID
    //         ;(async () => {
    //             setIsTemplateLoaded(fetchContractTemplate(type))
    //         })()
    //     } else {
    //         setType(type === 'head' ? 'head' : 'body')
    //         setIsLoaded(true)
    //     }
    // }, [])

    // useEffect(() => {
    //     if (isTemplateLoaded && !isLoaded && templateToBeLoaded) {
    //         if (!Number.isNaN(type)) {
    //             // set type to whatever the fetched template to be
    //             setType(templateToBeLoaded.type)
    //             setIsLoaded(true)
    //         }
    //     }
    // }, [isLoaded, isTemplateLoaded, templateToBeLoaded])

    const onSuccess = msg => {
        sendSuccessMessage(dispatch, msg)
        fetchPaymentReceiver()(dispatch)
        setTimeout(() => {
            navigate(`/app/administrative/contractTemplate`)
        }, 1000)
    }

    const renderBackButton = () => (
        <Button color="secondary" variant="contained" onClick={() => navigate('/app/administrative/contractTemplate')}>
            Back
        </Button>
    )

    return (
        <ViewHead
            functionId={Functions.Administrative_Contract_Template_Create}
            topButtons={renderBackButton()}
            noMaxWidth
        >
            <PaymentReceiverEditor onSubmitSuccess={onSuccess} />
        </ViewHead>
    )
}

export default CreatePaymentReceiverView
