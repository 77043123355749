import React from 'react';
import { Navigate } from 'react-router';
import DashboardLayout from "../../layout/DashboardLayout";
import AuthGuard from "../../components/public/AuthGuard";
import systemRoutes from "./system/index.routes";
import customerRoutes from './customer/index.routes';
import trainingRoutes from './training/index.routes'
import personalRoutes from "./personal";
import formsRoutes from './forms/index.routes';
import productRoutes from "./product";
import campaignRoutes from './campaign';
import employeeRoutes from "./employee";
import administrativeRoutes from './tracking/index.routes';
import reviewRoutes from './review/index.routes';
import qrcodeRoutes from './qrcode/index.routes';
import matricRoutes from './matric/index.routes';
import DashboardView from '../../views/DashboardView';

const appRoutes = [
    {
        path: 'app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            ...systemRoutes,
            ...customerRoutes,
            ...personalRoutes,
            ...formsRoutes,
            ...trainingRoutes,
            ...productRoutes,
            ...campaignRoutes,
            ...qrcodeRoutes,
            ...reviewRoutes,
            ...employeeRoutes,
            ...administrativeRoutes,
            ...matricRoutes,
            {
                path: "",
                exact: 'true',
                component: <Navigate to="/app/dashboard"/>,
            },
            {
                path: "dashboard",
                exact: 'true',
                component: <DashboardView/>
            },

            {
                component: <Navigate to="404"/> // this must be the last one
            },

        ]
    },

]

export default appRoutes;
