import React, { useState } from 'react';
import { Button, IconButton, Paper, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ListAlt } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useCurrentViewCheck from '../../../hooks/useCurrentViewCheck';
import { Functions } from '../../../utils/Functions';
import ViewHead from '../../../components/public/ViewHead';
import { fetchAllPosterTemplates } from '../../../actions/forms/posterMakerAction';
import PageGrid from '../../../components/mui/datagrid/PageGrid';
import DraggableDialog from "../../../components/mui/dialog/DraggableDialog";
import PosterMakerView from "./view";

function PosterGeneratorList() {
  useCurrentViewCheck(Functions.Forms_Poster_Generator_List)
  const  navigate = useNavigate();
  const allPosterTemplates = useSelector(state => state.posterMaker.allPosterTemplates)
  // eslint-disable-next-line no-unused-vars
  const [searchArgs, setSearchArgs] = useState({ searchKey: undefined })
  // const searchArgs ={ searchKey: undefined }
  const [clickedRow, setClickedRow] = useState("")
  const [openDetails, setOpenDetails] = useState(false)
  const renderGrid = () => {
    const columns = []

    columns.push({
      field: '_actions', headerName: ' ', resizable: false,
      disableClickEventBubbling: true, width: 55,
      filterable: false, renderCell: (params) => (
          <Tooltip title='Details' placement='top'>
            <IconButton color='primary'
                        size='small'
                        component='span'
                        onClick={async () => {
                          await setClickedRow(params.row.id);
                          await setOpenDetails(true);
                        }

                            // navigate(`/app/forms/poster/view/${params.row.id}`)
            }>
              <ListAlt size={20} />
            </IconButton>
          </Tooltip>
        )
    })

    columns.push(...[
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'name', headerName: 'Name', width: 270 },
      { field: 'description', headerName: 'Description', width: 350 },
      { field: 'createdAt', headerName: 'Date', width: 200, valueFormatter: ({ value }) => moment(value).format('L LT')},
    ])

    return <PageGrid columns={columns}
                     datas={allPosterTemplates}
                     searchArgs={searchArgs}
                     fetchDatas={fetchAllPosterTemplates}
                     defaultSortColumn='id'/>
  }


  const renderAddNewButton = () =>
    <Button color='secondary'
            variant='contained'
            onClick={() => navigate('/app/forms/poster/add')}>
      Add Template
    </Button>

  return (
    <ViewHead functionId={Functions.Forms_Poster_Generator_List} topButtons={renderAddNewButton()} noMaxWidth>
      <Paper elevation={0}>
        { renderGrid() }
      </Paper>
      <DraggableDialog open={Boolean(openDetails)} onClose={() => setOpenDetails(null)}
                       title='View Template' isLoaded={Boolean(openDetails)}>
        <Paper elevation={0}>
          <PosterMakerView rowId={clickedRow} isPopUp={openDetails}/>
        </Paper>

      </DraggableDialog>
    </ViewHead>
  )
}

export default PosterGeneratorList
