import React, { useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText, FormLabel,
  Grid,
  IconButton,
  InputLabel,
  NativeSelect,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AlternateEmail,
  Backspace,
  CheckCircleOutline,
  Image,
  InsertDriveFile,
  PictureAsPdf,
  TextFormat
} from '@mui/icons-material';
import { Calendar, Hash, Link2, Phone, Type } from 'react-feather';
import {
  COMMON_FILETYPES,
  COMMON_PATTERNS,
  DEFAULT_SELECT_VALUE, FORM_UNIQUE_TAGS_AREA, FORM_UNIQUE_TAGS_FILE,
  FORM_UNIQUE_TAGS_TEXT,
  WRONG_INPUT_SYNTAX_MESSAGE
} from './formBuilderData';

const useStyles = makeStyles(() => ({
  formControl: {
    marginBottom: '2rem'
  },
  saveButton: {
    marginTop: '10rem'
  },
  hidden: {
    display: 'none'
  },
  submitCheckBox: {
    marginLeft: '0px'
  },
  marginLeft: {
    marginLeft: '2rem'
  },
  saveCheckButton: {
    marginTop: '2.5rem'
  }
}))

function AddInputDialogue({formType, onCreateInputSubmit, register, errors, handleSubmit, isEdit, inputValues={}}) {

  const [tempPatternVal, setTempPatternVal] = useState(isEdit ? inputValues.pattern : '')
  const [tempFileType, setTempFileType] = useState(isEdit ? inputValues.fileType : '')
  const [isRequired, setIsRequired] = useState(isEdit ? inputValues.isRequired : false)
  const [isAddressAdded,setIsAddressAdded] = useState(false)
  const [isCityAdded,setIsCityAdded] = useState(false)
  const [isCountryAdded,setIsCountryAdded] = useState(false)
  const [isAddressRequired,setIsAddressRequired] = useState(false)
  const [isCountryRequired,setIsCountryRequired] = useState(false)
  const [isCityRequired,setIsCityRequired] = useState(false)
  const styles = useStyles()



  const onSubmitBuffer = (formData) => {

    switch (formType) {

      case 'address':

        formData.isAddressAdded = isAddressAdded
        formData.isCountryAdded = isCountryAdded
        formData.isCityAdded = isCityAdded
        formData.isAddressRequired = isAddressRequired
        formData.isCountryRequired = isCountryRequired
        formData.isCityRequired = isCityRequired
        break
      default:
        formData.isRequired = isRequired
    }

    return onCreateInputSubmit(formData)
  }

  const renderDisplay = () => {
    const baseInput = (
      <div>
        <Grid item>
          <TextField label='Type' name='inputType' value={formType} className={styles.hidden} inputRef={register} />
        </Grid>
        <Grid item xs={9}>
          <TextField variant='outlined' fullWidth size='small'
                     label='Label' required
                     defaultValue={ isEdit ? inputValues.label : '' }
                     className={styles.formControl}
                     name='label' inputRef={register}
                     InputLabelProps={{ shrink: true }}
                     error={Boolean(errors.label?.message)}
                     helperText={errors.label?.message}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            className={styles.submitCheckBox}
            inputRef={register}
            control={
              <Checkbox name='isRequired'
                        checked={isRequired}
                        onChange={() => setIsRequired(!isRequired)}/>
            }
            label='Required?'
          />
        </Grid>

        <Grid item xs={12}>
          <TextField variant='outlined' fullWidth size='small'
                     label='Description'
                     defaultValue={ isEdit ? inputValues.description : '' }
                     className={styles.formControl}
                     InputLabelProps={{ shrink: true}}
                     name='description' inputRef={register}
                     error={Boolean(errors.description?.message)}
                     helperText={errors.description?.message}
          />
        </Grid>
      </div>
    )
    const submitInput = <Grid item xs={12} className={styles.saveCheckButton}>
      <IconButton aria-label="add" onClick={handleSubmit(onSubmitBuffer)} size="large">
        <CheckCircleOutline style={{ color: 'green' }} />
      </IconButton>
    </Grid>

    switch (formType) {
      case 'text':
        return (
          <Grid container direction='row' justifyContent='space-between' alignContent='flex-end'>

            <Grid item xs={12}>
              <FormControl className={styles.formControl} fullWidth>
                <InputLabel>Unique Tag</InputLabel>
                <NativeSelect
                  inputRef={register}
                  inputProps={{ name: 'tag' }}
                  defaultValue={ isEdit ? inputValues.tag : DEFAULT_SELECT_VALUE }
                >
                  <option value={DEFAULT_SELECT_VALUE}/>
                  { FORM_UNIQUE_TAGS_TEXT.map((tag,index) =>
                    <option key={index} value={tag.value}>{tag.name}</option>) }
                </NativeSelect>
                <FormHelperText>Leave blank if not a unique text field</FormHelperText>
              </FormControl>
            </Grid>

            { baseInput }

            <Grid container spacing={2}>

              <Grid item xs={12} md={6}>
                <TextField variant='outlined' fullWidth size='small'
                           label='Min Length'
                           defaultValue={ isEdit ? inputValues.minLength : 1 }
                           className={styles.formControl}
                           InputLabelProps={{ shrink: true}}
                           name='minLength' inputRef={register}
                           error={Boolean(errors.minLength?.message)}
                           helperText={errors.minLength?.message}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField variant='outlined' fullWidth size='small'
                           label='Max Length'
                           defaultValue={ isEdit ? inputValues.maxLength : 250 }
                           className={styles.formControl}
                           InputLabelProps={{ shrink: true }}
                           name='maxLength' inputRef={register}
                           error={Boolean(errors.maxLength?.message)}
                           helperText={errors.maxLength?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField variant='outlined' size='small'
                           label='Pattern' fullWidth
                           onChange={(e) => setTempPatternVal(e.target.value)}
                           value={tempPatternVal}
                           InputLabelProps={{ shrink: true }}
                           name='pattern' inputRef={register}
                />

                <Typography variant='caption'>Common Patterns:</Typography>
                <Tooltip title='Number'>
                  <IconButton onClick={() => setTempPatternVal(COMMON_PATTERNS.number)} size="large">
                    <Hash />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Letter'>
                  <IconButton onClick={() => setTempPatternVal(COMMON_PATTERNS.letter)} size="large">
                    <TextFormat />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Alphanumeric w/ Space'>
                  <IconButton
                    onClick={() => setTempPatternVal(COMMON_PATTERNS.alphanumeric)}
                    size="large">
                    <Type />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Email: X@X.com'>
                  <IconButton onClick={() => setTempPatternVal(COMMON_PATTERNS.email)} size="large">
                    <AlternateEmail />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Phone'>
                  <IconButton onClick={() => setTempPatternVal(COMMON_PATTERNS.phone)} size="large">
                    <Phone />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Date: DD-MM-YYYY'>
                  <IconButton onClick={() => setTempPatternVal(COMMON_PATTERNS.date)} size="large">
                    <Calendar />
                  </IconButton>
                </Tooltip>
                <Tooltip title='URL'>
                  <IconButton onClick={() => setTempPatternVal(COMMON_PATTERNS.website)} size="large">
                    <Link2 />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Clear'>
                  <IconButton onClick={() => setTempPatternVal(COMMON_PATTERNS.clear)} size="large">
                    <Backspace />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <TextField variant='outlined' size='small'
                           label='Pattern Message' fullWidth
                           defaultValue={ isEdit ? inputValues.patternMessage : WRONG_INPUT_SYNTAX_MESSAGE }
                           name='patternMessage' inputRef={register}
                           InputLabelProps={{ shrink: true }}
                           error={Boolean(errors.patternMessage?.message)}
                           helperText={errors.patternMessage?.message}
                />
              </Grid>

            </Grid>
            { submitInput }
          </Grid>
        );
      case 'textarea':
        return(
          <Grid container direction='row' justifyContent='space-between' alignContent='flex-end'>

            <Grid item xs={12}>
              <FormControl className={styles.formControl} fullWidth>
                <InputLabel>Unique Tag</InputLabel>
                <NativeSelect
                  inputRef={register}
                  inputProps={{ name: 'tag' }}
                  defaultValue={ isEdit ? inputValues.tag : DEFAULT_SELECT_VALUE }
                >
                  <option value={FORM_UNIQUE_TAGS_AREA}/>
                  { FORM_UNIQUE_TAGS_AREA.map((tag,index) =>
                    <option key={index} value={tag.value}>{tag.name}</option>) }
                </NativeSelect>
                <FormHelperText>Leave blank if not a unique text area</FormHelperText>
              </FormControl>
            </Grid>

            { baseInput }

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField variant='outlined' fullWidth size='small'
                           label='Min Length'
                           defaultValue={ isEdit ? inputValues.minLength : 1 }
                           className={styles.formControl}
                           InputLabelProps={{ shrink: true }}
                           name='minLength' inputRef={register}
                           error={Boolean(errors.minLength?.message)}
                           helperText={errors.minLength?.message}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField variant='outlined' fullWidth size='small'
                           label='Max Length'
                           defaultValue={ isEdit ? inputValues.maxLength : 250 }
                           className={styles.formControl}
                           InputLabelProps={{ shrink: true }}
                           name='maxLength' inputRef={register}
                           error={Boolean(errors.maxLength?.message)}
                           helperText={errors.maxLength?.message}
                />
              </Grid>
              { submitInput }
            </Grid>
          </Grid>
        )
      case 'radio':
      case 'check':
      case 'select':
        return(
          <Grid container direction='row' justifyContent='space-between' alignContent='flex-end'>
            { baseInput }

            <Grid item>
              <TextField label='hidden' name='minLength' value={2} className={styles.hidden} inputRef={register} />
              <TextField label='hidden' name='maxLength' value={200} className={styles.hidden} inputRef={register} />
            </Grid>
            <TextField variant='outlined' fullWidth size='small'
                       multiline rows={5} inputRef={register}
                       label='Enter Choices'
                       InputLabelProps={{ shrink: true }}
                       defaultValue={isEdit && inputValues && inputValues.values
                         ? inputValues.values.map(c => `${c.value}`).join('\n')
                         : '' }
                       name='values'
                       error={Boolean(errors.values?.message)}
                       helperText={errors.values?.message ? errors.values?.message : 'Each choice on one line'}
            />
            { submitInput }
          </Grid>
        )
      case 'file':
        return (
          <Grid container direction='row' justifyContent='space-between' alignContent='flex-end'>

            <Grid item xs={12}>
              <FormControl className={styles.formControl} fullWidth>
                <InputLabel>Unique Tag</InputLabel>
                <NativeSelect
                  inputRef={register}
                  inputProps={{ name: 'tag' }}
                  defaultValue={ isEdit ? inputValues.tag : DEFAULT_SELECT_VALUE }
                >
                  <option value={DEFAULT_SELECT_VALUE}/>
                  { FORM_UNIQUE_TAGS_FILE.map((tag,index) =>
                    <option key={index} value={tag.value}>{tag.name}</option>) }
                </NativeSelect>
                <FormHelperText>Leave blank if not a unique file</FormHelperText>
              </FormControl>
            </Grid>

            { baseInput }

            <Grid item>
              <TextField label='hidden' name='minLength' value={2} className={styles.hidden} inputRef={register} />
              <TextField label='hidden' name='maxLength' value={2} className={styles.hidden} inputRef={register} />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField variant='outlined' fullWidth size='small'
                           label='Min File Size (MB)'
                           defaultValue={ isEdit ? inputValues.minSize : 0 }
                           className={styles.formControl}
                           InputLabelProps={{ shrink: true }}
                           name='minSize' inputRef={register}
                           error={Boolean(errors.minSize?.message)}
                           helperText={errors.minSize?.message}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField variant='outlined' fullWidth size='small'
                           label='Max File Size (MB)'
                           defaultValue={ isEdit ? inputValues.maxSize : 5 }
                           className={styles.formControl}
                           InputLabelProps={{ shrink: true }}
                           name='maxSize' inputRef={register}
                           error={Boolean(errors.maxSize?.message)}
                           helperText={errors.maxSize?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField variant='outlined' size='small'
                           label='File Type' fullWidth
                           onChange={(e) => setTempFileType(e.target.value)}
                           value={tempFileType}
                           InputLabelProps={{ shrink: true }}
                           name='fileType' inputRef={register}
                           helperText='MIME only, multiple separated by comma. If set, please specify the type of accepted file below for the users.'
                />

                <Typography variant='caption'>Common File Types:</Typography>
                <Tooltip title='Images'>
                  <IconButton onClick={() => setTempFileType(COMMON_FILETYPES.images)} size="large">
                    <Image />
                  </IconButton>
                </Tooltip>
                <Tooltip title='PDF'>
                  <IconButton onClick={() => setTempFileType(COMMON_FILETYPES.pdf)} size="large">
                    <PictureAsPdf />
                  </IconButton>
                </Tooltip>
                <Tooltip title='DOC'>
                  <IconButton onClick={() => setTempFileType(COMMON_FILETYPES.doc)} size="large">
                    <InsertDriveFile />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Clear'>
                  <IconButton onClick={() => setTempFileType(COMMON_PATTERNS.clear)} size="large">
                    <Backspace />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <TextField variant='outlined' size='small'
                           label='Pattern Message' fullWidth
                           defaultValue={ isEdit ? inputValues.patternMessage : WRONG_INPUT_SYNTAX_MESSAGE }
                           name='patternMessage' inputRef={register}
                           InputLabelProps={{ shrink: true }}
                           error={Boolean(errors.patternMessage?.message)}
                           helperText={errors.patternMessage?.message}
                />
              </Grid>
            </Grid>
            { submitInput }
          </Grid>
        );
      case 'address':

        return(
          <Grid>
            <Grid>
              <TextField label='Type' name='inputType' value={formType} className={styles.hidden} inputRef={register} />

            </Grid>
            <div>
              <Grid container direction='row'>
                <Grid item xs={6}>
                  <FormControl className={styles.formControl} >
                    <FormLabel>
                      Address
                    </FormLabel>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControlLabel
                    className={styles.submitCheckBox}
                    inputRef={register}
                    control={
                      <Checkbox name='isAddressAdded'
                                checked={!!isAddressAdded}
                                onChange={() => setIsAddressAdded(add=>!add)}/>
                    }
                    label='Added?'
                  />
                </Grid>
              </Grid>

              <Grid container direction='row' justifyContent='space-between' className={isAddressAdded? null:styles.hidden}>
                <Grid item xs={9}>
                  <TextField variant='outlined' fullWidth size='small'
                             label='Address Label' required
                             defaultValue={ isEdit ? inputValues.label : '' }
                             className={styles.formControl}
                             name='addressLabel' inputRef={register}
                             InputLabelProps={{ shrink: true }}
                             error={Boolean(errors.addressLabel?.message)}
                             helperText={errors.addressLabel?.message}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    className={styles.submitCheckBox}
                    inputRef={register}
                    control={
                      <Checkbox name='isAddressRequired'
                                checked={!!isAddressRequired}
                                onChange={() => setIsAddressRequired(required=>!required)}/>
                    }
                    label='Required?'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField variant='outlined' fullWidth size='small'
                             label='addressDescription'
                             defaultValue={ isEdit ? inputValues.description : '' }
                             className={styles.formControl}
                             InputLabelProps={{ shrink: true}}
                             name='addressDescription' inputRef={register}
                             error={Boolean(errors.description?.message)}
                             helperText={errors.description?.message}
                  />
                </Grid>
              </Grid>
            </div>
            {/* country */}
            <div>
              <Grid container direction='row'>
                <Grid item xs={6}>
                  <FormControl className={styles.formControl} >
                    <FormLabel>
                      Country
                    </FormLabel>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControlLabel
                    className={styles.submitCheckBox}
                    inputRef={register}
                    control={
                      <Checkbox name='isCountryAdded'
                                checked={!!isCountryAdded}
                                onChange={() => setIsCountryAdded(add=>!add)}/>
                    }
                    label='Added?'
                  />
                </Grid>
              </Grid>

              <Grid container direction='row' justifyContent='space-between' className={isCountryAdded? null:styles.hidden}>
                <Grid item xs={9}>
                  <TextField variant='outlined' fullWidth size='small'
                             label='Country Label' required
                             defaultValue={ isEdit ? inputValues.label : '' }
                             className={styles.formControl}
                             name='countryLabel' inputRef={register}
                             InputLabelProps={{ shrink: true }}
                             error={Boolean(errors.countryLabel?.message)}
                             helperText={errors.countryLabel?.message}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    className={styles.submitCheckBox}
                    inputRef={register}
                    control={
                      <Checkbox name='isCountryRequired'
                                checked={!!isCountryRequired}
                                onChange={() => setIsCountryRequired(required => ! required)}/>
                    }
                    label='Required?'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField variant='outlined' fullWidth size='small'
                             label='countryDescription'
                             defaultValue={ isEdit ? inputValues.description : '' }
                             className={styles.formControl}
                             InputLabelProps={{ shrink: true}}
                             name='countryDescription' inputRef={register}
                             error={Boolean(errors.description?.message)}
                             helperText={errors.description?.message}
                  />
                </Grid>
              </Grid>
            </div>
            {/* city */}
            <div>
              <Grid container direction='row'>
                <Grid item xs={6}>
                  <FormControl className={styles.formControl} >
                    <FormLabel>
                      City
                    </FormLabel>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControlLabel
                    className={styles.submitCheckBox}
                    inputRef={register}
                    control={
                      <Checkbox name='isCityAdded'
                                checked={!!isCityAdded}
                                onChange={() => setIsCityAdded(add => !add)}/>
                    }
                    label='Added?'
                  />
                </Grid>
              </Grid>

              <Grid container direction='row' justifyContent='space-between' className={isCityAdded? null:styles.hidden}>
                <Grid item xs={9}>
                  <TextField variant='outlined' fullWidth size='small'
                             label='City Label' required
                             defaultValue={ isEdit ? inputValues.label : '' }
                             className={styles.formControl}
                             name='cityLabel' inputRef={register}
                             InputLabelProps={{ shrink: true }}
                             error={Boolean(errors.cityLabel?.message)}
                             helperText={errors.cityLabel?.message}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    className={styles.submitCheckBox}
                    inputRef={register}
                    control={
                      <Checkbox name='isCityRequired'
                                checked={!!isCityRequired}
                                onChange={() => setIsCityRequired(required => !required)}/>
                    }
                    label='Required?'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField variant='outlined' fullWidth size='small'
                             label='City Description'
                             defaultValue={ isEdit ? inputValues.description : '' }
                             className={styles.formControl}
                             InputLabelProps={{ shrink: true}}
                             name='cityDescription' inputRef={register}
                             error={Boolean(errors.description?.message)}
                             helperText={errors.description?.message}
                  />
                </Grid>
              </Grid>
            </div>
            <Grid item>
              <TextField label='hidden' name='minLength' value={2} className={styles.hidden} inputRef={register} />
              <TextField label='hidden' name='maxLength' value={200} className={styles.hidden} inputRef={register} />
            </Grid>
            { submitInput }
          </Grid>

        )
      default:
        return <div />
        // console.log('Unsupported input type')
    }
  }

  return (
    <div>
      {renderDisplay()}
    </div>
  )
}

export default AddInputDialogue
