import './Background.scss';
import React from 'react';

function hexToRGB(hex, alpha) {
  const r = parseInt(hex?.slice(1, 3), 16);
    const g = parseInt(hex?.slice(3, 5), 16);
    const b = parseInt(hex?.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${  r  }, ${  g  }, ${  b  }, ${  alpha  })`;
  } 
    return `rgb(${  r  }, ${  g  }, ${  b  })`;
  
}

export function Background(props) {
  const {url:curUrl,type,maskColor,startOpacity,endOpacity,makeStyle,fallBackUrl,midPoint,children} = props;


  Background.defaultProps = {
    type: 'img',
    maskColor: '#000000',
    startOpacity: 0.3,
    midPoint: '30%',
    endOpacity: 1.0,
    makeStyle: false,
    fallBackUrl:false,

  };
  return (
    <div
      className='backgroundCtnr'
    >
      {type === 'img' ?
        <div
          className='backgroundImg'
          style={{ backgroundImage: `url(${curUrl})` }}
         />
        :
        <video autoPlay muted loop
               className='backgroundVideo'
        >
          <source src={curUrl} type='video/mp4' />

          {fallBackUrl ? <img src={fallBackUrl} alt=''/>:<div />}
        </video>
      }
      {makeStyle ?
        !!makeStyle && <div
        className='backgroundBlack'
        style={makeStyle}
        >
          {children}
        </div>
        :
        !makeStyle &&
      <div
          className='backgroundBlack'
          style={{
            background: `linear-gradient(180deg,
                ${hexToRGB(maskColor, startOpacity)} 0%,
                ${hexToRGB(maskColor, endOpacity)} ${midPoint}),
                ${hexToRGB(maskColor, endOpacity)} 100%`
          }}
        >
          {children}
        </div>
      }

    </div>
  );


}
export default Background
