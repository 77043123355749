import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Checkbox, Grid, IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { debounce } from 'lodash';
import Fuse from 'fuse.js';
import ResponsiveDialog from '../../../../components/dialog/ResponsiveDialog';
import { getBasicHash } from '../../../../utils/Scripts';
import { useKeywordsView } from '../../matricHelper';
import LXGrid from '../../../../components/mui/datagrid/XGrid';
import {
  deleteKeyword,
  fetchAllKeywords,
  sendErrorMessage,
  sendSuccessMessage,
  updateKeywordStatus
} from '../../../../actions';
import SearchBox from '../../../../components/public/SearchBox';

function MonitorKeywordsListView() {

  const classes = useKeywordsView()
  const dispatch = useDispatch()
  const allKeywords = useSelector(state => state.matric.allKeywords)

  const [isLoaded, setIsLoaded] = useState(false)
  const [selectedForDel, setSelectedForDel] = useState(null)
  const [hash, setHash] = useState(getBasicHash())

  const [searchQuery, setSearchQuery] = useState('')
  const [displayList, setDisplayList] = useState([])

  const loadData = () => {
    (async() => {
      setIsLoaded(await fetchAllKeywords()(dispatch))
    })()
  }
  useEffect(loadData, [])

  useEffect(() => {
    onSearch(searchQuery)
  }, [allKeywords])

  const onSuccess = (msg) => {
    sendSuccessMessage(dispatch, msg)
    setSelectedForDel(null)
  }
  const onError = (msg) => sendErrorMessage(dispatch, msg)

  const handleKeywordStatusChange = async (id, isActive) => {
    const data = { isActive, ids: [parseInt(id,10)] }
    await updateKeywordStatus(data, onSuccess, onError)(dispatch)
  }

  const handleDeleteOpen = (keywordObj) => {
    setSelectedForDel(keywordObj)
    setHash(getBasicHash())
  }

  const handleDelete = async () =>
     deleteKeyword(selectedForDel?.id, onSuccess, onError)(dispatch)

  const onSearch = (searchVal) => {
    if (allKeywords && allKeywords.length > 0) {
      const cleanSearchVal = searchVal.toLowerCase().trim()
      setSearchQuery(searchVal)

      const fuse = new Fuse(allKeywords, { keys: ['keyword', 'description'] })

      const filteredDisplay = cleanSearchVal === ''
        ? allKeywords
        : fuse.search(cleanSearchVal).map(f => f.item)
      setDisplayList(filteredDisplay)
    }
  }

  const searchHandler = debounce((searchVal) => {
    onSearch(searchVal)
  }, 500)
  const renderSearchContainer = () => <div className={classes.search}>
      <SearchBox
        placeholder='Search...'
        size='small'
        type='search'
        searchHandler={searchHandler} />
    </div>

  const renderGrid = () => {
    const columns = [
      { field: 'isActive', headerName: '✓', width: 65,
        renderCell: (params) =>
          <Checkbox
            className={classes.keywordCheckbox}
            checked={params.row.isActive}
            onChange={() => handleKeywordStatusChange(params.row.id, !params.row.isActive)}
          />
      },
      { field: '_deleteAtn', headerName: '✗', width: 65,
        renderCell: (params) =>
          <Tooltip title='Delete' placement='top'>
            <IconButton color='primary'
                        size='small'
                        component='span'
                        onClick={() => handleDeleteOpen(params.row)}>
              <Delete />
            </IconButton>
          </Tooltip>
      },
      { field: 'keyword', headerName: 'Keyword', width: 300,
        renderCell: (params) => params.row.isActive
          ? <span className={classes.keywordActive}>{ params.value }</span>
          : <del className={classes.keywordDisable}>{ params.value }</del>
      },
      { field: 'description', headerName: 'Description', width: 300 },
      { field: 'createdAt', headerName: 'Created', width: 200,
        valueFormatter: ({ value }) => value ? moment(value).format('L LT') : '' },
      { field: 'user', headerName: 'User', width: 200,
        valueFormatter: ({ value }) => `${value.firstName} ${value.lastName}` },
    ]

    return (
      <LXGrid rows={displayList}
              columns={columns}
              loading={!isLoaded}
              disableSelectionOnClick
              sortModel={[{ field: 'createdAt', sort: 'desc' }]}
      />
    )
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          { renderSearchContainer() }
        </Grid>
        <Grid item xs={12}>
          { renderGrid() }
        </Grid>
      </Grid>
      <ResponsiveDialog
        isOpen={!!selectedForDel} openHash={hash}
        title="Delete Keyword" content={`This action cannot be undone and all posts matched with this keyword will also be deleted from the database. Are you sure you want to delete the [${selectedForDel?.keyword}] keyword?`}
        Buttons={
          [
            { name: "Yes", event: () => handleDelete() },
            { name: "Cancel", event: () => setSelectedForDel(null) }
          ]
        }
      />
    </div>
  )
}

export default MonitorKeywordsListView
