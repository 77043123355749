import React from 'react';
import { Navigate } from 'react-router';
import AddCustomerPage from '../../../views/customer/register/AddCustomerPage';
import CheckCustomerPage from '../../../views/customer/register/CheckCustomerPage';
import EditCustomerPage from '../../../views/customer/register/EditCustomerPage';
import RegisteredInfo from '../../../views/customer/register';

const registerRoutes = [
  {
    path: 'registered',
    routes: [
      {
        path: '',
        exact: 'true',
        component:<RegisteredInfo/>
      },
      {
        path: 'edit/:id?',
        exact: 'true',
        component: <EditCustomerPage/>
      },
      {
        path: 'check/:id?',
        exact: 'true',
        component: <CheckCustomerPage/>
      },
      {
        path: 'add',
        exact: 'true',
        component:<AddCustomerPage/>
      },
      {
        component: <Navigate to='404' /> // this must be the last one
      }
    ]
  }
];

export default registerRoutes;
