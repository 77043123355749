import {noop} from 'lodash'
import api from '../../apis/api'
import {sendErrorMessage, sendSuccessMessage} from '../message/messageActions'
import {isMobile} from '../../utils/Scripts'
import {pagedGridDefaultFetch} from '../../components/mui/datagrid/PagedGridAction'

export const AdministrativeActionType = {
    FetchAllLoginHistory: 'FetchAllLoginHistory',
    FetchLoginHistoryProps: 'FetchLoginHistoryProps',

    FetchAllTrackers: 'FetchAllTrackers',
    FetchAllTrackersByDate: 'FetchAllTrackersByDate',
    SetTrackerFetchProps: 'SetTrackerFetchProps',

    FetchAllStaff: 'FetchAllStaff',

    FetchAllContractTemplates: 'FetchAllContractTemplates',
    FetchContractTemplateProps: 'FetchContractTemplateProps',
    FetchContractTemplate: 'FetchContractTemplate',
    ChangeTemplateTab: 'ChangeTemplateTab',

    FetchAllContracts: 'FetchAllContracts',
    FetchContract: 'FetchContract',
    ClearContractNotes: 'ClearContractNotes',
    FetchContractNotes: 'FetchContractNotes',
    fetchAllContractNotes: 'fetchAllContractNotes',
    FetchContractProps: 'FetchContractProps',

    FetchAllProducts: 'FetchAllProducts',
    FetchCustomer: 'FetchCustomer',
    FetchAllCustomerAdministrative: 'FetchAllCustomerForAdministrative',
    FetchCurrency: 'FetchCurrency',
    FetchCustomersFilterList: 'FetchCustomersFilterList',
    ResetCustomersFilterList: 'ResetCustomersFilterList',
    FetchTeacherFilterList: 'FetchTeacherFilterList',

    ChangeInvoiceTab: 'ChangeInvoiceTab',
    FetchAllInvoices: 'FetchAllInvoices',
    FetchInvoicesProps: 'FetchInvoiceProps',
    FetchInvoice: 'FetchInvoice',
    FetchAllInvoiceHeads: 'FetchAllInvoiceHeads',
    ClearInvoiceNotes: 'ClearInvoiceNotes',
    FetchInvoiceNotes: 'FetchInvoiceNotes',

    FetchAllEmailAccounts: 'FetchAllEmailAccounts',
    FetchEmailAccount: 'FetchEmailAccount',
    FetchAllPaymentLogs: 'FetchAllPaymentLogs',
    FetchSignlePaymentLog: 'FetchSignlePaymentLog',
    FetchAllPaymentLogsProps: 'FetchAllPaymentLogsProps',

    // term
    FetchFixedTerms: 'FetchFixedTerms',
    FetchTermFilterList: 'FetchTermFilterList',
    FetchTermsBeSelected: 'FetchTermsBeSelected',
    FetchAllContractTerms: 'FetchAllContractTerms',
    FetchContractTerm: 'FetchContractTerm'
}

export const fetchAllLoginHistory = args => async dispatch => {
    try {
        const res = await pagedGridDefaultFetch(
            '/administrative/fetchAllLogInHistory',
            args,
            AdministrativeActionType.FetchLoginHistoryProps
        )(dispatch)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchAllLoginHistory, payload: res.data})
            return true
        }
        throw res.data.message
    } catch (e) {
        sendErrorMessage(dispatch, e.message)
        return false
    }
}

export const fetchAllTrackers = args => async dispatch => {
    try {
        const fetchTrackerUrl = '/administrative/fetchAllTrackingByPage'

        const res = await pagedGridDefaultFetch(
            fetchTrackerUrl,
            args,
            AdministrativeActionType.SetTrackerFetchProps
        )(dispatch)

        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchAllTrackers, payload: res.data})
            return true
        }
        sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch trackers')
    } catch (e) {
        // console.log('Error fetching tracking details: ', e.message)
        sendErrorMessage(dispatch, e.message)
    }
    return false
}

export const fetchAllTrackersByDate = (user, startDate, endDate, cb) => async dispatch => {
    try {
        const res = await api.post('/administrative/fetchAllTrackingByDate', {user, startDate, endDate})
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchAllTrackersByDate, payload: res.data.data})
            return cb(true)
        }
        sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch trackers')
    } catch (e) {
        // console.log('Error fetching tracking details: ', e.message)
        sendErrorMessage(dispatch, e.message)
    }
    return cb(false)
}

// Contract Templates
export const fetchAllContractTemplates = args => async dispatch => {
    try {
        const contractTemplateUrl = '/administrative/contractTemplate/fetchAllTemplates'
        const res = await pagedGridDefaultFetch(
            contractTemplateUrl,
            args,
            AdministrativeActionType.FetchContractTemplateProps,
            async () => api.get(contractTemplateUrl)
        )(dispatch)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchAllContractTemplates, payload: res.data})
            return true
        }
        throw res.data.message
    } catch (e) {
        // console.log('Error fetching contract template details: ', e.message)
        sendErrorMessage(dispatch, e.message)
        return false
    }
}

export const fetchContractTemplate = id => async dispatch => {
    try {
        const res = await api.get(`/administrative/contractTemplate/${id}`)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchContractTemplate, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch contract template')
    } catch (e) {
        // console.log('Error fetching contract template details: ', e.message)
        sendErrorMessage(dispatch, e.message)
    }
    return false
}

export const createContractTemplate = (data, fetchProps, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post('/administrative/contractTemplate/create', data)
        if (res.data?.rs) {
            await dispatch(fetchAllContractTemplates(fetchProps))
            return onSuccess('Contract template created')
        }
        return onError(res?.data?.message || 'Cannot create contract template')
    } catch (e) {
        // console.log('Error creating contract template: ', e.message)
        return onError(e.message)
    }
}

export const updateContractTemplate = (data, fetchProps, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.put('/administrative/contractTemplate', data)
        if (res.data?.rs) {
            await dispatch(fetchAllContractTemplates(fetchProps))
            return onSuccess('Contract template updated')
        }
        return onError(res?.data?.message || 'Cannot update contract template')
    } catch (e) {
        // console.log('Error updating contract template: ', e.message)
        return onError(e.message)
    }
}

export const updateContractTemplateStatus =
    (ids, fetchProps, isActive, isDelete, onSuccess, onError) => async dispatch => {
        try {
            const res = await api.post('/administrative/contractTemplate/updateStatus', {ids, isActive, isDelete})
            if (res.data?.rs) {
                await dispatch(fetchAllContractTemplates(fetchProps))
                return onSuccess(`Template(s) ${isDelete ? 'deleted' : isActive ? 'activated' : 'deactivated'}`)
            }
            return onError(res?.data?.message || 'Cannot update contract template status')
        } catch (e) {
            // console.log('Error updating contract template status: ', e.message)
            return onError(e.message)
        }
    }

export const fetchAllContract = args => async dispatch => {
    try {
        const contractUrl = '/administrative/contract/getAllContracts'
        const res = await pagedGridDefaultFetch(
            contractUrl,
            args,
            AdministrativeActionType.FetchContractProps,
            async () => api.get(contractUrl)
        )(dispatch)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchAllContracts, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res.data?.message)
        // throw (res.data.message)
        return false
    } catch (e) {
        sendErrorMessage(dispatch, e.message)
        return false
    }
}

export const fetchContract = (uid, cid) => async dispatch => {
    try {
        const res = await api.get(`/administrative/contract/getContract/${uid}/${cid}`)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchContract, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch contract')
    } catch (e) {
        // console.log('Error fetching contract details: ', e.message)
        sendErrorMessage(dispatch, e.message)
    }
    return false
}

export const closeContract = () => dispatch => {
    dispatch({type: AdministrativeActionType.FetchContract, payload: null})
    return false
}

export const fetchContractPublic = (uid, cid, onError, errorMessage) => async dispatch => {
    try {
        const res = await api.get(`/contract/${uid}/${cid}`)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchContract, payload: res.data.data})
            return true
        }
        onError(errorMessage)
    } catch (e) {
        // console.log('Error fetching contract details: ', e.message)
        return onError(errorMessage)
    }
    return false
}

export const createContract = (data, onSuccess, onError, uid) => async () => {
    try {
        const res = await api.post('/administrative/contract/createContract', data)
        if (res.data?.rs) {
            return onSuccess('Contract created', uid, res.data.data.cid)
        }
        return onError(res?.data?.message || 'Cannot create contract')
    } catch (e) {
        // console.log('Error creating contract: ', e.message)
        return onError(e.message)
    }
}

export const updateContract = (data, onSuccess, onError, uid) => async () => {
    try {
        const res = await api.put('/administrative/contract/updateContract', data)
        if (res.data?.rs) {
            return onSuccess('Contract updated', uid, res.data.data.cid)
        }
        return onError(res?.data?.message || 'Cannot update contract')
    } catch (e) {
        // console.log('Error updating contract: ', e)
        return onError(e.message)
    }
}

export const updateContractReadDate = (type, uid, cid, source) => async dispatch => {
    try {
        const data = {
            cid: cid.toString(),
            customerId: parseInt(uid, 10),
            type,
            source
        }
        const res = await api.put('/contract/updateContractReadDate', data)
        if (!res.data?.rs) {
            sendErrorMessage(dispatch, res?.data?.message || 'Error updating contract date')
            // console.log(res?.data?.message || 'Error updating contract date')
        }
        // return
    } catch (e) {
        sendErrorMessage(dispatch, 'Error updating contract date')
        // console.log('Error updating contract date', e);
    }
}

export const fetchProducts = () => async dispatch => {
    try {
        const res = await api.get('/product/getProduct')
        if (res.data?.rs) {
            const payloadData = res.data.data?.filter(prod => prod.isActive)
            dispatch({type: AdministrativeActionType.FetchAllProducts, payload: payloadData})
            return true
        }
        sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch products')
    } catch (e) {
        // console.log('Error fetching products: ', e)
        sendErrorMessage(dispatch, 'Cannot fetch products')
    }
    return false
}

export const fetchCustomer = id => async dispatch => {
    try {
        const res = await api.get(`/customer/fetchOneCustomer/${id}`)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchCustomer, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch customer')
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch customer details')
    }
    return false
}

export const fetchAllStaff = () => async dispatch => {
    try {
        const res = await api.get(`/staff/fetchAllStaff?isActive=true`)
        if (res.data) {
            dispatch({type: AdministrativeActionType.FetchAllStaff, payload: res.data})
            return true
        }
        sendErrorMessage(dispatch, 'Cannot fetch staff')
    } catch (e) {
        // console.log('Error fetching staff: ', e)
        sendErrorMessage(dispatch, 'Cannot fetch staff')
    }
    return false
}

export const fetchAllCustomersForSearchBar = args => async dispatch => {
    const {searchKey} = args
    try {
        const customerUrl = '/customer/searchCustomerByName'
        const res = await api.post(customerUrl, {searchKey})

        if (res.data) {
            dispatch({type: AdministrativeActionType.FetchCustomersFilterList, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, 'Cannot fetch customers')
        return false
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch customers')
        return false
    }
}

export const fetchAllTeacherForSearchBar = args => async dispatch => {
    const {searchKey} = args
    try {
        const customerUrl = '/customer/searchTeacherByName'
        const res = await api.post(customerUrl, {searchKey})
        if (res.data) {
            dispatch({type: AdministrativeActionType.FetchTeacherFilterList, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, 'Cannot fetch teachers')
        return false
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch teachers')
        return false
    }
}
export const fetchAllCustomerForInvoice = () => async dispatch => {
    try {
        const res = await api.get(`/customer/fetchAllCustomers`)
        if (res.data) {
            dispatch({type: AdministrativeActionType.FetchAllCustomerAdministrative, payload: res.data})
            return true
        }
        sendErrorMessage(dispatch, 'Cannot fetch customers')
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch customers')
    }
    return false
}

export const signContract = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.post('/contract/sign', data)
        if (res.data?.rs) {
            return onSuccess()
        }
        return onError(res?.data?.message || 'Cannot sign contract')
    } catch (e) {
        return onError(e.message)
    }
}

export const sendContractEmail = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.post('/administrative/contract/sendContractEmail', data)
        if (res.data?.rs) {
            return onSuccess(res.data)
        }
        return onError(res?.data?.message || 'Email failed to send')
    } catch (e) {
        // console.log('Error sending email: ', e.message)
        return onError(e.message)
    }
}

export const downloadUnsignedContractPdf = (cid, uid, productName, customerName, onSuccess, onError) => async () => {
    try {
        const res = await api.post(`/administrative/contract/unsignedPDF`, {uid, cid}, {responseType: 'blob'})
        if (res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}))
            const link = document.createElement('a')
            link.href = url
            link.download = `${productName}-${customerName}-${cid}_unsigned.pdf`
            document.body.appendChild(link)
            link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}))
            link.parentNode.removeChild(link)
            return onSuccess('Done')
        }
    } catch (e) {
        return onError(e.message)
    }
    return onError('Cannot fetch PDF file')
}

export const downloadContractPdf = (cid, productName, customerName, onError) => async () => {
    try {
        const res = await api.post(`/administrative/contract/getContractPdf`, {cid}, {responseType: 'blob'})
        if (res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}))
            const link = document.createElement('a')
            link.href = url
            link.download = `${productName}-${customerName}-${cid}.pdf`
            document.body.appendChild(link)
            link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}))
            link.parentNode.removeChild(link)
        }
    } catch (e) {
        onError(e.message)
    }
}

export const downloadContractFile = (data, onError) => async () => {
    try {
        const res = await api.post(`/administrative/contract/getFile`, data, {responseType: 'blob'})
        if (res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data], {type: res.data.type}))
            const link = document.createElement('a')
            link.href = url
            document.body.appendChild(link)
            // If is mobile, open in same tab
            if (!isMobile()) {
                link.target = '_blank'
            }
            link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}))
            link.parentNode.removeChild(link)
        }
    } catch (e) {
        onError(e.message)
    }
}

export const updateContractStatus =
    (ids, isActive, fetchProps, onSuccess, onError, isSub = false, uid = null, cid = null) =>
    async dispatch => {
        try {
            const res = await api.post('/administrative/contract/updateStatus', {ids, isActive})
            if (res.data?.rs) {
                if (isSub) {
                    if (uid && cid) {
                        await dispatch(fetchContract(uid, cid))
                    }
                    return onSuccess(`Sub contract ${isActive ? 'enabled' : 'disabled'}`)
                }
                await dispatch(fetchAllContract(fetchProps))
                return onSuccess(`Contract ${isActive ? 'enabled' : 'disabled'}`)
            }
            return onError(res?.data?.message || 'Cannot update contract status')
        } catch (e) {
            // console.log('Error updating contract status: ', e.message)
            return onError(e.message)
        }
    }

export const confirmContract = (cid, onSuccess, onError, fetchProps) => async dispatch => {
    try {
        const res = await api.post('/administrative/contract/confirm', {cid})
        if (res.data?.rs) {
            // Refresh either main list or the corresponding one
            if (!res?.data?.data?.isMain) {
                await dispatch(fetchContract(res?.data?.data?.customer, res?.data?.data?.mainContract))
            } else {
                await dispatch(fetchAllContract(fetchProps))
            }
            return onSuccess('Contract done')
        }
        return onError(res?.data?.message || 'Cannot confirm contract')
    } catch (e) {
        // console.log('Error confirming contract: ', e)
        return onError(e.message)
    }
}

export const forceDoneContract = (cid, onSuccess, onError, fetchProps) => async dispatch => {
    try {
        const res = await api.post('/administrative/contract/forcedone', {cid})
        if (res.data?.rs) {
            if (!res?.data?.data?.isMain) {
                await dispatch(fetchContract(res?.data?.data?.customer, res?.data?.data?.mainContract))
            } else {
                await dispatch(fetchAllContract(fetchProps))
            }
            return onSuccess('Contract force done')
        }
        return onError(res?.data?.message || 'Cannot force done contract')
    } catch (e) {
        return onError(e.message)
    }
}
export const uploadContractFile = (data, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post('/administrative/contract/uploadFile', data)
        if (res.data?.rs) {
            await dispatch(fetchContract(res.data.data?.uid, res.data.data?.cid))
            return onSuccess('File uploaded')
        }
        return onError(res.data?.message || 'Cannot upload file')
    } catch (e) {
        // console.log(e)
        return onError(e.message)
    }
}

export const deleteContractFile = (data, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post('/administrative/contract/deleteFile', data)
        if (res.data?.rs) {
            await dispatch(fetchContract(data.uid, data.cid))
            return onSuccess('File deleted')
        }
        return onError(res.data?.message || 'Cannot delete file')
    } catch (e) {
        // console.log(e)
        return onError(e.message)
    }
}

export const clearContractNotes = () => async dispatch => {
    try {
        dispatch({type: AdministrativeActionType.ClearContractNotes})
    } catch (e) {
        sendErrorMessage(dispatch, 'Error in Clear Notes')
        // console.log('Cannot clear notes', e)
    }
}

export const fetchContractNotes = cid => async dispatch => {
    try {
        const res = await api.get(`/administrative/contract/notes/${cid}`)
        if (res.data) {
            if (res.data.rs) {
                dispatch({type: AdministrativeActionType.FetchContractNotes, payload: res.data.data})
                return true
            }
            sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch notes')
        }
    } catch (e) {
        // console.log('Error fetching notes: ', e)
        sendErrorMessage(dispatch, 'Cannot fetch notes')
    }
    return false
}

export const deleteContractNote = (id, cid, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.delete(`/administrative/contract/deleteNote/${id}/${cid}`)
        if (res.data?.rs) {
            await dispatch(fetchContractNotes(cid))
            return onSuccess('Note deleted')
        }
    } catch (e) {
        // console.log('Note delete error:\n', e)
        return onError(e.message)
    }
    return onError('Something went wrong')
}

export const createContractNote = (cid, notes, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post(`/administrative/contract/createNote/`, {cid, notes})
        if (res.data?.rs) {
            await dispatch(fetchContractNotes(cid))
            return onSuccess('Note added')
        }
    } catch (e) {
        // console.log('Note creation error:\n', e)
        return onError(e.message)
    }
    return onError('Something went wrong')
}

// Contract Commissions
export const updateContractCommission = (id, data, fetchProps, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.put(`/administrative/contract/commission/${id}`, data)
        if (res.data?.rs) {
            await dispatch(fetchAllContract(fetchProps))
            return onSuccess('Commission updated')
        }
        return onError(res?.data?.message || 'Cannot update commission')
    } catch (e) {
        return onError(e.message)
    }
}

export const createContractCommission = (data, fetchProps, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post('/administrative/contract/commission', data)
        if (res.data?.rs) {
            await dispatch(fetchAllContract(fetchProps))
            return onSuccess('Commission created')
        }
        return onError(res?.data?.message || 'Cannot create commission')
    } catch (e) {
        return onError(e.message)
    }
}

// Invoices
export const fetchAllInvoices = args => async dispatch => {
    try {
        const invoiceUrl = '/administrative/invoice/getAllInvoices'
        const res = await pagedGridDefaultFetch(invoiceUrl, args, AdministrativeActionType.FetchInvoicesProps)(dispatch)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchAllInvoices, payload: res.data})
            return true
        }
        throw res.data.message
    } catch (e) {
        // console.log('Cannot fetch invoice: ', e)
        sendErrorMessage(dispatch, 'Cannot fetch invoice')
        return false
    }
}

export const fetchInvoice = (uid, iid) => async dispatch => {
    try {
        const res = await api.get(`/administrative/invoice/getInvoice/${uid}/${iid}`)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchInvoice, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch invoice')
    } catch (e) {
        // console.log('Error fetching invoice details: ', e.message)
        sendErrorMessage(dispatch, e.message)
    }
    return false
}

export const fetchInvoicePublic = (uid, iid, onError, errorMessage) => async dispatch => {
    try {
        const res = await api.get(`/invoice/${uid}/${iid}`)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchInvoice, payload: res.data.data})
            return true
        }
        onError(errorMessage)
    } catch (e) {
        // console.log('Error fetching invoice details: ', e.message)
        return onError(errorMessage)
    }
    return false
}

export const signInvoice = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.post('/invoice/sign', data)
        if (res.data?.rs) {
            return onSuccess()
        }
        return onError(res?.data?.message || 'Cannot sign invoice')
    } catch (e) {
        // console.log('Error signing invoice: ', e.message)
        return onError(e.message)
    }
}

export const downloadUnsignedInvoicePdf =
    (paid, iid, uid, productName, customerName, onSuccess, onError) => async () => {
        try {
            const res = await api.post(`/administrative/invoice/unsignedPDF`, {uid, iid, paid}, {responseType: 'blob'})
            if (res.data) {
                const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}))
                const link = document.createElement('a')
                link.href = url
                link.download = `${productName}-${customerName}-${iid}_unsigned.pdf`
                document.body.appendChild(link)
                link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}))
                link.parentNode.removeChild(link)
                return onSuccess('Done')
            }
        } catch (e) {
            return onError(e.message)
        }
        return onError('Cannot fetch PDF file')
    }

export const downloadInvoicePdf = (uid, iid, customerName, onError) => async () => {
    try {
        const res = await api.post(`/administrative/invoice/getInvoicePdf`, {uid, iid}, {responseType: 'blob'})
        if (res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}))
            const link = document.createElement('a')
            link.href = url
            link.download = `${customerName}-${iid}.pdf`
            document.body.appendChild(link)
            link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: false, view: window}))
            link.parentNode.removeChild(link)
        }
    } catch (e) {
        onError(e.message)
    }
}

export const updateInvoiceStatus = (ids, fetchProps, isActive, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post('/administrative/invoice/status', {ids, isActive})
        if (res.data?.rs) {
            await dispatch(fetchAllInvoices(fetchProps))
            return onSuccess(`Invoice ${isActive ? 'enabled' : 'disabled'}`)
        }
        return onError(res?.data?.message || 'Cannot update invoice status')
    } catch (e) {
        // console.log('Error updating invoice status: ', e.message)
        return onError(e.message)
    }
}

export const fetchAllInvoiceHeads = () => async dispatch => {
    try {
        const res = await api.get('/administrative/contractTemplate/head')
        if (res.data?.rs) {
            dispatch({
                type: AdministrativeActionType.FetchAllInvoiceHeads,
                payload: res.data.data.filter(r => r.isActive)
            })
            return true
        }
        sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch templates')
    } catch (e) {
        // console.log('Error fetching template details: ', e.message)
        sendErrorMessage(dispatch, e.message)
    }
    return true
}

export const clearInvoiceNotes = () => async dispatch => {
    try {
        dispatch({type: AdministrativeActionType.ClearInvoiceNotes})
    } catch (e) {
        sendErrorMessage(dispatch, 'Error in Clear Invoice Notes')
        // console.log('Cannot clear notes', e)
    }
}

export const fetchInvoiceNotes = iid => async dispatch => {
    try {
        const res = await api.get(`/administrative/invoice/notes/${iid}`)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchInvoiceNotes, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch notes')
    } catch (e) {
        // console.log('Error fetching notes: ', e)
        sendErrorMessage(dispatch, 'Cannot fetch notes')
    }
    return false
}

export const createInvoice = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.post('/administrative/invoice/create', data)
        if (res.data?.rs) {
            return onSuccess('Invoice created', res.data.data.customer.id, res.data.data.iid)
        }
        return onError(res?.data?.message || 'Cannot create invoice')
    } catch (e) {
        // console.log('Error creating invoice: ', e.message)
        return onError(e.message)
    }
}

export const updateInvoice = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.put('/administrative/invoice/update', data)
        if (res.data?.rs) {
            return onSuccess('Invoice updated', res.data.data.customer.id, res.data.data.iid)
        }
        return onError(res?.data?.message || 'Cannot update invoice')
    } catch (e) {
        // console.log('Error updating invoice: ', e)
        return onError(e.message)
    }
}

export const sendInvoiceEmail = (data, onSuccess, onError) => async () => {
    try {
        const res = await api.post('/administrative/invoice/sendInvoiceEmail', data)
        if (res.data?.rs) {
            return onSuccess(res.data)
        }
        return onError(res?.data?.message || 'Email failed to send')
    } catch (e) {
        // console.log('Error sending email: ', e.message)
        return onError(e.message)
    }
}

export const deleteInvoiceNote = (id, iid, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.delete(`/administrative/invoice/deleteNote/${id}/${iid}`)
        if (res.data?.rs) {
            await dispatch(fetchInvoiceNotes(iid))
            return onSuccess('Note deleted')
        }
    } catch (e) {
        // console.log('Note delete error:\n', e)
        return onError(e.message)
    }
    return onError('Something went wrong')
}

export const createInvoiceNote = (iid, notes, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.post(`/administrative/invoice/createNote/`, {iid, notes})
        if (res.data?.rs) {
            await dispatch(fetchInvoiceNotes(iid))
            return onSuccess('Note added')
        }
    } catch (e) {
        // console.log('Note creation error:\n', e)
        return onError(e.message)
    }
    return onError('Something went wrong')
}

export const fetchAllEmailAccounts = () => async dispatch => {
    try {
        const res = await api.get('/api/getAllEmailAccounts')
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchAllEmailAccounts, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch email accounts')
    } catch (e) {
        // console.log('Error fetching email accounts: ', e)
        sendErrorMessage(dispatch, 'Cannot fetch email accounts')
    }
    return false
}

export const fetchEmailAccount = id => async dispatch => {
    try {
        const res = await api.get(`/api/getEmailAccount/${id}`)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchEmailAccount, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch email account')
        return false
    } catch (e) {
        // console.log('Error fetching email account: ', e)
        sendErrorMessage(dispatch, 'Cannot fetch email account')
        return false
    }
}

export const addContractPaymentEmails =
    (fname, lname, email, cid, onSuccess, onError, fetchProps) => async dispatch => {
        try {
            const data = {
                fname,
                lname,
                email,
                cid
            }
            await api.post(`administrative/contract/addPaymentEmail`, data).then(async res => {
                if (res.data?.rs) {
                    await dispatch(fetchAllContract(fetchProps))
                    return onSuccess('Payment Email created')
                }
                return onError(res?.data?.message || 'Cannot create payment email')
            })
        } catch (e) {
            return onError(e.message)
        }
        return onError('Error in add Contract Payment Emails')
    }

export const updateContractPaymentEmails =
    (fname, lname, email, emailId, cid, onSuccess, onError, fetchProps) => async dispatch => {
        try {
            const data = {
                fname,
                lname,
                email,
                id: emailId,
                cid
            }
            await api.post(`administrative/contract/updatePaymentEmail`, data).then(async res => {
                if (res.data?.rs) {
                    await dispatch(fetchAllContract(fetchProps))
                    return onSuccess('Payment Email updated')
                }
                return onError(res?.data?.message || 'Cannot update payment email')
            })
        } catch (e) {
            return onError(e.message)
        }
        return onError('Error in update Payment Email')
    }

export const deleteContractPaymentEmails =
    (fname, lname, email, emailId, cid, onSuccess, onError, fetchProps) => async dispatch => {
        try {
            const data = {
                fname,
                lname,
                email,
                id: emailId,
                cid
            }
            await api.post(`administrative/contract/deletePaymentEmail`, data).then(async res => {
                if (res.data?.rs) {
                    await dispatch(fetchAllContract(fetchProps))
                    return onSuccess('Payment Email deleted')
                }
                return onError(res?.data?.message || 'Cannot delete payment email')
            })
        } catch (e) {
            return onError(e.message)
        }
        return onError('Error in deleting Contract payment email')
    }

// export const fetchAllPaymentLogs = () => async (dispatch) => {
//   try {
//     const res = await api.get(`administrative/contract/getAllPaymentLogs`)
//     if (res.data?.rs) {
//       dispatch({ type: AdministrativeActionType.FetchAllPaymentLogs, payload: res.data.data })
//       return true
//     }
//       sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch payment logs')
//
//   } catch (e) {
//     // console.log('Error Cannot fetch payment logs: ', e)
//     sendErrorMessage(dispatch, 'Cannot fetch payment logs')
//   }
//   return false
// }

export const fetchAllPaymentLogsByPage = args => async dispatch => {
    try {
        const paymentLogUrl = 'administrative/contract/getAllPaymentLogs'
        const res = await pagedGridDefaultFetch(
            paymentLogUrl,
            args,
            AdministrativeActionType.FetchAllPaymentLogsProps,
            async () => api.get(paymentLogUrl)
        )(dispatch)

        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchAllPaymentLogs, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res.data?.message)
        return false
    } catch (e) {
        // console.log('Error Cannot fetch payment logs: ', e)
        sendErrorMessage(dispatch, e.message)
        return false
    }
}

export const fetchSinglePaymentLog = pid => async dispatch => {
    try {
        const res = await api.post(`administrative/contract/getSinglePaymentLog`, {pid})
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchSignlePaymentLog, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res.data?.message)
        return false
    } catch (e) {
        // console.log('Error Cannot fetch single payment Email: ', e)
        sendErrorMessage(dispatch, e.message)
        return false
    }
}

// export const processPaymentLogManually = ( pid,transferCurrency,conversionRate, spend,cid, onSuccess, onError ,setIsLoading) => async (dispatch) => {
//   try {
//     if (spend <= 0){
//       setIsLoading(false)
//       return onError( 'Amount to spend must be greater than 0.')
//     }
//
//     const decimalChecker = spend.split('.');
//     if(decimalChecker.length === 2 && decimalChecker[1].length > 2 ){
//       setIsLoading(false)
//       return onError( 'Amount to spend must be at most 2 decimals')
//     }
//
//     if (!cid){
//       setIsLoading(false)
//       return onError( 'No Contract selected.')
//     }
//     const data = {
//       pid,
//       currency:transferCurrency,
//       conversionRate,
//       spend,
//       cid
//     }
//     await api.post(`msg-queue/logManualProcessPayments`,data).then( async (res)=>{
//           if (res.data?.rs) {
//             await dispatch(fetchAllPaymentLogs()).then( async ()=>{
//               await dispatch(fetchAllContract({undefined,
//                 ...DEFAULT_FETCH_PARAM}))
//             })
//             setIsLoading(false)
//             return onSuccess('payment log processed manually ')
//           }
//           setIsLoading(false)
//           return onError(res?.data?.message || 'Cannot process payment log.')
//         }
//     )
//   } catch (e) {
//     return onError(e.message)
//   }
//   return onError('Error in processing payment log')
// }

export const addContractPaymentHistory =
    (email, currency, payer, date, type, amount, cid, pic, note, onSuccess, onError, fetchProps) => async dispatch => {
        try {
            const decimalChecker = amount.split('.')
            if (decimalChecker.length === 2 && decimalChecker[1].length > 2) {
                return onError('Amount to spend must be at most 2 decimals')
            }

            if (note.length === 0) {
                return onError('note should be added before')
            }
            const formData = new FormData()
            pic.forEach(file => {
                formData.append(file.name, file)
            })
            const data = {
                email,
                payerName: payer,
                receiveTime: date,
                paymentType: type,
                amount,
                cid,
                conversionRate: '1',
                currency,
                noteInJson: JSON.stringify(note)
            }
            Object.keys(data).forEach(param => {
                formData.append(param, data[param])
            })

            await api.post(`administrative/contract/createPaymentHistory`, formData).then(async res => {
                if (res.data?.rs) {
                    await dispatch(fetchAllContract(fetchProps))
                    return onSuccess('Payment history created')
                }
                return onError(res?.data?.message || 'Cannot create payment history')
            })
        } catch (e) {
            return onError(e.message)
        }
        return onError('Error in creating payment history')
    }

export const fetchCurrency = () => async dispatch => {
    try {
        const res = await api.get('administrative/contract/get-all-currency')
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchCurrency, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, res?.data?.message || 'Cannot fetch currency')
    } catch (e) {
        // console.log('Error fetching currency: ', e)
        sendErrorMessage(dispatch, 'Cannot fetch currency')
    }
    return false
}

export const searchContractsNoPage = searchKey => async dispatch => {
    const data = {
        searchKey
    }
    try {
        const res = await api.post('administrative/contract/searchContractsNoPage', data)
        if (res.data?.rs) {
            return res.data.data
        }
        return []
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch contract')
        return []
    }
}

export const assignPaymentEmailToOneContract = (email, fname, lname, cid) => async dispatch => {
    const data = {
        email,
        fname,
        lname,
        cid
    }
    try {
        const res = await api.post('administrative/contract/assiegnPaymentEmailToOneCnntract', data)
        if (res.data?.rs) {
            sendSuccessMessage(dispatch, 'Successfully linked One Contract')
            return true
        }
        sendErrorMessage(dispatch, res.data?.message)
        return false
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot linked contract')
        return false
    }
}

export const createPaymentHistoryFromLog = (cid, pid, amountPaying) => async dispatch => {
    const data = {
        cid,
        pid,
        amountPaying
    }
    try {
        const decimalChecker = amountPaying.split('.')
        if (decimalChecker.length === 2 && decimalChecker[1].length > 2) {
            sendErrorMessage(dispatch, 'Amount to spend must be at most 2 decimals')
            return false
        }

        const res = await api.post('administrative/contract/createPaymentHistoryFromLog', data)
        if (res.data?.rs) {
            sendSuccessMessage(dispatch, 'Successfully linked One Contract')
            return true
        }
        sendErrorMessage(dispatch, res.data?.message)
        return false
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot linked contract')
        return false
    }
}

export const updateContractRemaining = (remaining, cid, fetchProps) => async dispatch => {
    const remainingInCent = parseFloat(remaining) * 100
    const data = {
        cid,
        remaining: remainingInCent
    }
    try {
        if (!Number.isInteger(remainingInCent)) {
            sendErrorMessage(dispatch, 'please enter a valid amount')
            return false
        }

        const res = await api.post('administrative/contract/updateContractRemaining', data)
        if (res.data?.rs) {
            await dispatch(fetchAllContract(fetchProps))
            sendSuccessMessage(dispatch, 'Successfully updated Contract Remaining')
            return true
        }
        sendErrorMessage(dispatch, res.data?.message)
        return false
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot update contract Remaining')
        return false
    }
}
export const fetchAllContractTerms = args => async dispatch => {
    try {
        // when category property of args is term, setting url to all contract templates, otherwise, setting url to contract terms
        const contractTermUrl = '/administrative/contractTemplate/fetchAllTerms'
        const res = await pagedGridDefaultFetch(
            contractTermUrl,
            args,
            AdministrativeActionType.FetchContractTemplateProps,
            async () => api.get(contractTermUrl)
        )(dispatch)

        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchAllContractTerms, payload: res.data})
            return true
        }
        return false
    } catch (e) {
        // console.log('Error fetching contract template details: ', e.message)
        sendErrorMessage(dispatch, e.message)
        return false
    }
}

export const createContractTerm = async (data, onSuccess = noop, onError = noop) => {
    try {
        const res = await api.post('/administrative/contractTerm/create', data)
        if (res.data?.rs) {
            return onSuccess('New contract term created', {...res?.data?.data})
        }
        return onError(res?.data?.message || 'Cannot create new contract term')
    } catch (e) {
        return onError(e.message)
    }
}
export const updateContractTerm = async (data, onSuccess = noop, onError = noop) => {
    try {
        const res = await api.put('/administrative/contractTerm/update', data)
        if (res.data?.rs) {
            return onSuccess('Contract term updated', {...res?.data?.data})
        }
        return onError(res?.data?.message || 'Cannot update contract term')
    } catch (e) {
        // console.log('Error updating contract template: ', e.message)
        return onError(e.message)
    }
}

export const fetchContractTerm = id => async dispatch => {
    try {
        const res = await api.get(`/administrative/contract/term/${id}`)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchContractTerm, payload: res.data.data})
            return true
        }
        return sendErrorMessage(dispatch, res.data?.message || 'Cannot fetch contract term')
    } catch (e) {
        // console.log('Error fetching contract template details: ', e.message)
        sendErrorMessage(dispatch, e.message)
    }
    return false
}

export const fetchDefaultTerms = () => async dispatch => {
    try {
        const res = await api.post(`administrative/contract/term/fetchDefaultTerms`)
        if (res.data?.rs) {
            dispatch({type: AdministrativeActionType.FetchFixedTerms, payload: res.data.data})
        }
        return false
    } catch (e) {
        return false
    }
}

export const fetchAllFilterTerms = args => async dispatch => {
    const {searchKey} = args
    try {
        const termUrl = 'administrative/contract/term/searchTermByName'
        const res = await api.post(termUrl, {searchKey})

        if (res.data) {
            dispatch({type: AdministrativeActionType.FetchTermFilterList, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, 'Cannot fetch terms')
        return false
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch terms')
        return false
    }
}

export const fetchTermsBeSelected = ids => async dispatch => {
    try {
        const res = await api.post('administrative/contract/term/fetchTermsBeSelected', {ids})
        if (res.data.rs) {
            dispatch({type: AdministrativeActionType.FetchTermsBeSelected, payload: res.data.data})
            return true
        }
        sendErrorMessage(dispatch, 'Cannot fetch terms')
        return false
    } catch (e) {
        sendErrorMessage(dispatch, 'Cannot fetch terms')
        return false
    }
}
export const updateContractTermStatus = (ids, fetchProps, isActive, isDelete, onSuccess, onError) => async dispatch => {
    try {
        const res = await api.put('/administrative/contractTerm/updateStatus', {ids, isActive, isDelete})
        if (res.data?.rs) {
            await dispatch(fetchAllContractTerms(fetchProps))
            return onSuccess(`Term(s) ${isDelete ? 'deleted' : isActive ? 'activated' : 'deactivated'}`)
        }
        return onError(res?.data?.message || 'Cannot update contract term status')
    } catch (e) {
        // console.log('Error updating contract template status: ', e.message)
        return onError(e.message)
    }
}
