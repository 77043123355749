

export const Functions = {
    System_Management : 1,
    System_Management_Functions : 3,
    System_Management_Functions_Add_Function : 5,
    System_Management_Functions_Edit_Function : 6,
    System_Management_Roles : 4,
    System_Management_Roles_Add_Role : 7,
    System_Management_Roles_Edit_Role : 8,
    System_Management_Switch_User : 9,
    System_Management_Log : 10,
    System_Management_System_Setting : 20,
    System_Management_Online_Users : 23,
    Customer_Management : 102,
    Customer_Management_Registered_Information : 111,
    Customer_Management_Registered_Information_Active : 112,
    Customer_Management_Registered_Information_Add_Customer : 121,
    Customer_Management_Registered_Information_Edit_Customer : 122,
    Customer_Management_Registered_Information_Check_Customer : 289,
    Customer_Management_Category : 228,
    Customer_Management_Tracing: 256,
    HR_Management : 24,
    HR_Management_Employees : 25,
    HR_Management_Add_Employee : 26,
    HR_Management_Edit_Employee : 27,
    Academic_Calendar : 13,
    Academic_Calendar_Student_Menu_1 : 14,
    Academic_Calendar_Student_Menu_2 : 15,
    My_Profile : 116,
    My_Profile_Personal_Information : 17,
    My_Profile_Personal_Information_Change_Password : 18,
    My_Profile_Personal_Information_Upload_Photo : 19,
    Training_Management : 137,
    Training_Management_Class_Information : 138,
    Training_Management_Class_Information_Add_Class : 139,
    Training_Management_Class_Information_Edit_Class : 140,
    Training_Management_Class_Information_People_Management : 141,
    Training_Management_Syllabus_Information: 148,
    Training_Management_Syllabus_Information_Syllabus_Details: 150,
    Training_Management_Syllabus_Information_Add_Syllabus: 151,
    Training_Management_Homework_Information: 162,
    Training_Management_Homework_Information_Add_Homework: 163,
    Training_Management_Homework_Information_Edit_Homework: 164,
    Training_Management_Syllabus_Information_Edit_Syllabus: 152,
    Training_Management_Student_Message: 168,
    Training_Management_Student_Message_Edit_Student_Message: 173,

    Forms: 142,
    Forms_View_All_Form: 143,
    Forms_Add_Form: 144,
    Forms_Edit_Form: 176,
    Forms_Category_Manager: 177,
    Forms_View_Form_Submissions: 145,
    Forms_Manage_Form_Submissions: 212,
    Forms_View_Form: 178,
    Forms_Poster_Generator_List: 270,
    Forms_Poster_Generator_View: 275,
    Forms_Poster_Generator_Create: 271,
    Forms_Poster_Generator_Edit: 272,
    Forms_Poster_Generator_Generate: 273,

    Product_Management:130,
    Product_Management_Product_List:131,
    Product_Management_Add_Product:132,
    Product_Management_Edit_Product:136,
    Product_Product_Category:261,

    Training_Message: 165,
    Training_Message_Edit_Message: 166,
    Training_Message_Add_New_Message: 167,
    Training_Message_Student_Read: 174,


    Training_Class: 138,
    Training_Class_Create_Class: 139,
    Training_Class_Class_Details: 140,
    Training_Class_Class_Progress: 276,
    Training_Class_Delete_Class: 179,
    Training_Class_Edit_Class: 180,
    Training_Class_Announcement: 192,
    Training_Class_File: 207,
    Training_Class_Homework: 208,
    Training_Class_Lesson: 210,
    Training_Class_Class_Details_Give_Feedback: 190,
    Training_Class_Class_Details_Read_Feedback: 191,


    Training_Syllabus: 148,
    Training_Homework: 162,
    Training_Resources: 278,

    Campaign: 156,
    Campaign_View_List: 158,
    Campaign_Delete: 202,
    Campaign_Create: 203,
    Campaign_Edit: 205,
    Campaign_Category_Manager: 204,
    Campaign_Details: 206,
    Campaign_Add_Poster: 215,
    Campaign_Edit_Poster: 216,
    Campaign_Delete_Poster: 217,
    Campaign_Download_Poster: 221,


    Employee_Management: 125,
    Employee_Management_Employee_List: 213,
    Employee_Management_Employee_List_Edit_Employee: 127,
    Employee_Management_Employee_List_Add_Employee: 126,

    Administrative: 229,
    Administrative_Login_History: 317,
    Administrative_Tracking_List: 230,
    Administrative_Contract_Template_List: 231,
    Administrative_Contract_Template_View: 234,
    Administrative_Contract_Template_Create: 232,
    Administrative_Contract_Template_Edit: 233,

    Administrative_Contract_List: 235,
    Administrative_Contract_Create: 239,
    Administrative_Contract_Edit: 237,
    Administrative_Contract_View: 238,
    Administrative_Contract_Commission: 314,

    Administrative_Invoice: 147,
    Administrative_Invoice_List: 240,
    Administrative_Invoice_Details: 241,
    Administrative_Invoice_Edit: 242,
    Administrative_Invoice_Create: 243,

    Review: 244,
    Review_List: 245,
    Review_Details: 250,
    Review_Create: 251,
    Review_Edit: 252,
    Review_Set: 246,
    Review_Set_Details: 247,
    Review_Set_Create: 249,
    Review_Set_Edit: 248,
    Review_Set_Send: 253,
    Review_M2W_List: 290,
    Review_M2W_Add: 291,
    Review_M2W_Edit: 292,

    Interview_List: 255,
    Interview_Create: 257,
    Interview_Edit: 258,
    Interview_List_Schedule: 254,
    Interview_Review: 259,

    QRCode: 262,
    QRCode_Category: 266,
    QRCode_Set_List: 263,
    QRCode_Set_Add: 264,
    QRCode_Set_Edit: 265,
    QRCode_Code: 267,
    QRCode_Code_Add: 268,
    QRCode_Code_Edit: 269,

    Matric: 279,
    Matric_Monitor_List: 280,
    Matric_Keywords_List: 281,
    Matric_Keywords_Add: 282,
    Matric_Keywords_Edit: 283,
    Matric_TopUp_List: 284,
    Matric_TopUp_Add_Account: 285,
    Matric_TopUp_Edit_Account: 286,
    Matric_TopUp_Add_TopUp_Post: 287,
    Matric_TopUp_Edit_TopUp_Post: 288,
}

export default Functions