import React, {useEffect, useState} from 'react'
import {List, ListItem, ListItemText, Paper} from '@mui/material'
import {getTotalHeight} from '../../../utils/Scripts'

const ClassSyllabusRearranger = ({data, onSet = () => {}}) => {
    const itemHeight = Math.floor(getTotalHeight() / 1.5) - 100

    const [state, setState] = useState({
        items: data,
        draggedItem: {}
    })

    useEffect(() => {
        setState({...state, items: data.filter(d => d)})
    }, [data])

    useEffect(() => {
        onSet(state.items?.map((item, idx) => ({id: item.id, order: idx})))
    }, [state.items, onSet])

    const handleDragStart = (e, item) => {
        setState({...state, draggedItem: item})
    }

    const handleDragOver = e => {
        e.preventDefault()
    }

    const handleDrop = (e, droppedIndex) => {
        const {draggedItem} = state
        if (draggedItem && draggedItem !== droppedIndex) {
            const newItems = [...state.items]
            const draggedIndex = newItems.findIndex(item => item.id === draggedItem.id)
            if (draggedIndex !== -1 && droppedIndex !== -1) {
                newItems.splice(droppedIndex, 0, newItems.splice(draggedIndex, 1)[0])
                setState({...state, items: newItems, draggedItem: null})
            }
        }
    }

    const renderView = () => (
        <Paper style={{width: '100%', height: `${itemHeight}px`, overflow: 'auto'}}>
            <List style={{width: '100%'}}>
                {state.items.map((item, index) => (
                    <ListItem
                        key={item.id}
                        draggable
                        onDragStart={e => handleDragStart(e, item)}
                        onDragOver={handleDragOver}
                        onDrop={e => handleDrop(e, index)}
                    >
                        <ListItemText key={item.id} primary={item.name} />
                    </ListItem>
                ))}
            </List>
        </Paper>
    )

    return renderView()
}

export default ClassSyllabusRearranger
